import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "../../components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  configNames,
  getConfigValue,
  serverConfig,
  serverApi,
} from "../../helper/Consts";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {
  getDataFromUrl,
  requestList,
  getFileDownload,
  uploadFile,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { supplierTypesList } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import LogsComponents from "../CustumComponents/LogsComponents.js";
//import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate.js";

class SupplierMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "suppId",
      date1: moment().format("DD-MM-YYYY"),
      year: moment().format("YYYY"),
      suppCode: "",
      createdBy: "",
      supName: "",
      address: "",
      // suppTyp: this.getDefaultSupType("STORE"),
      suppTyp:"",
      phone1: "",
      phone2: "",
      fax: "",
      cellPhone: "",
      gstno: "",
      panNo: "",
      accountNumber: "",
      ifscCode: "",
      cin: "",
      tanNo: "",
      pincode: "",
      district: "",
      country: "",
      state: "",
      mukamList: [],
      ledgerGroup: "",
      openningBalance: "",
      invoiceList: [],
      autoId: "",
      email: "",
      branchAddress: "",
      gstNo: "",
      contactPerson: "",
      contactNo: "",
      stateCode: "",
      stateName: "",
      city: "",
      statesList: [],
      branchId: "",
      status: "",
      approverButton: false,
      statusDesc: "",
      msme: "N",
      file: "",
      itemName: "",
      supplierPopupEmail: "",
      aStatus: "Yes",
      rcCopy: "",
      rcCopyFile: "",
      panFileList: [],
      tanFileList: [],
      canChequeFileList: [],
      msmeFileList: [],
      gstFileList: [],
      rcCopyFileList: [],
      panFileName: "",
      tanFileName: "",
      cancelledChequeFileName: "",
      msmeFileName: "",
      gstFileName: "",
      rcCopyFileName: "",
      branchId: "",
      companyId: "",
      //showMe:false
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleFileClose = () => {
    this.setState({
      itemName: "",
      file: "",
      fileType: "",
    });
  };

  handleSelectedPSValues = (defValue) => {
    console.log("sssssssssssssss", defValue);

    this.setState({
      // unitConvertion: defValue,
      suppTyp: defValue,
    });
    if (defValue.value === "J") {
      this.handleLoading();
      getDataFromUrl(
        serverApi.SEARCH_MUKAM + this.props.state.companyInfo.id,
        this.handleItems
      );
    } else {
      this.setState({
        mukamList: [],
      });
    }
    //  console.log(`Option selected:`, this.state.unitConvertionName);
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_SIPPLIER_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp,
      list = [];

    if (header !== null) {
      if (resp.supLineItems && resp.supLineItems.length > 0) {
        resp.supLineItems.forEach((prop, key) => {
          var obj = { id: key, ...prop };
          list.push(obj);
        });
      }
      this.setState({
        updateView: true,
        isCreated: true,
        suppId: header.suppId,
        suppCode: header.id,
        createdBy: header.createdBy,
        createdOn: header.createdOn,
        mukamList: header.mukamsList,
        supName: header.suppName,
        address: header.address1,
        suppTyp: this.getDefaultSupType(header.suppTyp),
        phone1: header.phone1,
        phone2: header.phone2,
        fax: header.fax,
        cellPhone: header.cellPhone,
        email: header.email,
        vatno: header.vatno,
        ledgerGroup: header.ledgerGroup,
        contactPerson: header.contactPerson,
        designation: header.designation,
        openningBalance: header.openningBalance,
        gstno: header.gstNo,
        panNo: header.panNo,
        accountNumber: header.accountNumber,
        ifscCode: header.ifscCode,
        cin: header.cin,
        pincode: header.pincode,
        district: header.district,
        country: header.country,
        state: header.state,
        invoiceList: list,
        approverButton: header.approverButton,
        status: header.status,
        branchId: header.branchId,
        statusDesc: header.statusDesc,
        comments: header.comments,
        msmeCertified: header.msmeCertified,
        tanNo: header.tanNo,
        contactPerson: header.contactPerson,
        supplierPopupEmail: header.email,
        companyId: header.companyId,
      });
      if (header.suppTyp === "JUTE") {
        this.handleLoading();
        getDataFromUrl(
          serverApi.SEARCH_MUKAM + this.props.state.companyInfo.id,
          this.handleItems
        );
      }
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "36/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handlePanFileView
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "37/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleTanFileView
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "38/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleMsmeFileView
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "39/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleCanChequeFileView
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "40/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleGstFileView
      );
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "41/" +
          header.suppId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleRcCopyFileView
      );
    }
  };
  handlePanFileView = (resp) => {
    console.log("response", resp);
    if (resp.data) {
      this.setState({
        panFileList: resp.data,
        //isCreated: true
      });
    }
  };
  handleTanFileView = (resp) => {
    console.log("response", resp);
    if (resp.data) {
      this.setState({
        tanFileList: resp.data,
        //isCreated: true
      });
    }
  };
  handleMsmeFileView = (resp) => {
    console.log("response", resp);
    if (resp.data) {
      this.setState({
        msmeFileList: resp.data,
        //isCreated: true
      });
    }
  };
  handleCanChequeFileView = (resp) => {
    if (resp.data) {
      this.setState({
        canChequeFileList: resp.data,
        //isCreated: true
      });
    }
  };
  handleGstFileView = (resp) => {
    console.log("response", resp);
    if (resp.data) {
      this.setState({
        gstFileList: resp.data,
        //isCreated: true
      });
    }
  };
  handleRcCopyFileView = (resp) => {
    console.log("response", resp);
    if (resp.data) {
      this.setState({
        rcCopyFileList: resp.data,
        isCreated: true,
      });
    }
  };
  getDefaultSupType = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "JUTE") {
        val = "J";
        label = "JUTE";
      } else if (name === "STORE") {
        val = "S";
        label = "STORE";
      } else if (name === "AGENT") {
        val = "O";
        label = "AGENT";
      } else {
        val = "V";
        label = "VENDOR";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });

    //  this.hideAlertMessage();
  };

  componentDidMount() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
    getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);
  }
  handleStates = (resp) => {
    var list = [];
    if (resp.status === undefined) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) obj.value = prop.label;
        else obj.value = "";
        list.push(obj);
      });
    }
    this.setState({
      statesList: list,
    });
  };

  handleLedgerGroup = (resp) => {
    const lgoptions = [];
    var ledger_data = resp.data;
    if (ledger_data !== null || ledger_data !== undefined) {
      ledger_data.forEach((r) => {
        const { name, id } = r;
        lgoptions.push({
          value: r.id,
          label: r.name,
          name: r.name,
        });
      });
    }
    this.setState({ ledger_data: lgoptions });
  };
  handleItems = (resp) => {
    var list = [];
    resp.map((prop, key) => {
      var val = {
        mukamId: prop.value,
        mukamName: prop.label,
        isEnabled: false,
      };
      if (this.state.updateView) {
        this.state.mukamList.map((o, i) => {
          if (o === val.mukamId) {
            val.isEnabled = true;
          }
        });
      }
      //console.log("mukamId", val);
      //  console.log("mukamId oo",o);
      if (key > 0) list.push(val);
    });
    this.setState({
      mukamList: list,
    });
    this.handleLoading();
  };

  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (index) => (event) => {
    var arr = this.state.mukamList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      mukamList: arr,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  hideInvoiceBasicAlert() {
    if (this.state.deletePanFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.panFileId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteTanFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.tanFileId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteCanChqFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.canChequeFileId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteMsmeFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.msmeFileId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteGstFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.gstFileId,
        this.handleDeleteInvoiceResp
      );
    }
    if (this.state.deleteRcCopyFile) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.rcCopyFileId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      if (this.state.deletePanFile) {
        var arr = this.state.panFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          panFileList: arr,
          deletePanFile: false,
          fileId: "",
        });
      }
      if (this.state.deleteTanFile) {
        var arr = this.state.tanFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          tanFileList: arr,
          deleteTanFile: false,
          fileId: "",
        });
      }
      if (this.state.deleteCanChqFile) {
        var arr = this.state.canChequeFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          canChequeFileList: arr,
          deleteCanChqFile: false,
          fileId: "",
        });
      }
      if (this.state.deleteMsmeFile) {
        var arr = this.state.msmeFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          msmeFileList: arr,
          deleteMsmeFile: false,
          fileId: "",
        });
      }
      if (this.state.deleteGstFile) {
        var arr = this.state.gstFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          gstFileList: arr,
          deleteGstFile: false,
          fileId: "",
        });
      } else {
        var arr = this.state.rcCopyFileList;
        arr.splice(this.state.fileId, 1);
        this.setState({
          rcCopyFileList: arr,
          deleteRcCopyFile: false,
          fileId: "",
        });
      }
    }
    console.log("resp", JSON.stringify(resp));
  };
  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      if (this.state.updateView) data[this.state.id].isActive = 0;
      else data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      autoId: "",
      branchAddress: "",
      gstNo: "",
      contactNo: "",
      stateCode: "",
      stateName: "",
      city: "",
      supplierPopupEmail: "",
      popupContactPerson: "",
      rcCopy: "",
      gstFile: "",
      gstFileName: "",
      gstFileType: "",
      rcCopyFile: "",
      rcCopyFileName: "",
      rcCopyFileType: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      suppCode: "",
      createdBy: "",
      supName: "",
      address: "",
      //suppTyp: "",
      phone1: "",
      phone2: "",
      fax: "",
      cellPhone: "",
      email: "",
      vatno: "",
      //contactPerson: "",
      designation: "",
      gstno: "",
      panNo: "",
      accountNumber: "",
      ifscCode: "",
      cin: "",
      pincode: "",
      district: "",
      country: "",
      state: "",
      openningBalance: "",
      mukamList: [],
      invoiceList: [],
      branchId: "",
      status: "",
      approverButton: false,
      statusDesc: "",
      tanNo: "",
      activeStatus: "",
      ledgerGroup: "",
      comments: "",
      panFile: "",
      panFileName: "",
      panFileType: "",
      tanFile: "",
      tanFileName: "",
      tanFileType: "",
      cancelledChequeFile: "",
      cancelledChequeFileName: "",
      cancelledChequeFileType: "",
      msmeFile: "",
      msmeFileName: "",
      msmeFileType: "",
      gstFile: "",
      gstFileName: "",
      gstFileType: "",
      gstFileList: [],
      rcCopyFile: "",
      rcCopyFileName: "",
      rcCopyFileType: "",
      rcCopyFileList: [],
      companyId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  handleDownloadedFile = (url) => {
    window.open(url);
  };
  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        openningBalance,
        suppCode,
        suppId,
        mukamList,
        supName,
        address,
        suppTyp,
        phone1,
        phone2,
        fax,
        cellPhone,
        email,
        contactPerson,
        designation,
        gstno,
        panNo,
        accountNumber,
        ifscCode,
        cin,
        pincode,
        district,
        country,
        state,
        ledgerGroup,
        branchId,
        tanNo,
        msmeCertified,
        comments,
        supplierPopupEmail,
        popupContactPerson,
      } = this.state;

      var list = [];
      mukamList.map((prop, key) => {
        if (prop.isEnabled) {
          var val = {
            id: prop.mukamId,
            mukamName: prop.mukamName,
          };
          list.push(val);
        }
      });
      var lineItem = [];
      invoiceList.forEach((prop) => {
        lineItem.push({
          ...prop,
          suppId: suppId,
          createdBy: getCookie(cons.USERID),
        });
      });
      var data = {
        suppId: suppId,
        id: suppCode,
        suppName: supName,
        address1: address,
        suppTyp: suppTyp.value,
        phone1: phone1,
        phone2: phone2,
        fax: fax,
        cellPhone: cellPhone,
        email: email,
        activeFlag: "",
        vatno: "",
        contactPerson: contactPerson,
        designation: designation,
        cin: cin,
        gstNo: gstno,
        panNo: panNo,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        district: district,
        state: state,
        country: country,
        pincode: pincode,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        address2: address,
        cipher: getCookie(cons.CIPHER),
        ledgerGroup: ledgerGroup,
        openningBalance: openningBalance,
        mukam: list,
        status: status,
        branchId: branchId,
        userId: getCookie(cons.USERID),
        tanNo: tanNo,
        msmeCertified: msmeCertified,
        comments: comments,
        contactPerson: contactPerson,
        supLineItems: lineItem,
      };

      const validateFields = [
        { field: supName, msg: "Supplier Name", type: 1 },
        { field: suppTyp, msg: "Supplier Type", type: 2 },
        { field: branchId, msg: "Branch", type: 2 },
        { field: email, msg: "Email", type: 1 },
        { field: invoiceList, msg: "Line Items", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.SUPPLIER_MASTER_UPDATE,
          data,
          this.handleOncreateFileUpload
        );
      }
      // if (
      //   suppTyp !== "" &&
      //   suppTyp !== 0 &&
      //   supName !== "" &&
      //   email !== "" &&
      //   branchId
      // ) {
      //   if (suppTyp.value === "J") {
      //     if (mukamList.length > 0) {
      //       this.handleLoading();
      //       requestList(
      //         serverApi.SUPPLIER_MASTER_UPDATE,
      //         data,
      //         this.handleOncreateFileUpload
      //       );
      //     } else {
      //       this.basicAlert("Please Enter All Mandatory Fields..!!");
      //     }
      //   } else {
      //     this.handleLoading();
      //     requestList(
      //       serverApi.SUPPLIER_MASTER_UPDATE,
      //       data,
      //       this.handleOncreateFileUpload
      //     );
      //   }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        openningBalance,
        mukamList,
        supName,
        address,
        suppTyp,
        phone1,
        phone2,
        fax,
        cellPhone,
        email,
        contactPerson,
        designation,
        gstno,
        panNo,
        accountNumber,
        ifscCode,
        cin,
        pincode,
        district,
        country,
        state,
        ledgerGroup,
        branchId,
        tanNo,
        msmeCertified,
        comments,
        supplierPopupEmail,
        popupContactPerson,
      } = this.state;
      console.log("Invoice List", invoiceList);
      var list = [];
      mukamList.map((prop, key) => {
        if (prop.isEnabled) {
          var val = {
            id: prop.mukamId,
            mukamName: prop.mukamName,
          };
          list.push(val);
        }
      });
      var lineItem = [];
      invoiceList.forEach((prop) => {
        //lineItem.push({...prop,createdBy:getCookie(cons.USERID)})
        var obj = {
          autoId: "",
          branchAddress: prop.branchAddress,
          city: prop.city,
          contactNo: prop.contactNo,
          createdBy: getCookie(cons.USERID),
          gstNo: prop.gstNo,
          id: prop.id,
          isActive: prop.isActive,
          contactPerson: prop.contactPerson,
          rcCopy: prop.rcCopy,
          stateCode: prop.stateCode,
          stateName: prop.stateName,
          email: prop.email,
        };
        lineItem.push(obj);
      });
      var data = {
        suppName: supName,
        //"address1": address,
        suppTyp: suppTyp.value,
        phone1: phone1,
        phone2: phone2,
        //"fax": fax,
        cellPhone: cellPhone,
        email: email,
        activeFlag: "",
        vatno: "",
        contactPerson: contactPerson,
        designation: designation,
        cin: cin,
        gstNo: gstno,
        panNo: panNo,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        //"district": district,
        state: state,
        //"country": country,
        //"pincode": pincode,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        address2: address,
        cipher: getCookie(cons.CIPHER),
        mukam: list,
        ledgerGroup: ledgerGroup,
        autoCreate: false,
        openningBalance: openningBalance,
        status: 1,
        branchId: branchId,
        userId: getCookie(cons.USERID),
        tanNo: tanNo,
        msmeCertified: msmeCertified,
        comments: comments,
        contactPerson: contactPerson,
        supLineItems: lineItem,
      };
    
      const validateFields = [
        { field: supName, msg: "Supplier Name", type: 1 },
        { field: suppTyp, msg: "Supplier Type", type: 2 },
        { field: branchId, msg: "Branch", type: 2 },
        { field: email, msg: "Email", type: 1 },
        { field: invoiceList, msg: "Line Items", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.SUPPLIER_MASTER_ADD,
          data,
          this.handleOncreateFileUpload
        );
      }

      // if (
      //   suppTyp !== "" &&
      //   suppTyp !== 0 &&
      //   supName !== "" &&
      //   email !== "" &&
      //   branchId
      // ) {
      //   if (suppTyp.value === "J") {
      //     var pattern = new RegExp(
      //       /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      //     );
      //     if (pattern.test(email)) {
      //       if (mukamList.length > 0) {
      //         this.handleLoading();
      //         requestList(
      //           serverApi.SUPPLIER_MASTER_ADD,
      //           data,
      //           this.handleOncreateFileUpload
      //         );
      //       } else {
      //         this.basicAlert("Please Enter All Mandatory Fields..!!");
      //       }
      //     } else {
      //       this.basicAlert("Please Enter valid Email..!!");
      //     }
      //   } else {
      //     var pattern = new RegExp(
      //       /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      //     );
      //     if (pattern.test(email)) {
      //       console.log("data-----------------", data);
      //       this.handleLoading();
            // requestList(
            //   serverApi.SUPPLIER_MASTER_ADD,
            //   data,
            //   this.handleOncreateFileUpload
            // );
      //     } else {
      //       this.basicAlert("Please Enter valid Email..!!");
      //     }
      //   }
      //   // if (this.state.email!== "") {
      //   //     //debugger;
      //   //     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      //   //     if (pattern.test(this.state.email)) {
      //   //         alert("Success")
      //   //     }
      //   //     else{
      //   //         this.basicAlert("Please Enter valid Email..!!");
      //   //     }

      //   // }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    const {
      autoId,
      email,
      branchAddress,
      gstNo,
      contactPerson,
      contactNo,
      stateCode,
      stateName,
      city,
      supplierPopupEmail,
      popupContactPerson,
      rcCopy,
    } = this.state;

    var addItemObj = {
      id: itemIndex,
      autoId: autoId,
      isActive: 1,
      //"email": email,
      branchAddress: branchAddress,
      gstNo: gstNo,
      //"contactPerson": contactPerson,
      contactNo: contactNo,
      stateCode: stateCode,
      stateName: stateName,
      city: city,
      email: supplierPopupEmail,
      contactPerson: popupContactPerson,
      rcCopy: rcCopy,
    };

    const validateFields = [
      { field: branchAddress, msg: "Address", type: 1 },
      { field: stateName, msg: "State", type: 2 },
      { field: contactNo, msg: "Contact No", type: 1 },
      { field: supplierPopupEmail, msg: "Email", type: 1 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    } 
    
    else if (gstNo !== "") {
      var compareStr = this.state.gstNo.slice(0, 2);
      if (compareStr === stateCode) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);
        //console.log(arr.toString());

        this.setState({
          loading: true,
          invoiceList: arr,
        });
        this.hideAlert();
      } else {
        this.basicAlert("GST Number is not matching with State Code ..!!");
      }
    } 
    else {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);
      //console.log(arr.toString());

      this.setState({
        loading: true,
        invoiceList: arr,
      });
      this.hideAlert();
    }

    // if (branchAddress && supplierPopupEmail && stateName && contactNo) {
    //   if (gstNo !== "") {
    //     var compareStr = this.state.gstNo.slice(0, 2);
    //     if (compareStr === stateCode) {
    //       if (this.state.updateItem) {
    //         arr[itemIndex] = addItemObj;
    //       } else arr.splice(arr.length, 0, addItemObj);
    //       //console.log(arr.toString());

    //       this.setState({
    //         loading: true,
    //         invoiceList: arr,
    //       });
    //       this.hideAlert();
    //     } else {
    //       this.basicAlert("GST Number is not matching with State Code ..!!");
    //     }
    //   } 
    //   else {
    //     if (this.state.updateItem) {
    //       arr[itemIndex] = addItemObj;
    //     } else arr.splice(arr.length, 0, addItemObj);
    //     //console.log(arr.toString());

    //     this.setState({
    //       loading: true,
    //       invoiceList: arr,
    //     });
    //     this.hideAlert();
    //   }
    // } else {
    //   this.basicAlert("Please Enter All Mandatory Fields..!!");
    // }
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType,supName,suppTyp,branchId ,email} = this.state;
    const validateFields = [
      { field: supName, msg: "Supplier Name", type: 1 },
      { field: suppTyp, msg: "Supplier Type", type: 2 },
      { field: branchId, msg: "Branch", type: 2 },
      { field: email, msg: "Email", type: 1 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    }
    else {
    this.setState({
      loading: true,
      alert: true,
    });
  }
  }

  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // alert(o.qualityId);
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //  data.splice(i,1);

                        this.basicItemAlert("Do You Like To Update This Item?");
                        this.setState({
                          //alert: true,
                          updateItem: true,
                          updateItemAlert: true,
                          id: o.id,
                          autoId: o.autoId,
                          //"email": o.email,
                          branchAddress: o.branchAddress,
                          gstNo: o.gstNo,
                          accountNumber: o.accountNumber,
                          ifscCode: o.ifscCode,
                          //"contactPerson": o.contactPerson,
                          contactNo: o.contactNo,
                          stateCode: o.stateCode,
                          stateName: o.stateName,
                          city: o.city,
                          supplierPopupEmail: o.email,
                          popupContactPerson: o.contactPerson,
                          rcCopy: o.rcCopy,
                        });

                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };
  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }
  handleOncreateFileUpload = (resp) => {
    //console.log("%%%%%",resp,this.state.gstFileName,resp.id)
    const {
      panFileName,
      panFile,
      panFileType,
      tanFileName,
      tanFile,
      tanFileType,
      msmeFileName,
      msmeFile,
      msmeFileType,
      cancelledChequeFileName,
      cancelledChequeFile,
      cancelledChequeFileType,
      gstFileName,
      gstFile,
      gstFileType,
      rcCopyFileName,
      rcCopyFile,
      rcCopyFileType,
      suppId,
    } = this.state;

    var response = resp;
    if (resp.status) {
      if (
        panFileName !== "" ||
        tanFileName !== "" ||
        msmeFileName !== "" ||
        cancelledChequeFileName !== "" ||
        gstFileName !== "" ||
        rcCopyFileName !== ""
      ) {
        if (panFileName) {
          uploadFile(
            panFileName,
            panFile,
            panFileType,
            36,
            panFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
        if (tanFileName) {
          uploadFile(
            tanFileName,
            tanFile,
            tanFileType,
            37,
            tanFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
        if (msmeFileName) {
          uploadFile(
            msmeFileName,
            msmeFile,
            msmeFileType,
            38,
            msmeFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
        if (cancelledChequeFileName) {
          uploadFile(
            cancelledChequeFileName,
            cancelledChequeFile,
            cancelledChequeFileType,
            39,
            cancelledChequeFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
        if (gstFileName) {
          uploadFile(
            gstFileName,
            gstFile,
            gstFileType,
            40,
            gstFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
        if (rcCopyFileName) {
          uploadFile(
            rcCopyFileName,
            rcCopyFile,
            rcCopyFileType,
            41,
            rcCopyFileName,
            resp.suppId ? resp.suppId : suppId,
            this.handleAddIndent
          );
        }
      } else {
        this.handleAddIndent(response);
      }
    } else {
      this.handleAddIndent(response);
    }
  };
  handleFileClick(e) {
    var fName = e.currentTarget.id;
    var itemName = e.currentTarget.name;
    var input = document.createElement("input");
    input.id = fName;
    input.name = itemName;
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleFileChange(e) {
    e.preventDefault();
    let fName = e.target.id;
    var itemName = e.target.name;
    var fType = fName + "Type";
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileType = fName + "Type";
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType == "PDF" ||
        fileType == "JPG" ||
        fileType == "JPEG" ||
        fileType == "PNG" ||
        fileType == "PDF" ||
        fileType == "XLSX"
      ) {
        this.setState({
          //file: file,
          [fName]: file,
          [itemName]: file.name,
          [fType]: fileName[1],
          //filesList: arr,
          imagePreviewUrl: reader.result,
        });
        console.log("^^^", this.state);
        //this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }
  handleFileClose = (e) => {
    //console.log("YYY",e.currentTarget.id)
    if (e.currentTarget.id === "panFile") {
      this.setState({
        panFile: "",
        panFileName: "",
        panFileType: "",
      });
    } else if (e.currentTarget.id === "tanFile") {
      this.setState({
        tanFile: "",
        tanFileName: "",
        tanFileType: "",
      });
    } else if (e.currentTarget.id === "cancelledChequeFile") {
      this.setState({
        cancelledChequeFile: "",
        cancelledChequeFileName: "",
        cancelledChequeFileType: "",
      });
    } else if (e.currentTarget.id === "msmeFile") {
      this.setState({
        msmeFile: "",
        msmeFileName: "",
        msmeFileType: "",
      });
    } else if (e.currentTarget.id === "gstFile") {
      this.setState({
        gstFile: "",
        gstFileName: "",
        gstFileType: "",
      });
    } else if (e.currentTarget.id === "rcCopyFile") {
      this.setState({
        rcCopyFile: "",
        rcCopyFileName: "",
        rcCopyFileType: "",
      });
    }
    this.setState({
      itemName: "",
      file: "",
      fileType: "",
    });
  };
  operation() {
    this.setState({
      showMe: !this.state.showMe,
    });
  }
  render() {
    const { classes } = this.props;
    const { ledger_data } = this.state;
    const msmeoptions = [
      { value: "Y", label: "Yes", name: "Yes" },
      { value: "N", label: "No", name: "No" },
    ];
    const activeStatusOption = [
      { value: "Yes", label: "Yes", name: "Yes" },
      { value: "No", label: "No", name: "No" },
    ];
    const SupplierTypOptions=[];
    const supplier=getConfigValue(configNames.SUPPLIER_TYPES)
    if(supplier !=="1"){
      const SupplierType=JSON.parse(supplier)
      console.log(
        "configNames.CURRENCY_TYPESssss",
        SupplierType,
        
      );

      if(SupplierType.jute===1 &&SupplierType.store===0&&SupplierType.agent===0&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        console.log("getting the suppliertype1", SupplierTypOptions);
      }
        else if(SupplierType.jute===0 &&SupplierType.store===1&&SupplierType.agent===0&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
      }
       else if(SupplierType.jute===0 &&SupplierType.store===0&&SupplierType.agent===1&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
      }
       else if(SupplierType.jute===0 &&SupplierType.store===0&&SupplierType.agent===0&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }


      else if(SupplierType.jute===1 &&SupplierType.store===1&&SupplierType.agent===0&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
      }
      else if(SupplierType.jute===0 &&SupplierType.store===0&&SupplierType.agent===1&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
            SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===1 &&SupplierType.store===0&&SupplierType.agent===0&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
            SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===0 &&SupplierType.store===1&&SupplierType.agent===1&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
      }
      else if(SupplierType.jute===0 &&SupplierType.store===1&&SupplierType.agent===0&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===1 &&SupplierType.store===0&&SupplierType.agent===1&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
      }
      // -----
      else if(SupplierType.jute===0 &&SupplierType.store===1&&SupplierType.agent===1&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===1 &&SupplierType.store===0&&SupplierType.agent===1&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===1 &&SupplierType.store===1&&SupplierType.agent===0&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      else if(SupplierType.jute===1 &&SupplierType.store===1&&SupplierType.agent===1&&SupplierType.vendor===0){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
      }


      else if(SupplierType.jute===1 &&SupplierType.agent===1 &&SupplierType.store===1&&SupplierType.vendor===1){
        SupplierTypOptions.push({value:"J",label:"JUTE",name:"Jute"})
        SupplierTypOptions.push({value:"A",label:"AGENT",name:"Agent"})
        SupplierTypOptions.push({value:"S",label:"STORE",name:"Store"})
        SupplierTypOptions.push({value:"V",label:"VENDOR",name:"Vendor"})
      }
      
    }

    // const psoptions=getConfigValue(configNames.SUPPLIER_TYPES)
    // if(psoptions !=="1"){
    //   console.log(supplierTypesList);
    // }
    // else{
    //   console.log( JSON.parse(getConfigValue(configNames.SUPPLIER_TYPES)));
    // }

    // const psoptions =
    //   getConfigValue(configNames.SUPPLIER_TYPES) === "1"
    //     ? JSON.parse(getConfigValue(configNames.SUPPLIER_TYPES))
    //     : supplierTypesList
    // console.log("SUPPLIER_TYPES", psoptions);
    // var labelType = getConfigValue(configNames.LABELS_TYPE);

    const options = ledger_data;
    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Address
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="branchAddress"
                                  value={this.state.branchAddress}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  multiline={true}
                                  rowsMax={3}
                                  height={60}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  City
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="city"
                                  value={this.state.city}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.stateName}
                                  getName={(name) => {
                                    var val = name.split("^");
                                    this.setState({
                                      stateCode:
                                        val[1] !== "null" ? val[1] : "",
                                    });
                                  }}
                                  stateKey="stateName"
                                  staticData={this.state.statesList}
                                  // url={serverApi.GET_ALL_STATES}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State Code
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  disabled={true}
                                  id="stateCode"
                                  value={this.state.stateCode}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        GST No
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <TextField
                                                                        id="gstNo"
                                                                        value={this.state.gstNo}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}
                                                                    />
                                                                </ItemGrid>
                                                                {this.state.gstNo!==""&&this.state.gstNo.length>0?
                                                                <ItemGrid sm={4}>
                                                                    <Button color="info" size="sm" id="gstFile"
                                                                    onClick={this.handleFileClick}>UPLOAD
                                                                    </Button>
                                                                </ItemGrid>:null}
                                                            </GridContainer>
                                                        </ItemGrid> */}
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  GST No
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={4}>
                                <TextField
                                  id="gstNo"
                                  value={this.state.gstNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                              {this.state.gstNo !== "" &&
                              this.state.gstNo !== null &&
                              this.state.gstNo.length > 0 ? (
                                <ItemGrid sm={4}>
                                  <Button
                                    color="info"
                                    size="sm"
                                    id="gstFile"
                                    name="gstFileName"
                                    onClick={this.handleFileClick}
                                  >
                                    UPLOAD
                                  </Button>
                                </ItemGrid>
                              ) : null}
                              {this.state.updateView
                                ? this.state.gstFileList.map((r, i) => (
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4} />
                                      <ItemGrid xs={12} sm={1}>
                                        {i + 1}
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={4}>
                                        <a
                                          onClick={() => {
                                            getFileDownload(
                                              serverConfig.SERVER_URL +
                                                r.retrievalPath,
                                              r.fileName,
                                              r.fileExtension,
                                              this.handleDownloadedFile
                                            );
                                          }}
                                        >
                                          {r.fileName}
                                        </a>
                                      </ItemGrid>
                                      <ItemGrid xs={1}>
                                        <IconButton
                                          right={true}
                                          onClick={() => {
                                            this.setState({
                                              deleteGstFile: true,
                                              gstFileId: r.fileUploadId,
                                              fileId: i,
                                            });
                                            this.InvoicebasicAlert(
                                              "Do You Like To Delete This File?"
                                            );
                                          }}
                                          color="dangerNoBackground"
                                        >
                                          <Close />
                                        </IconButton>{" "}
                                        {""}
                                      </ItemGrid>
                                    </GridContainer>
                                  ))
                                : null}
                              {this.state.gstFileName ? (
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4} />
                                  <ItemGrid xs={12} sm={1}>
                                    {this.state.gstFileList.length + 1}
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={4}>
                                    <a>{this.state.gstFileName}</a>
                                  </ItemGrid>
                                  <ItemGrid xs={1}>
                                    <IconButton
                                      id="gstFile"
                                      right={true}
                                      onClick={this.handleFileClose}
                                      color="dangerNoBackground"
                                    >
                                      <Close />
                                    </IconButton>{" "}
                                    {""}
                                  </ItemGrid>
                                </GridContainer>
                              ) : null}
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Contact No
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="contactNo"
                                  value={this.state.contactNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  iType="number"
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Contact Person
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="popupContactPerson"
                                  value={this.state.popupContactPerson}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Email
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="supplierPopupEmail"
                                  value={this.state.supplierPopupEmail}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        RC Copy
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={4}>
                                                                <TextField
                                                                        id="rcCopy"
                                                                        value={this.state.rcCopy}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}
                                                                    />
                                                                </ItemGrid>
                                                                    <ItemGrid sm={4}>
                                                                        <Button color="info" size="sm" id="rcCopyFile" name="rcCopyFileName"
                                                                            onClick={this.handleFileClick}>UPLOAD
                                                                        </Button>
                                                                    </ItemGrid>
                                                                {this.state.updateView?
                                                                this.state.gstFileList.map((r, i) => (
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}></ItemGrid>
                                                                    <ItemGrid xs={12} sm={1}>
                                                                        {i+1}
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <a onClick={()=>{
                                                                                getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                                            }}>{r.fileName}</a>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={1}>
                                                                        <IconButton
                                                                            right={true}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    deleteRcCopyFile: true,
                                                                                    rcCopyFileId: r.fileUploadId,
                                                                                    fileId: i
                                                                                });
                                                                                this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                                            }}
                                                                            color="dangerNoBackground"
                                                                        >
                                                                            <Close/>
                                                                        </IconButton> {""}
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                                )):null}
                                                                {this.state.rcCopyFileName !== "" ?
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}></ItemGrid>
                                                                    <ItemGrid xs={12} sm={1}>
                                                                        {this.state.rcCopyFileList.length+1}
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <a>{this.state.rcCopyFileName}</a>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={1}>
                                                                            <IconButton id="rcCopyFile"
                                                                                right={true}
                                                                                onClick={this.handleFileClose}

                                                                                color="dangerNoBackground"
                                                                            >
                                                                                <Close/>
                                                                            </IconButton> {""}
                                                                        </ItemGrid>
                                                                </GridContainer>:null}
                                                            </GridContainer>
                                                        </ItemGrid> */}
                        </GridContainer>
                      }
                    />
                  ) : null}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="supName"
                          value={this.state.supName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.suppTyp}
                          name={"suppTyp"}
                          value={this.state.suppTyp}
                          onChange={this.handleSelectedPSValues}
                          options={SupplierTypOptions.length === 0 ? JSON.parse(getConfigValue(configNames.SUPPLIER_TYPES)) : SupplierTypOptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",

                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
              
                  {/* Supplier Code */}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier Code
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <TextField
                            disabled={true}
                            id="suppCode"
                            value={this.state.suppCode}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <SelectboxTemp
                          // selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Phone 1
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Phone 1
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="phone1"
                                                    value={this.state.phone1}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                    iType="number"
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {/* Phone-2 */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Phone 2
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="phone2"
                          value={this.state.phone2}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                          iType="number"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Cell Phone */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Cell Phone
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="cellPhone"
                          value={this.state.cellPhone}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                          iType="number"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Contact Person */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Contact Person
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="contactPerson"
                          value={this.state.contactPerson}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Ledger Group */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ledger Group
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <SelectboxTemp
                          id={"ledgerGroup"}
                          defValue={this.state.ledgerGroup}
                          onSelect={this.onSelect}
                          //  getName={this.getGateEntryDetails}
                          stateKey="ledgerGroup"
                          staticData={options}
                          // value={this.state.gateEntryNo}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Fax */}
                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Fax
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="fax"
                                                    value={this.state.fax}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                  {/* Email */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Email
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="email"
                          value={this.state.email}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Opening Balance
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="openningBalance"
                          value={this.state.openningBalance}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* Pan Number */}
                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="3"?"CR No":"PAN NO"}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="panNo"
                                                    value={this.state.panNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                            {this.state.panNo!==""&&this.state.panNo.length>0?
                                            <ItemGrid sm={2}>
                                                <Button color="info" size="sm" id="panFile"
                                                onClick={this.handleFileClick}>UPLOAD
                                                </Button>
                                            </ItemGrid>:null}
                                            {this.state.itemName!==""?
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid sm={6}>
                                                        <h6>{this.state.itemName}<span style={{color:"red",margin:"0 0 0 10px",cursor:"pointer"}} onClick={this.handleFileClose}>X</span></h6>
                                                </ItemGrid>
                                            </GridContainer>:null}
                                        </GridContainer>
                                    </ItemGrid> */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {/* {labelType==="3"?"CR No":"PAN NO"} */}PAN NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="panNo"
                          value={this.state.panNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                      {this.state.panNo !== "" &&
                      this.state.panNo !== null &&
                      this.state.panNo.length > 0 ? (
                        <ItemGrid sm={2}>
                          <Button
                            color="info"
                            size="sm"
                            id="panFile"
                            name="panFileName"
                            onClick={this.handleFileClick}
                          >
                            UPLOAD
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView
                        ? this.state.panFileList.map((r, i) => (
                            <GridContainer>
                              <ItemGrid xs={12} sm={4} />
                              <ItemGrid xs={12} sm={1}>
                                {i + 1}
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <a
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL + r.retrievalPath,
                                      r.fileName,
                                      r.fileExtension,
                                      this.handleDownloadedFile
                                    );
                                  }}
                                >
                                  {r.fileName}
                                </a>
                              </ItemGrid>
                              <ItemGrid xs={1}>
                                <IconButton
                                  right={true}
                                  onClick={() => {
                                    this.setState({
                                      deletePanFile: true,
                                      panFileId: r.fileUploadId,
                                      fileId: i,
                                    });
                                    this.InvoicebasicAlert(
                                      "Do You Like To Delete This File?"
                                    );
                                  }}
                                  color="dangerNoBackground"
                                >
                                  <Close />
                                </IconButton>{" "}
                                {""}
                              </ItemGrid>
                            </GridContainer>
                          ))
                        : null}
                      {this.state.panFileName !== "" ? (
                        <GridContainer>
                          <ItemGrid xs={12} sm={4} />
                          <ItemGrid xs={12} sm={1}>
                            {this.state.panFileList.length + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a>{this.state.panFileName}</a>
                          </ItemGrid>
                          <ItemGrid xs={1}>
                            <IconButton
                              id="panFile"
                              right={true}
                              onClick={this.handleFileClose}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    TAN NO
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="tanNo"
                                                    value={this.state.tanNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                            {this.state.tanNo!==""&&this.state.tanNo.length>0?
                                            <ItemGrid sm={2}>
                                                <Button color="info" size="sm" id="tanFile"
                                                onClick={this.handleFileClick}>UPLOAD
                                                </Button>
                                            </ItemGrid>:null}
                                            {this.state.itemName!==""?
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid sm={6}>
                                                        <h6>{this.state.itemName}<span style={{color:"red",margin:"0 0 0 10px",cursor:"pointer"}} onClick={this.handleFileClose}>X</span></h6>
                                                </ItemGrid>
                                            </GridContainer>:null}
                                        </GridContainer>
                                    </ItemGrid> */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          TAN NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="tanNo"
                          value={this.state.tanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                      {this.state.tanNo !== "" &&
                      this.state.tanNo !== null &&
                      this.state.tanNo.length > 0 ? (
                        <ItemGrid sm={2}>
                          <Button
                            color="info"
                            size="sm"
                            id="tanFile"
                            name="tanFileName"
                            onClick={this.handleFileClick}
                          >
                            UPLOAD
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView
                        ? this.state.tanFileList.map((r, i) => (
                            <GridContainer>
                              <ItemGrid xs={12} sm={4} />
                              <ItemGrid xs={12} sm={1}>
                                {i + 1}
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL + r.retrievalPath,
                                      r.fileName,
                                      r.fileExtension,
                                      this.handleDownloadedFile
                                    );
                                  }}
                                >
                                  {r.fileName}
                                </a>
                              </ItemGrid>
                              <ItemGrid xs={1}>
                                <IconButton
                                  right={true}
                                  onClick={() => {
                                    this.setState({
                                      deleteTanFile: true,
                                      tanFileId: r.fileUploadId,
                                      fileId: i,
                                    });
                                    this.InvoicebasicAlert(
                                      "Do You Like To Delete This File?"
                                    );
                                  }}
                                  color="dangerNoBackground"
                                >
                                  <Close />
                                </IconButton>{" "}
                                {""}
                              </ItemGrid>
                            </GridContainer>
                          ))
                        : null}
                      {this.state.tanFileName !== "" ? (
                        <GridContainer>
                          <ItemGrid xs={12} sm={4} />
                          <ItemGrid xs={12} sm={1}>
                            {this.state.tanFileList.length + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a style={{ cursor: "pointer" }}>
                              {this.state.tanFileName}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={1}>
                            <IconButton
                              id="tanFile"
                              right={true}
                              onClick={this.handleFileClose}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>

                  {/* Cancelled Cheque Upload */}
                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Cancelled Cheque
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid sm={2}>
                                                <Button color="info" size="sm" id="cancelledChequeDoc"
                                                    onClick={this.handleFileClick}>UPLOAD
                                                </Button>
                                            </ItemGrid>
                                            {this.state.itemName!==""?
                                            <ItemGrid sm={6}>
                                                    <h6>{this.state.itemName}<span style={{color:"red",margin:"0 0 0 10px",cursor:"pointer"}} onClick={this.handleFileClose}>X</span></h6>
                                            </ItemGrid>:null}
                                        </GridContainer>
                                    </ItemGrid> */}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Account No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="accountNumber"
                          value={this.state.accountNumber}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          IFSC CODE
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="ifscCode"
                          value={this.state.ifscCode}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Cancelled Cheque
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid sm={2}>
                        <Button
                          color="info"
                          size="sm"
                          id="cancelledChequeFile"
                          name="cancelledChequeFileName"
                          onClick={this.handleFileClick}
                        >
                          UPLOAD
                        </Button>
                      </ItemGrid>
                      {this.state.updateView
                        ? this.state.canChequeFileList.map((r, i) => (
                            <GridContainer>
                              <ItemGrid xs={12} sm={4} />
                              <ItemGrid xs={12} sm={1}>
                                {i + 1}
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL + r.retrievalPath,
                                      r.fileName,
                                      r.fileExtension,
                                      this.handleDownloadedFile
                                    );
                                  }}
                                >
                                  {r.fileName}
                                </a>
                              </ItemGrid>
                              <ItemGrid xs={1}>
                                <IconButton
                                  right={true}
                                  onClick={() => {
                                    this.setState({
                                      deleteCanChqFile: true,
                                      CanChqFileId: r.fileUploadId,
                                      fileId: i,
                                    });
                                    this.InvoicebasicAlert(
                                      "Do You Like To Delete This File?"
                                    );
                                  }}
                                  color="dangerNoBackground"
                                >
                                  <Close />
                                </IconButton>{" "}
                                {""}
                              </ItemGrid>
                            </GridContainer>
                          ))
                        : null}
                      {this.state.cancelledChequeFileName !== "" ? (
                        <GridContainer>
                          <ItemGrid xs={12} sm={4} />
                          <ItemGrid xs={12} sm={1}>
                            {this.state.canChequeFileList.length + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a>{this.state.cancelledChequeFileName}</a>
                          </ItemGrid>
                          <ItemGrid xs={1}>
                            <IconButton
                              id="cancelledChequeFile"
                              right={true}
                              onClick={this.handleFileClose}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  {/* MSME Certified Upload */}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          MSME Certified
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <SelectboxTemp
                          id={"msme"}
                          defValue={this.state.msme}
                          onSelect={this.onSelect}
                          stateKey="msme"
                          staticData={msmeoptions}
                        />
                      </ItemGrid>
                      {this.state.msme === "Y" ? (
                        <ItemGrid sm={2}>
                          <Button
                            color="info"
                            size="sm"
                            id="msmeFile"
                            name="msmeFileName"
                            onClick={this.handleFileClick}
                          >
                            UPLOAD
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView
                        ? this.state.msmeFileList.map((r, i) => (
                            <GridContainer>
                              <ItemGrid xs={12} sm={4} />
                              <ItemGrid xs={12} sm={1}>
                                {i + 1}
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <a
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL + r.retrievalPath,
                                      r.fileName,
                                      r.fileExtension,
                                      this.handleDownloadedFile
                                    );
                                  }}
                                >
                                  {r.fileName}
                                </a>
                              </ItemGrid>
                              <ItemGrid xs={1}>
                                <IconButton
                                  right={true}
                                  onClick={() => {
                                    this.setState({
                                      deleteMsmeFile: true,
                                      msmeFileId: r.fileUploadId,
                                      fileId: i,
                                    });
                                    this.InvoicebasicAlert(
                                      "Do You Like To Delete This File?"
                                    );
                                  }}
                                  color="dangerNoBackground"
                                >
                                  <Close />
                                </IconButton>{" "}
                                {""}
                              </ItemGrid>
                            </GridContainer>
                          ))
                        : null}
                      {this.state.msmeFileName !== "" ? (
                        <GridContainer>
                          <ItemGrid xs={12} sm={4} />
                          <ItemGrid xs={12} sm={1}>
                            {this.state.msmeFileList.length + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a>{this.state.msmeFileName}</a>
                          </ItemGrid>
                          <ItemGrid xs={1}>
                            <IconButton
                              id="msmeFile"
                              right={true}
                              onClick={this.handleFileClose}
                              color="dangerNoBackground"
                            >
                              <Close />
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                MSME Certified
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <SelectboxTemp 
                                                    id={"msme"}
                                                    defValue={this.state.msme}
                                                    onSelect={this.onSelect}
                                                    stateKey="msme"
                                                    staticData={msmeoptions}
                                                />
                                            </ItemGrid>
                                            {this.state.msme==='Y'?
                                            <ItemGrid sm={2}>
                                                <Button color="info" size="sm" id="msmeFile"
                                                    onClick={this.handleFileClick}>UPLOAD
                                                </Button>
                                            </ItemGrid>:null}
                                            {this.state.itemName!==""?
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid sm={6}>
                                                        <h6>{this.state.itemName}<span style={{color:"red",margin:"0 0 0 10px",cursor:"pointer"}} onClick={this.handleFileClose}>X</span></h6>
                                                </ItemGrid>
                                            </GridContainer>:null}
                                        </GridContainer>
                                    </ItemGrid> */}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Active Status
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <SelectboxTemp
                          id={"aStatus"}
                          defValue={this.state.aStatus}
                          onSelect={this.onSelect}
                          stateKey="aStatus"
                          staticData={activeStatusOption}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Add Comments
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          multiline
                          rowsMax="3"
                          height={60}
                          id="comments"
                          value={this.state.comments}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.suppTyp.value === "J" ? (
                    <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={2}>
                          <InputLabel className={classes.label}>
                            Mukam List
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} md={10}>
                          <GridContainer>
                            {this.state.mukamList.map((prop, key) => {
                              return (
                                <ItemGrid xs={12} sm={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        value={prop.isEnabled ? 1 : 0}
                                        checked={prop.isEnabled ? 1 : 0}
                                        onChange={this.handleChange(key)}
                                        // onClick={() => this.handleToggle(prop)}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                        }}
                                        style={{ marginLeft: 10 }}
                                      />
                                    }
                                    classes={{
                                      label: classes.menulabel,
                                    }}
                                    label={prop.mukamName}
                                  />
                                </ItemGrid>
                              );
                            })}
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12}>
                    <LineItemTable
                      data={this.getDataTable()}
                      loading={this.state.loading}
                      columns={[
                        {
                          Header: "Branch Address",
                          accessor: "branchAddress",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "GST Number",
                          accessor: "gstNo",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Contact Number",
                          accessor: "contactNo",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Contact Person",
                          accessor: "contactPerson",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Actions",
                          minWidth: 100,
                          accessor: "actions",
                          style: { color: "#000", textAlign: "center" },
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                    />
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={6}>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: 400,
                              color: "#003F66",
                            }}
                          >
                            Status:
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#003F66",
                            }}
                          >
                            {this.state.statusDesc}
                            <br />
                          </span>
                        </ItemGrid>
                        <ItemGrid xs={6}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12}>
                      <Button
                        color={"info"}
                        right={true}
                        onClick={this.handleAddItem}
                      >
                        Add Item
                      </Button>
                    </ItemGrid>
                  )}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approverButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick(this.state.status);
                              /* this.setState({
                                                                    isApprove: true,
                                                                });
                                                                this.InvoicebasicAlert("Do You Like To Approve This PO..!!");*/
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {!this.state.updateView ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => {
                            this.onUpdateButtonClick("");
                          }}
                        >
                          Update
                        </Button>
                      )}
                      <ItemGrid>
                        <Button color="danger" onClick={this.handleCreate}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? <LogsComponents /> : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.SUPPLIER_MASTER_TABLE_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(SupplierMaster)
);
