import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import {ClientVisitTypes} from "../TableData";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";
import { connect } from 'react-redux';


class ClientVisit  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            isRefresh: false,
            isLoading: false,
            itemViewType: "clientVisitPlanId",
            approveStatus: false,
            date1:moment().format("DD-MM-YYYY"),
            companyName:"",
            contactNumber:"",
            discussionDone:"",
            followUpRequired:"",
            location:"",
            officialName:"",
            empName:"",
            planningdate:"",
            purpose:"",
            status:"",
            summary:"",
            type:"",
            visitFromDate:"",
            visitTime:"",
            visitToDate:"",
            ebId:getCookie(cons.EB_ID),
            ebNo:getCookie(cons.EB_NO)
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverApi.CLIENT_VISIT_GET_BYID+"/"+id+"/"+getCookie(cons.USERID), this.handleViewClickResp);
        }
    };



    handleViewClickResp = (resp) => {
        var o = resp;
        if (o !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                clientVisitPlanId:o.clientVisitPlanId,
                ebId:o.ebId,
                planningdate:o.planningdate,
                companyName:o.companyName,
                contactNumber:o.contactNumber,
                discussionDone:o.discussionDone,
                followUpRequired:o.followUpRequired,
                location:o.location,
                empName:o.officialName,
                purpose:o.purpose,
                status:o.status,
                summary:o.summary,
                type:o.type,
                visitFromDate:o.visitFromDate,
                visitTime:o.visitTime,
                visitToDate:o.visitToDate,
                createdBy: o.createdBy,
                createdOn: o.createDateTime,
                updatedBy:o.updatedBy
            });
        }


    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {


        this.setState({
            [stateKey]: selectedValue
        });
    };

    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
    };
    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('HH:mm:ss'),
            });
    };
    dateChanged = (time) => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2]+" "+time;
        return new Date(newDate).getTime();


    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            updateItemAlert: false,
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }

        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callClientVisitList();
        }
        this.setState({
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            companyName:"",
            contactNumber:"",
            discussionDone:"",
            followUpRequired:"",
            location:"",
            officialName:"",
            empName:"",
            planningdate:"",
            purpose:"",
            status:"",
            summary:"",
            type:"",
            visitFromDate:"",
            visitTime:"",
            visitToDate:"",
            ebId:getCookie(cons.EB_ID),
            ebNo:getCookie(cons.EB_NO)
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {  clientVisitPlanId,empName,contactNumber,location,planningdate,purpose,companyName ,type,visitFromDate,visitToDate,visitTime,
                discussionDone,followUpRequired,status,summary}=this.state;
            var data = {
                "clientVisitPlanId":clientVisitPlanId,
                "officialName":empName,
                "location":location,
                "contactNumber":contactNumber,
                "planningdate":planningdate,
                "purpose":purpose,
                "companyName":companyName,
                "type":type,
                "visitFromDate":visitFromDate,
                "visitToDate":visitToDate,
                "visitTime":this.dateChanged(visitTime),
                "discussionDone":discussionDone,
                "followUpRequired":followUpRequired,
                "status":status,
                "summary":summary,
                "ebId":getCookie(cons.EB_ID),
                "updatedBy":getCookie(cons.USERID),
                "companyId": this.props.state.companyInfo.id
            };

            if (empName !== ""&& contactNumber!==""&& location!==""&&companyName!=="" && status!=="") {
                this.handleLoading();
                requestList(serverApi.CLIENT_VISIT_UPDATE, data, this.handleAddClientVisit)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const { ebId,empName, officialName,contactNumber,location,planningdate,purpose,companyName ,type,visitFromDate,visitToDate,visitTime}=this.state;
            var data = {
                "officialName":empName,
                "location":location,
                "contactNumber":contactNumber,
                "planningdate":planningdate,
                "purpose":purpose,
                "companyName":companyName,
                "type":type,
                "visitFromDate":visitFromDate,
                "visitToDate":visitToDate,
                "visitTime":this.dateChanged(visitTime),
                "ebId":ebId,
                "createdBy":getCookie(cons.USERID),
                "companyId": this.props.state.companyInfo.id
            };
            if (empName !== ""&& contactNumber!==""&& location!=="" && companyName!=="") {
                this.handleLoading();
                requestList(serverApi.CLIENT_VISIT_ADD, data, this.handleAddClientVisit)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddClientVisit = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    componentDidMount() {

        this.callClientVisitList();

    }

    callClientVisitList(){
        getDataFromUrl(serverApi.CLIENT_VISIT_LIST+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID),this.handleTabelList);

    }

    handleTabelList =(resp)=>{
        this.setState({tableList:resp,isRefresh:true})
    }
    getEmpDetails=()=>{
        const {ebNo} = this.state;
        if(ebNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+ebNo+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(ebNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                //  isDisabled:false,
                empName: header.workerName,
                ebId:header.ebId
            });

        }else {
            this.showMessage(resp.message,false)
        }


    };

    render() {
        const {classes} = this.props;

        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: "26", label: 'PLANNING' ,name:'PLANNING'},
            { value: "27", label: 'VISITED',name: 'VISITED' }
        ];

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Emp Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    defValue={getCookie(cons.EB_NO)}
                                                    id="ebNo"
                                                    value={this.state.ebNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}


                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="officialName"
                                                    value={this.state.empName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Comapany Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="companyName"
                                                    value={this.state.companyName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Contact No<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="contactNumber"
                                                    value={this.state.contactNumber}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Location<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="location"
                                                    value={this.state.location}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Purpose
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="purpose"
                                                    value={this.state.purpose}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    height={80}
                                                    rowsMax={4}
                                                    multiline={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Planning Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "planningdate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"planningdate",
                                                            value:this.state.planningdate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Type
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <SelectboxTemp id={"type"}
                                                               defValue={this.state.type}
                                                               onSelect={this.onSelect}
                                                               stateKey="type"
                                                               staticData={ClientVisitTypes}
                                                    //  url={serverApi.LEAVE_TYPE_SELECTION_LIST+"/"+this.props.state.companyInfo.id}
                                                               value={this.state.type}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Visit From Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "visitFromDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"visitFromDate",
                                                            value:this.state.visitFromDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Visit To Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "visitToDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"visitToDate",
                                                            value:this.state.visitToDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Visit Time
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        defaultValue={new Date()}
                                                        closeOnSelect={true}
                                                        timeFormat={"HH:mm:ss"}
                                                        // defaultValue={this.state.time1}
                                                        dateFormat={false}
                                                        onChange={(timeObj) => {
                                                            this.timeChanged1(timeObj, "visitTime")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"visitTime",
                                                            value:this.state.visitTime,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                       Discussion Done
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        // disabled={true}
                                                        id="discussionDone"
                                                        value={this.state.discussionDone}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                         Follow Up Required
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        // disabled={true}
                                                        id="followUpRequired"
                                                        value={this.state.followUpRequired}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Summary
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        // disabled={true}
                                                        id="summary"
                                                        value={this.state.summary}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}

                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} >
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Status<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8} >
                                                    <SelectboxTemp id={"status"}
                                                                   defValue={this.state.status}
                                                                   onSelect={this.onSelect}
                                                                   stateKey="status"
                                                                   staticData={options}
                                                        //  url={serverApi.LEAVE_TYPE_SELECTION_LIST+"/"+this.props.state.companyInfo.id}
                                                                   value={this.state.status}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}

                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Updated By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="updatedBy"
                                                        value={this.state.updatedBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}

                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                               // url={serverApi.CLIENT_VISIT_LIST+"/"+this.props.state.companyInfo.id}
                                                staticData={this.state.tableList}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={false}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(ClientVisit)
);
