import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Button from "../components/CustomButtons/Button";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import Edit from "@material-ui/icons/Edit";
import Download from "@material-ui/icons/FileDownload";
import Attach from "@material-ui/icons/AttachFile";
// import Checkbox from"@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "material-ui/Checkbox";
import Check from "@material-ui/icons/CheckBox";
import Close from "@material-ui/icons/Close";
import Dvr from "@material-ui/icons/Dvr";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import IconButton from "../components/CustomButtons/IconButton";
import ExpandMoreIcon from '@material-ui/icons/FileDownload';


export const VIEW = 1;
export const OFFER_ACTION = 2;
export const OFFER_ACCEPTED = 3;
export const MODIFY_EMP = 4;
export const FILE_DOWNLOAD = 5;
export  const DELETE =6;
export  const CHECKBOX =7;
export const ATTACHMENT=8

function UiActions({...props}) {
    const {classes, btnKey, actionType, isDelete,onDownloadClick,isExcel,isResetPassword,isDownload} = props;
    switch (actionType) {
        case VIEW:
            return (
                <div className="actions-right">
                    {/*<IconButton onClick={clicked}>*/}
                   
                    {isDownload?                    <IconButton
                        color="primaryNoBackground" onClick={downloadClick}>
                        <Download/>
                    </IconButton>:null
                    }
                    {isExcel?                    <IconButton
                       color="successNoBackground" onClick={exportClick}>
                        <ExpandMoreIcon/>
                    </IconButton>:null
                    }
                    <IconButton
                        color="warningNoBackground"
                        onClick={clicked}
                    >
                        <Dvr/>
                    </IconButton>
                    {isDelete?                    <IconButton
                        color="dangerNoBackground" onClick={deleteClick}>
                        <Close/>
                    </IconButton>:null
                    }
                    {isResetPassword?
                        <Button color="danger" style={{width:"100px"}} className={classes.title} onClick={resetPasswordClick}>
                            Reset Password
                        </Button>
                    :null
                    }
                </div>
            );
            case ATTACHMENT:
            return (
                <div className="actions-right">
                    {/*<IconButton onClick={clicked}>*/}
                   
                    {isDownload?                    <IconButton
                        color="primaryNoBackground" onClick={downloadClick}>
                        <Download/>
                    </IconButton>:null
                    }
                    <IconButton
                        color="warningNoBackground"
                        onClick={clicked}
                    >
                        <Attach/>
                    </IconButton>
                    
                </div>
            );
        case OFFER_ACTION:
            return (
                <div className={classes.flex}>
                    <Button color="successNoBackground" className={classes.title} onClick={clicked}>
                        ISSUE OFFER
                    </Button>
                </div>
            );
        case OFFER_ACCEPTED:
            return (
                <div className={classes.flex}>
                    <IconButton onClick={clicked}>
                        <OpenInBrowser/>
                    </IconButton>
                </div>
            );
        case MODIFY_EMP:
            return (
                <div className={classes.flex}>
                    <IconButton onClick={clicked}>
                        <Edit/>
                    </IconButton>
                </div>
            );
        case FILE_DOWNLOAD:
            return (
                <div className={classes.flex}>
                    <IconButton onClick={clicked} color={"primaryNoBackground"}>
                        <Download/>
                    </IconButton>
                </div>
            );
        case DELETE:
            return (
                <div className="actions-right">
                    {/*<IconButton onClick={clicked}>*/}
                    <IconButton
                        color="dangerNoBackground" onClick={clicked}>
                        <Close/>
                    </IconButton>
                </div>
            );

        case CHECKBOX:
            return (
                <div className={classes.flex}>
                    <IconButton onClick={clicked}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    // onClick={this.handleToggle}
                                    checkedIcon={
                                        <Check className={classes.checkedIcon} />
                                    }
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label
                            }}

                        />
                    </IconButton>
                </div>
            );
        default:
            return null;
    }

    function clicked() {
        props.actionClick(btnKey);
    }
    function deleteClick() {
        props.deleteClick(btnKey);

    }
    function resetPasswordClick() {
        props.resetPasswordClick(btnKey);

    }
    function exportClick () {
        props.excelExport(btnKey);

    }
    function downloadClick () {
        props.onDownloadClick(btnKey);

    }
}

UiActions.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(UiActions);
