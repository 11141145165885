import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import cons, { serverApi} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import {deleteDataFromUrl, getDataFromUrl, requestList} from "../../../server/server";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import {DELETE, VIEW} from "../../../ui-actions/UiActions";
import {getMenuPermission} from "../../CustumComponents/PermissionAlert";
import TableComponent from "../../CustumComponents/TableComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from 'material-ui/Radio/Radio';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import {menuTypes} from "../../TableData";

class TenantMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "tenant",
            updateView:false,
            "url": "",
            "user": "",
            "password": "",
            "tenant": "",
            "dataSourceClassName": ""
    };
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount(){
        var TenantsList= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":true,"Header":"Tenant","accessor":"tenant","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"URL","accessor":"url","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Data Source Class","accessor":"dataSourceClassName","minWidth":50,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"User","accessor":"user","minWidth":50,"style":{"textAlign":"left"},"sortable":true}]};

        this.setState({
            TenantsList:TenantsList,
        });
        this.getTenantsList()

    }


    getTenantsList=()=>{
        this.handleLoading();
        getDataFromUrl(serverApi.TENANT_MASTER_LIST+"InstanceOne",this.handleList);
    };
    handleList = (resp) =>{
        var TenantsList= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":true,"Header":"Tenant","accessor":"tenant","minWidth":60,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"URL","accessor":"url","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Data Source Class","accessor":"dataSourceClassName","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"User","accessor":"user","minWidth":50,"style":{"textAlign":"left"},"sortable":true}]};
      var list=resp;
        if(resp.status===undefined){
            TenantsList.data=list
        }


        this.setState({
            TenantsList:TenantsList,
            isRefresh:true
        });
        this.handleLoading();

    };



    handleSeacrh = () => {
        if(!this.state.isRefresh){
            this.getTenantsList()
        }

        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }


    handleCreate = () => {
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        this.setState({
            isCreated: !this.state.isCreated,

        });
    };


    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }
    hideAlertMessage() {
         if(this.state.isCreated){
             this.getTenantsList();
         }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            "url": "",
            "user": "",
            "password": "",
            "tenant": "",
            "dataSourceClassName": ""
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 8) {
            return length
        } else {
            return 8;
        }
    };

    onCreateButtonClick = () => {
            const {url, user, password, tenant,dataSourceClassName} = this.state;
            var data = {
                "url":url,
                "user": user,
                "password": password,
                "tenant": tenant,
                "dataSourceClassName": dataSourceClassName
            };

            if(url!==""&&user!==""&&password!==""&&tenant!==""&&dataSourceClassName!==""){
                this.handleLoading();
                requestList(serverApi.ADD_TENANT_MASTER+"InstanceOne", data, this.handleAddMenu)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

    };
    handleAddMenu=(resp)=>{
        if (resp.status===undefined) {
            this.showMessage("Tenant Created Successfully..!!")
        }else {
            this.showMessage(resp.message)
        }
        this.handleLoading();
    };



    showMessage = (msg) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        this.hideAlertMessage();
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleDeleteClick = (obj) => {
        deleteDataFromUrl(serverApi.DELETE_TENANT+obj+"/InstanceOne",this.handleDeleteClickResp)
    };
    handleDeleteClickResp=(resp)=>{
        console.log("ondelte",resp);
        if(resp.status)
        this.getTenantsList()

    };

    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            cardTitle={<Button color={"info"} onClick={this.handleCreate}>
                                Back To List</Button>}
                            isLoading={this.state.isLoading}
                            content={
                                <GridContainer>
                                    {this.state.showMessagealert}
                                    {this.state.basicAlert}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Tenant<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="tenant"
                                                    value={this.state.tenant}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    URL<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="url"
                                                    value={this.state.url}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    User<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="user"
                                                    value={this.state.user}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Password<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="password"
                                                    value={this.state.password}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Data Source Class<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="dataSourceClassName"
                                                    value={this.state.dataSourceClassName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}/>

                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">

                                            {!this.state.updateView ?
                                                <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                                    <GridContainer justify="center">
                                                        <ItemGrid>
                                                            <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>
                                                        </ItemGrid>
                                                        <ItemGrid>
                                                            <Button color="danger" onClick={this.hideAlertMessage}>Cancel</Button>
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid>:null

                                            }

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }
                        />

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <GridContainer>

                                            <ItemGrid xs={10}>

                                            </ItemGrid>

                                        <ItemGrid xs={2}>
                                            <IconButton
                                                onClick={this.handleCreate}
                                                color="success" right={true}>
                                                <Add/>
                                            </IconButton>

                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>



                                            <TableComponent
                                               // url={serverApi.GET_LEAVE_TRANSACTIONS_LIST}
                                                  staticData={this.state.TenantsList}
                                                borderRight={true}
                                                //searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                // isLoading={this.state.isRefresh}
                                                  onActionClick={this.handleDeleteClick}
                                                actionType={DELETE}
                                                fileName={"jute_with_value"}
                                                itemViewType={this.state.itemViewType}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


}

const extendedFormsStyle = {

    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
export default withStyles(extendedFormsStyle)(TenantMaster);
