import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {dataTable} from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';
import FormControl from "material-ui/Form/FormControl";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import {PeriodTypes, sampleData} from "../TableData";
import PaymentComponent from "../CustumComponents/PaymentComponent";
import moment from "moment";
//import LineItemTable from "../CustumComponents/LineItemTable";
import ReactTable from "react-table";
import { connect } from 'react-redux';


class CustomerMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            toDate: "",
            isRefresh: false,
            isLoading: false,
            itemViewType: "hdrId",
            approveStatus: false,
            amount:"",
            customerHdrId:"",
            packageHdrId:"",
            pamount:"",
            packageNames:"",
            customerNames:"",
            description:"",
            status:"",
            isDisabled:false
        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);

    }

    handleSeacrh = () => {

        if(!this.state.isRefresh){
            this.callCustomerMappingList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {

            getDataFromUrl(serverApi.CUSTOMER_MAPPING_GET_BYID+"/"+id+"/"+getCookie(cons.USERID), this.handleViewClickResp);
        }
       // this.handleViewClickResp();
    };
    handleViewClickResp = (resp) => {

        const inputFocus={
            autoComplete: 'off'
        };
        var header=resp.hdrDto;
        var list = resp.dtlList;
        var indentlist = list.map((prop,key)=>{
            return(
                {
                    dtlId:prop.dtlId,
                    hdrId:prop.hdrId,
                    packageDtlId:prop.packageDtlId,
                    make:prop.make,
                    serialNo:prop.serialNo,
                    makeName:(
                        <div style={{marginTop:10}}>
                            <TextField
                                defValue={prop.make}
                                id="make"
                                // className={classes.textField}
                                // value={prop.approvedQTY}
                                onChange={(event) => {
                                    var data=this.state.MappingArray;

                                        data[key].make=event.target.value;
                                        //prop.make=event.target.value;
                                        this.setState(
                                            {make: event.target.value,
                                                MappingArray:data
                                            });
                                    //  console.log(`Option selected:`, defValue);
                                }}
                                fullWidth={true}
                                variant="outlined"
                                autoFocus={true}
                               InputProps={inputFocus}

                            />

                        </div>
                    ),
                    serialName:(
                        <div style={{marginTop:10}}>
                            <TextField
                                defValue={prop.serialNo}
                                id="serialNo"
                                // className={classes.textField}
                                // value={prop.approvedQTY}
                                onChange={(event) => {
                                    var data=this.state.MappingArray;
                                        data[key].serialNo=event.target.value;
                                        prop.serialNo=event.target.value;
                                        this.setState(
                                            {serialNo: event.target.value,
                                                MappingArray:data
                                            });

                                    //  console.log(`Option selected:`, defValue);
                                }}
                                fullWidth={true}
                                variant="outlined"
                                autoFocus={true}
                                 InputProps={inputFocus}

                            />

                        </div>
                    )
                }
            )
        });
        if(header !== null){
        //     console.log("challanNo",header.challanNo);
            this.setState({
                updateItem:false,
                updateView:true,
                isCreated:true,
                isDisabled:true,
                approveStatus:header.approveStatus,
                hdrId:header.hdrId,
                amount:header.amount,
                customerHdrId:header.customerHdrId,
                packageHdrId:header.packageHdrId,
                pamount:header.paid,
                fromDate:header.fromDate,
                toDate:header.toDate,
                activeStatus:header.activeStatus,
                description:header.description,
                account:header.bankId,
                bankRefnumber:header.bankRefnumber,
                date1:header.transactionDate,
                paymentSource:header.paymentSource,
                status:header.status,
                reloadLedger:true,
                MappingArray:indentlist,
            });

        }
    };
    timeChanged1 = (dateObj,id) => {

        if (typeof dateObj === 'object'){

            this.setState({
                [id] :dateObj.format('YYYY-MM-DD')
            });

        }

    };
    timeChanged = (dateObj,id) => {



        if (typeof dateObj === 'object'){

                this.setState({
                    [id] :dateObj.format('YYYY-MM-DD')
                });

            if(this.state.periodType===1){
                var add1days=dateObj.add(3, 'M').format('YYYY-MM-DD');
                this.setState({toDate: moment(add1days).subtract(1, 'days').format('YYYY-MM-DD')})
            }else if(this.state.periodType===2){
                var add1days=dateObj.add(6, 'M').format('YYYY-MM-DD');
                this.setState({toDate: moment(add1days).subtract(1, 'days').format('YYYY-MM-DD')})
            }else if(this.state.periodType===3){
                var add1days=dateObj.add(12, 'M').format('YYYY-MM-DD');
                this.setState({toDate: moment(add1days).subtract(1, 'days').format('YYYY-MM-DD')})
            }else if(this.state.periodType===4){
                var add1days=dateObj.add(1, 'M').format('YYYY-MM-DD');
                this.setState({toDate: moment(add1days).subtract(1, 'days').format('YYYY-MM-DD')})
            }

        }

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        this.setState({
            isCreated: !this.state.isCreated,
            isDisabled:false,
            periodType:""
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

        this.setState({
            [stateKey]: selectedValue
        });
    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            isRefresh: val
        });
    };
    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            itemGroupCode:"",
            amount:"",
            customerHdrId:"",
            packageHdrId:"",
            pamount:"",
            packageNames:"",
            customerNames:"",
            description:"",
            status:"",
            fromDate: "",
            toDate: "",
            ledger:"",
            account:"",
            date1:"",
            bankRefnumber:"",
            paymentSource:""
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        this.setState({
            basicAlert: null
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callCustomerMappingList()
        }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            itemGroupCode:"",
            amount:"",
            customerHdrId:"",
            packageHdrId:"",
            pamount:"",
            packageNames:"",
            customerNames:"",
            description:"",
            status:"",
            fromDate: "",
            toDate: "",
            ledger:"",
            account:"",
            date1:"",
            bankRefnumber:"",
            paymentSource:""

        });



    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = (stas) => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var MappingArray = this.state.MappingArray.map((prop, key) => {
                return ({
                    make: prop.make,
                    serialNo: prop.serialNo,
                    hdrId:prop.hdrId,
                    dtlId:prop.dtlId,
                    packageDtlId:prop.packageDtlId
                })
            });
            const {amount,customerHdrId,fromDate,packageHdrId,pamount,status,toDate,hdrId,activeStatus}=this.state;
            var data = {
                "hdrDto": {
                    "hdrId": hdrId,
                    "amount": amount,
                    "customerHdrId": customerHdrId,
                    "fromDate": fromDate,
                    "packageHdrId": packageHdrId,
                    "paid": pamount,
                    "status": "Y",
                    "toDate": toDate,
                    "activeStatus": stas === "" ? activeStatus : stas,
                    "updatedBy": getCookie(cons.USERID),
                    "companyId": this.props.state.companyInfo.id
                },
                "dtlList":MappingArray

            };
            if (amount !=='' && customerHdrId !== '' && packageHdrId!='' ) {
                this.handleLoading();
                requestList(serverApi.UPDATE_CUSTOMER_MAPPING, data, this.handleAddCustomerMapping)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {amount,customerHdrId,fromDate,packageHdrId,pamount,status,toDate,account,bankRefnumber,description,ledger,paymentSource,date1}=this.state;
            var data = {
                "hdrDto":{
                    "amount": amount,
                    "amountfund":amount,
                    "bankId":account,
                    "bankRefnumber":bankRefnumber,
                    "description":description,
                    "ledgerId":ledger,
                    "paymentSource":paymentSource,
                    "transactionDate":date1,
                    "customerHdrId":customerHdrId,
                    "fromDate": fromDate,
                    "packageHdrId": packageHdrId,
                    "paid": pamount,
                     "status": "Y",
                    "toDate": toDate,
                    "createdBy":getCookie(cons.USERID),
                    "companyId":this.props.state.companyInfo.id
                },
                "dtlList":[{
                }]

            };
            if (amount !=='' && customerHdrId !== '' && packageHdrId!='' ) {
                this.handleLoading();
                requestList(serverApi.ADD_CUSTOMER_MAPPING, data, this.handleAddCustomerMapping)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddCustomerMapping = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    componentDidMount() {
        this.callCustomerMappingList();
        this.callCustomerList();
        this.callPackageList();
    }

    callPackageList(){
        var data={
            "companyId": this.props.state.companyInfo.id,
            "activeStatus":1
        }
        requestList(serverApi.PACKAGE_MASTER_LIST,data,this.handlePackages)
    }

    handlePackages=(resp)=>{
        var PackageData =resp.data,PackageList=[];
        if(PackageData.length>0){
            var obj={label:"Select",value:null,name:"null"};
            PackageList.push(obj);
            PackageData.forEach(prop=>{
                var name=prop.packageName+'^'+prop.packagePrice+'^'+prop.periodType;
                var obj={label:prop.packageName,value:prop.hdrId,name:name};
                PackageList.push(obj)
            });
            this.setState({
                packageTypeList:PackageList,isRefresh:true
            })
        }
}
    callCustomerList(){
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.CUSTOMER_MASTER,data,this.handelCustomer)
    }

    handelCustomer=(resp)=>{
        var data=resp.data,customerList=[];
        if(data.length>0){
            var obj={label:"Select",value:null,name:"null"};
            customerList.push(obj);
            data.forEach(prop=>{
                var name=prop.name+'^'+prop.ledgerId;
                var obj={label:prop.name,value:prop.id,name:name};
                customerList.push(obj)
            });
            this.setState({
                customerList:customerList,isRefresh:true
            })
        }
    };
    callCustomerMappingList(){
        var data={
            "companyId": this.props.state.companyInfo.id,
            packageHdrId:this.state.packageNames,
            customerHdrId:this.state.customerNames,
        }
        requestList(serverApi.CUSTOMER_MAPPING_LIST,data,this.handleList)
    }
    handleList=(resp)=>{
        this.setState({tableList:resp,isRefresh:true});
    };


    setIsRefresh=(key,val)=>{
        this.setState({[key]:val})
    };

    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}} >
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                            Customer Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                            defValue={this.state.customerHdrId}
                                            id={"customerHdrId"}
                                            selectDisabled={this.state.isDisabled}
                                            onSelect={this.onSelect}
                                            isRefresh={this.state.isRefresh}
                                            stateKey="customerHdrId"
                                            getName={(name)=>{
                                                var values=name.split('^');
                                                //if(!this.state.updateView)
                                                    this.setState({
                                                        ledger:values[1]==='null'?"":parseInt(values[1]),
                                                        reloadLedger:true
                                                    })
                                            }}
                                            setIsRefresh={this.afterRefreshItemGrp}
                                            staticData={this.state.customerList}
                                            // url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                            value={this.state.customerHdrId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={4}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                            Package Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                            defValue={this.state.packageHdrId}
                                            id={"packageHdrId"}
                                            selectDisabled={this.state.isDisabled}
                                            onSelect={this.onSelect}
                                            isRefresh={this.state.isRefresh}
                                            stateKey="packageHdrId"
                                            getName={(name)=>{
                                                var values=name.split('^');
                                                // if(!this.state.updateView)
                                                    this.setState({
                                                        amount:values[1]==='null'?"":values[1],
                                                        periodType:values[2]==='null'?"": parseInt(values[2]),
                                                        description:"Paid towards subscription "+values[0],
                                                        loadPeriod:true
                                                    });
                                            }}
                                            setIsRefresh={this.afterRefreshItemGrp}
                                            staticData={this.state.packageTypeList}
                                           // url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                            value={this.state.packageHdrId}
                                        />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={4} >
                                        <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                            Period Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                        </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                            defValue={this.state.periodType}
                                            onSelect={this.onSelect}
                                            selectDisabled={true}
                                            stateKey="periodType"
                                            isRefresh={this.state.loadPeriod}
                                            setIsRefresh={(val)=> {
                                                this.setState({loadPeriod:false})
                                            }}

                                            staticData={PeriodTypes}
                                            value={this.state.periodType}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Amount<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <TextField
                                            disabled={true}
                                            id="amount"
                                            value={this.state.amount}
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                            iType={'number'}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Paid Amount<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <TextField
                                            id="pamount"
                                            disabled={this.state.isDisabled}
                                            value={this.state.pamount}
                                            className={classes.textField}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            variant="outlined"
                                            iType={'number'}
                                        />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                            subscription valid from<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <FormControl variant="outlined" fullWidth>
                                            <Datetime
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                onChange={(dateObj) => {
                                                    this.timeChanged(dateObj, "fromDate")
                                                }}
                                                formControlProps={
                                                    {height:"10px",inlineBlock: "none"}
                                                }

                                                inputProps={{
                                                    id:"fromDate",
                                                    value:this.state.fromDate,
                                                    autoComplete: 'off',
                                                    disabled:this.state.isDisabled
                                                }}
                                            />
                                        </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                            subscription valid till<span style={{color: "red", fontSize: "12px"}}>*</span>
                                        </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                        <FormControl variant="outlined" fullWidth>
                                            <Datetime
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                onChange={(dateObj) => {
                                                    this.timeChanged(dateObj, "toDate")
                                                }}
                                                formControlProps={
                                                    {height:"10px",inlineBlock: "none"}
                                                }

                                                inputProps={{
                                                    id:"toDate",
                                                    value:this.state.toDate,
                                                    autoComplete: 'off',
                                                    disabled:true
                                                }}
                                            />
                                        </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <PaymentComponent
                                        reloadLedger={this.state.reloadLedger}
                                        setIsRefresh={this.setIsRefresh}
                                        divSize={4}
                                        isDisabled={this.state.isDisabled}
                                        ledger={this.state.ledger}
                                        account={this.state.account}
                                        date1={this.state.date1}
                                        description={this.state.description}
                                        amount={this.state.amount}
                                        bankRefnumber={this.state.bankRefnumber}
                                        paymentSource={this.state.paymentSource}
                                        onSelect={this.onSelect}
                                        onChange={this.onChangeValues}
                                        onTimeChange={this.timeChanged1}
                                    />
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>


                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid xs={12}>
                                                    <ReactTable
                                                        data={this.state.MappingArray}
                                                        filterable={false}
                                                        resizable={false}
                                                        loading={this.state.loading}
                                                        getTableProps={() => {
                                                            return {
                                                                style: {
                                                                    border: "2px solid #00ABDC",
                                                                    borderRadius: "4px",

                                                                }
                                                            }}}

                                                        getTheadProps={() => {
                                                            return {
                                                                style: {
                                                                    borderBottom: "2px solid #00ABDC",
                                                                    //height:45
                                                                    //lineHeight:"1.3"
                                                                }
                                                            }}}
                                                        getTheadThProps={() => {
                                                            return {
                                                                style: {
                                                                    borderRight: "1px solid #999999",
                                                                    textAlign:"center",
                                                                    whiteSpace:"pre-wrap",
                                                                    lineHeight:1.5,
                                                                }
                                                            }}}
                                                        style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}
                                                        columns={[
                                                            {
                                                                Header: "Make",
                                                                accessor: "makeName",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "left"}

                                                            },
                                                            {
                                                                Header: "Serial No",
                                                                accessor: "serialName",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "left"}
                                                            }
                                                        ]}
                                                        showPagination={false}
                                                        pageSize={8}
                                                        //  defaultPageSize={5}
                                                        sortable={false}
                                                        getNoDataProps={()=>{
                                                            return{
                                                                style:{
                                                                    display:"none"
                                                                }

                                                            };
                                                        }}
                                                        className="-striped -highlight"
                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null
                                    }


                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            {this.state.approveStatus ?
                                                <GridContainer justify="center">
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={() => {
                                                                    this.onUpdateButtonClick("")
                                                                }}>Update</Button>
                                                    </ItemGrid>
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={() => {
                                                                    this.onUpdateButtonClick(3)
                                                                }}>Approve</Button>
                                                    </ItemGrid>
                                                    <ItemGrid>
                                                        <Button color="danger"
                                                                onClick={() => {
                                                                    this.onUpdateButtonClick(0)
                                                                }}>Delete</Button>
                                                    </ItemGrid>
                                                    <ItemGrid>
                                                        <Button color="danger"
                                                                onClick={this.handleCreate}>Cancel</Button>
                                                    </ItemGrid>

                                                </GridContainer> : null
                                            }

                                        </ItemGrid> : null
                                    }
                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Customer
                                                </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.customerNames}
                                                    id={"customerNames"}
                                                    onSelect={this.onSelect}
                                                    isRefresh={this.state.isRefresh}
                                                    stateKey="customerNames"
                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                    staticData={this.state.customerList}
                                                //    url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.customerNames}
                                                />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                            <ItemGrid xs={12} sm={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Package
                                                </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.packageNames}
                                                    id={"packageNames"}
                                                    onSelect={this.onSelect}
                                                    isRefresh={this.state.isRefresh}
                                                    stateKey="packageNames"
                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                    staticData={this.state.packageTypeList}
                                                  //  url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.packageNames}
                                                />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>

                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <IconButton
                                                    onClick={this.handleCreate}
                                                    color="success" right={true}>
                                                    <ExpandMoreIcon/>
                                                </IconButton>
                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                //url={serverApi.LEAVE_TYPE_DATATABLE+"/"+this.props.state.companyInfo.id}
                                                searchData={this.getData()}
                                                staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={false}
                                                // onDeleteClick={this.onDeleteParamater}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(CustomerMapping)
);
