import initialState from './initial-state';
import {FETCH_COMPANY, SET_COMPANIES, SET_COMPANY} from '../actions/action-types';

export default function componyReducer(state = initialState.companyInfo, action) {
  let componyState = state;
  switch (action.type) {
    case FETCH_COMPANY:
      return action;
    case SET_COMPANY:
      componyState.companyInfo = action.componyInfo;
      return componyState;
      case SET_COMPANIES:
        componyState.companies = action.companies;
        return componyState;
    default:
      return state;
  }
}