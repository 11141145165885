import React from "react";
// @material-ui/icons





// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import cons, {serverConfig,serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import FormControlLabel from "material-ui/Form/FormControlLabel";

import {Check} from "@material-ui/icons";
import Checkbox from "material-ui/Checkbox";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import { connect } from "react-redux";

class RoleManagement extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            isRefresh:false,
            isLoading:false,
            itemViewType:"roleId",
            approveStatus:false,

            updateView:false,
            date1:moment().format('DD-MM-YYYY'),
            year:moment().format('YYYY'),

            createdBy:"",
            createdOn:"",

            menusList:[],
            menusToAdd:[],
            menusToSend:[],
            selectedRoleType:'',
            roleName:'',
            hrmsFullAccess:false
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubMenuChange = this.handleSubMenuChange.bind(this);

        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }
    handleMenus=(resp)=> {
        var menus=[];
        if(resp.length>0){
            resp.map((prop,key)=>{
               var data={
                   id:0,
                   menuId:prop.menuId,
                   isEnable:0,
                   readPermission:0,
                   writePermission:0,
                   editPermission:0,
                };
                menus.push(data);
               if(prop.subMenus.length>0){
                   prop.subMenus.map((prop,key)=>{
                       var data={
                           id:0,
                           menuId:prop.menuId,
                           isEnable:0,
                           readPermission:0,
                           writePermission:0,
                           editPermission:0
                       };
                       menus.push(data);
                       if(prop.subSubMenus.length>0){
                           prop.subSubMenus.map((prop,key)=>{
                               var data={
                                   id:0,
                                   menuId:prop.menuId,
                                   isEnable:0,
                                   readPermission:0,
                                   writePermission:0,
                                   editPermission:0
                               };
                               menus.push(data);
                           })

                       }
                   })
               }
            })
        }
        console.log("updateView",this.state.updateView);
        if(this.state.updateView){
            menus.map((prop,key)=>{
                this.state.menusToAdd.find((o,i)=>{
                    if(prop.menuId===o.menuId&& o.isEnable){
                        menus[key].id=o.id;
                        menus[key].isEnable=o.isEnable;
                        menus[key].readPermission=o.readPermission;
                        menus[key].writePermission=o.writePermission;
                        menus[key].editPermission=o.writePermission;
                    }
                })
            });
            this.setState({
                menusList:resp,
                menusToAdd:menus,
                isCreated: true,
            });

        }else {
            this.setState({
                menusList:resp,
                menusToAdd:menus,
                isCreated: true,
            });

        }
        this.handleLoading();
    };

    handleSeacrh = () => {
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleViewClick =(id)=>{
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            console.log("jute  " + "  " + id);
            getDataFromUrl(serverApi.GET_USER_GROUP_BY_ID + id + "/" + getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        var header = resp.roleDtl;
      //  var menusToSend = resp.menuJson.Data;
        var menus=resp.menus;
        this.handleLoading();
        this.setState({
            updateView: true,
            roleID:header.id,
            roleName:header.userGroupName,
            selectedRoleType:this.getDefaultRoleType(header.reqSource),
            menusToAdd:menus,
            createdBy:header.createdBy,
            createdOn:header.createdDateTime,
            hrmsFullAccess:header.hrmsFullAccess===1?true:false
        });

        getDataFromUrl(serverApi.GET_MENUS_BY_ROLE+header.reqSource+"/"+this.props.state.companyInfo.id,this.handleMenus);
        //console.log("indentWeight "+header.indentWeight);

    };
    getDefaultRoleType=(name)=>{
        var data="";
        if(name !== null && name!==''){
            if(name === 0){
                data={ value: 0, label: 'Portal',name: 'Portal' };
            }else {
                data={ value: 1, label: 'App',name: 'App' };
            }

        }
        return data;
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
       if(!this.state.isCreated){
           this.hideAlert();
       }

        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data={
            "indentType":"J",
            "fromDate":this.state.fromDate
            ,"toDate":this.state.todate
            ,"mukam":this.state.mukamId,
            "status":this.state.status
        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
        if (id !== 'date1')
            this.setState({
                [id] :''
            });

        this.dateChanged();
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };
    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
/*
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
*/
    handleChange = (object,permission) => event => {
        var id=object.menuId;
        var menus=this.state.menusToAdd;
        menus.map((prop,key)=>{
            if(id===prop.menuId){
                var obj=prop;

                if(event.target.checked){
                    if(object.subMenus.length>0){
                            object.subMenus.map((sub,i)=>{

                                menus.find(
                                    (obj,i) =>{
                                        if ( obj.menuId === sub.menuId) {
                                            if(permission===0){

                                                obj.isEnable=1;
                                                obj.readPermission=1;
                                                obj.writePermission=1;
                                                obj.editPermission=1;
                                            }else if(permission===1){
                                                object.subMenus.readPermission=1;
                                                obj.readPermission=1;
                                            }else if(permission===2){
                                                obj.writePermission=1;
                                                object.subMenus.writePermission=1;

                                            }else {
                                                obj.editPermission=1;
                                                object.subMenus.editPermission=1;

                                            }

                                            menus[i] = obj;
                                        }
                                    }
                                );
                                if(sub.subSubMenus.length>0){

                                    sub.subSubMenus.map((sub,i)=>{

                                        menus.find(
                                            (obj,i) =>{
                                                if ( obj.menuId === sub.menuId) {
                                                    if(permission===0){

                                                        obj.isEnable=1;
                                                        obj.readPermission=1;
                                                        obj.writePermission=1;
                                                        obj.editPermission=1;
                                                    }else if(permission===1){
                                                        obj.readPermission=1;
                                                    }else if(permission===2){
                                                        obj.writePermission=1;
                                                    }else {
                                                        obj.editPermission=1;
                                                    }

                                                    menus[i] = obj;
                                                }
                                            }
                                        );



                                        //  }
                                    });

                                }



                                //  }
                            });
                    }


                    if(permission===0){
                        obj.isEnable=1;
                        obj.readPermission=1;
                        obj.writePermission=1;
                        obj.editPermission=1;
                    }else if(permission===1){
                        obj.readPermission=1;
                        object.readPermission=1;

                    }else if(permission===2){
                        obj.writePermission=1;
                        object.writePermission=1;

                    }else {
                        obj.editPermission=1;
                        object.editPermission=1;

                    }

                    //var subMenuIndex=menus.findIndex(obj => obj.menuId === prop.subMenus.menuId);
                }else {
                    object.subMenus.map((sub,i)=>{
                        var subId=sub.menuId;

                        // if(prop.menuId===sub.menuId){
                            menus.find((obj,i) =>{
                                if(obj.menuId === sub.menuId){
                                    if(permission===0){

                                        obj.isEnable=0;
                                        obj.readPermission=0;
                                        obj.writePermission=0;
                                        obj.editPermission=0;
                                    }else if(permission===1){

                                        obj.readPermission=0;
                                    }else if(permission===2){

                                        obj.writePermission=0;
                                    }else {

                                        obj.editPermission=0;
                                    }
                                    menus[i] = obj;
                                }
                                }


                            );
                        if(sub.subSubMenus.length>0){

                            sub.subSubMenus.map((sub,i)=>{

                                menus.find(
                                    (obj,i) =>{
                                        if ( obj.menuId === sub.menuId) {
                                            if(permission===0){
                                                obj.isEnable=0;
                                                obj.readPermission=0;
                                                obj.writePermission=0;
                                                obj.editPermission=0;
                                            }else if(permission===1){
                                                obj.readPermission=0;
                                            }else if(permission===2){
                                                obj.writePermission=0;
                                            }else {
                                                obj.editPermission=0;
                                            }

                                            menus[i] = obj;
                                        }
                                    }
                                );



                                //  }
                            });

                        }

                        // }
                    });

                    if(permission===0){

                        obj.isEnable=0;
                        obj.readPermission=0;
                        obj.writePermission=0;
                        obj.editPermission=0;
                    }else if(permission===1){

                        obj.readPermission=0;
                    }else if(permission===2){
                        obj.writePermission=0;

                    }else {
                        obj.editPermission=0;
                    }
                }
                menus[key] = obj;
            }
        });
        this.setState({
           // [id]: event.target.checked,
            //menusToSend:menusToSend,
            menusToAdd:menus,
        });
       // console.log("menus to sens",JSON.stringify(menusToSend));
    };
    handleSubMenuChange = (menuId,subObject,permission) => event => {
        var id=subObject.menuId;
        var menus=this.state.menusToAdd;
        menus.map((prop,key)=>{
            if(id===prop.menuId){
                var obj=prop;

                if(event.target.checked){
                    if(subObject.subSubMenus.length>0){
                        subObject.subSubMenus.map((sub,i)=>{

                            // if(prop.menuId===sub.menuId){
                            menus.find((obj,i) =>{
                                    if(obj.menuId === sub.menuId){
                                        if(permission===0){
                                            obj.isEnable=1;
                                            obj.readPermission=1;
                                            obj.writePermission=1;
                                            obj.editPermission=1;
                                        }else if(permission===1){
                                            obj.readPermission=1;
                                        }else if(permission===2){
                                            obj.writePermission=1;
                                        }else {
                                            obj.editPermission=1;
                                        }
                                        menus[i] = obj;
                                    }
                                }


                            );
                            // }
                        });

                    }


                    if(permission===0){
                        obj.isEnable=1;
                        obj.readPermission=1;
                        obj.writePermission=1;
                        obj.editPermission=1;
                    }else if(permission===1){
                        obj.readPermission=1;
                    }else if(permission===2){
                        obj.writePermission=1;
                    }else {
                        obj.editPermission=1;
                    }

                }else {
                    subObject.subSubMenus.map((sub,i)=>{
                        var subId=sub.menuId;

                        // if(prop.menuId===sub.menuId){
                        menus.find((obj,i) =>{
                                if(obj.menuId === sub.menuId){
                                    if(permission===0){
                                        obj.isEnable=0;
                                        obj.readPermission=0;
                                        obj.writePermission=0;
                                        obj.editPermission=0;
                                    }else if(permission===1){
                                        obj.readPermission=0;
                                    }else if(permission===2){
                                        obj.writePermission=0;
                                    }else {
                                        obj.editPermission=0;
                                    }
                                    menus[i] = obj;
                                }
                            }


                        );
                        // }
                    });

                    if(permission===0){
                        obj.isEnable=0;
                        obj.readPermission=0;
                        obj.writePermission=0;
                        obj.editPermission=0;


                    }else if(permission===1){
                        obj.readPermission=0;
                    }else if(permission===2){
                        obj.writePermission=0;
                    }else {
                        obj.editPermission=0;
                    }
                }
                menus[key] = obj;




            }
        });
        this.setState({
            menusToAdd:menus,
        });

    };

    handleSubSubMenuChange = (menuId,subObject,permission) => event => {
        var id=subObject.menuId;
        var menus=this.state.menusToAdd;
        menus.map((prop,key)=>{
            if(id===prop.menuId){
                var obj=prop;

                if(event.target.checked){

                    if(permission===0){
                        obj.isEnable=1;
                        obj.readPermission=1;
                        obj.writePermission=1;
                        obj.editPermission=1;
                    }else if(permission===1){
                        obj.readPermission=1;
                    }else if(permission===2){
                        obj.writePermission=1;
                    }else {
                        obj.editPermission=1;
                    }
                    //var subMenuIndex=menus.findIndex(obj => obj.menuId === prop.subMenus.menuId);
                }else {

                    if(permission===0){
                        obj.isEnable=0;
                        obj.readPermission=0;
                        obj.writePermission=0;
                        obj.editPermission=0;
                    }else if(permission===1){
                        obj.readPermission=0;
                    }else if(permission===2){
                        obj.writePermission=0;
                    }else {
                        obj.editPermission=0;
                    }
                }
                menus[key] = obj;
            }
        });
        this.setState({
          //  [id]: event.target.checked,
            menusToAdd:menus,
          //  menusList:this.state.menusList

        });
        console.log("menus to add",JSON.stringify(menus));
    };


    handleToggle(obj) {
        var id=obj.menuId,menus=this.state.menusToAdd;

        var sub=obj.subMenus;
        if(sub.length>0){

            sub.map((prop,key)=>{
                var index=menus.findIndex(obj => obj.menuId === prop.menuId);
                var obj=menus[index];

                if(index===prop.menuId){
                    obj.readPermission=1;
                    obj.writePermission=1;
                    obj.editPermission=1;

                    menus[index] = obj;
                }
            });

        }
        this.setState({
           // [id]: event.target.checked,
            menusToAdd:menus
        });
    }


    onChangeValues =(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
          this.setState({
              [stateKey]: selectedValue,
          });


    };
    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };
    hideRoleAlert() {
        // console.log("alert");
        this.setState({
           // isCreated:false,
            alert: false,
            showMessagealert:false,
            loading:false,
            roleName:'',
            roleID:'',
            updateView:false
        });
    }


    hideAlert() {
       // console.log("alert");
        this.setState({
            isCreated:false,
            alert: false,
            showMessagealert:false,
            loading:false,
            menusList:[],
            menusToAdd:[],
            roleName:'',
            roleID:'',
            selectedRoleType:'',
            updateView:false,
            hrmsFullAccess:false

        });
    }
    hideBasicAlert(){
        this.setState({
            basicAlert:null
        })
    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onUpdateButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var menusToadd=[];

            this.state.menusToAdd.map((prop,key)=>{
                if(prop.isEnable){
                    menusToadd.push(prop)
                }
            });
            var menus, data = {
                "roleDtl": {
                    "userGroupName": this.state.roleName,
                    "reqSource": this.state.selectedRoleType.value,
                    "userId": getCookie(cons.USERID),
                    "id": this.state.roleID,
                    "companyId": this.props.state.companyInfo.id,
                    "hrmsFullAccess":this.state.hrmsFullAccess?1:0

                },
                "cipher": getCookie(cons.CIPHER),
                "menuJson": {},
                "menus": menusToadd

            };
            if (this.state.selectedRoleType !== '')

                if (this.state.selectedRoleType.value === 0) {
                    if (this.state.menusList.length > 0) {
                        menus = [];
                        this.state.menusList.map((prop, key) => {
                            this.state.menusToAdd.find((o, i) => {
                                if (o.menuId === prop.menuId) {
                                    if (o.isEnable) {
                                        var subMenu = [];
                                        prop.subMenus.map((sub, k) => {
                                            this.state.menusToAdd.find((o, i) => {
                                                if (o.menuId === sub.menuId) {
                                                    if (o.isEnable) {
                                                        var subSubMenu = [];
                                                        sub.subSubMenus.map((sub, k) => {
                                                            this.state.menusToAdd.find((o, i) => {
                                                                if (o.menuId === sub.menuId) {
                                                                    if (o.isEnable) {
                                                                        // var subIndex = prop.subMenus.findIndex(obj => obj.menuId === o.menuId);
                                                                        sub.readPermission = o.readPermission;
                                                                        sub.writePermission = o.writePermission;
                                                                        sub.editPermission = o.editPermission;
                                                                        subSubMenu.push(sub)
                                                                    }
                                                                }
                                                            });

                                                        });
                                                        // var subIndex = prop.subMenus.findIndex(obj => obj.menuId === o.menuId);
                                                        sub.readPermission = o.readPermission;
                                                        sub.writePermission = o.writePermission;
                                                        sub.editPermission = o.editPermission;
                                                        sub.subSubMenus = subSubMenu;
                                                        subMenu.push(sub)
                                                    }
                                                }
                                            });

                                        });

                                        var obj = {
                                            menuId: prop.menuId,
                                            menuName: prop.menuName,
                                            menuPath: prop.menuPath,
                                            state: prop.state,
                                            menuIcon: prop.menuIcon,
                                            readPermission: o.readPermission,
                                            writePermission: o.writePermission,
                                            editPermission: o.editPermission,
                                            subMenus: subMenu,
                                            menuType:prop.menuType,
                                        };
                                        menus.push(obj)
                                    }

                                }
                            });

                        });
                        data.menuJson = {
                            "Data": menus
                        };

                    }

                } else if (this.state.selectedRoleType.value === 1) {
                    menus = {};
                    this.state.menusToAdd.map((prop, key) => {
                        // var obj;
                        if (prop.isEnable) {
                            menus[prop.menuId] = {
                                "status": prop.isEnable,
                                "readPermission": prop.readPermission,
                                "editPermission": prop.editPermission,
                                "writePermission": prop.writePermission
                            };

                            // menus.add(obj);
                            //  return obj
                        }
                    });
                    data.menuJson = menus;
                }
            console.log("menus in update", JSON.stringify(data));
            if (menus !== null && this.state.roleName !== '' && this.state.selectedRoleType !== '') {
                this.handleLoading();
                requestList(serverApi.UPDATE_USER_GROUP_ROLE, data, this.handleAddIndent)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields !!!")
            }
        }

    };

    onCreateButtonClick=()=>{
        console.log("menu permission",!getMenuPermission(2));
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var menusToadd=[];
            this.state.menusToAdd.map((prop,key)=>{
                if(prop.isEnable){
                    menusToadd.push(prop)
                }
            });
            var menus, data = {
                "roleDtl": {
                    "userGroupName": this.state.roleName,
                    "reqSource": this.state.selectedRoleType.value,
                    "userId": getCookie(cons.USERID),
                    "companyId": this.props.state.companyInfo.id,
                    "hrmsFullAccess":this.state.hrmsFullAccess?1:0
                },
                "cipher": getCookie(cons.CIPHER),
                "menuJson": {},
                "menus": menusToadd

            };
            if (this.state.selectedRoleType !== '')

                if (this.state.selectedRoleType.value === 0) {
                    if (this.state.menusList.length > 0) {
                        menus = [];
                        this.state.menusList.map((prop, key) => {
                            this.state.menusToAdd.find((o, i) => {
                                if (o.menuId === prop.menuId) {
                                    if (o.isEnable) {
                                        var subMenu = [];
                                        prop.subMenus.map((sub, k) => {
                                            this.state.menusToAdd.find((o, i) => {
                                                if (o.menuId === sub.menuId) {
                                                    if (o.isEnable) {
                                                        var subSubMenu = [];
                                                        sub.subSubMenus.map((sub, k) => {
                                                            this.state.menusToAdd.find((o, i) => {
                                                                if (o.menuId === sub.menuId) {
                                                                    if (o.isEnable) {
                                                                        // var subIndex = prop.subMenus.findIndex(obj => obj.menuId === o.menuId);
                                                                        sub.readPermission = o.readPermission;
                                                                        sub.writePermission = o.writePermission;
                                                                        sub.editPermission = o.editPermission;
                                                                        subSubMenu.push(sub)
                                                                    }
                                                                }
                                                            });

                                                        });
                                                        // var subIndex = prop.subMenus.findIndex(obj => obj.menuId === o.menuId);
                                                        sub.readPermission = o.readPermission;
                                                        sub.writePermission = o.writePermission;
                                                        sub.editPermission = o.editPermission;
                                                        sub.subSubMenus = subSubMenu;
                                                        subMenu.push(sub)
                                                    }
                                                }
                                            });

                                        });

                                        var obj = {
                                            menuId: prop.menuId,
                                            menuName: prop.menuName,
                                            menuPath: prop.menuPath,
                                            state: prop.state,
                                            menuIcon: prop.menuIcon,
                                            readPermission: o.readPermission,
                                            writePermission: o.writePermission,
                                            editPermission: o.editPermission,
                                            subMenus: subMenu
                                        };
                                        menus.push(obj)
                                    }

                                }
                            });

                        });
                        data.menuJson = {
                            "Data": menus
                        };

                    }

                } else if (this.state.selectedRoleType.value === 1) {
                    menus = {};
                    this.state.menusToAdd.map((prop, key) => {
                        // var obj;
                        if (prop.isEnable) {
                            menus[prop.menuId] = {
                                "status": prop.isEnable,
                                "readPermission": prop.readPermission,
                                "editPermission": prop.editPermission,
                                "writePermission": prop.writePermission
                            };

                            // menus.add(obj);
                            //  return obj
                        }
                    });
                    data.menuJson = menus;
                }
            console.log("menus in create", JSON.stringify(data));

            if (menus !== null && this.state.roleName !== '' && this.state.selectedRoleType.value !== "") {
               this.handleLoading();
                requestList(serverApi.ADD_USER_GROUP_ROLE, data, this.handleAddIndent)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields !!!")
            }
        }
       // this.handleLoading();
       // requestList(serverApi.ADD_USER_GROUP_ROLE,data,this.handleAddIndent)
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlert();
                        }else {
                            this.hideRoleAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
/*
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
        console.log("checked ",JSON.stringify(newChecked));
    }
*/



    handleSelectedValues = (defValue) => {
        if(defValue.value !==''){
            this.setState(
                {
                    menusList:[],
                    menusToAdd:[]
                });

            this.handleLoading();
            getDataFromUrl(serverApi.GET_MENUS_BY_ROLE+defValue.value+"/"+this.props.state.companyInfo.id,this.handleMenus);
        }
        else
            this.setState(
                {
                    menusList:[],
                    menusToAdd:[]
                });


        this.setState(
                {
                    selectedRoleType:defValue,

                });
    };
    getDefaultChecked = (id)=>{
        var checkedList=this.state.checked;
        console.log("checkedList ",JSON.stringify(checkedList));
        console.log("checked value",id);

        var val=false;

        checkedList.map((prop)=>{
            if(prop === id){
                val=true;
                console.log("checked value",prop);
            }
        });

        return val;


    };
    render(){
        const { classes } = this.props;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: 0, label: 'Portal',name: 'Portal role' },
            { value: 1, label: 'App' ,name:'App Role'}
        ];
        const inputFocus={
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                        <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Role Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                      //  disabled={true}
                                                        id="roleName"
                                                        className={classes.textField}
                                                        value={this.state.roleName}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Role Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <Select
                                                    isDisabled={this.state.updateView}
                                                  //  defValue={this.state.selectedRoleType}
                                                    name ={"selectedRoleType"}
                                                    value={this.state.selectedRoleType}
                                                    onChange={this.handleSelectedValues}
                                                    options={options}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        // height:"20px",
                                                        colors: {
                                                            ...theme.colors,
                                                            //  primary25: '#00ABDC',
                                                            primary: '#00ABDC',
                                                            //   primary50:"#00ABDC"
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                            marginBottom:"10px"
                                                        }),

                                                    }}
                                                />



                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    value={this.state.hrmsFullAccess}
                                                    checked={this.state.hrmsFullAccess}
                                                    onChange={()=>{
                                                        console.log("hrmsFullAccess",this.state.hrmsFullAccess);
                                                        this.setState({hrmsFullAccess:!this.state.hrmsFullAccess})
                                                    }}
                                                    // onClick={() => this.handleToggle(prop)}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked
                                                    }}
                                                    style={{marginLeft:10}}

                                                />
                                            }
                                            classes={{
                                                label: classes.menulabel
                                            }}
                                            label={"HRMS Access"}
                                        />

                                    </ItemGrid>


{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
                                    {this.state.updateView?
                                        <ItemGrid xs={12} sm={6}  style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
*/}
                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={8}>
                                                <InputLabel className={classes.label}>
                                                    Permissions
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={4}>
                                                <GridContainer>
                                                    <ItemGrid xs={4}>
                                                        <InputLabel className={classes.label}>
                                                            View
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={4}>
                                                        <InputLabel className={classes.label}>
                                                            Add
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={4}>
                                                        <InputLabel className={classes.label}>
                                                            Update
                                                        </InputLabel>
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid>


                                    <ItemGrid xs={12} style={{maxHeight:400,overflow:"auto"}}>
                                        {this.state.menusList.map((prop, key) => {
                                            var menuID=prop.menuId;
                                            var menuToAdd=this.state.menusToAdd;
                                          //  console.log("menusToAdd",key);
                                           // var checkedIndex = this.state.menusToSend.indexOf(prop);
                                            var index=menuToAdd.findIndex(obj => obj.menuId === prop.menuId);

                                            if(menuToAdd[index]!==undefined)
                                            return(
                                                <GridContainer>
                                                <ItemGrid xs={12} sm={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={8}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={-1}
                                                                        value={menuToAdd[index].isEnable}
                                                                        checked={menuToAdd[index].isEnable}
                                                                        onChange={
                                                                            this.handleChange(prop,0)
                                                                        }
                                                                       // onClick={() => this.handleToggle(prop)}
                                                                        checkedIcon={
                                                                            <Check className={classes.checkedIcon} />
                                                                        }
                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                        classes={{
                                                                            checked: classes.checked
                                                                        }}
                                                                        style={{marginLeft:10}}

                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.menulabel
                                                                }}
                                                                label={prop.menuName}
                                                            />

                                                        </ItemGrid>
                                                        <ItemGrid xs={4} >
                                                            <GridContainer>
                                                                <ItemGrid xs={4}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={-1}
                                                                                value={menuToAdd[index].readPermission}
                                                                                checked={menuToAdd[index].readPermission}
                                                                                onChange={
                                                                                    this.handleChange(prop,1)
                                                                                }
                                                                              //  onClick={() => this.handleToggle(key)}
                                                                                checkedIcon={
                                                                                    <Check className={classes.checkedIcon} />
                                                                                }
                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                classes={{
                                                                                    checked: classes.checked
                                                                                }}
                                                                                style={{marginLeft:10}}

                                                                            />
                                                                        }
                                                                    />
                                                                </ItemGrid>
                                                                <ItemGrid xs={4}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={-1}
                                                                                value={menuToAdd[index].writePermission}
                                                                                checked={menuToAdd[index].writePermission}
                                                                                onChange={
                                                                                    this.handleChange(prop,2)
                                                                                }

                                                                               // onClick={() => this.handleToggle(key)}
                                                                                checkedIcon={
                                                                                    <Check className={classes.checkedIcon} />
                                                                                }
                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                classes={{
                                                                                    checked: classes.checked
                                                                                }}
                                                                                style={{marginLeft:10}}

                                                                            />
                                                                        }
                                                                    />
                                                                </ItemGrid>
                                                                <ItemGrid xs={4}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                tabIndex={-1}
                                                                                value={menuToAdd[index].editPermission}
                                                                                checked={menuToAdd[index].editPermission}
                                                                                onChange={
                                                                                    this.handleChange(prop,3)
                                                                                }

                                                                                //onClick={() => this.handleToggle(key)}
                                                                                checkedIcon={
                                                                                    <Check className={classes.checkedIcon} />
                                                                                }
                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                classes={{
                                                                                    checked: classes.checked
                                                                                }}
                                                                                style={{marginLeft:10}}

                                                                            />
                                                                        }
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>


                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                    <ItemGrid xs={12} sm={12}>
                                                        {prop.subMenus.map((prop,key) =>{
                                                            var subIndex=menuToAdd.findIndex(obj => obj.menuId === prop.menuId);
                                                            return(
                                                                <GridContainer>
                                                                <ItemGrid xs={12} sm={12}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={8}>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                       // checked={this.getDefaultChecked(menuID)}
                                                                                        tabIndex={-1}
                                                                                        value={menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                        checked={menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                        onChange={
                                                                                            this.handleSubMenuChange(menuID,prop,0)
                                                                                        }
                                                                                      //  onClick={() => this.handleToggle(prop.menuId)}
                                                                                        checkedIcon={
                                                                                            <Check className={classes.checkedIcon} />
                                                                                        }
                                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                                        classes={{
                                                                                            checked: classes.checked
                                                                                        }}
                                                                                        style={{marginLeft:30}}
                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    label: classes.menulabel
                                                                                }}
                                                                                label={prop.menuName}
                                                                            />

                                                                        </ItemGrid>
                                                                        <ItemGrid xs={4} >
                                                                            <GridContainer>
                                                                                <ItemGrid xs={4}>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                tabIndex={-1}
                                                                                                value={menuToAdd[index].isEnable &&menuToAdd[subIndex].readPermission}
                                                                                                checked={menuToAdd[index].isEnable &&menuToAdd[subIndex].readPermission}
                                                                                                onChange={
                                                                                                    this.handleSubMenuChange(menuID,prop,1)
                                                                                                }
                                                                                               // onClick={() => this.handleToggle(key)}
                                                                                                checkedIcon={
                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                }
                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                classes={{
                                                                                                    checked: classes.checked
                                                                                                }}
                                                                                                style={{marginLeft:10}}

                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </ItemGrid>
                                                                                <ItemGrid xs={4}>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                tabIndex={-1}
                                                                                                value={menuToAdd[index].isEnable &&menuToAdd[subIndex].writePermission}
                                                                                                checked={menuToAdd[index].isEnable &&menuToAdd[subIndex].writePermission}
                                                                                                onChange={
                                                                                                    this.handleSubMenuChange(menuID,prop,2)
                                                                                                }
                                                                                               // onClick={() => this.handleToggle(key)}
                                                                                                checkedIcon={
                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                }
                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                classes={{
                                                                                                    checked: classes.checked
                                                                                                }}
                                                                                                style={{marginLeft:10}}

                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </ItemGrid>
                                                                                <ItemGrid xs={4}>
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                tabIndex={-1}
                                                                                                value={menuToAdd[index].isEnable &&menuToAdd[subIndex].editPermission}
                                                                                                checked={menuToAdd[index].isEnable &&menuToAdd[subIndex].editPermission}
                                                                                                onChange={
                                                                                                    this.handleSubMenuChange(menuID,prop,3)
                                                                                                }
                                                                                              //  onClick={() => this.handleToggle(key)}
                                                                                                checkedIcon={
                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                }
                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                classes={{
                                                                                                    checked: classes.checked
                                                                                                }}
                                                                                                style={{marginLeft:10}}

                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </ItemGrid>
                                                                            </GridContainer>


                                                                        </ItemGrid>
                                                                    </GridContainer>

                                                                </ItemGrid>
                                                                    {prop.subSubMenus.map((prop,key) =>{
                                                                        var subSubindex=menuToAdd.findIndex(obj => obj.menuId === prop.menuId);
                                                                        return(
                                                                                <ItemGrid xs={12} sm={12}>
                                                                                    <GridContainer>
                                                                                        <ItemGrid xs={8}>
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        tabIndex={-1}
                                                                                                        value={menuToAdd[subSubindex].isEnable&&menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                        checked={menuToAdd[subSubindex].isEnable && menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                        onChange={
                                                                                                            this.handleSubSubMenuChange(menuID,prop,0)
                                                                                                        }
                                                                                                      //  onClick={() => this.handleToggle(prop.menuId)}
                                                                                                        checkedIcon={
                                                                                                            <Check className={classes.checkedIcon} />
                                                                                                        }
                                                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                                                        classes={{
                                                                                                            checked: classes.checked
                                                                                                        }}
                                                                                                        style={{marginLeft:60}}
                                                                                                    />
                                                                                                }
                                                                                                classes={{
                                                                                                    label: classes.menulabel
                                                                                                }}
                                                                                                label={prop.menuName}
                                                                                            />

                                                                                        </ItemGrid>
                                                                                        <ItemGrid xs={4} >
                                                                                            <GridContainer>
                                                                                                <ItemGrid xs={4}>
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                tabIndex={-1}
                                                                                                                value={menuToAdd[subSubindex].readPermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].readPermission}
                                                                                                                checked={menuToAdd[subSubindex].readPermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].readPermission}
                                                                                                                onChange={
                                                                                                                    this.handleSubSubMenuChange(menuID,prop,1)
                                                                                                                }                                                                                                                checkedIcon={
                                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                                }
                                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                                classes={{
                                                                                                                    checked: classes.checked
                                                                                                                }}
                                                                                                                style={{marginLeft:10}}

                                                                                                            />
                                                                                                        }
                                                                                                    />
                                                                                                </ItemGrid>
                                                                                                <ItemGrid xs={4}>
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                tabIndex={-1}
                                                                                                                value={menuToAdd[subSubindex].writePermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].writePermission}
                                                                                                                checked={menuToAdd[subSubindex].writePermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].writePermission}
                                                                                                                onChange={
                                                                                                                    this.handleSubSubMenuChange(menuID,prop,2)
                                                                                                                }                                                                                                                checkedIcon={
                                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                                }
                                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                                classes={{
                                                                                                                    checked: classes.checked
                                                                                                                }}
                                                                                                                style={{marginLeft:10}}

                                                                                                            />
                                                                                                        }
                                                                                                    />
                                                                                                </ItemGrid>
                                                                                                <ItemGrid xs={4}>
                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                tabIndex={-1}
                                                                                                                value={menuToAdd[subSubindex].editPermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].editPermission}
                                                                                                                checked={menuToAdd[subSubindex].editPermission && menuToAdd[index].isEnable &&menuToAdd[subIndex].editPermission}
                                                                                                                onChange={
                                                                                                                    this.handleSubSubMenuChange(menuID,prop,3)
                                                                                                                }                                                                                                                checkedIcon={
                                                                                                                    <Check className={classes.checkedIcon} />
                                                                                                                }
                                                                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                                                                classes={{
                                                                                                                    checked: classes.checked
                                                                                                                }}
                                                                                                                style={{marginLeft:10}}

                                                                                                            />
                                                                                                        }
                                                                                                    />
                                                                                                </ItemGrid>
                                                                                            </GridContainer>


                                                                                        </ItemGrid>
                                                                                    </GridContainer>

                                                                                </ItemGrid>

                                                                        )

                                                                    })}

                                                                </GridContainer>

                                                            )

                                                        })}

                                                    </ItemGrid>
                                                </GridContainer>


                                        )
                                            else return null
                                        })
                                        }
                                    </ItemGrid>

                                    {!this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
                                    {this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onUpdateButtonClick}>update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            cardTitle={
                                <div>
                                    <IconButton
                                        onClick={this.handleCreate}
                                        color="success" right={true}>
                                        <ExpandMoreIcon/>
                                    </IconButton>

                                </div>
                            }
                            content={
                                <div>
                                    <GridContainer >
{/*
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
*/}

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {this.state.basicAlert}

                                            <TableComponent
                                                url={serverApi.GET_ALL_USER_GROUP+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(RoleManagement)
);
