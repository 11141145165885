import React from "react";
import Select from "react-select";
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { getDataFromUrl, requestList } from "../../server/server";
import PropTypes from "prop-types";

class SelectboxTemp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defValue: 0,
      selectData: null,
      url: null,
      selectDisabled: false,
    };
  }
  handleSelectedValues = (defValue) => {
    const { onSelect, getName, stateKey, getLabel, getItem } = this.props;
    console.log("defValue " + JSON.stringify(this.props.defValue));
    if (this.props.isChangeValue !== undefined && !this.props.isChangeValue) {
      // this.setState({ defValue });
      if (onSelect !== undefined && onSelect !== null) {
        onSelect(defValue.value, stateKey, defValue.item);
      }
      if (getName !== undefined && getName !== null) {
        getName(defValue.name);
      }
      if (getItem !== undefined && getItem !== null) {
        getItem(defValue.item);
      }
    } else {
      this.setState({ defValue });
      if (onSelect !== undefined && onSelect !== null) {
        onSelect(defValue.value, stateKey, defValue.item);
      }
      if (getName !== undefined && getName !== null) {
        getName(defValue.name);
      }
      if (getLabel !== undefined && getLabel !== null) {
        getLabel(defValue.name);
      }
      if (getItem !== undefined && getItem !== null) {
        getItem(defValue.item);
      }
    }

    console.log(`Option selected:`, defValue);
  };
  getData = () => {
    if (this.props.postData !== undefined)
      requestList(this.props.url, this.props.postData, this.handleDept);
    else getDataFromUrl(this.props.url, this.handleDept);
  };
  handleDept = (resp) => {
    const {
      onSelect,
      getName,
      stateKey,
      setIsRefresh,
      setIsReload,
      getLabel,
      getItem,
    } = this.props;
    //if (resp.status) {
    //        console.log("resp",resp);
    if (resp.length > 0 && resp.status === undefined) {
      if (this.props.isRefresh) {
        this.setState({
          defValue: 0,
        });
      }
      this.setState({ selectData: resp });
      if (this.props.setIsRefresh !== undefined) setIsRefresh(false);
      if (this.props.setIsReload !== undefined) setIsReload(false);

      //            console.log("defvalue", this.props.defValue);
      resp.forEach((o, i) => {
        if (
          o.value === this.props.defValue &&
          this.props.defValue !== null &&
          this.props.defValue !== undefined
        ) {
          // here you should add some custom code so you can delete the data
          // from this component and from your server as well
          console.log("getting the data here", o);
          this.setState({
            defValue: o,
          });
          if (onSelect !== undefined && onSelect !== null) {
            onSelect(o.value, stateKey, o.item);
          }
          if (getName !== undefined && getName !== null) {
            getName(o.name);
          }
          if (getLabel !== undefined && getLabel !== null) {
            getLabel(o.name);
          }
          if (getItem !== undefined && getItem !== null) {
            getItem(o.item);
          }

          console.log("test  " + this.props.defValue);
        } else if (this.props.defValue === "") {
          this.setState({
            defValue: "",
          });
        }
      });
    } else {
      this.setState({ selectData: null, defValue: "" });
    }
  };

  componentDidMount() {
    if (this.props.staticData === undefined || this.props.staticData === null) {
      this.getData();
    } else {
      this.handleDept(this.props.staticData);
      this.setState({
        selectDisabled: this.props.selectDisabled,
      });
    }
  }
  getReloadDef = (options) => {
    this.handleDept(options);
  };

  render() {
    const { classes, setIsRefresh, setIsReload } = this.props;
    const { selectData } = this.state;
    var options = [];
    //console.log("selectData",selectData);
    if (selectData !== null) {
      options = selectData;
      if (this.props.isReload) {
        this.getReloadDef(options);
      }
    }

    //console.log(options);
    /*const options = [
            { value: '', label: 'Select...' ,name:'Select...'},
            { value: '1', label: 'Chocolate' ,name:'Chocolate'},
            { value: '2', label: 'Strawberry',name: 'Strawberry' },
            { value: '3', label: 'Vanilla',name: 'Vanilla' }
        ];*/
    // console.log("urlid "+ this.props.isRefresh);
    if (this.props.isRefresh) {
      if (
        this.props.staticData === undefined ||
        this.props.staticData === null
      ) {
        this.getData();
      } else {
        this.handleDept(this.props.staticData);
      }
      if (this.props.setIsRefresh !== undefined) setIsRefresh(false);
    }

    return (
      <div>
        <Select
          isDisabled={this.props.selectDisabled}
          // isMulti={this.props.isMulti}
          name={"defValue"}
          value={
            this.props.unSelect !== undefined && this.props.unSelect
              ? ""
              : this.state.defValue
          }
          onChange={this.handleSelectedValues}
          options={options}
          className={classes.selectFormControl}
          theme={(theme) => ({
            ...theme,
            borderRadius: "4px",
            height: "20px",
            colors: {
              ...theme.colors,
              //  primary25: '#00ABDC',
              primary: "#00ABDC",
              //   primary50:"#00ABDC"
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              height: "30px",
              "min-height": "30px",
              marginBottom: "10px",
            }),
          }}
        />
      </div>
    );
  }
}

SelectboxTemp.propTypes = {
  defValue: PropTypes.int,
  selectData: PropTypes.object.isRequired,
  selectName: PropTypes.string,
  staticData: PropTypes.object.isRequired,
};

export default withStyles(customSelectStyle)(SelectboxTemp);
