import React from "react";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { connect } from 'react-redux';

class TermPackageMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      departmentList: [],
      itemGroupName: "",
      grpId: "",
      packageId: "",
      termId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    var table = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsAccess: false,
      approveButton: false,
      totalRecords: 0,
      column: [
        {
          filterable: "true",
          Header: "ID",
          accessor: "id",
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Package",
          accessor: "packageName",
          style: { textAlign: "left" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Term",
          accessor: "termName",
          style: { textAlign: "left" },
          sortable: "true",
        },
      ],
    };
    console.log("getting table", table);
    this.setState({ tableList: table, isRefresh: true });
    this.getTermsList();
    this.getList();
    this.calLPackageMasterList();
  }
  calLPackageMasterList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      customerId: "",
    };
    requestList(serverApi.CM_PACKAGE_MASTER_LIST, data, this.handlePackList);
  };

  handlePackList = (resp) => {
    var list = [];

    resp.data.forEach((prop) => {
      var obj = { label: prop.name, value: prop.id, name: prop.name };
      list.push(obj);
    });
    this.setState({ packageList: list, loadPackage: true });
  };
  getTermsList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.CM_TERMS_LIST, data, this.handleTermsList);
  };
  getList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.CM_TERMS_PACKAGE_MAPPING_LIST, data, this.handleList);
  };

  handleTermsList = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var o = {
          label: prop.description,
          value: prop.id,
          name: prop.description,
        };
        list.push(o);
      });
    }
    this.setState({ termsList: list, loadTerms: true });
  };
  handleList = (resp) => {
    console.log(
      "getting new tableData",
      resp + " getting value",
      this.state.tableList
    );
    var list = this.state.tableList;
    // list.data = resp.data;
    this.setState({ tableList: list, isRefresh: true });
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getTermsList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      this.handleLoading();

      getDataFromUrl(
        serverApi.CM_TERMS_PACKAGE_MAPPING_DELETE + id,
        this.handleAddItem
      );
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp.data.itemGrpDtl;
    if (resp.status)
      if (header !== null) {
        this.setState({
          updateView: true,
          isCreated: true,
          //taxId: header.taxId,
          createdBy: header.createdBy,
          createdOn: header.createdOn,
          grpId: header.id,
          groupId: header.groupId,
          itemGroupName: header.grpDsc,
          departmentList: resp.data.itemGrpDpt,
        });
        this.handleLoading();
        getDataFromUrl(
          serverApi.SEARCH_DEPATMENTS +
            this.props.state.companyInfo.id +
            "/" +
            getCookie(cons.CIPHER),
          this.handleItems
        );
      }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
      this.handleLoading();
      getDataFromUrl(
        serverApi.SEARCH_DEPATMENTS +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.CIPHER),
        this.handleItems
      );
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleItems = (resp) => {
    var list = [];
    resp.map((prop, key) => {
      var val = {
        deptId: prop.value,
        deptName: prop.label,
        //   mapId:0,
        isEnabled: false,
      };
      if (this.state.updateView) {
        this.state.departmentList.map((o, key) => {
          if (o.deptId + "" === val.deptId + "" && o.isMapped) {
            val.isEnabled = true;
            //  val.mapId=o.mapId;
          }
        });
      }
      if (key > 0) list.push(val);
    });
    this.setState({
      departmentList: list,
    });
    this.handleLoading();
  };
  handleChange = (index) => (event) => {
    var arr = this.state.departmentList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      departmentList: arr,
    });
  };

  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
      cipher: getCookie(cons.CIPHER),
    };
    return data;
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
    });
  }

  hideAlertMessage() {
    this.getList();

    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      packageId: "",
      termId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { taxName, taxId, taxPercent } = this.state;
      var list = [];
      this.state.departmentList.map((prop, key) => {
        if (prop.isEnabled) {
          var data = {
            deptId: prop.deptId,
            isMapped: prop.isEnabled,
          };
          list.push(data);
        }
      });
      var data = {
        itemGrpDtl: {
          id: this.state.grpId,
          groupId: this.state.groupId,
          grpDsc: this.state.itemGroupName,
          companyId: this.props.state.companyInfo.id,
        },
        itemGrpDpt: list,
        postAuthenticationDto: {
          userId: getCookie(cons.USERID),
          cipher: getCookie(cons.CIPHER),
        },
      };

      if (this.state.itemGroupName !== "" && list.length > 0) {
        this.handleLoading();
        requestList(
          serverApi.ITEM_GROUP_MASTER_UPDATE,
          data,
          this.handleAddItem
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const { packageId, termId } = this.state;
      var list = [];
      var data = {
        companyId: this.props.state.companyInfo.id,
        createdBy: getCookie(cons.USERID),
        termId: termId,
        packageId: packageId,
      };

      if (packageId !== "" && termId !== "") {
        this.handleLoading();
        requestList(
          serverApi.CM_TERMS_PACKAGE_MAPPING_ADD,
          data,
          this.handleAddItem
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddItem = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Package
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.packageId}
                          onSelect={this.onSelect}
                          stateKey="packageId"
                          staticData={this.state.packageList}
                          isRefresh={this.state.loadPackage}
                          setIsRefresh={(val) => {
                            this.setState({ loadPackage: false });
                          }}
                          //  url={serverApi.SEARCH_JUTE_STATUS}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Term</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.termId}
                          onSelect={this.onSelect}
                          stateKey="termId"
                          staticData={this.state.termsList}
                          isRefresh={this.state.loadTerm}
                          setIsRefresh={(val) => {
                            this.setState({ loadTerm: false });
                          }}
                          //  url={serverApi.SEARCH_JUTE_STATUS}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        //url={serverApi.DATATABLE_SERVICE_URL+"/getAllIndent"}
                        //   searchData={this.getData()}
                        staticData={this.state.tableList}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"Package_List"}
                        actionType={DELETE}
                        // showDelete={true}
                        //  onDeleteClick={this.onDeletePackageMaster}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(TermPackageMapping)
);
