// import request from '../serverCalls/server'
import cons from './Consts'
//import Cookies from 'universal-cookie'
import {reactLocalStorage} from 'reactjs-localstorage';

//const ck = new Cookies();
const isLoggedIn = () => {
    var data = reactLocalStorage.get(cons.USERID);
    console.log(data);
    if (data === null || data === undefined) {
        return false
    } else {
        return (data.toString().length > 0) ? true : false
    }
};


// const isLoggedIn = (cb) => {
//     request(101, {}, function (res) {
//         if (res.status) {
//             if (res.isLoggedIn)
//                 cb(cons.SESSION_OK)
//             else
//                 cb(cons.SESSION_EXP)
//         } else {
//             cb(cons.ERROR)
//         }
//     });
// };

export default isLoggedIn