import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import {VIEW} from "../../ui-actions/UiActions";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import Search from "@material-ui/icons/Search";
import LineItemTable from "../CustumComponents/LineItemTable";
import { connect } from 'react-redux';


class EmployeeBilling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate:  moment().format('DD-MM-YYYY'),
            todate: moment().format('DD-MM-YYYY'),
            status: '',
            isRefresh: false,
            isLoading: false,
            isDisabled:true,
            isDisabledEbNo:false,
            approveButton:false,
            itemViewType: "issueObj",
            year: moment().format('YYYY'),
            leaveTypeId:"",
            leavesMonth:"",
            leavesYear:"",
            dept:"",
            workerName:"",
            dataTableList:null,
            LeaveTypeOptions:[],
            invoiceList:[],
            updateView:false,
            paySchemeLineData:[],
            "customerId": 0,
            "empName": "",
            "payschemeId": 0,
            "customerId1": 0,
            "empName1": "",
            "paySchemeId1": 0,
            paySchemeListNoCalData:[],paySchemeListEarningsData:[],paySchemeListDeductionData:[],paySchemeListSummaryData:[],payComponentData:[]
            //ebNo:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",

            //ebIdList:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount(){
        var LeavesList= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":false,"Header":"Emp Code","accessor":"employeeid","minWidth":50,"style":{"textAlign":"center"},"sortable":false},{"filterable":false,"Header":"Emp Name","accessor":"empName","minWidth":100,"style":{"textAlign":"center"},"sortable":false},{"filterable":false,"Header":"Customer","accessor":"customerName","minWidth":100,"style":{"textAlign":"center"},"sortable":false},{"filterable":false,"Header":"Pay Scheme","accessor":"payschemeName","minWidth":80,"style":{"textAlign":"center"},"sortable":false}]};

        this.setState({
            dataTableList:LeavesList,
           // isRefresh: true,
        });
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.CUSTOMER_MASTER,data,this.handelCustomer);
        this.callPaySchemeComponentList();
        this.callPaySchemeList();
        this.getList();

    }
    handelCustomer=(resp)=>{
        var data=resp.data,customerList=[];
        if(data.length>0){
            var obj={label:"Select",value:null,name:"null"};
            customerList.push(obj);
            data.forEach(prop=>{
                var obj={label:prop.name,value:prop.id,name:prop.name};
                customerList.push(obj)
            });
            this.setState({
                customerList:customerList,
                isCustomer:true
            })
        }
    };
    callPaySchemeList() {

        var data={
            "status":"32","activeStatus":""
        }
        requestList(serverApi.PAY_SCHEME_GET_LIST,data, this.handlePaySchemeList);    }

    handlePaySchemeList = (resp) => {
        console.log("payresp",resp);
        if(resp.status===undefined) {
            var payscheme = [];
           payscheme.push( {
                "value": 0,
                "name":"",
                "label":"Select"

            })
            resp.map((prop, i) => {
                var obj = {
                    "value": prop.id,
                    "name": prop.name,
                    "label": prop.name

                };
                payscheme.push(obj);
            });
            this.setState({paySchemeList: payscheme, loadPayscheme: true});
        }
    };

    getList(){
        const {customerId1,empName1,paySchemeId1}=this.state;
        var data={
            "companyId": 0,
            "customerId": customerId1,
            "empName": empName1,
            "payschemeId": paySchemeId1
        };

        requestList(serverApi.EMPLOYEE_BILLING_LIST,data,this.handleList)
    }
    handleList=(resp)=>{
        console.log("ddd",JSON.stringify(resp));
        var list=this.state.dataTableList;
        if(resp){
            list.data=resp
        }
        this.setState({dataTableList:list,isRefresh:true});
    };





    handleSeacrh = () => {
        var loading = false;
        if( !this.state.isRefresh)
            this.getList();
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }


    handleCreate = () => {
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        this.setState({
            isCreated: !this.state.isCreated,

        });

    };

    getData = () => {
        var data={

            "companyId": 0,
            "customerId": 0,
            "empName": "",
            "payschemeId": 0
        };

        return data;
    };
    timeChanged = (dateObj, id) => {

        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
         if(this.state.isCreated){
             this.getList()
         }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            empName:"",
            paySchemeLineData:[],
            "customerId": 0,
            "payschemeId": 0,
            paySchemeListNoCalData:[],paySchemeListEarningsData:[],paySchemeListDeductionData:[],paySchemeListSummaryData:[],

            empId:getCookie(cons.EB_ID)!=="null"?getCookie(cons.EB_ID):"",
            ebNo:getCookie(cons.EB_NO)==="null"?"":getCookie(cons.EB_NO),
            empPayschemeId:"",
            "paySchemeId1": 0,
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 8) {
            return length
        } else {
            return 8;
        }
    };
    onSelect = (selectedValue, stateKey) => {


        if (stateKey === "paySchemeId" && this.state.paySchemeId !== selectedValue) {

            getDataFromUrl(serverApi.PAY_SCHEME_GET_BYID + "/" + selectedValue, this.handleViewClickRespPayscheme)

        }

        this.setState({
            [stateKey]: selectedValue
        });
    };
    getComponentName=(id,name)=>{
        var componentvalue="";
        this.state.payComponentData.forEach(function(value){
            if(id===value.id&&name==="name"){
                componentvalue=value.name;
            }
            if(id===value.id&&name==="type"){
                componentvalue=value.type;
            }
            if(id===value.id&&name==="formula"){
                componentvalue=value.formula;
            }
        });
        return componentvalue;
    }
    callPaySchemeComponentList(){
        getDataFromUrl(serverApi.PAY_SCHEME_DATATABLE, this.handlePayComponent)
    }
    handlePayComponent =(resp) =>{
        var componentList = [];
        console.log(resp);
        this.setState({payComponentData:resp})
    };
    handleViewClickRespPayscheme = (resp) => {
        var header = resp.paySchemeDTO;
        var list = resp.paySchemeDetailsDTO, paySchemeListEarningsData=[],
            paySchemeListDeductionData=[],paySchemeListNoCalData=[],paySchemeListSummaryData=[];
        list.forEach((prop,key)=>{
            var obj={
                id:paySchemeListNoCalData.length,
                lineItemId:prop.id,
                componentId:prop.componentId,
                componentvalue:this.getComponentName(prop.componentId,"name"),
                formula:prop.formula,
                type:parseInt(prop.type),
                value:""

            };
            console.log("paySchemeLineData",this.state.paySchemeLineData);
            if(this.state.paySchemeLineData.length>0&&this.state.paySchemeIdNew===this.state.paySchemeId){
                this.state.paySchemeLineData.forEach(p=>{
                    if(p.componentId===prop.componentId){
                        obj.value=p.amount;
                        if(obj.type===0&&prop.status===1){
                            obj.formula=p.amount;
                        }
                    }
                });
            }
            if(obj.type===0&&prop.status===1){
                paySchemeListNoCalData.push( obj);
            }
            if(obj.type===1&&prop.status===1){
                paySchemeListEarningsData.push( obj);
            }
            if(obj.type===2&&prop.status===1){
                paySchemeListDeductionData.push(obj);
            }
            if(obj.type===3&&prop.status===1){
                paySchemeListSummaryData.push(obj);
            }
        });
        //console.log("indentWeight "+header.indentWeight);
        if (header !== null) {
            this.setState({

                // loadPayscheme:true,
                //  effectiveFrom:header.effectiveFrom,
                paySchemeListEarningsData:paySchemeListEarningsData,
                paySchemeListDeductionData:paySchemeListDeductionData,paySchemeListNoCalData:paySchemeListNoCalData,
                paySchemeListSummaryData:paySchemeListSummaryData

            });

        }
        if(this.state.paySchemeLineData.length===0) {
            this.onClaculationButtonClick();
        }
    };
    onClaculationButtonClick =()=>{
        const {payschemeId, description,effectiveFrom, payschemeCode, payschemeName, wageType ,paySchemeListEarningsData,paySchemeListDeductionData,paySchemeListNoCalData,paySchemeListSummaryData} = this.state;

        var PayschemeList =[...paySchemeListEarningsData,...paySchemeListDeductionData,...paySchemeListNoCalData,...paySchemeListSummaryData];

        var obj1={};
        PayschemeList.forEach(function(prop, keyIndex) {
            obj1={...obj1,[prop.componentId]:prop.formula}
        });
        requestList(serverApi.CHECK_PAYSCHEME+"/1111/"+this.state.paySchemeId, obj1, this.handleCheckPayscheme)
    }
    handleCheckPayscheme=(resp)=>{
        if(resp.status===true) {
            console.log("checkPayscheme", resp);
            const {paySchemeListEarningsData, paySchemeListDeductionData, paySchemeListNoCalData,paySchemeListSummaryData} = this.state;
            var paySchemeListEarningsData1 = paySchemeListEarningsData.map(prop => {
                var amount = "";
                if(resp.list)
                resp.list.find(o => {
                    if (o.componentId === prop.componentId) {
                        amount = o.amount
                        return true;
                    }

                })
                return {...prop, value: amount}
            });
            var paySchemeListDeductionData1 = paySchemeListDeductionData.map(prop => {
                var amount = "";
                if(resp.list)
                    resp.list.find(o => {
                    if (o.componentId === prop.componentId) {
                        amount = o.amount
                        return true;
                    }

                })
                return {...prop, value: amount}
            });
            var paySchemeListNoCalData1 = paySchemeListNoCalData.map(prop => {
                var amount = "";
                if(resp.list)
                    resp.list.find(o => {
                    if (o.componentId === prop.componentId) {
                        amount = o.amount;
                        return true;
                    }

                })
                return {...prop, value: amount}
            });
            var paySchemeListSummaryData1 = paySchemeListSummaryData.map(prop => {
                var amount = "";
                if(resp.list)
                    resp.list.find(o => {
                    if (o.componentId === prop.componentId) {
                        amount = o.amount;
                        return true;
                    }

                });
                return {...prop, value: amount}
            });

            this.setState({
                paySchemeListEarningsData: paySchemeListEarningsData1,
                paySchemeListDeductionData: paySchemeListDeductionData1,
                paySchemeListNoCalData: paySchemeListNoCalData1,
                paySchemeListSummaryData:paySchemeListSummaryData1
            })
        }

        // console.log("paySchemeListEarningsData1",paySchemeListEarningsData1);

    }

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var payscheme = [...this.state.paySchemeListEarningsData, ...this.state.paySchemeListDeductionData, ...this.state.paySchemeListNoCalData,...this.state.paySchemeListSummaryData];

            var payschemedata = payscheme.map((prop, key) => {
                return ( {
                    "amount": prop.value,
                    "componentId": prop.componentId,
                    "effectiveFrom":this.state.effectiveFrom,
                    "employeeid": this.state.empId,
                    "endsOn": "",
                    "payschemeId":  this.state.paySchemeId,
                    "remarks": ""
                })
            });

            var data ={
                "payEmployeeDTO": {
                    "customerId": this.state.customerId,
                    "employeeid": this.state.empId,
                    "paySchemeId": this.state.paySchemeId,
                    "createdBy":getCookie(cons.USERID)
                },
                "payEmployeeStructureDTO": payschemedata
            };

            if ( this.state.paySchemeId > 0) {
                this.handleLoading();
                requestList(serverApi.ADD_EMPLOYEE_BILLING, data, this.handleAddBilling)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }

    };
    dateChanged = (date) => {
        var myDate=date;
        myDate=myDate.split("-");
        var newDate=myDate[2]+"-"+myDate[1]+"-"+myDate[0];
         return newDate;
    };
    onUpdateButtonClick=(status)=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var payscheme = [...this.state.paySchemeListEarningsData, ...this.state.paySchemeListDeductionData, ...this.state.paySchemeListNoCalData,...this.state.paySchemeListSummaryData];

            var payschemedata = payscheme.map((prop, key) => {
                return ( {
                    "amount": prop.value,
                    "componentId": prop.componentId,
                    "effectiveFrom":this.state.effectiveFrom,
                    "employeeid": this.state.empId,
                    "endsOn": "",
                    "payschemeId":  this.state.paySchemeId,
                    "remarks": ""
                })
            });

            var data ={
                "payEmployeeDTO": {
                    "id": this.state.empPayschemeId,
                    "employeeid": this.state.empId,
                    "paySchemeId": this.state.paySchemeId,
                    customerId:this.state.customerId,
                    "modifiedBy":getCookie(cons.USERID)
                },
                "payEmployeeStructureDTO": payschemedata
            };

            if ( this.state.paySchemeId > 0) {
                this.handleLoading();
                requestList(serverApi.UPDATE_EMPLOYEE_BILLING, data, this.handleAddBilling)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }

    };

    handleAddBilling=(resp)=>{
        if (resp.status) {
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if(status)
                        this.hideAlertMessage();
                        else
                            this.setState({showMessagealert:false})
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleViewClick = (id) => {

        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var val=JSON.parse(id);

            this.setState({empName:val.empName,empId:val.employeeid});
            getDataFromUrl(serverApi.EMPLOYEE_BILLING_BY_ID+val.id,this.handleViewClickResp)
        }
    };
    handleViewClickResp=(resp)=>{
        if(resp.payEmployeeDTO!==null){
            var payheader = resp.payEmployeeDTO;
            //console.log("indentWeight "+header.indentWeight);
                this.setState({
                    paySchemeId:payheader.paySchemeId,empPayschemeId:payheader.id,
                    paySchemeIdNew:payheader.paySchemeId,
                    effectiveFrom:payheader.effectiveFrom,
                    customerId:payheader.customerId,
                    paySchemeLineData:resp.payEmployeeStructureDTO
                });


            getDataFromUrl(serverApi.PAY_SCHEME_GET_BYID + "/" + payheader.paySchemeId, this.handleViewClickRespPayscheme)

        }

        if(resp){
            this.setState({
                isCreated:true,
                updateView:true,
                isDisabledEbNo:true,
                approveButton:resp.approveButton,

            })

        }
    };
    getEmpDetails=()=>{
        const {ebNo} = this.state;
        if(ebNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+ebNo+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(ebNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
              //  isDisabled:false,
                empName: header.workerName,
                dept: header.deptId,
                loadDept:true,
                empId:header.ebId,
            });
            getDataFromUrl(serverApi.LEAVE_REQUEST_AVAILABILITY+"/"+header.ebId+"/"+getCookie(cons.USERID),this.handleAvailabilityCheck)

        }else {
            this.showMessage(resp.message,false)
        }


    };

    render() {
        const {classes} = this.props;
        const {dataTableList}=this.state;
        const inputFocus = {
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );

        const options=[
            {value:0,name:"select...",label:"select..."},
            {value: 1, name: "OPEN", label: "OPEN"},
            {value:3,name:"APPROVED",label:"APPROVED"},
            {value:4,name:"REJECTED",label:"REJECTED"},
            {value:6,name:"CANCELLED",label:"CANCELLED"},
            {value: 17, name: "PENDING APPROVAL LEVEL 2", label: "PENDING APPROVAL LEVEL 2"},
            {value: 18, name: "PENDING APPROVAL LEVEL 3", label: "PENDING APPROVAL LEVEL 3"},
            {value: 19, name: "PENDING APPROVAL LEVEL 4", label: "PENDING APPROVAL LEVEL 4"}
        ];
        var valid = function( current ){
            if(this.props.state.companyInfo.id!=='2')
                return current.isAfter( yesterday );
            else
                return true;        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            cardTitle={<Button color={"info"} onClick={this.handleCreate}>
                                Back To List</Button>}
                            isLoading={this.state.isLoading}
                            content={
                                <GridContainer>
                                    {this.state.showMessagealert}
                                    {this.state.basicAlert}
                                    <ItemGrid xs={12} md={6} >

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Customer
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.customerId}
                                                    id={"customerId"}
                                                    onSelect={this.onSelect}
                                                    stateKey="customerId"
                                                    staticData={this.state.customerList}
                                                    isRefresh={this.state.isCustomer}
                                                    getName={(name)=>{

                                                    }}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({isCustomer:false})
                                                    }}
                                                    //  url={serverApi.SEARCH_JUTE_STATUS}
                                                    value={this.state.customer}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView?<ItemGrid xs={12} md={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Emp Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    // defValue={this.state.ebNo}
                                                    id="ebNo"
                                                    value={this.state.ebNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}


                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Worker Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="empName"
                                                    value={this.state.empName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Pay Scheme Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>

                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.paySchemeId}
                                                    id={"paySchemeId"}
                                                    onSelect={this.onSelect}
                                                    //getName={this.getQualityDetails}
                                                    isRefresh={this.state.loadPayscheme}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadPayscheme:false})
                                                    }}
                                                    stateKey="paySchemeId"
                                                    staticData={this.state.paySchemeList}
                                                    //url={serverApi.SELECTION_PAY_SCHEME+this.props.state.companyInfo.id}
                                                    //value={this.state.selectedJuteQuality}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Effective Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "effectiveFrom")
                                                        }}
                                                        formControlProps={
                                                            {height: "10px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "effectiveFrom",
                                                            value: this.state.effectiveFrom,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>



                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}/>

                                    {this.state.paySchemeId!==""&&this.state.paySchemeId!==null?
                                        <ItemGrid xs={12}>
                                            <GridContainer>
                                                {this.state.paySchemeListNoCalData.length>0?<ItemGrid xs={12} style={{ marginTop: "10px" }}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12}>
                                                            <InputLabel className={classes.label}>
                                                                Input Values
                                                            </InputLabel>
                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>:null}
                                                <ItemGrid xs={12} >
                                                    <GridContainer>
                                                        {this.state.paySchemeListNoCalData.map((value,key)=>{

                                                            //console.log(value,key)
                                                            return(
                                                                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                {value.componentvalue}<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <TextField
                                                                                id={key}
                                                                                //defaultValue={value.defaultValue}
                                                                                value={value.formula}
                                                                                className={classes.textField}
                                                                                onChange={(event)=>{
                                                                                    var data=this.state.paySchemeListNoCalData;
                                                                                    data[key].formula=event.target.value;
                                                                                    data[key].value=event.target.value;
                                                                                    this.setState({paySchemeListNoCalData:data})
                                                                                }}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                // getName={this.getDeptName}
                                                                                iType={"number"}
                                                                            />

                                                                        </ItemGrid>
                                                                    </GridContainer>

                                                                </ItemGrid>

                                                            )
                                                        })}

                                                    </GridContainer>
                                                </ItemGrid>
                                                {this.state.paySchemeListNoCalData.length>0?<ItemGrid xs={12}>
                                                    <Button color="primary" right={true} style={{width:130}} onClick={this.onClaculationButtonClick}>Check Calculation</Button>
                                                </ItemGrid>:null}
                                                <ItemGrid xs={12}
                                                          style={{
                                                              height: 2,
                                                              backgroundColor: "#00acc1",
                                                              marginTop: "5px"
                                                          }}></ItemGrid>
                                                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                                    <InputLabel className={classes.label} >
                                                        Earnings :
                                                    </InputLabel>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                                    <InputLabel className={classes.label} >
                                                        Deductions :
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={6} style={{margin: 0}}>
                                                    <LineItemTable
                                                        data={this.state.paySchemeListEarningsData}
                                                        loading={this.state.loading}
                                                        tableSize={350}
                                                        columns={[
                                                            {
                                                                Header: "Component",
                                                                accessor: "componentvalue",
                                                                minWidth: 100,
                                                                style: { color: "#000", textAlign: "left" }
                                                            },
                                                            {
                                                                Header: "Formula",
                                                                accessor: "formula",
                                                                minWidth: 100,
                                                                style: { color: "#000", textAlign: "left" }
                                                            },

                                                            {
                                                                Header: "Value",
                                                                accessor: "value",
                                                                minWidth: 50,
                                                                style: { color: "#000", textAlign: "left" },
                                                            }
                                                        ]}
                                                    />
                                                </ItemGrid>
                                                <ItemGrid xs={6} >
                                                    <LineItemTable
                                                        data={this.state.paySchemeListDeductionData}
                                                        loading={this.state.loading}
                                                        tableSize={350}
                                                        columns={[
                                                            {
                                                                Header: "Component",
                                                                accessor: "componentvalue",
                                                                minWidth: 100,
                                                                style: { color: "#000", textAlign: "left" }
                                                            },
                                                            {
                                                                Header: "Formula",
                                                                accessor: "formula",
                                                                minWidth: 100,
                                                                style: { color: "#000", textAlign: "left" }
                                                            },

                                                            {
                                                                Header: "Value",
                                                                accessor: "value",
                                                                minWidth: 50,
                                                                style: { color: "#000", textAlign: "left" },
                                                            }
                                                        ]}

                                                    />
                                                </ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <InputLabel className={classes.label}>
                                                        Summary:
                                                    </InputLabel>
                                                </ItemGrid>

                                                {this.state.paySchemeListSummaryData.map((value,key)=>{

                                                    //console.log(value,key)
                                                    return(
                                                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        {value.componentvalue}<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={8}>
                                                                    <TextField
                                                                        disabled={true}
                                                                        id={key}
                                                                        //defaultValue={value.defaultValue}
                                                                        value={value.value}
                                                                        className={classes.textField}
                                                                        onChange={(event)=>{
                                                                            var data=this.state.paySchemeListSummaryData;
                                                                            data[key].formula=event.target.value;
                                                                            this.setState({paySchemeListSummaryData:data})
                                                                        }}
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        // getName={this.getDeptName}
                                                                        iType={"number"}
                                                                    />

                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>

                                                    )
                                                })}

                                            </GridContainer>
                                        </ItemGrid>:null}

                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {this.state.approveButton?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.onUpdateButtonClick(this.state.statusId)
                                                            }}>Approve</Button>

                                                </ItemGrid>
                                                :null}
                                            {this.state.updateView?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.onUpdateButtonClick("")}}>Update</Button>

                                                </ItemGrid>:null}

                                            {!this.state.updateView?
                                                <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                                    <GridContainer justify="center">
                                                        <ItemGrid>
                                                            <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>
                                                        </ItemGrid>
                                                        <ItemGrid>
                                                            <Button color="danger" onClick={this.hideAlertMessage}>Cancel</Button>
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid>:null

                                            }

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }
                        />

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <GridContainer>
{
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={4} md={4} >

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Customer
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.customerId1}
                                                                id={"customerId1"}
                                                                onSelect={this.onSelect}
                                                                stateKey="customerId1"
                                                                staticData={this.state.customerList}
                                                                isRefresh={this.state.isCustomer}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({isCustomer:false})
                                                                }}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={4} md={4}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Pay Scheme
                                                            </InputLabel>
                                                        </ItemGrid>

                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.paySchemeId1}
                                                                id={"paySchemeId1"}
                                                                onSelect={this.onSelect}
                                                                //getName={this.getQualityDetails}
                                                                isRefresh={this.state.loadPayscheme}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadPayscheme:false})
                                                                }}
                                                                stateKey="paySchemeId1"
                                                                staticData={this.state.paySchemeList}
                                                                //url={serverApi.SELECTION_PAY_SCHEME+this.props.state.companyInfo.id}
                                                                //value={this.state.selectedJuteQuality}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={4} md={4} >
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Emp Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="empName1"
                                                                value={this.state.empName1}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>


{/*
                                                <ItemGrid xs={4} sm={4}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.status}
                                                                id={"status"}
                                                                onSelect={this.onSelect}
                                                                getName={this.getQualityDetails}
                                                                // isRefresh={this.state.loadquality}
                                                                //setIsRefresh={this.afterRefreshquality}
                                                                stateKey="status"
                                                                staticData={options}
                                                                value={this.state.status}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
*/}




                                            </GridContainer>
                                        </ItemGrid>
}
                                        <ItemGrid xs={2}>
                                            <IconButton
                                                onClick={this.handleCreate}
                                                color="success" right={true}>
                                                <Add/>
                                            </IconButton>
{
                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrh}>Search</Button>
}

                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>



                                            {dataTableList?  <ReportsTableComponent
                                                   //  url={serverApi.EMPLOYEE_BILLING_LIST}
                                                    staticData={dataTableList}
                                                    borderRight={true}
                                                     searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    // isLoading={this.state.isRefresh}
                                                      onActionClick={this.handleViewClick}
                                                    actionType={VIEW}

                                                    fileName={"jute_with_value"}
                                                    itemViewType={this.state.itemViewType}/>:null}



                                            {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}


                                        </ItemGrid>
                                      </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(EmployeeBilling)
);
