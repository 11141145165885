import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie, setCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import { AdvanceType, months, ParameterTypes, yesOrNo } from "../TableData";
import { subMenus } from "../../routes/routeIcons";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";
import { connect } from "react-redux";

class AdvanceRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "hdrId",
      updateView: false,
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      leavePolicieList: [],
      empList: [],
      tableList: null,
      ebId: "",
      requestDate: moment().format("DD-MM-YYYY"),
      typeOfAdvance: "Issue Loan",
      applicableYear: moment().format("YYYY"),
      monthlySalary: "",
      deductionYear: "",
      amountRequested: "",
      deductionStartsFrom: "",
      noOfApplicableMonths: "",
      deductionUpto: "",
      remarksOfApplicant: "",
      remarks: "",
      hdrId: "",
      isActive: "",
      deductionStatus: "N",
      ebNo: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    this.callPaySchemeParameters();
    this.callAdvanceRequestList();
    this.getReportsTable();
  }

  getReportsTable = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      deptId: 0,
      empCode: "",
      empName: "",
      esiNo: "",
      payScheme: "",
      pfNo: "",
      reporting: 0,
      userId: getCookie(cons.USERID),
    };

    requestList(serverApi.TABLE_WORKERS_LIST, data, this.handleReports);
  };
  handleReports = (resp) => {
    var data = [];
    data = resp.data.map((prop) => {
      var obj = {
        label: prop.workerName,
        value: prop.ebId,
        name:
          prop.workerName +
          "^" +
          prop.empCode +
          "^" +
          prop.designationId +
          "^" +
          prop.departmentId +
          "^" +
          prop.ebNo,
      };
      return obj;
    });

    this.setState({
      empList: data,
      loadEmp: true,
    });
    //}
    //  this.handleLoading();
  };

  callPaySchemeParameters() {
    getDataFromUrl(
      serverApi.LEAVE_TYPE_SELECTION_LIST +
        "/" +
        this.props.state.companyInfo.id,
      this.handleleaveType
    );
  }

  handleleaveType = (resp) => {
    this.setState({ leave_Types: resp });
  };

  callAdvanceRequestList() {
    getDataFromUrl(
      serverApi.ADVANCE_REQUEST_LIST_TABLE +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.USERID),
      this.handleleavePolicy
    );
  }

  handleleavePolicy = (resp) => {
    if (resp.status === undefined)
      this.setState({ tableList: resp, isRefresh: true });
  };
  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: false,
    });
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + !getMenuPermission(0));
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.ADVANCE_REQUEST_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.hdrDto;
    var list = resp.dtlDto;
    var policylist = list.map((prop, key) => {
      return {
        id: key,
        dtlId: prop.dtlId,
        month: prop.month,
        year: prop.year,
        amount: prop.amount,
        deductionStatus: prop.deductionStatus,
        actions: (
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.leavePolicieList;
                data.find((o, i) => {
                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");

                    this.setState({
                      id: o.id,
                      updateItemAlert: true,
                      updateItem: true,
                      dtlId: o.dtlId,
                      month: o.month,
                      year: o.year,
                      amount: o.amount,
                      deductionStatus: o.deductionStatus,
                      // alert:true,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                var data = this.state.leavePolicieList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    this.basicItemAlert("Do You Like To Delete This Item?");
                    this.setState({
                      deleteItem: true,
                      id: o.id,
                    });
                    //  data.splice(i,1);
                    return true;
                  }
                });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });

    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        leavePolicieList: policylist,
        ebId: header.ebId,
        requestDate: header.requestDateDesc,
        typeOfAdvance: header.typeOfAdvance,
        applicableYear: header.applicableYear,
        monthlySalary: header.monthlySalary,
        deductionYear: header.deductionYear,
        amountRequested: header.amountRequested,
        deductionStartsFrom: header.deductionStartsFrom,
        noOfApplicableMonths: header.noOfApplicableMonths,
        deductionUpto: header.deductionUpto,
        remarksOfApplicant: header.remarksOfApplicant,
        remarks: header.remarks,
        hdrId: header.hdrId,
        isActive: header.isActive,
        status: header.status,
        approveButton: header.approveButton,
        bankName: header.bankName,
        ledgerId: header.ledgerId,
        // empName:header.workerName,
        ebNo: header.ebNo,
        // department:header.deptIdDesc,
        // designation:header.designationIdDesc

        //SloadEmp:true
      });
      if (header.ebNo !== null) {
        getDataFromUrl(
          serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
            header.ebNo +
            "/" +
            this.props.state.companyInfo.id +
            "/" +
            getCookie(cons.USERID),
          this.handleEmpDetails
        );
      }
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      // leavePolicieList:""
    });
    //  this.hideAlertMessage();
  };

  timeChanged = (dateObj, id) => {
    var canAdd = true;
    if (this.state.typeOfAdvance === "Issue Loan") {
      if (this.state.ebId === "") {
        canAdd = false;
      }
    }

    if (typeof dateObj === "object" && canAdd)
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else {
      if (this.state.ebId === "")
        this.basicAlert("Please Check The Employee Found Or Not..!!");
    }
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  setListValues = (
    deductionStartsFrom,
    amountRequested,
    noOfApplicableMonths,
    deductionYear
  ) => {
    var list = [];
    if (
      deductionStartsFrom !== "" &&
      amountRequested !== "" &&
      noOfApplicableMonths !== "" &&
      deductionYear !== ""
    ) {
      var amount = parseFloat(amountRequested) / parseInt(noOfApplicableMonths);
      for (var i = 0; i < parseInt(noOfApplicableMonths); i++) {
        var val = parseInt(deductionStartsFrom) + i;
        var y = deductionYear;
        if (val > 12) {
          var n = parseInt(val / 12);
          y = y + n;
        }

        // var month=val-n*12;

        var obj = {
          month: moment()
            .month(this.state.deductionStartsFrom + i - 1)
            .format("MMMM"),
          year: y,
          amount: amount.toFixed(2),
          deductionStatus: this.state.deductionStatus,
        };
        list.push(obj);
      }
    }
    this.setState({
      leavePolicieList: list,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    if (event.target.id === "ebNo") {
      this.setState({ ebId: "" });
    }
    if (event.target.id === "amountRequested") {
      if (value !== "") {
        this.setListValues(
          this.state.deductionStartsFrom,
          value,
          this.state.noOfApplicableMonths,
          this.state.deductionYear
        );
      } else {
        this.setState({ leavePolicieList: [] });
      }
    }
    if (event.target.id === "noOfApplicableMonths") {
      if (value !== "") {
        this.setState({
          [event.target.id]: value,
        });

        if (this.state.deductionStartsFrom !== "") {
          this.setListValues(
            this.state.deductionStartsFrom,
            this.state.amountRequested,
            value,
            this.state.deductionYear
          );
          var val =
            parseInt(this.state.deductionStartsFrom) + parseInt(value) - 1;
          if (val <= 12) {
            var name =
              moment()
                .month(val - 1)
                .format("MMMM") +
              " " +
              this.state.deductionYear;
          } else {
            var n = parseInt(val / 12);
            // var month=val-n*12;
            var y = this.state.deductionYear + n;
            var name =
              moment()
                .month(val - 1)
                .format("MMMM") +
              " " +
              y;
          }
          this.setState({ deductionUpto: name, loadDedct: true });
        }
      } else {
        this.setState({
          [event.target.id]: value,
          leavePolicieList: [],
          deductionUpto: "",
          loadDedct: true,
        });
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "typeOfAdvance" && selectedValue === "Borrow Loan") {
      this.setState({
        showBank: true,
      });
    }
    if (stateKey === "deductionStartsFrom") {
      this.setListValues(
        selectedValue,
        this.state.amountRequested,
        this.state.noOfApplicableMonths,
        this.state.deductionYear
      );

      if (selectedValue !== "" && this.state.noOfApplicableMonths !== "") {
        var val =
          parseInt(selectedValue) +
          parseInt(this.state.noOfApplicableMonths) -
          1;
        if (val <= 12) {
          var name =
            moment()
              .month(val - 1)
              .format("MMMM") +
            " " +
            this.state.deductionYear;
        } else {
          var n = parseInt(val / 12);
          // var month=val-n*12;
          var y = this.state.deductionYear + n;
          var name =
            moment()
              .month(val - 1)
              .format("MMMM") +
            " " +
            y;
        }
        this.setState({ deductionUpto: name, loadDedct: true });
      } else {
        this.setState({ deductionUpto: "", loadDedct: true });
      }
    }
    if (stateKey === "deductionYear") {
      this.setListValues(
        selectedValue,
        this.state.amountRequested,
        this.state.noOfApplicableMonths,
        this.state.deductionYear
      );
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        //  updateItem: false,
        updateItemAlert: false,
      });
    }
    // if(this.state.deleteList)
    if (this.state.deleteList) {
      this.handleLoading();
      getDataFromUrl(
        serverApi.LEAVE_POLICIES_DELETE_BYID + "/" + this.state.leavePolicyId,
        this.handleAddIndent
      );
    }

    if (this.state.deleteItem) {
      var data = this.state.leavePolicieList;
      data.splice(this.state.id, 1);
      data.map((o, key) => {
        o.id = key;
        o.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.leavePolicieList;
                data.find((o, i) => {
                  console.log("key in edit", key);

                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    console.log("id in edit", o.id);
                    this.setState({
                      // alert:true,
                      id: o.id,
                      updateItemAlert: true,
                      updateItem: true,
                      leavePolicyDtlId: o.leavePolicyDtlId,
                      leavePolicyId: o.leavePolicyId,
                      carryFrwStatus: o.carryFrwStatus,
                      encashmentStatus: o.encashmentStatus,
                      leaveTypeId: o.leaveTypeId,
                      leavesPerYear: o.leavesPerYear,
                      maxConsecutiveCount: o.maxConsecutiveCount,
                      maxLeavesPerYear: o.maxLeavesPerYear,
                      yearEndDate: o.yearEndDate,
                      yearStartDate: o.yearStartDate,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>{" "}
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.leavePolicieList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    //if (this.state.approveStatus) {
                    console.log("id in delete", o.id);
                    if (this.state.approveButton && this.state.updateView) {
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                    } else if (!this.state.updateView) {
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                    }
                    return true;
                    //  data.splice(i,1);
                    // }
                  }
                });
                this.setState({ leavePolicieList: data });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>
          </div>
        );
      });

      //  if(this.state.approveStatus)
      // this.setState({
      //     deleteItem:false});
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      loading: false,
      alert: false,
      showMessagealert: false,
      month: "",
      year: "",
      amount: "",
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.callAdvanceRequestList();
    }
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      leavePolicieList: [],
      ebNo: "",
      empName: "",
      department: "",
      designation: "",
      ebId: "",
      requestDate: moment().format("DD-MM-YYYY"),
      typeOfAdvance: "Issue Loan",
      applicableYear: parseInt(moment().format("YYYY")),
      monthlySalary: "",
      deductionYear: parseInt(moment().format("YYYY")),
      amountRequested: "",
      deductionStartsFrom: "",
      noOfApplicableMonths: "",
      deductionUpto: "",
      remarksOfApplicant: "",
      remarks: "",
      hdrId: "",
      isActive: "",
      ledgerId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        leavePolicieList,
        ebId,
        requestDate,
        typeOfAdvance,
        applicableYear,
        monthlySalary,
        deductionYear,
        amountRequested,
        deductionStartsFrom,
        noOfApplicableMonths,
        deductionUpto,
        remarksOfApplicant,
        remarks,
        bankName,
        ebNo,
      } = this.state;
      var policylist = leavePolicieList.map((prop, key) => {
        return {
          month: prop.month,
          year: prop.year,
          amount: prop.amount,
          deductionStatus: prop.deductionStatus,
        };
      });

      var data = {
        dtlDto: policylist,
        hdrDto: {
          companyId: this.props.state.companyInfo.id,
          ebId: ebId,
          requestDate: this.dateChanged(requestDate),
          typeOfAdvance: typeOfAdvance,
          applicableYear: applicableYear,
          monthlySalary: monthlySalary,
          deductionYear: deductionYear,
          amountRequested: amountRequested,
          deductionStartsFrom: deductionStartsFrom,
          noOfApplicableMonths: noOfApplicableMonths,
          deductionUpto: deductionUpto,
          remarksOfApplicant: remarksOfApplicant,
          createdBy: getCookie(cons.USERID),
          remarks: remarks,
          bankName: bankName,
        },
      };
      var ebStatus = 0;
      if ((ebId !== "" && ebId !== null) || typeOfAdvance === "Borrow Loan")
        ebStatus = 1;
      else if (ebNo !== "" && ebNo !== null) ebStatus = 2;

      if (
        this.state.leavePolicieList.length >= 1 &&
        requestDate !== "" &&
        typeOfAdvance !== "" &&
        applicableYear !== "" &&
        deductionYear !== "" &&
        deductionStartsFrom !== "" &&
        noOfApplicableMonths !== "" &&
        ebStatus === 1
      ) {
        if (typeOfAdvance === "Borrow Loan") {
          if (bankName !== "") {
            this.handleLoading();
            requestList(
              serverApi.ADVANCE_REQUEST_ADD,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Please Select Employee..!!");
          }
        } else {
          if (ebId !== "") {
            this.handleLoading();
            requestList(
              serverApi.ADVANCE_REQUEST_ADD,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Please Enter Bank Name..!!");
          }
        }
      } else if (ebStatus !== 1) {
        if (ebStatus === 0) this.basicAlert("Please Enter EB Number..!!");
        if (ebStatus === 2)
          this.basicAlert("Please Click on Search Button of EB Number..!!");
      } else if (this.state.leavePolicieList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        leavePolicieList,
        ebId,
        requestDate,
        typeOfAdvance,
        applicableYear,
        monthlySalary,
        deductionYear,
        amountRequested,
        deductionStartsFrom,
        noOfApplicableMonths,
        deductionUpto,
        remarksOfApplicant,
        remarks,
        hdrId,
        isActive,
        bankName,
      } = this.state;

      var policylist = leavePolicieList.map((prop, key) => {
        return {
          dtlId: prop.dtlId,
          month: prop.month,
          year: prop.year,
          amount: prop.amount,
          deductionStatus: prop.deductionStatus,
        };
      });

      var data = {
        dtlDto: policylist,
        hdrDto: {
          hdrId: hdrId,
          companyId: this.props.state.companyInfo.id,
          ebId: ebId,
          requestDate: this.dateChanged(requestDate),
          typeOfAdvance: typeOfAdvance,
          applicableYear: applicableYear,
          monthlySalary: monthlySalary,
          deductionYear: deductionYear,
          amountRequested: amountRequested,
          deductionStartsFrom: deductionStartsFrom,
          noOfApplicableMonths: noOfApplicableMonths,
          deductionUpto: deductionUpto,
          remarksOfApplicant: remarksOfApplicant,
          updatedBy: getCookie(cons.USERID),
          remarks: remarks,
          status: status,
          isActive: isActive,
          userId: getCookie(cons.USERID),
          bankName: bankName,
        },
      };
      console.log("data", data);
      if (
        this.state.leavePolicieList.length >= 1 &&
        requestDate !== "" &&
        typeOfAdvance !== "" &&
        applicableYear !== "" &&
        deductionYear !== "" &&
        deductionStartsFrom !== "" &&
        noOfApplicableMonths !== ""
      ) {
        if (typeOfAdvance === "Borrow Loan") {
          if (bankName !== "") {
            this.handleLoading();
            requestList(
              serverApi.ADVANCE_REQUEST_UPDATE,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Please Select Employee..!!");
          }
        } else {
          if (ebId !== "") {
            this.handleLoading();
            requestList(
              serverApi.ADVANCE_REQUEST_UPDATE,
              data,
              this.handleAddIndent
            );
          } else {
            this.basicAlert("Please Enter Bank Name..!!");
          }
        }
      } else if (this.state.leavePolicieList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.deleteList) this.callAdvanceRequestList();
      this.setState({
        deleteList: false,
        basicAlert: null,
      });
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.leavePolicieList;

    var itemIndex = 0,
      dtlId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      dtlId = this.state.dtlId;
      //   arr[itemIndex].quantity = this.state.quantity
    } else {
      itemIndex = this.state.leavePolicieList.length;
    }
    var addItemObj = {
      id: itemIndex,
      dtlId: dtlId,
      month: this.state.month,
      year: this.state.year,
      amount: this.state.amount,
      deductionStatus: this.state.deductionStatus,
      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.leavePolicieList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    updateItemAlert: true,
                    updateItem: true,
                    dtlId: o.dtlId,
                    month: o.month,
                    year: o.year,
                    amount: o.amount,
                    deductionStatus: o.deductionStatus,
                    // alert:true,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.leavePolicieList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({
                    deleteItem: true,
                    id: o.id,
                  });

                  //  data.splice(i,1);
                  return true;
                }
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };
    if (
      this.state.month !== "" &&
      this.state.year !== "" &&
      this.state.amount > 0 &&
      this.state.deductionStatus !== ""
    ) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else {
        arr.splice(arr.length, 0, addItemObj);
      }
      this.setState({
        //loading:,
        leavePolicieList: arr,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.leavePolicieList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { leavePolicyDesc } = this.state;

    //  var id="";
    console.log(this.state);
    //if(leavePolicyDesc !== "")
    this.setState({
      loading: true,
      alert: true,
    });
    /* else {
             this.basicAlert("Please Enter All Mandatory Fields..!!");
         }*/
  }

  onDeleteLeavePolicy = (id) => {
    this.setState({ deleteList: true, leavePolicyId: id });

    this.basicItemAlert("Do You Like To Delete This Record?");
  };
  getEmpDetails = () => {
    const { ebNo } = this.state;
    if (ebNo !== "") {
      getDataFromUrl(
        serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
          ebNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.USERID),
        this.handleEmpDetails
      );
    } else if (ebNo === "") {
      this.basicAlert("Please Enter Employee No..!!");
    }
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      var header = resp.data;
      this.setState({
        empName: header.workerName,
        department: header.deptIdDesc,
        designation: header.designationIdDesc,
        monthlySalary: header.grossSalary,
        ebId: header.ebId,
      });
    } else {
      this.showMessage(resp.message, false);
    }
  };

  render() {
    const { classes } = this.props;
    const { leave_Types } = this.state;
    const LeaveTypes = leave_Types,
      years = [];
    if (getCookie(cons.ACADEMIC_YEAR_LIST) !== "undefined") {
      var acyears = JSON.parse(getCookie(cons.ACADEMIC_YEAR_LIST));
      acyears.forEach((o) => {
        var obj = { label: o.value, value: o.value, name: o.value };
        years.push(obj);
      });
    }

    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var valid = function(current) {
      //   if (this.props.state.companyInfo.id !== "2")
      if (getCookie(cons.COMPANY_ID) !== "2") return current.isAfter(yesterday);
      else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <form>
                          <GridContainer xs={12} md={12}>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Month
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"month"}
                                        defValue={this.state.month}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          this.setState({
                                            carryFrwStatusName: name,
                                          });
                                        }}
                                        stateKey="month"
                                        staticData={months}
                                        //url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedMukam +"/getAllItemByMukam/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                        value={this.state.month}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Year
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"year"}
                                        defValue={this.state.year}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          this.setState({
                                            encashmentStatusName: name,
                                          });
                                        }}
                                        stateKey="year"
                                        staticData={years}
                                        //  url={serverApi.LEAVE_TYPE_SELECTION_LIST+"/"+this.props.state.companyInfo.id}
                                        value={this.state.encashmentStatus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount{" "}
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        // disabled={true}
                                        id="amount"
                                        value={this.state.amount}
                                        //  className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Deduction Status
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"deductionStatus"}
                                        defValue={this.state.deductionStatus}
                                        onSelect={this.onSelect}
                                        stateKey="deductionStatus"
                                        staticData={yesOrNo}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Type Of Advance
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.typeOfAdvance}
                          onSelect={this.onSelect}
                          stateKey="typeOfAdvance"
                          staticData={AdvanceType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.typeOfAdvance === "Borrow Loan" ? (
                    <ItemGrid xs={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Bank Name
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            // disabled={true}
                            id="bankName"
                            value={this.state.bankName}
                            //  className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.typeOfAdvance === "Issue Loan" ? (
                    <ItemGrid xs={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            EB No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="ebNo"
                            value={this.state.ebNo}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <Search
                                      className={classes.inputAdornmentIcon}
                                      onClick={this.getEmpDetails}
                                    />
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Name</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="empName"
                          value={this.state.empName}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Department
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="department"
                          value={this.state.department}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Designation
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="designation"
                          value={this.state.designation}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Request Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            value={this.state.requestDate}
                            closeOnSelect={true}
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "requestDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "attendanceDate",
                              value: this.state.requestDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Applicable Year
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.applicableYear}
                          onSelect={this.onSelect}
                          stateKey="applicableYear"
                          staticData={years}
                          value={this.state.applicableYear}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Monthly Salary
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="monthlySalary"
                          value={this.state.monthlySalary}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Deduction Year
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.deductionYear}
                          onSelect={this.onSelect}
                          stateKey="deductionYear"
                          staticData={years}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Amount Requested
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // disabled={true}
                          id="amountRequested"
                          value={this.state.amountRequested}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Deduction Starts From
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"deductionStartsFrom"}
                          defValue={this.state.deductionStartsFrom}
                          onSelect={this.onSelect}
                          stateKey="deductionStartsFrom"
                          staticData={months}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          No Of Applicable Months
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // disabled={true}
                          id="noOfApplicableMonths"
                          value={this.state.noOfApplicableMonths}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Deduction UpTo
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="deductionUpto"
                          value={this.state.deductionUpto}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          //iType={"number"}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks Of Applicant
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // disabled={true}
                          id="remarksOfApplicant"
                          value={this.state.remarksOfApplicant}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          multiline={true}
                          rowsMax={3}
                          height={50}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // disabled={true}
                          id="remarks"
                          value={this.state.remarks}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          multiline={true}
                          rowsMax={3}
                          height={50}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(
                          this.state.leavePolicieList.length + " length"
                        )}
                        <ReactTable
                          data={this.state.leavePolicieList}
                          filterable={false}
                          resizable={false}
                          loading={this.state.loading}
                          getTableProps={() => {
                            return {
                              style: {
                                border: "2px solid #00ABDC",
                                borderRadius: "4px",
                              },
                            };
                          }}
                          getTheadProps={() => {
                            return {
                              style: {
                                borderBottom: "2px solid #00ABDC",
                                //height:45
                                //lineHeight:"1.3"
                              },
                            };
                          }}
                          getTheadThProps={() => {
                            return {
                              style: {
                                borderRight: "1px solid #999999",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                                lineHeight: 1.3,
                              },
                            };
                          }}
                          style={{
                            color: "#00ABDC",
                            textAlign: "center",
                            overflowY: "auto",
                          }}
                          columns={[
                            {
                              Header: "Month",
                              accessor: "month",
                              minWidth: 160,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Year",
                              accessor: "year",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Amount",
                              accessor: "amount",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Deduction Status",
                              accessor: "deductionStatus",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "center" },
                            } /*,
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }*/,
                          ]}
                          showPagination={false}
                          pageSize={this.getInvoiceTablePageSize()}
                          //  defaultPageSize={5}
                          sortable={false}
                          getNoDataProps={() => {
                            return {
                              style: {
                                display: "none",
                              },
                            };
                          }}
                          className="-striped -highlight"
                        />
                      </ItemGrid>
                      {/*
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        {this.state.approveButton ?
                                                            <ItemGrid xs={12}>
                                                                <Button color={"info"} right={true}
                                                                        onClick={this.handleAddItem}>Add Item</Button>
                                                            </ItemGrid> : null}
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                        Item</Button>
                                                </ItemGrid>

                                            }
*/}
                    </GridContainer>
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {this.state.approveButton ? (
                          <ItemGrid>
                            <Button
                              color={"info"}
                              right={true}
                              onClick={() => {
                                this.onUpdateButtonClick(this.state.status);
                              }}
                            >
                              Approve
                            </Button>
                          </ItemGrid>
                        ) : null}

                        {this.state.status !== 3 && this.state.status !== 4 ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                this.onUpdateButtonClick("");
                              }}
                            >
                              Update
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.status === 3 &&
                        this.state.typeOfAdvance === "Issue Loan" ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                setCookie(cons.ADVANCE_ID, this.state.hdrId);
                                setCookie(cons.LEDGER_ID, this.state.ledgerId);
                                this.props.history.push(
                                  subMenus.ACCOUNT_PAYMENTS
                                );
                              }}
                            >
                              Process Payment
                            </Button>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {this.state.tableList !== null ? (
                        <TableComponent
                          //  url={serverApi.DATATABLE_SERVICE_URL+"/getAllIndent"}
                          //   searchData={this.getData()}
                          staticData={this.state.tableList}
                          isRefresh={this.state.isRefresh}
                          handleSearch={this.handleSeacrh.bind(this)}
                          // isLoading={this.state.isRefresh}
                          onActionClick={this.handleViewClick}
                          itemViewType={this.state.itemViewType}
                          fileName={"jute_indent_currentdate"}
                          actionType={VIEW}
                          showDelete={false}
                        />
                      ) : null}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AdvanceRequest)
);
