import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import ExpandMoreIcon from '@material-ui/icons/FileDownload';
import IconButton from "../CustomButtons/IconButton";
import Button from "../CustomButtons/Button";

class ExportToExcel extends React.Component {
    componentDidMount() {

    }
    render() {

        const {posts, fileName, sheetName, dataTable,btnName} = this.props;
        return (
            <div>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="export"
                    table="table-to-xls"
                    filename={fileName}
                    //  sheet={sheetName}
                    buttonText={
                        btnName!==undefined?<Button color={"primary"}>{btnName}</Button>: <IconButton
                            color="success">
                            <ExpandMoreIcon/>
                        </IconButton>
                    }
                />

                <table id="table-to-xls" style={{display: "none"}}>

                    <tr>
                        {
                            dataTable.column.map(post => {
                                    return (
                                        <th>{post.Header}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                    <tbody>

                    {
                        posts.map(post => {
                                return (
                                    <tr>
                                        {
                                            dataTable.column.map(prop => {
                                                    return (
                                                        <td>{post[prop.accessor]}</td>
                                                    )
                                                }
                                            )
                                        }
                                    </tr>
                                )
                            }
                        )
                    }
                    </tbody>
                    <tr>
                        {
                            dataTable.column.map(post => {
                                    return (
                                        <th>{post.Footer}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                </table>
            </div>
        );

    }
}


export default ExportToExcel;