export const validate = (fieldsList) => {
  let returnObj;

  for (var i = 0; i <= fieldsList.length - 1; i++) {
    console.log(fieldsList[i], i);

    if (fieldsList) {
      if (
        fieldsList[i].field === "" ||
        fieldsList[i].field === "0" ||
        fieldsList[i].field === 0 ||
        fieldsList[i].field === undefined ||
        fieldsList[i].field === null ||
        fieldsList[i].field.length === 0
      ) {
        console.log("getting the fieldList data", fieldsList);
        returnObj = {
          type: fieldsList[i].type,
          message: fieldsList[i].msg,
          fieldName: fieldsList[i].field,
        };

        break;
      }
    }
    // else {
    //   if (
    //     fieldsList[i].field === "" ||
    //     fieldsList[i].field === "0" ||
    //     fieldsList[i].field === 0 ||
    //     fieldsList[i].field === undefined ||
    //     fieldsList[i].field === null ||
    //     fieldsList[i].field.length === 0
    //   ) {
    //     returnObj = { error: true, message: fieldsList[i].msg };

    //     break;
    //   }
    // }
  }

  return returnObj;
};
