import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import LinearProgress from "material-ui/Progress/CircularProgress";

import customLinearProgressStyle
    from "../../assets/jss/material-dashboard-pro-react/components/customCircularProgress.js";

function CustomCircularProgress({...props}) {
    const {classes, color, ...rest} = props;
    return (
        <div style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: "0",
            left: "20%",
            right: "0",
            bottom: "0",
            margin: "auto",
            zIndex: 6000,
            backgroundColor: "rgba(0,0,0, 0.3)"
        }}>
        <LinearProgress
            {...rest}
            classes={{
                root: classes.root + " " + classes[color + "Background"]
            }}
        />
        </div>
    );
}

CustomCircularProgress.defaultProps = {
    color: "gray"
};

CustomCircularProgress.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "warning",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
        "primaryNoBackground"
    ])
};

export default withStyles(customLinearProgressStyle)(CustomCircularProgress);
