import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";

import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {
  getDataFromUrl,
  requestList,
  deleteDataFromUrl,
} from "../../server/server";
import { getCookie, removeCookies } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import LineItemTable from "../CustumComponents/LineItemTable";
import Close from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";

class UomMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "uomCode",
      uomDsc: "",
      uomCode: "",
      alternateuomDsc: "",
      invoiceList: [],
      itemsList: [],
      statesList: [],
      companyList: [],
      finalStatus: "N",
      itemCode: "",
      relation: "",
      itemCode: "",
      itemGroup: "",
      itemGroupId: "",
      itemId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (uomCode, id) => {
    var juteId = "";
    console.log("uomcode" + "  " + uomCode);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.UOM_MASTER_BY_ID + uomCode,
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp;
    var list = [];
    if (o !== null) {
      if (resp) {
        resp.forEach((prop, key) => {
          console.log("finding prop data", this.state.companyList);
          var obj = {
            id: key,
            relation: prop.relation != 0 ? prop.relation : null,
            relationValue: prop.relationValue !== 0 ? prop.relationValue : null,
            uomCode: prop.uomCode,
            uomDsc: prop.uomDsc,
            itemCode: prop.itemCode,
            relationToUom: prop.relationToUom,
            recordType: prop.recordType,
            actions: (
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.companyList;
                    alert("hello1");
                    data.find((o, i) => {
                      if (o.id === key) {
                        if (this.state.approveButton) {
                          this.setState({ deleteItem: true, id: o.id });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                        }
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };
        });
      }
      var resdata = resp[0];
      console.log("we are printing the most", resdata);
      // var branchArray = this.state.itemsList;
      // branchArray.map((t, i) => {
      //   o.mappedBranchIds.forEach((id) => {
      //     if (t.bId === id) {
      //       t.isEnabled = true;
      //     }
      //   });
      // });

      this.setState({
        updateView: true,
        isCreated: true,
        id: resdata.id,
        uomCode: resdata.uomCode,
        uomDsc: resdata.uomDsc,
        companyList: list,
      });

      console.log("getting the retion as id", this.state.uomCode);

      // var req = getDataFromUrl(
      //   serverApi.UOM_GET_RELATION_DATA +
      //     "/" +
      //     2 +
      //     "/" +
      //     "companyId" +
      //     "/" +
      //     this.props.state.companyInfo.id
      // );
      var req = getDataFromUrl(
        serverApi.UOM_GET_RELATION_BY_UOMCODE +
          this.state.uomCode +
          "/" +
          "companyId" +
          "/" +
          this.props.state.companyInfo.id +
          "/itemId" +
          "/0",

        this.handleData
      );

      this.setState({
        companyList: this.state.companyList,
      });

      console.log("Id has been printed", this.state.companyList);
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    // if (this.state.isCreated) {
    //   this.setState({
    //     itemGroupId: "",
    //     itemGroup: "",
    //     itemCode: "",
    //     itemId: "",
    //     relation: "",
    //   });
    // }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      itemGroupId: "",
      itemGroup: "",
      itemCode: "",
      itemId: "",
      relation: "",
      relationValue: "",
    });
  };
  getData = () => {
    var data = {
      companyID: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      recordType: 1,
    };
    return data;
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    console.log("printing........... " + value);
    if (type === "AN") {
      if (/[^0-9a-zA-Z\s]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    } else {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    if (selectedValue && stateKey === "itemGroup") {
      var data = {
        // itemGroupId: selectedValue,
        // saleable: "Y",
        // consumable: "",
        // companyId: this.props.state.companyInfo.id,

        // itemGroupId: this.state.itemGroupId,
        itemGroupId: selectedValue,
        saleable: "Y",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
        indentTypeId: "",
        tangible: "",
      };

      requestList(serverApi.GET_ITEMGROUP, data, this.handleItem);
      this.setState({
        loadItem: true,
      });
    }

    if (selectedValue && stateKey === "itemCode") {
      //console.log("selectedvalue showing", selectedValue);

      var req = getDataFromUrl(
        serverApi.UOM_GET_RELATION_BY_UOMCODE +
          this.state.uomCode +
          "/" +
          "companyId" +
          "/" +
          this.props.state.companyInfo.id +
          "/itemId/" +
          selectedValue,
        // ? selectedValue
        // : 0,

        this.handleData2
      );
      // this.state.companyList.map((d) => {
      //   console.log("selectedvalue showing", d);
      // });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    //this.handleCreate();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.companyList;
      if (this.state.updateView) data[this.state.id].activityFlag = 0;
      else data.splice(this.state.mid, 1);
      this.setState({
        companyList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,

      alert: false,
      showMessagealert: false,
      loading: false,
      uomCode: "",
      uomDsc: "",
      relation: "",
      relationValue: "",
    });
  }

  hideAlertMessage() {
    var list = this.state.itemsList.map((prop, i) => {
      prop.isEnabled = false;
      return prop;
    });
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      relation: null,
      relationByUom: "",
      relationTo: "",
      itemGroup: null,
      itemCode: null,
      itemId: null,
      itemGroupId: "",
      updateView: false,
      alert: false,
      basicAlert: false,
      id: null,
      uomDsc: "",
      uomCode: "",
      createdBy: null,
      createdOn: null,
      itemsList: list,
      invoiceList: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onClickAddItem(e) {
    //   // e.preventDefault();
    //   var arr = this.state.companyList;
    //   var sumOfQty = 0,
    //     quantityInItem = 0;
    //   var itemIndex = 0,
    //     quantity = 0,
    //     hasItem = false,
    //     indentItemId = 0;
    //   if (this.state.updateItem) {
    //     itemIndex = this.state.id;
    //   } else {
    //     itemIndex = this.state.companyList.length;
    //   }
    //   console.log("quantity " + quantity);
    //   var addItemObj = {
    //     id: itemIndex,
    //     indentItemId: indentItemId,
    //     juteType: this.state.selectedJuteType,
    //     relation: this.state.relation,
    //     itemCode: this.state.itemCode,
    //     relationValue: this.state.relationValue,
    //     actions: (
    //       <div className="actions-right">
    //         <IconButton
    //           onClick={() => {
    //             var data = this.state.companyList;
    //             data.find((o, i) => {
    //               if (o.id === addItemObj.id) {
    //                 // here you should add some custom code so you can delete the data
    //                 // from this component and from your server as well
    //                 this.basicItemAlert("Do You Like To Delete This Item?");
    //                 this.setState({ deleteItem: true, id: o.id });
    //                 //  data.splice(i,1);
    //                 return true;
    //               }
    //             });
    //             this.setState({
    //               selectedJuteType: "",
    //               selectedJuteName: "",
    //               selectedJuteQuality: "",
    //               selectedQualityName: "",
    //               quantity: "",
    //               selectedUnit: "",
    //               selectedDeptId: "",
    //               selectedGrpId: "",
    //               stock: "",
    //             });
    //           }}
    //           color="dangerNoBackground"
    //           customClass="remove"
    //         >
    //           <Close />
    //         </IconButton>{" "}
    //       </div>
    //     ),
    //   };
    //   if (!hasItem) {
    //     if (this.state.updateItem) {
    //       arr[itemIndex] = addItemObj;
    //     } else arr.splice(arr.length, 0, addItemObj);
    //     this.setState({
    //       loading: true,
    //       companyList: arr,
    //     });
    //     this.hideAlert();
    //   } else {
    //     this.basicAlert(
    //       "Item Already Saved For This Quality. Please Select Another One..!!"
    //     );
    //   }
  }

  onRelationClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      if (
        this.state.uomCode === "" ||
        this.state.uomCode === undefined
      ){
        this.basicAlert("Please Enter UOM Code")
      }
      else if (
        this.state.uomDsc === "" ||
        this.state.uomDsc === undefined
      ){
        this.basicAlert("Please Enter UOM Desc")
      }
      else if (
        this.state.relationValue === "" ||
        this.state.relationValue === undefined
      ){
        this.basicAlert("Please Enter Value")
      }
      else if(  
        this.state.relation === "" ||
         this.state.relation === undefined){
          this.basicAlert("Please Select Relation UOM")
         }
       else {
        if (this.state.itemCode === "" || this.state.itemCode === undefined) {
          var data = {
            uomCode: this.state.uomCode,
            uomDsc: this.state.uomDsc,
            companyID: this.props.state.companyInfo.id,
            recordType: 2,
            createdBy: getCookie(cons.USERID),
            relation: this.state.id,
            relationValue: this.state.relationValue,
            relationTo: this.state.relation,
            activityFlag: 1,
          };
        } else {
          var data = {
            uomCode: this.state.uomCode,
            uomDsc: this.state.uomDsc,
            companyID: this.props.state.companyInfo.id,
            recordType: 3,
            createdBy: getCookie(cons.USERID),
            relation: this.state.id,
            relationValue: this.state.relationValue,
            relationTo: this.state.relation,
            activityFlag: 1,
            itemId: this.state.itemCode,
            itemDesc: this.state.itemGroup,
          };
        }
        //console.log("getting data", data, this.handleUpdateRelation);
        requestList(
          serverApi.UOM_MASTER_UPDATE,
          data,
          this.handleUpdateRelation
        );
        console.log("getting data", data, this.handleUpdateRelation);
      }
    }
  };

  handleUpdateRelation = (prop) => {
    if (prop) {
      this.basicAlert("Relation Created successfully");
      console.log("getting values from here", prop);
      var arr = this.state.companyList;

      arr.push({
        rid: prop.id,
        id: prop.id,
        relation: prop.relationTo,
        relationValue: prop.relationValue,
        activityFlag: prop.activityFlag,
        actions:
          prop.activityFlag !== "0" ? (
            <IconButton
              onClick={() => {
                var data = this.state.companyList;

                data.find((o, i) => {
                  if (o.rid === i) {
                    this.setState({ deleteItem: true, id: o.id });
                    this.deleteUom(o.id);
                    return true;
                  }
                });
              }}
              color="black"
              customClass="remove"
            >
              <Close />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                var data = this.state.companyList;

                data.find((o, i) => {
                  if (o.rid === i) {
                    this.setState({ deleteItem: true, id: o.id });
                    this.deleteUom(o.id);
                    return true;
                  }
                });
              }}
              color="black"
              customClass="remove"
            >
              <Close />
            </IconButton>
          ),
      });
      console.log("getting values from here", arr);
    }
    this.setState({ companyList: arr });

    // if (this.state.updateItem) {
    //   itemIndex = this.state.id;
    // } else {
    //   itemIndex = this.state.companyList.length;
    // }
    //   console.log("quantity " + quantity);
    //   var addItemObj = {
    //     id: itemIndex,
    //     indentItemId: indentItemId,
    //     juteType: this.state.selectedJuteType,
    //     relation: this.state.relation,
    //     itemCode: this.state.itemCode,
    //     relationValue: this.state.relationValue,
    //     actions: (
    //       <div className="actions-right">
    //         <IconButton
    //           onClick={() => {
    //             var data = this.state.companyList;
    //             data.find((o, i) => {
    //               if (o.id === addItemObj.id) {
    //                 // here you should add some custom code so you can delete the data
    //                 // from this component and from your server as well
    //                 this.basicItemAlert("Do You Like To Delete This Item?");
    //                 this.setState({ deleteItem: true, id: o.id });
    //                 //  data.splice(i,1);
    //                 return true;
    //               }
    //             });
    //             this.setState({
    //               selectedJuteType: "",
    //               selectedJuteName: "",
    //               selectedJuteQuality: "",
    //               selectedQualityName: "",
    //               quantity: "",
    //               selectedUnit: "",
    //               selectedDeptId: "",
    //               selectedGrpId: "",
    //               stock: "",
    //             });
    //           }}
    //           color="dangerNoBackground"
    //           customClass="remove"
    //         >
    //           <Close />
    //         </IconButton>{" "}
    //       </div>
    //     ),
    //   };
    //   if (!hasItem) {
    //     if (this.state.updateItem) {
    //       arr[itemIndex] = addItemObj;
    //     } else arr.splice(arr.length, 0, addItemObj);
    //     this.setState({
    //       loading: true,
    //       companyList: arr,
    //     });
    //     this.hideAlert();
    //   } else {
    //     this.basicAlert(
    //       "Item Already Saved For This Quality. Please Select Another One..!!"
    //     );
    //   }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        uomCode: this.state.uomCode,
        uomDsc: this.state.uomDsc,
        companyID: this.props.state.companyInfo.id,
        createdBy: getCookie(cons.USERID),
        recordType: 1,
        relation: 0,
        relationValue: 0,
        relationTo: 0,
      };

      // if (
      //   this.state.companyId !== "" &&
      //   this.state.uomCode !== undefined &&
      //   this.state.uomDsc !== undefined &&
      //   this.state.uomCode !== "" &&
      //   this.state.uomDsc !== ""
      // ) {
      //   this.handleLoading();
      //   requestList(
      //     //add here uom api
      //     serverApi.UOM_MASTER_ADD,
      //     data,
      //     this.handleAddIndent
      //   );
      //   // alert("Created successfully");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      const {uomCode,uomDsc}=this.state

      const validateFields = [
        { field: uomCode, msg: "UOM Code", type: 1 },
        { field: uomDsc, msg: "UOM Description", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.UOM_MASTER_ADD,
          data,
          this.handleAddIndent
        );
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    console.log("getttt",msg,status);
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            console.log(status);
            if (status === true || status === false) {
              console.log(status);
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  componentDidMount() {
    getDataFromUrl(
      serverApi.UOM_GET_RELATION_DATA +
        "/" +
        1 +
        "/" +
        "companyId" +
        "/" +
        this.props.state.companyInfo.id,

      this.handleUOMData
    );
  }

  deleteUom(rid) {
    console.log("nmmmmmmmm", rid);

    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      this.handleLoading();
      deleteDataFromUrl(serverApi.UOM_DELETE_DATA + rid, this.handleAddIndent);
    }
  }

  handleData = (resp) => {
    console.log("geting uom relation data...........", resp);
    var companyList = [];
    if (resp) {
      resp.forEach((prop, key) => {
        companyList.push({
          rid: key,
          id: prop.id,
          relation: prop.relationToUom,
          relationByUom: prop.uomDsc,
          relationValue: prop.relationValue,
          activityFlag: prop.activityFlag,
          itemDesc: prop.itemDesc,
          itemCode: prop.itemId,
          recordType: prop.recordType,
          actions:
            prop.activityFlag !== "0" ? (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="black"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ),
        });
      });

      var displayRecordTypeTwo = companyList.filter(
        (data) => data.recordType < 3
      );
      this.setState({ companyList: displayRecordTypeTwo });
      console.log("Displaying companyList");
    }
  };

  handleData2 = (resp) => {
    console.log("geting uom relation data...........", resp);
    var companyList = [];
    if (resp) {
      resp.forEach((prop, key) => {
        companyList.push({
          rid: key,
          id: prop.id,
          relation: prop.relationToUom,
          relationByUom: prop.uomDsc,
          relationValue: prop.relationValue,
          activityFlag: prop.activityFlag,
          itemDesc: prop.itemDesc,
          itemCode: prop.itemId,
          recordType: prop.recordType,
          actions:
            prop.activityFlag !== "0" ? (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="black"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  var data = this.state.companyList;

                  data.find((o, i) => {
                    if (o.rid === key) {
                      this.setState({ deleteItem: true, id: o.id });
                      this.deleteUom(o.id);
                      return true;
                    }
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            ),
        });
      });

      this.setState({ companyList: companyList });
      console.log("Displaying companyList data", companyList);
    }
  };

  handleCompanies = (resp) => {
    console.log("printing for data...........", resp);
    var companyList = [];
    if (resp) {
      resp.forEach((prop) => {
        companyList.push({
          label: prop.uomCode,
          value: prop.id,
          name: prop.uomCode,
        });
      });
      this.setState({ companyList: companyList });
    }
  };

  handleUOMData = (resp) => {
    var statesList = [];
    if (resp) {
      resp.forEach((prop) => {
        statesList.push({
          label: prop.uomCode,
          value: prop.id,
          name: prop.uomCode,
        });
      });
      this.setState({ statesList: statesList });
    }
  };

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer />
                          </ItemGrid>
                        </GridContainer>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Uom Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="uomCode"
                          defValue={this.state.uomCode}
                          value={this.state.uomCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Uom Desc
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="uomDsc"
                          value={this.state.uomDsc}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Value
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="relationValue"
                              value={this.state.relationValue}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Relation Uom
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              onSelect={this.onSelect}
                              defValue={this.state.relation}
                              stateKey="relation"
                              staticData={this.state.statesList}
                              value={this.state.relation}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Item Group
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={
                                this.state.itemGroup === ""
                                  ? null
                                  : this.state.itemGroup
                              }
                              id={"itemGroup"}
                              onSelect={this.onSelect}
                              getName={(name) => {
                                this.setState({ itemGroupName: name });
                              }}
                              isRefresh={this.state.loadItemGroup}
                              stateKey="itemGroup"
                              setIsRefresh={this.afterRefreshItemGrp}
                              url={
                                serverApi.SEARCH_ITEM_GROUPS +
                                this.props.state.companyInfo.id +
                                "/" +
                                getCookie(cons.CIPHER)
                              }
                              value={this.state.itemGroup}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={6} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Item
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.itemCode}
                              staticData={this.state.ItemOptions}
                              id={"itemCode"}
                              onSelect={this.onSelect}
                              getName={this.getitemDetails}
                              isRefresh={this.state.loadItem}
                              stateKey="itemCode"
                              setIsRefresh={(val) => {
                                this.setState({ loadItem: val });
                              }}
                              value={this.state.itemCode}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            //onClick={this.onRelationClick}
                            onClick={() => {
                              this.onRelationClick();
                              // this.onClickAddItem();
                            }}
                          >
                            Save Relation
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                      <br />
                    </ItemGrid>
                  ) : null}

                  {/* {this.state.itemCode ? (
                    <>
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.companyList}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: "Item Desc",
                              accessor: "itemDesc",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation",
                              accessor: "relation",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation Value",
                              accessor: "relationValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                            },
                          ]}
                        />
                      </ItemGrid>
                    </>
                  ) : this.state.updateView ? (
                    <>
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.companyList}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: "Relation",
                              accessor: "relation",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation Value",
                              accessor: "relationValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                            },
                          ]}
                        />
                      </ItemGrid>
                    </>
                  ) : null} */}
                  {this.state.updateView ? (
                    <>
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.state.companyList}
                          loading={this.state.loading}
                          columns={[
                            {
                              Header: "Item Desc",
                              accessor: "itemDesc",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation",
                              accessor: "relation",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Relation Value",
                              accessor: "relationValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },

                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                            },
                          ]}
                        />
                      </ItemGrid>
                    </>
                  ) : null}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.UOM_MASTER_TABLE_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(UomMaster)
);
