import {createStore, applyMiddleware, compose} from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers/root-reducer';
const initialState = {};
export const createdStore = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(ReduxThunk))
);; 
export default function configureStore() {
  return createdStore;
};