import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import TableComponent from "../CustumComponents/TableComponent";
import Print from "@material-ui/icons/Print";

import PaginationTableComponent from "../CustumComponents/PaginationTableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
  getTenantId,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import LineItemTable from "../CustumComponents/LineItemTable";
import { GoodTypes, TCSPercentages } from "../TableData";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import LogsComponents from "../CustumComponents/LogsComponents";
import { connect } from "react-redux";

class StoreReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      // fromDate:
      //   getCookie(cons.YEAR_FROM_DATE) && getCookie(cons.YEAR_FROM_DATE) != null
      //     ? getCookie(cons.YEAR_FROM_DATE)
      //     : "",
      // todate:
      //   getCookie(cons.YEAR_TO_DATE) && getCookie(cons.YEAR_TO_DATE) != null
      //     ? getCookie(cons.YEAR_TO_DATE)
      //     : "",
      fromDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      mrNo: "",
      searchMrNo: "",
      vehicleNo: "",
      poNO: "",
      status: "",
      srId: "",
      search_srNo: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      srDate: moment().format("DD-MM-YYYY"),
      form_supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100"
          ? getCookie(cons.SUPP_CODE)
          : null,
      gateEntryNo: "",
      form_poNO: "",
      poDate: "",
      challanNo: "",
      challanDate: "",
      contractNo: "",
      contractDate: "",
      uom: "",
      agentId: "",
      form_vehicleNo: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      approveStatus: false,
      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",
      taxType: "",
      igstPercent: 0,
      sgstPercent: 0,
      cgstPercent: 0,
      indentNo: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      loadGateEntry: false,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      alert: false,
      //warehouseNo: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      materialHdrId: "0",
      lineItemId: "0",
      taxPercentage: "",
      srStatus: "",
      remarks: "",
      branchId: "",
      projectId: 0,
      projectIds: "",
      branchIds: "",
      internalNote: "",
      poNos: "",
      stprePrintNo: "",
      isgst: 0,
      csgst: 0,
      ssgst: 0,
      itemTaxAmt: 0,
      taxPercent: 0,
      goodType: "GRN",
      item: "",
      filesList: [],
      dataTable: null,
      tcsAmount: 0,
      tcsPercentage: "",
      roundoff: null,
      warehouseNo: "",
      // wareHouseNo: 0,
      dataTable: "",
      startIndex: 0,
      pageSize: 10,
      lastIndex: 10,
      storeId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    this.getData(this.state.startIndex, this.state.lastIndex);
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    if (getCookie(cons.USER_ROLE_ID) !== "100")
      getDataFromUrl(
        serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id,
        this.handleSupplier
      );
    // this.getList();
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments,
      this.handlePrintResp
    );
    var data2 = {
      companyId: this.props.state.companyInfo.id,
      type: "S",
      branchId: this.state.branchId,
    };
    requestList(serverApi.SELECT_GODOWN_LIST, data2, this.handleGoDown);
  }
  handleGoDown = (resp) => {
    var list = [];
    resp.map((prop, ind) => {
      list.push({
        value: prop.value,
        name: prop.name,
        label: prop.name,
      });
    });
    this.setState({
      wareHosueList: list,
    });
    console.log("getting data value", this.state.wareHosueList);
  };
  handleDepartments = (resp) => {
    this.setState({ budgetHeadList: resp, loadDepartment: true });
    console.log("response of budget-heads", resp);
  };
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      this.setState({
        projectNameList: projectNameList,
      });
    }
  };
  handleSupplier = (resp) => {
    if (resp.status === undefined) {
      this.setState({ supplierList: resp, loadSupplier: true });
    }
  };
  getList = () => {
    var data = this.getData(this.state.startIndex, this.state.lastIndex);
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getAllStoreGoodReceiveDetails",
      data,
      this.handleList
    );
  };
  handleList = (resp) => {
    var list = resp;
    var data = list.data;
    if (getCookie(cons.USER_ROLE_ID) === "100") {
      data = [];
      resp.data.forEach((prop) => {
        if (prop.status === "APPROVED" || prop.status === "REJECTED")
          data.push(prop);
      });
      list.data = data;
    }
    if (resp) {
      this.setState({
        dataTable: list,
        isRefresh: true,
      });
    }
  };

  // handleSeacrh = () => {
  //   var loading = false;

  //   this.setState({
  //     isRefresh: !this.state.isRefresh,
  //   });
  // };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getData(this.state.startIndex, this.state.lastIndex);

      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.INWARD_FETCH_BY_ID + id + "/" + getCookie(cons.USERID),
        this.handleViewClickResp
      );
      this.setState({
        storeId: id,
      });
    }
  };
  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    console.log("Nagesh StoreRecepit", resp);
    var header = resp;
    console.log("getting projectId details", header.projectId);
    var list = resp.inwardDetails;
    var indentlist = [],
      poNo = [],
      indentNo = [];
    list.forEach((prop, key) => {
      // if (poNo.indexOf(prop.poHdrid) <= -1 && prop.poHdrid) {
      //     poNo.push(prop.poHdrid);
      //     getDataFromUrl(serverApi.GET_FILE_BY_SOURCE + "14/" + prop.poHdrid + "/" + this.props.state.companyInfo.id, this.handleInvoiceFiles);

      // }
      // if (indentNo.indexOf(prop.indentHdrid) <= -1 && prop.indentHdrid) {
      //     indentNo.push(prop.indentHdrid);
      //     getDataFromUrl(serverApi.GET_FILE_BY_SOURCE + "10/" + prop.indentHdrid + "/" + this.props.state.companyInfo.id, this.handleInvoiceFiles);

      // }
      var actualQty = prop.inwardQty;

      if (prop.poRcdQty && prop.poQty)
        actualQty = prop.poQty - prop.poRcdQty + prop.inwardQty;

      var obj = {
        id: key,
        lineId: prop.id,
        department: prop.departmentName,
        itemGroup: prop.itemGroup,
        item: prop.itemName,
        quantity: prop.approvedQty,
        poQuantity: parseFloat(prop.poQuantity) + parseFloat(prop.inwardQty),
        uomCode: prop.uom,

        //stockValue: prop.stock,
        deptId: parseInt(prop.departmentId),
        itemGroupId: prop.itemGroupId,
        itemId: prop.itemId + "",
        itemGrp: prop.itemGroupId + "" + prop.itemCode,
        poNo: prop.poId,
        poId: prop.poId,
        poSequence: prop.poSequence,
        rate: prop.rate,
        inwardId: prop.inwardId,
        poLineItemId: prop.poLineItemId,
        isActive: prop.isActive ? prop.isActive : 1,
        costDesc: prop.costDesc,
        costFactor: prop.costFactor,
        description: prop.description,
        budgetHeadId: prop.budgetHeadId + "",
        budgetHeadName: prop.budgetHeadName,
        oldQty: prop.oldQty,
        isActive: prop.isActive,
        poQty: actualQty,
        igstPercentage: prop.igstPercentage,
        cgstPercentage: prop.cgstPercentage,
        sgstPercentage: prop.sgstPercentage,
        hsnCode: prop.hsnCode === "null" ? "" : prop.hsnCode,
        taxPercentage: prop.taxPercentage,
        taxId: prop.tax,
        discountPercentage: prop.discount,
        discountMode: prop.discountMode,
        //warehouseNo: 18,
        warehouseNo: prop.warehouseno,
        make: prop.make,
        itemId: prop.itemId,
        approvedQty: prop.approvedQty,
      };
      //  if(!prop.deleteRec)
      indentlist.push(obj);
    });

    if (header.poNo !== "") var poNo = header.poNo;

    if (header !== null) {
      this.setState({
        updateItem: false,
        updateView: true,
        isCreated: true,
        isDisabled: true,
        status: header.status,
        approveButton: header.approveButton,
        srApproveButton: header.srApproveButton,
        entryId: header.id,
        challanNo: header.challanno,
        challanDate: header.challannoDate,
        form_supplierId: header.supplierId + "",
        selectedSupplier: header.suppCode,
        supplierName: header.supplierName,
        selectedSupplier: header.supplierId + "",
        remarks: header.remarks,
        selectedPO: poNo,
        poDates:
          header.podatedescription !== "" ? header.podatedescription : [],
        createdBy: header.createdBy,
        invoiceList: indentlist,
        consignmentNo: header.consignmentNo,
        consignmentDate: header.consignmentDateDesc,
        branchId: header.branchId,
        inspectionChk: header.inspectionChk,
        projectId: header.projectId,
        internalNote: header.internalNote,
        inwardSequenceNo: header.inwardSequenceNo,
        goodType: "GRN",
        consignmentNumber: header.consignmentNumber,
        consignmentDate: header.consignmentDate,
        vehicleNumber: header.vehicleNumber,
        driverContactNumber: header.driverContactNumber,
        remarks: header.remarks,
        inwardStatus: header.status,
        taxType: this.getDefaultTaxConvertion(header.taxType),
        taxTypeDesc: header.taxTypeDesc,
        invoiceNumber: header.invoiceNumber,
        invoiceDate: header.invoiceDate,
        ewaybillno: header.ewaybillno,
        ewaybillnoDate: header.ewaybillnoDate,
        phaseId: header.phaseId,
        stprePrintNo: header.storeReceiptNo,
        gateEntryNo: header.inwardSequenceNo,
        form_vehicleNo: header.vehicleNumber,
        srStatus: header.srStatusId,
        status: header.status,
        tcsPercentage: header.tcsPercentage,
        // creditnoteValue: header.creditnoteValue,
        storeApprovedBy: header.storeApprovedBy,
        roundoff: header.roundOffValue,
      });
      console.log("tax type", this.state.taxType);
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "18/" +
          header.id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleInvoiceFiles
      );
    }
  };

  getDefaultTaxConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name == 1) {
        val = 1;
        label = "IGST";
      } else if (name == 2) {
        val = 2;
        label = "CGST & SGST";
      } else {
        val = 3;
        label = "VAT";
      }
      data = {
        value: val,
        label: label,
      };
    }
    console.log("sss", data);
    return data;
  };

  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getAgentName = (name) => {
    this.setState({
      agentName: name,
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemDesc: values[0],
      itemGroupId: values[1],
      uom: values[2],
      hsnCode: values[4],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      loadquality: val,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      tcsAmount: 0,
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  // getData = () => {
  //   var data = {
  //     fromDate: this.state.fromDate,
  //     toDate: this.state.todate,
  //     supplierId: this.state.supplierId,
  //     companyId: this.props.state.companyInfo.id,
  //     cipher: getCookie(cons.CIPHER),
  //     financialYear: getCookie(cons.ACADEMIC_YEAR),
  //     erpType: getConfigValue(configNames.LABELS_TYPE),
  //     branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
  //     projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
  //     recordType: "SR",
  //   };
  //   return data;
  // };
  getData = (startIndex, lastIndex) => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      supplierId: this.state.supplierId,
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
      recordType: "SR",
      srNum: this.state.search_srNo,
      poNum: this.state.poNos,
      status: this.state.status,
      lastIndex: this.state.lastIndex ? this.state.lastIndex : lastIndex,
      startIndex: this.state.startIndex ? this.state.startIndex : startIndex,
      // inwardNum: this.state.search_srNo,
    };
    requestList(serverApi.GET_ALL_INWARDS, data, this.handleListStoreReceipt);
  };

  handleListStoreReceipt = (resp) => {
    this.setState({
      dataTable: resp,
      isRefresh: true,
    });

    // this.handleLoading();
  };

  handlePage = (start, lastIndex) => {
    // console.log("Nagesh handlepage", start, pageSize);
    this.getData(start, lastIndex);
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };
  dateChanged = (date) => {
    if (date !== null) {
      var myDate = date;
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    if (event.target.id === "vehicleQuantity") {
      console.log("val " + value);
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (event.target.id === "stock") {
      console.log("val " + this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }
    if (event.target.id === "quantity" || event.target.id === "rate") {
      var value = event.target.value;
      if (event.target.id === "rate") {
        var Calrate = this.state.quantity;
      } else {
        var Calrate = this.state.rate;
      }
      if (Calrate !== "" && value != "") {
        var calAmount = parseFloat(Calrate) * parseFloat(value);
        calAmount = calAmount.toFixed(2);
        this.setState({
          amount: calAmount,
          itemTaxAmt:
            this.state.taxPercent > 0
              ? (calAmount * this.state.taxPercent) / 100
              : this.state.itemTaxAmt,
        });
      } else {
        this.setState({
          amount: "",
        });
      }
    }

    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "suppielr_id") {
      this.setState({
        loadGateEntry: true,
      });
    }
    if (stateKey === "projectIds") {
      console.log("getting the selected value for projectId", selectedValue);
      this.setState({
        projectIds: selectedValue,
      });
    }
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
      });
    }
    if (stateKey === "branchIds") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "warehouseNo") {
      var list = this.state.invoiceList;
      // console.log("getting warehouse no", list);
      list[0].warehouseNo = selectedValue;
      this.setState({ invoiceList: list });
      // this.setState({ warehouseNo: selectedValue });
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
      };

      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
    }
    if (stateKey === "tcsPercentage") {
      var total =
        parseFloat(this.getTotalTax("cgst")) +
        parseFloat(this.getTotalTax("sgst")) +
        parseFloat(this.getTotalTax("igst")) +
        parseFloat(this.getTotalTax("totalAmount"));
      var tcsAmount = (parseFloat(total) * selectedValue) / 100;
      console.log(
        "tcs amount",
        parseFloat(this.getTotalTax("cgst")) +
          " cgst " +
          parseFloat(this.getTotalTax("sgst")) +
          " sgst " +
          parseFloat(this.getTotalTax("igst")) +
          " igst " +
          parseFloat(this.getTotalTax("totalAmount")) +
          "selected value" +
          selectedValue
      );
      this.setState({
        tcsAmount: Math.round(tcsAmount).toFixed(2),
        //tcsAmount: parseFloat(tcsAmount).toFixed(),
      });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      deptName: selectedValue,
    });
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue,
        amount = 0;
      values = values.split("^");
      if (this.state.quantity !== "")
        amount = parseInt(values[3]) * parseInt(this.state.quantity);

      this.setState({
        itemName: values[0],
        // selectedItemId:values[1],
        uom: values[1],
        stock: values[2],
        hsnCode: values[4],
      });

      if (/*this.state.itemDesc!==values[0]&&*/ !this.state.updateItem) {
        this.setState({
          loadTax: true,
          rate:
            /*this.state.itemName===values[0]&& this.state.updateItem?this.state.rate:*/ values[3],
          taxId: /*this.state.itemDesc===values[0]&&this.state.updateItem?this.state.taxId:*/ parseInt(
            values[5]
          ),
          amount: /*this.state.itemDesc===values[0]&&this.state.updateItem?this.state.amount:*/ amount,
        });
      }

      /*  if(this.state.itemName===values[0]&& this.state.updateItem){
                  this.setState({
                      rate:this.state.rate
                  })
              }else {
                  this.setState({
                      rate:values[3]
                  })
              }*/
      /*   var SlectedTaxType=this.state.taxType.value;

              var taxPercent=values[4];

               if(SlectedTaxType===1){
                   this.setState({
                       igstPercent: taxPercent,
                       sgstPercent: 0,
                       cgstPercent: 0,
                   });
               } else{
                   taxPercent=taxPercent/2;

                   this.setState({
                       sgstPercent: taxPercent,
                       cgstPercent: taxPercent,
                       igstPercent:0,
                   });
               }*/
    }
  };

  afterRefreshGateEntry = (val) => {
    this.setState({
      loadGateEntry: val,
    });
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      if (this.state.goodType === "SRN")
        this.setState({
          costAlert: true,
          updateItemAlert: false,
        });
      else {
        this.setState({
          alert: true,
          updateItemAlert: false,
        });
      }
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      // var netTotal = this.state.netTotal - data[this.state.id].amount - data[this.state.id].taxamount;

      var totalAmount = 0;
      var totalTax = 0;
      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        } else {
          if (o.isActive === 1) {
            if (o.taxamount > 0) totalTax = totalTax + parseFloat(o.taxamount);
            totalAmount = totalAmount + parseFloat(o.amount);
          }
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        totalAmount: totalAmount.toFixed(2),
        totalTax: totalTax,
        netTotal: (parseFloat(totalAmount) + parseFloat(totalTax)).toFixed(2),
      });

      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
      this.hideItemAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideItemAlert() {
    console.log("alert");
    this.setState({
      //   alert: null,
      id: 0,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
    });
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      warehouseNo: "",
      rate: "",
      amount: "",
      uom: "",
      item: "",
      itemId: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      taxId: "",
      itemGroupId: "",
      stock: "",
      actions: "",
      isgst: 0,
      csgst: 0,
      ssgst: 0,
      itemTaxAmt: 0,
      taxPercent: 0,
      purchaseType: "",
      lineItemId: "",
      remarks: "",
      wareName: "",
      printingPop: false,
      igstPercent: 0,
      sgstPercent: 0,
      cgstPercent: 0,
      remarks1: "",
      costDesc: "",
      costFactor: "",
      description: "",
      costAlert: false,
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      // this.getList();
    }
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      lineItemId: "",
      materialHdrId: "",
      srStatus: "",
      consignmentNumber: "",
      consignmentDate: "",
      remarks: "",
      branchId: "",
      internalNote: "",
      stprePrintNo: "",
      goodType: "GRN",
      item: "",
      filesList: [],
      roundoff: null,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
    });
  }

  getDefaultSideConvertion = (name) => {
    var val = 0,
      label = "Select..",
      data = "";
    if (name !== null && name !== 0 && name !== "") {
      if (name === 1) {
        val = 1;
        label = "IGST";
      } else {
        val = 2;
        label = "CGST & SGST";
      }

      // else {
      //     val = 3;
      //     label = 'VAT';
      // }

      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.lineItemId,
        storeGrnHdrId: prop.storeGrnHdrId,
        itemId: prop.itemId,
        additionalRequirements: "Not required",
        advisedQuantity: "",
        actualQuantity: prop.quantity,
        deviation: "",
        orderedPrice: "",
        receivedPrice: "",
        remarks: "",
        receiveDate: "",
        storeId: prop.storeId,
        floor: "",
        room: "",
        rack: "",
        position: "",
        expiryDate: "",
        batchno: "",
        status: "1",
        uomCode: prop.uom,
        debitNotesFlag: "",
        drNote: "",
        drDate: "",
        crNote: "",
        crDate: "",
        taxId: "",
        taxPercentage: prop.taxPercent,
        cTax: prop.csgst,
        sTax: prop.ssgst,
        iTax: prop.isgst,
        amountWithTax: null,
        amountWithOutTax: prop.amount,
        warehouseNo: prop.warehouseno,
        rate: prop.rate,
      };
    });

    var data = {
      storeGoodReceiveHeader: {
        id: this.state.srNo,
        goodReceiptDate: this.dateChanged(this.state.srDate),
        supplierId: this.state.form_supplierId,
        poId: this.state.form_poNO,
        poDate: this.dateChanged(this.state.poDate),
        chalanNo: this.state.challanNo,
        chalanDate: this.dateChanged(this.state.challanDate),
        storeNo: "1",
        status: "4",
        submitter: getCookie(cons.USERID),
        issuedTo: "21",
        vehicleNo: this.state.form_vehicleNo,
        taxType: this.state.taxType,
        consignmentNumber: this.state.consignmentNumber,
        consignmentDate: this.dateChanged(this.state.consignmentDate),
        userId: getCookie(cons.USERID),
        remarks: this.state.remarks,
        internalNote: this.state.internalNote,
      },
      storeGrnItemList: invoiceList,
      cipher: getCookie(cons.CIPHER),
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_STORE_SR, data, this.handleAddIndent);
  };

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var hasWareHouseNo = false;
      var validate = false;
      var createStatus = false;
      var storeNo = [];
      if (status === 1) {
        createStatus = true;
      }
      console.log("nagesh data r", this.state.invoiceList);
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        var desc = "";
        console.log(
          "getting prop here from warehouse after isActive zero",
          prop.warehouseNo
        );
        if (prop.isActive !== 0) {
          if (prop.warehouseNo !== 0 && prop.warehouseNo !== null) {
          } else {
            validate = true;
          }
        }

        if (
          prop.description === "Printing Charges" ||
          prop.description === "Labour Charges" ||
          prop.description === "Transport Charges" ||
          prop.description === "Other Charges" ||
          prop.description === "Delivery Charges" ||
          prop.description === "Packing & Forwarding Charges"
        ) {
          hasWareHouseNo = false;
          desc = prop.description;
        }

        if (this.state.goodType === "SRN") hasWareHouseNo = false;

        var amount = parseFloat(prop.amount);
        if (prop.taxamount > 0 && prop.taxPercent > 0)
          amount = parseFloat(prop.amount) + parseFloat(prop.taxamount);

        var whouseNo = 0;
        if (
          prop.warehouseNo === "" ||
          prop.warehouseNo === 0 ||
          prop.warehouseNo === undefined
        ) {
          whouseNo = null;
          this.setState({
            warehouseNo: whouseNo,
          });
          //this.basicAlert("Please Enter !!");
        } else {
          whouseNo = prop.warehouseNo;
          this.state.warehouseNo = whouseNo;
        }

        return {
          id: prop.lineId,
          inwardId: prop.inwardId,
          hsnCode: prop.hsnCode,
          warehouseno: whouseNo,
          uom: prop.customSelectStyle,
          status: 3,
          isActive: 1,
          budgetHeadId: prop.budgetHeadId,
          itemId: prop.itemId,
          approvedQty: prop.approvedQty,
          discount: prop.discountPercentage,
          discountMode: prop.discountMode,
        };
      });
      console.log("getting headers data", this.state.selectedProject);
      var data = {
        id: this.state.entryId,
        // goodReceiptDate: this.dateChanged(this.state.srDate),
        // supplierId: this.state.form_supplierId,
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        isCommited: false,
        remarks: this.state.remarks,
        // srStatusId: this.state.isApprove ? this.state.srStatus : "",
        srStatusId: this.state.isApprove
          ? this.state.srStatus
          : this.state.isReject
          ? "4"
          : "",

        inspectionChk: "Y",
        //billpassStatusId: 1,
        tcsPercentage: this.state.tcsPercentage,
        branchId: this.state.branchId,
        status: this.state.isApprove ? this.state.status : "",
        // status: this.state.isApprove
        //   ? this.state.status
        //   : this.state.isReject
        //   ? "4"
        //   : "",
        recordType: "GRN",
        projectId: this.state.projectId,
        lastModifiedBy: getCookie(cons.USERID),
        inwardDetails: invoiceList,
        roundOffValue: this.state.roundoff,
      };

      // if (!hasWareHouseNo) {
      if (
        this.state.srDate !== "" &&
        this.state.form_supplierId !== ""
        //this.state.gateEntryNo !== "" &&
        //this.state.taxType > 0 &&
        //this.state.invoiceList.length >= 1
      ) {
        var wrhse = this.state.warehouseNo;
        console.log("update store receipt hello", validate);

        // if (wrhse === 0 || wrhse === null)
        if (validate) {
          this.basicAlert("Please Enter Store No!!!");
        } else {
          this.handleLoading();
          //this.basicAlert("updated");
          console.log("update store receipt", this.state.warehouseNo);
          requestList(
            serverApi.UPDATE_STORE_RECEIPT,
            data,
            this.handleAddIndent
          );
          console.log("updated Successfully", data);
          console.log("the branch id", this.state.branchId);
        }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
      // } else {
      // this.basicAlert("Please Enter Warehouse No. For All Line Items..!!");
      // }
    }
  };

  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
      this.getData(this.state.startIndex, this.state.lastIndex);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    console.log("itemName ", this.state.itemName);
    var itemIndex = 0,
      sum_of_tax = 0,
      sumOfamt = 0,
      hasItem = false;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      arr[itemIndex].taxamount = parseFloat(this.state.itemTaxAmt);
      arr[itemIndex].amount = parseFloat(this.state.amount);
    } else {
      itemIndex = this.state.invoiceList.length;
      if (this.state.itemTaxAmt > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
      if (parseFloat(this.state.amount) > 0)
        sumOfamt = sumOfamt + parseFloat(this.state.amount);
    }
    arr.map((q) => {
      // sumOfQty = sumOfQty+parseInt(q.quantityType);
      sum_of_tax = sum_of_tax + parseFloat(q.taxamount);
      sumOfamt = sumOfamt + parseFloat(q.amount);
      if (
        (this.state.selectedDepartment === q.depId) &
          (this.state.selectedItemGroup === q.itemGroupId) &&
        this.state.item === q.itemId &&
        !this.state.updateItem
      )
        hasItem = true;
    });

    var addItemObj = {
      id: itemIndex,
      itemId: this.state.item,
      itemDesc: this.state.itemName,
      // unitConvertion:"",
      quantity: this.state.quantity,
      qualityCode: this.state.selectedJuteQuality,
      quality: this.state.selectedJuteQuality,
      unit: this.state.uom,
      weight: this.state.weight,
      uom: this.state.uom,
      warehouseno: this.state.warehouseNo,
      rate: this.state.rate,
      amount: this.state.amount,
      itemGroupId: this.state.selectedItemGroup,
      itemGrp: this.state.selectedItemGroup + "" + this.state.item,
      depId: this.state.depId,
      lineItemId: this.state.lineItemId,
      materialHdrId: this.state.materialHdrId,
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxid: this.state.taxId,
      taxPercent: this.state.taxPercent,
      taxamount: this.state.itemTaxAmt,
      poNo: this.state.poNo,
      wareName: this.state.wareName,
      remarks: this.state.remarks1,
      costDesc: this.state.costDesc,
      costFactor: this.state.costFactor,
      description: this.state.description,
      isActive: 1,
      budgetHeadName: this.state.budgetHeadName,
      budgetHeadId: this.state.budgetHeadId,
      debitQuantity: this.state.debitQuantity,
    };
    if (this.state.printingPop) {
      if (this.state.amount !== "" && this.state.amount > 0) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        this.setState({
          hideJuteType: false,
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: sumOfamt.toFixed(2),
          netTotal: (parseFloat(sumOfamt) + parseFloat(sum_of_tax)).toFixed(2),
          updateItem: false,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      if (this.state.goodType === "SRN") {
        if (
          this.state.quantity !== "" &&
          this.state.rate !== "" &&
          this.state.uom !== ""
        ) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
            totalTax: sum_of_tax.toFixed(2),
            totalAmount: sumOfamt.toFixed(2),
            netTotal: (parseFloat(sumOfamt) + parseFloat(sum_of_tax)).toFixed(
              2
            ),
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        if (
          this.state.item !== "" &&
          this.state.quantity !== "" &&
          this.state.warehouseNo !== "" &&
          this.state.amount !== "" &&
          this.state.taxId !== "" &&
          this.state.taxId !== 0
        ) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            hideJuteType: false,
            loading: true,
            invoiceList: arr,
            totalTax: sum_of_tax.toFixed(2),
            totalAmount: sumOfamt.toFixed(2),
            netTotal: (parseFloat(sumOfamt) + parseFloat(sum_of_tax)).toFixed(
              2
            ),
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      }
    }
  }

  handleSelectedUnitValue = (defValue) => {
    /*   if (this.state.invoiceList.length > 0&&this.state.taxType!=='' && this.state.taxType !== defValue ) {
               this.unitConvertionAlert("Changing of Tax Type will remove all the line items. Click yes if you want to continue..!!")
           } else*/
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue.value;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercent;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else if (SlectedTaxType === 2) {
        var taxPercent = prop.taxPercent / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      } else {
        prop.isgst = 0;
        prop.ssgst = 0;
        prop.csgst = 0;
      }
      /*  var itemAmount =parseFloat(prop.amount);

              var itemtaxAmount =itemAmount * parseFloat(prop.taxPercent) / 100;
              itemtaxAmount = Math.round(itemtaxAmount);
              prop.taxamount=itemtaxAmount;
              sum_of_tax=sum_of_tax+itemtaxAmount;
              sumOfamt=sumOfamt+itemAmount*/
    });
    this.setState({
      invoiceList: data,
      //unitConversion: defValue,
      taxType: defValue,
    });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    console.log(this.state);
    if (this.state.taxType > 0) {
      this.setState({
        loading: true,
        alert: true,
        updateItem: false,
      });
    } else {
      this.basicAlert("Please Select Tax Type..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion !== defValue
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
    //console.log(`Option selected:`, this.state.unitConvertionName);
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.state.amount;
    if (taxPercent !== "" && itemAmount !== "") {
      var itemtaxAmount =
        (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
      //  itemtaxAmount = Math.round(itemtaxAmount);
      this.setState({
        itemTaxAmt: itemtaxAmount.toFixed(2),
        taxPercent: taxPercent,
      });
    }
    var SlectedTaxType = this.state.taxType.value;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercent: taxPercent,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else if (SlectedTaxType === 2) {
      taxPercent = taxPercent / 2;

      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    }
  };

  afterRefreshTax = (val) => {
    this.setState({
      loadTax: val,
    });
  };

  //   getTotalTax(tax) {
  //     var totSgst = 0,
  //       totIgst = 0,
  //       totcgst = 0;
  //     var data = this.state.invoiceList;
  //     data.forEach((prop, key) => {
  //       if (prop.isActive === 1) {
  //         console.log("itemTaxAmt", prop.itemTaxAmt);
  //         if (prop.ssgst)
  //           totSgst =
  //             totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100;
  //         if (prop.isgst)
  //           totIgst =
  //             totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100;
  //         if (prop.csgst)
  //           totcgst =
  //             totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100;
  //       }
  //     });
  //     var totalTax = 0;
  //     if (tax === "sgst") {
  //       totalTax = totSgst;
  //     } else if (tax === "igst") {
  //       totalTax = totIgst;
  //     } else if (tax === "cgst") {
  //       totalTax = totcgst;
  //     }
  //     return totalTax.toFixed(2);
  //   }

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0,
      tottcsAmt = 0,
      totalAmount = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        var disAmount = 0;
        var amount = parseFloat(prop.quantity) * parseFloat(prop.rate);

        if (
          prop.discountPercentage &&
          prop.discountPercentage !== null &&
          prop.discountPercentage !== "" &&
          prop.discountPercentage !== "0"
        ) {
          if (prop.discountMode == 2 && prop.discountMode === "2.0") {
            disAmount =
              parseFloat(amount) * (parseFloat(prop.discountPercentage) / 100);
          } else {
            disAmount = parseFloat(prop.discountPercentage);
          }
        }
        amount = (parseFloat(amount) - disAmount).toFixed(2);

        totalAmount = totalAmount + parseFloat(amount);
        if (prop.sgstPercentage !== undefined && prop.sgstPercentage !== null)
          totSgst =
            totSgst +
            (parseFloat(amount) * parseFloat(prop.sgstPercentage)) / 100;
        if (prop.igstPercentage !== undefined && prop.igstPercentage !== null)
          totIgst =
            totIgst +
            (parseFloat(amount) * parseFloat(prop.igstPercentage)) / 100;
        if (prop.cgstPercentage !== undefined && prop.cgstPercentage !== null)
          totcgst =
            totcgst +
            (parseFloat(amount) * parseFloat(prop.cgstPercentage)) / 100;

        if (prop.tcsPercentage !== undefined && prop.tcsPercentage !== null)
          tottcsAmt =
            tottcsAmt +
            (parseFloat(amount) * parseFloat(prop.tcsPercentage)) / 100;
      }
    });
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "tcsAmount") {
      totalTax = tottcsAmt;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    } else if (tax === "totalTax") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;
    } else if (tax === "totalAmount") {
      totalTax = totalAmount;
    } else if (tax === "netTotal") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;

      totalTax = totalTax + totalAmount;
    }
    return totalTax.toFixed(2);
  }
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert1: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };
  // handleFileChange(e) {
  //   e.preventDefault();
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   var fileName = file.name,
  //     fileType;
  //   fileName = fileName.split(".");
  //   fileType = fileName[1].toUpperCase();
  //   reader.onloadend = () => {
  //     if (
  //       fileType === "PDF" ||
  //       fileType === "JPG" ||
  //       fileType === "JPEG" ||
  //       fileType === "PNG" ||
  //       fileType === "XLSX"
  //     ) {
  //       this.setState({
  //         file: file,
  //         fileName: file.name,
  //         fileType: fileName[1],
  //         //filesList: arr,
  //         uploadInvoice: true,
  //         // imagePreviewUrl: reader.result
  //       });
  //       this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
  //     }
  //   };
  //   reader.readAsDataURL(file);
  // }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    var fileNameArr = fileName.split(".");
    for (var i = 0; i < fileNameArr.length; i++) {
      fileType = fileNameArr[i].toUpperCase();
    }

    // if (fileNameArr.length > 2) {
    //   this.basicAlert(
    //     "Your File Name Should be shorter and fullstop(.) is not allowed in file name"
    //   );
    //   console.log(
    //     "file name while upload",
    //     fileName.length + " hello " + fileType
    //   );
    // } else {
    fileName = fileName.split(".");
    // fileType = fileName[1].toUpperCase();

    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          // fileType: fileName[1],
          fileType: fileType,
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
    // }
  }

  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      srNo,
      isReject,
      isApprove,
      srStatus,
      entryId,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(this.state.srStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        18,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  setHoveredRow = (rowInfo) => {
    console.log("hovver", rowInfo);
    this.setState({ selectedRowData: rowInfo });
  };
  getDataTable = () => {
    var data = [];
    const { classes } = this.props;
    console.log("dta", JSON.stringify(this.state.invoiceList));
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        prop.id = key;
        console.log("getting the response of discount percentage here", prop);

        var amount = parseFloat(prop.quantity) * parseFloat(prop.rate);
        var disAmount = 0;
        if (
          prop.discountPercentage &&
          prop.discountPercentage !== null &&
          prop.discountPercentage !== "" &&
          prop.discountPercentage !== "0"
        ) {
          if (prop.discountMode == 2 && prop.discountMode === "2.0") {
            disAmount =
              parseFloat(amount) * (parseFloat(prop.discountPercentage) / 100);
          } else {
            disAmount = parseFloat(prop.discountPercentage);
          }
        }
        amount = (parseFloat(amount) - disAmount).toFixed(2);

        var taxAmount = 0;
        if (prop.taxPercentage != "0" && prop.taxPercentage > 0) {
          taxAmount = (amount * parseFloat(prop.taxPercentage)) / 100;
        }
        var netAmount = amount;
        if (taxAmount)
          netAmount = Number(parseFloat(amount).toFixed(2)) + taxAmount;
        taxAmount = taxAmount.toFixed(2);
        netAmount = Number(parseFloat(netAmount).toFixed(2));
        // amount = amount.toFixed(2);
        amount = amount;
        if (prop.isActive === 1) {
          var obj = {};
          if (
            this.state.selectedRowData &&
            this.state.selectedRowData.index == key
          ) {
            obj = {
              ...prop,
              taxAmount: taxAmount,
              amount: amount,
              qty: prop.quantity,
              debitQuantity: (
                <div>
                  <TextField
                    id="debitnoteQty"
                    className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;

                      list[key].debitnoteQty = event.target.value;
                      this.setState({ invoiceList: list });
                    }}
                    value={prop.quantity}
                    fullWidth={true}
                    iType="number"
                    variant="outlined"
                  />
                </div>
              ),
              hsn: (
                <div>
                  <TextField
                    id="hsnCode"
                    className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;
                      list[key].hsnCode = event.target.value;
                      this.setState({ invoiceList: list });
                    }}
                    value={prop.hsnCode}
                    fullWidth={true}
                    variant="outlined"
                  />
                  {/* <TextField
                    id="make"
                    // className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;
                      list[key].make = event.target.value;
                      this.setState({ invoiceList: list });
                    }}
                    value={prop.make}
                    fullWidth={true}
                    variant="outlined"
                  /> */}
                  {/* <TextField
                    id="remarks"
                    // className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;
                      list[key].remarks = event.target.value;
                      this.setState({ invoiceList: list });
                    }}
                    value={prop.remarks}
                    fullWidth={true}
                    variant="outlined"
                  /> */}
                </div>
              ),
              subitem:
                prop.item && prop.item.length > 220
                  ? prop.item.substring(0, 220) + "..."
                  : prop.item,
              storeData: (
                <SelectboxTemp
                  defValue={this.state.warehouseNo}
                  selectDisabled={this.state.srStatus === 3 ? true : false}
                  id={"warehouseNo"}
                  // onSelect={this.onSelect}
                  onSelect={(selectedValue) => {
                    var list = this.state.invoiceList;
                    list[key].warehouseNo = selectedValue;
                    this.setState({ invoiceList: list });
                  }}
                  stateKey="warehouseNo"
                  // postData={{
                  //   companyId: this.props.state.companyInfo.id,
                  //   type: "S",
                  //   branchId: this.state.branchId,
                  // }}
                  // url={serverApi.SELECT_GODOWN_LIST}
                  staticData={this.state.wareHosueList}
                  getName={(name) => {
                    this.setState({ wareName: name });
                  }}
                  value={this.state.warehouseNo}
                  //style={{ border: "1px solid red" }}
                />
              ),

              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {netAmount}
                  <IconButton
                    onClick={() => {
                      var data = this.state.invoiceList;
                      data.find((o, i) => {
                        if (o.id === key) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //if (this.state.approveButton) {

                          this.setState({
                            deleteItem: true,
                            id: key,
                          });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                          // }
                          return true;
                        }
                      });
                      this.setState({ invoiceList: data });
                    }}
                    color="dangerNoBackground"
                    customClass="remove"
                  >
                    <Close />
                  </IconButton>{" "}
                </div>
              ),
            };
          } else {
            obj = {
              ...prop,
              taxAmount: taxAmount,
              amount: amount,
              qty: prop.quantity,
              subitem:
                prop.item && prop.item.length > 220
                  ? prop.item.substring(0, 220) + "..."
                  : prop.item,
              storeData: (
                <SelectboxTemp
                  selectDisabled={this.state.srStatus === 3 ? true : false}
                  defValue={prop.warehouseNo}
                  id={"warehouseNo"}
                  // onSelect={this.onSelect}
                  onSelect={(selectedValue) => {
                    var list = this.state.invoiceList;
                    list[key].warehouseNo = selectedValue;
                    this.setState({ invoiceList: list });
                  }}
                  stateKey={"warehouseNo"}
                  // postData={{
                  //   companyId: this.props.state.companyInfo.id,
                  //   type: "S",
                  //   branchId: this.state.branchId,
                  // }}
                  // url={serverApi.SELECT_GODOWN_LIST}
                  staticData={this.state.wareHosueList}
                  getName={(name) => {
                    this.setState({ wareName: name });
                  }}
                  value={prop.warehouseNo}
                />
              ), //prop.storeNo,
              hsn: prop.hsnCode,
              //+ "\n" + "Make: " + (prop.make ? prop.make : ""),
              //+
              // "\nRemarks: " +
              // (prop.remarks ? prop.remarks : ""),

              debitQuantity: prop.debitnoteQty,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {netAmount}
                  <IconButton
                    onClick={() => {
                      var data = this.state.invoiceList;
                      data.find((o, i) => {
                        if (o.id === key) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //if (this.state.approveButton) {

                          this.setState({
                            deleteItem: true,
                            id: key,
                          });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                          // }
                          return true;
                        }
                      });
                      this.setState({ invoiceList: data });
                    }}
                    color="dangerNoBackground"
                    customClass="remove"
                  >
                    <Close />
                  </IconButton>{" "}
                </div>
              ),
            };
          }

          data.push(obj);
        }
      });
    console.log("getting data is store receipt", this.state.invoiceList);
    return data;
  };

  handlePrintData = () => {
    console.log(
      "getting the inward id on click print",
      this.state.stprePrintNo,
      this.state.form_supplierId
    );
    // fetch(
    //   serverApi.PRINT_INWARD
    // )
    fetch(
      serverApi.PRINT_INWARD +
        this.state.storeId +
        "/" +
        getCookie(cons.USERID) +
        "/" +
        3,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.state.stprePrintNo}_${this.state.form_supplierId}.pdf`
        );
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    const { classes } = this.props;
    const { tcsAmount, taxType } = this.state;
    var companyId = this.props.state.companyInfo.id;

    //console.log("jute type",selectedJuteType);
    //add item button click
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];
    var taxTypes = getConfigValue(configNames.TAX_TYPES);
    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
    } else {
      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }
    const inputFocus = {
      /*classes: {
                root: classes.cssOutlinedInput,
                focused: classes.notchedOutline,
                notchedOutline: classes.notchedOutline,
            },*/
      autoComplete: "off",
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    var columns = [];

    // if (this.state.goodType === "SRN")
    //   columns = [
    //     {
    //       Header: "Budget Head Name",
    //       accessor: "budgetHeadName",
    //       minWidth: 100,
    //       style: { color: "#000", textAlign: "left" },
    //     },
    //     {
    //       Header: "Description",
    //       accessor: "description",
    //       minWidth: 80,
    //       style: { color: "#000", textAlign: "left" },
    //     },

    //     {
    //       Header: "Quantity",
    //       accessor: "quantity",
    //       minWidth: 60,
    //       style: { color: "#000", textAlign: "left" },
    //     },
    //     {
    //       Header: "Debit Qty",
    //       accessor: "debitQuantity",
    //       minWidth: 80,
    //       style: { color: "#000", textAlign: "right" },
    //     },
    //     {
    //       Header: "Unit",
    //       accessor: "unit",
    //       width: 80,
    //       style: { color: "#000", textAlign: "left" },
    //     },
    //     {
    //       Header: labelType === "2" ? "Store No" : "Warehouse No",
    //       accessor: "wareName",
    //       minWidth: 100,
    //       style: { color: "#000", textAlign: "left" },
    //     },

    //     {
    //       Header: taxType === 3 ? "VAT" : "IGST",
    //       accessor: "isgst",
    //       minWidth: 40,
    //       style: { color: "#000", textAlign: "right" },
    //     },
    //     {
    //       Header: "SGST",
    //       accessor: "ssgst",
    //       minWidth: 50,
    //       style: { color: "#000", textAlign: "right" },
    //     },
    //     {
    //       Header: "CGST",
    //       accessor: "csgst",
    //       minWidth: 40,
    //       style: { color: "#000", textAlign: "right" },
    //     },
    //     {
    //       Header: "Rate",
    //       accessor: "rate",
    //       width: 50,
    //       style: { color: "#000", textAlign: "left" },
    //     },

    //     {
    //       Header: "Actions",
    //       accessor: "actions",
    //       style: { color: "#000", textAlign: "center" },
    //       sortable: false,
    //       filterable: false,
    //     },
    //   ];
    // else {
    //   if (taxType === 3)
    //     columns = [
    //       {
    //         Header: "Item Code",
    //         accessor: "itemGrp",
    //         minWidth: 70,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Item Desc",
    //         accessor: "itemDesc",
    //         minWidth: 150,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "PO No",
    //         accessor: "poNo",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Quantity",
    //         accessor: "quantity",
    //         minWidth: 60,
    //         style: { color: "#000", textAlign: "right" },
    //       },
    //       {
    //         Header: "Debit Qty",
    //         accessor: "debitQuantity",
    //         minWidth: 80,
    //         style: { color: "#000", textAlign: "right" },
    //       },
    //       {
    //         Header: "UOM",
    //         accessor: "uom",
    //         minWidth: 50,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: labelType === "2" ? "Store No" : "Warehouse No",
    //         accessor: "wareName",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },

    //       {
    //         Header: "VAT",
    //         accessor: "isgst",
    //         minWidth: 40,
    //         style: { color: "#000", textAlign: "right" },
    //       },
    //       {
    //         Header: "Rate",
    //         accessor: "rate",
    //         minWidth: 50,
    //         style: { color: "#000", textAlign: "right" },
    //       },

    //       {
    //         Header: "Amount",
    //         accessor: "amount",
    //         minWidth: 80,
    //         style: { color: "#000", textAlign: "right" },
    //       },
    //       {
    //         Header: "Actions",
    //         minWidth: 100,
    //         accessor: "actions",
    //         style: { color: "#000", textAlign: "center" },
    //         sortable: false,
    //         filterable: false,
    //       },
    //     ];
    //   else
    //     columns = [
    //       {
    //         Header: "HSN/SAC Code",
    //         accessor: "hsn",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Item Code",
    //         accessor: "itemGrp",
    //         minWidth: 50,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "PO No",
    //         accessor: "poNo",
    //         minWidth: 80,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Budget Head",
    //         accessor: "budgetHeadName",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Department",
    //         accessor: "departmentName",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Item Group",
    //         accessor: "itemGroup",
    //         minWidth: 100,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Item",
    //         accessor: "subitem",
    //         minWidth: 200,
    //         Cell: (row) => (
    //           <div>
    //             <span title={row.original ? row.original.item : row.value}>
    //               {row.value}
    //             </span>
    //           </div>
    //         ),
    //         style: {
    //           color: "#000",
    //           textAlign: "left",
    //           textOverflow: "unset",
    //         },
    //       },
    //       {
    //         Header: "Quantity",
    //         accessor: "qty",
    //         minWidth: 70,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "UOM",
    //         accessor: "uomCode",
    //         width: 50,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Store No",
    //         accessor: "storeData",
    //         width: 50,
    //         style: { color: "#000", textAlign: "left" },
    //       },

    //       {
    //         Header: "Rate",
    //         accessor: "rate",
    //         style: {
    //           color: "#000",
    //           textAlign: "center",
    //         },
    //         sortable: false,
    //         filterable: false,
    //       },
    //       {
    //         Header: "Tax",
    //         accessor: "taxPercentage",
    //         width: 50,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //       {
    //         Header: "Amount",
    //         accessor: "amount",
    //         width: 100,
    //         style: {
    //           color: "#000",
    //           textAlign: "right",
    //         },
    //       },
    //       {
    //         Header: "Tax Amount",
    //         accessor: "taxAmount",
    //         width: 70,
    //         style: {
    //           color: "#000",
    //           textAlign: "right",
    //         },
    //       },
    //       {
    //         Header: "Net Amount",
    //         accessor: "actions",
    //         width: 150,
    //         style: { color: "#000", textAlign: "left" },
    //       },
    //     ];
    // }
    var tcs = 0;
    if (tcsAmount !== null && tcsAmount !== "") tcs = parseFloat(tcsAmount);
    var round = (
      parseFloat(this.getTotalTax("netTotal")) + parseFloat(tcs)
    ).toFixed(2);

    round = round.split(".");
    var total = parseFloat(round[0]);
    round = "0." + round[1];
    var showRound = false;
    if (parseFloat(round) > 0) {
      showRound = true;
    }
    var max = (1 - parseFloat(round)).toFixed(2);
    round = parseFloat("-" + round);

    console.log("round", round);
    var roundoff = this.state.roundoff;
    if (roundoff !== null && roundoff !== undefined) {
      console.log("round", round, this.state.roundoff);

      if (parseFloat(this.state.roundoff) === parseFloat(max))
        total = Math.round(
          total + parseFloat(this.state.roundoff) - round
        ).toFixed(2);
      else if (parseFloat(this.state.roundoff) === parseFloat(round))
        total = Math.round(total).toFixed(2);
      else {
        if (parseFloat(max) <= 0.5) {
          roundoff = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          roundoff = round;
          total = Math.round(total).toFixed(2);
        }
      }
    } else {
      if (parseFloat(max) <= 0.5) {
        roundoff = max;
        total = Math.round(total + parseFloat(max) - round).toFixed(2);
      } else {
        roundoff = round;
        total = Math.round(total).toFixed(2);
      }
    }
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.costAlert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Budget Head
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    id={"budgetHeadId"}
                                    onSelect={this.onSelect}
                                    defValue={this.state.budgetHeadId}
                                    getName={this.getBudgetName}
                                    stateKey="budgetHeadId"
                                    staticData={this.state.budgetHeadList}
                                    isRefresh={this.state.loadDepartment1}
                                    setIsRefresh={(val) => {
                                      this.setState({ loadDepartment1: false });
                                    }}
                                    value={this.state.budgetHeadId}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={3}
                                    iType={"number"}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    UOM
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="uom"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.uom}
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          {labelType === "2"
                                            ? "Store No"
                                            : "Warehouse No"}

                                          {/*<span
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: "12px"
                                                                            }}>*</span>*/}
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          defValue={this.state.warehouseNo}
                                          id={"warehouseNo"}
                                          onSelect={this.onSelect}
                                          stateKey="warehouseNo"
                                          postData={{
                                            companyId: getCookie(
                                              cons.COMPANY_ID
                                            ),
                                            type: "S",
                                            branchId: this.state.branchId,
                                          }}
                                          url={serverApi.SELECT_GODOWN_LIST}
                                          getName={(name) => {
                                            this.setState({ wareName: name });
                                          }}
                                          value={this.state.warehouseNo}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={12}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Tax
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          // selectDisabled={this.state.poNo!==""&&this.state.poNo!==null}
                                          defValue={this.state.taxId}
                                          id={"taxId"}
                                          onSelect={this.onSelect}
                                          stateKey="taxId"
                                          getName={this.gettaxpercent}
                                          isReload={this.state.loadTax}
                                          setIsReload={this.afterRefreshTax}
                                          url={
                                            serverApi.TAX_MASTER_LIST +
                                            "/" +
                                            this.props.state.companyInfo.id +
                                            "/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.taxId}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Description
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="description"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    height={60}
                                    value={this.state.description}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            {this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                sm={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      id={"description"}
                                      defValue={this.state.description}
                                      staticData={[
                                        {
                                          label: "Printing Charges",
                                          value: "Printing Charges",
                                          name: "Printing Charges",
                                        },
                                        {
                                          label: "Labour Charges",
                                          value: "Labour Charges",
                                          name: "Labour Charges",
                                        },
                                        {
                                          label: "Transport Charges",
                                          value: "Transport Charges",
                                          name: "Transport Charges",
                                        },
                                        {
                                          label: "Delivery Charges",
                                          value: "Delivery Charges",
                                          name: "Delivery Charges",
                                        },
                                        {
                                          label: "Packing & Forwarding Charges",
                                          value: "Packing & Forwarding Charges",
                                          name: "Packing & Forwarding Charges",
                                        },
                                        {
                                          label: "Other Charges",
                                          value: "Other Charges",
                                          name: "Other Charges",
                                        },
                                      ]}
                                      onSelect={this.onSelect}
                                      getName={(val) => {
                                        this.setState({ itemName: val });
                                      }}
                                      stateKey="description"
                                      //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={12}
                                sm={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item Group
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={true}
                                      defValue={this.state.item}
                                      id={"item"}
                                      onSelect={this.onSelect}
                                      getName={this.getItemGroupName}
                                      //   isRefresh={this.state.loadItemGroup}
                                      stateKey="item"
                                      //     setIsRefresh={this.afterRefreshItemGrp}
                                      url={
                                        serverApi.SEARCH_ITEM_GROUPS +
                                        this.props.state.companyInfo.id +
                                        "/" +
                                        getCookie(cons.CIPHER)
                                      }
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={12}
                                sm={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={true}
                                      defValue={this.state.item}
                                      id={"item"}
                                      staticData={this.state.ItemOptions}
                                      onSelect={this.onSelect}
                                      getName={this.getItemDetailsByName}
                                      isRefresh={this.state.loadItem}
                                      stateKey="item"
                                      setIsRefresh={this.afterRefreshItem}
                                      //url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      value={this.state.item}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    disabled={!this.state.printingPop}
                                    id="quantity"
                                    className={classes.textField}
                                    value={this.state.quantity}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    iType={"number"}
                                    decimal={
                                      companyId === "1" ||
                                      companyId === "2" ||
                                      companyId === "116"
                                        ? 3
                                        : 2
                                    }
                                    inputProps={{
                                      style: {
                                        height: 30,
                                        color: "#000",
                                      },
                                      classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                      },
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {this.state.uom}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                    style={{ height: 30 }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      UOM
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="uom"
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                      value={this.state.uom}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {/*
                                                                    <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={12}>
                                                                                <GridContainer>
                                                                                    <ItemGrid xs={12} sm={4}>
                                                                                        <InputLabel
                                                                                            className={classes.label}>
                                                                                            {labelType==="2"?"City Stock":"Central Stock"}
                                                                                        </InputLabel>
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={8}>
                                                                                        <TextField
                                                                                            disabled={true}
                                                                                            id="stock"
                                                                                            className={classes.textField}
                                                                                            onChange={this.onChangeValues}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            InputProps={inputFocus}
                                                                                            style={{height: 30}}
                                                                                            value={this.state.stock}
                                                                                            iType={"number"}
                                                                                        />

                                                                                    </ItemGrid>

                                                                                </GridContainer>
                                                                            </ItemGrid>


                                                                        </GridContainer>
                                                                    </ItemGrid>
*/}

                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Tax
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.taxId}
                                        id={"taxId"}
                                        onSelect={this.onSelect}
                                        stateKey="taxId"
                                        getName={this.gettaxpercent}
                                        isReload={this.state.loadTax}
                                        setIsReload={this.afterRefreshTax}
                                        url={
                                          serverApi.TAX_MASTER_LIST +
                                          "/" +
                                          this.props.state.companyInfo.id +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.taxId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            {this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Price
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="rate"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.rate}
                                          decimal={
                                            companyId === "1" ||
                                            companyId === "2" ||
                                            companyId === "116"
                                              ? 3
                                              : 2
                                          }
                                          iType={"number"}
                                        />
                                      </ItemGrid>
                                      {this.state.selectedJuteQuality > 0 ? (
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "150px",
                                            color: "red",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {"Last purchase at  " +
                                            this.state.lastPurchase}
                                        </span>
                                      ) : null}
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Price
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="rate"
                                          disabled={
                                            this.state.poNo !== "" &&
                                            this.state.poNo !== null
                                          }
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.rate}
                                          decimal={
                                            companyId === "1" ||
                                            companyId === "2" ||
                                            companyId === "116"
                                              ? 3
                                              : 2
                                          }
                                          iType={"number"}
                                        />
                                      </ItemGrid>
                                      {this.state.selectedJuteQuality > 0 ? (
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "150px",
                                            color: "red",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          {"Last purchase at  " +
                                            this.state.lastPurchase}
                                        </span>
                                      ) : null}
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            )}
                            {!this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          {labelType === "2"
                                            ? "Store No"
                                            : "Warehouse No"}
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          defValue={this.state.warehouseNo}
                                          id={"warehouseNo"}
                                          onSelect={this.onSelect}
                                          stateKey="warehouseNo"
                                          postData={{
                                            companyId: getCookie(
                                              cons.COMPANY_ID
                                            ),
                                            type: "S",
                                            branchId: this.state.branchId,
                                          }}
                                          url={serverApi.SELECT_GODOWN_LIST}
                                          getName={(name) => {
                                            this.setState({ wareName: name });
                                          }}
                                          value={this.state.warehouseNo}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={!this.state.printingPop}
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        inputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.amount}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {/*
                                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Tax Type<span
                                                                            style={{color: "red", fontSize: "12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <Select
                                                                            isDisabled={this.state.form_poNO!==""&&this.state.form_poNO!==null&&!this.state.printingPop}
                                                                            defValue={this.state.taxType}
                                                                            name={"taxType"}
                                                                            value={this.state.taxType}
                                                                            onChange={this.handleSelectedUnitValue}
                                                                            options={ucoptions}
                                                                            className={classes.selectFormControl}
                                                                            theme={(theme) => ({
                                                                                ...theme,
                                                                                borderRadius: '4px',
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    primary: '#00ABDC',
                                                                                },
                                                                            })}
                                                                            styles={{
                                                                                control: (base, state) => ({
                                                                                    ...base,
                                                                                    height: '30px',
                                                                                    'min-height': '30px',
                                                                                }),

                                                                            }}
                                                                        />

                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>
*/}

                            <ItemGrid xs={6} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Remarks
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="remarks1"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.remarks1}
                                    fullWidth={true}
                                    height={60}
                                    multiline={true}
                                    rowsMax={3}
                                    InputProps={{
                                      autoComplete: "off",
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          SR Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "srDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "srDate",
                              value: this.state.srDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          SR Print NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="stprePrintNo"
                          value={this.state.stprePrintNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Supplier
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          defValue={this.state.form_supplierId}
                          onSelect={this.onSelect}
                          stateKey="form_supplierId"
                          isRefresh={this.state.loadSupplier}
                          setIsRefresh={(val) => {
                            this.setState({ loadSupplier: false });
                          }}
                          staticData={this.state.supplierList}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Gate Entry No
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="gateEntryNo"
                          value={this.state.gateEntryNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan NO
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="challanNo"
                          value={this.state.challanNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //  this.timeChanged(dateObj, "challanDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "challanDate",
                              value: this.state.challanDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px", marginBottom: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Agent<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"agentId"}
                                                                    defValue={this.state.agentId}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="agentId"
                                                                    getName={this.getAgentName}
                                                                    url={serverApi.AGENTS_LIST}
                                                                    value={this.state.agentId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="form_vehicleNo"
                          value={this.state.form_vehicleNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          inputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Consignment Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          {/* <Datetime
                            selectDisabled={true}
                            closeOnSelect={true}
                            //  isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //   this.timeChanged(dateObj, "consignmentDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "consignmentDate",
                              value: this.state.consignmentDate,
                              autoComplete: "off",
                            }}
                          /> */}

                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              //  this.timeChanged(dateObj, "challanDate")
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "consignmentDate",
                              value: this.state.consignmentDate,
                              autoComplete: "off",
                              disabled: true,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Consignment No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="consignmentNumber"
                          // className={classes.textField}
                          value={this.state.consignmentNumber}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tax Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          // isDisabled={
                          //   this.state.form_poNO !== "" &&
                          //   this.state.form_poNO !== null
                          // }
                          isDisabled={true}
                          defValue={this.state.taxType}
                          name={"taxType"}
                          value={this.state.taxType}
                          onChange={this.handleSelectedUnitValue}
                          options={ucoptions}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            colors: {
                              ...theme.colors,
                              primary: "#00ABDC",
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                            }),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH +
                            this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Project
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          defValue={this.state.projectId}
                          id={"projectId"}
                          onSelect={this.onSelect}
                          stateKey="selectedProject"
                          staticData={this.state.projectNameList}
                          // getName={(name) => {
                          //   this.setState({ projectId: name });
                          // }}
                          getName={(name) => {
                            this.setState({ projectName: name });
                          }}
                          value={this.state.projectId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Goods Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={true}
                          id={"goodType"}
                          //selectDisabled={this.state.updateView || this.state.SelectedChalanType.value === 1}
                          defValue={this.state.goodType}
                          onSelect={this.onSelect}
                          stateKey="goodType"
                          staticData={GoodTypes}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <GridContainer>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Remark
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="remarks"
                              // label="Multiline"
                              fullWidth
                              multiline={true}
                              rowsMax="3"
                              value={this.state.remarks}
                              onChange={this.onChangeValues}
                              className={classes.textField}
                              height={60}
                              // helperText="hello"
                              variant="outlined"
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Internal Note
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="internalNote"
                              fullWidth
                              multiline
                              rowsMax="3"
                              height={60}
                              value={this.state.internalNote}
                              onChange={this.onChangeValues}
                              className={classes.textField}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12} className="reactLineItemTable">
                        {getCookie(cons.USER_ROLE_ID) === "100" ? (
                          <LineItemTable
                            data={this.getDataTable()}
                            loading={this.state.loading}
                            setHoveredRow={this.setHoveredRow}
                            hideOverFlow={true}
                            columns={
                              this.state.goodType === "SRN"
                                ? [
                                    {
                                      Header: "Budget Head Name",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Description",
                                      accessor: "description",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Quantity",
                                      accessor: "quantity",
                                      minWidth: 60,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Debit Qty",
                                      accessor: "debitQuantity",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "unit",
                                      width: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    // {
                                    //   Header:
                                    //     labelType === "2"
                                    //       ? "Store No"
                                    //       : "Warehouse No",
                                    //   accessor: "wareName",
                                    //   width: 150,
                                    //   style: { color: "#000", textAlign: "left" },
                                    // },

                                    {
                                      Header: taxType === 3 ? "VAT" : "IGST",
                                      accessor: "isgst",
                                      minWidth: 40,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "SGST",
                                      accessor: "ssgst",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "CGST",
                                      accessor: "csgst",
                                      minWidth: 40,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Actions",
                                      accessor: "actions",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]
                                : taxType === 3
                                ? [
                                    {
                                      Header: "HSN/SAC Code",
                                      accessor: "hsn",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Code",
                                      accessor: "itemGrp",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "PO No",
                                      accessor: "poSequence",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Budget Head",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Department",
                                      accessor: "departmentName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Group",
                                      accessor: "itemGroup",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item",
                                      accessor: "subitem",
                                      minWidth: 200,
                                      Cell: (row) => (
                                        <div>
                                          <span
                                            title={
                                              row.original
                                                ? row.original.item
                                                : row.value
                                            }
                                          >
                                            {row.value}
                                          </span>
                                        </div>
                                      ),
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                        textOverflow: "unset",
                                      },
                                    },
                                    {
                                      Header: "Quantity",
                                      accessor: "qty",
                                      minWidth: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "uomCode",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    // {
                                    //   Header: "Store No",
                                    //   accessor: "storeData",
                                    //   width: 150,
                                    //   style: { color: "#000", textAlign: "left" },
                                    // },

                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                    {
                                      Header: "Tax",
                                      accessor: "taxPercentage",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Discount",
                                      accessor: "discountPercentage",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Amount",
                                      accessor: "amount",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Tax Amount",
                                      accessor: "taxAmount",
                                      width: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Net Amount",
                                      accessor: "actions",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      Header: "HSN/SAC Code",
                                      accessor: "hsn",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Code",
                                      accessor: "itemGrp",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "PO No",
                                      accessor: "poSequence",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Budget Head",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Department",
                                      accessor: "departmentName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Group",
                                      accessor: "itemGroup",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item",
                                      accessor: "subitem",
                                      minWidth: 200,
                                      Cell: (row) => (
                                        <div>
                                          <span
                                            title={
                                              row.original
                                                ? row.original.item
                                                : row.value
                                            }
                                          >
                                            {row.value}
                                          </span>
                                        </div>
                                      ),
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                        textOverflow: "unset",
                                      },
                                    },
                                    {
                                      Header: "Quantity",
                                      accessor: "qty",
                                      minWidth: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "uomCode",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    // {
                                    //   Header: "Store No",
                                    //   accessor: "storeData",
                                    //   width: 150,
                                    //   style: { color: "#000", textAlign: "left" },
                                    // },

                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                    {
                                      Header: "Tax",
                                      accessor: "taxPercentage",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Discount",
                                      accessor: "discountPercentage",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Amount",
                                      accessor: "amount",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Tax Amount",
                                      accessor: "taxAmount",
                                      width: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Net Amount",
                                      accessor: "actions",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                  ]
                            }
                          />
                        ) : (
                          <LineItemTable
                            data={this.getDataTable()}
                            loading={this.state.loading}
                            setHoveredRow={this.setHoveredRow}
                            hideOverFlow={true}
                            columns={
                              this.state.goodType === "SRN"
                                ? [
                                    {
                                      Header: "Budget Head Name",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Description",
                                      accessor: "description",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Quantity",
                                      accessor: "quantity",
                                      minWidth: 60,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Debit Qty",
                                      accessor: "debitQuantity",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "unit",
                                      width: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header:
                                        labelType === "2"
                                          ? "Store No"
                                          : "Warehouse No",
                                      accessor: "wareName",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: taxType === 3 ? "VAT" : "IGST",
                                      accessor: "isgst",
                                      minWidth: 40,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "SGST",
                                      accessor: "ssgst",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "CGST",
                                      accessor: "csgst",
                                      minWidth: 40,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Actions",
                                      accessor: "actions",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                  ]
                                : taxType === 3
                                ? [
                                    {
                                      Header: "HSN/SAC Code",
                                      accessor: "hsn",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Code",
                                      accessor: "itemGrp",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "PO No",
                                      accessor: "poSequence",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Budget Head",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Department",
                                      accessor: "departmentName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Group",
                                      accessor: "itemGroup",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item",
                                      accessor: "subitem",
                                      minWidth: 200,
                                      Cell: (row) => (
                                        <div>
                                          <span
                                            title={
                                              row.original
                                                ? row.original.item
                                                : row.value
                                            }
                                          >
                                            {row.value}
                                          </span>
                                        </div>
                                      ),
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                        textOverflow: "unset",
                                      },
                                    },
                                    {
                                      Header: "Quantity",
                                      accessor: "qty",
                                      minWidth: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "uomCode",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Store No",
                                      accessor: "storeData",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                    {
                                      Header: "Tax",
                                      accessor: "taxPercentage",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Discount",
                                      accessor: "discountPercentage",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Amount",
                                      accessor: "amount",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Tax Amount",
                                      accessor: "taxAmount",
                                      width: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Net Amount",
                                      accessor: "actions",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      Header: "HSN/SAC Code",
                                      accessor: "hsn",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Code",
                                      accessor: "itemGrp",
                                      minWidth: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "PO No",
                                      accessor: "poSequence",
                                      minWidth: 80,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Budget Head",
                                      accessor: "budgetHeadName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Department",
                                      accessor: "departmentName",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item Group",
                                      accessor: "itemGroup",
                                      minWidth: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Item",
                                      accessor: "subitem",
                                      minWidth: 200,
                                      Cell: (row) => (
                                        <div>
                                          <span
                                            title={
                                              row.original
                                                ? row.original.item
                                                : row.value
                                            }
                                          >
                                            {row.value}
                                          </span>
                                        </div>
                                      ),
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                        textOverflow: "unset",
                                      },
                                    },
                                    {
                                      Header: "Quantity",
                                      accessor: "qty",
                                      minWidth: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Unit",
                                      accessor: "uomCode",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Store No",
                                      accessor: "storeData",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },

                                    {
                                      Header: "Rate",
                                      accessor: "rate",
                                      style: {
                                        color: "#000",
                                        textAlign: "center",
                                      },
                                      sortable: false,
                                      filterable: false,
                                    },
                                    {
                                      Header: "Tax",
                                      accessor: "taxPercentage",
                                      width: 50,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                    {
                                      Header: "Discount",
                                      accessor: "discountPercentage",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Amount",
                                      accessor: "amount",
                                      width: 100,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Tax Amount",
                                      accessor: "taxAmount",
                                      width: 70,
                                      style: {
                                        color: "#000",
                                        textAlign: "right",
                                      },
                                    },
                                    {
                                      Header: "Net Amount",
                                      accessor: "actions",
                                      width: 150,
                                      style: {
                                        color: "#000",
                                        textAlign: "left",
                                      },
                                    },
                                  ]
                            }
                          />
                        )}
                      </ItemGrid>
                      {/* {this.state.srStatus !== "3" &&
                      this.state.srStatus !== "4" &&
                      this.state.srStatus !== "5" &&
                      this.state.srStatus !== "6" ? (
                        <ItemGrid
                          xs={12}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ printingPop: true, alert: true });
                          }}
                        >
                          Click Here To Add Printing Charges/Labour
                          Charges/Transport Charges/Delivery Charges/Packing &
                          Forwarding Charges/Other Charges
                        </ItemGrid>
                      ) : null} */}
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                              {/*<span style={{*/}
                              {/*fontStyle: "italic",*/}
                              {/*fontWeight: 400,*/}
                              {/*color: "red"*/}
                              {/*}}>Note:</span>*/}
                              {/*<span style={{fontSize:"12px",color:"red",fontStyle:"italic"}}>{"Unit & Mukam can't modified"}</span>*/}
                            </ItemGrid>
                            {/*
                                                        <ItemGrid xs={6}>
                                                            {this.state.srStatus !== '3' && this.state.srStatus !== '4' && this.state.srStatus !== '5' && this.state.srStatus !== '6' ?
                                                                <Button color={"info"} right={true}
                                                                        onClick={this.handleAddItem}>Add
                                                                    Item</Button> : null}
                                                        </ItemGrid>
*/}
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView && this.state.status === 3 ? (
                        getCookie(cons.USER_ROLE_ID) === "100" ? null : this
                            .state.storeApprovedBy === null ? null : (
                          <ItemGrid xs={12}>
                            <GridContainer>
                              <ItemGrid xs={6}>
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontWeight: 400,
                                    color: "#003F66",
                                  }}
                                >
                                  Approved By:
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#003F66",
                                  }}
                                >
                                  {" " + this.state.storeApprovedBy}
                                  <br />
                                </span>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        )
                      ) : null}
                      {this.state.statusDesc !== "APPROVED" ? (
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={8}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        TCS Percentage
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        //selectDisabled={true}
                                        defValue={this.state.tcsPercentage}
                                        onSelect={this.onSelect}
                                        stateKey="tcsPercentage"
                                        staticData={TCSPercentages}
                                        value={this.state.tcsPercentage}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={8}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        TCS Percentage
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={true}
                                        defValue={this.state.tcsPercentage}
                                        onSelect={this.onSelect}
                                        stateKey="tcsPercentage"
                                        staticData={TCSPercentages}
                                        //value={this.state.tcsPercentage}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      )}

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              {taxType === 3 ? "Total VAT :" : "Total IGST :"}
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.getTotalTax("igst")}
                            {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalIgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("igst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              Total SGST :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.getTotalTax("sgst")}
                            {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalSgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("sgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              Total CGST :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.getTotalTax("cgst")}
                            {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalCgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("cgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              Total Amount :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.getTotalTax("totalAmount")}
                            {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalAmount"
                                                    className={classes.textField}
                                                    value={this.state.totalAmount}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              TCS Amount :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.state.tcsAmount}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      {showRound ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={9}
                              style={{ marginTop: "10px", textAlign: "right" }}
                            >
                              <InputLabel className={classes.label}>
                                Round Off :
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={1}
                              style={{ textAlign: "right" }}
                            >
                              {console.log(
                                "getting the correct rounf value here up",
                                roundoff + "......" + max
                              )}
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={
                                      parseFloat(roundoff) === parseFloat(max)
                                        ? 1
                                        : 0
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        roundoff: max,
                                      });
                                    }}
                                    name="radio button demo"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord
                                        className={classes.radioChecked}
                                      />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={max + ""}
                              />
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={1}
                              style={{ textAlign: "right" }}
                            >
                              {console.log(
                                "getting the correct rounf value here",
                                roundoff + "......" + round
                              )}
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={roundoff === round ? 1 : 0}
                                    onChange={(event) => {
                                      this.setState({
                                        roundoff: round,
                                      });
                                    }}
                                    name="radio button demo"
                                    aria-label="A"
                                    icon={
                                      <FiberManualRecord
                                        className={classes.radioUnchecked}
                                      />
                                    }
                                    checkedIcon={
                                      <FiberManualRecord
                                        className={classes.radioChecked}
                                      />
                                    }
                                    classes={{
                                      checked: classes.radio,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={round}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}

                      {/* {this.state.updateView &&
                      this.state.creditnoteValue !== null ? (
                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={9}
                              style={{ textAlign: "right" }}
                            >
                              <InputLabel className={classes.label}>
                                Credit Note:
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={2}
                              style={{ textAlign: "right" }}
                            >
                              {this.state.creditnoteValue}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null} */}

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              Net Total:
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {/* {parseFloat(this.getTotalTax("netTotal")) +
                              parseFloat(this.state.tcsAmount)} */}
                            {total}
                            {/*
                                                <TextField
                                                    disabled={true}
                                                    id="netTotal"
                                                    className={classes.textField}
                                                    value={this.state.netTotal}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={12}
                                                      style={{height: 1, backgroundColor: "#00acc1", marginTop: "10px"}}/>
*/}
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              {this.state.srStatus === 3 ||
                              this.state.srStatus === 5 ? null : (
                                <Close />
                              )}
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView && this.state.srStatus !== "3" ? (
                    getCookie(cons.USER_ROLE_ID) === "100" ||
                    getCookie(cons.USER_ROLE_ID) === "99" ? null : (
                      <ItemGrid xs={4}>
                        {this.state.srStatus === 3 ||
                        this.state.srStatus === 5 ? null : (
                          <Button color={"info"} onClick={this.handleFileClick}>
                            Upload File
                          </Button>
                        )}
                      </ItemGrid>
                    )
                  ) : null}
                  {this.state.statusDesc === "DRAFTED" ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick(1);
                            }}
                          >
                            open
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.srApproveButton === true ? (
                        <>
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                this.setState({ isApprove: true });
                                this.InvoicebasicAlert(
                                  "Do You Like To Approve..!!"
                                );
                              }}
                            >
                              Approve
                            </Button>
                          </ItemGrid>
                          <ItemGrid>
                            <Button
                              color="danger"
                              onClick={() => {
                                this.setState({ isReject: true });
                                this.InvoicebasicAlert(
                                  "Do You Like To Reject..!!"
                                );
                              }}
                            >
                              Reject
                            </Button>
                          </ItemGrid>
                        </>
                      ) : null}
                      {/* {this.state.srApproveButton ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({ isReject: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null} */}
                      {(this.state.approveStatus &&
                        this.state.srStatus !== "3") ||
                      this.state.srStatus == "1" ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick("");
                            }}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                    {this.state.updateView ? (
                      // <LogsComponents id={this.state.srNo} recType={"INWARD"} />

                      <LogsComponents
                        id={this.state.entryId}
                        recType={"INWARD"}
                      />
                    ) : null}
                  </ItemGrid>
                  {this.state.srStatus !== "21" ? (
                    // <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                    //   <SamplePDF
                    //     invoiceType={11}
                    //     printResp={this.state.printResp}
                    //   />
                    // </ItemGrid>
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <div className="exportPdf">
                        <IconButton
                          color="primary"
                          onClick={this.handlePrintData}
                        >
                          {<Print />}
                        </IconButton>
                      </div>
                    </ItemGrid>
                  ) : null}
                  {/*{this.state.approveStatus ?*/}
                  {/*<ItemGrid xs={12} style={{marginTop: "5px"}}>*/}
                  {/*<GridContainer justify="center">*/}
                  {/*<ItemGrid>*/}
                  {/*<Button color="primary"*/}
                  {/*onClick={this.onCreateButtonClick}>Approve</Button>*/}
                  {/*</ItemGrid>*/}
                  {/*<ItemGrid>*/}
                  {/*<Button color="danger"*/}
                  {/*onClick={this.onRejectButtonClick}>Reject</Button>*/}
                  {/*</ItemGrid>*/}
                  {/*</GridContainer>*/}
                  {/*</ItemGrid> : null*/}
                  {/*}*/}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                // removed search field from list table
                <div className="storeReceiptTable">
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierId}
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  staticData={this.state.supplierList}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                SR No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                defValue={this.state.search_srNo}
                                id="search_srNo"
                                value={this.state.search_srNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                PO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="poNos"
                                value={this.state.poNos}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Status
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.status}
                                  id={"status"}
                                  onSelect={this.onSelect}
                                  stateKey="status"
                                  url={serverApi.SEARCH_STORE_STATUS}
                                  value={this.state.status}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                // isRefresh={this.state.loadQuality}
                                // setIsRefresh={this.afterRefreshQuality}
                                url={
                                  serverApi.SELECT_BRANCH +
                                  this.props.state.companyInfo.id
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              {/* <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  this.props.state.companyInfo.id +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              /> */}

                              {console.log(
                                "getting project data",
                                this.state.projectNameList
                              )}
                              {/* <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.projectId}
                                id={"projectId"}
                                onSelect={this.onSelect}
                                stateKey="selectedProject"
                                staticData={this.state.projectNameList}
                                // getName={(name) => {
                                //   this.setState({ projectId: name });
                                // }}
                                getName={(name) => {
                                  this.setState({ projectName: name });
                                }}
                                value={this.state.projectId}
                              /> */}
                              <SelectboxTemp
                                defValue={this.state.projectIds}
                                id={"projectIds"}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                isRefresh={this.state.loadSupplier}
                                setIsRefresh={(val) => {
                                  this.setState({ loadSupplier: false });
                                }}
                                staticData={this.state.projectNameList}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {/*
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
*/}

                        <ItemGrid xs={12}>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {/* <TableComponent
                        // staticData={this.state.dataTable}
                        url={serverApi.GET_ALL_INWARDS}
                        //url={serverApi.DATATABLE_SERVICE_URL + "getAllStoreGoodReceiveDetails"}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_receipt_currentdate"}
                        actionType={VIEW}
                      /> */}
                      <PaginationTableComponent
                        // url={serverApi.FETCH_ALL_INDENT + "/0/10"}
                        staticData={this.state.dataTable}
                        // dataTableAll={this.state.dataTable}
                        // searchData={this.getData()}
                        showPagination={true}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        handlePage={this.handlePage}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_receipt_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument() {
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "srPrint/" +
        this.state.srNo +
        "/" +
        getCookie(cons.CIPHER),
      this.handlePrintResp
    );
  }

  handlePrintResp = (resp) => {
    console.Consolelog("from store receipt issue", resp.srHeader);
    var printHeaderValues = resp.srHeader;
    var modules = [];
    var modules = resp.srLineItems;
    console.log("lineitems", modules);
    var printTotalvalues = resp.totals;
    //  this.setState({
    //      printHeaderValues:resp.srHeader,
    //      modules:resp.srLineItems,
    //      printTotalvalues:resp.totals,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt == 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt == 3) {
      printcopy = "Triplicate";
    } else if (printCounnt == 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }
    var a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    var b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    function inWords(num) {
      if ((num = num.toString()).length > 9) return "overflow";
      var n = ("000000000" + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = "";
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
          : "";
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
          : "";
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
          : "";
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
          : "";
      str +=
        n[5] != 0
          ? (str != "" ? "and " : "") +
            (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
            "only "
          : "";
      return str;
    }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth("STORES RECEIPT NOTE") *
        doc.internal.getFontSize()) /
        2;
    doc.text("STORES RECEIPT NOTE", xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    var companyName = doc.splitTextToSize(printHeaderValues.companyName, 200);
    var supplier = doc.splitTextToSize(printHeaderValues.supplier, 200);
    doc.cell(30, 60, 260, 25, companyName, "", "C");
    doc.cell(30, 60, 260, 25, supplier, "", "C");
    doc.cellInitialize();
    doc.cell(30, 85, 520, 55, " ", "", "C");
    doc.text(250, 80, " ");
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(35, 100, "SR NO");
    doc.text(70, 100, ":");
    doc.setFontType("normal");
    doc.text(80, 100, printHeaderValues.srNo + "");
    doc.setFontType("bold");
    doc.text(150, 100, "DATE : ");
    doc.setFontType("normal");
    doc.text(200, 100, printHeaderValues.srDate + "");
    doc.setFontType("bold");
    doc.text(250, 100, "CHALLAN NO");
    doc.text(320, 100, ":");
    doc.setFontType("normal");
    doc.text(340, 100, printHeaderValues.challanNo + "");
    doc.setFontType("bold");
    doc.text(400, 100, "DATE");
    doc.text(425, 100, ":");
    doc.setFontType("normal");
    doc.text(450, 100, "12-06-2019 ");
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(35, 120, "INW NO");
    doc.text(70, 120, ":");
    doc.setFontType("normal");
    doc.text(80, 120, printHeaderValues.srGateNo + "");
    doc.setFontType("bold");
    doc.text(150, 120, "DATE : ");
    doc.setFontType("normal");
    doc.text(200, 120, printHeaderValues.srGateDate + "");

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, 140, 100, 25, "I/CODE/UNIT", "", "C");
    doc.cell(70, 140, 150, 25, "DESCRIPTION/DETAIL/MAKE", "", "C");
    doc.cell(170, 140, 60, 25, "P/O NO.", "", "C");
    doc.cell(210, 140, 70, 25, "QUANTITY", "", "C");
    doc.cell(310, 140, 70, 25, "RATE", "", "C");
    doc.cell(310, 140, 70, 25, "AMOUNT", "", "C");

    doc.cellInitialize();

    var cellWidths = ["", 100, 150, 60, 70, 70, 70];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 140;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 140;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          var companyName = doc.splitTextToSize(
            "NELLIMARLA JUTE MILLS CO. LTD    ",
            200
          );
          doc.cell(30, 60, 260, 25, companyName, "", "C");
          doc.cell(30, 60, 260, 25, "SANYASI RAJU & CO", "", "C");
          doc.cellInitialize();
          doc.cell(30, 85, 520, 55, " ", "", "C");
          doc.text(250, 80, " ");
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.text(35, 100, "SR NO");
          doc.text(70, 100, ":");
          doc.setFontType("normal");
          doc.text(80, 100, " 123");
          doc.setFontType("bold");
          doc.text(150, 100, "DATE : ");
          doc.setFontType("normal");
          doc.text(200, 100, "1235 ");
          doc.setFontType("bold");
          doc.text(250, 100, "CHALLAN NO");
          doc.text(320, 100, ":");
          doc.setFontType("normal");
          doc.text(340, 100, "258");
          doc.setFontType("bold");
          doc.text(400, 100, "DATE");
          doc.text(425, 100, ":");
          doc.setFontType("normal");
          doc.text(450, 100, "12-06-2019 ");
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.text(35, 120, "INW NO");
          doc.text(70, 120, ":");
          doc.setFontType("normal");
          doc.text(80, 120, " 123");
          doc.setFontType("bold");
          doc.text(150, 120, "DATE : ");
          doc.setFontType("normal");
          doc.text(200, 120, "1235 ");
          doc.setFontType("bold");
          doc.text(250, 120, "R/NOTE NO.");
          doc.text(320, 120, ":");
          doc.setFontType("normal");
          doc.text(340, 120, "258");
          doc.setFontType("bold");
          doc.text(400, 120, "DATE");
          doc.text(425, 120, ":");
          doc.setFontType("normal");
          doc.text(450, 120, "12-06-2019 ");

          doc.cellInitialize();
          doc.setFontType("bold");
          doc.setFontSize(9);
          doc.cell(30, 140, 100, 25, "I/CODE/UNIT", "", "C");
          doc.cell(70, 140, 150, 25, "DESCRIPTION/DETAIL/MAKE", "", "C");
          doc.cell(170, 140, 60, 25, "P/O NO.", "", "C");
          doc.cell(210, 140, 70, 25, "QUANTITY", "", "C");
          doc.cell(310, 140, 70, 25, "RATE", "", "C");
          doc.cell(310, 140, 70, 25, "AMOUNT", "", "C");

          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else {
              var size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }

          return doc.cell(
            30,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.setFontSize(9);
    doc.cell(30, k + 25, 90, 25, "GROSS AMOUNT ", "", "right");
    doc.cell(105, k + 25, 80, 25, "DISCOUNT ", "", "right");
    doc.cell(147, k + 25, 40, 25, "EXCISE ", "", "right");
    doc.cell(189, k + 25, 40, 25, "GST ", "", "right");
    doc.cell(231, k + 25, 40, 25, "SUR.CH ", "", "right");
    doc.cell(189, k + 25, 60, 25, "DELIVERY", "", "right");
    doc.cell(271, k + 25, 60, 25, "TOTAL DED ", "", "right");
    doc.cell(271, k + 25, 40, 25, "ROUND ", "", "right");
    doc.cell(322, k + 25, 70, 25, "NET AMOUNT ", "", "right");

    doc.cellInitialize();
    doc.cell(
      30,
      k + 50,
      90,
      25,
      printTotalvalues.grossAmount + " ",
      "",
      "right"
    );
    doc.cell(105, k + 50, 80, 25, printTotalvalues.discount + " ", "", "right");
    doc.cell(147, k + 50, 40, 25, printTotalvalues.excise + " ", "", "right");
    doc.cell(189, k + 50, 40, 25, printTotalvalues.gst + " ", "", "right");
    doc.cell(231, k + 50, 40, 25, printTotalvalues.surCh + " ", "", "right");
    doc.cell(189, k + 50, 60, 25, printTotalvalues.delivery + " ", "", "right");
    doc.cell(271, k + 50, 60, 25, printTotalvalues.totDed + " ", "", "right");
    doc.cell(271, k + 50, 40, 25, printTotalvalues.round + " ", "", "right");
    doc.cell(
      322,
      k + 50,
      70,
      25,
      printTotalvalues.netAmount + " ",
      "",
      "right"
    );
    doc.cellInitialize();
    var words = "RUPEES  " + inWords(printTotalvalues.netAmount);
    doc.cell(30, k + 75, 520, 25, words.toUpperCase(), "", "left");

    doc.setFontType("normal");
    doc.setFontSize(10);

    doc.text(30, k + 160, "RECEIPT AUTHORITY");
    doc.text(200, k + 160, "STORE AUTHORITY");
    doc.text(400, k + 160, " AUTHORISED SIGNATORY");
    //doc.text(400, k + 180, 'Authorised Signature');
    doc.setFontSize(8);
    doc.setFontType("bold");
    var note =
      "Note* : This is a computer generated print, Signature is not required.";
    var NotexOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(note) * doc.internal.getFontSize()) / 2;
    doc.text(note, NotexOffset, k + 200);

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 250, newdat);
    doc.text(500, k + 250, printBy);
    addWaterMark(doc, printcopy);
    doc.save("StroeReceipt.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {
    border: "1px solid #00ADBC",
    borderRadius: "4px",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      border: "2px solid #0ADBC",
      // borderColor: '#D2D2D2',
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(StoreReceipt)
);
