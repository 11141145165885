import React, { Component } from 'react'
//import { useLocation } from 'react-router-dom'
import { requestList } from '../../server/server'
import { getDataFromUrl} from "../../server/server";
import cons, { serverApi, serverVars } from '../../helper/Consts';
import Button from "../../components/CustomButtons/Button";

export default class LogsComponents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            resp:[],
            logData:[],
        }
        //this.hideAlert = this.hideAlert.bind(this);
        //this.hideAlertMessage = this.hideAlertMessage.bind(this);
    }
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });
    }

    handleLogsClick=()=>{
        const {id,recType} = this.props;
        this.handleLoading();
        var currentPage = window.location.href.split('/').pop();
            getDataFromUrl(serverApi.GET_ACTIONS+recType +"/action/LOGS/"+id,this.handleLogs)
    }
    handleLogs=(resp)=>{
        this.setState({
            logData:resp
        });
    }
    render() {
        const sampleData = this.state.logData.map((item,key)=>{
            return(
                <div>
                    <p style={{borderBottom:"1px solid #d9d9d9",marginBottom:"5px"}}><span style={{fontSize:"13px",color:"#3C4858",marginRight:"5px"}}>{item.actionDate}</span><span>{item.remarks}</span></p><br/>
                </div>
            )
        })
        return(
            <>
                <div>
                    <Button color={"info"} onClick={this.handleLogsClick} style={{marginBottom:"10px"}}>
                        Click here to see the Logs
                    </Button>
                    <br/>
                    {sampleData}
                </div>
            </>
        )
    }
}
