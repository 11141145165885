import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi,serverConfig} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList,getFileDownload,uploadFile} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";
import { connect } from 'react-redux';


class ExpenseBooking  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            toDate: "",
            isRefresh: false,
            isLoading: false,
            itemViewType: "recId",
            approveStatus: false,
            date1:moment().format("DD-MM-YYYY"),
            project:"",
            dept:"",
            createDate:moment().format("DD-MM-YYYY"),
            expenseDate:"",
            description:"",
            total:"",
            advanceTaken:"",
            dueAmount:"",
            status:"",
            payNote:"",
            payDate:"",
            ExpenseList:[],
            paidAmount:"",
            expenseType:"",
            expenseNote:"",
            quantity:"",
            rate:"",
            amount:"",
            uom:"",
            tableList:null,costFactorDes:"",
            filesList:[],
            filesLineList:[],

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.handleHeaderFileChange=this.handleHeaderFileChange.bind(this);
        this.handleHeaderFileClick=this.handleHeaderFileClick.bind(this);

    }

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };


    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            getDataFromUrl(serverApi.EXPENSE_BOOKING_GET_BYID+"/"+id+"/"+getCookie(cons.USERID), this.handleViewClickResp);
        }
    };



    // handleViewClickResp = (resp) => {
    //     var o = resp;
    //     if (o !== null) {
    //         this.setState({
    //             updateView: true,
    //             isCreated: true,
    //             recId:o.recId,
    //             ebId:o.ebId,
    //             expenseDate:o.expenseDateDesc,
    //             project:o.project,
    //             description:o.description,
    //             total:o.total,
    //             advanceTaken:o.advanceTaken,
    //             status:o.status,
    //             dueAmount:o.dueAmount,
    //             payNote:o.payNote,
    //             payDate:o.payDateDesc,
    //             userId:o.userId,
    //             createdBy: o.createdBy,
    //             updatedDateTime: o.updatedDateTime,
    //             updatedBy:o.updatedBy,
    //             approveButton:o.approveButton
    //         });
    //     }
    //
    //
    // };

    handleViewClickResp = (resp) => {
        var header = resp.exboHdr;
        var list = resp.exboDtl;
        var expensebookinglist = list.map((prop,key)=>{
            return(
                {
                    id:key,
                    dtlId:prop.dtlId,
                    hdrId:prop.hdrId,
                    expenseDate:prop.expenseDateDesc,
                    expenseType: prop.expenseType,
                    expenseNote:prop.expenseNote,
                    quantity:prop.quantity,
                    rate:prop.rate,
                    amount: prop.amount,
                    uom: prop.uom,
                    isActive:prop.isActive,
                    costFactor:prop.costId,
                    costFactorDes:prop.costName,


                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.ExpenseList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            this.basicItemAlert("Do You Like To Update This Item?");
                                            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"33/"+o.dtlId+"/"+this.props.state.companyInfo.id,this.handleLineInvoiceFiles);
                                            this.setState({
                                                id:o.id,
                                                updateItemAlert:true,
                                                updateItem: true,
                                                dtlId:o.dtlId,
                                                hdrId:o.hdrId,
                                                expenseDate:o.expenseDate,
                                                expenseType: o.expenseType,
                                                expenseNote:o.expenseNote,
                                                quantity:o.quantity,
                                                rate:o.rate,
                                                amount: o.amount,
                                                uom: o.uom,
                                                isActive:prop.isActive,
                                                costFactor:o.costFactor,
                                                costFactorDes:o.costFactorDes
                                                // alert:true,

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>

                            <IconButton
                                onClick={() => {

                                    var data = this.state.ExpenseList;
                                    data.find((o,i) => {

                                        if(o.id === key) {

                                            this.setState({deleteItem: true,
                                                id:o.id,});

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            //  data.splice(i,1);
                                            return true;
                                        }
                                    });

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>{" "}
                        </div>

                    )
                }
            )
        });
        //console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                approveButton:header.approveButton,
                recId:header.recId,
                project:header.project,
                createDate:header.createDateDesc,
                description:header.description,
                total:header.total,
                dueAmount:header.dueAmount,
                payNote:header.payNote,
                payDate:header.payDateDesc,
                ebId:header.ebId,
                ebNo:header.ebNo,
                department:header.department,
                paidAmount:header.paidAmount,
                fromDate:header.fromDateDesc,
                toDate:header.toDateDesc,
                status:header.status,
                createdBy:header.createdBy,
                companyId:header.companyId,

                ExpenseList:expensebookinglist,

            });
            if(header.ebNo!==null){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+header.ebNo+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
            }
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"32/"+header.recId+"/"+this.props.state.companyInfo.id,this.handleInvoiceFiles);
        }
    };
    handleInvoiceFiles=(resp)=>{
        if(resp.data){
            this.setState({filesList:resp.data})
        }
    };
    handleLineInvoiceFiles=(resp)=>{
        if(resp.data){
            this.setState({filesLineList:resp.data})
        }
    };
    handleDownloadedFile=(url)=>{
        window.open(url);
    };


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };

    onChangeValues = (event) => {
        var key=event.target.id;
        var value = event.target.value;
        var amt=0;
        if(key==="quantity"){
            if(value>0  ){
                amt=value*this.state.rate;
                this.setState({amount:amt})
            }

        }
        if(key==="rate"){
            if(value>0 && this.state.quantity>0)
            amt=value*this.state.quantity;
            this.setState({amount:amt});

        }

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };
    onChangeValues1 = (event) => {
        var value = event.target.value;
        var amt=0;
        var qty= this.state.quantity;

        //console.log("val "+value);
if(qty>0 && value>0){
    amt= value*qty;
}


        this.setState({
            [event.target.id]: value,loadEmp:true,amount:amt

        });

    };

    onSelect = (selectedValue, stateKey) => {


        this.setState({
            [stateKey]: selectedValue
        });
    };

    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
    };

    dateChanged = (date) => {
        if(date){
            var myDate=date;
            myDate=myDate.split("-");
            var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
            return new Date(newDate).getTime();
        }else{
            return "";
        }
       

    };
    dateChanged1= (date) => {
        var time= moment().format("HH:mm:ss");
        var myDate=date;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2]+" "+time;
        return new Date(newDate).getTime();

    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            updateItemAlert: false,
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if(this.state.deleteItem){
            var data = this.state.ExpenseList;
            data.splice(this.state.id,1);
            data.map((o,key)=>{
                o.id=key;
                o.actions= (
                    // we've added some custom button actions
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.ExpenseList;
                                data.find((o,i) => {
                                    console.log("key in edit",key);

                                    if(o.id === key){
                                        this.basicItemAlert("Do You Like To Update This Item?");
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        console.log("id in edit",o.id);
                                        this.setState({
                                            id:o.id,
                                            expenseItemId:o.expenseItemId,
                                            updateItemAlert:true,
                                            updateItem: true,
                                            dtlId:o.dtlId,
                                            hdrId:o.hdrId,
                                            expenseDate: o.expenseDate,
                                            expenseType:o.expenseType,
                                            expenseNote: o.expenseNote,
                                            quantity: o.quantity,
                                            rate: o.rate,
                                            amount:o.amount,
                                            uom: o.uom,
                                         // file:o.expenseDocument,
                                            // docName:o.docFileName,
                                            // fileType:o.docType,

                                            // alert:true,

                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit />
                        </IconButton>{" "}
                        { /* use this button to remove the data row */ }
                        <IconButton
                            onClick={() => {
                                var data = this.state.ExpenseList;
                                data.find((o,i) => {
                                    if(o.id === key){

                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        //if (this.state.approveStatus) {
                                        console.log("id in delete",o.id);
                                        if (this.state.approveButton && this.state.updateView) {


                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                            });

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                        }else if(!this.state.updateView){
                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                            });

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                        }
                                        return true;
                                        //  data.splice(i,1);
                                        // }
                                    }
                                });
                                this.setState({ExpenseList: data});

                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>
                    </div>

                );

            });

            //  if(this.state.approveStatus)
            // this.setState({
            //     deleteItem:false});
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            // expenseDate:"",
            // expenseType:"",
            // expenseNote:"",
            // quantity:"",
            // rate:"",
            // amount:"",
            // uom:"",
            // docFileName:"",

        });
    }

    hideAlertMessage() {
        if(this.state.isCreated){
            this.callClientExpenseList();
        }
        this.setState({
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            project:"",
            createDate:moment().format("DD-MM-YYYY"),
            description:"",
            total:"",
            advanceTaken:"",
            dueAmount:"",
            status:"",
            payNote:"",
            payDate:"",
            expenseDate:"",
            expenseType:"",
            expenseNote:"",
            quantity:"",
            rate:"",
            amount:"",
            uom:"",
            docFileName:"",
            fromDate:"",
            toDate:"",
            dept:"",
            ebId:"",
            ExpenseList:[],
            ebNo:"",
            empName:"",
            designation:"",
            department:"",
            costFactor:""
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = (status) => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {recId,project,createDate,description ,total,dueAmount,payNote,payDate,ebId,date1,dept,fromDate,toDate}=this.state;
            var ExpenseList = this.state.ExpenseList.map((o, key) => {
                return ({
                    dtlId:o.dtlId,
                    hdrId:o.hdrId,
                    expenseDate: this.dateChanged(o.expenseDate),
                    expenseType:o.expenseType,
                    expenseNote: o.expenseNote,
                    quantity: o.quantity,
                    rate: o.rate,
                    amount:o.amount,
                    uom:  o.uom,
                    costId:o.costId
                    // file:this.state.expenseDocument,
                    // docName:this.state.docFileName,
                    // fileType:this.state.docType
                })
            });

            var data = {
                "exboHdr": {
                    "recId": recId,
                    "project": project,
                    "createDate": this.dateChanged(createDate),
                    "description": description,
                    "total": total,
                    "dueAmount": dueAmount,
                    "payNote": payNote,
                    "payDate": this.dateChanged(payDate),
                    "ebId": ebId,
                    "department": dept,
                    "fromDate":this.dateChanged(fromDate),
                    "toDate":this.dateChanged(toDate),
                    "status": status !== "" && status !== 4 ? this.state.status : status,
                    "isActive": 1,
                    "userId": getCookie(cons.USERID),
                    "createdBy": getCookie(cons.USERID),
                    "updatedBy": getCookie(cons.USERID),
                    "updatedDateTime": this.dateChanged1(date1),
                    "companyId": this.props.state.companyInfo.id
                },
                "exboDtl":ExpenseList
            };

            if (recId !== ""&& ebId!=="" && project !== ""&& total!==""&& description!=="") {
                this.handleLoading();
                requestList(serverApi.EXPENSE_BOOKING_UPDATE, data, this.handleAddExpenseBooking)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {project,createDate,description ,total,payNote,payDate,ebId,fromDate,toDate,dept,ebNo}=this.state;

            var ExpenseList = this.state.ExpenseList.map((o, key) => {
                return ({

                    expenseDate: this.dateChanged(o.expenseDate),
                    expenseType:o.expenseType,
                    expenseNote: o.expenseNote,
                    quantity: o.quantity,
                    rate: o.rate,
                    amount:o.amount,
                    uom:  o.uom,
                    costId:o.costId
                    // file:this.state.expenseDocument,
                    // docName:this.state.docFileName,
                    // fileType:this.state.docType
                })
            });


            var data = {
                "exboHdr":{
                    "project":project,
                    "createDate":this.dateChanged(createDate),
                    "description":description,
                    "total":total,
                    "payNote":payNote,
                    "payDate":this.dateChanged(payDate),
                    "ebId":ebId,
                    "department":dept,
                    "fromDate":this.dateChanged(fromDate),
                    "toDate":this.dateChanged(toDate),
                    "acYear":getCookie(cons.ACADEMIC_YEAR),
                    "createdBy":getCookie(cons.USERID),
                    "companyId": this.props.state.companyInfo.id
                },
                "exboDtl":ExpenseList

            };
            var ebStatus=0;
            if((ebId!==""&&ebId!==null))
                ebStatus=1;
            else if(ebNo!==""&&ebNo!==null)
                ebStatus=2;

            if (ebStatus===1 && project !== ""&& total!==""&& description!=="") {
                this.handleLoading();
                requestList(serverApi.EXPENSE_BOOKING_ADD, data, this.handleAddExpenseBooking)
            }else if(ebStatus!==1){
                if(ebStatus===0)
                    this.basicAlert("Please Enter EB Number..!!");
                if(ebStatus===2)
                    this.basicAlert("Please Click on Search Button of EB Number..!!");
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

        }
    };
    handleAddExpenseBooking = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem(){
        var arr=this.state.ExpenseList;

        var itemIndex = 0,expenseItemId=0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            expenseItemId=this.state.expenseItemId
            //   arr[itemIndex].quantity = this.state.quantity
        } else {
            itemIndex = this.state.ExpenseList.length
        }


        var addItemObj={

            id:itemIndex,
            expenseItemId:expenseItemId,
            expenseDate: this.state.expenseDate,
            expenseType: this.state.expenseType,
            expenseNote: this.state.expenseNote,
            quantity: this.state.quantity,
            rate: this.state.rate,
            amount: this.state.amount,
            uom: this.state.uom,
            costFactorDes:this.state.costFactorDes,
            costId:this.state.costFactor,
            // file:this.state.expenseDocument,
            // docName:this.state.docFileName,
            // fileType:this.state.docType,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {

                            var data = this.state.ExpenseList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id:o.id,
                                        expenseItemId:o.expenseItemId,
                                        updateItemAlert:true,
                                        updateItem: true,
                                        expenseDate: o.expenseDate,
                                        expenseType:o.expenseType,
                                        expenseNote: o.expenseNote,
                                        quantity: o.quantity,
                                        rate: o.rate,
                                        amount:o.amount,
                                        uom: o.uom,
                                        //costFactorDes:o.costFactorDes,
                                        costId:o.costId
                                        // file:o.expenseDocument,
                                        // docName:o.docFileName,
                                        // fileType:o.docType,

                                        // alert:true,

                                    });
                                    return true;

                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.ExpenseList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({deleteItem: true,
                                        id:o.id,});
                                     // data.splice(i,1);
                                    return true;
                                }
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close />
                    </IconButton>{" "}
                </div>

            )
        };


        if (this.state.expenseDate!=='' && this.state.quantity!=='' && this.state.rate !=='' && this.state.amount !=='' && this.state.uom!==''&& this.state.costFactorDes!='') {
            if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
            } else{
                arr.splice(arr.length, 0, addItemObj);

            }
           var totalAmount=0;
            arr.map((k,i)=>{
                totalAmount +=  k.amount;
            });

            this.setState({
                //loading:,
                total:totalAmount,
                dueAmount:totalAmount,
                ExpenseList:arr
            });
            this.hideAlert();
        } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }

    }
    handleAddItem () {
        // const {classes}=this.props;
         const {ebId}=this.state;

        //  var id="";
        // console.log(this.state);
         if(ebId !== "" && ebId!==0)
            this.setState({
                loading:true,
                alert:true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'XLSX') {
                    this.setState({
                        file: file,
                        fileName: file.name,
                        fileType:fileName[1],
                        //filesList: arr,
                        uploadLineFile: true
                        // imagePreviewUrl: reader.result
                    });
                    this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
                }


        };
        reader.readAsDataURL(file);
    }
    handleHeaderFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleHeaderFileChange;
        input.click();
    }
    handleHeaderFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let hFile = e.target.files[0];
        var hFileName = hFile.name, hFileType;
        hFileName = hFileName.split(".");
        hFileType = hFileName[1].toUpperCase();
        reader.onloadend = () => {
            if (hFileType === 'PDF' || hFileType === 'JPG' || hFileType === 'JPEG' || hFileType === 'PNG' || hFileType === 'XLSX') {
                    this.setState({
                        hFile: hFile,
                        hFileName: hFile.name,
                        hFileType:hFileName[1],
                        //filesList: arr,
                        uploadInvoice: true
                        // imagePreviewUrl: reader.result
                    });
                    this.InvoicebasicAlert("Do you want to upload file " + hFile.name + "?");
                }


        };
        reader.readAsDataURL(hFile);
    }
    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }
    hideInvoiceBasicAlert() {
        const {hFileName,hFileType,hFile,uploadInvoice,recId}=this.state;
        const {fileName,fileType,file,uploadLineFile,dtlId}=this.state;

        if(uploadLineFile){
            console.log("&&&&&&&&&&&&&&&",dtlId)
            uploadFile(fileName,file,fileType,33,fileName,dtlId, this.handleLineInvoiceResp);

        }


        if (uploadInvoice) {
            uploadFile(hFileName,hFile,hFileType,32,hFileName,recId, this.handleInvoiceResp);
            //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
            //  this.uploadInvoiceFile();
        }
        if(this.state.deleteLineInvoice){
             getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.lineInvoiceId, this.handleDeleteLineInvoiceResp)

        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }
    handleDeleteLineInvoiceResp=(resp)=>{
        if (resp.status) {
            var arr = this.state.filesLineList;
            arr.splice(this.state.fileId, 1);
            this.setState({
                filesLineList: arr,
                deleteLineInvoice: false,
                lineFileId: ""
            });
    }
    }
    handleDeleteInvoiceResp = (resp) => {
        if (resp.status) {
                var arr = this.state.filesList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    filesList: arr,
                    deleteInvoice: false,
                    fileId: ""
                });
        }

    };
    
    handleInvoiceResp = (resp) => {
        console.log("file resp",resp);
        var data = resp;
        if (resp.status) {

//            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

                var arr = this.state.filesList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.hFileName,fileExtension:this.state.hFileType,"retrievalPath":data.fileUrl,sourceMenu:32};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadInvoice: false,
                    filesList: arr,
                    basicAlert: null
                });


        }
        console.log("resp", JSON.stringify(resp));

    };
    handleLineInvoiceResp=(resp)=>{
        console.log("file resp",resp);
        var data = resp;
        if (resp.status) {

//            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

                var arr = this.state.filesLineList;
                data={"fileUploadId": data.id,
                    "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:33};
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadLineFile: false,
                    filesLineList: arr,
                    basicAlert: null
                });


        }
        console.log("resp", JSON.stringify(resp));

    }
   
    getInvoiceTablePageSize=()=>{
        var length =this.state.ExpenseList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    componentDidMount() {

        this.callClientExpenseList();
        this.getWorkersList();

    }

    callClientExpenseList(){
        getDataFromUrl(serverApi.EXPENSE_BOOKING_LIST+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID),this.handleTabelList);

    }

    handleTabelList =(resp)=>{
        if(resp.status===undefined)
        this.setState({tableList:resp,isRefresh:true})
        else
            this.setState({tableList:null,isRefresh:true})


    };
    getWorkersList=()=>{
        var data={
            "companyId": this.props.state.companyInfo.id,
            "deptId":0,
            "empCode": "",
            "empName": "",
            "esiNo":"",
            "payScheme":"",
            "pfNo": "",
            "reporting":0,
            "userId":getCookie(cons.USERID)
        };

        requestList(serverApi.TABLE_WORKERS_LIST,data,this.handleworkers)
    };
    handleworkers = (resp) =>{
        var data=[];
        data= resp.data.map(prop=>{
            var obj={label:prop.workerName,value:prop.ebId,name:prop.workerName+"^"+prop.departmentId};
            return obj;
        });

        this.setState({
            empList:data,
            loadEmp:true
        });
        //}
        //  this.handleLoading();

    };
    getEmpDetails=()=>{
        const {ebNo} = this.state;
        if(ebNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+ebNo+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(ebNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                empName: header.workerName,
                department:header.deptIdDesc,
                designation:header.designationIdDesc,
                loadDept:true,
                ebId:header.ebId
            });

        }else {
            this.showMessage(resp.message,false)
        }


    };


    render() {
        const {classes} = this.props;

        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: "26", label: 'PLANNING' ,name:'PLANNING'},
            { value: "27", label: 'VISITED',name: 'VISITED' }
        ];

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"Add"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer xs={12} md={12}>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Expense Date<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <FormControl variant="outlined" fullWidth>
                                                                                    <Datetime
                                                                                        value={this.state.expenseDate}
                                                                                        closeOnSelect={true}
                                                                                        timeFormat={false}
                                                                                        onChange={(dateObj) => {
                                                                                            this.timeChanged(dateObj, "expenseDate")
                                                                                        }}
                                                                                        formControlProps={
                                                                                            {height:"10px",inlineBlock: "none"}
                                                                                        }

                                                                                        inputProps={{
                                                                                            id:"expenseDate",
                                                                                            value:this.state.expenseDate,
                                                                                            autoComplete: 'off'
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>


                                                                    {/* <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Expense Type <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="expenseType"
                                                                                    value={this.state.expenseType}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid> */}
                                                                    {/* <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Expense Note <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="expenseNote"
                                                                                    value={this.state.expenseNote}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid> */}
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quantity <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="quantity"
                                                                                    value={this.state.quantity}
                                                                                    isRefresh={this.state.loadEmp}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Rate <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="rate"
                                                                                    value={this.state.rate}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Units <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="uom"
                                                                                    value={this.state.uom}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Amount <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                     disabled={true}
                                                                                    id="amount"
                                                                                    value={this.state.amount}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Cost Center <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <SelectboxTemp
                                                                                id={"costFactor"}
                                                                                onSelect={this.onSelect}
                                                                                defValue={this.state.costFactor}
                                                                                getName={(name)=>{
                                                                                    this.setState({costFactorDes:name})
                                                                                }}
                                                                                stateKey="costFactor"
                                                                                url={serverApi.SEARCH_COST_FACTOR + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                                            />
                                                                        </ItemGrid>
                                                                    </GridContainer>

                                                                </ItemGrid>
                                                                
                                                                    {/* <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer> */}
                                                                        {this.state.updateView?<ItemGrid xs={12} sm={12}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Support Documents :
                                                                                </InputLabel>
                                                                            </ItemGrid>:null}
                                                                            {this.state.updateView?<ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                                                                {this.state.filesLineList.map((r, i) => (
                                                                                <GridContainer style={{marginTop: "10px"}}>
                                                                                    <ItemGrid xs={12} sm={2}>
                                                                                         {i + 1}
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={6}>
                                                                                        <a onClick={()=>{
                                                                                            getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                                                        }}>
                                                                                            {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                                                        </a>
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={4}>
                                                                                        <IconButton
                                                                                            right={true}
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    deleteLineInvoice: true,
                                                                                                    lineInvoiceId: r.fileUploadId,
                                                                                                    lineFileId: i
                                                                                                });
                                                                                                this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                                                            }}

                                                                                            color="dangerNoBackground"
                                                                                        >
                                                                                            <Close/>
                                                                                        </IconButton> {""}

                                                                                    </ItemGrid>

                                                                                </GridContainer>

                                                                                ))}

                                                                            </ItemGrid>:null}
                                                                            {this.state.updateView&&this.state.status!=="4" && this.state.status!=='3'? <ItemGrid xs={4}>
                                                                             <Button color={"info"} right={true}
                                                                                            onClick={this.handleFileClick}>Upload File</Button>
                                                                            </ItemGrid>:null}
                                                                        {/* </GridContainer>
                                                                    </ItemGrid> */}
                                                                </GridContainer>
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }
                                    {this.state.basicAlert1}
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    EB No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="ebNo"
                                                    value={this.state.ebNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}


                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="empName"
                                                    value={this.state.empName}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Employee<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.ebId}
                                                    id={"ebId"}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        var val=name.split("^")
                                                        if(val[1]!==null)
                                                            this.setState({dept:parseInt(val[1]),loadDept:true})
                                                    }}
                                                    isRefresh={this.state.loadEmp}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadEmp:false})
                                                    }}
                                                    stateKey="ebId"
                                                    staticData={this.state.empList}
                                                    //  url={serverApi.DATATABLE_SERVICE_URL + this.state.item + "/getQualityByItem"}
                                                    value={this.state.ebId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Department
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                {/* <SelectboxTemp
                                                    defValue={this.state.dept}
                                                    id={"dept"}
                                                    onSelect={this.onSelect}
                                                    isReload={this.state.loadDept}
                                                    setIsReload={(val)=>{
                                                        this.setState({loadDept:false})
                                                    }}
                                                    stateKey="dept"
                                                    url={serverApi.SELECT_SUB_DEPARTMENT_MASTER+this.props.state.companyInfo.id}
                                                    value={this.state.dept}
                                                /> */}
                                                <TextField
                                                    //disabled={true}
                                                    id="department"
                                                    value={this.state.department}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Designation
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="designation"
                                                    value={this.state.designation}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Project<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="project"
                                                    value={this.state.project}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Description<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="description"
                                                    value={this.state.description}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    height={80}
                                                    rowsMax={4}
                                                    multiline={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>




                                    {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Pay Note<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="payNote"
                                                    value={this.state.payNote}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Create Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        value={this.state.createDate}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "createDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"createDate",
                                                            value:this.state.createDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    {/* <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Pay Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "payDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"payDate",
                                                            value:this.state.payDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid> */}
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Expenses From<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "fromDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"fromDate",
                                                            value:this.state.fromDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Expenses To<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "toDate")
                                                        }}
                                                        formControlProps={
                                                            {height:"10px",inlineBlock: "none"}
                                                        }

                                                        inputProps={{
                                                            id:"toDate",
                                                            value:this.state.toDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {/*{console.log(this.state.leavePolicieList.length+" length")}*/}
                                                <ReactTable
                                                    data={this.state.ExpenseList}
                                                    filterable={false}
                                                    resizable={false}
                                                    loading={this.state.loading}
                                                    getTableProps={() => {
                                                        return {
                                                            style: {
                                                                border: "2px solid #00ABDC",
                                                                borderRadius: "4px"
                                                            }
                                                        }}}

                                                    getTheadProps={() => {
                                                        return {
                                                            style: {
                                                                borderBottom: "2px solid #00ABDC",
                                                                //height:45
                                                                //lineHeight:"1.3"
                                                            }
                                                        }}}
                                                    getTheadThProps={() => {
                                                        return {
                                                            style: {
                                                                borderRight: "1px solid #999999",
                                                                textAlign:"center",
                                                                whiteSpace:"pre-wrap",
                                                                lineHeight:1.3,
                                                            }
                                                        }}}
                                                    style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}
                                                    columns={[
                                                        {
                                                            Header: "Expense Date",
                                                            accessor: "expenseDate",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Cost Center",
                                                            accessor: "costFactorDes",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        // {
                                                        //     Header: "Expense Type",
                                                        //     accessor: "expenseType",
                                                        //     minWidth:100,
                                                        //     style: {color: "#000", textAlign: "left"}
                                                        // },
                                                        // {
                                                        //     Header: "Expense Note",
                                                        //     accessor: "expenseNote",
                                                        //     minWidth:100,
                                                        //     style: {color: "#000", textAlign: "left"}
                                                        // },
                                                        {
                                                            Header: "Quantity",
                                                            accessor: "quantity",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Rate",
                                                            accessor: "rate",
                                                            width: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Units",
                                                            accessor: "uom",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Amount",
                                                            accessor: "amount",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        // {
                                                        //     Header: "Attachment",
                                                        //     accessor: "docName",
                                                        //     minWidth:100,
                                                        //     style: {color: "#000", textAlign: "left"}
                                                        // },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                    pageSize={this.getInvoiceTablePageSize()}
                                                    //  defaultPageSize={5}
                                                    sortable={false}
                                                    getNoDataProps={()=>{
                                                        return{
                                                            style:{
                                                                display:"none"
                                                            }

                                                        };
                                                    }}
                                                    className="-striped -highlight"
                                                />
                                            </ItemGrid>
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        {this.state.approveButton?
                                                            <ItemGrid xs={6}>
                                                                <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                            </ItemGrid>:null}
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                </ItemGrid>

                                            }
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Total<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="total"
                                                    value={this.state.total}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Due Amount<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="dueAmount"
                                                    value={this.state.dueAmount}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                         Paid Amount
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="paidAmount"
                                                        value={this.state.paidAmount}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                        {this.state.updateView? <ItemGrid xs={12} sm={12}>
                                        <InputLabel className={classes.label}>
                                            Support Documents :
                                        </InputLabel>
                                    </ItemGrid>:null}
                                    {this.state.updateView? <ItemGrid xs={8}  style={{overflowY: "auto", maxHeight: 250}}>
                                        {this.state.filesList.map((r, i) => (
                                            <GridContainer style={{marginTop: "10px"}}>
                                                <ItemGrid xs={12} sm={2}>
                                                    {i + 1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                        getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                    }}>
                                                        {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                    </a>
                                                </ItemGrid>
                                                <ItemGrid xs={4}>
                                                    <IconButton
                                                        right={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                deleteInvoice: true,
                                                                invoiceId: r.fileUploadId,
                                                                fileId: i
                                                            });
                                                            this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        }}

                                                        color="dangerNoBackground"
                                                    >
                                                        <Close/>
                                                    </IconButton> {""}

                                                </ItemGrid>

                                            </GridContainer>

                                        ))}

                                    </ItemGrid>:null}
                                    {this.state.updateView&&this.state.status!=="4" && this.state.status!=='3'? <ItemGrid xs={4}>
                                        <Button color={"info"} right={true}
                                    onClick={this.handleHeaderFileClick}>Upload File</Button>
                                    </ItemGrid>:null}

                                    {/*{this.state.updateView ?*/}
                                        {/*<ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>*/}
                                            {/*<GridContainer>*/}
                                                {/*<ItemGrid xs={12} sm={4}>*/}
                                                    {/*<InputLabel className={classes.label}>*/}
                                                        {/*Updated By*/}
                                                    {/*</InputLabel>*/}
                                                {/*</ItemGrid>*/}
                                                {/*<ItemGrid xs={12} sm={8}>*/}
                                                    {/*<TextField*/}
                                                        {/*disabled={true}*/}
                                                        {/*id="updateBy"*/}
                                                        {/*value={this.state.updateBy}*/}
                                                        {/*className={classes.textField}*/}
                                                        {/*onChange={this.onChangeValues}*/}
                                                        {/*fullWidth={true}*/}
                                                        {/*InputProps={inputFocus}*/}

                                                    {/*/>*/}

                                                {/*</ItemGrid>*/}
                                            {/*</GridContainer>*/}
                                        {/*</ItemGrid> : null}*/}

                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            {this.state.approveButton ?
                                            <GridContainer justify="center">

                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{this.onUpdateButtonClick("")}}>Update</Button>
                                                </ItemGrid>

                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Approve</Button>
                                                </ItemGrid>

                                            </GridContainer>:
                                                <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={
                                                                ()=>{this.onUpdateButtonClick(4)}
                                                            }>Reject</Button>
                                                </ItemGrid>
                                                </GridContainer>}

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                               // url={serverApi.CLIENT_VISIT_LIST+"/"+this.props.state.companyInfo.id}
                                                staticData={this.state.tableList}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={false}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};

function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(ExpenseBooking)
);
