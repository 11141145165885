import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import FormHelperText from "material-ui/Form/FormHelperText";
import Input from "material-ui/Input";
import InputLabel from "material-ui/Input/InputLabel";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import {defaultFont} from "../../assets/jss/material-dashboard-pro-react";

function CustomTextField({...props}) {
    const {
        inputClass,
        classes,
        formControlProps,
        labelText,
        id,
        height,
        labelProps,
        InputProps,
        error,
        success,
        helpText,
        rtlActive,
        iType,
        onChange,
        value,
        defValue,
        onKeyPress,
        disabled,
        multiline,
        rowsMax,
        autoFocus,
        backgroundColor,
        decimal
    } = props;

    var labelClasses = cx({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    var formControlClasses = classes.formControl;
    if (formControlProps !== undefined) {
        formControlClasses += " " + formControlProps.className;
    }
    var underlineClasses = cx({
        [classes.underline]: false,
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
    });
    if (InputProps !== undefined) {
        formControlClasses =
            formControlClasses +
            " " +
            cx({
                [classes.inputWithAdornment]:
                (InputProps.startAdornment !== undefined ||
                    InputProps.endAdornment !== undefined) &&
                labelText === undefined
            });
    }
    if (InputProps !== undefined) {
        labelClasses =
            labelClasses +
            " " +
            cx({
                [classes.labelWithAdornment]: InputProps.endAdornment !== undefined
            });
    }
    const successClasses =
        classes.feedback +
        " " +
        classes.labelRootSuccess +
        " " +
        cx({
            [classes.feedbackNoLabel]: labelText === undefined,
            [classes.feedbackAdorment]:
            InputProps !== undefined && InputProps.endAdornment !== undefined
        });
    const errorClasses =
        classes.feedback +
        " " +
        classes.labelRootError +
        " " +
        cx({
            [classes.feedbackNoLabel]: labelText === undefined,
            [classes.feedbackAdorment]:
            InputProps !== undefined && InputProps.endAdornment !== undefined
        });
    const input =
        inputClass +
        " " +
        cx({
            [classes.inputRTL]: rtlActive,
            [classes.inputNoLabel]: labelText === undefined
        });

    function getValue(value) {
        var numberVal=value+"",decimal1=false;
        if(numberVal!==""&&!isNaN(parseFloat(value))){
            numberVal = numberVal.split(".");
            if(decimal!==undefined&&decimal!==null){
                if (numberVal[1] !== undefined && numberVal[1].length > decimal&&!isNaN(parseFloat(numberVal[1]))) {
                    decimal1 = true;
                } else {
                    decimal1 = false
                }
            }else {
                if (numberVal[1] !== undefined && numberVal[1].length > 2 && !isNaN(parseFloat(numberVal[1]))) {
                    decimal1 = true;
                } else {
                    decimal1 = false
                }
            }
        }
        return decimal1
    }


    return (
        <FormControl
            fullWidth={true}
            {...formControlProps}
            className={formControlClasses}
            aria-describedby={id + "-text"}>

            <Input
                className={classes.input}
                autoFocus={autoFocus}
                classes={{
                    input: inputClass,
                    disabled: classes.disabled,
                    focused: classes.inputFocused}}

                // className={"input"}
                disableUnderline={true}
/*
                classes={{
                    root:classes.root,
                    input: input,
                    disabled: classes.disabled,
                    focused:{border:"2px solid #00ADBC"},
                   // underline: underlineClasses,
                }}
*/
                style={{height:height,backgroundColor:backgroundColor}}
                disabled={disabled}
                defaultValue={defValue}
                value={value}
                id={id}
                type={iType}
                onChange={(event)=>{
                    var eventvalue=event
                    if(getValue(event.target.value)&&iType==='number'){
                        if(decimal!==undefined&&decimal!==null){
                            var numberVal = parseFloat(value).toFixed(decimal);
                        }else
                       var numberVal = parseFloat(value).toFixed(6);
                        eventvalue.target.value=numberVal;
                        onChange(eventvalue);
                    }else {
                        onChange(event)
                    }
                }}
                onKeyPress={onKeyPress}
                multiline={multiline}
                rowsMax={rowsMax}
                {...InputProps}
            />
            {error ? (
                <Clear className={errorClasses}/>
            ) : success ? (
                <Check className={successClasses}/>
            ) : null}
            {helpText !== undefined ? (
                <FormHelperText id={id + "-text"}>{helpText}</FormHelperText>
            ) : null}
        </FormControl>
    );
}
const styles = theme => ({
    input: {
        color:"#000",
        fontFamily:defaultFont,
        paddingLeft:"10px",
        textAlign:'right',
       // width: '20%',
        height:"30px",
        borderRadius: 4,
       // backgroundColor: 'white',
        border: '1px solid #D2D2D2',
        fontSize: "14px",
        fontWeight: "400",
    },
/*    inputClass: {
        padding: "2px 0 0",
        fontWeight: "400",
    },*/
    // Separate this part into it's own CSS class
    inputFocused: {
        border:"2px solid #00ABDC",
      //  boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
       // backgroundColor: "#00ADBC",
    },
    feedbackNoLabel: {
       // top: "8px"
    },
    inputNoLabel: {
        paddingTop: "18px"
    },
    inputRTL: {
        textAlign: "right"
    },
    inputWithAdornment: {
       // paddingTop: "21px"
    },
    formControl: {
        paddingBottom: "10px",
        position: "relative"
    },
    labelWithAdornment: {
        top: "3px"
    },
    feedbackAdorment: {
        right: "22px"
    },

    disabled: {
        color:"#000"
    },

});

CustomTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    inputClass:PropTypes.object,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    InputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    helpText: PropTypes.string,
    rtlActive: PropTypes.bool,
    isOpen:PropTypes.bool,
    getItemProps:PropTypes.object,
    value:PropTypes.string,
    defValue:PropTypes.string,
    highlightedIndex:PropTypes.int,
    multiline:PropTypes.bool,
    rowsMax:PropTypes.string,
    autoFocus:PropTypes.bool,
    iType:PropTypes.string,

};

export default withStyles(styles)(CustomTextField);
