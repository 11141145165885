import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";

import sweetAletStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import bgImage from "assets/img/register.jpeg";

import {removeCookies} from '../helper/Cookies';
/*import {request} from "../server/server";
import {taskCodes} from "../helper/Consts";
import cons from "../helper/Consts";*/
import isLoggedIn from "../helper/isLoggedIn";

// var ps;

class Logout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null
        }
    }

    redirectToHome = () => {
            this.props.history.push('/Login');
    };
    checkLogin = () => {
        console.log("isLoggedIn()  "+isLoggedIn());
        if (!isLoggedIn()) {
            this.props.history.push('/Login');
        }
    };

    showLoggedOutSuccess = (msg) => {

        removeCookies();
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{display: "block", marginTop: "-100px"}}
                    title={msg}
                    onConfirm={() => this.redirectToHome()}
                    onCancel={() => this.redirectToHome()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    };
    handleRsp = (resp) => {
        if (resp.status) {
            if(removeCookies())
            this.showLoggedOutSuccess("You Have Logged Out Successfully!!!");
        } else {
            removeCookies();
            this.showLoggedOutSuccess(resp.msg);
        }
    };
    componentDidMount() {
      /*  this.checkLogin();
        var data = {

        };
        request(taskCodes.LOGOUT, data, this.handleRsp);*/
        this.showLoggedOutSuccess("You have LoggedOut Successfully!!");
    }

    componentWillUnmount() {

    }


    render() {

        const {classes} = this.props;
        return (
            <div>
                {this.state.alert}
                <div
                    className={classes.fullPageBackground}
                    style={{backgroundImage: "url(" + bgImage + ")"}}
                />
            </div>
        );
    }
}

Logout.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(sweetAletStyle)(Logout);
