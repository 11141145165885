import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Manager, Target, Popper } from "react-popper";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import MenuItem from "material-ui/Menu/MenuItem";
import MenuList from "material-ui/Menu/MenuList";
import ClickAwayListener from "material-ui/utils/ClickAwayListener";
import Paper from "material-ui/Paper";
import Grow from "material-ui/transitions/Grow";
import IconButton from "material-ui/IconButton";
//import Hidden from "material-ui/Hidden";

// @material-ui/icons
/*import Notifications from "@material-ui/icons/Notifications";
import ExitToApp from "@material-ui/icons/ExitToApp";

import Tooltip from "material-ui/Tooltip";*/

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import defaultImage from "assets/img/default-avatar.png";
import Cookies from "universal-cookie";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { settingsRoutes } from "../../routes/dashboard";
import Collapse from "@material-ui/core/Collapse";
import { getCookie, setCookie } from "../../helper/Cookies";
import cons, {
  configNames,
  getConfigValue,
  PHP_LINK,
  serverApi,
  serverConfig,
} from "../../helper/Consts";
import { getDataFromUrl, getFileDownload } from "../../server/server";
import SelectboxTemp from "../../views/CustumComponents/SelectboxTemp";
import GridWrapper from "material-ui/Grid/Grid";
import GridContainer from "../Grid/GridContainer";
import ItemGrid from "../Grid/ItemGrid1";
import {
  IsMenuAvailable,
  IsTallyAvailable,
} from "../../views/CustumComponents/PermissionAlert";
import { connect } from "react-redux";
class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      userName: "Profile Name",
      imagePreviewUrl: null,
      yearsData: [],
      yearOpen: false,
    };
    this.activeRoute.bind(this);
  }
  componentDidMount() {
    if (
      getCookie(cons.ACADEMIC_YEAR_LIST) !== undefined &&
      getCookie(cons.ACADEMIC_YEAR_LIST) !== "undefined" &&
      getCookie(cons.ACADEMIC_YEAR_LIST) !== "null"
    )
      this.setState({
        yearsData: JSON.parse(getCookie(cons.ACADEMIC_YEAR_LIST)),
      });
    if (
      getCookie(cons.EB_ID) !== null &&
      getCookie(cons.EB_ID) !== undefined &&
      getCookie(cons.EB_ID) !== "null"
    )
      console.log("printing null file here", getCookie(cons.EB_ID));
    // getDataFromUrl(
    //   serverApi.GET_FILE_BY_SOURCE +
    //     "1/" +
    //     getCookie(cons.EB_ID) +
    //     "/" +
    //     this.props.state.companyInfo.id,
    //   this.handleProfileImage
    // );
    //   getDataFromUrl(serverApi.GET_ACADEMIC_YEAR+this.props.state.companyInfo.id,this.handleYears)
  }
  handleProfileImage = (resp) => {
    console.log("profileimage resp", resp);
    if (resp.status) {
      if (resp.data.length > 0)
        getFileDownload(
          serverConfig.SERVER_URL + resp.data[0].retrievalPath,
          resp.data[0].fileName,
          resp.data[0].fileExtension,
          this.handleDownloadedFile
        );
    }
  };
  handleDownloadedFile = (url) => {
    this.setState({ imagePreviewUrl: url });
  };

  handleYears = (resp) => {
    console.log("years", resp);
    var yearsData = [];
    resp.data.forEach((prop, i) => {
      var obj = { label: prop.displayLable, value: prop.year };
      yearsData.push(obj);
      if (i === 0) {
        setCookie(cons.ACADEMIC_YEAR, prop.year);
      }
    });

    this.setState({ yearsData: yearsData });
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleYearBtnClick = () => {
    this.setState({ yearOpen: !this.state.yearOpen });
  };
  handleClose = () => {
    this.setState({ open: false, yearOpen: false });
  };
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  handleYearClick = (val) => {
    setCookie(cons.ACADEMIC_YEAR, val);
  };

  render() {
    const ck = new Cookies();
    const {
      classes,
      rtlActive,
      handleLogout,
      handleProfile,
      handleChanagePassword,
      handleSettings,
      color,
      handleTallySync,
    } = this.props;
    const { open, yearOpen } = this.state;
    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: rtlActive,
      });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    var hostName = window.location.hostname;
    var isTally = IsTallyAvailable("Tally Sync");

    return (
      <div className={wrapper} style={{ marginRight: "70px" }}>
        <div>
          <Manager className={managerClasses}>
            <Target>
              <GridContainer>
                {/* <ItemGrid xs={1}> */}

                <span
                  onClick={this.handleYearBtnClick}
                  className={classes.linkText}
                >
                  FY
                </span>
                {/* </ItemGrid> */}
                <ItemGrid xs={6}>
                  {this.state.yearsData.length > 0 ? (
                    <SelectboxTemp
                      id={"year"}
                      defValue={
                        getCookie(cons.ACADEMIC_YEAR) !== undefined
                          ? parseInt(getCookie(cons.ACADEMIC_YEAR))
                          : ""
                      }
                      onSelect={(selectedValue, stateKey) => {
                        if (
                          getCookie(cons.ACADEMIC_YEAR) !==
                          selectedValue + ""
                        ) {
                          setCookie(cons.ACADEMIC_YEAR, selectedValue);
                          this.setState({ [stateKey]: selectedValue });
                          this.props.refreshDashboard();
                        }
                      }}
                      getName={(name) => {
                        if (name) {
                          name = name.split("^");
                          setCookie(cons.YEAR_FROM_DATE, name[0]);
                          setCookie(cons.YEAR_TO_DATE, name[1]);
                          console.log("yeardates", name[0], name[1]);
                        }
                      }}
                      stateKey="year"
                      staticData={this.state.yearsData}
                      value={this.state.year}
                    />
                  ) : null}
                </ItemGrid>
                <ItemGrid xs={4}>
                  <span
                    className={classes.nameText}
                    onClick={this.handleClick}
                    style={{ marginLeft: "40px" }}
                  >
                    {getCookie(cons.USER_NAME)}
                  </span>
                </ItemGrid>
                <ItemGrid xs={1}>
                  <IconButton
                    color="inherit"
                    aria-owns={open ? "menu-list" : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className={
                      rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                    }
                    classes={{
                      label: rtlActive ? classes.labelRTL : "",
                    }}
                  >
                    <div className="fileinput text-center">
                      <div className={"thumbnail" + " img-circle"}>
                        <img
                          src={
                            getCookie(cons.EB_ID) !== undefined &&
                            getCookie(cons.EB_ID) !== "null" &&
                            this.state.imagePreviewUrl !== null
                              ? this.state.imagePreviewUrl
                              : defaultImage
                          }
                          className="picture-src"
                          alt="..."
                          style={{ width: 40, height: 40, color: "#FFF" }}
                        />
                      </div>
                    </div>
                  </IconButton>
                </ItemGrid>
              </GridContainer>
            </Target>

            <Popper
              placement="bottom-end"
              eventsEnabled={open}
              className={
                classNames({ [classes.popperClose]: !open }) +
                " " +
                classes.pooperResponsive
              }
            >
              <ClickAwayListener onClickAway={this.handleClose}>
                <Grow
                  in={open}
                  id="menu-list"
                  style={{ transformOrigin: "0 0 0" }}
                >
                  <Paper
                    className={classes.dropdown}
                    onClick={this.handleClose}
                  >
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleProfile}
                        className={dropdownItem}
                      >
                        My Profile
                      </MenuItem>
                      {hostName === "erp.itis.com.sa" ||
                      hostName === "itis.vowerp.com" ? (
                        <MenuItem
                          onClick={() => {
                            window.open(PHP_LINK, "_blank");
                          }}
                          className={dropdownItem}
                        >
                          Training Module
                        </MenuItem>
                      ) : null}
                      {hostName === "erp.itis.com.sa" ||
                      hostName === "itis.vowerp.com" ? (
                        <MenuItem
                          onClick={() => {
                            window.open(PHP_LINK, "_blank");
                          }}
                          className={dropdownItem}
                        >
                          Reporting Module
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        onClick={handleSettings}
                        className={dropdownItem}
                      >
                        Settings
                      </MenuItem>
                      {isTally ? (
                        <MenuItem
                          onClick={handleTallySync}
                          className={dropdownItem}
                        >
                          Tally Sync
                        </MenuItem>
                      ) : null}
                      <MenuItem onClick={handleLogout} className={dropdownItem}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              </ClickAwayListener>
            </Popper>
          </Manager>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(headerLinksStyle)(HeaderLinks)
);
