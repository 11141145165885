import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import Print from "@material-ui/icons/Print";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import PaginationTableComponent from "../CustumComponents/PaginationTableComponent";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxComponent from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

import withStyles from "material-ui/styles/withStyles";

import TextField from "../../components/CustomInput/CustomTextField";

import { connect } from "react-redux";
import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import {
  configNames,
  getConfigValue,
  getDecimal,
  serverApi,
  getTenantId,
  serverConfig,
} from "../../helper/Consts";
import { VIEW, ATTACHMENT } from "../../ui-actions/UiActions";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
  uploadFormdataWithImage,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import InputAdornment from "material-ui/Input/InputAdornment";
import moment from "moment";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import {
  dangerColor,
  roseColor,
} from "../../assets/jss/material-dashboard-pro-react";
import LogsComponents from "../CustumComponents/LogsComponents";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import { validate } from "../../components/Validation/validate";

class JutePO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      basicAlert: null,
      isRefresh: false,
      isLoading: false,
      approveButton: false,
      showMessagealert: false,
      disableTax: false,
      // fromDate:
      //   getCookie(cons.YEAR_FROM_DATE) && getCookie(cons.YEAR_FROM_DATE) != null
      //     ? getCookie(cons.YEAR_FROM_DATE)
      //     : "",
      // todate:
      //   getCookie(cons.YEAR_TO_DATE) && getCookie(cons.YEAR_TO_DATE) != null
      //     ? getCookie(cons.YEAR_TO_DATE)
      //     : "",
      fromDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      poNote: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      supplier_Id:
        getCookie(cons.USER_ROLE_ID) === "100"
          ? getCookie(cons.SUPP_CODE)
          : null,
      poNo: "",
      hdrId: "",
      statusDesc: "",
      form_purchaseType: "",
      itemViewType: "id",
      taxType: "",
      SelectindentType: this.getDefaultindentConvertion(2),
      selectedItem: "",
      selectedItemGroup: "",
      selectedDepartment: "",
      itemtype: "",
      itemTaxAmt: 0,
      igstPercent: 0,
      cgstPercent: 0,
      sgstPercent: 0,
      totalTax: 0,
      sumOftax: 0,
      totalAmount: 0,
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate:
        this.props.state.companyInfo.id === "116"
          ? moment()
              .add("days", 10)
              .format("DD-MM-YYYY")
          : moment()
              .add("days", 15)
              .format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      createPO: "",
      date: "",
      poQuantity: "",
      creditTerm: 60,
      deliveryTimeLine: this.props.state.companyInfo.id === "116" ? 10 : 15,
      expectedDate: "",
      vehicleQuantiy: "",
      selectedSupplier: "",
      selectedJuteType: "",
      selectedIndent: "",
      netTotal: "",
      termConditions: "",
      selectedJutePO: "",
      POQuantity: "",
      selectedTax: "",
      alert: false,
      loadsupCode: "",
      indentAlert: false,
      allowMositure: 18,
      selectedIndentQuantity: "",
      indentNum: "",
      sumOfAmount: 0,
      sumOfweight: 0,
      depId: "",
      remarks: "",
      footerNote: "",
      delAddress:
        getCookie(cons.SHIPPING_ADDRESS) === null
          ? ""
          : getCookie(cons.SHIPPING_ADDRESS),
      invoiceList: [],
      item: "",
      itemId: "",
      lineItemId: "",
      indentLineId: null,
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      deducAmount: 0,
      duductaxAmount: 0,
      poStatus: "",
      taxId: "",
      branchId: "",
      isMail: false,
      lastPurchaseRate: "",
      lastPurchaseDate: "",
      projectIds: "",
      branchIds: "",
      internalNote: "",
      indentlist: null,
      pendingStock: 0,
      minStock: 0,
      maxStock: 0,
      billingBranchId: "",
      rqName: "",
      poValidDate: "",
      supplierList: [],
      indentType: "",
      filesList: [],
      typeId: 0,
      dataTable: null,
      addPendQty: true,
      showIndentAlert: false,
      latestPrice: "",
      latestPriceDate: "",
      indentHdrId: "",
      approvedIndents: [],
      phaseId1: 0,
      projectId1: 0,
      isWO: getCookie(cons.MENU_ID) == 513,
      InsRate: "",
      InsAmount: "",
      make: "",
      remarks1: "",
      discountMode: "1",
      discountPercentage: "",
      advanceType: "",
      advancePercentage: "",
      advanceAmount: "",
      customerList: [],
      customerId: "",
      oldQty: 0,
      termsList: [],
      contactPerson: "",
      contactNo: "",
      taxType: "",
      isSupplier: false,
      taxPayable: "",
      currencyType: "INR",
      WotermsList: [],
      deleteItem: false,
      itemIdNew: 0,
      dataTable: "",
      // startIndex: 0,
      pageSize: 10,
      startIndex: 0,
      lastIndex: 10,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    this.getList(this.state.startIndex, this.state.lastIndex);

    getDataFromUrl(
      serverApi.GET_CUSTOMER_BY_COMPANY + this.props.state.companyInfo.id,
      this.handelCustomer
    );

    this.setState({ isWO: getCookie(cons.MENU_ID) == 513 });
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.BRANCHMASTER_DATATABLE, data, this.handelbranch);
    var data2 = {
      itemGroupId: "997",
      saleable: "",
      consumable: "",
      companyId: this.props.state.companyInfo.id,
      indentTypeId: "",
    };
    requestList(serverApi.GET_ITEMGROUP, data2, this.handleCharges);
    if (getCookie(cons.USER_ROLE_ID) !== "100")
      getDataFromUrl(
        serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id,
        this.handleSupplier
      );
    this.getList();
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments
    );

    getDataFromUrl(
      serverApi.TERMS_AND_CONDITIONS_BY_ORG_LIST +
        "/" +
        this.props.state.companyInfo.id +
        "/" +
        "PO",
      this.handleTermsList
    );

    getDataFromUrl(
      serverApi.TERMS_AND_CONDITIONS_BY_ORG_LIST +
        "/" +
        this.props.state.companyInfo.id +
        "/" +
        "WO",
      this.handleWOTermsList
    );
    if (getCookie(cons.USER_ROLE_ID) == "100") {
      this.setState({ isSupplier: true });
    }
  }

  handleCharges = (resp) => {
    console.log("extra charges adding", resp);
    var list = [];

    resp.forEach((prop) => {
      list.push({
        label: prop.label,
        name: prop.name,
        value: prop.itemCode,
        item: prop.itemId,
      });
    });
    this.setState({ ItemOption: list, loadItem: true });
  };

  handleTermsList = (resp) => {
    console.log("TERMS_AND_CONDITIONS_LIST", resp);
    var list = [];
    if (resp && resp.length > 0) {
      resp.forEach((prop) => {
        list.push({
          label: prop.termsAndConditions,
          value: prop.autoId,
          name: prop.termsAndConditions,
        });
      });
    }
    this.setState({ termsList: list, loadTerms: true });
  };
  handleWOTermsList = (resp) => {
    console.log("TERMS_AND_CONDITIONS_LIST", resp);
    var list = [];
    if (resp && resp.length > 0) {
      resp.forEach((prop) => {
        list.push({
          label: prop.termsAndConditions,
          value: prop.autoId,
          name: prop.termsAndConditions,
        });
      });
    }
    this.setState({ WotermsList: list, loadTerms: true });
  };

  handelCustomer = (resp) => {
    var data = resp,
      customerList = [];
    if (data.length > 0) {
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.value, name: name };
        customerList.push(obj);
      });

      this.setState({
        customerList: customerList,
      });
    }
  };
  getList = (startIndex, lastIndex) => {
    var data = this.getData(startIndex, lastIndex);
    console.log("getting the start and end index", startIndex, lastIndex);
    // getDataFromUrl(serverApi.GET_ALL_PO ,this.handleList)
    requestList(
      // serverApi.GET_ALL_PO + "/" + startIndex + "/" + pageSize,
      serverApi.GET_ALL_PO,
      data,
      this.handleList
    );
  };
  handleDepartments = (resp) => {
    this.setState({
      budgetHeadList: resp,
      loadDepartment: true,
      loadDepartment1: true,
    });
  };
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      this.setState({
        projectNameList: projectNameList,
      });
    }
  };

  handlePhaseList = (resp) => {
    this.setState({
      phaseNameList: resp ? resp : [],
      loadPhaseName: true,
    });
  };
  handleList = (resp) => {
    var list = resp;
    var data = list.data;
    console.log("Nagesh", list);
    if (getCookie(cons.USER_ROLE_ID) === "100") {
      data = [];
      resp.data.forEach((prop) => {
        if (
          prop.status == 3 ||
          prop.status == 6 ||
          prop.status == 4 ||
          prop.status == 5
        )
          data.push(prop);
      });
      list.data = data;
    }
    if (resp) {
      this.setState({
        dataTable: list,
        isRefresh: true,
      });
    }
  };
  handleSupplier = (resp) => {
    if (resp.status === undefined) {
      this.setState({ supplierList: resp, loadSupplier: true });
    }
  };
  handelbranch = (resp) => {
    var branchArray = [];
    resp.data.map((p, i) => {
      var obj = {
        value: p.id,
        name:
          p.brname + "^" + p.address + "^" + p.stateCode + "^" + p.stateName,
        label: p.brname + "-" + p.address,
        address: p.address,
        branchName: p.brname,
        contactPerson: p.personName,
        contactNo: p.contactNo,
        gstNo: p.gstNo,
        email: p.email,
        billingStateCode: p.stateCode,
        billingStateName: p.stateName,
      };
      branchArray.push(obj);
    });

    this.setState({ branchOptions: branchArray });
  };
  getBranchDetailsFromId = (branchId) => {
    var val = null;
    this.state.branchOptions.forEach((prop) => {
      if (prop.value == branchId) {
        val = prop;
      }
    });

    return val;
  };
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };

  handleCreate = () => {
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }

    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      filesList: [],
    });
  };

  handleCreate2 = () => {
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    //window.location("/purchase_purchase_order");
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      filesList: [],
    });
  };
  dateChanged = (dateObj, key) => {
    if (typeof dateObj === "object") {
      console.log("getting the data here", dateObj, key);
      this.setState({
        [key]: dateObj.format("DD-MM-YYYY"),
        expecDate:
          key !== "poValidDate"
            ? this.props.state.companyInfo.id === "116"
              ? moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                  .add(10, "days")
                  .format("DD-MM-YYYY")
              : moment(dateObj.format("DD-MM-YYYY"), "DD-MM-YYYY")
                  .add(15, "days")
                  .format("DD-MM-YYYY")
            : this.state.expecDate,
      });
    }
  };
  getDefaultTaxConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name == 1) {
        val = 1;
        label = "IGST";
      } else if (name == 2) {
        val = 2;
        label = "CGST & SGST";
      } else {
        val = 3;
        label = "VAT";
      }
      data = {
        value: val,
        label: label,
      };
    }
    console.log("sss", data);
    return data;
  };
  getDefaultindentConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name == 1) {
        val = 1;
        label = "With Indent";
      } else {
        val = 2;
        label = "Without Indent";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  getData = (startIndex, lastIndex) => {
    console.log("getting the start and end index", startIndex, lastIndex);
    // var data = {
    //   purchaseType: "store",
    //   startDate: this.state.fromDate,
    //   endDate: this.state.todate,
    //   status: this.state.status,
    //   financialYear: getCookie(cons.ACADEMIC_YEAR),
    //   branchId: this.state.branchId !== 0 ? this.state.branchId : "",
    //   // phase: this.state.phaseId1 > 0 ? this.state.phaseId1 : "",
    //   phase: this.state.purchaseType > 0 ? this.state.purchaseType : "",
    //   project: this.state.projectId1 > 0 ? this.state.projectId1 : "",
    //   poNum: this.state.searchpoNo,
    //   companyId: this.props.state.companyInfo.id,
    //   supplierId: this.state.supplierId,
    //   recordType: getCookie(cons.MENU_ID) == 513 ? "WO" : "PO",
    //   customerId: this.state.customerId,
    // };
    // return data;
    var data = {
      purchaseType: "store",
      startDate: this.state.fromDate,
      endDate: this.state.todate,
      status: this.state.status,
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      branchId: this.state.branchId !== 0 ? this.state.branchId : "",
      // phase: this.state.phaseId1 > 0 ? this.state.phaseId1 : "",
      phase: this.state.purchaseType > 0 ? this.state.purchaseType : "",
      project: this.state.projectId1 > 0 ? this.state.projectId1 : "",
      poNum: this.state.searchpoNo,
      companyId: this.props.state.companyInfo.id,
      supplierId: this.state.supplierId,
      recordType: getCookie(cons.MENU_ID) == 513 ? "WO" : "PO",
      customerId: this.state.customerId,
      lastIndex: this.state.lastIndex ? this.state.lastIndex : lastIndex,
      startIndex: this.state.startIndex ? this.state.startIndex : startIndex,
    };
    return data;
  };

  handlePage = (start, lastIndex) => {
    console.log("Nagesh handlepage", start, lastIndex);

    this.getList(start, lastIndex);
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  onChangeValues = (event) => {
    var value = event.target.value,
      id = event.target.id;

    const { itemStock, pendingStock, InsRate, quantity } = this.state;
    if (id === "advancePercentage" && value !== "") {
      if (this.state.totalAmount > 0) {
        var amt = (
          (parseFloat(this.state.totalAmount) * parseFloat(value)) /
          100
        ).toFixed(2);
        this.setState({ advanceAmount: amt });
      }
      this.setState({ advanceType: "Percentage" });
    }
    if (id === "advanceAmount" && value !== "") {
      var per = 0;
      if (this.state.netAmount > 0) {
        per = (parseFloat(value) * 100) / parseFloat(this.state.totalAmount);
        per = parseFloat(per).toFixed(2);
      }

      this.setState({ advancePercentage: per, advanceType: "Amount" });
    }
    if (id === "InsRate") {
      if (value !== "" && quantity !== "") {
        var val = (parseFloat(quantity) * parseFloat(value)).toFixed(2);
        this.setState({ InsAmount: val });
      } else this.setState({ InsAmount: 0 });
    }
    if (id === "quantity") {
      console.log("bbb", InsRate);
      if (value !== "" && InsRate !== "" && InsRate) {
        var val = (parseFloat(InsRate) * parseFloat(value)).toFixed(2);
        this.setState({ InsAmount: val });
      } else this.setState({ InsAmount: 0 });
    }

    if (event.target.id === "quantity" && value !== "" && itemStock > 0) {
      if (
        parseFloat(value) >
        parseFloat(itemStock) + parseFloat(pendingStock)
      ) {
        this.basicAlert("Item cannot be placed , Stock is already pending");
      } else {
        if (
          this.state.SelectindentType === 1 &&
          this.state.updateItem &&
          this.state.indentType !== 2 &&
          !this.state.printingPop
        ) {
          if (this.state.stRemQty >= parseFloat(value))
            this.setState({
              [event.target.id]: value,
            });
          else {
            this.basicAlert(
              "Quantity should not be greater than Indent quantity " +
                this.state.stRemQty
            );
          }
        } else {
          this.setState({
            [event.target.id]: value,
          });
        }
      }
    } else {
      if (event.target.id === "quantity") {
        if (this.state.SelectindentType !== 1)
          this.setState({
            [event.target.id]: value,
          });
        else {
          if (this.state.indentType !== 2 && !this.state.printingPop) {
            if (this.state.stRemQty >= parseFloat(value))
              this.setState({
                [event.target.id]: value,
              });
            else {
              this.basicAlert(
                "Quantity should not be greater than Indent quantity " +
                  this.state.stRemQty
              );
            }
          } else
            this.setState({
              [event.target.id]: value,
            });
        }
      }
      if (id === "discountPercentage" && value !== "") {
        if (this.state.discountMode == "2") {
          if (parseFloat(value) <= 100) {
            this.setState({
              [event.target.id]: value,
            });
          } else
            this.basicAlert(
              "Discount Percentage should not be greater than 100"
            );
        } else {
          if (
            parseFloat(value) <=
            parseFloat(this.state.rate) * parseFloat(this.state.quantity)
          ) {
            this.setState({
              [event.target.id]: value,
            });
          } else {
            this.basicAlert("Discount should not be greater than amount");
          }
        }
      } else {
        this.setState({
          [event.target.id]: value,
        });
      }
    }

    if (event.target.id === "deliveryTimeLine") {
      var value = event.target.value;
      var podate = this.state.PoDate;
      this.setState({
        expecDate: moment(podate, "DD-MM-YYYY")
          .add(value, "days")
          .format("DD-MM-YYYY"),
      });
    }
    if (event.target.id === "quantity" || event.target.id === "rate") {
      var value = event.target.value;
      if (event.target.id === "rate") {
        var Calrate = this.state.quantity;
      } else {
        var Calrate = this.state.rate;
      }
      if (Calrate !== "" && value != "") {
        var calAmount = parseFloat(Calrate) * parseFloat(value);
        calAmount = calAmount.toFixed(2);
        this.setState({
          amount: calAmount,
          itemTaxAmt:
            this.state.taxPercent > 0
              ? (calAmount * this.state.taxPercent) / 100
              : this.state.itemTaxAmt,
        });
      } else {
        this.setState({
          amount: "",
        });
      }
    }
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue;
      values = values.split("^");
      var amout = 0;
      var rate = values[3] !== "null" ? values[3] : 0;
      if (values[10] !== "0" && values[10] !== undefined) {
        rate = values[10];
      }
      if (this.state.quantity !== "")
        amout = (parseFloat(rate) * parseFloat(this.state.quantity)).toFixed(2);

      this.setState({
        itemDesc: values[0],
        // selectedItemId:values[1],
        stock: values[2] !== "null" ? values[2] : 0,
        uom: values[1],
        rate:
          this.state.itemDesc === values[0] && this.state.updateItem
            ? this.state.rate
            : rate,
        hsnCode: values[4] !== "null" ? values[4] : "",
        // taxId:
        //   this.state.itemDesc === values[0] && this.state.updateItem
        //     ? this.state.taxId
        //     : values[5],
        amount:
          this.state.itemDesc === values[0] && this.state.updateItem
            ? this.state.amount
            : amout,
        itemStock:
          values[6] !== undefined && values[6] !== null ? values[6] : 0,
        minStock: values[7] !== undefined && values[7] !== null ? values[7] : 0,
      });
      console.log("taxis", this.state.taxId);
      if (this.state.itemDesc !== values[0] && !this.state.updateItem) {
        this.setState({
          loadTax: true,
        });
      }
    }
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    /*   var loading = false;
           if (this.state.isRefresh) {
               loading = true
           }*/
    //  this.handleLoading();
    if (!this.state.isRefresh) {
      console.log(
        "getting start index and end index",
        this.state.startIndex,
        this.state.pageSize
      );
      this.getList(this.state.startIndex, this.state.lastIndex);
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  onSelect = (selectedValue, stateKey, selectedName) => {
    // if (stateKey === "customerId") {
    //   console.log("getting selected value", selectedValue);
    // }
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
      });
    }
    if (stateKey === "supplier_Id") {
      getDataFromUrl(
        serverApi.GET_SIPPLIER_BY_SUPPL_ID +
          parseInt(selectedValue) +
          "/" +
          getCookie(cons.USERID),
        this.handleSupplierBranches
      );
    }
    if (stateKey === "currencyType") {
      this.setState({
        currencyType: selectedValue,
      });
    }
    if (
      ((stateKey === "projectId" || stateKey === "selectedProjectList") &&
        selectedValue !== null) ||
      stateKey === "projectId1"
    ) {
      getDataFromUrl(
        serverApi.GET_PHASE_BY_PROJECT_ID + selectedValue,
        this.handlePhaseList
      );
    }
    if (stateKey === "selectedItem") {
      console.log("getting the data of selectedItem", selectedValue);
      if (selectedValue !== "") {
        getDataFromUrl(
          serverApi.FETCH_PO_QOH_DETAILS +
            "comapny/" +
            this.props.state.companyInfo.id +
            "/item/" +
            selectedValue +
            "/branch" +
            "/" +
            this.state.branchId +
            "/project" +
            "/" +
            this.state.projectId,
          this.handleQohData
        );

        if (!this.state.updateItem)
          var data1 = {
            itemId: selectedValue,
            companyId: this.props.state.companyInfo.id,
          };
        // requestList(serverApi.PENDING_STOCK, data1, this.handlePendingStock);
        var stockData = {
          // itemId: this.state.selectedItemGroup + "" + selectedValue,
          itemId: selectedName,
          companyId: this.props.state.companyInfo.id,
          branchId:
            this.state.branchId === ""
              ? this.state.billingBranchId
              : this.state.branchId,
        };
        requestList(
          serverApi.PENDING_STOCK_AT_PO_RECEIPT,
          stockData,
          this.handlePendingStockAtPoReceipt
        );
        // getDataFromUrl(serverApi.GET_PO_PENDING_QUANTITY+this.state.selectedItemGroup+"/"+selectedValue+"/"+this.props.state.companyInfo.id,this.handlePendingQuantity);
      }
    }
    if (stateKey === "selectedBudgetHead" || stateKey === "branchId") {
      var budgetId = "";
      var branId = "";
      if (stateKey === "selectedBudgetHead") {
        this.setState({ isBranch: false, loadItemGroup: true });
        budgetId = selectedValue;
      } else {
        branId = selectedValue;
      }
      var data = {
        finshedSaleable: "N",
        companyId: this.props.state.companyInfo.id,
        budgetHeadId: budgetId,
        cipher: getCookie(cons.CIPHER),
        branchId: branId,
      };
      requestList(
        serverApi.GET_GROUP_ITEM_BY_BUDGET,
        data,
        this.handleGroupItem
      );
    }
    if (stateKey === "branchId" || stateKey === "branchIds") {
      this.setState({ loadProject: true, isBranch: true });
      if (!this.state.updateView) {
        this.setState({
          loadIndent: true,
        });
      }
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
        indentTypeId: this.state.form_purchaseType,
        tangible: this.state.isWO ? "N" : "Y",
      };

      requestList(serverApi.GET_ITEMGROUP, data, this.handleItem);
      // this.setState({
      //     loadItem: true
      // })
    }
    if (stateKey === "selectedIndent") {
      getDataFromUrl(
        serverApi.GET_INDENT_BY_ID +
          selectedValue +
          "/userid/" +
          getCookie(cons.USERID),
        this.handleApprovedList
      );
    }
    if (
      stateKey === "itemGroup" ||
      (stateKey === "selectedItemGroup" && !this.state.updateView)
    ) {
      var data = {
        companyId: this.props.state.companyInfo.id,
        branchId: this.state.branchId,
        financialYear: getCookie(cons.ACADEMIC_YEAR),
        recordType: this.state.isBranch
          ? "INDENT"
          : this.state.isWO
          ? "BOQ"
          : "BOM",
        itemGroup: selectedValue,
      };
      requestList(
        serverApi.GET_APPROVED_INDENT_BY_ITEMGROUP,
        data,
        this.handleApprovedIndents
      );
    }
    // if(stateKey=="taxId"){

    // }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleGroupItem = (resp) => {
    this.setState({
      groupItemList: resp,
      loadItemGroup: true,
    });
  };
  handleApprovedList = (resp) => {
    if (resp) {
      this.setState({ indentlist: resp });
    }
  };
  handleQohData = (resp) => {
    if (resp.data !== null) {
      this.setState({
        pendingAtRcpt: resp.qtyPo,
        projectQoh: resp.projectQoh,
        branchStock: resp.branchQoh,
        stock: resp.comapnyQoh,
        lastPurchaseRate: resp.poLastPurchaseRate,
        lastPurchaseDate: resp.poLastPurchaseDate,
        lastPurchaseItemRate: resp.itemRate,
      });
    }
  };
  handlePendingStockAtPoReceipt = (resp) => {
    var stock = "";
    if (resp.data !== null) {
      stock = resp.data.split("_");
      console.log("pendingStockDesc", stock);

      // stock = "Pending Stock at PO " + stock[0] + " and Receipt " + stock[1];
      stock = stock[0];
    }
    this.setState({ pendingStockDesc: stock });
  };
  handleSupplierBranch = (suppResp, resp) => {
    console.log("supplier branch id check", suppResp);
    console.log("supplier branch id check2", resp.poSequenceNo);
    //console.log("supplier branch id checkssss", resp);

    var data = suppResp.supLineItems,
      supplierBranchList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      supplierBranchList.push(obj);
      var rr = 1;
      data.forEach((prop) => {
        if (prop.autoId === resp.supplierBranchId) {
          console.log("getting data for supplier", suppResp.suppName);
          this.setState({
            suppName: suppResp.suppName,
            respPoseq: resp.poSequenceNo,
          });
          console.log(
            "getting data for poSequesnce",
            resp.poSequenceNo + "_" + suppResp.suppName + ".pdf"
          );

          fetch(serverApi.PRINT_PO + resp.id + "/" + getCookie(cons.USERID), {
            method: "GET",

            headers: {
              "Content-Type": "application/pdf",

              "X-TenantId": getTenantId(window.location.hostname),

              Authorization:
                getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
            },
          })
            .then((response) => response.blob())

            .then((blob) => {
              // Create blob link to download

              const url = window.URL.createObjectURL(new Blob([blob]));

              const link = document.createElement("a");

              link.href = url;

              link.setAttribute(
                "download",
                resp.poSequenceNo + "_" + suppResp.suppName + ".pdf"
              );

              // Append to html link element page

              document.body.appendChild(link);

              // Start download

              link.click();

              // Clean up and remove the link

              link.parentNode.removeChild(link);
            });

          // var name = prop.branchAddress;

          // var obj = {
          //   label: name,
          //   value: prop.autoId,
          //   name: name + "^" + prop.stateCode,
          //   data: prop,
          // };
          // supplierBranchList.push(obj);
          // if (rr === 1 && !this.state.updateView) {
          //   this.setState({
          //     supplierBranchId: prop.autoId,
          //   });
          // }
          // rr++;
        }
      });
    }
    // this.setState({
    //   supplierBranchData: supplierBranchList,
    //   loadSupplierBranch: true,
    // });
    // if (this.state.updateView) {
    //   var supplierData = null;

    //   supplierBranchList.forEach((prop) => {
    //     if (prop.value == this.state.supplierBranchId) {
    //       supplierData = prop.data;
    //       console.log("ssss", supplierData);
    //     }
    //   });
    //   var printResp = this.state.printResp;

    //   if (supplierData) {
    //     printResp = {
    //       ...printResp,
    //       supplierAddress: supplierData.branchAddress,
    //       supplierGst: supplierData.gstNo,
    //       contactPerson: supplierData.contactPerson,
    //       contactNumber: supplierData.contactNo,
    //       email: supplierData.email,
    //     };
    //     this.setState({
    //       printResp: printResp,
    //     });
    //   }
    // }

    // var supplierData = null;
    // supplierBranchList.forEach((prop) => {
    //   if (prop.value == this.state.supplierBranchId) {
    //     supplierData = prop.data;
    //     console.log("ssss", supplierData);
    //   }
    // });
    // var printResp = this.state.printResp;

    // if (supplierData) {
    //   var billingBranchName = "",
    //     billingBranchAddress = "",
    //     billContactNo = "",
    //     billingContactPerson = "",
    //     billingGST = "",
    //     billingEmail = "";
    //   var shippingBranchName = "",
    //     shippingBranchAddress = "",
    //     shippingContactNo = "",
    //     shippingContactPerson = "",
    //     shippingGST = "",
    //     shippingEmail = "";

    //   var branchData = this.getBranchDetailsFromId(resp.billingBranchId);
    //   var shipData = this.getBranchDetailsFromId(resp.shippingBranchId);

    //   if (branchData !== null) {
    //     billingBranchAddress = branchData.address;
    //     billContactNo = branchData.contactNo;
    //     billingContactPerson = branchData.contactPerson;
    //     billingGST = branchData.gstNo;
    //     billingBranchName = branchData.branchName;
    //     billingEmail = branchData.email;
    //   }
    //   if (shipData !== null) {
    //     shippingBranchAddress = shipData.address;
    //     shippingContactNo = shipData.contactNo;
    //     shippingContactPerson = shipData.contactPerson;
    //     shippingGST = shipData.gstNo;
    //     shippingBranchName = shipData.branchName;
    //     shippingEmail = shipData.email;
    //   }

    //   var hdr = {
    //     ...resp,
    //     billingBranchName: billingBranchName,
    //     billingBranchAddress: billingBranchAddress,
    //     billContactNo: billContactNo,
    //     billingContactPerson: billingContactPerson,
    //     billingEmail: billingEmail,
    //     billingGST: billingGST,
    //     shippingBranchAddress: shippingBranchAddress,
    //     shippingContactPerson: shippingContactPerson,
    //     shippingContactNo: shippingContactNo,
    //     shippingGST: shippingGST,
    //     shippingBranchName: shippingBranchName,
    //     shippingEmail: shippingEmail,
    //     // supplierAddress: resp.supplierBranchName,
    //     // poContactPerson: resp.contactPerson,
    //     poContactPerson: resp.contactPerson,
    //     supplierAddress: supplierData.branchAddress,
    //     supplierGst: supplierData.gstNo,
    //     contactPerson: supplierData.contactPerson,
    //     contactNumber: supplierData.contactNo,
    //     email: supplierData.email,
    //   };

    //   var data1 = resp;
    //   data1 = hdr;
    //   this.setState({ printResp: data1, isSupplierPrint: true });
    // }
  };

  handleSupplierBranches = (suppResp, resp) => {
    console.log("supplier branch id check", suppResp.supLineItems);
    //console.log("supplier branch id checkssss", resp);
    var data = suppResp.supLineItems,
      supplierBranchList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      supplierBranchList.push(obj);
      var rr = 1;
      data.forEach((prop) => {
        var name = prop.branchAddress;
        var obj = {
          label: name,
          value: prop.autoId,
          name: name + "^" + prop.stateCode,
          data: prop,
        };
        supplierBranchList.push(obj);
        if (rr === 1 && !this.state.updateView) {
          this.setState({
            supplierBranchId: prop.autoId,
          });
        }
        rr++;
      });
    }
    this.setState({
      supplierBranchData: supplierBranchList,
      loadSupplierBranch: true,
    });
    if (this.state.updateView) {
      var supplierData = null;

      supplierBranchList.forEach((prop) => {
        if (prop.value == this.state.supplierBranchId) {
          supplierData = prop.data;
          console.log("ssss", supplierData);
        }
      });
      var printResp = this.state.printResp;

      if (supplierData) {
        printResp = {
          ...printResp,
          supplierAddress: supplierData.branchAddress,
          supplierGst: supplierData.gstNo,
          contactPerson: supplierData.contactPerson,
          contactNumber: supplierData.contactNo,
          email: supplierData.email,
        };
        this.setState({
          printResp: printResp,
        });
      }
    }

    var supplierData = null;
    supplierBranchList.forEach((prop) => {
      if (prop.value == this.state.supplierBranchId) {
        supplierData = prop.data;
        console.log("ssss", supplierData);
      }
    });
    var printResp = this.state.printResp;

    if (supplierData) {
      var billingBranchName = "",
        billingBranchAddress = "",
        billContactNo = "",
        billingContactPerson = "",
        billingGST = "",
        billingEmail = "";
      var shippingBranchName = "",
        shippingBranchAddress = "",
        shippingContactNo = "",
        shippingContactPerson = "",
        shippingGST = "",
        shippingEmail = "";

      var branchData = this.getBranchDetailsFromId(resp.billingBranchId);
      var shipData = this.getBranchDetailsFromId(resp.shippingBranchId);

      if (branchData !== null) {
        billingBranchAddress = branchData.address;
        billContactNo = branchData.contactNo;
        billingContactPerson = branchData.contactPerson;
        billingGST = branchData.gstNo;
        billingBranchName = branchData.branchName;
        billingEmail = branchData.email;
      }
      if (shipData !== null) {
        shippingBranchAddress = shipData.address;
        shippingContactNo = shipData.contactNo;
        shippingContactPerson = shipData.contactPerson;
        shippingGST = shipData.gstNo;
        shippingBranchName = shipData.branchName;
        shippingEmail = shipData.email;
      }

      var hdr = {
        ...resp,
        billingBranchName: billingBranchName,
        billingBranchAddress: billingBranchAddress,
        billContactNo: billContactNo,
        billingContactPerson: billingContactPerson,
        billingEmail: billingEmail,
        billingGST: billingGST,
        shippingBranchAddress: shippingBranchAddress,
        shippingContactPerson: shippingContactPerson,
        shippingContactNo: shippingContactNo,
        shippingGST: shippingGST,
        shippingBranchName: shippingBranchName,
        shippingEmail: shippingEmail,
        // supplierAddress: resp.supplierBranchName,
        // poContactPerson: resp.contactPerson,
        poContactPerson: resp.contactPerson,
        supplierAddress: supplierData.branchAddress,
        supplierGst: supplierData.gstNo,
        contactPerson: supplierData.contactPerson,
        contactNumber: supplierData.contactNo,
        email: supplierData.email,
      };

      var data1 = resp;
      data1 = hdr;
      this.setState({ printResp: data1, isSupplierPrint: true });
    }
  };

  handlePendingQuantity = (resp) => {
    if (resp.data !== null) {
      this.setState({
        pendingAtRcpt: resp.data,
      });
    }
  };
  handleItemRate = (resp) => {
    if (resp.data) {
      this.setState({
        latestPrice: resp.data.latestPrice,
        latestPriceDate: resp.data.latestPriceDate,
      });
    }
  };
  handlePendingStock = (resp) => {
    if (resp.data > 0) this.setState({ pendingStock: resp.data });
  };

  handleStock = (resp) => {
    this.setState({ stock: resp.data });
  };
  handleBrachStock = (resp) => {
    this.setState({ branchStock: resp.data });
  };
  handleRate = (resp) => {
    var data = "",
      date = "";
    if (resp.data !== null) {
      data = resp.data.poPurchasRate;
      date = resp.data.poPurchaseDate;
    }

    this.setState({ lastPurchaseRate: data, lastPurchaseDate: date });
  };
  handleItem = (resp) => {
    var list = [];

    resp.forEach((prop) => {
      list.push({
        label: prop.label,
        name: prop.name,
        value: prop.itemCode,
        item: prop.itemId,
      });
    });
    this.setState({ ItemOptions: list, loadItem: true });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };
  afterRefreshTax = (val) => {
    this.setState({
      loadTax: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
    });
  };
  handleSelectedUnitValue = (defValue) => {
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue.value;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercent;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else {
        var taxPercent = prop.taxPercent / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      }
    });

    this.setState({
      invoiceList: data,
      taxType: defValue,
      loadTaxType: true,
    });
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.getAmount(
      this.state.quantity,
      this.state.rate,
      this.state.InsRate,
      this.state.discountMode,
      this.state.discountPercentage
    );
    if (taxPercent !== "") {
      if (itemAmount !== "") {
        var itemtaxAmount =
          (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
        //  itemtaxAmount = Math.round(itemtaxAmount);
        this.setState({
          itemTaxAmt: itemtaxAmount,
          taxPercent: parseFloat(taxPercent),
        });
      } else {
        this.setState({
          itemTaxAmt: 0,
          taxPercent: parseFloat(taxPercent),
        });
      }
    }
    var SlectedTaxType = this.state.taxType.value;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercent: taxPercent,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else {
      taxPercent = taxPercent / 2;
      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    }
  };
  handleSelectedValues = (selectedValue, stateKey) => {
    console.log("printing state key", selectedValue);
    //const {classes}=this.props;
    this.setState({
      SelectindentType: selectedValue,
    });
    // const {selectedJuteType}=this.state;
    if (!this.state.updateView) {
      if (selectedValue === 1 || selectedValue === 3) {
        this.setState({
          loading: true,
          indentAlert: true,
        });
      } else {
        this.setState({
          indentNo: "",
          indentQuantity: "",
          indentQuantityTon: "",
          indentBales: "",
          createdBy: "",
          createdOn: "",
          quantity: "",
          stock: "",
          // itemGroupId: '',
          selectedTax: "",
          invoiceList: [],
          selectedJuteType: "",
          selectedJuteName: "",
          selectedIndentQuantity: "",
          sumOFQuantities: 0,
          selectedDeptId: "",
          selectedIndent: "",
          selectedGrpId: "",
          selectedUnit: "",
          totalTax: 0,
          totalAmount: 0,
          netTotal: 0,
          indentlist: null,
          advanceAmount: 0,
          advancePercentage: 0,
        });
      }
    }

    //console.log(`Option selected:`, defValue);
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  // Create PO
  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      console.log("getting indentId here invoiceList", this.state.invoiceList);

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          //indentId: this.state.indentNo ? this.state.indentNo : null,
          indentId: prop.indentId ? prop.indentId : null,
          indentDetailsId:
            this.state.SelectindentType !== 2 && prop.indentLineId !== 0
              ? prop.indentLineId
              : null,
          budgetHeadId: prop.budgetHeadId,
          departmentId: prop.depId,
          itemId: prop.itemId,
          qty: prop.quantity,
          qohCompany: prop.stock ? parseFloat(prop.stock) : null,
          qohBranch: prop.branchStock ? prop.branchStock : 0,
          rate: parseFloat(prop.rate),
          rateLastpurchase:
            prop.lastPurchaseRate !== 0 && prop.lastPurchaseRate !== null
              ? prop.lastPurchaseRate
              : prop.rat,
          rateLastpurchaseDate:
            prop.lastPurchaseDate !== "" && prop.lastPurchaseDate !== null
              ? prop.lastPurchaseDate
              : this.state.PoDate,
          tax: prop.taxId,
          taxId: prop.taxId,
          ctaxPercentage: prop.csgst,
          staxPercentage: prop.ssgst,
          iTaxPercentage: prop.isgst,
          description: prop.description,
          discountMode: prop.discountMode,
          discountPercentage: prop.discountPercentage,
          hsnCode: prop.hsnCode,
          uomCode: prop.unit,
          installationRate: prop.InsRate,
          installationAmount: prop.InsAmount ? parseFloat(prop.InsAmount) : 0,
          make: prop.make,
          remarks: prop.remarks,
          taxPercentage: prop.taxPercent,
          currency: this.state.currencyType,
        };
      });
      var data = {
        poDate: this.state.PoDate,
        projectId: this.state.projectId,
        source: this.getSource(this.state.SelectindentType),
        companyId: this.props.state.companyInfo.id,
        // branchId: this.state.branchId,
        // branchId: this.state.billingBranchId,
        branchId:
          this.state.branchId === ""
            ? this.state.billingBranchId
            : this.state.branchId,
        supplierId: parseInt(this.state.supplier_Id),
        // taxTypeId: this.state.taxType.value,
        taxTypeName: this.state.taxType.name,
        billToAddress: this.state.billingBranchAddress,
        billToStateCode: this.state.billingStateCode,
        billToStateName: this.state.billingStateName,
        shipToAddress: this.state.delAddress,
        shipToStateCode: this.state.shippingStateCode,
        shipToStateName: this.state.shippingStateName,
        freightCharges: this.state.frieghtCharge,
        termsNconditions: this.state.remarks,
        footerNotes: this.state.footerNote,
        creditDays: this.state.creditTerm,
        expectedDeliveryDate: this.state.expecDate,
        category: parseInt(this.state.form_purchaseType),
        remarks: this.state.internalNote,
        indentId: this.state.selectedIndent,
        poTillDate: this.state.poValidDate,
        rqnName: this.state.rqName,
        phaseId: this.state.phaseId,
        valueWithTax: Math.round(
          parseFloat(this.getTotalTax("netTotal"))
        ).toFixed(2),
        valueWithoutTax: this.getTotalTax("totalAmount"),
        taxPayable: this.state.taxPayable,
        supplierBranchId: this.state.supplierBranchId,
        billingBranchId: this.state.billingBranchId,
        shippingBranchId: this.state.shippingBranchId,
        deliverTimeLine: this.state.deliveryTimeLine,
        budgetHeadId: this.state.selectedBudgetHead,
        itemGroup: this.state.itemGroup,
        poDetails: invoiceList,
        createdBy: getCookie(cons.USERID),
        advanceType: this.state.advanceType,
        advancePercentage: this.state.advancePercentage
          ? this.state.advancePercentage
          : "0",
        advanceAmount: this.state.advanceAmount
          ? this.state.advanceAmount
          : "0",
        indentId: this.state.indentNo,
        customerId: this.state.customerId,
        contactPerson: this.state.contactPerson,
        contactNo: this.state.contactNo,
        currency: this.state.currencyType,
      };

      const validateFields = [
        {
          field: this.getSource(this.state.SelectindentType),
          msg: "PO Source",
          type: 2,
        },
        { field: this.state.supplier_Id, msg: "Supplier", type: 2 },
        { field: this.state.supplierBranchId, msg: "Supplier Branch", type: 2 },
        { field: this.state.billingBranchId, msg: "Billing Address", type: 2 },
        {
          field: this.state.shippingBranchId,
          msg: "Shipping Address",
          type: 2,
        },
        // { field: this.state.taxPayable, msg: "Tax Payable", type: 2 },
        {
          field: this.state.deliveryTimeLine,
          msg: "Delivery Timeline",
          type: 2,
        },

        { field: this.state.form_purchaseType, msg: "Category Type", type: 2 },
        {
          field: this.state.deliveryTimeLine,
          msg: "Delivery Timeline",
          type: 2,
        },
        { field: this.state.projectId, msg: "Project", type: 2 },

        { field: this.state.invoiceList, msg: "one lineItem", type: 2 },
        { field: this.state.remarks, msg: "Terms and Condition", type: 2 },
      ];
      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        // this.basicAlert("Created Successfully");
        this.handleLoading();
        requestList(serverApi.SAVE_PURCHASE_ORDER, data, this.handleAddIndent);
      }

      // if (
      //   this.state.invoiceList.length >= 1 &&
      //   this.state.supplier_Id !== "" &&
      //   this.state.supplier_Id !== 0 &&
      //   this.state.form_purchaseType &&
      //   // this.state.totalAmount > 0 &&
      //   this.state.billingBranchId !== "" &&
      //   this.state.billingBranchId !== 0 &&
      //   this.state.supplierBranchId &&
      //   // this.state.SelectindentType &&
      //   this.getSource(this.state.SelectindentType) !== 0 &&
      //   this.state.deliveryTimeLine &&
      //   this.state.shippingBranchId !== 0 &&
      //   this.state.shippingBranchId &&
      //   this.state.advancePercentage !== "" &&
      //   // this.state.taxPayable &&
      //   this.state.advanceAmount !== "" &&
      //   this.state.projectId !== ""
      //   //this.state.remarks
      // ) {
      //   this.handleLoading();
      //   // alert("projectid", this.state.projectId);
      //   requestList(serverApi.SAVE_PURCHASE_ORDER, data, this.handleAddIndent);
      //   console.log(
      //     "getting the SelectindentType",
      //     this.state.SelectindentType
      //   );
      //   console.log("created successfully", data);
      // } else if (this.state.invoiceList.length < 1) {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.finalApprover && this.state.poStatus != "3") {
        this.setState({
          sendMail: true,
          finalApprovalMsg: resp.message,
        });

        // this.printDocument()
      } /* else {
                this.createShowMessage(resp.message, resp.status)
            }*/
      this.createShowMessage(resp.message, resp.status);
      this.getList(this.state.startIndex, this.state.lastIndex);

      console.log("resp " + JSON.stringify(resp));
      //  this.createShowMessage(resp.message, resp.status)
    } else {
      this.createShowMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  getSource = (data, type) => {
    var val = 0;
    var options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "By Indent", name: "WITHINDENT" },
      { value: 2, label: "Direct Order", name: "WITHOUTINDENT" },
      { value: 3, label: "By BOM", name: "WITHBOM" },
    ];
    if (getCookie(cons.MENU_ID) == 513) {
      options = [
        { value: 0, label: "Select...", name: "Select..." },
        //  { value: 1, label: 'By Indent', name: 'WITHINDENT' },
        { value: 2, label: "Direct Order", name: "WITHOUTBOQ" },
        { value: 3, label: "By BOQ", name: "WITHBOQ" },
      ];
    }
    options.forEach((prop) => {
      if (type == "id") {
        if (data == prop.name) val = prop.value;
      } else {
        if (data == prop.value) val = prop.name;
      }
    });
    return val;
  };

  //update PO (approve)

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList
        .map((prop, key) => {
          console.log("getting prop value", prop);
          if (prop.isActive != 0) {
            return {
              indentId: prop.indentId ? prop.indentId : null,
              indentDetailsId: prop.indentLineId,
              id: prop.lineItemId,
              budgetHeadId: prop.budgetHeadId,
              departmentId: prop.depId,
              //itemId: prop.itemId,
              itemId: prop.itemId ? prop.itemId : null,
              qty: prop.quantity,
              qohCompany: prop.stock ? parseFloat(prop.stock) : null,
              qohBranch: prop.branchStock,
              rate: parseFloat(prop.rate),
              rateLastpurchase:
                prop.lastPurchaseRate !== 0 && prop.lastPurchaseRate !== null
                  ? prop.lastPurchaseRate
                  : prop.rat,
              rateLastpurchaseDate:
                prop.lastPurchaseDate !== "" && prop.lastPurchaseDate !== null
                  ? prop.lastPurchaseDate
                  : this.state.PoDate,
              tax: prop.taxId,
              taxId: prop.taxId,
              ctaxPercentage: prop.csgst,
              staxPercentage: prop.ssgst,
              iTaxPercentage: prop.isgst,
              description: prop.description,
              discountMode: prop.discountMode,
              discountPercentage: prop.discountPercentage,
              hsnCode: prop.hsnCode,
              uomCode: prop.unit,
              installationRate: prop.InsRate,
              installationAmount: prop.InsAmount
                ? parseFloat(prop.InsAmount)
                : 0,
              make: prop.make,
              remarks: prop.remarks,
              taxPercentage: prop.taxPercent,
              oldQty: prop.oldQty,
              isActive: prop.isActive,
              currency: this.state.currencyType,
            };
          }
        })
        .filter((prop) => prop !== undefined);
      var data = {
        id: this.state.poNo,
        poDate: this.state.PoDate,
        projectId: this.state.projectId,
        source: this.getSource(this.state.SelectindentType),
        companyId: this.props.state.companyInfo.id,
        branchId: this.state.branchId,
        supplierId: parseInt(this.state.supplier_Id),
        taxTypeId: this.state.taxType.value,
        taxTypeName: this.state.taxType.name,
        billToAddress: this.state.billingBranchAddress,
        billToStateCode: this.state.billingStateCode,
        billToStateName: this.state.billingStateName,
        shipToAddress: this.state.delAddress,
        shipToStateCode: this.state.shippingStateCode,
        shipToStateName: this.state.shippingStateName,
        freightCharges: this.state.frieghtCharge,
        termsNconditions: this.state.remarks,
        footerNotes: this.state.footerNote,
        creditDays: this.state.creditTerm,
        expectedDeliveryDate: this.state.expecDate,
        category: parseInt(this.state.form_purchaseType),
        remarks: this.state.internalNote,
        indentId: parseInt(this.state.selectedIndent),
        poTillDate: this.state.poValidDate,
        rqnName: this.state.rqName,
        phaseId: this.state.phaseId,
        valueWithTax: Math.round(
          parseFloat(this.getTotalTax("netTotal"))
        ).toFixed(2),
        valueWithoutTax: this.getTotalTax("totalAmount"),
        taxPayable: this.state.taxPayable,
        supplierBranchId: this.state.supplierBranchId,
        billingBranchId: this.state.billingBranchId,
        shippingBranchId: this.state.shippingBranchId,
        deliverTimeLine: this.state.deliveryTimeLine,
        status: status,
        poSequenceNo: this.state.poSequenceNo,
        budgetHeadId: this.state.selectedBudgetHead,
        itemGroup: this.state.itemGroup,
        poDetails: invoiceList,
        lastModifiedBy: getCookie(cons.USERID),
        advanceType: this.state.advanceType,
        advancePercentage: this.state.advancePercentage
          ? this.state.advancePercentage
          : "0",
        advanceAmount: this.state.advanceAmount
          ? this.state.advanceAmount
          : "0",
        // indentId: this.state.indentNo,
        customerId: this.state.customerId,
        contactPerson: this.state.contactPerson,
        contactNo: this.state.contactNo,
        currency: this.state.currencyType,
      };
      this.setState({
        status: "",
      });

      if (
        this.state.invoiceList.length >= 1 &&
        this.state.supplier_Id !== "" &&
        this.state.supplier_Id !== 0 &&
        this.state.form_purchaseType &&
        this.state.totalAmount > 0 &&
        this.state.billingBranchId !== "" &&
        this.state.billingBranchId !== undefined &&
        this.state.billingBranchId !== 0 &&
        this.state.supplierBranchId &&
        this.state.SelectindentType &&
        this.state.deliveryTimeLine &&
        this.state.shippingBranchId !== 0 &&
        this.state.shippingBranchId &&
        this.state.shippingBranchId !== undefined &&
        this.state.shippingBranchId !== "" &&
        // this.state.advancePercentage &&
        // this.state.advamt &&
        this.state.remarks
      ) {
        this.handleLoading();
        console.log("Nagesh Payload", data);
        requestList(
          serverApi.UPDATE_PURCHASE_ORDER,
          data,
          this.handleAddIndent
        );
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  //download print
  handleDownloadPrint = (id) => {
    console.log("idd  " + "  ", id);
    console.log("getting value from user", id + "/" + getCookie(cons.USERID));
    // this.handleDownloadClickResp();
    getDataFromUrl(
      serverApi.GET_PO_BY_ID + id + "/" + getCookie(cons.USERID),
      this.handleDownloadClickResp
    );
    console.log("getting supplier data", this.state.supplierBranchData);
    console.log("getting admin data", this.state.adminResp);
  };
  handleDownloadClickResp = (resp) => {
    console.log("supplier branch id", resp.supplierBranchId);
    //console.log("supplier branch id check", resp.autoId);
    getDataFromUrl(
      serverApi.GET_SIPPLIER_BY_SUPPL_ID +
        parseInt(resp.supplierId) +
        "/" +
        getCookie(cons.USERID),
      this.handleSupplierBranch,
      resp
    );
  };

  /// view indent code
  handleViewClick = (id) => {
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      if (this.state.isSupplier) {
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "14/" +
            id +
            "/" +
            this.props.state.companyInfo.id,
          this.handleInvoiceFiles
        );
      } else
        getDataFromUrl(
          serverApi.GET_PO_BY_ID + id + "/" + getCookie(cons.USERID),
          this.handleViewClickResp
        );
    }

    console.log(
      "indentStatusmm----------------------------",
      this.state.indentStatus
    );
  };
  handleViewClickResp = (resp) => {
    var dataList = [];
    var billingBranchName = "",
      billingBranchAddress = "",
      billContactNo = "",
      billingContactPerson = "",
      billingGST = "",
      billingEmail = "";
    var shippingBranchName = "",
      shippingBranchAddress = "",
      shippingContactNo = "",
      shippingContactPerson = "",
      shippingGST = "",
      shippingEmail = "";

    var branchData = this.getBranchDetailsFromId(resp.billingBranchId);
    var shipData = this.getBranchDetailsFromId(resp.shippingBranchId);

    if (branchData !== null) {
      billingBranchAddress = branchData.address;
      billContactNo = branchData.contactNo;
      billingContactPerson = branchData.contactPerson;
      billingGST = branchData.gstNo;
      billingBranchName = branchData.branchName;
      billingEmail = branchData.email;
    }
    if (shipData !== null) {
      shippingBranchAddress = shipData.address;
      shippingContactNo = shipData.contactNo;
      shippingContactPerson = shipData.contactPerson;
      shippingGST = shipData.gstNo;
      shippingBranchName = shipData.branchName;
      shippingEmail = shipData.email;
    }

    var hdr = {
      ...resp,
      billingBranchName: billingBranchName,
      billingBranchAddress: billingBranchAddress,
      billContactNo: billContactNo,
      billingContactPerson: billingContactPerson,
      billingEmail: billingEmail,
      billingGST: billingGST,

      shippingBranchAddress: shippingBranchAddress,
      shippingContactPerson: shippingContactPerson,
      shippingContactNo: shippingContactNo,
      shippingGST: shippingGST,
      shippingBranchName: shippingBranchName,
      shippingEmail: shippingEmail,
      supplierAddress: "",
      supplierGst: "",
      contactPerson: "",
      contactNumber: "",
      email: "",
      poContactPerson: resp.contactPerson,
    };

    var data1 = resp;
    data1 = hdr;
    this.setState({ printResp: data1 });
    var header = resp;
    var list = resp.poDetails ? resp.poDetails : [],
      selectedDepartment,
      indentlist = [],
      selectedItemGroup;
    dataList = list;

    if (resp.poDetails !== null)
      indentlist = list.map((prop, key) => {
        selectedDepartment = prop.departmentId;
        selectedItemGroup = prop.itemGroupId;
        return {
          id: key,
          indentId: prop.indentId,
          indentLineId: prop.indentDetailsId,
          lineItemId: prop.id,
          itemId: prop.itemId + "",
          itemCode: prop.itemCode,
          itemDesc: prop.itemName,
          itemGroupId: prop.itemGroupId,
          itemGrp:
            (prop.itemGroupId ? prop.itemGroupId : "") +
            "" +
            (prop.itemCode ? prop.itemCode : ""),
          quantity: prop.qty,
          hsnCode: prop.hsnCode,
          unit: prop.uomCode,
          rate: prop.rate,
          amount: this.getAmount(
            prop.qty,
            prop.rate,
            prop.installationRate,
            prop.discountMode,
            prop.discountPercentage
          ),
          depId: prop.departmentId,
          selectedDepartment: prop.departmentId,
          isgst: prop.iTaxPercentage,
          ssgst: prop.staxPercentage,
          csgst: prop.ctaxPercentage,
          taxId: prop.taxId,
          taxamount: prop.tax,
          taxPercent: prop.taxPercentage,
          taxPercentage: prop.taxPercentage,
          // isActive: prop.deleteRec ? 0 : 1,
          lastPurchaseRate: prop.lastPurchaseRate,
          lastPurchaseDate:
            prop.lastPurchaseDate !== null ? prop.lastPurchaseDate : "",
          stRemQty: prop.stRemQty + prop.qty,
          remainingQuantity: prop.remainingQuantity,
          indentType: header.categoryId,
          pendingAtRcpt: prop.pendingAtRcpt,
          description: prop.description,
          selectedBudgetHeadItem: prop.budgetHeadId + "",
          discountMode: prop.discountMode + "",
          discountPercentage: prop.discountPercentage
            ? prop.discountPercentage
            : "",
          isActive: prop.isActive,
          InsRate: prop.installationRate,
          InsAmount: prop.installationAmount,
          make: prop.make,
          remarks: prop.remarks,
          budgetHeadId: prop.budgetHeadId + "",
          oldQty: prop.oldQty,
          currencyType: prop.currency,
        };
      });

    //  console.log("indentWeight "+header.indentWeight);
    // var Datalist = resp.poDetails ? resp.poDetails : [];
    console.log("getting uomCode from action", dataList[0].currency);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeUnit: true,
        approveButton: header.approveButton,
        hdrId: header.id,
        poNo: header.id,
        supplier_Id: header.supplierId,
        statusDesc: header.statusName,
        year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateTime,
        PoDate: header.poDate,
        indentNo: header.indentNum,
        indentNum: header.indentNum,
        indentId: header.indentId,
        // SelectindentType: this.getDefaultindentConvertion(header.withOrWithout),
        creditTerm: header.creditTerm,
        deliveryTimeLine: header.deliverTimeLine,
        expecDate: header.exceptedDate,
        selectedVehicleType: "",
        expecDate: header.expectedDeliveryDate,
        shippingBranchId: header.shippingBranchId,
        delAddress: header.shipToAddress,
        shippingStateCode: header.shipToStateCode,
        shippingStateName: header.shipToStateName,
        // supplierId: header.supplierId,
        supplierName: header.supplierName,
        supplier_Id: header.supplierId + "",
        totalTax: header.tax,
        totalAmount: parseFloat(header.valueWithoutTax).toFixed(2),
        netTotal: parseFloat(header.valueWithTax).toFixed(2),
        quantity: "",
        stock: "",
        branchId: header.branchId,
        projectId: header.projectId,
        delAddress: header.deliveryAddress,
        footerNote: header.footerNotes,
        frieghtCharge: header.freightCharges,
        remarks: header.termsNconditions,
        taxType: this.getDefaultTaxConvertion(header.taxTypeId),
        form_purchaseType: header.category + "",
        invoiceList: indentlist,
        poStatus: header.status,
        statusName: header.statusName,
        finalApprover: resp.finalApprover,
        billingBranchId: header.billingBranchId,
        rqName: header.rqnName,
        poValidDate: header.poTillDate,
        billingBranchAddress: billingBranchAddress,
        internalNote: header.remarks,
        indentHdrId: header.indentHdrId,
        creditTerm: header.creditDays,
        supplierBranchId: header.supplierBranchId,
        phaseId: header.phaseId + "",
        taxPayable: header.taxPayable,
        poSequenceNo: header.poSequenceNo,
        poSeqNo: header.poSequenceNo,
        itemGroup: header.itemGroup,
        loadSupplier: true,
        loadSupplierBranch: true,
        // loadItemGroup: true,
        selectedIndent: header.indentId + "",
        selectedBudgetHead: header.budgetHeadId + "", //header.budgetHeadId,
        // loadIndent: true,
        // loadDepartment: true,
        //loadDepartment1: true,
        SelectindentType: this.getSource(header.source, "id"),
        advanceType: header.advanceType,
        advancePercentage: header.advancePercentage,
        advanceAmount: header.advanceAmount,
        customerId: header.customerId,
        contactPerson: header.contactPerson,
        contactNo: header.contactNo,
        currencyType: dataList ? dataList[0].currency : "INR",
      });
      console.log(
        "getting data for USERID",
        getCookie(cons.USERID) + "getting data for id",
        this.state.hdrId
      );

      // getDataFromUrl(
      //   serverApi.PRINT_PO + "/" + this.state.id
      // )
      console.log("fff", this.getSource(header.source, "id"));
      if (header.withOrWithout === 1) {
        this.setState({ itemGroup: selectedItemGroup });
        //  getDataFromUrl(serverApi.GET_INDENT_DETAILS_BY_ID + header.indentNum + "/userid/" + getCookie(cons.USERID) + "/company/" + this.props.state.companyInfo.id+"/year/"+getCookie(cons.ACADEMIC_YEAR), this.handleIndentSelectResp1)
      }
      if (
        getConfigValue(
          configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
        ) === "2"
      ) {
        console.log("INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS", true);
        this.setState({
          disableDept: true,
          selectedDepartment: selectedDepartment,
          selectedItemGroup: selectedItemGroup,
        });
      }
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "14/" +
          header.id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleInvoiceFiles
      );
      console.log(
        "printing the hdrId",
        this.state.supplierBranchId,
        this.state.billingBranchId,
        this.state.shippingStateCode
      );
      if (
        this.state.supplierBranchId !== null &&
        this.state.billingBranchId !== null &&
        this.state.shippingStateCode !== null
      ) {
        getDataFromUrl(
          serverApi.PRINT_PO + this.state.hdrId + "/" + getCookie(cons.USERID),
          this.handlePrintData
        );
      }
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.isSupplier) {
        if (list.length > 0) {
          this.setState({ updateView: true, isCreated: true });
        } else {
          this.basicAlert("No documents available to download");
        }
      }

      if (this.state.filesList.length > 0) {
        list.push(...this.state.filesList);
      }
      this.setState({ filesList: list });
    }
  };

  handlePrintData = () => {
    // console.log("getting data for USERID", resp);
    fetch(
      serverApi.PRINT_PO + this.state.hdrId + "/" + getCookie(cons.USERID),
      {
        method: "GET",

        headers: {
          "Content-Type": "application/pdf",

          "X-TenantId": getTenantId(window.location.hostname),

          Authorization:
            getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
        },
      }
    )
      .then((response) => response.blob())

      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute(
          "download",

          `${this.state.poSequenceNo} _${this.state.supplierName}.pdf`
        );

        // Append to html link element page

        document.body.appendChild(link);

        // Start download

        link.click();

        // Clean up and remove the link

        link.parentNode.removeChild(link);
      });
  };

  onSelectIndent = (selectedIndent, year) => {
    getDataFromUrl(
      serverApi.GET_INDENT_DETAILS_BY_ID +
        selectedIndent +
        "/userid/" +
        getCookie(cons.USERID) +
        "/company/" +
        this.props.state.companyInfo.id +
        "/year/" +
        year,
      this.handleIndentSelectResp1
    );
  };
  handleIndentSelectResp1 = (resp) => {
    var header = resp.indentHeader;
    var list = resp.indentItems;
    var sum_of_tax = 0,
      sumOfamt = 0,
      netTotal = 0,
      indentlist = [],
      key = 0;
    list.map((prop, i) => {
      //  if(prop.itemGroupId===this.state.itemGroup){
      prop = { ...prop, isActive: 0 };
      if (this.state.updateView) {
        this.state.invoiceList.forEach((o) => {
          if (o.itemGroupId === prop.itemGroupId && o.itemId === prop.itemId)
            prop.isActive = 1;
        });
      }
      if (prop.indentType === 2) {
        var qty = prop.remainingQuantity;
        if (qty <= 0) {
          prop.indentQuantity = 0;
        }
        var pushitem = true;
        if (qty <= 0) {
          if (this.state.updateView) {
            this.state.invoiceList.forEach((o) => {
              if (o.itemGroupId == prop.itemGroupId && o.itemId == prop.itemId)
                pushitem = false;
            });
          }
        }
        if (pushitem) indentlist.push(prop);
      } else {
        if (prop.remainingQuantity > 0) {
          prop.indentQuantity = prop.remainingQuantity;
          indentlist.push(prop);
        }
      }
      // }
    });
    resp.indentItems = indentlist;

    if (header !== null) {
      if (this.state.updateView) {
        if (this.state.indentHdrId === header.id) {
          this.setState({
            //   indentAlert: false,

            indentlist: resp,
          });
          getDataFromUrl(
            serverApi.GET_FILE_BY_SOURCE +
              "10/" +
              header.id +
              "/" +
              this.props.state.companyInfo.id,
            this.handleInvoiceFiles
          );
        }
      } else {
        this.setState({
          //   indentAlert: false,

          indentlist: resp,
        });
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "10/" +
            header.id +
            "/" +
            this.props.state.companyInfo.id,
          this.handleInvoiceFiles
        );
      }
    }
    //this.indenthideAlert();
  };

  handleIndentSelectResp = () => {
    var resp = this.state.indentlist;
    if (resp !== null) {
      var header = resp;
      // var list = resp.indentItems;
      // console.log(list);
      var sum_of_tax = 0,
        sumOfamt = 0,
        netTotal = 0,
        indentlist = [],
        key = 0;
      var invoiceList = this.state.invoiceList;
      if (this.state.updateView) {
        indentlist = invoiceList;
      }
      resp.indentDetails.map((prop, i) => {
        var quantiy = prop.qty;
        if (prop.qtyPo) quantiy = parseFloat(quantiy) - parseFloat(prop.qtyPo);

        if (prop.isActive === 1) {
          var itemAmount = this.getAmount(
            quantiy,
            prop.rate,
            prop.installationRate
          );
          var itemtaxAmount =
            (itemAmount * parseFloat(prop.taxPercentage)) / 100; //11 is tax percent
          // itemtaxAmount = Math.round(itemtaxAmount);
          // prop.taxamount = itemtaxAmount;
          sum_of_tax = sum_of_tax + itemtaxAmount;
          sumOfamt = sumOfamt + parseFloat(itemAmount);
          var SlectedTaxType = this.state.taxType;
          if (SlectedTaxType !== "") {
            if (SlectedTaxType.value === 1) {
              prop.iTax = prop.taxPercentage;
              prop.sTax = 0;
              prop.cTax = 0;
            } else if (SlectedTaxType.value === 2) {
              var taxPercent = prop.taxPercentage / 2;
              prop.iTax = 0;
              prop.sTax = taxPercent;
              prop.cTax = taxPercent;
            }
          }

          if (prop.indentType === 2) {
            if (i > 0) {
              key = key + 1;
            }
            var obj = {
              lineItemId: 0,
              indentId: header.id,
              indentLineId: prop.id,
              id: key,
              itemId: prop.itemId + "",
              itemDesc: prop.itemName,
              itemGroupId: prop.itemGroupId,
              itemGrp: prop.itemGroupId + "" + prop.itemCode,
              quantity: quantiy,
              hsnCode: prop.hsnCode,
              unit: prop.uomCode,
              weight: quantiy,
              rate: prop.rate, //rate
              amount: itemAmount,
              selectedDepartment: prop.departmentId + "",
              depId: prop.departmentId,
              isgst: prop.iTax,
              ssgst: prop.sTax,
              csgst: prop.cTax,
              taxId: prop.taxId,
              taxamount: itemtaxAmount,
              taxPercent: prop.taxPercentage,
              // amount: parseFloat(prop.qty* prop.rate).toFixed(2),
              isActive: 1,
              lastPurchaseRate: 0,
              lastPurchaseDate: "",
              stRemQty: prop.remainingQuantity,
              indentType: prop.categoryId,
              selectedBudgetHead: prop.budgetHeadId + "",
              itemGroup: prop.itemGroupId,
              stock: prop.qohCompany,
              branchStock: prop.qohBranch,
              pendingAtRcpt: prop.qtyPo,
              selectedBudgetHeadItem: prop.budgetHeadId + "",
              budgetHeadId: prop.budgetHeadId + "",
              selectedItemGroup: prop.itemGroupId,
              //selectedItem: prop.itemId + "",
              selectedItem: prop.itemCode,
              itemCode: prop.itemCode,
              make: prop.make,
              remarks: prop.remarks,
              InsRate: prop.installationRate,
              InsAmount: prop.installationRate
                ? parseFloat(prop.installationRate) * parseFloat(quantiy)
                : 0,
            };

            /* var qty = prop.remainingQuantity;
                         if (qty <= 0) {
                             obj.quantity = 0;
                             obj.amount = 0.00;
                         }*/
            if (this.state.updateView) {
              obj.id = indentlist.length;
              indentlist.push(obj);
              /*  invoiceList.forEach(o=>{
                                  console.log("tttt",o.itemGroupId,prop.itemGroupId,o.itemId,prop.itemId);
  
                                  if(o.itemGroupId===prop.itemGroupId&&o.itemId===prop.itemId){
                                      o.id=indentlist.length;
                                      indentlist.push(o)
                                  }else{
                                      if(indentlist.indexOf(o)<-1){
                                          o.id=indentlist.length;
                                          indentlist.push(o);
                                      }
  
                                      obj.id=indentlist.length;
                                      indentlist.push(obj)
                                  }
  
                              })*/
            } else indentlist.push(obj);
          } else {
            if (prop.remainingQuantity > 0) {
              if (i > 0) {
                key = key + 1;
              }

              var obj = {
                lineItemId: 0,
                indentId: header.id,
                indentLineId: prop.id,
                id: key,
                itemId: prop.itemId + "",
                itemDesc: prop.itemName,
                itemGroupId: prop.itemGroupId,
                itemGrp: prop.itemGroupId + "" + prop.itemCode,
                quantity: quantiy,
                hsnCode: prop.hsnCode !== null ? prop.hsnCode : "",
                unit: prop.uomCode,
                weight: quantiy,
                rate: prop.rate, //rate
                amount: itemAmount,
                selectedDepartment: prop.departmentId + "",
                depId: prop.departmentId,
                isgst: prop.iTax,
                ssgst: prop.sTax,
                csgst: prop.cTax,
                taxId: prop.taxId,
                taxamount: itemtaxAmount,
                taxPercent: prop.taxPercentage,
                // amount: parseFloat(prop.qty * prop.rate).toFixed(2),
                isActive: 1,
                lastPurchaseRate: 0,
                lastPurchaseDate: "",
                stRemQty: prop.remainingQuantity,
                indentType: prop.categoryId,
                selectedBudgetHead: prop.budgetHeadId + "",
                itemGroup: prop.itemGroupId,
                stock: prop.qohCompany,
                branchStock: prop.qohBranch,
                pendingAtRcpt: prop.qtyPo,
                selectedBudgetHeadItem: prop.budgetHeadId + "",
                budgetHeadId: prop.budgetHeadId,
                selectedItemGroup: prop.itemGroupId,
                //selectedItem: prop.itemId + "",
                selectedItem: prop.itemCode,
                itemCode: prop.itemCode,
                make: prop.make,
                remarks: prop.remarks,
                InsRate: prop.installationRate,
                InsAmount: prop.installationRate
                  ? parseFloat(prop.installationRate) * parseFloat(quantiy)
                  : 0,
              };
              if (this.state.updateView) {
                obj.id = indentlist.length;
                indentlist.push(obj);
                /*
                                                                invoiceList.forEach(o=>{
                                                                    console.log("tttt",o.itemGroupId,prop.itemGroupId,o.itemId,prop.itemId);
                                
                                                                    if(o.itemGroupId===prop.itemGroupId&&o.itemId===prop.itemId){
                                                                        o.id=indentlist.length;
                                                                        indentlist.push(o)
                                                                    }else{
                                                                        if(indentlist.indexOf(o)<-1){
                                                                            o.id=indentlist.length
                                                                            indentlist.push(o);
                                                                        }
                                
                                                                        obj.id=indentlist.length;
                                                                        indentlist.push(obj)
                                                                    }
                                
                                                                })
                                */
              } else indentlist.push(obj);
            }
          }
        }
      });
      if (indentlist.length <= 0) {
        this.basicAlert("No line items available for the purchase order.");
      }

      if (header !== null) {
        this.setState({
          indentAlert: false,
          loading: false,
          //updateView:true,
          // isCreated:true,
          changeTaxType: false,
          reloadSelect: true,
          // approveButton: header.approveButton,
          indentNo: header.id,
          statusDesc: header.statusDesc,
          branchId: header.branchId,
          projectId: header.projectId,
          loadBranch: true,
          loadProject: true,
          indentQuantity: header.indentWeight,
          //  year: header.finnacialYear,
          createdBy: header.submitter,
          createdOn: header.createDateDesc,
          date1: header.indentDateDesc,
          selectedVehicleType: "",
          quantity: "",
          stock: "",
          invoiceList: indentlist,
          // indentlist: null,
          totalTax: sum_of_tax,
          totalAmount: sumOfamt.toFixed(2),
          netTotal: parseFloat(sumOfamt + sum_of_tax).toFixed(2),
          form_purchaseType: header.categoryId + "",
          loadCategory: true,
          loadItemGroup: true,
          loadItem: true,
          loadDepartment: true,
          customerId: header.customerId,
          loadCustomer: true,
          InternalNote: header.internalRemarks,
          //  itemGroup: ""
        });
        indentlist.forEach((prop, i) => {
          this.getLastPurchaseRate(i, prop.itemId, prop.itemGroupId);
        });
      }
    }
    //this.indenthideAlert();
  };

  getLastPurchaseRate = (id, itemId, itemGroupId) => {
    getDataFromUrl(
      serverApi.GET_LAST_PURCHASE_RATE +
        itemGroupId +
        "/" +
        itemId +
        "/" +
        this.props.state.companyInfo.id,
      (resp) => {
        this.handlePRate(resp, id);
      }
    );
  };
  handlePRate = (resp, id) => {
    console.log("handlePRate", resp);
    var list = this.state.invoiceList;
    if (resp.data) {
      list[id].lastPurchaseRate = resp.data.poPurchasRate;
      list[id].lastPurchaseDate = resp.data.poPurchaseDate;
      this.setState({ invoiceList: list });
    }
  };
  /*
    getitemDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");

        this.setState({

            itemDesc: values[0],
            itemGroupId: values[1],
            uom: values[2],
            depId: values[3],
            hsnCode: values[4],

        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
*/

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  createShowMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  showMessage = (msg, status) => {
    this.setState({
      indentAlert: (
        <Popup
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
        changeTaxType: true,
      });
    }
    console.log("Deleted Hide ItemBasic Alert", this.state.deleteItem);
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      var deducAmount = this.state.deducAmount;
      var duductaxAmount = this.state.duductaxAmount;
      // data.forEach((o, key) => {
      //   if (o.id === this.state.id) {
      //     if (this.state.updateView) {
      //       data[key].isActive = 0;
      //       console.log("Deleted--1-->state.id", this.state.id);
      //       console.log("Deleted--2-->o.id", o.id);
      //       console.log("Deleted--3-->state.updateView", this.state.updateView);
      //       console.log("Deleted--4-->state.key", key);
      //       console.log("Deleted--5-->data", data);
      //     } else data.splice(key, 1);
      //   }
      // });
      var disableDept = false;
      var mdata = data
        .map((o, key) => {
          if (o.id === this.state.id || o.isActive === 0) {
            if (this.state.updateView) {
              o.isActive = 0;
            }
          } else {
            if (
              getConfigValue(
                configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
              ) === "2"
            )
              disableDept = true;
            var itemtaxAmount =
              (parseFloat(o.amount) * parseFloat(o.taxPercent)) / 100; //11 is tax percent

            sum_of_tax = sum_of_tax + itemtaxAmount;
            sumOfamt = sumOfamt + parseFloat(o.amount);
            return o;
          }
        })
        .filter((o) => o !== undefined);

      // mdata.forEach((o, key) => {
      //   console.log("isass", o.isActive);
      //   if (o.isActive === 1 || o.isActive === "1") {
      //     if (
      //       getConfigValue(
      //         configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
      //       ) === "2"
      //     )
      //       disableDept = true;
      //     var itemtaxAmount =
      //       (parseFloat(o.amount) * parseFloat(o.taxPercent)) / 100; //11 is tax percent

      //     sum_of_tax = sum_of_tax + itemtaxAmount;
      //     sumOfamt = sumOfamt + parseFloat(o.amount);
      //   }
      // });
      var netTotal = Math.round(sum_of_tax + sumOfamt).toFixed(2);

      console.log("totalAmount", sumOfamt, sum_of_tax);

      this.setState({
        totalAmount: sumOfamt.toFixed(2),
        totalTax: sum_of_tax.toFixed(2),
        netTotal: netTotal,
        invoiceList: mdata,
        deleteItem: false,
        disableDept: disableDept,
      });
      console.log("printing the invoice list", this.state.invoiceList);
      this.hideItemAlert();
      //  if(this.state.approveButton)
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideItemAlert() {
    console.log("alert");
    /*
                if(!this.state.disableDept){
                    this.setState({  selectedItemGroup: '',
                        selectedDepartment: '',})
                }
        */

    this.setState({
      //   alert: null,
      id: 0,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
      indentLineId: null,
      taxPercent: "",
    });
  }

  hideAlert() {
    console.log("alert");
    /* if(!this.state.disableDept){
           this.setState({  selectedItemGroup: '',
               selectedDepartment: '',})
         }*/
    this.setState({
      alert: null,
      loading: false,
      showMessagealert: false,
      indentAlert: false,
      item: "",
      itemId: "",
      selectedItem: "",
      itemDesc: "",
      quantity: "",
      hsnCode: "",
      unit: "",
      weight: "",
      uom: "",
      taxId: "",
      itemGroupId: "",
      rate: "",
      amount: "",
      stock: "",
      actions: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      purchaseType: "",
      lineItemId: 0,
      indentLineId: 0,
      price: "",
      taxPercent: "",
      isActive: 1,
      lastPurchaseRate: "",
      lastPurchaseDate: "",
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      indentType: "",
      pendingAtRcpt: "",
      printingPop: false,
      description: "",
      InsRate: "",
      InsAmount: "",
      make: "",
      remarks1: "",
      discountMode: "1",
      discountPercentage: "",
      oldQty: 0,
    });
  }

  //on select indent no

  indenthideAlert() {
    if (this.state.invoiceList.length === 0) {
      this.setState({
        SelectindentType: this.getDefaultindentConvertion(2),
      });
    }

    this.setState({
      indentAlert: false,
      loading: false,
      //SelectindentType: this.getDefaultindentConvertion(2)
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getList();
    }
    this.setState({
      // searchpoNo: '',s
      canChangeUnit: true,
      isCreated: false,
      approveButton: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      quantity: "",
      stock: "",
      itemGroupId: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      SelectindentType: this.getDefaultindentConvertion(2),
      taxType: "",
      selectedDeptId: "",
      selectedIndent: "",
      selectedGrpId: "",
      selectedUnit: "",
      vehicleQuantiy: "",
      netTotal: "",
      itemTaxAmt: "",
      isgst: "",
      csgst: "",
      ssgst: "",
      supplier_Id: "",
      updateItem: false,
      selectedDepartment: "",
      PoDate: moment().format("DD-MM-YYYY"),
      expecDate:
        this.props.state.companyInfo.id === "116"
          ? moment()
              .add("days", 10)
              .format("DD-MM-YYYY")
          : moment()
              .add("days", 15)
              .format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      creditTerm: 60,
      deliveryTimeLine: this.props.state.companyInfo.id === "116" ? 10 : 15,
      totalTax: "",
      totalAmount: "",
      footerNote: "",
      delAddress: getCookie(cons.SHIPPING_ADDRESS),
      remarks: "",
      form_purchaseType: "",
      lineItemId: 0,
      indentLineId: null,
      finalApprover: false,
      taxId: "",
      branchId: "",
      frieghtCharge: "",
      projectId: "",
      internalNote: "",
      indentlist: null,
      poStatus: "",
      itemGroup: "",
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      selectedItemGroup: "",
      disableDept: false,
      billingBranchId: "",
      rqName: "",
      poValidDate: "",
      filesList: [],
      typeId: 0,
      addPendQty: true,
      indentHdrId: "",
      approvedIndents: [],
      shippingBranchId: "",
      billingStateCode: "",
      billingStateName: "",
      shippingBranchName: "",
      shippingStateCode: "",
      shippingBranchAddress: "",
      billingBranchAddress: "",
      delAddress: "",
      supplierStateCode: "",
      phaseId: "",
      supplierBranchId: "",
      supplierAddress: "",
      expecDate: "",
      deliveryTimeLine: "",
      taxPayable: "",
      shippingStateName: "",
      projectId: "",
      selectedBudgetHead: "",
      advanceType: "",
      advancePercentage: "",
      advanceAmount: "",
      customerId: "",
      contactPerson: "",
      contactNo: "",
      terms: "",
      disableTax: false,
    });
  }

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList,
      printingPop = this.state.printingPop;

    /* var sum_of_tax = parseInt(this.state.sumOftax) + parseInt(this.state.itemTaxAmt);
         var sumOfamt = parseInt(this.state.sumOfAmount) + parseInt(this.state.amount);*/
    console.log("itemTaxAmt " + this.state.itemTaxAmt);
    var itemIndex = 0,
      hasItem = false,
      sum_of_tax = 0,
      sumOfamt = 0;
    if (this.state.updateItem) {
      /* itemIndex=this.state.id;
             arr[itemIndex].taxamount = parseFloat(this.state.itemTaxAmt);
             arr[itemIndex].amount = parseFloat(this.state.amount)*/
      arr.forEach((prop, i) => {
        if (prop.id === this.state.id) {
          arr[i].taxamount = parseFloat(this.state.itemTaxAmt);
          arr[i].amount = parseFloat(
            this.getAmount(
              this.state.quantity,
              this.state.rate,
              this.state.InsRate,
              this.state.discountMode,
              this.state.discountPercentage
            )
          );
          itemIndex = i;
        }
      });
    } else {
      itemIndex = this.state.invoiceList.length;
      if (parseFloat(this.state.amount) > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
      sumOfamt =
        sumOfamt +
        parseFloat(
          this.getAmount(
            this.state.quantity,
            this.state.rate,
            this.state.InsRate,
            this.state.discountMode,
            this.state.discountPercentage
          )
        );
    }
    arr.forEach((q) => {
      // sumOfQty = sumOfQty+parseInt(q.quantityType);
      if (q.isActive === 1) {
        sum_of_tax = sum_of_tax + (q.taxamount ? parseFloat(q.taxamount) : 0);
        sumOfamt = sumOfamt + parseFloat(q.amount);
        if (
          (this.state.selectedDepartment === q.depId) &
            (this.state.selectedItemGroup === q.itemGroupId) &&
          this.state.selectedItem === q.itemId &&
          !this.state.updateItem
        )
          hasItem = true;
      }
    });
    console.log(
      "getting here the itemId",
      this.state.selectedItemGroup + "" + this.state.selectedItem,
      this.state.itemIdNew
    );
    var addItemObj = {
      id: itemIndex,
      //new  Lineadded for indentId
      indentId: this.state.indentId,
      indentLineId: this.state.indentLineId,
      lineItemId: this.state.lineItemId,
      //itemId: this.state.selectedItem,
      itemId: this.state.itemIdNew,
      itemDesc: this.state.itemDesc,
      itemGrp: this.state.selectedItemGroup + "" + this.state.selectedItem,
      selectedItem: this.state.selectedItem,
      quantity: this.state.quantity,
      hsnCode: this.state.hsnCode,
      unit: this.state.uom,
      rate: this.state.rate,
      amount: this.getAmount(
        this.state.quantity,
        this.state.rate,
        this.state.InsRate,
        this.state.discountMode,
        this.state.discountPercentage
      ),
      // amount: parseFloat(this.state.amount),
      itemGroupId: this.state.selectedItemGroup,
      depId: !printingPop ? this.state.selectedDepartment : "",
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxId: this.state.taxId,
      taxamount: this.state.itemTaxAmt,
      taxPercent: this.state.taxPercent,
      itemStock: this.state.itemStock,
      isActive: 1,
      lastPurchaseRate: this.state.lastPurchaseRate,
      lastPurchaseDate: this.state.lastPurchaseDate,
      stRemQty: this.state.stRemQty,
      indentType: this.state.indentType,
      pendingAtRcpt: this.state.pendingAtRcpt,
      description: this.state.description,
      branchStock: this.state.branchStock,
      stock: this.state.stock,
      hsnCode: this.state.hsnCode,
      selectedBudgetHeadItem: this.state.selectedBudgetHeadItem,
      budgetHeadId: this.state.selectedBudgetHeadItem,
      discountMode: this.state.discountMode,
      discountPercentage: this.state.discountPercentage,
      InsRate: this.state.InsRate,
      InsAmount: this.state.InsAmount,
      make: this.state.make,
      remarks: this.state.remarks1,
      oldQty: this.state.oldQty,
    };

    if (this.state.printingPop) {
      if (
        this.state.amount !== "" &&
        this.state.amount > 0 &&
        this.state.taxId !== 0 &&
        this.state.taxId !== ""
      ) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);
        this.setState({
          hideJuteType: false,
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: Math.round(sumOfamt).toFixed(2),
          netTotal: Math.round(
            parseFloat(sumOfamt) + parseFloat(sum_of_tax)
          ).toFixed(2),
          updateItem: false,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else if (
      (this.state.selectedDepartment !== "") &
        (this.state.selectedItemGroup !== "") &&
      this.state.selectedItem !== "" &&
      this.state.quantity !== "" &&
      this.state.quantity !== "0" &&
      this.state.amount !== "" &&
      this.state.amount !== "0" &&
      // this.state.taxId !== "0" &&
      this.state.taxId !== ""
      // this.state.taxId > 0
    ) {
      console.log("getting the taxId", this.state.taxId);
      if (!hasItem) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        this.setState({
          hideJuteType: false,
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: Math.round(sumOfamt).toFixed(2),
          netTotal: Math.round(
            parseFloat(sumOfamt) + parseFloat(sum_of_tax)
          ).toFixed(2),
          updateItem: false,
        });
        if (
          getConfigValue(
            configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
          ) === "2"
        )
          this.setState({ disableDept: true });

        this.hideAlert();
      } else {
        this.basicAlert("Item Already Saved. Please Select Another One..!!");
      }
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideItemAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      isReject: false,
      isApprove: false,
    });
  }
  handleApprovedIndents = (resp) => {
    this.setState({ approvedIndents: resp, loadIndent: true });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    // new code for AddItem Validation...

    const validateFields = [
      {
        field: this.getSource(this.state.SelectindentType),
        msg: "PO Source",
        type: 2,
      },
      { field: this.state.supplier_Id, msg: "Supplier", type: 2 },
      { field: this.state.supplierBranchId, msg: "Supplier Branch", type: 2 },
      { field: this.state.billingBranchId, msg: "Billing Address", type: 2 },
      {
        field: this.state.shippingBranchId,
        msg: "Shipping Address",
        type: 2,
      },
      {
        field: this.state.deliveryTimeLine,
        msg: "Delivery Timeline",
        type: 2,
      },

      { field: this.state.form_purchaseType, msg: "Category Type", type: 2 },
      {
        field: this.state.deliveryTimeLine,
        msg: "Delivery Timeline",
        type: 2,
      },
      { field: this.state.projectId, msg: "Project", type: 2 },
    ];
    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message}...!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message}...!!`);
      }
    } else {
      if (
        this.state.SelectindentType === 1 ||
        this.state.SelectindentType === 3
      ) {
        if (this.state.updateView) {
          var data = {
            companyId: this.props.state.companyInfo.id,
            branchId: this.state.branchId,
            financialYear: getCookie(cons.ACADEMIC_YEAR),
            recordType: this.state.isBranch
              ? "INDENT"
              : this.state.isWO
              ? "BOQ"
              : "BOM",
            itemGroup: this.state.itemGroup,
          };
          requestList(
            serverApi.GET_APPROVED_INDENT_BY_ITEMGROUP,
            data,
            this.handleApprovedIndents
          );
          // getDataFromUrl(serverApi.APPROVED_INDENTS + 's/' + this.props.state.companyInfo.id + "/" + getCookie(cons.ACADEMIC_YEAR) + "/" + this.state.branchId + "/" + this.state.itemGroup, this.handleApprovedIndents)
        }
        this.setState({
          loading: true,
          indentAlert: true,
          updateItem: false,
        });
      } else
        this.setState({
          loading: true,
          alert: true,
          updateItem: false,
        });
    }

    // old code for addItem Validation...

    // if (this.state.supplier_Id !== "" && this.state.form_purchaseType !== "")
    //   if (
    //     this.state.SelectindentType === 1 ||
    //     this.state.SelectindentType === 3
    //   ) {
    //     if (this.state.updateView) {
    //       var data = {
    //         companyId: this.props.state.companyInfo.id,
    //         branchId: this.state.branchId,
    //         financialYear: getCookie(cons.ACADEMIC_YEAR),
    //         recordType: this.state.isBranch
    //           ? "INDENT"
    //           : this.state.isWO
    //           ? "BOQ"
    //           : "BOM",
    //         itemGroup: this.state.itemGroup,
    //       };
    //       requestList(
    //         serverApi.GET_APPROVED_INDENT_BY_ITEMGROUP,
    //         data,
    //         this.handleApprovedIndents
    //       );
    //       // getDataFromUrl(serverApi.APPROVED_INDENTS + 's/' + this.props.state.companyInfo.id + "/" + getCookie(cons.ACADEMIC_YEAR) + "/" + this.state.branchId + "/" + this.state.itemGroup, this.handleApprovedIndents)
    //     }
    //     this.setState({
    //       loading: true,
    //       indentAlert: true,
    //       updateItem: false,
    //     });
    //   } else
    //     this.setState({
    //       loading: true,
    //       alert: true,
    //       updateItem: false,
    //     });
    // else {
    //   this.basicAlert("Please Enter All Mandatory Fieldssss..!!");
    // }
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      totalTax: 0,
      totalAmount: 0,
      netTotal: 0,
      basicAlert: null,
      //  canChangeUnit: true,
      invoiceList: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0,
      totalAmount = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        totalAmount = totalAmount + parseFloat(prop.amount);
        if (prop.ssgst !== undefined && prop.ssgst !== null)
          totSgst =
            totSgst + (parseFloat(prop.amount) * parseFloat(prop.ssgst)) / 100;
        if (prop.isgst !== undefined && prop.isgst !== null)
          totIgst =
            totIgst + (parseFloat(prop.amount) * parseFloat(prop.isgst)) / 100;
        if (prop.csgst !== undefined && prop.csgst !== null)
          totcgst =
            totcgst + (parseFloat(prop.amount) * parseFloat(prop.csgst)) / 100;
      }
    });
    var totalTax = 0;
    var advAmtCal = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    } else if (tax === "totalTax") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;
    } else if (tax === "totalAmount") {
      totalTax = totalAmount;
      advAmtCal = (totalTax * this.state.advancePercentage) / 100;
      this.state.advanceAmount = advAmtCal;
      console.log("getting the calculated value here", advAmtCal);
    } else if (tax === "netTotal") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;

      totalTax = totalTax + totalAmount;
    }
    return totalTax.toFixed(2);
  }
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      hdrId,
      isCancel,
      isClose,
      isReject,
      isApprove,
      poStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(poStatus);
    }
    if (isCancel) {
      this.setState({ isCancel: false });
      this.onApproveButtonClick("6");
    }
    if (isClose) {
      this.setState({ isClose: false });
      this.onApproveButtonClick("5");
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        14,
        fileName,
        hdrId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
      isCancel: false,
      isClose: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  // handleFileChange(e) {
  //   e.preventDefault();
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   var fileName = file.name,
  //     fileType;
  //   fileName = fileName.split(".");
  //   fileType = fileName[1].toUpperCase();
  //   reader.onloadend = () => {
  //     if (
  //       fileType === "PDF" ||
  //       fileType === "JPG" ||
  //       fileType === "JPEG" ||
  //       fileType === "PNG" ||
  //       fileType === "XLSX"
  //     ) {
  //       this.setState({
  //         file: file,
  //         fileName: file.name,
  //         fileType: fileName[1],
  //         //filesList: arr,
  //         uploadInvoice: true,
  //         // imagePreviewUrl: reader.result
  //       });
  //       this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
  //     }
  //   };
  //   reader.readAsDataURL(file);
  // }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    var fileNameArr = fileName.split(".");
    for (var i = 0; i < fileNameArr.length; i++) {
      fileType = fileNameArr[i].toUpperCase();
    }

    fileName = fileName.split(".");
    // fileType = fileName[1].toUpperCase();

    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          // fileType: fileName[1],
          fileType: fileType,
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
    // }
  }
  getDataTable = () => {
    var data = [];
    //   console.log(">>>>>>>>>ta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      console.log(
        "getting the response here in getDataTable",
        this.state.invoiceList
      );
    this.state.invoiceList.map((prop, key) => {
      var discount = prop.discountPercentage;
      var amount = (parseFloat(prop.rate) * parseFloat(prop.quantity)).toFixed(
        2
      );
      if (prop.discountMode == 2 && discount) discount = discount + "%";
      if (prop.isActive === 1) {
        prop.id = key;
        var obj = {};
        if (
          this.state.selectedRowData &&
          this.state.selectedRowData.index == key
        ) {
          console.log("Nag", "after Click");
          var obj = {
            ...prop,
            discountPercentageSim: discount,
            rateIns: prop.rate + "\n" + (prop.InsRate ? prop.InsRate : ""),
            amountIns: amount + "\n" + prop.InsAmount,
            itemData: (
              <div>
                <TextField
                  id="itemDesc"
                  // className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].itemDesc = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.itemDesc}
                  fullWidth={true}
                  variant="outlined"
                />
                <TextField
                  id="make"
                  // className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].make = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.make}
                  fullWidth={true}
                  variant="outlined"
                />
                <TextField
                  id="remarks"
                  // className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].remarks = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.remarks}
                  fullWidth={true}
                  variant="outlined"
                />
              </div>
            ),
            // itemData:
            //   prop.itemDesc +
            //   "\n" +
            //   "Make: " +
            //   (prop.make ? prop.make : "") +
            //   "\nRemarks: " +
            //   (prop.remarks ? prop.remarks : ""),

            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      // console.log("getting data from here", o);
                      if (o.id === key) {
                        if (o.itemGroupId == 997) {
                          this.setState({ printingPop: true, alert: true });
                        } else
                          this.basicItemAlert(
                            "Do You Like To Update This Item?"
                          );
                        console.log("getting data from here", o);

                        if (
                          this.state.supplier_Id !== "" &&
                          this.state.taxType !== "" &&
                          this.state.taxType.value !== 0 &&
                          this.state.form_purchaseType !== ""
                        ) {
                          // alert(o.qualityId);
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //  data.splice(i,1);
                          this.setState({
                            //alert: true,
                            id: o.id,
                            indentId: o.indentId,
                            indentLineId: o.indentLineId,
                            lineItemId: o.lineItemId,
                            itemId: o.itemId,
                            updateItem: true,
                            updateItemAlert: true,
                            reloadSelect: true,
                            selectedDepartment: o.depId,
                            selectedItemGroup: o.itemGroupId,
                            //selectedItem: o.itemId,
                            selectedItem: o.itemCode,
                            //itemCode: o.itemCode,
                            quantity: o.quantity,
                            uom: o.uom,
                            hsnCode: o.hsnCode,
                            rate: o.rate,
                            amount: o.amount,
                            taxId: o.taxId,
                            itemDesc: o.itemDesc,
                            igstPercent: o.isgst,
                            sgstPercent: o.ssgst,
                            cgstPercent: o.csgst,
                            taxPercent: o.taxPercent,
                            itemTaxAmt: o.taxamount,
                            isActive: o.isActive,
                            lastPurchaseRate: o.lastPurchaseRate,
                            lastPurchaseDate: o.lastPurchaseDate,
                            loadItemGroup: true,
                            loadItem: true,
                            stRemQty: o.stRemQty,
                            indentType: o.indentType,
                            pendingAtRcpt: o.pendingAtRcpt,
                            description: o.description,
                            selectedBudgetHeadItem: o.selectedBudgetHeadItem,
                            discountMode: o.discountMode,
                            discountPercentage: o.discountPercentage,
                            InsRate: o.InsRate,
                            InsAmount: o.InsAmount,
                            make: o.make,
                            remarks1: o.remarks,
                            oldQty: o.oldQty,
                            budgetHeadId: o.budgetHeadId,
                          });
                        } else {
                          this.basicAlert(
                            "Please Enter All Mandatory Fields..!!"
                          );
                        }
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>{" "}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color={
                    parseFloat(prop.rate) > parseFloat(prop.lastPurchaseRate) &&
                    prop.lastPurchaseRate > 0
                      ? "danger"
                      : "dangerNoBackground"
                  }
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };
        } else {
          var obj = {
            ...prop,

            discountPercentageSim: discount,
            rateIns: prop.rate + "\n" + (prop.InsRate ? prop.InsRate : ""),

            amountIns: amount + "\n" + prop.InsAmount,
            itemData:
              prop.itemDesc +
              "\n" +
              "Make: " +
              (prop.make ? prop.make : "") +
              "\nRemarks: " +
              (prop.remarks ? prop.remarks : ""),

            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        if (o.itemGroupId == 997) {
                          this.setState({ printingPop: true, alert: true });
                        } else
                          this.basicItemAlert(
                            "Do You Like To Update This Item?"
                          );
                        console.log(
                          "getting data from heressssno",
                          this.state.supplier_Id,
                          this.state.taxType,
                          this.state.form_purchaseType
                        );
                        if (
                          this.state.supplier_Id !== "" &&
                          // this.state.taxType !== "" &&
                          // this.state.taxType.value !== 0 &&
                          this.state.form_purchaseType !== ""
                        ) {
                          // alert(o.qualityId);
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //  data.splice(i,1);
                          this.setState({
                            //alert: true,
                            id: o.id,
                            indentId: o.indentId,
                            indentLineId: o.indentLineId,
                            lineItemId: o.lineItemId,
                            itemId: o.itemId,
                            itemIdNew: o.itemId,
                            updateItem: true,
                            updateItemAlert: true,
                            reloadSelect: true,
                            selectedDepartment: o.depId,
                            selectedItemGroup: o.itemGroupId,
                            //selectedItem: o.itemId,
                            selectedItem: o.itemCode
                              ? o.itemCode
                              : o.selectedItem + "",
                            quantity: o.quantity,
                            uom: o.uom,
                            hsnCode: o.hsnCode,
                            rate: o.rate,
                            amount: o.amount,
                            taxId: o.taxId,
                            itemDesc: o.itemDesc,
                            igstPercent: o.isgst,
                            sgstPercent: o.ssgst,
                            cgstPercent: o.csgst,
                            taxPercent: o.taxPercent,
                            itemTaxAmt: o.taxamount,
                            isActive: o.isActive,
                            lastPurchaseRate: o.lastPurchaseRate,
                            lastPurchaseDate: o.lastPurchaseDate,
                            loadItemGroup: true,
                            loadItem: true,
                            stRemQty: o.stRemQty,
                            indentType: o.indentType,
                            pendingAtRcpt: o.pendingAtRcpt,
                            description: o.description,
                            selectedBudgetHeadItem: o.selectedBudgetHeadItem,
                            discountMode: o.discountMode,
                            discountPercentage: o.discountPercentage,
                            InsRate: o.InsRate,
                            InsAmount: o.InsAmount,
                            make: o.make,
                            remarks1: o.remarks,
                            oldQty: o.oldQty,
                            budgetHeadId: o.budgetHeadId,
                          });
                        } else {
                          this.basicAlert(
                            "Please Enter All Mandatory Fields..!!"
                          );
                        }
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>{" "}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      console.log("Nagesh--->deleted item11", o.id);
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        console.log("Nagesh--->deleted item", o.id);
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color={
                    parseFloat(prop.rate) > parseFloat(prop.lastPurchaseRate) &&
                    prop.lastPurchaseRate > 0
                      ? "danger"
                      : "dangerNoBackground"
                  }
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };
        }
        console.log(
          "indennnnnnnnnnnnnnnnnnnnnnnnnm-----------------",
          this.state.indentStatus
        );
        data.push(obj);
      }
    });

    return data;
  };

  getAmount = (quantity, rate, insRate, disMode, discountPercentage) => {
    var amt = 0,
      rate1 = 0;

    if (insRate > 0 && insRate !== "0") {
      rate1 = parseFloat(rate1) + parseFloat(insRate);
    }
    if (rate > 0 && rate !== "0") rate1 = parseFloat(rate1) + parseFloat(rate);
    if (quantity > 0 && quantity !== "0") {
      amt = (parseFloat(rate1) * parseFloat(quantity)).toFixed(2);
    }
    var disAmount = 0;
    if (discountPercentage && discountPercentage !== "0") {
      if (disMode == "2") {
        disAmount = parseFloat(amt) * (parseFloat(discountPercentage) / 100);
      } else {
        disAmount = parseFloat(discountPercentage);
      }
    }
    amt = (parseFloat(amt) - disAmount).toFixed(2);
    console.log("amm", amt);

    return amt;
  };
  setHoveredRow = (rowInfo) => {
    console.log("hovver", rowInfo);
    this.setState({ selectedRowData: rowInfo });
  };

  render() {
    const { classes } = this.props;

    const {
      selectedJuteType,
      selectedIndent,
      taxType,
      printingPop,
      isSupplier,
    } = this.state;
    console.log("taxType", taxType);
    var discountOptions = [
      { label: "Fixed", value: "1", name: "Fixed" },
      { label: "Percentage", value: "2", name: "Percentage" },
    ];
    var advancetOptions = [
      { label: "Amount", value: "Amount", name: "Amount" },
      { label: "Percentage", value: "Percentage", name: "Percentage" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
    var poPrintBeforeApproval = getConfigValue(
      configNames.PO_PRINT_BEFORE_APPROVE
    );
    if (poPrintBeforeApproval == "2") {
      if (this.state.poStatus == "3") {
        poPrintBeforeApproval = true;
      } else {
        poPrintBeforeApproval = false;
      }
    } else {
      poPrintBeforeApproval = true;
    }

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    var valid1 = function(current) {
      return current.isAfter(yesterday);
    };

    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];
    var taxTypes = getConfigValue(configNames.TAX_TYPES);

    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      console.log("getting the data here to get Gstss", taxTypes, taxTypes);

      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
      if (taxTypes.noTax === 1) {
        ucoptions.push({ value: 4, label: "NO TAX", name: "NO TAX" });
      }
    } else {
      console.log("getting the data here to get Gst else", taxTypes, taxTypes);

      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }

    const currencyoptions = [
      // { value: 0, label: "Select...", name: "Select..." },
    ];

    var currency = getConfigValue(configNames.CURRENCY_TYPES);
    if (currency !== "1") {
      var currencyType = JSON.parse(currency);
      console.log(
        "configNames.CURRENCY_TYPESssss",
        currencyType,
        currencyType.inr
      );

      if (currencyType.inr === 1 && currencyType.usd === 0) {
        currencyoptions.push({ value: "INR", label: "INR", name: "INR" });
        console.log("getting the currency1", currencyoptions);
      }
      if (currencyType.inr === 0 && currencyType.usd === 1) {
        currencyoptions.push({ value: "USD", label: "USD", name: "USD" });
        console.log("getting the currency2", currencyoptions);
      }
      if (currencyType.inr === 1 && currencyType.usd === 1) {
        console.log("getting the currency3", currencyoptions);

        currencyoptions.push({ value: "INR", label: "INR", name: "INR" });
        currencyoptions.push({ value: "USD", label: "USD", name: "USD" });
      }
      console.log("getting the currency data", currencyoptions);
    } else {
      currencyoptions.push({ value: "INR", label: "INR", name: "INR" });
    }

    var options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "By Indent", name: "WITHINDENT" },
      { value: 2, label: "Direct Order", name: "WITHOUTINDENT" },
      { value: 3, label: "By BOM", name: "WITHBOM" },
    ];
    if (getCookie(cons.MENU_ID) == 513) {
      options = [
        { value: 0, label: "Select...", name: "Select..." },
        //  { value: 1, label: 'By Indent', name: 'WITHINDENT' },
        { value: 2, label: "Direct Order", name: "WITHOUTBOQ" },
        { value: 3, label: "By BOQ", name: "WITHBOQ" },
      ];
    }

    const taxPaybleOptions = [
      { value: 1, label: "Yes", name: "Yes" },
      { value: 0, label: "No", name: "No" },
    ];

    const supplierBranchData = [
      {
        value: 16,
        name:
          "Supplier1^Global Technology Park, Tower C, Marathalli, Outer Ring Road, Devarabeesanahalli Village, Varthur Hobli, Bangalore - 560103^37^Andra Pradesh",
        label: "Supplier1",
        address:
          "Global Technology Park, Tower C, Marathalli, Outer Ring Road, Devarabeesanahalli Village, Varthur Hobli, Bangalore - 560103",
        contactPerson: "Satyapriya Singh",
        gstNo: "29AAWCS5258F1ZM",
        email: null,
        billingStateCode: "37",
        billingStateName: "Andra Pradesh",
      },
      {
        value: 17,
        name:
          "Supplier2^Global Technology Park, Tower C, Marathalli, Outer Ring Road, Devarabeesanahalli Village, Varthur Hobli, Bangalore - 560103^29^Andra Pradesh",
        label: "Supplier2",
        address:
          "Global Technology Park, Tower C, Marathalli, Outer Ring Road, Devarabeesanahalli Village, Varthur Hobli, Bangalore - 560103",
        contactPerson: "Satyapriya Singh",
        gstNo: "29AAWCS5258F1ZM",
        email: null,
        billingStateCode: "37",
        billingStateName: "Andra Pradesh",
      },
    ];
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              //   cardTitle={"Create Jute PO"}
              // headerColor={"blue"}
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert1}
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.handleIndentSelectResp()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  {this.state.SelectindentType == 3 ? (
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Budget Head
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          //selectDisabled={this.state.disableDept}
                                          id={"selectedBudgetHead"}
                                          onSelect={this.onSelect}
                                          defValue={
                                            this.state.selectedBudgetHead
                                          }
                                          getName={this.getBudgetName}
                                          stateKey="selectedBudgetHead"
                                          staticData={this.state.budgetHeadList}
                                          isRefresh={this.state.loadDepartment}
                                          setIsRefresh={(val) => {
                                            this.setState({
                                              loadDepartment: false,
                                            });
                                          }}
                                          //   url={serverApi.GET_BUDGET_HEAD + "company/"+this.props.state.companyInfo.id}
                                          value={this.state.selectedBudgetHead}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  ) : (
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          {/* {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                                          Branch{this.state.branchId}
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          // selectDisabled={this.state.isDisabled}
                                          defValue={this.state.branchId}
                                          onSelect={this.onSelect}
                                          stateKey="branchId"
                                          // isRefresh={this.state.loadQuality}
                                          // setIsRefresh={this.afterRefreshQuality}
                                          url={
                                            serverApi.SELECT_BRANCH +
                                            this.props.state.companyInfo.id
                                          }
                                          value={this.state.branchId}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  )}
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        // selectDisabled={true}
                                        defValue={this.state.itemGroup}
                                        onSelect={this.onSelect}
                                        getName={this.getItemGroupName}
                                        staticData={this.state.groupItemList}
                                        isRefresh={this.state.loadItemGroup}
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadItemGroup: false,
                                          });
                                        }}
                                        stateKey="itemGroup"
                                        // url={serverApi.SEARCH_ITEM_GROUPS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={2}>
                                      <InputLabel className={classes.label}>
                                        {this.state.SelectindentType == 3
                                          ? this.state.isWO
                                            ? "BOQ List"
                                            : "BOM List"
                                          : "Indent List"}
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={10}>
                                      <SelectboxComponent
                                        // selectDisabled={this.state.updateView}
                                        id={"selectedIndent"}
                                        defValue={selectedIndent}
                                        onSelect={this.onSelect}
                                        // getName={(name)=>{
                                        //     var name=name.split("^");
                                        //     if(this.state.selectedIndent!==name[2])
                                        //     this.onSelectIndent( name[2],name[3]);
                                        //     else {
                                        //         if(this.state.selectedIndent===name[2]&&this.state.updateView){
                                        //             this.onSelectIndent( name[2],name[3]);
                                        //         }

                                        //     }
                                        // }}
                                        isRefresh={this.state.loadIndent}
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadIndent: false,
                                          });
                                        }}
                                        stateKey="selectedIndent"
                                        staticData={this.state.approvedIndents}
                                        //  url={serverApi.APPROVED_INDENTS + 's/' + this.props.state.companyInfo.id + "/" + getCookie(cons.ACADEMIC_YEAR)+"/"+this.state.branchId+"/"+this.state.itemGroup}
                                        value={selectedIndent}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {this.state.indentlist !== null ? (
                                  <ItemGrid xs={12}>
                                    <GridContainer>
                                      <ItemGrid xs={1}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              tabIndex={
                                                -1
                                              } /*
                                                                                            value={this.state.selectAll?1:0}indentlist
                                                                                            checked={this.state.selectAll?1:0}*/
                                              onChange={() => {
                                                var list = this.state
                                                  .indentlist;
                                                var data = list.indentDetails;
                                                data.forEach((o, k) => {
                                                  if (o.isActive === 0)
                                                    data[k].isActive = 1;
                                                  else data[k].isActive = 0;
                                                });
                                                list.indentDetails = data;

                                                this.setState({
                                                  indentlist: list,
                                                });
                                              }}
                                              // onClick={() => this.handleToggle(prop)}
                                              checkedIcon={
                                                <Check
                                                  className={
                                                    classes.checkedIcon
                                                  }
                                                />
                                              }
                                              icon={
                                                <Check
                                                  className={
                                                    classes.uncheckedIcon
                                                  }
                                                />
                                              }
                                              classes={{
                                                checked: classes.checked,
                                              }}
                                              style={{ marginLeft: 10 }}
                                            />
                                          }
                                          classes={{
                                            label: classes.menulabel,
                                          }}
                                          label={""}
                                        />
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Item Code
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Item Description
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={3}>
                                        <InputLabel className={classes.label}>
                                          Quantity
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={2}>
                                        <InputLabel className={classes.label}>
                                          Rate
                                        </InputLabel>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                <ItemGrid
                                  xs={12}
                                  style={{
                                    maxHeight: 250,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <GridContainer>
                                    {this.state.indentlist !== null
                                      ? this.state.indentlist.indentDetails.map(
                                          (prop, i) => {
                                            console.log("Nagesh4", prop);
                                            return (
                                              <ItemGrid
                                                xs={12}
                                                style={{
                                                  padding: "0!important",
                                                }}
                                              >
                                                <GridContainer>
                                                  <ItemGrid xs={1}>
                                                    <FormControlLabel
                                                      // style={{
                                                      //   marginRight: "10px",
                                                      // }}
                                                      control={
                                                        <Checkbox
                                                          tabIndex={-1}
                                                          value={prop.isActive}
                                                          checked={
                                                            prop.isActive
                                                          }
                                                          onChange={() => {
                                                            var list = this
                                                              .state.indentlist;
                                                            var data = this
                                                              .state.indentlist
                                                              .indentDetails;
                                                            if (
                                                              data[i]
                                                                .isActive === 0
                                                            )
                                                              data[
                                                                i
                                                              ].isActive = 1;
                                                            else
                                                              data[
                                                                i
                                                              ].isActive = 0;
                                                            list.indentDetails = data;
                                                            this.setState({
                                                              indentlist: list,
                                                            });
                                                          }}
                                                          // onClick={() => this.handleToggle(prop)}
                                                          checkedIcon={
                                                            <Check
                                                              className={
                                                                classes.checkedIcon
                                                              }
                                                            />
                                                          }
                                                          icon={
                                                            <Check
                                                              className={
                                                                classes.uncheckedIcon
                                                              }
                                                            />
                                                          }
                                                          classes={{
                                                            checked:
                                                              classes.checked,
                                                          }}
                                                          // style={{
                                                          //   marginLeft: 10,
                                                          // }}
                                                        />
                                                      }
                                                      classes={{
                                                        label:
                                                          classes.menulabel,
                                                      }}
                                                      label={""}
                                                    />
                                                  </ItemGrid>
                                                  <ItemGrid xs={3}>
                                                    {prop.itemGroupId +
                                                      "" +
                                                      prop.itemCode}
                                                  </ItemGrid>
                                                  <ItemGrid xs={3}>
                                                    {prop.itemName}
                                                  </ItemGrid>

                                                  <ItemGrid xs={3}>
                                                    <TextField
                                                      disabled={true}
                                                      id="quantity"
                                                      className={
                                                        classes.textField
                                                      }
                                                      onChange={(event) => {
                                                        var list = this.state
                                                          .indentlist;
                                                        var data =
                                                          list.indentDetails;
                                                        if (
                                                          event.target.value !==
                                                          ""
                                                        ) {
                                                          if (
                                                            prop.categoryId !==
                                                            2
                                                          ) {
                                                            if (
                                                              parseFloat(
                                                                event.target
                                                                  .value
                                                              ) <=
                                                              prop.remainingQuantity
                                                            ) {
                                                              data[
                                                                i
                                                              ].qty = parseFloat(
                                                                event.target
                                                                  .value
                                                              );
                                                              data[i].stRemQty =
                                                                prop.remainingQuantity;
                                                            } else {
                                                              this.basicAlert(
                                                                "Quantity should not be greater than indent quantity..!!"
                                                              );
                                                            }
                                                          } else {
                                                            data[
                                                              i
                                                            ].qty = parseFloat(
                                                              event.target.value
                                                            );
                                                            data[i].stRemQty =
                                                              prop.remainingQuantity;
                                                          }
                                                        } else data[i].qty = 0;
                                                        list.indentItems = data;
                                                        this.setState({
                                                          indentlist: list,
                                                        });
                                                      }}
                                                      value={
                                                        prop.remainingQuantity
                                                        // ? prop.remainingQuantity
                                                        // : prop.re
                                                      }
                                                      fullWidth={true}
                                                      iType={"number"}
                                                      decimal={3}
                                                    />
                                                  </ItemGrid>
                                                  <ItemGrid xs={2}>
                                                    {prop.rate}
                                                  </ItemGrid>
                                                </GridContainer>
                                              </ItemGrid>
                                            );
                                          }
                                        )
                                      : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {//add item alert
                  this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                {this.state.SelectindentType == 3 &&
                                !printingPop ? (
                                  <ItemGrid xs={12} md={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Budget Head
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          id={"selectedBudgetHeadItem"}
                                          onSelect={this.onSelect}
                                          defValue={
                                            this.state.selectedBudgetHeadItem
                                          }
                                          getName={this.getBudgetName}
                                          stateKey="selectedBudgetHeadItem"
                                          staticData={this.state.budgetHeadList}
                                          isRefresh={this.state.loadDepartment1}
                                          setIsRefresh={(val) => {
                                            this.setState({
                                              loadDepartment1: false,
                                            });
                                          }}
                                          value={
                                            this.state.selectedBudgetHeadItem
                                          }
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {!printingPop &&
                                this.state.SelectindentType != 3 ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Department
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          selectDisabled={
                                            (this.state.SelectindentType ===
                                              1 &&
                                              this.state.updateItem) ||
                                            this.state.disableDept
                                          }
                                          id={"selectedDepartment"}
                                          onSelect={this.onSelect}
                                          defValue={
                                            this.state.selectedDepartment
                                          }
                                          getName={this.getDeptName}
                                          stateKey="selectedDepartment"
                                          url={
                                            serverApi.SEARCH_DEPATMENTS +
                                            this.props.state.companyInfo.id +
                                            "/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.selectedDepartment}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Item Group
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          selectDisabled={
                                            (this.state.SelectindentType ===
                                              1 &&
                                              this.state.updateItem) ||
                                            this.state.disableDept
                                          }
                                          defValue={
                                            this.state.selectedItemGroup
                                          }
                                          id={"selectedItemGroup"}
                                          onSelect={this.onSelect}
                                          getName={this.getItemGroupName}
                                          isRefresh={this.state.loadItemGroup}
                                          stateKey="selectedItemGroup"
                                          setIsRefresh={
                                            this.afterRefreshItemGrp
                                          }
                                          url={
                                            serverApi.SEARCH_ITEM_GROUPS +
                                            this.props.state.companyInfo.id +
                                            "/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.selectedItemGroup}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {this.state.printingPop ? (
                                  <ItemGrid xs={6} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Item
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          id={"selectedItem"}
                                          defValue={this.state.selectedItem}
                                          staticData={this.state.ItemOption}
                                          onSelect={this.onSelect}
                                          getName={this.getItemDetailsByName}
                                          getItem={(item) => {
                                            this.setState({ itemIdNew: item });
                                          }}
                                          isRefresh={this.state.loadItem}
                                          setIsRefresh={this.afterRefreshItem}
                                          value={this.state.selectedItem}
                                          stateKey="selectedItem"
                                          //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        iType={"number"}
                                        decimal={3}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                    {this.state.minStock > 0 &&
                                    this.state.itemStock > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "100px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Quantity Range (" +
                                          this.state.minStock +
                                          " - " +
                                          this.state.itemStock +
                                          ")"}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Items
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          selectDisabled={
                                            this.state.SelectindentType === 1 &&
                                            this.state.updateItem
                                          }
                                          defValue={this.state.selectedItem}
                                          id={"selectedItem"}
                                          staticData={this.state.ItemOptions}
                                          onSelect={this.onSelect}
                                          getName={this.getItemDetailsByName}
                                          getItem={(item) => {
                                            this.setState({ itemIdNew: item });
                                          }}
                                          isRefresh={this.state.loadItem}
                                          setIsRefresh={this.afterRefreshItem}
                                          stateKey="selectedItem"
                                          // url={serverApi.GET_ITEMS_BY_GRPID + this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                          value={this.state.selectedItem}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {/* {!this.state.printingPop ? ( */}

                                {/* ) : null} */}
                                <ItemGrid xs={6} md={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedItem !== "" ? (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          marginLeft: "130px",
                                          color: "red",
                                          fontStyle: "italic",
                                          marginBottom: "5px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {this.state.latestPrice !== ""
                                          ? "Last purchase at  " +
                                            this.state.latestPrice +
                                            " on " +
                                            this.state.latestPriceDate
                                          : "Last purchase not found"}
                                      </span>
                                    ) : null}

                                    {/* {this.state.lastPurchaseRate!==""?"Last purchase at  " + this.state.lastPurchaseRate+" on "+this.state.lastPurchaseDate:"Last purchase not found"}</span> : null} */}
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={6} md={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        HSN/SAC Code
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="hsnCode"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        value={this.state.hsnCode}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {/* {this.state.printingPop ? (
																	<ItemGrid
																		xs={6}
																		md={6}
																		style={{ marginTop: "10px" }}
																	>
																		<GridContainer>
																			<ItemGrid xs={12} sm={4}>
																				<InputLabel className={classes.label}>
																					UOM
																				</InputLabel>
																			</ItemGrid>
																			<ItemGrid xs={12} sm={8}>
																				<TextField
																					id="uom"
																					className={classes.textField}
																					onChange={this.onChangeValues}
																					fullWidth={true}
																					variant="outlined"
																					InputProps={inputFocus}
																					value={this.state.uom}
																				/>
																			</ItemGrid>
																		</GridContainer>
																	</ItemGrid>
																) : null} */}
                                {this.state.SelectindentType == 3 &&
                                !printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Installation Rate
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="InsRate"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          value={this.state.InsRate}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {this.state.SelectindentType == 3 &&
                                !printingPop ? (
                                  <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Installation Amount
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={true}
                                          id="InsAmount"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          value={this.state.InsAmount}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!printingPop ? (
                                  <ItemGrid xs={6} md={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Discount Percentage
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="discountPercentage"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          iType={"number"}
                                          value={this.state.discountPercentage}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {!printingPop ? (
                                  <ItemGrid xs={6} md={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Discount Mode
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          defValue={this.state.discountMode}
                                          id={"discountMode"}
                                          staticData={discountOptions}
                                          onSelect={this.onSelect}
                                          stateKey="discountMode"
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                <ItemGrid xs={6} md={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.getAmount(
                                          this.state.quantity,
                                          this.state.rate,
                                          this.state.InsRate,
                                          this.state.discountMode,
                                          this.state.discountPercentage
                                        )}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={6} md={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Tax
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.taxId}
                                        id={"taxId"}
                                        onSelect={this.onSelect}
                                        stateKey="taxId"
                                        getName={this.gettaxpercent}
                                        isReload={this.state.loadTax}
                                        setIsReload={this.afterRefreshTax}
                                        url={
                                          serverApi.TAX_MASTER_LIST +
                                          "/" +
                                          this.props.state.companyInfo.id +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                        value={this.state.taxId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Remarks
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="remarks1"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.remarks1}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {/* {this.state.SelectindentType == 3 && */}
                                {/* !printingPop ? ( */}
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Make
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="make"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.make}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {/* ) : null} */}
                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} md={4}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={12}>
                                        <InputLabel
                                          className={classes.label}
                                          style={{
                                            fontSize: "12px",
                                            color: "red",
                                            fontStyle: "italic",
                                            marginBottom: "5px",
                                            marginTop: "-5px",
                                          }}
                                        >
                                          {labelType === "2"
                                            ? "City Stock"
                                            : "Central Stock"}{" "}
                                          <span>
                                            &nbsp;:&nbsp;{this.state.stock}
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid
                                        xs={12}
                                        sm={8}
                                        style={{ display: "none" }}
                                      >
                                        <TextField
                                          disabled={true}
                                          id="stock"
                                          className={classes.textField}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.stock}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} sm={4}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={12}>
                                        <InputLabel
                                          className={classes.label}
                                          style={{
                                            fontSize: "12px",
                                            color: "red",
                                            fontStyle: "italic",
                                            marginBottom: "5px",
                                            marginTop: "-5px",
                                          }}
                                        >
                                          {labelType === "2"
                                            ? "Project Stock"
                                            : " Branch Stock"}{" "}
                                          <span>
                                            &nbsp;:&nbsp;
                                            {this.state.branchStock}
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid
                                        xs={12}
                                        sm={8}
                                        style={{ display: "none" }}
                                      >
                                        <TextField
                                          id="branchStock"
                                          disabled={true}
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          value={this.state.branchStock}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!this.state.printingPop ? (
                                  <ItemGrid xs={12} md={4}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={12}>
                                        <InputLabel
                                          className={classes.label}
                                          style={{
                                            fontSize: "12px",
                                            color: "red",
                                            fontStyle: "italic",
                                            marginBottom: "5px",
                                            marginTop: "-5px",
                                          }}
                                        >
                                          Qty pending for Receipt
                                          <span>
                                            &nbsp;:&nbsp;
                                            {/* {this.state.branchStock} */}
                                            {this.state.pendingStockDesc}
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid
                                        xs={12}
                                        sm={8}
                                        style={{ display: "none" }}
                                      >
                                        <TextField
                                          disabled={true}
                                          id="pendingAtRcpt"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.pendingAtRcpt}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {!this.state.isSupplier ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              {this.state.alert}
                              {this.state.basicAlert}

                              {this.state.showMessagealert}
                              <InputLabel className={classes.label}>
                                {this.state.isWO ? "WO Source" : "PO Source"}
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                selectDisabled={this.state.updateView}
                                defValue={this.state.SelectindentType}
                                name={"SelectindentType"}
                                value={this.state.SelectindentType}
                                onSelect={(e) => {
                                  this.handleSelectedValues(e);
                                }}
                                statekey={"SelectindentType"}
                                //  isRefresh={this.state.loadSupplier}
                                //  setIsRefresh={(val)=>{
                                //      this.setState({loadSupplier:false})
                                //  }}
                                staticData={options}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  isValidDate={valid}
                                  // onChange={(dateObj) => {
                                  //     this.timeChanged(dateObj, "PoDate")
                                  // }}
                                  onChange={(dateObj) => {
                                    this.dateChanged(dateObj, "PoDate");
                                  }}
                                  height={"15px"}
                                  formControlProps={{
                                    height: "15px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "PoDate",
                                    value: this.state.PoDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) != "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  selectDisabled={
                                    this.state.updateView &&
                                    this.state.poStatus != "1"
                                  }
                                  id={"supplier_Id"}
                                  defValue={this.state.supplier_Id}
                                  onSelect={this.onSelect}
                                  getName={(val) => {
                                    var name = val.split("_");
                                    this.setState({
                                      supplierName: name[0],
                                      supplierAddress: name[1],
                                      supplierPhno: name[3],
                                      supplierGst: name[2],
                                      supplierCode: name[4],
                                    });
                                  }}
                                  stateKey="supplier_Id"
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  staticData={this.state.supplierList}
                                  //  url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        {getCookie(cons.USER_ROLE_ID) != "100" ? (
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier Branch
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.supplierBranchId}
                                  //  selectDisabled={this.state.updateView}
                                  onSelect={this.onSelect}
                                  getName={(name) => {
                                    var val = name.split("^");
                                    if (val[1] !== null)
                                      this.setState({
                                        supplierAddress: val[0],
                                        supplierStateCode: val[1],
                                      });
                                    // this.setTaxVlaue()
                                  }}
                                  stateKey="supplierBranchId"
                                  staticData={this.state.supplierBranchData}
                                  isRefresh={this.state.loadSupplierBranch}
                                  setIsRefresh={(val) => {
                                    this.setState({
                                      loadSupplierBranch: false,
                                    });
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Billing Address
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.billingBranchId}
                                //  selectDisabled={this.state.updateView}
                                onSelect={this.onSelect}
                                getName={(name) => {
                                  var val = name.split("^");
                                  if (val[1] !== null) {
                                    this.setState({
                                      billingBranchAddress:
                                        val[1] !== "null" ? val[1] : "",
                                      billingStateCode:
                                        val[2] !== "null" ? val[2] : "",
                                      billingStateName:
                                        val[3] !== "null" ? val[3] : "",
                                      shippingStateCode:
                                        val[2] !== "null" ? val[2] : "",
                                    });
                                    if (taxTypes.gst === 1) {
                                      if (
                                        this.state.supplierStateCode ==
                                          val[2] &&
                                        val[2] !== "null"
                                      ) {
                                        if (this.state.taxPayable !== 0) {
                                          this.handleSelectedUnitValue(
                                            ucoptions[2]
                                          );
                                          this.setState({ disableTax: true });
                                        }
                                      } else {
                                        if (!this.state.updateView) {
                                          this.setState({ disableTax: true });
                                          this.handleSelectedUnitValue(
                                            ucoptions[1]
                                          );
                                        }
                                      }
                                    } else {
                                      this.setState({ disableTax: false });
                                    }
                                  }
                                  // this.setTaxVlaue();
                                }}
                                stateKey="billingBranchId"
                                staticData={this.state.branchOptions}
                                isRefresh={this.state.loadBranch}
                                setIsRefresh={(val) => {
                                  this.setState({
                                    loadBranch: false,
                                  });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Billing State Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                disabled={true}
                                id="billingStateName"
                                value={this.state.billingStateName}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Billing State Code
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                disabled={true}
                                id="billingStateCode"
                                value={this.state.billingStateCode}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Shipping Address
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.shippingBranchId}
                                //  selectDisabled={this.state.updateView}
                                onSelect={this.onSelect}
                                getName={(name) => {
                                  var val = name.split("^");
                                  if (val[1] !== null)
                                    this.setState({
                                      delAddress:
                                        val[1] !== "null" ? val[1] : "",
                                      shippingStateCode:
                                        val[2] !== "null" ? val[2] : "",
                                      shippingStateName:
                                        val[2] !== "null" ? val[3] : "",
                                    });
                                }}
                                stateKey="shippingBranchId"
                                staticData={this.state.branchOptions}
                                isRefresh={this.state.loadBranch}
                                setIsRefresh={(val) => {
                                  this.setState({
                                    loadBranch: false,
                                  });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Shipping State Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                disabled={true}
                                id="shippingStateName"
                                value={this.state.shippingStateName}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Shipping State Code
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                diabled={true}
                                id="shippingStateCode"
                                // value={this.state.billingStateCode}
                                value={this.state.shippingStateCode}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Tax Payable
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              {/* {this.state.taxPayable} */}
                              <SelectboxTemp
                                // selectDisabled={this.state.SelectindentType===1||(this.state.updateView&&this.state.poStatus !== "1")}

                                defValue={
                                  this.state.taxPayable === ""
                                    ? 1
                                    : this.state.taxPayable
                                }
                                value={this.state.taxPayable}
                                isReload={this.state.loadCategory}
                                setIsReload={() => {
                                  this.setState({ loadCategory: false });
                                }}
                                onSelect={this.onSelect}
                                getName={(value) => {
                                  this.setState({ typeId: value });
                                  if (value == "No") {
                                    // this.setState({ taxType: "" });
                                    this.setState({ taxType: "" });
                                  }
                                }}
                                stateKey="taxPayable"
                                //       url={serverApi.GET_EXPENSE_TYPE}
                                // value={this.state.taxPaybleOptions}
                                staticData={taxPaybleOptions}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Tax Type
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <Select
                                isDisabled={
                                  this.state.updateView &&
                                  this.state.poStatus != "1" //||
                                  // this.state.disableTax
                                }
                                defValue={this.state.taxType}
                                name={"taxType"}
                                value={this.state.taxType}
                                onChange={this.handleSelectedUnitValue}
                                options={ucoptions}
                                className={classes.selectFormControl}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary: "#00ABDC",
                                  },
                                })}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    height: "30px",
                                    "min-height": "30px",
                                  }),
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        {!this.state.isWO ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Credit Term
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="creditTerm"
                                  value={this.state.creditTerm}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={{
                                    autoComplete: "off",
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span
                                          style={{
                                            color: "#00ABDC",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Days
                                        </span>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Delivery Timeline
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="deliveryTimeLine"
                                className={classes.textField}
                                value={this.state.deliveryTimeLine}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                InputProps={{
                                  autoComplete: "off",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{
                                          color: "#00ABDC",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        Days
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Expected Date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  isValidDate={valid}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "expecDate");
                                  }}
                                  height={"15px"}
                                  formControlProps={{
                                    height: "15px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "expecDate",
                                    value: this.state.expecDate,
                                    autoComplete: "off",
                                    disabled: true,
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        {!this.state.isWO ? (
                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  {this.state.isWO ? "WO " : "PO "} Till Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    isValidDate={valid1}
                                    onChange={(dateObj) => {
                                      this.dateChanged(dateObj, "poValidDate");
                                    }}
                                    height={"15px"}
                                    formControlProps={{
                                      height: "15px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "poValidDate",
                                      value: this.state.poValidDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Category Type
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={this.state.SelectindentType===1||(this.state.updateView&&this.state.poStatus !== "1")}
                                id={"form_purchaseType"}
                                defValue={this.state.form_purchaseType}
                                isReload={this.state.loadCategory}
                                setIsReload={() => {
                                  this.setState({ loadCategory: false });
                                }}
                                onSelect={this.onSelect}
                                getName={(value) => {
                                  this.setState({ typeId: value });
                                }}
                                stateKey="form_purchaseType"
                                url={serverApi.GET_EXPENSE_TYPE}
                                value={this.state.form_purchaseType}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Project
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                //    selectDisabled={this.state.SelectindentType.value===1||this.state.updateView}
                                // url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+this.state.branchId}
                                defValue={this.state.projectId}
                                staticData={this.state.projectNameList}
                                onSelect={this.onSelect}
                                stateKey="projectId"
                                value={this.state.projectId}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Client
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.customerId}
                                id={"customerId"}
                                onSelect={this.onSelect}
                                stateKey="customerId"
                                staticData={this.state.customerList}
                                isRefresh={this.state.loadCustomer}
                                setIsRefresh={() => {
                                  this.setState({ loadCustomer: false });
                                }}
                                getName={(name) => {
                                  this.setState({ customerName: name });
                                }}
                                value={this.state.customerId}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {currencyoptions.length > 1 ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Currency
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                {/* <SelectboxTemp
                                // selectDisabled={this.state.SelectindentType===1||(this.state.updateView&&this.state.poStatus !== "1")}
                                id={"form_purchaseType"}
                                defValue={this.state.form_purchaseType}
                                isReload={this.state.loadCategory}
                                setIsReload={() => {
                                  this.setState({ loadCategory: false });
                                }}
                                onSelect={this.onSelect}
                                getName={(value) => {
                                  this.setState({ typeId: value });
                                }}
                                stateKey="form_purchaseType"
                                url={serverApi.GET_EXPENSE_TYPE}
                                value={this.state.form_purchaseType}
                              /> */}
                                {console.log(
                                  "getting the currency type in render",
                                  this.state.currencyType
                                )}
                                <SelectboxTemp
                                  // selectDisabled={this.state.SelectindentType===1||(this.state.updateView&&this.state.poStatus !== "1")}

                                  defValue={this.state.currencyType}
                                  value={this.state.currencyType}
                                  isReload={this.state.loadCategory}
                                  setIsReload={() => {
                                    this.setState({ loadCategory: false });
                                  }}
                                  onSelect={this.onSelect}
                                  // getName={(value) => {
                                  //   this.setState({ typeId: value });
                                  //   if (value == "USD") {
                                  //     this.setState({ taxType: "" });
                                  //   }
                                  // }}
                                  stateKey="currencyType"
                                  //       url={serverApi.GET_EXPENSE_TYPE}
                                  // value={this.state.taxPaybleOptions}
                                  staticData={currencyoptions}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : (
                          ""
                        )}
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                RQN Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="rqName"
                                value={this.state.rqName}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                //  style={{height: 30}}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Contact Person
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="contactPerson"
                                value={this.state.contactPerson}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                //  style={{height: 30}}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Contact No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="contactNo"
                                value={this.state.contactNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                iType="number"
                                //  style={{height: 30}}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {/* {this.state.phaseNameList != undefined ?
                                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Phase
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.phaseId}
                                                        id={"phaseId"}
                                                        onSelect={this.onSelect}
                                                        stateKey="phaseId"
                                                        staticData={this.state.phaseNameList}
                                                        getName={(name) => {
                                                            this.setState({ id: name })
                                                        }}
                                                        isRefresh={this.state.loadPhaseName}
                                                        setIsRefresh={(val) => {
                                                            this.setState({ loadPhaseName: false })
                                                        }}
                                                        value={this.state.phaseId}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>
                                        : null} */}

                        {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Project":"Delivery Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.branchId}
                                                    selectDisabled={this.state.updateView||this.state.SelectindentType.value===1}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        var val=name.split("^");
                                                        if(val[1]!==null)
                                                            this.setState({delAddress:val[1]})
                                                    }}
                                                    stateKey="branchId"
                                                    staticData={this.state.branchOptions}
                                                    isRefresh={this.state.loadBranch}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBranch:false})
                                                    }}
                                                    // url={serverApi.SELECT_BRANCH + this.props.state.companyInfo.id}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
                        {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Billing Project":"Billing Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.billingBranchId}
                                                  //  selectDisabled={this.state.updateView}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        var val=name.split("^");
                                                        if(val[1]!==null)
                                                            this.setState({delAddress:val[1]})
                                                    }}
                                                    stateKey="billingBranchId"
                                                    staticData={this.state.branchOptions}
                                                    isRefresh={this.state.loadBranch}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBranch:false})
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}

                        {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Delivery Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={this.state.branchId!==""&&this.state.branchId!==null}
                                                    id="delAddress"
                                                    fullWidth
                                                    value={this.state.delAddress}
                                                    onChange={this.onChangeValues}
                                                    className={classes.textField}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                        {/* <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
										<GridContainer>
											<ItemGrid xs={12} sm={4}>
												<InputLabel className={classes.label}>
													Freight Charges
												</InputLabel>
											</ItemGrid>
											<ItemGrid xs={12} sm={8}>
												<TextField
													id="frieghtCharge"
													fullWidth
													iType={"number"}
													value={this.state.frieghtCharge}
													onChange={this.onChangeValues}
													className={classes.textField}
													variant="outlined"
												/>
											</ItemGrid>
										</GridContainer>
									</ItemGrid> */}

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Footer Note
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="footerNote"
                                fullWidth
                                multiline
                                rowsMax="3"
                                height={60}
                                value={this.state.footerNote}
                                onChange={this.onChangeValues}
                                className={classes.textField}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Internal Note
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="internalNote"
                                fullWidth
                                multiline
                                rowsMax="3"
                                height={60}
                                value={this.state.internalNote}
                                onChange={this.onChangeValues}
                                className={classes.textField}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        // style={{height: 30}}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}
                        {/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        //  style={{height: 30}}
                                                        InputProps={inputFocus}
ā
                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}

                        <br />

                        <br />

                        <br />

                        <ItemGrid xs={12} sm={12}>
                          <GridContainer justify="center">
                            <ItemGrid xs={12}>
                              <LineItemTable
                                data={this.getDataTable()}
                                loading={this.state.loading}
                                setHoveredRow={this.setHoveredRow}
                                hideOverFlow={true}
                                fixRowHeight={true}
                                disableActions={
                                  this.state.poStatus == "3" ||
                                  this.state.poStatus == "4" ||
                                  this.state.poStatus == "5" ||
                                  this.state.poStatus == "6"
                                }
                                columns={
                                  taxType.value === 3
                                    ? [
                                        {
                                          Header: "Item Code",
                                          accessor: "itemGrp",
                                          minWidth: 70,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Item Desc",
                                          accessor: "itemDesc",
                                          minWidth: 300,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                          },

                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "HSN/SAC Code",
                                          accessor: "hsnCode",
                                          width: 150,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                            overflow: "unset",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },

                                        {
                                          Header: "Quantity",
                                          accessor: "quantity",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Rate",
                                          accessor: "rate",
                                          minWidth: 40,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "VAT (%)",
                                          accessor: "isgst",
                                          minWidth: 40,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Discount Mode",
                                          accessor: "discountMode",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Discount %",
                                          accessor: "discountPercentage",
                                          minWidth: 50,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },

                                        {
                                          Header: "Amount",
                                          accessor: "amount",
                                          minWidth: 40,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Actions",
                                          accessor: "actions",
                                          minWidth: 80,
                                          style: {
                                            color: "#000",
                                            textAlign: "center",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]
                                    : [
                                        {
                                          Header: "Item Code",
                                          accessor: "itemGrp",
                                          minWidth: 70,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Item Desc",
                                          accessor: "itemData",
                                          minWidth: 200,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                          },

                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "HSN/SAC Code",
                                          accessor: "hsnCode",
                                          width: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "left",
                                            overflow: "unset",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },

                                        {
                                          Header: "Quantity",
                                          accessor: "quantity",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        // {
                                        //     Header: "Pending For Receipt",
                                        //     accessor: "pendingAtRcpt",
                                        //     minWidth: 100,
                                        //     style: { color: "#000", textAlign: "right" },
                                        //     getProps: (state, rowInfo, column) => {
                                        //         return {
                                        //             style: {
                                        //                 background: rowInfo && parseFloat(rowInfo.row._original.rate) > rowInfo.row._original.lastPurchaseRate && rowInfo.row._original.lastPurchaseRate > 0 ? dangerColor : null,
                                        //                 color: rowInfo && parseFloat(rowInfo.row._original.rate) > rowInfo.row._original.lastPurchaseRate && rowInfo.row._original.lastPurchaseRate > 0 ? '#FFF' : "#000",
                                        //             },
                                        //         };
                                        //     }
                                        // },
                                        {
                                          Header: "Supply Rate/ Ins Rate",
                                          accessor: "rateIns",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Unit",
                                          accessor: "unit",
                                          minWidth: 70,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "IGST (%)",
                                          accessor: "isgst",
                                          minWidth: 50,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "SGST (%)",
                                          accessor: "ssgst",
                                          minWidth: 50,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "CGST (%)",
                                          accessor: "csgst",
                                          minWidth: 50,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },

                                        {
                                          Header: "Discount",
                                          accessor: "discountPercentageSim",
                                          minWidth: 70,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },

                                        {
                                          Header: "Amount/ Inst. Amount",
                                          accessor: "amountIns",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "right",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                        },
                                        {
                                          Header: "Actions",
                                          accessor: "actions",
                                          minWidth: 100,
                                          style: {
                                            color: "#000",
                                            textAlign: "center",
                                          },
                                          getProps: (
                                            state,
                                            rowInfo,
                                            column
                                          ) => {
                                            return {
                                              style: {
                                                background:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? dangerColor
                                                    : null,
                                                color:
                                                  rowInfo &&
                                                  parseFloat(
                                                    rowInfo.row._original.rate
                                                  ) >
                                                    rowInfo.row._original
                                                      .lastPurchaseRate &&
                                                  rowInfo.row._original
                                                    .lastPurchaseRate > 0
                                                    ? "#FFF"
                                                    : "#000",
                                              },
                                            };
                                          },
                                          sortable: false,
                                          filterable: false,
                                        },
                                      ]
                                }
                              />
                            </ItemGrid>
                            {this.state.poStatus != "3" &&
                            this.state.poStatus != "4" &&
                            this.state.poStatus != "5" &&
                            this.state.poStatus != "6" ? (
                              <ItemGrid
                                xs={12}
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  this.setState({
                                    selectedItemGroup: "997",
                                    printingPop: true,
                                    alert: true,
                                  });
                                }}
                              >
                                Click Here To Add Printing Charges/Labour
                                Charges/Transport Charges/Delivery
                                Charges/Packing & Forwarding Charges/Other
                                Charges
                              </ItemGrid>
                            ) : null}

                            {this.state.updateView ? (
                              <ItemGrid xs={12}>
                                <GridContainer>
                                  <ItemGrid xs={6}>
                                    <span
                                      style={{
                                        fontStyle: "italic",
                                        fontWeight: 400,
                                        color: "#003F66",
                                      }}
                                    >
                                      Status:
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "#003F66",
                                      }}
                                    >
                                      {this.state.statusDesc}
                                      <br />
                                    </span>
                                  </ItemGrid>
                                  <ItemGrid xs={6}>
                                    {this.state.poStatus != "3" &&
                                    this.state.poStatus != "4" &&
                                    this.state.poStatus != "5" &&
                                    this.state.poStatus != "6" ? (
                                      <Button
                                        color={"info"}
                                        right={true}
                                        onClick={this.handleAddItem}
                                      >
                                        Add Item
                                      </Button>
                                    ) : null}
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : (
                              <ItemGrid xs={12}>
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              </ItemGrid>
                            )}
                            <ItemGrid
                              xs={12}
                              style={{
                                height: 1,
                                backgroundColor: "#00acc1",
                                marginTop: "15px",
                              }}
                            />
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={9}
                              style={{ textAlign: "right" }}
                            >
                              <InputLabel className={classes.label}>
                                {taxType.value === 3
                                  ? "Total VAT :"
                                  : "Total IGST :"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={2}
                              style={{ textAlign: "right" }}
                            >
                              {this.getTotalTax("igst")}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {taxType.value !== 3 ? (
                          <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                            <GridContainer>
                              <ItemGrid
                                xs={12}
                                sm={9}
                                style={{ textAlign: "right" }}
                              >
                                <InputLabel className={classes.label}>
                                  Total SGST :
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid
                                xs={12}
                                sm={2}
                                style={{ textAlign: "right" }}
                              >
                                {this.getTotalTax("sgst")}
                                {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalSgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("sgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        {taxType.value !== 3 ? (
                          <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                            <GridContainer>
                              <ItemGrid
                                xs={12}
                                sm={9}
                                style={{ textAlign: "right" }}
                              >
                                <InputLabel className={classes.label}>
                                  Total CGST :
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid
                                xs={12}
                                sm={2}
                                style={{ textAlign: "right" }}
                              >
                                {this.getTotalTax("cgst")}
                                {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalCgst"
                                                    className={classes.textField}
                                                    value={this.getTotalTax("cgst")}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={9}
                              style={{ textAlign: "right" }}
                            >
                              <InputLabel className={classes.label}>
                                Total Amount :
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={2}
                              style={{ textAlign: "right" }}
                            >
                              {this.getTotalTax("totalAmount")}
                              {/*
                                                <TextField
                                                    disabled={true}
                                                    id="totalAmount"
                                                    className={classes.textField}
                                                    value={this.state.totalAmount}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
*/}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={9}
                              style={{ textAlign: "right" }}
                            >
                              <InputLabel className={classes.label}>
                                Net Total:
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={2}
                              style={{ textAlign: "right" }}
                            >
                              {Math.round(
                                parseFloat(this.getTotalTax("netTotal"))
                              ).toFixed(2)}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Advance Percentage
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="advancePercentage"
                                    value={this.state.advancePercentage}
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    iType="number"
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Advance Amount
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="advanceAmount"
                                    value={this.state.advanceAmount}
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    iType="number"
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    {/* {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                                    Select Terms And Conditions
                                  </InputLabel>
                                </ItemGrid>
                                {this.state.isWO ? (
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      // selectDisabled={this.state.isDisabled}
                                      defValue={this.state.terms}
                                      onSelect={this.onSelect}
                                      stateKey="terms"
                                      isRefresh={this.state.loadTerms}
                                      setIsRefresh={(val) => {
                                        this.setState({ loadTerms: false });
                                      }}
                                      getName={(name) => {
                                        //if (this.state.terms)
                                        this.setState({ remarks: name });
                                      }}
                                      staticData={this.state.WotermsList}
                                    />
                                  </ItemGrid>
                                ) : (
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      // selectDisabled={this.state.isDisabled}
                                      defValue={this.state.terms}
                                      onSelect={this.onSelect}
                                      stateKey="terms"
                                      isRefresh={this.state.loadTerms}
                                      setIsRefresh={(val) => {
                                        this.setState({ loadTerms: false });
                                      }}
                                      getName={(name) => {
                                        //if (this.state.terms)
                                        this.setState({ remarks: name });
                                      }}
                                      staticData={this.state.termsList}
                                    />
                                  </ItemGrid>
                                )}
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={12}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={2}>
                                  <InputLabel className={classes.label}>
                                    Terms & Conditions
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={10}>
                                  <TextField
                                    id="remarks"
                                    fullWidth
                                    multiline
                                    rowsMax="8"
                                    height={160}
                                    value={this.state.remarks}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && !this.state.isSupplier ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer
                          style={{ marginTop: "10px", cursor: "pointer" }}
                        >
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          {!isSupplier ? (
                            <ItemGrid xs={4}>
                              <IconButton
                                right={true}
                                onClick={() => {
                                  this.setState({
                                    deleteInvoice: true,
                                    invoiceId: r.fileUploadId,
                                    fileId: i,
                                  });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Delete This File?"
                                  );
                                }}
                                color="dangerNoBackground"
                              >
                                {this.state.poStatus === 5 ||
                                this.state.poStatus === 3 ? null : (
                                  <Close />
                                )}
                              </IconButton>{" "}
                              {""}
                            </ItemGrid>
                          ) : null}
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && !this.state.isSupplier ? (
                    <ItemGrid xs={4}>
                      {this.state.poStatus === 3 ||
                      this.state.poStatus === 5 ? null : (
                        <Button color={"info"} onClick={this.handleFileClick}>
                          Upload File
                        </Button>
                      )}
                    </ItemGrid>
                  ) : null}
                  {/* {this.state.finalApprover &&
                  this.state.poStatus == "3" &&
                  !this.state.isSupplier ? (
                    <ItemGrid xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            //value={this.state.addPendQty?true:false}
                            checked={this.state.addPendQty ? true : false}
                            onChange={() => {
                              {
                                this.state.addPendQty
                                  ? this.setState({
                                      addPendQty: false,
                                    })
                                  : this.setState({
                                      addPendQty: true,
                                    });
                              }
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                            }}
                          />
                        }
                      />
                      <InputLabel
                        className={classes.label}
                        style={{ color: "Red" }}
                      >
                        Add pending qty back to indent
                      </InputLabel>
                    </ItemGrid>
                  ) : null} */}

                  {!this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <br />
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                          <br />
                        </ItemGrid>
                        <ItemGrid>
                          <br />
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({
                                isApprove: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve This PO..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isReject: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject This PO..!!"
                              );
                              // this.onApproveButtonClick("4")
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.poStatus != "3" &&
                        this.state.poStatus != "6" &&
                        this.state.approveButton) ||
                      (this.state.updateView && this.state.poStatus == "1") ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onApproveButtonClick("");
                            }}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.poStatus == "3" &&
                      getCookie(cons.USER_ROLE_ID) != "100" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isCancel: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Cancel This PO..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.poStatus == "3" &&
                      getCookie(cons.USER_ROLE_ID) != "100" ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isClose: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Close This PO..!!"
                              );
                              //this.onApproveButtonClick("6")
                            }}
                          >
                            Close
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView && !this.state.isSupplier ? (
                        <LogsComponents id={this.state.poNo} recType={"PO"} />
                      ) : null}

                      {this.state.updateView &&
                      poPrintBeforeApproval &&
                      this.state.poStatus != "6" &&
                      getCookie(cons.USER_ROLE_ID) != "100" ? (
                        <ItemGrid xs={12}>
                          {/* <SamplePDF
                            invoiceType={this.state.isWO ? 22 : 1}
                            printResp={this.state.printResp}
                            sendMail={true}
                            handlePOMail={this.handlePOMail}
                            isMail={this.state.isMail}
                            handleMail={this.handleMail}
                          /> */}
                          {/* <div onClick={this.handlePrintData}> */}
                          <div className="exportPdf">
                            <IconButton
                              color="primary"
                              onClick={this.handlePrintData}
                            >
                              {<Print />}
                            </IconButton>
                          </div>
                          {/*
                                                        <IconButton color={"primary"} right={true} onClick={() => {
                                                        this.setState({
                                                            sendMail: true
                                                        });
                                                        this.printDocument();
                                                    }
                                                    }>
                                                        <Email/>
                                                    </IconButton>
*/}
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView &&
                      poPrintBeforeApproval &&
                      this.state.poStatus != "6" &&
                      !this.state.isSupplier ? (
                        <ItemGrid xs={11}>
                          {/* <SamplePDF
                            invoiceType={this.state.isWO ? 22 : 1}
                            printResp={this.state.printResp}
                            isSupplierPrint={this.state.isSupplierPrint}
                            handleSupppPrint={() => {
                              this.setState({ isSupplierPrint: false });
                            }}
                          /> */}

                          {/* <div className="exportPdf">
                            <IconButton
                              color={"red"}
                              onClick={this.handlePrintData}
                            >
                              {<Print />}
                            </IconButton>
                          </div> */}
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                // removed search field from list table
                <div className="wo-po-Table">
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Supplier
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxComponent
                                    defValue={this.state.supplierId}
                                    id={"supplierId"}
                                    onSelect={this.onSelect}
                                    stateKey="supplierId"
                                    // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                    isRefresh={this.state.loadSupplier}
                                    setIsRefresh={(val) => {
                                      this.setState({ loadSupplier: false });
                                    }}
                                    staticData={this.state.supplierList}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Client
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxComponent
                                    defValue={this.state.customerId}
                                    id={"customerId"}
                                    onSelect={this.onSelect}
                                    stateKey="customerId"
                                    // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                    isRefresh={this.state.loadSupplier}
                                    value={this.state.customerId}
                                    setIsRefresh={(val) => {
                                      this.setState({ loadCustomer: false });
                                    }}
                                    getName={(name) => {
                                      this.setState({ customerName: name });
                                    }}
                                    staticData={this.state.customerList}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </>
                        ) : null}

                        {!this.state.isSupplier ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  {this.state.isWO ? "WO " : "PO "}No
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="searchpoNo"
                                  value={this.state.searchpoNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Status
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxComponent
                                  id={"status"}
                                  defValue={this.state.status}
                                  onSelect={this.onSelect}
                                  stateKey="status"
                                  url={serverApi.SEARCH_STORE_STATUS}
                                  value={this.state.status}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        {!this.state.isSupplier ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Category Type
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  id={"form_purchaseType"}
                                  onSelect={this.onSelect}
                                  stateKey="form_purchaseType"
                                  url={serverApi.GET_EXPENSE_TYPE}
                                  value={this.state.purchaseType}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}

                        {this.state.projectNameList !== undefined &&
                        !this.state.isSupplier ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  {/* {labelType==="2"?"Phase":"Project"} */}
                                  Project
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  // url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+this.state.branchId}
                                  defValue={this.state.projectId1}
                                  staticData={this.state.projectNameList}
                                  onSelect={this.onSelect}
                                  stateKey="projectId1"
                                  value={this.state.projectId1}
                                  isRefresh={this.state.loadProject}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadProject: false });
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        {/* {this.state.phaseNameList !== undefined ?
                                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Phase
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.phaseId1}
                                                                    id={"phaseId1"}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="phaseId1"
                                                                    staticData={this.state.phaseNameList}
                                                                    getName={(name) => {
                                                                        this.setState({ id: name })
                                                                    }}
                                                                    isRefresh={this.state.loadPhaseName}
                                                                    setIsRefresh={(val) => {
                                                                        this.setState({ loadPhaseName: false })
                                                                    }}
                                                                    value={this.state.phaseId}
                                                                />
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>
                                                    : null} */}
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid xs={12}>
                            <IconButton
                              onClick={this.handleCreate}
                              color="success"
                              right={true}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </ItemGrid>
                        ) : null}
                        {!this.state.isSupplier ? (
                          <ItemGrid xs={12}>
                            <br />
                            <br />
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {this.state.basicAlert}
                      {this.state.dataTable ? (
                        <PaginationTableComponent
                          staticData={this.state.dataTable}
                          // dataTableAll={this.state.dataTable}
                          // url={serverApi.GET_ALL_PO}
                          searchData={this.getData()}
                          showPagination={true}
                          handlePage={this.handlePage.bind(this)}
                          isRefresh={this.state.isRefresh}
                          handleSearch={this.handleSeacrh.bind(this)}
                          isLoading={this.state.isLoading}
                          handleLoading={this.handleTableLoading.bind(this)}
                          onActionClick={this.handleViewClick}
                          itemViewType={this.state.itemViewType}
                          actionType={this.state.isSupplier ? ATTACHMENT : VIEW}
                          onDownloadClick={this.handleDownloadPrint}
                          // isDownload={this.state.isSupplier}
                          isDownload={true}
                        />
                      ) : // <TableComponent
                      //   // url={serverApi.ITEM_MASTER_TABLE}
                      //   // searchData={this.getData()}
                      //   // isRefresh={this.state.isRefresh}
                      //   // handleSearch={this.handleSeacrh.bind(this)}
                      //   // isLoading={this.state.isLoading}
                      //   // handleLoading={this.handleLoading.bind(this)}
                      //   // onActionClick={this.handleViewClick}
                      //   // itemViewType={this.state.itemViewType}
                      //   // actionType={VIEW}
                      //   // showDelete={true}
                      //   // // onDeleteClick={this.handleDeleteClick}
                      //   // // fileName={"ITEM_MASTER"}
                      //   // showPagination
                      //   // handlePage={this.handlePage.bind(this)}
                      //   staticData={this.state.dataTable}
                      //   showPagination={true}
                      //   handlePage={this.handlePage}
                      //   isRefresh={this.state.isRefresh}
                      //   handleSearch={this.handleSeacrh.bind(this)}
                      //   isLoading={this.state.isLoading}
                      //   handleLoading={this.handleTableLoading.bind(this)}
                      //   onActionClick={this.handleViewClick}
                      //   itemViewType={this.state.itemViewType}
                      //   actionType={this.state.isSupplier ? ATTACHMENT : VIEW}
                      //   onDownloadClick={this.handleDownloadPrint}
                      //   // isDownload={this.state.isSupplier}
                      //   isDownload={true}
                      // />
                      null}
                      {this.state.isSupplierPrint &&
                      this.state.poStatus != "6" ? (
                        <ItemGrid xs={11}>
                          {/* <SamplePDF
                            invoiceType={this.state.isWO ? 22 : 1}
                            printResp={this.state.printResp}
                            isSupplierPrint={this.state.isSupplierPrint}
                            handleSupppPrint={() => {
                              this.setState({ isSupplierPrint: false });
                            }}
                          /> */}

                          <div className="exportPdf">
                            <IconButton
                              color="primary"
                              onClick={this.handlePrintData}
                            >
                              {<Print />}
                            </IconButton>
                          </div>
                        </ItemGrid>
                      ) : null}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        poNum: this.state.poNo,
        userId: getCookie(cons.USERID),
        cipher: getCookie(cons.CIPHER),
      };
      requestList(serverApi.GET_PO_DETAILS, data, this.handlePrintResp);
      //   getDataFromUrl(serverApi.GET_PO_DETAILS + this.state.hdrId + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handlePrintResp)

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }

  handlePrintResp = (resp) => {
    this.setState({ printResp: resp, isMail: true });
  };

  handlePrintResp1 = (resp) => {
    var printHeaderValues = resp.poHeader;
    var modules = [];
    var lineItems = resp.poLineItemVo;
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var obj = {
          srlNo: key + 1,
          itemId: prop.itemId,
          juteType: prop.itemDesc,
          unit: prop.unitId,
          make: " ",
          indentNo: prop.id,
          bales_drums: prop.quantity,
          rate: prop.rate,
          disc: " ",
          vat: " ",
          excise: " ",
          Schrg: " ",
          delivery: "10 days",
        };
        modules.push(obj);
      });
    }
    var printTotalvalues = resp.totalValues;
    // this.setState({
    //     printHeaderValues:header,
    //     printLineItems:printLineItems,
    //     printTotalvalues:totalvalues,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.poDate;
    mr_date = mr_date + " ";
    var poId = printHeaderValues.id;
    poId = poId + " ";

    var brokerName = printHeaderValues.brokerName;
    brokerName = brokerName + " ";
    var frightPaid = printHeaderValues.frightPaid;
    frightPaid = frightPaid + " ";

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt == 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt == 3) {
      printcopy = "Triplicate";
    } else if (printCounnt == 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth("PURCHASE ORDER") * doc.internal.getFontSize()) /
        2;
    doc.text("PURCHASE ORDER", xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.text(20, 70, "PURCHASE ORDER NO :" + poId);
    doc.setFontType("normal");
    doc.setFontType("bold");
    doc.text(400, 70, "PO DATE : ");
    doc.setFontType("normal");
    doc.text(470, 70, mr_date);
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("bold");

    var companyName = doc.splitTextToSize(
      "NELLIMARLA JUTE MILLS CO. LTD    ",
      200
    );
    var address1 = doc.splitTextToSize(
      "P.O  NELLIMARLA Dist: VIZAYANAGARAM, ANDRAPRADESH",
      250
    );
    var Pno = doc.splitTextToSize("PHONE NO : 73866556665", 250);
    var vatno = doc.splitTextToSize("VAT NO : 566844556", 250);
    var party = doc.splitTextToSize("Party Name", 200);

    doc.cell(20, 90, 250, 80, companyName, "", "C");

    doc.setFontSize(8);
    doc.setFontType("normal");

    doc.text(25, 120, address1);
    doc.text(25, 140, Pno);
    doc.text(25, 160, vatno);
    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.cell(300, 90, 1, 1, " ", "", "right");
    doc.cellInitialize();

    doc.cell(320, 90, 250, 80, party, "", "C");

    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.text(330, 120, "BALAJI TRADING CO");
    doc.text(470, 120, "B076");
    doc.text(330, 140, vatno);

    doc.cellInitialize();
    var greeting = doc.splitTextToSize("Dear Sir/Madam,", 200);

    doc.cell(20, 180, 550, 100, greeting, "", "C");

    doc.cellInitialize();
    var srlno = doc.splitTextToSize("Srl No", 30);
    doc.cell(20, 290, 30, 40, srlno, "", "C");

    var item_code = doc.splitTextToSize("Item Code", 50);
    doc.cell(40, 290, 50, 40, item_code, "", "C");
    var desc = doc.splitTextToSize("Description", 50);
    doc.cell(80, 290, 70, 40, desc, "", "C");
    var unit = doc.splitTextToSize("Unit", 40);
    doc.cell(100, 290, 30, 40, unit, "", "C");
    var make = doc.splitTextToSize("Make", 50);
    doc.cell(130, 290, 60, 40, make, "", "C");
    var indentno = doc.splitTextToSize("Indent No/Srl No", 40);
    doc.cell(160, 290, 50, 40, indentno, "", "C");
    var quantity = doc.splitTextToSize("Quantity", 40);
    doc.cell(200, 290, 50, 40, quantity, "", "C");
    var moisture = doc.splitTextToSize("Rate", 40);
    doc.cell(230, 290, 50, 40, moisture, "", "C");
    var rate = doc.splitTextToSize("Disc", 40);
    doc.cell(260, 290, 30, 40, rate, "", "C");
    var stock = doc.splitTextToSize("VAT/CST", 20);
    doc.cell(290, 290, 30, 40, stock, "", "C");
    var schrg = doc.splitTextToSize("S Chrg", 30);
    doc.cell(320, 290, 30, 40, schrg, "", "C");
    var excise = doc.splitTextToSize("Excise", 30);
    doc.cell(320, 290, 30, 40, excise, "", "C");
    var delivery = doc.splitTextToSize("Delivery Schedule", 35);
    doc.cell(320, 290, 40, 40, delivery, "", "C");

    var cellWidths = ["", 30, 50, 70, 30, 60, 50, 50, 50, 30, 30, 30, 30, 40];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "left",
      "right",
      "left",
      "right",
      "right",
      "right",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 305;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 300;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var srlno = doc.splitTextToSize("Srl No", 30);
          doc.cell(20, 290, 30, 40, srlno, "", "C");

          var item_code = doc.splitTextToSize("Item Code", 50);
          doc.cell(40, 290, 50, 40, item_code, "", "C");
          var desc = doc.splitTextToSize("Description", 50);
          doc.cell(80, 290, 70, 40, desc, "", "C");
          var unit = doc.splitTextToSize("Unit", 40);
          doc.cell(100, 290, 30, 40, unit, "", "C");
          var make = doc.splitTextToSize("Make", 50);
          doc.cell(130, 290, 60, 40, make, "", "C");
          var indentno = doc.splitTextToSize("Indent No/Srl No", 40);
          doc.cell(160, 290, 50, 40, indentno, "", "C");
          var quantity = doc.splitTextToSize("Quantity", 40);
          doc.cell(200, 290, 50, 40, quantity, "", "C");
          var moisture = doc.splitTextToSize("Rate", 40);
          doc.cell(230, 290, 50, 40, moisture, "", "C");
          var rate = doc.splitTextToSize("Disc", 40);
          doc.cell(260, 290, 30, 40, rate, "", "C");
          var stock = doc.splitTextToSize("VAT/CST", 20);
          doc.cell(290, 290, 30, 40, stock, "", "C");
          var schrg = doc.splitTextToSize("S Chrg", 30);
          doc.cell(320, 290, 30, 40, schrg, "", "C");
          var excise = doc.splitTextToSize("Excise", 30);
          doc.cell(320, 290, 30, 40, excise, "", "C");
          var delivery = doc.splitTextToSize("Delivery Schedule", 35);
          doc.cell(320, 290, 40, 40, delivery, "", "C");
          /*  doc.cell(450, 310, 100, 20, '                 CLAIM FOR        ', '', 'C');
                      doc.cell(460, 310, 50, 20, ' ', '', 'C');*/
          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          /*   doc.cellInitialize();
                       doc.cell(372, 330, 50, 20, 'QUALITY', '', 'C');
                       doc.cell(500, 330, 50, 20, 'CONDITION', '', 'C');
                       doc.cell(550, 330, 50, 20, 'INITIAL', '', 'C');

                       doc.setFontType("normal");*/
          // doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          if (prop[key] == 0 || prop[key] == 0.0) {
            var myItem = " ";
          } else {
            if (key === "itemCode") {
              var size = 90;
            } else {
              var size = 45;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
          }
          return doc.cell(
            20,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    doc.cellInitialize();
    doc.setFontType("bold");
    var jobno = doc.splitTextToSize("Job No :    ", 200);
    var footerNote = doc.splitTextToSize("footer note  ...AS PER QTN    ", 200);
    var freedelivery = doc.splitTextToSize("FREE DELIVERY AT :", 200);
    var address = doc.splitTextToSize(
      "MITTAL TRANSPORT Co., 10A Chitpur Spur(Behind Bungur Building Kotkata 300 002)",
      200
    );

    doc.cell(20, k + 25, 550, 20, jobno, "", "C");
    doc.cellInitialize();
    doc.setFontType("normal");

    doc.cell(20, k + 45, 550, 50, footerNote, "", "C");
    doc.text(
      20,
      k + 105,
      "N.B : ITEM CODE, ORDER NO, INDENT NO MUST BE INDICATED ON CHALLAN"
    );
    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(20, k + 115, 250, 90, freedelivery, "", "C");
    doc.setFontType("normal");

    doc.text(20, k + 135, address);

    /*  doc.cellInitialize();
          doc.setFontType("bold");
          doc.cell(30, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(105, k + 25, 60, 30, ' ', '', 'right');
          doc.cell(147, k + 25, 60, 30,' ', '', 'right');
          doc.cell(189, k + 25, 60, 30,  ' ', '', 'right');
          doc.cell(231, k + 25, 60, 30,  ' ', '', 'right');
          doc.cell(189, k + 25, 40, 30,  ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(271, k + 25, 40, 30, ' ', '', 'right');
          doc.cell(322, k + 25, 60, 30, printHeaderValues.valueWithoutTax+'', '', 'right');*/

    doc.setFontType("normal");
    doc.setFontSize(10);

    //doc.text(400, k + 180, 'Authorised Signature');
    /*    doc.setFontSize(8);
            doc.setFontType("bold");
            var note='Note* : This is a computer generated print, Signature is not required.';
            var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
            doc.text(note, NotexOffset, k + 200);

            //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
            doc.setFontType("normal");
            doc.setFontSize(7);*/
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 250, newdat);
    doc.text(500, k + 250, printBy);
    addWaterMark(doc, printcopy);
    if (!this.state.sendMail) doc.save("PO_" + poId + ".pdf");
    else {
      const filename = "PO_" + poId + ".pdf";

      var pdf = doc.output("blob");
      // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});

      console.log("poId", this.state.poNo);

      const formData = new FormData();
      formData.append("poId", this.state.poNo);
      formData.append("file", pdf);
      formData.append("userId", getCookie(cons.USERID));
      formData.append("companyId", this.props.state.companyInfo.id);
      formData.append("cipher", getCookie(cons.CIPHER));
      formData.append("fileName", filename);

      var URL = serverApi.PO_MAIL_SERVICE;
      // requestList(URL,data,this.handleMRMail);

      uploadFormdataWithImage(URL, formData, this.handlePOMail);

      this.setState({
        sendMail: false,
      });
    }
  };
  handleMail = () => {
    this.setState({ isMail: false });
  };
  handlePOMail = (resp) => {
    console.log(resp);
    this.setState({
      sendMail: false,
      isMail: false,
    });

    if (resp.status) {
      if (this.state.finalApprover && this.state.poStatus !== "3") {
        this.createShowMessage(this.state.finalApprovalMsg, resp.status);
        this.setState({
          finalApprovalMsg: "",
        });
      } else {
        this.createShowMessage(resp.message, false);
      }
    } else {
      if (this.state.finalApprover && this.state.poStatus != "3") {
        this.createShowMessage(this.state.finalApprovalMsg, resp.status);
        this.setState({
          finalApprovalMsg: "",
        });
      } else {
        this.createShowMessage(resp.message, resp.status);
      }
      // this.createShowMessage(resp.message,resp.status)
    }
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  textField: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },
  notchedOutline: {},
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};

function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(withStyles(extendedFormsStyle)(JutePO));

// GET_ALL_PO: serverVars.MASTER_API + "budget-heads/getAllPOSPagination",
