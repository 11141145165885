import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Button from "../../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import { VIEW} from "../../../ui-actions/UiActions";
import moment from "moment";
import {getCookie} from "../../../helper/Cookies";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import {requestList} from "../../../server/server";
import {subMenus} from "../../../routes/routeIcons";
import IconButton from "../../../components/CustomButtons/IconButton";
import ExpandMoreIcon from "@material-ui/icons/FileDownload";
import exportFromJSON from "export-from-json";
import { connect } from "react-redux";


class ItemWiseStockQuantity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate:moment().days(-6).format('DD-MM-YYYY'),
            todate: moment().format('DD-MM-YYYY'),
            isRefresh: false,
            isLoading: false,
            startIndex:1,
            lastIndex:10,
            loadAll:true,
            dataTable: null,
            itemCode:""
        };
        this.onChangeValues = this.onChangeValues.bind(this);

    }
    componentDidMount() {
        this.getReportsTable(this.state.startIndex,this.state.lastIndex);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        var data = this.getData(1,10);
        this.handleLoading();

        if(nextProps.menuPath===subMenus.ITEM_WISE_STOCK_QUANTITY_REPORT)
            requestList(serverApi.ITEM_WISE_STOCK_QUANTITY_REPORT, data, this.handleReports)
        else if(nextProps.menuPath===subMenus.ITEM_WISE_STOCK_VALUE_REPORT) {
            requestList(serverApi.ITEM_WISE_STOCK_VALUE_REPORT, data, this.handleReports)

        }

    }


    handleSeacrh = () => {
        if (!this.state.isRefresh){
            // this.handleLoading();
            this.setState({loadAll:true});
            this.getReportsTable(this.state.startIndex,this.state.lastIndex);
        }

        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handlePage=(start,end)=>{
        this.setState({
            startIndex:start,
            lastIndex:end
        });
        console.log("start11",start,end);
        this.getReportsTable(start,end);

      //  this.handleSeacrh();
    };





    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    getData = (startIndex,lastIndex) => {
        const {itemCode,itemName1,fromDate,todate}=this.state;
        var data = {
            "startDate":fromDate,"toDate":todate,"finYear":getCookie(cons.ACADEMIC_YEAR),
            "companyId": this.props.state.companyInfo.id,
            "startIndex": startIndex,
            "lastIndex": lastIndex,
            "itemCode":itemCode,
        };
        return data;
    };




    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };



    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

    };
    getReportsTable = (startIndex,lastIndex) => {
        var data = this.getData(startIndex,lastIndex);
      //  this.handleLoading();

        if(this.props.menuPath===subMenus.ITEM_WISE_STOCK_QUANTITY_REPORT)
        requestList(serverApi.ITEM_WISE_STOCK_QUANTITY_REPORT, data, this.handleReports)
        else if(this.props.menuPath===subMenus.ITEM_WISE_STOCK_VALUE_REPORT) {
            requestList(serverApi.ITEM_WISE_STOCK_VALUE_REPORT, data, this.handleReports)

        }
    };
    handleTotalReports=(resp)=>{
        resp= resp.data;
        const fileName='ITEM_WISE_STOCK_QUANTITY';
        var exportType = 'xls';
        var data=resp.data.map(prop=>{
            var obj={};
            resp.column.forEach(o=>{
                obj[o.Header]=prop[o.accessor]
            });
            return obj;
        });
        exportFromJSON({ data, fileName, exportType })
        this.handleLoading();
    }
    handleReports = (resp) => {
        console.log("resp", resp.data);
        var dataTable = resp.data;
        dataTable.totalRecords=resp.totalRec;
       /* if(this.state.isExcel){
            this.setState({
                dataTableAll: dataTable,isExcel:false, isRefresh: false,
            });

        }else {*/
            this.setState({
                dataTable: dataTable,
                //    totalRecords: resp.totalRecords,
                hrmsAccess: resp.hrmsAccess,
                isRefresh: true,
                totalRecords:resp.totalRec
            });
            // this.handleLoading();
          /*  if (this.state.loadAll) {
                this.setState({isExcel:true,loadAll:false});

                this.getReportsTable(this.state.startIndex,resp.totalRec)
            }
*/

       // }
        if(this.state.isLoading){
            this.handleLoading();
        }
        //}

    };



    render() {
        const {classes} = this.props;

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                <ItemGrid xs={12}>

                    <RegularCard
                        isLoading={this.state.isLoading}

                        /* icon={ExpandMoreIcon}
                         iconColor={"green"}*/
                        content={
                            <div>
                                <GridContainer>
                                    <ItemGrid xs={10}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                //   closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }

                                                                inputProps={{
                                                                    id: "fromDate",
                                                                    value: this.state.fromDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime

                                                                //  closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "todate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "toDate",
                                                                    value: this.state.todate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>

                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Item Code
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="itemCode"
                                                            value={this.state.itemCode}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                        />

                                                    </ItemGrid>

                                                </GridContainer>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={2}>
                                        <GridContainer>
                                            <ItemGrid xs={12}>

                                                <Button color="primary" right={true}
                                                        onClick={()=>{
                                                            this.handleLoading();
                                                            this.setState({loadAll:true,startIndex:1,lastIndex:10})
                                                            this.handlePage(1,10)
                                                        }}>Search</Button>

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>

                                    <ItemGrid xs={12}>


                                        <ReportsTableComponent
                                          //  url={serverApi.ITEM_WISE_STOCK_QUANTITY_REPORT}
                                            staticData={this.state.dataTable}

                                            searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                            isLoading={this.state.isRefresh}
                                            fileName={"ITEM_WISE_STOCK_QUANTITY"}
                                            showPagination
                                           // dataTableAll={this.state.dataTableAll}
                                            handleLoading={this.handleLoading.bind(this)}
                                            handlePage={this.handlePage.bind(this)}

                                        />
                                        {this.state.totalRecords?  <IconButton right={true} style={{marginTop:"-40px"}}
                                                                                   color="success" onClick={()=>{
                                            var data = this.getData(this.state.startIndex,this.state.totalRecords);
                                             this.handleLoading();

                                            if(this.props.menuPath===subMenus.ITEM_WISE_STOCK_QUANTITY_REPORT)
                                                requestList(serverApi.ITEM_WISE_STOCK_QUANTITY_REPORT, data, this.handleTotalReports)
                                            else if(this.props.menuPath===subMenus.ITEM_WISE_STOCK_VALUE_REPORT) {
                                                requestList(serverApi.ITEM_WISE_STOCK_VALUE_REPORT, data, this.handleTotalReports)

                                            }
                                        }}>
                                            <ExpandMoreIcon/>
                                        </IconButton>:null}

                                    </ItemGrid>
                                </GridContainer>
                            </div>
                        }
                    />
                </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(ItemWiseStockQuantity)
);
