import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Search from "@material-ui/icons/Search";
import InputAdornment from "material-ui/Input/InputAdornment";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { quantity } from "chartist";
import { connect } from 'react-redux';

class AppDataCutsJugar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      date: moment().format("DD-MM-YYYY"),
      outTime: moment().format("hh:mm:ss A"),
      spellList: [],
      wevingList: [],
      loomNo: "",
      prodDate: moment().format("DD-MM-YYYY"),
      machineId: "",
      itemViewType: "id",
      spell: "",
      quantityCode: "",
      actualshots: "",
      speed: "",
      finishedlength: "",
      spellList1: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  componentDidMount() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      qualityType: 1,
    };

    var data2 = {
      companyId: this.props.state.companyInfo.id,
      qualityType: 1,
    };

    requestList(serverApi.WEAVING_QUALITY_MASTER, data2, this.handleData);

    requestList(serverApi.WEAVING_QUALITY_MASTER, data, this.handleQuality);
    getDataFromUrl(
      serverApi.GET_ALL_SPELL_LIST +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleSpell
    );
    getDataFromUrl(
      serverApi.GET_FRAME_NUMBERS + this.props.state.companyInfo.id + "/Loom",
      this.handleFrameNos
    );
  }

  handleData = (resp) => {
    var list = [];
    console.log("geting response from here only", resp.data);
    if (resp.data) {
      resp.data.forEach((prop) => {
        list.push({
          label: prop.qualityName + "(" + prop.qualityCode + ")",
          value: prop.qualityId,
          name: prop.qualityName,
        });
      });
      this.setState({ statesList: list });
      console.log(
        "helllllllloooooooooo.................",
        this.state.statesList
      );
    }
  };

  handleFrameNos = (resp) => {
    this.setState({ frameNoList: resp });
  };
  handleQuality = (resp) => {
    var list = resp.data.map((prop) => {
      var obj = {
        label: prop.qualityName + "_" + prop.qualityCode,
        value: prop.qualityCode,
        name: prop.qualityName,
      };
      return obj;
    });
    this.setState({ qualityList: list });
  };

  handleSpell = (resp) => {
    var options = [];
    resp.forEach((prop) => {
      var name = prop.name.split("_");

      if (name[1] === "1") {
        options.push(prop);
      }
    });
    console.log("getting the loom data", options);

    this.setState({ spellList1: options, loadSpell: true });
  };
  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      //  getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    getDataFromUrl(serverApi.CUTS_JUGAR_WEAVING_DELETE + id);

    // var data = this.state.dataTable;
    var juteId = "";
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
      });
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  // handleCreate = () => {
  //   //alert();
  //   if (!this.state.isCreated) {
  //     this.hideAlertMessage();
  //   }
  //   console.log(this.state);
  //   this.setState({
  //     isCreated: !this.state.isCreated,
  //   });
  //   //  this.hideAlertMessage();
  // };

  handleCreate = () => {
    // alert("helllo");
    window.location.reload();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };

  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (this.state.prodDate !== dateObj.format("DD-MM-YYYY")) {
      this.hideAlertMessage();
    }
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    console.log("value printingggggg " + value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.handleCreate();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");

    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      showMapPopup: false,
      showSpellPopup: false,
      showEbSpell: false,
      showActualShotPopup: false,
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      loomText: "",
      cuts: "",
      loomNo: "",
      prodDate: this.state.prodDate,
      jugar: "",
      spell: "",
      machineId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.CUTS_JUGAR_WEAVING_DELETE + id,
      this.handleDeleteClickResp
    );
  }
  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        issueId: this.state.indentNo,
        juteType: this.state.selectedJuteType,
        finYear: this.state.year,
        issueDate: this.dateChanged(),
        juteQuality: this.state.form_QualityId,
        baleLoose: this.state.unitConvertionName,
        noBales: this.state.balesCount,
        quantity: this.state.form_quantity,
        uomCode: this.state.uom,
        openStock: "",
        closeStock: "",
        issueStatus: "",
        godownNo: this.state.form_godownNo,
        side: this.state.processSideName,
        createdBy: getCookie(cons.USER_NAME),
        wastageId: this.state.wastageType,
      };
      /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                  this.handleLoading();
                  requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
              }
              else {
                  this.basicAlert("Please Enter All Mandatory Fields..!!");
              }*/
    }
  };

  addWeaving = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        quantityCode,
        actualshots,
        speed,
        finishedlength,
        prodDate,
      } = this.state;

      console.log("getting prodate");
      var data = {
        qualityId: quantityCode,
        actualShot: actualshots,
        spiningActualCount: "",
        qualityType: 1,
        // speed: speed,
        isActive: 1,
        companyId: this.props.state.companyInfo.id,
        // finishedLength: finishedlength,
        mappingDate: moment(prodDate, "DD-MM-YYYY", true).format("YYYY-MM-DD"),
      };

      if (quantityCode !== "") {
        this.handleLoading();
        requestList(
          serverApi.CUTS_JUGAR_WEAVING_ADD,
          data,
          this.handleAddIndent
        );
        // getDataFromUrl(
        //   serverApi.CUTS_JUGAR_DATA_LIST + this.props.state.companyInfo.id + "/1"
        // );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        spellList,
        loomNo,
        prodDate,
        cuts,
        jugar,
        spell,
        machineId,
      } = this.state;
      var data = spellList.map((prop) => {
        return {
          userId: this.props.state.companyInfo.id,
          loomNo: machineId,
          cuts: prop.cuts,
          jugar: prop.jugar,
          prodDate: prodDate,
          spell: prop.spell,
          dataSource: 0,
          activeStatus: 1,
          companyId: this.props.state.companyInfo.id,
          // "ebNo":prop.empNo
        };
      });

      /*  var data=[{  "userId":getCookie(cons.USERID),
                 "loomNo":machineId,
                 "cuts":cuts,
                 "jugar":jugar,
                 "prodDate":prodDate,
                 "spell":spell,
                 "dataSource":0,
                 "activeStatus":1,
                 "companyId":this.props.state.companyInfo.id}];*/
      console.log("data", data);
      if (prodDate !== "" && machineId !== "" && loomNo !== "") {
        this.handleLoading();
        requestList(
          serverApi.CUTS_JUGAR_PRODUCTION_ADD,
          data,
          this.handleAddIndent
        );
      } else if (machineId === "" && loomNo !== "") {
        this.basicAlert("Machine Id Not Found On Loom No Search ..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    console.log("resp message ", JSON.stringify(resp));

    if (resp.status === true) {
      if (this.state.showMapPopup) {
        console.log("resp dd ", this.state.hasData);

        if (!this.state.hasData) {
          this.setState({ showMapPopup: false });
          this.showMessage(resp.message, false);
          this.handleLoading();
        }
      } else if (this.state.showEbSpell) {
        this.setState({ showEbSpell: false });
        this.showMessage(resp.message, false);
        this.handleLoading();
      } else if (this.state.productEff) {
        this.setState({ productEff: false });
        this.showMessage(resp.message, false);
        this.handleLoading();
      } else {
        this.showMessage(resp.message, resp.status);

        this.handleLoading();
      }
    } else {
      this.showMessage(resp.message, resp.status);
      this.handleLoading();
    }
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status === true) {
              this.hideAlertMessage();
              //this.handleCreate();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        //  time2:dateObj.format('HH:mm:ss')
      });
  };
  getEmpDetails = () => {
    const { loomNo, prodDate } = this.state;
    if (loomNo !== "" && prodDate !== "") {
      var data = {
        date: prodDate,
        loomId: loomNo,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      requestList(
        serverApi.GET_EMP_DETAILS_BY_LOOMNO,
        data,
        this.handleEmpDetails
      );
    } else {
      this.basicAlert("Please Enter Loom No And Date..!!");
    }
  };
  handleCutsJugar = (resp) => {
    console.log("getting response from spellLit1", this.state.spellList1);
    var spellList = this.state.spellList1.map((prop) => {
      var obj = { spell: prop.value, empNo: "", cuts: "", jugar: "" };

      if (resp.length > 0) {
        resp.forEach((o) => {
          if (o.spell === prop.value) {
            obj.cuts = o.cuts;
            obj.jugar = o.jugar;
          }
        });
      }
      return obj;
    });
    this.setState({
      spellList: spellList,
    });
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      var header = resp.data;
      //   var list=[];
      var data = {
        date: this.state.prodDate,
        loomId: header.machineId,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(serverApi.GET_CUTS_JUGAR_LOOMNO, data, this.handleCutsJugar);

      this.setState({ machineId: header.machineId, loomText: resp.message });
      // this.showMessage(resp.message,false)
    } else {
      this.setState({ loomNo: "", loomText: resp.message, machineId: "" });

      // this.showMessage(resp.message,false)
    }
  };

  handleMachineQuality = (resp) => {
    var wevingList = [];
    console.log("frameNoList", this.state.frameNoList);
    this.state.frameNoList.forEach((prop, i) => {
      if (i > 0) {
        var obj = { qCode: "", mcId: prop.value, mcName: prop.label };
        resp.data.forEach((o) => {
          if (prop.value === o.mcId) {
            obj.qCode = o.qCode;
          }
        });
        wevingList.push(obj);
      }
    });

    /*
        if(resp.status&&resp.data!==undefined){
            wevingList=resp.data
        }
*/
    this.setState({ wevingList: wevingList, showMapPopup: true });
    this.handleLoading();
  };
  onUpdateMachineMap() {
    const { prodDate, spell, wevingList } = this.state;
    var list = [];
    wevingList.forEach((prop) => {
      if (prop.qCode !== "") {
        list.push(prop);
      }
    });

    //if(list.length<=10){
    var data = {
      date: prodDate,
      spell: spell,
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
      wevingList: list,
    };
    this.handleLoading();
    requestList(
      serverApi.UPDATE_DAILY_WEAVING_QUALITY_MAPPING,
      data,
      this.handleAddIndent
    );
    // }else {
    //     console.log("fff",list.length);
    //     this.setState({hasData:true});
    //     var list1=[];
    //     this.handleLoading();
    //     list.forEach((prop,i)=>{

    //         if(list1.length<5){
    //             list1.push(prop);
    //             console.log("lise",list1.length);

    //         }else {
    //             list1=[];
    //             list1.push(prop);
    //         }
    //         if(list1.length===5){
    //             var data={
    //                 "date":prodDate,
    //                 "spell":spell,
    //                 "companyId":this.props.state.companyInfo.id,
    //                 "userId":getCookie(cons.USERID),
    //                 "wevingList":list1
    //             };
    //             console.log("lis11",data);
    //             setTimeout(()=>{
    //                 requestList(serverApi.UPDATE_DAILY_WEAVING_QUALITY_MAPPING, data,this.handleAddIndent)
    //             },1000)
    //         }else {
    //             if(i+1===list.length&&list1.length>0){
    //                 console.log("lis12",list1);
    //                 this.setState({hasData:false});

    //                 var data={
    //                     "date":prodDate,
    //                     "spell":spell,
    //                     "companyId":this.props.state.companyInfo.id,
    //                     "userId":getCookie(cons.USERID),
    //                     "wevingList":list1
    //                 };
    //                 list1=[];
    //                 setTimeout(()=>{
    //                     requestList(serverApi.UPDATE_DAILY_WEAVING_QUALITY_MAPPING, data,this.handleAddIndent)
    //                 },1000);
    //             }
    //         }
    //     });

    //}
  }
  getMachineMap() {
    const {
      prodDate,
      spell,
      showSpellPopup,
      showActualShotPopup,
      showEbSpell,
      productEff,
    } = this.state;

    if (showSpellPopup) {
      //this.setState({showSpellPopup:false});
      if (spell !== "") {
        var data = {
          date: prodDate,
          spell: spell,
          companyId: this.props.state.companyInfo.id,
        };
        this.handleLoading();
        requestList(
          serverApi.GET_DAILY_WEAVING_QUALITY_MAPPING,
          data,
          this.handleMachineQuality
        );
      } else {
        this.basicAlert("Please select spell..!!");
      }
    }
    if (showActualShotPopup) {
      if (spell !== "") {
        var data = {
          date: prodDate,
          quantity: quantity,
          spell: spell,
          companyId: this.props.state.companyInfo.id,
        };
        this.handleLoading();
        requestList(
          serverApi.GET_DAILY_WEAVING_QUALITY_MAPPING,
          data,
          this.handleMachineQuality
        );
      } else {
        this.basicAlert("Please select spell..!!");
      }
    }

    if (showEbSpell) {
      if (this.state.prodDate !== "") {
        var data = {
          date: this.state.prodDate,
          /*"spell":this.state.spell,*/ companyId: this.props.state.companyInfo.id,
        };
        this.handleLoading();

        requestList(
          serverApi.UPDATE_YARN_EB_QUALITY_MAPPING,
          data,
          this.handleAddIndent
        );
      } else {
        this.basicAlert("Please select date..!!");
      }
    }

    if (productEff) {
      if (this.state.prodDate !== "") {
        var data = {
          date: this.state.prodDate,
          /*"spell":this.state.spell,*/ companyId: this.props.state.companyInfo.id,
        };
        this.handleLoading();

        requestList(
          serverApi.UPDATE_PRODUCT_EFFICIENCY_MAPPING,
          data,
          this.handleAddIndent
        );
      } else {
        this.basicAlert("Please select date..!!");
      }
    }
  }

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}
                {/*
                                {this.state.showEbSpell?
                                    <Popup closePopup={()=>this.hideAlert()}
                                           onClickSave={() => this.getMachineMap()}
                                           isShowSave={true}
                                           isShowCancel={true}
                                           save_btn_txt={"Ok"}
                                           cancel_button_text={"Cancel"}
                                           content={
                                               <form>
                                                   <GridContainer>

                                                       <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                           <GridContainer>
                                                               <ItemGrid xs={12} sm={4}>
                                                                   <InputLabel className={classes.label}>
                                                                       Spell<span
                                                                       style={{color: "red", fontSize: "12px"}}>*</span>
                                                                   </InputLabel>
                                                               </ItemGrid>
                                                               <ItemGrid xs={12} sm={8}>
                                                                   <SelectboxTemp
                                                                       defValue={this.state.spell}
                                                                       id={"spell"}
                                                                       getName={this.getQualityDetails}
                                                                       onSelect={this.onSelect}
                                                                       isRefresh={this.state.loadSpell}
                                                                       setIsReload={(val)=>{
                                                                           this.setState({loadSpell:false})
                                                                       }}
                                                                       stateKey="spell"
                                                                       //  staticData={this.state.spellList}
                                                                       url={serverApi.GET_ALL_SPELL_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                       value={this.state.spell}
                                                                   />

                                                               </ItemGrid>
                                                           </GridContainer>
                                                       </ItemGrid>


                                                   </GridContainer>

                                               </form>

                                           }

                                    />:null
                                }
*/}
                {this.state.showSpellPopup ? (
                  <Popup
                    closePopup={() => this.hideAlert()}
                    onClickSave={() => this.getMachineMap()}
                    isShowSave={true}
                    isShowCancel={true}
                    save_btn_txt={"Ok"}
                    cancel_button_text={"Cancel"}
                    content={
                      <form>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Spell
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.spell}
                                  id={"spell"}
                                  getName={this.getQualityDetails}
                                  onSelect={this.onSelect}
                                  isRefresh={this.state.loadSpell}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSpell: false });
                                  }}
                                  stateKey="spell"
                                  //  staticData={this.state.spellList}
                                  url={
                                    serverApi.GET_ALL_SPELL_LIST +
                                    this.props.state.companyInfo.id +
                                    "/" +
                                    getCookie(cons.CIPHER)
                                  }
                                  value={this.state.spell}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </form>
                    }
                  />
                ) : null}
                {this.state.showActualShotPopup ? (
                  <Popup
                    closePopup={() => this.hideAlert()}
                    // onClickSave={() => this.addWeaving()}
                    //isShowSave={true}
                    //isShowCancel={true}
                    // save_btn_txt={"Ok"}
                    //cancel_button_text={"Cancel"}
                    content={
                      <form>
                        <GridContainer>
                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Date time
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="outlined" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(dateObj, "prodDate");
                                    }}
                                    formControlProps={{
                                      height: "10px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "prodDate",
                                      value: this.state.prodDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Quality Code
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.quantityCode}
                                  // getName={(name) => {
                                  //   var val = name.split("^");
                                  //   this.setState({
                                  //     stateCode: val[1] !== undefined ? val[1] : "",
                                  //   });
                                  // }}
                                  stateKey="quantityCode"
                                  staticData={this.state.statesList}
                                  value={this.state.quantityCode}
                                  // url={serverApi.GET_ALL_STATES}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Actual Shots
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.actualshots}
                                  onChange={this.onChangeValues}
                                  id={"actualshots"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Speed
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.speed}
                                  onChange={this.onChangeValues}
                                  id={"speed"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Finished Length
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.finishedlength}
                                  onChange={this.onChangeValues}
                                  id={"finishedlength"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                          <ItemGrid xs={12} md={2}>
                            <Button color="primary" onClick={this.addWeaving}>
                              Create
                            </Button>
                          </ItemGrid>

                          <ItemGrid xs={12} md={6}>
                            <Button color="danger" onClick={this.hideAlert}>
                              Cancel
                            </Button>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            style={{
                              height: 2,
                              backgroundColor: "#00acc1",
                              marginTop: "5px",
                            }}
                          />
                          <ItemGrid
                            xs={12}
                            style={{
                              height: "300px",
                              overflow: "scroll",
                              display: "block",
                            }}
                          >
                            <TableComponent
                              url={
                                serverApi.CUTS_JUGAR_DATA_LIST +
                                this.props.state.companyInfo.id +
                                "/1"
                              }
                              //
                              isRefresh={this.state.isRefresh}
                              handleSearch={this.handleSeacrh.bind(this)}
                              isLoading={this.state.isRefresh}
                              onActionClick={this.handleDeleteClick}
                              itemViewType={this.state.itemViewType}
                              actionType={DELETE}
                              fileName={"CutsJugar"}
                            />
                          </ItemGrid>

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Spell
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.spell}
                                  id={"spell"}
                                  getName={this.getQualityDetails}
                                  onSelect={this.onSelect}
                                  isRefresh={this.state.loadSpell}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSpell: false });
                                  }}
                                  stateKey="spell"
                                  //  staticData={this.state.spellList}
                                  url={
                                    serverApi.GET_ALL_SPELL_LIST +
                                    this.props.state.companyInfo.id +
                                    "/" +
                                    getCookie(cons.CIPHER)
                                  }
                                  value={this.state.spell}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel
                                  className={classes.label}
                                  defValue={this.state.quantity}
                                  onChange={this.onChangeValues}
                                  id={"quantity"}
                                >
                                  Quantity
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                        </GridContainer>
                      </form>
                    }
                  />
                ) : null}

                {this.state.showMapPopup ? (
                  <Popup
                    closePopup={() => this.hideAlert()}
                    onClickSave={() => this.onUpdateMachineMap()}
                    isShowSave={true}
                    isShowCancel={true}
                    save_btn_txt={"Update"}
                    cancel_button_text={"Cancel"}
                    content={
                      <form>
                        <GridContainer>
                          {/*
                                                           <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Machine Id
                                                               </InputLabel>
                                                           </ItemGrid>
*/}
                          <ItemGrid
                            xs={12}
                            sm={4}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Machine Name
                            </InputLabel>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Quality
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            style={{ maxHeight: 250, overflow: "auto" }}
                          >
                            {this.state.wevingList.map((prop, i) => {
                              return (
                                <GridContainer>
                                  {/*
                                                                          <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                                              <InputLabel className={classes.label}>
                                                                                  {prop.mcId}
                                                                              </InputLabel>
                                                                          </ItemGrid>
*/}
                                  <ItemGrid
                                    xs={12}
                                    sm={4}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      {prop.mcName}
                                    </InputLabel>
                                  </ItemGrid>

                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <SelectboxTemp
                                      id={"qualityCode"}
                                      onSelect={(val, key) => {
                                        var list = this.state.wevingList;
                                        list[i].qCode = val;
                                        this.setState({ wevingList: list });
                                      }}
                                      defValue={prop.qCode}
                                      //  getName={this.getYarnName}
                                      // isRefresh={this.state.loadQuality}
                                      stateKey="qualityCode"
                                      staticData={this.state.qualityList}
                                      // url={serverApi.SELECT_QUALITIES+this.props.state.companyInfo.id}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              );
                            })}
                          </ItemGrid>
                        </GridContainer>
                      </form>
                    }
                  />
                ) : null}

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "prodDate");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "prodDate",
                            value: this.state.prodDate,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Loom No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="loomNo"
                        // label="Multiline"
                        value={this.state.loomNo}
                        onChange={this.onChangeValues}
                        //className={classes.textField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                <Search
                                  className={classes.inputAdornmentIcon}
                                  onClick={this.getEmpDetails}
                                />
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {this.state.loomText !== undefined &&
                      this.state.loomText !== null ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {this.state.loomText}
                        </span>
                      ) : null}
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} style={{ overflowX: "auto" }}>
                  <GridContainer>
                    {this.state.machineId !== "" ? (
                      <ItemGrid xs={12} sm={12}>
                        <GridContainer>
                          <ItemGrid xs={2}>
                            <InputLabel className={classes.label}>
                              Spell
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={3} style={{ minWidth: 150 }}>
                            <InputLabel className={classes.label}>
                              Cuts
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={3} style={{ minWidth: 150 }}>
                            <InputLabel className={classes.label}>
                              Jugar
                            </InputLabel>
                          </ItemGrid>
                          {/*
                                        <ItemGrid xs={3} style={{minWidth:150}}>
                                            <InputLabel  className={classes.label}>
                                                Emp No
                                            </InputLabel>
                                        </ItemGrid>
*/}
                        </GridContainer>
                      </ItemGrid>
                    ) : null}

                    {this.state.machineId !== "" &&
                      this.state.spellList.map((prop, key) => {
                        return (
                          <ItemGrid
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: "10px",
                              minWidth: 1000,
                              width: "auto",
                            }}
                          >
                            <GridContainer>
                              <ItemGrid xs={2}>{prop.spell}</ItemGrid>
                              <ItemGrid xs={3} style={{ minWidth: 150 }}>
                                <TextField
                                  id="cuts1"
                                  // label="Multiline"
                                  value={prop.cuts}
                                  onChange={(event) => {
                                    var list = this.state.spellList;
                                    list[key].cuts = event.target.value;
                                    this.setState({ spellList: list });
                                  }}
                                  //className={classes.textField}
                                />
                              </ItemGrid>
                              <ItemGrid xs={3} style={{ minWidth: 150 }}>
                                <TextField
                                  id="jugar1"
                                  // label="Multiline"
                                  value={prop.jugar}
                                  onChange={(event) => {
                                    var list = this.state.spellList;
                                    list[key].jugar = event.target.value;
                                    this.setState({ spellList: list });
                                  }}
                                  //className={classes.textField}
                                />
                              </ItemGrid>
                              {/*
                                                        <ItemGrid xs={3} style={{minWidth:150}}>
                                                            <TextField
                                                                id="emp1"
                                                                // label="Multiline"
                                                                value={prop.empNo}
                                                                onChange={(event)=>{
                                                                    var list=this.state.spellList;
                                                                    list[key].empNo=event.target.value;
                                                                    this.setState({spellList:list})
                                                                }}
                                                                //className={classes.textField}
                                                            />
                                                        </ItemGrid>
*/}
                            </GridContainer>
                          </ItemGrid>
                        );
                      })}
                  </GridContainer>
                </ItemGrid>

                {/*
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Spell<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.spell}
                                                id={"spell"}
                                                getName={this.getQualityDetails}
                                                onSelect={this.onSelect}
                                                isRefresh={this.state.loadSpell}
                                                setIsReload={(val)=>{
                                                    this.setState({loadSpell:false})
                                                }}
                                                stateKey="spell"
                                                staticData={this.state.spellList}
                                             //   url={serverApi.GET_ALL_SPELL_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                value={this.state.spell}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
*/}
                {/*
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Cuts<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="cuts"
                                                // label="Multiline"
                                                value={this.state.cuts}
                                                onChange={this.onChangeValues}

                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
*/}

                {/*
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Jugar<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="jugar"
                                                // label="Multiline"
                                                value={this.state.jugar}
                                                onChange={this.onChangeValues}

                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
*/}

                <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                  <span
                    style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                    onClick={() => {
                      this.setState({ showSpellPopup: true });
                    }}
                  >
                    Click Here To Map Machines & Quality
                  </span>
                </ItemGrid>
                <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                  <span
                    style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                    onClick={() => {
                      this.setState({ showActualShotPopup: true });
                    }}
                  >
                    Click Here To Map Actual Shots
                  </span>
                </ItemGrid>

                <ItemGrid xs={12}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          this.setState({ showEbSpell: true });
                          this.getMachineMap();
                        }}
                      >
                        Click Here To Update EB No & Working Hours
                      </span>
                    </ItemGrid>
                    <ItemGrid xs={5} style={{ marginTop: "15px" }}>
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          this.setState({ productEff: true });
                          this.getMachineMap();
                        }}
                      >
                        Click Here To Calculate Production & Efficiency
                      </span>
                    </ItemGrid>

                    {!this.state.updateView ? (
                      <ItemGrid xs={6} style={{ marginTop: "5px" }}>
                        <GridContainer>
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={this.onCreateButtonClick}
                            >
                              Create
                            </Button>
                          </ItemGrid>

                          <ItemGrid>
                            <Button color="danger" onClick={this.handleCreate1}>
                              Cancel
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={12}>
                  <TableComponent
                    url={serverApi.CUTS_JUGAR_PRODUCTION_TABLE_LIST}
                    searchData={this.getData()}
                    isRefresh={this.state.isRefresh}
                    handleSearch={this.handleSeacrh.bind(this)}
                    isLoading={this.state.isRefresh}
                    onActionClick={this.handleDeleteClick}
                    itemViewType={this.state.itemViewType}
                    actionType={DELETE}
                    fileName={"CutsJugar"}

                    /*  showDelete={true}
                                              onDeleteClick={this.handleDeleteClick}*/
                  />
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AppDataCutsJugar)
);
