import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { getDataFromUrl, requestList } from "../../../server/server";
import moment from "moment";
import { getCookie } from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import { connect } from 'react-redux';

class MonthsWiseSalarySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment()
        .days(-6)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "indentNo",
      approveStatus: false,

      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",

      date1: moment().format("DD-MM-YYYY"),
      indentNo: "",
      year: moment().format("YYYY"),
      unitConvertion: "",
      unitConvertionId: "",
      unitConversionName: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantiy: "",
      selectedMukam: "",
      alert: false,
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    this.getReportsTable();
  }
  getReportsTable = () => {
    var data = {
      taskCode: 1043,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.EMP_BANK_STATEMENT, data, this.handleReports);
  };
  handleReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    var dataTable = resp;
    if (dataTable !== null) {
      var columns = dataTable.data.column.map((prop, key) => {
        var obj = prop;
        obj.filterable = false;
        obj.sortable = false;
        obj.minWidth = 30;
        //  obj.maxWidth=50;
        // delete obj.minWidth;
        delete obj.maxWidth;
        return obj;
      });
    }
    dataTable.data.column = columns;
    console.log("columns", JSON.stringify(dataTable.data.column));

    if (resp.status) {
      this.setState({
        dataTable: dataTable,
        isRefresh: true,
      });
    }
    this.handleLoading();
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) this.getReportsTable();
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        id +
        "/" +
        getCookie(cons.USERID) +
        "/getIndentDetails",
      this.handleViewClickResp
    );
  };
  handleViewClickResp = (resp) => {
    var header = resp.indentHeader;
    var list = resp.indentItems;
    var indentlist = list.map((prop, key) => {
      return {
        id: key,
        juteType: parseInt(prop.itemId),
        juteName: prop.itemIdDesc,
        quality: prop.qualityCode,
        qualityName: prop.qualityCodeDesc,
        quantity: prop.indentQuantity,
        unit: prop.unitId,
        deptId: prop.deptId,
        grpId: prop.itemGroupId,
        stockValue: prop.stock,
        indentstatus: prop.indentStatus,
        indentItemId: prop.indentLineItemId,
        quantityType: prop.noOfBales,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                if (this.state.approveStatus)
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");
                      // console.log("no of bales",o.quantityType);
                      this.setState({
                        id: o.id,
                        updateItemAlert: true,
                        updateItem: true,
                        indentItemId: o.indentItemId,
                        selectedJuteType: o.juteType,
                        selectedJuteName: o.juteName,
                        selectedJuteQuality: o.quality,
                        selectedQualityName: o.qualityName,
                        quantity: o.quantityType,
                        selectedUnit: o.unit,
                        selectedDeptId: o.deptId,
                        selectedGrpId: o.grpId,
                        stock: o.stockValue,
                        // alert:true,
                      });
                      return true;
                    }
                  });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            {/* use this button to remove the data row */}
            <IconButton
              onClick={() => {
                var data = this.state.invoiceList;
                data.find((o, i) => {
                  if (o.id === key) {
                    if (this.state.approveStatus) {
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");
                      //  data.splice(i,1);
                    }
                    return true;
                  }
                });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        canChangeMukam: true,
        canChangeUnit: true,
        indentStatus: header.status,
        approveStatus: header.approveButton,
        indentNo: header.id,
        statusDesc: header.statusDesc,
        unitConvertion: this.getDefaultUnitConvertion(header.unitConversion),
        // unitConvertionId:"0",
        indentQuantity: header.indentWeight,
        //  sumOFQuantities:header.indentWeight,
        year: header.finnacialYear,
        createdBy: header.submitter,
        createdOn: header.createDateDesc,
        date1: header.indentDateDesc,
        selectedMukam: header.mukamNo,
        selectedVehicleType: header.vehicleTypeId,
        vehicleQuantity: header.vehicleQuantity,
        quantity: "",
        stock: "",
        selectedJuteQuality: "",
        selectedQualityName: "",
        invoiceList: indentlist,
      });
    }
  };
  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      indentType: "J",
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      mukam: this.state.mukamId,
      status: this.state.status,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(parseFloat((this.state.indentQuantity * 100) / 150));
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      //  console.log("unitConvertionId"+this.state.indentQuantity);
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  getStock = () => {
    console.log("sttt", this.state.stock);
    return this.state.stock;
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    if (event.target.id === "indentQuantity") {
      //   console.log("val "+this.state.indentQuantity);
      if (value > 0 && value <= 100) {
        this.setState({
          selectedVehicleType: 3,
          vehicleQuantity: 1,
        });
      } else if (value > 100 && value <= 120) {
        this.setState({
          selectedVehicleType: 2,
          vehicleQuantity: 1,
        });
      } else if (value > 120 && value <= 160) {
        this.setState({
          selectedVehicleType: 1,
          vehicleQuantity: 1,
        });
      } else if (value > 160 && value <= 200) {
        this.setState({
          selectedVehicleType: 4,
          vehicleQuantity: 1,
        });
      } else if (value > 200) {
        this.setState({
          selectedVehicleType: 4,
          vehicleQuantity: Math.round(value / 200),
        });
      } else if (value === "") {
        this.setState({
          selectedVehicleType: "",
          vehicleQuantity: "",
        });
      }
    }

    if (event.target.id === "vehicleQuantity") {
      if (value !== null && value !== "") {
        if (this.state.selectedVehicleType !== "")
          this.setState({
            indentQuantity:
              parseInt(value) * parseInt(this.state.selectedIndentQuantity),
          });
        else {
          value = "";
          this.basicAlert("Please Select Vehicle Type..!!");
        }
      }
    }
    if (event.target.id === "stock") {
      //   console.log("val "+this.state.indentQuantity);
      this.setState({
        stock: this.state.stock,
      });
    }
    if (event.target.id === "quantity" && this.state.updateItem) {
    }
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        [stateKey]: selectedValue,
      });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      quantity: "",
      stock: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.indentItemId,
        indentHeaderId: this.state.indentNo,
        itemId: prop.juteType,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        id: this.state.indentNo,
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantiy,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
        status: "4",
      },
      indentItems: invoiceList,
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
  };

  onApproveButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.indentItemId,
        indentHeaderId: this.state.indentNo,
        itemId: prop.juteType,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        id: this.state.indentNo,
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantiy,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
        status: this.state.indentStatus,
      },
      indentItems: invoiceList,
    };
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== "" &&
      this.state.invoiceList.length >= 1
    ) {
      this.handleLoading();
      requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
    } else if (this.state.invoiceList.length < 1) {
      this.basicAlert("Please Add Atleast One Item..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };

  onCreateButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        itemId: prop.juteType,
        dtlItemDesc: prop.juteName,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantity,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
      },
      indentItems: invoiceList,
    };
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== "" &&
      this.state.invoiceList.length >= 1
    ) {
      this.handleLoading();
      requestList(serverApi.ADD_INDENT, data, this.handleAddIndent);
    } else if (this.state.invoiceList.length < 1) {
      this.basicAlert("Please Add Atleast One Item..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var sumOfQty = 0;
    var itemIndex = 0,
      quantity = 0,
      hasItem = false;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      arr[itemIndex].quantityType = this.state.quantity;
    } else {
      itemIndex = this.state.invoiceList.length;
    }

    //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
    arr.map((q) => {
      sumOfQty = sumOfQty + parseInt(q.quantityType);
      if (
        q.quality === this.state.selectedJuteQuality &&
        !this.state.updateItem
      )
        hasItem = true;
    });
    if (!this.state.updateItem)
      sumOfQty = sumOfQty + parseInt(this.state.quantity);

    if (this.state.unitConvertion.value === 1) {
      quantity =
        (parseInt(this.state.indentQuantity) * this.state.quantity) / 100;
    } else {
      quantity = Math.round(
        parseFloat((this.state.quantity * 150) / 100).toFixed(2)
      );
    }

    console.log("selectedUnit " + this.state.selectedUnit);
    var addItemObj = {
      id: itemIndex,
      indentItemId: this.state.indentItemId,
      juteType: this.state.selectedJuteType,
      juteName: this.state.selectedJuteName,
      quality: this.state.selectedJuteQuality,
      qualityName: this.state.selectedQualityName,
      quantityType: this.state.quantity,
      quantity: quantity,
      unit: this.state.selectedUnit,
      deptId: this.state.selectedDeptId,
      grpId: this.state.selectedGrpId,
      stockValue: this.state.stock,
      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    indentItemId: o.indentItemId,
                    updateItemAlert: true,
                    updateItem: true,
                    selectedJuteType: o.juteType,
                    selectedJuteName: o.juteName,
                    selectedJuteQuality: o.quality,
                    selectedQualityName: o.qualityName,
                    quantity: o.quantityType,
                    selectedUnit: o.unit,
                    selectedDeptId: o.deptId,
                    selectedGrpId: o.grpId,
                    stock: o.stockValue,
                    // alert:true,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.invoiceList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({
                    deleteItem: true,
                    id: o.id,
                  });

                  //  data.splice(i,1);
                  return true;
                }
              });
              this.setState({
                invoiceList: data,
                selectedJuteType: "",
                selectedJuteName: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                quantity: "",
                selectedUnit: "",
                selectedDeptId: "",
                selectedGrpId: "",
                stock: "",
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };

    if (!hasItem) {
      if (
        this.state.selectedJuteType !== "" &&
        this.state.selectedJuteQuality !== "" &&
        this.state.quantity !== "" &&
        this.state.vehicleQuantity !== ""
      ) {
        if (this.getConvertedQty() !== "") {
          if (sumOfQty <= parseInt(this.getConvertedQty())) {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);
            console.log(arr.toString());

            this.setState({
              hideJuteType: false,
              loading: true,
              sumOFQuantities: sumOfQty,
              canChangeMukam: false,
              invoiceList: arr,
            });
            this.hideAlert();
          } else {
            this.basicAlert(
              "Quantity Should Not Exceed Converted Quantity..!!"
            );
            // alert("Quantity should be less than or equal to Indent quantity")
          }
        } else {
          if (sumOfQty <= 100) {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);
            //console.log(arr.toString());

            this.setState({
              loading: true,
              invoiceList: arr,
              sumOFQuantities: sumOfQty,
            });
            this.hideAlert();
          } else {
            this.basicAlert("Quantity % can not exceed 100..!!");
          }
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This Quality. Please Select Another One..!!"
      );
    }
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  getQuantityType = () => {
    var val = this.state.unitConvertion.value;
    if (val === 2) {
      return "BALE";
    } else {
      return "%";
    }
  };
  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConvertion);
    //  var id="";
    console.log(this.state);
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== ""
    )
      this.setState({
        loading: true,
        alert: true,
      });
    else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };
  handleSelectedValues = (defValue) => {
    if (
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeUnit &&
      this.state.unitConversion !== defValue
    ) {
      this.unitConvertionAlert(
        "Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        unitConvertion: defValue,
        unitConvertionId: defValue.value,
        unitConvertionName: defValue.label,
        canChangeUnit: false,
      });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "LOOSE", name: "LOOSE" },
      { value: 2, label: "BALE", name: "BALE" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            content={
              <div>
                <GridContainer>
                  <ItemGrid xs={10}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              From date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="outlined" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "fromDate");
                                }}
                                formControlProps={{
                                  height: "10px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "fromDate",
                                  value: this.state.fromDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              To date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="outlined" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "todate");
                                }}
                                formControlProps={{
                                  height: "10px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "toDate",
                                  value: this.state.todate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Branch
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={false}
                              defValue={this.state.branchId}
                              onSelect={this.onSelect}
                              stateKey="branchId"
                              url={
                                serverApi.SELECT_BRANCH +
                                this.props.state.companyInfo.id
                              }
                              value={this.state.branchId}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Pay Scheme
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Employee Code
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField onChange={this.onChangeValues} />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={2}>
                    <GridContainer>
                      {/*<ItemGrid xs={12}>*/}
                      {/*<IconButton*/}
                      {/*onClick={this.handleCreate}*/}
                      {/*color="success" right={true}>*/}
                      {/*<ExpandMoreIcon/>*/}
                      {/*</IconButton>*/}

                      {/*</ItemGrid>*/}
                      <ItemGrid xs={12}>
                        <Button
                          color="primary"
                          right={true}
                          onClick={this.handleSeacrh}
                        >
                          Search
                        </Button>
                        <br />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />

                  <ItemGrid xs={12}>
                    {/* reports table  */}
                    {/* {this.state.dataTable !== null &&
                    this.state.dataTable !== undefined ? (
                      <ReportsTableComponent
                        // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                        staticData={this.state.dataTable.data}
                        //  searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        // isLoading={this.state.isRefresh}
                        //  onActionClick={this.handleViewClick}
                        //  printDoc={this.printDocument}
                        fileName={"jute_Godownwise_report"}
                        itemViewType={this.state.itemViewType}
                      />
                    ) : null} */}
                    {/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>
*/}
                    {/* <SamplePDF printDocument={this.printDocument}/> */}

                    {/*<TableComponent*/}
                    {/*url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}*/}
                    {/*searchData={this.getData()}*/}
                    {/*isRefresh={this.state.isRefresh}*/}
                    {/*handleSearch={this.handleSeacrh.bind(this)}*/}
                    {/*isLoading={this.state.isRefresh}*/}
                    {/*onActionClick={this.handleViewClick}*/}
                    {/*itemViewType={this.state.itemViewType}*/}
                    {/*printDoc={this.printDocument}*/}
                    {/*actionType={VIEW}/>*/}
                  </ItemGrid>
                  {/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
                  {/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
                </GridContainer>
              </div>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
  printDocument = () => {
    var data = {
      taskCode: 1043,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    if (resp.status) {
      // this.setState({
      //     dataTable:resp,
      //     isRefresh: true,
      // })
      var modules = resp.data.reportValues;
      var grandTotals = resp.data.totalValues;
      var companyName = resp.data.companyName;
    }
    console.log("modules", modules);
    var pageNo = 1;

    //var modules=[]

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("l", "pt", "l");
    doc.setFontSize(14);
    doc.text(300, 40, companyName);
    doc.setFontSize(10);
    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 16);

    doc.text(700, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    doc.text(350, 60, "Report No.:[JUTE/03]");
    doc.text(
      320,
      80,
      "Jute Quality Wise Report From  " +
        this.state.fromDate +
        " to " +
        this.state.todate +
        ""
    );
    doc.text(650, 80, "Quantity in : Quintal");
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(40, 100, 50, 30, "    J code     ", "", "center");
    doc.cell(141, 100, 80, 30, "    Quality   ", "", "center");
    var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
    doc.cell(172, 100, 50, 30, OpeningWeight, "", "center");
    var OpeningBales = doc.splitTextToSize("Opening Bales", 38);
    doc.cell(203, 100, 50, 30, OpeningBales, "", "center");
    var OpeningDrums = doc.splitTextToSize("Opening Drums", 38);
    doc.cell(203, 100, 50, 30, OpeningDrums, "", "center");
    var ReceiptWeight = doc.splitTextToSize("Receipt Weight", 38);
    doc.cell(203, 100, 50, 30, ReceiptWeight, "", "center");
    var ReceiptBales = doc.splitTextToSize("Receipt Bales", 38);
    doc.cell(203, 100, 50, 30, ReceiptBales, "", "center");
    var ReceiptDrums = doc.splitTextToSize("Receipt Drums", 38);
    doc.cell(203, 100, 50, 30, ReceiptDrums, "", "center");
    var IssuedWeight = doc.splitTextToSize("Issued Weight", 38);
    doc.cell(203, 100, 50, 30, IssuedWeight, "", "center");
    var IssuedBales = doc.splitTextToSize("Issued Bales", 38);
    doc.cell(203, 100, 50, 30, IssuedBales, "", "center");
    var IssuedDrums = doc.splitTextToSize("Issued Drums", 38);
    doc.cell(203, 100, 50, 30, IssuedDrums, "", "center");
    var ClosingWeight = doc.splitTextToSize("Closing Weight", 38);
    doc.cell(203, 100, 50, 30, ClosingWeight, "", "center");
    var ClosingBales = doc.splitTextToSize("ClosingBales", 38);
    doc.cell(203, 100, 50, 30, ClosingBales, "", "center");
    var ClosingDrums = doc.splitTextToSize("Closing Drums", 38);
    doc.cell(203, 100, 50, 30, ClosingDrums, "", "center");

    // doc.cellInitialize();

    var cellWidths = [
      "",
      50,
      80,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
      50,
    ];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 105;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 17) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var date = moment().format("DD/MM/YYYY");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(40, 580, newdat);
          doc.text(650, 580, printBy);
          doc.addPage("a4", "l");
          doc.setFontSize(14);
          doc.text(300, 40, companyName);
          doc.setFontSize(10);
          var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 16);

          doc.text(700, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(350, 60, "Report No.:[JUTE/03]");
          doc.text(
            320,
            80,
            "Jute Quality Wise Report From  " +
              this.state.fromDate +
              " to " +
              this.state.todate +
              ""
          );
          doc.text(650, 80, "Quantity in : Quintal");
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(40, 100, 50, 30, "    J code     ", "", "center");
          doc.cell(141, 100, 80, 30, "    Quality   ", "", "center");
          var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
          doc.cell(172, 100, 50, 30, OpeningWeight, "", "center");
          var OpeningBales = doc.splitTextToSize("Opening Bales", 38);
          doc.cell(203, 100, 50, 30, OpeningBales, "", "center");
          var OpeningDrums = doc.splitTextToSize("Opening Drums", 38);
          doc.cell(203, 100, 50, 30, OpeningDrums, "", "center");
          var ReceiptWeight = doc.splitTextToSize("Receipt Weight", 38);
          doc.cell(203, 100, 50, 30, ReceiptWeight, "", "center");
          var ReceiptBales = doc.splitTextToSize("Receipt Bales", 38);
          doc.cell(203, 100, 50, 30, ReceiptBales, "", "center");
          var ReceiptDrums = doc.splitTextToSize("Receipt Drums", 38);
          doc.cell(203, 100, 50, 30, ReceiptDrums, "", "center");
          var IssuedWeight = doc.splitTextToSize("Issued Weight", 38);
          doc.cell(203, 100, 50, 30, IssuedWeight, "", "center");
          var IssuedBales = doc.splitTextToSize("Issued Bales", 38);
          doc.cell(203, 100, 50, 30, IssuedBales, "", "center");
          var IssuedDrums = doc.splitTextToSize("Issued Drums", 38);
          doc.cell(203, 100, 50, 30, IssuedDrums, "", "center");
          var ClosingWeight = doc.splitTextToSize("Closing Weight", 38);
          doc.cell(203, 100, 50, 30, ClosingWeight, "", "center");
          var ClosingBales = doc.splitTextToSize("ClosingBales", 38);
          doc.cell(203, 100, 50, 30, ClosingBales, "", "center");
          var ClosingDrums = doc.splitTextToSize("Closing Drums", 38);
          doc.cell(203, 100, 50, 30, ClosingDrums, "", "center");

          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var myItem = prop[key] + " ";
          return doc.cell(
            40,
            k,
            cellWidths[a],
            25,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    // var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"5282.04","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(40, k + 25, 50, 30, "Grand Total", "", "C");
    // // //Opening
    doc.cell(203, k + 25, 80, 30, " ", "", "right");
    doc.cell(
      110,
      k + 25,
      50,
      30,
      grandTotals.openingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      141,
      k + 25,
      50,
      30,
      grandTotals.openingBalesTot + "",
      "",
      "right"
    );
    doc.cell(
      172,
      k + 25,
      50,
      30,
      grandTotals.openingDrumsTot + " ",
      "",
      "right"
    );
    doc.cell(
      203,
      k + 25,
      50,
      30,
      grandTotals.receivedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      248,
      k + 25,
      50,
      30,
      grandTotals.receiptbalesTot + " ",
      "",
      "right"
    );
    doc.cell(
      248,
      k + 25,
      50,
      30,
      grandTotals.receiptDrumTot + " ",
      "",
      "right"
    );
    doc.cell(
      203,
      k + 25,
      50,
      30,
      grandTotals.issuedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      248,
      k + 25,
      50,
      30,
      grandTotals.issuedbalesTot + " ",
      "",
      "right"
    );
    doc.cell(248, k + 25, 50, 30, grandTotals.issuedDrumTot + " ", "", "right");
    doc.cell(
      203,
      k + 25,
      50,
      30,
      grandTotals.closingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      248,
      k + 25,
      50,
      30,
      grandTotals.closingbalesTot + " ",
      "",
      "right"
    );
    doc.cell(
      248,
      k + 25,
      50,
      30,
      grandTotals.closingDrumTot + " ",
      "",
      "right"
    );

    doc.text(40, k + 130, "Dept. Incharge");
    doc.text(650, k + 130, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(40, k + 170, newdat);
    doc.text(650, k + 170, printBy);
    doc.save("EmpBankStatement.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
};

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(MonthsWiseSalarySummary)
);
