import React from "react";
import Datetime from "react-datetime";
// core components
import Print from "@material-ui/icons/Print";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  serverVars,
  configNames,
  getConfigValue,
  getDecimal,
  getTenantId,
} from "../../helper/Consts";

import { subMenus } from "../../routes/routeIcons";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {
  getDataFromUrl,
  requestList,
  uploadFile,
  getDataFromUrlSimple,
  requestUomSimple,
} from "../../server/server";
import moment from "moment";
import { getCookie, removeCookie, setCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
  IsTallyAvailable,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import {
  invoiceTypesList,
  saleTypes,
  TCSPercentages,
  yesOrNo,
  packagingType,
} from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";
import { connect } from "react-redux";

class DeliveryOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      billPassNo: "",
      customerList: [],
      customerAddressList: [],
      file: null,
      fileName: "No file Chosen",
      isRefresh: false,
      isLoading: false,
      itemViewType: "issueObj",
      invoiceType: "",
      approveStatus: false,
      updateView: false,
      statusDesc: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      invoiceList: [],
      lineItemId: "0",
      billPassStatus: "",
      form_supplierId: "",
      date1: moment().format("DD-MM-YYYY"),
      customerId: "",
      freightCharges: "",
      footerNotes: "",
      terms: "",
      termsConditions: "",
      customer: "",
      customerName: "",
      billingAddress: "",
      shippingAddress: "",
      quoteDate: "",
      expireDate: "",
      taxType: "",
      totalAmount: "",
      sumOfamt: "",
      invoiceNo: "",
      invoiceDate: "",
      paymentDueDate: "",
      invoiceAmount: "",
      invoiceReceivedDate: "",
      quantity: "",
      invoiceQty: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      alternateContact: "",
      contactNo: "",
      gstNo: "",
      gstNo2: "",
      state: "",
      stateCode: "",
      panNo: "",
      challanDate: "",
      challanNo: "",
      billingStateCode: "",
      shippingStateCode: "",
      saleNo: "",
      delOrderNo: "",
      vehicleNo: "",
      invoiceTypeId: "",
      quoteNoString: "",
      quoteId: "",
      delOrderDate: "",
      typeOfSale: "",
      payableTax: "N",
      saleOrderDate: "",
      bales: "",
      staticbales: "",
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      transporterList: [],
      billingStateName: "",
      shippingStateName: "",
      brokerName: "",
      pcsoNo: "",
      dateOfRemovalOfGoods: "",
      administrativeOfficeAddress: "",
      factoryAddress: "",
      destinationRailHead: "",
      transporterId: 0,
      transporterName: "",
      transporterAddress: "",
      transporterStateCode: "",
      transporterStateName: "",
      containerNo: "",
      netWeight: 0,
      packSheet: 0,
      totalWeight: 0,
      loadingPoint: "",
      contractNo: "",
      contractDate: "",
      pcsoDate: "",
      time: "",
      time2: "",
      branchId: "",
      isShipping: false,
      mrNo: "",
      claimAmount: "",
      statesList: [],
      tcsPercentage: "",
      tcsAmount: 0,
      otherReference: "",
      ewayBillDate: "",
      ewayBillNo: "",
      destination: "",
      claimDesc: "",
      claimAmountDtl: "",
      mrList: [],
      internalCompanyId: "",
      destinationMrList: [],
      destinationMr: "",
      netTotal: 0,
      itemCode: "",
      itemGroup: "",
      taxPercentage: 0,
      invoiceTyp: "",
      admBranchId: "",
      facBranchId: "",
      qualityList: [],
      unitConversion: "",
      availbleQty: "",
      disableUnit: false,
      isBill: false,
      billId: "",
      rateType: "",
      invoicePdf: "",
      taxPayable: "Y",
      tcsOther: "",
      selectedValueID: 0,
      branch: 0,
      billingTo: 0,
      shippingTo: 0,
      itemCodeNew: "",
      saleLineItemId: 0,
      packagingType: 0,
      packagingTypeId: "",
      fromSalesOrder: false,
      amendmentNo1: "",
      amendmentNo2: "",
      amendmentNo3: "",
      amendmentNo4: "",
      amendmentDate1: "",
      amendmentDate2: "",
      amendmentDate3: "",
      amendmentDate4: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };

    requestList(serverApi.CUSTOMER_MASTER, data, this.handelCustomer);
    getDataFromUrl(
      serverApi.TRANSPORT_MASTER_LIST + this.props.state.companyInfo.id,
      this.handleTransporter
    );
    getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);
    var invoicingModel = "1";
    invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
    if (invoicingModel === "1") {
      this.setState({ invoiceTyp: "1", loadInvoiceType: true });
    } else if (invoicingModel === "2") {
      this.setState({ invoiceTyp: "2", loadInvoiceType: true });
    } else {
      invoicingModel = JSON.parse(invoicingModel);
      if (invoicingModel[0].value === "3")
        this.setState({ typeOfSale: "Sale Of Material", loadSaleType: true });

      this.setState({
        invoiceTyp: invoicingModel[0].value,
        loadInvoiceType: true,
      });
    }
    var quotation_id = getCookie(cons.QUOTATION_ID);
    var sale_id = getCookie(cons.SALE_ID);
    if (
      quotation_id !== null &&
      quotation_id !== undefined &&
      quotation_id !== ""
    ) {
      getDataFromUrl(
        serverApi.QUOTATIONS_DETAILS_BY_ID +
          getCookie(cons.QUOTATION_ID) +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handleViewByQuotation
      );
    }
    if (sale_id !== null && sale_id !== undefined && sale_id !== "") {
      this.setState({ isFromSaleOrder: true });
      getDataFromUrl(
        serverApi.GET_SALE_ORDER_BY_ID +
          sale_id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleViewBySaleOrder
      );
    }

    /* var isMRinvoice=getConfigValue(configNames.SENDING_MR_INVOICE);
        if(isMRinvoice==="2"){
           this.getMRList()

        }*/
    this.getInvoiceTableList();

    getDataFromUrl(
      serverApi.UOM_GET_RELATION_DATA +
        "/" +
        1 +
        "/" +
        "companyId/" +
        this.props.state.companyInfo.id,

      this.handleUomType1
    );
  }

  onCreateInvoiceButtonClick = () => {
    setCookie(cons.DELIVERY_ORDER_ID, this.state.deliveryOrderNo);
    this.props.history.push(subMenus.SALES_INVOICE);
  };

  handleUomType1 = (resp) => {
    var listType1 = [];

    resp.forEach((prop) => {
      listType1.push({
        label: prop.uomCode,

        value: prop.uomCode,

        name: prop.uomDsc,
      });
    });
    this.setState({
      uomListType1: listType1,
    });
  };

  getInvoiceTableList = () => {
    var data = this.getData();
    requestList(serverApi.INVOICE_LIST, data, this.handleInvoiceList);
    // requestList(serverApi.DELIVERYORDERS_LIST, data, this.handleInvoiceList);
  };
  handleInvoiceList = (resp) => {
    var list = resp;
    if (IsTallyAvailable("Tally Sync")) {
      list.column.push({
        Header: "Tally",
        accessor: "tally",
        sortable: false,
        filterable: false,
        minWidth: 100,
      });
      list.data = resp.data.map((prop, i) => {
        var obj = {
          ...prop,
          tally: (
            <div className="actions-right">
              {prop.tallySync === "Y" ? (
                <img src={TallyIcon} color={"danger"} width={50} height={50} />
              ) : null}
              {prop.tallySync === "R" ? (
                <img
                  src={TallyRedIcon}
                  width={50}
                  height={50}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.showMessage(prop.tallyMessage, false);
                  }}
                />
              ) : null}
            </div>
          ),
        };
        return obj;
      });
    }

    if (resp) {
      this.setState({ invoiceTableList: list, isRefresh: true });
    }
  };
  getMRList = () => {
    var data1 = {
      fromDate: "",
      toDate: "",
      status: "3,31",
      supplierCode: "",
      mrNo: "",
      vehicleNo: "",
      poNum: "",
      agentId: "",
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      //  "acYear": getCookie(cons.ACADEMIC_YEAR),
    };
    requestList(serverApi.GET_MR_LIST, data1, this.handleMrList);
  };
  handleMrList = (resp) => {
    var list = [];
    if (resp.data !== undefined) {
      resp.data.forEach((prop) => {
        list.push({
          label: prop.mrPrintNo,
          value: prop.mrNo,
          name: prop.mrPrintNo + "^" + prop.supplierName,
        });
      });
      this.setState({ mrList: list, loadMr: true });
    }
  };
  handleDestinationMrList = (resp) => {
    console.log("destinationMrList", resp);
    var list = [];
    if (resp.data !== undefined) {
      resp.data.forEach((prop) => {
        list.push({
          label: prop.mrPrintNo,
          value: prop.mrNo,
          name: prop.mrPrintNo,
        });
      });
      this.setState({ destinationMrList: list, loadDMR: true });
    }
  };

  handleStates = (resp) => {
    var list = [];
    if (resp.status === undefined) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) obj.value = prop.label;
        else obj.value = "";
        list.push(obj);
      });
    }
    this.setState({
      statesList: list,
    });
  };
  handleTransporter = (resp) => {
    var list = resp.data;
    console.log("transporter", list);
    if (list.length > 0) {
      list = list.map((prop) => {
        return {
          value: prop.transporterId,
          label: prop.transporterName,
          name:
            prop.transporterName +
            "_" +
            prop.transporterAddress +
            "_" +
            prop.stateCode +
            "_" +
            prop.stateName,
        };
      });
      this.setState({ transporterList: list, loadTransporter: true });
    }
  };
  handleViewBySaleOrder = (resp) => {
    var header = resp.hdr;
    var list = resp.li;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    var sum_of_tax = 0,
      sumOfamt = 0,
      taxType = 0;

    removeCookie(cons.SALE_ID);

    if (list !== null)
      indentlist = list.map((prop, key) => {
        var itemAmount = parseFloat(prop.amount);
        var taxPercentage = 0;
        if (prop.igstPer > 0) {
          taxType = 1;
          taxPercentage = prop.igstPer;
        } else {
          taxType = 2;
          taxPercentage = prop.cgstPer + prop.sgstPer;
        }
        var qty = (
          parseFloat(prop.quantity) - parseFloat(prop.invoiceQty)
        ).toFixed(2);
        var saleBales = parseFloat(qty) / prop.balePerBag;
        var amount = parseFloat(qty) * parseFloat(prop.rate);
        sumOfamt = sumOfamt + amount;
        var taxAmnt = (
          (parseFloat(amount) * parseFloat(taxPercentage)) /
          100
        ).toFixed(2);
        // console.log("avaible", parseFloat(prop.quantity) - parseFloat(prop.invoiceQty))
        return {
          id: key,
          itemCode: prop.itemId,
          itemName: prop.itemName,
          itemGroup: prop.itemGroup,
          itemGrp: prop.itemGroup + "" + prop.itemId,
          description: prop.itemDescription,
          quantity: qty,
          uom: prop.uom,
          rate: parseFloat(prop.rate).toFixed(2),
          hsnCode: prop.hsnCode,
          amount: parseFloat(prop.amountWithoutTax).toFixed(2),
          lineItemId: prop.invoiceLineitemId,
          isgst: prop.igstPer,
          ssgst: prop.sgstPer,
          csgst: prop.cgstPer,
          taxId: prop.taxId,
          make: prop.make,
          taxPercentage: taxPercentage,
          taxamount: taxAmnt,
          isActive: prop.isActive,
          invoiceQty: prop.invoiceQty,
          availbleQty: parseFloat(prop.quantity) - parseFloat(prop.doQuantity),
          saleLineitemId: prop.saleLineItemId,
          weightOfBag: prop.weightPerBag,
          bales: saleBales,
          saleBales: saleBales,
          staticbales: saleBales,
          // uom: prop.uom,
          uomRate: prop.quantityUom,
          fromSalesOrder: true,
        };
      });

    if (header != undefined && header !== null) {
      console.log("header", header);
      this.setState({
        isCreated: true,
        // approveStatus: header.approveStatus,
        statusDesc: header.statusDesc,
        //  mrDate: header.goodReceiptDateDesc,
        year: header.acYear,
        createdBy: header.createdBy,
        createdOn: header.createDateDesc,
        //  frieghtCharges:header.frieghtCharges,
        invoiceList: indentlist,
        // filesList:filesList,
        // challanList:header.billChallanUrl,

        freightCharges: header.freightCharges,
        footerNotes: header.footerNotes,
        terms: header.terms,
        termsConditions: header.termsConditions,
        customer: header.customerId,
        isCustomer: header.customerId ? true : false,
        //   customerName: header.customerName,
        billingAddress: header.billingAddress,
        shippingAddress: header.shippingAddress,
        loadAddress: true,
        totalAmount: header.saleAmount,
        netTotal: header.grandTotal.toFixed(2),
        sumOfamt: header.grandTotal.toFixed(2),
        taxType: this.getDefaultSideConvertion(taxType),
        invoiceTypeId: header.invoiceType,
        quoteId: header.saleId,
        branchId: header.branchId,
        saleNo: header.saleId,
        //saleOrderDate: header.saleDateDesc,
        saleOrderDate: header.salesOrderDate
          ? header.salesOrderDate
          : header.saleDateDesc,
        //quoteDate:header.saleDateDesc,
        // isFromSaleOrder:true,
        saleNoString: header.saleNoString,
        brokerName: header.brokerName,
        transporterId: header.transporterId,
        transporterName: header.transporterName,
        transporterAddress: header.transporterAddress,
        transporterStateCode: header.transporterStateCode,
        transporterStateName: header.transporterStateName,
        loadTransporter: true,
        loadState: true,
        pcsoDate: header.pcsoDateDesc,
        pcsoNo: header.pcsoNo,
      });
    }

    getDataFromUrl(
      serverApi.CUSTOMER_MASTER_DETAILS_BY_ID +
        header.customerId +
        "/" +
        getCookie(cons.CIPHER),
      this.handleCustomer
    );
  };

  handleViewByQuotation = (resp) => {
    var header = resp.quoteHdr;
    var list = resp.quoteLineItems;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    var sum_of_tax = 0,
      sumOfamt = 0,
      taxType = 0;

    removeCookie(cons.QUOTATION_ID, header.quoteUniqueNo);

    if (list !== null)
      indentlist = list.map((prop, key) => {
        var itemAmount = parseFloat(prop.amount);
        var taxPercentage = 0;
        if (prop.igstPer > 0) {
          taxType = 1;
          taxPercentage = prop.igstPer;
        } else {
          taxType = 2;
          taxPercentage = prop.cgstPer + prop.sgstPer;
        }
        return {
          id: key,
          itemCode: prop.itemId + "",
          itemName: prop.itemName,
          description: prop.itemDescription,
          quantity: prop.quantity,
          uom: prop.uom,
          rate: parseFloat(prop.rate).toFixed(2),
          hsnCode: prop.hsnCode,
          amount: parseFloat(prop.amountWithoutTax).toFixed(2),
          lineItemId: prop.invoiceLineitemId,
          isgst: prop.igstPer,
          ssgst: prop.sgstPer,
          csgst: prop.cgstPer,
          taxId: prop.taxId + "",
          make: prop.make,
          taxPercentage: taxPercentage,
          taxamount: prop.taxAmt,
          isActive: prop.isActive,
          itemGroup: prop.itemGroup,
          itemGroupName: prop.itemGroupDesc,
          saleLineitemId: prop.saleLineitemId,
        };
      });

    if (header !== null) {
      this.setState({
        isCreated: true,
        approveStatus: header.approveStatus,
        statusDesc: header.statusDesc,
        //  mrDate: header.goodReceiptDateDesc,
        year: header.acYear,
        createdBy: header.createdBy,
        createdOn: header.createDateDesc,
        //  frieghtCharges:header.frieghtCharges,
        invoiceList: indentlist,
        // filesList:filesList,
        // challanList:header.billChallanUrl,

        freightCharges: header.freightCharges,
        footerNotes: header.footerNotes,
        terms: header.terms,
        termsConditions: header.termsConditions,
        customer: header.customerId,
        customerName: header.customerName,
        billingAddress: header.billingAddress,
        shippingAddress: header.shippingAddress,
        totalAmount: header.quoteAmount,
        netTotal: header.grandTotal.toFixed(2),
        sumOfamt: header.grandTotal.toFixed(2),
        taxType: this.getDefaultSideConvertion(taxType),
        invoiceType: header.invoiceType,
        quoteId: header.quoteId,
        branchId: header.branchId,
      });
    }
  };
  handelCustomer = (resp) => {
    var data = resp.data,
      customerList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      customerList.push(obj);
      data.forEach((prop) => {
        var name =
          prop.name +
          "^" +
          prop.address +
          "^" +
          prop.shippingAddress +
          "^" +
          prop.contactNo +
          "^" +
          prop.alternateContactNo +
          "^" +
          prop.gstNo +
          "^" +
          prop.state +
          "^" +
          prop.billingStateCode +
          "^" +
          prop.shippingStateCode +
          "^" +
          prop.state2 +
          "^" +
          prop.internalCompanyId +
          "^" +
          prop.finalStatus;
        var obj = { label: prop.name, value: prop.id, name: name };
        customerList.push(obj);
      });
      this.setState({
        customerList: customerList,
        isCustomer: true,
      });
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getInvoiceTableList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  getDefaultSideConvertion = (name) => {
    var val = 0,
      label = "Select..",
      data = "";
    if (name !== null && name !== 0 && name !== "") {
      if (name === 1) {
        val = 1;
        label = "IGST";
      } else if (name === 2) {
        val = 2;
        label = "CGST & SGST";
      } else if (name === 3) {
        val = 3;
        label = "VAT";
      } else {
        val = 4;
        label = "NO TAX";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  handleViewClick = (id) => {
    var juteId = "";
    console.log("jute  " + "  " + id);
    var obj = JSON.parse(id);
    // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_DELIVERY_ORDER_BY_ID +
          obj.deliveryNo +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          this.props.state.companyInfo.id,
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp.hdr;
    var Deliverydate = header.deliveryDate;
    Deliverydate = Deliverydate.split("T")[0];
    console.log("sale order", resp);

    var saleOrderdate = header.salesOrderDate;
    saleOrderdate = saleOrderdate.split("T")[0];
    var list = resp.line;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    var sum_of_tax = 0,
      sumOfamt = 0;

    if (list !== null) indentlist = [];
    list.forEach((prop, key) => {
      var itemAmount = parseFloat(prop.amountWithoutTax);
      sumOfamt = sumOfamt + itemAmount;
      var taxPercentage = 0;
      if (prop.igstPer > 0) {
        // taxType = 1;
        taxPercentage = prop.igstPer;
      } else {
        // taxType = 2;
        taxPercentage = prop.cgstPer + prop.sgstPer;
      }

      var obj = {
        id: key,
        itemCode: prop.itemCode,
        itemGroup: prop.itemGroup,
        itemCodeID: prop.itemGroup + prop.itemCode,
        itemName: prop.description,
        item: prop.item,
        itemGrp: prop.itemGroup + prop.itemCode,
        qualityId: prop.qualityId,
        qualityName: prop.qualityName,
        description: prop.itemDescription,
        quantity: prop.quantity,
        uom: prop.uom,
        uomRate: prop.uomRate,
        uom2: prop.uom2,
        uom3: prop.uom3,
        uomQuantity1: prop.quantity2,
        uomQuantity2: prop.quantity3,
        remarks: prop.remarks,
        description: prop.remarks,
        rate: getDecimal(prop.itemRate),
        hsnCode: prop.hsnCode,
        amount: getDecimal(prop.amount),
        lineItemId: prop.lineItemId,
        isgst: prop.igstPer,
        ssgst: prop.sgstPer,
        csgst: prop.cgstPer,
        taxId: prop.taxId,
        make: prop.make,
        taxPercentage: prop.taxRate,
        taxamount: prop.taxAmt,
        isActive: prop.isActive,
        itemGroup: prop.itemGroup,
        itemGroupName: prop.itemGroupDesc,
        bales: prop.bales,
        packingWithIdentificationNo: prop.packingWithIdentificationNo,
        balesSrlNo: prop.balesSrlNo,
        costFactor: prop.costFactor,
        costFactorDes: prop.costFactorDes,
        claimDesc: prop.claimDesc,
        claimAmountDtl: prop.claimAmountDtl,
        saleDrums: prop.saleDrums,
        saleBales: prop.saleBales,
        staticbales: prop.saleBales,
        saleWeight: prop.saleWeight,
        mrLineId: prop.mrLineId,
        destinationMrLine: prop.destinationMrLine,
        acceptedWeight: parseFloat(prop.claimAmountDtl) / prop.claimRate,
        claimRate: prop.claimRate,
        saleAvailableQty: prop.saleAvailableQty,
        saleLineItemId: prop.saleLineItemId,
        availbleQty:
          parseFloat(prop.quantity) + parseFloat(prop.saleAvailableQty),
        weightOfBag: prop.weightOfBag,
        status: prop.status,
      };
      indentlist.push(obj);
    });

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        approveStatus: header.approveStatus,
        statusDesc: header.statusDesc,
        //  mrDate: header.goodReceiptDateDesc,
        year: header.acYear,
        createdBy: header.createdBy,
        createdOn: moment(header.createdDate).format("DD-MM-YYYY"),
        //  frieghtCharges:header.frieghtCharges,
        invoiceList: indentlist,
        // filesList:filesList,
        // challanList:header.billChallanUrl,
        invoiceStatus: header.status,
        deliveryOrderNo: header.deliveryOrderNo,
        deliveryOrderSeq: header.deliveryOrderSeq,
        deliveryDate: moment(header.deliveryDate).format("DD-MM-YYYY"),
        factoryOffice: header.factoryOffice,
        status: header.status,
        branch: header.branch,

        freightCharges: header.freightCharges,
        footerNotes: header.footerNotes,
        terms: header.terms,
        termsConditions: header.termsConditions,
        customer: header.customer,
        customerName: header.customerName,
        billingTo: header.billingTo,
        shippingTo: header.shippingTo,
        billingAddress: header.billingToAddress,
        shippingAddress: header.shippingAddress,
        quoteDate: header.invoiceDateDesc,
        expireDate: header.dueDateDesc,
        totalAmount: parseFloat(sumOfamt.toFixed(2)),
        netTotal: header.grandTotal,
        sumOfamt: header.grandTotal,
        taxType: this.getDefaultSideConvertion(header.taxType),
        // invoiceType: header.invoiceType,
        invoiceType: JSON.stringify(header.invoiceType),
        challanDate: header.challanDateDesc,
        gstNo: header.gstNo,
        challanNo: header.challanNo,
        billingStateCode: header.billingStateCode,
        shippingStateCode: header.shippingStateCode,
        saleNo: header.saleNo,
        saleNoString: header.salesNoString,
        delOrderNo: "DO/" + header.salesNoString,
        vehicleNo: header.vehicleNo,
        delOrderDate: header.delOrderDateDesc,
        typeOfSale: header.typeOfSale,
        saleOrderDate: moment(header.salesOrderDate).format("DD-MM-YYYY"),
        pcsoDate: saleOrderdate,
        payableTax: header.taxPayable !== null ? header.payableTax : "N",
        tdsPayable: header.tdsPayable !== null ? header.tdsPayable : "N",
        tdsReason: header.tdsReason,
        tdsAmount: header.tdsAmount,
        billingStateName: header.billingStateName,
        shippingStateName: header.shippingStateName,
        brokerName: header.brokerName,
        pcsoNo: header.pcsoNo,
        dateOfRemovalOfGoods: header.dateOfRemovalDesc,
        administrativeOfficeAddress: header.adminOfficeAddress,
        factoryAddress: header.factoryOfficeAddress,
        destinationRailHead: header.destinationRailHead,
        packagingType: header.packagingType,
        transporterId: header.transporterId,
        transporterName: header.transporterName,
        transporterAddress: header.transporterAddress,
        transporterStateCode: header.transporterStateCode,
        transporterStateName: header.transporterState,
        salesOrderNo: header.salesOrderNo,

        containerNo: header.containerNo,
        netWeight: header.netWeight,
        packSheet: header.packSheet,
        totalWeight: header.totalWeight,
        loadingPoint: header.loadingPoint,
        pcsoDate: moment(header.pcsoDate).format("DD-MM-YYYY"),
        contractNo: header.contractNo,
        contractDate: header.contractDateDesc,
        time2: moment(header.dateOfRemovalOfGoods).format("HH:mm:ss"),
        time: header.dateOfRemovalDateTimeDesc,
        //branchId: header.branchId,
        mrNo: header.mrId,
        claimAmount: header.claimAmount,
        tcsPercentage: header.tcsPercentage,
        tcsAmount:
          header.tcsAmount !== null
            ? parseFloat(header.tcsAmount).toFixed(2)
            : 0.0,
        otherReference: header.otherReference,
        ewayBillDate: header.ewayBillDateDesc,
        ewayBillNo: header.ewayBillNo,
        destination: header.destination,
        saleOrderType: header.saleOrderType,
        destinationMr: header.destinationMr,
        // invoiceTyp: header.invoiceTyp,
        admBranchId: header.administartiveOffice,
        administartiveOffice: header.administartiveOffice,
        facBranchId: header.facBranchId,
        unitConversion: header.unitConversion,
        roundoff: header.roundOff,
        disableUnit: true,
        gstNo2: header.gstNo,
        status: header.status,
        taxPayable: header.taxPayable,
        amendmentNo1: header.amendmentNo1,
        amendmentNo2: header.amendmentNo2,
        amendmentNo3: header.amendmentNo3,
        amendmentNo4: header.amendmentNo4,
        amendmentDate1:
          header.amendmentDate1 === null
            ? ""
            : moment(header.amendmentDate1).format("DD-MM-YYYY"),
        amendmentDate2:
          header.amendmentDate2 === null
            ? ""
            : moment(header.amendmentDate2).format("DD-MM-YYYY"),
        amendmentDate3:
          header.amendmentDate3 === null
            ? ""
            : moment(header.amendmentDate3).format("DD-MM-YYYY"),
        amendmentDate4:
          header.amendmentDate4 === null
            ? ""
            : moment(header.amendmentDate4).format("DD-MM-YYYY"),
      });
      console.log("invoice", JSON.stringify(this.state.branchId));
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getAgentName = (name) => {
    this.setState({
      agentName: name,
    });
  };
  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      itemName: values[0],
      // uom: values[1],
    });
    if (values[0] !== this.state.itemName) {
      var weightOfBag = 0,
        NoOfBags = 0,
        weightOfBale = 0;
      if (values[8] !== "null") weightOfBag = parseFloat(values[8]);
      if (values[9] !== "null") NoOfBags = parseFloat(values[9]);

      weightOfBale = weightOfBag * NoOfBags;

      this.setState({
        rate: parseFloat(values[3]),
        hsnCode: values[4] !== "null" ? values[4] : "",
        taxId: values[5] !== "null" ? parseInt(values[5]) : "",
        loadTax: true,
        weightOfBale: weightOfBale,
        NoOfBags: NoOfBags,
        weightOfBag: weightOfBag,
      });
    }
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshquality = (val) => {
    this.setState({
      isCustomer: val,
    });
  };
  handleCreate = () => {
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      status: this.state.status,
      companyId: this.props.state.companyInfo.id,
      saleNo: this.state.saleNo,
      acYear: this.state.year,
      cusId: this.state.customerId,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    console.log("printing the delivery date", id);
    if (typeof dateObj === "object")
      this.setState({
        // [id]: dateObj.format("DD-MM-YYYY"),
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
    if (
      id === "deliveryDate" &&
      !this.state.updateView &&
      typeof dateObj === "object"
    ) {
      var data = {
        fromDate: "",
        toDate: dateObj.format("DD-MM-YYYY"),
        cusId: "",
        saleNo: "",
        status: "3",
        companyId: this.props.state.companyInfo.id,
        acYear: getCookie(cons.ACADEMIC_YEAR),
      };
      requestList(serverApi.SALE_ORDER_LIST, data, this.handleSales);
    }
  };

  handleSales = (resp) => {
    if (resp.data) {
      var list = [];
      if (resp.data.length > 0) {
        resp.data.forEach((prop) => {
          var obj = {
            label: prop.saleNoString,
            value: prop.saleId,
            name:
              prop.saleNoString +
              "^" +
              prop.saleDateDesc +
              "^" +
              prop.brokerName,
          };
          list.push(obj);
        });
      }
      this.setState({ saleOrderList: list, loadSaleOrder: true });
    } else {
      this.setState({ saleOrderList: [], loadSaleOrder: true });
    }
  };
  dateChanged = (date) => {
    var myDate = date;
    if (myDate !== null && myDate !== undefined && myDate !== "") {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    } else {
      return;
    }
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  getConvertedQty = () => {
    if (this.state.unitConvertion.value === 2) {
      console.log("indentQuantity  " + this.state.indentQuantity);
      return Math.round(
        parseFloat(parseFloat((this.state.indentQuantity * 100) / 150))
      );
    } else {
      return "";
    }
  };
  getIndentQtyTon = () => {
    var val = this.state.indentQuantity;
    if (val !== "") {
      return parseFloat(parseFloat(val / 10).toFixed(2));
    } else {
      return "";
    }
  };
  getIndentQty = () => {
    var val = this.state.vehicleQuantity;
    console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
    if (val !== "" && this.state.indentQuantity !== "") {
      return parseInt(val) * parseInt(this.state.selectedIndentQuantity);
    } else {
      return "";
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    if (event.target.id === "tcsPercentageO") {
      console.log("val " + value);
      this.setState({
        tcsOther: value,
      });
    }
    if (event.target.id === "quantity" && this.state.itemGroup !== "997") {
      console.log("printing the quantity group name", this.state.itemGroup);

      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = value; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown

      var testdata = {
        groupCode: groupCode,
        itemId: itemId,
        priceUom: priceUom,
        quantityUom: quantityUom,
        itemPrice: itemPrice,
        itemQuantity: itemQuantity,
        selectedUom2: selectedUom2,
        selectedUom3: selectedUom3,
      };
      console.log("Nagesh quantity items", testdata);

      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }
    if (event.target.id === "rate" && this.state.itemGroup !== "997") {
      console.log("printing the rate group name", this.state.itemGroup);
      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = value; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }

    if (
      event.target.id === "rate" &&
      this.state.quantity !== "" &&
      this.state.quantity !== null
    ) {
      console.log("val " + value);
      if (value !== null && value !== "") {
        var Calrate = this.state.quantity;
        // var amount = parseFloat(Calrate) * parseFloat(value);
        var amount =
          parseFloat(Calrate) * parseFloat(value) * this.state.conversionValue;
        if (
          this.state.invoiceTyp === "3" &&
          this.state.printingPop &&
          this.state.itemName === "Printing Charges"
        ) {
          amount = (parseFloat(Calrate) / 100) * parseFloat(value);
        }

        this.setState({
          amount: amount.toFixed(2),
        });
        if (this.state.taxPercentage > 0 && this.state.taxPercentage) {
          var itemtaxAmount =
            (parseFloat(amount) * parseFloat(this.state.taxPercentage)) / 100;
          this.setState({
            itemTaxAmt: itemtaxAmount,
          });
        }
      } else {
        this.setState({
          amount: 0,
        });
        if (this.state.itemGroup !== 997) {
          var groupCode = this.state.itemGroup;
          var itemId = this.state.itemCode;
          var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
          var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
          var itemPrice = value; // Price From Popup Window
          var itemQuantity = this.state.quantity; // Quantity from Popup window
          var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
          var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown
          this.handleUomConversion2(
            groupCode,
            itemId,
            priceUom,
            quantityUom,
            itemPrice,
            itemQuantity,
            selectedUom2,
            selectedUom3
          );
        }
      }
    }

    if (event.target.id === "freightCharges") {
      var sumOfamt = this.state.sumOfamt;
      if (value !== "") {
        var total =
          parseFloat(this.state.sumOfamt) + parseFloat(value).toFixed(2);
        this.setState({
          netTotal: total,
        });
      } else {
        this.setState({ netTotal: sumOfamt });
      }
    }
    if (event.target.id === "netWeight") {
      var val = 0;
      if (event.target.value !== "") {
        val = value;
      }
      var packsheet =
        this.state.packSheet !== "" && this.state.packSheet !== null
          ? parseFloat(this.state.packSheet)
          : 0;
      var net = parseFloat(val) + packsheet;
      this.setState({ totalWeight: net });
    }
    if (event.target.id === "packSheet") {
      var val = 0;
      if (event.target.value !== "") {
        val = value;
      }
      var packsheet =
        this.state.netWeight !== "" && this.state.netWeight !== null
          ? parseFloat(this.state.netWeight)
          : 0;
      var net = parseFloat(val) + packsheet;
      this.setState({ totalWeight: net });
    }

    if (event.target.id === "roundoff") {
      var tcs = 0;
      if (this.state.tcsAmount !== "" && this.state.tcsAmount)
        tcs = parseFloat(this.state.tcsAmount);
      var round = (
        parseFloat(this.state.netTotal) -
        parseFloat(this.getTotalTax("claim")) +
        tcs
      ).toFixed(2);

      round = round.split(".");
      var total = parseFloat(round[0]);
      round = "0." + round[1];
      var max = 1 - parseFloat(round);
      if (value !== "") {
        if (parseFloat(value) > 0) {
          if (parseFloat(value) <= max) {
            this.setState({
              [event.target.id]: value,
            });
          }
        } else {
          console.log("--round", parseFloat(value));
          if (value === "-")
            this.setState({
              [event.target.id]: value,
            });

          if (parseFloat(value) >= parseFloat("-" + round)) {
            this.setState({
              [event.target.id]: value,
            });
          }
        }
      }
    } else if (
      event.target.id === "quantity" &&
      this.state.rate !== "" &&
      this.state.rate !== null
    ) {
      console.log("avail", this.state.availbleQty);
      console.log(
        "getting conversion value",
        this.state.conversionValue,
        "getting value",
        value
      );

      if (value !== null && value !== "") {
        var availqty = this.state.availbleQty;

        if (this.state.invoiceTyp === "3") {
          var noofbags = 0;
          if (this.state.NoOfBags === undefined) {
            noofbags =
              parseFloat(this.state.availbleQty) / this.state.staticbales;
          } else {
            noofbags = this.state.NoOfBags;
          }
          var saleBales = (parseFloat(value) / noofbags).toFixed(2);
          this.setState({ bales: saleBales, saleBales: saleBales });
        }
        // quantity validation
        if (this.state.saleNo > 0) {
          // if (
          //   value > availqty &&
          //   this.state.saleLineitemId !== undefined &&
          //   this.state.saleLineitemId !== null
          // ) {
          //   this.basicAlert(
          //     "Entered Quantity should not be greater than available Quantity"
          //   );
          // } else {
          //   var Calrate = this.state.rate;
          //   var amount = parseFloat(Calrate) * parseFloat(value);
          //   this.setState({
          //     amount: amount.toFixed(2),
          //     [event.target.id]: value,
          //   });
          // }
          console.log(
            "printing the group id",
            this.state.itemGroupId,
            this.state.itemGroup
          );
          if (this.state.itemGroup == 997) {
            if (
              this.state.saleLineitemId == 0 ||
              this.state.saleLineitemId !== null ||
              this.state.saleLineitemId > 0 ||
              this.state.saleLineItemId == undefined
            ) {
              var Calrate = this.state.rate;
              var cnt_qty = parseFloat(
                value * this.state.conversionValue
              ).toFixed(3);
              var amount = parseFloat(Calrate) * cnt_qty;
              this.setState({
                amount: amount.toFixed(2),
                [event.target.id]: value,
              });
            } else {
              this.basicAlert(
                "Entered Quantity should not be greater than available Quantity"
              );
            }
          } else {
            if (
              (value == availqty || value < availqty) &&
              (this.state.saleLineitemId == 0 ||
                this.state.saleLineitemId !== null ||
                this.state.saleLineitemId > 0 ||
                this.state.saleLineItemId == undefined)
            ) {
              var Calrate = this.state.rate;
              // var amount = parseFloat(Calrate) * parseFloat(value);
              var cnt_qty = parseFloat(
                value * this.state.conversionValue
              ).toFixed(3);
              var amount = parseFloat(Calrate) * cnt_qty;
              console.log(
                "getting rate",
                Calrate + "getting value",
                value + "getting uom Conversion",
                this.state.conversionValue + "total = ",
                amount
              );
              this.setState({
                amount: amount.toFixed(2),
                [event.target.id]: value,
              });
            } else {
              this.basicAlert(
                "Entered Quantity should not be greater than available Quantity"
              );
            }
          }
        } else {
          var Calrate = this.state.rate;

          // var amount = parseFloat(Calrate) * parseFloat(value);
          var cnt_qty = parseFloat(value * this.state.conversionValue).toFixed(
            3
          );
          var amount = parseFloat(Calrate) * cnt_qty;

          this.setState({
            amount: amount.toFixed(2),
            [event.target.id]: value,
          });
        }
        if (this.state.taxPercentage > 0 && this.state.taxPercentage) {
          var itemtaxAmount =
            (parseFloat(amount) * parseFloat(this.state.taxPercentage)) / 100;
          this.setState({
            itemTaxAmt: itemtaxAmount,
          });
        }
        // for uom2 quantity
        // if (this.state.uom2conversionValue !== undefined) {
        //   var uomQuantityCal1 = value * this.state.uom2conversionValue;
        //   this.setState({
        //     uomQuantity1: uomQuantityCal1,
        //   });
        //   var uomQuantityCal1Amount;
        //   uomQuantityCal1Amount = parseFloat(
        //     this.state.amount / this.state.uomQuantity1
        //   ).toFixed(2);
        //   this.setState({
        //     uom2Amount: uomQuantityCal1Amount,
        //   });
        // }
        // for uom3 quantity
        // if (this.state.uom3conversionValue !== undefined) {
        //   var uomQuantityCal2 = value * this.state.uom3conversionValue;
        //   this.setState({
        //     uomQuantity2: uomQuantityCal2,
        //   });
        //   var uomQuantityCal2Amount;
        //   uomQuantityCal2Amount = parseFloat(
        //     this.state.amount / this.state.uomQuantity2
        //   ).toFixed(2);
        //   this.setState({
        //     uom3Amount: uomQuantityCal2Amount,
        //   });
        // }
      } else {
        this.setState({
          // amount: 0,
          [event.target.id]: value,
        });
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };
  handleCustomer = (resp) => {
    var addressList = [];
    if (resp.customerLineitems) {
      resp.customerLineitems.forEach((prop) => {
        var obj = {
          label: prop.address,
          value: prop.customerLineId,
          name:
            prop.address +
            "^" +
            prop.state +
            "^" +
            prop.stateCode +
            "^" +
            prop.gstNo,
        };
        addressList.push(obj);
      });
    }

    if (addressList.length === 0) {
      this.setState({
        gstNo: "",
        gstNo2: "",
        billingStateCode: "",
        shippingStateCode: "",
        billingStateName: "",
        shippingStateName: "",
        billingAddress: "",
        shippingAddress: "",
      });
    } else {
      var taxTypes = getConfigValue(configNames.TAX_TYPES);
      if (this.state.taxType === "") {
        if (taxTypes !== "1") {
          taxTypes = JSON.parse(taxTypes);
          var taxtype = { value: 2, label: "CGST & SGST", name: "CGST & SGST" };

          if (taxTypes.gst === 1) {
            if (
              getCookie(cons.COMPANY_STATECODE) ==
                resp.customerLineitems[0].stateCode + "" &&
              resp.customerLineitems[0].stateCode !== null
            ) {
              this.setState({ taxType: taxtype });
              this.handleSelectedUnitValue(taxtype);
            } else {
              taxtype = { value: 1, label: "IGST", name: "IGST" };
              this.setState({ taxType: taxtype });
              this.handleSelectedUnitValue(taxtype);
            }
          }
        }
      }

      // if (
      //   this.state.billingAddress === "" &&
      //   this.state.shippingAddress === ""
      // ) {
      this.setState({
        gstNo: resp.customerLineitems[0].gstNo,
        gstNo2: resp.customerLineitems[0].gstNo,
        billingStateCode: resp.customerLineitems[0].stateCode,
        shippingStateCode: resp.customerLineitems[0].stateCode,
        billingStateName: resp.customerLineitems[0].state,
        shippingStateName: resp.customerLineitems[0].state,
        billingAddress: resp.customerLineitems[0].address,
        shippingAddress: resp.customerLineitems[0].address,
        billingTo: resp.customerLineitems[0].customerLineId,
        shippingTo: resp.customerLineitems[0].customerLineId,
      });
      // }
    }
    this.setState({ customerAddressList: addressList, loadAddress: true });
  };
  // getUomConversion = (fromUom, toUom, stateKey) => {
  //   this.setState({
  //     uomStateKey: stateKey,
  //   });
  //   getDataFromUrl(
  //     serverApi.UOM_CONVERSION_DATA +
  //       this.props.state.companyInfo.id +
  //       "/fromUom/" +
  //       fromUom +
  //       "/toUom/" +
  //       toUom,
  //     this.handleUomConversion
  //   );
  // };

  // handleUomConversion = (resp) => {
  //   var uomStateKey = this.state.uomStateKey;

  //   var conversionValue = resp.relationValue;
  //   var uomAmount;

  //   if (uomStateKey == "uom" || uomStateKey == "uomRate") {
  //     uomAmount = this.state.rate * this.state.quantity * conversionValue;
  //     uomAmount = parseFloat(uomAmount).toFixed(2);
  //     this.setState({
  //       amount: uomAmount,
  //       uomStateKey: 0,
  //       conversionValue: conversionValue,
  //     });
  //   } else if (uomStateKey == "uom2") {
  //     var uomQuantityCal1;
  //     var uomQuantityCal1Amount;

  //     this.setState({
  //       uom2conversionValue: conversionValue,
  //     });

  //     if (conversionValue !== 0) {
  //       uomQuantityCal1 = this.state.quantity * this.state.uom2conversionValue;
  //       uomQuantityCal1 = parseFloat(uomQuantityCal1).toFixed(2);
  //       this.setState({
  //         uomQuantity1: uomQuantityCal1,
  //       });
  //       uomQuantityCal1Amount = parseFloat(
  //         this.state.amount / this.state.uomQuantity1
  //       ).toFixed(2);
  //       this.setState({
  //         uom2Amount: uomQuantityCal1Amount,
  //       });
  //     } else {
  //       this.basicAlert("Converting to selected UOM not Possible");
  //       this.setState({
  //         uom2: "",
  //         uomQuantity1: 0,
  //         uom2Amount: 0,
  //       });
  //     }
  //   } else if (uomStateKey == "uom3") {
  //     var uomQuantityCal2;
  //     var uomQuantityCal2Amount;
  //     var uom3conversionValue;
  //     this.setState({
  //       uom3conversionValue: conversionValue,
  //     });
  //     if (conversionValue !== 0) {
  //       uomQuantityCal2 = this.state.quantity * this.state.uom3conversionValue;
  //       uomQuantityCal2 = parseFloat(uomQuantityCal2).toFixed(2);
  //       this.setState({
  //         uomQuantity2: uomQuantityCal2,
  //       });
  //       uomQuantityCal2Amount = parseFloat(
  //         this.state.amount / this.state.uomQuantity2
  //       ).toFixed(2);
  //       this.setState({
  //         uom3Amount: uomQuantityCal2Amount,
  //       });
  //     } else {
  //       this.basicAlert("Converting to Selected UOM not Possible");
  //       this.setState({
  //         uom3: "",
  //         uomQuantity2: 0,
  //         uom3Amount: 0,
  //       });
  //     }
  //   }
  // };requestUomSimple
  // handleUomConversion2 = (
  //   groupCode,
  //   itemId,
  //   priceUom,
  //   quantityUom,
  //   itemPrice,
  //   itemQuantity,
  //   selectedUom2,
  //   selectedUom3
  // ) => {
  //   console.log("Nagesh ---> Quantity---> handleUomConversion2", itemQuantity);
  //   //Convertion Price and Quantity
  //   if (quantityUom != undefined && priceUom != undefined) {
  //     var data = {
  //       groupCode: groupCode,
  //       itemCode: itemId,
  //       fromUom: quantityUom,
  //       toUom: priceUom,
  //       companyID: this.props.state.companyInfo.id,
  //     };
  //     requestUomSimple(
  //       serverApi.UOM_CONVERSION_DATA,
  //       data,
  //       this.handleUomConversionOne,
  //       groupCode,
  //       itemId,
  //       priceUom,
  //       quantityUom,
  //       itemPrice,
  //       itemQuantity,
  //       selectedUom2,
  //       selectedUom3
  //     );
  //     // getDataFromUrlSimple(
  //     //   serverApi.UOM_CONVERSION_DATA +
  //     //     this.props.state.companyInfo.id +
  //     //     "/fromUom/" +
  //     //     quantityUom +
  //     //     "/toUom/" +
  //     //     priceUom,
  //     //   this.handleUomConversionOne,
  //     //   itemId,
  //     //   priceUom,
  //     //   quantityUom,
  //     //   itemPrice,
  //     //   itemQuantity,
  //     //   selectedUom2,
  //     //   selectedUom3
  //     // );
  //   }

  //   //Convertion Quantity and Uom2
  //   if (quantityUom != undefined && selectedUom2 != undefined) {
  //     var data = {
  //       groupCode: groupCode,
  //       itemCode: itemId,
  //       fromUom: quantityUom,
  //       toUom: selectedUom2,
  //       companyID: this.props.state.companyInfo.id,
  //     };
  //     requestUomSimple(
  //       serverApi.UOM_CONVERSION_DATA,
  //       data,
  //       this.handleUomConversionTwo,
  //       groupCode,
  //       itemId,
  //       priceUom,
  //       quantityUom,
  //       itemPrice,
  //       itemQuantity,
  //       selectedUom2,
  //       selectedUom3
  //     );
  //   }

  //   //Convertion Quantity and Uom3
  //   if (quantityUom != undefined && selectedUom3 != undefined) {
  //     var data = {
  //       groupCode: groupCode,
  //       itemCode: itemId,
  //       fromUom: quantityUom,
  //       toUom: selectedUom3,
  //       companyID: this.props.state.companyInfo.id,
  //     };
  //     requestUomSimple(
  //       serverApi.UOM_CONVERSION_DATA,
  //       data,
  //       this.handleUomConversionThree,
  //       groupCode,
  //       itemId,
  //       priceUom,
  //       quantityUom,
  //       itemPrice,
  //       itemQuantity,
  //       selectedUom2,
  //       selectedUom3
  //     );
  //   }
  // };

  handleUomConversion2 = (
    groupCode,
    itemId,
    priceUom,
    quantityUom,
    itemPrice,
    itemQuantity,
    selectedUom2,
    selectedUom3
  ) => {
    console.log("Nagesh ---> Quantity---> handleUomConversion2", itemQuantity);
    //Convertion Price and Quantity
    if (quantityUom != undefined && priceUom != undefined) {
      var data = {
        groupCode: groupCode,
        itemCode: itemId,
        fromUom: quantityUom,
        toUom: priceUom,
        companyID: this.props.state.companyInfo.id,
      };
      requestUomSimple(
        serverApi.UOM_CONVERSION_DATA,
        data,
        this.handleUomConversionOne,
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
      // getDataFromUrlSimple(
      //   serverApi.UOM_CONVERSION_DATA +
      //     this.props.state.companyInfo.id +
      //     "/fromUom/" +
      //     quantityUom +
      //     "/toUom/" +
      //     priceUom,
      //   this.handleUomConversionOne,
      //   itemId,
      //   priceUom,
      //   quantityUom,
      //   itemPrice,
      //   itemQuantity,
      //   selectedUom2,
      //   selectedUom3
      // );
    }

    //Convertion Quantity and Uom2
    if (quantityUom != undefined && selectedUom2 != undefined) {
      var data = {
        groupCode: groupCode,
        itemCode: itemId,
        fromUom: quantityUom,
        toUom: selectedUom2,
        companyID: this.props.state.companyInfo.id,
      };
      requestUomSimple(
        serverApi.UOM_CONVERSION_DATA,
        data,
        this.handleUomConversionTwo,
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }

    //Convertion Quantity and Uom3
    if (quantityUom != undefined && selectedUom3 != undefined) {
      var data = {
        groupCode: groupCode,
        itemCode: itemId,
        fromUom: quantityUom,
        toUom: selectedUom3,
        companyID: this.props.state.companyInfo.id,
      };
      requestUomSimple(
        serverApi.UOM_CONVERSION_DATA,
        data,
        this.handleUomConversionThree,
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }
  };

  handleUomConversionOne = (
    resp,
    groupCode,
    itemId,
    priceUom,
    quantityUom,
    itemPrice,
    itemQuantity,
    selectedUom2,
    selectedUom3
  ) => {
    if (resp) {
      console.log(
        "getting response from handleUomConversionOne",
        resp.relationValue
      );
      // var cnt_qty = parseFloat(itemQuantity * resp.relationValuey).toFixed(3);
      // var itemAmount = resp.relationValue === 0 ? cnt_qty : cnt_qty * itemPrice; // amount caluclation with price * quantity * relationvalue
      var conv_qty = parseFloat(itemQuantity * resp.relationValue).toFixed(3);
      var itemAmount = itemPrice * conv_qty;
      console.log("getting response from handleUomConversionOne", conv_qty);
      this.setState({
        conversionValue: resp.relationValue,
        itemAmount1: itemAmount,
        amount: itemAmount.toFixed(2),
      });
    } else {
      this.setState({
        conversionValue: resp.relationValue,
      });
    }
  };

  // handleUomConversionOne = (
  //   resp,
  //   groupCode,
  //   itemId,
  //   priceUom,
  //   quantityUom,
  //   itemPrice,
  //   itemQuantity,
  //   selectedUom2,
  //   selectedUom3
  // ) => {
  //   if (resp) {
  //     console.log("Nagesh UOM Conversion One", resp);
  //     var conv_qty = parseFloat(itemQuantity * resp.relationValue).toFixed(3);
  //     var itemAmount = itemPrice * conv_qty; // amount caluclation with price * quantity * relationvalue

  //     console.log("Nagesh itemPrice", itemPrice);
  //     console.log("Nagesh itemQuantity", itemQuantity);

  //     console.log("Nagesh conversionValue", resp.relationValue);
  //     console.log("Nagesh itemAmount", itemAmount);
  //     console.log("Nagesh itemAmount", itemAmount);
  //     this.setState({
  //       conversionValue: resp.relationValue,
  //       itemAmount1: itemAmount,
  //       amount: itemAmount.toFixed(2),
  //     });
  //   } else {
  //     this.setState({
  //       conversionValue: resp.relationValue,
  //     });
  //   }
  // };
  // handleUomConversionTwo = (
  //   resp,
  //   groupCode,
  //   itemnId,
  //   priceUom,
  //   quantityUom,
  //   itemPrice,
  //   itemQuantity,
  //   selectedUom2,
  //   selectedUom3
  // ) => {
  //   if (resp) {
  //     console.log("Nagesh UOM Conversion Two", resp);
  //     var uom2Quantity = itemQuantity * resp.relationValue; // uom2 caluclation with original qty * relationvalue
  //     var uom2Rate = this.state.itemAmount1 / uom2Quantity; // total amount / uom2quantity
  //     console.log(
  //       "getting converted itemQty",
  //       itemQuantity + "getting converted conversion",
  //       resp.relationValue
  //     );
  //     console.log("getting qty 2", uom2Quantity);
  //     this.setState({
  //       uom2conversionValue: resp.relationValue,
  //       uomQuantity1: uom2Quantity.toFixed(3),
  //       uom2Amount: uom2Rate.toFixed(3),
  //     });
  //   } else {
  //     this.setState({
  //       uom2conversionValue: resp.relationValue,
  //     });
  //   }
  // };
  handleUomConversionTwo = (
    resp,
    groupCode,
    itemnId,
    priceUom,
    quantityUom,
    itemPrice,
    itemQuantity,
    selectedUom2,
    selectedUom3
  ) => {
    if (resp) {
      var uom2Quantity = itemQuantity * resp.relationValue; // uom2 caluclation with original qty * relationvalue
      var uom2Rate = this.state.itemAmount1 / uom2Quantity; // total amount / uom2quantity
      this.setState({
        uom2conversionValue: resp.relationValue,
        uomQuantity1: uom2Quantity.toFixed(3),
        uom2Amount: uom2Rate.toFixed(2),
      });
    } else {
      this.setState({
        uom2conversionValue: resp.relationValue,
      });
    }
  };
  // handleUomConversionThree = (
  //   resp,
  //   groupCode,
  //   itemId,
  //   priceUom,
  //   quantityUom,
  //   itemPrice,
  //   itemQuantity,
  //   selectedUom2,
  //   selectedUom3
  // ) => {
  //   if (resp) {
  //     console.log("Nagesh UOM Conversion Three", resp);
  //     var uom3Quantity = itemQuantity * resp.relationValue; // uom2 caluclation with original qty * relationvalue
  //     var uom3Rate = this.state.itemAmount1 / uom3Quantity; // total amount / uom3quantity
  //     console.log(
  //       "getting itemQtyssss ",
  //       itemQuantity + " getting conversion value",
  //       resp.relationValue + " total = ",
  //       uom3Quantity
  //     );
  //     // console.log("getting uom 3 conversion", uom3Quantity);
  //     this.setState({
  //       uom3conversionValue: resp.relationValue,
  //       uomQuantity2: uom3Quantity.toFixed(3),
  //       uom3Amount: uom3Rate.toFixed(2),
  //     });
  //   } else {
  //     this.setState({
  //       uom3conversionValue: resp.relationValue,
  //     });
  //   }
  // };
  handleUomConversionThree = (
    resp,
    groupCode,
    itemId,
    priceUom,
    quantityUom,
    itemPrice,
    itemQuantity,
    selectedUom2,
    selectedUom3
  ) => {
    if (resp) {
      var uom3Quantity = itemQuantity * resp.relationValue; // uom2 caluclation with original qty * relationvalue
      var uom3Rate = this.state.itemAmount1 / uom3Quantity; // total amount / uom3quantity
      this.setState({
        uom3conversionValue: resp.relationValue,
        uomQuantity2: uom3Quantity.toFixed(3),
        uom3Amount: uom3Rate.toFixed(2),
      });
    } else {
      this.setState({
        uom3conversionValue: resp.relationValue,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;

    this.setState({
      selectedValueID: selectedValue,
    });

    if (stateKey === "customer") {
      getDataFromUrl(
        serverApi.CUSTOMER_MASTER_DETAILS_BY_ID +
          selectedValue +
          "/" +
          getCookie(cons.CIPHER),
        this.handleCustomer
      );
    }

    if (
      stateKey === "destinationMr" &&
      selectedValue !== this.state.destinationMr
    ) {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getMaterialGoodReceiveDetailsById/" +
          selectedValue +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleDMrClickResp
      );
    }
    if (stateKey === "saleOrderType") {
      if (selectedValue === 2 || selectedValue === 3) {
        this.setState({ typeOfSale: "Sale Of Material", loadSaleType: true });
      }
    }
    if (stateKey === "itemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "Y",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
        // finshedSaleable: this.state.invoiceTyp === "3" ? "Y" : "N",
      };
      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);

      getDataFromUrl(
        serverApi.SEARCH_ITEM_GROUPS +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.CIPHER),
        this.handleId
      );
    }
    if (stateKey === "tcsPercentage") {
      console.log("tcsPercentage11", selectedValue);
      if (selectedValue) {
        var total =
          parseFloat(this.getTotalTax("cgst")) +
          parseFloat(this.getTotalTax("sgst")) +
          parseFloat(this.getTotalTax("igst")) +
          parseFloat(this.state.totalAmount) -
          parseFloat(this.getTotalTax("claim"));
        var tcsAmount = (parseFloat(total) * selectedValue) / 100;
        this.setState({ tcsAmount: Math.round(tcsAmount).toFixed(2) });
      }
    }

    if (stateKey === "suppielr_id") {
      this.setState({
        loadGateEntry: true,
      });
    }
    if (stateKey === "itemCode") {
      if (this.state.itemGroup === "999" || this.state.invoiceTyp === "3") {
        getDataFromUrl(
          serverApi.DATATABLE_SERVICE_URL +
            selectedValue +
            "/" +
            this.props.state.companyInfo.id +
            "/getQualityByItem",
          this.handleQualities
        );
      }
      var groupCode = this.state.itemGroup;
      var itemId = selectedValue;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown

      // this.setState({ uom: selectedValue });
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }
    if (
      stateKey === "selectedJuteType" &&
      selectedValue > 0 &&
      selectedValue !== type
    ) {
      this.setState({
        hideJuteType: true,
      });
    }
    if (stateKey === "mrNo" && !this.state.updateView) {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "getMaterialGoodReceiveDetailsById/" +
          selectedValue +
          "/" +
          getCookie(cons.USERID) +
          "/" +
          getCookie(cons.CIPHER),
        this.handleMrClickResp
      );
    }
    if (
      stateKey === "itemName" &&
      this.state.printingPop &&
      this.state.invoiceTyp === "3" &&
      (selectedValue === "Printing Charges" ||
        selectedValue === "Second Stage Handling Charges")
    ) {
      var qty = 0;
      this.state.invoiceList.forEach((prop) => {
        if (prop.itemCode !== "" && prop.itemCode !== null)
          qty = qty + parseFloat(prop.quantity);
      });
      this.setState({ quantity: qty });
    }
    if (stateKey === "rateType") {
      var rate = 0;
      if (selectedValue === 1) {
        rate = parseFloat(getConfigValue(configNames.RAIL_RATE)) / 1000;
        console.log("rrrr", getConfigValue(configNames.RAIL_RATE));
        var amount = parseFloat(this.getWeight(3)) * rate;
        this.setState({ rate: rate.toFixed(2), amount: amount.toFixed(2) });
      }
      if (selectedValue === 2) {
        rate = parseFloat(getConfigValue(configNames.CONTAINER_RATE)) / 1000;
        var amount = parseFloat(this.getWeight(3)) * rate;
        this.setState({ rate: rate.toFixed(2), amount: amount.toFixed(2) });
      }
    }
    if (stateKey === "invoiceType") {
      if (selectedValue === "2" || selectedValue === "3") {
        this.setState({ typeOfSale: "Sale Of Material", loadSaleType: true });
      }

      if (selectedValue === "2") {
        this.getMRList();
        this.setState({ itemGroup: "999", itemGroupName: "JUTE" });
        var data = {
          itemGroupId: "999",
          saleable: "Y",
          consumable: "",
          companyId: this.props.state.companyInfo.id,
        };

        requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
      } else {
        if (selectedValue === "2") {
          this.setState({
            mrNo: "",
            invoiceList: [],
            mrList: [],
            loadMr: true,
          });
        }
      }
      this.setState({
        invoiceTyp: selectedValue,
      });
    }
    if (stateKey === "saleNo") {
      getDataFromUrl(
        serverApi.GET_SALE_ORDER_BY_ID +
          selectedValue +
          "/" +
          getCookie(cons.USERID),
        this.handleViewBySaleOrder
      );
    }
    if (stateKey === "billingAddress") {
      // this.setState({ billingTo: selectedValue });
    }
    if (stateKey === "transporterId") {
      // this.setState({ billingTo: selectedValue });
    }
    if (stateKey === "taxId") {
      // alert(selectedValue);
      // this.setState({ billingTo: selectedValue });
    }
    // if (stateKey == "uom") {
    //   this.setState({ uom: selectedValue });
    //   this.getUomConversion(selectedValue, this.state.uomRate, stateKey);
    // }
    // if (stateKey === "uomRate") {
    //   this.setState({
    //     uomRate: selectedValue,
    //   });
    //   this.getUomConversion(this.state.uom, selectedValue, stateKey);
    // }
    // if (stateKey === "uom2") {
    //   this.setState({
    //     uom2: selectedValue,
    //   });
    //   this.getUomConversion(selectedValue, this.state.uomRate, stateKey);
    // }
    // if (stateKey === "uom3") {
    //   this.setState({
    //     uom3: selectedValue,
    //   });
    //   this.getUomConversion(selectedValue, this.state.uomRate, stateKey);
    // }

    if (stateKey == "uom") {
      // Price UOM Selection
      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = selectedValue; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown

      this.setState({ uom: selectedValue });
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }
    if (stateKey === "uomRate") {
      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = selectedValue; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown
      console.log("Nagesh ---> Quantity", this.state.quantity);
      console.log("Nagesh ---> Quantity--->", itemQuantity);

      this.setState({ uomRate: selectedValue });
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }

    if (stateKey === "uom2") {
      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = selectedValue; // UOM 2 Select Dropdown
      var selectedUom3 = this.state.uom3; // UOM 3 Select Dropdown

      this.setState({ uom2: selectedValue });
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }
    if (stateKey === "uom3") {
      var groupCode = this.state.itemGroup;
      var itemId = this.state.itemCode;
      var priceUom = this.state.uom; // Defualt (UOM From Item Master) Price UOM Select Dropdon
      var quantityUom = this.state.uomRate; // Quantity UOM Select Dropdown
      var itemPrice = this.state.rate; // Price From Popup Window
      var itemQuantity = this.state.quantity; // Quantity from Popup window
      var selectedUom2 = this.state.uom2; // UOM 2 Select Dropdown
      var selectedUom3 = selectedValue; // UOM 3 Select Dropdown

      this.setState({ uom3: selectedValue });
      this.handleUomConversion2(
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleQualities = (resp) => {
    if (resp) {
      this.setState({ qualityList: resp, loadquality: true });
    } else {
      this.setState({ qualityList: [], loadquality: true });
    }
  };
  handleMrClickResp = (resp) => {
    console.log("MRRRRR", JSON.stringify(resp));
    var header = resp.materialGoodReceiveHeader,
      list = resp.materialGRItemList,
      unitConversion = "",
      claimAmount = 0;
    if (header) {
      unitConversion = header.unitConversion;
      if (list !== null)
        var indentlist = [],
          totalAmount = 0;
      list.forEach((prop, key) => {
        var itemAmount = 0;
        var taxPercentage = 0;
        var remainingBale =
          prop.actualBale - prop.balesConsumed - prop.salesBale;
        var remainingLoose =
          prop.actualLoose - prop.drumConsumed - prop.salesDrum;
        var remainingWeight =
          prop.acceptedWeight / 100 -
          prop.stockQuantityConsumed -
          prop.salesWeight;
        var claim = parseFloat(
          ((prop.acceptedWeight / 100) * prop.claimRate).toFixed(2)
        );
        claimAmount = claimAmount + claim;

        itemAmount = prop.rate * remainingWeight;

        totalAmount = totalAmount + itemAmount;
        var obj = {
          id: key,
          itemCode: prop.itemId + "",
          itemName: prop.itemDesc,
          itemGrp: "999" + prop.itemId,
          qualityId: prop.actualQuality,
          qualityName: prop.qualityDesc,
          description: "",
          quantity: getDecimal(
            remainingWeight
          ) /*unitConversion==="LOOSE"?remainingLoose:remainingBale*/,
          uom: prop.quantityUnit,
          rate: getDecimal(prop.rate),
          hsnCode: "",
          amount: getDecimal(itemAmount),
          mrLineId: prop.id,
          isgst: 0,
          ssgst: 0,
          csgst: 0,
          taxId: 0,
          make: "",
          taxPercentage: 0,
          taxamount: 0,
          isActive: 1,
          itemGroup: "999",
          itemGroupName: "JUTE",
          bales: "",
          packingWithIdentificationNo: "",
          balesSrlNo: "",
          costFactor: "",
          costFactorDes: "",
          claimDesc: "",
          saleDrums: getDecimal(remainingLoose),
          saleBales: getDecimal(remainingBale),
          saleWeight: getDecimal(remainingWeight),
          acceptedWeight: prop.acceptedWeight / 100,
          claimRate: prop.claimRate,
          claimAmountDtl: claim,
        };
        if (unitConversion === "LOOSE") {
          if (remainingLoose > 0) {
            indentlist.push(obj);
          }
        } else {
          if (remainingBale > 0) {
            indentlist.push(obj);
          }
        }
      });
      if (header.unitConversion === "BALE") {
        var totalAvailable = 0;
        var totalConsumed = 0;
        var totalSales = 0;
        list.forEach((prop, key) => {
          totalAvailable = totalAvailable + prop.actualBale;
          totalConsumed = totalConsumed + prop.balesConsumed;
          totalSales = totalSales + prop.salesBale;
        });
        if (totalConsumed === 0 && totalSales === 0) {
          this.setState({
            challanDate: header.chalanDateDesc,
            challanNo: header.challanNo,
            brokerName: header.brokerName,
            vehicleNo: header.vehicleNo,
            supplierName: header.supplierName,
            unitConversion: header.unitConversion,
            loadUnit: true,
            invoiceList: indentlist,
            totalAmount: totalAmount,
            netTotal: totalAmount,
            mrLineList: indentlist,
            disableUnit: true,
          });
        } else {
          this.setState({
            challanDate: "",
            challanNo: "",
            brokerName: "",
            vehicleNo: "",
            supplierName: "",
            unitConversion: "",
            loadUnit: true,
            invoiceList: [],
            totalAmount: 0.0,
            netTotal: 0.0,
            mrLineList: [],
            disableUnit: false,
          });
          this.basicAlert("MR is Consumed we can not raise invoice");
        }
      }
      if (header.unitConversion === "LOOSE") {
        var totalAvailable = 0;
        var totalConsumed = 0;
        var totalSales = 0;
        list.forEach((prop, key) => {
          totalAvailable = totalAvailable + prop.actualLoose;
          totalConsumed = totalConsumed + prop.drumConsumed;
          totalSales = totalSales + prop.salesDrum;
        });
        if (totalConsumed === 0 && totalSales === 0) {
          this.setState({
            challanDate: header.chalanDateDesc,
            challanNo: header.challanNo,
            brokerName: header.brokerName,
            vehicleNo: header.vehicleNo,
            supplierName: header.supplierName,
            unitConversion: header.unitConversion,
            loadUnit: true,
            invoiceList: indentlist,
            totalAmount: totalAmount,
            netTotal: totalAmount,
            mrLineList: indentlist,
            disableUnit: true,
          });
        } else {
          this.setState({
            challanDate: "",
            challanNo: "",
            brokerName: "",
            vehicleNo: "",
            supplierName: "",
            unitConversion: "",
            loadUnit: true,
            invoiceList: [],
            totalAmount: 0.0,
            netTotal: 0.0,
            mrLineList: [],
            disableUnit: false,
          });
          this.basicAlert("MR is Consumed we can not raise invoice");
        }
      }
    }
  };
  handleDMrClickResp = (resp) => {
    console.log("MRRRRR111", JSON.stringify(resp));
    var header = resp.materialGoodReceiveHeader,
      list = resp.materialGRItemList,
      unitConversion = "",
      indentlist = [];
    if (header) {
      unitConversion = header.unitConversion;
      if (list !== null) {
        this.state.invoiceList.forEach((prop) => {
          list.forEach((o) => {
            if (
              prop.itemName === o.itemDesc &&
              prop.qualityName === o.qualityDesc
            ) {
              var obj = { ...prop, destinationMrLine: o.id };
              indentlist.push(obj);
            }
          });
        });
      }
      if (indentlist.length > 0) {
        this.setState({ invoiceList: indentlist });
      } else {
        this.setState({ destinationMr: "", loadDMR: true });
        this.basicAlert("Destination MR not matched with selected MR");
      }
    }
  };

  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterRefreshGateEntry = (val) => {
    this.setState({
      loadGateEntry: val,
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      availqty: "",
      saleLineId: "",
      saleLineItemId: "",
      saleLineitemId: "",
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      quantity: "",
      itemCode: "",
      itemName: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      make: "",
      itemGroup: this.state.invoiceTyp === "2" ? "999" : "",
      itemGroupName: this.state.invoiceTyp === "2" ? "JUTE" : "",
      taxPercentage: "",
      itemTaxAmt: "",
      igstPercent: "",
      cgstPercent: "",
      sgstPercent: "",
      bales: "",
      packingWithIdentificationNo: "",
      printingPop: false,
      balesSrlNo: "",
      id: "",
      updateItem: false,
      lineItemId: "",
      costFactor: "",
      costFactorDes: "",
      claimDesc: "",
      claimAmountDtl: "",
      saleDrums: "",
      saleBales: "",
      saleWeight: "",
      qualityId: "",
      qualityName: "",
      destinationMrLine: "",
      acceptedWeight: "",
      claimRate: "",
      rateType: "",
    });
  }

  //log

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getInvoiceTableList();
    }
    this.setState({
      salesOrderNo: "",
      salesNoString: "",
      administartiveOffice: "",
      administrativeOfficeAddress: "",
      factoryAddress: "",
      factoryOffice: "",
      packagingType: "",
      branch: "",
      branchId: "",
      deliveryDate: "",
      invoiceType: "",
      billPassStatus: "",
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      invoiceStatus: "",
      sumOfamt: "",
      createdBy: "",
      createdOn: "",
      invoiceList: [],
      totalAmount: "",
      form_supplierId: "",
      billPassDate: moment().format("DD-MM-YYYY"),
      taxType: "",
      netTotal: 0,
      invoiceNo: "",
      invoiceDate: "",
      paymentDueDate: "",
      invoiceAmount: "",
      invoiceReceivedDate: "",
      salesNoString: "",
      supplierName: "",
      costCenterId: "",
      costCenter: "",
      taxId: "",
      uom: "",
      amount: "",
      rate: "",
      description: "",
      make: "",
      freightCharges: "",
      footerNotes: "",
      terms: "",
      termsConditions: "",
      customer: "",
      customerName: "",
      billingAddress: "",
      shippingAddress: "",
      quoteDate: "",
      expireDate: "",
      challanDate: "",
      challanNo: "",
      billingStateCode: "",
      shippingStateCode: "",
      saleNo: "",
      delOrderNo: "",
      vehicleNo: "",
      invoiceTypeId: "",
      quoteNoString: "",
      quoteId: "",
      delOrderDate: "",
      typeOfSale: "",
      payableTax: "N",
      saleOrderDate: "",
      tdsPayable: "N",
      tdsReason: "",
      tdsAmount: "",
      shippingStateName: "",
      brokerName: "",
      pcsoNo: "",
      dateOfRemovalOfGoods: "",
      administrativeOfficeAddress: "",
      factoryAddress: "",
      destinationRailHead: "",
      transporterId: 0,
      transporterName: "",
      transporterAddress: "",
      transporterStateCode: "",
      transporterStateName: "",
      containerNo: "",
      netWeight: 0,
      packSheet: 0,
      totalWeight: 0,
      loadingPoint: "",
      contractNo: "",
      contractDate: "",
      pcsoDate: "",
      time: "",
      time2: "",
      branchId: "",
      isShipping: false,
      mrNo: "",
      claimAmount: "",
      tcsPercentage: "",
      tcsAmount: 0,
      otherReference: "",
      ewayBillDate: "",
      ewayBillNo: "",
      destination: "",
      mrLineId: "",
      saleOrderType: "",
      internalCompanyId: "",
      destinationMrList: [],
      destinationMr: "",
      invoiceTyp: "",
      admBranchId: "",
      facBranchId: "",
      qualityList: [],
      unitConversion: "",
      finalStatus: "N",
      roundoff: null,
      isFromSaleOrder: false,
      disableUnit: false,
      isBill: false,
      billId: "",
      invoicePdf: "",
      availbleQty: "",
      availqty: "",
      customerAddressList: [],
      gstNo2: "",
      gstNo: "",
      taxPercentage: 0,
      taxPayable: "Y",
      billingStateName: "",
      status: "",
      amendmentNo1: "",
      amendmentNo2: "",
      amendmentNo3: "",
      amendmentNo4: "",
      amendmentDate1: "",
      amendmentDate2: "",
      amendmentDate3: "",
      amendmentDate4: "",
    });

    var invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
    if (invoicingModel === "1") {
      this.setState({ invoiceTyp: "1", loadInvoiceType: true });
    } else if (invoicingModel === "2") {
      this.setState({ invoiceTyp: "2", loadInvoiceType: true });
    } else {
      invoicingModel = JSON.parse(invoicingModel);
      if (invoicingModel[0].value === "3")
        this.setState({ typeOfSale: "Sale Of Material", loadSaleType: true });

      this.setState({
        invoiceTyp: invoicingModel[0].value,
        loadInvoiceType: true,
      });
    }
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  handleId = (resp, select) => {
    var list = [];
    resp.forEach((prop) => {
      if (prop.value === this.state.selectedValueID) {
        this.setState({
          item: prop.id,
        });
      }
    });
  };

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        saleOrderDate,
        transporterId,
        facBranchId,
        admBranchId,
        billingStateCode,
        shippingStateCode,
        saleNo,
        invoiceList,
        branchId,
        deliveryDate,
        invoiceType,
        customer,
        taxPayable,
        taxType,
        packagingType,
        salesOrderNo,
        createdDate,
        createdBy,
        // status,
        factoryOffice,
        isActive,
        companyId,
        totalQuantity,
        deliveryOrderNo,
        billingTo,
        shippingTo,

        amendmentNo1,
        amendmentNo2,
        amendmentNo3,
        amendmentNo4,

        amendmentDate1,
        amendmentDate2,
        amendmentDate3,
        amendmentDate4,
        deliveryOrderSeq,
      } = this.state;

      // console("updated Payload List",invoiceList);

      var invoiceList = invoiceList.map((prop, key) => {
        console.log("updated Payload Single Item", prop);
        var claim = 0;
        if (prop.claimAmountDtl !== "" && prop.claimAmountDtl !== null) {
          claim = parseFloat(prop.claimAmountDtl);
        }
        var cgstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.csgst)) / 100,
          sgstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.ssgst)) / 100,
          igstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.isgst)) / 100;
        var invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
        console.log("item printing", this.state.item);

        return {
          deliveryOrderId: deliveryOrderNo,
          lineItemId: prop.lineItemId,
          itemCode: prop.itemCode, //this.state.item === undefined ? prop.item : this.state.item,
          itemGroup: prop.itemGroup,
          description: prop.itemName,
          hsnCode: prop.hsnCode,
          itemRate: prop.rate,
          quantity: prop.quantity,
          // noOfBales: prop.bales,
          noOfBales: 0,
          make: prop.make,
          amount: parseFloat(prop.amount),
          taxId: prop.taxId,
          remarks: prop.description,
          updatedDate: moment().format("YYYY-MM-DD"),
          updatedBy: getCookie(cons.USERID),
          status: status,
          isActive: prop.isActive,
          uom: prop.uom,
          uomRate: prop.uomRate,
          uom2: prop.uom2,
          uom3: prop.uom3,
          quantity2: prop.uomQuantity1,
          quantity3: prop.uomQuantity2,
          companyId: this.props.state.companyInfo.id,
          saleLineItemId:
            prop.saleLineItemId == "undefined" ? 0 : prop.saleLineItemId,
        };
      });
      let saleDate = moment(saleOrderDate, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      let dlvryDate = moment(deliveryDate, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      let amD1 = moment(amendmentDate1, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD2 = moment(amendmentDate2, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD3 = moment(amendmentDate3, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD4 = moment(amendmentDate4, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      var data = {
        hdr: {
          deliveryOrderNo: deliveryOrderNo,
          deliveryOrderSeq: deliveryOrderSeq,
          deliveryDate: dlvryDate,
          invoiceType: invoiceType,
          branch: branchId,
          customer: customer,
          billingTo: billingTo,
          shippingTo: shippingTo,
          taxPayable: taxPayable,
          taxType: taxType.value,
          salesOrderNo: salesOrderNo,
          salesOrderDate: saleDate,
          administartiveOffice: admBranchId,
          factoryOffice: facBranchId,
          transporterId: transporterId === 0 ? "" : transporterId,
          packagingType: packagingType,
          updatedDate: moment().format("YYYY-MM-DD"),
          updatedBy: getCookie(cons.USERID),
          status: status,
          isActive: 1,
          companyId: this.props.state.companyInfo.id,
          totalQuantity: totalQuantity,
          userId: getCookie(cons.USERID),
          amendmentNo1: amendmentNo1,
          amendmentNo2: amendmentNo2,
          amendmentNo3: amendmentNo3,
          amendmentNo4: amendmentNo4,
          amendmentDate1: amendmentDate1 ? amD1 : "",
          amendmentDate2: amendmentDate2 ? amD2 : "",
          amendmentDate3: amendmentDate3 ? amD3 : "",
          amendmentDate4: amendmentDate4 ? amD4 : "",
        },
        line: invoiceList,
      };
      var isValid = false,
        invoiceField = true;
      if (status !== "") {
        if (deliveryDate !== "") invoiceField = true;
        else invoiceField = false;
      }

      if (billingStateCode !== "" && shippingStateCode !== "" && branchId > 0) {
        if (invoiceType !== "") {
          isValid = true;
        } else {
          isValid = true;
        }

        console.log("updated Payload", data);

        if (isValid) {
          this.handleLoading();
          requestList(
            serverApi.DELIVERY_ORDER_UPDATE,
            data,
            this.handleAddIndent
          );
        }
      } else if (!invoiceField) {
        this.basicAlert("Invoice Date And Due Date Are Mandatory..!!");
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onApproveButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        saleOrderDate,
        transporterId,
        facBranchId,
        admBranchId,
        billingStateCode,
        shippingStateCode,
        saleNo,
        invoiceList,
        branchId,
        deliveryDate,
        invoiceType,
        customer,
        taxPayable,
        taxType,
        packagingType,
        salesOrderNo,
        createdDate,
        createdBy,
        // status,
        factoryOffice,
        isActive,
        companyId,
        totalQuantity,
        deliveryOrderNo,
        billingTo,
        shippingTo,

        amendmentNo1,
        amendmentNo2,
        amendmentNo3,
        amendmentNo4,

        amendmentDate1,
        amendmentDate2,
        amendmentDate3,
        amendmentDate4,
        deliveryOrderSeq,
      } = this.state;

      // console("updated Payload List",invoiceList);

      var invoiceList = invoiceList.map((prop, key) => {
        console.log("updated Payload Single Item", prop);
        var claim = 0;
        if (prop.claimAmountDtl !== "" && prop.claimAmountDtl !== null) {
          claim = parseFloat(prop.claimAmountDtl);
        }
        var cgstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.csgst)) / 100,
          sgstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.ssgst)) / 100,
          igstAmount =
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.isgst)) / 100;
        var invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
        console.log("item printing", this.state.item);

        return {
          deliveryOrderId: deliveryOrderNo,
          lineItemId: prop.lineItemId,
          itemCode: prop.itemCode, //this.state.item === undefined ? prop.item : this.state.item,
          itemGroup: prop.itemGroup,
          description: prop.itemName,
          hsnCode: prop.hsnCode,
          itemRate: prop.rate,
          quantity: prop.quantity,
          // noOfBales: prop.bales,
          noOfBales: 0,
          make: prop.make,
          amount: parseFloat(prop.amount),
          taxId: prop.taxId,
          remarks: prop.description,
          updatedDate: moment().format("YYYY-MM-DD"),
          updatedBy: getCookie(cons.USERID),
          status: prop.status,
          isActive: prop.isActive,
          uom: prop.uom,
          uomRate: prop.uomRate,
          uom2: prop.uom2,
          uom3: prop.uom3,
          quantity2: prop.uomQuantity1,
          quantity3: prop.uomQuantity2,
          companyId: this.props.state.companyInfo.id,
          saleLineItemId:
            prop.saleLineItemId == "undefined" ? 0 : prop.saleLineItemId,
        };
      });
      let saleDate = moment(saleOrderDate, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      let dlvryDate = moment(deliveryDate, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      let amD1 = moment(amendmentDate1, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD2 = moment(amendmentDate2, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD3 = moment(amendmentDate3, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD4 = moment(amendmentDate4, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      var data = {
        hdr: {
          deliveryOrderNo: deliveryOrderNo,
          deliveryOrderSeq: deliveryOrderSeq,
          deliveryDate: dlvryDate,
          invoiceType: invoiceType,
          branch: branchId,
          customer: customer,
          billingTo: billingTo,
          shippingTo: shippingTo,
          taxPayable: taxPayable,
          taxType: taxType.value,
          salesOrderNo: salesOrderNo,
          salesOrderDate: saleDate,
          administartiveOffice: admBranchId,
          factoryOffice: facBranchId,
          transporterId: transporterId === 0 ? "" : transporterId,
          packagingType: packagingType,
          updatedDate: moment().format("YYYY-MM-DD"),
          updatedBy: getCookie(cons.USERID),
          status: this.state.status,
          isActive: 1,
          companyId: this.props.state.companyInfo.id,
          totalQuantity: totalQuantity,
          userId: getCookie(cons.USERID),
          amendmentNo1: amendmentNo1,
          amendmentNo2: amendmentNo2,
          amendmentNo3: amendmentNo3,
          amendmentNo4: amendmentNo4,
          amendmentDate1: amendmentDate1 ? amD1 : "",
          amendmentDate2: amendmentDate2 ? amD2 : "",
          amendmentDate3: amendmentDate3 ? amD3 : "",
          amendmentDate4: amendmentDate4 ? amD4 : "",
        },
        line: invoiceList,
      };
      var isValid = false,
        invoiceField = true;
      if (status !== "") {
        if (deliveryDate !== "") invoiceField = true;
        else invoiceField = false;
      }

      if (billingStateCode !== "" && shippingStateCode !== "" && branchId > 0) {
        if (invoiceType !== "") {
          isValid = true;
        } else {
          isValid = true;
        }

        console.log("updated Payload", data);

        if (isValid) {
          this.handleLoading();
          requestList(
            serverApi.DELIVERY_ORDER_UPDATE,
            data,
            this.handleAddIndent
          );
        }
      } else if (!invoiceField) {
        this.basicAlert("Invoice Date And Due Date Are Mandatory..!!");
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        saleOrderDate,
        transporterId,
        facBranchId,
        admBranchId,
        billingStateCode,
        shippingStateCode,
        saleNo,
        invoiceList,
        branchId,
        deliveryDate,
        invoiceType,
        customer,
        taxPayable,
        taxType,
        packagingType,
        createdDate,
        createdBy,
        status,
        isActive,
        companyId,
        totalQuantity,
        billingTo,
        shippingTo,
        amendmentNo1,
        amendmentNo2,
        amendmentNo3,
        amendmentNo4,
        amendmentDate1,
        amendmentDate2,
        amendmentDate3,
        amendmentDate4,
      } = this.state;
      console.log("printing the state", this.state.packagingType);
      console.log("heloooooooindia", saleOrderDate);
      var sd1 = saleOrderDate;
      var sd2 = saleOrderDate;
      var sd3 = saleOrderDate;
      sd1 = sd1.split("-")[2];
      sd2 = sd2.split("-")[1];
      sd3 = sd3.split("-")[0];

      var saleOrderdate = sd1 + "-" + sd2 + "-" + sd3;

      console.log("heloooooooo", saleOrderdate);

      var invoiceList = invoiceList.map((prop, key) => {
        var claim = 0;
        if (prop.claimAmountDtl !== "" && prop.claimAmountDtl !== null) {
          claim = parseFloat(prop.claimAmountDtl);
        }
        // var cgstAmount =
        //     ((parseFloat(prop.amount) - claim) * parseFloat(prop.csgst)) / 100,
        //   sgstAmount =
        //     ((parseFloat(prop.amount) - claim) * parseFloat(prop.ssgst)) / 100,
        //   igstAmount =
        //     ((parseFloat(prop.amount) - claim) * parseFloat(prop.isgst)) / 100;

        // var invoicingModel = getConfigValue(configNames.INVOICING_MODEL);

        return {
          itemCode: prop.itemCode !== "" ? prop.itemCode : null,
          itemGroup: prop.itemGroup,
          description: prop.itemName,
          itemRate: prop.rate,
          quantity: prop.quantity,
          // noOfBales: prop.bales,
          noOfBales: 0,
          make: prop.make,
          amount: parseFloat(prop.amount),
          taxId: prop.taxId,
          remarks: prop.description,
          createdDate: moment().format("YYYY-MM-DD"),
          createdBy: getCookie(cons.USERID),
          status: 1,
          isActive: 1,
          companyId: this.props.state.companyInfo.id,
          saleLineItemId: prop.saleLineitemId,
          uom: prop.uom,
          uomRate: prop.uomRate,
          uom2: prop.uom2,
          uom3: prop.uom3,
          quantity2: prop.uomQuantity1,
          quantity3: prop.uomQuantity2,
          hsnCode: prop.hsnCode,
        };
      });
      console.log("getting delivery date", amendmentDate1);
      let dlvryDate = moment(deliveryDate, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      let amD1 = moment(amendmentDate1, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD2 = moment(amendmentDate2, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD3 = moment(amendmentDate3, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );
      let amD4 = moment(amendmentDate4, "DD-MM-YYYY", true).format(
        "YYYY-MM-DD"
      );

      var data = {
        hdr: {
          deliveryDate: dlvryDate,
          invoiceType: invoiceType,
          branch: branchId,
          customer: customer,
          billingTo: billingTo,
          shippingTo: shippingTo,

          taxPayable: "Y",
          taxType: taxType.value,
          salesOrderNo: saleNo == "" ? 0 : saleNo,
          salesOrderDate:
            saleOrderdate === "undefined-undefined-" ? "" : saleOrderdate,
          administartiveOffice: admBranchId,
          factoryOffice: facBranchId,
          transporterId: transporterId === 0 ? "" : transporterId,
          packagingType: packagingType,
          createdDate: moment().format("YYYY-MM-DD"),
          createdBy: getCookie(cons.USERID),
          status: 1,
          isActive: 1,
          companyId: this.props.state.companyInfo.id,
          totalQuantity: totalQuantity,
          userId: getCookie(cons.USERID),
          amendmentNo1: amendmentNo1,
          amendmentNo2: amendmentNo2,
          amendmentNo3: amendmentNo3,
          amendmentNo4: amendmentNo4,

          amendmentDate1: amendmentDate1 ? amD1 : "",

          amendmentDate2: amendmentDate2 ? amD2 : "",
          amendmentDate3: amendmentDate3 ? amD3 : "",
          amendmentDate4: amendmentDate4 ? amD4 : "",
        },
        line: invoiceList,
      };
      var isValid = false;

      if (
        // taxType.value !== "" &&
        // billingAddress !== "" &&
        invoiceList.length > 0 &&
        // typeOfSale !== "" &&
        // tdsPayable !== null &&
        deliveryDate &&
        branchId !== "" &&
        branchId !== 0 &&
        packagingType !== "" &&
        packagingType !== null &&
        packagingType !== undefined &&
        packagingType !== 0
      ) {
        if (customer !== "" && customer !== "0") {
          if (
            // billingAddress !== "" &&
            // billingAddress !== "" &&
            // billingStateName !== "" &&
            // shippingStateName !== "" &&
            billingStateCode !== ""
            // shippingStateCode !== ""
          )
            isValid = true;
          else isValid = false;
        }

        if (isValid) {
          this.handleLoading();
          requestList(serverApi.DELIVERY_ORDER_ADD, data, this.handleAddIndent);
          console.log("Created successfully", data);
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.deleteBill) console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
      if (resp.billId > 0 && this.state.updateView) {
        this.setState({ isBill: true, billId: resp.billId });
      }
    } else {
      this.showMessage(resp.message, resp.status);
    }

    this.handleLoading();
  };

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteBill) {
      //this.handleLoading();
      getDataFromUrl(
        serverApi.ACCOUNT_BILLS_DELETE +
          this.state.invoiceUniqueNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handleAddIndent
      );
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  data.splice(this.state.id, 1);
      // data[this.state.id].isActive=0;

      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        } else {
          if (o.isActive === 1) {
            if (o.taxPercentage > 0)
              sum_of_tax = sum_of_tax + parseFloat(o.taxamount);
            sumOfamt = sumOfamt + parseFloat(o.amount);
          }
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        totalTax: sum_of_tax.toFixed(2),
        totalAmount: sumOfamt.toFixed(2),
        netTotal: sumOfamt + sum_of_tax,
        sumOfamt: sumOfamt + sum_of_tax,
      });
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;

    var itemIndex = 0,
      sum_of_tax = 0,
      sumOfamt = 0,
      hasItem = false;
    if (this.state.updateItem) {
      itemIndex = this.state.id;

      if (this.state.taxPercentage > 0) {
        var claim = 0;
        if (
          this.state.claimAmountDtl &&
          this.state.claimAmountDtl !== "null" &&
          this.state.claimAmountDtl !== undefined
        ) {
          claim = parseFloat(this.state.claimAmountDtl);
        }
        arr[itemIndex].taxamount = (
          ((parseFloat(this.state.amount) - claim) *
            parseFloat(this.state.taxPercentage)) /
          100
        ).toFixed(2);
        arr[itemIndex].taxPercentage = this.state.taxPercentage;
      } else arr[itemIndex].taxamount = 0;

      arr[itemIndex].amount = parseFloat(this.state.amount);
      console.log("taxamount ", arr[itemIndex].taxamount);
    } else {
      itemIndex = this.state.invoiceList.length;

      if (this.state.itemTaxAmt > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
      sumOfamt = sumOfamt + parseFloat(this.state.amount);
    }

    var hasHandlingCharges = false;
    arr.map((q) => {
      console.log("get the value", q);
      if (q.isActive === 1) {
        if (q.itemName === "Second Stage Handling Charges") {
          hasHandlingCharges = true;
        }
        console.log("amount", q.amount + " aa");
        // sumOfQty = sumOfQty+parseInt(q.quantityType);
        var taxa = 0;
        if (q.taxPercentage > 0) {
          var claim = 0;
          if (
            q.claimAmountDtl !== null &&
            q.claimAmountDtl !== "" &&
            q.claimAmountDtl !== undefined
          ) {
            claim = parseFloat(q.claimAmountDtl);
          }
          taxa = (
            ((parseFloat(q.amount) - claim) * parseFloat(q.taxPercentage)) /
            100
          ).toFixed(2);

          sum_of_tax = sum_of_tax + parseFloat(taxa);
        }
        sumOfamt = sumOfamt + parseFloat(q.amount);
      }
    });

    var addItemObj = {
      id: itemIndex,
      // item: this.state.itemCode,
      itemCode: this.state.itemCode,
      itemName: this.state.itemName,
      itemGroup: this.state.printingPop ? "" : this.state.itemGroup,
      itemGroupName: this.state.printingPop ? "" : this.state.itemGroupName,
      itemGrp: this.state.printingPop
        ? ""
        : this.state.itemGroup + "" + this.state.itemCode,
      qualityId: this.state.qualityId,
      qualityName: this.state.qualityName,
      description: this.state.description,
      quantity: this.state.quantity,
      uom: this.state.uom,
      uomRate: this.state.uomRate,
      uom2: this.state.uom2,
      uom3: this.state.uom3,
      uomQuantity1: this.state.uomQuantity1,
      uomQuantity2: this.state.uomQuantity2,
      uom2Amount: this.state.uom2Amount,
      uom3Amount: this.state.uom3Amount,
      rate:
        this.state.rate !== "" ? parseFloat(this.state.rate).toFixed(2) : 0.0,
      hsnCode: this.state.hsnCode,
      amount:
        this.state.amount !== ""
          ? parseFloat(this.state.amount).toFixed(2)
          : 0.0,
      lineItemId: this.state.lineItemId,
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxId: this.state.taxId,
      taxPercentage: this.state.taxPercentage,
      taxamount: this.state.itemTaxAmt,
      make: this.state.make,
      bales: this.state.bales,
      isActive: 1,
      packingWithIdentificationNo: this.state.packingWithIdentificationNo,
      balesSrlNo: this.state.balesSrlNo,
      costFactor: this.state.costFactor,
      costFactorDes: this.state.costFactorDes,
      claimDesc: this.state.claimDesc,
      claimAmountDtl: this.state.claimAmountDtl,
      saleDrums: this.state.saleDrums,
      saleBales: this.state.saleBales,
      saleWeight:
        this.state.mrNo > 0 ? this.state.saleWeight : this.state.quantity,
      mrLineId: this.state.mrLineId,
      acceptedWeight: this.state.acceptedWeight,
      claimRate: this.state.claimRate,
      saleLineitemId: this.state.saleLineitemId,
      saleLineId: this.state.saleLineId,
      weightOfBale: this.state.weightOfBale,
      NoOfBags: this.state.NoOfBags,
      weightOfBag: this.state.weightOfBag,
      rateType: this.state.rateType,
      fromSalesOrder: this.state.fromSalesOrder,
      availbleQty: this.state.availbleQty,
    };

    var istaxValid = false;
    if (this.state.taxType.value === 4) istaxValid = true;
    else {
      if (
        this.state.taxId !== "" &&
        this.state.taxId !== null &&
        this.state.taxId !== 0
      )
        istaxValid = true;
    }
    if (this.state.printingPop) {
      if (
        this.state.itemName !== "" &&
        this.state.rate !== "" &&
        this.state.quantity !== "" &&
        this.state.amount !== "" &&
        istaxValid &&
        this.state.description !== ""
      ) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        console.log("sumOfamt", sumOfamt);
        console.log("sum_of_tax", sum_of_tax);

        this.setState({
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: sumOfamt.toFixed(2),
          netTotal: sumOfamt + sum_of_tax,
          sumOfamt: sumOfamt + sum_of_tax,
          updateItem: false,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      if (!this.state.updateItem && hasHandlingCharges) {
        this.basicAlert(
          "Please Remove Second Stage Handling Charges For Adding New Item..!!"
        );
      } else {
        if (
          this.state.rate > 0 &&
          this.state.quantity > 0 &&
          this.state.itemCode !== "" &&
          this.state.itemCode !== "0" &&
          this.state.itemGroup !== "" &&
          this.state.itemGroup !== 0 &&
          this.state.amount !== "" &&
          istaxValid
        ) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else {
            arr.splice(arr.length, 0, addItemObj);
          }

          console.log("sumOfamt", sumOfamt);
          console.log("sum_of_tax", sum_of_tax);

          this.setState({
            loading: true,
            invoiceList: arr,
            totalTax: sum_of_tax.toFixed(2),
            totalAmount: sumOfamt.toFixed(2),
            netTotal: sumOfamt + sum_of_tax,
            sumOfamt: sumOfamt + sum_of_tax,
            updateItem: false,
          });

          this.hideAlert();
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      }
    }
    this.handleSelectedUnitValue(this.state.taxType);
    var stateKey = "tcsPercentage";
    var selectedValue = this.state.tcsPercentage;
    this.onSelect(selectedValue, stateKey);
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  handleAddItem() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    if (
      this.state.taxType !== null &&
      this.state.taxType.value !== 0 &&
      this.state.taxType !== "" &&
      this.state.invoiceTyp !== ""
    )
      if (this.state.invoiceTyp == 2 || this.state.invoiceTyp == 3) {
        this.setState({
          loading: true,
          alert: true,
          updateItem: false,
          availqty: this.state.saleLineItemId == undefined ? 0 : "",
        });
      } else {
        if (this.state.unitConversion !== "") {
          this.setState({
            loading: true,
            alert: true,
            updateItem: false,
          });
        } else {
          this.basicAlert("Please Select Unit Conversion..!!");
        }
      }
    else {
      this.basicAlert("Please Select Tax Type and Invoice Type..!!");
    }
  }

  getVehicleQlty = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedIndentQuantity: values[1],
      indentQuantity: values[1],
    });
    console.log("indentQuantity " + this.state.indentQuantity);
  };

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0,
      totclaim = 0;
    var data = this.state.invoiceList;

    data.forEach((prop, key) => {
      var claim = 0;
      if (prop.isActive === 1) {
        if (prop.claimAmountDtl !== "" && prop.claimAmountDtl) {
          claim = parseFloat(prop.claimAmountDtl);
          totclaim = totclaim + claim;
        }

        if (prop.ssgst !== undefined && prop.ssgst > 0)
          totSgst =
            totSgst +
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.ssgst)) / 100;
        if (prop.isgst !== undefined && prop.isgst > 0)
          totIgst =
            totIgst +
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.isgst)) / 100;
        if (prop.csgst !== undefined && prop.csgst > 0)
          totcgst =
            totcgst +
            ((parseFloat(prop.amount) - claim) * parseFloat(prop.csgst)) / 100;
      }
    });
    var totalTax = 0;
    if (tax === "claim") {
      totalTax = totclaim;
    } else if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    } else if (tax === "total") {
      if (totIgst > 0) totalTax = totIgst;
      else totalTax = totSgst + totcgst;
    }
    return parseFloat(totalTax).toFixed(2);
  }

  handleSelectedUnitValue = (defValue) => {
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue.value;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercentage;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else if (SlectedTaxType === 2) {
        var taxPercent = prop.taxPercentage / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      } else {
        prop.isgst = 0;
        prop.ssgst = 0;
        prop.csgst = 0;
      }
    });
    this.setState({
      invoiceList: data,
      taxType: defValue,
    });
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = 0;
    console.log("taxPercent22", values[1]);

    if (values[1] !== "undefined" && values[1] !== "null" && values[1]) {
      taxPercent = values[1];
    }
    console.log("taxPercent11", taxPercent);

    var itemAmount = this.state.amount,
      claim = 0;
    if (this.state.claimAmountDtl && this.state.claimAmountDtl !== "") {
      claim = parseFloat(this.state.claimAmountDtl);
    }
    if (taxPercent) {
      console.log("taxPercent", taxPercent);

      if (itemAmount !== "") {
        var itemtaxAmount =
          ((parseFloat(itemAmount) - claim) * parseFloat(taxPercent)) / 100;
        //  itemtaxAmount = Math.round(itemtaxAmount);
        this.setState({
          itemTaxAmt: itemtaxAmount.toFixed(2),
        });
      }
      this.setState({
        taxPercentage: parseFloat(taxPercent),
      });
    } else var SlectedTaxType = this.state.taxType.value;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercent: taxPercent,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else {
      taxPercent = taxPercent / 2;

      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    }
  };

  getDataTable = () => {
    console.log("updateClick", this.state.invoiceList);
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        console.log("prop", prop.isActive);
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;

                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //  data.splice(i,1);
                        if (this.state.taxType.value > 0) {
                          if (o.itemName === "Second Stage Handling Charges") {
                            this.basicItemAlert(
                              "Delete Second Stage Handling Charges and Add Again..!!"
                            );
                          } else {
                            this.basicItemAlert(
                              "Do You Like To Update This Item?"
                            );
                            if (
                              o.itemName === "Printing Charges" ||
                              o.itemName === "Labour Charges" ||
                              o.itemName === "Other Charges" ||
                              o.itemName === "Second Stage Handling Charges" ||
                              o.itemName === "Handling Charges"
                            ) {
                              this.setState({ printingPop: true });
                            }
                            this.setState({
                              updateItem: true,
                              updateItemAlert: true,
                              id: o.id,
                              itemCode: o.itemCode,
                              itemName: o.itemName,
                              itemGroup: o.itemGroup,
                              itemGroupName: o.itemGroupName,
                              description: o.description,
                              quantity: o.quantity,
                              uom: o.uom,
                              uomRate: o.uomRate,
                              uom2: o.uom2,
                              uom3: o.uom3,
                              uomQuantity1: o.uomQuantity1,
                              uomQuantity2: o.uomQuantity2,
                              uom2conversionValue: o.uom2conversionValue,
                              uom3conversionValue: o.uom3conversionValue,
                              uom2Amount: o.uom2Amount,
                              uom3Amount: o.uom3Amount,
                              rate: o.rate,
                              hsnCode: o.hsnCode,
                              amount: o.amount,
                              lineItemId: o.lineItemId,
                              isgst: o.igstPercent,
                              ssgst: o.sgstPercent,
                              csgst: o.cgstPercent,
                              taxId: o.taxId,
                              taxPercentage: o.taxPercentage,
                              itemTaxAmt: o.taxamount,
                              make: o.make,
                              bales: o.bales,
                              staticbales: o.bales,
                              packingWithIdentificationNo:
                                o.packingWithIdentificationNo,
                              balesSrlNo: o.balesSrlNo,
                              costFactor: o.costFactor,
                              costFactorDes: o.costFactorDes,
                              claimDesc: o.claimDesc,
                              claimAmountDtl: o.claimAmountDtl,
                              saleDrums: o.saleDrums,
                              saleBales: o.saleBales,
                              saleWeight: o.saleWeight,
                              mrLineId: o.mrLineId,
                              qualityId: o.qualityId,
                              qualityName: o.qualityName,
                              destinationMrLine: o.destinationMrLine,
                              acceptedWeight: o.acceptedWeight,
                              claimRate: o.claimRate,
                              saleLineitemId: o.saleLineitemId,
                              availbleQty: o.availbleQty,
                              saleLineId: prop.saleLineId,
                              weightOfBale: o.weightOfBale,
                              NoOfBags: o.NoOfBags,
                              weightOfBag: o.weightOfBag,
                              rateType: o.rateType,
                              fromSalesOrder: o.fromSalesOrder,
                            });
                          }
                        } else {
                          this.basicAlert("Please Select Tax Type..!!");
                        }

                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("HH:mm"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };

  timeChangedFormat = (myDate, time) => {
    console.log("time-----", myDate + time);
    if (time === "" && time === null) time = "00:00:00";

    if (myDate !== "" && myDate !== null) {
      myDate = myDate.split("-");
      var timeParts = time.split(":");
      return new Date(
        myDate[2],
        myDate[1] - 1,
        myDate[0],
        timeParts[0],
        timeParts[1],
        timeParts[2]
      ).getTime();
    } else return "";
  };
  handleuploadBill = (resp) => {
    console.log("base64pdf", resp);
    /*
        if(resp){
            this.setState({invoicePdf:resp})
        }
*/
    this.setState({ isBill: false, billId: "" });
  };
  getWeight = (id) => {
    var netWeight = 0;
    var data = this.state.invoiceList;

    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        if (prop.itemCode !== "" && prop.weightOfBag > 0) {
          var weight = parseFloat(prop.quantity) * parseFloat(prop.weightOfBag);
          netWeight = netWeight + weight;
        }
      }
    });
    if (id === 1) netWeight = netWeight / 1000;

    if (id === 2) {
      netWeight = netWeight / 1000;
      netWeight = (netWeight * 0.75) / 100;
    }
    if (id === 3) {
      var net = netWeight / 1000;
      var pack = (net * 0.75) / 100;
      netWeight = net + pack;
    }
    return parseFloat(netWeight).toFixed(2);
  };
  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      invoiceStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onUpdateButtonClick(4);
    }
    if (isApprove) {
      this.setState({ isApprove: false });

      this.onUpdateButtonClick(invoiceStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        22,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handlePrintData = () => {
    // console.log("getting data for USERID", resp);
    console.log(
      "getting deliveryOrderId",
      this.state.deliveryOrderNo,
      "getting UserId",
      getCookie(cons.USERID),
      "getting companyId",
      this.props.state.companyInfo.id
    );
    fetch(
      serverApi.DELIVERY_ORDER_PRINT +
        this.state.deliveryOrderNo +
        "/" +
        getCookie(cons.USERID) +
        "/" +
        this.props.state.companyInfo.id,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/pdf",

          "X-TenantId": getTenantId(window.location.hostname),

          Authorization:
            getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
        },
      }
    )
      .then((response) => response.blob())

      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute(
          "download",

          `DELIVERY_ORDER_${this.state.deliveryOrderNo}.pdf`
        );

        // Append to html link element page

        document.body.appendChild(link);

        // Start download

        link.click();

        // Clean up and remove the link

        link.parentNode.removeChild(link);
      });
  };

  onSelectIndent = (selectedIndent, year) => {
    getDataFromUrl(
      serverApi.GET_INDENT_DETAILS_BY_ID +
        selectedIndent +
        "/userid/" +
        getCookie(cons.USERID) +
        "/company/" +
        this.props.state.companyInfo.id +
        "/year/" +
        year,
      this.handleIndentSelectResp1
    );
  };
  getInvoicePdf = () => {};
  render() {
    const { classes } = this.props;
    const {
      taxPayable,
      internalCompanyId,
      ItemOptions,
      typeOfSale,
      tcsAmount,
      claimAmount,
      taxType,
      expireDate,
      invoiceTyp,
      loadSaleOrder,
      saleOrderList,
      qualityList,
    } = this.state;
    var tcs = 0;
    var chargesOptions = [
      {
        label: "Printing Charges",
        value: "Printing Charges",
        name: "Printing Charges",
      },
      {
        label: "Labour Charges",
        value: "Labour Charges",
        name: "Labour Charges",
      },
      {
        label: "Handling Charges",
        value: "Handling Charges",
        name: "Handling Charges",
      },
      { label: "Other Charges", value: "Other Charges", name: "Other Charges" },
    ];
    if (invoiceTyp === "3")
      chargesOptions = [
        {
          label: "Printing Charges",
          value: "Printing Charges",
          name: "Printing Charges",
        },
        {
          label: "Labour Charges",
          value: "Labour Charges",
          name: "Labour Charges",
        },
        {
          label: "Handling Charges",
          value: "Handling Charges",
          name: "Handling Charges",
        },
        {
          label: "Second Stage Handling Charges",
          value: "Second Stage Handling Charges",
          name: "Second Stage Handling Charges",
        },
        {
          label: "Other Charges",
          value: "Other Charges",
          name: "Other Charges",
        },
      ];

    var invoiceOptions = [{ value: "", label: "Select...", name: "Select..." }];

    const unitoptions = [
      { value: "LOOSE", label: "LOOSE", name: "LOOSE" },
      { value: "BALE", label: "BALE", name: "BALE" },
    ];

    const SaleOrderTypes = [
      { value: 0, label: "Select...", name: "Select..." },
    ];
    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];
    var taxTypes = getConfigValue(configNames.TAX_TYPES);
    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
      if (taxTypes.noTax === 1) {
        ucoptions.push({ value: 4, label: "NO TAX", name: "NO TAX" });
      }
    } else {
      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }
    var sales = getConfigValue(configNames.SALE_ORDER_TYPES);
    if (sales !== "1") {
      sales = JSON.parse(sales);
      if (sales.general === 1) {
        SaleOrderTypes.push({ value: 1, label: "General", name: "General" });
      }
      if (sales.rawMaterial === 1) {
        SaleOrderTypes.push({
          value: 2,
          label: "Raw Material",
          name: "Raw Material",
        });
      }
      if (sales.juteSale === 1) {
        SaleOrderTypes.push({
          value: 3,
          label: "Jute Sale",
          name: "Jute Sale",
        });
      }
    } else {
      SaleOrderTypes.push({ value: 1, label: "General", name: "General" });
    }
    if (tcsAmount && tcsAmount !== "") tcs = parseFloat(tcsAmount);

    if (this.state.packagingType) {
      var columns = [
        {
          Header: "ITEM CODE",
          accessor: "itemGrp",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        // {
        //   Header: "ITEM GROUP",
        //   accessor: "itemGroupName",
        //   minWidth: 100,
        //   style: { color: "#000", textAlign: "left" },
        // },

        {
          Header: "ITEM DESCRIPTION",
          accessor: "itemName",
          minWidth: 200,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "HSN Code",
          accessor: "hsnCode",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "REMARKS",
          accessor: "description",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },

        // {
        //   Header: "HSN",
        //   accessor: "hsn",
        //   minWidth: 80,
        //   style: { color: "#000", textAlign: "right" },
        // },
        {
          Header: "MAKE",
          accessor: "make",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "QTY",
          accessor: "quantity",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "QTY UOM",
          accessor: "uomRate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "RATE",
          accessor: "rate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "PRICE UOM",
          accessor: "uom",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Tax(%)",
          accessor: "taxPercentage",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Amount",
          accessor: "amount",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Qty 2",

          accessor: "uomQuantity1",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Uom 2",
          accessor: "uom2",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Qty 3",

          accessor: "uomQuantity2",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Uom 3",
          accessor: "uom3",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Actions",
          minWidth: 100,
          accessor: "actions",
          style: { color: "#000", textAlign: "center" },
          sortable: false,
          filterable: false,
        },
      ];
    } else {
      var columns = [
        {
          Header: "ITEM CODE",
          accessor: "itemGrp",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        // {
        //   Header: "ITEM GROUP",
        //   accessor: "itemGroupName",
        //   minWidth: 100,
        //   style: { color: "#000", textAlign: "left" },
        // },

        {
          Header: "ITEM DESCRIPTION",
          accessor: "itemName",
          minWidth: 200,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "HSN Code",
          accessor: "hsnCode",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "REMARKS",
          accessor: "description",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },

        // {
        //   Header: "HSN",
        //   accessor: "hsn",
        //   minWidth: 80,
        //   style: { color: "#000", textAlign: "right" },
        // },
        {
          Header: "MAKE",
          accessor: "make",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "QTY",
          accessor: "quantity",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "QTY UOM",
          accessor: "uomRate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "RATE",
          accessor: "rate",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "PRICE UOM",
          accessor: "uom",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Tax(%)",
          accessor: "taxPercentage",
          minWidth: 50,
          style: { color: "#000", textAlign: "right" },
        },
        {
          Header: "Amount",
          accessor: "amount",
          minWidth: 80,
          style: { color: "#000", textAlign: "right" },
        },

        {
          Header: "Actions",
          minWidth: 100,
          accessor: "actions",
          style: { color: "#000", textAlign: "center" },
          sortable: false,
          filterable: false,
        },
      ];
    }

    var invoicingModel = "1",
      invoicingPrintModel = "1";
    invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
    invoicingPrintModel = getConfigValue(configNames.INVOICING_PRINT_MODEL);

    console.log("invoicingModelaaaa", invoicingModel);

    //add item button click
    var invoiceOptions = [{ value: "", label: "Select...", name: "Select..." }];
    if (invoicingModel === "1") {
      invoiceOptions.push(invoiceTypesList[0]);
    } else if (invoicingModel === "2") {
      invoiceOptions.push(invoiceTypesList[1]);
    } else {
      invoiceOptions = JSON.parse(invoicingModel);
    }

    var yester = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
    var tomorrow = Datetime.moment().add(0, "day");

    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yester);
      /*   else if(isfutureDate==="2"&&isBackDate==="1"){
                return current.isBefore( tomorrow );
            } else if(isfutureDate==="1"&&isBackDate==="2"){
                return current.isAfter( yester );
            }*/ else
        return true;
    };

    var yesterday = new moment(this.state.quoteDate).subtract(1, "day");
    console.log("yesterday", yesterday);
    var quoteDate = this.state.quoteDate;

    var valid1 = function(current) {
      if (
        expireDate !== undefined &&
        expireDate !== null &&
        expireDate !== ""
      ) {
        var momentA = moment(expireDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        // console.log("momentB",momentB);
        return momentA >= momentB;
      } else return false;
    };
    var valid2 = function(current) {
      if (quoteDate !== undefined && quoteDate !== null && quoteDate !== "") {
        var momentA = moment(quoteDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        //   console.log("momentB",momentB);
        return momentA <= momentB;
      } else return false;
    };
    var valid3 = function(current) {
      if (quoteDate !== undefined && quoteDate !== null && quoteDate !== "") {
        var momentA = moment(quoteDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        //   console.log("momentB",momentB);
        return momentA >= momentB;
      } else return false;
    };

    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const inputFocus = {
      autoComplete: "off",
    };
    var round = (
      parseFloat(this.state.netTotal) -
      parseFloat(this.getTotalTax("claim")) +
      tcs
    ).toFixed(2);

    console.log("round", round);
    round = round.split(".");
    var total = parseFloat(round[0]);
    round = "0." + round[1];

    var showRound = false;
    if (parseFloat(round) > 0) {
      showRound = true;
    }
    var max = (1 - parseFloat(round)).toFixed(2);
    round = parseFloat("-" + round);

    var roundoff = this.state.roundoff;
    if (roundoff !== null && roundoff !== undefined) {
      if (parseFloat(this.state.roundoff) === parseFloat(max))
        total = Math.round(
          total + parseFloat(this.state.roundoff) - round
        ).toFixed(2);
      else if (parseFloat(this.state.roundoff) === parseFloat(round))
        total = Math.round(total).toFixed(2);
      else {
        if (parseFloat(max) <= 0.5) {
          roundoff = max;
          total = Math.round(total + parseFloat(max) - round).toFixed(2);
        } else {
          roundoff = round;
          total = Math.round(total).toFixed(2);
        }
      }
    } else {
      if (parseFloat(max) <= 0.5) {
        roundoff = max;
        total = Math.round(total + parseFloat(max) - round).toFixed(2);
      } else {
        roundoff = round;
        total = Math.round(total).toFixed(2);
      }
    }
    // if (IsTallyAvailable("Tally Sync")) {
    //   columns.push({
    //     Header: "Tally",
    //     accessor: "tally",
    //     sortable: false,
    //     filterable: false,
    //     minWidth: 100,
    //   });
    // }
    return (
      <GridContainer
        justify="center"
        style={{ backgroundColor: "#00ABDC" }}
        spacing={0}
        rowSpacing={2}
        columnSpacing={2}
      >
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer spacing={0} rowSpacing={0} columnSpacing={0}>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <form>
                          <div
                            style={{
                              position: "relative",
                              left: "-18px",
                            }}
                          >
                            <GridContainer spacing={0}>
                              {this.state.printingPop ? (
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer spacing={0}>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.itemName}
                                        staticData={chargesOptions}
                                        onSelect={this.onSelect}
                                        stateKey="itemName"
                                        getName={(name) => {
                                          this.setState({ description: name });
                                        }}
                                        value={this.state.itemName}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              {/* {invoiceTyp === "2" && !this.state.printingPop ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item Group
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      disabled={true}
                                      id="itemGroupName"
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                      value={this.state.itemGroupName}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}
                              {!this.state.printingPop &&
                              this.state.typeOfSale !== "Sale Of Service" &&
                              invoiceTyp !== "2" ? (
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer spacing={0}>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={
                                          this.state.itemGroup === ""
                                            ? null
                                            : this.state.itemGroup
                                        }
                                        id={"itemGroup"}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          this.setState({
                                            itemGroupName: name,
                                          });
                                        }}
                                        isRefresh={this.state.loadItemGroup}
                                        stateKey="itemGroup"
                                        setIsRefresh={this.afterRefreshItemGrp}
                                        url={
                                          invoiceTyp === "3"
                                            ? serverApi.SEARCH_FINISHED_ITEM_GROUPS +
                                              this.props.state.companyInfo.id +
                                              "/" +
                                              getCookie(cons.CIPHER)
                                            : serverApi.SEARCH_ITEM_GROUPS +
                                              this.props.state.companyInfo.id +
                                              "/" +
                                              getCookie(cons.CIPHER)
                                        }
                                        value={this.state.itemGroup}
                                        // selectDisabled={this.state.fromSalesOrder}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}

                              {!this.state.printingPop &&
                              this.state.typeOfSale !== "Sale Of Service" ? (
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer spacing={0}>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        // selectDisabled={this.state.fromSalesOrder}
                                        id={"itemCode"}
                                        defValue={this.state.itemCode}
                                        staticData={this.state.ItemOptions}
                                        onSelect={this.onSelect}
                                        getName={this.getitemDetails.bind(this)}
                                        stateKey="itemCode"
                                        isRefresh={this.state.loadItem}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadItem: val });
                                        }}
                                        //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                        value={this.state.itemCode}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              {qualityList.length > 1 &&
                              !this.state.printingPop ? (
                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        selectDisabled={this.state.mrNo > 0}
                                        defValue={this.state.qualityId}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          var values = name;
                                          values = values.split("^^");
                                          this.setState({
                                            qualityName: values[0],
                                          });
                                        }}
                                        isRefresh={this.state.loadquality}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadquality: false });
                                        }}
                                        stateKey="qualityId"
                                        staticData={qualityList}
                                        //url={serverApi.DATATABLE_SERVICE_URL + this.state.itemCode +"/"+this.props.state.companyInfo.id+ "/getQualityByItem"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}

                              {this.state.typeOfSale === "Sale Of Service" ? (
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Cost Factor
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"costFactor"}
                                        onSelect={this.onSelect}
                                        defValue={this.state.costFactor}
                                        getName={(name) => {
                                          this.setState({
                                            costFactorDes: name,
                                          });
                                        }}
                                        stateKey="costFactor"
                                        url={
                                          serverApi.SEARCH_COST_FACTOR +
                                          this.props.state.companyInfo.id +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              <ItemGrid xs={6} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Remarks
                                      {this.state.printingPop ? (
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      ) : null}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="description"
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      value={this.state.description}
                                      fullWidth={true}
                                      InputProps={{
                                        autoComplete: "off",
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              {/* {this.state.invoiceTyp === "3" &&
                            this.state.itemName ===
                              "Second Stage Handling Charges" &&
                            this.state.printingPop ? (
                              <ItemGrid xs={6} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Rate Type
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      defValue={this.state.rateType}
                                      staticData={[
                                        {
                                          label: "Rail Rate",
                                          value: 1,
                                          name: "Rail Rate",
                                        },
                                        {
                                          label: "Container Rate",
                                          value: 2,
                                          name: "Container Rate",
                                        },
                                      ]}
                                      onSelect={this.onSelect}
                                      stateKey="rateType"
                                      value={this.state.rateType}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Price
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <div style={{ display: "flex" }}>
                                          <div style={{ width: "50%" }}>
                                            <TextField
                                              disabled={
                                                this.state.invoiceTyp === "3" &&
                                                this.state.printingPop &&
                                                this.state.itemName ===
                                                  "Second Stage Handling Charges"
                                              }
                                              id="rate"
                                              className={classes.textField}
                                              onChange={this.onChangeValues}
                                              fullWidth={true}
                                              variant="outlined"
                                              iType="number"
                                              InputProps={inputFocus}
                                              value={this.state.rate}
                                            />

                                            {this.state.invoiceTyp === "3" &&
                                            this.state.printingPop &&
                                            this.state.itemName ===
                                              "Second Stage Handling Charges" ? (
                                              <span>Rate Per 100 Bags</span>
                                            ) : null}
                                          </div>
                                          {/* </ItemGrid>
                                      <ItemGrid xs={12} sm={4}> */}
                                          <div style={{ width: "50%" }}>
                                            <SelectboxTemp
                                              style={{ width: "50%" }}
                                              id="uom"
                                              onSelect={this.onSelect}
                                              defValue={this.state.uom}
                                              stateKey="uom"
                                              staticData={
                                                this.state.uomListType1
                                              }
                                              value={this.state.uom}
                                              isRefresh={this.state.loadUomDsc}
                                            />
                                          </div>
                                        </div>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>

                              <ItemGrid xs={6} md={6}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={12}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Quantity
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <div style={{ display: "flex" }}>
                                          <div style={{ width: "50%" }}>
                                            <TextField
                                              disabled={
                                                (this.state.invoiceTyp ===
                                                  "3" &&
                                                  this.state.printingPop &&
                                                  this.state.itemName ===
                                                    "Printing Charges") ||
                                                this.state.mrNo > 0
                                              }
                                              id="quantity"
                                              className={classes.textField}
                                              value={this.state.quantity}
                                              onChange={this.onChangeValues}
                                              fullWidth={true}
                                              style={{ height: 30 }}
                                              iType="number"
                                              decimal={3}
                                              // InputProps={{
                                              //   autoComplete: "off",
                                              //   endAdornment: (
                                              //     <InputAdornment position="end">
                                              //       <span
                                              //         style={{
                                              //           color: "#00ABDC",
                                              //           fontSize: "16px",
                                              //           fontWeight: "400",
                                              //         }}
                                              //       >
                                              //         {this.state.uom}
                                              //       </span>
                                              //     </InputAdornment>
                                              //   ),
                                              // }}
                                            />
                                          </div>
                                          {/* </ItemGrid>
                                      <ItemGrid xs={12} sm={4}> */}
                                          <div style={{ width: "50%" }}>
                                            <SelectboxTemp
                                              id="uomRate"
                                              onSelect={this.onSelect}
                                              defValue={this.state.uomRate}
                                              stateKey="uomRate"
                                              staticData={
                                                this.state.uomListType1
                                              }
                                              value={this.state.uomRate}
                                              isRefresh={this.state.loadUomDsc}
                                            />
                                          </div>
                                        </div>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              {/*                             
                            {!this.state.printingPop &&
                            invoiceTyp === "2" &&
                            this.state.unitConversion === "LOOSE" ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          No of Drums
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          // disabled={true}
                                          id="saleDrums"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          iType="number"
                                          InputProps={inputFocus}
                                          value={this.state.saleDrums}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}
                              {/* {!this.state.printingPop &&
                            invoiceTyp === "2" &&
                            this.state.unitConversion === "BALE" ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          No of Bales
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          // disabled={true}
                                          id="saleBales"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          iType="number"
                                          InputProps={inputFocus}
                                          value={this.state.saleBales}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}

                              {/* {invoiceTyp === "2" && !this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Claim Description
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="claimDesc"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          //iType="number"
                                          InputProps={inputFocus}
                                          value={this.state.claimDesc}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}
                              {/* {invoiceTyp === "2" && !this.state.printingPop ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Claim Amount
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="claimAmountDtl"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined "
                                          iType="number"
                                          InputProps={inputFocus}
                                          value={this.state.claimAmountDtl}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}

                              {/* {(invoicingModel === "2" || invoiceTyp === "3") &&
                            !this.state.printingPop ? (
                              <ItemGrid xs={6} md={6}>
                                <GridContainer>
                                  <ItemGrid
                                    xs={12}
                                    sm={12}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Bales
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={invoiceTyp === "3"}
                                          id="bales"
                                          className={classes.textField}
                                          value={this.state.bales}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          style={{ height: 30 }}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}

                              {!this.state.printingPop ? (
                                <ItemGrid xs={6} md={6}>
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={12}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Make
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <TextField
                                            id="make"
                                            className={classes.textField}
                                            value={this.state.make}
                                            onChange={this.onChangeValues}
                                            fullWidth={true}
                                            style={{ height: 30 }}
                                          />
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}

                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Amount
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          id="amount"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          inputProps={inputFocus}
                                          iType="number"
                                          style={{ height: 30 }}
                                          value={this.state.amount}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                              {taxType.value !== 4 ? (
                                <ItemGrid
                                  xs={6}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid
                                      xs={12}
                                      sm={12}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                          <InputLabel className={classes.label}>
                                            Tax
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              *
                                            </span>
                                          </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                          <SelectboxTemp
                                            defValue={this.state.taxId}
                                            id={"taxId"}
                                            onSelect={this.onSelect}
                                            stateKey="taxId"
                                            getName={this.gettaxpercent}
                                            isReload={this.state.loadTax}
                                            setIsReload={(val) => {
                                              this.setState({ loadTax: false });
                                            }}
                                            url={
                                              serverApi.TAX_MASTER_LIST +
                                              "/" +
                                              this.props.state.companyInfo.id +
                                              "/" +
                                              getCookie(cons.CIPHER)
                                            }
                                            value={this.state.taxId}
                                          />
                                        </ItemGrid>
                                      </GridContainer>
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                              {this.state.packagingType != "" ? (
                                <>
                                  <ItemGrid
                                    xs={6}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid
                                        xs={12}
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <GridContainer>
                                          <ItemGrid xs={12} sm={4}>
                                            <InputLabel
                                              className={classes.label}
                                            >
                                              UOM 2
                                            </InputLabel>
                                          </ItemGrid>
                                          <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                              id="uom2"
                                              onSelect={this.onSelect}
                                              defValue={this.state.uom2}
                                              stateKey="uom2"
                                              staticData={
                                                this.state.uomListType1
                                              }
                                              value={this.state.uom2}
                                              isRefresh={this.state.loadUomDsc}
                                            />
                                          </ItemGrid>
                                        </GridContainer>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={6}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid
                                        xs={12}
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <GridContainer>
                                          <ItemGrid xs={12} sm={4}>
                                            <InputLabel
                                              className={classes.label}
                                            >
                                              QTY 2
                                            </InputLabel>
                                          </ItemGrid>
                                          <ItemGrid xs={12} sm={8}>
                                            <TextField
                                              disabled={true}
                                              id="uomQuantity1"
                                              defValue={this.state.uomQuantity1}
                                              stateKey="uomQuantity1"
                                              value={this.state.uomQuantity1}
                                              onChange={this.onChangeValues}
                                              InputProps={inputFocus}
                                            />
                                          </ItemGrid>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              marginLeft: "130px",
                                              color: "red",
                                              fontStyle: "italic",
                                              marginBottom: "5px",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            Rate : {this.state.uom2Amount}
                                          </span>
                                        </GridContainer>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={6}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid
                                        xs={12}
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <GridContainer>
                                          <ItemGrid xs={12} sm={4}>
                                            <InputLabel
                                              className={classes.label}
                                            >
                                              UOM 3
                                            </InputLabel>
                                          </ItemGrid>
                                          <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                              id="uom3"
                                              onSelect={this.onSelect}
                                              defValue={this.state.uom3}
                                              stateKey="uom3"
                                              staticData={
                                                this.state.uomListType1
                                              }
                                              value={this.state.uom3}
                                              isRefresh={this.state.loadUomDsc}
                                              onChange={this.onChangeValues}
                                            />
                                          </ItemGrid>
                                        </GridContainer>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                  <ItemGrid
                                    xs={6}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid
                                        xs={12}
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <GridContainer>
                                          <ItemGrid xs={12} sm={4}>
                                            <InputLabel
                                              className={classes.label}
                                            >
                                              QTY 3
                                            </InputLabel>
                                          </ItemGrid>
                                          <ItemGrid xs={12} sm={8}>
                                            <TextField
                                              disabled={true}
                                              id="uomQuantity2"
                                              defValue={this.state.uomQuantity2}
                                              stateKey="uomQuantity2"
                                              value={this.state.uomQuantity2}
                                            />
                                          </ItemGrid>
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              marginLeft: "130px",
                                              color: "red",
                                              fontStyle: "italic",
                                              marginBottom: "5px",
                                              marginTop: "-5px",
                                            }}
                                          >
                                            Rate : {this.state.uom3Amount}
                                          </span>
                                        </GridContainer>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </>
                              ) : null}
                              {/* {!this.state.printingPop && invoiceTyp === "3" ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Packing With ID.No
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="packingWithIdentificationNo"
                                      value={
                                        this.state.packingWithIdentificationNo
                                      }
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null} */}

                              {!this.state.printingPop &&
                              invoicingModel === "2" ? (
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Bale SL No.
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="balesSrlNo"
                                        value={this.state.balesSrlNo}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}
                            </GridContainer>
                          </div>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12}>
                    <GridContainer className={classes.rectangleBoarder}>
                      {this.state.updateView ? (
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Delivery Order No
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                disabled={
                                  getConfigValue(
                                    configNames.INVOICE_NO_MODIFY
                                  ) === "1"
                                }
                                id="delivery_order_no"
                                value={this.state.deliveryOrderSeq}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                InputProps={{
                                  autoComplete: "off",
                                  placeholder:
                                    "Will be generated after Saving Delivery Order",
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}

                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Delivery Order Date{" "}
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "deliveryDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "delivery_order_date",
                                  value: this.state.deliveryDate,
                                  autoComplete: "off",
                                  disabled: this.state.updateView,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Sale Order
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            {this.state.updateView ||
                            this.state.isFromSaleOrder ? (
                              <TextField
                                disabled={true}
                                id="saleNoString"
                                value={this.state.saleNoString}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                InputProps={inputFocus}
                                defValue={this.state.saleNoString}
                              />
                            ) : (
                              <SelectboxTemp
                                selectDisabled={this.state.updateView}
                                defValue={this.state.salesOrderNo}
                                onSelect={this.onSelect}
                                stateKey="saleNo"
                                getName={(name) => {
                                  name = name.split("^");
                                  this.setState({
                                    saleOrderDate: name[1],
                                    saleNoString: name[0],
                                  });
                                }}
                                value={this.state.salesOrderNo}
                                staticData={saleOrderList}
                                isRefresh={this.state.loadSaleOrder}
                                setIsRefresh={(val) => {
                                  this.setState({ loadSaleOrder: false });
                                }}
                              />
                            )}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Invoice Type
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.invoiceType}
                              id={"invoiceType"}
                              onSelect={this.onSelect}
                              stateKey="invoiceType"
                              staticData={invoiceOptions}
                              isRefresh={this.state.loadInvoiceType}
                              setIsRefresh={(val) => {
                                this.setState({ loadInvoiceType: false });
                              }}
                              value={this.state.invoiceType}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              {labelType === "2" ? "Project" : "Branch"}
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.branch}
                              onSelect={this.onSelect}
                              stateKey="branchId"
                              url={
                                serverApi.SELECT_BRANCH +
                                this.props.state.companyInfo.id
                              }
                              value={this.state.branch}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Customer
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              // selectDisabled={
                              //   this.state.saleNoString && this.state.customer
                              // }
                              defValue={this.state.customer}
                              id={"customer"}
                              onSelect={this.onSelect}
                              stateKey="customer"
                              staticData={this.state.customerList}
                              isRefresh={this.state.isCustomer}
                              setIsRefresh={(val) => {
                                this.setState({ isCustomer: false });
                              }}
                              getName={(name) => {
                                var values = name.split("^");
                                console.log(
                                  "values[11]",
                                  values[11],
                                  values[9]
                                );
                                if (this.state.customerName !== values[0])
                                  this.setState({
                                    customerName: values[0],
                                  });
                                this.setState({
                                  internalCompanyId:
                                    values[10] !== "null" ? values[10] : "",
                                  finalStatus: values[11],
                                });

                                if (values[11] === "Y") {
                                  var data1 = {
                                    fromDate: "",
                                    toDate: "",
                                    status: "",
                                    supplierCode: "",
                                    mrNo: "",
                                    vehicleNo: "",
                                    poNum: "",
                                    agentId: "",
                                    companyId: values[10],
                                    cipher: getCookie(cons.CIPHER),
                                  };
                                  requestList(
                                    serverApi.GET_MR_LIST,
                                    data1,
                                    this.handleDestinationMrList
                                  );
                                } else {
                                  this.setState({ destinationMrList: [] });
                                }
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Billing To
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={this.state.invoiceStatus === 3}
                              id={"billingAddress"}
                              onSelect={this.onSelect}
                              stateKey="billingAddress"
                              staticData={this.state.customerAddressList}
                              isRefresh={this.state.loadAddress}
                              defValue={this.state.billingTo}
                              value={this.state.billingTo}
                              setIsRefresh={(val) => {
                                this.setState({ loadAddress: false });
                              }}
                              getName={(name) => {
                                var values = name.split("^");
                                console.log(
                                  "values[11]",
                                  getCookie(cons.COMPANY_STATECODE),
                                  values[2]
                                );
                                if (
                                  this.state.billingAddress !== values[0] ||
                                  this.state.isFromSaleOrder
                                ) {
                                  if (taxTypes.gst === 1) {
                                    if (
                                      getCookie(cons.COMPANY_STATECODE) ===
                                        values[2] &&
                                      values[2] !== "null"
                                    ) {
                                      if (this.state.taxPayable !== "N") {
                                        this.setState({
                                          taxType: ucoptions[2],
                                        });
                                        this.handleSelectedUnitValue(
                                          ucoptions[2]
                                        );
                                      }
                                    } else {
                                      this.setState({ taxType: ucoptions[1] });
                                      this.handleSelectedUnitValue(
                                        ucoptions[1]
                                      );
                                    }
                                  }
                                  this.setState({
                                    billingStateName:
                                      values[1] !== "null" ? values[1] : "",
                                    billingStateCode:
                                      values[2] !== "null" ? values[2] : "",
                                    gstNo:
                                      values[3] !== "null" ? values[3] : "",
                                    loadState: true,
                                  });
                                }
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Shipping To
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={this.state.invoiceStatus === 3}
                              defValue={this.state.shippingTo}
                              id={"shippingAddress"}
                              onSelect={this.onSelect}
                              stateKey="shippingAddress"
                              staticData={this.state.customerAddressList}
                              isRefresh={this.state.loadAddress}
                              value={this.state.shippingTo}
                              setIsRefresh={(val) => {
                                this.setState({ loadAddress: false });
                              }}
                              getName={(name) => {
                                var values = name.split("^");
                                if (
                                  this.state.shippingAddress !== values[0] ||
                                  this.state.isFromSaleOrder
                                )
                                  this.setState({
                                    shippingStateName:
                                      values[1] !== "null" ? values[1] : "",
                                    shippingStateCode:
                                      values[2] !== "null" ? values[2] : "",
                                    gstNo2:
                                      values[3] !== "null" ? values[3] : "",
                                    loadShipState: true,
                                  });
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Billing State Name
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="billingStateName"
                              defValue={this.state.billingStateName}
                              value={this.state.billingStateName}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Shipping State Name
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="shippingStateName"
                              value={this.state.shippingStateName}
                              defValue={this.state.shippingStateName}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Billing State Code
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="billingStateCode"
                              defValue={this.state.billingStateCode}
                              value={this.state.billingStateCode}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              iType={"number"}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Shipping State Code
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              //  disabled={true}
                              id="shippingStateCode"
                              value={
                                this.state.isShipping
                                  ? this.state.billingStateCode
                                  : this.state.shippingStateCode
                              }
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              iType={"number"}
                              variant="outlined"
                              InputProps={inputFocus}
                              defValue={this.state.shippingStateCode}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Tax Type
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <Select
                              isDisabled={
                                taxTypes.gst === 1 &&
                                this.state.billingStateCode &&
                                taxPayable
                              }
                              defValue={this.state.taxType}
                              name={"taxType"}
                              value={this.state.taxType}
                              onChange={this.handleSelectedUnitValue}
                              options={ucoptions}
                              className={classes.selectFormControl}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "4px",
                                colors: {
                                  ...theme.colors,
                                  primary: "#00ABDC",
                                },
                              })}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  height: "30px",
                                  "min-height": "30px",
                                }),
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Sale Order Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid1}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "saleOrderDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "saleOrderDate",
                                  value: this.state.saleOrderDate,
                                  autoComplete: "off",
                                  disabled: true,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              PCSO NO
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid1}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "saleOrderDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "pcsoNo",
                                  value: this.state.pcsoNo,
                                  autoComplete: "off",
                                  disabled: true,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              PCSO Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid1}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "saleOrderDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "pcsoDate",
                                  value: this.state.pcsoDate,
                                  autoComplete: "off",
                                  disabled: true,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Administrative Office{" "}
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.administartiveOffice}
                              onSelect={this.onSelect}
                              getName={(name) => {
                                name = name.split("_");
                                name = name[1];
                                this.setState({
                                  administrativeOfficeAddress:
                                    name !== "null" ? name : "",
                                });
                              }}
                              stateKey="admBranchId"
                              // isRefresh={this.state.loadQuality}
                              // setIsRefresh={this.afterRefreshQuality}
                              url={
                                serverApi.SELECT_BRANCH +
                                this.props.state.companyInfo.id
                              }
                              value={this.state.administartiveOffice}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Administrative Office Address
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="administrativeOfficeAddress"
                              value={this.state.administrativeOfficeAddress}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                              defValue={this.state.administrativeOfficeAddress}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Factory
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.factoryOffice}
                              onSelect={this.onSelect}
                              getName={(name) => {
                                name = name.split("_");
                                name = name[1];
                                this.setState({
                                  factoryAddress: name !== "null" ? name : "",
                                });
                              }}
                              stateKey="facBranchId"
                              // isRefresh={this.state.loadQuality}
                              // setIsRefresh={this.afterRefreshQuality}
                              url={
                                serverApi.SELECT_BRANCH +
                                this.props.state.companyInfo.id
                              }
                              value={this.state.factoryOffice}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Factory Address
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="factoryAddress"
                              value={this.state.factoryAddress}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Transporter Name
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              // selectDisabled={
                              //   this.state.saleNoString &&
                              //   this.state.transporterId
                              // }
                              defValue={this.state.transporterId}
                              value={this.state.transporterName}
                              id={"transporterId"}
                              onSelect={this.onSelect}
                              stateKey="transporterId"
                              staticData={this.state.transporterList}
                              isRefresh={this.state.loadTransporter}
                              getName={(name) => {
                                var values = name.split("_");
                                if (this.state.transporterName !== values[0])
                                  this.setState({
                                    transporterName: values[0],
                                    transporterAddress:
                                      values[1] !== "null" ? values[1] : "",
                                    transporterStateCode:
                                      values[2] !== "null" ? values[2] : "",
                                    transporterStateName:
                                      values[3] !== "null" ? values[3] : "",
                                    loadState: true,
                                  });
                              }}
                              setIsRefresh={(val) => {
                                this.setState({ loadTransporter: false });
                              }}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Transporter Address
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="transporterAddress"
                              value={this.state.transporterAddress}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Transporter State
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={
                                this.state.saleNoString &&
                                this.state.transporterStateName
                              }
                              onSelect={this.onSelect}
                              defValue={this.state.transporterStateName}
                              getName={(name) => {
                                var val = name.split("^");
                                if (!this.state.updateView)
                                  this.setState({
                                    transporterStateCode:
                                      val[1] != "null" ? val[1] : "",
                                  });
                              }}
                              isRefresh={this.state.loadState}
                              setIsRefresh={(val) => {
                                this.setState({ loadState: false });
                              }}
                              stateKey="transporterStateName"
                              staticData={this.state.statesList}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Transporter State Code
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="transporterStateCode"
                              value={this.state.transporterStateCode}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Packaging Type
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              id={"packaging_type"}
                              defValue={this.state.packagingType}
                              onSelect={this.onSelect}
                              stateKey="packagingType"
                              value={this.state.packagingType}
                              staticData={packagingType}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.1
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="amendmentNo1"
                              value={this.state.amendmentNo1}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.1 Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "amendmentDate1");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "amendmentDate1",
                                  value: this.state.amendmentDate1,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.2
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="amendmentNo2"
                              value={this.state.amendmentNo2}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.2 Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "amendmentDate2");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "amendmentDate2",
                                  value: this.state.amendmentDate2,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.3
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="amendmentNo3"
                              value={this.state.amendmentNo3}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.3 Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "amendmentDate3");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "amendmentDate3",
                                  value: this.state.amendmentDate3,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.4
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="amendmentNo4"
                              value={this.state.amendmentNo4}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amendment No.4 Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "amendmentDate4");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "amendmentDate4",
                                  value: this.state.amendmentDate4,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(this.state.invoiceList.length + " length")}
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          columns={columns}
                          disableActions={
                            this.state.invoiceStatus === 3 ||
                            this.state.invoiceStatus === 4 ||
                            this.state.invoiceStatus === 5 ||
                            this.state.invoiceStatus === 6
                          }
                        />
                      </ItemGrid>
                      {/* {internalCompanyId === "null" ||
                      internalCompanyId === "" ? (
                        <ItemGrid
                          xs={12}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ printingPop: true, alert: true });
                          }}
                        >
                          Click Here To Add Printing Charges/Labour
                          Charges/Handling Charges/Second Stage Handling
                          Charges/Other Charges
                        </ItemGrid>
                      ) : null} */}
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                            </ItemGrid>
                            <ItemGrid xs={6}>
                              {this.state.invoiceStatus !== 3 &&
                              this.state.invoiceStatus !== 4 &&
                              this.state.invoiceStatus !== 5 &&
                              this.state.invoiceStatus !== 6 &&
                              (this.state.mrNo === 0 ||
                                this.state.mrNo === null ||
                                this.state.mrNo === "") ? (
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              ) : null}
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          {this.state.mrNo === 0 ||
                          this.state.mrNo === null ||
                          this.state.mrNo === "" ? (
                            <Button
                              color={"info"}
                              right={true}
                              onClick={this.handleAddItem}
                            >
                              Add Item
                            </Button>
                          ) : null}
                        </ItemGrid>
                      )}

                      <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={8}
                            style={{ marginTop: "10px" }}
                          />
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.status === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onCreateInvoiceButtonClick();
                            }}
                          >
                            Create Invoice
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({ isReject: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.invoiceStatus !== 3 &&
                        this.state.approveStatus) ||
                      this.state.invoiceStatus === 1 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick();
                            }}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {!this.state.updateView ? (
                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                          <GridContainer justify="center">
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateButtonClick}
                              >
                                Create
                              </Button>
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={this.handleCreate}
                              >
                                Discard
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                      {/* {this.state.invoiceStatus === 3 ? (
                        <ItemGrid xs={9}> */}
                      {/* <SamplePDF
                            invoiceType={3}
                            printResp={this.state.printResp}
                            sendMail={true}
                            handlePOMail={this.handleAddIndent}
                            invoicingModel={invoicingPrintModel}
                          /> */}
                      {/* <div className="exportPdf">
                            <IconButton
                              color="primary"
                              onClick={this.handlePrintData}
                            >
                              {<Print />}
                            </IconButton>
                          </div>
                        </ItemGrid>
                      ) : null} */}
                      {/* {this.state.isBill ? (
                        // <SamplePDF
                        //   invoiceType={3}
                        //   printResp={this.state.printResp}
                        //   isBill={this.state.isBill}
                        //   handlePOMail={this.handleuploadBill}
                        //   invoicingModel={invoicingPrintModel}
                        //   billId={this.state.billId}
                        //   companyId={this.state.internalCompanyId}
                        // />
                        <div className="exportPdf">
                          <IconButton
                            color="primary"
                            onClick={this.handlePrintData}
                          >
                            {<Print />}
                          </IconButton>
                        </div>
                      ) : null} */}
                      {this.state.updateView ? (
                        <ItemGrid
                          xs={12}
                          style={{
                            marginTop:
                              this.state.approveStatus ||
                              this.state.invoiceStatus === 3
                                ? 0
                                : 20,
                          }}
                        >
                          {/* <SamplePDF
                            invoiceType={3}
                            printResp={this.state.printResp}
                            invoicingModel={invoicingPrintModel}
                          /> */}
                          {invoiceTyp === "1" ||
                          invoiceTyp === "2" ||
                          invoiceTyp === "3" ? (
                            // <SamplePDF
                            //   invoiceType={15}
                            //   printResp={this.state.printResp}
                            // />
                            <div className="exportPdf">
                              <IconButton
                                color="primary"
                                onClick={this.handlePrintData}
                              >
                                {<Print />}
                              </IconButton>
                            </div>
                          ) : null}
                          {/* {invoiceTyp === "1" ||
                          invoiceTyp === "2" ||
                          invoiceTyp === "3" ? (
                            // <SamplePDF
                            //   invoiceType={19}
                            //   printResp={this.state.printResp}
                            // />
                            <div className="exportPdf">
                              <IconButton
                                color="primary"
                                onClick={this.handlePrintData}
                              >
                                {<Print />}
                              </IconButton>
                            </div>
                          ) : null} */}
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Invoice Type
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"invoiceType"}
                                onSelect={this.onSelect}
                                stateKey="invoiceType"
                                staticData={invoiceOptions}
                                value={this.state.invoiceType}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Sale No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id=""
                                value={this.state.saleNo}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                inputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Customer Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"customerId"}
                                onSelect={this.onSelect}
                                stateKey="customerId"
                                staticData={this.state.customerList}
                                isRefresh={this.state.isCustomer}
                                setIsRefresh={this.afterRefreshquality}
                                //  url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.customerName}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {/* <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            // round color="white"
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid> */}

                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.DELIVERY_ORDER_LIST}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={
                          getCookie(cons.COMPANY_NAME) + "DELIVERY ORDER"
                        }
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  textFieldRight: {
    textAlign: "right",
    paddingRight: "5px",
    paddingBottom: "10px",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(DeliveryOrder)
);
