// ##############################
// // // StatsCard styles
// #############################

import {
  card,
  cardHeader,
  defaultFont,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  cardActions,
  grayColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  primaryColor,
  roseColor,
  liteBlueCardHeader,
  warningCardHeader,
  brownCardHeader
} from "assets/jss/material-dashboard-pro-react.jsx";

const statsCardStyle = {
  card,
  cardHeader: {
    ...cardHeader,
    float: "left",
    textAlign: "center",
    color:"#FFF"
  },
  cardHeader1: {
    ...cardHeader,
    float: "left",
    textAlign: "center",
    color:"#FFF",
    padding:5
  },
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  liteBlueCardHeader,warningCardHeader,brownCardHeader,

  cardContent: {
    textAlign: "right",
    paddingTop: "10px",
    padding: "15px 20px"
  },
  cardContent1: {
    textAlign: "right",
    padding: "5px 5px"
  },
  cardTitle1: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize:"14px",
    textDecoration: "none",
    color:"#FFFFFF"
  },
  cardIcon: {
    width: "40px",
    height: "36px",
    fill: "#fff"
  },
  cardAvatar: {
    margin: '10px 8px 10px',
    display: 'flex',
  },
  cardCategory: {
    marginBottom: "0",
    color: grayColor,
    margin: "0 0 10px",
    ...defaultFont
  },
  cardCategory1: {
    marginBottom: "0",
    color: grayColor,
    ...defaultFont
  },
  cardTitle: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.825em"
  },
  cardTitleValue: {
    margin: "0",
    ...defaultFont,
    fontSize: "1.3em"
  },

  cardTitleSmall: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
    color: "#777"
  },
  cardActions: {
    ...cardActions,
    padding: "10px 0 0 0!important"
  },
  cardStats: {
    lineHeight: "22px",
    color: grayColor,
    fontSize: "12px",
    display: "inline-block",
    margin: "0!important"
  },
  cardStatsIcon: {
    position: "relative",
    top: "4px",
    width: "16px",
    height: "16px"
  },
  warningCardStatsIcon: {
    color: warningColor
  },
  primaryCardStatsIcon: {
    color: primaryColor
  },
  dangerCardStatsIcon: {
    color: dangerColor
  },
  successCardStatsIcon: {
    color: successColor
  },
  infoCardStatsIcon: {
    color: infoColor
  },
  roseCardStatsIcon: {
    color: roseColor
  },
  grayCardStatsIcon: {
    color: grayColor
  },
  cardStatsLink: {
    color: primaryColor,
    textDecoration: "none",
    ...defaultFont
  },
  cardStattext: {
    width:"50%",
    color:primaryColor,
    fontWeight:"bold",
    textDecoration: "none",
    float:"left",
    fontSize:"12px",
    ...defaultFont
  },
  cardStatsdiv: {
    width:"100%"
  },
  cardStatvalue: {
    width:"50%",
    color:"#000",
    fontWeight:"bold",
    textDecoration: "none",
    float:"right",
    fontSize:"12px",
    ...defaultFont
  }
};

export default statsCardStyle;
