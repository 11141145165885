
import React from "react";

class PrintData extends React.Component{

    constructor(props){
        super(props)

}
componentDidMount(){
  //  window.print();

}

    print = function(){
        window.print();


    };

    render(){

        return(
            <div>
            <div>
               {/* <h2>
                    <a href="#" title='Reports'>Reports</a>
				>
				<a  title='Store Reports'>Store Reports</a>
				>
				PO-02
				</h2>
				<p>&nbsp;</p>*/}

			{/*	<div className="content-heading-box nonprintableContent">
					<div className="row form-field-style">
						<!-- <div class="col-md-4"> -->
							<!-- <div class="row form-group"> -->
								<!-- <div class="col-md-4"><label for="makerSelection">Date<span class="start">*</span></label></div> -->
								<!-- <div class="col-md-8"> -->
								<!-- <my-date-picker name="mydate" [options]="myDatePickerOptions" required [(ngModel)] = "reportModel.reportdate"></my-date-picker> -->
								<!-- </div> -->
							<!-- </div> -->
						<!-- </div> -->
						<div class="col-md-4" *ngIf="allPOListed.length>0">
							<div class="row form-group">
								<div class="col-md-4"><label for="makerSelection">PO<span class="start">*</span></label></div>
								<div class="col-md-8">
								<ng-select [allowClear]="false" [(ngModel)]="reportModel.selectedPO" placeholder="Please Select"
													 [multiple]="false" [(options)]="allPOListed" name="po" required></ng-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="row form-group">
								<button class="btn btn-primary" [disabled] = "reportModel.selectedPO == ''" (click) = "searchReport()">Get Report</button>
							</div>
						</div>
					</div>
				</div>*/}
				<p>&nbsp;</p>
				<div className="form-group nonprintableContent">
					<div className="alert alert-info alert-dismissable">
						No report to show.
					</div>
				</div>
				<div className="row form-group printableContent">
					<table className="print-table-show">
						{/*	<tr>
								<td>
									{{company}}<br/>{{companyaddress}}<br/>{{contact}}
								</td>
								<td class="text-right">
									PO/02<br/>Date : {{approvaldata[reportModel.selectedPO].poDate | date:'mediumDate'}}
								</td>
							</tr>*/}
							{/*<tr>
								<td className="2">
									Purchase Order No.: {{prefix+"/PO/"+approvaldata[reportModel.selectedPO].id}}
								</td>
							</tr>*/}
							{/*<tr>
								<td>
									{{printableSupplierData.name}}<br/>
									{{(printableSupplierData.address1)?printableSupplierData.address1:''}}
									{{(printableSupplierData.address2)?", "+printableSupplierData.address2:''}}
									{{(printableSupplierData.address3)?", "+printableSupplierData.address3:''}}
									<br/>{{(printableSupplierData.phone1)?'Phone : '+printableSupplierData.phone1:''}} {{(printableSupplierData.phone2)?'/'+printableSupplierData.phone2:''}}
                </td>
                <td class="text-right">
                    {{printableSupplierData.id}}
                </td>
            </tr>*/}
        <tr>
            <td colSpan="2">&nbsp;</td>
        </tr>
        <tr>
            <td colSpan="2">
                <p>SLAE TAX WILL FOLLOW / CHARGEABLE</p>
                <p>FREE DELIVERY THIS OFFICE / GODOWN AT 4,GOUR DAS BYSACK LANE, NEAR C.I.T PARK/MAHESWARI VIDYALAYA(KALAKAR ST)KOLKATA- 700007,3 PM TO 6 PM BY 5PM ON WEEKDAY EXCEPT SATURDAY, AT JUTE MILLS.</p>
            </td>
        </tr>
        <tr>
            <td colSpan="2">&nbsp;</td>
        </tr>
    </table>
        <table className="print-table-show">
            <tr>
                <td>
                    Sl No
                </td>
                <td>
                    Item Code
                </td>
                <td>
                    Description
                </td>
                <td>
                    Unit
                </td>
                <td>
                    Make
                </td>
                <td>
                    Indent No./Srl No.
                </td>
                <td>
                    Quantity
                </td>
                <td>
                    Rate
                </td>
                <td>
                    Disc
                </td>
                <td>
                    C St
                </td>
                <td>
                    S Chr
                </td>
                <td>
                    Excise
                </td>
                <td>
                    Delivery Schedule
                </td>
            </tr>
            {/*<tbody *ngFor = "let eachindnt of reportdata; let i = index">
            <tr>
                <td>
                    {{i+1}}
                </td>
                <td>
                    {{eachindnt.itemCode}}
                </td>
                <td>
                    {{eachindnt.description}}
                </td>
                <td>
                    {{eachindnt.unit}}
                </td>
                <td>
                    {{eachindnt.make}}
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
                <td>

                </td>
            </tr>
            <tr *ngFor = "let eachline of eachindnt.lines">
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>
                {{eachline.indentNo}}/{{eachline.srlNo}}
            </td>
            <td>
                {{eachline.quantity}}
            </td>
            <td>
                {{eachline.rate}}
            </td>
            <td>
                {{eachline.discount}}
            </td>
            <td>
                {{eachline.cst}}
            </td>
            <td>
                {{eachline.surcharge}}
            </td>
            <td>
                {{eachline.excise}}
            </td>
            <td>
                {{eachline.deliverySchedule +" DAYS"}}
            </td>
        </tr>
        <tr>
            <td colspan="13">&nbsp;</td>
        </tr>
    </tbody>*/}
    </table>
        <table className="print-table-show">
            <tr>
                <td colSpan="2">
                    Job No :
                </td>
            </tr>
{/*
            <tr>
                <td colSpan="2">
                    Footer Note : {{approvaldata[reportModel.selectedPO].footerNote}}
                </td>
            </tr>
*/}
            <tr>
                <td colSpan="2">
                    N.B : ITEM CODE , ORDER NO , INDENT NO MUST BE  INDICATED ON CHALLAN.
                </td>
            </tr>
            <tr>
{/*
                <td style="width:60%">&nbsp;</td>
*/}
                <td className="text-center">
                    For <strong>{"njm"}</strong>
                    <br/>
                    <br/>
                    <br/>
                    Authorised Signatory
                </td>
            </tr>
        </table>
    </div>
    <p>&nbsp;</p>
            </div>
    <div>
            <div className="col-md-12 text-center">
            <button className="btn btn-primary" title="Print Report" onClick= {this.print} >print</button>
        </div>
    </div>



    </div>
        )
    }

}
export default PrintData;