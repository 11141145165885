import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import {
  Categories,
  JobTypes,
  NoorYes,
  PackageTypes,
  PeriodTypes,
  Status,
} from "../TableData";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import LineItemTable from "../CustumComponents/LineItemTable";
import SelectboxMultiple from "../CustumComponents/SelectboxMultiple";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from "@material-ui/icons/Search";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { connect } from 'react-redux';

class JobsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      updateView: false,
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      PackageList: [],
      packageName: "",
      packagePrice: "",
      periodType: "",
      sureVisits: "",
      maxVisit: "",
      standbyEligible: "",
      remoteSupport: "",
      description: "",
      itemCode: "",
      itemGroupCode: "",
      qty: "",
      withSpare: "",
      status: 1,
      isActive: "",
      isDisabled: false,
      problemList: [],
      PackageMasterList: [],
      properties: [],
      itemGroupsList: [],
      jobCarriers: [],
      jobDate: moment().format("DD-MM-YYYY"),
      branchId: "",
      customerName: "",
      helpTopicList: [],
      helpTopics: [],
      complaintId: "",
      variantId: "",
      productId: "",
      amt: "",
      packageId: "",
      subscription: "",
      propertyList: [],
      customerName1: "",
      approveButton: false,
      carrierList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleSeacrh = () => {
    if (!this.state.isRefresh) {
      this.getJobsList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.CM_JOB_BY_ID + id + "/" + getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.data.jobHdr;
    var list = resp.data.jobDetails;
    var jobActions = resp.data.jobActions;
    var jobCarriers = resp.data.jobCarriers;
    /*
                var Packagelist = list.map((prop, key) => {
                    return (
                        {
                            id: key,
                            lineItemId:prop.id,
                            variantId: prop.variantId,
                            productId: prop.productId,
                            variantDesc: prop.variantDesc,
                            productDesc: prop.productDesc,
                            amt:prop.amt,
                            isActive:prop.status,
                            complaintId:prop.complaintId,
                            packageId:prop.packageId,
                            subscription:prop.subscription,
                            packageName:prop.packageName
                        }
                    )
                });
        */
    //console.log("indentWeight "+header.indentWeight);
    var helpTopics = [];
    if (list[0]) {
      list[0].jobHelpTopics.forEach((prop) => {
        helpTopics.push(prop.helpTopicId);
      });
    }

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        advamt: header.advamt,
        category: header.category,
        customerId: header.customerId,
        estimation: header.estimation,
        type: header.type,
        jobDate: header.jobDate,
        branchId: header.branchId,
        status: header.status,
        jobId: header.id,
        // PackageMasterList:Packagelist,
        carrierList: jobCarriers,
        customer: header.customerContactNo,
        customerName: header.customerName,
        complaintId: list[0].complaintId,
        variantId: list[0].variantId,
        productId: list[0].productId,
        amt: list[0].amt,
        packageId: header.packageId,
        subscription: list[0].subscription,
        jobActions: header.jobActions,
        jobHelpTopics: list[0].jobHelpTopics,
        helpTopics: helpTopics,
        lineItemId: list[0].id,
        approveButton: header.approveButton,
      });
      this.callCarrierList();
      // getDataFromUrl(serverApi.CUSTOMER_MASTER_DETAILS_BY_ID+header.customerId+"/"+getCookie(cons.CIPHER), this.handleCustomerDetails);
    }
  };
  handleCustomerDetails = (resp) => {};

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      // PackageMasterList:""
    });
    //  this.hideAlertMessage();
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("YYYY-MM-DD"),
      });
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (index) => (event) => {
    this.setState({ remark: "" });
    var arr = this.state.carrierList;
    arr[index].active = !arr[index].active;
    this.setState({
      carrierList: arr,
    });
    if (arr[index].declaration === "Y" && arr[index].active)
      this.setState({
        checkBoxPopUp: true,
        checkboxId: index,
      });

    if (!arr[index].active) arr[index].remark = "";

    this.setState({
      carrierList: arr,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "complaintId") {
      this.callHelpTopicsList(selectedValue);
      // this.calLPackageMasterList(selectedValue)
    }
    if (stateKey === "packageId") {
      this.state.packageList.forEach((prop) => {
        if (selectedValue === prop.value) {
          this.setState({ propertyList: prop.propertyList });
        }
      });
    }

    if (stateKey === "productId") {
      /*  if(this.state.productId===selectedValue){
                  if(this.state.problemList.length===0){
                      var data={
                          "productId":selectedValue};
                      requestList(serverApi.PRODUCT_PROPERTY_LIST,data,this.handlePropertyData)
                  }
              }else {*/

      var data1 = {
        customerId: this.state.customerId,
        variantId: this.state.variantId,
        productId: selectedValue,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(
        serverApi.CM_MAPPED_PRODUCT_FOR_CUSTOMER,
        data1,
        this.handleMappedProducts
      );
      //get properties of product
      var data = {
        productId: selectedValue,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(
        serverApi.PRODUCT_PROPERTY_LIST,
        data,
        this.handlePropertyData
      );
      //  }
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handlePropertyData = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          name: prop.property,
          id: prop.id,
          value: prop.defaultValue,
          isActive: 0,
          propId: null,
          dataType: prop.dataType,
          maxLenght: prop.maxLenght,
        };
        list.push(obj);
      });
    }
    // console.log("propertyList",list);
    if (this.state.updateItem) {
      list.forEach((prop, i) => {
        this.state.propertyList.forEach((o) => {
          if (prop.id === o.id) {
            list[i].isActive = o.isActive;
            list[i].propId = o.propId;
            list[i].value = o.value;
          }
        });
      });
    }
    this.setState({ propertyList: list, loadProperty: true });
  };

  handleMappedProducts = (resp) => {
    console.log("mapped0", resp);
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var header = prop.packageCustomerHdr;
        var obj = {
          label: header.packageName,
          value: header.packageId,
          name: header.packageName,
          propertyList: prop.packageCustomerPropertiesDtl,
        };
        list.push(obj);
      });
    }
    this.setState({ packageList: list, loadPackage: true });
  };
  handleComplaintData = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          name: prop.problemName,
          value: prop.problemId,
          label: prop.problemName,
        };
        list.push(obj);
      });
    }
    // console.log("problemList",list);
    this.setState({ problemList: list, loadProblem: true });
  };

  handleItem = (resp) => {
    var list = [];
    resp.forEach((prop) => {
      var obj = {
        label: prop.label,
        value: prop.itemId,
        name: prop.value + "^" + prop.name,
      };
      list.push(obj);
    });
    this.setState({ ItemOptions: list, loadItem: true });
  };

  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      productDesc: values[1],
    });
    var data = {
      companyId: this.props.state.companyInfo.id,
      itemGroupid: this.state.itemGroupId,
      itemId: values[0],
      problemName: "",
    };
    requestList(serverApi.PROBLEM_MASTER_LIST, data, this.handleComplaintData);
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.PackageMasterList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        //  updateItem: false,
        updateItemAlert: false,
      });
    }
    // if(this.state.deleteList)
    if (this.state.deleteList) {
      this.handleLoading();
      getDataFromUrl(
        serverApi.DELETE_PACKAGE_MASTER + "/" + this.state.packageMasterId,
        this.handleAddIndent
      );
    }

    if (this.state.deleteItem) {
      var data = this.state.PackageMasterList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  data.splice(this.state.id, 1);
      // data[this.state.id].isActive=0;

      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        PackageMasterList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      loading: false,
      alert: false,
      showMessagealert: false,
      variantId: "",
      productId: "",
      variantDesc: "",
      productDesc: "",
      cnt: "",
      problemList: [],
      id: null,
      lineItemId: null,
      isActive: 1,
      packageId: "",
      complaintDesc: "",
      packageName: "",
      helpTopicList: [],
      helpTopics: [],
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getJobsList();
    }
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      jobCarriers: [],
      packageId: "",
      description: "",
      amt: "",
      maxDiscountAllowed: "",
      image: "",
      name: "",
      estimation: "",
      type: "",
      PackageMasterList: [],
      problemList: [],
      jobDate: moment().format("DD-MM-YYYY"),
      branchId: "",
      customerName: "",
      customer: "",
      propertyList: [],
      approveButton: false,
      status: "",
      complaintId: "",
      variantId: "",
      productId: "",
      subscription: "",
      helpTopicList: [],
      helpTopics: [],
      advamt: "",
      category: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      /*
                        var packageDtl = this.state.PackageMasterList.map((o, key) => {
                            return ({
                                "variantId": o.variantId,
                                "productId":o.productId,
                                "amt": o.amt,
                                "complaintId": o.complaintId,
                                packageId:o.packageId,
                                "internal": true,
                                "subscription": o.packageId>0?true:false
                            })
                        });
            */

      const {
        advamt,
        category,
        customerId,
        estimation,
        type,
        carrierList,
        branchId,
        jobDate,
        complaintId,
        variantId,
        productId,
        amt,
        packageId,
        subscription,
        helpTopics,
      } = this.state;
      var jobHelpTopics = helpTopics.map((prop) => {
        return { helpTopicId: prop };
      });
      var packageDtl = [
        {
          amt: amt,
          complaintId: complaintId,
          internal: true,
          subscription: subscription,
          packageId: packageId,
          variantId: variantId,
          productId: productId,
          jobHelpTopics: jobHelpTopics,
        },
      ];
      var Carriers = [];
      carrierList.forEach((prop) => {
        if (prop.active)
          Carriers.push({
            carrierId: prop.carrierId,
          });
      });
      var data = {
        jobCarriers: Carriers,

        jobActions: [
          {
            action: "test started",
            assignedTo: "shiva",
            remarks: "",
          },
        ],
        jobDetails: packageDtl,
        jobHdr: {
          advamt: advamt,
          category: category,
          companyId: this.props.state.companyInfo.id,
          createdBy: getCookie(cons.USERID),
          customerId: customerId,
          estimation: estimation,
          packageId: packageId,
          source: 1,
          type: type,
          branchId: branchId,
          jobDate: moment().format("YYYY-MM-DD").jobDate,
        },
      };
      if (
        jobDate !== "" &&
        branchId > 0 &&
        customerId > 0 &&
        variantId !== "" &&
        productId !== "" &&
        variantId !== "0" &&
        productId !== "0" &&
        complaintId > 0
      ) {
        this.handleLoading();
        requestList(serverApi.CM_ADD_JOB, data, this.handleAddIndent);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var packagePropertiesDtl = [];
      /*
                        var packageDtl = this.state.PackageMasterList.map((o, key) => {
                            o.problemList.forEach(k=>{

                                var obj={
                                    id:k.propId,
                                    "value":  k.value,
                                    "variantId": o.variantId,
                                    "productId":o.productId,
                                    "productPropId": k.id,
                                    status:k.isActive
                                };
                                if(k.isActive===1)
                                    packagePropertiesDtl.push(obj);
                            });
                            return ({
                                id:o.lineItemId,
                                "cnt": o.cnt,
                                "variantId": o.variantId,
                                "productId":o.productId,
                                status:o.isActive
                            })
                        });
            */

      const {
        jobHelpTopics,
        advamt,
        category,
        customerId,
        estimation,
        type,
        carrierList,
        branchId,
        jobDate,
        complaintId,
        variantId,
        productId,
        amt,
        packageId,
        subscription,
        helpTopics,
        jobActions,
        jobId,
        lineItemId,
      } = this.state;
      var jobHelps = helpTopics.map((prop) => {
        var obj = {
          helpTopicId: prop,
          id: 0,
          jobDetailId: lineItemId,
          jobId: jobId,
          active: true,
        };
        jobHelpTopics.forEach((o) => {
          if (o.helpTopicId === prop) {
            obj.id = o.id;
          }
        });
        return obj;
      });
      var packageDtl = [
        {
          id: lineItemId,
          jobId: jobId,
          amt: amt,
          complaintId: complaintId,
          internal: true,
          subscription: subscription,
          packageId: packageId,
          variantId: variantId,
          productId: productId,
          jobHelpTopics: jobHelps,
          status: 1,
        },
      ];
      var Carriers = [];
      carrierList.forEach((prop) => {
        Carriers.push({
          ...prop,
          jobId: jobId,
        });
      });
      var data = {
        jobCarriers: Carriers,

        jobActions: jobActions,
        jobDetails: packageDtl,
        jobHdr: {
          advamt: advamt,
          category: category,
          companyId: this.props.state.companyInfo.id,
          modBy: getCookie(cons.USERID),
          customerId: customerId,
          estimation: estimation,
          packageId: packageId,
          source: 1,
          type: type,
          branchId: branchId,
          jobDate: jobDate,
          id: jobId,
          status: status,
        },
      };
      if (
        jobDate !== "" &&
        branchId > 0 &&
        customerId > 0 &&
        variantId !== "" &&
        productId !== "" &&
        variantId !== "0" &&
        productId !== "0" &&
        complaintId > 0
      ) {
        this.handleLoading();
        requestList(serverApi.CM_UPDATE_JOB, data, this.handleAddIndent);
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  // onUpdateButtonClick=()=>{
  //     if(!getMenuPermission(2)){
  //         this.basicAlert("Access Denied. Please contact administrator!!!")
  //     }else {
  //
  //         const {description,maxVisit,packageName,packagePrice,periodType,remoteSupport,standbyEligible,sureVisits,hdrId,isActive}=this.state;
  //
  //         var PackageMasterList = this.state.PackageMasterList.map((o, key) => {
  //             return ({
  //                 dtlId: o.dtlId,
  //                 hdrId: o.hdrId,
  //                 withSpare: o.withSpare,
  //                 qty: o.qty,
  //                 itemGroupCode: o.itemGroupCode,
  //                 itemCode: o.itemCode,
  //                 "companyId": this.props.state.companyInfo.id,
  //                 "updatedBy": getCookie(cons.USERID)
  //             })
  //         });
  //
  //         var data = {
  //             "dtlList": PackageMasterList,
  //             "pmdto": {
  //                 "companyId": this.props.state.companyInfo.id,
  //                 "updatedBy": getCookie(cons.USERID),
  //                 "activeStatus": isActive,
  //                 "hdrId": hdrId,
  //                 "description": description,
  //                 "maxVisit": maxVisit,
  //                 "packageName": packageName,
  //                 "packagePrice":packagePrice,
  //                 "periodType": periodType,
  //                 "remoteSupport": remoteSupport,
  //                 "standbyEligible": standbyEligible,
  //                 "sureVisits": sureVisits
  //             }
  //         };
  //         if ( this.state.PackageMasterList.length >= 1) {
  //             this.handleLoading();
  //            requestList(serverApi.LEAVE_POLICIES_UPDATE, data, this.handleAddIndent)
  //         } else if (this.state.PackageMasterList.length < 1) {
  //             this.basicAlert("Please Add Atleast One Item..!!");
  //         }
  //         else {
  //             this.basicAlert("Please Enter All Mandatory Fields..!!");
  //         }
  //     }
  // };
  handleAddIndent = (resp) => {
    if (resp.status) {
      /* if (this.state.deleteList)
                 this.calLPackageMasterList();*/
      this.setState({
        deleteList: false,
        basicAlert: null,
      });
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.PackageMasterList;

    var itemIndex = 0,
      packageMasterItemId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      packageMasterItemId = this.state.packageMasterItemId;
      //   arr[itemIndex].quantity = this.state.quantity
    } else {
      itemIndex = this.state.PackageMasterList.length;
    }
    var addItemObj = {
      id: itemIndex,
      lineItemId: this.state.lineItemId,
      variantId: this.state.variantId,
      productId: this.state.productId,
      packageId: this.state.packageId,
      variantDesc: this.state.variantDesc,
      productDesc: this.state.productDesc,
      amt: this.state.amt,
      complaintId: this.state.complaintId,
      complaintDesc: this.state.complaintDesc,
      packageName: this.state.packageName,
      internal: true,
      subscription: true,
      isActive: 1,
      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.PackageMasterList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    updateItemAlert: true,
                    updateItem: true,
                    lineItemId: o.lineItemId,
                    status: o.status,
                    variantId: o.variantId,
                    productId: o.productId,
                    variantDesc: o.variantDesc,
                    productDesc: o.productDesc,
                    amt: o.amt,
                    complaintId: o.complaintId,
                    internal: o.internal,
                    subscription: o.subscription,
                    packageId: o.packageId,
                    complaintDesc: o.complaintDesc,
                    packageName: o.packageName,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.PackageMasterList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({
                    deleteItem: true,
                    id: o.id,
                  });

                  //  data.splice(i,1);
                  return true;
                }
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };
    if (
      this.state.variantId !== "" &&
      this.state.productId !== "" &&
      this.state.amt !== "" &&
      this.state.problemList.length > 0
    ) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else {
        arr.splice(arr.length, 0, addItemObj);
      }
      this.setState({
        //loading:,
        PackageMasterList: arr,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { name, packagePrice, sureVisits, description } = this.state;

    //  var id="";
    //  if (name !== "")
    this.setState({
      loading: true,
      alert: true,
    });
    /* else {
             this.basicAlert("Please Package Name..!!");
         }*/
  }

  // onDeletePackageMaster = (id) => {
  //
  //     this.setState({deleteList: true, packageMasterId: id});
  //
  //     this.basicItemAlert("Do You Like To Delete This Record?");
  // };

  componentDidMount() {
    var table = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsAccess: false,
      approveButton: false,
      totalRecords: 0,
      column: [
        {
          filterable: "true",
          Header: "Customer Name",
          accessor: "customerName",
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Customer Contact",
          accessor: "customerContactNo",
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Package Name",
          accessor: "packageName",
          style: { textAlign: "left" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Estimated",
          accessor: "estimation",
          style: { textAlign: "left" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Advance Amount",
          accessor: "advamt",
          style: { textAlign: "left" },
          sortable: "true",
        },
      ],
    };
    this.setState({ tableList: table, isRefresh: true });
    this.getItemGroups();
    this.callCarrierList();
    this.getJobsList();
    /*  var data = {
              "companyId": this.props.state.companyInfo.id,
              "cipher": getCookie(cons.CIPHER)
          };
          requestList(serverApi.CUSTOMER_MASTER, data, this.handelCustomer);*/
  }
  getJobsList = () => {
    var data = {
      customerName: this.state.customerName1,
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.CM_JOBS_LIST, data, this.handleJobsList);
  };
  handleJobsList = (resp) => {
    var list = this.state.tableList;
    list.data = resp.data;
    this.setState({ tableList: list, isRefresh: true });
  };
  /*
        handelCustomer = (resp) => {
            var data = resp.data, customerList = [];
            if (data.length > 0) {
                var obj = {label: "Select", value: null, name: "null"};
                customerList.push(obj);
                data.forEach(prop => {
                    var obj = {label: prop.name, value: prop.id, name: prop.name};
                    customerList.push(obj)
                });
                this.setState({
                    customerList: customerList,
                    isCustomer: true
                })
            }
        };
    */

  callCarrierList() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      itemGroupCode: this.state.itemGroupids,
    };
    requestList(serverApi.CARRIER_MASTER_LIST, data, this.handleCarrierList);
  }
  handleCarrierList = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          id: null,
          carrierId: prop.hdrId,
          active: false,
          declaration: prop.declaration,
          label: prop.carrierName,
        };
        list.push(obj);
      });
      if (this.state.updateView) {
        list.forEach((prop, i) => {
          this.state.carrierList.forEach((o) => {
            if (prop.carrierId === o.carrierId) {
              list[i].active = o.active;
              list[i].id = o.id;
            }
          });
        });
      }
    }
    this.setState({ carrierList: list, loadCarrier: true });
  };
  getItemGroups = () => {
    getDataFromUrl(
      serverApi.SEARCH_ITEM_GROUPS +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleItemGroups
    );
  };
  handleItemGroups = (resp) => {
    console.log("itemGroups", resp);
    var list = [];
    resp.forEach((prop) => {
      var obj = {
        label: prop.label,
        value: prop.id,
        name: prop.name + "^" + prop.value,
      };
      list.push(obj);
    });
    this.setState({ itemGroupsList: list, loadItemGroup: true });
  };
  calLPackageMasterList = (customerId) => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      customerId: customerId,
    };
    requestList(serverApi.CM_PACKAGE_MASTER_LIST, data, this.handlePackList);
  };

  handlePackList = (resp) => {
    var list = [];

    resp.data.forEach((prop) => {
      var obj = { label: prop.name, value: prop.id, name: prop.name };
      list.push(obj);
    });
    this.setState({ packageList: list, loadPackage: true });
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      var result = reader.result.split(",");
      console.log("reader.result", result[1]);

      if (fileType === "JPG" || fileType === "JPEG" || fileType === "PNG") {
        this.setState({
          //  file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          image: result[1],
        });
        //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
  }
  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.PackageMasterList.length > 0)
      this.state.PackageMasterList.forEach((prop, key) => {
        console.log("prop", prop.isActive);
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.PackageMasterList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        this.basicItemAlert("Do You Like To Update This Item?");

                        this.setState({
                          id: o.id,
                          updateItemAlert: true,
                          updateItem: true,
                          lineItemId: o.lineItemId,
                          status: o.status,
                          variantId: o.variantId,
                          productId: o.productId,
                          variantDesc: o.variantDesc,
                          productDesc: o.productDesc,
                          amt: o.amt,
                          complaintId: o.complaintId,
                          internal: o.internal,
                          subscription: o.subscription,
                          packageId: o.packageId,
                          complaintDesc: o.complaintDesc,
                          packageName: o.packageName,
                        });
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="remove"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.PackageMasterList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        //  data.splice(i,1);
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };
  getCustomerDetails = (phone) => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      contactNo: this.state.customer === "" ? phone : this.state.customer,
    };
    requestList(
      serverApi.CM_CUSTOMER_MASTER_BY_CONTACT,
      data,
      this.handelCustomer
    );
  };

  onCustomerButtonClick = () => {
    this.setState({
      customerPopup: false,
    });
  };

  handelCustomer = (resp) => {
    var data = resp.data,
      customerList = [];
    data.forEach((prop) => {
      var obj = {
        id: prop.id,
        contactNo: prop.contactNo,
        emailId: prop.emailId,
        name: prop.name,
        ledger: prop.ledgerId,
      };
      customerList.push(obj);
    });
    this.setState({
      customerPopup: true,
      customerList: customerList,
      customer: this.state.contactNo,
      packagePopUp: false,
      isRefresh: true,
    });
  };

  handleCustomerPopup = () => {
    this.setState({
      customerPopup: false,
    });
  };
  createCustomer = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);

    this.setState({
      customerPopup: false,
      addCustomerPopUp: true,
      name: "",
      contactPerson: "",
      contactNo: "",
      emailId: "",
      address: "",
      ledgerGroup: "",
    });
  };
  handleLedgerGroup = (resp) => {
    const lgoptions = [];
    var ledger_data = resp.data;
    if (ledger_data !== null || ledger_data !== undefined) {
      ledger_data.forEach((r) => {
        const { name, id } = r;
        lgoptions.push({
          value: r.id,
          label: r.name,
          name: r.name,
        });
      });
    }
    this.setState({ ledger_data: lgoptions });
  };
  onCreateCustomerClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        name,
        contactPerson,
        contactNo,
        emailId,
        address,
        ledgerGroup,
      } = this.state;
      var data = {
        name: name,
        contactPerson: contactPerson,
        contactNo: contactNo,
        emailId: emailId,
        address: address,
        ledgerGroup: ledgerGroup,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
      };

      if (
        name !== "" &&
        contactPerson !== "" &&
        contactNo !== "" &&
        address !== ""
      ) {
        this.handleLoading();
        requestList(
          serverApi.CUSTOMER_MASTER_ADD,
          data,
          this.handlecreateCustomer
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handlecreateCustomer = (resp) => {
    if (resp.status) {
      // this.showMessage(resp.message, resp.status)
      this.setState({ addCustomerPopUp: false, customer: "" });
      this.getCustomerDetails(this.state.contactNo);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  callHelpTopicsList(problemId) {
    var data = {
      companyId: this.props.state.companyInfo.id,
      problemId: problemId,
    };
    requestList(serverApi.CM_GET_HELP_TOPIC_LIST, data, this.handleList);
  }
  handleList = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          label: prop.helpTopicDescription,
          value: prop.helpTopicId,
          name: prop.helpTopicDescription,
        };
        list.push(obj);
      });
    }
    this.setState({ helpTopicList: list, loadHelpTopic: true });
  };
  render() {
    const { classes } = this.props;
    const { PackageMasterList, propertyList } = this.state;
    const options = [{ label: "All", value: 0 }, { label: "Active", value: 1 }];
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <form>
                          <GridContainer xs={12} md={12}>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Variant
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.variantId}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          name = name.split("^");
                                          this.setState({
                                            variantDesc: name[0],
                                            itemGroupId: name[1],
                                          });
                                          var data = {
                                            itemGroupId: name[1],
                                            saleable: "",
                                            consumable: "",
                                            companyId: getCookie(
                                              cons.COMPANY_ID
                                            ),
                                          };
                                          requestList(
                                            serverApi.GET_ITEMS_BY_GRPID,
                                            data,
                                            this.handleItem
                                          );
                                        }}
                                        isRefresh={this.state.loadItemGroup}
                                        stateKey="variantId"
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadItemGroup: false,
                                          });
                                        }}
                                        staticData={this.state.itemGroupsList}
                                        //  url={serverApi.SEARCH_ITEM_GROUPS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Product
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.productId}
                                        onSelect={this.onSelect}
                                        getName={this.getitemDetails}
                                        stateKey="productId"
                                        isRefresh={this.state.loadItem}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadItem: false });
                                        }}
                                        staticData={this.state.ItemOptions}
                                        //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid
                                  xs={6}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Complaint
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.complaintId}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          this.setState({
                                            complaintDesc: name,
                                          });
                                        }}
                                        stateKey="complaintId"
                                        isRefresh={this.state.loadProblem}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadProblem: false });
                                        }}
                                        staticData={this.state.problemList}
                                        //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={6}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Help Topics
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxMultiple
                                        defValue={this.state.helpTopics}
                                        onSelect={this.onSelect}
                                        isRefresh={this.state.loadHelpTopic}
                                        stateKey="helpTopics"
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadHelpTopic: false,
                                          });
                                        }}
                                        staticData={this.state.helpTopicList}
                                        //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {/*
                                                                    <ItemGrid xs={12} md={6} >

                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Package
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.packageId}
                                                                                    onSelect={this.onSelect}
                                                                                    stateKey="packageId"
                                                                                    getName={(name)=>{
                                                                                        this.setState({packageName:name})
                                                                                    }}
                                                                                    staticData={this.state.packageList}
                                                                                    isRefresh={this.state.loadPackage}
                                                                                    setIsRefresh={(val)=>{
                                                                                        this.setState({loadPackage:false})
                                                                                    }}
                                                                                    //  url={serverApi.SEARCH_JUTE_STATUS}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
*/}

                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        // disabled={true}
                                        id="amt"
                                        value={this.state.amt}
                                        //  className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.addCustomerPopUp ? (
                    <Popup
                      onClickSave={() => this.onCreateCustomerClick()}
                      closePopup={() => this.closeCustomerPopUp()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} md={4}>
                                <InputLabel className={classes.label}>
                                  Name
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} md={8}>
                                <TextField
                                  id="name"
                                  value={this.state.name}
                                  className={classes.textField}
                                  onChange={(event) => {
                                    this.onChangeValues(event, "AN");
                                  }}
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} md={4}>
                                <InputLabel className={classes.label}>
                                  Contact Person
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} md={8}>
                                <TextField
                                  id="contactPerson"
                                  value={this.state.contactPerson}
                                  className={classes.textField}
                                  onChange={(event) => {
                                    this.onChangeValues(event, "AN");
                                  }}
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} md={4}>
                                <InputLabel className={classes.label}>
                                  Contact No
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} md={8}>
                                <TextField
                                  id="contactNo"
                                  value={this.state.contactNo}
                                  className={classes.textField}
                                  onChange={(event) => {
                                    this.onChangeValues(event, "AN");
                                  }}
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} md={4}>
                                <InputLabel className={classes.label}>
                                  Billing Address
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} md={8}>
                                <TextField
                                  id="address"
                                  value={this.state.address}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} md={4}>
                                <InputLabel className={classes.label}>
                                  Email ID
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} md={8}>
                                <TextField
                                  id="emailId"
                                  value={this.state.emailId}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {this.state.ledger_data !== undefined ? (
                            <ItemGrid xs={12} md={6}>
                              <GridContainer>
                                <ItemGrid xs={12} md={4}>
                                  <InputLabel className={classes.label}>
                                    Ledger Group
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} md={8}>
                                  <SelectboxTemp
                                    // isDisabled={this.state.updateView}
                                    defValue={this.state.ledgerGroup}
                                    name={"ledgerGroup"}
                                    value={this.state.ledgerGroup}
                                    onSelect={this.onSelect}
                                    staticData={this.state.ledger_data}
                                    stateKey="ledgerGroup"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          ) : null}
                        </GridContainer>
                      }
                    />
                  ) : null}

                  {this.state.customerPopup ? (
                    <Popup
                      onClickSave={() => this.onCustomerButtonClick()}
                      closePopup={() => this.handleCustomerPopup()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <GridContainer>
                          {this.state.customerList.length > 0 ? (
                            this.state.customerList.map((prop, key) => {
                              return (
                                <ItemGrid xs={12} sm={12}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          this.state.selectedValue ===
                                          prop.id + ""
                                        }
                                        onChange={(event) => {
                                          this.setState({
                                            selectedValue: event.target.value,
                                            customerId: prop.id,
                                            ledger: prop.ledger,
                                            customerName: prop.name,
                                            customer: prop.contactNo,
                                            reloadLedger: true,
                                          });
                                        }}
                                        value={prop.id}
                                        name="radio button demo"
                                        aria-label="A"
                                        icon={
                                          <FiberManualRecord
                                            className={classes.radioUnchecked}
                                          />
                                        }
                                        checkedIcon={
                                          <FiberManualRecord
                                            className={classes.radioChecked}
                                          />
                                        }
                                        classes={{
                                          checked: classes.radio,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label,
                                    }}
                                    label={
                                      prop.name +
                                      " | " +
                                      prop.emailId +
                                      " | " +
                                      prop.contactNo
                                    }
                                  />
                                </ItemGrid>
                              );
                            })
                          ) : (
                            <GridContainer>
                              <ItemGrid xs={12} sm={12}>
                                <span>
                                  {" "}
                                  No registered Customer found on the mobile
                                  number. Please click on Plus Icon and create
                                  Customer.{" "}
                                </span>
                                <IconButton
                                  onClick={this.createCustomer}
                                  color="success"
                                  right={true}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </ItemGrid>
                            </GridContainer>
                          )}
                        </GridContainer>
                      }
                    />
                  ) : null}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Customer Phone
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="customer"
                          value={this.state.customer}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "PH");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {!this.state.updateView ? (
                                    <Search
                                      className={classes.inputAdornmentIcon}
                                      onClick={this.getCustomerDetails}
                                    />
                                  ) : null}
                                </span>
                              </InputAdornment>
                            ),
                            placeholder: "Enter Phone and click on search",
                            maxLength: "10",
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Customer Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="customerName"
                          value={this.state.customerName}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Job Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="outlined" fullWidth>
                          <Datetime
                            isValidDate={valid}
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "jobDate");
                            }}
                            formControlProps={{
                              height: "10px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "jobDate",
                              value: this.state.jobDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Customer
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.customerId}
                                                    onSelect={this.onSelect}
                                                    stateKey="customerId"
                                                    staticData={this.state.customerList}
                                                    isRefresh={this.state.isCustomer}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({isCustomer:false})
                                                    }}
                                                    //  url={serverApi.SEARCH_JUTE_STATUS}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                  {/*
                                    <ItemGrid xs={12} md={6} >

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Package
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.packageId}
                                                    onSelect={this.onSelect}
                                                    stateKey="packageId"
                                                    staticData={this.state.packageList}
                                                    isRefresh={this.state.loadPackage}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadPackage:false})
                                                    }}
                                                    //  url={serverApi.SEARCH_JUTE_STATUS}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}

                  <ItemGrid xs={12} md={12}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Variant
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.variantId}
                              onSelect={this.onSelect}
                              getName={(name) => {
                                name = name.split("^");
                                this.setState({
                                  variantDesc: name[0],
                                  itemGroupId: name[1],
                                });
                                var data = {
                                  itemGroupId: name[1],
                                  saleable: "",
                                  consumable: "",
                                  companyId: this.props.state.companyInfo.id,
                                };
                                requestList(
                                  serverApi.GET_ITEMS_BY_GRPID,
                                  data,
                                  this.handleItem
                                );
                              }}
                              isRefresh={this.state.loadItemGroup}
                              stateKey="variantId"
                              setIsRefresh={(val) => {
                                this.setState({ loadItemGroup: false });
                              }}
                              staticData={this.state.itemGroupsList}
                              //  url={serverApi.SEARCH_ITEM_GROUPS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={6} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Product
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.productId}
                              onSelect={this.onSelect}
                              getName={this.getitemDetails}
                              stateKey="productId"
                              isRefresh={this.state.loadItem}
                              setIsRefresh={(val) => {
                                this.setState({ loadItem: false });
                              }}
                              staticData={this.state.ItemOptions}
                              //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      {this.state.propertyList !== null
                        ? this.state.propertyList.map((prop, i) => {
                            return (
                              <ItemGrid xs={6} style={{ marginTop: "10px" }}>
                                <GridContainer>
                                  <ItemGrid xs={4}>
                                    <InputLabel className={classes.label}>
                                      {prop.name}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={8}>
                                    <TextField
                                      id="value"
                                      className={classes.textField}
                                      onChange={(event) => {
                                        var list = this.state.propertyList;
                                        var data = list;
                                        if (
                                          event.target.value.length <=
                                          prop.maxLenght
                                        ) {
                                          data[i].value = event.target.value;
                                        }
                                        list = data;
                                        this.setState({ propertyList: list });
                                      }}
                                      value={prop.value}
                                      fullWidth={true}
                                      iType={prop.dataType}
                                      decimal={3}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            );
                          })
                        : null}

                      <ItemGrid xs={6} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Complaint
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              defValue={this.state.complaintId}
                              onSelect={this.onSelect}
                              getName={(name) => {
                                this.setState({ complaintDesc: name });
                              }}
                              stateKey="complaintId"
                              isRefresh={this.state.loadProblem}
                              setIsRefresh={(val) => {
                                this.setState({ loadProblem: false });
                              }}
                              staticData={this.state.problemList}
                              //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={12}>
                            <InputLabel className={classes.label}>
                              Help Topics
                            </InputLabel>
                          </ItemGrid>

                          {this.state.helpTopicList !== null
                            ? this.state.helpTopicList.map((prop, i) => {
                                return (
                                  <ItemGrid xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          tabIndex={-1}
                                          value={
                                            this.state.helpTopics.indexOf(
                                              prop.value
                                            ) > -1
                                          }
                                          checked={
                                            this.state.helpTopics.indexOf(
                                              prop.value
                                            ) > -1
                                          }
                                          onChange={() => {
                                            var list = this.state.helpTopics;
                                            var data = list;
                                            if (data.indexOf(prop.value) > -1)
                                              data.splice(
                                                data.indexOf(prop.value),
                                                1
                                              );
                                            else data.push(prop.value);
                                            list = data;
                                            this.setState({ helpTopics: list });
                                          }}
                                          // onClick={() => this.handleToggle(prop)}
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                          }}
                                          style={{ marginLeft: 10 }}
                                        />
                                      }
                                      classes={{
                                        label: classes.menulabel,
                                      }}
                                      label={prop.label}
                                    />
                                  </ItemGrid>
                                );
                              })
                            : null}
                        </GridContainer>
                      </ItemGrid>

                      {/*
                                            <ItemGrid xs={6} sm={6}  style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Help Topics
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxMultiple
                                                            defValue={this.state.helpTopics}
                                                            onSelect={this.onSelect}
                                                            isRefresh={this.state.loadHelpTopic}
                                                            stateKey="helpTopics"
                                                            setIsRefresh={(val) => {
                                                                this.setState({loadHelpTopic: false})
                                                            }}
                                                            staticData={this.state.helpTopicList}
                                                            //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
*/}
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Package
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.packageId}
                          onSelect={this.onSelect}
                          stateKey="packageId"
                          getName={(name) => {
                            this.setState({ packageName: name });
                          }}
                          staticData={this.state.packageList}
                          isRefresh={this.state.loadPackage}
                          setIsRefresh={(val) => {
                            this.setState({ loadPackage: false });
                          }}
                          //  url={serverApi.SEARCH_JUTE_STATUS}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Amount
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // disabled={true}
                          id="amt"
                          value={this.state.amt}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/*
                                    {this.state.propertyList.length>0?<ItemGrid xs={12}  style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={4}>
                                                <InputLabel className={classes.label}>
                                                    Properties:
                                                </InputLabel>
                                            </ItemGrid>

                                            <ItemGrid xs={4}>
                                                <InputLabel className={classes.label}>
                                                    Property Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={4}>
                                                <InputLabel className={classes.label}>
                                                    Value
                                                </InputLabel>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>:null}
*/}

                  {/*
                                    <ItemGrid xs={12} style={{maxHeight:250,overflowY:"auto",overflowX:"hidden"}}>
                                        <GridContainer>
                                            {this.state.propertyList.length>0?
                                                this.state.propertyList.map((prop,i)=>{
                                                    return(<ItemGrid xs={12}>
                                                        <GridContainer>
                                                            <ItemGrid xs={4}>
                                                                {i+1}
                                                            </ItemGrid>

                                                            <ItemGrid xs={4}>
                                                                {prop.productPropName}
                                                            </ItemGrid>
                                                            <ItemGrid xs={4}>
                                                                {prop.value}
                                                            </ItemGrid>

                                                        </GridContainer>
                                                    </ItemGrid>)
                                                }):null
                                            }

                                        </GridContainer>
                                    </ItemGrid>
*/}

                  {/*
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer>

                                            {propertyList.length>0?  <ItemGrid xs={12}>
                                                <LineItemTable
                                                    loading={this.state.loading}
                                                    data={propertyList}
                                                    columns={[
                                                        {
                                                            Header: "PropertyName",
                                                            accessor: "productPropName",
                                                            minWidth: 160,
                                                            style: {color: "#000", textAlign: "center"}

                                                        },
                                                        {
                                                            Header: "Value",
                                                            accessor: "value",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "center"}
                                                        }
                                                    ]}

                                                />
                                            </ItemGrid>:null}

                                        </GridContainer>
                                    </ItemGrid>
*/}

                  {/*
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer>

                                            {PackageMasterList.length>0?  <ItemGrid xs={12}>
                                                    <LineItemTable
                                                        loading={this.state.loading}
                                                        data={this.getDataTable()}
                                                        columns={[
                                                            {
                                                                Header: "Variant",
                                                                accessor: "variantDesc",
                                                                minWidth: 160,
                                                                style: {color: "#000", textAlign: "center"}

                                                            },
                                                            {
                                                                Header: "Product",
                                                                accessor: "productDesc",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "center"}
                                                            },
                                                            {
                                                                Header: "Package",
                                                                accessor: "packageName",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "center"}
                                                            },
                                                            {
                                                                Header: "Complaint",
                                                                accessor: "complaintDesc",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "center"}
                                                            },
                                                            {
                                                                Header: "Amount",
                                                                accessor: "amt",
                                                                minWidth: 100,
                                                                style: {color: "#000", textAlign: "center"}
                                                            },
                                                            {
                                                                Header: "Actions",
                                                                minWidth: 100,
                                                                accessor: "actions",
                                                                style: {color: "#000", textAlign: "center"},
                                                                sortable: false,
                                                                filterable: false,
                                                            }


                                                        ]}

                                                    />
                                                </ItemGrid>:null}

                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        {this.state.approveButton ?
                                                            <ItemGrid xs={12}>
                                                                <Button color={"info"} right={true}
                                                                        onClick={this.handleAddItem}>Add Item</Button>
                                                            </ItemGrid> : null}
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                        Item</Button>
                                                </ItemGrid>

                                            }
                                        </GridContainer>
                                    </ItemGrid>
*/}
                  {/*
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                <InputLabel className={classes.label}>
                                                    Subscription
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={this.state.subscription?1:0}
                                                            onChange={(event)=>{
                                                                this.setState({
                                                                    subscription:true});
                                                            }}
                                                            name="radio button demo"
                                                            aria-label="A"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label
                                                    }}
                                                    label={"Yes"}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={!this.state.subscription?1:0}
                                                            onChange={(event)=>{
                                                                this.setState({
                                                                    subscription:false});
                                                            }}
                                                            name="radio button demo"
                                                            aria-label="A"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label
                                                    }}
                                                    label={"No"}
                                                />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Advance Amount
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="advamt"
                          value={this.state.advamt}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Category
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.category}
                          onSelect={this.onSelect}
                          stateKey="category"
                          staticData={Categories}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Type</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.type}
                          onSelect={this.onSelect}
                          stateKey="type"
                          staticData={JobTypes}
                          value={this.state.type}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Estimated Amount
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id={"estimation"}
                          value={this.state.estimation}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
                    <InputLabel className={classes.label}>
                      Carriers
                      <span style={{ color: "red", fontSize: "12px" }}>*</span>
                    </InputLabel>
                    <GridContainer>
                      {this.state.carrierList.map((prop, key) => {
                        return (
                          <ItemGrid xs={12} sm={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={prop.active ? 1 : 0}
                                  checked={prop.active ? 1 : 0}
                                  onChange={this.handleChange(key)}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={prop.label}
                            />
                            <br />
                            {/*
                                                        <span>{prop.remark}</span>
*/}
                          </ItemGrid>
                        );
                      })}
                    </GridContainer>
                  </ItemGrid>

                  {/*
                                    <ItemGrid xs={6} sm={6}  style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Carriers<span style={{
                                                    color: "red",
                                                    fontSize: "12px"
                                                }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxMultiple
                                                    defValue={this.state.jobCarriers}
                                                    onSelect={this.onSelect}
                                                    isRefresh={this.state.loadCarrier}
                                                    stateKey="jobCarriers"
                                                    setIsRefresh={(val) => {
                                                        this.setState({loadCarrier: false})
                                                    }}
                                                    staticData={this.state.carrierList}
                                                    //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {this.state.status !== 3 &&
                        this.state.status !== 4 &&
                        this.state.status !== 6 ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                this.onUpdateButtonClick(0);
                              }}
                            >
                              Update
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.approveButton ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                this.onUpdateButtonClick(this.state.status);
                              }}
                            >
                              Approve
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.approveButton ? (
                          <ItemGrid>
                            <Button
                              color="danger"
                              onClick={() => {
                                this.onUpdateButtonClick(4);
                              }}
                            >
                              Reject
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.status === 3 ? (
                          <ItemGrid>
                            <Button
                              color="danger"
                              onClick={() => {
                                this.onUpdateButtonClick(6);
                              }}
                            >
                              Cancel
                            </Button>
                          </ItemGrid>
                        ) : null}
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Customer Name
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="customerName1"
                                value={this.state.customerName1}
                                className={classes.textField}
                                onChange={(event) => {
                                  this.onChangeValues(event);
                                }}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <Button
                          color="primary"
                          right={true}
                          onClick={this.handleSeacrh}
                        >
                          Search
                        </Button>

                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <IconButton
                          onClick={this.handleCreate}
                          color="success"
                          right={true}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        //url={serverApi.DATATABLE_SERVICE_URL+"/getAllIndent"}
                        //   searchData={this.getData()}
                        staticData={this.state.tableList}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"Package_List"}
                        actionType={VIEW}
                        showDelete={false}
                        // onDeleteClick={this.onDeletePackageMaster}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(JobsNew)
);
