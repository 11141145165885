import React from "react";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  serverVars,
  configNames,
  getConfigValue,
  getDecimal,
} from "../../helper/Consts";
import axios from "axios";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList, uploadFile } from "../../server/server";
import moment from "moment";
import { getCookie, removeCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {
  getMenuPermission,
  IsTallyAvailable,
} from "../CustumComponents/PermissionAlert";

import { invoiceTypesList, frequency } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { connect } from "react-redux";

class reOccuringInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      customerName: null,
      invoiceId: "",
      customerName: "",
      fromDate: "",
      toDate: "",
      frequency: "",
      amount: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onChangeValues = this.onChangeValues.bind(this);
    // this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };

    requestList(serverApi.CUSTOMER_MASTER, data, this.handelCustomer);
    this.getInvoiceTableList();
  }
  getInvoiceTableList = () => {
    var data = this.getInvoiceData();
    requestList(serverApi.INVOICE_LIST, data, this.handleInvoice);
  };

  handleInvoice = (resp) => {
    if (resp.data) {
      var List = [];
      resp.data.forEach((prop) => {
        if (resp.data.length > 0) {
          console.log("printing the invoice list data", resp.data);
          var obj = {
            label: prop.invoiceNoString,
            value: prop.invoiceId,
            name: prop.invoiceNoString,
          };
          List.push(obj);
          console.log("printing invoice", List);
          this.setState({ invoiceList: List, loadInvioce: true });
        } else {
          this.setState({ invoiceList: [], loadInvioce: true });
        }
      });
    }
  };

  getInvoiceData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      cusId: this.state.customerId,
      invoiceNo: this.state.invoiceNo,
      status: this.state.status,
      type: this.state.invoiceType,
      companyId: this.props.state.companyInfo.id,
      acYear: this.state.year,
    };
    return data;
  };

  handelCustomer = (resp) => {
    var data = resp.data,
      customerList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      customerList.push(obj);
      data.forEach((prop) => {
        var name =
          prop.name +
          "^" +
          prop.address +
          "^" +
          prop.shippingAddress +
          "^" +
          prop.contactNo +
          "^" +
          prop.alternateContactNo +
          "^" +
          prop.gstNo +
          "^" +
          prop.state +
          "^" +
          prop.billingStateCode +
          "^" +
          prop.shippingStateCode +
          "^" +
          prop.state2 +
          "^" +
          prop.internalCompanyId +
          "^" +
          prop.finalStatus;
        var obj = { label: prop.name, value: prop.id, name: name };
        customerList.push(obj);
      });
      this.setState({
        customerList: customerList,
        isCustomer: true,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    let userData = {
      invoiceId: this.state.invoiceId,
      customerName: this.state.customerName,
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      frequency: this.state.frequency,
    };
    axios.get(userData);
    console.log("response----", userData);
  };

  createHandler = (e) => {
    e.preventDefault();
    let userData = {
      invoiceId: this.state.invoiceId,
      customerName: this.state.customerName,
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      frequency: this.state.frequency,
    };
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getInvoiceTableList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
    if (
      id === "quoteDate" &&
      !this.state.updateView &&
      typeof dateObj === "object"
    ) {
      var data = {
        fromDate: "",
        toDate: dateObj.format("DD-MM-YYYY"),
        cusId: "",
        saleNo: "",
        status: "3",
        companyId: this.props.state.companyInfo.id,
        acYear: getCookie(cons.ACADEMIC_YEAR),
      };
      requestList(serverApi.SALE_ORDER_LIST, data, this.handleSales);
    }

    //  this.dateChanged();
  };

  dateChanged = (date) => {
    var myDate = date;
    if (myDate !== null && myDate !== undefined && myDate !== "") {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    } else {
      return;
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  handleCustomer = (resp) => {
    var addressList = [];
    if (resp.customerLineitems) {
      resp.customerLineitems.forEach((prop) => {
        var obj = {
          label: prop.address,
          value: prop.address,
          name:
            prop.address +
            "^" +
            prop.state +
            "^" +
            prop.stateCode +
            "^" +
            prop.gstNo,
        };
        addressList.push(obj);
      });
    }
    if (addressList.length === 0) {
      this.setState({
        gstNo: "",
        gstNo2: "",
        billingStateCode: "",
        shippingStateCode: "",
        billingStateName: "",
        shippingStateName: "",
        billingAddress: "",
        shippingAddress: "",
      });
    } else {
      var taxTypes = getConfigValue(configNames.TAX_TYPES);
      if (this.state.taxType === "") {
        if (taxTypes !== "1") {
          taxTypes = JSON.parse(taxTypes);
          var taxtype = { value: 2, label: "CGST & SGST", name: "CGST & SGST" };

          if (taxTypes.gst === 1) {
            if (
              getCookie(cons.COMPANY_STATECODE) ===
                resp.customerLineitems[0].stateCode + "" &&
              resp.customerLineitems[0].stateCode !== null
            ) {
              this.setState({ taxType: taxtype });
              this.handleSelectedUnitValue(taxtype);
            } else {
              taxtype = { value: 1, label: "IGST", name: "IGST" };
              this.setState({ taxType: taxtype });
              this.handleSelectedUnitValue(taxtype);
            }
          }
        }
      }
      if (
        this.state.billingAddress === "" &&
        this.state.shippingAddress === ""
      ) {
        this.setState({
          gstNo: resp.customerLineitems[0].gstNo,
          gstNo2: resp.customerLineitems[0].gstNo,
          billingStateCode: resp.customerLineitems[0].stateCode,
          shippingStateCode: resp.customerLineitems[0].stateCode,
          billingStateName: resp.customerLineitems[0].state,
          shippingStateName: resp.customerLineitems[0].state,
          billingAddress: resp.customerLineitems[0].address,
          shippingAddress: resp.customerLineitems[0].address,
        });
      }
    }
    this.setState({ customerAddressList: addressList, loadAddress: true });
  };

  // requestList(serverApi.SALE_ORDER_LIST, data, this.handleSales);
  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    // this.getInvoiceTableList();

    if (stateKey === "invoiceId") {
      getDataFromUrl(
        serverApi.INVOICE_DETAILS_BY_ID +
          selectedValue +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
    // this.hideAlert();
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  //log

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getInvoiceTableList();
    }
    this.setState({
      billPassStatus: "",
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    var obj = JSON.parse(id);
    // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.INVOICE_DETAILS_BY_ID +
          obj.invoiceId +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp.invoiceHdr;
    console.log("invoicevalue", header);
    var list = resp.inVoiceLineItems;
    var indentlist = [],
      filesList = [],
      totalValue = 0;
    var sum_of_tax = 0,
      sumOfamt = 0;

    if (list !== null) indentlist = [];
    list.forEach((prop, key) => {
      var itemAmount = parseFloat(prop.amountWithoutTax);
      sumOfamt = sumOfamt + itemAmount;
      var taxPercentage = 0;
      if (prop.igstPer > 0) {
        // taxType = 1;
        taxPercentage = prop.igstPer;
      } else {
        // taxType = 2;
        taxPercentage = prop.cgstPer + prop.sgstPer;
      }

      var obj = {
        id: prop.customerName,
        itemCode: prop.itemId !== null ? prop.itemId + "" : "",
        itemName: prop.itemName,
        itemGrp: prop.itemId !== null ? prop.itemGroup + "" + prop.itemId : "",
        qualityId: prop.qualityId,
        amount: getDecimal(prop.grandTotal),
      };
      indentlist.push(obj);
    });
    // var invoiceUrls=resp.billDocsUrl;

    // console.log("indentWeight " + header.indentWeight);
    if (header !== null) {
      // alert(header.agentId);

      this.setState({
        customerName: header.customerName,
        invoiceAmount: header.invoiceAmount,
      });
    }
  };

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteBill) {
      //this.handleLoading();
      getDataFromUrl(
        serverApi.ACCOUNT_BILLS_DELETE +
          this.state.invoiceUniqueNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handleAddIndent
      );
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  data.splice(this.state.id, 1);
      // data[this.state.id].isActive=0;

      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        } else {
          if (o.isActive === 1) {
            if (o.taxPercentage > 0)
              sum_of_tax = sum_of_tax + parseFloat(o.taxamount);
            sumOfamt = sumOfamt + parseFloat(o.amount);
          }
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        totalTax: sum_of_tax.toFixed(2),
        totalAmount: sumOfamt.toFixed(2),
        netTotal: sumOfamt + sum_of_tax,
        sumOfamt: sumOfamt + sum_of_tax,
      });
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("HH:mm"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };

  timeChangedFormat = (myDate, time) => {
    console.log("time", myDate + time);
    if (time === "" && time === null) time = "00:00:00";

    if (myDate !== "" && myDate !== null) {
      myDate = myDate.split("-");
      var timeParts = time.split(":");
      return new Date(
        myDate[2],
        myDate[1] - 1,
        myDate[0],
        timeParts[0],
        timeParts[1],
        timeParts[2]
      ).getTime();
    } else return "";
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      invoiceStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onUpdateButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });

      this.onUpdateButtonClick(invoiceStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        22,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }

  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  //add Item
  onClickAddItem() {
    var arr = this.state.invoiceList;
    //  console.log("taxPercentage " + this.state.taxPercentage);
    var itemIndex = 0,
      sum_of_tax = 0,
      sumOfamt = 0,
      hasItem = false;
    if (this.state.updateItem) {
      itemIndex = this.state.id;

      if (this.state.taxPercentage > 0) {
        var claim = 0;
        if (
          this.state.claimAmountDtl &&
          this.state.claimAmountDtl !== "null" &&
          this.state.claimAmountDtl !== undefined
        ) {
          claim = parseFloat(this.state.claimAmountDtl);
        }
        arr[itemIndex].taxamount = (
          ((parseFloat(this.state.amount) - claim) *
            parseFloat(this.state.taxPercentage)) /
          100
        ).toFixed(2);
        arr[itemIndex].taxPercentage = this.state.taxPercentage;
      } else arr[itemIndex].taxamount = 0;

      arr[itemIndex].amount = parseFloat(this.state.amount);
      console.log("taxamount ", arr[itemIndex].taxamount);
    } else {
      itemIndex = this.state.invoiceList.length;

      if (this.state.itemTaxAmt > 0)
        sum_of_tax = sum_of_tax + parseFloat(this.state.itemTaxAmt);
      sumOfamt = sumOfamt + parseFloat(this.state.amount);
    }

    var hasHandlingCharges = false;
    arr.map((q) => {
      if (q.isActive === 1) {
        if (q.itemName === "Second Stage Handling Charges") {
          hasHandlingCharges = true;
        }
        console.log("amount", q.amount + " aa");
        // sumOfQty = sumOfQty+parseInt(q.quantityType);
        var taxa = 0;
        if (q.taxPercentage > 0) {
          var claim = 0;
          if (
            q.claimAmountDtl !== null &&
            q.claimAmountDtl !== "" &&
            q.claimAmountDtl !== undefined
          ) {
            claim = parseFloat(q.claimAmountDtl);
          }
          taxa = (
            ((parseFloat(q.amount) - claim) * parseFloat(q.taxPercentage)) /
            100
          ).toFixed(2);

          sum_of_tax = sum_of_tax + parseFloat(taxa);
        }
        sumOfamt = sumOfamt + parseFloat(q.amount);
      }
    });

    var addItemObj = {
      id: itemIndex,
      itemCode: this.state.itemCode,
      itemName: this.state.itemName,
      itemGroup: this.state.printingPop ? "" : this.state.itemGroup,
      itemGroupName: this.state.printingPop ? "" : this.state.itemGroupName,
      itemGrp: this.state.printingPop
        ? ""
        : this.state.itemGroup + "" + this.state.itemCode,
      qualityId: this.state.qualityId,
      qualityName: this.state.qualityName,
      description: this.state.description,
      quantity: this.state.quantity,
      uom: this.state.uom,
      uom2: this.state.uomDcs2,
      uomQuantity1: this.state.uomQuantity1,
      uom3: this.state.uomDcs3,
      uomQuantity2: this.state.uomQuantity2,
      rate:
        this.state.rate !== "" ? parseFloat(this.state.rate).toFixed(2) : 0.0,
      hsn: this.state.hsn,
      amount:
        this.state.amount !== ""
          ? parseFloat(this.state.amount).toFixed(2)
          : 0.0,
      lineItemId: this.state.lineItemId,
      isgst: this.state.igstPercent,
      ssgst: this.state.sgstPercent,
      csgst: this.state.cgstPercent,
      taxId: this.state.taxId,
      taxPercentage: this.state.taxPercentage,
      taxamount: this.state.itemTaxAmt,
      make: this.state.make,
      bales: this.state.bales,
      isActive: 1,
      packingWithIdentificationNo: this.state.packingWithIdentificationNo,
      balesSrlNo: this.state.balesSrlNo,
      costFactor: this.state.costFactor,
      costFactorDes: this.state.costFactorDes,
      claimDesc: this.state.claimDesc,
      claimAmountDtl: this.state.claimAmountDtl,
      saleDrums: this.state.saleDrums,
      saleBales: this.state.saleBales,
      saleWeight:
        this.state.mrNo > 0 ? this.state.saleWeight : this.state.quantity,
      mrLineId: this.state.mrLineId,
      acceptedWeight: this.state.acceptedWeight,
      claimRate: this.state.claimRate,
      saleLineitemId: this.state.saleLineitemId,
      saleLineId: this.state.saleLineId,
      weightOfBale: this.state.weightOfBale,
      NoOfBags: this.state.NoOfBags,
      weightOfBag: this.state.weightOfBag,
      rateType: this.state.rateType,
    };
    var istaxValid = false;
    if (this.state.taxType.value === 4) istaxValid = true;
    else {
      if (
        this.state.taxId !== "" &&
        this.state.taxId !== null &&
        this.state.taxId !== 0
      )
        istaxValid = true;
    }
    if (this.state.printingPop) {
      if (
        this.state.itemName !== "" &&
        this.state.rate !== "" &&
        this.state.quantity !== "" &&
        this.state.amount !== "" &&
        istaxValid &&
        this.state.description !== ""
      ) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);

        console.log("sumOfamt", sumOfamt);
        console.log("sum_of_tax", sum_of_tax);

        this.setState({
          loading: true,
          invoiceList: arr,
          totalTax: sum_of_tax.toFixed(2),
          totalAmount: sumOfamt.toFixed(2),
          netTotal: sumOfamt + sum_of_tax,
          sumOfamt: sumOfamt + sum_of_tax,
          updateItem: false,
        });
        this.hideAlert();
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    } else {
      if (!this.state.updateItem && hasHandlingCharges) {
        this.basicAlert(
          "Please Remove Second Stage Handling Charges For Adding New Item..!!"
        );
      } else {
        if (
          this.state.rate > 0 &&
          this.state.quantity > 0 &&
          this.state.itemCode !== "" &&
          this.state.itemCode !== "0" &&
          this.state.itemGroup !== "" &&
          this.state.itemGroup !== 0 &&
          this.state.amount !== "" &&
          istaxValid
        ) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else {
            arr.splice(arr.length, 0, addItemObj);
          }

          console.log("sumOfamt", sumOfamt);
          console.log("sum_of_tax", sum_of_tax);

          this.setState({
            loading: true,
            invoiceList: arr,
            totalTax: sum_of_tax.toFixed(2),
            totalAmount: sumOfamt.toFixed(2),
            netTotal: sumOfamt + sum_of_tax,
            sumOfamt: sumOfamt + sum_of_tax,
            updateItem: false,
          });

          this.hideAlert();
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      }
    }
    this.handleSelectedUnitValue(this.state.taxType);
    var stateKey = "tcsPercentage";
    var selectedValue = this.state.tcsPercentage;
    this.onSelect(selectedValue, stateKey);
  }
  getInvoicePdf = () => {};
  render() {
    const { classes } = this.props;
    var valid = function(current) {
      if (isBackDate === "2") return current.isAfter(yester);
      else return true;
    };

    var invoicingModel = "0",
      invoicingPrintModel = "1";
    invoicingModel = getConfigValue(configNames.INVOICING_MODEL);
    invoicingPrintModel = getConfigValue(configNames.INVOICING_PRINT_MODEL);
    //add item button click
    var invoiceOptions = [{ value: "", label: "Select...", name: "Select..." }];
    if (invoicingModel === "0") {
      invoiceOptions.push(invoiceTypesList[0]);
    } else if (invoicingModel === "2") {
      invoiceOptions.push(invoiceTypesList[1]);
    } else {
      invoiceOptions = JSON.parse(invoicingModel);
    }
    var yester = Datetime.moment().subtract(1, "day");
    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    const inputFocus = {
      autoComplete: "off",
    };
    // var invoiceOptions = [{ value: "", label: "Select...", name: "Select..." }];

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12}>
                    <GridContainer className={classes.rectangleBoarder}>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Invoice
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              id={"invoiceId"}
                              onSelect={this.onSelect}
                              stateKey="invoiceId"
                              value={this.state.invoiceId}
                              staticData={this.state.invoiceList}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Customer
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              value={this.state.customerName}
                              defValue={this.state.customerName}
                              id={"customerName"}
                              isRefresh={this.state.customerName}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Start Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "fromDate");
                                }}
                                formControlProps={{
                                  height: "10px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "fromDate",
                                  value: this.state.fromDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              End Date
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                isValidDate={valid}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "deliveryDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "delivery_order_date",
                                  value: this.state.deliveryDate,
                                  autoComplete: "off",
                                  disabled: this.state.updateView,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Frequency
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              onSelect={this.onSelect}
                              staticData={frequency}
                              defValue={this.state.frequency}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Amount
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              value={this.state.invoiceAmount}
                              defValue={this.state.invoiceAmount}
                              id={"invoiceAmount"}
                              isRefresh={this.state.invoiceAmount}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                        <GridContainer justify="center">
                          {this.state.tcsPercentage == "" ? (
                            <ItemGrid xs={12} sm={4} alignItems="right">
                              <Button
                                color="primary"
                                xs={12}
                                sm={4}
                                md={4}
                                style={{ marginBottom: "10px" }}
                                stateKey="tcsPercentage"
                                onClick={this.submitHandler}
                              >
                                Submit
                              </Button>
                            </ItemGrid>
                          ) : null}
                          {this.state.approveStatus ? (
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.setState({
                                    isApprove: true,
                                    isBill: true,
                                  });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Approve..!!"
                                  );
                                }}
                              >
                                Approve
                              </Button>
                            </ItemGrid>
                          ) : null}
                          {this.state.approveStatus ? (
                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.setState({ isReject: true });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Reject..!!"
                                  );
                                }}
                              >
                                Reject
                              </Button>
                            </ItemGrid>
                          ) : null}
                          {(this.state.updateView &&
                            this.state.invoiceStatus !== 3 &&
                            this.state.approveStatus) ||
                          this.state.invoiceStatus === 1 ? (
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.onUpdateButtonClick("");
                                }}
                              >
                                Update
                              </Button>
                            </ItemGrid>
                          ) : null}

                          {!this.state.updateView ? (
                            <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                              <GridContainer justify="center">
                                <ItemGrid>
                                  <Button
                                    color="primary"
                                    onClick={this.createHandler}
                                  >
                                    Create
                                  </Button>
                                </ItemGrid>
                                <ItemGrid>
                                  <Button
                                    color="danger"
                                    onClick={this.handleCreate}
                                    isLoading={this.state.isLoading}
                                  >
                                    Cancel
                                  </Button>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          ) : null}
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}

                      <GridContainer>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Invoice Type
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"invoiceType"}
                                onSelect={this.onSelect}
                                stateKey="invoiceType"
                                staticData={invoiceOptions}
                                value={this.state.invoiceType}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Reoccuring Start Date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Reoccuring End Date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="" fullWidth>
                                <Datetime
                                  isValidDate={valid}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "deliveryDate");
                                  }}
                                  height={"15px"}
                                  formControlProps={{
                                    height: "15px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "delivery_order_date",
                                    value: this.state.deliveryDate,
                                    autoComplete: "off",
                                    disabled: this.state.updateView,
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Frequency
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                onSelect={this.onSelect}
                                defValue={this.state.frequency}
                                staticData={frequency}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        {/* <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                created By
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"customerId"}
                                onSelect={this.onSelect}
                                stateKey="customerId"
                                isRefresh={this.state.isCustomer}
                                setIsRefresh={this.afterRefreshquality}
                                //  url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.customerName}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                created Date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid> */}
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            // round color="white"
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>

                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />
                    <ItemGrid xs={12} />
                    <ItemGrid xs={12} sm={12} style={{ marginTop: "20px" }}>
                      <GridContainer justify="center">
                        <ItemGrid xs={12}>
                          <LineItemTable
                            // data={this.getDataTable()}
                            loading={this.state.loading}
                            disableActions={
                              this.state.quoteStatus === 3 ||
                              this.state.quoteStatus === 4 ||
                              this.state.quoteStatus === 5 ||
                              this.state.quoteStatus === 6
                            }
                            columns={[
                              {
                                Header: "INVOICE",
                                accessor: "invoice",
                                minWidth: 150,
                                style: {
                                  color: "#000",
                                  textAlign: "left",
                                  height: "50px",
                                },
                              },
                              {
                                Header: "CUSTOMER",
                                accessor: "customer",
                                minWidth: 150,
                                style: {
                                  color: "#000",
                                  textAlign: "left",
                                  height: "50px",
                                },
                              },
                              {
                                Header: "AMOUNT",
                                accessor: "amount",
                                minWidth: 130,
                                style: {
                                  color: "#000",
                                  textAlign: "left",
                                  height: "50px",
                                },
                              },
                              {
                                Header: "START DATE",
                                accessor: "startdate",
                                minWidth: 130,
                                style: {
                                  color: "#000",
                                  textAlign: "left",
                                  height: "50px",
                                },
                              },

                              {
                                Header: "END DATE",
                                accessor: "enddate",
                                minWidth: 130,
                                style: {
                                  color: "#000",
                                  textAlign: "right",
                                  height: "50px",
                                },
                              },
                              {
                                Header: "FREQUENCY",
                                accessor: "frequency",
                                minWidth: 130,
                                style: {
                                  color: "#000",
                                  textAlign: "left",
                                  height: "50px",
                                },
                              },
                            ]}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  textFieldRight: {
    textAlign: "right",
    paddingRight: "5px",
    paddingBottom: "10px",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(reOccuringInvoice)
);
