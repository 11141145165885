import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {configNames, getConfigValue, serverApi, serverConfig} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";

import TextField from "../../components/CustomInput/CustomTextField";

import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import moment from "moment";
import {getDataFromUrl, getFileDownload, requestList, uploadFile} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from '@material-ui/icons/Edit';
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import LogsComponents from "../CustumComponents/LogsComponents";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";

class ProjectList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isCreated:false,
            fromDate:"",
            todate:"",
            status:'',
            isRefresh:false,
            isLoading:false,
            itemViewType:"projectId",
            isDisabled:false,
            updateView:false,
            showMessagealert:false,
            alert:false,
            basicAlert:null,
            //selectedIndentType:"",
            //selectedDepartment:"",
            //selectedItemGroup:"",
            //itemGrpName:"",
            //itemName:"",
            //deptName:"",
            //uom:"",
            branchId: "",
            projectId:'',
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            invoiceList:[],
            //indentStatus:"",
            //itemDescription:'',
            //internalNote:"",
            //remarks:"",
            //deptList:[],
            //isNoteMandatory:false,
            //branchStock:0,
            //stock:0,
            //pendingStock:0,
            //minStock:0,
            //itemStock:0,
            //pendingStockDesc:"",
            //filesList:[],
            //lastPurchaseRate:0,lastPurchaseDate:"",
            projectName:'',
            customer:'',
            budgetHead:'',
            persftAmount:'',
            carpetArea:'',
            totalSft:'',
            typeId:0,
            budgetHeadsId:"",
            subHeadsId:"",
            customerId: "",
            fromDate:"",
            todate:"",
            projectbudgetList:[]

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
        //this.handleFileChange = this.handleFileChange.bind(this);
        //this.handleFileClick = this.handleFileClick.bind(this);
    }
componentDidMount(){
    this.getReportsData();
    this.callCustomerList();
    this.getBranchData();
}
getReportsData=()=> {
    getDataFromUrl(serverApi.BUDGET_HEAD_TABLE,this.handlebudgetHeadsName);
}
handlebudgetHeadsName = (resp) => {
    var data = resp.data, budgetHeadsNameList = [];
    if (data.length > 0) {
        var obj = {label: "Select", value: null, name: "null"};
        budgetHeadsNameList.push(obj)
        data.forEach(prop => {
            var name = prop.name;
            var obj = {label: prop.name, value: prop.budgetHeadId, name:prop.name};
            budgetHeadsNameList.push(obj)
        });
        this.setState({
            budgetHeadsNameList: budgetHeadsNameList,
            //loadbudgetHeadsName: true
        })
    }
};
getBranchData=()=> {
    requestList(serverApi.SELECT_BRANCH + this.props.state.companyInfo.id,this.handleBranchName);
}
handleBranchName = (resp) => {
    var data = resp, branchList = [];
    if (data.length > 0) {
        var obj = {label: "Select", value: null, name: "null"};
        branchList.push(obj)
        data.forEach(prop => {
            var name = prop.name;
            var obj = {label: prop.label, value: prop.value, name:prop.name};
            branchList.push(obj)
        });
        this.setState({
            branchList: branchList,
        })
    }
};
callCustomerList() {
    // var data = {
    //     "companyId": this.props.state.companyInfo.id,
    //     "cipher": getCookie(cons.CIPHER)
    // };
    getDataFromUrl(serverApi.GET_CUSTOMER_BY_COMPANY+this.props.state.companyInfo.id, this.handelCustomer)
    
}

handelCustomer = (resp) => {
    var data = resp, customerList = [];
    if (data.length > 0) {
        var obj = {label: "Select", value: null, name: "null"};
        customerList.push(obj);
        data.forEach(prop => {
            var name = prop.name;
            var obj = {label: prop.name, value:prop.value, name: name};
            customerList.push(obj)
        });
        this.setState({
            customerList: customerList,
        })
    }
};
handleLoading = () => {
    this.setState({
        isLoading: !this.state.isLoading,
    });

}

    handleSeacrh = () => {
        //   console.log(this.state);
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleTableLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };
    handleCreate=()=>{
        //alert();
        if(this.state.isCreated){
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "indentType":"store",
            "fromDate":this.state.fromDate
            ,"toDate":this.state.todate,
            "status":this.state.status,
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER),
            "financialYear":getCookie(cons.ACADEMIC_YEAR),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
            "branchId":this.state.branchIdList!==0?this.state.branchIdList:"",
            "projectId":this.state.projectIdList!==0?this.state.projectIdList:""

        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('YYYY-MM-DD')
            });
        else
            this.setState({
                [id] :''
            });

    }

    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value)
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    hideItemAlert() {
        if(getConfigValue(configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS)==="1"){
            this.setState({
                selectedDepartment: "",
                selectedItemGroup:""
            })
        }
        //  console.log("alert");
        this.setState({
            updateItem: false,
            deptName:"",
            itemGrpName:"",
            itemName:"",
            uom:"",
            stock: 0,
            branchStock:0,
            quantity:"",

            selectedItem:"",
            itemDescription:"",
            rate:""
        });
    }

    hideAlert() {
      //  console.log("alert");
        this.setState({
            alert: false,
            loading:false,
            showMessagealert:false,
            //projectName:'',
            //customer:'',
            budgetHead:'',
            budgetHeadsName:'',
            subHeadsName:'',
            persftAmount:'',
            itemGroup:'',
            //carpetArea:'',
            //totalSft:'',
        });
        this.hideItemAlert();
    }
    hideAlertMessage(){
        this.setState({
            isDisabled:false,
            isCreated:false,
            approveStatus:false,
            showMessagealert:false,
            updateView:false,
            alert: false,
            basicAlert:false,
            date1:moment().format('DD-MM-YYYY'),
            createdBy:"",
            createdOn:"",
            invoiceList:[],
            branchId: "",
            projectId:'',
            projectName:'',
            phaseName:'',
            address:'',
            customerId:'',
            carpetArea:'',
            totalSft:'',
            typeId:0,
            fromDate:'',
            todate:''
        });

    }
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };
    
    handleViewClick = (id) => {
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.PROJECT_LIST_BY_ID +"/"+ id, this.handleViewClickResp);
        }
        //console.log(getDataFromUrl(serverApi.PROJECT_LIST_BY_ID +"/"+ id))
    }
    handleViewClickResp=(resp)=>{
        var header = resp,list=[];
        
        if (header !== null) {
            // resp.projectbudgetList.forEach((prop,key)=>{
            //     var obj={
            //     id:key,
            //     "budgetHeadsId": prop.budgetHeadsId,
            //     "budgetHeadsName":prop.budgetHeadsName,
            //     "subHeadsId": prop.subHeadsId,
            //     "subHeadsName":prop.subHeadsName,
            //     "itemGroup":prop.itemGroup,
            //     "persftAmount": prop.persftAmount,
            //     "totalSft": prop.totalSft,
            //     "type":prop.type,  
            //     "budgetAmount":this.getTotal(this.state.persftAmount,this.state.totalSft),
            // };
            //     list.push(obj)
            // });
            this.setState({
                updateView: true,
                isCreated: true,
                projectName: header.name,
                customerId: header.customerId,
                carpetArea: header.carpetArea,
                totalSft:header.sft,
                //sft: header.sft,
                id:header.projectId,
                projectId:header.projectId,
                branchId: header.branchId,
                fromDate: header.startDate,
                todate: header.expectedCloserDate,
                phaseName:header.phaseName,
                projectEstimation: header.totalBudget,                
                companyId: this.props.state.companyInfo.id,
                description: header.projectDescription,
                createdBy: header.createdBy,
                createdOn: header.createdOn,
                address:header.address,
                invoiceList:list,
            });

        }
    }
    
    handleInvoiceFiles=(resp)=>{
        if(resp.data){
            this.setState({filesList:resp.data})
        }
    };
    onSelect = (selectedValue, stateKey) => {
            this.setState({
                [stateKey]: selectedValue
            });
    };
    //approve
    onUpdateButtonClick =() =>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const{invoiceList} = this.state;
            var invoiceList=invoiceList.map((prop, key) => {
                return({
                    "costId":prop.costId,
                    "budgetHeadsId": prop.budgetHeadsId,
                    "budgetHeadsName":prop.budgetHeadsName,
                    "subHeadsId": prop.subHeadsId,
                    "subHeadsName":prop.subHeadsName,
                    "itemGroup":prop.itemGroup,
                    "budgetAmount":this.getTotal(this.state.persftAmount,this.state.totalSft),
                    "persftAmount": prop.persftAmount,
                    "totalSft": prop.totalSft,
                    "type": prop.type,
                    "createdBy": getCookie(cons.USERID)   
                })
            })
            var data = {
                "projectId":this.state.projectId,
                "name": this.state.projectName,
                "description": this.state.projectDescription,
                "customerId": this.state.customerId,
                "customerName": this.state.customerName,
                "startDate": this.state.fromDate,
                "expectedCloserDate": this.state.todate,
                "projectEstimation": this.state.totalBudget,
                "branchId": this.state.branchId,
                "sft":this.state.totalSft,
                "carpetArea": this.state.carpetArea,
                "phaseName": this.state.phaseName,
                "createdBy": getCookie(cons.USERID),
                "companyId": this.props.state.companyInfo.id,
                "address":this.state.address
                // "projectbudgetList":invoiceList
            };
            // if (this.state.projectName !== ""&&this.state.totalSft !==""&&this.state.carpetArea!=="") {
            //     this.handleLoading();
            //     requestList(serverApi.PROJECT_LIST_UPDATE, data, this.handleAddIndent)
            // } else if (this.state.invoiceList.length < 1) {
            //     this.basicAlert("Please Add Atleast One Item..!!");
            // }else{
            //     this.basicAlert("Please Enter All Mandatory Fields..!!");
            // }
            const {projectName,carpetArea,totalSft}=this.state;
            const validateFields = [
                { field: projectName, msg: "Project Name", type: 1 },
                { field: carpetArea, msg: "Carpet Area", type: 1 },
                { field: totalSft, msg: "Super Built Up Area", type: 1 }
              ];
        
              const validObj = validate(validateFields);
              console.log(validObj);
              if (validObj) {
                if (validObj.type === 1) {
                  this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                } else {
                  this.basicAlert(`Please Select ${validObj.message} ..!!`);
                }
              } else {
                this.handleLoading();
                // this.basicAlert("Created Successfully");
                requestList(serverApi.PROJECT_LIST_UPDATE, data, this.handleAddIndent)
              }
        }
    };


    onCreateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            //var mailAlert=getConfigValue(cons.EMAIL);
            //const invoiceList=[]
            const {invoiceList} = this.state;
            var invoiceList=invoiceList.map((prop, key) => {
                return({
                    "budgetHeadsId": prop.budgetHeadsId,
                    "budgetHeadsName":prop.budgetHeadsName,
                    "subHeadsId": prop.subHeadsId,
                    "subHeadsName":prop.subHeadsName,
                    "itemGroup":prop.itemGroup,
                    "budgetAmount":this.getTotal(this.state.persftAmount,this.state.totalSft),
                    "persftAmount": prop.persftAmount,
                    "totalSft": prop.totalSft,
                    "type": prop.type,
                    "createdBy": getCookie(cons.USERID)
                })
            })
            var data = {
                //"code": "PROC",
                "name": this.state.projectName,
                "description": this.state.projectDescription,
                "customerId": this.state.customerId,
                "customerName": this.state.customerName,
                "startDate": this.state.fromDate,
                "expectedCloserDate": this.state.todate,
                "projectEstimation": this.state.totalBudget,
                "branchId": this.state.branchId,
                "sft":this.state.totalSft,
                "carpetArea": this.state.carpetArea,
                "phaseName": this.state.phaseName,
                "createdBy": getCookie(cons.USERID),
                "companyId": this.props.state.companyInfo.id,
                "address":this.state.address
                // "projectbudgetList":invoiceList
            };

            // if (this.state.projectName !== ""&&this.state.totalSft !==""&&this.state.carpetArea!=="") {
            //     this.handleLoading();
            //     requestList(serverApi.PROJECT_LIST_SAVE, data, this.handleAddIndent)
            // } else if (this.state.invoiceList.length < 1) {
            //     this.basicAlert("Please Add Atleast One Item..!!");
            // }else{
            //     this.basicAlert("Please Enter All Mandatory Fields..!!");
            // }
            const {projectName,carpetArea,totalSft}=this.state;
            const validateFields = [
                { field: projectName, msg: "Project Name", type: 1 },
                { field: carpetArea, msg: "Carpet Area", type: 1 },
                { field: totalSft, msg: "Super Built Up Area", type: 1 }
              ];
        
              const validObj = validate(validateFields);
              console.log(validObj);
              if (validObj) {
                if (validObj.type === 1) {
                  this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                } else {
                  this.basicAlert(`Please Select ${validObj.message} ..!!`);
                }
              } else {
                this.handleLoading();
                // this.basicAlert("Created Successfully");
                requestList(serverApi.PROJECT_LIST_SAVE, data, this.handleAddIndent)
              }

        }
    };

    onClickAddItem() {
        var arr = this.state.invoiceList;
        var itemIndex = 0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
        } else {
            itemIndex = this.state.invoiceList.length
        }
        const {budgetHeadsId,subHeadsId,itemGroup,persftAmount,totalSft,type,budgetHeadsName,subHeadsName} = this.state;


        var addItemObj = {
            id:itemIndex,
            "budgetHeadsId": budgetHeadsId,
            "budgetHeadsName":budgetHeadsName,
            "subHeadsId": subHeadsId,
            "subHeadsName": subHeadsName,
            "itemGroup":itemGroup,
            "persftAmount": persftAmount,
            "totalSft": totalSft,
            "type":type,  
            "budgetAmount":this.getTotal(this.state.persftAmount,this.state.totalSft),
        };
        console.log("##########################",addItemObj)
        if (persftAmount!=="") {   
            if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
            } else
                arr.splice(arr.length, 0, addItemObj);
            //console.log(arr.toString());

            this.setState({
                loading: true,
                invoiceList: arr,
            });
            this.hideAlert(); 
        }
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }

    }
    getDataTable = () => {
        var data = [];
        //console.log("data",JSON.stringify(this.state.invoiceList))
        if (this.state.invoiceList.length > 0)
            this.state.invoiceList.forEach((prop, key) => {
                prop.id = key;
                //if (prop.isActive === 1) {
                    var obj = {
                        ...prop,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to remove the data row */}
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                // alert(o.qualityId);
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                //  data.splice(i,1);

                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                this.setState({
                                                    //alert: true,
                                                    updateItem: true,
                                                    updateItemAlert: true,
                                                    id: o.id,
                                                    "budgetHeadsId": o.budgetHeadsId,
                                                    "budgetHeadsName":o.budgetHeadsName,
                                                    "subHeadsId": o.subHeadsId,
                                                    "subHeadsName":o.subHeadsName,
                                                    "itemGroup":o.itemGroup,
                                                    "persftAmount": o.persftAmount,
                                                    "totalSft": o.totalSft,
                                                    "type":o.type,  
                                                    "budgetAmount":this.getTotal(this.state.persftAmount,this.state.totalSft),
                                                });


                                                return true;
                                            }
                                        });

                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                });
                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                return true;
                                            }
                                        });
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>
                            </div>

                        )

                    };


                    data.push(obj)
                //}
            });
        return data;
    };

    getTotalAmount =()=>{
        var totalAmount=0
        var data = this.state.invoiceList;
        data.forEach((prop, key) => {
                    totalAmount = totalAmount + (parseFloat(prop.rate) * parseFloat(prop.quantity) );
        });
        return totalAmount.toFixed(2);
    }
    //invoiceTableSize
    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };
    //addItem
    handleAddItem () {
        // const {classes}=this.props;
         const {selectedIndentType}=this.state;
        if(this.state.projectName !== ""&&this.state.totalSft !==""&&this.state.carpetArea!==""){
         this.setState({
            loading:true,
            alert:true
         });
        }
        else {
            this.basicAlert("Please Select Mandatory Fields..!!");
        }
         
     };
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if(this.state.deleteItem){
            var data = this.state.invoiceList;
            data.splice(this.state.id,1);
            data.map((o,key)=>{
                o.id=key;
                o.actions= (
                    // we've added some custom button actions
                    <div className="actions-right">
                        { /* use this button to remove the data row */ }
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key){
                                        this.basicItemAlert("Do You Like To Update This Item?");

                                        this.setState({
                                            id:o.id,
                                            indentItemId:o.indentItemId,
                                            updateItemAlert:true,
                                            updateItem: true,
                                            deptName:o.department,
                                            itemGrpName:o.itemGroup,
                                            itemName:o.item,
                                            uom:o.unit,
                                            stock: o.stockValue,
                                            itemDescription:o.itemDesc,
                                            quantity:o.quantity,
                                            selectedDepartment: o.deptId,
                                            selectedItemGroup:o.itemGroupId,
                                            selectedItem:o.itemId,
                                            // alert:true,

                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key) {
                                       // if (this.state.approveStatus) {
                                            this.setState({deleteItem: true,
                                                id:o.id,});

                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            //  data.splice(i,1);
                                        //}
                                        return true;
                                    }
                                });
                                this.setState({invoiceList: data});
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>{" "}
                    </div>

                )

            });
            if(data.length===0)
                this.setState({disableDept:false});

            //  if(this.state.approveStatus)
            this.setState({invoiceList: data,
                deleteItem:false});
        }
        this.setState({
            basicAlert: null,
        });

    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    showIndentMessage = (msg,data,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                            {data.map((prop,i)=>{
                                return(
                                    <ItemGrid style={{maxHeight:250,overflowY: "auto" }}>
                                        <legend style={{textAlign:"center"}}>{prop}</legend>
                                    </ItemGrid>
                                )
                            })}
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideItemAlert();

        this.setState({
            basicAlert: null,
            deleteItem:false,
            updateItem:false,
            updateItemAlert:false,
            isReject:false,isApprove:false
        });

    }


    // handleFileClick() {
    //     var input = document.createElement("input");
    //     input.type = "file";
    //     input.onchange = this.handleFileChange;
    //     input.click();
    // }
    // hideInvoiceBasicAlert() {
    //     const {fileName,fileType,file,uploadChallan,uploadInvoice,hdrId,isReject,isApprove,indentStatus}=this.state;

    //     if(isReject){
    //         this.setState({isReject:false});
    //         this.onUpdateButtonClick("4");
    //     }
    //     if(isApprove){
    //         this.setState({isApprove:false});
    //         this.onUpdateButtonClick(indentStatus)
    //     }

    //     if (uploadInvoice) {
    //         uploadFile(fileName,file,fileType,10,fileName,hdrId, this.handleInvoiceResp);
    //         //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
    //         //  this.uploadInvoiceFile();
    //     }
    //     if (this.state.deleteInvoice) {

    //         getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
    //     }
    //     this.setState({
    //         basicAlert1: null,
    //     });

    // }
    // handleDeleteInvoiceResp = (resp) => {
    //     if (resp.status) {
    //             var arr = this.state.filesList;
    //             arr.splice(this.state.fileId, 1);
    //             this.setState({
    //                 filesList: arr,
    //                 deleteInvoice: false,
    //                 fileId: ""
    //             });
    //     }

    // };

//     handleDownloadedFile=(url)=>{
//         window.open(url);
//         //  console.log("url...",url)
//     };
//     handleInvoiceResp = (resp) => {
//         console.log("file resp",resp);
//         var data = resp;
//         if (resp.status) {

// //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

//                 var arr = this.state.filesList;
//                 data={"fileUploadId": data.id,
//                     "fileName": this.state.fileName,fileExtension:this.state.fileType,"retrievalPath":data.fileUrl,sourceMenu:2};
//                 arr.splice(arr.length, 0, data);
//                 this.setState({
//                     uploadInvoice: false,
//                     filesList: arr,
//                     basicAlert: null
//                 });


//         }
//         console.log("resp", JSON.stringify(resp));

//     };
    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }

    // handleFileChange(e) {
    //     e.preventDefault();
    //     let reader = new FileReader();
    //     let file = e.target.files[0];
    //     var fileName = file.name, fileType;
    //     fileName = fileName.split(".");
    //     fileType = fileName[1].toUpperCase();
    //     reader.onloadend = () => {
    //         if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'XLSX') {
    //                 this.setState({
    //                     file: file,
    //                     fileName: file.name,
    //                     fileType:fileName[1],
    //                     //filesList: arr,
    //                     uploadInvoice: true
    //                     // imagePreviewUrl: reader.result
    //                 });
    //                 this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
    //             }


    //     };
    //     reader.readAsDataURL(file);
    // }

    getAmount=(quantity,rate)=>{
        var amt=0;
        if(rate>0&&quantity>0&&quantity!=="0"&&rate!=="0"){
            amt=(parseFloat(rate)*parseFloat(quantity)).toFixed(2)
        }
        return amt;
    };
    getTotal=(totalSft,persftAmount)=>{
        var total=0
        if(totalSft>0&&persftAmount>0){
            total=(parseFloat(totalSft)*parseFloat(persftAmount)).toFixed(2)
        }
        return total;
    }
    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });
        
    };
    render(){
        //console.log("..............................",this.state.customerId)
        const { classes } = this.props;
        const inputFocus={
            autoComplete: 'off'
        };
        var companyId=this.props.state.companyInfo.id;
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var tomorrow = Datetime.moment().add( 0, 'day' );

        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var isfutureDate=getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

        var valid = function( current ){
            if(isBackDate==="2"&&isfutureDate==="2")
                return current.isAfter( yesterday )&&current.isBefore( tomorrow );
            else if(isfutureDate==="2"&&isBackDate==="1"){
                return current.isBefore( tomorrow );
            } else if(isfutureDate==="1"&&isBackDate==="2"){
                return current.isAfter( yesterday );
            }
            else
                return true;
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        const {selectedIndentType}=this.state;
        // var budgetOptions=[{label:"CIVIL & Interior",value:1,name:"CIVIL&INTERIOR"},{label:"ELECTRICAL",value:2,name:"ELECTRICAL"}]
        //var subHeadOptions=[{label:"PAINT",value:1,name:"PAINT"},{label:"CEMENT",value:2,name:"CEMENT"}];
        var typeOptions=[
            {label:"BOQ",value:"BOQ",name:"BOQ"},
            {label:"BOM",value:"BOM",name:"BOM"}
        ];
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <div>
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                                    {/* {this.state.updateView?"":"  Creation Of Indent"} */}

                                </div>

                            }
                            // headerColor={"blue"}
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert?
                                        <Popup
                                        onClickSave={() => this.onClickAddItem()}
                                        closePopup={() => this.hideAlert()}
                                        title="Post Temperature"
                                        isShowSave={true}
                                        isShowCancel={true}
                                        save_btn_txt={"Add"}
                                        cancel_button_text={"Cancel"}
                                        // juteType={selectedJuteType}
                                        content={
                                            <form>
                                                <GridContainer>
                                                    <ItemGrid xs={12} md={12}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={6}>
                                                                <br/>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Budget Head<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            defValue={this.state.budgetHeadsId}
                                                                            id={"budgetHeadsId"}
                                                                            onSelect={this.onSelect}
                                                                            stateKey="budgetHeadsId"
                                                                            staticData={this.state.budgetHeadsNameList}
                                                                            getName={(name)=>{
                                                                                this.setState({budgetHeadsName:name})
                                                                            }}
                                                                            // isRefresh={this.state.loadbudgetHeadsName}
                                                                            // setIsRefresh={(val)=>{
                                                                            //     this.setState({loadbudgetHeadsName:false})
                                                                            // }}
                                                                            value={this.state.budgetHeadsId}
                                                                        />
                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6}>
                                                                <br/>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Sub-Head
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            defValue={this.state.subHeadsId}
                                                                            id={"subHeadsId"}
                                                                            onSelect={this.onSelect}
                                                                            stateKey="subHeadsId"
                                                                            staticData={this.state.budgetHeadsNameList}
                                                                            getName={(name)=>{
                                                                                this.setState({subHeadsName:name})
                                                                            }}
                                                                            // isRefresh={this.state.loadbudgetHeadsName}
                                                                            // setIsRefresh={(val)=>{
                                                                            //     this.setState({loadbudgetHeadsName:false})
                                                                            // }}
                                                                            value={this.state.subHeadsId}
                                                                        />
                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Type
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            // selectDisabled={this.state.disableDept}
                                                                            defValue={this.state.type}
                                                                            id={"budgetType"}
                                                                            onSelect={this.onSelect}
                                                                            staticData={typeOptions}
                                                                            getName={(name)=>{
                                                                                this.setState({type:name})
                                                                            }}
                                                                            stateKey="type"
                                                                            value={this.state.type}
                                                                        />
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>
                                                        
                                                     
                                                    <ItemGrid xs={12} sm={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Item Group/Cost Center
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    id="itemGroup"
                                                                    // disabled={true}
                                                                     className={classes.textField}
                                                                     onChange={this.onChangeValues}
                                                                    value={this.state.itemGroup}
                                                                     fullWidth={true}
                                                                     
                                                                    // variant="outlined"
                                                                    // InputProps={inputFocus}
                                                                />

                                                            </ItemGrid>
                                                            

                                                        </GridContainer>
                                                    </ItemGrid>

                                                            <ItemGrid xs={12} sm={6}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Cost Per Sqft<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="persftAmount"
                                                                             className={classes.textField}
                                                                             onChange={this.onChangeValues}
                                                                            value={this.state.persftAmount}
                                                                             fullWidth={true}
                                                                             iType="number"
                                                                            // variant="outlined"
                                                                            // InputProps={inputFocus}
                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>

                                                            <ItemGrid xs={12} sm={6}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Super built-up area
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="totalSft"
                                                                            disabled={true}
                                                                             className={classes.textField}
                                                                             onChange={this.onChangeValues}
                                                                            value={this.state.totalSft}
                                                                             fullWidth={true}
                                                                             iType="number"
                                                                            // variant="outlined"
                                                                            // InputProps={inputFocus}
                                                                        />
                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Total Amount
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                             id="totalAmount"
                                                                             disabled={true}
                                                                             className={classes.textField}
                                                                             onChange={this.onChangeValues}
                                                                             value={this.getTotal(this.state.persftAmount,this.state.totalSft)}
                                                                             fullWidth={true}
                                                                            // variant="outlined"
                                                                            // InputProps={inputFocus}
                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>

                                                    {/* <ItemGrid xs={6} md={6} style={{marginTop: "10px"}}>

                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Amount<span style={{
                                                                            color: "red",
                                                                            fontSize: "12px"
                                                                        }}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            disabled={true}
                                                                            id="amount"
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            InputProps={inputFocus}
                                                                            style={{height: 30}}
                                                                            value={this.getAmount(this.state.quantity,this.state.rate)}
                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>


                                                        </GridContainer>
                                                    </ItemGrid> */}
                                                </GridContainer>
                                            </ItemGrid>
                                        </GridContainer>


                                            </form>

                                        }
                                    >
                                    </Popup>:null
                                    }
                                    {this.state.basicAlert1}
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Project Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="projectName"
                                                    value={this.state.projectName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Client 
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.customerId}
                                                    id={"customerId"}
                                                    onSelect={this.onSelect}
                                                    stateKey="customerId"
                                                    staticData={this.state.customerList}
                                                    getName={(name)=>{
                                                        this.setState({customerName:name})
                                                    }}
                                                    value={this.state.customerId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Carpet Area<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="carpetArea"
                                                    value={this.state.carpetArea}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    iType="number"
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                Super built-up area<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="totalSft"
                                                    value={this.state.totalSft}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    iType="number"
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    GST
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                {/* <SelectboxTemp
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    //  getName={this.getQualityName}
                                                    getName={(name)=>{
                                                        this.setState({branchId:name})
                                                    }}
                                                    stateKey="branchId"
                                                    // isRefresh={this.state.loadQuality}
                                                    // setIsRefresh={this.afterRefreshQuality}
                                                    url={serverApi.SELECT_BRANCH + this.props.state.companyInfo.id}
                                                    value={this.state.branchId}
                                                /> */}
                                                <SelectboxTemp
                                                    defValue={this.state.branchId}
                                                    id={"branchId"}
                                                    onSelect={this.onSelect}
                                                    stateKey="branchId"
                                                    staticData={this.state.branchList}
                                                    // getName={(label)=>{
                                                    //     this.setState({branchId:label})
                                                    // }}
                                                    value={this.state.branchId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    From Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "fromDate")
                                                        }}
                                                        formControlProps={
                                                            {height: "10px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "fromDate",
                                                            value: this.state.fromDate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    To Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "todate")
                                                        }}

                                                        formControlProps={
                                                            {height: "10px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "toDate",
                                                            value: this.state.todate,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Phase Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="phaseName"
                                                    value={this.state.phaseName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rowsMax="3"
                                                    height={60}
                                                    id="address"
                                                    value={this.state.address}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {/* <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                <LineItemTable
                                                    data={this.getDataTable()}
                                                    loading={this.state.loading}
                                                    //disableActions={this.state.indentStatus==='3'||this.state.indentStatus==='4'||this.state.indentStatus==='5'||this.state.indentStatus==='6'}
                                                    columns={[
                                                        {
                                                            Header: "Budget Head",
                                                            accessor: "budgetHeadsName",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Budget Sub Head",
                                                            accessor: "subHeadsName",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Type",
                                                            accessor: "type",
                                                            minWidth:75,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Item group / Cost center",
                                                            accessor: "itemGroup",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Cost per sft",
                                                            accessor: "persftAmount",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Total sft",
                                                            accessor: "totalSft",
                                                            minWidth:100,
                                                            style: {color: "#000", textAlign: "left",textOverflow: 'unset'}
                                                        },                                                      
                                                        {
                                                            Header: "Total Amount",
                                                            accessor: "budgetAmount",
                                                            width: 150,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                />
                                                {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                                    <span style={{
                                                                        fontStyle: "italic",
                                                                        fontWeight: 400,
                                                                        color: "#003F66"
                                                                    }}></span>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: "#003F66"
                                                            }}>{this.state.statusDesc}<br/></span>
                                                        </ItemGrid>
                                                        <ItemGrid xs={6}>
                                                            <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button>
                                                </ItemGrid>
                                                }
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                                    {/* {this.state.updateView?  <ItemGrid xs={12} sm={12}>
                                        <InputLabel className={classes.label}>
                                            Support Documents :
                                        </InputLabel>
                                    </ItemGrid>:null} */}

                                    {/* {this.state.updateView?<ItemGrid xs={8} style={{overflowY: "auto", maxHeight: 250}}>
                                        {this.state.filesList.map((r, i) => (
                                            <GridContainer style={{marginTop: "10px"}}>
                                                <ItemGrid xs={12} sm={2}>
                                                    {i + 1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                        getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                    }}>
                                                        {r.fileName!==null&&r.fileName!==""?r.fileName:"indent"+(i+1)}
                                                    </a>
                                                </ItemGrid>
                                                <ItemGrid xs={4}>
                                                    <IconButton
                                                        right={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                deleteInvoice: true,
                                                                invoiceId: r.fileUploadId,
                                                                fileId: i
                                                            });
                                                            this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        }}

                                                        color="dangerNoBackground"
                                                    >
                                                        <Close/>
                                                    </IconButton> {""}

                                                </ItemGrid>

                                            </GridContainer>

                                        ))}

                                    </ItemGrid>:null} */}


                                    {/* {this.state.updateView&&this.state.indentStatus!=="4" && this.state.indentStatus!=='3'? <ItemGrid xs={4}>
                                        <Button color={"info"}
                                                onClick={this.handleFileClick}>Upload File</Button>
                                    </ItemGrid>:null} */}
                                    {/* {!this.state.updateView?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }

                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                {this.state.approveStatus ? <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.setState({
                                                                    isApprove: true,
                                                                });
                                                                this.InvoicebasicAlert("Do You Like To Approve This Indent..!!");

                                                            }}>Approve</Button>
                                                </ItemGrid>:null
                                                }
                                                {this.state.approveStatus?
                                                <ItemGrid>
                                                    <Button color="danger" onClick={()=>{
                                                        this.setState({
                                                            isReject: true,
                                                        });
                                                        this.InvoicebasicAlert("Do You Like To Reject This Indent..!!");

                                                    }}>Reject</Button>
                                                </ItemGrid>:null}
                                                {(this.state.updateView && this.state.indentStatus!=="3"&& this.state.indentStatus!=="4"&& this.state.indentStatus!=="5"&&this.state.approveStatus)||this.state.updateView && this.state.indentStatus==="1"?
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={()=>{this.onUpdateButtonClick("")}}>update</Button>
                                                    </ItemGrid>:null
                                                }
                                                {this.state.finalApprover && this.state.indentStatus==='3'?
                                                    <ItemGrid>
                                                        <Button color="danger"
                                                                onClick={()=>{this.onUpdateButtonClick("6")}}>Cancel</Button>
                                                    </ItemGrid>: null
                                                }
                                                {this.state.finalApprover && this.state.indentStatus==='3'?
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={()=>{this.onUpdateButtonClick("5")}}>Close</Button>
                                                    </ItemGrid>: null
                                                }

                                            </GridContainer>

                                        </ItemGrid>

                                    {this.state.updateView&& this.state.indentStatus!=="4"?
                                        <ItemGrid xs={12} style={{marginTop:"15px"}}>
                                            <SamplePDF
                                                invoiceType={6} printResp={this.state.printResp}/>
                                        </ItemGrid>:null} */}
                                        {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                        {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                        }
                                        {this.state.updateView ?
                                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                        onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null
                                        }
                                        {this.state.updateView ?
                                            // <ItemGrid >
                                            //     <Button color={"info"} onClick={this.handleLogsClick} style={{marginBottom:"10px"}}>
                                            //         Click here to see the Logs
                                            //     </Button>
                                            //     <br/>
                                            //     {sampleData}
                                            // </ItemGrid>
                                            <LogsComponents id={this.state.projectId} recType={"PROJECT"} />:null
                                        }
                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer >
                                         <ItemGrid xs={10}>
                                           {/* <GridContainer>

                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.status}
                                                                id={"status"}
                                                                                onSelect={this.onSelect}
                                                                                stateKey="status"
                                                                                url={serverApi.SEARCH_STORE_STATUS}
                                                                                value={this.state.status}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                
                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Phase":"Project"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+this.state.branchIdList}
                                                                defValue={this.state.projectIdList}
                                                                // staticData={this.state.ProjectOptions}
                                                                onSelect={this.onSelect}
                                                                stateKey="projectIdList"
                                                                isRefresh={this.state.loadProject}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadProject:false})
                                                                }}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Phase
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                //url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+this.state.branchIdList}
                                                                //defValue={this.state.projectIdList}
                                                                // staticData={this.state.ProjectOptions}
                                                                onSelect={this.onSelect}
                                                                stateKey="phaseIdList"
                                                                //isRefresh={this.state.loadProject}
                                                                // setIsRefresh={(val)=>{
                                                                //     this.setState({loadProject:false})
                                                                // }}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>                

                                            </GridContainer>*/}
                                        </ItemGrid> 
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        // round color="white"
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
                                                <ItemGrid xs={12}></ItemGrid>
                                                {/* <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid> */}

                                            </GridContainer>
                                        </ItemGrid>
                                        {/* <ItemGrid xs={12} style={{color:"red" ,fontSize:"12px"}}>Note* For Approved,Rejected,Closed Indents Please Select Status and Click On Search</ItemGrid> */}
                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.PROJECT_LIST_LIST}
                                                //staticData={this.state.projectDataTableList}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                            />

                            


                                        </ItemGrid>
                                        {/* <ItemGrid xs={12} style={{marginTop:"15px"}}>
                                            <LineItemTable
                                                data={this.state.invoiceList}
                                                loading={this.state.loading}
                                                disableActions={this.state.indentStatus==='3'||this.state.indentStatus==='4'||this.state.indentStatus==='5'||this.state.indentStatus==='6'}
                                                columns={[
                                                    {
                                                        Header: "Project ID",
                                                        accessor: "budgetHeadsName",
                                                        minWidth:70,
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Project Name",
                                                        //accessor: "budgetSubHead",
                                                        minWidth:100,
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Project Start Date",
                                                        //accessor: "itemGroupOrCostCenter",
                                                        minWidth:150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Project End Date",
                                                        //accessor: "costPerSft",
                                                        minWidth:150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Customer",
                                                        //accessor: "totalSft",
                                                        minWidth:150,
                                                        style: {color: "#000", textAlign: "left",textOverflow: 'unset'}
                                                    },                                                      
                                                    {
                                                        Header: "Total Sqft",
                                                        //accessor: "totalAmount",
                                                        width: 100,
                                                        style: {color: "#000", textAlign: "right"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                            />
                                        </ItemGrid> */}
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
    printDocument() {
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.GET_INDENT_DETAILS_BY_ID + this.state.indentNo + "/userid/" + getCookie(cons.USERID) + "/company/"+this.props.state.companyInfo.id+"/year/"+getCookie(cons.ACADEMIC_YEAR), this.handlePrintResp)

            //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
        }
    };
    handlePrintResp = (resp)=> {
        var printHeaderValues=resp.indentHeader;
        var modules=[];
        var lineItems=resp.indentItems;
        if(lineItems.length>0){
            lineItems.map((prop,key)=>{
                var obj={
                    srNo:key+1,
                    itemId:prop.itemId,
                    itemIdDesc:prop.itemIdDesc,
                    unitId:prop.unitId,
                    stockquantity:prop.stock,
                    avgQty:' ',
                    indentQuantity:prop.indentQuantity,
                    deptId:prop.deptIdDesc,
                    pono:' ',
                    rate:prop.rate+' ',
                    supName:' ',
                    clmn12:' ',
                    clmn13:' ',
                    clmn14:' ',
                    clmn15:' ',
                    clmn16:' ',
                    orderNo:' ',
                    ctrlStock:' ',
                    deliveryStatus:'15 days'
                };
                modules.push(obj)
            })
        }
        console.log('lineitems',modules);
        var printTotalvalues=resp.totalValues;
        // this.setState({
        //     printHeaderValues:header,
        //     printLineItems:printLineItems,
        //     printTotalvalues:totalvalues,
        // })


        // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
        //     var printHeaderValues=this.state.printHeaderValues;
        // }
        var pageNo = 1;

        var mrid=printHeaderValues.juteRecptNo;
        var mrid=mrid+' ';
        var mr_date=printHeaderValues.indentDateDesc;
        mr_date=mr_date+' ';
        var poId=printHeaderValues.id;
        poId=poId+' ';
        var challan_no=printHeaderValues.challanNo;
        challan_no=challan_no+' ';
        var challan_date=printHeaderValues.challanDate;
        challan_date=challan_date+' ';
        var vehicleNo=printHeaderValues.lorryNo;
        vehicleNo=vehicleNo+' ';
        var SupplierName=printHeaderValues.supplierName;
        SupplierName=SupplierName+' ';
        var brokerName=printHeaderValues.brokerName;
        brokerName=brokerName+' ';
        var frightPaid=printHeaderValues.frightPaid;
        frightPaid=frightPaid+' ';
        var gateEntryDate=printHeaderValues.gateEntryDate;
        gateEntryDate=gateEntryDate+' ';
        var mukamName=printHeaderValues.mukamName;
        mukamName=mukamName+' ';

        var companyName=getCookie(cons.COMPANY_NAME);
        var address1=(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress1 +' ' :printHeaderValues.cAddress1;
        var address2=(printHeaderValues.agentName!=='')? printHeaderValues.agentAddress2 + ' ' :printHeaderValues.cAddress2;

        var printcopy='';
        var printCounnt=printHeaderValues.printCount;

        if(printCounnt==0){
            printcopy='NOT A VALID';
        }
        else if(printCounnt==1){
            printcopy='ORIGINAL';
        }else if(printCounnt==2){
            printcopy='DUPLICATE';
        }
        else if(printCounnt==3){
            printcopy='Triplicate';
        }
        else if(printCounnt==4){
            printcopy='Quadruplicate';
        }else{
            printcopy='COPY'+' '+printCounnt;
        }


        // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
        //      modules=this.state.printLineItems;
        //  }
        // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
        //   var  printTotalvalues=this.state.printTotalvalues;
        // }

        function addWaterMark(doc,printcopy) {
            var totalPages = doc.internal.getNumberOfPages();

            for (i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
                doc.setFontSize(24);
                doc.setTextColor(200);
                doc.globalAlpha = 0.5
                //doc.zIndex(-1);
                doc.text(10, 30, printcopy);

                // doc.text(150, 600, printcopy,null,45);
            }

            return doc;
        }

        var config = {
            printHeaders: true,
            autoSize: true,
            margins: {left: 0, top: 0, bottom: 0, width: 0},
            fontSize: 10
        };
        const doc = new Jspdf('p', 'pt', 'a4');
        doc.setFontSize(16);
        var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / 2);
        doc.text(companyName, xOffset, 40);
        doc.setFontSize(10);
        //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
        var TotalPgaeNo = doc.internal.getNumberOfPages();
        doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
        doc.setFontSize(10);
        /* doc.text(190, 60, address1+'');
         doc.text(250, 80, address2+'');
         doc.setFontSize(9);
        */
        doc.setFontType("bold");
        doc.text(30, 80, 'JUTE REPORT NO');
        doc.text(120, 80, ':');
        doc.setFontType("normal");
        doc.text(130, 80, mrid);
        doc.setFontType("bold");
        doc.text(400, 120, 'INDENT DATE : ');
        doc.setFontType("normal");
        doc.text(480, 120, mr_date);
        doc.setFontType("bold");
        doc.text(30, 120, 'INDENT NO');
        doc.text(120, 120, ':');
        doc.setFontType("normal");
        doc.text(130, 120, poId);
        var srno = doc.splitTextToSize('Srl no', 20);
        doc.cell(10, 150, 20, 40, srno, '', 'C');
        var itemCode = doc.splitTextToSize('Item Code', 30);
        doc.cell(30, 150, 30, 40, itemCode, '', 'C');
        var description = doc.splitTextToSize('Description', 60);
        doc.cell(90, 150, 70, 40, description, '', 'C');
        var unit = doc.splitTextToSize('Unit', 50);
        doc.cell(180, 150, 20, 40, unit, '', 'C');
        var stockqty = doc.splitTextToSize('Stock/Appr Qty', 30);
        doc.cell(220, 150, 40, 40, stockqty, '', 'C');
        var avg = doc.splitTextToSize('Avg Cons Last Mth',40);
        doc.cell(260, 150, 40, 40, avg, '', 'C');
        var indentQty = doc.splitTextToSize('Indent Quantity',35);
        doc.cell(290, 150, 40, 40, indentQty, '', 'C');
        var dept = doc.splitTextToSize('Dept', 20);
        doc.cell(310, 150, 25, 40, dept, '', 'C');
        doc.cell(340, 150, 100, 20, '       Previous Details     ', '', 'C');
        //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

        /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
        doc.cellInitialize();
        doc.setFontSize(9);
        doc.setFontType('normal');

        var pono = doc.splitTextToSize('PO No/Dt', 30);

        doc.cell(295, 170, 30, 20, pono, '', 'C');
        doc.cell(320, 170, 30, 20, 'Rate', '', 'C');
        var supName = doc.splitTextToSize('Supplier Name', 36);

        doc.cell(350, 170, 40, 20, supName, '', 'C');
        doc.cellInitialize();
        doc.setFontSize(10);
        doc.setFontType('normal');
        doc.cell(395, 150, 100, 20, '          Comp Status          ', '', 'C');
        //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
        /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
        doc.cellInitialize();
        doc.setFontSize(9);
        doc.setFontType('normal');
        doc.cell(395, 170, 20, 20, '<12>', '', 'C');
        doc.cell(420, 170, 20, 20, '<13>', '', 'C');
        doc.cell(450, 170, 20, 20, '<14>', '', 'C');
        doc.cell(470, 170, 20, 20, '<15>', '', 'C');
        doc.cell(490, 170, 20, 20, '<16>', '', 'C');
        doc.cellInitialize();
        doc.setFontSize(10);
        doc.setFontType('normal');
        var order = doc.splitTextToSize('Order No', 30);
        doc.cell(495, 150, 30, 40, order, '', 'C');
        var cntrl = doc.splitTextToSize('Cntrl Stock',30);
        doc.cell(525, 150, 30, 40, cntrl, '', 'C');
        var delivery = doc.splitTextToSize('Delivery Status',38);
        doc.cell(555, 150, 36, 40, delivery, '', 'C');


        var cellWidths = ['', 20, 30, 70, 20, 40, 40, 40, 25, 30, 30, 40, 20, 20, 20, 20, 20, 30, 30, 36];
        var cellTextaligns = ['', 'left', 'left', 'left', 'right', 'right', 'right', 'right', 'left', 'left', 'right'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k = 165;
            var i = 0;
            modules.map((prop, key) => {
                k = k + 25;
                i = i + 1;
                doc.cellInitialize();
                if (i === 27) {
                    k = 165;
                    i=0;
                    pageNo = pageNo + 1;
                    var today = moment().format('DD/MM/YYYY H:mm:ss');
                    var name = getCookie(cons.USERID);
                    var newdat = " Printed on: " + today;
                    var printBy = " Printed By: " + name;
                    doc.text(30, 800, newdat);
                    doc.text(500, 800, printBy);
                    doc.addPage('a4', 'p');

                    doc.setFontType("bold");
                    doc.cellInitialize();
                    var srno = doc.splitTextToSize('Srno', 20);
                    doc.cell(10, 150, 20, 40, srno, '', 'C');
                    var itemCode = doc.splitTextToSize('Item Code', 30);
                    doc.cell(30, 150, 30, 40, itemCode, '', 'C');
                    var description = doc.splitTextToSize('Description', 50);
                    doc.cell(90, 150, 70, 40, description, '', 'C');
                    var unit = doc.splitTextToSize('Unit', 50);
                    doc.cell(180, 150, 20, 40, unit, '', 'C');
                    var stockqty = doc.splitTextToSize('Stock/Appr Qty', 30);
                    doc.cell(220, 150, 40, 40, stockqty, '', 'C');
                    var avg = doc.splitTextToSize('Avg Cons Last Mth',40);
                    doc.cell(260, 150, 40, 40, avg, '', 'C');
                    var indentQty = doc.splitTextToSize('Indent Quantity',35);
                    doc.cell(290, 150, 40, 40, indentQty, '', 'C');
                    var dept = doc.splitTextToSize('Dept', 40);
                    doc.cell(310, 150, 25, 40, dept, '', 'C');
                    doc.cell(340, 150, 100, 20, '       Previous Details     ', '', 'C');
                    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

                    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
                    doc.cellInitialize();
                    doc.setFontSize(9);
                    doc.setFontType('normal');

                    var pono = doc.splitTextToSize('PO No/Dt', 30);

                    doc.cell(295, 170, 30, 20, pono, '', 'C');
                    doc.cell(320, 170, 30, 20, 'Rate', '', 'C');
                    var supName = doc.splitTextToSize('Supplier Name', 36);

                    doc.cell(350, 170, 40, 20, supName, '', 'C');
                    doc.cellInitialize();
                    doc.setFontSize(10);
                    doc.setFontType('normal');
                    doc.cell(395, 150, 100, 20, '          Comp Status          ', '', 'C');
                    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
                    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
                    doc.cellInitialize();
                    doc.setFontSize(9);
                    doc.setFontType('normal');
                    doc.cell(395, 170, 20, 20, '<12>', '', 'C');
                    doc.cell(420, 170, 20, 20, '<13>', '', 'C');
                    doc.cell(450, 170, 20, 20, '<14>', '', 'C');
                    doc.cell(470, 170, 20, 20, '<15>', '', 'C');
                    doc.cell(490, 170, 20, 20, '<16>', '', 'C');
                    doc.cellInitialize();
                    doc.setFontSize(10);
                    doc.setFontType('normal');
                    var order = doc.splitTextToSize('Order No', 30);
                    doc.cell(495, 150, 30, 40, order, '', 'C');
                    var cntrl = doc.splitTextToSize('Cntrl Stock',30);
                    doc.cell(525, 150, 30, 40, cntrl, '', 'C');
                    var delivery = doc.splitTextToSize('Delivery Status',38);
                    doc.cell(555, 150, 36, 40, delivery, '', 'C');
                    doc.setFontSize(7);
                }
                var mytext="Max Quantity :30.00    Min Quantity: 15.00";

                var a = 0;
                return ([
                    Object.keys(prop).map((key, index) => {

                        a = a + 1;
                        var size=30;

                            if(key==='itemIdDesc'){
                                var size=60;
                            }else{
                                var size=30;
                            }
                        var myItem = prop[key]+" ";
                        myItem = doc.splitTextToSize(myItem,size);
                        // }


                        return (
                            doc.cell(10, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])

                        )
                    }),

                    // doc.text(30, k+10, mytext)-+-
                ])

            });

        }
        doc.cellInitialize();
        doc.setFontType("bold");
        doc.cell(10, k + 25, 20, 30, ' ', '', 'right');

        doc.cell(30, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(90, k + 25, 70, 30, ' ', '', 'right');
        doc.cell(180, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(220, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(260, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(290, k + 25, 40, 30,  ' ', '', 'right');
        doc.cell(310, k + 25, 25, 30, ' ', '', 'right');
        doc.cell(340, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(295, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(320, k + 25, 40, 30, ' ', '', 'right');
        doc.cell(350, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(395, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(420, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(450, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(490, k + 25, 20, 30, ' ', '', 'right');
        doc.cell(495, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(525, k + 25, 30, 30, ' ', '', 'right');
        doc.cell(555, k + 25, 36, 30, ' ', '', 'right');

        doc.setFontType("bold");
        doc.setFontSize(10);
        doc.text(30, k+150,'Checked By',);

        doc.text(150, k+150,'Store Signatory',);
        doc.text(300, k+150,'Authorised Signatory',);
        doc.text(450, k+150,'Approval Signatory',);

        doc.text(30, k+170,'Note For Head Office',);
        doc.setFontType("normal");
        doc.text(30, k+190,'1. This indent copy is made only for office use, No other outside persons are entitled to see that.',);
        doc.text(30, k+210,'2. From column No. 1 to 11 to be filled from computer ot stores department and balance 12 to 18 are kept blank',);
        doc.text(50, k+230,'<a> 12 to 17 for rate comparison <b> 18 for writing order no after placing to supplier',);
        doc.text(30, k+250,'3. Pl also make sure that order item code & unit should be tallyed with Indent',);
        doc.text(30, k+270,'4. Pl also give indent & indent Srl No on order. This will help a lot for Stores job processing',);
        doc.text(30, k+290,'5.Column No 19 is showing Central Store Stock. So,Please see carefully before sending indent to HO',);
        doc.text(30, k+310,'3. Option available a) General Indent   b) O/H Indent   c) Open Indent',);







        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name =getCookie(cons.USERID);
        var newdat = " Printed on: " + today;
        var printBy = " Printed By: " + name;
        doc.text(30, k + 350, newdat);
        doc.text(500, k + 350, printBy);
        addWaterMark(doc,printcopy);

        doc.save('Indent_'+printHeaderValues.id+'.pdf');


    }


}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(ProjectList)
);
