import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Add from "@material-ui/icons/Add";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import cons, { configNames, serverApi } from "../../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../../server/server";
import { getCookie } from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import { VIEW } from "../../../ui-actions/UiActions";
import { getMenuPermission } from "../../CustumComponents/PermissionAlert";
import TableComponent from "../../CustumComponents/TableComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import {
  ApprovalTasks,
  invoiceTypesList,
  menuTypes,
  currencyList,
  supplierTypesList,
} from "../../TableData";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import { columnIndexOf } from "fusioncharts/vendors/fc-datatable/src/utils/datatable-utils";
import InputAdornment from "material-ui/Input/InputAdornment";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      companyList: [],
      supplierTypes: [],
      invoicingModel: [],
      backDateAllowable: "1",
      gateEntryOut: "1",
      mrPrintMode: "1",
      companyId: "",
      configList: [],
      labelType: "1",
      futureDateAllowable: "1",
      indentWithMultiDepartments: "1",
      tasksList: [],
      gateEntryWithoutPo: "1",
      poPrintBeforeApproval: "1",
      emailAlert: "1",
      sendingMR: "1",
      customerInternalMapping: "1",
      taxTypes: { gst: 0, vat: 0, noTax: 0 },
      brokerTypes: { jute: 1, sales: 0 },
      saleOrderTypes: { general: 1, rawMaterial: 0, juteSale: 0 },
      currencyTypes: { inr: 1, usd: 0 },
      invoicingPrintModel: "1",
      container_rate: "0",
      rail_rate: "0",
      autoSync: "N",
      invoiceNoMdicfication: "1",
      constructionFields: "1",
      configurationListResp: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
  }
  hideAlertMessage() {
    this.setState({
      showMessagealert: false,
      alert: false,
      basicAlert: false,
      invoicingModel: [],
      backDateAllowable: "1",
      companyId: "",
      reloadList: true,
      configList: [],
      gateEntryOut: "1",
      mrPrintMode: "1",
      supplierTypes: [],
      labelType: "1",
      futureDateAllowable: "1",
      indentWithMultiDepartments: "1",
      tasksList: [],
      gateEntryWithoutPo: "1",
      poPrintBeforeApproval: "1",
      emailAlert: "1",
      sendingMR: "1",
      customerInternalMapping: "1",
      taxTypes: { gst: 0, vat: 0, noTax: 0 },
      brokerTypes: { jute: 1, sales: 0 },
      saleOrderTypes: { general: 1, rawMaterial: 0, juteSale: 0 },
      currencyTypes: { inr: 1, usd: 0 },
      invoicingPrintModel: "1",
      container_rate: "0",
      rail_rate: "0",
      autoSync: "N",
      invoiceNoMdicfication: "1",
    });
  }

  componentDidMount() {
    if (getCookie(cons.COMPANIES_LIST) !== undefined) {
      var companyList = JSON.parse(getCookie(cons.COMPANIES_LIST));
      console.log("companyList", companyList);
      this.setState({
        companyList: companyList,
        reloadList: true,
      });
    }
  }

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "companyId") {
      this.handleLoading();
      getDataFromUrl(
        serverApi.GET_CONFIGURATION_BY_COMPANY + selectedValue,
        this.handleConfigData
      );
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleConfigData = (resp) => {
    var list = resp.configList;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (
          prop.configParameter === configNames.INVOICING_MODEL &&
          prop.configValue === "2"
        ) {
          this.setState({
            [prop.configParameter]: [invoiceTypesList[1]],
          });
        } else if (
          prop.configParameter === configNames.INVOICING_MODEL &&
          prop.configValue === "1"
        ) {
          this.setState({
            [prop.configParameter]: [invoiceTypesList[0]],
          });
        } else if (
          prop.configParameter === configNames.INVOICING_MODEL ||
          prop.configParameter === configNames.SUPPLIER_TYPES ||
          prop.configParameter === configNames.APPROVAL_TASKS_LIST ||
          prop.configParameter === configNames.TAX_TYPES ||
          prop.configParameter === configNames.BROKER_TYPES ||
          prop.configParameter === configNames.SALE_ORDER_TYPES ||
          prop.configParameter === configNames.CURRENCY_TYPES
        ) {
          console.log("configggg list", prop.configParameter, configNames);

          this.setState({
            [prop.configParameter]: JSON.parse(prop.configValue),
          });
          //condition added if currencyType is not there in erp
          //then state will cleared and by default set to INR after company selection
          if (prop.configParameter !== configNames.CURRENCY_TYPES) {
            this.state.currencyTypes.inr = 1;
            this.state.currencyTypes.usd = 0;
          }
        } else
          this.setState({
            [prop.configParameter]: prop.configValue,
          });
      });
      this.setState({
        configList: list,
        configurationListResp: resp.configList,
      });
    }
    this.handleLoading();
  };
  getAutoIdFromList = (configParameter) => {
    var list = this.state.configList;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
          console.log("id", id);
        }
      });
    }
    return id;
  };

  onCreateButtonClick = () => {
    const {
      invoicingModel,
      backDateAllowable,
      companyId,
      gateEntryOut,
      mrPrintMode,
      supplierTypes,
      labelType,
      futureDateAllowable,
      indentWithMultiDepartments,
      tasksList,
      gateEntryWithoutPo,
      poPrintBeforeApproval,
      emailAlert,
      taxTypes,
      sendingMR,
      customerInternalMapping,
      brokerTypes,
      saleOrderTypes,
      currencyTypes,
      invoicingPrintModel,
      rail_rate,
      container_rate,
      autoSync,
      invoiceNoMdicfication,
      constructionFields,
    } = this.state;

    var data = {
      companyId: companyId,
      configList: [
        {
          autoId: this.getAutoIdFromList(configNames.INVOICING_MODEL),
          configParameter: configNames.INVOICING_MODEL,
          isActive: 1,
          configValue: JSON.stringify(invoicingModel),
        },
        {
          autoId: this.getAutoIdFromList(configNames.BACK_DATE_ALLOWABLE),
          configParameter: configNames.BACK_DATE_ALLOWABLE,
          isActive: 1,
          configValue: backDateAllowable,
        },
        {
          autoId: this.getAutoIdFromList(configNames.GATE_ENTRY_OUT),
          configParameter: configNames.GATE_ENTRY_OUT,
          isActive: 1,
          configValue: gateEntryOut,
        },
        {
          autoId: this.getAutoIdFromList(configNames.MR_PRINT_MODE),
          configParameter: configNames.MR_PRINT_MODE,
          isActive: 1,
          configValue: mrPrintMode,
        },
        {
          autoId: this.getAutoIdFromList(configNames.SUPPLIER_TYPES),
          configParameter: configNames.SUPPLIER_TYPES,
          isActive: 1,
          configValue: JSON.stringify(supplierTypes),
        },
        {
          autoId: this.getAutoIdFromList(configNames.LABELS_TYPE),
          configParameter: configNames.LABELS_TYPE,
          isActive: 1,
          configValue: labelType,
        },
        {
          autoId: this.getAutoIdFromList(configNames.FUTURE_DATE_ALLOWABLE),
          configParameter: configNames.FUTURE_DATE_ALLOWABLE,
          isActive: 1,
          configValue: futureDateAllowable,
        },
        {
          autoId: this.getAutoIdFromList(
            configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
          ),
          configParameter:
            configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS,
          isActive: 1,
          configValue: indentWithMultiDepartments,
        },
        {
          autoId: this.getAutoIdFromList(configNames.APPROVAL_TASKS_LIST),
          configParameter: configNames.APPROVAL_TASKS_LIST,
          isActive: 1,
          configValue: JSON.stringify(tasksList),
        },
        {
          autoId: this.getAutoIdFromList(configNames.GATE_ENTRY_WITHOUT_PO),
          configParameter: configNames.GATE_ENTRY_WITHOUT_PO,
          isActive: 1,
          configValue: gateEntryWithoutPo,
        },
        {
          autoId: this.getAutoIdFromList(configNames.PO_PRINT_BEFORE_APPROVE),
          configParameter: configNames.PO_PRINT_BEFORE_APPROVE,
          isActive: 1,
          configValue: poPrintBeforeApproval,
        },
        {
          autoId: this.getAutoIdFromList(configNames.EMAIL_ALERTS),
          configParameter: configNames.EMAIL_ALERTS,
          isActive: 1,
          configValue: emailAlert,
        },
        {
          autoId: this.getAutoIdFromList(configNames.TAX_TYPES),
          configParameter: configNames.TAX_TYPES,
          isActive: 1,
          configValue: JSON.stringify(taxTypes),
        },
        {
          autoId: this.getAutoIdFromList(configNames.SENDING_MR_INVOICE),
          configParameter: configNames.SENDING_MR_INVOICE,
          isActive: 1,
          configValue: sendingMR,
        },
        {
          autoId: this.getAutoIdFromList(configNames.CUSTOMER_INTERNAL_MAPPING),
          configParameter: configNames.CUSTOMER_INTERNAL_MAPPING,
          isActive: 1,
          configValue: customerInternalMapping,
        },
        {
          autoId: this.getAutoIdFromList(configNames.BROKER_TYPES),
          configParameter: configNames.BROKER_TYPES,
          isActive: 1,
          configValue: JSON.stringify(brokerTypes),
        },
        {
          autoId: this.getAutoIdFromList(configNames.SALE_ORDER_TYPES),
          configParameter: configNames.SALE_ORDER_TYPES,
          isActive: 1,
          configValue: JSON.stringify(saleOrderTypes),
        },
        {
          autoId: this.getAutoIdFromList(configNames.CURRENCY_TYPES),
          configParameter: configNames.CURRENCY_TYPES,
          isActive: 1,
          configValue: JSON.stringify(currencyTypes),
        },
        {
          autoId: this.getAutoIdFromList(configNames.INVOICING_PRINT_MODEL),
          configParameter: configNames.INVOICING_PRINT_MODEL,
          isActive: 1,
          configValue: invoicingPrintModel,
        },
        {
          autoId: this.getAutoIdFromList(configNames.RAIL_RATE),
          configParameter: configNames.RAIL_RATE,
          isActive: 1,
          configValue: rail_rate,
        },
        {
          autoId: this.getAutoIdFromList(configNames.CONTAINER_RATE),
          configParameter: configNames.CONTAINER_RATE,
          isActive: 1,
          configValue: container_rate,
        },
        {
          autoId: this.getAutoIdFromList(configNames.INVOICE_NO_MODIFY),
          configParameter: configNames.INVOICE_NO_MODIFY,
          isActive: 1,
          configValue: invoiceNoMdicfication,
        },
        {
          autoId: this.getAutoIdFromList(configNames.TALLY_SYNC),
          configParameter: configNames.TALLY_SYNC,
          isActive: 1,
          configValue: autoSync,
        },
        {
          autoId: this.getAutoIdFromList(configNames.CONSTRUCTION_FIELDS),
          configParameter: configNames.CONSTRUCTION_FIELDS,
          isActive: 1,
          configValue: constructionFields,
        },
      ],
    };

    if (companyId !== "") {
      this.handleLoading();
      requestList(serverApi.UPDATE_CONFIGURATION, data, this.handleAddMenu);
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };
  handleAddMenu = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message);
    } else {
      this.showMessage(resp.message);
    }
    this.handleLoading();
  };

  showMessage = (msg) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            this.hideAlertMessage();
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  onCheckBranch = (prop) => (event) => {
    var list = this.state.supplierTypes;
    if (list.filter((vendor) => vendor.value === prop.value).length > 0) {
      const index = list.findIndex((vendor) => vendor.value === prop.value);
      list.splice(index, 1);
    } else {
      list.push(prop);
    }
    this.setState({ supplierTypes: list });
    //  console.log("supplierrmmm",list);
  };
  onCheckInvoiceModel = (prop) => (event) => {
    var list = this.state.invoicingModel;
    if (list.filter((vendor) => vendor.value === prop.value).length > 0) {
      const index = list.findIndex((vendor) => vendor.value === prop.value);
      list.splice(index, 1);
    } else {
      list.push(prop);
    }
    this.setState({ invoicingModel: list });
    //  console.log("supplierrmmm",list);
  };

  onCheckTaxType = (prop) => (event) => {
    var list = this.state.taxTypes;
    if (prop === "gst") {
      list.gst = list.gst === 0 ? 1 : 0;
    } else if (prop === "vat") {
      list.vat = list.vat === 0 ? 1 : 0;
    } else if (prop === "noTax") {
      list.noTax = list.noTax === 0 ? 1 : 0;
    }
    this.setState({ taxTypes: list });
    //  console.log("supplierrmmm",list);
  };
  onCheckBrokerType = (prop) => (event) => {
    var list = this.state.brokerTypes;
    if (prop === "jute") {
      list.jute = list.jute === 0 ? 1 : 0;
    } else if (prop === "sales") {
      list.sales = list.sales === 0 ? 1 : 0;
    }
    this.setState({ brokerTypes: list });
    //  console.log("supplierrmmm",list);
  };

  onCheckTasks = (prop) => (event) => {
    var list = this.state.tasksList;
    if (list.filter((vendor) => vendor.taskId === prop.taskId).length > 0) {
      const index = list.findIndex((vendor) => vendor.taskId === prop.taskId);
      list.splice(index, 1);
    } else {
      list.push(prop);
    }
    this.setState({ tasksList: list });
    //  console.log("supplierrmmm",list);
  };
  onCheckSaleType = (prop) => (event) => {
    var list = this.state.saleOrderTypes;
    console.log(
      "getting the props value heresssssss",

      this.state.saleOrderTypes
    );

    if (prop === "general") {
      list.general = list.general === 0 ? 1 : 0;
    } else if (prop === "rawMaterial") {
      list.rawMaterial = list.rawMaterial === 0 ? 1 : 0;
    } else if (prop === "juteSale") {
      list.juteSale = list.juteSale === 0 ? 1 : 0;
    }
    this.setState({ saleOrderTypes: list });
    //  console.log("supplierrmmm",list);
  };

  onCheckCurrecyType = (prop) => (event) => {
    var list = this.state.currencyTypes;

    console.log(
      "getting the props value here check",
      prop,
      this.state.currencyTypes,
      this.state.configList
    );
    if (prop === "inr") {
      list.inr = list.inr === 0 ? 1 : 0;
    } else if (prop === "usd") {
      list.usd = list.usd === 0 ? 1 : 0;
    }
    this.setState({
      currencyTypes: list,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      invoicingPrintModel,
      invoicingModel,
      backDateAllowable,
      gateEntryOut,
      mrPrintMode,
      supplierTypes,
      labelType,
      futureDateAllowable,
      indentWithMultiDepartments,
      tasksList,
      gateEntryWithoutPo,
      poPrintBeforeApproval,
      emailAlert,
      taxTypes,
      sendingMR,
      customerInternalMapping,
      brokerTypes,
      saleOrderTypes,
      currencyTypes,
      companyId,
      rail_rate,
      autoSync,
      invoiceNoMdicfication,
      constructionFields,
    } = this.state;
    /*  console.log("supplierrmmm",supplierTypes);
        console.log("supplierrmmm111",supplierTypesList);*/

    //  var supplierTypesList=supplierTypesList;

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          {this.state.showMessagealert}
          {this.state.basicAlert}
          <RegularCard
            cardTitle={""}
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Company
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.companyId}
                        onSelect={this.onSelect}
                        isRefresh={this.state.reloadList}
                        setIsRefresh={() => {
                          this.setState({ reloadList: false });
                        }}
                        stateKey="companyId"
                        staticData={this.state.companyList}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                {companyId !== "" ? (
                  <ItemGrid xs={12}>
                    <GridContainer>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Currency Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            {console.log(
                              "getting the currency type",
                              currencyTypes
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={currencyTypes.inr}
                                  checked={currencyTypes.inr}
                                  onChange={this.onCheckCurrecyType("inr")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"INR"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={currencyTypes.usd}
                                  checked={currencyTypes.usd}
                                  onChange={this.onCheckCurrecyType("usd")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"USD"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      {/* <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Currency
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            {currencyList.map((prop, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      value={
                                        invoicingModel.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      checked={
                                        invoicingModel.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      onChange={this.onCheckInvoiceModel(prop)}
                                      // onClick={() => this.handleToggle(prop)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  }
                                  classes={{
                                    label: classes.menulabel,
                                  }}
                                  label={prop.label}
                                />
                              );
                            })}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid> */}

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Invoicing Model
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            {invoiceTypesList.map((prop, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      value={
                                        invoicingModel.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      checked={
                                        invoicingModel.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      onChange={this.onCheckInvoiceModel(prop)}
                                      // onClick={() => this.handleToggle(prop)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  }
                                  classes={{
                                    label: classes.menulabel,
                                  }}
                                  label={prop.label}
                                />
                              );
                            })}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Invoice Print
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoicingPrintModel === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoicingPrintModel: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"General"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoicingPrintModel === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoicingPrintModel: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"AJM Model"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoicingPrintModel === "3"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoicingPrintModel: "3",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"EJM Model"}
                            />

                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoicingPrintModel === "4"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoicingPrintModel: "4",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Raw Jute Model"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid xs={6} md={6} style={{ marginTop: "10px" }}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Rail Rate
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="rail_rate"
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      iType="number"
                                      value={rail_rate}
                                      InputProps={{
                                        autoComplete: "off",
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              MT
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid xs={6} md={6} style={{ marginTop: "10px" }}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Container Rate
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="container_rate"
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                      iType="number"
                                      value={this.state.container_rate}
                                      InputProps={{
                                        autoComplete: "off",
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <span
                                              style={{
                                                color: "#00ABDC",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              MT
                                            </span>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Sending MR In Invoice
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={sendingMR === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      sendingMR: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={sendingMR === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      sendingMR: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Invoice No Modify
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoiceNoMdicfication === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoiceNoMdicfication: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={invoiceNoMdicfication === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      invoiceNoMdicfication: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Back Date Allowable
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={backDateAllowable === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      backDateAllowable: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={backDateAllowable === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      backDateAllowable: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Future Date Allowable
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={futureDateAllowable === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      futureDateAllowable: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={futureDateAllowable === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      futureDateAllowable: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Gate Without PO
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={gateEntryWithoutPo === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      gateEntryWithoutPo: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Enable"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={gateEntryWithoutPo === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      gateEntryWithoutPo: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Disable"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Gate Entry Out
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={gateEntryOut === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      gateEntryOut: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Enable"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={gateEntryOut === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      gateEntryOut: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Disable"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              MR Print
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={mrPrintMode === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      mrPrintMode: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Legacy Mode"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={mrPrintMode === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      mrPrintMode: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Latest Mode"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Supplier Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            {supplierTypesList.map((prop, i) => {
                              /* console.log("datasupp",prop);
                                                    console.log("datasupp1",supplierTypes[i]);
                                                    console.log("index",supplierTypes.filter(vendor => vendor.value === prop.value).length > 0);
*/
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      value={
                                        supplierTypes.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      checked={
                                        supplierTypes.filter(
                                          (vendor) =>
                                            vendor.value === prop.value
                                        ).length > 0
                                          ? 1
                                          : 0
                                      }
                                      onChange={this.onCheckBranch(prop)}
                                      // onClick={() => this.handleToggle(prop)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  }
                                  classes={{
                                    label: classes.menulabel,
                                  }}
                                  label={prop.label}
                                />
                              );
                            })}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Labels Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={labelType === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      labelType: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Legacy"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={labelType === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      labelType: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Corporate"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={labelType === "3"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      labelType: "3",
                                    });
                                  }}
                                  value={3}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"KSA"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Indent With Multiple Departments & Item Groups
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={indentWithMultiDepartments === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      indentWithMultiDepartments: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={indentWithMultiDepartments === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      indentWithMultiDepartments: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              PO Print before Approval
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={poPrintBeforeApproval === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      poPrintBeforeApproval: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={poPrintBeforeApproval === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      poPrintBeforeApproval: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Email Alerts
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={emailAlert === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      emailAlert: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={emailAlert === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      emailAlert: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Tally Auto Sync
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={autoSync === "N"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      autoSync: "N",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />

                            <FormControlLabel
                              control={
                                <Radio
                                  checked={autoSync === "Y"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      autoSync: "Y",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Customer Internal Mapping
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={customerInternalMapping === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      customerInternalMapping: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Disable"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={customerInternalMapping === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      customerInternalMapping: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Enable"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Tax Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={taxTypes.gst}
                                  checked={taxTypes.gst}
                                  onChange={this.onCheckTaxType("gst")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"GST"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={taxTypes.vat}
                                  checked={taxTypes.vat}
                                  onChange={this.onCheckTaxType("vat")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"VAT"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={taxTypes.noTax}
                                  checked={taxTypes.noTax}
                                  onChange={this.onCheckTaxType("noTax")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"NO TAX"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Sale Order Types
                              {console.log(
                                "getting the props value here",
                                saleOrderTypes,
                                currencyTypes
                              )}
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={saleOrderTypes.general}
                                  checked={saleOrderTypes.general}
                                  onChange={this.onCheckSaleType("general")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"General"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={saleOrderTypes.rawMaterial}
                                  checked={saleOrderTypes.rawMaterial}
                                  onChange={this.onCheckSaleType("rawMaterial")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"Raw Material"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={saleOrderTypes.juteSale}
                                  checked={saleOrderTypes.juteSale}
                                  onChange={this.onCheckSaleType("juteSale")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"Jute Sale Goods"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Broker Type
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={brokerTypes.jute}
                                  checked={brokerTypes.jute}
                                  onChange={this.onCheckBrokerType("jute")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"Jute"}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  value={brokerTypes.sales}
                                  checked={brokerTypes.sales}
                                  onChange={this.onCheckBrokerType("sales")}
                                  // onClick={() => this.handleToggle(prop)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                  }}
                                  style={{ marginLeft: 10 }}
                                />
                              }
                              classes={{
                                label: classes.menulabel,
                              }}
                              label={"Sale"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Construction Fields
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={constructionFields === "1"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      constructionFields: "1",
                                    });
                                  }}
                                  value={1}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"No"}
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={constructionFields === "2"}
                                  onChange={(event) => {
                                    //  if(!this.state.updateView)
                                    this.setState({
                                      constructionFields: "2",
                                    });
                                  }}
                                  value={2}
                                  name="radio button demo"
                                  aria-label="A"
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                  }}
                                />
                              }
                              label={"Yes"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={12}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Approval Tasks
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <GridContainer>
                              {ApprovalTasks.map((prop, i) => {
                                /*console.log("datasupp",prop);
                                                    console.log("datasupp1",supplierTypes[i]);*/
                                // console.log("index",tasksList.filter(vendor => vendor.taskId === prop.taskId).length > 0);

                                return (
                                  <ItemGrid xs={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          tabIndex={-1}
                                          value={
                                            tasksList.filter(
                                              (vendor) =>
                                                vendor.taskId === prop.taskId
                                            ).length > 0
                                              ? 1
                                              : 0
                                          }
                                          checked={
                                            tasksList.filter(
                                              (vendor) =>
                                                vendor.taskId === prop.taskId
                                            ).length > 0
                                              ? 1
                                              : 0
                                          }
                                          onChange={this.onCheckTasks(prop)}
                                          // onClick={() => this.handleToggle(prop)}
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                          }}
                                          style={{ marginLeft: 10 }}
                                        />
                                      }
                                      classes={{
                                        label: classes.menulabel,
                                      }}
                                      label={prop.taskName}
                                    />
                                  </ItemGrid>
                                );
                              })}
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                        <GridContainer justify="center">
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={this.onCreateButtonClick}
                            >
                              save
                            </Button>
                          </ItemGrid>
                          <ItemGrid>
                            <Button
                              color="danger"
                              onClick={this.hideAlertMessage}
                            >
                              Cancel
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                ) : null}
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
export default withStyles(extendedFormsStyle)(Configuration);
