import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import { VIEW } from "../../ui-actions/UiActions";
import LineItemTable from "../CustumComponents/LineItemTable";
import { Conditions, Operators } from "../TableData";
import InputAdornment from "material-ui/Input/InputAdornment";
import List from "material-ui/List/List";
import ListItem from "material-ui/List/ListItem";
import ListItemText from "material-ui/List/ListItemText";
import { connect } from "react-redux";
// new code implemented
class PaySchemeCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment().format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      alert: false,
      isRefresh: false,
      isLoading: false,
      loadType: false,
      itemViewType: "id",
      year: moment().format("YYYY"),
      dataTable: null,
      wageType: "",
      isDisabled: false,
      disbaled: false,
      componentId: "",
      formula: "",
      defaultValue: "",
      EducationList: [],
      PayschemeList: [],
      paySchemeListData: [],
      payComponentData: [],
      paySchemeListEarningsData: [],
      paySchemeListDeductionData: [],
      paySchemeListNoCalData: [],
      paySchemeListSummaryData: [],
      oldPayscheme: "",
      paySchemeStatus: "",
      calculationError: "",
      activeStatus: "",
      designation: "",
      branchid: "",
      locationList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    var table = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsFullAccess: false,
      totalRecords: 0,
      column: [
        {
          filterable: false,
          Header: "Payscheme Id",
          accessor: "id",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Payscheme Code",
          accessor: "code",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Payscheme Name",
          accessor: "name",
          minWidth: 200,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "WageType",
          accessor: "wageDesc",
          minWidth: 150,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Description",
          accessor: "description",
          minWidth: 200,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Status",
          accessor: "statusDesc",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
      ],
    };
    this.setState({
      dataTable: table,
      isRefresh: true,
    });

    this.callPaySchemeList();
    //    this.callFormulaMasterList();
    //  this.callPaySchemeParameters();
    this.callPaySchemeComponentList();
    this.getWageTypes();
    // this.getReportsTable();
    this.callCustomerBranch();
  }
  callCustomerBranch() {
    getDataFromUrl(
      serverApi.SELECT_CUSTOMER_BRANCH + this.props.state.companyInfo.id,
      this.handleCustomerBranch
    );
  }

  handleCustomerBranch = (resp) => {
    var branchid = [];
    resp.forEach((prop, i) => {
      var obj = {
        value: prop.customerLineId,
        name: prop.address + "^" + prop.customerId,
        label: prop.address,
        customerId: prop.customerId,
      };
      branchid.push(obj);
    });
    this.setState({ locationList: branchid, loadLocation: true });
  };
  getCustomerId = (branch) => {
    var val = "";
    if (this.state.locationList.length > 0) {
      this.state.locationList.forEach((prop) => {
        if (prop.value == branch) val = prop.customerId;
      });
    }
    return val;
  };
  callPaySchemeComponentList() {
    getDataFromUrl(serverApi.PAY_SCHEME_DATATABLE, this.handlePayComponent);
  }

  callPaySchemeList() {
    var data = {
      status: "",
      activeStatus: "",
      orgId: getCookie(cons.ORG_ID), // orgid added
    };
    requestList(serverApi.PAY_SCHEME_GET_LIST, data, this.handlePaySchemeList);
  }

  /*
        callFormulaMasterList() {
            getDataFromUrl(serverApi.FORMULA_MASTER_LIST + "/" + this.props.state.companyInfo.id + "/" + getCookie(cons.USERID), this.handleList);
        }
    */

  callPaySchemeParameters() {
    getDataFromUrl(
      serverApi.PAY_SCHEME_PARAMETERS_DATATABLE +
        "/" +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.USERID),
      this.handleparameters
    );
  }

  getWageTypes() {
    getDataFromUrl(serverApi.GET_WAGE_TYPES, this.handleWageTypeList);
  }

  handleWageTypeList = (resp) => {
    var WageType = [];
    resp.map((prop, i) => {
      var obj = {
        value: prop.id,
        name: prop.name,
        label: prop.name,
      };
      WageType.push(obj);
    });
    this.setState({ WageTypeList: WageType });
  };
  handlePayComponent = (resp) => {
    console.log(resp);
    var componentList = [];
    resp.map((prop, i) => {
      if (i === 0)
        componentList.push({
          value: "",
          name: "",
          label: "Select Component",
        });

      var obj = {
        value: prop.id,
        name:
          prop.name +
          "^" +
          prop.id +
          "^" +
          prop.roundOf +
          "^" +
          prop.defaultValue +
          "^" +
          prop.code,
        label: prop.name,
      };
      componentList.push(obj);
    });
    this.setState({
      componentData: componentList,
      loadType: true,
      payComponentData: resp,
    });
  };
  handlePaySchemeList = (resp) => {
    if (resp.status === undefined) {
      var dTable = this.state.dataTable;
      dTable.data = resp;
      this.setState({
        dataTable: dTable,
        isRefresh: true,
      });
    }
    console.log("payschemennnnnn", resp);
  };

  handleList = (resp) => {
    var formula = [];
    resp.map((prop, i) => {
      var obj = {
        value: prop.formulaId,
        name: prop.formulaName,
        label: prop.formulaName,
      };
      formula.push(obj);
    });
    this.setState({ formulaList: formula });
  };
  handleparameters = (resp) => {
    var parameters = [];
    resp.map((prop, i) => {
      var obj = {
        value: prop.paramId,
        name: prop.paramName,
        label: prop.paramName,
      };
      parameters.push(obj);
    });
    this.setState({ paramList: parameters });
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) this.getReportsTable();
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.PAY_SCHEME_GET_BYID + "/" + id,
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.paySchemeDTO;
    var list = resp.paySchemeDetailsDTO,
      paySchemeListEarningsData = [],
      paySchemeListDeductionData = [],
      paySchemeListNoCalData = [],
      paySchemeListSummaryData = [];
    list.forEach((prop, key) => {
      var obj = {
        id: 0,
        lineItemId: prop.id,
        componentId: prop.componentId,
        componentvalue: this.getComponentName(prop.componentId),
        formula: prop.formula,
        type: this.getComponentName(prop.componentId, "type"),
        isActive: prop.status,
        //formula:defaultValue
      };
      console.log(
        "componentvalue type",
        this.getComponentName(prop.componentId, "type")
      );

      if (obj.type === 0) {
        obj.id = paySchemeListNoCalData.length;
        paySchemeListNoCalData.push(obj);
      }
      if (obj.type === 1) {
        obj.id = paySchemeListEarningsData.length;
        paySchemeListEarningsData.push(obj);
      }
      if (obj.type === 2) {
        obj.id = paySchemeListDeductionData.length;
        paySchemeListDeductionData.push(obj);
      }
      if (obj.type === 3) {
        obj.id = paySchemeListSummaryData.length;
        paySchemeListSummaryData.push(obj);
      }
    });
    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        payschemeId: header.id,
        description: header.description,
        designation: header.designation,
        branchid: header.branchid,
        effectiveFrom: header.effectiveFrom,
        payschemeCode: header.code,
        payschemeName: header.name,
        wageType: header.wageId,
        paySchemeListEarningsData: paySchemeListEarningsData,
        paySchemeListDeductionData: paySchemeListDeductionData,
        paySchemeListNoCalData: paySchemeListNoCalData,
        paySchemeListSummaryData: paySchemeListSummaryData,
        paySchemeStatus: header.status,
        activeStatus: header.activeStatus,
      });
      //  if(header.status!==32)
      this.onClaculationButtonClick();
    }
  };
  getComponentName = (id, name) => {
    var componentvalue = "";
    this.state.payComponentData.forEach(function(value) {
      if (id === value.id) {
        componentvalue = value.name;
        if (name === "type") {
          componentvalue = value.type;
          console.log("type", value.id, componentvalue);
        }
        if (name === "roundOf") {
          componentvalue = value.roundOf;
        }
      }
    });
    return componentvalue;
  };

  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      taskCode: 1011,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    // this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  onChangeValues = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    if (event.target.id === "defaultValue" && event.target.value !== "") {
      this.setState({ disabled: true });
      this.setState({ isDisabled: false });
    } else if (event.target.id === "formula" && event.target.value !== "") {
      this.setState({ isDisabled: true });
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: false });
    }
    var value = event.target.value;
    var id = event.target.id;
    if (id === "payschemeCode") {
      this.hasWhiteSpace(value);
    } else {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  hasWhiteSpace(s) {
    let reWhiteSpace = new RegExp(/\s/);
    if (reWhiteSpace.test(s)) {
      this.basicAlert(" Space is not Allowed..!!");
      return false;
    } else {
      this.setState({
        payschemeCode: s,
        payschemeName: s,
      });
    }
    return true;
  }

  paySchemeParameters = () => {
    this.props.history.push("/psparameter");
  };
  formulaMaster = () => {
    this.props.history.push("/formulamaster");
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      formulaId: "",
      paramId: "",
      paramValue: "",
      orderId: "",
      componentId: "",
      loadType: true,
      formula: "",
      defaultValue: "",
    });
  }

  hideAlertMessage() {
    this.callPaySchemeList();
    this.setState({
      oldPayscheme: "",
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      wageType: "",
      payschemeName: "",
      effectiveFrom: "",
      payschemeCode: "",
      formulaId: "",
      orderId: "",
      paramId: "",
      paramValue: "",
      createdBy: "",
      createdOn: "",
      PayschemeList: [],
      componentId: "",
      loadType: true,
      formula: "",
      defaultValue: "",
      paySchemeListEarningsData: [],
      paySchemeListDeductionData: [],
      paySchemeListNoCalData: [],
      paySchemeListSummaryData: [],
      description: "",
      paySchemeStatus: "",
      calculationError: "",
      activeStatus: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItemSummary) {
      var data = this.state.paySchemeListSummaryData;
      if (this.state.lineItemId === undefined) {
        data.splice(this.state.id, 1);
        this.setState({ paySchemeListSummaryData: data });
      } else {
        data.map((o, key) => {
          // o.id = key;
          if (o.id === this.state.id) {
            data[key].isActive = 0;
          }
        });
        this.setState({
          paySchemeListSummaryData: data,
          deleteItemSummary: false,
        });
      }
    }

    if (this.state.deleteItemNoCal) {
      var data = this.state.paySchemeListNoCalData;
      if (this.state.lineItemId === undefined) {
        data.splice(this.state.id, 1);
        this.setState({ paySchemeListNoCalData: data });
      } else {
        data.map((o, key) => {
          // o.id = key;
          if (o.id === this.state.id) {
            data[key].isActive = 0;
          }
        });
        this.setState({ paySchemeListNoCalData: data, deleteItemNoCal: false });
      }
    }

    if (this.state.deleteItemDeduction) {
      var data = this.state.paySchemeListDeductionData;
      if (this.state.lineItemId === undefined) {
        data.splice(this.state.id, 1);
        this.setState({ paySchemeListDeductionData: data });
      } else {
        data.map((o, key) => {
          // o.id = key;
          if (o.id === this.state.id) {
            data[key].isActive = 0;
          }
        });
        this.setState({
          paySchemeListDeductionData: data,
          deleteItemDeduction: false,
        });
      }
      console.log("paySchemeListDeductionData", data);
    }
    if (this.state.deleteItemSummary) {
      var data = this.state.paySchemeListSummaryData;
      if (this.state.lineItemId === undefined) {
        data.splice(this.state.id, 1);
        this.setState({ paySchemeListSummaryData: data });
      } else {
        data.map((o, key) => {
          // o.id = key;
          if (o.id === this.state.id) {
            data[key].isActive = 0;
          }
        });
        this.setState({
          paySchemeListSummaryData: data,
          deleteItemSummary: false,
        });
      }
    }

    if (this.state.deleteItem) {
      var data = this.state.paySchemeListEarningsData;
      if (this.state.lineItemId === undefined) {
        data.splice(this.state.id, 1);
        this.setState({ paySchemeListEarningsData: data });
      } else {
        data.map((o, key) => {
          // o.id = key;
          if (o.id === this.state.id) {
            data[key].isActive = 0;
          }
        });
        this.setState({ paySchemeListEarningsData: data, deleteItem: false });
      }
    }

    this.setState({
      basicAlert: null,
    });
  }

  onClickAddItem() {
    const { componentId, defaultValue, formula, payComponentData } = this.state;
    var value = "";
    var arrEarning = this.state.paySchemeListEarningsData;
    var arrDeduction = this.state.paySchemeListDeductionData;
    var arrNocal = this.state.paySchemeListNoCalData;
    var paySchemeListSummaryData = this.state.paySchemeListSummaryData;
    var itemIndexEar = 0;
    var itemIndexDed = 0;
    var itemIndexNoCal = 0;
    itemIndexEar = this.state.paySchemeListEarningsData.length;
    itemIndexDed = this.state.paySchemeListDeductionData.length;
    itemIndexNoCal = this.state.paySchemeListNoCalData.length;
    if (defaultValue !== "") {
      this.value = defaultValue;
    } else {
      this.value = formula;
    }
    if (componentId !== "" && this.value !== "") {
      console.log(formula, defaultValue);
      console.log("item added");
      var componentvalue = "";
      var type = "";
      payComponentData.forEach(function(value) {
        if (componentId === value.id) {
          componentvalue = value.name;
          type = value.type;
        }
      });
      var formulaValue = "";
      if (formula !== "") {
        formulaValue = formula;
      } else {
        formulaValue = defaultValue;
      }
      if (type === 1) {
        var addItemObj = {
          id: itemIndexEar,
          componentId: componentId,
          componentvalue: componentvalue,
          formula: formulaValue,
          type: type,
          isActive: 1,
          //formula:defaultValue
        };
        console.log(addItemObj);
        arrEarning.splice(arrEarning.length, 0, addItemObj);

        this.setState({
          paySchemeListEarningsData: arrEarning,
          alert: false,
        });
      } else if (type === 2) {
        var addItemObj1 = {
          id: itemIndexDed,
          componentId: componentId,
          componentvalue: componentvalue,
          formula: formulaValue,
          type: type,
          isActive: 1,
          //formula:defaultValue
        };
        console.log(addItemObj1);
        arrDeduction.splice(arrDeduction.length, 0, addItemObj1);
        this.setState({
          paySchemeListDeductionData: arrDeduction,
          alert: false,
        });
      } else if (type === 0) {
        var addItemObjNoCal = {
          id: itemIndexNoCal,
          componentId: componentId,
          componentvalue: componentvalue,
          formula: defaultValue,
          type: type,
          isActive: 1,
        };
        arrNocal.splice(arrNocal.length, 0, addItemObjNoCal);
        console.log(arrNocal);
        this.setState({
          paySchemeListNoCalData: arrNocal,
          alert: false,
        });
      } else if (type === 3) {
        var addItemObjNoCal = {
          id: paySchemeListSummaryData.length,
          componentId: componentId,
          componentvalue: componentvalue,
          formula: formulaValue,
          type: type,
          isActive: 1,
        };
        paySchemeListSummaryData.splice(
          paySchemeListSummaryData.length,
          0,
          addItemObjNoCal
        );
        //  console.log(arrNocal);
        this.setState({
          paySchemeListSummaryData: paySchemeListSummaryData,
          alert: false,
        });
      } else {
        this.setState({
          alert: false,
        });
      }
    }
    //  else {
    //   this.basicAlert("Please Enter All Mandatory Fields..!!");
    // }
    // this.hideAlert();
  }

  handleAddItem() {
    const { classes } = this.props;
    const { componentId, payschemeCode, payschemeName, wageType } = this.state;
    this.setState({
      alert: true,
    });
  }

  onClaculationButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        payschemeId,
        paySchemeListEarningsData,
        paySchemeListDeductionData,
        paySchemeListNoCalData,
        paySchemeListSummaryData,
      } = this.state;

      var PayschemeList = [
        ...paySchemeListEarningsData,
        ...paySchemeListDeductionData,
        ...paySchemeListNoCalData,
        ...paySchemeListSummaryData,
      ];

      var obj1 = {};
      PayschemeList.forEach(function(prop, keyIndex) {
        if (prop.isActive === 1)
          obj1 = { ...obj1, [prop.componentId]: prop.formula };
      });

      if (payschemeId !== "" && payschemeId !== undefined) {
        requestList(
          serverApi.CHECK_PAYSCHEME + "/1111/" + payschemeId,
          obj1,
          this.handleCheckPayscheme
        );
      } else {
        requestList(
          serverApi.CHECK_PAYSCHEME + "/1111/0",
          obj1,
          this.handleCheckPayscheme
        );

        // this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onUpdateButtonClick = (status, isActive) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        payschemeId,
        description,
        effectiveFrom,
        payschemeCode,
        payschemeName,
        wageType,
        paySchemeListEarningsData,
        paySchemeListDeductionData,
        paySchemeListNoCalData,
        paySchemeListSummaryData,
      } = this.state;

      var PayschemeList = [
        ...paySchemeListEarningsData,
        ...paySchemeListDeductionData,
        ...paySchemeListNoCalData,
        ...paySchemeListSummaryData,
      ];
      PayschemeList = PayschemeList.map((prop) => {
        var obj = {
          id: prop.lineItemId,
          componentId: prop.componentId,
          formula: prop.formula,
          paySchemeId: payschemeId,
          type: prop.type,
          status: prop.isActive,
        };
        return obj;
      });

      var data = {
        paySchemeDTO: {
          id: payschemeId,
          businessunitId: this.props.state.companyInfo.id,
          designation: this.state.designation,
          branchid: this.state.branchid,
          code: payschemeCode,
          description: description,
          name: payschemeName,
          wageId: wageType,
          status: status === 32 ? 32 : 1,
          activeStatus: isActive === 0 ? 0 : 1,
        },
        paySchemeDetailsDTO: PayschemeList,
      };


      if(payschemeCode ==="" ||payschemeCode===undefined){
        this.basicAlert("Please Enter Code..!!");
      }
      else if(payschemeName ==="" ||payschemeName===undefined){
        this.basicAlert("Please Enter Name..!!");
      }
      else if(wageType ==="" ||wageType===undefined){
        this.basicAlert("Please Select Wage Type..!!");
      }
      else {
        this.handleLoading();
        requestList(serverApi.PAY_SCHEME_EDIT, data, this.handleAddPayScheme);
      }

      // if (
      //   payschemeId !== "" &&
      //   payschemeCode !== "" &&
      //   payschemeName !== "" &&
      //   wageType != ""
      // ) {
      //   this.handleLoading();
      //   requestList(serverApi.PAY_SCHEME_EDIT, data, this.handleAddPayScheme);
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        description,
        effectiveFrom,
        payschemeCode,
        payschemeName,
        wageType,
        paySchemeListEarningsData,
        paySchemeListDeductionData,
        paySchemeListNoCalData,
        paySchemeListSummaryData,
      } = this.state;

      var PayschemeList = [
        ...paySchemeListEarningsData,
        ...paySchemeListDeductionData,
        ...paySchemeListNoCalData,
        ...paySchemeListSummaryData,
      ];
      PayschemeList = PayschemeList.map((prop) => {
        if (prop.isActive === 1)
          var obj = {
            componentId: prop.componentId,
            formula: prop.formula,
            type: prop.type,
            status: prop.isActive,
          };
        return obj;
      });

      var data = {
        paySchemeDTO: {
          businessunitId: this.props.state.companyInfo.id,
          code: payschemeCode,
          businessunitId: this.props.state.companyInfo.id,
          designation: this.state.designation,
          branchid: this.state.branchid,
          description: description,
          name: payschemeName,
          wageId: wageType,
          id: 0,
          payschemeId: 0,
        },
        paySchemeDetailsDTO: PayschemeList,
      };

      if(payschemeCode ==="" ||payschemeCode===undefined){
        this.basicAlert("Please Enter Code..!!");
      }
      else if(payschemeName ==="" ||payschemeName===undefined){
        this.basicAlert("Please Enter Name..!!");
      }
      else if(wageType ==="" ||wageType===undefined){
        this.basicAlert("Please Select Wage Type..!!");
      }
      else {
        this.handleLoading();
        requestList(serverApi.PAY_SCHEME_ADD, data, this.handleAddPayScheme);
      }

      // if (payschemeCode !== "" && payschemeName !== "" && wageType != "") {
      //   this.handleLoading();
      //   requestList(serverApi.PAY_SCHEME_ADD, data, this.handleAddPayScheme);
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  handleCheckPayscheme = (resp) => {
    if (resp.status === true) {
      const {
        paySchemeListEarningsData,
        paySchemeListDeductionData,
        paySchemeListNoCalData,
        paySchemeListSummaryData,
      } = this.state;
      var paySchemeListEarningsData1 = paySchemeListEarningsData.map((prop) => {
        var amount = "";
        if (resp.list)
          resp.list.find((o) => {
            if (o.componentId === prop.componentId) {
              var roundOf = this.getComponentName(o.componentId, "roundOf");
              amount = parseFloat(o.amount).toFixed(roundOf);
              return true;
            }
          });
        return { ...prop, value: amount };
      });
      var paySchemeListDeductionData1 = paySchemeListDeductionData.map(
        (prop) => {
          var amount = "";
          if (resp.list)
            resp.list.find((o) => {
              if (o.componentId === prop.componentId) {
                var roundOf = this.getComponentName(o.componentId, "roundOf");
                amount = parseFloat(o.amount).toFixed(roundOf);
                return true;
              }
            });
          return { ...prop, value: amount };
        }
      );
      var paySchemeListNoCalData1 = paySchemeListNoCalData.map((prop) => {
        var amount = "";

        if (resp.list)
          resp.list.find((o) => {
            if (o.componentId === prop.componentId) {
              var roundOf = this.getComponentName(o.componentId, "roundOf");
              amount = parseFloat(o.amount).toFixed(roundOf);
              return true;
            }
          });
        return { ...prop, value: amount };
      });
      var paySchemeListSummaryData1 = paySchemeListSummaryData.map((prop) => {
        var amount = "";
        if (resp.list)
          resp.list.find((o) => {
            if (o.componentId === prop.componentId) {
              var roundOf = this.getComponentName(o.componentId, "roundOf");
              amount = parseFloat(o.amount).toFixed(roundOf);
              return true;
            }
          });
        return { ...prop, value: amount };
      });

      this.setState({
        paySchemeListEarningsData: paySchemeListEarningsData1,
        paySchemeListDeductionData: paySchemeListDeductionData1,
        paySchemeListNoCalData: paySchemeListNoCalData1,
        paySchemeListSummaryData: paySchemeListSummaryData1,
        calculationError: "",
      });
    } else {
      this.setState({ calculationError: resp.message });
    }

    // console.log("paySchemeListEarningsData1",paySchemeListEarningsData1);
  };

  handleAddPayScheme = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.EducationList.length;
    if (length > 8) {
      return length;
    } else {
      return 8;
    }
  };
  onSelect = (selectedValue, stateKey) => {
    var that = this;
    const { payComponentData } = this.state;
    if (stateKey === "componentId") {
      payComponentData.forEach(function(param) {
        if (selectedValue === param.id) {
          let paramType = param.type;
          if (paramType === 0) {
            that.setState({ disabled: true, formula: "" });
          } else {
            that.setState({ disabled: false });
          }
        }
      });
    }
    if (stateKey === "oldPayscheme") {
      if (this.state.oldPayscheme !== selectedValue) {
        this.setState({
          paySchemeListEarningsData: [],
          paySchemeListDeductionData: [],
          paySchemeListNoCalData: [],
          paySchemeListSummaryData: [],
        });
      }

      if (selectedValue !== 0)
        getDataFromUrl(
          serverApi.PAY_SCHEME_GET_BYID + "/" + selectedValue,
          this.handleViewClickRespOld
        );
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleViewClickRespOld = (resp) => {
    var header = resp.paySchemeDTO;
    var list = resp.paySchemeDetailsDTO,
      paySchemeListEarningsData = [],
      paySchemeListDeductionData = [],
      paySchemeListNoCalData = [],
      paySchemeListSummaryData = [];
    list.forEach((prop, key) => {
      var obj = {
        id: 0,
        componentId: prop.componentId,
        componentvalue: this.getComponentName(prop.componentId),
        formula: prop.formula,
        type: this.getComponentName(prop.componentId, "type"),
        isActive: prop.status,
      };
      console.log("component id", prop.componentId);

      if (obj.type === 0) {
        obj.id = paySchemeListNoCalData.length;
        paySchemeListNoCalData.push(obj);
      }
      if (obj.type === 1) {
        obj.id = paySchemeListEarningsData.length;
        paySchemeListEarningsData.push(obj);
      }
      if (obj.type === 2) {
        obj.id = paySchemeListDeductionData.length;
        paySchemeListDeductionData.push(obj);
      }
      if (obj.type === 3) {
        obj.id = paySchemeListSummaryData.length;
        paySchemeListSummaryData.push(obj);
      }
    });
    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        paySchemeListEarningsData: paySchemeListEarningsData,
        paySchemeListDeductionData: paySchemeListDeductionData,
        paySchemeListNoCalData: paySchemeListNoCalData,
        paySchemeListSummaryData: paySchemeListSummaryData,
      });
    }
  };

  getPaySchemeListEarningsData = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.paySchemeListEarningsData.length > 0)
      this.state.paySchemeListEarningsData.map((prop, key) => {
        if (prop.isActive === 1) {
          prop.id = key;
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.paySchemeListEarningsData;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                          lineItemId: prop.lineItemId,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };
          data.push(obj);
        }
      });
    return data;
  };
  getPaySchemeListDeductionsData = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.paySchemeListDeductionData.length > 0)
      this.state.paySchemeListDeductionData.map((prop, key) => {
        if (prop.isActive === 1) {
          prop.id = key;
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.paySchemeListDeductionData;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItemDeduction: true,
                          id: o.id,
                          lineItemId: prop.lineItemId,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };
          data.push(obj);
        }
      });
    return data;
  };
  handleListItemClick = (event, index) => {};

  render() {
    const { classes } = this.props;
    const { PayschemeList, dataTable } = this.state;
    var payList = [],
      payschemeOptions = [];
    PayschemeList.forEach((prop) => {
      payList.push(prop);
    });
    if (dataTable) {
      payschemeOptions.push({ value: 0, name: "", label: "select..." });
      dataTable.data.forEach((prop) => {
        payschemeOptions.push({
          value: prop.id,
          name: prop.name,
          label: prop.name,
        });
      });
    }

    const options = [
      { value: 0, name: "select...", label: "select..." },
      { value: 1, name: "Daily", label: "Daily" },
      { value: 2, name: "Weekly", label: "Weekly" },
      { value: 3, name: "Fortnight", label: "Fortnight" },
      { value: 4, name: "Monthly", label: "Monthly" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");

    var valid = function(current) {
      if (this.props.state.companyInfo.id !== "2")
        return current.isAfter(yesterday);
      else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <form>
                          <GridContainer xs={12} md={12}>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Component
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.componentId}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          name = name.split("^");

                                          this.setState({
                                            defaultValue: name[3],
                                            roundOf: name[2],
                                          });
                                        }}
                                        isRefresh={this.state.loadType}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadType: false });
                                        }}
                                        stateKey="componentId"
                                        staticData={this.state.componentData}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {this.getComponentName(
                                  this.state.componentId,
                                  "type"
                                ) !== 3 &&
                                !this.state.isDisabled &&
                                this.state.disabled ? (
                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Default Value{" "}
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          //disabled={this.state.isDisabled}
                                          id="defaultValue"
                                          value={this.state.defaultValue}
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          iType={"number"}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!this.state.disabled ? (
                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Formula Component
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          defValue={this.state.component}
                                          onSelect={this.onSelect}
                                          getName={(name) => {
                                            name = name.split("^");
                                            var id = name[4];
                                            var formula = "";
                                            if (this.state.formula !== null)
                                              formula = this.state.formula;
                                            if (id !== undefined)
                                              this.setState({
                                                formula: formula + id,
                                                loadComp: true,
                                                component: "",
                                              });
                                          }}
                                          isRefresh={this.state.loadComp}
                                          setIsRefresh={(val) => {
                                            this.setState({ loadComp: false });
                                          }}
                                          stateKey="component"
                                          staticData={this.state.componentData}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {!this.state.disabled ? (
                                  <ItemGrid xs={12} md={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Operator/Condition
                                        </InputLabel>
                                      </ItemGrid>

                                      <ItemGrid
                                        xs={12}
                                        sm={8}
                                        style={{
                                          height: 250,
                                          overflowY: "auto",
                                        }}
                                      >
                                        <List
                                          component="nav"
                                          aria-label="secondary mailbox folder"
                                        >
                                          {Operators.map((prop) => {
                                            return (
                                              <ListItem
                                                button
                                                selected={
                                                  this.state.operator === 3
                                                }
                                                onClick={(event) => {
                                                  var name = prop.name;
                                                  var formula = "";
                                                  if (
                                                    this.state.formula !== null
                                                  )
                                                    formula = this.state
                                                      .formula;
                                                  if (name !== "")
                                                    this.setState({
                                                      formula: formula + name,
                                                      operator: "",
                                                      loadOp: true,
                                                    });
                                                }}
                                              >
                                                <ListItemText
                                                  primary={prop.label}
                                                />
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                        {/*
                                                                                            <SelectboxTemp
                                                                                                defValue={this.state.operator}
                                                                                                onSelect={this.onSelect}
                                                                                                getName={(name) => {
                                                                                                    var formula = "";
                                                                                                    if (this.state.formula !== null)
                                                                                                        formula = this.state.formula;
                                                                                                    if (name !== "")
                                                                                                        this.setState({
                                                                                                            formula: formula + name,
                                                                                                            operator: "",
                                                                                                            loadOp: true
                                                                                                        })
                                                                                                }}
                                                                                                isRefresh={this.state.loadOp}
                                                                                                setIsRefresh={(val) => {
                                                                                                    this.setState({loadOp: false})
                                                                                                }}
                                                                                                stateKey="operator"
                                                                                                staticData={Operators}
                                                                                            />
*/}
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}
                                {/*
                                                                                <ItemGrid xs={12} md={6}>
                                                                                    <GridContainer>
                                                                                        <ItemGrid xs={12} sm={4}>
                                                                                            <InputLabel
                                                                                                className={classes.label}>
                                                                                                Condition
                                                                                            </InputLabel>
                                                                                        </ItemGrid>

                                                                                        <ItemGrid xs={12} sm={8}>
                                                                                            <SelectboxTemp
                                                                                                defValue={this.state.condition}
                                                                                                onSelect={this.onSelect}
                                                                                                getName={(name) => {
                                                                                                    var formula = "";
                                                                                                    if (this.state.formula !== null)
                                                                                                        formula = this.state.formula;
                                                                                                    if (name !== "")
                                                                                                        this.setState({
                                                                                                            formula: formula + name,
                                                                                                            condition: "",
                                                                                                            loadCondition: true
                                                                                                        })
                                                                                                }}
                                                                                                isRefresh={this.state.loadCondition}
                                                                                                setIsRefresh={(val) => {
                                                                                                    this.setState({loadCondition: false})
                                                                                                }}
                                                                                                stateKey="condition"
                                                                                                staticData={Conditions}
                                                                                            />


                                                                                        </ItemGrid>
                                                                                    </GridContainer>
                                                                                </ItemGrid>
*/}
                                {!this.state.disabled ? (
                                  <ItemGrid xs={12} md={6}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Value
                                        </InputLabel>
                                      </ItemGrid>

                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          //disabled={this.state.isDisabled}
                                          id="value"
                                          value={this.state.value}
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          iType={"number"}
                                          InputProps={{
                                            autoComplete: "off",
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <span
                                                  style={{
                                                    color: "#003F66",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (this.state.value !== "")
                                                      this.setState({
                                                        formula:
                                                          this.state.formula +
                                                          this.state.value,
                                                        value: "",
                                                      });
                                                  }}
                                                >
                                                  ADD
                                                </span>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                {!this.state.disabled ? (
                                  <ItemGrid
                                    xs={12}
                                    md={12}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={2}>
                                        <InputLabel className={classes.label}>
                                          Formula
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={4}>
                                        <TextField
                                          disabled={false}
                                          id="formula"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          value={this.state.formula}
                                          fullWidth={true}
                                          InputProps={{
                                            autoComplete: "off",
                                          }}
                                          height={60}
                                          multiline={true}
                                          rowsMax={3}
                                          //  disabled={this.state.disabled}
                                        />
                                      </ItemGrid>
                                      <ItemGrid>
                                        <Button
                                          onClick={() => {
                                            var formula = this.state.formula;

                                            if (formula !== "") {
                                              var lastCharRemoved = formula.slice(
                                                0,
                                                formula.length - 1
                                              );
                                              this.setState({
                                                formula: lastCharRemoved,
                                              });
                                            }
                                          }}
                                          color={"info"}
                                        >
                                          Undo
                                        </Button>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                <ItemGrid
                                  xs={12}
                                  md={12}
                                  style={{ marginTop: "10px" }}
                                >
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontWeight: 400,
                                      color: "red",
                                    }}
                                  >
                                    Note:
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {
                                      "select either Formula or Default Value..!!"
                                    }
                                  </span>
                                </ItemGrid>
                                {/*  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Order Id <span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    // disabled={true}
                                                                                    id="orderId"
                                                                                    value={this.state.orderId}
                                                                                    //  className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <InputLabel className={classes.label}>
                                                                            Note: Order Id should be given in sequence order starts from 1.
                                                                        </InputLabel>
                                                                    </ItemGrid> */}
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="payschemeCode"
                          value={this.state.payschemeCode}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="payschemeName"
                          value={this.state.payschemeName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Wage Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.wageType}
                          id={"wageType"}
                          onSelect={this.onSelect}
                          //getName={this.getQualityDetails}
                          // isRefresh={this.state.loadquality}
                          //setIsRefresh={this.afterRefreshquality}
                          stateKey="wageType"
                          staticData={this.state.WageTypeList}
                          value={this.state.wageType}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Description
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            id="description"
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            value={this.state.description}
                            fullWidth={true}
                            InputProps={{
                              autoComplete: "off",
                            }}
                            height={60}
                            multiline={true}
                            rowsMax={"3"}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Designation
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.designation}
                          onSelect={this.onSelect}
                          stateKey="designation"
                          url={
                            serverApi.SELECT_DESIGNATION +
                            this.props.state.companyInfo.id +
                            "/" +
                            0
                          }
                          value={this.state.designation}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Location
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.branchid}
                          onSelect={this.onSelect}
                          stateKey="branchid"
                          staticData={this.state.locationList}
                          isRefresh={this.state.loadLocation}
                          setIsRefresh={(val) => {
                            this.setState({
                              loadLocation: false,
                            });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Copy From Payscheme
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.oldPayscheme}
                            id={"oldPayscheme"}
                            onSelect={this.onSelect}
                            stateKey="oldPayscheme"
                            staticData={payschemeOptions}
                            value={this.state.oldPayscheme}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid
                    xs={12}
                    style={{
                      marginTop: "10px",
                      height: 2,
                      backgroundColor: "#00acc1",
                    }}
                  />
                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12}>
                        <InputLabel className={classes.label}>
                          Input Values
                        </InputLabel>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <GridContainer>
                      {this.state.paySchemeListNoCalData.map((value, key) => {
                        if (value.isActive === 1) {
                          //console.log(value,key)
                          return (
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    {value.componentvalue}
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={7}>
                                  <TextField
                                    id={key}
                                    // defaultValue={value.formula}
                                    value={value.formula}
                                    className={classes.textField}
                                    onChange={(event) => {
                                      var data = this.state
                                        .paySchemeListNoCalData;
                                      data[key].formula = event.target.value;
                                      this.setState({
                                        paySchemeListNoCalData: data,
                                      });
                                    }}
                                    fullWidth={true}
                                    variant="outlined"
                                    // getName={this.getDeptName}
                                    iType={"number"}
                                  />
                                </ItemGrid>
                                {this.state.paySchemeStatus !== 32 ? (
                                  <ItemGrid
                                    xs={12}
                                    sm={1}
                                    style={{
                                      marginTop: "-10px",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        var data = this.state
                                          .paySchemeListNoCalData;
                                        data.find((o, i) => {
                                          if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            this.setState({
                                              deleteItemNoCal: true,
                                              id: o.id,
                                              lineItemId: o.lineItemId,
                                            });

                                            this.basicItemAlert(
                                              "Do You Like To Delete This Value?"
                                            );
                                            return true;
                                          }
                                        });
                                        //   this.setState({invoiceList: data});
                                      }}
                                      color="dangerNoBackground"
                                      customClass="remove"
                                    >
                                      <Close />
                                    </IconButton>
                                  </ItemGrid>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>
                          );
                        }
                      })}
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />
                  {/* <ItemGrid xs={12} md={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Wage Type<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>

                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.wageType}
                                                        id={"wageType"}
                                                        onSelect={this.onSelect}
                                                        //getName={this.getQualityDetails}
                                                        // isRefresh={this.state.loadquality}
                                                        //setIsRefresh={this.afterRefreshquality}
                                                        stateKey="wageType"
                                                        staticData={options}
                                                        value={this.state.wageType}
                                                    />

                                                </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <InputLabel className={classes.label}>
                      Earnings :
                    </InputLabel>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <InputLabel className={classes.label}>
                      Deductions :
                    </InputLabel>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <LineItemTable
                      data={this.getPaySchemeListEarningsData()}
                      loading={this.state.loading}
                      tableSize={350}
                      disableActions={this.state.paySchemeStatus === 32}
                      columns={[
                        {
                          Header: "Component",
                          accessor: "componentvalue",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Formula",
                          accessor: "formula",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },

                        {
                          Header: "Value",
                          accessor: "value",
                          minWidth: 50,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          minWidth: 50,
                          style: { color: "#000", textAlign: "left" },
                        },
                      ]}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <LineItemTable
                      data={this.getPaySchemeListDeductionsData()}
                      loading={this.state.loading}
                      tableSize={350}
                      disableActions={this.state.paySchemeStatus === 32}
                      columns={[
                        {
                          Header: "Component",
                          accessor: "componentvalue",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Formula",
                          accessor: "formula",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },

                        {
                          Header: "Value",
                          accessor: "value",
                          minWidth: 50,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          minWidth: 50,
                          style: { color: "#000", textAlign: "left" },
                        },
                      ]}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <InputLabel className={classes.label}>Summary:</InputLabel>
                  </ItemGrid>

                  {this.state.paySchemeListSummaryData.map((value, key) => {
                    if (value.isActive === 1) {
                      return (
                        <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {value.componentvalue}
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={7}>
                              <TextField
                                id={key}
                                disabled={true}
                                //defaultValue={value.defaultValue}
                                value={value.value}
                                className={classes.textField}
                                onChange={(event) => {
                                  var data = this.state
                                    .paySchemeListSummaryData;
                                  data[key].value = event.target.value;
                                  this.setState({
                                    paySchemeListSummaryData: data,
                                  });
                                }}
                                fullWidth={true}
                                variant="outlined"
                                // getName={this.getDeptName}
                                iType={"number"}
                              />
                              <InputLabel className={classes.label}>
                                <span
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Formula: {value.formula}
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            {this.state.paySchemeStatus !== 32 ? (
                              <ItemGrid
                                xs={12}
                                sm={1}
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "-10px",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    var data = this.state
                                      .paySchemeListSummaryData;
                                    data.find((o, i) => {
                                      if (o.id === key) {
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        this.setState({
                                          deleteItemSummary: true,
                                          id: o.id,
                                          lineItemId: o.lineItemId,
                                        });

                                        this.basicItemAlert(
                                          "Do You Like To Delete This Value?"
                                        );
                                        return true;
                                      }
                                    });
                                    //this.setState({invoiceList: data});
                                  }}
                                  color="dangerNoBackground"
                                  customClass="remove"
                                >
                                  <Close />
                                </IconButton>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </ItemGrid>
                      );
                    }

                    //console.log(value,key)
                  })}
                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />
                  {this.state.paySchemeStatus !== 32 &&
                  this.state.activeStatus !== 0 ? (
                    <ItemGrid xs={12} sm={12}>
                      <Button
                        color={"info"}
                        right={true}
                        onClick={this.handleAddItem}
                      >
                        Add Item
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {/*
                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}/>
*/}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 130 }}
                            onClick={this.onCreateButtonClick}
                          >
                            save
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 130 }}
                            onClick={this.onClaculationButtonClick}
                          >
                            Check Calculation
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            color="danger"
                            style={{ width: 130 }}
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {this.state.paySchemeStatus !== 32 &&
                        this.state.activeStatus !== 0 ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              style={{ width: 130 }}
                              onClick={this.onUpdateButtonClick}
                            >
                              Update
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.activeStatus !== 0 ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              style={{ width: 130 }}
                              onClick={this.onClaculationButtonClick}
                            >
                              Check Claculation
                            </Button>
                          </ItemGrid>
                        ) : null}

                        {this.state.paySchemeStatus !== 32 &&
                        getCookie(cons.HRMS_FULL_ACCESS) ? (
                          <ItemGrid>
                            <Button
                              color="danger"
                              style={{ width: 130 }}
                              onClick={() => {
                                this.onUpdateButtonClick(32);
                              }}
                            >
                              Lock
                            </Button>
                          </ItemGrid>
                        ) : null}

                        {this.state.paySchemeStatus === 32 &&
                        this.state.activeStatus !== 0 &&
                        getCookie(cons.HRMS_FULL_ACCESS) ? (
                          <ItemGrid>
                            <Button
                              color="danger"
                              style={{ width: 130 }}
                              onClick={() => {
                                this.onUpdateButtonClick(32, 0);
                              }}
                            >
                              Deactivate
                            </Button>
                          </ItemGrid>
                        ) : null}
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.calculationError !== "" ? (
                    <ItemGrid xs={12}>
                      <span
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                          color: "red",
                          marginTop: "10px",
                        }}
                      >
                        Error Message: {this.state.calculationError}
                      </span>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        {/*<ItemGrid xs={6} sm={6}>*/}
                        {/*<GridContainer>*/}
                        {/*<ItemGrid xs={12} sm={4}>*/}
                        {/*<InputLabel className={classes.label}>*/}
                        {/*Show*/}
                        {/*</InputLabel>*/}
                        {/*</ItemGrid>*/}
                        {/*<ItemGrid xs={12} sm={8}>*/}
                        {/*<MultiSelect*/}
                        {/*id={"status"}*/}
                        {/*defValue={this.state.status}*/}
                        {/*onSelect={this.onSelect}*/}
                        {/*getName={this.getName}*/}
                        {/*stateKey="status"*/}
                        {/*url={serverApi.GET_PO_BY_SUPPLIER+this.state.selectedSupplier+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}*/}
                        {/*value={this.state.status}*/}
                        {/*/>*/}

                        {/*</ItemGrid>*/}
                        {/*</GridContainer>*/}

                        {/*</ItemGrid>*/}
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <IconButton
                        onClick={this.handleCreate}
                        color="success"
                        right={true}
                      >
                        <Add />
                      </IconButton>
                      {/*<Button color="primary" right={true}*/}
                      {/*onClick={this.handleSeacrh}>Search</Button>*/}
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <ReportsTableComponent
                        // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                        borderRight={true}
                        staticData={this.state.dataTable}
                        //  searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        actionType={VIEW}
                        fileName={"payscheme"}
                        itemViewType={this.state.itemViewType}
                      />

                      {/*                                             <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                                <span style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                                                    onClick={this.paySchemeParameters}>Click Here To Add PayScheme Parameters</span>

                                            </ItemGrid>

                                            <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                                <span style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                                                    onClick={this.formulaMaster}>Click Here To Add Formula Master</span>

                                            </ItemGrid> */}

                      {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument = () => {
    var data = {
      taskCode: 1013,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    var pageNo = 1;
    var modules = [];
    // if(this.state.dataTable!==undefined &&this.state.dataTable!==null ){
    //     console.log("print ",JSON.stringify(this.state.dataTable.data.data));
    //     modules=this.state.dataTable.data.data;
    //     reportValues
    // }
    modules = resp.data.reportValues;
    var grandTotals = resp.data.totalValues;
    var companyName = resp.data.companyName;
    console.log("total", grandTotals);
    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(14);
    doc.text(170, 40, companyName);
    doc.setFontSize(10);
    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);

    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    // doc.table(50, 100,modules,modulesHeader,config);
    doc.text(220, 60, "Report No.:[JUTE/01]");
    doc.text(
      170,
      80,
      "Daily Jute With Value Report From  " +
        this.state.fromDate +
        " to " +
        this.state.todate +
        "           Quantity in :Quintal"
    );
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(30, 100, 30, 30, "Jcode", "", "C");
    doc.cell(60, 100, 45, 30, "Quality", "", "C");
    var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
    doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
    var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
    doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
    var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
    doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
    doc.cell(231, 100, 40, 30, "Rate", "", "C");
    var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
    doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
    var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
    doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
    doc.cell(
      364,
      100,
      155,
      15,
      "<--------------------M T D----------------->",
      "",
      "C"
    );
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
    doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
    doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
    // doc.cellInitialize();
    var cellWidths = ["", 30, 45, 42, 42, 42, 40, 51, 42, 50, 50, 55, 45];
    var cellTextaligns = [
      "",
      "center",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "center",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 110;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 20;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");
          doc.setFontSize(14);
          doc.text(170, 40, companyName);
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
          // doc.table(50, 100,modules,modulesHeader,config);
          doc.text(220, 60, "Report No.:[JUTE/01]");
          doc.text(
            170,
            80,
            "Daily Jute With Value Report From  " +
              this.state.fromDate +
              " to " +
              this.state.todate +
              "           Quantity in :Quintal"
          );
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(30, 100, 30, 30, "Jcode", "", "C");
          doc.cell(60, 100, 45, 30, "Quality", "", "C");
          var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
          doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
          var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
          doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
          var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
          doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
          doc.cell(231, 100, 40, 30, "Rate", "", "C");
          var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
          doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
          var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
          doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
          doc.cell(
            364,
            100,
            155,
            15,
            "<--------------------M T D----------------->",
            "",
            "C"
          );
          /* var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                     doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
          doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
          doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var myItem = prop[key] + " ";
          return doc.cell(
            30,
            k,
            cellWidths[a],
            20,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    // var grandTotals = {
    //     "OpeningWeight": 25455 + "",
    //     "ReceiptWeight": 0.0 + "",
    //     "IssueWeight": 200 + "",
    //     "Rate": " ",
    //     "IssueAmount": 1300000 + "",
    //     "Closing": 2555005 + "",
    //     "ReptQty": 5544 + "",
    //     "IssueQty": 2455 + "",
    //     "IsueAmount": 25555 + "",
    //   //  "StockForDays": " "
    // };

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, k + 20, 75, 30, "Grand Total", "", "left");
    doc.cell(
      105,
      k + 20,
      42,
      30,
      grandTotals.openingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      147,
      k + 20,
      42,
      30,
      grandTotals.receivedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      189,
      k + 20,
      42,
      30,
      grandTotals.issuedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(231, k + 20, 40, 30, " ", "", "right");
    doc.cell(
      271,
      k + 20,
      51,
      30,
      grandTotals.issueAmountTot + " ",
      "",
      "right"
    );
    doc.cell(
      322,
      k + 20,
      42,
      30,
      grandTotals.closingweightcTot + " ",
      "",
      "right"
    );
    doc.cell(
      364,
      k + 20,
      50,
      30,
      grandTotals.receivedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      414,
      k + 20,
      50,
      30,
      grandTotals.issuedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      464,
      k + 20,
      55,
      30,
      grandTotals.issueAmountToDateTot + " ",
      "",
      "right"
    );
    //  doc.cell(519, k + 25, 45, 30, grandTotals.StockForDays, '', 'right');

    var Avgs = {
      AvgIssuePriceToday: 4240.74 + "",
      AvgIssuePriceTodate: 4240.74 + "",
      batchPriceToday: "4284.00",
      batchPriceTodate: " 4339.59",
    };
    doc.setFontType("normal");
    doc.setFontSize(10);

    // doc.text(30, k + 75, 'Avg. Issue Price : Today');
    // doc.text(170, k + 75, Avgs.AvgIssuePriceToday);
    // doc.text(220, k + 75, 'Todate');
    // doc.text(260, k + 75, Avgs.AvgIssuePriceTodate);
    // doc.text(30, k + 90, 'Batch Price        : Today');
    // doc.text(170, k + 90, Avgs.batchPriceToday);
    // doc.text(220, k + 90, 'Todate');
    // doc.text(260, k + 90, Avgs.batchPriceTodate);

    doc.text(30, k + 150, "Dept. Incharge");
    doc.text(400, k + 150, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 200, newdat);
    doc.text(500, k + 200, printBy);

    doc.save("JuteValueReport.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(PaySchemeCreation)
);
