import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import List from "material-ui/List";
import ListItem from "material-ui/List/ListItem";


import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import IconCustomButton from "../CustomButtons/IconButton";
import ExpandMoreIcon from "@material-ui/icons/Help";
import ItemGrid from "../Grid/ItemGrid";

function Footer({...props}) {
    const {classes, fluid, white,hideAlert,showAlert} = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });
    var anchor =
        classes.a +
        cx({
            [" " + classes.whiteColor]: white
        });
/*
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    })
*/

    return (
        <footer className={classes.footer}>
            <div className={container}>

                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>

                        </ListItem>
                    </List>
                </div>
                <p className={classes.right}>
                 {/*   VOW JUTE*/}
                    &copy; {1900 + new Date().getYear()}{" "}
                    <a href="#" target="_blank" className={anchor}>
                        {/*{rtlActive ? " " : "Creative Tim"}*/}
                        {window.location.hostname}
                    </a>
                  {/*  <span style={{marginLeft:10,cursor:'pointer',color:'#00ADBC'}} onClick={showAlert}>Help</span>
                    <IconCustomButton
                        onClick={showAlert}
                        color={"primaryNoBackground"}>
                        <ExpandMoreIcon/>
                    </IconCustomButton>*/}

                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
