// ##############################
// // // CustomLinearProgress component styles
// #############################

import {
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const customCircularProgressStyle = {
    root: {
        height: "4px",
        marginBottom: "20px",
        overflow: "hidden",
        position: "absolute", display: "center",
        top: "40%",
        left: "20%",
        right: "30%",
        bottom: "50%",
        margin: "auto",
    },
    bar: {
        height: "4px",

    },
    primaryNoBackground: {
        "&,&:hover,&:focus": {
            color: primaryColor,
            boxShadow: "none",
            backgroundColor: "transparent"
        }
    },
    primary: {
        backgroundColor: primaryColor,
        boxShadow:"none",
    },
    warning: {
        backgroundColor: warningColor
    },
    danger: {
        backgroundColor: dangerColor
    },
    success: {
        backgroundColor: successColor
    },
    info: {
        backgroundColor: infoColor
    },
    rose: {
        backgroundColor: roseColor
    },
    gray: {
        backgroundColor: grayColor
    },
    primaryBackground: {
        background: "rgba(156, 39, 176, 0.2)"
    },
    warningBackground: {
        background: "rgba(255, 152, 0, 0.2)"
    },
    dangerBackground: {
        background: "rgba(244, 67, 54, 0.2)"
    },
    successBackground: {
        background: "rgba(76, 175, 80, 0.2)"
    },
    infoBackground: {
        background: "rgba(0, 188, 212, 0.2)"
    },
    roseBackground: {
        background: "rgba(233, 30, 99, 0.2)"
    },
    grayBackground: {
        background: "rgba(221, 221, 221, 0.2)"
    }
};

export default customCircularProgressStyle;
