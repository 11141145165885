// ##############################
// // // App styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
} from "../../material-dashboard-pro-react.jsx";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    height: "100vh",
    width: "100%",
    overflowScrolling: "touch",
    border: "1px solid transparent",
  },
  content: {
    marginTop: "40px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px",
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important",
  },
  customButton: {
    position: "fixed",
    right: "0",
    bottom: "0",
    width: 160,
    //  height:40,
    marginTop: 20,
    marginRight: 10,
    marginBottom: 0,
    borderTopRightRadius: "6px",
    webkitBorderTopLeftRadius: "6px",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    //borderRadius:'6px',
    //   paddingBottom:'10px',
    //  left: "0",
    zIndex: "1032",
  },
});

export default appStyle;
