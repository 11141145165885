import LoginPage from "../views/login/LoginPage.jsx";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import ForgetPassword from "../views/login/ForgetPassword";
import ResetPassword from "../views/login/ResetPassword";

import getIcon from "./routeIcons";
import PrintData from "../views/CustumComponents/printData";

const pagesRoutes = [
    {
        path: "/Login",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        icon: Fingerprint,
        component: LoginPage
    },
    {path: "/ForgotPassword", name: "ForgotPassword", component: ForgetPassword},
    {path: "/ResetPassword", name: "ResetPassword", component: ResetPassword},
{redirect: true, path: "/", pathTo: "/Login", name: "Login"}
];

export default pagesRoutes;
