import React from "react";
import Select from "react-select";
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { getDataFromUrl, requestList } from "../../server/server";
import PropTypes from "prop-types";

class SelectboxTemp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defValue: [],
      selectData: null,
      url: null,
      selectDisabled: false,
    };
  }
  handleSelectedValues = (defValue) => {
    const { onSelect, getName, stateKey } = this.props;
    console.log("defValue " + JSON.stringify(this.props.defValue));
    if (this.props.isChangeValue !== undefined && !this.props.isChangeValue) {
      // this.setState({ defValue });
      if (onSelect !== undefined && onSelect !== null) {
        if (defValue.length > 0) {
          var array = [];
          defValue.map((o, key) => {
            array.push(o.value);
          });

          onSelect(array, stateKey);
        } else {
          onSelect([], stateKey);
        }
        //onSelect(defValue.value, stateKey);
      }
    } else {
      // if(defValue[this.state.selectData[0]])
      this.setState({ defValue });
      if (onSelect !== undefined && onSelect !== null) {
        //  onSelect(defValue.value, stateKey);
        if (defValue.length > 0) {
          var array = [],
            array1 = [];

          defValue.map((o, key) => {
            if (o.value !== 0 && o.value !== "0") array.push(o.value);
            /*
                        if(key+1===defValue.length&& o.value===0)
                            this.setState({ defValue:[] });
*/
          });

          onSelect(array, stateKey);
        } else {
          onSelect([], stateKey);
        }
        if (getName !== undefined && getName !== null) {
          defValue.map((o, key) => {
            if (o.value !== 0 && o.value !== "0") array1.push(o.name);
          });
          getName(array1);
        }
      }
    }

    console.log(`Option selected:`, defValue);
  };
  getData = () => {
    if (this.props.postData !== null && this.props.postData !== undefined) {
      var data = this.props.postData;
      requestList(this.props.url, data, this.handleDept);
    } else getDataFromUrl(this.props.url, this.handleDept);
  };
  handleDept = (resp) => {
    const {
      onSelect,
      getName,
      stateKey,
      setIsRefresh,
      setIsReload,
    } = this.props;
    //if (resp.status) {
    if (resp.length > 0 && resp.status === undefined) {
      if (this.props.isRefresh) {
        this.setState({
          defValue: [],
        });
      }
      this.setState({ selectData: resp });
      if (this.props.setIsRefresh !== undefined) setIsRefresh(false);
      if (this.props.setIsReload !== undefined) setIsReload(false);

      var defarray = [];
      var defvals = [];
      var dup = 0;
      this.props.defValue.forEach((o, i) => {
        resp.find((obj, key) => {
          if (o === obj.value && o !== null) {
            if (dup === obj.shrCode) {
            } else {
              defarray.push(obj);
            }
            dup = obj.shrCode;
          }
        });
      });
      this.setState({
        defValue: defarray,
      });
    } else {
      if (resp.data !== undefined && resp.status === true) {
        this.setState({ selectData: resp.data });
        var defarray = [];

        this.props.defValue.forEach((o, i) => {
          resp.data.find((obj, key) => {
            if (o === obj.value && o !== null) {
              // here you should add some custom code so you can delete the data
              // from this component and from your server as well

              defarray.push(obj);

              if (onSelect !== undefined && onSelect !== null) {
                //  onSelect(o.value, stateKey);
                if (o.length > 0) {
                  var array = [],
                    array1 = [];
                  obj.map((o, key) => {
                    array.push(obj.value);
                  });
                  onSelect(array, stateKey);
                }
              }
              if (getName !== undefined && getName !== null) {
                obj.map((o, key) => {
                  array1.push(o.name);
                });
                getName(array1);
              }
            }
          });
        });
        console.log("def", defarray);
        this.setState({
          defValue: defarray,
        });
      } else this.setState({ selectData: null, defValue: "" });
    }
  };

  componentDidMount() {
    if (this.props.staticData === undefined || this.props.staticData === null) {
      this.getData();
    } else {
      this.handleDept(this.props.staticData);

      this.setState({
        selectDisabled: this.props.selectDisabled,
      });
    }
  }
  getReloadDef = (options) => {
    this.handleDept(options);
  };

  render() {
    const { classes, setIsRefresh, setIsReload } = this.props;
    const { selectData } = this.state;
    var options = [];
    if (selectData !== null) {
      options = selectData;
      if (this.props.isReload) {
        this.getReloadDef(options);
      }
    }

    //console.log("options",options);
    /*const options = [
            { value: '', label: 'Select...' ,name:'Select...'},
            { value: '1', label: 'Chocolate' ,name:'Chocolate'},
            { value: '2', label: 'Strawberry',name: 'Strawberry' },
            { value: '3', label: 'Vanilla',name: 'Vanilla' }
        ];*/
    // console.log("urlid "+ this.props.isRefresh);
    if (this.props.isRefresh) {
      if (
        this.props.staticData === undefined ||
        this.props.staticData === null
      ) {
        this.getData();
      } else {
        this.handleDept(this.props.staticData);
      }
      if (this.props.setIsRefresh !== undefined) setIsRefresh(false);
    }

    return (
      <div>
        <Select
          isDisabled={this.props.selectDisabled}
          isMulti={true}
          name={"defValue"}
          value={
            this.props.unSelect !== undefined && this.props.unSelect
              ? ""
              : this.state.defValue
          }
          onChange={this.handleSelectedValues}
          options={options}
          className={classes.selectFormControl}
          theme={(theme) => ({
            ...theme,
            borderRadius: "4px",
            height: "20px",
            colors: {
              ...theme.colors,
              //  primary25: '#00ABDC',
              primary: "#00ABDC",
              //   primary50:"#00ABDC"
            },
          })}
          styles={{
            control: (base, state) => ({
              ...base,
              // height: '30px',
              minHeight: "30px",
              maxHeight: "100px",
              marginBottom: "10px",
              overflowY: "auto",
            }),
          }}
        />
      </div>
    );
  }
}

SelectboxTemp.propTypes = {
  defValue: PropTypes.int,
  selectData: PropTypes.object.isRequired,
  selectName: PropTypes.string,
  staticData: PropTypes.object.isRequired,
};

export default withStyles(customSelectStyle)(SelectboxTemp);
