import React, { Component } from 'react'
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import LineItemTable from "../CustumComponents/LineItemTable";
import LogsComponents from "../CustumComponents/LogsComponents";


export class BudgetHeads extends Component {
    constructor(props) {
        super(props)
        this.state = {
            basicAlert: null,
            budgetHeadCode: '',
            budgetHeadName: '',
            parentHeads: '',
            isCreated: false,
            invoiceList: [],
            dataTable:null, 
            itemViewType:"budgetHeadId",
            isActive:"YES",
            resp:[],
            logData:[],
        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
    }
    
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });
    }

    handleCreate = () => {
        this.getReportsData()
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });
    }
    handleViewClick = (id) => {
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.BUDGET_HEAD_TABLE +"/"+id, this.handleViewClickResp);
        }
    }
    handleViewClickResp=(resp)=>{
        var header = resp;

        if (header !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                budgetHeadId:header.budgetHeadId,
                budgetHeadCode: header.code,
                budgetHeadName: header.name,
                parentHeadId:header.parentBudgetId,
                isActive:header.isActive,
                createdBy: header.createdBy,
                createdOn: header.createdOn
            });

        }
    }
    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }
    hideAlertMessage() {
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            budgetHeadCode:"",
            budgetHeadName:"",
            parentHeadId:""
        });
    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }
    onSelect = (selectedValue, stateKey) => {
        this.setState({
            [stateKey]: selectedValue
        });
    };
    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });
    }
    /*Create*/
    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {budgetHeadCode,budgetHeadName} = this.state;
            var data = {
                "code" :budgetHeadCode ,
                "name" : budgetHeadName,
                "description" : budgetHeadName + " " +"DESCRIPTION",
                //"status" : true,
                "parentBudgetId" : this.state.parentHeadId,
                "createdBy": getCookie(cons.USERID)
            }
            if (budgetHeadCode !== "" && budgetHeadName !== "") {
                this.handleLoading();
                requestList(serverApi.BUDGET_HEAD_TABLE, data, this.handleAddBudget)
            }
            else{
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            } 
        }
    };
    
    /*Update*/
    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {budgetHeadId,budgetHeadCode,budgetHeadName,isActive} = this.state;
            var data = {
                "budgetHeadId":budgetHeadId,
                "code" :budgetHeadCode,
                "name" : budgetHeadName,
                "description" : budgetHeadName + " " +"DESCRIPTION",
                //"status" : true,
                "parentBudgetId" : this.state.parentHeadId,
                "isActive": isActive,
                "createdBy": getCookie(cons.USERID)
                
            }
            if (budgetHeadCode !== '' && budgetHeadName !== "") {
                this.handleLoading();
                requestList(serverApi.BUDGET_HEAD_TABLE_UPDATE , data, this.handleAddBudget)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    /*Update ends*/
    handleAddBudget = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };
    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>
                    }
                >
                </Popup>
            )
        });

    };
    componentDidMount(){
        this.getReportsData()
    }
    getReportsData=()=> {
        getDataFromUrl(serverApi.BUDGET_HEAD_TABLE,this.handleBudgetHeadName);
    }
    handleBudgetHeadName = (resp) => {
        var data = resp.data, budgetHeadNameList = [];
        if (data.length > 0) {
            var obj = {label: "Select", value: null, name: "null"};
            budgetHeadNameList.push(obj)
            data.forEach(prop => {
                var name = prop.name;
                var obj = {label: prop.name, value: prop.budgetHeadId, name:prop.name};
                budgetHeadNameList.push(obj)
            });
            this.setState({
                budgetHeadNameList: budgetHeadNameList,
                loadBudgetHeadName: true
            })
        }
    };
    // handleLogsClick=()=>{
    //     const {budgetHeadId} = this.state;
    //     this.handleLoading();
    //     getDataFromUrl(serverApi.BUDGET_LOGS+budgetHeadId,this.handleLogs)
    // }
    // handleLogs=(resp)=>{
    //     // console.log("looooooooooooooooog",resp);
    //     this.setState({
    //         logData:resp
    //     });
    //     console.log("log",resp)
    // }
    
    render() {
        const { classes } = this.props;
        const inputFocus = {
            autoComplete: 'off'
        }
        const activeStatusOption = [
            {value: 'YES', label: 'YES', name: 'YES'},
            {value: 'NO', label: 'NO', name: 'NO'}
        ];

        //console.log("testdata----",this.state.logData);
        // const sampleData = this.state.logData.map((item,key)=>{
        //     return(
        //         <div>
        //             <p style={{borderBottom:"1px solid #d9d9d9",marginBottom:"5px"}}><span>{item.budgetHeadId}</span><span style={{fontSize:"13px",color:"#3C4858",marginRight:"5px"}}>{item.actionDate}</span><span>{item.remarks}</span></p><br/>
        //         </div>
        //     )
        // });

        return (
            <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
                {this.state.isCreated ?
                    <ItemGrid xs={12}>
                        
                        <RegularCard
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                                    
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Budget Head Code<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="budgetHeadCode"
                                                    value={this.state.budgetHeadCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    iType="number"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Budget Head Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="budgetHeadName"
                                                    value={this.state.budgetHeadName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    itemViewType={this.state.itemViewType}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Parent Head
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.parentHeadId}
                                                    id={"parentHeadId"}
                                                    onSelect={this.onSelect}
                                                    stateKey="parentHeadId"
                                                    staticData={this.state.budgetHeadNameList}
                                                    isRefresh={this.state.loadBudgetHeadName}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBudgetHeadName:false})
                                                    }}
                                                    value={this.state.parentHeadId}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView ?
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Is Active
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id="isActive"
                                                    defValue={this.state.isActive}
                                                    //options={parentoptions}
                                                    onSelect={this.onSelect}
                                                    stateKey="isActive"
                                                    staticData={activeStatusOption}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                        {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                        }
                                        {this.state.updateView ?
                                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                        onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null
                                        }
                                        {this.state.updateView ?
                                            // <ItemGrid >
                                            //     <Button color={"info"} onClick={this.handleLogsClick} style={{marginBottom:"10px"}}>
                                            //         Click here to see the Logs
                                            //     </Button>
                                            //     <br/>
                                            //     {sampleData}
                                            // </ItemGrid>
                                            <LogsComponents id={this.state.budgetHeadId} recType={"BUDGET_HEADS"} />:null
                                        }
                                </GridContainer>
                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon />
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                            style={{
                                                height: 2,
                                                backgroundColor: "#00acc1",
                                                marginTop: "5px"
                                            }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                            url={serverApi.BUDGET_HEAD_TABLE}
                                            // searchData={this.getData()}
                                            //staticData={this.state.budgetDataTableList}
                                            isRefresh={this.state.isRefresh}
                                            isLoading={this.state.isRefresh}
                                            onActionClick={this.handleViewClick}
                                            itemViewType={this.state.itemViewType}
                                            actionType={VIEW}
                                            showLog={true}
                                            //onLogClick={this.handleLogsClick}
                                            // showDelete={true}
                                            //  onDeleteClick={this.handleDeleteClick}
                                            />

                                        </ItemGrid>
                                        {/* <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                                            <LineItemTable
                                                data={this.state.invoiceList}
                                                loading={this.state.loading}
                                                disableActions={this.state.indentStatus === '3' || this.state.indentStatus === '4' || this.state.indentStatus === '5' || this.state.indentStatus === '6'}
                                                columns={[
                                                    {
                                                        Header: "Head Code",
                                                        accessor: "code",
                                                        minWidth: 70,
                                                        style: { color: "#000", textAlign: "left" }

                                                    },
                                                    {
                                                        Header: "Head Name",
                                                        accessor: "name",
                                                        minWidth: 100,
                                                        style: { color: "#000", textAlign: "left" }

                                                    },
                                                    {
                                                        Header: "Parent Head",
                                                        accessor: "parentBudgetId",
                                                        minWidth: 150,
                                                        style: { color: "#000", textAlign: "left" }
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: { color: "#000", textAlign: "center" },
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                            />
                                        </ItemGrid> */}
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>}

            </GridContainer>
        )
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(BudgetHeads)
