import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
//import {NavLink} from "react-router-dom";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import Button from "material-ui/Button";
import Hidden from "material-ui/Hidden";
import Drawer from "material-ui/Drawer";
import List from "material-ui/List";
import ListItem from "material-ui/List/ListItem";
/*import ListItemIcon from "material-ui/List/ListItemIcon";
import ListItemText from "material-ui/List/ListItemText";*/

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

import pagesRoutes from "routes/pages.jsx";

import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";

class PagesHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleDrawerToggle = () => {
        this.setState({open: !this.state.open});
    };

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    render() {
        const {classes, color} = this.props;
        const appBarClasses = cx({
            [" " + classes[color]]: color
        });
        var list = (
            <List className={classes.list}>
                {pagesRoutes.map((prop, key) => {
                    if (prop.redirect) {
                        return null;
                    }
/*
                    const navLink =
                        classes.navLink +
                        cx({
                            [" " + classes.navLinkActive]: this.activeRoute(prop.path)
                        });
*/
                    return (
                        <ListItem key={key} className={classes.listItem}>
                            {/*<NavLink to={prop.path} className={navLink}>*/}
                                {/*<ListItemIcon className={classes.listItemIcon}>*/}
                                    {/*<prop.icon/>*/}
                                {/*</ListItemIcon>*/}
                                {/*<ListItemText*/}
                                    {/*primary={prop.short}*/}
                                    {/*disableTypography={true}*/}
                                    {/*className={classes.listItemText}*/}
                                {/*/>*/}
                            {/*</NavLink>*/}
                        </ListItem>
                    );
                })}
            </List>
        );
        return (
            <AppBar position="static" className={classes.appBar + appBarClasses}>
                <Toolbar className={classes.container}>
                    <div className={classes.flex}>
                        <Button href="#" className={classes.title}>

                        </Button>
                    </div>
                    <Hidden smDown implementation="css">
                        {list}
                    </Hidden>
{/*                    <Hidden mdUp>
                        <IconButton
                            className={classes.sidebarButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu/>
                        </IconButton>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <Hidden mdUp>
                            <Drawer
                                variant="temporary"
                                anchor={"right"}
                                open={this.state.open}
                                classes={{
                                    paper: classes.drawerPaper
                                }}
                                onClose={this.handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true // Better open performance on mobile.
                                }}
                            >
                                {list}
                            </Drawer>
                        </Hidden>
                    </Hidden>*/}
                </Toolbar>
            </AppBar>
        );
    }
}

PagesHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
