import React from "react";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi, serverVars } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class AppDataSpinningDoffEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment()
        .day(-2)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      itemViewType: "doffId",
      date: moment().format("DD-MM-YYYY"),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),

      trollyNo: "",
      spell: "",
      frameNo: "",
      doffNo: "",
      tareWeightKG: "",
      grossWeightKG: "",
      netWeightKG: 0,
      netWeightTotalKG: 0,
      quantityCode: "",
      statesList: [],
      indentAlert: false,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    getDataFromUrl(
      serverApi.GET_FRAME_NUMBERS +
        this.props.state.companyInfo.id +
        "/SPINNING",
      this.handleFrameNos
    );

    // previously used quality list in machine and yarn type
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      qualityType: 1,
    };

    var data2 = {
      companyId: this.props.state.companyInfo.id,
      qualityType: 2,
    };

    requestList(serverApi.WEAVING_QUALITY_MASTER, data2, this.handleData);
    requestList(serverApi.WEAVING_QUALITY_MASTER, data2, this.handleQuality);
  }

  handleData = (resp) => {
    var list = [];
    console.log("geting response from here only", resp.data);
    if (resp.data) {
      resp.data.forEach((prop) => {
        list.push({
          label: prop.qualityName,
          value: prop.qualityId,
          name: prop.qualityName,
        });
      });
      this.setState({ statesList: list });
      console.log(
        "helllllllloooooooooo.................",
        this.state.statesList
      );
    }
  };

  handleQuality = (resp) => {
    var list = resp.data.map((prop) => {
      var obj = {
        label: prop.qualityName + "_" + prop.qualityCode,
        value: prop.qualityCode,
        name: prop.qualityName,
      };
      return obj;
    });
    this.setState({ qualityList: list });
  };

  handleFrameNos = (resp) => {
    this.setState({ frameNoList: resp, loadFrames: true });
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          "/getJuteIssueById/" +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
      });
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    // window.location.reload();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectedChalanType: "",
    });
  }

  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date")
      this.setState({
        [id]: "",
      });
    const { spell, spreader, date } = this.state;
    // console.log("stateKey",stateKey);

    if (id !== "date") {
      var data = {
        doffDate: id === "date" ? dateObj.format("DD-MM-YYYY") : date,
        spell: this.state.spell,
        frameNo: this.state.frameNo,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      this.setState({
        doffNo: "",
      });
      requestList(serverApi.GET_DOFFNO_SPINNING, data, this.handleDoffNo);
      requestList(
        serverApi.GET_NETWEIGHT_DOFF_SPINNING,
        data,
        this.handleNetWeight
      );
    }

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/
    const { spell, date } = this.state;
    if (stateKey === "spell" || stateKey === "frameNo") {
      var data = {
        doffDate: date,
        spell: stateKey === "spell" ? selectedValue : this.state.spell,
        frameNo: stateKey === "frameNo" ? selectedValue : this.state.frameNo,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      this.setState({
        doffNo: "",
      });
      requestList(serverApi.GET_DOFFNO_SPINNING, data, this.handleDoffNo);
      requestList(
        serverApi.GET_NETWEIGHT_DOFF_SPINNING,
        data,
        this.handleNetWeight
      );
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleDoffNo = (resp) => {
    if (resp.status) {
      this.setState({
        doffNo: resp.data,
      });
    }
  };
  handleNetWeight = (resp) => {
    if (resp.status) {
      this.setState({
        netWeightTotalKG: resp.data,
      });
    }
  };
  getNetWeight() {
    console.log("grossWeightKG ", this.state.grossWeightKG);
    if (this.state.grossWeightKG > 0 && this.state.tareWeightKG > 0)
      return (
        parseFloat(this.state.grossWeightKG) -
        parseFloat(this.state.tareWeightKG)
      );
    else return 0;
  }

  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      indentAlert: false,
      showMapPopup: false,
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      showActualShotPopup: false,
      /*  date: moment().format('DD-MM-YYYY'),
            outTime: moment().format('hh:mm:ss A'),
            trollyNo:'',
            spell:'',
            frameNo:'',
            doffNo:'',
            tareWeightKG:'',
            grossWeightKG:'',
            netWeightKG:'',
            netWeightTotalKG:'',*/
    });
  }

  hideAlertMessage() {
    this.setState({
      isRefresh: true,
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      // date: moment().format('DD-MM-YYYY'),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      trollyNo: 0,
      //spell:"0",
      frameNo: "",
      doffNo: "",
      tareWeightKG: 0,
      grossWeightKG: 0,
      netWeightKG: 0,
      netWeightTotalKG: 0,
      reloadSelect: true,
      loadFrames: true,
      indentAlert: false,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DELETE_SPINNING_DOFF_ENTRY + id + "/" + getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  timeChangedFormat = (myDate, time) => {
    console.log("time", myDate + time);

    myDate = myDate.split("-");
    var timeParts = time.split(":");
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        doffDate: this.timeChangedFormat(this.state.date, this.state.time2),
        spell: this.state.spell,
        frameNo: this.state.frameNo,
        grossWT: this.state.grossWeightKG,
        tareWT: this.state.tareWeightKG,
        netWT: this.getNetWeight(),
        trollyNo: this.state.trollyNo,
        ebNo: "",
        qCode: "",
        entryMode: "M",
        userName: getCookie(cons.USER_NAME),
        // "userIp": "192.168.1.51",
        totNetWt: this.state.netWeightTotalKG,
        companyId: this.props.state.companyInfo.id,
        entryDate: this.timeChangedFormat(
          moment().format("DD-MM-YYYY"),
          moment().format("hh:mm:ss A")
        ),
        cipher: getCookie(cons.CIPHER),
      };
      // if (
      //   this.state.date !== "" &&
      //   this.state.spell !== "" &&
      //   this.state.trollyNo !== "" &&
      //   this.state.grossWeightKG !== ""
      // ) {
      //   if (this.getNetWeight() > 0) {
      //     this.handleLoading();
      //     requestList(serverApi.ADD_SPINNING_DOFF, data, this.handleAddIndent);
      //   } else {
          // this.basicAlert(
          //   "Gross Weight Should Be Greater Than Tare Weight..!!"
          // );
      //   }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      const {date,trollyNo,spell,frameNo,doffNo,tareWeightKG,grossWeightKG,netWeightTotalKG}=this.state
     
   
      const validateFields = [
        { field: date, msg: "Date", type: 2 },
        { field: trollyNo, msg: "Trolly", type: 2 },
        { field: spell, msg: "Spell", type: 2 },
        { field: frameNo, msg: "Frame No", type: 2 },
        { field: doffNo, msg: "Doff No.", type: 1 },
        { field: tareWeightKG, msg: "Tare Weight", type: 1 },
        { field: grossWeightKG, msg: "Gross Weight", type: 1 },
      ];


      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } 
      else if(this.getNetWeight()<0){
        this.basicAlert("Gross Weight Should Be Greater Than Tare Weight..!!");

      }
      else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(serverApi.ADD_SPINNING_DOFF, data, this.handleAddIndent);
      }

  }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.showMapPopup) {
        this.setState({ showMapPopup: false });
      }

      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };
  getTrollyDetails = (selectedValue) => {
    var val = selectedValue.split("^");
    this.setState({
      tareWeightKG: val[1],
    });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  //   handleMachineYarn = (resp) => {
  //     console.log("resp", resp);
  //     var yarnList = [];
  //     this.state.frameNoList.forEach((prop, i) => {
  //       if (i > 0) {
  //         var obj = { yarnId: "", spinningMcId: prop.value, mcName: prop.label };
  //         resp.data.forEach((o) => {
  //           if (prop.value === o.spinningMcId) {
  //             console.log("yarnIdqqqqqq", o.yarnId);
  //             obj.yarnId = o.yarnId;
  //           }
  //         });
  //         yarnList.push(obj);
  //       }
  //     });
  //     /*
  //         if(resp.status){
  //             yarnList=resp.data;
  //         }
  // */
  //     this.setState({ yarnList: yarnList, showMapPopup: true });
  //     this.handleLoading();
  //   };

  handleMachineQuality = (resp) => {
    var wevingList = [];
    // console.log("the valueeeeeeeeeeeeeeeeeeeeee............");
    this.state.frameNoList.forEach((prop, i) => {
      console.log("frameNoList", prop);
      if (i > 0) {
        var obj = { qCode: "", mcId: prop.value, mcName: prop.label };
        resp.data.forEach((o) => {
          if (prop.value === o.mcId) {
            obj.qCode = o.qCode;
          }
        });
        wevingList.push(obj);
      }
    });

    /*
            if(resp.status&&resp.data!==undefined){
                wevingList=resp.data
            }
    */
    this.setState({ wevingList: wevingList, showMapPopup: true });
    this.handleLoading();
  };

  onUpdateMachineMap() {
    const { date, spell, wevingList } = this.state;
    var list = [];
    console.log("printing....................", this.state.wevingList);
    wevingList.forEach((prop) => {
      console.log("we are getting value..........", prop);
      if (prop.yarnId !== "") {
        var obj = {
          yarnId: prop.qCode,
          spinningMcId: prop.mcId,
          mcName: prop.mcName,
        };
        list.push(obj);
      }
    });
    var data = {
      date: date,
      spell: spell,
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
      yarnList: list,
    };

    this.handleLoading();
    requestList(
      serverApi.UPDATE_SPINNING_YARN_MAPPING_LIST,
      data,
      this.handleAddIndent
    );
  }

  getMachineMap() {
    const {
      prodDate,
      spell,
      showSpellPopup,
      showActualShotPopup,
      showEbSpell,
      productEff,
    } = this.state;

    //this.setState({showSpellPopup:false});
    if (showActualShotPopup) {
      if (spell !== "") {
        var data = {
          date: this.state.date,
          spell: spell,
          companyId: this.props.state.companyInfo.id,
        };
        this.handleLoading();
        requestList(
          serverApi.GET_DAILY_WEAVING_QUALITY_MAPPING,
          data,
          this.handleMachineQuality
        );
      }
    }
    // if (showActualShotPopup) {
    //   if (spell !== "") {
    //     var data = {
    //       date: prodDate,
    //       quantity: quantity,
    //       spell: spell,
    //       companyId: this.props.state.companyInfo.id,
    //     };
    //     this.handleLoading();
    //     requestList(
    //       serverApi.GET_DAILY_WEAVING_QUALITY_MAPPING,
    //       data,
    //       this.handleMachineQuality
    //     );
    //   } else {
    //     this.basicAlert("Please select spell..!!");
    //   }
    // }
  }

  addWeaving = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        quantityCode,
        actualshots,
        speed,
        finishedlength,
        spiningActualCount,
      } = this.state;
      var data = {
        qualityId: quantityCode,
        actualShot: "",
        spiningActualCount: spiningActualCount,
        //speed: speed,
        isActive: 1,
        companyId: this.props.state.companyInfo.id,
        finishedLength: finishedlength,
        qualityType: 2,
      };

      if (speed !== "" && quantityCode !== "") {
        this.handleLoading();
        requestList(
          serverApi.CUTS_JUGAR_WEAVING_ADD,
          data,
          this.handleAddIndent
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}
                {this.state.showActualShotPopup ? (
                  <Popup
                    closePopup={() => this.hideAlert()}
                    content={
                      <form>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Spinning Code
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.quantityCode}
                                  stateKey="quantityCode"
                                  staticData={this.state.statesList}
                                  value={this.state.quantityCode}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Actual count
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.spiningActualCount}
                                  onChange={this.onChangeValues}
                                  id={"spiningActualCount"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Speed
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.speed}
                                  onChange={this.onChangeValues}
                                  id={"speed"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}

                          {/* <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Finished Length
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  defValue={this.state.finishedlength}
                                  onChange={this.onChangeValues}
                                  id={"finishedlength"}
                                  className={classes.textField}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid> */}
                          <ItemGrid xs={12} md={2}>
                            <Button color="primary" onClick={this.addWeaving}>
                              Create
                            </Button>
                          </ItemGrid>

                          <ItemGrid xs={12} md={6}>
                            <Button color="danger" onClick={this.hideAlert}>
                              Cancel
                            </Button>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            style={{
                              height: 2,
                              backgroundColor: "#00acc1",
                              marginTop: "5px",
                            }}
                          />
                          <ItemGrid
                            xs={12}
                            style={{
                              height: "300px",
                              overflow: "scroll",
                              display: "block",
                            }}
                          >
                            <TableComponent
                              url={
                                serverApi.CUTS_JUGAR_DATA_LIST +
                                this.props.state.companyInfo.id +
                                "/2"
                              }
                              //
                              isRefresh={this.state.isRefresh}
                              handleSearch={this.handleSeacrh.bind(this)}
                              isLoading={this.state.isRefresh}
                              onActionClick={this.handleDeleteClick}
                              itemViewType={this.state.itemViewType}
                              actionType={DELETE}
                              fileName={"CutsJugar"}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </form>
                    }
                  />
                ) : null}
                {this.state.showMapPopup ? (
                  <Popup
                    closePopup={() => this.hideAlert()}
                    onClickSave={() => this.onUpdateMachineMap()}
                    isShowSave={true}
                    isShowCancel={true}
                    save_btn_txt={"Update"}
                    cancel_button_text={"Cancel"}
                    content={
                      <form>
                        <GridContainer>
                          {/*
                                                           <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Machine Id
                                                               </InputLabel>
                                                           </ItemGrid>
*/}
                          <ItemGrid
                            xs={12}
                            sm={4}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Machine
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <InputLabel className={classes.label}>
                              Quality
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            style={{ maxHeight: 250, overflow: "auto" }}
                          >
                            {this.state.wevingList.map((prop, i) => {
                              return (
                                <GridContainer>
                                  {/*
                                                                          <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                                              <InputLabel className={classes.label}>
                                                                                  {prop.mcId}
                                                                              </InputLabel>
                                                                          </ItemGrid>
*/}
                                  <ItemGrid
                                    xs={12}
                                    sm={4}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <InputLabel className={classes.label}>
                                      {prop.mcName}
                                    </InputLabel>
                                  </ItemGrid>

                                  <ItemGrid
                                    xs={12}
                                    md={6}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <SelectboxTemp
                                      id={"qualityCode"}
                                      onSelect={(val, key) => {
                                        var list = this.state.wevingList;
                                        list[i].qCode = val;
                                        this.setState({ wevingList: list });
                                      }}
                                      defValue={prop.qCode}
                                      //  getName={this.getYarnName}
                                      // isRefresh={this.state.loadQuality}
                                      stateKey="qualityCode"
                                      staticData={this.state.qualityList}

                                      // url={serverApi.SELECT_QUALITIES+this.props.state.companyInfo.id}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              );
                            })}
                          </ItemGrid>{" "}
                        </GridContainer>
                      </form>
                    }
                  />
                ) : null}

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "date");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "date",
                            value: this.state.date,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Time
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          // defaultValue={this.state.time1}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.timeChanged1(timeObj, "time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "outTime",
                            value: this.state.time,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Trolly No.
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        id={"trollyNo"}
                        selectDisabled={this.state.updateView}
                        defValue={this.state.trollyNo}
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        onSelect={this.onSelect}
                        getName={this.getTrollyDetails}
                        stateKey="trollyNo"
                        url={
                          serverApi.GET_TROLLY_LIST +
                          "2/" +
                          this.props.state.companyInfo.id +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.trollyNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        spell
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.spell}
                        id={"spell"}
                        getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="spell"
                        url={
                          serverApi.GET_ALL_SPELL_LIST +
                          this.props.state.companyInfo.id +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        value={this.state.spell}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Frame No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.frameNo}
                        id={"frameNo"}
                        // getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="frameNo"
                        isRefresh={this.state.loadFrames}
                        setIsRefresh={() => {
                          this.setState({ loadFrames: false });
                        }}
                        staticData={this.state.frameNoList}
                        // url={serverApi.GET_FRAME_NUMBERS+this.props.state.companyInfo.id+"/SPINNING"}
                        value={this.state.frameNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Doff No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        disabled={true}
                        id="doffNo"
                        value={this.state.doffNo}
                        onChange={this.onChangeValues}
                        //className={classes.textField}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Tare Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="tareWeightKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.tareWeightKG}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Gross Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="grossWeightKG"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.grossWeightKG}
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Net Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        //  id="netWeightKG"
                        disabled={true}
                        className={classes.textField}
                        //  onChange={this.onChangeValues}
                        value={this.getNetWeight()}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Total Net Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="netWeightTotalKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.netWeightTotalKG}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                  <span
                    style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                    onClick={() => {
                      this.setState({ showActualShotPopup: true });
                    }}
                  >
                    Click Here To Map Actual Count
                  </span>
                </ItemGrid>

                <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                  <span
                    style={{ color: "red", cursor: "pointer", fontWeight: 400 }}
                    onClick={() => {
                      if (this.state.date !== "" && this.state.spell !== "") {
                        var data = {
                          date: this.state.date,
                          spell: this.state.spell,
                          companyId: this.props.state.companyInfo.id,
                        };
                        this.handleLoading();
                        requestList(
                          serverApi.GET_DAILY_WEAVING_QUALITY_MAPPING,
                          data,
                          this.handleMachineQuality
                        );
                        // var data = {
                        //   date: this.state.date,
                        //   spell: this.state.spell,
                        //   companyId: this.props.state.companyInfo.id,
                        // };
                        // this.handleLoading();
                        // requestList(
                        //   serverApi.GET_SPINNING_YARN_MAPPING_LIST,
                        //   data,
                        //   this.handleMachineYarn
                        // );
                      } else {
                        this.basicAlert("Please select date and spell..!!");
                      }
                    }}
                  >
                    Click Here To Map Machines & Yarn
                  </span>
                </ItemGrid>

                <ItemGrid xs={12}>
                  <GridContainer>
                    <ItemGrid xs={5} style={{ marginTop: "10px" }}>
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          if (
                            this.state.date !== "" &&
                            this.state.spell !== ""
                          ) {
                            var data = {
                              doffDate: this.state.date,
                              spell: this.state.spell,
                              companyId: this.props.state.companyInfo.id,
                            };
                            this.handleLoading();
                            requestList(
                              serverApi.UPDATE_YARN_EB_MAPPING_PROD,
                              data,
                              this.handleAddIndent
                            );
                          } else {
                            this.basicAlert("Please select date and spell..!!");
                          }
                        }}
                      >
                        Click Here To Map EB No & Yarn
                      </span>
                    </ItemGrid>
                    {!this.state.updateView ? (
                      <ItemGrid xs={6} style={{ marginTop: "5px" }}>
                        <GridContainer>
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={this.onCreateButtonClick}
                            >
                              Create
                            </Button>
                          </ItemGrid>

                          <ItemGrid>
                            <Button color="danger" onClick={this.handleCreate1}>
                              Cancel
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />
                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />
                <ItemGrid xs={12}>
                  {
                    <TableComponent
                      url={serverApi.SPINNIG_DOFF_TABLE}
                      searchData={this.getData()}
                      isRefresh={this.state.isRefresh}
                      handleSearch={this.handleSeacrh.bind(this)}
                      isLoading={this.state.isRefresh}
                      onActionClick={this.handleDeleteClick}
                      itemViewType={this.state.itemViewType}
                      actionType={DELETE}
                      fileName={"SpinningDoffEntry"}

                      // showDelete={true}
                      // onDeleteClick={this.handleDeleteClick}
                    />
                  }
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AppDataSpinningDoffEntry)
);
