import React, { Component } from "react";
import { getTenantId } from "../../../helper/Consts";

class DataPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.open("http://data." + getTenantId(window.location.hostname));
  };

  render() {
    return <>{/* <p>Data Portal</p> */}</>;
  }
}

export default DataPortal;
