import React from "react";
import ReactTable from "react-table";
import {getDataFromUrl, requestList} from "../../server/server";
import Actions, {DELETE} from "../../ui-actions/UiActions";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import RegularButton from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import SelectboxTemp from "./SelectboxTemp";
import {BloodGroups} from "../TableData";
import ItemGrid from "../../components/Grid/ItemGrid1";
import cons, {serverApi} from "../../helper/Consts";
import {getCookie} from "../../helper/Cookies";
import TextField from "../../components/CustomInput/CustomTextField";
import IconButton from "../../components/CustomButtons/IconButton";
import ExpandMoreIcon from "@material-ui/icons/FileDownload";
import exportFromJSON from "export-from-json";

class ReportsTableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsTable: null,
            dataTable: null,
            pageSize:10,
            pageIndex:1,
            totalRecords:0,
            dataTableAll:null
        };

        this.reactTable = React.createRef();

    }
    onClickExcel=(lastIndex)=>{
        if(this.props.url!==undefined){
            var  data= this.props.searchData;
            data.startIndex=1;
            data.lastIndex=lastIndex;
            console.log("data export",data);

            this.props.handleLoading();
            requestList(this.props.url,data, this.handleTotalResp);
        }
    };

    getData = () => {
        // var data={
        //     "purchaseType":"J",
        //     "frrmDt":"12-OCT-18",
        //     "toDt":"10-NOV-18",
        //     "supplierId":"J74",
        //     "mukam":"3",
        //     "statusId":"3"
        // }
        const {staticData}=this.props;

        if(staticData!==null && staticData!==undefined){
            this.handleDept(staticData)
        }else {
            if(this.props.url!==undefined){
                var  data= this.props.searchData;
                requestList(this.props.url,data, this.handleDept);
                if (this.props.isRefresh)
                    this.props.handleSearch();

            }

        }

    };
    handleDept = (resp) => {

        if (resp&&resp.status===undefined) {
            if(this.props.staticData!==null&&this.props.staticData!==undefined&&this.props.isRefresh){
                this.setActions(resp);
            }else {
                if(this.props.staticData===null||this.props.staticData===undefined)
                    this.setActions(resp);
            }
            this.setState({
                dataTable: resp,
               // dataTableAll:resp
            });
            if(this.props.showPagination){
                this.setState({totalRecords:resp.totalRecords});
             //   this.onClickExcel(resp.totalRecords);
               // this.props.handleExcelData(1,resp.totalRecords)
            }

        }else if(resp.status===true){
            if(this.props.staticData!==null&&this.props.staticData!==undefined&&this.props.isRefresh){
                this.setActions(resp.data);
            }else {
                if(this.props.staticData===null||this.props.staticData===undefined)
                    this.setActions(resp.data);
            }
            this.setState({
                dataTable: resp.data,
                // dataTableAll:resp
            });
            if(this.props.showPagination){
                this.setState({totalRecords:resp.totalRec});
               // this.onClickExcel(resp.totalRec);
                // this.props.handleExcelData(1,resp.totalRecords)
            }

        }
        if (this.props.isRefresh)
            this.props.handleSearch();
    };
    handleTotalResp=(resp)=>{
        const {fileName, ...rest} = this.props;
        var exportType = 'xls';
        if(resp.status===undefined){
            var data=resp.data.map(prop=>{
                var obj={};
                resp.column.forEach(o=>{
                    obj[o.Header]=prop[o.accessor]
                })
                return obj;
            });
            exportFromJSON({ data, fileName, exportType })
        }
        else if(resp.status===true){
            var data=resp.data.data.map(prop=>{
                var obj={};
                resp.data.column.forEach(o=>{
                    obj[o.Header]=prop[o.accessor]
                })
                return obj;
            });
            exportFromJSON({ data, fileName, exportType })

        }
            this.props.handleLoading();
    };
    setActions = (resp) => {
        const {actionType,staticData, onActionClick,onExcelExport,itemViewType,onDeleteClick, ...rest} = this.props;
        if(staticData!==null&&staticData!==undefined)
         var   tableData=staticData;
        else {
            var   tableData=resp;

        }

        if (actionType > 0) {
            if(tableData.column[(tableData.column.length-1)].Header!=="Actions")
                tableData.column.push({
                    Header: "Actions",
                    accessor: "actions",
                    //   width:100,
                    sortable: false,
                    style: {color: "#000", textAlign: "center"},
                    filterable: false
             });

        tableData.data.map((prop, key) => {
            if(prop.actions===undefined) {
                prop.actions =
                    (
                        <div>
                            <Actions
                                actionType={actionType}
                                btnKey={itemViewType === 'issueObj' ? JSON.stringify(prop) : prop[itemViewType]}
                                {...rest}
                                actionClick={onActionClick}
                                deleteClick={onDeleteClick}
                                isDelete={this.props.showDelete}
                                isExcel={this.props.isExcel}
                                excelExport={onExcelExport}
                            />


                        </div>


                    );
                return null;
            }
             });
             this.setState({
                 dataTable: tableData
             });
         } else {
            this.setState({dataTable: tableData});
             console.log(tableData);
        }
    };
    componentDidMount() {
        this.getData();
    }
     onRowClick = (state, rowInfo, column, instance) => {

         return {
            onClick: e => {
                if(this.props.onRowClick!==undefined){
                    this.props.onRowClick(rowInfo.original.hdrId);
                    console.log("rowInfo",rowInfo)

                }

              //  this.setState({IssueId: rowInfo.original.hdrId})
            }
     }
    };
    onChangeValues = (event) => {
        var value = event.target.value;
        var pageIndex=parseInt(value);
        var pageSize=this.state.pageSize;
        pageIndex=pageIndex;
        if(pageIndex>0&&pageIndex!==this.state.pageIndex&&pageIndex<=this.getTotalPages()){
            var start=(pageIndex-1)*pageSize+1;
            var end=pageIndex*pageSize;
            this.props.handlePage(start,pageSize);
            console.log("start33",start,end);

        }
        if(pageIndex<=this.getTotalPages()&&pageIndex>0)
        this.setState({
            [event.target.id]: value
        });
    };

    onSelect = (selectedValue, stateKey) => {
        var pageIndex=this.state.pageIndex;
        var pageSize=selectedValue;
        pageIndex=pageIndex;
        if(pageIndex>0&&selectedValue!==this.state.pageSize){
            var start=(pageIndex-1)*pageSize+1;
            var end=pageIndex*pageSize;
            this.props.handlePage(start,pageSize)
            console.log("start444",start,end);
            this.setState({
                pageIndex:pageIndex,
            })

        }
        this.setState({
            [stateKey]: selectedValue
        });
    };

    getTotalPages=()=>{
        var total=parseFloat(this.state.totalRecords/this.state.pageSize).toFixed(2);
        var totalpoint=total.split(".");
        if(totalpoint[1]>0){
            total=parseInt(totalpoint[0])+1
        }else {
            total=parseInt(totalpoint[0])
        }
        return total;

    }
    render() {
        const {isRefresh,isLoading,showPrint,printDoc,actionType,onRowClick,showPagination} = this.props;
       const label= {
            cursor: "pointer",
                paddingLeft: "0",
                color: "#00ABDC",
                fontSize: "15px",
                //  lineHeight: "1.0",
                fontWeight: "400",
                display: "inline-flex",
           marginTop:"5px"
        };
        if (isRefresh) {
            this.getData();
        }
        function filterCaseInsensitive(filter, row) {
            const id = filter.pivotId || filter.id;
            if(row[id] !== undefined && typeof(row[id]) === "string")
                return String(row[id].toLowerCase()).includes(filter.value.toLowerCase());
            else if(row[id] !== undefined && typeof(row[id]) !== "string")
                return  String(row[id]).includes(filter.value);
            return true

            /*
                        return (
                            row[id] !== undefined && typeof(row[id]) === "string"?
                                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                            :
                                true
                        );
            */
        }

        // var columns=[];
      //  console.log("columns",JSON.stringify(columns));
        var options=[{label:"5",value:5},{label:"10",value:10},{label:"20",value:20},{label:"25",value:25},{label:"50",value:50},
            {label:"80",value:80},{label:"100",value:100},{label:"200",value:200},{label:"500",value:500}];

        return (
            <div>

                {(this.state.dataTable !== null) ?
                    <div>

                    <ReactTable
                    loading={isRefresh}
                   // minRows={10}
                    data={this.state.dataTable.data}
                    filterable={false}
                    sortable={false}
                    pageSize={this.state.pageSize}
                    columns={this.state.dataTable.column}
                    defaultPageSize={10}
                    showPageJump={true}
                    showPageSizeOptions={true}
                    getTrProps={this.onRowClick}
/*
                    onPageChange={(pageIndex)=>{
                        console.log("page index",pageIndex);
                        var start=pageIndex*10+1;
                        var end=(pageIndex+1)*10;
                        this.props.handlePage(start,end)
                    }}
*/
                    onPageSizeChange={(pageSize,pageIndex)=>{
                        console.log("page index",pageSize+" , ",pageIndex);
                        this.setState({pageSize:pageSize})
                    }}

                  //  showPaginationTop={this.state.dataTable.showPaginationTop}
                    showPaginationBottom={this.state.dataTable.showPaginationBottom}
                    showPagination={!this.props.showPagination}
                    className="-striped -highlight"
                    style={{color: "#00ABDC",overflowX:'hidden'}}
                    noDataText={this.props.noDataText!==undefined?this.props.noDataText:"No Data Found"}
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }

                    ref={this.reactTable}
                    getTheadThProps={() => {
                        return {
                            style: {
                              //  borderRight: "1px solid #ddd",
                                textAlign:"center",
                                whiteSpace:"pre-wrap",
                              //  wordWrap:"break-word",
                                lineHeight:1.3,
                                fontSize:'13px'
                            }
                        }}}
                    getTdProps={()=>{
                        return {
                            style: {
                                borderRight: "1px solid #ddd",
                                borderTop: "1px solid #ddd",
                                whiteSpace:"pre-wrap",
                                wordWrap:"break-word",
                                lineHeight:1.5,
                                paddingTop:actionType>0?0:10,
                                paddingBottom:actionType>0?0:10,
                                minHeight:30,
                                fontSize:'12px',
                              //  backgroundColor:'green'

                            }
                        }
                    }}


                        /*
                                            defaultFilterMethod={(filter, row) =>
                                                String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
                        */

                    >{/*{(state, filtredData, instance) => {
                        this.reactTable = state.pageRows.map(data => {
                            console.log("filtredData",JSON.stringify(state));
                            return data
                        });
                        return (
                            <div>
                                {filtredData()}
                                <ExportToExcel posts={this.reactTable}
                                               dataTable={this.state.dataTable}
                                               fileName={"Jute indent"}
                                               sheetName={"Order1"}/>
                            </div>
                        )
                    }
                    }*/}</ReactTable>
                        {this.props.showPagination?<GridContainer >
                            <ItemGrid >
                                <RegularButton color={"primary"} onClick={()=>{
                                    var pageIndex=this.state.pageIndex;
                                    var pageSize=this.state.pageSize;
                                    pageIndex=pageIndex-1;
                                    if(pageIndex>0){
                                        var start=(pageIndex-1)*pageSize+1;
                                        var end=pageIndex*pageSize;
                                        console.log("start55",start,end);

                                        this.props.handlePage(start,pageSize)
                                        this.setState({
                                            pageIndex:pageIndex,
                                        })

                                    }

                                }}>Previous</RegularButton>
                            </ItemGrid>

                            <span style={label}>Rows</span>
                            <ItemGrid xs={2}>

                                <SelectboxTemp
                                    defValue={this.state.pageSize}
                                    onSelect={this.onSelect}
                                    stateKey="pageSize"
                                    staticData={options}
                                />
                            </ItemGrid>
                            <span style={label}>Page  </span>
                            <ItemGrid xs={1}>
                                <TextField
                                    id="pageIndex"
                                   // className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.pageIndex}
                                    iType={"number"}
                                    fullWidth={true}

                                />
                            </ItemGrid>
                            <span style={label}>Of {this.getTotalPages()} </span>

                            <ItemGrid>
                                <RegularButton color={"primary"} onClick={()=>{
                                    var pageIndex=this.state.pageIndex;
                                    var pageSize=this.state.pageSize;
                                    pageIndex=pageIndex+1;
                                    if(pageIndex>0&&pageIndex<=this.getTotalPages()){
                                        var start=(pageIndex-1)*pageSize+1;
                                        var end=pageIndex*pageSize;
                                        this.props.handlePage(start,pageSize);
                                        console.log("start22",start,end);
                                        this.setState({
                                            pageIndex:pageIndex,
                                        })

                                    }

                                }}>Next</RegularButton>
                            </ItemGrid>

                        </GridContainer>:null}
                        {this.props.dataTableAll!==null&&this.props.dataTableAll!==undefined?<ExportToExcel posts={this.props.dataTableAll.data}
                                                                       dataTable={this.props.dataTableAll}
                                                                       fileName={this.props.fileName}
                                                                       sheetName={"Order1"}/>:null}
                        {showPagination&&!this.props.staticData&&this.props.url?    <IconButton right={true} style={{marginTop:"-40px"}}
                            color="success" onClick={()=>{
                                this.onClickExcel(this.state.totalRecords)
                        }}>
                            <ExpandMoreIcon/>
                        </IconButton>:null}

                        {this.state.dataTable!==null&&!showPagination?  <ExportToExcel posts={this.state.dataTable.data}
                                       dataTable={this.state.dataTable}
                                       fileName={this.props.fileName}
                                       sheetName={"Order1"}/>:null}



                        {(printDoc!==undefined)? <SamplePDF printDocument={printDoc}/>:null}
                    </div>


: null}

            </div>
        );
    }

}

export default ReportsTableComponent;