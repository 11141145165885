// ##############################
// // // SweetAlert view styles
// #############################

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const sweetAlertStyle = {
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    fontSize: "18px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex"
  },
  line:{
    height: 2, backgroundColor: "#00acc1", marginTop: "5px"
  },
  ...buttonStyle
};

export default sweetAlertStyle;
