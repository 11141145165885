import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, getConfigValue, configNames} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import VIEW_ICON from "@material-ui/icons/Dvr";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import SelectboxMulti from "../CustumComponents/SelectboxMultiple";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import { connect } from 'react-redux';

class BatchPlanForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            fromDate:moment().subtract(7,'d').format('DD-MM-YYYY'),
            todate:moment().format('DD-MM-YYYY'),
            status:'',
            isRefresh:false,
            isLoading:false,
            invoiceList:[],
            tableList:[],
            yarnTypes:[],
            plansList:[
                { value: 0, label: 'Select...' ,name:'Select...'},
                { value: 1, label: 'NEW' ,name:'NEW'}
            ],
            planName:"",qualities:[],percentage:""
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
    }
    componentDidMount() {
        this.onSearchButtonClick();
        this.getYarnTypes();
        this.getPlans();

    }
    onSearchButtonClick=()=>{
       var data= {
            "companyId":this.props.state.companyInfo.id,
            "startDate":this.state.fromDate,
            "endDate":this.state.todate,
            "userId":getCookie(cons.USERID),
            "cipher":getCookie(cons.CIPHER)
        };
        requestList(serverApi.GET_BATCH_PLAN_LIST,data,this.handleSearchResult)
    };
    handleSearchResult=(resp)=>{
        var data=resp;
        var plansList=[
            { value: 0, label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'NEW' ,name:'NEW'}
        ];
       data=data.map((prop,key)=>{
            var obj={
                "id":key,
                "planDate":prop.planDate,
                "planCode":prop.planCode,
                "planName":prop.planName,
                "yarnType":prop.yarnType,
                yarnId:prop.yarnId,
                actions: (
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.tableList;
                                data.find((o,i) => {
                                    if(o.id === key){
                                       // this.basicItemAlert("Do You Like To Update This Plan?");

                                        this.setState({
                                            id:o.id,
                                            planAlert:true,
                                            updatePlan: true,
                                            planDate:o.planDate,
                                            plan1:o.planCode,
                                            planName:o.planName,
                                            yarnType1:o.yarnId,
                                         //   yarnId:o.yarnId
                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="warningNoBackground"
                            customClass="remove">
                            <VIEW_ICON />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                var data = this.state.tableList;
                                data.find((o,i) => {
                                    if(o.id ===key){
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        this.basicItemAlert("Do You Like To Delete This Plan?");
                                        this.setState({deletePlan: true,
                                            planDate:o.planDate,
                                            plan1:"",
                                            yarnType1:o.yarnId,

                                            planId:o.id,});

                                        //  data.splice(i,1);
                                        return true;
                                    }
                                });
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>{" "}
                    </div>

                )
            };
          /*  var arr={label:prop.planName,value:prop.planCode,name:prop.planName};
            plansList.push(arr);*/
            return obj;

        });
        this.setState({
            tableList:data,
            isRefresh:false,
           // plansList:plansList
        })
    };

    handleSeacrh = () => {
        this.setState({
            isRefresh: true,
        });

        this.onSearchButtonClick();


    };
    getPlans=()=>{
        getDataFromUrl(serverApi.SELECT_BATCH_PLAN + this.props.state.companyInfo.id,this.handlePlansList)
    };
    handlePlansList=(resp)=>{
        var plansList=[
            { value: 0, label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'NEW' ,name:'NEW'},
                ...resp
        ];
        this.setState({
            plansList:plansList
        })

    };
    getYarnTypes=()=>{
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "userId": getCookie(cons.USERID)
        };
        requestList(serverApi.YARN_MASTER_TYPE_LIST, data, this.handleYarntype)
    };
    handleYarntype = (resp) => {
        console.log("yarn  ", resp);
        if(resp.data!==null){
            var yarnTypes=resp.data.map(prop=>{
                return {label:prop.yarnType,value:prop.yarnTypeId,name:prop.yarnType}
            });
            this.setState({
                yarnTypes: yarnTypes
            })
        }


    };

    handleViewClick =(id)=>{
        // var data = this.state.dataTable;
        var juteId="";
        console.log("jute  "+"  "+!getMenuPermission(0));
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + id + "/" + getCookie(cons.USERID) + "/getIndentDetails/"+getCookie(cons.CIPHER), this.handleViewClickResp)
        }
    };
    handleViewClickResp = (resp) => {
        var header = resp.indentHeader;
        var list = resp.indentItems;
        var indentlist = list.map((prop,key)=>{
            return(
                {
                    id:key,
                    juteType:parseInt(prop.itemId),
                    juteName:prop.itemIdDesc,
                    quality:prop.qualityCode,
                    qualityName:prop.qualityCodeDesc,
                    quantity:prop.indentQuantity,
                    unit: prop.unitId,
                    deptId:prop.deptId,
                    grpId:prop.itemGroupId,
                    stockValue: prop.stock,
                    indentstatus:prop.indentStatus,
                    indentItemId:prop.indentLineItemId,
                    quantityType:prop.noOfBales,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    if(this.state.approveButton)
                                        data.find((o,i) => {
                                            if(o.id === key){
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                // console.log("no of bales",o.quantityType);
                                                this.setState({
                                                    id:o.id,
                                                    updateItemAlert:true,
                                                    updateItem: true,
                                                    indentItemId:o.indentItemId,
                                                    selectedJuteType:o.juteType,
                                                    selectedJuteName:o.juteName,
                                                    selectedJuteQuality:o.quality,
                                                    selectedQualityName:o.qualityName,
                                                    quantity:o.quantityType,
                                                    selectedUnit: o.unit,
                                                    selectedDeptId:o.deptId,
                                                    selectedGrpId:o.grpId,
                                                    stock: o.stockValue,
                                                    // alert:true,

                                                });
                                                return true;
                                            }
                                        });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>

                            { /* use this button to remove the data row */ }
                            <IconButton
                                onClick={() => {

                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {

                                        if(o.id === key) {
                                            if (this.state.approveButton) {

                                                this.setState({deleteItem: true,
                                                    id:o.id,});

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                //  data.splice(i,1);
                                            }
                                            return true;
                                        }
                                    });

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>{" "}
                        </div>

                    )
                }
            )
        });
        //console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                canChangeMukam:true,
                canChangeUnit:true,
                indentStatus:header.status,
                approveButton:header.approveButton,
                indentNo:header.id,
                statusDesc:header.statusDesc,
                unitConvertion:this.getDefaultUnitConvertion(header.unitConversion),
                // unitConvertionId:"0",
                indentQuantity:header.indentWeight,
                //  sumOFQuantities:header.indentWeight,
               // year:header.finnacialYear,
                createdBy:header.submitter,
                createdOn:header.autoDateTime,
                date1:header.indentDateDesc,
                selectedMukam: header.mukamNo,
                selectedVehicleType:header.vehicleTypeId,
                vehicleQuantity:header.vehicleQuantity,
                quantity:"",
                stock:"",
                selectedJuteQuality:"",
                selectedQualityName:"",
                invoiceList:indentlist,
                finalApprover:resp.finalApprover

            });

        }


    };
    getDefaultUnitConvertion=(name)=>{
        var val="",label="",data="";
        if(name !== null){
            if(name === 'LOOSE'){
                val=1;
                label="LOOSE";
            }else {
                val=2;
                label='BALE';
            }
            data={
                value:val,label:label
            };
        }
        return data;
    }
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    }

    handleCreate=()=>{
        //alert();
        if(!this.state.isCreated){
            this.hideAlertMessage();
        }
        if(this.state.isCreated){
            this.setState({
                canChangeMukam:true,
                canChangeUnit:true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data={
            "indentType":"J",
            "fromDate":this.state.fromDate
            ,"toDate":this.state.todate
            ,"mukam":this.state.mukamId,
            "status":this.state.status,
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER),
            "financialYear":getCookie(cons.ACADEMIC_YEAR)

        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id] :''
            });

    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
    getConvertedQty=()=>{
        if(this.state.unitConvertion.value === 2){
            console.log("indentQuantity  "+this.state.indentQuantity);
            return Math.round(parseFloat((this.state.indentQuantity*100)/150));
        }else {
            return "";
        }
    };
    getIndentQtyTon=()=>{
        var val = this.state.indentQuantity;
        if(val!== ""){
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return  parseFloat(parseFloat(val/10).toFixed(2));
        }else {
            return "";
        }
    };
    getIndentQty=()=>{
        var val = this.state.vehicleQuantity;
        console.log(val+"vehicleQuantity "+this.state.selectedIndentQuantity);
        if(val!== "" && this.state.indentQuantity !==""){
            return parseInt(val)* parseInt(this.state.selectedIndentQuantity)
        }else {
            return "";
        }
    };

    getStock=()=>{
        console.log("sttt",this.state.stock);
        return this.state.stock;
    };

    onChangeValues =(event)=> {
        const re = /^[0-9\b]+$/;
        var value= event.target.value;
        if(event.target.id==="indentQuantity"){
            //   console.log("val "+this.state.indentQuantity);
            if(value>0 && value <= 100){
                this.setState({
                    selectedVehicleType:3,
                    vehicleQuantity:1,
                })

            }else if(value>100 && value <= 120){
                this.setState({
                    selectedVehicleType:2,
                    vehicleQuantity:1,
                })

            }else if(value>120 && value <= 160){
                this.setState({
                    selectedVehicleType:1,
                    vehicleQuantity:1,
                })

            }else if(value>160 && value <= 200){
                this.setState({
                    selectedVehicleType:4,
                    vehicleQuantity:1,
                })

            }else if(value>200){
                this.setState({
                    selectedVehicleType:4,
                    vehicleQuantity:Math.round(value/200),
                })
            }else if(value === ''){
                this.setState({
                    selectedVehicleType:"",
                    vehicleQuantity:"",
                })
            }
        }


        if(event.target.id==="vehicleQuantity"){

            if(value !== null && value!=="" ){
                if(this.state.selectedVehicleType !=="")
                    this.setState({
                        indentQuantity: parseInt(value)* parseInt(this.state.selectedIndentQuantity)
                    });
                else {
                    value = "";
                    this.basicAlert("Please Select Vehicle Type..!!")
                }
            }
        }
        if(event.target.id==="stock"){
            //   console.log("val "+this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if(event.target.id === 'quantity' && this.state.updateItem){

        }
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };
    handlePlanResp=(resp)=>{
        console.log("rsp",JSON.stringify(resp));
        var data=resp.data,planCode,planName,yarnId;
        if(data!==null){
         //   var invoiceList=[];
            var invoiceList= data.map((prop,key)=>{
                var qualityName="",qualities=[];
                planCode=prop.hdr.planCode;
                planName=prop.hdr.planName;
                yarnId=prop.hdr.yarnTypeId+"";
               prop.dtl.forEach(o=>{
                    if(qualityName==='')
                        qualityName=o.qualityDesc;
                    else
                    qualityName=qualityName+', '+o.qualityDesc;
                    qualities.push(o.juteQualityId) ;
                });
                return ({
                    id:key,
                    category:prop.hdr.category,
                    percentage:prop.hdr.percentage,
                    qualities:qualities,
                    qualityNames: qualityName,
                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            this.basicItemAlert("Do You Like To Update This Item?");
                                            console.log("qualities",o.qualities);

                                            this.setState({
                                                id:o.id,
                                                updateItemAlert:true,
                                                updateItem: true,
                                                qualities:o.qualities,
                                                category:o.category,
                                                percentage:o.percentage,
                                                qualityNames:o.qualityNames,

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            this.setState({deleteItem: true,
                                                id:o.id,});

                                            //  data.splice(i,1);
                                            return true;
                                        }
                                    });
                                    this.setState({
                                        qualities:[],
                                        category:"",
                                        percentage:"",
                                        qualityNames:"",
                                    });
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>{" "}
                        </div>

                    )

                })
               // invoiceList.push(obj);
            });
            this.setState({
                 planCode:planCode,
                planName:planName,
                yarnId:yarnId,
                loadYarn:true,
                invoiceList:invoiceList
            })
        }

    };

    onSelect = (selectedValue, stateKey) => {

        if(stateKey === 'plan'&&selectedValue!==1&&selectedValue!==0){
            var data={
                "companyId":this.props.state.companyInfo.id,
                "planCode":selectedValue
            };
            requestList(serverApi.BATCH_PLAN_DETAILS_PLAN_CODE,data,this.handlePlanResp)
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    afterRefreshQuality = (val) => {
        this.setState({
            loadQuality: val
        });
    };
    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };

    hideBasicAlert(){
        if(this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem:false,
            updateItemAlert:false
        });

    }
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if(this.state.deletePlan){
            var list=this.state.tableList;
            var id=this.state.planId;
            var obj=list[id];
         //   console.log("detele obj",JSON.stringify(obj));
            var data={
                "batchPlanCode":obj.planCode,
                "yarnTypeId":obj.yarnId,
                "companyId":this.props.state.companyInfo.id
            };
            this.handleLoading();
            requestList(serverApi.BATCH_PLAN_DETAIL_DELETE,data,this.handleAddIndent);

        }
        if(this.state.deleteItem){
            var data = this.state.invoiceList;
            data.splice(this.state.id,1);
            data.map((o,key)=>{
                o.id=key;
                o.actions= (
                    <div className="actions-right">
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key){
                                        this.basicItemAlert("Do You Like To Update This Item?");
                                        console.log("qualities",o.qualities);

                                        this.setState({
                                            id:o.id,
                                            updateItemAlert:true,
                                            updateItem: true,
                                            qualities:o.qualities,
                                            category:o.category,
                                            percentage:o.percentage,
                                            qualityNames:o.qualityNames,

                                        });
                                        return true;
                                    }
                                });
                            }}
                            color="primaryNoBackground"
                            customClass="remove">
                            <Edit />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                var data = this.state.invoiceList;
                                data.find((o,i) => {
                                    if(o.id === key){
                                        // here you should add some custom code so you can delete the data
                                        // from this component and from your server as well
                                        this.basicItemAlert("Do You Like To Delete This Item?");
                                        this.setState({deleteItem: true,
                                            id:o.id,});

                                        //  data.splice(i,1);
                                        return true;
                                    }
                                });
                                this.setState({
                                    qualities:[],
                                    category:"",
                                    percentage:"",
                                    qualityNames:"",
                                });
                            }}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close />
                        </IconButton>{" "}
                    </div>

                )

            });

            //  if(this.state.approveStatus)
            this.setState({invoiceList: data,
                deleteItem:false});
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            updatePlan: false,
            alert: false,
            planAlert:false,
            showMessagealert:false,
            loading:false,
            qualities:[],
            qualityNames:"",
            category:"",
            percentage:"",
        });
    }
    hideAlertMessage(){
        this.setState({
            isCreated:false,
            approveButton:false,
            showMessagealert:false,
            updateView:false,
            alert: false,
            basicAlert:false,
            invoiceList:[],
            qualities:[],
            qualityNames:"",
            category:"",
            percentage:"",
            plan:"",
            planName:"",
            planCode:"",
            yarnType:"",
            planAlert:false,
            yarnId:""
        });

    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert(){
        this.setState({
            basicAlert: null,
            canChangeUnit:true,
            canChangeMukam:true,
            invoiceList:[],
            sumOFQuantities:0

        });

    }


    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"black"}}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onRejectButtonClick=()=>{
        var invoiceList= this.state.invoiceList.map((prop,key) => {
            return ({
                id: prop.indentItemId,
                indentHeaderId:this.state.indentNo,
                itemId:prop.juteType,
                itemGroupId:prop.grpId,
                qualityCode:prop.quality,
                deptId:prop.deptId,
                stock:prop.stockValue,
                indentQuantity:prop.quantity,
                unitId:prop.unit,
                noOfBales:prop.quantityType
            })
        });

        var data={
            "indentHeader": {
                "id":this.state.indentNo,
                "type": "J",
                "mukam": this.state.selectedMukam,
                "vehicleTypeId": this.state.selectedVehicleType,
                "vehicleQuantity": this.state.vehicleQuantiy,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(),
                "finnacialYear": getCookie(cons.ACADEMIC_YEAR),
                "unitConversion":this.state.unitConvertionName,
                "status":"4"

            },
            "indentItems":invoiceList,
            "cipher":getCookie(cons.CIPHER)
        };
        this.handleLoading();
        requestList(serverApi.UPDATE_INDENT,data,this.handleAddIndent)
    };

    onApproveButtonClick=(status)=>{

        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                return ({
                    id: prop.indentItemId,
                    indentHeaderId: this.state.indentNo,
                    itemId: prop.juteType,
                    itemGroupId: prop.grpId,
                    qualityCode: prop.quality,
                    deptId: prop.deptId,
                    stock: prop.stockValue,
                    indentQuantity: prop.quantity,
                    unitId: prop.unit,
                    noOfBales: prop.quantityType,
                })
            });

            var data = {
                "indentHeader": {
                    "id": this.state.indentNo,
                    "type": "J",
                    "mukam": this.state.selectedMukam,
                    "vehicleTypeId": this.state.selectedVehicleType,
                    "vehicleQuantity": this.state.vehicleQuantiy,
                    "submitter": getCookie(cons.USERID),
                    "indentDate": this.dateChanged(),
                    "finnacialYear": getCookie(cons.ACADEMIC_YEAR),
                    "unitConversion": this.state.unitConvertionName,
                    "status":status

                },
                "indentItems": invoiceList,
                "cipher":getCookie(cons.CIPHER)
            };
            if (this.state.selectedMukam !== '0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType !== "" && this.state.invoiceList.length >= 1) {
                this.handleLoading();
                requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent)
            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick=()=>{
        //   var obj=getMenuPermission(0);
        // console.log("obj",obj);
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                var arr=[];
                arr=prop.qualities.map(q=>{
                    var obj={
                        juteQualityId:q
                    };
                    return obj
                });
                return ({

                    "dtl":arr,
                    "hdr":{"planCode":this.state.planCode,
                        "planName":this.state.planName,
                        "yarnTypeId":this.state.yarnId,
                        "percentage":prop.percentage,
                        "category":prop.category
                    }
                })
            });

            var data = {
                "data": invoiceList,
                "cipher":getCookie(cons.CIPHER),
                "userId":getCookie(cons.USERID),
                "companyId":this.props.state.companyInfo.id
            };
            if (this.state.planName !== '' && this.state.yarnId !== "" && this.state.yarnId !== "0" && this.state.invoiceList.length >= 1) {
                this.handleLoading();
                requestList(serverApi.BATCH_PLAN_ADD, data, this.handleAddIndent);
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if(this.state.deletePlan||this.state.planAlert){
            this.onSearchButtonClick();
            this.setState({
                deletePlan:false
            })
        }
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem(){
        var arr = this.state.invoiceList;
        var sumOfQty=0,quantityInItem=0;
        var itemIndex = 0,quantity=0,hasItem=false,indentItemId=0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
        } else {
            itemIndex = this.state.invoiceList.length
        }
        var addItemObj={
            id:itemIndex,
            qualities:this.state.qualities,
            qualityNames:this.state.qualityNames,
            category:this.state.category,
            percentage:this.state.percentage,

           /*  actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id:o.id,
                                        updateItemAlert:true,
                                        updateItem: true,
                                        qualities:o.qualities,
                                        category:o.category,
                                        percentage:o.percentage,
                                        qualityNames:o.qualityNames,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o,i) => {
                                if(o.id === addItemObj.id){
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({deleteItem: true,
                                        id:o.id,});

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                            this.setState({
                                qualities:[],
                                category:"",
                                percentage:"",
                                qualityNames:"",
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close />
                    </IconButton>{" "}
                </div>

            ) */
        };
        if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
        } else
            arr.splice(arr.length, 0, addItemObj);
        this.setState({
            loading: true,
            invoiceList: arr,
        });
        this.hideAlert();


    }
    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };
    getTablePageSize=()=>{
        var length =this.state.tableList.length;
        if( length > 10){
            return length
        }else {
            return 10;
        }
    };

    getQuantityType=()=>{
        var val =this.state.unitConvertion.value;
        if( val ===2){
            return "BALE"
        }else {
            return "%";
        }
    };
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {

        var values=selectedValue;
        console.log("stock ",values);

        this.setState({
           qualityNames:values+''
        });

    };
    handleAddItem () {
        const {classes}=this.props;
        const {planName,qualities,percentage}=this.state;
        console.log("unitConversionName "+ this.state.unitConvertion);
        //  var id="";
        console.log(this.state);
     //   if(planName !=='' && qualities !== "" && percentage !== "")
            this.setState({
                loading:true,
                alert:true
            });
       /* else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }*/
    };

    getVehicleQlty = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^^");
        this.setState({
            selectedIndentQuantity:values[1],
            indentQuantity:values[1]
        });
        console.log("indentQuantity "+this.state.indentQuantity);

    };
    handleSelectedValues = (defValue) => {
        if(this.state.invoiceList.length > 0 && !this.state.canChangeUnit && this.state.unitConversion !== defValue){
            this.unitConvertionAlert("Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!")
        }else
            this.setState(
                {
                    unitConvertion:defValue,
                    unitConvertionId:defValue.value,
                    unitConvertionName:defValue.label,
                    canChangeUnit:false

                });
    };
    onClickAssignBtn=()=>{
        const {yarnType1,plan1,planDate}=this.state;
        var data={
            "batchPlanCode":plan1,
            "yarnTypeId":yarnType1,
            "planDate":planDate,
            "createdBy":getCookie(cons.USERID),
            "companyId":this.props.state.companyInfo.id
        };
        this.handleLoading();
        requestList(serverApi.BATCH_PLAN_DETAIL_ADD,data,this.handleAddIndent)

    };

    render(){
        console.log("meuId ",getCookie(cons.MENU_ID));
        const { classes } = this.props;
        const {plansList,yarnTypes}=this.state;
        const options = [
            { value: 0, label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'NEW' ,name:'NEW'}
        ];
        const inputFocus={
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var valid = function( current ){
            if(isBackDate==="2")
                return current.isAfter( yesterday );
            else
                return true;
        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"Add"}
                                                cancel_button_text={"Cancel"}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12} >
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Category Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8} >
                                                                                <TextField
                                                                                    id="category"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    value={this.state.category}
                                                                                    fullWidth={true}

                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <br/>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Qualities<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                {/* {(selectedJuteType >0) ?*/}
                                                                                <SelectboxMulti
                                                                                    defValue={this.state.qualities}
                                                                                    id={"qualities"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getQualityName}
                                                                                    stateKey="qualities"
                                                                                    isRefresh={this.state.loadQuality}
                                                                                    setIsRefresh={this.afterRefreshQuality}
                                                                                    url={serverApi.SELECT_QUALITIES+this.props.state.companyInfo.id}
                                                                                    value={this.state.qualities}
                                                                                />{/*:<legend style={{color:"red",fontSize:"12px"}}>Select Jute Type To Display Qualities</legend>
                                                                            }*/}




                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} md={12}>
                                                                <br/>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Percentage<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="percentage"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    value={this.state.percentage}
                                                                                    fullWidth={true}
                                                                                    InputProps={{
                                                                                        autoComplete: 'off',
                                                                                        endAdornment: <InputAdornment position="end">
                                                                                            <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>%</span>
                                                                                        </InputAdornment>,
                                                                                    }}

                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>

                                                        </GridContainer>



                                                    </form>

                                                }
                                            >
                                            </Popup>:null
                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Plan <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"plan"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.plan}
                                                    getName={this.getYarnName}
                                                    stateKey="plan"
                                                    staticData={plansList}
                                                    //  url={serverApi.SEARCH_DEPATMENTS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                    value={this.state.plan}
                                                />



                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Plan Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    // disabled={true}
                                                    id="planName"
                                                    value={this.state.planName}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Paln Code<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    // disabled={true}
                                                    id="planCode"
                                                    value={this.state.planCode}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Yarn Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"yarnId"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.yarnId}
                                                    getName={this.getYarnName}
                                                    isRefresh={this.state.loadYarn}
                                                    setIsRefresh={(val)=>{
                                                       this.setState({
                                                           loadYarn:false
                                                       })
                                                    }}
                                                    stateKey="yarnId"
                                                   // staticData={yarnTypes}
                                                    url={serverApi.YARN_MASTER_TYPE_LIST + this.props.state.companyInfo.id}
                                                    value={this.state.yarnId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {console.log(this.state.invoiceList.length+" length")}
                                                <ReactTable
                                                    data={this.state.invoiceList}
                                                    filterable={false}
                                                    resizable={false}
                                                    loading={this.state.loading}
                                                    getTableProps={() => {
                                                        return {
                                                            style: {
                                                                border: "2px solid #00ABDC",
                                                                borderRadius: "4px"
                                                            }
                                                        }}}

                                                    getTheadProps={() => {
                                                        return {
                                                            style: {
                                                                borderBottom: "2px solid #00ABDC",
                                                                //height:45
                                                                //lineHeight:"1.3"
                                                            }
                                                        }}}
                                                    getTheadThProps={() => {
                                                        return {
                                                            style: {
                                                                borderRight: "1px solid #999999",
                                                                textAlign:"center",
                                                                whiteSpace:"pre-wrap",
                                                                lineHeight:1.3,
                                                            }
                                                        }}}
                                                    style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}
                                                    columns={[
                                                        {
                                                            Header: "Category",
                                                            accessor: "category",
                                                            minWidth:40,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Qualities",
                                                            accessor: "qualityNames",
                                                            minWidth:150,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Percentage",
                                                            accessor: "percentage",
                                                            minWidth:40,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            minWidth:50,
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                    pageSize={this.getInvoiceTablePageSize()}
                                                    //  defaultPageSize={5}
                                                    sortable={false}
                                                    getNoDataProps={()=>{
                                                        return{
                                                            style:{
                                                                display:"none"
                                                            }

                                                        };
                                                    }}
                                                    className="-striped -highlight"
                                                />
                                            </ItemGrid>
                                            <ItemGrid xs={12}>
                                                <Button color={"info"} right={true} onClick={this.handleAddItem}>Add</Button>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Save</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>


                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>

                                            {

                                                this.state.planAlert?
                                                    <Popup
                                                        onClickSave={() => this.onClickAssignBtn()}
                                                        closePopup={() => this.hideAlert()}
                                                        title="Post Temperature"
                                                        isShowSave={!this.state.updatePlan}
                                                        isShowCancel={true}
                                                        save_btn_txt={"Assign"}
                                                        cancel_button_text={"Cancel"}
                                                        content={
                                                            <form>
                                                                <GridContainer>
                                                                            <ItemGrid xs={12} sm={6}>
                                                                                <GridContainer>
                                                                                    <ItemGrid xs={12} sm={4}>
                                                                                        <InputLabel className={classes.label}>
                                                                                            Plan Date
                                                                                        </InputLabel>
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={8}>
                                                                                        <FormControl variant="outlined" fullWidth>
                                                                                            <Datetime
                                                                                                closeOnSelect={true}
                                                                                                timeFormat={false}
                                                                                                onChange={(dateObj) => {
                                                                                                    this.timeChanged(dateObj, "planDate")
                                                                                                }}
                                                                                                formControlProps={
                                                                                                    {height:"10px",inlineBlock: "none"}
                                                                                                }

                                                                                                inputProps={{
                                                                                                    id:"planDate",
                                                                                                    value:this.state.planDate,
                                                                                                    autoComplete: 'off'
                                                                                                }}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </ItemGrid>
                                                                                </GridContainer>

                                                                            </ItemGrid>
{/*
                                                                            <ItemGrid xs={12} sm={6}>

                                                                                <GridContainer>
                                                                                    <ItemGrid xs={12} sm={4}>
                                                                                        <InputLabel className={classes.label}>
                                                                                            To date
                                                                                        </InputLabel>
                                                                                    </ItemGrid>
                                                                                    <ItemGrid xs={12} sm={8}>
                                                                                        <FormControl variant="outlined" fullWidth >
                                                                                            <Datetime
                                                                                                closeOnSelect={true}
                                                                                                timeFormat={false}
                                                                                                onChange={(dateObj) => {
                                                                                                    this.timeChanged(dateObj, "todate1")
                                                                                                }}
                                                                                                formControlProps={
                                                                                                    {height:"10px",inlineBlock: "none"}
                                                                                                }
                                                                                                inputProps={{
                                                                                                    id:"toDate",
                                                                                                    value:this.state.todate1,
                                                                                                    autoComplete: 'off'
                                                                                                }}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </ItemGrid>
                                                                                </GridContainer>

                                                                            </ItemGrid>
*/}



                                                                    <ItemGrid xs={6} sm={6} >

                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    YarnType<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8} >
                                                                                <SelectboxTemp
                                                                                    id={"yarnType1"}
                                                                                    onSelect={this.onSelect}
                                                                                    defValue={this.state.yarnType1}
                                                                                    getName={this.getYarnName}
                                                                                    stateKey="yarnType1"
                                                                                  //  staticData={yarnTypes}
                                                                                    url={serverApi.YARN_MASTER_TYPE_LIST + this.props.state.companyInfo.id}
                                                                                    value={this.state.yarnType1}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>

                                                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Plan <span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>

                                                                                <SelectboxTemp
                                                                                    id={"plan1"}
                                                                                    onSelect={this.onSelect}
                                                                                    defValue={this.state.plan1}
                                                                                    getName={this.getYarnName}
                                                                                    stateKey="plan1"
                                                                                  //  staticData={plansList}
                                                                                      url={serverApi.SELECT_BATCH_PLAN + this.props.state.companyInfo.id}
                                                                                    value={this.state.plan1}
                                                                                />



                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>

                                                                </GridContainer>



                                                            </form>

                                                        }
                                                    >
                                                    </Popup>:null
                                            }
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>



                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12} style={{marginTop:10}}>
                                            <ReactTable
                                                data={this.state.tableList}
                                                filterable={false}
                                                resizable={false}
                                                loading={this.state.isRefresh}
                                                getTableProps={() => {
                                                    return {
                                                        style: {
                                                            border: "2px solid #00ABDC",
                                                            borderRadius: "4px"
                                                        }
                                                    }}}

                                                getTheadProps={() => {
                                                    return {
                                                        style: {
                                                            borderBottom: "2px solid #00ABDC",
                                                            //height:45
                                                            //lineHeight:"1.3"
                                                        }
                                                    }}}
                                                getTheadThProps={() => {
                                                    return {
                                                        style: {
                                                            borderRight: "1px solid #999999",
                                                            textAlign:"center",
                                                            whiteSpace:"pre-wrap",
                                                            lineHeight:1.3,
                                                        }
                                                    }}}
                                                style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}
                                                columns={[
                                                    {
                                                        Header: "Date",
                                                        accessor: "planDate",
                                                        minWidth:60,
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Plan Code",
                                                        accessor: "planCode",
                                                        minWidth:100,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Plan Name",
                                                        accessor: "planName",
                                                        minWidth:100,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Yarn Type",
                                                        accessor: "yarnType",
                                                        minWidth:100,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                                showPagination={false}
                                                pageSize={this.getTablePageSize()}
                                                //  defaultPageSize={5}
                                                sortable={false}
                                                getNoDataProps={()=>{
                                                    return{
                                                        style:{
                                                            display:"none"
                                                        }

                                                    };
                                                }}
                                                className="-striped -highlight"
                                            />

                                        </ItemGrid>
                                        <ItemGrid xs={12} style={{marginTop:10}}>
                                                <Button color="success" onClick={this.handleCreate}>Create/Modify Plan</Button>

                                                <Button color="success" right={true} onClick={()=>{
                                                    this.setState({
                                                        planAlert:true
                                                    })
                                                }}>Assign Plan</Button>


                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(BatchPlanForm)
);

