import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import PaginationTableComponent from "../CustumComponents/PaginationTableComponent";

import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  configNames,
  getConfigValue,
  getDecimal,
  serverApi,
  serverConfig,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";

import TextField from "../../components/CustomInput/CustomTextField";

import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import moment from "moment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import InternalNote from "../CustumComponents/InternalNote";
import LogsComponents from "../CustumComponents/LogsComponents";
import { event } from "jquery";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class Bom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowData: null,
      isCreated: false,
      // fromDate:
      //   getCookie(cons.YEAR_FROM_DATE) && getCookie(cons.YEAR_FROM_DATE) != null
      //     ? getCookie(cons.YEAR_FROM_DATE)
      //     : "",
      fromDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      // todate:
      //   getCookie(cons.YEAR_TO_DATE) && getCookie(cons.YEAR_TO_DATE) != null
      //     ? getCookie(cons.YEAR_TO_DATE)
      //     : "",
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      isDisabled: false,
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      selectedIndentType: "",
      selectedBudgetHead: "",
      selectedItemGroup: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      uom: "",
      branchId: "",
      projectId: "",
      phaseId: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      invoiceList: [],
      indentStatus: "",
      itemDescription: "",
      internalNote: "",
      remarks: "",
      isNoteMandatory: false,
      branchStock: 0,
      projectQoh: 0,
      stock: 0,
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      pendingStockDesc: "",
      filesList: [],
      copyBomList: [],
      lastPurchaseRate: 0,
      lastPurchaseDate: "",
      lastPurchaseItemRate: 0,
      showDescription: true,
      typeId: 0,
      InsRate: 0,
      InsAmount: 0,
      title: "",
      isBOQ: false,
      budgetHeadList: [],
      customerList: [],
      itemGroupList: [],
      dataTable: "",
      startIndex: 0,
      pageSize: 10,
      lastIndex: 10,
      indentNum: "",
      submitRemarks:"",
      internalRemarks: [] 
      // internalNoteData:[]
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }
  componentDidMount() {
    this.getData(this.state.startIndex, this.state.lastIndex);

    console.log("menuID...", getCookie(cons.MENU_ID));
    this.setState({ isBOQ: getCookie(cons.MENU_ID) == 514 ? true : false });
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    var bomData = {
      companyId: "118",
      recordType: getCookie(cons.MENU_ID) == 514 ? "BOQ" : "BOM",
      financialYear: getCookie(cons.ACADEMIC_YEAR),
    };
    requestList(serverApi.GET_COPY_OF_BOM, bomData, this.handleCopyOfBom);
    getDataFromUrl(serverApi.GET_EXPENSE_TYPE, this.handleCategory);
    getDataFromUrl(
      serverApi.SELECT_BRANCH + this.props.state.companyInfo.id,
      this.handleBranch
    );
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments
    );
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(
      serverApi.GET_CUSTOMER_BY_COMPANY + this.props.state.companyInfo.id,
      this.handelCustomer
    );
    if (
      getConfigValue(
        configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
      ) === "2"
    )
      this.setState({ disableDept: true });
  }
  handleDepartments = (resp) => {
    if (resp.length > 0) {
      if (resp.length == 2) {
        this.setState({
          selectedBudgetHead: resp[1].value,
          budgetHeadList: resp,
          loadDepartment: true,
          selectedbudgetName: resp[1].name,
        });
      } else {
        this.setState({ budgetHeadList: resp, loadDepartment: true });
      }
    }
  };

  handleCopyOfBom = (resp) => {
    if (resp) {
      this.setState({ copyBomList: resp, loadcopyBomList: true });
    }
  };

  handleBranch = (resp) => {
    if (resp.length > 0) {
      if (resp.length == 2) {
        var typeId = resp[1].name.split("_");
        typeId = typeId[1];
        this.setState({
          branchId: resp[1].value,
          branchList: resp,
        });
      } else {
        this.setState({ branchList: resp });
      }
    }
  };
  handleCategory = (resp) => {
    if (resp.length > 0) {
      if (resp.length == 2) {
        this.setState({
          selectedIndentType: resp[1].value,
          categoryList: resp,
        });
      } else {
        this.setState({ categoryList: resp });
      }
    }
  };

  handelCustomer = (resp) => {
    var data = resp,
      customerList = [];
    if (data.length > 0) {
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.value, name: name };
        customerList.push(obj);
      });
      if (data.length == 2) {
        this.setState({
          customerId: data[1].value,
          customerName: data[1].name,
        });
      }
      this.setState({
        customerList: customerList,
      });
    }
  };
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      if (data.length == 1) {
        this.setState({
          projectNameList: projectNameList,
          selectedProject: data[0].projectId,
          projectName: data[0].name,
        });
      } else {
        this.setState({
          projectNameList: projectNameList,
        });
      }
    }
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) {
      this.getData(this.state.startIndex, this.state.lastIndex);

      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
      this.setState({
        internalRemarks:""
      })
    }
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  getData = (startIndex, lastIndex) => {
    // var data = {
    //   startDate: this.state.fromDate,
    //   endDate: this.state.todate,
    //   status: this.state.status > 0 ? this.state.status : "",
    //   companyId: this.props.state.companyInfo.id,
    //   financialYear: getCookie(cons.ACADEMIC_YEAR),
    //   branchId: this.state.branchIdList !== 0 ? this.state.branchIdList : "",
    //   phase: this.state.phaseIdList !== 0 ? this.state.phaseIdList : "",
    //   project:
    //     this.state.selectedProjectList !== 0
    //       ? this.state.selectedProjectList
    //       : "",
    //   recordType: getCookie(cons.MENU_ID) == 514 ? "BOQ" : "BOM",
    // };
    const data = {
      startDate: this.state.fromDate,
      endDate: this.state.todate,
      status: this.state.status,
      companyId: this.props.state.companyInfo.id,
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      recordType: getCookie(cons.MENU_ID) == 514 ? "BOQ" : "BOM",
      branchId: this.state.branchIdList !== 0 ? this.state.branchIdList : "",
      indentNum: this.state.indentNum,
      lastIndex: this.state.lastIndex ? this.state.lastIndex : lastIndex,
      startIndex: this.state.startIndex ? this.state.startIndex : startIndex,
    };
    // return data;
    requestList(serverApi.FETCH_ALL_INDENT, data, this.handleReports);
  };

  handleReports = (resp) => {
    this.setState({
      dataTable: resp,
      isRefresh: true,
    });

    // this.handleLoading();
  };

  handlePage = (start, lastIndex) => {
    // console.log("Nagesh handlepage", start, pageSize);
    this.getData(start, lastIndex);
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    var id = event.target.id;
    

    if (event.target.id === "indentQuantity") {
      console.log("val " + this.state.indentQuantity);
      this.setState({
        indentQuantityTon: parseFloat(parseFloat(value / 10).toFixed(2)),
      });
    }
    const { itemStock, pendingStock, InsRate, quantity } = this.state;
    if (id === "InsRate") {
      if (value !== "" && quantity !== "") {
        var val = (parseFloat(quantity) * parseFloat(value)).toFixed(2);
        this.setState({ InsAmount: val });
      } else this.setState({ InsAmount: 0 });
    }
    if (id === "quantity") {
      console.log("bbb", InsRate);
      if (value !== "" && InsRate !== "" && InsRate) {
        var val = (parseFloat(InsRate) * parseFloat(value)).toFixed(2);
        this.setState({ InsAmount: val });
      } else this.setState({ InsAmount: 0 });
    }

    if (id === "indentNum") {
      // console.log("indentNum type", event.target.value);
      this.setState({
        indentNum: event.target.value,
      });
    }

    if(id === "submitRemarks"){
      this.setState({
        submitRemarks: event.target.value,
      });


    }

    if (id === "quantity" && value !== "" && itemStock > 0) {
      if (
        parseFloat(value) >
        parseFloat(itemStock) + parseFloat(pendingStock)
      ) {
        this.basicAlert("Item cannot be placed , Stock is already pending");
      } else {
        this.setState({
          [event.target.id]: value,
        });
      }
    } else
      this.setState({
        [event.target.id]: value,
      });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
    });
  };
  handleRate = (resp) => {
    var data = "",
      date = "";
    if (resp.data !== null) {
      data = resp.data.poPurchasRate;
      date = resp.data.poPurchaseDate;
    }
    if (!this.state.updateItem && data !== "") {
      this.setState({ rate: data });
    }
    this.setState({ lastPurchaseRate: data, lastPurchaseDate: date });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedBudgetHead") {
      getDataFromUrl(
        serverApi.GET_ITEM_GROUP_BY_BUDGET_HEAD +
          "company/" +
          this.props.state.companyInfo.id +
          "/budgetHead/" +
          selectedValue,
        this.handleItemGroupData
      );
    }
    if (
      (stateKey === "selectedProject" || stateKey === "selectedProjectList") &&
      selectedValue !== null
    ) {
      getDataFromUrl(
        serverApi.GET_PHASE_BY_PROJECT_ID + selectedValue,
        this.handlePhaseList
      );
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
        indentTypeId: this.state.selectedIndentType,
        tangible: this.state.isBOQ ? "N" : "Y",
      };

      requestList(serverApi.GET_ITEMGROUP, data, this.handleItem);
    }
    if (stateKey === "branchId" || stateKey === "branchIdList") {
      this.setState({ loadProject: true });
      //  getDataFromUrl(serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+selectedValue,this.handleProjects);
    }
    if (stateKey === "selectedItem") {
      getDataFromUrl(
        serverApi.FETCH_PO_QOH_DETAILS +
          "comapny/" +
          this.props.state.companyInfo.id +
          "/item/" +
          selectedValue,
        this.handleQohData
      );
      var data1 = {
        itemId: selectedValue,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(serverApi.PENDING_STOCK, data1, this.handlePendingStock);
      var stockData = {
        itemId: this.state.selectedItemGroup + "" + selectedValue,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(
        serverApi.PENDING_STOCK_AT_PO_RECEIPT,
        stockData,
        this.handlePendingStockAtPoReceipt
      );
      this.setState({
        showDescription: false,
      });
    }

    if (stateKey === "copyBomId" && selectedValue !== "") {
      this.handleCopyClick(selectedValue);
    }
    if (stateKey === "selectedIndentType") {
      getDataFromUrl(
        serverApi.GET_SUB_EXPENSE_TYPE + selectedValue,
        this.handleExpenseSubType
      );
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleExpenseSubType = (resp) => {
    var data = resp;
    if (data.length > 0) {
      if (data.length == 1) {
        this.setState({
          expenseSubTypeList: data,
          subExpenseTypeName: data[0].name,
          selectedSubExpenseType: data[0].value,
          loadSubType: true,
        });
      } else {
        this.setState({
          expenseSubTypeList: data,
          loadSubType: true,
          // projectNameList: projectNameList,
        });
      }
    } else {
      this.setState({
        expenseSubTypeList: data,
        loadSubType: true,
      });
    }

    // this.setState({ expenseSubTypeList: resp, loadSubType: true })
  };

  handleItemGroupData = (resp) => {
    this.setState({
      itemGroupList: resp ? resp : [],
      loadItemGroup: true,
    });
  };
  handlePhaseList = (resp) => {
    this.setState({
      phaseNameList: resp ? resp : [],
      loadPhaseName: true,
    });
  };

  handleQohData = (resp) => {
    if (resp.data !== null) {
      this.setState({
        pendingAtPo: resp.qtyPo,
        projectQoh: resp.projectQoh,
        branchStock: resp.branchQoh,
        stock: resp.comapnyQoh,
        lastPurchaseRate: resp.poLastPurchaseRate,
        lastPurchaseDate: resp.poLastPurchaseDate,
        lastPurchaseItemRate: resp.itemRate,
      });
    }
  };

  // handlePendingQuantity=(resp)=>{
  //     if(resp.data!==null){
  //         this.setState({
  //             pendingAtPo:resp.data
  //         })
  //     }
  // }
  handlePendingStockAtPoReceipt = (resp) => {
    console.log("pending...", resp);
    var stock = "";
    if (resp.data !== null) {
      stock = resp.data.split("_");
      stock = "Pending Stock at PO " + stock[0] + " and Receipt " + stock[1];
    }
    this.setState({ pendingStockDesc: stock });
  };
  handlePendingStock = (resp) => {
    if (resp.data > 0) this.setState({ pendingStock: resp.data });
  };
  // handleStock=(resp)=>{
  //     this.setState({stock:resp.data})
  // };
  // handleBrachStock=(resp)=>{
  //     this.setState({branchStock:resp.data})
  // };

  /*   handleProjects=(resp)=>{
           this.setState({ProjectOptions:resp,loadItem:true});
       }*/

  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  getBudgetName = (selectedValue) => {
    this.setState({
      selectedbudgetName: selectedValue,
    });
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var rate = values[3] !== "null" ? values[3] : 0;

    this.setState({
      itemName: values[0],
      // selectedItemId:values[1],
      uom: values[1],
      // stock:values[2]!=='null'?values[2]:0.00,
      rate: this.state.itemName != values[0] ? rate : this.state.rate,
      itemStock: values[6] !== undefined && values[6] !== null ? values[6] : 0,
      minStock: values[7] !== undefined && values[7] !== null ? values[7] : 0,
    });
  };
  hideItemAlert() {
    if (
      getConfigValue(
        configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
      ) === "1"
    ) {
      this.setState({
        selectedBudgetHead: "",
        selectedItemGroup: "",
      });
    }
    //  console.log("alert");
    this.setState({
      updateItem: false,
      // deptName: "",
      // itemGrpName: "",
      itemName: "",
      uom: "",
      stock: 0,
      branchStock: 0,
      projectQoh: 0,
      quantity: "",
      selectedExpenseType: "",
      selectedSubExpenseType: "",
      selectedItem: "",
      itemDescription: "",
      rate: "",
    });
  }

  hideAlert() {
    //  console.log("alert");
    this.setState({
      alert: false,
      loading: false,
      showMessagealert: false,
      rate: "",
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      pendingStockDesc: "",
      lastPurchaseRate: 0,
      lastPurchaseDate: "",
      pendingAtPo: "",
      indentItemId: "",
      updateItemAlert: false,
      updateItem: false,
      selectedbudgetName: "",
      itemName: "",
      uom: "",
      stock: "",
      quantity: "",
      selectedItem: "",
      branchStock: "",
      projectQoh: "",
      pendingAtPo: "",
      lastPurchaseDate: "",
      lastPurchaseRate: "",
      selectedSubExpenseType: "",
      expenseTypeName: "",
      subExpenseTypeName: "",
      InsAmount: "",
      InsRate: "",
      make: "",
    });
    this.hideItemAlert();
  }
  hideAlertMessage() {
    this.setState({
      isDisabled: false,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      showDescription: true,
      date1: moment().format("DD-MM-YYYY"),
      indentNo: "",
      createdBy: "",
      createdOn: "",
      invoiceList: [],
      deptName: "",
      itemGrpName: "",
      itemName: "",
      uom: "",
      stock: "",
      quantity: "",
      selectedBudgetHead: "",
      selectedItemGroup: "",
      selectedItem: "",
      selectedIndentType: "",
      finalApprover: false,
      itemDescription: "",
      indentStatus: "",
      branchId: "",
      projectId: "",
      phaseId: "",
      internalNote: "",
      remarks: "",
      customerId: "",
      // disableDept: false,
      hdrId: "",
      isNoteMandatory: false,
      pendingStock: 0,
      minStock: 0,
      itemStock: 0,
      filesList: [],
      typeId: 0,
      title: "",
      copyBomId: "",
    });
  }

  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
      this.getData(this.state.startIndex, this.state.lastIndex);
    } else {
      if (resp.data !== null) {
        this.showIndentMessage(resp.message, resp.data, resp.status);
      } else this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_INDENT_BY_ID + id + "/userid/" + getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleCopyClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);

    getDataFromUrl(
      serverApi.GET_INDENT_BY_ID + id + "/userid/" + getCookie(cons.USERID),
      this.handleCopyClickResp
    );
  };
  handleCopyClickResp = (resp) => {
    var list = resp.indentDetails;
    var indentlist = list.map((prop, key) => {
      var insamount = prop.qty * prop.installationRate;
      return {
        id: key,
        selectedbudgetName: prop.budgetHeadName,
        itemGroup: prop.itemGroup,
        item: prop.itemName,
        quantity: prop.qty,
        unit: prop.uomCode,
        stockValue: prop.qohCompany,
        itemDesc: prop.remarks,
        selectedBudgetHead: prop.budgetHeadId + "",
        itemGroupId: prop.itemGroupId,
        selectedItemGroup: prop.itemGroupId + "",
        itemId: prop.itemId + "",
        itemGrp: prop.itemGroupId + "" + prop.itemId,
        indentItemId: prop.id,
        itemStock: prop.itemStock,
        rate: prop.rate,
        branchStock: prop.qohBranch,
        projectQoh: prop.qohProject,
        amount: this.getAmount(prop.qty, prop.rate, prop.installationRate),
        pendingAtPo: prop.qtyPo,
        selectedExpenseType: prop.selectedExpenseType,
        selectedSubExpenseType: prop.selectedSubExpenseType + "",
        InsRate: prop.installationRate,
        InsAmount: insamount > 0 ? insamount : "",
        make: prop.make,
        subExpenseTypeName: prop.subExpenseTypeName,
      };
    });
    this.setState({ invoiceList: indentlist });
  };

  handleViewClickResp = (resp) => {
    console.log(">>>>>resp", resp);
    this.setState({ printResp: resp });
    var header = resp;
    var list = resp.indentDetails,
      budgetHeadId,
      selectedItemGroup;
    var indentlist = list.map((prop, key) => {
      budgetHeadId = prop.budgetHeadId + "";
      selectedItemGroup = prop.itemGroupId + "";
      var insamount = prop.qty * prop.installationRate;
      return {
        id: key,
        selectedbudgetName: prop.budgetHeadName,
        itemGroup: prop.itemGroup,
        item: prop.itemName,
        quantity: prop.qty,
        unit: prop.uomCode,
        stockValue: prop.qohCompany,
        itemDesc: prop.remarks,
        selectedBudgetHead: prop.budgetHeadId + "",
        itemGroupId: prop.itemGroupId,
        selectedItemGroup: prop.itemGroupId + "",
        itemId: prop.itemId + "",
        itemGrp: prop.itemGroupId + "" + prop.itemId,
        indentItemId: prop.id,
        itemStock: prop.itemStock,
        rate: prop.rate,
        branchStock: prop.qohBranch,
        projectQoh: prop.qohProject,
        amount: this.getAmount(prop.qty, prop.rate, prop.installationRate),
        pendingAtPo: prop.qtyPo,
        selectedExpenseType: prop.selectedExpenseType,
        selectedSubExpenseType: prop.selectedSubExpenseType + "",
        InsRate: prop.installationRate ? prop.installationRate : "",
        InsAmount: insamount > 0 ? insamount : "",
        make: prop.make ? prop.make : "",
        subExpenseTypeName: prop.subExpenseTypeName,
      };
    });
    console.log("indentWeight " + header.indentWeight);

    if (header !== null) {
      this.setState({
        isDisabled: true,
        updateView: true,
        isCreated: true,
        approveStatus: header.approveButton,
        indentNo: header.id,
        hdrId: header.id,
        statusDesc: header.indentStatusName,
        indentSquenceNo: header.indentSquenceNo,
        customerId: header.customerId,
        // unitConvertionId:"0",
        //    quantity:header.indentWeight,
        // year:header.finnacialYear,
        date1: header.indentDate,
        createdBy: header.userDesc,
        createdOn: header.autoDateTime,
        date1: header.indentDate,
        selectedIndentType: header.categoryId + "",
        invoiceList: indentlist,
        indentStatus: header.indentStatus,
        finalApprover: header.finalApprover,
        branchId: header.branchId,
        projectId: header.projectId,
        // internalNote: header.internalRemarks,
        internalRemarks: header.internalRemarks,
        loadItemGroup: true,
        remarks: header.remarks,
        phaseId: header.phaseId + "",
        isNoteMandatory: false,
        title: header.title,
        indentStatus: header.indentStatus,
      });
      if (
        getConfigValue(
          configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
        ) === "2"
      )
        this.setState({
          disableDept: true,
          selectedBudgetHead: budgetHeadId,
          selectedItemGroup: selectedItemGroup,
          loadDepartment: true,
        });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "10/" +
          header.id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleInvoiceFiles
      );
      getDataFromUrl(
        serverApi.GET_ACTIONS + "INDENTS/action/INTERNALNOTE/" + header.id,
        this.handleActions
      );
    }
  };

  handleActions = (resp) => {
    console.log("response",resp);
    // if(resp){
    //   this.setState({ internalRemarks: resp.length === 0 ? "" : resp});
    // }
    
  };
  handleInvoiceFiles = (resp) => {
    if (resp.data) {
      this.setState({ filesList: resp.data });
    }
  };

  submitNotes=()=>{

    let data = {
      "sourceModuleId": this.state.hdrId,
      "internalRemarks": this.state.submitRemarks,
      "createdBy": getCookie(cons.USERID),
      "oldId":"",
      "companyId":this.props.state.companyInfo.id,
  }
  requestList(serverApi.SAVE_ACTIONS, data);


    if (this.state.submitRemarks) {
      if (
        this.state.internalRemarks === "" ||
        this.state.internalRemarks === null
      ) {
        let internalNotes = this.state.submitRemarks;
        // this.state.internalNote = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      } else {
        let internalNotes =
          this.state.internalRemarks + "\n" + this.state.submitRemarks;
        // this.state.internalNote = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      }
    }

    // console.log(this.state.internalNoteInputVal);
    // // const internalNoteData=[];
    // this.setState({
    //   internalNoteData:[...this.state.internalNoteData, this.state.internalNoteInputVal]
    // })
    // console.log("getting the data here",this.state.internalNoteData);

  //   let data = {
  //     "sourceModuleId": this.state.hdrId,
  //     "internalRemarks": this.state.internalNoteInputVal,
  //     "createdBy": getCookie(cons.USERID),
  //     // "oldId":note?note[0].id:"",
  //     "companyId":this.props.state.companyInfo.id,
  // }
  // requestList(serverApi.SAVE_ACTIONS, data, this.handleInternalResp);
 

  }

  // internalRemarksData = (resp) =>{
  //   console.log("getting the response of data", resp);
  //   if(resp){
  //     this.setState({
  //       internalRemarks: this.state.internalRemarks + "\n" + resp.internalRemarks
  //     })
  //   }
  //   // if(resp){
  //   //   if (this.state.submitRemarks) {
  //   //     if (
  //   //       this.state.internalRemarks === "" ||
  //   //       this.state.internalRemarks === null
  //   //     ) {
  //   //       let internalNotes = this.state.submitRemarks;
  //   //       // this.state.internalNote = internalNotes;
  //   //       this.setState({
  //   //         internalRemarks: internalNotes,
  //   //         submitRemarks: "",
  //   //       });
  //   //     } else {
  //   //       let internalNotes =
  //   //         this.state.internalRemarks + "\n" + this.state.submitRemarks;
  //   //       // this.state.internalNote = internalNotes;
  //   //       this.setState({
  //   //         internalRemarks: internalNotes,
  //   //         submitRemarks: "",
  //   //       });
  //   //     }
  //   //   }

  //   //   // this.setState({
  //   //   //   internalRemarks: resp.internalRemarks
  //   //   // })
  //   // }
  // }

  //approve
  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var mailAlert = getConfigValue(cons.EMAIL);
      var invoiceList = [];
      this.state.invoiceList.forEach((prop, key) => {
        var obj = {
          id: prop.indentItemId,
          qty: prop.quantity,
          uomCode: prop.unit,
          qohCompany: prop.stockValue,
          qohBranch: prop.branchStock,
          qohProject: prop.projectQoh,
          rate: prop.rate,
          rateSource: prop.lastPurchaseRate ? 0 : 1,
          remarks: prop.itemDesc,
          budgetHeadId: prop.selectedBudgetHead,
          itemId: prop.itemId,
          lastPurchaseDate: prop.lastPurchaseDate,
          lastPurchaseRate: prop.lastPurchaseRate,
          selectedExpenseType: prop.selectedExpenseType,
          selectedSubExpenseType: prop.selectedSubExpenseType,
          qtyPo: prop.pendingAtPo,
          installationRate: prop.InsRate,
          amount: this.getAmount(prop.quantity, prop.rate, prop.InsRate),
          //InsAmount: prop.InsAmount,
          make: prop.make,
        };
        if (prop.quantity > 0 && prop.quantity !== "0") invoiceList.push(obj);
      });

      var data = {
        indentDate: this.state.date1,
        id: this.state.hdrId,
        indentSquenceNo: this.state.indentSquenceNo,
        fy: getCookie(cons.ACADEMIC_YEAR),
        recordType: this.state.isBOQ ? "BOQ" : "BOM",
        totalValue: this.getTotalAmount(),
        internalRemarks: this.state.internalRemarks,
        remarks: this.state.remarks,
        source: 0,
        companyId: this.props.state.companyInfo.id,
        branchId: this.state.branchId,
        indentStatus: status,
        categoryId: parseInt(this.state.selectedIndentType),
        storeId: this.state.branchId,
        projectId: this.state.selectedProject,
        customerId: this.state.customerId,
        indentDetails: invoiceList,
        lastModifiedBy: getCookie(cons.USERID),
        title: this.state.title,
        // "cipher":getCookie(cons.CIPHER),
        // "mailAlert":mailAlert==='2'?"N":"Y"
      };
      // if (
      //   this.state.selectedIndentType !== "0" &&
      //   this.state.invoiceList.length >= 1 &&
      //   this.state.branchId !== "" &&
      //   this.state.branchId !== 0 &&
      //   this.state.title !== ""
      // ) {
      //   this.handleLoading();
      //   requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
      // } else if (this.state.invoiceList.length < 1) {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      const {title,selectedIndentType,customerId,branchId,projectId,selectedBudgetHead,selectedItemGroup}=this.state
     
      if(this.state.disableDept){
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
          { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
          { field: selectedItemGroup, msg: "Item Group", type: 2 },
          { field: invoiceList, msg: "Line Items", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.handleLoading();
          // this.basicAlert("Created Successfully");
          requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
          this.setState({
            internalRemarks:""
          })
        }
      }
      else{
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
          { field: invoiceList, msg: "Line Items", type: 2 },
          // { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
          // { field: selectedItemGroup, msg: "Item Types", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.handleLoading();
          // this.basicAlert("Created Successfully");
          requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
          this.setState({
            internalRemarks:""
          })
        }

      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var mailAlert = getConfigValue(cons.EMAIL);
      var invoiceList = [];
      this.state.invoiceList.forEach((prop, key) => {
        var obj = {
          qty: prop.quantity,
          uomCode: prop.unit,
          qohCompany: prop.stockValue,
          qohBranch: prop.branchStock,
          qohProject: prop.projectQoh,
          rate: prop.rate,
          rateSource: prop.lastPurchaseRate ? 0 : 1,
          remarks: prop.itemDesc,
          budgetHeadId: prop.selectedBudgetHead,
          itemId: prop.itemId,
          lastPurchaseDate: prop.lastPurchaseDate,
          lastPurchaseRate: prop.lastPurchaseRate,
          selectedExpenseType: prop.selectedExpenseType,
          selectedSubExpenseType: prop.selectedSubExpenseType,
          qtyPo: prop.pendingAtPo,
          installationRate: prop.InsRate,
          amount: this.getAmount(prop.quantity, prop.rate, prop.InsRate),
          //InsAmount: prop.InsAmount,
          make: prop.make,
        };
        if (prop.quantity > 0 && prop.quantity !== "0") invoiceList.push(obj);
      });

      var data = {
        indentDate: this.state.date1,
        fy: getCookie(cons.ACADEMIC_YEAR),
        recordType: this.state.isBOQ ? "BOQ" : "BOM",
        totalValue: this.getTotalAmount(),
        internalRemarks: this.state.internalRemarks.length>0 ?this.state.internalRemarks:"",
        remarks: this.state.remarks,
        source: 0,
        companyId: this.props.state.companyInfo.id,
        branchId: this.state.branchId,
        categoryId: this.state.selectedIndentType,
        storeId: this.state.branchId,
        projectId: this.state.selectedProject,
        customerId: this.state.customerId,
        indentDetails: invoiceList,
        title: this.state.title,
        createdBy: getCookie(cons.USERID),
      };

      // if (
      //   this.state.selectedIndentType !== "0" &&
      //   this.state.invoiceList.length >= 1 &&
      //   this.state.branchId !== "" &&
      //   this.state.branchId !== 0 &&
      //   this.state.title !== ""
      // ) {
      //   this.handleLoading();
      //   requestList(serverApi.SAVE_INDENT, data, this.handleAddIndent);
      // } else if (this.state.invoiceList.length < 1) {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }

      const {title,selectedIndentType,customerId,branchId,projectId,selectedBudgetHead,selectedItemGroup}=this.state
     
      if(this.state.disableDept){
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
          { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
          { field: selectedItemGroup, msg: "Item Group", type: 2 },
          { field: invoiceList, msg: "Line Items", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.handleLoading();
          // this.basicAlert("Created Successfully");
          requestList(serverApi.SAVE_INDENT, data, this.handleAddIndent);
        }
      }
      else{
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
          { field: invoiceList, msg: "Line Items", type: 2 },
          // { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
          // { field: selectedItemGroup, msg: "Item Types", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.handleLoading();
          // this.basicAlert("Created Successfully");
          requestList(serverApi.SAVE_INDENT, data, this.handleAddIndent);
        }

      }
      
    }
  };

  onClickAddItem() {
    var arr = this.state.invoiceList;
    var itemIndex = 0,
      quantity = 0,
      hasItem = false,
      sumOfQty = 0,
      indentItemId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      indentItemId = this.state.indentItemId;
      //   arr[itemIndex].quantity = this.state.quantity
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    /*
                if (!this.state.updateItem)
                    sumOfQty = sumOfQty+parseInt(this.state.quantity);
        */
    arr.map((q) => {
      //sumOfQty = sumOfQty+parseInt(q.quantityType);
      if (
        q.itemId === this.state.selectedItem &&
        q.itemGroupId === this.state.selectedItemGroup &&
        !this.state.updateItem
      )
        hasItem = true;
    });

    var addItemObj = {
      id: itemIndex,
      indentItemId: indentItemId,
      selectedbudgetName: this.state.selectedbudgetName,
      itemGroup: this.state.itemGrpName,
      item: this.state.itemName,
      quantity: this.state.quantity,
      unit: this.state.uom,
      itemDesc: this.state.itemDescription,
      stockValue: this.state.stock,
      selectedBudgetHead: this.state.selectedBudgetHead,
      selectedItemGroup: this.state.selectedItemGroup,
      itemGroupId: this.state.selectedItemGroup,
      itemId: this.state.selectedItem,
      itemGrp: this.state.selectedItemGroup + "" + this.state.selectedItem,
      itemStock: this.state.itemStock,
      branchStock: this.state.branchStock,
      projectQoh: this.state.projectQoh,
      rate: this.state.rate,
      amount: this.getAmount(
        this.state.quantity,
        this.state.rate,
        this.state.InsRate
      ),
      pendingAtPo: this.state.pendingAtPo,
      lastPurchaseDate: this.state.lastPurchaseDate,
      lastPurchaseRate: this.state.lastPurchaseRate,
      selectedExpenseType: this.state.selectedExpenseType,
      selectedSubExpenseType: this.state.selectedSubExpenseType,
      expenseTypeName: this.state.expenseTypeName,
      subExpenseTypeName: this.state.subExpenseTypeName,
      InsAmount: this.state.InsAmount,
      InsRate: this.state.InsRate,
      make: this.state.make,
    };

    // if (!hasItem) {
    //   if (
    //     this.state.selectedBudgetHead !== "" &&
    //     this.state.selectedBudgetHead !== 0 &&
    //     this.state.quantity !== "" &&
    //     this.state.selectedItem !== "" &&
    //     this.state.selectedItem !== "0" &&
    //     this.state.selectedItemGroup !== "" &&
    //     this.state.selectedItemGroup !== 0
    //   ) {
    //     if (this.state.updateItem) {
    //       if (this.state.updateView) {
    //         if (
    //           parseFloat(this.state.quantity) !==
    //           parseFloat(arr[itemIndex].quantity)
    //         ) {
    //           this.setState({ isNoteMandatory: true });
    //         }
    //       }
    //       arr[itemIndex] = addItemObj;
    //     } else {
    //       if (this.state.updateView) this.setState({ isNoteMandatory: true });
    //       arr.splice(arr.length, 0, addItemObj);
    //     }
    //     this.setState({
    //       loading: true,
    //       invoiceList: arr,
    //     });
    //     // if (getConfigValue(configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS) === "2")
    //     //     this.setState({ disableDept: true });

    //     this.hideAlert();
    //   } else {
    //     this.basicAlert("Please Enter All Mandatory Fields..!!");
    //   }
    // } else
    //   this.basicAlert(
    //     "Item Already Saved For This Item Group. Please Select Another One..!!"
    //   );
    // console.log(arr.toString());


    const {selectedBudgetHead,selectedItemGroup,selectedItem,quantity,rate}=this.state;
    if(this.state.disableDept){
      const validateFields = [
        // { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
        // { field: selectedItemGroup, msg: "Item Group", type: 1 },
        { field: selectedItem, msg: "Item", type: 2 },
        { field: quantity, msg: "Quantity", type: 1 },
        { field: rate, msg: "Item Rate", type: 1 },
      ];
   
    const validObj = validate(validateFields);
    // if (!hasItem) {
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    } 
    else {
     if (this.state.updateItem) {
      if (this.state.updateView) {
        if (
          parseFloat(this.state.quantity) !==
          parseFloat(arr[itemIndex].quantity)
        ) {
          this.setState({ isNoteMandatory: true });
        }
      }
      arr[itemIndex] = addItemObj;
    } else {
      if (this.state.updateView) this.setState({ isNoteMandatory: true });
      arr.splice(arr.length, 0, addItemObj);
    }
  
    this.setState({
      loading: true,
      invoiceList: arr,
    });
    this.hideAlert();
  }
    }
    else {
      const validateFields = [
        { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
        { field: selectedItemGroup, msg: "Item Group", type: 1 },
        { field: selectedItem, msg: "Item", type: 2 },
        { field: quantity, msg: "Quantity", type: 1 },
        { field: rate, msg: "Item Rate", type: 1 },
      ];
      const validObj = validate(validateFields);
      // if (!hasItem) {
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } 
      else {
       if (this.state.updateItem) {
        if (this.state.updateView) {
          if (
            parseFloat(this.state.quantity) !==
            parseFloat(arr[itemIndex].quantity)
          ) {
            this.setState({ isNoteMandatory: true });
          }
        }
        arr[itemIndex] = addItemObj;
      } else {
        if (this.state.updateView) this.setState({ isNoteMandatory: true });
        arr.splice(arr.length, 0, addItemObj);
      }
    
      this.setState({
        loading: true,
        invoiceList: arr,
      });
      this.hideAlert();
    }
    }

  // }
  // else{
  // this.basicAlert(
  //   "Item Already Saved For This Item Group. Please Select Another One..!!"
  // )}
  }

  getTotalAmount = () => {
    var totalAmount = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      var amount = this.getAmount(prop.quantity, prop.rate, prop.InsRate);
      totalAmount = totalAmount + parseFloat(amount);
    });
    return totalAmount.toFixed(2);
  };
  //invoiceTableSize
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };
  //addItem
  handleAddItem() {
    // if (
    //   selectedIndentType !== "0" &&
    //   selectedIndentType !== "" &&
    //   this.state.branchId !== "" &&
    //   this.state.branchId !== 0
    // )
      // this.setState({
      //   loading: true,
      //   alert: true,
      // });
    // else {
    //   this.basicAlert("Please Select Mandatory Fields..!!");
    // }
    const {title,selectedIndentType,customerId,branchId,projectId,selectedBudgetHead,selectedItemGroup}=this.state
     
      if(this.state.disableDept){
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
          { field: selectedBudgetHead, msg: "Budget Head", type: 2 },
          { field: selectedItemGroup, msg: "Item Group", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.setState({
            loading: true,
            alert: true,
          });
        }
      }
      else{
        const validateFields = [
          { field: title, msg: "Title", type: 1 },
          { field: selectedIndentType, msg: "Category Type", type: 2 },
          { field: customerId, msg: "Client", type: 2 },
          { field: branchId, msg: "Branch", type: 2 },
          { field: projectId, msg: "Project", type: 2 },
        ];
  
        const validObj = validate(validateFields);
        console.log(validObj);
        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message} ..!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message} ..!!`);
          }
        } else {
          this.setState({
            loading: true,
            alert: true,
          });
        }

      }
  }
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);

      if (data.length === 0) this.setState({ disableDept: false });

      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  showIndentMessage = (msg, data, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
              {data.map((prop, i) => {
                return (
                  <ItemGrid style={{ maxHeight: 250, overflowY: "auto" }}>
                    <legend style={{ textAlign: "center" }}>{prop}</legend>
                  </ItemGrid>
                );
              })}
            </form>
          }
        />
      ),
    });
  };
  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideItemAlert();

    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItem: false,
      updateItemAlert: false,
      isReject: false,
      isApprove: false,
    });
  }

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      hdrId,
      isReject,
      isApprove,
      indentStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onUpdateButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onUpdateButtonClick(indentStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        10,
        fileName,
        hdrId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    var data = resp;
    if (resp.status) {
      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
  };
  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  getAmount = (quantity, rate, insRate) => {
    var amt = 0,
      rate1 = 0;

    if (insRate > 0 && insRate !== "0") {
      rate1 = parseFloat(rate1) + parseFloat(insRate);
    }
    if (rate > 0 && rate !== "0") rate1 = parseFloat(rate1) + parseFloat(rate);
    if (quantity > 0 && quantity !== "0") {
      amt = (parseFloat(rate1) * parseFloat(quantity)).toFixed(2);
    }

    return amt;
  };
  showEditableRow = () => {
    var data = [],
      invoiceList = this.state.invoiceList;
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        var amount = (
          parseFloat(prop.rate) * parseFloat(prop.quantity)
        ).toFixed(2);
        prop.id = key;
        var obj = {
          ...prop,
          itemData: (
            <div>
              {" "}
              <SelectboxTemp
                //selectDisabled={this.state.disableDept}
                id={"selectedBudgetHead"}
                onSelect={(val) => {
                  invoiceList[key].selectedBudgetHead = val;
                  // this.setState({invoiceList:invoiceList})
                }}
                defValue={this.state.selectedBudgetHead}
                getName={(name) => {
                  invoiceList[key].selectedbudgetName = name;
                }}
                stateKey="selectedBudgetHead"
                staticData={this.state.budgetHeadList}
                isRefresh={this.state.loadDepartment}
                setIsRefresh={(val) => {
                  this.setState({ loadDepartment: false });
                }}
                //   url={serverApi.GET_BUDGET_HEAD + "company/"+this.props.state.companyInfo.id}
                value={prop.selectedBudgetHead}
              />
            </div>
          ),
          //prop.itemGroupId+""+prop.itemId+"\n"+prop.selectedbudgetName+"\n"+prop.itemGroup,

          quantityUnit: prop.quantity + " " + prop.unit,
          rateIns: prop.rate + "\n" + prop.InsRate,
          //  amountInsAmount:prop.amount+"\n"+prop.InsAmount,
          actions: (
            <GridContainer>
              <ItemGrid xs={6}>{amount + "\n" + prop.InsAmount}</ItemGrid>

              <ItemGrid xs={3}>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // if (this.state.approveStatus) {
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        //  data.splice(i,1);
                        // }
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  right={true}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </ItemGrid>
            </GridContainer>
          ),
        };
        data.push(obj);
      });
    return data;
  };

  getDataTable = () => {
    const { classes } = this.props;
    var data = [];

    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        var expenseData =
          prop.subExpenseTypeName +
          "\nMake: " +
          prop.make +
          "\nRemark:" +
          prop.itemDesc;
        var amount = (
          parseFloat(prop.rate) * parseFloat(prop.quantity)
        ).toFixed(2);
        prop.id = key;
        var obj = {};
        console.log("indentStatusmm", this.state.indentStatus);
        if (
          this.state.selectedRowData &&
          this.state.selectedRowData.index == key &&
          this.state.indentStatus != "3" &&
          this.state.indentStatus != "4" &&
          this.state.indentStatus != "5" &&
          this.state.indentStatus != "6"
        ) {
          var postdata = {
            itemGroupId: prop.itemGroupId,
            saleable: "",
            consumable: "",
            companyId: this.props.state.companyInfo.id,
            indentTypeId: this.state.selectedIndentType,
            tangible: this.state.isBOQ ? "N" : "Y",
          };
          obj = {
            ...prop,
            subitem: (
              <div>
                <SelectboxTemp
                  defValue={prop.itemId}
                  postData={postdata}
                  url={serverApi.GET_ITEMGROUP}
                  //	staticData={this.state.ItemOptions}
                  id={"selectedItem"}
                  onSelect={(val) => {
                    var list = this.state.invoiceList;
                    list[key].itemId = val;
                    this.setState({ invoiceList: list });
                  }}
                  getName={(selectedValue) => {
                    var values = selectedValue;
                    values = values.split("^");
                    var rate = values[3] !== "null" ? values[3] : 0;
                    var list = this.state.invoiceList;
                    list[key].item = values[0];
                    list[key].unit = values[1];
                    list[key].rate = prop.item != values[0] ? rate : prop.rate;
                    // this.setState({
                    //     itemStock: values[6] !== undefined && values[6] !== null ? values[6] : 0,
                    //     minStock: values[7] !== undefined && values[7] !== null ? values[7] : 0,
                    // });
                  }}
                  isRefresh={this.state.loadItem}
                  stateKey="selectedItem"
                  setIsRefresh={this.afterRefreshItem}
                  //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                  value={this.state.selectedItem}
                />
              </div>
            ),
            expenseData: (
              <div>
                <SelectboxTemp
                  id={"selectedSubExpenseType"}
                  defValue={prop.selectedSubExpenseType}
                  getName={(name) => {
                    var list = this.state.invoiceList;
                    list[key].subExpenseTypeName = name;
                    this.setState({ invoiceList: list });
                  }}
                  onSelect={(val) => {
                    var list = this.state.invoiceList;
                    list[key].selectedSubExpenseType = val;
                    this.setState({ invoiceList: list });
                  }}
                  stateKey="selectedSubExpenseType"
                  staticData={this.state.expenseSubTypeList}
                  // url={serverApi.GET_SUB_EXPENSE_TYPE + this.state.selectedExpenseType}
                  value={this.state.selectedSubExpenseType}
                  isRefresh={this.state.loadSubType}
                  setIsRefresh={(val) => {
                    this.setState({ loadSubType: false });
                  }}
                />
                <TextField
                  id="make"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].make = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.make}
                  fullWidth={true}
                  variant="outlined"
                />
                <TextField
                  id="remarks"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].itemDesc = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.itemDesc}
                  fullWidth={true}
                  variant="outlined"
                />
              </div>
            ),
            itemData:
              prop.itemGroupId +
              "" +
              prop.itemId +
              "\n" +
              prop.selectedbudgetName +
              "\n" +
              prop.itemGroup,

            quantityUnit: (
              <div>
                <TextField
                  id="quantity"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].quantity = event.target.value;
                    var ins = "";
                    if (event.target.value !== "" && prop.InsRate)
                      ins = (
                        parseFloat(prop.InsRate) *
                        parseFloat(event.target.value)
                      ).toFixed(2);
                    list[key].InsAmount = ins;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.quantity}
                  fullWidth={true}
                  variant="outlined"
                  InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{
                            color: "#00ABDC",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {prop.unit}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            ),
            rateIns: (
              <div>
                <TextField
                  id="rate"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].rate = event.target.value;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.rate}
                  fullWidth={true}
                  variant="outlined"
                />
                <TextField
                  id="InsRate"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    list[key].InsRate = event.target.value;
                    var val = "";
                    if (event.target.value !== "")
                      val = (
                        parseFloat(prop.quantity) *
                        parseFloat(event.target.value)
                      ).toFixed(2);
                    list[key].InsAmount = val;
                    this.setState({ invoiceList: list });
                  }}
                  value={prop.InsRate}
                  fullWidth={true}
                  variant="outlined"
                />
              </div>
            ),
            //  amountInsAmount:prop.amount+"\n"+prop.InsAmount,
            actions: (
              <GridContainer>
                <ItemGrid xs={9}>
                  {amount +
                    "\n" +
                    (prop.InsAmount ? getDecimal(prop.InsAmount) : "")}
                </ItemGrid>

                <ItemGrid xs={3}>
                  <IconButton
                    onClick={() => {
                      var data = this.state.invoiceList;
                      data.find((o, i) => {
                        if (o.id === key) {
                          // if (this.state.approveStatus) {
                          this.setState({
                            deleteItem: true,
                            id: o.id,
                          });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                          // }
                          return true;
                        }
                      });
                      this.setState({ invoiceList: data });
                    }}
                    right={true}
                    color="dangerNoBackground"
                    customClass="remove"
                  >
                    <Close />
                  </IconButton>{" "}
                </ItemGrid>
              </GridContainer>
            ),
          };
        } else
          obj = {
            ...prop,
            subitem:
              prop.item.length > 220
                ? prop.item.substring(0, 220) + "..."
                : prop.item,
            itemDescription: prop.item,
            itemData:
              prop.itemGroupId +
              "" +
              prop.itemId +
              "\n" +
              prop.selectedbudgetName +
              "\n" +
              prop.itemGroup,

            expenseData:
              expenseData.length > 120
                ? expenseData.substring(0, 120) + "..."
                : expenseData,
            expenseSub: expenseData,
            quantityUnit: prop.quantity + " " + prop.unit,
            rateIns: prop.rate + "\n" + prop.InsRate,
            //   amountInsAmount:prop.amount+"\n"+prop.InsAmount,
            actions: (
              <GridContainer>
                <ItemGrid xs={12}>{amount + "\n" + prop.InsAmount}</ItemGrid>
                {/* <ItemGrid xs={3}>
									<IconButton
										onClick={() => {
											var data = this.state.invoiceList;
											data.find((o, i) => {
												if (o.id === key) {
													this.basicItemAlert(
														"Do You Like To Update This Item?"
													);

													this.setState({
														id: o.id,
														indentItemId: o.indentItemId,
														updateItemAlert: true,
														updateItem: true,
														deptName: o.department
															? o.department
															: this.state.department,
														selectedDepartment: o.deptId
															? o.deptId
															: this.state.selectedDepartment,
														selectedbudgetName: o.selectedbudgetName
															? o.selectedbudgetName
															: this.state.selectedbudgetName,
														itemGrpName: o.itemGroup
															? o.itemGroup
															: this.state.itemGroup,
														itemName: o.item,
														uom: o.unit,
														stock: o.stockValue,
														itemDescription: o.itemDesc,
														quantity: o.quantity,
														selectedBudgetHead: o.selectedBudgetHead
															? o.selectedBudgetHead
															: this.state.selectedBudgetHead,
														selectedItemGroup: o.itemGroupId
															? o.itemGroupId
															: this.state.selectedItemGroup,
														selectedItem: o.itemId,
														itemStock: o.itemStock,
														rate: o.rate,
														branchStock: o.branchStock,
														projectQoh: o.projectQoh,
														pendingAtPo: o.pendingAtPo,
														lastPurchaseDate: o.lastPurchaseDate,
														lastPurchaseRate: o.lastPurchaseRate,
														selectedExpenseType: o.selectedExpenseType,
														selectedSubExpenseType: o.selectedSubExpenseType,
														expenseTypeName: o.expenseTypeName,
														subExpenseTypeName: o.subExpenseTypeName,
														InsAmount: o.InsAmount,
														InsRate: o.InsRate,
														make: o.make,

														// alert:true,
													});
													return true;
												}
											});
										}}
										color="primaryNoBackground"
										customClass="remove"
									>
										<Edit />
									</IconButton>
								</ItemGrid>
								<ItemGrid xs={3}>
									<IconButton
										onClick={() => {
											var data = this.state.invoiceList;
											data.find((o, i) => {
												if (o.id === key) {
													// if (this.state.approveStatus) {
													this.setState({
														deleteItem: true,
														id: o.id,
													});

													this.basicItemAlert(
														"Do You Like To Delete This Item?"
													);
													//  data.splice(i,1);
													// }
													return true;
												}
											});
											this.setState({ invoiceList: data });
										}}
										right={true}
										color="dangerNoBackground"
										customClass="remove"
									>
										<Close />
									</IconButton>{" "}
								</ItemGrid> */}
              </GridContainer>
            ),
          };
        data.push(obj);
      });
    return data;
  };
  setHoveredRow = (rowInfo) => {
    console.log("hovver", rowInfo);
    this.setState({ selectedRowData: rowInfo });
  };

  render() {
    const { classes } = this.props;
    const inputFocus = {
      autoComplete: "off",
    };
    var companyId = this.props.state.companyInfo.id;
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const { selectedIndentType } = this.state;
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <div>
                  <Button color={"info"} onClick={this.handleCreate}>
                    Back To List
                  </Button>
                  {this.state.updateView
                    ? ""
                    : this.state.isBOQ
                    ? " Creation Of BOQ"
                    : "  Creation Of BOM"}
                </div>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            {!this.state.disableDept ? (
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Budget Head
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        //selectDisabled={this.state.disableDept}
                                        id={"selectedBudgetHead"}
                                        onSelect={this.onSelect}
                                        defValue={this.state.selectedBudgetHead}
                                        getName={this.getBudgetName}
                                        stateKey="selectedBudgetHead"
                                        staticData={this.state.budgetHeadList}
                                        isRefresh={this.state.loadDepartment}
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadDepartment: false,
                                          });
                                        }}
                                        //   url={serverApi.GET_BUDGET_HEAD + "company/"+this.props.state.companyInfo.id}
                                        value={this.state.selectedBudgetHead}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        //selectDisabled={this.state.disableDept}
                                        defValue={this.state.selectedItemGroup}
                                        id={"selectedItemGroup"}
                                        onSelect={this.onSelect}
                                        getName={this.getItemGroupName}
                                        isRefresh={this.state.loadItemGroup}
                                        stateKey="selectedItemGroup"
                                        setIsRefresh={this.afterRefreshItemGrp}
                                        staticData={this.state.itemGroupList}
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadItemGroup: false,
                                          });
                                        }}
                                        //  url={serverApi.GET_ITEM_GROUP_BY_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id + "/budgetHead/" + this.state.selectedBudgetHead}
                                        value={this.state.selectedItemGroup}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            ) : null}
                            {/* <ItemGrid xs={12} md={12}> */}
                            <GridContainer>
                              {this.state.expenseSubTypeList.length > 0 ? (
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Expense type
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedSubExpenseType"}
                                        defValue={
                                          this.state.selectedSubExpenseType
                                        }
                                        getName={(name) => {
                                          this.setState({
                                            subExpenseTypeName: name,
                                          });
                                        }}
                                        onSelect={this.onSelect}
                                        stateKey="selectedSubExpenseType"
                                        staticData={
                                          this.state.expenseSubTypeList
                                        }
                                        // url={serverApi.GET_SUB_EXPENSE_TYPE + this.state.selectedExpenseType}
                                        value={
                                          this.state.selectedSubExpenseType
                                        }
                                        isRefresh={this.state.loadSubType}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadSubType: false });
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              ) : null}

                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                      Item
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      defValue={this.state.selectedItem}
                                      staticData={this.state.ItemOptions}
                                      id={"selectedItem"}
                                      onSelect={this.onSelect}
                                      getName={this.getItemDetailsByName}
                                      isRefresh={this.state.loadItem}
                                      stateKey="selectedItem"
                                      setIsRefresh={this.afterRefreshItem}
                                      //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      value={this.state.selectedItem}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            </GridContainer>
                            {/* </ItemGrid> */}
                            {/* <ItemGrid xs={12} md={12}> */}
                            {/* <GridContainer> */}
                            {/* { this.state.showDescription &&
                                                                    <ItemGrid xs={12} sm={6}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={4}>
                                                                            <InputLabel className={classes.label}>
                                                                                Description
                                                                            </InputLabel>
                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={8}>
                                                                            <TextField
                                                                                id="description"
                                                                                className={classes.textField}
                                                                                onChange={this.onChangeValues}
                                                                                value={this.state.description}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                InputProps={inputFocus}
                                                                            />

                                                                        </ItemGrid>

                                                                    </GridContainer>
                                                                  </ItemGrid>
                                                                        
                                                                     } */}

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={
                                      companyId === "1" ||
                                      companyId === "2" ||
                                      companyId === "116"
                                        ? 3
                                        : 2
                                    }
                                    InputProps={{
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {this.state.uom}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </ItemGrid>
                                {this.state.minStock > 0 &&
                                this.state.itemStock > 0 ? (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "100px",
                                      color: "red",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {"Quantity Range (" +
                                      this.state.minStock +
                                      " - " +
                                      this.state.itemStock +
                                      ")"}
                                  </span>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>
                            {/* </GridContainer> */}
                            {/* </ItemGrid> */}

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Item Rate
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="rate"
                                    //  disabled={this.state.selectedItem && this.state.selectedItem != 0 ? true : false}
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.rate}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                                {this.state.selectedItem !== "" ? (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "100px",
                                      color: "red",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {this.state.lastPurchaseRate !== ""
                                      ? "Last purchase at  " +
                                        this.state.lastPurchaseRate +
                                        " on " +
                                        this.state.lastPurchaseDate
                                      : "Last purchase at" +
                                        this.state.lastPurchaseItemRate}
                                  </span>
                                ) : null}
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Remarks
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="itemDescription"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.itemDescription}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {this.state.selectedItem &&
                            this.state.selectedItem != 0 ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      {labelType === "2"
                                        ? "City Stock"
                                        : "Central Stock"}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="stock"
                                      disabled={true}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      value={this.state.stock}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                    />
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {this.state.pendingStockDesc}
                                    </span>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.selectedItem &&
                            this.state.selectedItem != 0 ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      {labelType === "2"
                                        ? "Project Stock"
                                        : " Branch Stock"}
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="branchStock"
                                      disabled={true}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      value={this.state.branchStock}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.selectedItem &&
                            this.state.selectedItem != 0 ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Project QOH
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      id="projectQoh"
                                      disabled={true}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      value={this.state.projectQoh}
                                      fullWidth={true}
                                      variant="outlined"
                                      InputProps={inputFocus}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Installation Rate
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="InsRate"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={
                                      this.state.InsRate
                                        // ? this.state.InsRate
                                        // : 0
                                    }
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                    iType="number"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Installation Amount
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    disabled={true}
                                    id="InsAmount"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={
                                      this.state.InsAmount
                                        // ? this.state.InsAmount
                                        // : 0
                                    }
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Make
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="make"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.make}
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                        {/* <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span> */}
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        disabled={true}
                                        id="amount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.getAmount(
                                          this.state.quantity,
                                          this.state.rate,
                                          this.state.InsRate
                                        )}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            {this.state.selectedItem &&
                            this.state.selectedItem != 0 ? (
                              <ItemGrid
                                xs={6}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Qty pending for PO
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <TextField
                                          disabled={true}
                                          id="pendingAtPo"
                                          className={classes.textField}
                                          onChange={this.onChangeValues}
                                          fullWidth={true}
                                          variant="outlined"
                                          InputProps={inputFocus}
                                          style={{ height: 30 }}
                                          value={this.state.pendingAtPo}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Title
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="title"
                          className={classes.textField}
                          value={this.state.title}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            {this.state.isBOQ ? "BOQ " : "BOM "}No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="indentSquenceNo"
                            className={classes.textField}
                            value={this.state.indentSquenceNo}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.categoryList &&
                  this.state.categoryList.length > 2 ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Category Type
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedIndentType"}
                            selectDisabled={this.state.updateView}
                            defValue={this.state.selectedIndentType}
                            // getName={(name) => {
                            //     var typeId = name.split("_");
                            //     typeId = typeId[1];
                            //     this.setState({ typeId: typeId })
                            // }}
                            onSelect={this.onSelect}
                            stateKey="selectedIndentType"
                            staticData={this.state.categoryList}
                            // url={serverApi.GET_CATEGORY_LIST}
                            value={this.state.selectedIndentType}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Client
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.customerId}
                          id={"customerId"}
                          onSelect={this.onSelect}
                          stateKey="customerId"
                          staticData={this.state.customerList}
                          getName={(name) => {
                            this.setState({ customerName: name });
                          }}
                          value={this.state.customerId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.branchList && this.state.branchList.length > 2 ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            {/* {labelType==="2"?"Project":"Branch"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                            Branch
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            selectDisabled={
                              this.state.isDisabled &&
                              this.state.branchId !== null
                            }
                            defValue={this.state.branchId}
                            onSelect={this.onSelect}
                            //  getName={this.getQualityName}
                            stateKey="branchId"
                            // isRefresh={this.state.loadQuality}
                            // setIsRefresh={this.afterRefreshQuality}
                            staticData={this.state.branchList}
                            // url={serverApi.SELECT_BRANCH + this.props.state.companyInfo.id}
                            value={this.state.branchId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.projectNameList ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            {/* {labelType==="2"?"Phase":"Project"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                            Project
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.projectId}
                            id={"projectId"}
                            onSelect={this.onSelect}
                            stateKey="selectedProject"
                            staticData={this.state.projectNameList}
                            getName={(name) => {
                              this.setState({ projectId: name });
                            }}
                            value={this.state.projectId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.disableDept ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Budget Head
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            //selectDisabled={this.state.disableDept}
                            id={"selectedBudgetHead"}
                            onSelect={this.onSelect}
                            defValue={this.state.selectedBudgetHead}
                            getName={this.getBudgetName}
                            stateKey="selectedBudgetHead"
                            staticData={this.state.budgetHeadList}
                            isRefresh={this.state.loadDepartment}
                            setIsRefresh={(val) => {
                              this.setState({ loadDepartment: false });
                            }}
                            //   url={serverApi.GET_BUDGET_HEAD + "company/"+this.props.state.companyInfo.id}
                            value={this.state.selectedBudgetHead}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.disableDept ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Item Group
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            //selectDisabled={this.state.disableDept}
                            defValue={this.state.selectedItemGroup}
                            id={"selectedItemGroup"}
                            onSelect={this.onSelect}
                            getName={this.getItemGroupName}
                            isRefresh={this.state.loadItemGroup}
                            stateKey="selectedItemGroup"
                            setIsRefresh={this.afterRefreshItemGrp}
                            staticData={this.state.itemGroupList}
                            setIsRefresh={(val) => {
                              this.setState({ loadItemGroup: false });
                            }}
                            //  url={serverApi.GET_ITEM_GROUP_BY_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id + "/budgetHead/" + this.state.selectedBudgetHead}
                            value={this.state.selectedItemGroup}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            {this.state.isBOQ
                              ? "Copy from BOQ"
                              : "Copy from BOM"}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            // selectDisabled={this.state.isDisabled&&this.state.branchId!==null}
                            defValue={this.state.copyBomId}
                            staticData={this.state.copyBomList}
                            onSelect={this.onSelect}
                            stateKey="copyBomId"
                            value={this.state.copyBomId}
                            isRefresh={this.state.loadcopyBomList}
                            setIsRefresh={() => {
                              this.setState({ loadcopyBomList: false });
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          setHoveredRow={this.setHoveredRow}
                          hideOverFlow={true}
                          fixRowHeight={true}
                          //disableActions={this.state.indentStatus==='3'||this.state.indentStatus==='4'||this.state.indentStatus==='5'||this.state.indentStatus==='6'}
                          columns={[
                            {
                              Header: "Item Code\nBudget Head\nItem Group",
                              accessor: "itemData",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "left" },
                            },

                            // {
                            //     Header: "Expense Type",
                            //     accessor: "expenseTypeName",
                            //     minWidth: 200,
                            //     style: { color: "#000", textAlign: "left", textOverflow: 'unset' }
                            // },
                            {
                              Header: "Expense Type/ Make/ Remarks",
                              accessor: "expenseData",
                              minWidth: 150,
                              Cell: (row) => (
                                <div>
                                  <span
                                    title={
                                      row.original
                                        ? row.original.expenseSub
                                        : row.value
                                    }
                                  >
                                    {row.value}
                                  </span>
                                </div>
                              ),
                              style: {
                                color: "#000",
                                textAlign: "left",
                                textOverflow: "unset",
                              },
                            },
                            {
                              Header: "Item",
                              accessor: "subitem",
                              minWidth: 250,
                              Cell: (row) => (
                                <div>
                                  <span
                                    title={
                                      row.original
                                        ? row.original.itemDescription
                                        : row.value
                                    }
                                  >
                                    {row.value}
                                  </span>
                                </div>
                              ),
                              style: {
                                color: "#000",
                                textAlign: "left",
                                textOverflow: "unset",
                              },
                            },
                            {
                              Header: "Quantity",
                              accessor: "quantityUnit",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: this.state.isBOQ
                                ? "Supply Rate/ Inst. Rate"
                                : "Item Rate/ Inst. Rate",
                              accessor: "rateIns",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "right" },
                            },

                            {
                              Header: "Supply Amount/ Inst. Amount",
                              accessor: "actions",
                              minWidth: 150,
                              style: { color: "#000", textAlign: "right" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                        />

                        {this.state.updateView ? (
                          <ItemGrid xs={12}>
                            <GridContainer>
                              <ItemGrid xs={6}>
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontWeight: 400,
                                    color: "#003F66",
                                  }}
                                >
                                  Status:
                                </span>
                                <span
                                  style={{ fontSize: "12px", color: "#003F66" }}
                                >
                                  {this.state.statusDesc}
                                  <br />
                                </span>
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontWeight: 400,
                                    color: "red",
                                  }}
                                >
                                  Note:
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {"Type can't be modified"}
                                </span>
                              </ItemGrid>
                              <ItemGrid xs={6}>
                                {this.state.indentStatus !== "3" &&
                                this.state.indentStatus !== "4" &&
                                this.state.indentStatus !== "5" &&
                                this.state.indentStatus !== "6" &&
                                this.state.approveStatus
                                 ? (
                                  <Button
                                    color={"info"}
                                    right={true}
                                    onClick={this.handleAddItem}
                                  >
                                    Add Item
                                  </Button>
                                ) : null}
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : (
                          <ItemGrid xs={12}>
                            {this.state.indentStatus !== "3" &&
                            this.state.indentStatus !== "4" &&
                            this.state.indentStatus !== "5" &&
                            this.state.indentStatus !== "6" ? (
                              <Button
                                color={"info"}
                                right={true}
                                onClick={this.handleAddItem}
                              >
                                Add Item
                              </Button>
                            ) : null}
                          </ItemGrid>
                        )}

                        {/*<ItemGrid xs={12}*/}
                        {/*style={{height: 1, backgroundColor: "#00acc1", marginTop: "40px"}}/>*/}
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} md={6}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Remarks
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="remarks"
                                  fullWidth
                                  multiline
                                  rowsMax="3"
                                  height={60}
                                  value={this.state.remarks}
                                  onChange={this.onChangeValues}
                                  className={classes.textField}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} md={6} style={{marginLeft:"10px"}}>
                        <GridContainer>
                        <ItemGrid xs={12} sm={3} style={{marginLeft:"7px"}}>
                                <InputLabel className={classes.label}>
                                  Internal Notes 
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8} >
                                <TextField 
                                disabled={this.state.updateView ? true: false}
                                  id="internalRemarks"
                                  fullWidth
                                  multiline
                                  rowsMax="8"
                                  height={120}
                                  value={this.state.internalRemarks}
                                  onChange={this.onChangeValues}
                                  className={classes.textField}
                                />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={3}>
                                <InputLabel className={classes.label}>
                                 
                                </InputLabel>
                              </ItemGrid>
                              {this.state.updateView ?
                               <ItemGrid xs={12} sm={8} style={{marginLeft:"7px"}}>
                               <TextField
                                 id="submitRemarks"
                                 fullWidth
                                 multiline
                                 rowsMax="3"
                                 height={40}
                                 value={this.state.submitRemarks}
                                 onChange={this.onChangeValues}

                                 // onChange={(e)=>{this.setState({
                                 //   internalNoteInputVal:e.target.value
                                 // })}}
                                 className={classes.textField}
                               />
                             
                             </ItemGrid>
                             :null }

                           <ItemGrid xs={12} sm={3} style={{marginLeft:"7px"}}>
                                <InputLabel className={classes.label}>
                                 
                                </InputLabel>
                              </ItemGrid>
                             
                              {(this.state.updateView&&
                                this.state.indentStatus !== "3" &&
                                this.state.indentStatus !== "4" &&
                                this.state.indentStatus !== "5" &&
                                this.state.indentStatus !== "6" &&
                                this.state.approveStatus)?(
                              <Button color={"info"} style={{marginBottom:"10px",marginLeft:"15px"}}
                              onClick={()=>this.submitNotes()}
                              >submit</Button>) :null
                              }
                              
                          {/* <InternalNote
                            setNote={this.onChangeValues}
                            isUpdate={this.state.updateView}
                            hdrId={this.state.hdrId}
                            note={this.state.internalDisc}
                            getLatestNotes={this.getLatestDisc}
                          /> */}
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={3} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            sm={9}
                            style={{ textAlign: "right" }}
                          >
                            <InputLabel className={classes.label}>
                              Total Amount :
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            sm={2}
                            style={{ textAlign: "right" }}
                          >
                            {this.getTotalAmount()}
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            {this.state.indentStatus !== 3 ? (
                              <IconButton
                                right={true}
                                onClick={() => {
                                  this.setState({
                                    deleteInvoice: true,
                                    invoiceId: r.fileUploadId,
                                    fileId: i,
                                  });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Delete This File?"
                                  );
                                }}
                                color="dangerNoBackground"
                              >
                                <Close />
                              </IconButton>
                            ) : null}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView &&
                  this.state.indentStatus !== "4" &&
                  this.state.indentStatus !== 3 ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({
                                isApprove: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve This Indent..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                isReject: true,
                              });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject This Indent..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {(this.state.updateView &&
                        this.state.indentStatus !== 3 &&
                        this.state.indentStatus !== 4 &&
                        this.state.indentStatus !== 5 &&
                        this.state.approveStatus) ||
                      (this.state.updateView &&
                        this.state.indentStatus === 1) ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.finalApprover &&
                      this.state.indentStatus !== 3 ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.onUpdateButtonClick("6");
                            }}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {/* {this.state.finalApprover &&
                      this.state.indentStatus === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("5");
                            }}
                          >
                            Close
                          </Button>
                        </ItemGrid>
                      ) : null} */}
                    </GridContainer>
                    {this.state.updateView ? (
                      <LogsComponents
                        id={this.state.hdrId}
                        recType={"INDENTS"}
                      />
                    ) : null}
                  </ItemGrid>

                  {this.state.updateView && this.state.indentStatus !== 4 ? (
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <SamplePDF
                        invoiceType={this.state.isBOQ ? 21 : 20}
                        printResp={this.state.printResp}
                      />
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon} 

                             iconColor={"green"}*/
              content={
                // removed search field from list table
                <div className="bom-boqTable">
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.status}
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_STORE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {/* {labelType==="2"?"Project":"Branch"} */}
                                Branch
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.branchIdList}
                                onSelect={this.onSelect}
                                stateKey="branchIdList"
                                // isRefresh={this.state.loadQuality}
                                // setIsRefresh={this.afterRefreshQuality}
                                url={
                                  serverApi.SELECT_BRANCH +
                                  this.props.state.companyInfo.id
                                }
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          {this.state.projectNameList ? (
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  {/* {labelType==="2"?"Phase":"Project"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                                  Project
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.projectId}
                                  id={"projectId"}
                                  onSelect={this.onSelect}
                                  stateKey="selectedProjectList"
                                  staticData={this.state.projectNameList}
                                  getName={(name) => {
                                    this.setState({ projectId: name });
                                  }}
                                  value={this.state.projectId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          ) : null}
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {this.state.isBOQ ? "BOQ No" : "BOM No"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="indentNum"
                                value={this.state.indentNum}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            // round color="white"
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12} />
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      Note* For Approved,Rejected,Closed Data Please Select
                      Status and Click On Search
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <PaginationTableComponent
                        // url={serverApi.FETCH_ALL_INDENT}
                        // searchData={this.getData()}
                        staticData={this.state.dataTable}
                        // dataTableAll={this.state.dataTable}
                        showPagination={true}
                        handlePage={this.handlePage}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_indent_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
  printDocument() {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_INDENT_DETAILS_BY_ID +
          this.state.indentNo +
          "/userid/" +
          getCookie(cons.USERID) +
          "/company/" +
          this.props.state.companyInfo.id +
          "/year/" +
          getCookie(cons.ACADEMIC_YEAR),
        this.handlePrintResp
      );

      //  getDataFromUrl(serverApi.JUUTE_MR_PRINT_DETAILS + "/" + 153 + "/" +getCookie(cons.CIPHER), this.handlePrintResp)
    }
  }
  handlePrintResp = (resp) => {
    var printHeaderValues = resp.indentHeader;
    var modules = [];
    var lineItems = resp.indentItems;
    if (lineItems.length > 0) {
      lineItems.map((prop, key) => {
        var obj = {
          srNo: key + 1,
          itemId: prop.itemId,
          itemIdDesc: prop.itemIdDesc,
          unitId: prop.unitId,
          stockquantity: prop.stock,
          avgQty: " ",
          indentQuantity: prop.indentQuantity,
          deptId: prop.deptIdDesc,
          pono: " ",
          rate: prop.rate + " ",
          supName: " ",
          clmn12: " ",
          clmn13: " ",
          clmn14: " ",
          clmn15: " ",
          clmn16: " ",
          orderNo: " ",
          ctrlStock: " ",
          deliveryStatus: "15 days",
        };
        modules.push(obj);
      });
    }
    var printTotalvalues = resp.totalValues;
    // this.setState({
    //     printHeaderValues:header,
    //     printLineItems:printLineItems,
    //     printTotalvalues:totalvalues,
    // })

    // if(this.state.printHeaderValues!==undefined &&this.state.printHeaderValues!==null ){
    //     var printHeaderValues=this.state.printHeaderValues;
    // }
    var pageNo = 1;

    var mrid = printHeaderValues.juteRecptNo;
    var mrid = mrid + " ";
    var mr_date = printHeaderValues.indentDateDesc;
    mr_date = mr_date + " ";
    var poId = printHeaderValues.id;
    poId = poId + " ";
    var challan_no = printHeaderValues.challanNo;
    challan_no = challan_no + " ";
    var challan_date = printHeaderValues.challanDate;
    challan_date = challan_date + " ";
    var vehicleNo = printHeaderValues.lorryNo;
    vehicleNo = vehicleNo + " ";
    var SupplierName = printHeaderValues.supplierName;
    SupplierName = SupplierName + " ";
    var brokerName = printHeaderValues.brokerName;
    brokerName = brokerName + " ";
    var frightPaid = printHeaderValues.frightPaid;
    frightPaid = frightPaid + " ";
    var gateEntryDate = printHeaderValues.gateEntryDate;
    gateEntryDate = gateEntryDate + " ";
    var mukamName = printHeaderValues.mukamName;
    mukamName = mukamName + " ";

    var companyName = getCookie(cons.COMPANY_NAME);
    var address1 =
      printHeaderValues.agentName !== ""
        ? printHeaderValues.agentAddress1 + " "
        : printHeaderValues.cAddress1;
    var address2 =
      printHeaderValues.agentName !== ""
        ? printHeaderValues.agentAddress2 + " "
        : printHeaderValues.cAddress2;

    var printcopy = "";
    var printCounnt = printHeaderValues.printCount;

    if (printCounnt == 0) {
      printcopy = "NOT A VALID";
    } else if (printCounnt == 1) {
      printcopy = "ORIGINAL";
    } else if (printCounnt == 2) {
      printcopy = "DUPLICATE";
    } else if (printCounnt == 3) {
      printcopy = "Triplicate";
    } else if (printCounnt == 4) {
      printcopy = "Quadruplicate";
    } else {
      printcopy = "COPY" + " " + printCounnt;
    }

    // if(this.state.printLineItems!==undefined &&this.state.printLineItems!==null ){
    //      modules=this.state.printLineItems;
    //  }
    // if(this.state.printTotalvalues!==undefined &&this.state.printTotalvalues!==null ){
    //   var  printTotalvalues=this.state.printTotalvalues;
    // }

    function addWaterMark(doc, printcopy) {
      var totalPages = doc.internal.getNumberOfPages();

      for (i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
        doc.setFontSize(24);
        doc.setTextColor(200);
        doc.globalAlpha = 0.5;
        //doc.zIndex(-1);
        doc.text(10, 30, printcopy);

        // doc.text(150, 600, printcopy,null,45);
      }

      return doc;
    }

    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(16);
    var xOffset =
      doc.internal.pageSize.width / 2 -
      (doc.getStringUnitWidth(companyName) * doc.internal.getFontSize()) / 2;
    doc.text(companyName, xOffset, 40);
    doc.setFontSize(10);
    //var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var TotalPgaeNo = doc.internal.getNumberOfPages();
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    doc.setFontSize(10);
    /* doc.text(190, 60, address1+'');
         doc.text(250, 80, address2+'');
         doc.setFontSize(9);
        */
    doc.setFontType("bold");
    doc.text(30, 80, "JUTE REPORT NO");
    doc.text(120, 80, ":");
    doc.setFontType("normal");
    doc.text(130, 80, mrid);
    doc.setFontType("bold");
    doc.text(400, 120, "INDENT DATE : ");
    doc.setFontType("normal");
    doc.text(480, 120, mr_date);
    doc.setFontType("bold");
    doc.text(30, 120, "INDENT NO");
    doc.text(120, 120, ":");
    doc.setFontType("normal");
    doc.text(130, 120, poId);
    var srno = doc.splitTextToSize("Srl no", 20);
    doc.cell(10, 150, 20, 40, srno, "", "C");
    var itemCode = doc.splitTextToSize("Item Code", 30);
    doc.cell(30, 150, 30, 40, itemCode, "", "C");
    var description = doc.splitTextToSize("Description", 60);
    doc.cell(90, 150, 70, 40, description, "", "C");
    var unit = doc.splitTextToSize("Unit", 50);
    doc.cell(180, 150, 20, 40, unit, "", "C");
    var stockqty = doc.splitTextToSize("Stock/Appr Qty", 30);
    doc.cell(220, 150, 40, 40, stockqty, "", "C");
    var avg = doc.splitTextToSize("Avg Cons Last Mth", 40);
    doc.cell(260, 150, 40, 40, avg, "", "C");
    var indentQty = doc.splitTextToSize("Indent Quantity", 35);
    doc.cell(290, 150, 40, 40, indentQty, "", "C");
    var dept = doc.splitTextToSize("Dept", 20);
    doc.cell(310, 150, 25, 40, dept, "", "C");
    doc.cell(340, 150, 100, 20, "       Previous Details     ", "", "C");
    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.setFontSize(9);
    doc.setFontType("normal");

    var pono = doc.splitTextToSize("PO No/Dt", 30);

    doc.cell(295, 170, 30, 20, pono, "", "C");
    doc.cell(320, 170, 30, 20, "Rate", "", "C");
    var supName = doc.splitTextToSize("Supplier Name", 36);

    doc.cell(350, 170, 40, 20, supName, "", "C");
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.cell(395, 150, 100, 20, "          Comp Status          ", "", "C");
    //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.setFontSize(9);
    doc.setFontType("normal");
    doc.cell(395, 170, 20, 20, "<12>", "", "C");
    doc.cell(420, 170, 20, 20, "<13>", "", "C");
    doc.cell(450, 170, 20, 20, "<14>", "", "C");
    doc.cell(470, 170, 20, 20, "<15>", "", "C");
    doc.cell(490, 170, 20, 20, "<16>", "", "C");
    doc.cellInitialize();
    doc.setFontSize(10);
    doc.setFontType("normal");
    var order = doc.splitTextToSize("Order No", 30);
    doc.cell(495, 150, 30, 40, order, "", "C");
    var cntrl = doc.splitTextToSize("Cntrl Stock", 30);
    doc.cell(525, 150, 30, 40, cntrl, "", "C");
    var delivery = doc.splitTextToSize("Delivery Status", 38);
    doc.cell(555, 150, 36, 40, delivery, "", "C");

    var cellWidths = [
      "",
      20,
      30,
      70,
      20,
      40,
      40,
      40,
      25,
      30,
      30,
      40,
      20,
      20,
      20,
      20,
      20,
      30,
      30,
      36,
    ];
    var cellTextaligns = [
      "",
      "left",
      "left",
      "left",
      "right",
      "right",
      "right",
      "right",
      "left",
      "left",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 165;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 165;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");

          doc.setFontType("bold");
          doc.cellInitialize();
          var srno = doc.splitTextToSize("Srno", 20);
          doc.cell(10, 150, 20, 40, srno, "", "C");
          var itemCode = doc.splitTextToSize("Item Code", 30);
          doc.cell(30, 150, 30, 40, itemCode, "", "C");
          var description = doc.splitTextToSize("Description", 50);
          doc.cell(90, 150, 70, 40, description, "", "C");
          var unit = doc.splitTextToSize("Unit", 50);
          doc.cell(180, 150, 20, 40, unit, "", "C");
          var stockqty = doc.splitTextToSize("Stock/Appr Qty", 30);
          doc.cell(220, 150, 40, 40, stockqty, "", "C");
          var avg = doc.splitTextToSize("Avg Cons Last Mth", 40);
          doc.cell(260, 150, 40, 40, avg, "", "C");
          var indentQty = doc.splitTextToSize("Indent Quantity", 35);
          doc.cell(290, 150, 40, 40, indentQty, "", "C");
          var dept = doc.splitTextToSize("Dept", 40);
          doc.cell(310, 150, 25, 40, dept, "", "C");
          doc.cell(340, 150, 100, 20, "       Previous Details     ", "", "C");
          //  doc.cell(360, 320, 50, 20, ' ', '', 'C');

          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.setFontSize(9);
          doc.setFontType("normal");

          var pono = doc.splitTextToSize("PO No/Dt", 30);

          doc.cell(295, 170, 30, 20, pono, "", "C");
          doc.cell(320, 170, 30, 20, "Rate", "", "C");
          var supName = doc.splitTextToSize("Supplier Name", 36);

          doc.cell(350, 170, 40, 20, supName, "", "C");
          doc.cellInitialize();
          doc.setFontSize(10);
          doc.setFontType("normal");
          doc.cell(
            395,
            150,
            100,
            20,
            "          Comp Status          ",
            "",
            "C"
          );
          //  doc.cell(360, 320, 50, 20, ' ', '', 'C');
          /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                      doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.setFontSize(9);
          doc.setFontType("normal");
          doc.cell(395, 170, 20, 20, "<12>", "", "C");
          doc.cell(420, 170, 20, 20, "<13>", "", "C");
          doc.cell(450, 170, 20, 20, "<14>", "", "C");
          doc.cell(470, 170, 20, 20, "<15>", "", "C");
          doc.cell(490, 170, 20, 20, "<16>", "", "C");
          doc.cellInitialize();
          doc.setFontSize(10);
          doc.setFontType("normal");
          var order = doc.splitTextToSize("Order No", 30);
          doc.cell(495, 150, 30, 40, order, "", "C");
          var cntrl = doc.splitTextToSize("Cntrl Stock", 30);
          doc.cell(525, 150, 30, 40, cntrl, "", "C");
          var delivery = doc.splitTextToSize("Delivery Status", 38);
          doc.cell(555, 150, 36, 40, delivery, "", "C");
          doc.setFontSize(7);
        }
        var mytext = "Max Quantity :30.00    Min Quantity: 15.00";

        var a = 0;
        return [
          Object.keys(prop).map((key, index) => {
            a = a + 1;
            var size = 30;

            if (key === "itemIdDesc") {
              var size = 60;
            } else {
              var size = 30;
            }
            var myItem = prop[key] + " ";
            myItem = doc.splitTextToSize(myItem, size);
            // }

            return doc.cell(
              10,
              k,
              cellWidths[a],
              25,
              myItem,
              2,
              cellTextaligns[a]
            );
          }),

          // doc.text(30, k+10, mytext)-+-
        ];
      });
    }
    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(10, k + 25, 20, 30, " ", "", "right");

    doc.cell(30, k + 25, 30, 30, " ", "", "right");
    doc.cell(90, k + 25, 70, 30, " ", "", "right");
    doc.cell(180, k + 25, 20, 30, " ", "", "right");
    doc.cell(220, k + 25, 40, 30, " ", "", "right");
    doc.cell(260, k + 25, 40, 30, " ", "", "right");
    doc.cell(290, k + 25, 40, 30, " ", "", "right");
    doc.cell(310, k + 25, 25, 30, " ", "", "right");
    doc.cell(340, k + 25, 30, 30, " ", "", "right");
    doc.cell(295, k + 25, 30, 30, " ", "", "right");
    doc.cell(320, k + 25, 40, 30, " ", "", "right");
    doc.cell(350, k + 25, 20, 30, " ", "", "right");
    doc.cell(395, k + 25, 20, 30, " ", "", "right");
    doc.cell(420, k + 25, 20, 30, " ", "", "right");
    doc.cell(450, k + 25, 20, 30, " ", "", "right");
    doc.cell(490, k + 25, 20, 30, " ", "", "right");
    doc.cell(495, k + 25, 30, 30, " ", "", "right");
    doc.cell(525, k + 25, 30, 30, " ", "", "right");
    doc.cell(555, k + 25, 36, 30, " ", "", "right");

    doc.setFontType("bold");
    doc.setFontSize(10);
    doc.text(30, k + 150, "Checked By");

    doc.text(150, k + 150, "Store Signatory");
    doc.text(300, k + 150, "Authorised Signatory");
    doc.text(450, k + 150, "Approval Signatory");

    doc.text(30, k + 170, "Note For Head Office");
    doc.setFontType("normal");
    doc.text(
      30,
      k + 190,
      "1. This indent copy is made only for office use, No other outside persons are entitled to see that."
    );
    doc.text(
      30,
      k + 210,
      "2. From column No. 1 to 11 to be filled from computer ot stores department and balance 12 to 18 are kept blank"
    );
    doc.text(
      50,
      k + 230,
      "<a> 12 to 17 for rate comparison <b> 18 for writing order no after placing to supplier"
    );
    doc.text(
      30,
      k + 250,
      "3. Pl also make sure that order item code & unit should be tallyed with Indent"
    );
    doc.text(
      30,
      k + 270,
      "4. Pl also give indent & indent Srl No on order. This will help a lot for Stores job processing"
    );
    doc.text(
      30,
      k + 290,
      "5.Column No 19 is showing Central Store Stock. So,Please see carefully before sending indent to HO"
    );
    doc.text(
      30,
      k + 310,
      "3. Option available a) General Indent   b) O/H Indent   c) Open Indent"
    );

    //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 350, newdat);
    doc.text(500, k + 350, printBy);
    addWaterMark(doc, printcopy);

    doc.save("Indent_" + printHeaderValues.id + ".pdf");
  };
}
const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(withStyles(extendedFormsStyle)(Bom));
