import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons





// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, serverVars, getConfigValue, configNames} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList, uploadFile} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import Edit from '@material-ui/icons/Edit';
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Search from "@material-ui/icons/Search";
import LineItemTable from "../CustumComponents/LineItemTable";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import {VIEW} from "../../ui-actions/UiActions";
import { connect } from "react-redux";


class StockReturn extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            fromDate:"",
            todate:"",
            deptId:"",
            itemGroup:'',
            item:'',
            costFactor:'',
            status:'',
            isRefresh:false,
            isLoading:false,
            itemViewType:"hdrId",
            approveStatus:false,
            hideViews:"none",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            createdBy:"",
            createdOn:"",
            alert:false,
            url:"",
            uom:'',
            selectedDepartment:'',
            selectedItemGroup:'',
            selectedItem:'',
            quantity:'',
            selectedCostCenter:'',
            ProcessType:'',
            stock:'',
            issueNo:'0',
            invoiceList:[],
            srLineId:[],
            showUpdate:false,
            indentTypeId:"",
            issueHdrId:"",
            internalNote:"",
            branchId:"",
            projectId:"",
            typeId:0
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem=this.handleAddItem.bind(this);
    }
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            })
        }else {
            this.showMessage(resp.message,resp.status);
        }
    };
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "deleteStoreIssue/" + id+"/"+getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    handleViewClick =(id)=>{
       // var data = this.state.dataTable;
      //  var obj= JSON.parse(id);
      //  console.log("jute  "+"  "+obj);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
                var data = {
                    hdrId: id,
                    companyId: this.props.state.companyInfo.id,
                    finnacialYear: getCookie(cons.ACADEMIC_YEAR),
                    userId:getCookie(cons.USERID)
                };
                //   var data={"date":obj.date,"deptCost":obj.deptCost,"uom":obj.uom,"userId":getCookie(cons.USERID),"companyId":this.props.state.companyInfo.id,"cipher":getCookie(cons.CIPHER)};
                // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getStoreIssueById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp);
                requestList(serverApi.STORE_RETURN_BY_ID , data, this.handleViewClickResp)

        }
    };
    handleIssueNoClick =(id)=>{
        // var data = this.state.dataTable;
        //   var obj= JSON.parse(id);
        //  console.log("jute  "+"  "+obj);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            if(this.state.issueHdrId!=="") {
                var data = {
                    hdrId: this.state.issueHdrId,
                    companyId: this.props.state.companyInfo.id,
                    finnacialYear: getCookie(cons.ACADEMIC_YEAR)
                };

                //   var data={"date":obj.date,"deptCost":obj.deptCost,"uom":obj.uom,"userId":getCookie(cons.USERID),"companyId":this.props.state.companyInfo.id,"cipher":getCookie(cons.CIPHER)};
                // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getStoreIssueById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp);
                requestList(serverApi.DATATABLE_SERVICE_URL + "getStoreIssueById", data, this.handleIssueNoClickResp)
            }else {
                this.basicAlert("Please Enter Stock Return No..!!")
            }
        }
    };
    handleIssueNoClickResp = (resp) => {
        this.setState({printResp:resp});
        var header = resp, createdBy = "", createdOn = "", date = "", finYear = "",
            approveButton = false, issueStatus = false,showUpdate=false,hdrId,indentTypeId,branchId="",projectId="";
        if(header !== null&&header.length > 0){
            var invoicelist = [];
            header.map((prop, key) => {
                createdBy = prop.createdBy;
                createdOn = prop.createDateDesc;
                date = prop.issueDateDesc;
                finYear = prop.finnacialYear;
                approveButton = prop.approveButton;
                indentTypeId=prop.indentTypeId;
                branchId=prop.branchId;
                projectId=prop.projectId;
                hdrId=prop.hdrId;
                if(prop.issueStatus!==3&&prop.issueStatus!==4){
                    showUpdate=true
                }
                var rate=prop.issueValue/prop.quantity;
                rate=rate.toFixed(2);
                issueStatus = prop.status;
                // issueId=prop.issueId;

                var obj= {
                    id: key,
                    issueRecId: prop.id,
                    department:prop.deptDesc,
                    itemGroupId:prop.groupCode,
                    itemGroup: prop.groupDesc,
                    itemId:prop.itemCode,
                    itemGrp:prop.groupCode+""+prop.itemCode,
                    item: prop.itemDesc,
                    quantity:prop.quantity,
                    issueQty:prop.quantity,
                    uom: prop.uomCode,
                    deptId: parseInt(prop.deptId),
                    deleteStatus:false,
                    srLineId:prop.srLineId,
                    srNo:prop.srNo,
                    issueValue:prop.issueValue,

                    rate:parseFloat(rate),

                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === obj.id) {
                                            this.basicItemAlert("Do You Like To Update This Item?");

                                            this.setState({
                                                id: o.id,
                                                issueRecId:o.issueRecId,
                                                indentItemId: o.indentItemId,
                                                updateItemAlert: true,
                                                updateItem: true,
                                                selectedDepartment:o.deptId,
                                                quantity:o.quantity,
                                                uom:o.uom,
                                                selectedItemGroup:o.itemGroupId,
                                                selectedItem:o.itemId,
                                                itemGroupName:o.itemGroup,
                                                item:o.item,
                                                deptName:o.department,
                                                srLineId:o.srLineId,
                                                srNo:o.srNo,
                                                issueValue:o.issueValue,
                                                issueQty:o.issueQty,
                                                rate:o.rate,
                                                loadItemGroup:true,
                                                loadItem:true
                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === obj.id) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                                issueId: o.issueId,
                                            });

                                            //  data.splice(i,1);
                                            return true;
                                        }
                                    });
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>{" "}
                        </div>

                    )
                }
                invoicelist.push(obj)
            });


            this.setState({
               // updateView: true,
               // isCreated:true,
                // unitConvertionId:"0",
                date1:date,
            /*    createdOn:createdOn,
                createdBy:createdBy,
                issueStatus:issueStatus,
                showUpdate:showUpdate,
                approveStatus:approveButton,*/
                invoiceList:invoicelist,
                issueHdrId:hdrId,
                indentTypeId:indentTypeId,
                loadType:true,
                branchId:branchId,
                projectId:projectId,
                loadProject:true,
                loadBranch:true
            });

        }


    };

    handleViewClickResp = (resp) => {
        this.setState({printResp:resp});
        var header = resp, createdBy = "", createdOn = "", date = "", finYear = "",
            approveButton = false, issueStatus = false,showUpdate=false,hdrId,issueHdrId,indentTypeId,internalNote,branchId="",projectId="";
        if(header !== null&&header.length > 0){
            var invoicelist = [];
                header.map((prop, key) => {
                createdBy = prop.createdBy;
                createdOn = prop.createDateDesc;
                date = prop.returnDateDesc;
                finYear = prop.finnacialYear;
                approveButton = prop.approveButton;
                branchId=prop.branchId;
                    projectId=prop.projectId;

                indentTypeId=prop.indentTypeId;
                    internalNote=prop.internalNote;
                hdrId=prop.hdrId;
                    issueHdrId=prop.issueHdrId;
                if(prop.status!=="3"&&prop.status!=="4"){
                    showUpdate=true
                }

                issueStatus = prop.status;
                // issueId=prop.issueId;

                   var obj= {
                        id: key,
                        issueId: prop.id,
                        department:prop.deptDesc,
                        itemGroupId:prop.groupCode,
                        itemGroup: prop.groupDesc,
                        itemId:prop.itemCode,
                        itemGrp:prop.groupCode+""+prop.itemCode,
                        item: prop.itemDesc,
                        quantity:prop.quantity,
                        uom: prop.uomCode,
                        stock:prop.stock,
                        deptId: parseInt(prop.deptId),
                        deleteStatus:false,
                        srLineId:prop.srLineId,
                       issueRecId:prop.issueRecId,
                        srNo:prop.issueHdrId,
                       issueValue:prop.returnValue,
                       rate:prop.returnRate,
                       issueComSeq:prop.issueComSeq,

                        actions: (
                            <div className="actions-right">
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                this.basicItemAlert("Do You Like To Update This Item?");

                                                this.setState({
                                                    id: o.id,
                                                    issueId:o.issueId,
                                                    indentItemId: o.indentItemId,
                                                    updateItemAlert: true,
                                                    updateItem: true,
                                                    selectedDepartment:o.deptId,
                                                    quantity:o.quantity,
                                                    selectedCostCenter:o.costFactorId,
                                                    stock: o.stock,
                                                    uom:o.uom,
                                                    selectedItemGroup:o.itemGroupId,
                                                    selectedItem:o.itemId,
                                                    itemGroupName:o.itemGroup,
                                                    item:o.item,
                                                    deptName:o.department,
                                                    customerId:o.customerId,
                                                    rate:o.rate,
                                                    srLineId:o.srLineId,
                                                    srNo:o.srNo,
                                                    issueValue:o.issueValue,
                                                    loadItemGroup:true,
                                                    loadItem:true,
                                                    issueRecId:o.issueRecId,
                                                    issueComSeq:o.issueComSeq
                                                    // alert:true,

                                                });
                                                return true;
                                            }
                                        });
                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                    issueId: o.issueId,
                                                });

                                                //  data.splice(i,1);
                                                return true;
                                            }
                                        });
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>{" "}
                            </div>

                        )
                    }
                    invoicelist.push(obj)
            });


            this.setState({
                updateView: true,
                isCreated:true,
                hideViews:"block",
               // unitConvertionId:"0",
                date1:date,
                createdOn:createdOn,
                createdBy:createdBy,
                issueStatus:issueStatus,
                showUpdate:showUpdate,
                approveStatus:approveButton,
                invoiceList:invoicelist,
                hdrId:hdrId,
                issueHdrId:issueHdrId,
                indentTypeId:indentTypeId,
                loadType:true,
                internalNote:internalNote, branchId:branchId,
                projectId:projectId,
                loadProject:true,
                loadBranch:true

            });

        }


    };
    getDefaultUnitConvertion=(name)=>{
        var val="",label="",data="";
        if(name !== null){
            if(name === 'LOOSE'){
                val=1;
                label="LOOSE";
            }else {
                val=2;
                label='BALE';
            }
            data={
                value:val,label:label
            };
        }
        return data;
    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
        //alert();
        if(!this.state.isCreated){
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getIssueListData=()=>{
        const {fromDates,toDates,IssueNos,customerIds,indentTypeIds,branchIds,projectIds,statuss}=this.state;
        var data={
            "fromDate":fromDates,
            "toDate":toDates,
            "hdrId":IssueNos,
            "customerId":customerIds,
            "indentTypeId":indentTypeIds,
            "branchId":branchIds,
            "status":statuss,
            "companyId":this.props.state.companyInfo.id,
            "projectId":projectIds,
            "finnacialYear":getCookie(cons.ACADEMIC_YEAR),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
        }
        return data;
    }

    getData = () => {
        var data={
            "fromDate":this.state.fromDate
            ,"toDate":this.state.todate,
          /*  ,"deptId":this.state.deptId,
            "groupId":this.state.itemGroup,
            "itemId":this.state.item,*/
           // "costFactor":this.state.costFactor,
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER),
            "erpType":getConfigValue(configNames.LABELS_TYPE),
        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else {
            this.setState({
                [id] :''
            });

        }
       // this.dateChanged();
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    }


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };
    getConvertedQty=()=>{
        if(this.state.unitConvertionId === '2'){
          //  console.log("unitConvertionId"+this.state.indentQuantity);
            return Math.round(parseFloat(parseFloat((this.state.indentQuantity*100)/150)));
        }else {
            return "";
        }
    };
    getIndentQtyTon=()=>{
        var val = this.state.indentQuantity;
        if(val!== ""){

            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return  parseFloat(parseFloat(val/10).toFixed(2));
        }else {
            return "";
        }
    };
    getIndentQty=()=>{
        var val = this.state.vehicleQuantity;
          console.log(val+"vehicleQuantity "+this.state.selectedIndentQuantity);
        if(val!== ""){

            return parseInt(val)* parseInt(this.state.selectedIndentQuantity)
        }else {
            return "";
        }
    };

    getStock=()=>{
        console.log("sttt",this.state.stock);
            return this.state.stock;
    };

    onChangeValues =(event)=> {
        var value= event.target.value;

        if(event.target.id==="stock"){
            console.log("val "+this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if(event.target.id==='quantity'&&value!==''){
           var issueValue=parseFloat(value)*parseFloat(this.state.rate);
           this.setState({issueValue:issueValue.toFixed(2)})
        }
        if(event.target.id==='rate'&&value!==''){
            var issueValue=parseFloat(value)*parseFloat(this.state.quantity);
            this.setState({issueValue:issueValue.toFixed(2)})
        }
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if(stateKey === 'selectedDepartment'){
            this.setState({
                loadItemGroup:true
            })
          //  this.getSrAStockByItemDept(selectedValue,this.state.selectedItemGroup,this.state.selectedItem)
        }
/*
        if(stateKey === 'selectedItemGroup'){
            var data={
                "itemGroupId":selectedValue,
                "saleable":"",
                "consumable":"Y",
                "companyId":this.props.state.companyInfo.id
            };

            requestList(serverApi.GET_ITEMS_BY_GRPID,data,this.handleItem);
            this.setState({
                loadItem:true
            })
          //  this.getSrAStockByItemDept(this.state.selectedDepartment,selectedValue,this.state.selectedItem)

        }
*/
/*
        if(stateKey==='selectedItem'){
            this.getSrAStockByItemDept(this.state.selectedDepartment,this.state.selectedItemGroup,selectedValue);
        }
*/

        this.setState({
            [stateKey]: selectedValue
        });
    };

    handleItem = (resp) => {
        this.setState({ItemOptions:resp,loadItem:true})

    }
    afterRefreshItem = (val) => {

        this.setState({
            loadItem: val
        });
    };

    afterRefreshItemGrp = (val) => {

        this.setState({
            loadItemGroup: val
        });
    };
    getDeptName = (selectedValue) => {
        this.setState({
            deptName:selectedValue
        });
    };
    getItemGroupName = (selectedValue) => {
        this.setState({
            itemGroupName:selectedValue
        });
    };
    getCostFactorName=(selectedValue)=>{
        this.setState({
            costFactorName:selectedValue
        });

    };

    getItemDetailsByName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            item:values[0],
            // selectedItemId:values[1],
            uom:values[1],
            //stock:values[2]!=='null'?values[2]:0.00,
        });
    };

    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };

    hideBasicAlert(){
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            isReject:false,isApprove:false
        });

    }
    hideAlert() {
        console.log("alert");
        this.setState({
            isJuteSelected:false,
            alert: false,
            updateItem: false,
            showMessagealert:false,
            loading:false,
            id:"",
            issueId:"",
            selectedDepartment:"",
            quantity:"",
            selectedCostCenter:"",
            stock: "",
            uom:"",
            selectedItemGroup:"",
            selectedItem:"",
            deptName:"",
            itemGroupName:"",
            item:"",
            costFactorName:"",
            machineName:"",
            machineId:"",
            srLineId:"",
            srNo:"",
            issueValue:"",
            srList:[],
            srStock:'',
            issueRecId:""
        });
    }
    hideAlertMessage(){
        this.setState({
            isCreated:false,
            hideViews:"none",
            approveStatus:false,
            showMessagealert:false,
            alert: false,
            indentNo:"",
            selectedDepartment:'',
            selectedItemGroup:'',
            selectedItem:'',
            quantity:'',
            selectedCostCenter:'',
            ProcessType:'',
            stock:'',
            updateView:false,
            machineName:"",
            machineId:"",
            date1:moment().format('DD-MM-YYYY'),
            year:getCookie(cons.ACADEMIC_YEAR),
            invoiceList:[],
            showUpdate:false,
            hdrId:"",
            indentTypeId:"",
            loadType:true,
            issueHdrId:"",
            branchId:"",
            projectId:"",
            loadProject:true,
            loadBranch:true,
            internalNote:"",
            issueComSeq:"",
            typeId:0
        });

    }
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    onRejectButtonClick = () => {
        var data = {
            "id": this.state.issueNo,
            "finnacialYear": this.state.year,
            "issueDate": this.dateChanged(),
            "deptId": this.state.selectedDepartment,
            "itemCode": this.state.selectedItem,
            "groupCode": this.state.selectedItemGroup,
            "quantity": this.state.quantity,
            "deptCost": this.state.selectedCostCenter,
            "stock": this.state.stock,
            "processType": this.state.ProcessType,
            "uomCode": this.state.uom,
            "status":4,
            "submitter": getCookie(cons.USERID),
            "lastModifiedUser": getCookie(cons.USER_NAME),


        };
        this.handleLoading();
        requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
    };

    onApproveButtonClick=(status)=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                return ({
                    "id":prop.issueId,
                    "finnacialYear": this.state.year,
                    "indentTypeId":this.state.indentTypeId,
                    "returnDate": this.state.date1,
                    "deptId": prop.deptId,
                    "itemCode": prop.itemId,
                    "groupCode": prop.itemGroupId,
                    "quantity": prop.quantity,
                    "deptCost": prop.costFactorId,
                    "stock": prop.stock,
                    // "processType": this.state.ProcessType,
                    "uomCode": prop.uom,
                    "submitter": getCookie(cons.USERID),
                    "createdBy": getCookie(cons.USER_NAME),
                    "companyId":this.props.state.companyInfo.id,
                    "cipher":getCookie(cons.CIPHER),
                    "returnSrlNo":key+1,
                    "srNo":prop.srNo,
                    "srLineId":prop.srLineId,
                    "returnValue":prop.issueValue,
                    returnRate:prop.rate,
                    issueHdrId:this.state.issueHdrId,
                    issueRecId:prop.issueRecId,
                    hdrId:this.state.hdrId,
                    isActive:1,
                    "status":status,
                    "userId":getCookie(cons.USERID),
                    internalNote:this.state.internalNote,
                    branchId:this.state.branchId,
                    projectId:this.state.projectId,
                    issueComSeq:prop.issueComSeq
                })
            });
            if (this.state.date1 !== ''&& this.state.invoiceList.length>0&&this.state.indentTypeId!==""&&this.state.indentTypeId!=="0") {
                this.handleLoading();
                requestList(serverApi.STORE_RETURN_UPDATE, invoiceList, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick=()=>{
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var invoiceList = this.state.invoiceList.map((prop, key) => {
                return ({
                    "finnacialYear": this.state.year,
                    "indentTypeId":this.state.indentTypeId,
                    "returnDate": this.state.date1,
                    "deptId": prop.deptId,
                    "itemCode": prop.itemId,
                    "groupCode": prop.itemGroupId,
                    "quantity": prop.quantity,
                    "deptCost": prop.costFactorId,
                    "stock": prop.stock,
                   // "processType": this.state.ProcessType,
                    "uomCode": prop.uom,
                    "submitter": getCookie(cons.USERID),
                    "createdBy": getCookie(cons.USER_NAME),
                    "companyId":this.props.state.companyInfo.id,
                    "cipher":getCookie(cons.CIPHER),
                    "returnSrlNo":key+1,
                    "srNo":prop.srNo,
                    "srLineId":prop.srLineId,
                    "returnValue":prop.issueValue,
                    returnRate:prop.rate,
                    issueHdrId:this.state.issueHdrId,
                    issueRecId:prop.issueRecId,
                    isActive:1,
                    internalNote:this.state.internalNote,
                    branchId:this.state.branchId,
                    projectId:this.state.projectId
                })
            });

/*
            var data = {
                invoiceList
            };
*/
            if (this.state.date1 !== ''&& this.state.invoiceList.length>0&&this.state.indentTypeId!==""&&this.state.indentTypeId!=="0") {
                this.handleLoading();
                requestList(serverApi.STORE_RETURN_ADD, invoiceList, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please add atleast one line item..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item



    getInvoiceTablePageSize=()=>{
        var length =this.state.invoiceList.length;
        if( length > 5){
            return length
        }else {
            return 5;
        }
    };


    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                  //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

   /* const onSelect = (selectedValue, stateKey) => {
        if(this.state.isJuteSelected){
            this.setState({
                isJuteSelected:false
            });

        }
        this.setState({
            [stateKey]: selectedValue,
        });

        if(stateKey==="selectedJuteType"){
            //   id=selectedValue;
            console.log(" is "+selectedValue);

            if(selectedValue !==0)
                this.setState({
                    isJuteSelected:true,
                    //  alert:null
                });
            //  return id;
        }
        //  handleAddItem();
    };*/

    handleAddItem () {
        const {classes}=this.props;
        const {selectedJuteType}=this.state;
        console.log("unitConversionName "+ this.state.unitConvertion);
        //  var id="";
        console.log(this.state);
        if(this.state.selectedMukam !=='0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType!== "")
            this.setState({
                loading:true,
                alert:true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    getVehicleQlty = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^^");
        this.setState({
            selectedIndentQuantity:values[1],
        });
        console.log("indentQuantity "+this.state.indentQuantity);

    };
    onClickAddItem() {
        var arr = this.state.invoiceList;
        var sumOfQty = 0, quantityInItem = 0;
        var itemIndex = 0, quantity = 0, hasItem = false, issueId = 0;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            issueId = this.state.issueId;
            quantityInItem = arr[itemIndex].quantity;
            arr[itemIndex].quantity = this.state.quantity
        } else {
            itemIndex = this.state.invoiceList.length
        }

        //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
        arr.map(q => {
            sumOfQty = sumOfQty + parseInt(q.quantity);
        });
        if (!this.state.updateItem) {
            sumOfQty = sumOfQty + parseInt(this.state.quantity);
        }
        var issueValue=parseFloat(this.state.rate)*parseFloat(this.state.quantity);

        var addItemObj = {
            id: itemIndex,
            issueId:issueId,
            department: this.state.deptName,
            itemGroupId:this.state.selectedItemGroup,
            itemGroup: this.state.itemGroupName,
            itemId: this.state.selectedItem,
            item: this.state.item,
            itemGrp:this.state.selectedItemGroup+""+this.state.selectedItem,
            quantity:this.state.quantity,
            uom: this.state.uom,
            stock:this.state.stock,
            deptId: this.state.selectedDepartment,
            deleteStatus:false,
            costFactorId:this.state.selectedCostCenter,
            costFactor:this.state.costFactorName,
            machineId:this.state.machineId,
            machineName:this.state.machineName,
            srNo:this.state.srNo,
            srLineId: this.state.srLineId,
            issueRecId:this.state.issueRecId,
            issueValue:issueValue.toFixed(2),
            rate:this.state.rate,
            issueComSeq:this.state.issueComSeq,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id: o.id,
                                        issueId: o.issueId,
                                        updateItemAlert: true,
                                        updateItem: true,
                                        selectedDepartment:o.deptId,
                                        quantity:o.quantity,
                                        selectedCostCenter:o.costFactorId,
                                        stock: o.stock,
                                        uom:o.uom,
                                        selectedItemGroup:parseInt(o.itemGroupId),
                                        selectedItem:o.itemId,
                                        deptName:o.department,
                                        itemGroupName:o.itemGroup,
                                        item:o.item,
                                        costFactorName:o.costFactor,
                                        machineId:o.machineId,
                                        machineName:o.machineName,
                                        srNo:o.srNo,
                                        srLineId: o.srLineId,
                                        issueValue:o.issueValue,
                                        rate:o.rate,
                                        loadItemGroup:true,
                                        loadItem:true,
                                        issueRecId:o.issueRecId,
                                        issueComSeq:o.issueComSeq

                                        // alert:true,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="edit">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({
                                        deleteItem: true,
                                        id: o.id,
                                        issueId: o.issueId,
                                    });

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>{" "}
                </div>

            )
        };


        // if (!hasItem) {issueQty
        if (this.state.date1 !== '' && this.state.selectedDepartment !== "0" && this.state.selectedItemGroup !== "0" && this.state.selectedItem !== "0" && this.state.quantity !== ""&&this.state.srLineId>0&& this.state.rate !== "") {

                    if (this.state.updateItem) {
                        arr[itemIndex] = addItemObj;
                    } else
                        arr.splice(arr.length, 0, addItemObj);
                    if(!this.state.updateView) {
                        if (parseFloat(addItemObj.quantity) <= parseFloat(this.state.issueQty)) {
                            this.setState({
                                hideJuteType: false,
                                loading: true,
                                invoiceList: arr,
                            });
                            this.hideAlert();
                        } else {
                            this.basicAlert("Quantity Should Not Exceed Issue Quantity "+this.state.issueQty);
                        }
                    }else {
                        this.setState({
                            hideJuteType: false,
                            loading: true,
                            invoiceList: arr,
                        });
                        this.hideAlert();
                    }
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }
    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.forEach((prop,i)=>{
                if(prop.id===this.state.id){
                    if(this.state.updateView && this.state.issueId!==0)
                        data[this.state.id].deleteStatus=true;
                    else
                        data.splice(this.state.id, 1);
                }

            });
/*
            if(this.state.updateView && this.state.issueId!==0)
                data[this.state.id].deleteStatus=true;
            else{
                data.splice(this.state.id, 1);
/!*
                data.map((o,key)=>{
                    o.id=key;
                    o.actions= (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            this.basicItemAlert("Do You Like To Update This Item?");

                                            this.setState({
                                                id: o.id,
                                                issueId: o.issueId,
                                                updateItemAlert: true,
                                                updateItem: true,
                                                selectedDepartment:o.department,
                                                quantity:o.quantity,
                                                selectedCostCenter:o.costFactor,
                                                stock: o.stock,
                                                uom:o.uom,
                                                selectedItemGroup:parseInt(o.itemGroupId),
                                                selectedItem:parseInt(o.item),
                                                // alert:true,

                                            });
                                            return true;
                                        }
                                    });
                                }}
                                color="primaryNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {
                                        if (o.id === key) {
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            this.basicItemAlert("Do You Like To Delete This Item?");
                                            this.setState({
                                                deleteItem: true,
                                                id: o.id,
                                                issueId: o.issueId,
                                            });

                                            //  data.splice(i,1);
                                            return true;
                                        }
                                    });
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>{" "}
                        </div>

                    )

                });
*!/

            }
*/
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }


    getDataTable =()=>{
        var data=[];
      //  console.log("dta",JSON.stringify(this.state.invoiceList))
        if(this.state.invoiceList.length>0)
        this.state.invoiceList.map((prop,key)=>{
            if(!prop.deleteStatus){
                data.push(prop)
            }
        });
        return data;
    };
    getMachineName=(val)=>{
        this.setState({
            machineName:val
        })
    };

    getSrAStockByItemDept=(deptId,itemGrpId,itemId)=>{
        if(deptId!==""&&itemGrpId!==""&&itemId!==""){
            var data={
                //"deptId":deptId,
                "itemGrpId":itemGrpId,
                "itemId":itemId,
                "companyId":this.props.state.companyInfo.id
            };
            requestList(serverApi.GET_SR_STOCK_LIST,data,this.handleSrStock);
            requestList(serverApi.GET_BALE_STOCK,data,this.handleStock);
        }else {
            this.setState({srList:[],loadSr:true})

        }

    };
    handleStock=(resp)=>{
        if(resp.status===true)
        this.setState({stock:resp.data});
        else {
            this.setState({stock:0})

        }
    };
    handleSrStock=(resp)=>{
        if(resp!==null){
            this.setState({srList:resp,loadSr:true})
        }
    };
    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,entryId,isReject,isApprove,isAccept}=this.state;

        if(isAccept){
            this.setState({isAccept:false});
            this.onApproveButtonClick("29")

        }
        if(isReject){
            this.setState({isReject:false});
            this.onApproveButtonClick("4")
        }
        if(isApprove){
            this.setState({isApprove:false});
            this.onApproveButtonClick(this.state.issueStatus)
        }

        if (uploadInvoice) {
            uploadFile(fileName,file,fileType,22,fileName,entryId, this.handleInvoiceResp);
            //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
            //  this.uploadInvoiceFile();
        }
        if (this.state.deleteInvoice) {

            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.invoiceId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert1: null,
        });

    }

    hideInvoiceAlert(){
        this.setState({
            basicAlert1: null,
            isReject:false,isApprove:false
        });
    }

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert1: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideInvoiceAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }    render(){
        const { classes } = this.props;
        const {selectedJuteType}=this.state;
        console.log("jute type",selectedJuteType);
//add item button click
       var handleSelectedValues = (defValue) => {
            this.setState(
                {
                    unitConvertion:defValue ,
                    unitConvertionId:defValue.value,
                    unitConvertionName:defValue.label
            });
            console.log(`Option selected:`, this.state.unitConvertionName);
        }
        const options = [
            { value: '', label: 'Select...' ,name:'Select...'},
            { value: '1', label: 'LOOSE' ,name:'LOOSE'},
            { value: '2', label: 'BALE',name: 'BALE' }
        ];
        const inputFocus={
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var tomorrow = Datetime.moment().add( 0, 'day' );

        var isBackDate=getConfigValue(configNames.BACK_DATE_ALLOWABLE);
        var isfutureDate=getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

        var valid = function( current ){
            if(isBackDate==="2"&&isfutureDate==="2")
                return current.isAfter( yesterday )&&current.isBefore( tomorrow );
            else if(isfutureDate==="2"&&isBackDate==="1"){
                return current.isBefore( tomorrow );
            } else if(isfutureDate==="1"&&isBackDate==="2"){
                return current.isAfter( yesterday );
            }
            else
                return true;
        };
        var labelType=getConfigValue(configNames.LABELS_TYPE);

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert1}
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    {
                                        this.state.alert ?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={this.state.updateItem ? "update" : "Add"}
                                                cancel_button_text={"Cancel"}
                                                // juteType={selectedJuteType}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Department<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            selectDisabled={true}
                                                                            id={"selectedDepartment"}
                                                                            onSelect={this.onSelect}
                                                                            defValue={this.state.selectedDepartment}
                                                                            getName={this.getDeptName}
                                                                            stateKey="selectedDepartment"
                                                                            url={serverApi.SEARCH_DEPATMENTS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                            value={this.state.selectedDepartment}
                                                                        />
                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            selectDisabled={true}
                                                                            id={"selectedItemGroup"}
                                                                            defValue={this.state.selectedItemGroup}
                                                                            onSelect={this.onSelect}
                                                                            getName={this.getItemGroupName}
                                                                            isRefresh={this.state.loadItemGroup}
                                                                            stateKey="selectedItemGroup"
                                                                            setIsRefresh={this.afterRefreshItemGrp}
                                                                            url={serverApi.DATATABLE_SERVICE_URL+this.state.selectedDepartment+"/"+this.props.state.companyInfo.id+"/getAllItemGroupsByDepartmentId/"+getCookie(cons.CIPHER)}
                                                                            value={this.state.selectedItemGroup}
                                                                        />


                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Item<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            selectDisabled={true}
                                                                            id={"selectedItem"}
                                                                            defValue={this.state.selectedItem}
                                                                            onSelect={this.onSelect}
                                                                            getName={this.getItemDetailsByName}
                                                                            isRefresh={this.state.loadItem}
                                                                            stateKey="selectedItem"
                                                                            setIsRefresh={this.afterRefreshItem}
                                                                           // staticData={this.state.ItemOptions}
                                                                            postData={{
                                                                                "itemGroupId":this.state.selectedItemGroup,
                                                                                "saleable":"",
                                                                                "consumable":"",
                                                                                "companyId":this.props.state.companyInfo.id,
                                                                                "indentTypeId":this.state.typeId
                                                                            }}
                                                                            url={serverApi.GET_ITEMS_BY_GRPID}
                                                                            //url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                            value={this.state.selectedItem}
                                                                        />


                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>
{/*
                                                            <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            SR No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <SelectboxTemp
                                                                            id={"srLineId"}
                                                                            onSelect={this.onSelect}
                                                                            defValue={this.state.srLineId}
                                                                            getName={(val)=>{
                                                                                var name=val.split('_');

                                                                                var srStock=name[2],qty=name[2],rate=name[1];
                                                                                if(this.state.updateView&&this.state.updateItem){
                                                                                    srStock=parseFloat(srStock)+parseFloat(this.state.quantity);
                                                                                    qty=this.state.quantity;
                                                                                    rate=this.state.rate
                                                                                }

                                                                                var issueval=parseFloat(this.state.quantity)*parseFloat(this.state.rate);
                                                                                this.setState({
                                                                                    srNo:name[0],
                                                                                    rate:name[1],
                                                                                    quantity:qty,
                                                                                    srStock:srStock,
                                                                                    issueValue:issueval.toFixed(2)
                                                                                })
                                                                            }}
                                                                            isRefresh={this.state.loadSr}
                                                                            setIsRefresh={(val)=>{
                                                                                this.setState({loadSr:false})
                                                                            }}
                                                                            stateKey="srLineId"
                                                                            staticData={this.state.srList}
                                                                            value={this.state.srLineId}
                                                                        />
                                                                    </ItemGrid>
                                                                </GridContainer>

                                                            </ItemGrid>
*/}

                                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Quantity<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="quantity"
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            value={this.state.quantity}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            decimal={3}
                                                                            InputProps={{

                                                                                autoComplete: 'off',
                                                                                endAdornment: <InputAdornment position="end">
                                                                                    <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>{this.state.uom}</span>
                                                                                </InputAdornment>,
                                                                            }}

                                                                        />

                                                                    </ItemGrid>

                                                                </GridContainer>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Return rate
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            id="rate"
                                                                            value={this.state.rate}
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            InputProps={inputFocus}
                                                                            iType={"number"}
                                                                            decimal={3}
                                                                        />


                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>


                                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={4}>
                                                                        <InputLabel className={classes.label}>
                                                                            Return Value
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={8}>
                                                                        <TextField
                                                                            disabled={true}
                                                                            id="issueValue"
                                                                            value={this.state.issueValue}
                                                                            className={classes.textField}
                                                                            onChange={this.onChangeValues}
                                                                            fullWidth={true}
                                                                            InputProps={inputFocus}
                                                                            iType={"number"}
                                                                           // decimal={3}
                                                                        />


                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>


                                                        </GridContainer>


                                                    </form>

                                                }
                                            >
                                            </Popup> : null
                                    }
                                    {this.state.updateView?<ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Stock Return No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="hdrId"
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.hdrId}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={{
                                                        autoComplete: 'off',
                                                    }}

                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>:null}
                                 {/*   <ItemGrid xs={6}>
                                        <Button color="primary" onClick={this.handleViewClick}>Search</Button>
                                    </ItemGrid>*/}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Issue No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="issueHdrId"
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.issueHdrId}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.handleIssueNoClick}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}

                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Stock Return Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        isValidDate={valid}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "date1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "date1",
                                                            value: this.state.date1,
                                                            autoComplete: 'off',
                                                            disabled:this.state.updateView
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Expenses Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <SelectboxTemp id={"indentTypeId"}
                                                               selectDisabled={true}
                                                               defValue={this.state.indentTypeId}
                                                               onSelect={this.onSelect}
                                                               stateKey="indentTypeId"
                                                               isReload={this.state.loadType}
                                                               getName={(name)=>{
                                                                   var typeId=name.split("_");
                                                                   typeId=typeId[1];
                                                                   this.setState({typeId:typeId})
                                                               }}

                                                               setIsReload={(val)=>{
                                                                   this.setState({loadType:false})
                                                               }}
                                                               url={serverApi.GET_STORE_INDENT_TYPES}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Project":"Branch"}<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={true}
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    //  getName={this.getQualityName}
                                                    stateKey="branchId"
                                                    isRefresh={this.state.loadBranch}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBranch:false})
                                                    }}
                                                    url={serverApi.SELECT_BRANCH + this.props.state.companyInfo.id}
                                                    value={this.state.branchId}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    {labelType==="2"?"Phase":"Project"}
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    selectDisabled={true}
                                                    defValue={this.state.projectId}
                                                    url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/"+0+"/branch/"+this.state.branchId}
                                                    //  staticData={this.state.ProjectOptions}
                                                    onSelect={this.onSelect}
                                                    stateKey="projectId"
                                                    value={this.state.projectId}
                                                    isRefresh={this.state.loadProject}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadProject:false})
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}>
                                        <GridContainer>

                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Internal Note
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="internalNote"
                                                    fullWidth
                                                    multiline
                                                    rowsMax="3"
                                                    height={60}
                                                    value={this.state.internalNote}
                                                    onChange={this.onChangeValues}
                                                    className={classes.textField}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>



                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {/* {console.log(this.state.invoiceList.length+" length")}*/}
                                                <LineItemTable
                                                    data={this.getDataTable()}
                                                    loading={this.state.loading}
                                                    disableActions={this.state.issueStatus==='3'||this.state.issueStatus==='4'||this.state.issueStatus==='5'||this.state.issueStatus==='6'}
                                                    columns={[
                                                        {
                                                            Header:"Item Code",
                                                            accessor:"itemGrp",
                                                            minWidth: 70,
                                                            style: {color: "#000", textAlign: "left"},
                                                        },
                                                       /* {
                                                            Header: "Department",
                                                            accessor: "department",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"},


                                                        },*/
                                                        {
                                                            Header: "Item Group",
                                                            accessor: "itemGroup",
                                                            minWidth: 150,
                                                            style: {
                                                                color: "#000",
                                                                textAlign: "left",
                                                                textOverflow: 'unset'
                                                            }
                                                        },
                                                        {
                                                            Header: "Item",
                                                            accessor: "item",
                                                            minWidth: 150,
                                                            style: {
                                                                color: "#000",
                                                                textAlign: "left",
                                                                textOverflow: 'unset'
                                                            }
                                                        },
                                                        {
                                                            Header: "Quantity",
                                                            accessor: "quantity",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        /*  {
                                                              Header: "Quantity",
                                                              accessor: "quantity",
                                                              minWidth:100,
 11211212200000000000038..
                                                              .
                                                              .5
                                                              ..5.style: {color: "#000", textAlign: "left"}
1                                                          },*/
                                                        {
                                                            Header: "Unit",
                                                            accessor: "uom",
                                                            minWidth: 50,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Return Value",
                                                            accessor: "issueValue",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "right"}
                                                        },

                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                />



                                            </ItemGrid>

                                           {/* <ItemGrid xs={12}>
                                                <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                    Item</Button>
                                            </ItemGrid>*/}
                                        </GridContainer>
                                    </ItemGrid>


{/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}
{/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
*/}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                {this.state.approveStatus ?  <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.setState({isApprove:true})
                                                                this.InvoicebasicAlert("Do You Like To Approve..!!");
                                                            }}>Approve</Button>
                                                </ItemGrid>: null
                                                }
                                                {this.state.approveStatus ? <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={()=>{
                                                                this.setState({isReject:true})
                                                                this.InvoicebasicAlert("Do You Like To Reject..!!");
                                                            }}>Reject</Button>
                                                </ItemGrid>: null
                                                }
                                                {this.state.updateView && this.state.showUpdate?

                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={()=>{this.onApproveButtonClick("")}}>Update</Button>
                                                    </ItemGrid>

                                                    : null
                                                }

                                                {this.state.updateView? <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>:null}

                                            </GridContainer>

                                        </ItemGrid>
                                    {this.state.updateView?

                                        <ItemGrid xs={12} style={{marginTop:"15px"}}>
                                            <SamplePDF
                                                invoiceType={16} printResp={this.state.printResp}/>
                                        </ItemGrid>
                                        :null}
                                </GridContainer>
                            }/>

                    </ItemGrid>:
                    <ItemGrid xs={12}>

                        <RegularCard
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>

                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Issue No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="IssueNos"
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                value={this.state.IssueNos}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    autoComplete: 'off'
                                                                }}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                            <InputLabel className={classes.label}>
                                                                From Date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDates")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id:"fromDates",
                                                                        value:this.state.fromDates,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} md={4}>
                                                            <InputLabel className={classes.label}>
                                                                To Date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} md={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "toDates")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id:"toDates",
                                                                        value:this.state.toDates,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.statuss}
                                                                id={"statuss"}
                                                                onSelect={this.onSelect}
                                                                stateKey="statuss"
                                                                url={serverApi.SEARCH_STORE_STATUS}
                                                                value={this.state.statuss}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Project":"Branch"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.branchIds}
                                                                onSelect={this.onSelect}
                                                                //  getName={this.getQualityName}
                                                                stateKey="branchIds"
                                                                // isRefresh={this.state.loadQuality}
                                                                // setIsRefresh={this.afterRefreshQuality}
                                                                url={serverApi.SELECT_BRANCH + this.props.state.companyInfo.id}
                                                                value={this.state.branchIds}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>
                                                {labelType!=="2"?  <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Customer Name
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                id={"customerIds"}
                                                                onSelect={this.onSelect}
                                                                stateKey="customerIds"
                                                                // staticData={this.state.customerList}
                                                                isRefresh={this.state.loadCustomer}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadCustomer:false})
                                                                }}
                                                                url={serverApi.GET_CUSTOMER_BY_BRANCH+this.props.state.companyInfo.id+"/"+this.state.branchIds}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>:null}

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                {labelType==="2"?"Phase":"Project"}
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.projectIds}
                                                                url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/"+this.state.customerIds+"/branch/"+this.state.branchIds}

                                                                //staticData={this.state.ProjectOptions}
                                                                onSelect={this.onSelect}
                                                                stateKey="projectIds"
                                                                value={this.state.projectIds}
                                                                isRefresh={this.state.loadItem}
                                                                setIsRefresh={this.afterRefreshItem}
                                                            />

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} sm={4} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Expenses Type
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8} >
                                                            <SelectboxTemp id={"indentTypeIds"}
                                                                // selectDisabled={this.state.updateView}
                                                                           defValue={this.state.indentTypeIds}
                                                                           onSelect={this.onSelect}
                                                                           stateKey="indentTypeIds"
                                                                           isReload={this.state.loadType}
                                                                           setIsReload={(val)=>{
                                                                               this.setState({loadType:false})
                                                                           }}
                                                                           url={serverApi.GET_STORE_INDENT_TYPES}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
                                                <ItemGrid><br/></ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            <TableComponent
                                                url={serverApi.STOCK_RETURN_LIST}
                                                searchData={this.getIssueListData()}
                                                //  staticData={this.state.tableList}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                //  onRowClick={this.handleRowclick}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                // showDelete={false}
                                                // onDeleteClick={this.onDeleteParamater}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }

            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    cssFocused: {},
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#00ADBC',
        },
        '&:after': {
            borderColor: '#D2D2D2',
        },

    },

    notchedOutline: {},
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(StockReturn)
);
