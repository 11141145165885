import React from "react";
// react component for creating dynamic tables
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  serverConfig,
  serverApi,
  getConfigValue,
  configNames,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import { Check } from "@material-ui/icons";
import Checkbox from "material-ui/Checkbox/Checkbox";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { yesOrNo } from "../TableData";
import LineItemTable from "../CustumComponents/LineItemTable";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class CustomerMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      approveStatus: false,
      name: "",
      contactPerson: "",
      contactNo: "",
      alternateContactNo: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      gstNo: "",
      panNo: "",
      cinNo: "",
      cgstNo: "",
      invoiceList: [],
      budgetArry: [],
      shippingAddress: "",
      ledgerGroup: "",
      billingStateCode: "",
      shippingStateCode: "",
      city2: "",
      state2: "",
      pincode2: "",
      gstNo2: "",
      openningBalance: "",
      itemsList: [],
      statesList: [],
      companyList: [],
      finalStatus: "N",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleAddItemBudget = this.handleAddItemBudget.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleDepartments = (resp) => {
    this.setState({ budgetHeadList: resp, loadbudgetHeadsName: true });
  };

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.GET_BY_ID_CUSTOMER_MASTER + id + "/" + getCookie(cons.CIPHER),
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp.customerHdr;
    var list = [],
      budgetList = [];
    if (o !== null) {
      if (resp.customerLineitems.length > 0) {
        resp.customerLineitems.forEach((prop, key) => {
          var obj = {
            id: key,
            mid: key,
            branchAddress: prop.address,
            city: prop.city,
            autoId: prop.customerId,
            customerLineId: prop.customerLineId,
            gstNo: prop.gstNo,
            isActive: prop.isActive,
            pinCode: prop.pincode,
            stateName: prop.state,
            stateCode: prop.stateCode,
          };
          list.push(obj);
        });
      }
      //     if ( resp.projectbudgetList!==null && resp.projectbudgetList.length > 0) {
      //     resp.projectbudgetList.forEach((prop,key)=>{
      //         var obj={
      //         id:key,
      //         "costId":prop.costId,
      //         "budgetHeadsId": prop.budgetHeadsId,
      //         "budgetHeadsName":prop.budgetHeadsName,
      //         "subHeadsId": prop.subHeadsId,
      //         "subHeadsName":prop.subHeadsName,
      //         "itemGroup":prop.itemGroup,
      //         "persftAmount": prop.persftAmount,
      //         "totalSft": prop.totalSft,
      //         "itemGroupName":prop.itemGroupName,
      //         "type":prop.type,
      //         "projectId":prop.projectId? prop.projectId+"":this.state.projectId,
      //         projectName:prop.projectName?prop.projectName:this.state.projectName,
      //         "budgetAmount":this.getTotal(prop.persftAmount,prop.totalSft),
      //     };
      //         budgetList.push(obj)
      //     });
      // }
      var branchArray = this.state.itemsList;
      branchArray.map((t, i) => {
        o.mappedBranchIds.forEach((id) => {
          if (t.bId === id) {
            t.isEnabled = true;
          }
        });
      });

      var createdOnData = o.createdOn.split("T");

      this.setState({
        updateView: true,
        isCreated: true,
        id: o.id,
        customerCode: o.customerCode,
        name: o.name,
        contactPerson: o.contactPerson,
        contactNo: o.contactNo,
        alternateContactNo: o.alternateContactNo,
        emailId: o.emailId,
        address: o.address,
        city: o.city,
        state: o.state,
        pincode: o.pincode,
        gstNo: o.gstNo,
        panNo: o.panNo,
        cinNo: o.cinNo,
        billingStateCode: o.billingStateCode,
        ledgerGroup: o.ledgerGroup,
        shippingAddress: o.shippingAddress,
        shippingStateCode: o.shippingStateCode,
        city2: o.city2,
        state2: o.state2,
        pincode2: o.pincode2,
        gstNo2: o.gstNo2,
        openningBalance: o.openningBalance,
        createdBy: o.createdBy,
        // createdOn: o.createDateTime,
        createdOn: createdOnData[0],
        itemsList: branchArray,
        internalCompanyId: o.internalCompanyId,
        finalStatus: o.finalStatus === "Y" ? "Y" : "N",
        invoiceList: list,
        budgetArry: budgetList,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onCheckBranch = (index) => (event) => {
    var arr = this.state.itemsList;
    arr[index].isEnabled = !arr[index].isEnabled;
    this.setState({
      itemsList: arr,
    });
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    //console.log("val "+value);
    if (type === "AN") {
      if (/[^0-9a-zA-Z\s]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    } else {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleSelectedLGValues = (value) => {
    this.setState({
      ledgerGroup: value,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  hideItemBasicAlert(PopupFlag) {
    if (this.state.updateItemAlert) {
      if (PopupFlag == 1) {
        this.setState({
          alert: true,
          updateItemAlert: false,
        });
      } else if (PopupFlag == 2) {
        this.setState({
          budgetalert: true,
          updateItemAlert: false,
        });
      }
    }

    if (PopupFlag == 1) {
      if (this.state.deleteItem) {
        var data = this.state.invoiceList;
        if (this.state.updateView) data[this.state.mid].isActive = 0;
        else data.splice(this.state.mid, 1);
        //  if(this.state.approveStatus)
        this.setState({
          invoiceList: data,
          deleteItem: false,
        });
      }
    } else if (PopupFlag == 2) {
      if (this.state.deleteItem) {
        var data = this.state.budgetArry;
        if (this.state.updateView) data[this.state.mid].isActive = 0;
        else data.splice(this.state.mid, 1);
        //  if(this.state.approveStatus)
        this.setState({
          budgetArry: data,
          deleteItem: false,
        });
      }
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      budgetalert: false,
      showMessagealert: false,
      loading: false,
      branchAddress: "",
      city: "",
      stateName: "",
      stateCode: "",
      cgstNo: "",
      pinCode: "",
    });
  }

  hideAlertMessage() {
    var list = this.state.itemsList.map((prop, i) => {
      prop.isEnabled = false;
      return prop;
    });
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      id: null,
      customerCode: null,
      name: "",
      contactPerson: "",
      contactNo: "",
      alternateContactNo: "",
      emailId: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      gstNo: "",
      panNo: "",
      cinNo: "",
      billingStateCode: "",
      shippingAddress: "",
      ledgerGroup: "",
      shippingStateCode: "",
      city2: "",
      state2: "",
      pincode2: "",
      gstNo2: "",
      createdBy: null,
      createdOn: null,
      openningBalance: "",
      itemsList: list,
      finalStatus: "N",
      internalCompanyId: "",
      invoiceList: [],
      budgetArry: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg, PopupFlag) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert(PopupFlag)}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }
  handleAddItem() {
    const { selectedJuteType } = this.state;
    console.log("unitConversionName " + this.state.unitConvertion);
    const {name,emailId}=this.state
    const validateFields = [
      { field: name, msg: "Name", type: 1 },
      { field: emailId, msg: "Email ID", type: 1 },
      // { field: list, msg: "Branches", type: 2 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    }
    else {
    this.setState({
      loading: true,
      alert: true,
    });
  }
  }
  handleAddItemBudget() {
    // if(this.state.budgetHeadsId !== "" && this.state.persftAmount!=="" && this.state.totalSft){
    this.setState({
      loading: true,
      budgetalert: true,
    });
    // }
    // else {
    //     this.basicAlert("Please Select Mandatory Fields..!!");
    // }
  }
  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // alert(o.qualityId);
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //  data.splice(i,1);

                        this.basicItemAlert(
                          "Do You Like To Update This Item?",
                          1
                        );
                        this.setState({
                          //alert: true,
                          updateItem: true,
                          updateItemAlert: true,
                          mid: o.id,
                          customerLineId: o.customerLineId,
                          autoId: o.autoId,
                          branchAddress: o.branchAddress,
                          cgstNo: o.gstNo,
                          pinCode: o.pinCode,
                          stateCode: o.stateCode,
                          stateName: o.stateName,
                          city: o.city,
                        });

                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          mid: o.id,
                        });
                        this.basicItemAlert(
                          "Do You Like To Delete This Item?",
                          1
                        );
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };
  getDataTableBudget = () => {
    var data = [];
    //console.log("data",JSON.stringify(this.state.invoiceList))
    if (this.state.budgetArry.length > 0)
      this.state.budgetArry.forEach((prop, key) => {
        prop.id = key;
        //if (prop.isActive === 1) {
        var obj = {
          ...prop,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.budgetArry;
                  data.find((o, i) => {
                    if (o.id === obj.id) {
                      // alert(o.qualityId);
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      //  data.splice(i,1);

                      this.basicItemAlert(
                        "Do You Like To Update This Item?",
                        2
                      );
                      this.setState({
                        //alert: true,
                        updateItem: true,
                        updateItemAlert: true,
                        id: o.id,
                        costId: o.costId,
                        budgetHeadsId: o.budgetHeadsId + "",
                        budgetHeadsName: o.budgetHeadsName,
                        subHeadsId: o.subHeadsId + "",
                        subHeadsName: o.subHeadsName,
                        itemGroup: o.itemGroup,
                        itemGroupName: o.itemGroupName,
                        persftAmount: o.persftAmount,
                        totalSft: o.totalSft,
                        type: o.type,
                        projectId: o.projectId
                          ? prop.projectId + ""
                          : this.state.projectId,
                        projectName: o.projectName
                          ? prop.projectName
                          : this.state.projectName,
                        budgetAmount: this.getTotal(o.persftAmount, o.totalSft),
                      });

                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="edit"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  var data = this.state.budgetArry;
                  data.find((o, i) => {
                    if (o.id === obj.id) {
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.setState({
                        deleteItem: true,
                        id: o.id,
                      });
                      this.basicItemAlert(
                        "Do You Like To Delete This Item?",
                        2
                      );
                      return true;
                    }
                  });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            </div>
          ),
        };

        data.push(obj);
        //}
      });
    return data;
  };

  getTotal = (totalSft, persftAmount) => {
    var total = 0;
    if (totalSft > 0 && persftAmount > 0) {
      total = (parseFloat(totalSft) * parseFloat(persftAmount)).toFixed(2);
    }
    return total;
  };
  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGroupName: selectedValue,
    });
  };

  onClickAddItemBudget() {
    var arr = this.state.budgetArry;
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    } else {
      itemIndex = this.state.budgetArry.length;
    }
    const {
      budgetHeadsId,
      subHeadsId,
      itemGroup,
      persftAmount,
      totalSft,
      type,
      budgetHeadsName,
      subHeadsName,
      costId,
      budgetAmount,
      itemGroupName,
      projectId,
      projectName,
    } = this.state;

    var addItemObj = {
      id: itemIndex,
      costId: costId,
      budgetHeadsId: budgetHeadsId,
      budgetHeadsName: budgetHeadsName,
      subHeadsId: subHeadsId,
      subHeadsName: subHeadsName,
      itemGroup: itemGroup,
      itemGroupName: itemGroupName,
      persftAmount: persftAmount,
      totalSft: totalSft,
      type: type,
      projectId: projectId,
      projectName: projectName,
      budgetAmount: this.getTotal(this.state.persftAmount, this.state.totalSft),
    };
    if (budgetHeadsId !== "" && persftAmount !== "" && totalSft) {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);
      //console.log(arr.toString());

      this.setState({
        loading: true,
        budgetArry: arr,
      });
      this.hideAlert();
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }
  onClickAddItem() {
    var arr = this.state.invoiceList;
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.mid;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    const {
      autoId,
      customerLineId,
      branchAddress,
      cgstNo,
      pinCode,
      stateCode,
      stateName,
      city,
      gstNo,
    } = this.state;

    var addItemObj = {
      mid: itemIndex,
      customerLineId: customerLineId,
      autoId: autoId,
      isActive: 1,
      branchAddress: branchAddress,
      gstNo: cgstNo,
      pinCode: pinCode,
      stateCode: stateCode,
      stateName: stateName,
      city: city,
    };

    

    // if (
    //   branchAddress !== "" &&
    //   city !== "" &&
    //   stateName !== "" &&
    //   stateCode !== "" &&
    //   pinCode !== "" &&
    //   pinCode !== null &&
    //   cgstNo !=="" &&
    //   cgstNo !==null
    // ) {
    //   if (cgstNo !== "") {
    //     var compareStr = this.state.cgstNo.slice(0, 2);
    //     if (compareStr === stateCode) {
    //       if (this.state.updateItem) {
    //         arr[itemIndex] = addItemObj;
    //       } else arr.splice(arr.length, 0, addItemObj);
    //       //console.log(arr.toString());

    //       this.setState({
    //         loading: true,
    //         invoiceList: arr,
    //       });
    //       this.hideAlert();
    //     } else {
    //       this.basicAlert("GST Number is not matching with State Code ..!!");
    //     }
    //   }
    // } else {
    //   this.basicAlert("Please Enter All Mandatory Fields..!!");
    // }

    const validateFields = [
      { field: branchAddress, msg: "Address", type: 1 },
      { field: city, msg: "City", type: 1 },
      { field: stateName, msg: "State", type: 2 },
      { field: stateCode, msg: "State Code", type: 1 },
      { field: cgstNo, msg: "GST No", type: 1 },
      { field: pinCode, msg: "Pin Code", type: 1 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    } 

    else { 
      if (cgstNo !== "") {
      var compareStr = this.state.cgstNo.slice(0, 2);
      if (compareStr === stateCode) {
        if (this.state.updateItem) {
          arr[itemIndex] = addItemObj;
        } else arr.splice(arr.length, 0, addItemObj);
        //console.log(arr.toString());

        this.setState({
          loading: true,
          invoiceList: arr,
        });
        this.hideAlert();
      } else {
        this.basicAlert("GST Number is not matching with State Code ..!!");
      }
    }}
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        id,
        invoiceList,
        customerCode,
        name,
        contactPerson,
        contactNo,
        alternateContactNo,
        emailId,
        shippingAddress,
        address,
        city,
        state,
        billingStateCode,
        pincode,
        gstNo,
        panNo,
        cinNo,
        ledgerGroup,
        shippingStateCode,
        city2,
        state2,
        pincode2,
        gstNo2,
        openningBalance,
        itemsList,
        internalCompanyId,
        finalStatus,
      } = this.state;
      var list = [];
      itemsList.map((prop, i) => {
        if (prop.isEnabled) {
          list.push(prop.bId);
        }
      });
      var invoiceList = invoiceList.map((prop, key) => {
        return {
          customerLineId: prop.customerLineId,
          customerId: prop.autoId,
          address: prop.branchAddress,
          city: prop.city,
          stateCode: prop.stateCode,
          state: prop.stateName,
          pincode: prop.pinCode,
          gstNo: prop.gstNo,
          isActive: prop.isActive,
          createdBy: getCookie(cons.USERID),
        };
      });
      const { budgetArry } = this.state;
      var budgetArry = budgetArry.map((prop, key) => {
        return {
          costId: prop.costId,
          budgetHeadsId: prop.budgetHeadsId,
          budgetHeadsName: prop.budgetHeadsName,
          subHeadsId: prop.subHeadsId,
          subHeadsName: prop.subHeadsName,
          itemGroup: prop.itemGroup,
          budgetAmount: this.getTotal(
            this.state.persftAmount,
            this.state.totalSft
          ),
          persftAmount: prop.persftAmount,
          totalSft: prop.totalSft,
          projectId: prop.projectId,
          type: prop.type,
          createdBy: getCookie(cons.USERID),
        };
      });
      var ldata = {
        id: id,
        customerCode: customerCode,
        name: name,
        contactPerson: contactPerson,
        contactNo: contactNo,
        alternateContactNo: alternateContactNo,
        emailId: emailId,
        panNo: panNo,
        cinNo: cinNo,
        ledgerGroup: ledgerGroup,
        openningBalance: openningBalance,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        mappedBranchIds: list,
        internalCompanyId: internalCompanyId,
        finalStatus: finalStatus,
        createdOn: this.state.createdOn,
      };
      var data = {
        customerHdr: ldata,
        customerLineitems: invoiceList,
        projectbudgetList: budgetArry,
      };
      const validateFields = [
        { field: name, msg: "Name", type: 1 },
        { field: emailId, msg: "Email ID", type: 2 },
        { field: list, msg: "Branches", type: 2 },
        { field: invoiceList, msg: "Line Items", type: 2 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(serverApi.UPDATE_CUSTOMER_MASTER, data, this.handleAddTax);
      }
      // if (name !== "" && list.length > 0) {
      //   if (invoiceList.length > 0) {
      //   if (panNo !== "" && panNo !== null) {
      //     var cName = this.state.name;
      //     if (panNo.substring(4, 5) === cName.substring(0, 1)) {
      //       this.handleLoading();
      //       requestList(
      //         serverApi.UPDATE_CUSTOMER_MASTER,
      //         data,
      //         this.handleAddTax
      //       );
      //     } else {
      //       this.basicAlert("Enter Correct PAN No.!!");
      //     }
      //   } else {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.UPDATE_CUSTOMER_MASTER,
      //     data,
      //     this.handleAddTax
      //   );
      //   //   }
      // } else {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // }
      // if(gstNo===""){
      //     if(gstNo2===""){
      //         if(panNo!==""){
      //             var cName=getCookie(cons.COMPANY_NAME);
      //             if(panNo.substring(4,5)===cName.substring(0,1)){
      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //             }
      //             else{
      //                 this.basicAlert("Enter Correct PAN No.!!")
      //             }
      //         }
      //         else{
      //             this.handleLoading();
      //             requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //         }
      //     }
      //     else{
      //         if(gstNo2.substring(0,2)===shippingStateCode){
      //             if(panNo!==""){
      //                 var cName=getCookie(cons.COMPANY_NAME);
      //                 if(panNo.substring(4,5)===cName.substring(0,1)){
      //                     this.handleLoading();
      //                     requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //                 }
      //                 else{
      //                     this.basicAlert("Enter Correct PAN No.!!")
      //                 }
      //             }
      //             else{

      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //         }
      //         }
      //         else{
      //             this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
      //         }

      //     }
      // }
      // else{
      //     if(gstNo.substring(0,2)===billingStateCode){
      //         if(this.state.gstNo2===""){
      //             if(panNo!==""){
      //                 var cName=getCookie(cons.COMPANY_NAME);
      //                 if(panNo.substring(4,5)===cName.substring(0,1)){
      //                     this.handleLoading();
      //                     requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //                 }
      //                 else{
      //                     this.basicAlert("Enter Correct PAN No.!!")
      //                 }
      //             }
      //             else{
      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //         }
      //         }
      //         else{
      //             if(gstNo2.substring(0,2)===shippingStateCode){
      //                 if(panNo!==""){
      //                     var cName=getCookie(cons.COMPANY_NAME);
      //                     if(panNo.substring(4,5)===cName.substring(0,1)){
      //                         this.handleLoading();
      //                         requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //                     }
      //                     else{
      //                         this.basicAlert("Enter Correct PAN No.!!")
      //                     }
      //                 }
      //                 else{
      //                         this.handleLoading();
      //                         requestList(serverApi.CUSTOMER_MASTER_UPDATE, data, this.handleAddTax)
      //                     }
      //             }
      //             else{
      //                 this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
      //             }

      //         }
      //     }
      //     else{
      //         this.basicAlert("Billing statecode be first two letters of Billing GST NO.!!")
      //     }

      // }
      // }
      // else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  onCreateButtonClick = () => {
    const date = new Date().toJSON();
    const createdOn = date.split("T");

    console.log("getting the new date", createdOn[0]);
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        invoiceList,
        name,
        contactPerson,
        contactNo,
        alternateContactNo,
        emailId,
        shippingAddress,
        address,
        city,
        state,
        billingStateCode,
        pincode,
        gstNo,
        panNo,
        cinNo,
        ledgerGroup,
        shippingStateCode,
        city2,
        state2,
        pincode2,
        gstNo2,
        openningBalance,
        itemsList,
        internalCompanyId,
        finalStatus,
      } = this.state;
      var list = [];
      itemsList.map((prop, i) => {
        if (prop.isEnabled) {
          list.push(prop.bId);
        }
      });

      var invoiceList = invoiceList.map((prop, key) => {
        return {
          address: prop.branchAddress,
          city: prop.city,
          stateCode: prop.stateCode,
          state: prop.stateName,
          pincode: prop.pinCode,
          gstNo: prop.gstNo,
          isActive: 1,
          createdBy: getCookie(cons.USERID),
        };
      });

      const { budgetArry } = this.state;
      var budgetArry = budgetArry.map((prop, key) => {
        return {
          budgetHeadsId: prop.budgetHeadsId,
          budgetHeadsName: prop.budgetHeadsName,
          subHeadsId: prop.subHeadsId,
          subHeadsName: prop.subHeadsName,
          itemGroup: prop.itemGroup,
          budgetAmount: this.getTotal(
            this.state.persftAmount,
            this.state.totalSft
          ),
          persftAmount: prop.persftAmount,
          totalSft: prop.totalSft,
          projectId: prop.projectId,
          type: prop.type,
          createdBy: getCookie(cons.USERID),
        };
      });

      var ldata = {
        name: name,
        contactPerson: contactPerson,
        contactNo: contactNo,
        alternateContactNo: alternateContactNo,
        emailId: emailId,
        panNo: panNo,
        cinNo: cinNo,
        ledgerGroup: ledgerGroup,
        openningBalance: openningBalance,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        mappedBranchIds: list,
        internalCompanyId: internalCompanyId,
        finalStatus: finalStatus,
        createdOn: createdOn[0],
      };
      var data = {
        customerHdr: ldata,
        customerLineitems: invoiceList,
        projectbudgetList: budgetArry,
      };

      const validateFields = [
        { field: name, msg: "Name", type: 1 },
        { field: emailId, msg: "Email ID", type: 1 },
        { field: list, msg: "Branches", type: 2 },
        { field: invoiceList, msg: "Line Items", type: 2 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(serverApi.CREATE_CUSTOMER_MASTER, data, this.handleAddTax);
      }

      // if (name !== "" && list.length > 0 && emailId !== "") {
      //   var pattern = new RegExp(
      //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      //   );
      //   if (pattern.test(emailId)) {
      //     if (invoiceList.length > 0) {
      // if (panNo !== "") {
      //     var cName = this.state.name;
      //     if (panNo.substring(4, 5) === cName.substring(0, 1)) {
      //        this.handleLoading();
      //        requestList(serverApi.CREATE_CUSTOMER_MASTER, data, this.handleAddTax)
      //     }
      //     else {
      //         this.basicAlert("Enter Correct PAN No.!!")
      //     }
      // }
      // else {
      //     this.handleLoading();
      //     requestList(
      //       serverApi.CREATE_CUSTOMER_MASTER,
      //       data,
      //       this.handleAddTax
      //     );
      //     // }
      //   } else {
      //     this.basicAlert("Please Add Atleast One Item..!!");
      //   }
      // } else {
      //   this.basicAlert("Please Enter valid Email..!!");
      // }

      // if(gstNo===""){
      //     if(gstNo2===""){
      //         if(panNo!==""){
      //             var cName=getCookie(cons.COMPANY_NAME);
      //             if(panNo.substring(4,5)===cName.substring(0,1)){
      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //             }
      //             else{
      //                 this.basicAlert("Enter Correct PAN No.!!")
      //             }
      //         }
      //         else{
      //             this.handleLoading();
      //             requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //         }
      //     }
      //     else{
      //         if(gstNo2.substring(0,2)===shippingStateCode){
      //             if(panNo!==""){
      //                 var cName=getCookie(cons.COMPANY_NAME);
      //                 if(panNo.substring(4,5)===cName.substring(0,1)){
      //                     this.handleLoading();
      //                     requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //                 }
      //                 else{
      //                     this.basicAlert("Enter Correct PAN No.!!")
      //                 }
      //             }
      //             else{

      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //         }
      //         }
      //         else{
      //             this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
      //         }

      //     }
      // }
      // else{
      //     if(gstNo.substring(0,2)===billingStateCode){
      //         if(this.state.gstNo2===""){
      //             if(panNo!==""){
      //                 var cName=getCookie(cons.COMPANY_NAME);
      //                 if(panNo.substring(4,5)===cName.substring(0,1)){
      //                     this.handleLoading();
      //                     requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //                 }
      //                 else{
      //                     this.basicAlert("Enter Correct PAN No.!!")
      //                 }
      //             }
      //             else{
      //                 this.handleLoading();
      //                 requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //         }
      //         }
      //         else{
      //             if(gstNo2.substring(0,2)===shippingStateCode){
      //                 if(panNo!==""){
      //                     var cName=getCookie(cons.COMPANY_NAME);
      //                     if(panNo.substring(4,5)===cName.substring(0,1)){
      //                         this.handleLoading();
      //                         requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //                     }
      //                     else{
      //                         this.basicAlert("Enter Correct PAN No.!!")
      //                     }
      //                 }
      //                 else{
      //                         this.handleLoading();
      //                         requestList(serverApi.CUSTOMER_MASTER_ADD, data, this.handleAddTax)
      //                     }
      //             }
      //             else{
      //                 this.basicAlert("Shipping statecode be first two letters of Shipping GST NO.!!")
      //             }

      //         }
      //     }
      //     else{
      //         this.basicAlert("Billing statecode be first two letters of Billing GST NO.!!")
      //     }

      // }
      // }
      // else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  handleAddTax = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  componentDidMount() {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments
    );
    requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
    requestList(serverApi.BRANCHMASTER_DATATABLE, data, this.handleBranches);
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(serverApi.GET_ALL_STATES, this.handleStates);
    if (getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) === "2") {
      data = {
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      requestList(
        serverApi.COMPANYMASTER_DATATABLE,
        data,
        this.handleCompanies
      );
    }
  }
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      if (data.length == 1) {
        this.setState({
          projectNameList: projectNameList,
          projectId: data.projectId,
          projectName: data.name,
        });
      } else {
        this.setState({
          projectNameList: projectNameList,
        });
      }
    }
  };
  handleCompanies = (resp) => {
    console.log("companujj", resp.data);
    var companyList = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        companyList.push({
          label: prop.companyName,
          value: prop.companyId,
          name: prop.companyName,
        });
      });
      this.setState({ companyList: companyList });
    }
  };
  handleStates = (resp) => {
    var list = [];
    if (resp.status === undefined) {
      resp.forEach((prop) => {
        var obj = prop;
        if (prop.value !== 0) obj.value = prop.label;
        else obj.value = "";
        list.push(obj);
      });
    }
    this.setState({
      statesList: list,
    });
  };

  handleBranches = (resp) => {
    var list = [];
    resp.data.map((prop, key) => {
      var val = {
        id: 0,
        bId: prop.id,
        bName: prop.brname,
        isEnabled: false,
      };

      // if(key>0)
      list.push(val);
    });
    this.setState({
      itemsList: list,
    });
  };

  handleLedgerGroup = (resp) => {
    const lgoptions = [];
    var ledger_data = resp.data;
    if (ledger_data !== null || ledger_data !== undefined) {
      ledger_data.forEach((r) => {
        const { name, id } = r;
        lgoptions.push({
          value: r.id,
          label: r.name,
          name: r.name,
        });
      });
    }
    this.setState({ ledger_data: lgoptions });
  };
  handleFileClick(e) {
    //var fName=e.currentTarget.id
    var input = document.createElement("input");
    input.id = "file";
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  handleFileChange(e) {
    e.preventDefault();
    //let fName=e.target.id
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG"
      ) {
        this.setState({
          file: file,
          // [fName]: file,
          itemName: file.name,
          fileType: fileType,
          //filesList: arr,
          imagePreviewUrl: reader.result,
        });
        //console.log("filename", this.state.itemName)
        //this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
    reader.readAsDataURL(file);
  }
  render() {
    const { classes } = this.props;
    const { selectedJuteType, ledger_data } = this.state;
    const options = ledger_data;
    var labelType = getConfigValue(configNames.LABELS_TYPE);

    const inputFocus = {
      autoComplete: "off",
    };
    var typeOptions = [{ label: "BOM", value: "BOM", name: "BOM" }];
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "Update" : "save"}
                      cancel_button_text={"Cancel"}
                      //juteType={itemtype}
                      content={
                        <GridContainer>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Address
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="branchAddress"
                                  value={this.state.branchAddress}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  multiline={true}
                                  rowsMax={3}
                                  height={60}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  City
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="city"
                                  value={this.state.city}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            sm={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  onSelect={this.onSelect}
                                  defValue={this.state.stateName}
                                  getName={(name) => {
                                    var val = name.split("^");
                                    this.setState({
                                      stateCode:
                                        val[1] !== undefined ? val[1] : "",
                                    });
                                  }}
                                  stateKey="stateName"
                                  staticData={this.state.statesList}
                                  // url={serverApi.GET_ALL_STATES}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  State Code
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  disabled={true}
                                  id="stateCode"
                                  value={this.state.stateCode}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  GST No
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={5}>
                                <TextField
                                  id="cgstNo"
                                  value={this.state.cgstNo}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                />
                              </ItemGrid>
                              {this.state.cgstNo !== "" &&
                              this.state.cgstNo.length > 0 ? (
                                <ItemGrid sm={3}>
                                  <Button
                                    color="info"
                                    size="sm"
                                    id="gstFile"
                                    onClick={this.handleFileClick}
                                  >
                                    UPLOAD
                                  </Button>
                                </ItemGrid>
                              ) : null}
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Pin Code
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    *
                                  </span>
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="pinCode"
                                  value={this.state.pinCode}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                  InputProps={inputFocus}
                                  iType={"number"}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      }
                    />
                  ) : null}
                  {this.state.budgetalert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItemBudget()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Budget Head
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.budgetHeadsId}
                                        id={"budgetHeadsId"}
                                        onSelect={this.onSelect}
                                        stateKey="budgetHeadsId"
                                        staticData={this.state.budgetHeadList}
                                        // url={serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id}
                                        getName={(name) => {
                                          this.setState({
                                            budgetHeadsName: name,
                                          });
                                        }}
                                        isRefresh={
                                          this.state.loadbudgetHeadsName
                                        }
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadbudgetHeadsName: false,
                                          });
                                        }}
                                        value={this.state.budgetHeadsId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Sub-Head
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.subHeadsId}
                                        id={"subHeadsId"}
                                        onSelect={this.onSelect}
                                        stateKey="subHeadsId"
                                        staticData={this.state.budgetHeadList}
                                        // url={serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id}
                                        getName={(name) => {
                                          this.setState({ subHeadsName: name });
                                        }}
                                        isRefresh={
                                          this.state.loadbudgetHeadsName
                                        }
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadbudgetHeadsName: false,
                                          });
                                        }}
                                        value={this.state.subHeadsId}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  {this.state.projectNameList &&
                                  this.state.projectNameList.length > 1 ? (
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Project
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          defValue={this.state.projectId}
                                          id={"projectId"}
                                          onSelect={this.onSelect}
                                          stateKey="projectId"
                                          staticData={
                                            this.state.projectNameList
                                          }
                                          getName={(name) => {
                                            this.setState({
                                              projectName: name,
                                            });
                                          }}
                                          value={this.state.projectId}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  ) : null}
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Type
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        // selectDisabled={this.state.disableDept}
                                        defValue={this.state.type}
                                        id={"budgetType"}
                                        onSelect={this.onSelect}
                                        staticData={typeOptions}
                                        getName={(name) => {
                                          this.setState({ type: name });
                                        }}
                                        stateKey="type"
                                        value={this.state.type}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Item Group
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.itemGroup}
                                        id={"itemGroup"}
                                        onSelect={this.onSelect}
                                        getName={this.getItemGroupName}
                                        //   isRefresh={this.state.loadItemGroup}
                                        stateKey="itemGroup"
                                        //     setIsRefresh={this.afterRefreshItemGrp}
                                        url={
                                          serverApi.SEARCH_ITEM_GROUPS +
                                          this.props.state.companyInfo.id +
                                          "/" +
                                          getCookie(cons.CIPHER)
                                        }
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Cost Per Sqft
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="persftAmount"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.persftAmount}
                                        fullWidth={true}
                                        iType="number"
                                        // variant="outlined"
                                        // InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Super built-up area
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="totalSft"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.totalSft}
                                        fullWidth={true}
                                        iType="number"
                                        // variant="outlined"
                                        // InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Total Amount
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="totalAmount"
                                        disabled={true}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.getTotal(
                                          this.state.persftAmount,
                                          this.state.totalSft
                                        )}
                                        fullWidth={true}
                                        // variant="outlined"
                                        // InputProps={inputFocus}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Customer Code
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            diabled={true}
                            id="customerCode"
                            value={this.state.customerCode}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="name"
                          value={this.state.name}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Contact Person
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="contactPerson"
                          value={this.state.contactPerson}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Contact No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="contactNo"
                          value={this.state.contactNo}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Alternate Contact No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="alternateContactNo"
                          value={this.state.alternateContactNo}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Email ID
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="emailId"
                          value={this.state.emailId}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "3" ? "CR No" : "PAN No"}
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="panNo"
                          value={this.state.panNo}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                      {this.state.panNo !== "" ? (
                        //   &&
                        //   this.state.panNo.length > 0
                        <ItemGrid sm={2}>
                          <Button
                            color="info"
                            size="sm"
                            id="panFile"
                            onClick={this.handleFileClick}
                          >
                            UPLOAD
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          CIN No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="cinNo"
                          value={this.state.cinNo}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                      {this.state.cinNo !== "" ? (
                        //   &&
                        //   this.state.cinNo.length > 0
                        <ItemGrid sm={2}>
                          <Button
                            color="info"
                            size="sm"
                            id="cinFile"
                            onClick={this.handleFileClick}
                          >
                            UPLOAD
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ledger Group
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <SelectboxTemp
                          defValue={this.state.ledgerGroup}
                          id={"ledgerGroup"}
                          getName={this.onSelect}
                          onSelect={this.onSelect}
                          stateKey="ledgerGroup"
                          staticData={options}
                          //  url={serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO + this.state.godownValue + "/" + getCookie(cons.CIPHER)}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) ===
                  "2" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Internal Company
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <SelectboxTemp
                            defValue={this.state.internalCompanyId}
                            id={"internalCompanyId"}
                            getName={this.onSelect}
                            onSelect={this.onSelect}
                            stateKey="internalCompanyId"
                            staticData={this.state.companyList}
                            //  url={serverApi.GET_STOCK_DETAILS_BY_GODOWN_NO + this.state.godownValue + "/" + getCookie(cons.CIPHER)}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {getConfigValue(configNames.CUSTOMER_INTERNAL_MAPPING) ===
                  "2" ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Final Status
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <SelectboxTemp
                            defValue={this.state.finalStatus}
                            onSelect={this.onSelect}
                            stateKey="finalStatus"
                            staticData={yesOrNo}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Opening Balance
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <TextField
                          id="openningBalance"
                          value={this.state.openningBalance}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType={"number"}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={2}>
                        <InputLabel className={classes.label}>
                          Branches:
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} md={10}>
                        <GridContainer>
                          {this.state.itemsList.map((prop, key) => {
                            return (
                              <ItemGrid xs={12} sm={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      tabIndex={-1}
                                      value={prop.isEnabled ? 1 : 0}
                                      checked={prop.isEnabled ? 1 : 0}
                                      onChange={this.onCheckBranch(key)}
                                      // onClick={() => this.handleToggle(prop)}
                                      checkedIcon={
                                        <Check
                                          className={classes.checkedIcon}
                                        />
                                      }
                                      icon={
                                        <Check
                                          className={classes.uncheckedIcon}
                                        />
                                      }
                                      classes={{
                                        checked: classes.checked,
                                      }}
                                      style={{ marginLeft: 10 }}
                                    />
                                  }
                                  classes={{
                                    label: classes.menulabel,
                                  }}
                                  label={prop.bName}
                                />
                              </ItemGrid>
                            );
                          })}
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <LineItemTable
                      data={this.getDataTable()}
                      loading={this.state.loading}
                      columns={[
                        {
                          Header: "Branch Address",
                          accessor: "branchAddress",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "State",
                          accessor: "stateName",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "State Code",
                          accessor: "stateCode",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "City",
                          accessor: "city",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Pin Code",
                          accessor: "pinCode",
                          minWidth: 100,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "GST Number",
                          accessor: "gstNo",
                          minWidth: 150,
                          style: { color: "#000", textAlign: "left" },
                        },
                        {
                          Header: "Actions",
                          minWidth: 100,
                          accessor: "actions",
                          style: { color: "#000", textAlign: "center" },
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <Button
                      color={"info"}
                      right={true}
                      onClick={this.handleAddItem}
                    >
                      Add Item
                    </Button>
                  </ItemGrid>
                  {/* <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                <LineItemTable
                                                    data={this.getDataTableBudget()}
                                                    loading={this.state.loading}
                                                    //disableActions={this.state.indentStatus==='3'||this.state.indentStatus==='4'||this.state.indentStatus==='5'||this.state.indentStatus==='6'}
                                                    columns={[
                                                        {
                                                            Header: "Budget Head",
                                                            accessor: "budgetHeadsName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }

                                                        },
                                                        {
                                                            Header: "Budget Sub Head",
                                                            accessor: "subHeadsName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }

                                                        },
                                                        {
                                                            Header: "Project",
                                                            accessor: "projectName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }

                                                        },
                                                        {
                                                            Header: "Type",
                                                            accessor: "type",
                                                            minWidth: 75,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Item group",
                                                            accessor: "itemGroupName",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Cost per sft",
                                                            accessor: "persftAmount",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left" }
                                                        },
                                                        {
                                                            Header: "Total sft",
                                                            accessor: "totalSft",
                                                            minWidth: 100,
                                                            style: { color: "#000", textAlign: "left", textOverflow: 'unset' }
                                                        },
                                                        {
                                                            Header: "Total Amount",
                                                            accessor: "budgetAmount",
                                                            width: 150,
                                                            style: { color: "#000", textAlign: "right" }
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: { color: "#000", textAlign: "center" },
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                />
                                                {this.state.updateView ?
                                                    <ItemGrid xs={12}>
                                                        <GridContainer>
                                                            <ItemGrid xs={6}>
                                                                <span style={{
                                                                    fontStyle: "italic",
                                                                    fontWeight: 400,
                                                                    color: "#003F66"
                                                                }}></span>
                                                                <span style={{
                                                                    fontSize: "12px",
                                                                    color: "#003F66"
                                                                }}>{this.state.statusDesc}<br /></span>
                                                            </ItemGrid>
                                                            <ItemGrid xs={6}>
                                                                <Button color={"info"} right={true} onClick={this.handleAddItemBudget}>Add Budget Item</Button>
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    </ItemGrid>

                                                    :
                                                    <ItemGrid xs={12}>
                                                        <Button color={"info"} right={true} onClick={this.handleAddItemBudget}>Add Budget Item</Button>
                                                    </ItemGrid>
                                                }
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
 */}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.CUSTOMER_MASTER}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(CustomerMaster)
);
