import React, { Component } from 'react'
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import cons, { serverApi } from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../../server/server";
import { getCookie } from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import { VIEW } from "../../../ui-actions/UiActions";
import { getMenuPermission } from "../../CustumComponents/PermissionAlert";
import TableComponent from "../../CustumComponents/TableComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from 'material-ui/Radio/Radio';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { menuTypes } from "../../TableData";

export class MRReport extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showMessagealert:null,
            basicAlert: null,
            email: ""
        }
    }

    onChangeValues = (event) => {
        var value = event.target.value
        this.setState({
            [event.target.id]: value
        });

    }
    handleGoClick = () => {
        var email = this.state.email
        var data = {
            "email": email
        };
        if (email !== "") {
            requestList(serverApi.GET_MR_BUYERS_LIST, data, this.handledata)
        }
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }

    }
    handledata = (resp) => {
        if (resp.status) {
            this.showMessage(resp.message)
        } else {
            this.showMessage(resp.message)
        }
    };
    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red" }}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false,
            
        });

    }
    showMessage = (msg) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        this.hideAlertMessage();
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    hideAlertMessage() {
       this.setState({
           isCreated: false,
           approveStatus: false,
           showMessagealert: false,
           updateView: false,
           alert: false,
           basicAlert: false,
           email:""
    
       });

   }

    render() {
        const { classes } = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
                <ItemGrid xs={12}>
                    <RegularCard
                        content={
                            <div>
                                <GridContainer>
                                    {this.state.showMessagealert}
                                    {this.state.basicAlert}
                                    <ItemGrid xs={10}>
                                        <GridContainer >
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Email<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            //disabled={true}
                                                            id="email"
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            InputProps={inputFocus}
                                                            value={this.state.email}
                                                            defValue={this.state.email}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <Button color="primary" onClick={() => { this.handleGoClick() }}>Go</Button>
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            </div>
                        }
                    />
                </ItemGrid>
            </GridContainer>

        )
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
export default withStyles(extendedFormsStyle)(MRReport);
