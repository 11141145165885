import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi, serverVars} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {getDataFromUrl, requestList} from "../../../server/server";
import moment from "moment";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import { connect } from "react-redux";


class JuteMRWise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            selectMukam: "",
            selectUnitConversion: "",
            VehicleType: "",
            mukamId: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "indentNo",
            approveStatus: false,

            indentStatus: "3",
            canChangeUnit: true,
            canChangeMukam: true,
            updateView: false,
            statusDesc: "",
            date1: moment().format('DD-MM-YYYY'),
            indentNo: "",
            year: moment().format('YYYY'),
            unitConvertion: "",
            unitConvertionId: "",
            unitConversionName: "",
            indentQuantity: "",
            indentQuantityTon: "",
            indentBales: "",
            createdBy: "",
            createdOn: "",
            selectedVehicleType: "",
            vehicleQuantiy: "",
            selectedMukam: "",
            alert: false,
            selectedJuteQuality: "",
            selectedQualityName: "",
            selectedTax: "",
            invoiceList: [],
            selectedJuteType: "",
            hideJuteType: false,
            url: "",
            selectedJuteName: "",
            selectedIndentQuantity: "",
            selectedDeptId: "",
            selectedGrpId: "",
            selectedUnit: "",
            supplierId:'',
            godownNo:''


        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
    }

    componentDidMount(){
        this.getReportsTable();
    }
    getReportsTable=()=>{
        var data={
            "taskCode": 1020,
            "payloadSize": 5,
            "data": {
                "fromDate": this.state.fromDate,
                "toDate": this.state.todate,
                "juteType":this.state.selectedJuteType,
                "supplier":"",
                "godownNo":this.state.godownNo


            },
            "cipher": getCookie(cons.CIPHER),
            "userId": getCookie(cons.USERID),
            "companyId": this.props.state.companyInfo.id
        };
        requestList(serverApi.REPORTS_JUTE_WITH_VALUE,data,this.handleReports)
    };
    handleReports = (resp) =>{
        // console.log("reports",JSON.stringify(resp));
        var dataTable=resp;
        if  (dataTable !== null){
            var columns= dataTable.data.column.map((prop,key)=>{
                var obj=prop;
                if(obj.Header==="MR Date"){
                    obj.Footer="Grand Total"
                }
                if(obj.Header==="Bales"){
                    obj.Footer= parseFloat(dataTable.totalValues.totBales).toFixed(2)
                }
                if(obj.Header==="Issued Bales"){
                    obj.Footer= parseFloat(dataTable.totalValues.totIssuedBales).toFixed(2)
                }
                if(obj.Header==="Sold Bales"){
                    obj.Footer= parseFloat(dataTable.totalValues.totSoldBales).toFixed(2)
                }
                if(obj.Header==="Bales Stock"){
                    obj.Footer= parseFloat(dataTable.totalValues.totBalesStock).toFixed(2)
                }
                if(obj.Header==="Drums"){
                    obj.Footer= parseFloat(dataTable.totalValues.totDrums).toFixed(2)
                }
                if(obj.Header==="Drums Issued"){
                    obj.Footer= parseFloat(dataTable.totalValues.totIssuedDrums).toFixed(2)
                }
                if(obj.Header==="Drums Sold"){
                    obj.Footer= parseFloat(dataTable.totalValues.totSoldDrums).toFixed(2)
                }
                if(obj.Header==="Drums Stock"){
                    obj.Footer= parseFloat(dataTable.totalValues.totDrumsStock).toFixed(2)
                }
                if(obj.Header==="Receipt Wt.(QNT)"){
                    obj.Footer= parseFloat(dataTable.totalValues.totReceiptWt).toFixed(2)
                }
                if(obj.Header==="Issued Wt.(QNT)"){
                    obj.Footer= parseFloat(dataTable.totalValues.totIssuedWt).toFixed(2)
                }
                if(obj.Header==="Sold Wt.(QNT)"){
                    obj.Footer= parseFloat(dataTable.totalValues.totSoldWt).toFixed(2)
                }
                if(obj.Header==="Stock(QNT)"){
                    obj.Footer= parseFloat(dataTable.totalValues.totStock).toFixed(2)
                }

                obj.filterable=false;
                obj.sortable=false;
                obj.minWidth=120;
                obj.maxWidth=250;
                //  obj.maxWidth=50;
                // delete obj.minWidth;
                delete obj.maxWidth;
                return obj;
            });
        }
        dataTable.data.column=columns;
        console.log("columns",JSON.stringify(dataTable.data.column));

        if(resp.status){
            this.setState({
                dataTable:dataTable,
                isRefresh: true,
            })
        }
        this.handleLoading();

    };





    handleSeacrh = () => {
        var loading = false;
        if(!this.state.isRefresh)
            this.getReportsTable();
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + id + "/" + getCookie(cons.USERID) + "/getIndentDetails", this.handleViewClickResp)
    };
    handleViewClickResp = (resp) => {
        var header = resp.indentHeader;
        var list = resp.indentItems;
        var indentlist = list.map((prop, key) => {
            return (
                {
                    id: key,
                    juteType: parseInt(prop.itemId),
                    juteName: prop.itemIdDesc,
                    quality: prop.qualityCode,
                    qualityName: prop.qualityCodeDesc,
                    quantity: prop.indentQuantity,
                    unit: prop.unitId,
                    deptId: prop.deptId,
                    grpId: prop.itemGroupId,
                    stockValue: prop.stock,
                    indentstatus: prop.indentStatus,
                    indentItemId: prop.indentLineItemId,
                    quantityType: prop.noOfBales,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    if (this.state.approveStatus)
                                        data.find((o, i) => {
                                            if (o.id === key) {
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                // console.log("no of bales",o.quantityType);
                                                this.setState({
                                                    id: o.id,
                                                    updateItemAlert: true,
                                                    updateItem: true,
                                                    indentItemId: o.indentItemId,
                                                    selectedJuteType: o.juteType,
                                                    selectedJuteName: o.juteName,
                                                    selectedJuteQuality: o.quality,
                                                    selectedQualityName: o.qualityName,
                                                    quantity: o.quantityType,
                                                    selectedUnit: o.unit,
                                                    selectedDeptId: o.deptId,
                                                    selectedGrpId: o.grpId,
                                                    stock: o.stockValue,
                                                    // alert:true,

                                                });
                                                return true;
                                            }
                                        });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit/>
                            </IconButton>

                            {/* use this button to remove the data row */}
                            <IconButton
                                onClick={() => {

                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {

                                        if (o.id === key) {
                                            if (this.state.approveStatus) {

                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                });

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                //  data.splice(i,1);
                                            }
                                            return true;
                                        }
                                    });

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>{" "}
                        </div>

                    )
                }
            )
        });
        //console.log("indentWeight "+header.indentWeight);
        if (header !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                canChangeMukam: true,
                canChangeUnit: true,
                indentStatus: header.status,
                approveStatus: header.approveButton,
                indentNo: header.id,
                statusDesc: header.statusDesc,
                unitConvertion: this.getDefaultUnitConvertion(header.unitConversion),
                // unitConvertionId:"0",
                indentQuantity: header.indentWeight,
                //  sumOFQuantities:header.indentWeight,
                year: header.finnacialYear,
                createdBy: header.submitter,
                createdOn: header.createDateDesc,
                date1: header.indentDateDesc,
                selectedMukam: header.mukamNo,
                selectedVehicleType: header.vehicleTypeId,
                vehicleQuantity: header.vehicleQuantity,
                quantity: "",
                stock: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                invoiceList: indentlist,
            });

        }


    };
    getDefaultUnitConvertion = (name) => {
        var val = "", label = "", data = "";
        if (name !== null) {
            if (name === 'LOOSE') {
                val = 1;
                label = "LOOSE";
            } else {
                val = 2;
                label = 'BALE';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    }
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "indentType": "J",
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate
            , "mukam": this.state.mukamId,
            "status": this.state.status
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date1;
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    }


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return Math.round(parseFloat((this.state.indentQuantity * 100) / 150));
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };

    getStock = () => {
        console.log("sttt", this.state.stock);
        return this.state.stock;
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        if (event.target.id === "indentQuantity") {
            //   console.log("val "+this.state.indentQuantity);
            if (value > 0 && value <= 100) {
                this.setState({
                    selectedVehicleType: 3,
                    vehicleQuantity: 1,
                })

            } else if (value > 100 && value <= 120) {
                this.setState({
                    selectedVehicleType: 2,
                    vehicleQuantity: 1,
                })

            } else if (value > 120 && value <= 160) {
                this.setState({
                    selectedVehicleType: 1,
                    vehicleQuantity: 1,
                })

            } else if (value > 160 && value <= 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: 1,
                })

            } else if (value > 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: Math.round(value / 200),
                })
            } else if (value === '') {
                this.setState({
                    selectedVehicleType: "",
                    vehicleQuantity: "",
                })
            }
        }


        if (event.target.id === "vehicleQuantity") {

            if (value !== null && value !== "") {
                if (this.state.selectedVehicleType !== "")
                    this.setState({
                        indentQuantity: parseInt(value) * parseInt(this.state.selectedIndentQuantity)
                    });
                else {
                    value = "";
                    this.basicAlert("Please Select Vehicle Type..!!")
                }
            }
        }
        if (event.target.id === "stock") {
            //   console.log("val "+this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if (event.target.id === 'quantity' && this.state.updateItem) {

        }
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/

        if (stateKey === 'selectedJuteType') {
            this.setState({
                loadQuality: true
            });
        }
        if (stateKey === 'selectedMukam' && this.state.invoiceList.length > 0 && !this.state.canChangeMukam) {
            this.unitConvertionAlert("Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!")
        }
        else
            this.setState({
                [stateKey]: selectedValue
            });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            selectedGrpId: values[1],
            selectedUnit: values[2],
            selectedDeptId: values[3],
        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            selectedJuteType: "",
            selectedJuteName: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            quantity: "",
            stock: ""
        });
    }

    hideAlertMessage() {
        this.setState({
            canChangeMukam: true,
            canChangeUnit: true,
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            indentNo: "",
            unitConvertion: "",
            unitConvertionId: 0,
            indentQuantity: "",
            indentQuantityTon: "",
            indentBales: "",
            createdBy: "",
            createdOn: "",
            selectedVehicleType: "",
            vehicleQuantity: "",
            quantity: "",
            stock: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            selectedMukam: "",
            selectedTax: "",
            invoiceList: [],
            selectedJuteType: "",
            selectedJuteName: "",
            selectedIndentQuantity: "",
            sumOFQuantities: 0,
            selectedDeptId: "",
            selectedGrpId: "",
            selectedUnit: "",
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }


    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{
                        display: "block",
                        marginTop: "-100px",
                        marginLeft: "-80px",
                        fontSize: "8px",
                        color: "black"
                    }}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onRejectButtonClick = () => {
        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                id: prop.indentItemId,
                indentHeaderId: this.state.indentNo,
                itemId: prop.juteType,
                itemGroupId: prop.grpId,
                qualityCode: prop.quality,
                deptId: prop.deptId,
                stock: prop.stockValue,
                indentQuantity: prop.quantity,
                unitId: prop.unit,
                noOfBales: prop.quantityType
            })
        });

        var data = {
            "indentHeader": {
                "id": this.state.indentNo,
                "type": "J",
                "mukam": this.state.selectedMukam,
                "vehicleTypeId": this.state.selectedVehicleType,
                "vehicleQuantity": this.state.vehicleQuantiy,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(),
                "finnacialYear": this.state.year,
                "unitConversion": this.state.unitConvertionName,
                "status": "4"

            },
            "indentItems": invoiceList
        };
        this.handleLoading();
        requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent)
    };

    onApproveButtonClick = () => {
        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                id: prop.indentItemId,
                indentHeaderId: this.state.indentNo,
                itemId: prop.juteType,
                itemGroupId: prop.grpId,
                qualityCode: prop.quality,
                deptId: prop.deptId,
                stock: prop.stockValue,
                indentQuantity: prop.quantity,
                unitId: prop.unit,
                noOfBales: prop.quantityType,
            })
        });

        var data = {
            "indentHeader": {
                "id": this.state.indentNo,
                "type": "J",
                "mukam": this.state.selectedMukam,
                "vehicleTypeId": this.state.selectedVehicleType,
                "vehicleQuantity": this.state.vehicleQuantiy,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(),
                "finnacialYear": this.state.year,
                "unitConversion": this.state.unitConvertionName,
                "status": this.state.indentStatus

            },
            "indentItems": invoiceList
        };
        if (this.state.selectedMukam !== '0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType !== "" && this.state.invoiceList.length >= 1) {
            this.handleLoading();
            requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent)
        } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
        }
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }

    };

    onCreateButtonClick = () => {
        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                itemId: prop.juteType,
                dtlItemDesc: prop.juteName,
                itemGroupId: prop.grpId,
                qualityCode: prop.quality,
                deptId: prop.deptId,
                stock: prop.stockValue,
                indentQuantity: prop.quantity,
                unitId: prop.unit,
                noOfBales: prop.quantityType
            })
        });

        var data = {
            "indentHeader": {
                "type": "J",
                "mukam": this.state.selectedMukam,
                "vehicleTypeId": this.state.selectedVehicleType,
                "vehicleQuantity": this.state.vehicleQuantity,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(),
                "finnacialYear": this.state.year,
                "unitConversion": this.state.unitConvertionName,


            },
            "indentItems": invoiceList
        };
        if (this.state.selectedMukam !== '0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType !== "" && this.state.invoiceList.length >= 1) {
            this.handleLoading();
            requestList(serverApi.ADD_INDENT, data, this.handleAddIndent)
        } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
        }
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem() {
        var arr = this.state.invoiceList;
        var sumOfQty = 0;
        var itemIndex = 0, quantity = 0, hasItem = false;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            arr[itemIndex].quantityType = this.state.quantity
        } else {
            itemIndex = this.state.invoiceList.length
        }

        //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
        arr.map(q => {
            sumOfQty = sumOfQty + parseInt(q.quantityType);
            if (q.quality === this.state.selectedJuteQuality && !this.state.updateItem)
                hasItem = true;
        });
        if (!this.state.updateItem)
            sumOfQty = sumOfQty + parseInt(this.state.quantity);


        if (this.state.unitConvertion.value === 1) {
            quantity = (parseInt(this.state.indentQuantity) * this.state.quantity) / 100
        } else {
            quantity = Math.round(parseFloat((this.state.quantity * 150) / 100).toFixed(2));
        }

        console.log("selectedUnit " + this.state.selectedUnit);
        var addItemObj = {
            id: itemIndex,
            indentItemId: this.state.indentItemId,
            juteType: this.state.selectedJuteType,
            juteName: this.state.selectedJuteName,
            quality: this.state.selectedJuteQuality,
            qualityName: this.state.selectedQualityName,
            quantityType: this.state.quantity,
            quantity: quantity,
            unit: this.state.selectedUnit,
            deptId: this.state.selectedDeptId,
            grpId: this.state.selectedGrpId,
            stockValue: this.state.stock,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id: o.id,
                                        indentItemId: o.indentItemId,
                                        updateItemAlert: true,
                                        updateItem: true,
                                        selectedJuteType: o.juteType,
                                        selectedJuteName: o.juteName,
                                        selectedJuteQuality: o.quality,
                                        selectedQualityName: o.qualityName,
                                        quantity: o.quantityType,
                                        selectedUnit: o.unit,
                                        selectedDeptId: o.deptId,
                                        selectedGrpId: o.grpId,
                                        stock: o.stockValue,
                                        // alert:true,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({
                                        deleteItem: true,
                                        id: o.id,
                                    });

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                            this.setState({
                                invoiceList: data,
                                selectedJuteType: "",
                                selectedJuteName: "",
                                selectedJuteQuality: "",
                                selectedQualityName: "",
                                quantity: "",
                                selectedUnit: "",
                                selectedDeptId: "",
                                selectedGrpId: "",
                                stock: "",
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>{" "}
                </div>

            )
        };

        if (!hasItem) {
            if (this.state.selectedJuteType !== "" && this.state.selectedJuteQuality !== "" && this.state.quantity !== "" && this.state.vehicleQuantity !== "") {

                if (this.getConvertedQty() !== "") {

                    if (sumOfQty <= parseInt(this.getConvertedQty())) {
                        if (this.state.updateItem) {
                            arr[itemIndex] = addItemObj;
                        } else
                            arr.splice(arr.length, 0, addItemObj);
                        console.log(arr.toString());

                        this.setState({
                            hideJuteType: false,
                            loading: true,
                            sumOFQuantities: sumOfQty,
                            canChangeMukam: false,
                            invoiceList: arr,
                        });
                        this.hideAlert();
                    } else {
                        this.basicAlert("Quantity Should Not Exceed Converted Quantity..!!");
                        // alert("Quantity should be less than or equal to Indent quantity")
                    }

                } else {
                    if (sumOfQty <= 100) {
                        if (this.state.updateItem) {
                            arr[itemIndex] = addItemObj;
                        } else
                            arr.splice(arr.length, 0, addItemObj);
                        //console.log(arr.toString());

                        this.setState({
                            loading: true,
                            invoiceList: arr,
                            sumOFQuantities: sumOfQty,
                        });
                        this.hideAlert();
                    } else {
                        this.basicAlert("Quantity % can not exceed 100..!!");
                    }
                }
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        } else {
            this.basicAlert("Item Already Saved For This Quality. Please Select Another One..!!");
        }
    }

    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };
    getQuantityType = () => {
        var val = this.state.unitConvertion.value;
        if (val === 2) {
            return "BALE"
        } else {
            return "%";
        }
    };
    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedQualityName: values[0],
            stock: values[1]
        });
        console.log("stock " + this.state.stock);

    };

    handleAddItem() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
        console.log("unitConversionName " + this.state.unitConvertion);
        //  var id="";
        console.log(this.state);
        if (this.state.selectedMukam !== '0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType !== "")
            this.setState({
                loading: true,
                alert: true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    getVehicleQlty = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedIndentQuantity: values[1],
            indentQuantity: values[1]
        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
    handleSelectedValues = (defValue) => {

        if (this.state.invoiceList.length > 0 && !this.state.canChangeUnit && this.state.unitConversion !== defValue) {
            this.unitConvertionAlert("Changing of Unit Conversion will remove all the line items. Click yes if you want to continue..!!")
        } else
            this.setState(
                {
                    unitConvertion: defValue,
                    unitConvertionId: defValue.value,
                    unitConvertionName: defValue.label,
                    canChangeUnit: false

                });
    }

    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
        const options = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'LOOSE', name: 'LOOSE'},
            {value: 2, label: 'BALE', name: 'BALE'}
        ];
        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert ?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={"Add"}
                                                cancel_button_text={"Cancel"}
                                                juteType={selectedJuteType}
                                                content={
                                                    <form>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} md={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Jute Type<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    id={"selectedJuteType"}
                                                                                    defValue={selectedJuteType}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getName}
                                                                                    stateKey="selectedJuteType"
                                                                                    url={serverApi.DATATABLE_SERVICE_URL + this.state.selectedMukam + "/getAllItemByMukam"}
                                                                                    value={selectedJuteType}
                                                                                />

                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <br/>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quality<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                {/* {(selectedJuteType >0) ?*/}
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.selectedJuteQuality}
                                                                                    id={"selectedJuteQuality"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getQualityName}
                                                                                    stateKey="selectedJuteQuality"
                                                                                    isRefresh={this.state.loadQuality}
                                                                                    setIsRefresh={this.afterRefreshQuality}
                                                                                    url={serverApi.DATATABLE_SERVICE_URL + selectedJuteType + "/getQualityByItem"}
                                                                                    value={this.state.selectedJuteQuality}
                                                                                />{/*:<legend style={{color:"red",fontSize:"12px"}}>Select Jute Type To Display Qualities</legend>
                                                                            }*/}


                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                </GridContainer>

                                                            </ItemGrid>
                                                            <ItemGrid xs={12} md={12}>
                                                                <br/>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Quantity<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <TextField
                                                                                    id="quantity"
                                                                                    className={classes.textField}
                                                                                    onChange={this.onChangeValues}
                                                                                    value={this.state.quantity}
                                                                                    fullWidth={true}
                                                                                    InputProps={{
                                                                                        autoComplete: 'off',
                                                                                        endAdornment: <InputAdornment
                                                                                            position="start">
                                                                                            <span style={{
                                                                                                color: "#00ABDC",
                                                                                                fontSize: "16px",
                                                                                                fontWeight: "400",
                                                                                                paddingTop: "25px"
                                                                                            }}>{this.getQuantityType()}</span>
                                                                                        </InputAdornment>,
                                                                                    }}

                                                                                />

                                                                            </ItemGrid>

                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>

                                                        </GridContainer>


                                                    </form>

                                                }
                                            >
                                            </Popup> : null
                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "date1")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "date1",
                                                            value: this.state.date1,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Indent No
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="indentNo"
                                                        className={classes.textField}
                                                        value={this.state.indentNo}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Unit Conversion<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <Select
                                                    isDisabled={this.state.updateView}
                                                    defValue={this.state.selectedJuteType}
                                                    name={"unitConvertion"}
                                                    value={this.state.unitConvertion}
                                                    onChange={this.handleSelectedValues}
                                                    options={options}
                                                    className={classes.selectFormControl}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: '4px',
                                                        // height:"20px",
                                                        colors: {
                                                            ...theme.colors,
                                                            //  primary25: '#00ABDC',
                                                            primary: '#00ABDC',
                                                            //   primary50:"#00ABDC"
                                                        },
                                                    })}
                                                    styles={{
                                                        control: (base, state) => ({
                                                            ...base,
                                                            height: '30px',
                                                            'min-height': '30px',
                                                            marginBottom: "10px"
                                                        }),

                                                    }}
                                                />


                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Mukam<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"selectedMukam"}
                                                                    selectDisabled={this.state.updateView}
                                                                    defValue={this.state.selectedMukam}
                                                                    onSelect={this.onSelect}
                                                                    isChangeValue={this.state.canChangeMukam}
                                                                    stateKey="selectedMukam"
                                                                    url={serverApi.SEARCH_MUKAM+this.props.state.companyInfo.id}
                                                                    value={this.state.selectedMukam}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Indent Quantity<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={4}>
                                                <TextField
                                                    // disabled={true}
                                                    id="indentQuantity"
                                                    value={this.state.indentQuantity}
                                                    //  className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"

                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment position="start">
                                                            <span style={{
                                                                color: "#00ABDC",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                paddingTop: "25px"
                                                            }}>QTL</span>
                                                        </InputAdornment>,
                                                    }}
                                                />

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={4}>
                                                <TextField
                                                    //  disabled={true}
                                                    id="indentQuantityTon"
                                                    value={this.getIndentQtyTon()}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {height: 30, color: "#000"},
                                                        endAdornment: <InputAdornment position="start">
                                                            <span style={{
                                                                color: "#00ABDC",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                paddingTop: "25px"
                                                            }}>Ton</span>
                                                        </InputAdornment>,
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Converted Quantity
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="indentBales"
                                                    disabled={true}
                                                    value={this.getConvertedQty()}
                                                    className={classes.textField}
                                                    //  onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {height: 30, color: "#000"},
                                                        endAdornment: <InputAdornment position="start">
                                                            <span style={{
                                                                color: "#00ABDC",
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                paddingTop: "25px"
                                                            }}>BALES</span>
                                                        </InputAdornment>,
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12}>
                                                {console.log(this.state.invoiceList.length + " length")}
                                                <ReactTable
                                                    data={this.state.invoiceList}
                                                    filterable={false}
                                                    resizable={false}
                                                    loading={this.state.loading}
                                                    getTableProps={() => {
                                                        return {
                                                            style: {
                                                                border: "2px solid #00ABDC",
                                                                borderRadius: "4px"
                                                            }
                                                        }
                                                    }}

                                                    getTheadProps={() => {
                                                        return {
                                                            style: {
                                                                borderBottom: "2px solid #00ABDC",
                                                                //height:45
                                                                //lineHeight:"1.3"
                                                            }
                                                        }
                                                    }}
                                                    getTheadThProps={() => {
                                                        return {
                                                            style: {
                                                                borderRight: "1px solid #999999",
                                                                textAlign: "center",
                                                                whiteSpace: "pre-wrap",
                                                                lineHeight: 1.3,
                                                            }
                                                        }
                                                    }}
                                                    style={{color: "#00ABDC", textAlign: "center", overflowY: "auto"}}
                                                    columns={[
                                                        {
                                                            Header: "Jute Type",
                                                            accessor: "juteName",
                                                            minWidth: 60,
                                                            style: {color: "#000", textAlign: "left"}

                                                        },
                                                        {
                                                            Header: "Quality",
                                                            accessor: "qualityName",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: this.getQuantityType(),
                                                            accessor: "quantityType",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Quantity",
                                                            accessor: "quantity",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Unit",
                                                            accessor: "unit",
                                                            width: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Stock.",
                                                            accessor: "stockValue",
                                                            minWidth: 100,
                                                            style: {color: "#000", textAlign: "left"}
                                                        },
                                                        {
                                                            Header: "Actions",
                                                            accessor: "actions",
                                                            style: {color: "#000", textAlign: "center"},
                                                            sortable: false,
                                                            filterable: false,
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                    pageSize={this.getInvoiceTablePageSize()}
                                                    //  defaultPageSize={5}
                                                    sortable={false}
                                                    getNoDataProps={() => {
                                                        return {
                                                            style: {
                                                                display: "none"
                                                            }

                                                        };
                                                    }}
                                                    className="-striped -highlight"
                                                />
                                            </ItemGrid>
                                            {this.state.updateView ?
                                                <ItemGrid xs={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={6}>
                                                            <span style={{
                                                                fontStyle: "italic",
                                                                fontWeight: 400,
                                                                color: "#003F66"
                                                            }}>Status:</span>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: "#003F66"
                                                            }}>{this.state.statusDesc}<br/></span>
                                                            <span style={{
                                                                fontStyle: "italic",
                                                                fontWeight: 400,
                                                                color: "red"
                                                            }}>Note:</span>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: "red",
                                                                fontStyle: "italic"
                                                            }}>{"Unit & Mukam can't modified"}</span>
                                                        </ItemGrid>
                                                        {this.state.approveStatus ?
                                                            <ItemGrid xs={6}>
                                                                <Button color={"info"} right={true}
                                                                        onClick={this.handleAddItem}>Add Item</Button>
                                                            </ItemGrid> : null}
                                                    </GridContainer>
                                                </ItemGrid>

                                                :
                                                <ItemGrid xs={12}>
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add
                                                        Item</Button>
                                                </ItemGrid>

                                            }
                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>

                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.approveStatus ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onApproveButtonClick}>Approve</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={this.onRejectButtonClick}>Reject</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }

                                                                inputProps={{
                                                                    id: "fromDate",
                                                                    value: this.state.fromDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "todate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "toDate",
                                                                    value: this.state.todate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>




                                        </GridContainer>
                                    </ItemGrid>

                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Godown No
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.warehouseNo}
                                                                id={"godownNo"}
                                                                onSelect={this.onSelect}
                                                                stateKey="godownNo"
                                                                postData={{"companyId":this.props.state.companyInfo.id,"type":"J","branchId":""}}
                                                                url={serverApi.SELECT_GODOWN_LIST}
                                                                value={this.state.godownNo}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                {/* <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Supplier
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.supplierId}
                                                                id={"supplierId"}
                                                                onSelect={this.onSelect}
                                                                stateKey="supplierId"
                                                                url={serverApi.SEARCH_MR_SUPPLIER+"/"+this.props.state.companyInfo.id}
                                                                value={this.state.supplierId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid> */}
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Jute Type
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp id={"selectedJuteType"}

                                                                           defValue={this.state.selectedJuteType}
                                                                           onSelect={this.onSelect}
                                                                           getName={this.getName}
                                                                           stateKey="selectedJuteType"
                                                                           isRefresh={this.state.loadJuteType}
                                                                           setIsRefresh={this.afterRefreshJuteType}
                                                                           url={serverVars.MASTER_API + "itemmaster/getItemsByItemGroupId/999/" + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                                           value={this.state.selectedJuteType}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>




                                            </GridContainer>
                                        </ItemGrid>

                                        {/* <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Jute Type
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp id={"selectedJuteType"}

                                                                           defValue={this.state.selectedJuteType}
                                                                           onSelect={this.onSelect}
                                                                           getName={this.getName}
                                                                           stateKey="selectedJuteType"
                                                                           isRefresh={this.state.loadJuteType}
                                                                           setIsRefresh={this.afterRefreshJuteType}
                                                                           url={serverVars.MASTER_API + "itemmaster/getItemsByItemGroupId/999/" + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                                           value={this.state.selectedJuteType}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>





                                            </GridContainer>
                                        </ItemGrid> */}


                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                {/*<ItemGrid xs={12}>*/}
                                                {/*<IconButton*/}
                                                {/*onClick={this.handleCreate}*/}
                                                {/*color="success" right={true}>*/}
                                                {/*<ExpandMoreIcon/>*/}
                                                {/*</IconButton>*/}

                                                {/*</ItemGrid>*/}
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {this.state.dataTable!==null&& this.state.dataTable!==undefined?
                                                <ReportsTableComponent
                                                    // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                                                    staticData={this.state.dataTable.data}
                                                    //  searchData={this.getData()}
                                                    isRefresh={this.state.isRefresh}
                                                    handleSearch={this.handleSeacrh.bind(this)}
                                                    // isLoading={this.state.isRefresh}
                                                    //  onActionClick={this.handleViewClick}
                                                    fileName={"jute_Godownwise_report"}
                                                    itemViewType={this.state.itemViewType}/> :null

                                            }
                                            {/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>
*/}
                                            {/* <SamplePDF printDocument={this.printDocument}/> */}


                                            {/*<TableComponent*/}
                                                {/*url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}*/}
                                                {/*searchData={this.getData()}*/}
                                                {/*isRefresh={this.state.isRefresh}*/}
                                                {/*handleSearch={this.handleSeacrh.bind(this)}*/}
                                                {/*isLoading={this.state.isRefresh}*/}
                                                {/*onActionClick={this.handleViewClick}*/}
                                                {/*itemViewType={this.state.itemViewType}*/}
                                                {/*printDoc={this.printDocument}*/}
                                                {/*actionType={VIEW}/>*/}


                                        </ItemGrid>
                                        {/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
                                        {/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
    printDocument=()=> {
        var data = {
            "taskCode": 1021,
            "payloadSize": 5,
            "data": {
                "fromDate": this.state.fromDate,
                "toDate": this.state.todate,
                "juteType":this.state.selectedJuteType,
                "supplier":this.state.supplierId,
                "godownNo":this.state.godownNo
            },
             "cipher": getCookie(cons.CIPHER),
             "userId": getCookie(cons.USERID),
            "companyId": this.props.state.companyInfo.id
        };
        requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handlePrintReports)
    };
    handlePrintReports = (resp) =>{
        // console.log("reports",JSON.stringify(resp));
        if(resp.status){
            // this.setState({
            //     dataTable:resp,
            //     isRefresh: true,
            // })
            var modules=resp.data.dataList;
            var grandTotals=resp.data.totals;
            var companyName=resp.data.companyName;
        }
        console.log('modules',modules);
        var pageNo=1;

        //var modules=[]

        var config={printHeaders:true,autoSize:true,margins:{left:0,top:0,bottom:0,width:0},fontSize:10};
        const doc = new Jspdf('l', 'pt', 'l');
        doc.setFontSize(14);
        doc.text(300, 40, companyName);
        doc.setFontSize(10);
        var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/16);

        doc.text(700, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
        // doc.setFont("courier");
        // doc.setFontType("normal");
        doc.setFontSize(10);
        doc.text(350, 60, 'Report No.:[JUTE/05]');
        doc.text(320, 80, 'Jute MR Wise Report From  '+ this.state.fromDate +' to '+ this.state.todate +'');
        doc.text(650, 80, 'Quantity in : Quintal');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.cellInitialize();
        doc.cell(25, 100,40,30, 'MR Id','','center');
        doc.cell(141, 100,35,30, 'PO No','','center');
        var MRDate = doc.splitTextToSize('MR Date', 38);
        doc.cell(172, 100,40,30, MRDate,'','center');
        doc.cell(203, 100,50,30, 'Supplier','','center');
        doc.cell(203, 100,40,30, 'Mukam','','center');
        var Gate_Entry_No = doc.splitTextToSize('Gate Entry No', 38);
        doc.cell(203, 100,40,30, Gate_Entry_No,'','center');
        doc.cell(203, 100,40,30, 'Quality','','center');
        doc.cell(203, 100,40,30, 'Item','','center');
        var WareHouseNo = doc.splitTextToSize('W.H No', 30);
        doc.cell(203, 100,30,30, WareHouseNo,'','center');
        doc.cell(203, 100,35,30, 'Rate','','center');
        doc.cell(203, 100,40,30, 'Weight','','center');
        var BaleOrLoose = doc.splitTextToSize('BaleOrLoose', 35);
        doc.cell(203, 100,40,30, BaleOrLoose,'','center');
        var MillWeight = doc.splitTextToSize('Mill Weight', 38);
        doc.cell(203, 100,40,30, MillWeight,'','center');
        doc.cell(203, 100,50,30, 'Amount','','center');
        var ClaimQuality = doc.splitTextToSize('Claim Quality', 38);
        doc.cell(203, 100,40,30, ClaimQuality,'','center');
        var ClaimDust = doc.splitTextToSize('Claim Dust', 38);
        doc.cell(203, 100,40,30, ClaimDust,'','center');
        var QCMoisture = doc.splitTextToSize('QC Moisture', 38);
        doc.cell(203, 100,40,30, QCMoisture,'','center');
        var AllowableMoisture = doc.splitTextToSize('Allow. Moisture', 42);
        doc.cell(203, 100,40,30, AllowableMoisture,'','center');
        var Shortage = doc.splitTextToSize('Shortage', 50);
        doc.cell(203, 100,40,30, Shortage,'','center');
        var ApprovedWeight = doc.splitTextToSize('Approved Weight', 50);
        doc.cell(203, 100,45,30, ApprovedWeight,'','center');



        // doc.cellInitialize();

        var cellWidths=['',40, 35, 40, 50,40 ,40,40, 40, 30,35 ,40, 40,40 ,50, 40,40 ,40 ,40,40,45];
        var cellTextaligns=['','left', 'right', 'right', 'right','right' ,'right','right' ,'right', 'right', 'right','right' ,'right','right' ,'right',,'right' ,'right','right' ,'right','right'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k=95;
            var i=0;
            modules.map((prop,key)=>{
                k=k+35;
                i=i+1;
                doc.cellInitialize();
                if(i===12){
                    k=130;
                    i=0;
                    pageNo=pageNo+1;
                    var today =moment().format('DD/MM/YYYY H:mm:ss');
                    var date=moment().format('DD/MM/YYYY');
                    var name=getCookie(cons.USERID);
                    var newdat = " Printed on: "+ today;
                    var printBy = " Printed By: "+ name;
                    doc.text(40,580,newdat);
                    doc.text(650,580,printBy);
                    doc.addPage('a4','l');
                    doc.setFontSize(14);
                    doc.text(300, 40, ' ');
                    doc.setFontSize(10);
                    var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/16);

                    doc.text(700, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
                    // doc.setFont("courier");
                    // doc.setFontType("normal");
                    doc.setFontSize(10);
                    doc.text(350, 60, 'Report No.:[JUTE/05]');
                    doc.text(320, 80, 'Jute MR Wise Report From  '+ this.state.fromDate +' to '+ this.state.todate +'');
                    doc.text(650, 80, 'Quantity in : Quintal');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.cellInitialize();
                    doc.cell(25, 100,40,30, 'MR Id','','center');
                    doc.cell(141, 100,35,30, 'PO No','','center');
                    var MRDate = doc.splitTextToSize('MR Date', 38);
                    doc.cell(172, 100,40,30, MRDate,'','center');
                    doc.cell(203, 100,50,30, 'Supplier','','center');
                    doc.cell(203, 100,40,30, 'Mukam','','center');
                    var Gate_Entry_No = doc.splitTextToSize('Gate Entry No', 38);
                    doc.cell(203, 100,40,30, Gate_Entry_No,'','center');
                    doc.cell(203, 100,40,30, 'Quality','','center');
                    doc.cell(203, 100,40,30, 'Item','','center');
                    var WareHouseNo = doc.splitTextToSize('W.H No', 30);
                    doc.cell(203, 100,30,30, WareHouseNo,'','center');
                    doc.cell(203, 100,35,30, 'Rate','','center');
                    doc.cell(203, 100,40,30, 'Weight','','center');
                    var BaleOrLoose = doc.splitTextToSize('BaleOrLoose', 35);
                    doc.cell(203, 100,40,30, BaleOrLoose,'','center');
                    var MillWeight = doc.splitTextToSize('Mill Weight', 38);
                    doc.cell(203, 100,40,30, MillWeight,'','center');
                    doc.cell(203, 100,50,30, 'Amount','','center');
                    var ClaimQuality = doc.splitTextToSize('Claim Quality', 38);
                    doc.cell(203, 100,40,30, ClaimQuality,'','center');
                    var ClaimDust = doc.splitTextToSize('Claim Dust', 38);
                    doc.cell(203, 100,40,30, ClaimDust,'','center');
                    var QCMoisture = doc.splitTextToSize('QC Moisture', 38);
                    doc.cell(203, 100,40,30, QCMoisture,'','center');
                    var AllowableMoisture = doc.splitTextToSize('Allow. Moisture', 42);
                    doc.cell(203, 100,40,30, AllowableMoisture,'','center');
                    var Shortage = doc.splitTextToSize('Shortage', 50);
                    doc.cell(203, 100,40,30, Shortage,'','center');
                    var ApprovedWeight = doc.splitTextToSize('Approved Weight', 50);
                    doc.cell(203, 100,45,30, ApprovedWeight,'','center');

                    doc.setFontSize(7);
                    doc.setFontType("normal");
                }
                var a=0;
                return(
                    Object.keys(prop).map((key, index) => {
                        a=a+1;
                        var myItem = prop[key] +' ';
                        myItem = doc.splitTextToSize(myItem,35);
                        return (
                            doc.cell(25,k,cellWidths[a],35,myItem,2,cellTextaligns[a])
                        )
                    })

                )

            });

        }
        // var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"5282.04","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

        doc.cellInitialize();
        doc.setFontType("bold");
          doc.cell(25, k+35,75,30, 'Grand Total','','C');
        // // //Opening
         doc.cell(203, k+35,40,30, ' ','','right');
          doc.cell(110, k+35,50,30, ' ','','right');
           doc.cell(141, k+35,40,30, ' ','','right');
           doc.cell(172, k+35,40,30,' ','','right');
           doc.cell(203, k+35,40,30,  ' ','','right');
           doc.cell(248, k+35,40,30, ' ','','right');
          doc.cell(248, k+35,30,30,  ' ','','right');
        doc.cell(248, k+35,35,30,  ' ','','right');
         doc.cell(203, k+35,40,30, grandTotals.weightTot+ ' ','','right');
         doc.cell(248, k+35,40,30,  ' ','','right');
        doc.cell(248, k+35,40,30, grandTotals.millWeightTot + ' ','','right');
         doc.cell(203, k+35,50,30, grandTotals.amountTot + ' ','','right');
         doc.cell(248, k+35,40,30,  ' ','','right');
         doc.cell(248, k+35,40,30,  ' ','','right');
        doc.cell(248, k+35,40,30,  ' ','','right');
        doc.cell(203, k+35,40,30,  ' ','','right');
        doc.cell(248, k+35,40,30, grandTotals.shortageTot + ' ','','right');
        doc.cell(248, k+35,45,30, grandTotals.approvedWeightTot + ' ','','right');




        doc.text(40, k+130, 'Dept. Incharge');
        doc.text(650, k+130, 'Authorised Signature');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today =moment().format('DD/MM/YYYY H:mm:ss');
        var name=getCookie(cons.USERID);
        var newdat = " Printed on: "+ today;
        var printBy = " Printed By: "+ name;
        doc.text(40,k+170,newdat);
        doc.text(650,k+170,printBy);
        doc.save('JuteMRWise.pdf');


    }

}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(JuteMRWise)
);
