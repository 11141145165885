import React from "react";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {requestList} from "../../server/server";
import {getCookie, removeCookie} from "../../helper/Cookies";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import {PaymentSource} from "../TableData";
import { connect } from 'react-redux';


class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            billPassNo: "",
            ledgerOptions: [],
            file: null,
            isRefresh: false,
            isLoading: false,
            approveStatus: false,
            updateView: false,
            statusDesc: "",
            year: getCookie(cons.ACADEMIC_YEAR),
            alert: false,


        };

    }

    componentDidMount() {
        var data1 = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.BANK_ACCOUNTS_MASTER, data1, this.handleBankAccounts);
        requestList(serverApi.LEDGER_MASTER, data1, this.handleLedgerList);
    }

    handleLedgerList = (resp) => {
        var options = [];
        resp.data.forEach(prop => {
            var obj = {label: prop.ledgerName, value: prop.id, name: prop.ledgerName};
            options.push(obj)
        });
        this.setState({
            ledgerOptions: options,
            reloadLedger: true
        })
    };


    handleBankAccounts = (resp) => {
        var options = [];
        resp.data.forEach(prop => {
            var obj = {label: prop.accountantName, value: prop.id, name: prop.accountantName};
            options.push(obj)
        });
        this.setState({
            accountOptions: options,
            reloadAccounts: true
        })

    };


    render() {
        const {classes, ledger, account, date1, description, amount, divSize, bankRefnumber, paymentSource, onSelect, onChange, onTimeChange, isDisabled, reloadLedger, setIsRefresh} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        return (

            <ItemGrid xs={12}>
                <GridContainer>
                    <ItemGrid xs={12} md={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Ledger
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                    id={"ledger"}
                                    selectDisabled={isDisabled}
                                    defValue={ledger !== undefined ? ledger : null}
                                    onSelect={onSelect}
                                    isRefresh={reloadLedger}
                                    setIsRefresh={(val) => {
                                        setIsRefresh("reloadLedger")
                                    }}
                                    stateKey="ledger"
                                    staticData={this.state.ledgerOptions}
                                    value={ledger !== undefined ? ledger : null}
                                />
                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Account
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                    //selectDisabled={this.state.updateView}
                                    id={"account"}
                                    defValue={account}
                                    onSelect={onSelect}
                                    stateKey="account"
                                    staticData={this.state.accountOptions}
                                    isRefresh={this.state.reloadAccounts}
                                    setIsRefresh={(val) => {
                                        this.setState({reloadAccounts: val})
                                    }}
                                    // url={serverApi.SELECT_PARENT_LEDGER_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                    // value={this.props.account}
                                />
                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>


                    <ItemGrid xs={12} sm={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Date<span
                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <FormControl variant="" fullWidth>
                                    <Datetime
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        onChange={(dateObj) => {
                                            onTimeChange(dateObj, "date1")
                                        }}
                                        height={"15px"}
                                        formControlProps={
                                            {height: "15px", inlineBlock: "none"}
                                        }
                                        inputProps={{
                                            id: "date1",
                                            value: date1,
                                            autoComplete: 'off'
                                        }}

                                    />
                                </FormControl>
                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Description
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <TextField
                                    id="description"
                                    disabled={isDisabled}
                                    defValue={description}
                                    value={description}
                                    className={classes.textField}
                                    onChange={onChange}
                                    fullWidth={true}
                                    variant="outlined"
                                    /* multiline={true}
                                     height={50}
                                     rowsMax={2}*/
                                />
                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Payment Source<span style={{color: "red", fontSize: "12px"}}>*</span>
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                    id={"paymentSource"}
                                    defValue={paymentSource}
                                    onSelect={onSelect}
                                    stateKey="paymentSource"
                                    staticData={PaymentSource}
                                    // url={serverApi.SELECT_PARENT_LEDGER_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                    // value={this.state.paymentSource}
                                />
                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={divSize} style={{marginTop: "10px"}}>
                        <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                    Cheque/Utr No
                                </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                                <TextField
                                    // disabled={true}
                                    defValue={bankRefnumber}
                                    id="bankRefnumber"
                                    className={classes.textField}
                                    onChange={onChange}
                                    fullWidth={true}
                                    InputProps={inputFocus}/>

                            </ItemGrid>
                        </GridContainer>
                    </ItemGrid>

                </GridContainer>
            </ItemGrid>

        );
    }


}


const extendedFormsStyle = {
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(Payments)
);





