import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {Link} from 'react-router-dom';
import HeaderCard from "../../components/Cards/HeaderCard";
import {getDataFromUrl, requestList} from "../../server/server";
import {serverApi, getHostIp} from "../../helper/Consts";
import SweetAlert from "react-bootstrap-sweetalert";
import Popup from "../../components/Popup/PopUp";
import MULTI_TENANT_DATA from "../../helper/Consts";
import LoginCard from "../../components/Cards/LoginCard";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            confirmPassword: '',
            password: '',
            basicAlert: null,
        };
        this.onChangeValues = this.onChangeValues.bind(this);
    }


    OnResetPassword = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');
        const userId=query.get('user');
        const {password, confirmPassword} = this.state;
       // console.log("userId",userId);

        if (password === confirmPassword&&password!=="") {
            var data = {"md5": token, "newPassword": password, "forgotPassword": true, "userId": ""};
            if(token==='1'){
                 data = {"newPassword": password, "forgotPassword": false, "userId": userId};
            }
            requestList(serverApi.MODIFY_PASSWORD, data, this.handleforgotResp);

        } else {
            this.basicAlert("Password does not match..!");

        }
    };
    onLogin = () => {
        this.props.history.push('/');

    };

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        this.setState({
            showMessagealert: null
        });
    }


    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.onLogin();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    handleforgotResp = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
    };

    onChangeValues(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        this.setState({
            isError: false
        });
    }


    render() {
        const {classes} = this.props;
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');
        return (

            <div className={classes.content}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        <ItemGrid xs={12} sm={8} md={8}>
                            <HeaderCard
                                cardTitle={token==="1"?"Change Password":"Reset Password"}
                                headerColor="blue"
                                content={

                                    <form>
                                        <br/>
                                        <CustomInput
                                            labelText="Password"
                                            iType={"password"}
                                            id="password"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={this.onChangeValues}
                                        />
                                        <CustomInput
                                            labelText="Confirm Password"
                                            iType={"password"}
                                            id="confirmPassword"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={this.onChangeValues}
                                        />
                                        {/*<Link to="/" className="btn btn-link" >*/}
                                        <span style={{color:"red"}}>Note*: Kindly change your default password to password of your choice to proceed further using of ERP.</span>
                                  {/*  </Link>*/}
                                        {/*<br/>*/}
                                        <GridContainer justify="center">
                                            <ItemGrid>
                                                <Button color="primary" onClick={this.OnResetPassword}>Submit</Button>
                                            </ItemGrid>
                                            <ItemGrid>
                                                <Button color="danger" onClick={this.onLogin}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>
                                        <br/>
                                    </form>

                                }
                            />
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,

};
const formStyle = {
    ...sweetAlertStyle,
    ...loginPageStyle
}

export default withStyles(formStyle)(ResetPassword);