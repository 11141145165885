import React from "react";
import IconCard from "../../components/Cards/IconCard";
import alertIcon from "@material-ui/icons/AddAlert";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import ItemGrid from "../../components/Grid/ItemGrid";

import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import RegularCard from "../Cards/RegularCard";
import IconCustomButton from "../CustomButtons/IconButton";
import ExpandMoreIcon from "@material-ui/icons/Close";

class PopupSupport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {content, title, closePopup, onClickSave, cancel_button_text, isShowSave, isShowCancel, save_btn_txt} = this.props;
        return (
            <div style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
                zIndex: 5000,
                backgroundColor: "rgba(0,0,0, 0.5)"
            }}>
                <div style={{
                    position: "fixed",
                    top: "20%",
                    left: "70%",
                    right: "5%",
                    bottom: "10%",
                    margin: "auto",
                    backGround: "white",
                    // zIndex: 5500
                }}>
                    <GridContainer justify="center" style={{backgroundColor: "#00ABDC",borderRadius:'4px'}}>
                        <span  style={{marginLeft:15,marginTop:10,color:'#FFFFFF'}}>
                            Please fill out the form below and we will get back to you as soon as possible.
                        </span>

                        <ItemGrid xs={12}>
                            <RegularCard
                                content={
                                    <GridContainer justify={"center"}>
                                        <ItemGrid xs={12}>
                                            {content}
                                        </ItemGrid>

                                        <ItemGrid xs={12}  style={{marginTop: "10px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    {isShowSave ?
                                                        <Button style={{width:100}} color="primary"
                                                                onClick={onClickSave}>{save_btn_txt}</Button>
                                                        :
                                                        <Button style={{width:100}} color="primary" onClick={onClickSave}>Save</Button>

                                                    }
                                                </ItemGrid>
{/*
                                                <ItemGrid>
                                                    {isShowCancel ?
                                                        <Button color="danger" style={{width:100}}
                                                                onClick={closePopup}>{cancel_button_text}</Button>
                                                        : null
                                                    }
                                                </ItemGrid>
*/}
                                            </GridContainer>

                                        </ItemGrid>
                                    </GridContainer>


                                }
                            />
                        </ItemGrid>
                        <ItemGrid xs={12} style={{marginBottom:10}}>
                            <IconCustomButton
                                onClick={closePopup}
                                color={"white"}
                                 right={true}>
                                <ExpandMoreIcon/>

                            </IconCustomButton>
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );

    }
}

const extendedCalendarStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    button:{
        width:100
    }
};

export default withStyles(extendedCalendarStyle)(PopupSupport);