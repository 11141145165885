import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import Button from "material-ui/Button";
import Hidden from "material-ui/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import {KeyboardArrowLeft,KeyboardArrowRight} from "@material-ui/icons";

/*import Arrow from "../../assets/icons/Arrow.png";
import ArrowBack from "../../assets/icons/ArrowBack.png";*/

// core components
import HeaderLinks from "./HeaderLinks";
import CustomIconButton from "components/CustomButtons/IconButton.jsx";
import CustomButton from '../../components/CustomButtons/Button'

import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import GridContainer from "../Grid/GridContainer";
import ItemGrid from "../Grid/ItemGrid1";
import {getCookie, setCookie} from "../../helper/Cookies";
import cons from "../../helper/Consts";
import SelectboxTemp from "../../views/CustumComponents/SelectboxTemp";

function Header({...props}) {
    var settings,name,menuId,path;
    props.routes.map((prop, key) => {
        if (prop.collapse) {

            if(prop.menuId===97){
                settings=prop;
            }
            prop.views.map((prop, key) => {

                if (prop.path === props.location.pathname) {
                    name = prop.name;
                    menuId=prop.menuId;
                    path=prop.path;
                    setCookie(cons.MENU_NAME,name)

                }

                prop.subViews.map((prop, key) => {

                    if(prop.path === props.location.pathname) {
                        name = prop.name;
                        menuId=prop.menuId;
                        path=prop.path;
                        setCookie(cons.MENU_NAME,name)
                    }
                    return null;
                });

                return null;
            });
        }

        if (prop.path === props.location.pathname) {
            name = prop.name;
            menuId=prop.menuId;
            path=prop.path;
            setCookie(cons.MENU_NAME,name)
        }
        return null;
    });

/*
    function makeBrand() {
        var name;
        props.routes.map((prop, key) => {
            if (prop.collapse) {

                if(prop.menuId===97){
                    settings=prop;
                }
                prop.views.map((prop, key) => {

                    prop.subViews.map((prop, key) => {

                        if(prop.path === props.location.pathname) {
                            name = prop.name;
                        }
                        return null;
                    });

                    if (prop.path === props.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            }

            if (prop.path === props.location.pathname) {
                name = prop.name;
            }
            return null;
        });
        return name;
    }
*/

    const {classes, color, rtlActive, handleLogout,handleProfile,handleChanagePassword,handleSettings,refreshDashboard,handleTallySync} = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown>
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                                <KeyboardArrowRight className={classes.sidebarMiniIcon}/>
                                {/*<img src={Arrow} alt={"open"}/>*/}
                            </CustomIconButton>
                        ) : (
                            <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                                <KeyboardArrowLeft className={classes.sidebarMiniIcon}/>
                                {/*<img src={ArrowBack} alt={"close"}/>*/}
                            </CustomIconButton>
                        )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {menuId!==97&&name!=='Change Password'&&menuId!==98 && menuId!==99 && menuId!==100&& menuId!==222 && menuId!==198&& menuId!==227?
                    <Button  className={classes.title}>
                        {name}
                    </Button>:null}


                    <GridContainer>
                        <ItemGrid xs={2}>
                            {name==='Change Password'||menuId===97 || menuId===98 || menuId===99 || menuId===100||  menuId===222 ||  menuId===198||menuId===227?

                                <span className={classes.title1}  onClick={() => {
                                    props.history.push("/ChangePassword");
                                }}>
                                    Change Password
                                </span>:null
                            }
                        </ItemGrid>
                        {name==='Change Password'||menuId===97 || menuId===98 || menuId===99 || menuId===100||  menuId===222  ||  menuId===198||menuId===227?

                            settings!==undefined?settings.views.map((prop,key)=>{
                                if(props.location.pathname.indexOf(prop.path) > -1 ){
                                         console.log("side menus ",prop.menuId);
                                    setCookie(cons.MENU_ID, prop.menuId);
                                    setCookie(cons.MENU_NAME, prop.name);
                                }
                                if( prop.name!=="Tally Sync")
                                return(
                                <ItemGrid xs={2} >
                                    <span className={name===prop.name?classes.title3:classes.title1}  onClick={() => {
                                        props.history.push(prop.path);
                                    }} >
                                        {prop.name}
                                    </span>

                                </ItemGrid>
                            )
                            }):null:null}

                    </GridContainer>

                </div>

                    <Hidden smDown implementation="css">
                        <HeaderLinks rtlActive={rtlActive} handleLogout={handleLogout} handleProfile={handleProfile} handleChanagePassword={handleChanagePassword} handleSettings={handleSettings} refreshDashboard={refreshDashboard} handleTallySync={handleTallySync} />
                    </Hidden>
                <Hidden mdUp>
                    <IconButton
                        className={classes.appResponsive}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}>
                        <Menu/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,

};

export default withStyles(headerStyle)(Header);
