import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import cons, { serverApi} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../../server/server";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import {VIEW} from "../../../ui-actions/UiActions";
import {getMenuPermission} from "../../CustumComponents/PermissionAlert";
import TableComponent from "../../CustumComponents/TableComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from 'material-ui/Radio/Radio';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import {menuTypes} from "../../TableData";
import { connect } from 'react-redux';

class Menus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "issueObj",
            updateView:false,
            menuTypeList:0,
            reqSource:0,
            selectedValue:1,
            menuId:0, menuName:"", menuPath:"",parentId:0,subParentId:0,menuType:1,wide:"N"
    };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount(){
        var menusList= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":false,"Header":"Menu Id","accessor":"menuId","minWidth":30,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"Menu Name","accessor":"menuName","minWidth":25,"style":{"textAlign":"left"},"sortable":false},{"filterable":false,"Header":"Menu Path","accessor":"menuPath","minWidth":30,"style":{"textAlign":"left"},"sortable":false}]};

        this.setState({
            MenusList:menusList,
            // isRefresh: true,
        });
        this.getMenusList(this.state.menuTypeList)
        // this.getReportsTable();
        // this.getWorkersList();
        // this.leaveAvailabilityCheck();
    }


    getMenusList=(selectedValue)=>{
        this.handleLoading();
        getDataFromUrl(serverApi.GET_MENUS_BY_ROLE+selectedValue+"/0",this.handleMenus);
    };
    handleMenus = (resp) =>{
            var menusList= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":true,"Header":"Menu Id","accessor":"menuId","minWidth":30,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Menu Name","accessor":"menuName","minWidth":25,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Menu Path","accessor":"menuPath","minWidth":30,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Parent Menu","accessor":"parentName","minWidth":30,"style":{"textAlign":"left"},"sortable":true}]};
      var list=[];
        resp.forEach(prop=>{
            var obj={
                "menuId": prop.menuId,
                "menuName": prop.menuName,
                "menuPath": prop.menuPath,
                "parentId":0,
                "selectedValue":1,
                "parentName":"",
                "subParentId":0,
                "menuType":prop.menuType
            };
            list.push(obj);
            prop.subMenus.forEach(sub=>{
                var obj1={
                    "menuId": sub.menuId,
                    "menuName": sub.menuName,
                    "menuPath": sub.menuPath,
                    "parentId":prop.menuId,
                    "parentName":prop.menuName,
                    "subParentId":0,
                    "selectedValue":2,
                    "menuType":sub.menuType

                };
                list.push(obj1);
                sub.subSubMenus.forEach(subSub=>{
                    var obj2={
                        "menuId": subSub.menuId,
                        "menuName": subSub.menuName,
                        "menuPath": subSub.menuPath,
                        "parentId":prop.menuId,
                        "subParentId":sub.menuId,
                        "parentName":prop.menuName+"/"+sub.menuName,
                        "selectedValue":3,
                        "menuType":subSub.menuType


                    };
                    list.push(obj2)
                })
            })

        });
        if(resp.status===undefined){
            menusList.data=list
        }


        this.setState({
            MenusList:menusList,
            isRefresh:true
        });
        this.handleLoading();

        //}
        //  this.handleLoading();

    };



    handleSeacrh = () => {
        var loading = false;
        if(!this.state.isRefresh){
            this.getMenusList(this.state.menuTypeList)
        }

        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }


    handleCreate = () => {
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        this.setState({
            isCreated: !this.state.isCreated,

        });
    };

    timeChanged = (dateObj, id) => {

        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('YYYY-MM-DD')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });
        // this.dateChanged();
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideAlertMessage() {
         if(this.state.isCreated){
             this.getMenusList(this.state.menuTypeList);
         }
        this.setState({
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            menuTypeList:0,
            reqSource:0,
            selectedValue:1,
            menuId:0, menuName:"", menuPath:"",parentId:0,subParentId:0,menuType:1,wide:"N"
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 8) {
            return length
        } else {
            return 8;
        }
    };
    onSelect = (selectedValue, stateKey) => {
        if(stateKey==='menuTypeList'&&this.state.menuTypeList!==selectedValue){
            this.getMenusList(selectedValue);
        }
        if(stateKey==='parentId'){
            this.setState({loadParent:true})
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };

    onCreateButtonClick = () => {

            const {menuId, menuName, menuPath, reqSource,parentId,selectedValue,subParentId,menuType,wide} = this.state;
            var data = {
                "id": menuId,
                "menuName": menuName,
                "menuPath": menuPath,
                "orderBy": getCookie(cons.USERID),
                "parentId": selectedValue !== 3?parentId:subParentId,
                "reqSource": reqSource,
                "menuType":menuType,
                wide:wide,
                "organisation":"0"
            };
            var isValid=false;

            if ( menuName !== "" && menuPath !== '' && reqSource !== '') {
                if (selectedValue === 2) {
                    if (parentId !== 0 && parentId !== "")
                        isValid = true
                } else if (selectedValue === 3) {
                    if (parentId !== 0 && parentId !== "" && subParentId !== "" && subParentId !== 0)
                        isValid = true
                } else {
                    isValid = true
                }
            }
            if(isValid){
                this.handleLoading();
                requestList(serverApi.API_USER+serverApi.ADD_UPDATE_MENU, data, this.handleAddMenu)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }

    };
    handleAddMenu=(resp)=>{
        if (resp.status) {
            this.showMessage(resp.message)
        }else {
            this.showMessage(resp.message)
        }
        this.handleLoading();
    };



    showMessage = (msg) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        this.hideAlertMessage();
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleViewClick = (obj) => {
        var prop=JSON.parse(obj);
        console.log("prop",obj);
        this.setState({
            "menuId": prop.menuId,
            "menuName": prop.menuName,
            "menuPath": prop.menuPath,
            "parentId":prop.parentId,
            "selectedValue":prop.selectedValue,
            isCreated:true,
            updateView:true,
            "subParentId":prop.subParentId,
            "menuType":prop.menuType,wide:prop.wide==="Y"?"Y":"N"

        })
    };

    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract( 1, 'day' );
        console.log("menuType",this.state.menuType);

        const options = [
            { value: 0, label: 'Portal',name: 'Portal role' },
            { value: 1, label: 'App' ,name:'App Role'}
        ];
        var valid = function( current ){
            if(this.props.state.companyInfo.id!=='2')
                return current.isAfter( yesterday );
            else
                return true;        };

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            cardTitle={<Button color={"info"} onClick={this.handleCreate}>
                                Back To List</Button>}
                            isLoading={this.state.isLoading}
                            content={
                                <GridContainer>
                                    {this.state.showMessagealert}
                                    {this.state.basicAlert}
                                    <ItemGrid xs={12} sm={2} style={{marginTop:"10px"}}>
                                        <InputLabel className={classes.label}>
                                            Create<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValue===1}
                                                    onChange={(event)=>{
                                                      //  if(!this.state.updateView)
                                                        this.setState({
                                                            selectedValue: 1,
                                                           });
                                                    }}
                                                    value={1}
                                                    name="radio button demo"
                                                    aria-label="A"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label={"Menu"}
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValue===2}
                                                    onChange={(event)=>{
                                                       // if(!this.state.updateView)
                                                        this.setState({
                                                            selectedValue: 2,
                                                        });
                                                    }}
                                                    value={2}
                                                    name="radio button demo"
                                                    aria-label="A"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label={"Sub Menu"}
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValue===3}
                                                    onChange={(event)=>{
                                                       // if(!this.state.updateView)
                                                        this.setState({
                                                            selectedValue: 3,
                                                        });
                                                    }}
                                                    value={3}
                                                    name="radio button demo"
                                                    aria-label="A"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label={"Sub Sub Menu"}
                                        />
                                    </ItemGrid>

                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                   Menu Id<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="menuId"
                                                    value={this.state.menuId}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Menu Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="menuName"
                                                    value={this.state.menuName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Menu Source<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.reqSource}
                                                    id={"reqSource"}
                                                    onSelect={this.onSelect}
                                                    stateKey="reqSource"
                                                    staticData={options}
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Menu Path<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="menuPath"
                                                    value={this.state.menuPath}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.selectedValue!==1?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Parent Menu<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.parentId}
                                                    onSelect={this.onSelect}
                                                    stateKey="parentId"
                                                    url={serverApi.API_USER+serverApi.GET_PARENT_MENU+this.state.reqSource+"/"+0}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>:null}
                                    {this.state.selectedValue===3?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Sub Parent Menu<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.subParentId}
                                                        onSelect={this.onSelect}
                                                        isRefresh={this.state.loadParent}
                                                        setIsRefresh={(val)=>{
                                                            this.setState({loadParent:false})
                                                        }}
                                                        stateKey="subParentId"
                                                        url={serverApi.API_USER+serverApi.GET_PARENT_MENU+this.state.reqSource+"/"+this.state.parentId}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Menu Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.menuType}
                                                    id={"menuType"}
                                                    onSelect={this.onSelect}
                                                    stateKey="menuType"
                                                    staticData={menuTypes}
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    {this.state.parentId===201?  <ItemGrid xs={12} sm={2} style={{marginTop:"10px"}}>
                                        <InputLabel className={classes.label}>
                                            Is Wide Widget<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                        </InputLabel>
                                    </ItemGrid>:null}
                                    {this.state.parentId===201? <ItemGrid xs={12} md={1}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.wide==="Y"}
                                                    onChange={(event)=>{
                                                        //  if(!this.state.updateView)
                                                        this.setState({
                                                            wide: "Y",
                                                        });
                                                    }}
                                                    value={1}
                                                    name="radio button demo"
                                                    aria-label="A"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label={"Yes"}
                                        />

                                    </ItemGrid>:null}
                                    { this.state.parentId===201?  <ItemGrid xs={12} md={1}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.wide==="N"}
                                                    onChange={(event)=>{
                                                        // if(!this.state.updateView)
                                                        this.setState({
                                                            wide: "N",
                                                        });
                                                    }}
                                                    value={2}
                                                    name="radio button demo"
                                                    aria-label="A"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label={"No"}
                                        />

                                    </ItemGrid>:null}




                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}/>

                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {this.state.updateView?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.onCreateButtonClick()}}>Update</Button>

                                                </ItemGrid>:null}

                                            {!this.state.updateView ?
                                                <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                                    <GridContainer justify="center">
                                                        <ItemGrid>
                                                            <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>
                                                        </ItemGrid>
                                                        <ItemGrid>
                                                            <Button color="danger" onClick={this.hideAlertMessage}>Cancel</Button>
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid>:null

                                            }

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }
                        />

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <GridContainer>
                                        {
                                            <ItemGrid xs={10}>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Menu Source
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.menuTypeList}
                                                                onSelect={this.onSelect}
                                                                stateKey="menuTypeList"
                                                                staticData={options}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>

                                            </ItemGrid>
                                        }
                                        <ItemGrid xs={2}>
                                            <IconButton
                                                onClick={this.handleCreate}
                                                color="success" right={true}>
                                                <Add/>
                                            </IconButton>

                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>



                                            <TableComponent
                                               // url={serverApi.GET_LEAVE_TRANSACTIONS_LIST}
                                                  staticData={this.state.MenusList}
                                                borderRight={true}
                                                //searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                // isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                actionType={VIEW}

                                                fileName={"jute_with_value"}
                                                itemViewType={this.state.itemViewType}/>


                                            {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}


                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(withStyles(extendedFormsStyle)(Menus));
