import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { Link } from "react-router-dom";
import HeaderCard from "../../components/Cards/HeaderCard";
import { getDataFromUrl, requestList } from "../../server/server";
import { serverApi, getHostIp } from "../../helper/Consts";
import SweetAlert from "react-bootstrap-sweetalert";
import Popup from "../../components/Popup/PopUp";
import MULTI_TENANT_DATA from "../../helper/Consts";
import LoginCard from "../../components/Cards/LoginCard";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      userName: "",
      basicAlert: null,
    };
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  OnForgotPassword = () => {
    if (this.state.userName !== "") {
      var host = "http://" + window.location.hostname + "/#/ResetPassword?";
      var data = {
        userName: this.state.userName,
        link: host,
      };
      requestList(serverApi.FORGOT_PASSWORD, data, this.handleforgotResp);
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };
  onLogin = () => {
    this.props.history.push("/");
  };

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    this.setState({
      showMessagealert: null,
    });
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.onLogin();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleforgotResp = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    // this.handleLoading();
  };

  onChangeValues(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });

    this.setState({
      isError: false,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            {this.state.basicAlert}
            {this.state.showMessagealert}
            <ItemGrid xs={12} sm={8} md={8}>
              <HeaderCard
                cardTitle="Forgot Passwordsss"
                headerColor="blue"
                content={
                  <form>
                    <br />
                    <CustomInput
                      labelText="User Name"
                      iType={"text"}
                      id="userName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      onChange={this.onChangeValues}
                    />
                    <Link to="/" className="btn btn-link">
                      <span style={{ color: "#00acc1" }}>Back To Login</span>
                    </Link>
                    <br />
                    <GridContainer justify="center">
                      <ItemGrid>
                        <Button color="primary" onClick={this.OnForgotPassword}>
                          Submit
                        </Button>
                      </ItemGrid>
                      <ItemGrid>
                        <Button color="danger" onClick={this.onLogin}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                    <br />
                  </form>
                }
              />
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    );
  }
}

ForgetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
const formStyle = {
  ...sweetAlertStyle,
  ...loginPageStyle,
};

export default withStyles(formStyle)(ForgetPassword);
