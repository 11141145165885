import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { requestList } from "../../../server/server";
import moment from "moment";
import { getCookie } from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../../CustumComponents/ReportsTableComponent";
import { Search } from "@material-ui/icons";
import { connect } from "react-redux";

class JuteQualityWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment()
        .days(-6)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      selectMukam: "",
      selectUnitConversion: "",
      VehicleType: "",
      mukamId: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "indentNo",
      approveStatus: false,

      indentStatus: "3",
      canChangeUnit: true,
      canChangeMukam: true,
      updateView: false,
      statusDesc: "",
      date1: moment().format("DD-MM-YYYY"),
      indentNo: "",
      year: moment().format("YYYY"),
      unitConvertion: "",
      unitConvertionId: "",
      unitConversionName: "",
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantiy: "",
      selectedMukam: "",
      alert: false,
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      hideJuteType: false,
      url: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      stock: "",
      juteSummaryType: {
        value: 1,
        label: "Issue Summary",
        name: "Issue Summary",
      },
      juteSummaryTypeId: 1,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  componentDidMount() {
    this.getReportsTable();
  }
  getReportsTable = () => {
    var data = {
      taskCode: 1015,
      payloadSize: 3,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        issueType: this.state.juteSummaryTypeId,
      },

      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      mrId: this.state.mrId,
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handleReports);
  };
  handleReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    var dataTable = resp;
    if (dataTable !== null) {
      var totalQuality = dataTable.totalValues.totQuantity;
      var totWeight = dataTable.totalValues.totWeight;

      var columns = dataTable.data.column.map((prop, key) => {
        var obj = prop;
        if (obj.Header === "Issue Date") {
          obj.Footer = "Grand Total";
        }
        if (obj.Header === "MR Date") {
          obj.Footer = "Grand Total";
        }
        if (obj.Header === "Quantity") {
          obj.Footer = totalQuality;
        }
        if (obj.Header === "Weight") {
          obj.Footer = totWeight;
        }
        obj.filterable = false;
        obj.sortable = false;
        obj.minWidth = 30;
        //  obj.maxWidth=50;
        // delete obj.minWidth;
        delete obj.maxWidth;
        return obj;
      });
    }
    dataTable.data.column = columns;
    console.log("columns", JSON.stringify(dataTable.data.column));

    if (resp.status) {
      this.setState({
        dataTable: dataTable,
        isRefresh: true,
      });
    }
    this.handleLoading();
  };

  handleSeacrh = () => {
    var loading = false;
    if (!this.state.isRefresh) this.getReportsTable();
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  getDefaultUnitConvertion = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name !== null) {
      if (name === "LOOSE") {
        val = 1;
        label = "LOOSE";
      } else {
        val = 2;
        label = "BALE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onChangeValues = (event) => {
    var value = event.target.value;

    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    if (
      stateKey === "selectedMukam" &&
      this.state.invoiceList.length > 0 &&
      !this.state.canChangeMukam
    ) {
      this.unitConvertionAlert(
        "Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!"
      );
    } else
      this.setState({
        [stateKey]: selectedValue,
      });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };
  getName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      selectedJuteName: values[0],
      selectedGrpId: values[1],
      selectedUnit: values[2],
      selectedDeptId: values[3],
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      selectedJuteType: "",
      selectedJuteName: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      quantity: "",
      stock: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      indentNo: "",
      unitConvertion: "",
      unitConvertionId: 0,
      indentQuantity: "",
      indentQuantityTon: "",
      indentBales: "",
      createdBy: "",
      createdOn: "",
      selectedVehicleType: "",
      vehicleQuantity: "",
      quantity: "",
      stock: "",
      selectedJuteQuality: "",
      selectedQualityName: "",
      selectedMukam: "",
      selectedTax: "",
      invoiceList: [],
      selectedJuteType: "",
      selectedJuteName: "",
      selectedIndentQuantity: "",
      sumOFQuantities: 0,
      selectedDeptId: "",
      selectedGrpId: "",
      selectedUnit: "",
      juteSummaryType: "",
      juteSummaryTypeId: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  unitConvertionAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          //warning
          showCancel
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "black",
          }}
          title={msg}
          onConfirm={() => this.hideUnitConvertionAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnText={"Yes"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onRejectButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.indentItemId,
        indentHeaderId: this.state.indentNo,
        itemId: prop.juteType,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        id: this.state.indentNo,
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantiy,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
        status: "4",
      },
      indentItems: invoiceList,
    };
    this.handleLoading();
    requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
  };

  onApproveButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        id: prop.indentItemId,
        indentHeaderId: this.state.indentNo,
        itemId: prop.juteType,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        id: this.state.indentNo,
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantiy,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
        status: this.state.indentStatus,
      },
      indentItems: invoiceList,
    };
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== "" &&
      this.state.invoiceList.length >= 1
    ) {
      this.handleLoading();
      requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent);
    } else if (this.state.invoiceList.length < 1) {
      this.basicAlert("Please Add Atleast One Item..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };

  onCreateButtonClick = () => {
    var invoiceList = this.state.invoiceList.map((prop, key) => {
      return {
        itemId: prop.juteType,
        dtlItemDesc: prop.juteName,
        itemGroupId: prop.grpId,
        qualityCode: prop.quality,
        deptId: prop.deptId,
        stock: prop.stockValue,
        indentQuantity: prop.quantity,
        unitId: prop.unit,
        noOfBales: prop.quantityType,
      };
    });

    var data = {
      indentHeader: {
        type: "J",
        mukam: this.state.selectedMukam,
        vehicleTypeId: this.state.selectedVehicleType,
        vehicleQuantity: this.state.vehicleQuantity,
        submitter: getCookie(cons.USERID),
        indentDate: this.dateChanged(),
        finnacialYear: this.state.year,
        unitConversion: this.state.unitConvertionName,
      },
      indentItems: invoiceList,
    };
    if (
      this.state.selectedMukam !== "0" &&
      this.state.selectedMukam !== "" &&
      this.state.unitConvertion !== "" &&
      this.state.selectedVehicleType !== "" &&
      this.state.invoiceList.length >= 1
    ) {
      this.handleLoading();
      requestList(serverApi.ADD_INDENT, data, this.handleAddIndent);
    } else if (this.state.invoiceList.length < 1) {
      this.basicAlert("Please Add Atleast One Item..!!");
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  handleSelectedUnitValue = (defValue) => {
    this.setState({
      juteSummaryType: defValue,
      juteSummaryTypeId: defValue.value,
      //unitConvertionName: defValue.label,
      //canChangeUnit: false
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    const options = [
      //{value: 0, label: 'Select...', name: 'Select...'},
      { value: 1, label: "Issue Summary", name: "Issue Summary" },
      { value: 2, label: "Receipt Summary", name: "Receipt Summary" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Jute Type
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedJuteType"}
                                        defValue={selectedJuteType}
                                        onSelect={this.onSelect}
                                        getName={this.getName}
                                        stateKey="selectedJuteType"
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          this.state.selectedMukam +
                                          "/getAllItemByMukam"
                                        }
                                        value={selectedJuteType}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quality
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      {/* {(selectedJuteType >0) ?*/}
                                      <SelectboxTemp
                                        defValue={
                                          this.state.selectedJuteQuality
                                        }
                                        id={"selectedJuteQuality"}
                                        onSelect={this.onSelect}
                                        getName={this.getQualityName}
                                        stateKey="selectedJuteQuality"
                                        isRefresh={this.state.loadQuality}
                                        setIsRefresh={this.afterRefreshQuality}
                                        url={
                                          serverApi.DATATABLE_SERVICE_URL +
                                          selectedJuteType +
                                          "/getQualityByItem"
                                        }
                                        value={this.state.selectedJuteQuality}
                                      />
                                      {/*:<legend style={{color:"red",fontSize:"12px"}}>Select Jute Type To Display Qualities</legend>
                                                                            }*/}
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} md={12}>
                              <br />
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="start">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                  paddingTop: "25px",
                                                }}
                                              >
                                                {this.getQuantityType()}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Indent No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="indentNo"
                            className={classes.textField}
                            value={this.state.indentNo}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Mukam
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"selectedMukam"}
                          selectDisabled={this.state.updateView}
                          defValue={this.state.selectedMukam}
                          onSelect={this.onSelect}
                          isChangeValue={this.state.canChangeMukam}
                          stateKey="selectedMukam"
                          url={
                            serverApi.SEARCH_MUKAM + this.props.state.companyInfo.id
                          }
                          value={this.state.selectedMukam}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Indent Quantity
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          // disabled={true}
                          id="indentQuantity"
                          value={this.state.indentQuantity}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    paddingTop: "25px",
                                  }}
                                >
                                  QTL
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={4}>
                        <TextField
                          //  disabled={true}
                          id="indentQuantityTon"
                          value={this.getIndentQtyTon()}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            style: { height: 30, color: "#000" },
                            endAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    paddingTop: "25px",
                                  }}
                                >
                                  Ton
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Converted Quantity
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="indentBales"
                          disabled={true}
                          value={this.getConvertedQty()}
                          className={classes.textField}
                          //  onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={{
                            style: { height: 30, color: "#000" },
                            endAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    paddingTop: "25px",
                                  }}
                                >
                                  BALES
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {console.log(this.state.invoiceList.length + " length")}
                        <ReactTable
                          data={this.state.invoiceList}
                          filterable={false}
                          resizable={false}
                          loading={this.state.loading}
                          getTableProps={() => {
                            return {
                              style: {
                                border: "2px solid #00ABDC",
                                borderRadius: "4px",
                              },
                            };
                          }}
                          getTheadProps={() => {
                            return {
                              style: {
                                borderBottom: "2px solid #00ABDC",
                                //height:45
                                //lineHeight:"1.3"
                              },
                            };
                          }}
                          getTheadThProps={() => {
                            return {
                              style: {
                                borderRight: "1px solid #999999",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                                lineHeight: 1.3,
                              },
                            };
                          }}
                          style={{
                            color: "#00ABDC",
                            textAlign: "center",
                            overflowY: "auto",
                          }}
                          columns={[
                            {
                              Header: "Jute Type",
                              accessor: "juteName",
                              minWidth: 60,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quality",
                              accessor: "qualityName",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: this.getQuantityType(),
                              accessor: "quantityType",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quantity",
                              accessor: "quantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Unit",
                              accessor: "unit",
                              width: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Stock.",
                              accessor: "stockValue",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          showPagination={false}
                          pageSize={this.getInvoiceTablePageSize()}
                          //  defaultPageSize={5}
                          sortable={false}
                          getNoDataProps={() => {
                            return {
                              style: {
                                display: "none",
                              },
                            };
                          }}
                          className="-striped -highlight"
                        />
                      </ItemGrid>
                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            <ItemGrid xs={6}>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "#003F66",
                                }}
                              >
                                Status:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#003F66",
                                }}
                              >
                                {this.state.statusDesc}
                                <br />
                              </span>
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: 400,
                                  color: "red",
                                }}
                              >
                                Note:
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  fontStyle: "italic",
                                }}
                              >
                                {"Unit & Mukam can't modified"}
                              </span>
                            </ItemGrid>
                            {this.state.approveStatus ? (
                              <ItemGrid xs={6}>
                                <Button
                                  color={"info"}
                                  right={true}
                                  onClick={this.handleAddItem}
                                >
                                  Add Item
                                </Button>
                              </ItemGrid>
                            ) : null}
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      )}
                    </GridContainer>
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.approveStatus ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onApproveButtonClick}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={this.onRejectButtonClick}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Jute Summary Type
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  *
                                </span>
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <Select
                                defValue={this.state.juteSummaryType}
                                name={"juteSummaryType"}
                                value={this.state.juteSummaryType}
                                onChange={this.handleSelectedUnitValue}
                                // statekey={"unitConvertion"}
                                options={options}
                                className={classes.selectFormControl}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  // height:"20px",
                                  colors: {
                                    ...theme.colors,
                                    //  primary25: '#00ABDC',
                                    primary: "#00ABDC",
                                    //   primary50:"#00ABDC"
                                  },
                                })}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    height: "30px",
                                    "min-height": "30px",
                                  }),
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Mr No.
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                // defValue={this.state.ebNo}
                                id="mrId"
                                value={this.state.mrId}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                // InputProps={{
                                //   endAdornment: (
                                //     // <InputAdornment position="end">
                                //       <span
                                //         style={{
                                //           color: "#00ABDC",
                                //           fontSize: "16px",
                                //           fontWeight: "400",
                                //         }}
                                //       >
                                //         <Search
                                //           className={classes.inputAdornmentIcon}
                                //           onClick={this.mrId}
                                //         />
                                //       </span>
                                //     {/* </InputAdornment> */}
                                //   ),
                                // }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {/*<ItemGrid xs={12}>*/}
                        {/*<IconButton*/}
                        {/*onClick={this.handleCreate}*/}
                        {/*color="success" right={true}>*/}
                        {/*<ExpandMoreIcon/>*/}
                        {/*</IconButton>*/}

                        {/*</ItemGrid>*/}
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      {this.state.dataTable !== null &&
                      this.state.dataTable !== undefined ? (
                        <ReportsTableComponent
                          // url={serverApi.REPORTS_JUTE_WITH_VALUE}
                          staticData={this.state.dataTable.data}
                          //  searchData={this.getData()}
                          isRefresh={this.state.isRefresh}
                          handleSearch={this.handleSeacrh.bind(this)}
                          // isLoading={this.state.isRefresh}
                          fileName={"jute_issue_summary_report"}
                          //  onActionClick={this.handleViewClick}
                          itemViewType={this.state.itemViewType}
                        />
                      ) : null}
                      {/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>
*/}
                      <SamplePDF printDocument={this.printDocument} />

                      {/*<TableComponent*/}
                      {/*url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}*/}
                      {/*searchData={this.getData()}*/}
                      {/*isRefresh={this.state.isRefresh}*/}
                      {/*handleSearch={this.handleSeacrh.bind(this)}*/}
                      {/*isLoading={this.state.isRefresh}*/}
                      {/*onActionClick={this.handleViewClick}*/}
                      {/*itemViewType={this.state.itemViewType}*/}
                      {/*printDoc={this.printDocument}*/}
                      {/*actionType={VIEW}/>*/}
                    </ItemGrid>
                    {/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
                    {/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument = () => {
    var data = {
      taskCode: this.state.juteSummaryTypeId === 1 ? 1016 : 1019,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    if (resp.status) {
      // this.setState({
      //     dataTable:resp,
      //     isRefresh: true,
      // })
      var modules = resp.data.juteIssue;
      var grandTotals = resp.data.totalValues;
      var companyName = resp.data.companyName;
      var reportName =
        this.state.juteSummaryTypeId === 1
          ? "Daily Issue Summary Report From :"
          : "Daily Receipt Summary Report From :";
      var columnName =
        this.state.juteSummaryTypeId === 1 ? "Issue Date" : "MR Date";
      var summaryType = this.state.juteSummaryTypeId;
    }
    var pageNo = 1;
    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(14);
    doc.text(170, 40, companyName);
    doc.setFontSize(10);
    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);
    var date = moment().format("DD/MM/YYYY");
    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    // doc.table(50, 100,modules,modulesHeader,config);
    doc.text(220, 60, "Report No.:[JUTE/07]");
    doc.text(
      170,
      80,
      reportName +
        this.state.fromDate +
        " to " +
        this.state.todate +
        "Quantity in :Quintal"
    );
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(30, 100, 60, 30, columnName, "", "C");
    doc.cell(105, 100, 60, 30, "   Mr No  ", "", "C");
    doc.cell(147, 100, 60, 30, "  Quality ", "", "C");
    doc.cell(189, 100, 60, 30, "Godown No", "", "C");
    doc.cell(231, 100, 100, 30, "    Bale/Loose   ", "", "C");
    doc.cell(271, 100, 60, 30, "    Quantity  ", "", "C");
    doc.cell(271, 100, 60, 30, "    Weight  ", "", "C");
    if (summaryType === 1) {
      doc.cell(271, 100, 60, 30, "    Yarn Type  ", "", "C");
    }

    // doc.cellInitialize();
    var cellWidths = ["", 60, 60, 60, 60, 100, 60, 60, 60, 50, 50, 50, 50];
    var cellTextaligns = [
      "",
      "center",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 105;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 25;
        i = i + 1;
        doc.cellInitialize();
        if (i === 24) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var date = moment().format("DD/MM/YYYY");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");
          doc.setFontSize(14);
          doc.text(170, 40, companyName);
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
          // doc.table(50, 100,modules,modulesHeader,config);
          doc.text(220, 60, "Report No.:[JUTE/07]");
          doc.text(
            170,
            80,
            reportName +
              this.state.fromDate +
              " to " +
              this.state.todate +
              "Quantity in :Quintal"
          );
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(30, 100, 60, 30, columnName, "", "C");
          doc.cell(105, 100, 60, 30, "   Mr No  ", "", "C");
          doc.cell(147, 100, 60, 30, "  Quality ", "", "C");
          doc.cell(189, 100, 60, 30, "Godown No", "", "C");
          doc.cell(231, 100, 100, 30, "    Bale/Loose   ", "", "C");
          doc.cell(271, 100, 60, 30, "    Quantity  ", "", "C");
          doc.cell(271, 100, 60, 30, "    Weight  ", "", "C");
          if (summaryType === 1) {
            doc.cell(271, 100, 60, 30, "    Yarn Type  ", "", "C");
          }

          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;

          if (index <= 7) {
            if (key !== "lineNo" && "qualityId" && "goDownName" && "status") {
              var myItem = prop[key] + " ";
              return doc.cell(
                30,
                k,
                cellWidths[a],
                25,
                myItem,
                2,
                cellTextaligns[a]
              );
            }
          }
        });
      });
    }

    doc.cellInitialize();
    doc.cell(30, k + 25, 60, 30, "Grand Total", "", "right");
    doc.cell(231, k + 25, 60, 30, " ", "", "right");
    doc.cell(271, k + 25, 60, 30, " ", "", "right");
    doc.cell(271, k + 25, 60, 30, " ", "", "right");
    doc.cell(271, k + 25, 100, 30, " ", "", "right");
    doc.cell(271, k + 25, 60, 30, grandTotals.totQuantity + " ", "", "right");
    doc.cell(271, k + 25, 60, 30, grandTotals.totWeight + " ", "", "right");
    if (summaryType === 1) {
      doc.cell(271, k + 25, 60, 30, " ", "", "right");
    }

    doc.text(30, k + 150, "Dept. Incharge");
    doc.text(400, k + 150, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 200, newdat);
    doc.text(500, k + 200, printBy);

    summaryType === 1
      ? doc.save("JuteissueSummaryReport.pdf")
      : doc.save("JuteReceiptSummaryReport.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(JuteQualityWise)
);
