import React, { Children } from "react";
import PropTypes from "prop-types";
// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
import Print from "@material-ui/icons/Print";

// react component used to create alerts

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Popup from "../../components/Popup/PopUp";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { getDataFromUrl, requestList } from "../../server/server";
import cons, {
  serverApi,
  serverConfig,
  serverVars,
  getTenantId,
} from "../../helper/Consts";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "react-select";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getCookie } from "../../helper/Cookies";
import TextField from "../../components/CustomInput/CustomTextField";
import InputAdornment from "material-ui/Input/InputAdornment";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import IconButton from "../../components/CustomButtons/IconButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import List from "@material-ui/icons/List";
import CalendarIcon from "@material-ui/icons/PermContactCalendar";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import TableComponent from "../CustumComponents/TableComponent.jsx";
import { VIEW } from "../../ui-actions/UiActions";
import Button from "../../components/CustomButtons/Button";
import {
  AttendanceMark,
  Attendace_Source,
  Attendancesource,
  AttendanceType,
  MaritalStatus,
} from "../TableData";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import SelectboxMultiple from "../CustumComponents/SelectboxMultiple";
import Attachment from "@material-ui/icons/AttachFile";
import Checkbox from "material-ui/Checkbox";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";
import exportFromJSON from "export-from-json";
import ExpandMoreIcon from "@material-ui/icons/FileDownload";
import LogsIcon from "../../assets/icons/log1.png";
import { CenterFocusStrong } from "@material-ui/icons";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

class AttendanceCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      empList: [],
      tableList: null,
      exitTime: "00:00:00",
      entryTime: "00:00:00",
      monthStart: moment()
        .startOf("month")
        .format("DD-MM-YYYY"),
      monthEnd: moment()
        .endOf("month")
        .format("DD-MM-YYYY"),
      attendanceDate: moment().format("DD-MM-YYYY"),
      attendanceSource: "",
      currentMonth: moment().format("MMM"),
      currentYear: moment().format("YYYY"),
      ebIdList: getCookie(cons.EB_NO) !== "null" ? getCookie(cons.EB_NO) : "",
      ebId: "",
      ebId_form_list: "",
      startingDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      toDate: moment().format("DD-MM-YYYY"),
      from: moment()
        .day(-7)
        .format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      selectClass: "",
      alert: null,
      eventName: "",
      showPopup: false,
      checked: [],
      checked1: false,
      description: "",
      isCalendar: true,
      itemViewType: "dailyAttenId",
      subDeptId: 0,
      designationId: 0,
      cataId: 0,
      workType: "",
      startIndex: 1,
      lastIndex: 10,
      ebNoList: "",
      ebNo: getCookie(cons.EB_NO) !== "null" ? getCookie(cons.EB_NO) : "",
      showStatus: false,
      spell: "",

      workedDesignationId: "",
      loadAll: true,
      employeeid: "",
      workersList: [],
      companyid: parseInt(this.props.state.companyInfo.id),
      companyList: [],
      designationList: [],
      departmentList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    //this.togglePopup = this.togglePopup.bind(this)
  }
  componentDidMount() {
    this.callHolidayMaster(
      this.state.monthStart,
      this.state.monthEnd,
      getCookie(cons.EB_NO)
    );
    this.callAttendanceSummary(
      this.state.monthStart,
      this.state.monthEnd,
      getCookie(cons.EB_NO)
    );
    this.getLastBioMerticLogs();
    // this.getReportsTable();
    this.callAttendanceList(this.state.startIndex, this.state.lastIndex);
    this.getEmpDetails();
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(
      serverApi.COMPANYMASTER_DATATABLE,
      data,
      this.handleCompaniesList
    );

    const {
      ebId_form_list,
      startingDate,
      toDate,
      subDeptId,
      designationId,
      cataId,
      workType,
      ebNoList,
      spell,
    } = this.state;
    //console.log("startIndex,lastIndex",startIndex,lastIndex);
    var data2 = {
      companyId: this.props.state.companyInfo.id,
      ebId: ebId_form_list,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
      subDeptId: subDeptId,
      designationId: designationId,
      cataId: cataId,
      workType: workType,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      ebNo: ebNoList,
      spell: spell,
    };

    requestList(
      serverApi.WORKER_DAILY_ATTENDACE_TABLE,
      data2,
      this.handleAttendanceList
    );
    getDataFromUrl(
      serverApi.SELECT_SUB_DEPARTMENT_MASTER + this.props.state.companyInfo.id,
      this.handleDepartment
    );
    getDataFromUrl(
      serverApi.SELECT_DESIGNATION + this.props.state.companyInfo.id + "/" + 0,
      this.handleDesig
    );
  }

  handleDepartment = (resp) => {
    console.log("getting depart response", resp);
    var list = [];
    resp.map((prop, i) => {
      list.push({
        label: prop.label,
        name: prop.name,
        value: prop.value,
      });
    });
    this.setState({
      departmentList: list,
    });
  };

  getWorkersList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      deptId: 0,
      empCode: "",
      empName: "",
      esiNo: "",
      payScheme: "",
      pfNo: "",
      reporting: 0,
      userId: getCookie(cons.USERID),
    };

    // this.handleLoading();
    requestList(serverApi.TABLE_WORKERS_LIST, data, this.handleWorkersList);
  };
  handleWorkersList = (resp) => {
    console.log("emplist", JSON.stringify(resp.data));
    var list = [{ label: "Select...", value: "", name: "Select..." }];
    resp.data.forEach((prop) => {
      list.push({
        label: prop.workerName,
        value: prop.ebId,
        name: prop.workerName,
      });
    });
    this.setState({ workersList: list, loadWorkers: true });
  };

  callAttendanceList(startIndex, lastIndex) {
    const {
      ebId_form_list,
      startingDate,
      toDate,
      subDeptId,
      designationId,
      cataId,
      workType,
      ebNoList,
      spell,
    } = this.state;
    //console.log("startIndex,lastIndex",startIndex,lastIndex);
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebId: ebId_form_list,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
      subDeptId: subDeptId,
      designationId: designationId,
      cataId: cataId,
      workType: workType,
      startIndex: startIndex,
      lastIndex: lastIndex,
      ebNo: ebNoList,
      spell: spell,
    };

    requestList(
      serverApi.WORKER_DAILY_ATTENDACE_TABLE,
      data,
      this.handleAttendanceList
    );
  }
  callAttendanceListTotal(startIndex, lastIndex) {
    const {
      ebId_form_list,
      startingDate,
      toDate,
      subDeptId,
      designationId,
      cataId,
      workType,
      ebNoList,
      spell,
    } = this.state;
    //console.log("startIndex,lastIndex",startIndex,lastIndex);
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebId: ebId_form_list,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
      subDeptId: subDeptId,
      designationId: designationId,
      cataId: cataId,
      workType: workType,
      startIndex: startIndex,
      lastIndex: lastIndex,
      ebNo: ebNoList,
      spell: spell,
    };

    requestList(
      serverApi.WORKER_DAILY_ATTENDACE_TABLE_NEW,
      data,
      this.handleAttendanceTotalList
    );
  }
  handleAttendanceTotalList = (resp) => {
    const exportType = "xls",
      fileName = "AttendaceCalendar";
    var data = resp.data.map((prop) => {
      var obj = {};
      resp.column.forEach((o) => {
        obj[o.Header] = prop[o.accessor];
      });
      return obj;
    });
    exportFromJSON({ data, fileName, exportType });
  };

  handleAttendanceList = (resp) => {
    const { classes } = this.props;
    var tabdata = [];
    this.setState({ tdata: resp });
    console.log("Response of the status", resp);
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
    }
    // else {
    //   this.showMessage(resp.message, resp.status);
    // }

    resp.data.map((prop, i) => {
      var s = {
        check: (
          <Checkbox
            className={classes.positionAbsolute}
            tabIndex={-1}
            onClick={() => this.handleToggle(prop.dailyAttenId)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
            }}
          />
        ),
        ...prop,
      };
      tabdata.push(s);
    });

    var k = [
      {
        filterable: false,
        Header: "Select",
        accessor: "check",
        minWidth: 50,
        style: { textAlign: "center" },
        sortable: false,
      },
    ];
    resp.column.map((prop, o) => {
      var j = {
        filterable: prop.filterable,
        Header: prop.Header,
        accessor: prop.accessor,
        minWidth: prop.minWidth,
        style: {
          textAlign: prop.textAlign,
        },
        sortable: prop.sortable,
      };

      k.push(j);
    });

    var dataArray = {
      data: tabdata,
      filterTable: resp.filterTable,
      defaultPageSize: resp.defaultPageSize,
      showPaginationTop: resp.showPaginationTop,
      showPaginationBottom: resp.showPaginationBottom,
      hrmsAccess: resp.hrmsAccess,
      approveButton: resp.approveButton,
      column: k,
      totalRecords: resp.totalRecords,
    };
    /* if(this.state.isExcel){
            this.setState({
                dataTableAll: dataArray,isExcel:false, isRefresh: false,
            });
            console.log("isExcel",this.state.isExcel);

        }else {*/
    this.setState({
      tableList: dataArray,
      //    totalRecords: resp.totalRecords,
      isRefresh: true,
      totalRecords: resp.totalRecords,
    });

    /*  console.log("loadAll",this.state.loadAll);
            // this.handleLoading();
            if (this.state.loadAll) {
                this.setState({isExcel:true,loadAll:false,totalRecords:resp.totalRecords});

                this.callAttendanceList(this.state.startIndex,resp.totalRecords)
            }*/

    //  }
  };
  getLastBioMerticLogs() {
    getDataFromUrl(
      serverApi.WORKER_DAILY_BIOMETRIC_LOGS,
      this.handleLastBioMerticLogs
    );
  }

  handleLastBioMerticLogs = (resp) => {
    var LastBiomertic = [];
    resp.data.map((prop, i) => {
      var obj = {
        location: prop.deviceName,
        lastUpdated: prop.lastUpdatedDate,
      };
      LastBiomertic.push(obj);
    });
    this.setState({ lastBiomerticData: LastBiomertic });
  };

  getReportsTable = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      deptId: 0,
      empCode: "",
      empName: "",
      esiNo: "",
      payScheme: "",
      pfNo: "",
      reporting: 0,
      userId: getCookie(cons.USERID),
    };

    requestList(serverApi.TABLE_WORKERS_LIST, data, this.handleReports);
  };
  handleReports = (resp) => {
    var data = [];
    data = resp.data.map((prop) => {
      var obj = {
        label: prop.workerName,
        value: prop.ebId,
        name:
          prop.workerName +
          "^" +
          prop.empCode +
          "^" +
          prop.designationId +
          "^" +
          prop.departmentId +
          "^" +
          prop.ebNo,
      };
      return obj;
    });

    this.setState({
      empList: data,
      loadEmp: true,
    });
    //}
    //  this.handleLoading();
  };
  callAttendanceSummary(monthStart, monthEnd, ebId) {
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebNo: ebId,
      startingDate: monthStart,
      toDate: monthEnd,
      userId: getCookie(cons.USERID),
    };

    requestList(
      serverApi.WORKER_DAILY_ATTENDANCE_SUMMARY,
      data,
      this.handleAttendanceSummary
    );
  }
  handleAttendanceSummary = (resp) => {
    var summary = [];

    Object.keys(resp.data).map((key, i) => {
      var obj = { type: "Total No Of " + key, value: resp.data[key] };

      summary.push(obj);
    });

    this.setState({ AttendanceSummary: summary });
  };

  callHolidayMaster(monthStart, monthEnd, ebId) {
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebNo: ebId,
      startingDate: monthStart,
      toDate: monthEnd,
      userId: getCookie(cons.USERID),
    };
    requestList(
      serverApi.WORKER_DAILY_ATTENDANCE_SUMMARY_LIST,
      data,
      this.handleHolidayMaster
    );
    requestList(
      serverApi.WORKER_DAILY_ATTENDANCE_SUMMARY,
      data,
      this.handleAttendanceSummary
    );
  }

  handleHolidayMaster = (resp) => {
    var data = resp.data,
      eventList = [];
    //if(data!==null&&data.length>0){

    data.map((prop, i) => {
      const datedesc = prop.attendanceDate.split("-");
      var today = new Date();
      console.log("getFullYear", today.getFullYear());
      /*   var y = today.getFullYear();
               var m = today.getMonth();
               var d = today.getDate();*/
      var d = datedesc[2].replace(/^0+/, "");
      var date = parseInt(d),
        m = parseInt(datedesc[1]),
        y = parseInt(datedesc[0]);
      var des = prop.attendanceMark;

      var h1 = "",
        h2 = "";
      if (des === "P") {
        if (prop.entryTime1 !== null)
          h1 = moment(prop.entryTime1).format("HH:mm");
        if (prop.exitTime1 !== null)
          h2 = moment(prop.exitTime1).format("HH:mm");
        if (prop.exitTime2 !== null)
          h2 = moment(prop.exitTime2).format("HH:mm");
      }
      var color = "green";
      if (prop.attendanceMark === "A") {
        color = "red";
      } else if (prop.attendanceMark === "LV") {
        color = "orange";
      }

      var obj = {
        title: des,
        allDay: true,
        start: new Date(y, m - 1, date),
        end: new Date(y, m - 1, date),
        color: color,
        startTime: h1,
        endTime: h2,
        //  id:o.id,
        date: prop.attendanceDate,
      };
      eventList.push(obj);
    });
    this.setState({
      events: eventList,
    });
    //}
  };
  onChangeValues(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  //     handleToggle1(value) {
  //         const {classes}=this.props;
  // var tabdata=[];
  //         var tbldata=this.state.tdata;
  //         tbldata.data.map((prop,i)=> {
  //             var s={
  //                 check: (<Checkbox
  //                     className={classes.positionAbsolute}
  //                     tabIndex={-1}
  //                     checked={true}
  //                     onClick={() => this.handleToggle(prop.dailyAttenId)}
  //                     checkedIcon={<Check className={classes.checkedIcon}/>}
  //                     icon={<Check className={classes.uncheckedIcon}/>}
  //                     classes={{
  //                         checked: classes.checked
  //                     }}
  //                 />),
  //                 "dailyAttenId": prop.dailyAttenId,
  //                 "ebNo": prop.ebNo,
  //                 "workerName": prop.workerName,
  //                 "attendanceDate": prop.attendanceDate,
  //                 "attendanceMark": prop.attendanceMark,
  //                 "attendanceSource": prop.attendanceSource,
  //                 "attendanceType": prop.attendanceType,
  //                 "workedDesignation": prop.workedDesignation,
  //                 "workedDepartment": prop.workedDepartment,
  //                 "deviceName": prop.deviceName,
  //                 "spell": prop.spell,
  //                 "spellHours": prop.spellHours,
  //                 "ebId": prop.ebId,
  //             } ;
  //             tabdata.push(s)
  //         });
  //
  //
  //
  //         var che= <Checkbox
  //             className={classes.positionAbsolute}
  //             tabIndex={-1}
  //             onClick={() => this.handleToggle1(tbldata.data)}
  //             checkedIcon={<Check className={classes.checkedIcon}/>}
  //             icon={<Check className={classes.uncheckedIcon}/>}
  //             classes={{
  //                 checked: classes.checked
  //             }}
  //         />
  //
  //         var k=[{
  //             "filterable":false,
  //             "Header":  che ,
  //             "accessor":"check",
  //             "minWidth":50,
  //             "style":{"textAlign":"center"},
  //             "sortable":false
  //         }];
  //         tbldata.column.map((prop,o)=>{
  //             var j=
  //                 {
  //                     "filterable": prop.filterable,
  //                     "Header": prop.Header,
  //                     "accessor": prop.accessor,
  //                     "minWidth": prop.minWidth,
  //                     "style": {
  //                         "textAlign": prop.textAlign
  //                     },
  //                     "sortable": prop.sortable
  //                 };
  //
  //             k.push(j)
  //         });
  //
  //
  //         var dataArray={
  //             "data":tabdata,
  //             "filterTable": tbldata.filterTable,
  //             "defaultPageSize": tbldata.defaultPageSize,
  //             "showPaginationTop": tbldata.showPaginationTop,
  //             "showPaginationBottom": tbldata.showPaginationBottom,
  //             "hrmsAccess": tbldata.hrmsAccess,
  //             "approveButton":tbldata.approveButton,
  //             "column":k,
  //         };
  //
  //         this.setState({
  //             tableList:dataArray,
  //             isRefresh: true,
  //         });
  //
  //         console.log("sadik basahaaaaa",this.state.tableList);
  //
  //         var attenId=[];
  //         tbldata.data.map((prop,i)=> {
  //             attenId.push(prop.dailyAttenId)
  //         });
  //         this.setState({checked:true, SetAll:attenId})
  //
  //
  //         // const { checked } = this.state;
  //         // const currentIndex = checked.indexOf(value);
  //         // const newChecked = [...checked];
  //         //
  //         // if (currentIndex === -1) {
  //         //     newChecked.push(value);
  //         // } else {
  //         //     newChecked.splice(currentIndex, 1);
  //         // }
  //         //
  //         // this.setState({
  //         //     checked: newChecked,
  //         // });
  //
  //         //console.log("checkeeeeeeeee",checked);
  //     }

  selectedEvent(event) {
    // alert(event.title);
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onSlotChange(slotInfo) {
    console.log("toggle is true", slotInfo.start);
    var startDate = moment(slotInfo.start.toLocaleString()).format(
      "DD-MM-YYYY"
    );
    var selectedEvents = [];
    this.state.events.forEach((o, i) => {
      if (o.date === startDate) {
        selectedEvents.push(o);
      }
    });
    this.setState({
      holiday: startDate,
      showPopup: !this.state.showPopup,
      selectedEvents: selectedEvents,
    });
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
      isLoading: false,
      description: "",
      holiday: "",
      updateEvent: false,
      addEvent: false,
    });
  }
  /*
        hideAlert() {
            this.setState({
                alert: null,
                showMessagealert:null
            });
        }
    */
  handleChange(e) {
    this.setState({
      selectClass: e.target.value,
    });
  }
  dayColors(date) {
    var style = {
      backgroundColor: "default",
    };

    if (this.state.events !== undefined) {
      console.log("date", date);

      /*  this.state.events.forEach((prop,i)=>{

                if(prop.start===date){
                    console.log("start",prop.start);*/

      style = {
        backgroundColor: "#00ADBC",
      };
      /*  }
            })*/
    }
    // var backgroundColor = "event-";

    return {
      style: style,
    };
  }

  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }
  onClickAddEvent = () => {
    this.handleLoading();

    if (!this.state.updateEvent) {
      var data = {
        holiday: this.state.holiday,
        description: this.state.description,
        createdby: getCookie(cons.USER_NAME),
        companyId: this.props.state.companyInfo.id,
      };

      if (this.state.description !== "" && this.state.description !== null)
        requestList(
          serverApi.HOLIDAY_MASTER_ADD,
          data,
          this.handleHolidayMasterAdd
        );
      else this.basicAlert("Please Enter Holiday Description..!!");
    } else {
      var data = {
        id: this.state.id,
        holiday: this.state.holiday,
        description: this.state.description,
        createdby: getCookie(cons.USER_NAME),
        companyId: this.props.state.companyInfo.id,
      };

      if (this.state.description !== "" && this.state.description !== null)
        requestList(
          serverApi.HOLIDAY_MASTER_UPDATE,
          data,
          this.handleHolidayMasterAdd
        );
      else this.basicAlert("Please Enter Holiday Description..!!");
    }
  };
  handleHolidayMasterAdd = (resp) => {
    if (resp.status) {
      /* this.setState({
                showPopup:false,
                id:"",
                "holiday": "",
                "description":"",
                updateEvent:false,
                addEvent:false
            });
            this.callHolidayMaster();*/
      if (this.state.deleteAttend) {
        this.setState({ deleteAttend: false, isRefresh: true });
      }
      this.setState({ checked: [], isRefresh: true });
      this.showMessage(resp.message, resp.status);
      var data2 = {
        companyId: this.props.state.companyInfo.id,
        ebId: this.state.ebId_form_list,
        startingDate: this.state.startingDate,
        toDate: this.state.toDate,
        userId: getCookie(cons.USERID),
        subDeptId: this.state.subDeptId,
        designationId: this.state.designationId,
        cataId: this.state.cataId,
        workType: this.state.workType,
        startIndex: this.state.startIndex,
        lastIndex: this.state.lastIndex,
        ebNo: this.state.ebNoList,
        spell: this.state.spell,
      };

      requestList(
        serverApi.WORKER_DAILY_ATTENDACE_TABLE,
        data2,
        this.handleAttendanceList
      );
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  hideAlert() {
    // this.callAttendanceList(1, 10);
    // const {
    //   ebId_form_list,
    //   startingDate,
    //   toDate,
    //   subDeptId,
    //   designationId,
    //   cataId,
    //   workType,
    //   ebNoList,
    //   spell,
    // } = this.state;
    // //console.log("startIndex,lastIndex",startIndex,lastIndex);
    // var data = {
    //   companyId: this.props.state.companyInfo.id,
    //   ebId: ebId_form_list,
    //   startingDate: startingDate,
    //   toDate: toDate,
    //   userId: getCookie(cons.USERID),
    //   subDeptId: subDeptId,
    //   designationId: designationId,
    //   cataId: cataId,
    //   workType: workType,
    //   startIndex: this.state.startIndex,
    //   lastIndex: this.state.lastIndex,
    //   ebNo: ebNoList,
    //   spell: spell,
    // };

    // requestList(
    //   serverApi.WORKER_DAILY_ATTENDACE_TABLE,
    //   data,
    //   this.handleAttendanceList
    // );

    console.log("alert");
    if (this.state.isCreated) {
      this.setState({
        isCreated: false,
        isList: true,
        updateView: false,
        approveButton: false,
        attendanceDate: moment().format("DD-MM-YYYY"),
        attendanceMark: "",
        attendanceSource: "",
        attendanceType: "",
        ebCode: "",
        ebId: "",
        workingHours: 0,
        entryTime: "00:00:00",
        exitTime: "00:00:00",
        idleHours: 0,
        remarks: 0,
        spell: "",
        workedDepartmentId: "",
        workedDesignationId: "",
        machineIds: [],
        checked: [],
        isRefresh: true,
        ebNo: getCookie(cons.EB_NO) !== "null" ? getCookie(cons.EB_NO) : "",
        startIndex: 1,
        lastIndex: 10,
        showStatus: false,
        loadAll: true,
      });
      this.getEmpDetails();
    } else {
      this.setState({
        spell: "",
      });
    }

    // this.callAttendanceList(this.state.startIndex, this.state.lastIndex);
    this.setState({
      alert: false,
      showMessagealert: null,
      showPopup: false,
      isRefresh: !this.state.isRefresh,

      // spell: this.state.spell,
    });

    // window.location.reload();
  }
  hideBasicAlert() {
    if (this.state.deleteAttend) {
      this.handleLoading();
      getDataFromUrl(
        serverApi.WORKER_DAILY_ATTENDANCE_DETAILS_DELETE +
          this.state.dailyAttenId,
        this.handleHolidayMasterAdd
      );
    }
    this.setState({
      basicAlert: null,
    });
  }

  // showMessage = (msg) => {
  //   this.setState({
  //     showMessagealert: (
  //       <Popup
  //         //  onClickSave={() => this.onClickAddItem()}
  //         onClickSave={() => {
  //           this.hideAlert();
  //         }}
  //         isShowSave={true}
  //         save_btn_txt={"OK"}
  //         content={
  //           <form>
  //             <legend style={{ textAlign: "center" }}>{msg}</legend>
  //           </form>
  //         }
  //       />
  //     ),
  //   });
  // };

  hideAlertMessage = () => {
    // alert("Redirect Back");
    console.log("getting the data here");
    this.setState({
      showMessagealert: false,
      loading: false,
      isCreated: false,
      isList: true,
      updateView: false,
    });
    // requestList(
    //   serverApi.WORKER_DAILY_ATTENDACE_TABLE,
    //   data2,
    //   this.handleAttendanceList
    // );

    // this.setState({
    //   isCreated: false,
    //   isList: true,
    //   updateView: false,
    //   approveButton: false,
    //   attendanceDate: moment().format("DD-MM-YYYY"),
    //   attendanceMark: "",
    //   attendanceSource: "",
    //   attendanceType: "",
    //   ebCode: "",
    //   ebId: "",
    //   workingHours: 0,
    //   entryTime: "00:00:00",
    //   exitTime: "00:00:00",
    //   idleHours: 0,
    //   remarks: 0,
    //   spell: "",
    //   workedDepartmentId: "",
    //   workedDesignationId: "",
    //   machineIds: [],
    //   checked: [],
    //   isRefresh: true,
    //   ebNo: getCookie(cons.EB_NO) !== "null" ? getCookie(cons.EB_NO) : "",
    //   startIndex: 1,
    //   lastIndex: 10,
    //   showStatus: false,
    //   loadAll: true,
    // });
    // this.getEmpDetails();
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            console.log("getting the status response here", status);

            if (status) {
              this.hideAlertMessage();
            } else this.setState({ showMessagealert: false });
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "holiday")
      this.setState({
        [id]: "",
      });
  };
  logDateChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("YYYY-MM-DD"),
      });
    else
      this.setState({
        [id]: "",
      });
  };

  // eventStyleGetter=(event, start, end, isSelected) =>{
  //     console.log(event);
  //     var backgroundColor = '#' + event.hexColor;
  //     var style = {
  //         backgroundColor: backgroundColor,
  //         borderRadius: '0px',
  //         opacity: 0.8,
  //         color: 'black',
  //         border: '0px',
  //         display: 'block'
  //     };
  //     return {
  //         style: style
  //     }};
  // deleteEvent=(resp)=>{
  //     //getDataFromUrl(serverApi.HOLIDAY_MASTER_DELETE+id+"/"+getCookie(cons.CIPHER),this.)
  //     if(resp.status) {
  //         var key = this.state.selectedEventIndex;
  //         var id = this.state.id;
  //         var selectedEvents = this.state.selectedEvents;
  //         selectedEvents.splice(key, 1);
  //         var events = this.state.events;
  //         events.find((o, i) => {
  //             console.log("id", o.id);
  //             if (o.id === id) {
  //                 events.splice(i, 1);
  //                 return true;
  //             }
  //         });
  //         this.setState({
  //             selectedEvents: selectedEvents,
  //             events: events,
  //             selectedEventIndex: "",
  //             id: "",
  //             showPopup:false
  //         });
  //         this.callHolidayMaster();
  //         this.showMessage(resp.message)
  //     }else {
  //         this.showMessage(resp.message)
  //
  //     }
  // };
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  handleApi = () => {
    const {
      ebId_form_list,
      startingDate,
      toDate,
      subDeptId,
      designationId,
      cataId,
      workType,
      ebNoList,
      spell,
    } = this.state;
    //console.log("startIndex,lastIndex",startIndex,lastIndex);
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebId: ebId_form_list,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
      subDeptId: subDeptId,
      designationId: designationId,
      cataId: cataId,
      workType: workType,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      ebNo: ebNoList,
      spell: spell,
    };

    requestList(
      serverApi.WORKER_DAILY_ATTENDACE_TABLE,
      data,
      this.handleAttendanceList
    );
  };
  handleSeacrh = () => {
    var loading = false;

    if (!this.state.isRefresh) {
      this.setState({ loadAll: true });
      this.callAttendanceList(this.state.startIndex, this.state.lastIndex);
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  getLogsList = () => {
    const { from, to, employeeid, companyid } = this.state;
    var data = {
      from: from,

      to: to,

      employeeid: employeeid,
      companyid: companyid,
    };
    requestList(serverApi.ATTENDANCE_LOGS_WITH_DATES, data, this.handleLogs);
  };
  handleCompaniesList = (resp) => {
    var list = [];
    //list.push({label:"Select...",value:0,name:""})
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          label: prop.companyCode + "-" + prop.companyName,
          value: prop.companyId,
          name: prop.companyName,
        };
        list.push(obj);
      });
    }
    this.setState({ companyList: list, loadCompany: true });
  };
  handleLogs = (resp) => {
    console.log("getting data from logs", resp);
    var table = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsAccess: false,
      approveButton: false,
      totalRecords: 0,
      column: [
        {
          filterable: false,
          Header: "Emp Code",
          accessor: "employeeNo",
          minWidth: 40,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Emp Name",
          accessor: "workerName",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },

        {
          filterable: false,
          Header: "Reader",
          accessor: "deviceName",
          minWidth: 60,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        /*  {
                    "filterable": false,
                    "Header": "Lattitude",
                    "accessor": "latitude",
                    "minWidth": 60,
                    "style": {
                        "textAlign": "center"
                    },
                    "sortable": false
                },
                {
                    "filterable": false,
                    "Header": "Longitude",
                    "accessor": "logitude",
                    "minWidth": 60,
                    "style": {
                        "textAlign": "center"
                    },
                    "sortable": false
                },*/
        {
          filterable: false,
          Header: "Location",
          accessor: "location_description",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Network",
          accessor: "network_Name",
          minWidth: 50,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Ip Address",
          accessor: "ip_Address",
          minWidth: 60,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Remarks",
          accessor: "remarks",
          minWidth: 50,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Transaction Time",
          accessor: "transaction_time",
          minWidth: 100,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Status",
          accessor: "statusDesc",
          minWidth: 60,
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
      ],
    };

    if (resp) {
      table.data = resp;
    }
    this.setState({ logsTable: table, loadLogs: true });
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("HH:mm:ss"),
      });
  };

  approveAttendance = () => {
    var data = {
      approveList: this.state.checked,
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };

    if (this.state.checked.length > 0) {
      this.handleLoading();
      requestList(
        serverApi.DAILY_ATTENDANCE_APPROVE,
        data,
        this.handleHolidayMasterAdd
      );
    } else {
      this.basicAlert("Please Select Atleast One Record..!!");
    }
  };

  getData = () => {
    const { ebNoList, startingDate, toDate } = this.state;
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebNo: ebNoList,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
    };
    return data;
  };
  dateChanged = (date, time) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2] + " " + time;
    return new Date(newDate).getTime();
  };
  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        attendanceDate,
        attendanceMark,
        attendanceSource,
        attendanceType,
        ebCode,
        ebId,
        ebNo,
        workingHours,
        entryTime,
        exitTime,
        idleHours,
        remarks,
        spell,
        workedDepartmentId,
        workedDesignationId,
        machineIds,
      } = this.state;
      var data = {
        attendanceDate: this.dateChanged(attendanceDate, "00:00:00"),
        attendanceMark: attendanceMark,
        attendanceSource: attendanceSource,
        attendanceType: attendanceType,
        deviceId: 0,
        ebCode: ebCode,
        ebId: ebId,
        ebNo: ebNo,
        entryTime: this.dateChanged(attendanceDate, entryTime),
        exitTime: this.dateChanged(attendanceDate, exitTime),
        idleHours: idleHours,
        remarks: remarks,
        spell: spell,
        workedDepartmentId: workedDepartmentId,
        workedDesignationId: workedDesignationId,
        workingHours: workingHours,
        statusId: "1",
        machineIds: machineIds,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
      };

      //new code for validation....

      const validateFields = [
        { field: this.state.attendanceDate, msg: "Attendance Date", type: 2 },
        { field: this.state.ebNo, msg: "Employee Code", type: 1 },
        { field: this.state.attendanceMark, msg: "Attendance Mark", type: 2 },
        { field: this.state.attendanceType, msg: "Attendance Type", type: 2 },
        { field: this.state.workingHours, msg: "Working Hours", type: 1 },
        { field: this.state.spell, msg: "Spell", type: 2 },
        { field: this.state.workedDepartmentId, msg: "Department", type: 2 },
        { field: this.state.workedDesignationId, msg: "Designation", type: 2 },
      ];

      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.WORKER_DAILY_ATTENDANCE_ADD,
          data,
          this.handleHolidayMasterAdd
        );
        var data2 = {
          companyId: this.props.state.companyInfo.id,
          ebId: this.state.ebId_form_list,
          startingDate: this.state.startingDate,
          toDate: this.state.toDate,
          userId: getCookie(cons.USERID),
          subDeptId: this.state.subDeptId,
          designationId: this.state.designationId,
          cataId: this.state.cataId,
          workType: this.state.workType,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
          ebNo: this.state.ebNoList,
          spell: this.state.spell,
        };

        requestList(
          serverApi.WORKER_DAILY_ATTENDACE_TABLE,
          data2,
          this.handleAttendanceList
        );
        // this.props.history.push("/attencalendar");
      }

      // old code for validation.....

      // if (
      //   attendanceDate !== "" &&
      //   attendanceMark !== "" &&
      //   ebId !== "" &&
      //   workingHours !== "" &&
      //   workedDepartmentId > 0 &&
      //   workedDesignationId > 0 &&
      //   spell !== "" &&
      //   spell !== "0" &&
      //   attendanceType !== "" &&
      //   spell !== "" &&
      //   spell !== "0"
      // ) {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.WORKER_DAILY_ATTENDANCE_ADD,
      //     data,
      //     this.handleHolidayMasterAdd
      //   );
      //   var data2 = {
      //     companyId: this.props.state.companyInfo.id,
      //     ebId: this.state.ebId_form_list,
      //     startingDate: this.state.startingDate,
      //     toDate: this.state.toDate,
      //     userId: getCookie(cons.USERID),
      //     subDeptId: this.state.subDeptId,
      //     designationId: this.state.designationId,
      //     cataId: this.state.cataId,
      //     workType: this.state.workType,
      //     startIndex: this.state.startIndex,
      //     lastIndex: this.state.lastIndex,
      //     ebNo: this.state.ebNoList,
      //     spell: this.state.spell,
      //   };

      //   requestList(
      //     serverApi.WORKER_DAILY_ATTENDACE_TABLE,
      //     data2,
      //     this.handleAttendanceList
      //   );
      //   // this.props.history.push("/attencalendar");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        dailyAttenId,
        attendanceDate,
        attendanceMark,
        attendanceSource,
        attendanceType,
        ebCode,
        ebId,
        ebNo,
        workingHours,
        entryTime,
        exitTime,
        idleHours,
        remarks,
        spell,
        workedDepartmentId,
        workedDesignationId,
        machineIds,
      } = this.state;

      var data = {
        dailyAttenId: dailyAttenId,
        attendanceDate: this.dateChanged(attendanceDate, "00:00:00"),
        attendanceMark: attendanceMark,
        attendanceSource: attendanceSource,
        attendanceType: attendanceType,
        deviceId: 0,
        ebCode: ebCode,
        ebId: ebId,
        ebNo: ebNo,
        entryTime: this.dateChanged(attendanceDate, entryTime),
        exitTime: this.dateChanged(attendanceDate, exitTime),
        idleHours: idleHours,
        remarks: remarks,
        spell: spell,
        workedDepartmentId: workedDepartmentId,
        workedDesignationId: workedDesignationId,
        workingHours: workingHours,
        statusId: status,
        machineIds: machineIds,
        updatedBy: getCookie(cons.USERID),
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
      };
      const validateFields = [
        { field: this.state.attendanceDate, msg: "Attendance Date", type: 2 },
        { field: this.state.ebNo, msg: "Employee Code", type: 1 },
        { field: this.state.attendanceMark, msg: "Attendance Mark", type: 2 },
        { field: this.state.attendanceType, msg: "Attendance Type", type: 2 },
        { field: this.state.workingHours, msg: "Working Hours", type: 1 },
        { field: this.state.spell, msg: "Spell", type: 2 },
        { field: this.state.workedDepartmentId, msg: "Department", type: 2 },
        { field: this.state.workedDesignationId, msg: "Designation", type: 2 },
      ];

      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        // alert("Created Successfully!!");
        this.handleLoading();
        requestList(
          serverApi.WORKER_DAILY_ATTENDANCE_UPDATE,
          data,
          this.handleHolidayMasterAdd
        );
        // var data2 = {
        //   companyId: this.props.state.companyInfo.id,
        //   ebId: this.state.ebId_form_list,
        //   startingDate: this.state.startingDate,
        //   toDate: this.state.toDate,
        //   userId: getCookie(cons.USERID),
        //   subDeptId: this.state.subDeptId,
        //   designationId: this.state.designationId,
        //   cataId: this.state.cataId,
        //   workType: this.state.workType,
        //   startIndex: this.state.startIndex,
        //   lastIndex: this.state.lastIndex,
        //   ebNo: this.state.ebNoList,
        //   spell: this.state.spell,
        // };

        // // requestList(
        // //   serverApi.WORKER_DAILY_ATTENDACE_TABLE,
        // //   data2,
        // //   this.handleAttendanceList
        // // );
      }
      // if (
      //   attendanceDate !== "" &&
      //   attendanceMark !== "" &&
      //   ebId !== "" &&
      //   workingHours !== "" &&
      //   workedDepartmentId > 0 &&
      //   workedDesignationId > 0 &&
      //   attendanceType !== "" &&
      //   spell !== "" &&
      //   spell !== "0"
      // ) {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.WORKER_DAILY_ATTENDANCE_UPDATE,
      //     data,
      //     this.handleHolidayMasterAdd
      //   );
      //   var data2 = {
      //     companyId: this.props.state.companyInfo.id,
      //     ebId: this.state.ebId_form_list,
      //     startingDate: this.state.startingDate,
      //     toDate: this.state.toDate,
      //     userId: getCookie(cons.USERID),
      //     subDeptId: this.state.subDeptId,
      //     designationId: this.state.designationId,
      //     cataId: this.state.cataId,
      //     workType: this.state.workType,
      //     startIndex: this.state.startIndex,
      //     lastIndex: this.state.lastIndex,
      //     ebNo: this.state.ebNoList,
      //     spell: this.state.spell,
      //   };

      //   requestList(
      //     serverApi.WORKER_DAILY_ATTENDACE_TABLE,
      //     data2,
      //     this.handleAttendanceList
      //   );
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  handleViewClick = (id) => {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.WORKER_DAILY_ATTENDANE_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.data;
    var lineitems = header.machineDetails;
    var machineIds = [];

    header.machineDetails.map((prop, i) => {
      if (resp.status) {
        if (lineitems !== null) {
          machineIds.push(prop.machineId);
        }
      }
    });
    this.setState({
      machineIds: machineIds,
    });

    if (resp.status) {
      if (header !== null) {
        this.setState({
          updateView: true,
          isCreated: true,
          isList: false,
          createdOn: header.updatedOnDesc,
          dailyAttenId: header.dailyAttenId,
          ebId: header.ebId,
          ebCode: header.ebCode,
          ebNo: header.ebNo,
          attendanceDate: header.attendanceDateDesc,
          entryTime: header.entryTimeDesc,
          exitTime: header.exitTimeDesc,
          workingHours: header.workingHours,
          attendanceMark: header.attendanceMark,
          attendanceSource: header.attendanceSource,
          attendanceType: header.attendanceType,
          workedDesignationId: header.workedDesignationId,
          workedDepartmentId: header.workedDepartmentId,
          deviceId: header.deviceId,
          statusId: header.statusId,
          remarks: header.remarks,
          idleHours: header.idleHours,
          spell: header.spell,
          spellHours: header.spellHours,
          isActive: header.isActive,
          approveButton: header.approveButton,
          createdDateTime: header.createdDateTime,
          createdBy: header.createdBy,
          updateDateTime: header.updateDateTime,
          updatedBy: header.updatedBy,
          // machineIds: lineitems.machineId,
          // machineIds: header.machineId,
        });
      }
    }
  };

  handleFetchResp = (resp) => {
    console.log("sdd", resp.message);
    // alert(resp.message);
    // this.showMessage(resp.message, false);
    if (resp.status === true) {
      this.basicAlert(resp.message);
    }
  };

  handleProcess = () => {
    const {
      paySchemeId1,
      deptCode,
      fDate,
      tDate,
      branchId,
      cataId,
      from,
      to,
    } = this.state;
    if (fDate !== "" && tDate !== "") {
      var today = new Date();
      // const fdt_arr = fDate.split("-");
      // const tdt_arr = tDate.split("-");
      // let fromDt = fdt_arr[2] + "-" + fdt_arr[1] + "-" + fdt_arr[0];
      // let toDt = tdt_arr[2] + "-" + tdt_arr[1] + "-" + tdt_arr[0];
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      getDataFromUrl(
        serverApi.PROCESS_PROCESSDATA_COLLECTION_FROM_ATTENDANCE +
          from +
          " 00:00:00" +
          "/" +
          to +
          " " +
          time +
          "/" +
          getCookie(cons.USERID),
        this.handleFetchResp
      );
    } else {
      this.basicAlert("Please Select From and To Date!!");
    }
  };

  handleDesig = (resp) => {
    console.log("getting response", resp);
    var list = [];
    resp.map((prop, ind) => {
      list.push({
        value: prop.value,
        label: prop.label,
        name: prop.name,
      });
    });
    this.setState({
      designationList: list,
    });
    console.log("getting complete response", this.state.designationList);
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
    if (stateKey === "ebIdList") {
      this.callHolidayMaster(
        this.state.monthStart,
        this.state.monthEnd,
        selectedValue
      );
      this.callAttendanceSummary(
        this.state.monthStart,
        this.state.monthEnd,
        selectedValue
      );
    }
    if (stateKey === "spell") {
      this.setState({ isReloadspell: true });
    }
    if (stateKey === "workedDesignationId") {
      this.setState({ reloadMachine: true });
    }
    if (stateKey === "workedDepartmentId") {
    }
  };
  handlePage = (start, end) => {
    this.setState({
      startIndex: start,
      lastIndex: end,
    });
    console.log("start", start, end);
    this.callAttendanceList(start, end);

    //this.handleSeacrh();
  };
  getEmpDetails = () => {
    const { ebNo } = this.state;
    if (ebNo !== "") {
      getDataFromUrl(
        serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
          ebNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.USERID),
        this.handleEmpDetails
      );
    } else if (ebNo === "") {
      if (this.state.showStatus)
        this.basicAlert("Please Enter Employee No..!!");
    }
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      if (resp.data.active == "Y") {
        var header = resp.data;

        this.setState({
          empName: header.workerName,
          dept: header.deptIdDesc,
          occupation: header.designationIdDesc,
          ebCode: header.empCode,
          workedDepartmentId: header.deptId,
          workedDesignationId: header.designationId,
          ebNo: header.ebNo,
          loadDesig: true,
          loadDept: true,
          ebId: header.ebId,
        });
      } else {
        if (this.state.showStatus)
          this.showMessage("Entered employee is inactive!!!", false);
      }
    } else {
      if (this.state.showStatus) this.showMessage(resp.message, false);
    }
  };

  ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < moment().toDate() ? "blue" : "red",
      },
    });

  handlePrintData = () => {
    // console.log("getting data for USERID", resp);
    const {
      ebId_form_list,
      startingDate,
      toDate,
      subDeptId,
      designationId,
      cataId,
      workType,
      ebNoList,
      spell,
    } = this.state;
    //console.log("startIndex,lastIndex",startIndex,lastIndex);
    var data = {
      companyId: this.props.state.companyInfo.id,
      ebId: ebId_form_list,
      startingDate: startingDate,
      toDate: toDate,
      userId: getCookie(cons.USERID),
      subDeptId: subDeptId,
      designationId: designationId,
      cataId: cataId,
      workType: workType,
      // startIndex: this.state.startIndex,
      // lastIndex: this.state.lastIndex,
      ebNo: ebNoList,
      spell: spell,
    };
    console.log(
      "getting deliveryOrderId",
      this.state.deliveryOrderNo,
      "getting UserId",
      getCookie(cons.USERID),
      "getting companyId",
      this.props.state.companyInfo.id
    );
    var headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "localhost:3000",
      "Content-Type": "application/json",
    };
    fetch(serverApi.DOWNLOAD_ATTENDENCE_LIST, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())

      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute(
          "download",

          `ATTENDENCE.pdf`
        );

        // Append to html link element page

        document.body.appendChild(link);

        // Start download

        link.click();

        // Clean up and remove the link

        link.parentNode.removeChild(link);
      });
  };

  render() {
    const { classes, ...rest } = this.props;
    console.log("getting complete response oii", this.state.designationList);
    var yesterday = Datetime.moment();

    var valid = function(current) {
      // console.log("getting the selected data here",);
      if (getCookie(cons.COMPANY_ID) !== "2")
        return yesterday.isSameOrAfter(current);
      else return true;
    };
    console.log("events", this.state.checked);

    return (
      <div>
        {this.state.basicAlert}
        {this.state.alert}
        <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
          {this.state.isList ? (
            <ItemGrid xs={12}>
              {this.state.showMessagealert}
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <div>
                    <GridContainer>
                      <ItemGrid xs={10}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Starting Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="outlined" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(dateObj, "startingDate");
                                    }}
                                    formControlProps={{
                                      height: "10px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "startingDate",
                                      value: this.state.startingDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  To Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="outlined" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.timeChanged(dateObj, "toDate");
                                    }}
                                    formControlProps={{
                                      height: "10px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "toDate",
                                      value: this.state.toDate,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/*
                                                    <ItemGrid xs={12} md={6}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Employee
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <SelectboxTemp
                                                                    defValue={this.state.ebId_form_list}
                                                                    onSelect={this.onSelect}
                                                                    stateKey="ebId_form_list"
                                                                       isRefresh={this.state.loadEmp}
                                                                       setIsRefresh={(val)=>{
                                                                           this.setState({loadEmp:false})
                                                                       }}
                                                                    staticData={this.state.empList}
                                                                    value={this.state.ebId_form_list}
                                                                />
                                                            </ItemGrid>

                                                        </GridContainer>
                                                    </ItemGrid>
*/}
                          {/*
                                                    <ItemGrid xs={12} md={4}>
                                                        <GridContainer>
                                                            <ItemGrid xs={12} sm={4}>
                                                                <InputLabel className={classes.label}>
                                                                    Emp Code
                                                                </InputLabel>
                                                            </ItemGrid>
                                                            <ItemGrid xs={12} sm={8}>
                                                                <TextField
                                                                    id="ebId_form_list"
                                                                    value={this.state.ebId_form_list}
                                                                    className={classes.textField}
                                                                    onChange={this.onChangeValues}
                                                                    fullWidth={true}

                                                                />

                                                            </ItemGrid>

                                                        </GridContainer>
                                                    </ItemGrid>
*/}
                          <ItemGrid xs={12} md={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Designation
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.designationId}
                                  onSelect={this.onSelect}
                                  //  getName={this.getQualityName}
                                  stateKey="designationId"
                                  url={
                                    serverApi.SELECT_DESIGNATION +
                                    this.props.state.companyInfo.id
                                  }
                                  value={this.state.designationId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={4}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Department
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.subDeptId}
                                  onSelect={this.onSelect}
                                  //  getName={this.getQualityName}
                                  stateKey="subDeptId"
                                  url={
                                    serverApi.SELECT_SUB_DEPARTMENT_MASTER +
                                    this.props.state.companyInfo.id
                                  }
                                  value={this.state.workedDepartmentId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={4}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Category
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.cataId}
                                  onSelect={this.onSelect}
                                  stateKey="cataId"
                                  url={
                                    serverApi.SELECT_WORKER_CATEGORY +
                                    this.props.state.companyInfo.id
                                  }
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={4}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Attendance Type
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.workType}
                                  onSelect={this.onSelect}
                                  stateKey="workType"
                                  staticData={AttendanceType}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid
                            xs={12}
                            md={4}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Emp Codes
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="ebNoList"
                                  value={this.state.ebNoList}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          <ItemGrid xs={12} sm={4}>
                            <GridContainer>
                              <ItemGrid
                                xs={12}
                                sm={4}
                                style={{ marginTop: "10px" }}
                              >
                                <InputLabel
                                  className={classes.label}
                                  setIsReload={this.state.isReload}
                                  isRefresh={this.state.isRefresh}
                                >
                                  Spell
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid
                                xs={12}
                                sm={8}
                                style={{ marginTop: "10px" }}
                              >
                                <SelectboxTemp
                                  defValue={this.state.spell}
                                  id={"spell"}
                                  getName={this.getQualityDetails}
                                  onSelect={this.onSelect}
                                  isReload={this.state.reloadSelect}
                                  // setIsReload={this.afterReloadSelect}
                                  stateKey="spell"
                                  url={
                                    serverApi.GET_ALL_SPELL_LIST +
                                    this.props.state.companyInfo.id +
                                    "/" +
                                    getCookie(cons.CIPHER)
                                  }
                                  value={this.state.spell}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>

                      <ItemGrid xs={12} sm={2}>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              isCalendar: true,
                              isList: false,
                              isCreated: false,
                            });
                          }}
                          right={true}
                          // round color="white"
                          color={"primary"}
                        >
                          <CalendarIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            this.setState({
                              isCreated: true,
                              isList: false,
                              ebId_form_list: "",
                            });
                          }}
                          color="success"
                        >
                          <Add />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            this.getWorkersList();
                            this.getLogsList();
                            this.setState({
                              isCalendar: false,
                              isList: false,
                              isLog: true,
                            });
                          }}
                          // round color="white"
                          color={"primary"}
                          right={true}
                        >
                          <img src={LogsIcon} width={30} height={25} />
                        </IconButton>
                        <Button
                          color="primary"
                          right={true}
                          onClick={this.handleSeacrh}
                          style={{ marginTop: "10px" }}
                        >
                          Search
                        </Button>
                      </ItemGrid>

                      <ItemGrid
                        xs={12}
                        style={{
                          height: 2,
                          backgroundColor: "#00acc1",
                          marginTop: "5px",
                        }}
                      />

                      <ItemGrid xs={12}>
                        {this.state.tableList !== null ? (
                          <>
                            <ReportsTableComponent
                              //url={serverApi.WORKER_DAILY_ATTENDACE_TABLE}
                              staticData={this.state.tableList}
                              borderRight={true}
                              searchData={this.getData()}
                              isRefresh={this.state.isRefresh}
                              handleSearch={this.handleSeacrh.bind(this)}
                              handleLoading={this.handleLoading.bind(this)}
                              // isLoading={this.state.isRefresh}
                              onActionClick={this.handleViewClick}
                              actionType={VIEW}
                              fileName={"Attendace calendar"}
                              showDelete={true}
                              //  dataTableAll={this.state.dataTableAll}
                              handlePage={this.handlePage.bind(this)}
                              showPagination={true}
                              onDeleteClick={(id) => {
                                this.setState({
                                  deleteAttend: true,
                                  dailyAttenId: id,
                                });
                                this.basicAlert(
                                  "Do Like To Delete This Attendance?"
                                );
                              }}
                              itemViewType={this.state.itemViewType}
                            />
                            <div className="exportPdf">
                              <IconButton
                                color="primary"
                                onClick={this.handlePrintData}
                              >
                                {<Print />}
                              </IconButton>
                            </div>
                          </>
                        ) : null}

                        {this.state.tableList !== null
                          ? // <IconButton
                            //   right={true}
                            //   style={{ marginTop: "-40px" }}
                            //   color="success"
                            //   onClick={() => {
                            //     this.callAttendanceListTotal(
                            //       this.state.startIndex,
                            //       this.state.totalRecords
                            //     );
                            //   }}
                            // >
                            //   <ExpandMoreIcon />
                            // </IconButton>
                            ""
                          : null}
                      </ItemGrid>
                      {this.state.checked.length > 0 ? (
                        <ItemGrid xs={12} sm={6}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.approveAttendance}
                          >
                            Approve{" "}
                          </Button>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </div>
                }
              />
            </ItemGrid>
          ) : null}
          {this.state.isLog ? (
            <ItemGrid xs={12}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <div>
                    <GridContainer>
                      <ItemGrid xs={10}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={6}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  From Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="outlined" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.logDateChanged(dateObj, "from");
                                    }}
                                    formControlProps={{
                                      height: "10px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "from",
                                      value: this.state.from,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  To Date
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <FormControl variant="outlined" fullWidth>
                                  <Datetime
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    onChange={(dateObj) => {
                                      this.logDateChanged(dateObj, "to");
                                    }}
                                    formControlProps={{
                                      height: "10px",
                                      inlineBlock: "none",
                                    }}
                                    inputProps={{
                                      id: "to",
                                      value: this.state.to,
                                      autoComplete: "off",
                                    }}
                                  />
                                </FormControl>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Employee
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.employeeid}
                                  onSelect={this.onSelect}
                                  stateKey="employeeid"
                                  staticData={this.state.workersList}
                                  isRefresh={this.state.loadWorkers}
                                  setIsRefresh={() => {
                                    this.setState({ loadWorkers: false });
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Company
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.companyid}
                                  onSelect={this.onSelect}
                                  stateKey="companyid"
                                  staticData={this.state.companyList}
                                  isRefresh={this.state.loadCompany}
                                  setIsRefresh={() => {
                                    this.setState({ loadCompany: false });
                                  }}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/* <ItemGrid xs={12} sm={6}>
                            <Button
                              color="primary"
                              right={true}
                              onClick={() => {
                                this.getLogsList();
                              }}
                            >
                              Search
                            </Button>
                          </ItemGrid> */}
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2}>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              isCalendar: true,
                              isList: false,
                              isCreated: false,
                              isLog: false,
                            });
                          }}
                          // round color="white"
                          right={true}
                          color={"primary"}
                        >
                          <CalendarIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            this.handleSeacrh();
                            this.setState({
                              isCalendar: false,
                              isList: true,
                              isLog: false,
                            });
                          }}
                          // round color="white"
                          color={"primary"}
                          right={true}
                        >
                          <List />
                        </IconButton>
                        <Button
                          color="primary"
                          right={true}
                          onClick={() => {
                            this.getLogsList();
                          }}
                        >
                          Search
                        </Button>
                      </ItemGrid>

                      <ItemGrid
                        xs={12}
                        style={{
                          height: 2,
                          backgroundColor: "#00acc1",
                          marginTop: "5px",
                        }}
                      />

                      <ItemGrid xs={12}>
                        {this.state.logsTable ? (
                          <ReportsTableComponent
                            // url={serverApi.WORKER_DAILY_ATTENDACE_TABLE}
                            staticData={this.state.logsTable}
                            borderRight={true}
                            searchData={this.getData()}
                            isRefresh={this.state.loadLogs}
                            handleSearch={() => {
                              this.setState({
                                loadLogs: !this.state.loadLogs,
                              });
                            }}
                            handleLoading={this.handleLoading.bind(this)}
                            // isLoading={this.state.isRefresh}
                            //   onActionClick={this.handleViewClick}
                            //  actionType={VIEW}
                            fileName={"Attendace Logs"}
                            itemViewType={this.state.itemViewType}
                          />
                        ) : null}
                        <ItemGrid xs={11} style={{ marginTop: "-40px" }}>
                          <itemGrid>
                            <Button
                              color="info"
                              style={{ width: 130 }}
                              right={true}
                              onClick={this.handleProcess}
                            >
                              Process Logs
                            </Button>
                          </itemGrid>
                        </ItemGrid>
                      </ItemGrid>
                    </GridContainer>
                  </div>
                }
              />
            </ItemGrid>
          ) : null}
          {this.state.isCreated ? (
            <ItemGrid xs={12}>
              <RegularCard
                isLoading={this.state.isLoading}
                cardTitle={
                  <Button
                    color={"info"}
                    onClick={this.hideAlert}
                    isReload={this.state.spell}
                  >
                    Back To List
                  </Button>
                }
                content={
                  <GridContainer>
                    {this.state.basicAlert}
                    {this.state.showMessagealert}
                    <ItemGrid xs={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Emp Code
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="ebNo"
                            value={this.state.ebNo}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color: "#00ABDC",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <Search
                                      className={classes.inputAdornmentIcon}
                                      onClick={() => {
                                        this.setState({ showStatus: true });
                                        this.getEmpDetails();
                                      }}
                                    />
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Attendance Date
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              isValidDate={valid}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "attendanceDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "attendanceDate",
                                value: this.state.attendanceDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    {/*
                                <ItemGrid xs={12} md={6}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Emp Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.ebId}
                                                onSelect={this.onSelect}
                                                stateKey="ebId"
                                                getName={(name)=>{
                                                    var val=name.split("^");
                                                    this.setState({ebCode:val[1],workedDepartmentId:parseInt(val[3])
                                                        ,workedDesignationId:parseInt(val[2]),ebNo:val[4],
                                                        loadDesig:true,
                                                        loadDept:true
                                                    })
                                                }}
                                                staticData={this.state.empList}
                                                value={this.state.ebId}
                                            />
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>
*/}

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Attendance Mark
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.attendanceMark}
                            onSelect={this.onSelect}
                            stateKey="attendanceMark"
                            staticData={AttendanceMark}
                            value={this.state.attendanceMark}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    {this.state.updateView ? (
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Attendance Source
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="attendanceSource"
                              value={this.state.attendanceSource}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : (
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Attendance Source
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <SelectboxTemp
                              selectDisabled={false}
                              defValue={"M"}
                              onSelect={this.onSelect}
                              stateKey="attendanceSource"
                              staticData={Attendancesource}
                              value={this.state.attendanceSource}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    )}

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Attendance Type
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.attendanceType}
                            onSelect={this.onSelect}
                            stateKey="attendanceType"
                            staticData={AttendanceType}
                            value={this.state.attendanceType}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Idle Hours
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="idleHours"
                            value={this.state.idleHours}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            iType="number"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Working Hours
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="workingHours"
                            value={this.state.workingHours}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            iType="number"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Entry Time
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              closeOnSelect={true}
                              timeFormat={"HH:mm:ss"}
                              // defaultValue={this.state.time1}
                              dateFormat={false}
                              onChange={(timeObj) => {
                                this.timeChanged1(timeObj, "entryTime");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "entryTime",
                                value: this.state.entryTime,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Exit Time
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              defaultValue={new Date()}
                              closeOnSelect={true}
                              timeFormat={"HH:mm:ss"}
                              // defaultValue={this.state.time1}
                              dateFormat={false}
                              onChange={(timeObj) => {
                                this.timeChanged1(timeObj, "exitTime");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "exitTime",
                                value: this.state.exitTime,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Spell
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.spell}
                            id={"spell"}
                            getName={this.getQualityDetails}
                            onSelect={this.onSelect}
                            isReload={this.state.reloadSelect}
                            setIsReload={this.afterReloadSelect}
                            stateKey="spell"
                            url={
                              serverApi.GET_ALL_SPELL_LIST +
                              this.props.state.companyInfo.id +
                              "/" +
                              getCookie(cons.CIPHER)
                            }
                            value={this.state.spell}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Dept
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.workedDepartmentId}
                            onSelect={this.onSelect}
                            //  getName={this.getQualityName}
                            stateKey="workedDepartmentId"
                            isReload={this.state.loadDept}
                            setIsReload={(val) => {
                              this.setState({ loadDept: val });
                            }}
                            staticData={this.state.departmentList}
                            isRefresh={this.state.loadQuality}
                            setIsRefresh={this.afterRefreshQuality}
                            // url={
                            //   serverApi.SELECT_SUB_DEPARTMENT_MASTER +
                            //   this.props.state.companyInfo.id
                            // }
                            value={this.state.workedDepartmentId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Designation
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            defValue={this.state.workedDesignationId}
                            onSelect={this.onSelect}
                            //  getName={this.getQualityName}
                            staticData={this.state.designationList}
                            stateKey="workedDesignationId"
                            isReload={this.state.loadDesig}
                            setIsReload={(val) => {
                              this.setState({ loadDesig: false });
                            }}
                            // url={
                            //   serverApi.SELECT_DESIGNATION +
                            //   this.props.state.companyInfo.id
                            // }
                            value={this.state.workedDesignationId}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      md={6}
                      style={{
                        marginTop: "10px",
                        display: this.state.machineIds == "" ? "none" : "block",
                      }}
                    >
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Machine No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxMultiple
                            id={"machineIds"}
                            //selectDisabled={this.state.updateView}
                            defValue={this.state.machineIds}
                            onSelect={this.onSelect}
                            isRefresh={this.state.reloadMachine}
                            setIsRefresh={(val) => {
                              this.setState({ reloadMachine: false });
                            }}
                            //  getName={this.getName}
                            stateKey="machineIds"
                            url={
                              serverApi.MACHINES_BY_DESIGNATION_NEW +
                              "/" +
                              this.state.workedDesignationId +
                              "/company/" +
                              this.props.state.companyInfo.id
                            }
                            value={this.state.machineIds}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Remarks
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="remarks"
                            value={this.state.remarks}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            height={80}
                            rowsMax={4}
                            multiline={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    {this.state.updateView ? (
                      <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Created By
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="createdBy"
                              value={this.state.createdBy}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null}
                    {this.state.updateView ? (
                      <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Created On
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              disabled={true}
                              id="createdOn"
                              value={this.state.createdOn}
                              className={classes.textField}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null}
                    <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                      <GridContainer justify="center" />
                    </ItemGrid>

                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {this.state.approveButton ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                // this.onUpdateButtonClick(this.state.statusId);
                                this.onUpdateButtonClick(3);
                              }}
                            >
                              Approve
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.updateView &&
                        this.state.statusId !== "3" ? (
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={() => {
                                this.onUpdateButtonClick("");
                              }}
                            >
                              {/* {typeof this.state.statusId} */}
                              Update
                            </Button>
                          </ItemGrid>
                        ) : null}

                        {!this.state.updateView ? (
                          <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                            <GridContainer justify="center">
                              <ItemGrid>
                                <Button
                                  color="primary"
                                  onClick={this.onCreateButtonClick}
                                >
                                  Create
                                </Button>
                              </ItemGrid>
                              <ItemGrid>
                                <Button color="danger" onClick={this.hideAlert}>
                                  Cancel
                                </Button>
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}

          {this.state.isCalendar ? (
            <ItemGrid xs={12}>
              <GridContainer>
                <ItemGrid xs={12}>
                  {this.state.showMessagealert}
                  <RegularCard
                    //  isLoading={this.state.isLoading}
                    content={
                      <GridContainer>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={3}>
                              <InputLabel className={classes.label}>
                                Emp Code
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={6}>
                              <TextField
                                id="ebIdList"
                                value={this.state.ebIdList}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                              />

                              {/*
                                                        <SelectboxTemp
                                                            defValue={this.state.ebIdList}
                                                            onSelect={this.onSelect}
                                                            stateKey="ebIdList"
                                                            isRefresh={this.state.loadEmp}
                                                            setIsRefresh={(val)=>{
                                                                this.setState({loadEmp:false})
                                                            }}
                                                            staticData={this.state.empList}
                                                            value={this.state.ebIdList}
                                                        />
*/}
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="primary"
                                right={true}
                                onClick={() => {
                                  if (this.state.ebIdList !== "") {
                                    this.callHolidayMaster(
                                      this.state.monthStart,
                                      this.state.monthEnd,
                                      this.state.ebIdList
                                    );
                                    this.callAttendanceSummary(
                                      this.state.monthStart,
                                      this.state.monthEnd,
                                      this.state.ebIdList
                                    );
                                  } else {
                                    this.setState({
                                      AttendanceSummary: [],
                                      events: [],
                                    });
                                    this.basicAlert(
                                      "Please Enter Emp Code..!!"
                                    );
                                  }
                                }}
                              >
                                Search
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={6}>
                          <IconButton
                            onClick={() => {
                              this.getWorkersList();
                              this.getLogsList();
                              this.setState({
                                isCalendar: false,
                                isList: false,
                                isLog: true,
                              });
                            }}
                            // round color="white"
                            color={"primary"}
                            right={true}
                          >
                            <img src={LogsIcon} width={30} height={25} />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              this.handleSeacrh();
                              this.setState({
                                isCalendar: false,
                                isList: true,
                                isLog: false,
                              });
                            }}
                            // round color="white"
                            color={"primary"}
                            right={true}
                          >
                            <List
                              onClick={() => {
                                this.handleApi();
                              }}
                            />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    }
                  />
                </ItemGrid>
                <ItemGrid xs={12} md={4}>
                  <GridContainer>
                    <ItemGrid xs={12}>
                      <RegularCard
                        cardTitle={"Attendace Summary"}
                        content={
                          <ReactTable
                            data={this.state.AttendanceSummary}
                            filterable={false}
                            resizable={false}
                            loading={this.state.loading}
                            getTableProps={() => {
                              return {
                                style: {
                                  border: "2px solid #00ABDC",
                                  borderRadius: "4px",
                                },
                              };
                            }}
                            getTheadProps={() => {
                              return {
                                style: {
                                  borderBottom: "2px solid #00ABDC",
                                  //height:45
                                  //lineHeight:"1.3"
                                },
                              };
                            }}
                            getTheadThProps={() => {
                              return {
                                style: {
                                  borderRight: "1px solid #999999",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                  lineHeight: 1.5,
                                },
                              };
                            }}
                            style={{
                              color: "#00ABDC",
                              textAlign: "center",
                              overflowY: "auto",
                            }}
                            columns={[
                              {
                                Header: "For Month",
                                accessor: "type",
                                minWidth: 30,
                                style: { color: "#000", textAlign: "left" },
                              },
                              {
                                Header:
                                  "" +
                                  this.state.currentMonth +
                                  "  " +
                                  this.state.currentYear,
                                accessor: "value",
                                minWidth: 30,
                                style: { color: "#000", textAlign: "center" },
                              },
                            ]}
                            showPagination={false}
                            pageSize={8}
                            //  defaultPageSize={5}
                            sortable={false}
                            getNoDataProps={() => {
                              return {
                                style: {
                                  display: "none",
                                },
                              };
                            }}
                            className="-striped -highlight"
                          />
                        }
                      />
                    </ItemGrid>
                    <ItemGrid xs={12}>
                      <RegularCard
                        cardTitle={"Bio Device Update Status"}
                        content={
                          <ReactTable
                            data={this.state.lastBiomerticData}
                            filterable={false}
                            resizable={false}
                            loading={this.state.loading}
                            getTableProps={() => {
                              return {
                                style: {
                                  border: "2px solid #00ABDC",
                                  borderRadius: "4px",
                                },
                              };
                            }}
                            getTheadProps={() => {
                              return {
                                style: {
                                  borderBottom: "2px solid #00ABDC",
                                  //height:45
                                  //lineHeight:"1.3"
                                },
                              };
                            }}
                            getTheadThProps={() => {
                              return {
                                style: {
                                  borderRight: "1px solid #999999",
                                  textAlign: "center",
                                  whiteSpace: "pre-wrap",
                                  lineHeight: 1.3,
                                },
                              };
                            }}
                            style={{
                              color: "#00ABDC",
                              textAlign: "center",
                              overflowY: "auto",
                            }}
                            columns={[
                              {
                                Header: "Location",
                                accessor: "location",
                                minWidth: 30,
                                style: { color: "#000", textAlign: "left" },
                              },
                              {
                                Header: "Last Updated",
                                accessor: "lastUpdated",
                                minWidth: 30,
                                style: { color: "#000", textAlign: "left" },
                              },
                            ]}
                            showPagination={false}
                            pageSize={8}
                            //  defaultPageSize={5}
                            sortable={false}
                            getNoDataProps={() => {
                              return {
                                style: {
                                  display: "none",
                                },
                              };
                            }}
                            className="-striped -highlight"
                          />
                        }
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={8}>
                  <RegularCard
                    isLoading={this.state.isLoading}
                    content={
                      <div>
                        <BigCalendar
                          selectable
                          events={this.state.events}
                          defaultView="month"
                          scrollToTime={new Date(1970, 1, 1, 6)}
                          defaultDate={new Date()}
                          onSelectEvent={(event) => this.selectedEvent(event)}
                          onSelectSlot={(slotInfo) =>
                            this.onSlotChange(slotInfo)
                          }
                          eventPropGetter={this.eventColors}
                          components={{
                            event: (event) => {
                              return (
                                <span>
                                  {event.title}
                                  {event.event.startTime !== "" ? (
                                    <p>In:{event.event.startTime}</p>
                                  ) : (
                                    <p />
                                  )}
                                  {event.event.endTime !== "" ? (
                                    <p>Out:{event.event.endTime}</p>
                                  ) : (
                                    <p />
                                  )}
                                </span>
                              );
                            },
                            // dateCellWrapper: this.ColoredDateCellWrapper
                          }}
                          onNavigate={(focusDate, flipUnit, prevOrNext) =>
                            this.updateCalendarData(focusDate, prevOrNext)
                          }
                          // slotPropGetter={this.dayColors}
                        />
                      </div>
                    }
                  />
                </ItemGrid>
              </GridContainer>
            </ItemGrid>
          ) : null}
        </GridContainer>
      </div>
    );
  }
  updateCalendarData = (date, next) => {
    console.log("date", date);
    this.setState({
      monthStart: moment(date)
        .startOf("month")
        .format("DD-MM-YYYY"),
      monthEnd: moment(date)
        .endOf("month")
        .format("DD-MM-YYYY"),
    });
    this.callHolidayMaster(
      moment(date)
        .startOf("month")
        .format("DD-MM-YYYY"),
      moment(date)
        .endOf("month")
        .format("DD-MM-YYYY"),
      this.state.ebIdList
    );
  };
}

const extendedCalendarStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  ...customSelectStyle,
  ...buttonStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};

AttendanceCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedCalendarStyle)(AttendanceCalendar)
);
