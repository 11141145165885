import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Card from "material-ui/Card";
import CardContent from "material-ui/Card/CardContent";
import CardHeader from "material-ui/Card/CardHeader";
import CardActions from "material-ui/Card/CardActions";
import Typography from "material-ui/Typography";
import statsCardStyle from "assets/jss/material-dashboard-pro-react/components/statsCardStyle";

function StatsCard({ ...props }) {
  const {
    classes,
    title,
    description,
    statLink,
    small,
    statText,
    statText1,
    statText2,
    statText3,
    val1,
    val2,
    val3,
    statIconColor,
    iconColor,Heading
  } = props;
  return (
    <Card className={classes.card}>
      <CardHeader color="danger"  classes={{
        root: classes.cardHeader1 + " " + classes[iconColor + "CardHeader"]
      }} title={   <h5 className={classes.cardTitle1}>{Heading}</h5>}>

      </CardHeader>
      <CardContent style={{height:"120px",padding:0,paddingLeft:"5px"}}>
      {/*  <Typography component="p" className={classes.cardCategory}>
          {title}
        </Typography>*/}
        {statText1?<div className={classes.cardStatsdiv}>
          <div className={classes.cardStattext}>
            {statText1}
          </div>
          <div className={classes.cardStatvalue}>
            : {val1}
          </div>
        </div>:null}
        {statText2?<div className={classes.cardStatsdiv}>
          <div className={classes.cardStattext}>
            {statText2}
          </div>
          <div className={classes.cardStatvalue}>
            : {val2}
          </div>
        </div>:null}
        {statText3?<div className={classes.cardStatsdiv}>
          <div className={classes.cardStattext}>
            {statText3}
          </div>
          <div className={classes.cardStatvalue}>
            : {val3}
          </div>
        </div>:null}


      </CardContent>
{/*
      <CardActions className={classes.cardActions}>
        <div className={classes.cardStats}>
          {statLink !== undefined ? (
            <a href={statLink.href} className={classes.cardStatsLink}>
              {statLink.text}
            </a>
          ) : statText !== undefined ? (
            statText
          ) : null}
        </div>
      </CardActions>
*/}
    </Card>
  );
}

StatsCard.defaultProps = {
  iconColor: "purple",
  statIconColor: "gray"
};

StatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.func.isRequired,
  iconColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple","liteBlue"]),
  title: PropTypes.node,
  description: PropTypes.node,
  small: PropTypes.node,
  statIcon: PropTypes.func.isRequired,
  statIconColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray","liteBlue"
  ]),
  statLink: PropTypes.object,
  statText: PropTypes.node
};

export default withStyles(statsCardStyle)(StatsCard);
