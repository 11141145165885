import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie, removeCookies} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";


class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            newPassword:'',
            confirmPassword:'',
            oldPassword:'',
            date1: moment().format('DD-MM-YYYY'),
            year: moment().format('YYYY'),
            alert: false,

        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.onChangeValues = this.onChangeValues.bind(this)
    }

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };



    onChangeValues = (event) => {
        var value = event.target.value;

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };




    hideBasicAlert() {
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,
        });
        this.props.history.push("/Login");

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    dateChanged = () => {
        var myDate = this.state.date1;
        if(myDate!=null){
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }

    };

    onCreateButtonClick = () => {

            var data = {
                "id":getCookie(cons.USERID),
                "oldPassword":this.state.oldPassword,
                "newPassword":this.state.newPassword,
                "confirmPassword":this.state.confirmPassword,
                "modifiedBy":getCookie(cons.USER_NAME),
                "modifiedOn":this.dateChanged(this.state.date1)
            };

                if (this.state.newPassword !== '' && this.state.confirmPassword !== "" && this.state.oldPassword !== "" ) {
                    if(this.state.newPassword=== this.state.confirmPassword){
                        this.handleLoading();
                        requestList(serverApi.CHANGE_PASSWORD, data, this.handleChangePassword)
                    }
                    else{
                        this.basicAlert("New Password And Confirm Password Should be same!!");
                    }

                }
                else {

                    this.basicAlert("Please Enter All Mandatory Fields..!!");
                }


    };
    handleChangePassword = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status);
            removeCookies();

        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };



    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                    <ItemGrid xs={12}>
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={2}></ItemGrid>
                                        <ItemGrid xs={8}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                                Old Password<span style={{
                                                                color: "red",
                                                                fontSize: "12px"
                                                            }}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6}>
                                                            <TextField

                                                                id="oldPassword"
                                                                value={this.state.oldPassword}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                InputProps={inputFocus}

                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                               New password<span style={{
                                                                color: "red",
                                                                fontSize: "12px"
                                                            }}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6}>
                                                            <TextField

                                                                id="newPassword"
                                                                value={this.state.newPassword}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                InputProps={inputFocus}

                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                                Confirm Password<span style={{
                                                                color: "red",
                                                                fontSize: "12px"
                                                            }}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={6}>
                                                            <TextField

                                                                id="confirmPassword"
                                                                value={this.state.confirmPassword}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                InputProps={inputFocus}

                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>



                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <GridContainer>

                                                        <ItemGrid xs={12} style={{marginLeft: "30px"}}>
                                                            <Button color="primary"
                                                                    onClick={this.onCreateButtonClick}>Change Password</Button>

                                                        </ItemGrid>

                                                    </GridContainer>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}>




                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(ChangePassword);
