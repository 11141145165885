import Pages from "layouts/Pages.jsx";
import Home from "layouts/Dashboard.jsx";
import Logout from "../layouts/Logout";
import PrintData from "../views/CustumComponents/printData";
import TenantData from "../layouts/TenantData";

var indexRoutes = [
    {path: "/tenant", name: "Tenant", component: TenantData},
    {path: "/Login", name: "Login", component: Pages},
    {path: "/Logout", name: "Logout", component: Logout},
    {path: "/ForgotPassword", name: "ForgotPassword", component: Pages},
    {path: "/ResetPassword", name: "ResetPassword", component: Pages},
    {
        path: "/print",
        name: "Print",
        component: PrintData,
    },
    {path: "/", name: "Login", component: Home}
];

export default indexRoutes;
