import React from 'react';

import {MULTI_TENANT_DATA} from "../helper/Consts"

export function getHostAddress(hostName) {
    var data = "";
    MULTI_TENANT_DATA.forEach((prop) => {
      if (hostName === prop.host) {
        data = prop;
      }
    });
    if (data !== "") return JSON.stringify(data);
    else {
      alert("Host not found, Please contact your administrator..!!");
      return null;
    }
  }
class TenantData extends React.Component {
    render(){
        return(<div>
            {getHostAddress(window.location.hostname)}
        </div>)
    }
}
export default TenantData;