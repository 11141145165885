import React from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "material-ui/styles/withStyles";
import { getDataFromUrl, requestList } from "../../server/server";
import TextField from "../../components/CustomInput/CustomTextField";
import { getCookie } from "../../helper/Cookies";
import cons, { configNames, getConfigValue, serverApi, serverConfig } from '../../helper/Consts';
import { connect } from 'react-redux';


class InternalNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    componentDidMount() {

    }

    onChangeValues = (event) => {
        var value = event.target.value;
        var id = event.target.id;

        this.setState({
            [event.target.id]: value
        });
        this.props.setNote(event)


    };

    handleInternalNote = (hdrId,note) => {

        let data = {
            "sourceModuleId": hdrId,
            "internalRemarks": this.state.internalNote,
            "createdBy": getCookie(cons.USERID),
            "oldId":note?note[0].id:"",
            "companyId":this.props.state.companyInfo.id,
        }
        requestList(serverApi.SAVE_ACTIONS, data, this.handleInternalResp);
    }

    handleInternalResp =(resp)=>{
        this.setState({internalNote:""})
        console.log(">>>>>>>>>>>", resp)
        this.props.getLatestNotes()
    }

    render() {
        const { classes, note, isUpdate, hdrId,isRemove } = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        var internalnote1 = "";
        if (note) {
            note.forEach((prop, i) => {
                if (i === 0)
                    internalnote1 = internalnote1 + prop.actionBy + " : " + prop.internalRemarks
                else
                    internalnote1 = internalnote1 +"\n"+ prop.actionBy + " : " + prop.internalRemarks
            })
        }


        return (
            <GridContainer justify="center" >
                <ItemGrid xs={12} md={12}>
                    <GridContainer>

                        <ItemGrid xs={12} sm={3}>
                            <InputLabel className={classes.label}>
                                Internal Note
                            </InputLabel>
                        </ItemGrid>
                        {isUpdate ? <ItemGrid xs={12} sm={8}>
                            <TextField
                                disabled={true}
                                id="internalNote1"
                                fullWidth
                                multiline
                                rowsMax="8"
                                height={150}
                                value={internalnote1}
                                onChange={this.onChangeValues}
                                className={classes.textField}
                            />
                        </ItemGrid> : null}
                        {isUpdate ? <ItemGrid xs={12} sm={3}>
                        </ItemGrid> : null}

                        <ItemGrid xs={12} sm={8}>
                            <TextField
                                id="internalNote"
                                fullWidth
                                multiline
                                rowsMax="3"
                                height={60}
                                value={this.state.internalNote}
                                onChange={this.onChangeValues}
                                className={classes.textField}
                            />
                        </ItemGrid>
                        {isUpdate ? <ItemGrid xs={1}>
                            <Button color={"info"}
                                onClick={() => {
                                    this.handleInternalNote(hdrId,note)

                                }}>submit</Button>

                        </ItemGrid> : null}
                    </GridContainer>
                </ItemGrid>

            </GridContainer>
        );
    }


}
const extendedFormsStyle = {
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(InternalNote)
);
