import React from "react";
import IconCard from "../../components/Cards/IconCard";
import alertIcon from "@material-ui/icons/AddAlert";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import ItemGrid from "../../components/Grid/ItemGrid";

import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import RegularCard from "../Cards/RegularCard";

class PopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {isShowClose,content, title, closePopup, onClickSave,onClickReject,reject_btn_txt, cancel_button_text, isShowSave, isShowCancel,isShowReject, save_btn_txt,maxHeight,tablePop} = this.props;
        var style={margin:0};
        if(maxHeight!==undefined){
            style={maxHeight:maxHeight,overflowY:"auto",overflowX:"hidden"}
        }
        var popupstyle={
            position: "absolute", display: "relative",
            top: "20%",
            left: "35%",
            right: "15%",
            bottom: "20%",
            margin: "auto",
            backGround: "white",
            // zIndex: 5500
        };
        if(tablePop){
            popupstyle={
                position: "fixed", display: "relative",
                top: "10%",
                left: "25%",
                right: "10%",
                bottom: "20%",
                margin: "auto",
                backGround: "white",
                // zIndex: 5500
            }
        }

        return (
            <div style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
                zIndex: 5000,
                backgroundColor: "rgba(0,0,0, 0.5)"
            }}>
                <div style={popupstyle}>
                    <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                        <ItemGrid xs={12} >
                            <RegularCard
                                content={
                                    <GridContainer justify={"center"} style={style}>



                                        <ItemGrid xs={12} >
                                            {content}
                                        </ItemGrid>

                                        <ItemGrid xs={12}>
                                            <br/>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    {isShowSave ?
                                                        <Button style={{width:100}} color="primary"
                                                                onClick={onClickSave}>{save_btn_txt}</Button>
                                                        :null

                                                    }
                                                </ItemGrid>
                                                <ItemGrid>
                                                    {isShowReject ?
                                                        <Button style={{width:100}} color="primary"
                                                                onClick={onClickReject}>{reject_btn_txt}</Button>
                                                        :null

                                                    }
                                                </ItemGrid>
                                                <ItemGrid>
                                                    {isShowCancel ?
                                                        <Button color="danger" style={{width:100}}
                                                                onClick={closePopup}>{cancel_button_text}</Button>
                                                        : null
                                                    }
                                                </ItemGrid>



                                            </GridContainer>

                                        </ItemGrid>
                                    </GridContainer>


                                }
                            />
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );

    }
}

const extendedCalendarStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    button:{
        width:100
    }
};

export default withStyles(extendedCalendarStyle)(PopUp);