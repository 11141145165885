import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Add from "@material-ui/icons/Add";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import { VIEW } from "../../ui-actions/UiActions";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Search from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class LeaveRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: moment().format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      isRefresh: false,
      isLoading: false,
      isDisabled: true,
      isDisabledEbNo: false,
      approveButton: false,
      itemViewType: "leaveTransactionId",
      year: moment().format("YYYY"),
      leaveTypeId: "",
      leavesMonth: "",
      leavesYear: "",
      dept: "",
      workerName: "",
      LeavesList: null,
      LeaveTypeOptions: [],
      invoiceList: [],
      updateView: false,
      //ebNo:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",

      //ebIdList:getCookie(cons.EB_NO)!=="null"?getCookie(cons.EB_NO):"",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    var LeavesList = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      column: [
        {
          filterable: false,
          Header: "Emp Code",
          accessor: "indentNo",
          minWidth: 30,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Emp Name",
          accessor: "indentLineId",
          minWidth: 25,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Leave Type",
          accessor: "indentDate",
          minWidth: 30,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Days",
          accessor: "itemCode",
          minWidth: 30,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "From Date",
          accessor: "itemDesc",
          minWidth: 30,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "To Date",
          accessor: "uom",
          minWidth: 25,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Status",
          accessor: "indentQty",
          minWidth: 30,
          style: { textAlign: "left" },
          sortable: false,
        },
        {
          filterable: false,
          Header: "Actions",
          accessor: "indentQty",
          minWidth: 30,
          style: { textAlign: "center" },
          sortable: false,
        },
      ],
    };

    this.setState({
      LeavesList: LeavesList,
      // isRefresh: true,
    });

    // this.getReportsTable();
    // this.getWorkersList();
    // this.leaveAvailabilityCheck();
  }
  leaveAvailabilityCheck() {
    getDataFromUrl(
      serverApi.LEAVE_REQUEST_AVAILABILITY +
        "/" +
        getCookie(cons.EB_ID) +
        "/" +
        getCookie(cons.USERID),
      this.handleAvailabilityCheck
    );
  }
  handleAvailabilityCheck = (resp) => {
    var availableCheck = [];
    if (resp.status === true) {
      if (resp.data.ledgers.length > 0)
        resp.data.ledgers.map((prop, i) => {
          var obj = {
            type: prop.leaveTypeId,
            leaveTypeName: prop.leaveTypeName,
            eligible: prop.leavesPerYear,
            used: prop.usedLeaves === null ? 0 : prop.usedLeaves,
            available: prop.availableLeaves,
            leavesPerYear: prop.leavesPerYear,
            maxLeavesPerMonth: prop.maxLeavesPerMonth,
            yearEndDate: prop.yearEndDate,
            yearStartDate: prop.yearStartDate,
          };
          availableCheck.push(obj);
        });
    } else {
      var obj = {
        message: resp.message,
      };
      availableCheck.push(obj);
    }
    this.setState({
      leaveAvailableCheck: availableCheck,
      LeaveTypeOptions: resp.data.leaveTypes,
      showAvailability: resp.status,
      isDisabled: false,
      loadEmp: true,
    });
  };

  getWorkersList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      deptId: 0,
      empCode: "",
      empName: "",
      esiNo: "",
      payScheme: "",
      pfNo: "",
      reporting: 0,
      userId: getCookie(cons.USERID),
    };

    requestList(serverApi.TABLE_WORKERS_LIST, data, this.handleworkers);
  };
  handleworkers = (resp) => {
    var data = [];
    data = resp.data.map((prop) => {
      var obj = {
        label: prop.workerName,
        value: prop.ebId,
        name: prop.workerName + "^" + prop.departmentId,
      };
      return obj;
    });

    this.setState({
      empList: data,
      loadEmp: true,
    });
    //}
    //  this.handleLoading();
  };

  getReportsTable = () => {
    this.handleLoading();
    getDataFromUrl(
      serverApi.GET_LEAVE_TRANSACTIONS_LIST + this.props.state.companyInfo.id,
      this.handleReports
    );
  };

  handleReports = (resp) => {
    // console.log("reports",JSON.stringify(resp));
    var LeavesList = resp;
    if (LeavesList !== null) {
      var columns = LeavesList.column.map((prop, key) => {
        var obj = prop;
        obj.filterable = false;
        obj.sortable = false;
        obj.minWidth = 30;
        //  obj.maxWidth=50;
        // delete obj.minWidth;
        delete obj.maxWidth;
        return obj;
      });
    }
    LeavesList.column = columns;
    console.log("columns", JSON.stringify(LeavesList.column));

    //    if(resp.status){
    this.setState({
      LeavesList: LeavesList,
      isRefresh: true,
    });
    // }
    this.handleLoading();
  };

  handleSeacrh = () => {
    var loading = false;

    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    this.setState({
      isCreated: !this.state.isCreated,
      leaveTypeId: "",
      leavesMonth: "",
      leavesYear: "",
      dept: "",
      empName: "",
      leavePurpose: "",
      leaveFromDate: "",
      leaveToDate: "",
      isDisabledEbNo: false,
      leaveAvailableCheck: [],
      ebId: getCookie(cons.EB_ID) !== "null" ? getCookie(cons.EB_ID) : "",
      ebNo: getCookie(cons.EB_NO) === "null" ? "" : getCookie(cons.EB_NO),
    });
    if (getCookie(cons.EB_NO) !== "" && getCookie(cons.EB_NO) !== "null") {
      this.leaveAvailabilityCheck();
    }
  };

  handleCreate1 = () => {
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    this.setState({
      isCreated: !this.state.isCreated,
      leaveTypeId: "",
      leavesMonth: "",
      leavesYear: "",
      dept: "",
      workerName: "",
      leavePurpose: "",
      leaveFromDate: "",
      leaveToDate: "",
      leaveAvailableCheck: [],
      ebId: "",
      approveButton: false,
    });
  };
  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
      ebNo: this.state.ebIdList,
      leaveTypeId: this.state.leaveTypeId,
      status: this.state.status,
    };

    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  hideAlertMessage() {
    // if(this.state.isCreated){
    //     this.getReportsTable()
    // }
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      ebId: "",
      leaveFromDate: "",
      leaveToDate: "",
      leavePurpose: "",
      leaveTypeId: "",
      ebNo: "",
      workerName: "",
      yearStartDate: "",
      yearEndDate: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 8) {
      return length;
    } else {
      return 8;
    }
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "ebId") {
      getDataFromUrl(
        serverApi.LEAVE_REQUEST_AVAILABILITY +
          "/" +
          selectedValue +
          "/" +
          getCookie(cons.USERID),
        this.handleAvailabilityCheck
      );
    }

    if (stateKey === "leaveTypeId" && this.state.leaveTypeId !== 0) {
      if (this.state.leaveAvailableCheck !== undefined)
        this.state.leaveAvailableCheck.forEach((j, k) => {
          if (selectedValue == j.type) {
            this.setState({
              leavesMonth: j.maxLeavesPerMonth,
              leavesYear: j.leavesPerYear,
              usedLeaves: j.used,
              maxConsecutiveCount: j.maxConsecutiveCount,
              yearStartDate: j.yearStartDate,
              yearEndDate: j.yearEndDate,
              leavesAvailable: j.availableLeaves,
              loadEmp: true,
            });
          }
        });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        leaveFromDate,
        leaveToDate,
        leavePurpose,
        leaveTypeId,
        ebId,
      } = this.state;

      var data = {
        companyId: this.props.state.companyInfo.id,
        createdBy: getCookie(cons.USERID),
        ebId: ebId,
        leaveFromDate: leaveFromDate,
        leaveToDate: leaveToDate,
        leavePurpose: leavePurpose,
        leaveTypeId: leaveTypeId,
      };

      var fromdate = this.dateChanged(leaveFromDate);
      var todate = this.dateChanged(leaveToDate);

      // var days=moment(todate).diff(fromdate, 'days');
      //
      //         //     var diffdays = todate.diff(fromdate, 'days');
      //         //
      //         // var days= moment.duration(diffdays, 'days').asDays();
      //         console.log("daysssssssssss",moment(todate).diff(fromdate, 'days'));
      // if(days <= this.state.maxConsecutiveCount) {

      // new code for validation...

      const validateFields = [
        { field: leaveTypeId, msg: "Leave Type", type: 2 },
        { field: leavePurpose, msg: "Leave Purpose", type: 2 },
        { field: ebId, msg: "EBID", type: 2 },
        { field: leaveFromDate, msg: "From Date", type: 2 },
        { field: leaveToDate, msg: "To Date", type: 2 },
      ];

      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        if (this.state.usedLeaves <= this.state.leavesYear) {
          this.handleLoading();
          // this.basicAlert("Created Successfully");
          requestList(
            serverApi.LEAVE_TRANSACTION_ADD,
            data,
            this.handleAddTransaction
          );
        } else {
          this.basicAlert("No Avaialble Leaves for this Quota ..!!");
        }
      }

      // old code for vvalidation....

      // if (
      //   ebId > 0 &&
      //   leaveFromDate !== "" &&
      //   leaveToDate !== "" &&
      //   leavePurpose !== "" &&
      //   leaveTypeId > 0
      // ) {
      //   // if (
      //   //   (moment(fromdate).isSame(this.state.yearStartDate) ||
      //   //     moment(fromdate).isBetween(
      //   //       this.state.yearStartDate,
      //   //       this.state.yearEndDate
      //   //     )) &&
      //   //   (moment(todate).isSame(this.state.yearEndDate) ||
      //   //     moment(todate).isBetween(
      //   //       this.state.yearStartDate,
      //   //       this.state.yearEndDate
      //   //     ))
      //   // ) {
      //   if (this.state.usedLeaves <= this.state.leavesYear) {
      //     this.handleLoading();
      //     requestList(
      //       serverApi.LEAVE_TRANSACTION_ADD,
      //       data,
      //       this.handleAddTransaction
      //     );
      //   } else {
      //     this.basicAlert("No Avaialble Leaves for this Quota ..!!");
      //   }
      //   //     } else {
      //   //       this.basicAlert(
      //   //         "Leave Dates should be in between " +
      //   //           moment(this.state.yearStartDate).format("DD-MM-YYYY") +
      //   //           " and " +
      //   //           moment(this.state.yearEndDate).format("DD-MM-YYYY") +
      //   //           "..!!"
      //   //       );
      //   //     }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      // }else{
      //     this.basicAlert("You can not apply " +this.state.maxConsecutiveCount  +" days leave at a time..!!");
      // }
    }
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[2] + "-" + myDate[1] + "-" + myDate[0];
    return newDate;
  };
  onUpdateButtonClick = (status) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        leaveFromDate,
        leaveToDate,
        leavePurpose,
        leaveTypeId,
        ebId,
        leaveTransactionId,
      } = this.state;

      var data = {
        leaveTransactionId: leaveTransactionId,
        companyId: this.props.state.companyInfo.id,
        ebId: ebId,
        leaveFromDate: leaveFromDate,
        leaveToDate: leaveToDate,
        leavePurpose: leavePurpose,
        leaveTypeId: leaveTypeId,
        status: this.state.statusId == "" ? "" : status,
        updatedBy: getCookie(cons.USERID),
      };
      var fromdate = this.dateChanged(leaveFromDate);
      var todate = this.dateChanged(leaveToDate);
      if (
        ebId > 0 &&
        leaveFromDate !== "" &&
        leaveToDate !== "" &&
        leavePurpose !== "" &&
        leaveTypeId > 0
      ) {
        // if (
        //   moment(fromdate).isBetween(
        //     this.state.yearStartDate,
        //     this.state.yearEndDate
        //   ) &&
        //   moment(todate).isBetween(
        //     this.state.yearStartDate,
        //     this.state.yearEndDate
        //   )
        // ) {
        this.handleLoading();
        requestList(
          serverApi.LEAVE_TRANSACTIONS_UPDATE,
          data,
          this.handleAddTransaction
        );
        //     } else {
        //       this.basicAlert(
        //         "Leave Dates should be in between " +
        //           moment(this.state.yearStartDate).format("DD-MM-YYYY") +
        //           " and " +
        //           moment(this.state.yearEndDate).format("DD-MM-YYYY") +
        //           "..!!"
        //       );
        //     }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  handleAddTransaction = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message);
    } else {
      this.showMessage(resp.message);
    }
    this.handleLoading();
  };

  showMessage = (msg) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            this.hideAlertMessage();
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  handleViewClick = (id) => {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.LEAVE_TRANSACTIONS_DETAILS_BY_ID +
          id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    if (resp) {
      if (resp.ebNo != "") {
        getDataFromUrl(
          serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
            resp.ebNo +
            "/" +
            this.props.state.companyInfo.id +
            "/" +
            getCookie(cons.USERID),
          this.handleEmpDetails
        );
        getDataFromUrl(
          serverApi.LEAVE_REQUEST_AVAILABILITY +
            "/" +
            resp.ebId +
            "/" +
            getCookie(cons.USERID),
          this.handleAvailabilityCheck
        );
      }
      this.setState({
        isCreated: true,
        updateView: true,
        isDisabledEbNo: true,
        approveButton: resp.approveButton,
        ebNo: resp.ebNo,
        statusId: resp.status,
        leaveTransactionId: resp.leaveTransactionId,
        ebId: resp.ebId,
        leaveFromDate: resp.leaveFromDate,
        leaveToDate: resp.leaveToDate,
        leaveTypeId: resp.leaveTypeId,
        leaveLedgerId: resp.leaveLedgerId,
        leavePurpose: resp.leavePurpose,
        updatedBy: resp.updatedBy,
        updatedDateTime: resp.updatedDateTime,
        createdBy: resp.createdBy,
        createdDateTime: resp.createdDateTime,
        leaveType: resp.leaveType,
        leaveLedger: resp.leaveLedger,
      });
    }
  };
  getEmpDetails = () => {
    const { ebNo } = this.state;
    if (ebNo !== "") {
      getDataFromUrl(
        serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
          ebNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.USERID),
        this.handleEmpDetails
      );
    } else if (ebNo === "") {
      this.basicAlert("Please Enter Employee No..!!");
    }
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      var header = resp.data;
      this.setState({
        //  isDisabled:false,
        empName: header.workerName,
        dept: header.deptId,
        loadDept: true,
        ebId: header.ebId,
      });
      getDataFromUrl(
        serverApi.LEAVE_REQUEST_AVAILABILITY +
          "/" +
          header.ebId +
          "/" +
          getCookie(cons.USERID),
        this.handleAvailabilityCheck
      );
    } else {
      this.showMessage(resp.message, false);
    }
  };

  render() {
    const { classes } = this.props;
    const inputFocus = {
      autoComplete: "off",
    };
    var yesterday = Datetime.moment().subtract(1, "day");

    const options = [
      { value: 0, name: "select...", label: "select..." },
      { value: 1, name: "OPEN", label: "OPEN" },
      { value: 3, name: "APPROVED", label: "APPROVED" },
      { value: 4, name: "REJECTED", label: "REJECTED" },
      { value: 6, name: "CANCELLED", label: "CANCELLED" },
      {
        value: 17,
        name: "PENDING APPROVAL LEVEL 2",
        label: "PENDING APPROVAL LEVEL 2",
      },
      {
        value: 18,
        name: "PENDING APPROVAL LEVEL 3",
        label: "PENDING APPROVAL LEVEL 3",
      },
      {
        value: 19,
        name: "PENDING APPROVAL LEVEL 4",
        label: "PENDING APPROVAL LEVEL 4",
      },
    ];
    var valid = function(current) {
      if (this.props.state.companyInfo.id !== "2")
        return current.isAfter(yesterday);
      else return true;
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate1}>
                  Back To List
                </Button>
              }
              isLoading={this.state.isLoading}
              content={
                <GridContainer>
                  {this.state.showMessagealert}
                  {this.state.basicAlert}
                  <ItemGrid xs={12} md={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Emp Code
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          // defValue={this.state.ebNo}
                          disabled={this.state.isDisabledEbNo}
                          id="ebNo"
                          value={this.state.ebNo}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#00ABDC",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <Search
                                    className={classes.inputAdornmentIcon}
                                    onClick={this.getEmpDetails}
                                  />
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {/*
                                    <ItemGrid xs={12} sm={6}>

                                        <GridContainer>
                                            <ItemGrid xs={12} sm={3}>
                                                <InputLabel className={classes.label}>
                                                    Employee<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={9}>
                                                <SelectboxTemp
                                                    defValue={parseInt(getCookie(cons.EB_ID))}
                                                    id={"ebId"}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        var val=name.split("^")
                                                        if(val[1]!==null)
                                                        this.setState({dept:parseInt(val[1]),loadDept:true})
                                                    }}
                                                     isRefresh={this.state.loadEmp}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadEmp:false})
                                                    }}
                                                    stateKey="ebId"
                                                    staticData={this.state.empList}
                                                  //  url={serverApi.DATATABLE_SERVICE_URL + this.state.item + "/getQualityByItem"}
                                                    value={this.state.ebId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}
                  <ItemGrid xs={12} sm={6}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={3}>
                        <InputLabel className={classes.label}>
                          Department
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9}>
                        <SelectboxTemp
                          defValue={this.state.dept}
                          id={"dept"}
                          onSelect={this.onSelect}
                          isReload={this.state.loadDept}
                          selectDisabled={this.state.isDisabled}
                          setIsReload={(val) => {
                            this.setState({ loadDept: false });
                          }}
                          stateKey="dept"
                          url={
                            serverApi.SELECT_SUB_DEPARTMENT_MASTER +
                            this.props.state.companyInfo.id
                          }
                          value={this.state.dept}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Worker Name
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={true}
                          id="empName"
                          value={this.state.empName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={3}>
                        <InputLabel className={classes.label}>
                          Leave Type
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9}>
                        <SelectboxTemp
                          defValue={this.state.leaveTypeId}
                          onSelect={this.onSelect}
                          // getName={this.getQualityDetails}
                          selectDisabled={this.state.isDisabled}
                          isRefresh={this.state.loadEmp}
                          setIsRefresh={(val) => {
                            this.setState({ loadEmp: false });
                          }}
                          stateKey="leaveTypeId"
                          staticData={this.state.LeaveTypeOptions}
                          // url={serverApi.LEAVE_TYPES_SELECTION + this.props.state.companyInfo.id}
                          value={this.state.leaveTypeId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.showAvailability === true ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <ReactTable
                        data={this.state.leaveAvailableCheck}
                        filterable={false}
                        resizable={false}
                        loading={this.state.loading}
                        getTableProps={() => {
                          return {
                            style: {
                              border: "2px solid #00ABDC",
                              borderRadius: "4px",
                            },
                          };
                        }}
                        getTheadProps={() => {
                          return {
                            style: {
                              borderBottom: "2px solid #00ABDC",
                              //height:45
                              //lineHeight:"1.3"
                            },
                          };
                        }}
                        getTheadThProps={() => {
                          return {
                            style: {
                              borderRight: "1px solid #999999",
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              lineHeight: 1.3,
                            },
                          };
                        }}
                        style={{
                          color: "#00ABDC",
                          textAlign: "center",
                          overflowY: "auto",
                        }}
                        columns={[
                          {
                            Header: "Leave Type",
                            accessor: "leaveTypeName",
                            minWidth: 60,
                            style: { color: "#000", textAlign: "left" },
                          },
                          {
                            Header: "Eligible",
                            accessor: "eligible",
                            minWidth: 30,
                            style: { color: "#000", textAlign: "center" },
                          },
                          {
                            Header: "Used",
                            accessor: "used",
                            minWidth: 30,
                            style: { color: "#000", textAlign: "center" },
                          },
                          {
                            Header: "Available",
                            accessor: "available",
                            minWidth: 30,
                            style: { color: "#000", textAlign: "center" },
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        showPagination={false}
                        pageSize={this.getInvoiceTablePageSize()}
                        //  defaultPageSize={5}
                        sortable={false}
                        getNoDataProps={() => {
                          return {
                            style: {
                              display: "none",
                            },
                          };
                        }}
                        className="-striped -highlight"
                      />
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <ReactTable
                        data={this.state.leaveAvailableCheck}
                        filterable={false}
                        resizable={false}
                        loading={this.state.loading}
                        getTableProps={() => {
                          return {
                            style: {
                              border: "2px solid #00ABDC",
                              borderRadius: "4px",
                            },
                          };
                        }}
                        getTheadProps={() => {
                          return {
                            style: {
                              borderBottom: "2px solid #00ABDC",
                              //height:45
                              //lineHeight:"1.3"
                            },
                          };
                        }}
                        getTheadThProps={() => {
                          return {
                            style: {
                              borderRight: "1px solid #999999",
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                              lineHeight: 1.3,
                            },
                          };
                        }}
                        style={{
                          color: "#00ABDC",
                          textAlign: "center",
                          overflowY: "auto",
                        }}
                        columns={[
                          {
                            Header: "Message",
                            accessor: "message",
                            minWidth: 60,
                            style: { color: "#000", textAlign: "left" },
                          },
                        ]}
                        showPagination={false}
                        pageSize={this.getInvoiceTablePageSize()}
                        //  defaultPageSize={5}
                        sortable={false}
                        getNoDataProps={() => {
                          return {
                            style: {
                              display: "none",
                            },
                          };
                        }}
                        className="-striped -highlight"
                      />
                    </ItemGrid>
                  )}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={3}>
                        <InputLabel className={classes.label}>
                          Leaves In Month
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9}>
                        <TextField
                          disabled={true}
                          id="leavesMonth"
                          value={this.state.leavesMonth}
                          isRefresh={this.state.loadEmp}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={3}>
                        <InputLabel className={classes.label}>
                          Leaves In Year
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9}>
                        <TextField
                          disabled={true}
                          id="leavesYear"
                          value={this.state.leavesYear}
                          isRefresh={this.state.loadEmp}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>

                      <ItemGrid xs={12} sm={3}>
                        <InputLabel className={classes.label}>
                          Description
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={9}>
                        <TextField
                          id="leavePurpose"
                          disabled={this.state.isDisabled}
                          value={this.state.leavePurpose}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          multiline={true}
                          height={100}
                          rowsMax={5}
                        />
                      </ItemGrid>

                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={2}>
                            <InputLabel className={classes.label}>
                              From
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={10}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                //   isValidDate={valid}

                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "leaveFromDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "leaveFromDate",
                                  value: this.state.leaveFromDate,
                                  autoComplete: "off",
                                  disabled: this.state.isDisabled,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={6}>
                        <GridContainer>
                          <ItemGrid xs={1}>
                            <InputLabel className={classes.label}>
                              To
                              <span style={{ color: "red", fontSize: "12px" }}>
                                *
                              </span>
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={10.5}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                //  isValidDate={valid}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  this.timeChanged(dateObj, "leaveToDate");
                                }}
                                height={"15px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "leaveToDate",
                                  value: this.state.leaveToDate,
                                  autoComplete: "off",
                                  disabled: this.state.isDisabled,
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid
                    xs={12}
                    style={{
                      height: 2,
                      backgroundColor: "#00acc1",
                      marginTop: "5px",
                    }}
                  />
                  {/*{this.state.updateView?*/}
                  {/*<ItemGrid xs={12}*/}
                  {/*style={{marginTop: "5px", backgroundColor: "#00abdc30"}}>*/}
                  {/*<GridContainer>*/}
                  {/*<ItemGrid xs={12} md={3} style={{marginTop:"10px"}}>*/}
                  {/*<TextField*/}
                  {/*disabled={true}*/}
                  {/*id="status"*/}
                  {/*value={this.state.status}*/}
                  {/*onChange={this.onChangeValues}*/}
                  {/*fullWidth={true}*/}
                  {/*variant="outlined"*/}
                  {/*backgroundColor={'#ffffff'}*/}

                  {/*InputProps={{*/}
                  {/*startAdornment: <InputAdornment position="start" variant="filled" >*/}
                  {/*<span style={{color:"#00ADBC",fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Status</span>*/}
                  {/*</InputAdornment>,*/}
                  {/*}}*/}
                  {/*/>*/}

                  {/*</ItemGrid>*/}
                  {/*<ItemGrid xs={12} md={5} style={{marginTop:"10px"}}>*/}
                  {/*<TextField*/}
                  {/*disabled={true}*/}
                  {/*id="updatedBy"*/}
                  {/*value={this.state.updatedBy}*/}
                  {/*onChange={this.onChangeValues}*/}
                  {/*fullWidth={true}*/}
                  {/*variant="outlined"*/}
                  {/*backgroundColor={'#ffffff'}*/}

                  {/*InputProps={{*/}
                  {/*startAdornment: <InputAdornment position="start" variant="filled" >*/}
                  {/*<span style={{color:"#00ADBC",fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Status Updated By</span>*/}
                  {/*</InputAdornment>,*/}
                  {/*}}*/}
                  {/*/>*/}

                  {/*</ItemGrid>*/}
                  {/*<ItemGrid xs={12} md={4} style={{marginTop:"10px"}}>*/}
                  {/*<TextField*/}
                  {/*disabled={true}*/}
                  {/*id="updatedDateTime"*/}
                  {/*value={this.state.updatedDateTime}*/}
                  {/*onChange={this.onChangeValues}*/}
                  {/*fullWidth={true}*/}
                  {/*variant="outlined"*/}
                  {/*backgroundColor={'#ffffff'}*/}

                  {/*InputProps={{*/}
                  {/*startAdornment: <InputAdornment position="start" variant="filled" >*/}
                  {/*<span style={{color:"#00ADBC",fontSize:"16px",height:25,fontWeight:"400",borderRight:'1px solid #d2d2d2'}}>Update Date</span>*/}
                  {/*</InputAdornment>,*/}
                  {/*}}*/}
                  {/*/>*/}

                  {/*</ItemGrid>*/}

                  {/*<ItemGrid xs={12} md={12} style={{marginTop:"10px"}}>*/}
                  {/*<TextField*/}
                  {/*//  disabled={true}*/}
                  {/*id="employer"*/}
                  {/*value={this.state.employer}*/}
                  {/*onChange={this.onChangeValues}*/}
                  {/*fullWidth={true}*/}
                  {/*variant="outlined"*/}
                  {/*multiline={true}*/}
                  {/*height={60}*/}
                  {/*backgroundColor={'#ffffff'}*/}
                  {/*rowsMax={3}*/}
                  {/*InputProps={{*/}
                  {/*startAdornment: <InputAdornment position="start">*/}
                  {/*<span style={{color:"#00ADBC",width:150,textAlign:'center',paddingTop:20,fontSize:"16px",height:55,fontWeight:"400",marginTop:40,borderRight:'1px solid #d2d2d2'}}>Employee Remarks</span>*/}
                  {/*</InputAdornment>*/}
                  {/*}}*/}
                  {/*/>*/}

                  {/*</ItemGrid>*/}

                  {/*</GridContainer>*/}
                  {/*</ItemGrid>:null}*/}

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              // this.onUpdateButtonClick(this.state.statusId);
                              this.onUpdateButtonClick(3);
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.updateView && this.state.approveButton ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {!this.state.updateView && this.state.showAvailability ? (
                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                          <GridContainer justify="center">
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateButtonClick}
                              >
                                Create
                              </Button>
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={this.hideAlertMessage}
                              >
                                Cancel
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              content={
                <div>
                  <GridContainer>
                    {
                      <ItemGrid xs={10}>
                        <GridContainer>
                          <ItemGrid xs={4} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={3}>
                                <InputLabel className={classes.label}>
                                  Leave Type
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={9}>
                                <SelectboxTemp
                                  defValue={this.state.leaveTypeId}
                                  onSelect={this.onSelect}
                                  getName={this.getQualityDetails}
                                  isRefresh={this.state.loadEmp}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadEmp: false });
                                  }}
                                  stateKey="leaveTypeId"
                                  // staticData={this.state.empList}
                                  url={
                                    serverApi.LEAVE_TYPES_SELECTION +
                                    this.props.state.companyInfo.id
                                  }
                                  value={this.state.leaveTypeId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                          <ItemGrid xs={4} md={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Emp Code
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <TextField
                                  id="ebIdList"
                                  value={this.state.ebIdList}
                                  className={classes.textField}
                                  onChange={this.onChangeValues}
                                  fullWidth={true}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>

                          {/*
                                                <ItemGrid xs={4} sm={4}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                                Employee
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={9}>
                                                            <SelectboxTemp
                                                                defValue={this.state.ebId}
                                                                id={"ebId"}
                                                                onSelect={this.onSelect}
                                                                getName={(name)=>{
                                                                    var val=name.split("^")
                                                                    if(val[1]!==null)
                                                                        this.setState({dept:parseInt(val[1]),loadDept:true})
                                                                }}
                                                                isRefresh={this.state.loadEmp}
                                                                setIsRefresh={(val)=>{
                                                                    this.setState({loadEmp:false})
                                                                }}
                                                                stateKey="ebId"
                                                                staticData={this.state.empList}
                                                                //  url={serverApi.DATATABLE_SERVICE_URL + this.state.item + "/getQualityByItem"}
                                                                value={this.state.ebId}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
*/}
                          <ItemGrid xs={4} sm={4}>
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Status
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  defValue={this.state.status}
                                  id={"status"}
                                  onSelect={this.onSelect}
                                  getName={this.getQualityDetails}
                                  // isRefresh={this.state.loadquality}
                                  //setIsRefresh={this.afterRefreshquality}
                                  stateKey="status"
                                  staticData={options}
                                  value={this.state.status}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    }
                    <ItemGrid xs={2}>
                      <IconButton
                        onClick={this.handleCreate}
                        color="success"
                        right={true}
                      >
                        <Add />
                      </IconButton>
                      {
                        <Button
                          color="primary"
                          right={true}
                          onClick={this.handleSeacrh}
                        >
                          Search
                        </Button>
                      }
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <ReportsTableComponent
                        url={serverApi.GET_LEAVE_TRANSACTIONS_LIST}
                        //  staticData={this.state.LeavesList}
                        borderRight={true}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        // isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        actionType={VIEW}
                        fileName={"jute_with_value"}
                        itemViewType={this.state.itemViewType}
                      />

                      {/*
                                            <SamplePDF printDocument={this.printDocument.bind(this)}/>
*/}
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }

  printDocument = () => {
    var data = {
      taskCode: 1013,
      payloadSize: 2,
      data: {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(
      serverApi.REPORTS_JUTE_WITH_VALUE,
      data,
      this.handlePrintReports
    );
  };
  handlePrintReports = (resp) => {
    var pageNo = 1;
    var modules = [];
    // if(this.state.LeavesList!==undefined &&this.state.LeavesList!==null ){
    //     console.log("print ",JSON.stringify(this.state.LeavesList.data.data));
    //     modules=this.state.LeavesList.data.data;
    //     reportValues
    // }
    modules = resp.data.reportValues;
    var grandTotals = resp.data.totalValues;
    var companyName = resp.data.companyName;
    console.log("total", grandTotals);
    var config = {
      printHeaders: true,
      autoSize: true,
      margins: { left: 0, top: 0, bottom: 0, width: 0 },
      fontSize: 10,
    };
    const doc = new Jspdf("p", "pt", "a4");
    doc.setFontSize(14);
    doc.text(170, 40, companyName);
    doc.setFontSize(10);
    var TotalPgaeNo = Math.ceil(parseFloat(modules.length) / 26);

    doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
    // doc.setFont("courier");
    // doc.setFontType("normal");
    doc.setFontSize(10);
    // doc.table(50, 100,modules,modulesHeader,config);
    doc.text(220, 60, "Report No.:[JUTE/01]");
    doc.text(
      170,
      80,
      "Daily Jute With Value Report From  " +
        this.state.fromDate +
        " to " +
        this.state.todate +
        "           Quantity in :Quintal"
    );
    doc.setFontSize(9);
    doc.setFontType("bold");
    doc.cellInitialize();
    doc.cell(30, 100, 30, 30, "Jcode", "", "C");
    doc.cell(60, 100, 45, 30, "Quality", "", "C");
    var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
    doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
    var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
    doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
    var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
    doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
    doc.cell(231, 100, 40, 30, "Rate", "", "C");
    var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
    doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
    var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
    doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
    doc.cell(
      364,
      100,
      155,
      15,
      "<--------------------M T D----------------->",
      "",
      "C"
    );
    /*  var StockForDays = doc.splitTextToSize('Stock For Days', 45);
          doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
    doc.cellInitialize();
    doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
    doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
    doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
    // doc.cellInitialize();
    var cellWidths = ["", 30, 45, 42, 42, 42, 40, 51, 42, 50, 50, 55, 45];
    var cellTextaligns = [
      "",
      "center",
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "center",
    ];
    doc.setFontType("normal");
    doc.setFontSize(7);
    {
      var k = 110;
      var i = 0;
      modules.map((prop, key) => {
        k = k + 20;
        i = i + 1;
        doc.cellInitialize();
        if (i === 27) {
          k = 130;
          i = 0;
          pageNo = pageNo + 1;
          var today = moment().format("DD/MM/YYYY H:mm:ss");
          var name = getCookie(cons.USERID);
          var newdat = " Printed on: " + today;
          var printBy = " Printed By: " + name;
          doc.text(30, 800, newdat);
          doc.text(500, 800, printBy);
          doc.addPage("a4", "p");
          doc.setFontSize(14);
          doc.text(170, 40, companyName);
          // doc.setFont("courier");
          // doc.setFontType("normal");
          doc.setFontSize(10);
          doc.text(500, 40, "Page " + pageNo + " of " + TotalPgaeNo + "");
          // doc.table(50, 100,modules,modulesHeader,config);
          doc.text(220, 60, "Report No.:[JUTE/01]");
          doc.text(
            170,
            80,
            "Daily Jute With Value Report From  " +
              this.state.fromDate +
              " to " +
              this.state.todate +
              "           Quantity in :Quintal"
          );
          doc.setFontSize(9);
          doc.setFontType("bold");
          doc.cellInitialize();
          doc.cell(30, 100, 30, 30, "Jcode", "", "C");
          doc.cell(60, 100, 45, 30, "Quality", "", "C");
          var OpeningWeight = doc.splitTextToSize("OpeningWeight", 38);
          doc.cell(105, 100, 42, 30, OpeningWeight, "", "C");
          var ReceiptWeight = doc.splitTextToSize("ReceiptWeight", 38);
          doc.cell(147, 100, 42, 30, ReceiptWeight, "", "C");
          var IssueWeight = doc.splitTextToSize("IssueWeight", 30);
          doc.cell(189, 100, 42, 30, IssueWeight, "", "C");
          doc.cell(231, 100, 40, 30, "Rate", "", "C");
          var IssueAmount = doc.splitTextToSize("Issue Amount", 35);
          doc.cell(271, 100, 51, 30, IssueAmount, "", "C");
          var ClosingWeight = doc.splitTextToSize("ClosingWeight", 37);
          doc.cell(322, 100, 42, 30, ClosingWeight, "", "C");
          doc.cell(
            364,
            100,
            155,
            15,
            "<--------------------M T D----------------->",
            "",
            "C"
          );
          /* var StockForDays = doc.splitTextToSize('Stock For Days', 45);
                     doc.cell(519, 80, 45, 30, StockForDays, '', 'C');*/
          doc.cellInitialize();
          doc.cell(364, 115, 50, 15, "Rept Wt", "", "C");
          doc.cell(414, 115, 50, 15, "Issue Wt", "", "C");
          doc.cell(464, 115, 55, 15, "Issue Amt", "", "C");
          doc.setFontType("normal");
          doc.setFontSize(7);
        }
        var a = 0;
        return Object.keys(prop).map((key, index) => {
          a = a + 1;
          var myItem = prop[key] + " ";
          return doc.cell(
            30,
            k,
            cellWidths[a],
            20,
            myItem,
            2,
            cellTextaligns[a]
          );
        });
      });
    }
    // var grandTotals = {
    //     "OpeningWeight": 25455 + "",
    //     "ReceiptWeight": 0.0 + "",
    //     "IssueWeight": 200 + "",
    //     "Rate": " ",
    //     "IssueAmount": 1300000 + "",
    //     "Closing": 2555005 + "",
    //     "ReptQty": 5544 + "",
    //     "IssueQty": 2455 + "",
    //     "IsueAmount": 25555 + "",
    //   //  "StockForDays": " "
    // };

    doc.cellInitialize();
    doc.setFontType("bold");
    doc.cell(30, k + 20, 75, 30, "Grand Total", "", "left");
    doc.cell(
      105,
      k + 20,
      42,
      30,
      grandTotals.openingWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      147,
      k + 20,
      42,
      30,
      grandTotals.receivedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(
      189,
      k + 20,
      42,
      30,
      grandTotals.issuedWeightTot + " ",
      "",
      "right"
    );
    doc.cell(231, k + 20, 40, 30, " ", "", "right");
    doc.cell(
      271,
      k + 20,
      51,
      30,
      grandTotals.issueAmountTot + " ",
      "",
      "right"
    );
    doc.cell(
      322,
      k + 20,
      42,
      30,
      grandTotals.closingweightcTot + " ",
      "",
      "right"
    );
    doc.cell(
      364,
      k + 20,
      50,
      30,
      grandTotals.receivedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      414,
      k + 20,
      50,
      30,
      grandTotals.issuedWeightToDateTot + " ",
      "",
      "right"
    );
    doc.cell(
      464,
      k + 20,
      55,
      30,
      grandTotals.issueAmountToDateTot + " ",
      "",
      "right"
    );
    //  doc.cell(519, k + 25, 45, 30, grandTotals.StockForDays, '', 'right');

    var Avgs = {
      AvgIssuePriceToday: 4240.74 + "",
      AvgIssuePriceTodate: 4240.74 + "",
      batchPriceToday: "4284.00",
      batchPriceTodate: " 4339.59",
    };
    doc.setFontType("normal");
    doc.setFontSize(10);

    // doc.text(30, k + 75, 'Avg. Issue Price : Today');
    // doc.text(170, k + 75, Avgs.AvgIssuePriceToday);
    // doc.text(220, k + 75, 'Todate');
    // doc.text(260, k + 75, Avgs.AvgIssuePriceTodate);
    // doc.text(30, k + 90, 'Batch Price        : Today');
    // doc.text(170, k + 90, Avgs.batchPriceToday);
    // doc.text(220, k + 90, 'Todate');
    // doc.text(260, k + 90, Avgs.batchPriceTodate);

    doc.text(30, k + 150, "Dept. Incharge");
    doc.text(400, k + 150, "Authorised Signature");
    doc.setFontType("normal");
    doc.setFontSize(7);
    var today = moment().format("DD/MM/YYYY H:mm:ss");
    var name = getCookie(cons.USERID);
    var newdat = " Printed on: " + today;
    var printBy = " Printed By: " + name;
    doc.text(30, k + 200, newdat);
    doc.text(500, k + 200, printBy);

    doc.save("JuteValueReport.pdf");
  };
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(LeaveRequest)
);
