import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi, serverVars } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import Search from "@material-ui/icons/Search";
import TableComponent from "../CustumComponents/TableComponent";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";

class AppDataWindingDoffEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      itemViewType: "id",
      date: moment().format("DD-MM-YYYY"),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      spell: "",
      yarnType: "",
      trollyId: "",
      empNo: "",
      grossWeightKG: "",
      tareWeightKG: "",
      netWeightKG: "",
      empName: "",
      dept: "",
      occupation: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      //  getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
      //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    }
  };
  handleDeleteClick = (id) => {
    this.basicDeleteAlert("Do you want to delete this record?", id);
    // var data = this.state.dataTable;
    var juteId = "";
    //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
  };
  handleDeleteClickResp = (resp) => {
    if (resp.status) {
      this.showMessage(resp.message, resp.status);
      this.setState({
        isRefresh: true,
      });
    } else {
      this.showMessage(resp.message, resp.status);
    }
  };

  handleViewClickResp = (resp) => {
    var header = resp;

    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
      });
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    if (this.state.fromDate !== "" || this.state.todate !== "") {
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
    } else
      var data = {
        fromDate: this.state.fromDate,
        toDate: this.state.todate,
        cipher: getCookie(cons.CIPHER),
      };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    //this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    if (myDate != null) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    var type = this.state.selectedJuteType;
    /*  this.setState({
              [stateKey]: selectedValue,
          });*/

    if (stateKey === "selectedJuteType") {
      this.setState({
        loadQuality: true,
      });
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  afterRefreshQuality = (val) => {
    this.setState({
      loadQuality: val,
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      spell: "",
      yarnType: "",
      trollyId: "",
      empNo: "",
      grossWeightKG: "",
      tareWeightKG: "",
      netWeightKG: "",
    });
  }

  hideAlertMessage() {
    this.setState({
      isRefresh: true,
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      //  date:moment().format('DD-MM-YYYY'),
      time: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      // spell:"0",
      //yarnType:0,
      trollyNo: 0,
      empNo: "",
      grossWeightKG: "",
      tareWeightKG: "",
      netWeightKG: "",
      empName: "",
      dept: "",
      occupation: "",
      reloadSelect: true,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DELETE_WINDING_DOFF_ENTRY + id + "/" + getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }
  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideUnitConvertionAlert() {
    this.setState({
      basicAlert: null,
      canChangeUnit: true,
      canChangeMukam: true,
      invoiceList: [],
      sumOFQuantities: 0,
    });
  }

  timeChangedFormat = (myDate, time) => {
    console.log("time", myDate + time);

    myDate = myDate.split("-");
    var timeParts = time.split(":");
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };
  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        spell,
        trollyNo,
        empNo,
        grossWeightKG,
        tareWeightKG,
        netWeightKG,
      } = this.state;
      console.log(this.state);
      var data = {
        entryDateTime: this.timeChangedFormat(
          this.state.date,
          this.state.time2
        ),
        spell: spell,
        // yarnType: "1",
        yarnType: this.state.yarnType,
        trollyNo: trollyNo,
        ebNo: empNo,
        grossWeight: grossWeightKG,
        tareWeight: tareWeightKG,
        netWeight: this.getNetWeight(),
        createdBy: getCookie(cons.USER_NAME),
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      // if (
      //   this.state.date !== "" &&
      //   spell !== "" &&
      //   spell !== "0" &&
      //   trollyNo !== "" &&
      //   trollyNo !== "0" &&
      //   empNo !== "" &&
      //   grossWeightKG !== ""
      // ) {
      //   this.handleLoading();
        // requestList(
        //   serverApi.ADD_WINDING_DOFF_ENTRY,
        //   data,
        //   this.handleFinishing
        // );
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      const validateFields = [
        { field: this.state.date, msg: "Date", type: 2 },
        { field: spell, msg: "Spell", type: 2 },
        { field: this.state.yarnType, msg: "yarn Type", type: 2 },
        { field: trollyNo, msg: "Trolly", type: 2 },
        { field: empNo, msg: "Emp No.", type: 1 },
        { field: grossWeightKG, msg: "Gross Weight", type: 2 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.ADD_WINDING_DOFF_ENTRY,
          data,
          this.handleFinishing
        );
      }
    }
  };
  handleFinishing = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getEmpDetails = () => {
    const { empNo } = this.state;
    if (empNo !== "") {
      getDataFromUrl(
        serverApi.WORKER_MASTER_DETAIL_BY_EBNO +
          empNo +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.USERID),
        this.handleEmpDetails
      );
    } else {
      this.basicAlert("Please Enter Employee No..!!");
    }
  };
  handleEmpDetails = (resp) => {
    if (resp.status) {
      var header = resp.data;
      this.setState({
        empName: header.workerName,
        dept: header.deptIdDesc,
        occupation: header.designationIdDesc,
      });
    } else {
      this.showMessage(resp.message, false);
    }
  };
  getTrollyDetails = (selectedValue) => {
    var val = selectedValue.split("^");
    this.setState({
      tareWeightKG: val[1],
    });
  };
  getNetWeight() {
    console.log("grossWeightKG ", this.state.grossWeightKG);
    if (this.state.grossWeightKG > 0 && this.state.tareWeightKG > 0)
      return (
        parseFloat(this.state.grossWeightKG) -
        parseFloat(this.state.tareWeightKG)
      );
    else return 0;
  }
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        //  time2:dateObj.format('HH:mm:ss')
      });
  };

  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };

  render() {
    const { classes } = this.props;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Date
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "date");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "date",
                            value: this.state.date,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Time
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="" fullWidth>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          // defaultValue={this.state.time1}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.timeChanged1(timeObj, "time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "time",
                            value: this.state.time,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Spell
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.spell}
                        id={"spell"}
                        getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="spell"
                        url={
                          serverApi.GET_ALL_SPELL_LIST +
                          this.props.state.companyInfo.id +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        value={this.state.spell}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Yarn Type
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.yarnType}
                        id={"yarnType"}
                        getName={this.getQualityDetails}
                        onSelect={this.onSelect}
                        stateKey="yarnType"
                        url={
                          serverApi.YARN_MASTER_TYPE_LIST +
                          this.props.state.companyInfo.id
                        }
                        value={this.state.yarnType}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Trolly
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={this.state.trollyNo}
                        id={"trollyNo"}
                        isReload={this.state.reloadSelect}
                        setIsReload={this.afterReloadSelect}
                        getName={this.getTrollyDetails}
                        onSelect={this.onSelect}
                        stateKey="trollyNo"
                        url={
                          serverApi.GET_TROLLY_LIST +
                          "5/" +
                          this.props.state.companyInfo.id +
                          "/" +
                          getCookie(cons.CIPHER)
                        }
                        value={this.state.trollyNo}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Emp No
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="empNo"
                        // label="Multiline"
                        value={this.state.empNo}
                        onChange={this.onChangeValues}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                <Search
                                  className={classes.inputAdornmentIcon}
                                  onClick={this.getEmpDetails}
                                />
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "15px",
                  }}
                />
                <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                  <p>Name : {this.state.empName}</p>
                  <p>Department : {this.state.dept}</p>
                  <p>Occupation : {this.state.occupation}</p>
                </ItemGrid>

                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Gross Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="grossWeightKG"
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.grossWeightKG}
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Tare Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="tareWeightKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.state.tareWeightKG}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Net Weight
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="netWeightKG"
                        disabled={true}
                        className={classes.textField}
                        onChange={this.onChangeValues}
                        value={this.getNetWeight()}
                        fullWidth={true}
                        variant="outlined"
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: "#00ABDC",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                KG
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12}>
                  <GridContainer>
                    <ItemGrid xs={5} style={{ marginTop: "10px" }}>
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontWeight: 400,
                        }}
                        onClick={() => {
                          if (
                            this.state.date !== "" &&
                            this.state.spell !== ""
                          ) {
                            var data = {
                              doffDate: this.state.date,
                              spell: this.state.spell,
                              companyId: this.props.state.companyInfo.id,
                            };
                            this.handleLoading();
                            requestList(
                              serverApi.UPDATE_MC_NO_IN_WINDING_PROD,
                              data,
                              this.handleAddIndent
                            );
                          } else {
                            this.basicAlert("Please select date and spell..!!");
                          }
                        }}
                      >
                        Click here to update machine number
                      </span>
                    </ItemGrid>
                    {!this.state.updateView ? (
                      <ItemGrid xs={6} style={{ marginTop: "5px" }}>
                        <GridContainer>
                          <ItemGrid>
                            <Button
                              color="primary"
                              onClick={this.onCreateButtonClick}
                            >
                              Create
                            </Button>
                          </ItemGrid>

                          <ItemGrid>
                            <Button color="danger" onClick={this.handleCreate1}>
                              Cancel
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={10} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={6}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            From date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            To date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "todate");
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.todate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={2}>
                  <GridContainer>
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        right={true}
                        onClick={this.handleSeacrh}
                      >
                        Search
                      </Button>
                      <br />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={12}>
                  <TableComponent
                    url={serverApi.GET_WINDING_ENTRY_TABLE}
                    searchData={this.getData()}
                    isRefresh={this.state.isRefresh}
                    handleSearch={this.handleSeacrh.bind(this)}
                    isLoading={this.state.isRefresh}
                    onActionClick={this.handleDeleteClick}
                    itemViewType={this.state.itemViewType}
                    actionType={DELETE}
                    showDelete={false}
                    fileName={"WindingDoffEntry"}

                    // onDeleteClick={this.handleDeleteClick}
                  />
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  inputAdornmentIcon: {
    color: "#00ABDC",
    cursor: "pointer",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AppDataWindingDoffEntry)
);
