import React from "react";
// @material-ui/icons

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import AttachmentIcon from "@material-ui/icons/AttachFile";

import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig,serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {FILE_DOWNLOAD, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, uploadFormdataWithImage} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import {Link} from "react-router-dom";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import InputAdornment from "material-ui/Input/InputAdornment";
import { connect } from "react-redux";

class MasterData extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,
            itemViewType:"uploadedFilePath",
            approveStatus:false,
            alert:false,
            masterDataType:"",
            itemName:"",
            companyId:this.props.state.companyInfo.id
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);

    }
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    componentDidMount() {
        this.callMasterDataUploadList();
    }
    callMasterDataUploadList(){
        getDataFromUrl(serverApi.GET_MASTER_DATA_LIST+"/"+this.props.state.companyInfo.id,this.handleList);
    }
    handleList=(resp)=>{
        this.setState({tableList:resp,isRefresh:true})
    };

    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
        if(!this.state.isCreated)
            this.hideAlertMessage();

        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "comId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER)

        };
        return data;
    };


    onChangeValues =(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });

    };

    downloadFile=(id)=>{
        if(id!==null)
            var url =(process.env.NODE_ENV === 'development') ? "http://qa.vowjute.com:8080" :  "http://"+window.location.hostname+":8080";
         window.location.assign(url+id);
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        //  console.log("file type",file.type);
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'XLSX' || fileType === 'XLS') {
                this.setState({
                    file: file,
                    itemName: file.name,
                    fileType:fileType,
                    //filesList: arr,
                    imagePreviewUrl: reader.result
                });
                console.log("filename",this.state.itemName)
                //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
            } else {
                this.basicAlert("File type you have selected can not be upload. Please select another file!!!")
            }


        };
        reader.readAsDataURL(file);
    }

    onSelect = (selectedValue, stateKey) => {

        getDataFromUrl(serverApi.GET_MASTER_DATA_SAMPLE_FILE+"/"+selectedValue,this.handleFile);

        this.setState({
            [stateKey]: selectedValue,
        });


    };
    handleFile=(resp)=>{
        this.setState({sampleFile:resp.data})
    };
    // getName = (selectedValue) => {
    //     var values=selectedValue;
    //     values=values.split("^");
    //     this.setState({
    //         selectedJuteName:values[0],
    //         selectedGrpId:values[1],
    //         selectedUnit:values[2],
    //         selectedDeptId:values[3],
    //     });
    // };


    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:false,
            loading:false,
            showUseralert:false
        });
    }
    hideAlertMessage() {
        console.log("alert");
        this.callMasterDataUploadList();
        this.setState({
            updateView: false,
            alert: false,
            showMessagealert:false,
            loading:false,
            isCreated:false,
            masterDataType:"",
            itemName:""
        });
    }

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onUploadButtonClick =()=>{
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {file,itemName,masterDataType,fileUploadStatus,attachedFile,fileType}=this.state;
            const formData = new FormData();
            if(file!==null )
                formData.append( "attachedFile", file);
            if(itemName!==null )
                formData.append( "uploadFileName", itemName);
            if(masterDataType!==null )
                formData.append( "masterDataType", masterDataType);
            if(masterDataType!==null )
                formData.append( "fType", fileType);
            formData.append( "fileUploadStatus", "Y");
            formData.append( "createdBy", getCookie(cons.USERID));
            formData.append( "companyId", this.props.state.companyInfo.id);


            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }


            if(file!=="" && masterDataType!==""){
                this.handleLoading();
                uploadFormdataWithImage(serverApi.ADD_MASTER_DATA,formData,this.handleMasterType)
            }else{
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }





    }




    handleMasterType = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}

                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };


    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    handleSelectedValues = (defValue) => {
        this.setState(
            {
                activeStatus:defValue,
                activeStatusId:defValue.value,
            });
    };
    getActiveStatus = (defValue) => {
        if(defValue ==='0'){
            return { value: 0, label: 'InActive',name: 'InActive' }
        }else  if(defValue==='1'){
            return { value: 1, label: 'Active' ,name:'Active'}

        }else return""
    };
    afterRefreshUsers = (val) => {

        this.setState({
            loadUsers: val
        });
    };

    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };


    render(){
        const { classes } = this.props;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'Active' ,name:'Active'},
            { value: 0, label: 'InActive',name: 'InActive' }

        ];
        const inputFocus={
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated?
                    <ItemGrid xs={12}>
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        {this.state.showUseralert}
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Master Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"masterDataType"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.masterDataType}
                                                    isRefresh={this.state.loadUsers}
                                                    setIsRefresh={this.afterRefreshUsers}
                                                    // getName={this.getDeptName}
                                                    stateKey="masterDataType"
                                                    url={serverApi.GET_MASTER_TYPE_LIST}
                                                    value={this.state.masterDataType}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    {
                                        this.state.masterDataType!==""?
                                            <ItemGrid xs={12} md={6}  style={{marginTop:"20px"}} >
                                                <GridContainer>
                                                        <InputLabel className={classes.label} style={{marginLeft:"30px"}} >
                                                            Click On Attachment Icon To Get Sample File
                                                        </InputLabel>
                                                        <IconButton
                                                            style={{marginTop:"-10px"}}
                                                            onClick={() => {
                                                               this.downloadFile(this.state.sampleFile);
                                                            }}
                                                            color="blackNoBackground"
                                                            customClass="edit">
                                                            <AttachmentIcon />
                                                        </IconButton>

                                                </GridContainer>
                                            </ItemGrid>


                                            :null
                                    }

                                    {
                                        this.state.masterDataType!==""?
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Upload File<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="itemName"
                                                    disabled={true}
                                                    value={this.state.itemName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        autoComplete: 'off',
                                                        endAdornment: <InputAdornment
                                                            position="end">
                                                            <span style={{
                                                                color: "#003F66",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                cursor:"pointer",
                                                            }} onClick={this.handleFileClick}>BROWSE</span>
                                                        </InputAdornment>,
                                                    }}


                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                            :null
                                    }

                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onUploadButtonClick}>Upload</Button>
                                                </ItemGrid>
{/*
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
*/}

                                            </GridContainer>

                                        </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid>:
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {/*{*/}
                                               {/*this.state.tableList!==undefined?*/}
                                                {/*console.log("rrrrrrrrrrrrrrrrrrrrr",this.state.tableList.data[7]):null*/}
                                            {/*}*/}

                                            <TableComponent
                                                staticData={this.state.tableList}
                                                 searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.downloadFile}
                                                itemViewType={this.state.itemViewType}
                                                actionType={FILE_DOWNLOAD}
                                                showDelete={false}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
};

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(MasterData)
);
