import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Print from "@material-ui/icons/Print";
import ExpandMoreIcon from "@material-ui/icons/Add";
import PaginationTableComponent from "../CustumComponents/PaginationTableComponent";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import InputAdornment from "material-ui/Input/InputAdornment";
import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
  configNames,
  getConfigValue,
  serverApi,
  serverConfig,
  getTenantId,
} from "../../helper/Consts";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import moment from "moment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import Select from "react-select";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import MultiSelect from "../CustumComponents/SelectboxMultiple";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import { GoodTypes, ReasonTypes } from "../TableData";
import LogsComponents from "../CustumComponents/LogsComponents";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class StoreMaterialInsc extends React.Component {
  /*
        handleCreate = () => {
            //alert();
            console.log(this.state);
            this.setState({
                isCreated: !this.state.isCreated,
            });
        };
    */
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      // fromDate:
      //   getCookie(cons.YEAR_FROM_DATE) && getCookie(cons.YEAR_FROM_DATE) != null
      //     ? getCookie(cons.YEAR_FROM_DATE)
      //     : "",
      // todate:
      //   getCookie(cons.YEAR_TO_DATE) && getCookie(cons.YEAR_TO_DATE) != null
      //     ? getCookie(cons.YEAR_TO_DATE)
      //     : "",
      fromDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      vehicleNo: "",
      gateEntryNO: "",
      selectMukam: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100"
          ? getCookie(cons.SUPP_CODE)
          : null,
      brokerId: "",
      poNo: "",
      isDisabled: false,
      outDateTime: "",
      itemViewType: "id",
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      entryId: "",
      selectedPO: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      uom: "",
      branchId: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      outDate: "",
      outTime: "",
      invoiceList: [],
      projectIds: "",
      branchIds: "",
      internalNote: "",
      goodType: "GRN",
      costDesc: "",
      costFactor: "",
      description: "",
      supplierList: [],
      filesList: [],
      inwardStatus: "",
      hsnCode: "",
      dataTable: "",
      startIndex: 0,
      pageSize: 10,
      lastIndex: 10,
      inwardNum: "",
      materialInsId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  componentDidMount() {
    this.getData(this.state.startIndex, this.state.lastIndex);

    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    getDataFromUrl(
      serverApi.SELECT_SUPPLIER_STORE + this.props.state.companyInfo.id,
      this.handleSuppliers
    );
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments
    );
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
  }
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      this.setState({
        projectNameList: projectNameList,
      });
    }
  };
  handleDepartments = (resp) => {
    this.setState({ budgetHeadList: resp, loadDepartment: true });
  };
  getBudgetName = (selectedValue) => {
    this.setState({
      selectedbudgetName: selectedValue,
    });
  };
  handleSuppliers = (resp) => {
    this.setState({ supplierList: resp, loadSupplier: true });
  };
  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };

  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      this.setState({
        projectNameList: projectNameList,
      });
    }
  };
  hideAlert() {
    this.setState({
      id: 0,
      indentAlert: false,
      alert: false,
      loading: false,
      costAlert: false,
      showMessagealert: false,
      deptName: "",
      itemGrpName: "",
      itemName: "",
      uom: "",
      stock: "",
      rate: "",
      quantity: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      poNo: "",
      poLineItemId: "",
      costDesc: "",
      costFactor: "",
      description: "",
    });
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      if (this.state.goodType === "SRN")
        this.setState({
          costAlert: true,
          updateItemAlert: false,
        });
      else
        this.setState({
          alert: true,
          updateItemAlert: false,
        });
      //  this.hideAlert();
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      // data.splice(this.state.id, 1);
      if (this.state.updateView) data[this.state.id].isActive = 0;
      else data.splice(this.state.id, 1);
      //  if(this.state.approveButton)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      updateView: false,
      showMessagealert: false,
      isDisabled: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      selectedPO: "",
      selectedDepartment: "",
      selectedItemGroup: "",
      selectedItem: "",
      quantity: "",
      itemGrpName: "",
      itemName: "",
      deptName: "",
      uom: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      invoiceList: [],
      outDateTime: "",
      id: 0,
      branchId: "",
      poLineItemId: "",
      consignmentNo: "",
      consignmentDate: "",
      inspectionChk: "",
      projectId: "",
      projectIds: "",
      branchIds: "",
      internalNote: "",
      goodType: "GRN",
      filesList: [],
    });
  }

  /*
        handleViewClick =(id)=>{
            // var data = this.state.dataTable;
            var juteId="";
            console.log("jute  "+"  "+id);
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL+id+"/"+getCookie(cons.USERID)+"/getIndentDetails",this.handleViewClickResp)
        };
    */
  /*
        <span style={{color:"red" ,fontSize:"12px"}}>*</span>(){
            var arr = this.state.invoiceList;
            /!*
                                                                            data.find((o,i) => {
                                                                                if(o.id === key){
                                                                                    // here you should add some custom code so you can delete the data
                                                                                    // from this component and from your server as well
                                                                                    data.splice(i,1);
                                                                                    return true;
                                                                                }
                                                                                return false;
                                                                            });
            *!/

            arr.splice(arr.length, 0, this.state.addItemObj);
            console.log(arr.toString());

            this.setState({
                loading:true,
                invoiceList:arr
            });
            this.hideAlert();
        }
    */
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  getData = (startIndex, lastIndex) => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      supplierId: this.state.supplierId,
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
      isInspection: true,
      recordType: "GRN",
      poNum: this.state.poNum,
      lastIndex: this.state.lastIndex ? this.state.lastIndex : lastIndex,
      startIndex: this.state.startIndex ? this.state.startIndex : startIndex,
      inwardNum: this.state.inwardNum,
    };
    requestList(serverApi.GET_ALL_INWARDS, data, this.handleReports);
  };
  handleReports = (resp) => {
    this.setState({
      dataTable: resp,
      isRefresh: true,
    });

    // this.handleLoading();
  };
  handlePage = (start, lastIndex) => {
    // console.log("Nagesh handlepage", start, pageSize);
    this.getData(start, lastIndex);
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };
  //dateFormate
  dateChanged = (myDate) => {
    if (myDate !== null && myDate !== undefined && myDate !== "") {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };
  timeChangedFormat = (myDate, time) => {
    console.log("time", time);
    // var time = new Date(myDate+" "+time).getTime();
    //var localDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2], timeParts[0], timeParts[1]);
    // console.log("time",time)
    myDate = myDate.split("-");
    var timeParts = time.split(":");
    // var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2] + timeParts[0]+ timeParts[1]+timeParts[2];
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    this.setState({
      [event.target.id]: value,
    });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    var loading = false;
    if (!this.state.isRefresh) {
      this.getData(this.state.startIndex, this.state.lastIndex);

      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "selectedDepartment") {
      this.setState({
        loadItemGroup: true,
        loadItem: true,
      });
    }
    if (stateKey === "branchId" || stateKey === "branchIds") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "selectedItemGroup") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
      };

      requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
      // this.setState({
      //     loadItem:true
      // })
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };

  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
      loadItem: val,
    });
  };
  getSupplierName = (selectedValue) => {
    var name = selectedValue.split("_");
    name = name[0];
    this.setState({
      supplierName: name,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      deptName: selectedValue,
    });
  };

  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGrpName: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue;
      values = values.split("^");
      this.setState({
        itemName: values[0],
        // selectedItemId:values[1],
        uom: values[1],
        stock: values[2],
        rate: this.state.itemName === values[0] ? this.state.rate : values[3],
      });
    }
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  dateChanged = (date) => {
    var myDate = date;
    if (date) {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];

      return new Date(newDate).getTime();
    } else return "";
  };

  handleViewClick = (id) => {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.INWARD_FETCH_BY_ID + id + "/" + getCookie(cons.USERID),
        this.handleViewClickResp
      );
      this.setState({
        materialInsId: id,
      });
    }
  };

  getDefaultChalanType = (name) => {
    var val = "",
      label = "",
      data = "";
    if (name === "WITHPO") {
      val = 1;
      label = "With PO";
    } else {
      val = 2;
      label = "Without PO";
    }
    data = {
      value: val,
      label: label,
    };
    return data;
  };

  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    var header = resp;
    var list = resp.inwardDetails;
    var indentlist = [],
      poNo = [],
      indentNo = [];
    list.forEach((prop, key) => {
      // if (poNo.indexOf(prop.poHdrid) <= -1 && prop.poHdrid) {
      //     poNo.push(prop.poHdrid);
      //     getDataFromUrl(serverApi.GET_FILE_BY_SOURCE + "14/" + prop.poHdrid + "/" + this.props.state.companyInfo.id, this.handleInvoiceFiles);

      // }
      // if (indentNo.indexOf(prop.indentHdrid) <= -1 && prop.indentHdrid) {
      //     indentNo.push(prop.indentHdrid);
      //     getDataFromUrl(serverApi.GET_FILE_BY_SOURCE + "10/" + prop.indentHdrid + "/" + this.props.state.companyInfo.id, this.handleInvoiceFiles);

      // }
      console.log(
        "getting the view byId response",
        typeof prop.approvedQty === "object" ? prop.approvedQTY : prop.inwardQty
      );
      if (prop.isActive !== 0) {
        var obj = {
          id: key,
          hsnCode: prop.hsnCode,
          discountPercentage: prop.discount,
          discountMode: prop.discountMode,
          lineId: prop.id,
          department: prop.departmentName,
          itemGroup: prop.itemGroup,
          item: prop.itemName,
          deptId: parseInt(prop.departmentId),
          itemGroupId: prop.itemGroupId,
          itemId: prop.itemId + "",
          itemGrp: prop.itemGroupId + "" + prop.itemCode,
          quantity: prop.inwardQty,
          poQuantity: parseFloat(prop.poQuantity) + parseFloat(prop.inwardQty),
          unit: prop.uom,
          poNo: prop.inwardId,
          rate: prop.rate,
          poId: prop.poId,
          poSequence: prop.poSequence,
          poDetailsId: prop.poDetailsId,
          isActive: prop.deleteRec ? 0 : 1,
          budgetHeadId: prop.budgetHeadId + "",
          budgetHeadName: prop.budgetHeadName,
          description: prop.description,
          inwardId: prop.inwardId,
          oldQty: prop.approvedQty === null ? prop.oldQty : prop.approvedQty,
          // oldQty:
          //   prop.approvedQty === null && prop.approvedQty === "null"
          //     ? prop.oldQty
          //     : prop.approvedQty,
          // approvedQTY: prop.approvedQty ? prop.approvedQty : prop.inwardQty,
          approvedQTY:
            typeof prop.approvedQty === "object"
              ? prop.inwardQty
              : prop.approvedQty,
          rejectedQTY: prop.rejectedQty,
          itemStatus: prop.status > 1 ? prop.status : 3,
          reason: prop.reason,
          /*
                                      actions: (
                                          // we've added some custom button actions
  
                                          <div className="actions-right">
                                              <IconButton
                                                  onClick={() => {
                                                      var data = this.state.invoiceList;
                                                      data.find((o,i) => {
                                                          if(o.id === key){
                                                              this.basicItemAlert("Do You Like To Update This Item?");
                                                              // here you should add some custom code so you can delete the data
                                                              // from this component and from your server as well
                                                              this.setState({
                                                                //  alert:true,
                                                                  id:key,
                                                                  updateItemAlert:true,
                                                                  lineId:o.lineId,
                                                                  updateItem: true,
                                                                  deptName:o.department,
                                                                  itemGrpName:o.itemGroup,
                                                                  itemName:o.item,
                                                                  uom:o.unit,
                                                                  stock: o.stockValue,
                                                                  quantity:o.quantity,
                                                                  selectedDepartment: o.deptId,
                                                                  selectedItemGroup:o.itemGroupId,
                                                                  selectedItem:o.itemId,
                                                                  poNo:o.poNo,
                                                                  rate:o.rate,
                                                                  poQuantity:o.poQuantity,
                                                                  poLineItemId:o.poLineItemId,
                                                                  loadItem:true,
                                                                  loadItemGroup:true
                                                              });
                                                              return true;
                                                          }
                                                      });
                                                  }}
                                                  color="primaryNoBackground"
                                                  customClass="remove">
                                                  <Edit />
                                              </IconButton>{" "}
  
                                              { /!* use this button to remove the data row *!/ }
                                              <IconButton
                                                  onClick={() => {
                                                      var data = this.state.invoiceList;
                                                      data.find((o,i) => {
                                                          if(o.id === key){
                                                              // here you should add some custom code so you can delete the data
                                                              // from this component and from your server as well
                                                              //if (this.state.approveButton) {
  
                                                              this.setState({deleteItem: true,
                                                                  id:key,});
  
                                                              this.basicItemAlert("Do You Like To Delete This Item?");
                                                              //  data.splice(i,1);
                                                              // }
                                                              return true;
                                                          }
                                                      });
                                                      this.setState({invoiceList: data});
                                                  }}
                                                  color="dangerNoBackground"
                                                  customClass="remove">
                                                  <Close />
                                              </IconButton>{" "}
                                          </div>
  
                                      )
                  */
        };
        //  if(!prop.deleteRec)
        indentlist.push(obj);
      }
    });

    //  console.log("indentWeight "+header.indentWeight);

    if (header.poNo !== "") var poNo = header.poNo;

    if (header !== null) {
      this.setState({
        updateItem: false,
        updateView: true,
        isCreated: true,
        isDisabled: true,
        approveButton: header.approveButton,
        entryId: header.id,
        workReportNo: header.id,
        chalanNo: header.challanno,
        chalanDate: header.challannoDate,
        selectedSupplier: header.supplierId + "",
        supplierName: header.supplierName,
        inwardSequenceNo: header.inwardSequenceNo,
        // remarks: header.remarks,
        // date1: header.createDateDesc,
        // time2: header.createdTimeDesc,

        selectedPO: header.poId,
        lastModifiedBy: header.lastModifiedBy,
        poDates:
          header.podatedescription !== "" ? header.podatedescription : [],
        createdBy: header.createdBy,
        invoiceList: indentlist,
        SelectedChalanType: this.getDefaultChalanType(header.source),
        branchId: header.branchId,
        inspectionChk: header.inspectionChk,
        projectId: header.projectId,
        internalNote: header.internalNote,
        goodType: header.goodType,
        inwardStatus: header.status,
        approveButton: header.approveButton,
        status: header.status,
        inspectionApprovedBy: header.inspectionApprovedBy,
        // creditnoteValue: header.creditnoteValue,
      });

      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "18/" +
          header.id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };
  //out
  onOutButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          dtlId: prop.lineId,
          itemCode: prop.itemId,
          itemDesc: prop.item,
          itemGroupId: prop.itemGroupId,
          // qualityCode:prop.quality,
          dept: prop.deptId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          //stock:prop.stockValue,
          quantity: Number(prop.quantity),
          uom: prop.unit,
          poLineItemId: prop.poLineItemId,
          poNo: prop.poNo,
          deleteRec: prop.isActive === 0,
          costFactor: prop.costFactor,
          description: prop.description,
        };
      });

      var data = {
        entryHeader: {
          hdrId: this.state.entryId,
          challanNo: this.state.chalanNo,
          challanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          remarks: this.state.remarks,
          finYear: this.state.year,
          consignmentNo: this.state.consignmentNo,
          consignmentDate: this.dateChanged(this.state.consignmentDate),
          branchId: this.state.branchId,
          projectId: this.state.projectId,
          /*inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1, this.state.time2),*/
          outDate: this.dateChanged(this.state.outDate),
          outTime: this.timeChangedFormat(this.state.outDate, this.state.time2),
          //  pos: this.state.selectedPO,
          updateBy: getCookie(cons.USER_NAME),
          internalNote: this.state.internalNote,
          companyId: this.props.state.companyInfo.id,
        },
        gateStoreLineItems: invoiceList,
      };
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (
          this.state.invoiceList.length >= 1 &&
          this.state.outDate !== "" &&
          this.state.outTime &&
          branchId !== "" &&
          branchId !== 0 &&
          projectId !== "" &&
          projectId !== 0
        ) {
          this.handleLoading();
          requestList(
            serverApi.UPDATE_STORE_GATE_ENTRY,
            data,
            this.handleAddStoreGate
          );
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter Out Date & Time..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  //approve
  onApproveButtonClick = (inwardStatus) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var aprroveStatus = false,
        emptyQty = false;
      var myreson = false;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        console.log("we are getting prop", prop);
        if (prop.itemStatus === 0 || prop.itemStatus === "") {
          aprroveStatus = true;
        }
        if (
          prop.itemStatus === 1 &&
          (prop.approvedQTY === "" ||
            prop.approvedQTY === null ||
            parseFloat(prop.approvedQTY) <= 0)
        ) {
          emptyQty = true;
        }
        this.setState({
          hsnCode: prop.hsnCode,
        });
        var rejectedQty = Number(prop.quantity) - Number(prop.approvedQTY);
        if (rejectedQty > 0) {
          if (!prop.reason) {
            myreson = true;
            this.basicAlert("Please Enter Reason..!!");
          }
        }

        console.log("getting the data invoice list", prop);

        return {
          id: prop.lineId,
          departmentId: prop.deptId,
          discountMode: prop.discountMode,
          discount: prop.discountPercentage,
          itemId: prop.itemId,
          itemGroupId: prop.itemGroupId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          budgetHeadId: prop.budgetHeadId + "",
          inwardQty: Number(prop.quantity),
          inwardId: prop.inwardId,
          uom: prop.unit,
          description: prop.description,
          approvedQty:
            prop.approvedQuantity === 0
              ? prop.approvedQuantity
              : prop.approvedQTY,
          // rejectedQty: prop.rejectedQTY,
          rejectedQty:
            prop.approvedQuantity === 0
              ? prop.rejectedQTY
              : Number(prop.quantity) - Number(prop.approvedQTY),
          status: prop.approvedQTY === "0" ? 4 : prop.itemStatus,
          reason: prop.reason,
          // oldQty:
          //   prop.approvedQty === null
          //     ? prop.oldQty
          //     : prop.approvedQuantity === 0
          //     ? prop.approvedQuantity
          //     : prop.approvedQTY,
          oldQty: prop.oldQty,

          poId: prop.poId,
          hsnCode: prop.hsnCode,
        };
      });

      var data = {
        id: this.state.entryId,
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType == 1 ? "WITHPO" : "WITHOUTPO",
        recordType: "GRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        lastModifiedBy: getCookie(cons.USERID),
        inwardSequenceNo: this.state.inwardSequenceNo,
        //	isCommitted: isCommitCheck,
        userName: getCookie(cons.USER_NAME),
        cipher: getCookie(cons.CIPHER),
        inwardDetails: invoiceList,
        status: this.state.isApprove ? this.state.status : "",
        storeReceiptDate: this.state.date1,
        // creditnoteValue: parseFloat(this.state.creditnoteValue),
      };
      if (!myreson) {
        // new validation code....

        const validateFields = [
          { field: this.state.branchId, msg: "Branch", type: 2 },
          { field: this.state.projectId, msg: "Project", type: 2 },
          { field: this.state.selectedSupplier, msg: "Supplier", type: 2 },
          {
            field: this.state.SelectedChalanType,
            msg: "Category Type No.",
            type: 2,
          },
          { field: this.state.workReportNo, msg: "Inward No.", type: 1 },
          { field: this.state.invoiceList, msg: "one lineItem", type: 2 },
        ];

        const validObj = validate(validateFields);

        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message}...!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message}...!!`);
          }
        } else {
          if (inwardStatus) {
            if (!aprroveStatus) {
              if (!emptyQty) {
                this.handleLoading();
                requestList(
                  serverApi.EDIT_INWARD,
                  data,
                  this.handleAddStoreGate
                );
                console.log("updated successfully if", data);
              } else {
                this.basicAlert(
                  "Please Enter Approve Quantity For Approved Items..!!"
                );
              }
            } else {
              this.basicAlert("Please Add Status To All Line Items..!!");
            }
          } else {
            this.handleLoading();
            requestList(serverApi.EDIT_INWARD, data, this.handleAddStoreGate);
            // console.log("we are getting hsncode", this.state.hsnCode);
            console.log("updated successfully else", data);
          }
        }

        // old validation code...

        // if (
        //   SelectedChalanType !== 0 &&
        //   SelectedChalanType !== "" &&
        //   selectedSupplier !== 0 &&
        //   selectedSupplier !== ""
        // ) {
        //   if (this.state.invoiceList.length >= 1) {
        //     if (inwardStatus) {
        //       if (!aprroveStatus) {
        //         if (!emptyQty) {
        //           this.handleLoading();
        //           requestList(
        //             serverApi.EDIT_INWARD,
        //             data,
        //             this.handleAddStoreGate
        //           );
        //           console.log("updated successfully if", data);
        //         } else {
        //           this.basicAlert(
        //             "Please Enter Approve Quantity For Approved Items..!!"
        //           );
        //         }
        //       } else {
        //         this.basicAlert("Please Add Status To All Line Items..!!");
        //       }
        //     } else {
        //       this.handleLoading();
        //       requestList(serverApi.EDIT_INWARD, data, this.handleAddStoreGate);
        //       // console.log("we are getting hsncode", this.state.hsnCode);
        //       console.log("updated successfully else", data);
        //     }
        //   } else if (this.state.invoiceList.length < 1) {
        //     this.basicAlert("Please Add Atleast One Item..!!");
        //   }
        // } else {
        //   this.basicAlert("Please Enter All Mandatory Fields..!!");
        // }
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          rate: prop.rate !== "null" ? prop.rate : 0,
          budgetHeadId: prop.selectedBudgetHeadItem,
          inwardQty: Number(prop.quantity),
          uom: prop.unit,
          description: prop.description,
          poDetailsId: prop.poDetailsId,
          poId: prop.poId,
        };
      });

      var data = {
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType == 1 ? "WITHPO" : "WITHOUTPO",
        recordType: "SRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        createdBy: getCookie(cons.USERID),
        inwardDetails: invoiceList,
        // creditnoteValue: parseFloat(this.state.creditnoteValue),
      };
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== "" &&
        branchId !== "" &&
        branchId !== 0 &&
        projectId !== "" &&
        projectId !== 0
      ) {
        if (this.state.invoiceList.length >= 1) {
          /* if (this.state.selectedPO !== "") {*/
          this.handleLoading();
          requestList(serverApi.SAVE_INWARD, data, this.handleAddStoreGate);
          /*} else {
                    this.onCreatePO();
                }*/
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCommitButtonClick = () => {
    this.setState({
      commitClicked: true,
    });
    this.onApproveButtonClick(true);
  };

  handleAddStoreGate = (resp) => {
    if (resp.status) {
      // if(this.state.commitClicked){
      //     var data={
      //         "userId":getCookie(cons.USERID),
      //         "userName":getCookie(cons.USER_NAME),
      //         "hdrId":this.state.entryId,
      //         "cipher":getCookie(cons.CIPHER)
      //     };
      //     this.handleLoading();
      //     requestList(serverApi.COMMIT_STORE_MATERIAL_INSPECTION,data,this.handleAddStoreGate)

      //     this.setState({
      //         commitClicked:false
      //     })
      // }else
      this.showMessage(resp.message, resp.status);
      this.getData(this.state.startIndex, this.state.lastIndex);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };
  onCreatePO = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          itemId: prop.itemId,
          itemDesc: prop.itemDesc,
          qualityCode: "",
          quantity: prop.quantity,
          unitId: prop.unit,
          rate: prop.rate,
          allowableMoisturePercentage: "",
          cTax: "",
          sTax: "",
          iTax: "",
          bale: "",
          loose: "",
          amount: parseFloat(prop.quantity) * parseFloat(prop.rate),
          marka: "",
          deptId: prop.depId,
          valueWithoutTax: parseFloat(prop.quantity) * parseFloat(prop.rate),
          valueWithTax: "",
          tax: "",
          indentId: "",
          actualQuantity: "",
          itemGroupId: prop.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
        };
      });

      var data = {
        poHeader: {
          type: "S",
          poDate: this.state.date1,
          creditTerm: 60,
          deliveryTimeline: 15,
          vehicleTypeId: "",
          vehicleQuantity: "",
          supplierId: this.state.selectedSupplier,
          mukam: "",
          brokerId: "",
          frieghtCharge: "",
          remarks: this.state.remarks,
          juteUnit: "",
          exceptedDate: moment()
            .add("days", 15)
            .format("DD-MM-YYYY"),
          cTax: "",
          sTax: "",
          iTax: "",
          taxType: "",
          valueWithoutTax: "",
          submitter: getCookie(cons.USERID),
          indentNum: "",
          finnacialYear: this.state.year,
          footerNote: "",
          valueWithTax: "",
          tax: "",
          deliveryAddress: "",
          discount: "",
          weight: 0,
          brokerName: "",
          companyId: this.props.state.companyInfo.id,
        },
        poLineItem: invoiceList,
        cipher: getCookie(cons.CIPHER),
      };
      console.log(data);
      if (
        this.state.invoiceList.length >= 1 &&
        this.state.supplier_Id !== "" &&
        this.state.taxType !== "" &&
        this.state.form_purchaseType !== "" &&
        this.state.totalTax !== "" &&
        this.state.totalAmount !== ""
      ) {
        this.handleLoading();
        requestList(serverApi.ADD_JUTE_PO, data, this.handlePOcreate);
      } else if (this.state.invoiceList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };
  handlePOcreate = (resp) => {
    if (resp.status) {
      this.setState({
        selectedPO: resp.id,
      });
      if (!getMenuPermission(2)) {
        this.basicAlert("Access Denied. Please contact administrator!!!");
      } else {
        const {
          vehicleNum,
          driverName,
          SelectedChalanType,
          selectedSupplier,
        } = this.state;
        var invoiceList = this.state.invoiceList.map((prop, key) => {
          return {
            lineId: 0,
            itemCode: prop.itemId,
            itemDesc: prop.item,
            itemGroupId: prop.itemGroupId,
            rate: prop.rate,
            // qualityCode:prop.quality,
            dept: prop.deptId,
            //stock:prop.stockValue,
            quantity: prop.quantity,
            uom: prop.unit,
            poNo: resp.id,
          };
        });

        var data = {
          entryHeader: {
            challanNo: this.state.chalanNo,
            challanDate: this.dateChanged(this.state.chalanDate),
            vehicleNo: this.state.vehicleNum,
            driverName: this.state.driverName,
            suppCode: this.state.selectedSupplier,
            supplierName: this.state.supplierName,
            remarks: this.state.remarks,
            inDate: this.dateChanged(this.state.date1),
            inTime: this.timeChangedFormat(this.state.date1, this.state.time2),
            poNo: resp.id,
            createdBy: getCookie(cons.USER_NAME),
            companyId: this.props.state.companyInfo.id,
          },
          gateStoreLineItems: invoiceList,
          cipher: getCookie(cons.CIPHER),
        };
        if (
          SelectedChalanType !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== 0 &&
          selectedSupplier !== ""
        ) {
          if (this.state.invoiceList.length >= 1) {
            //  this.handleLoading();
            requestList(
              serverApi.ADD_STORE_GATE_ENTRY,
              data,
              this.handleAddStoreGate
            );
          } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      }
    }
  };

  handleAddItem() {
    // const {classes}=this.props;
    const {
      vehicleNum,
      driverName,
      SelectedChalanType,
      selectedSupplier,
      goodType,
    } = this.state;
    // console.log("unitConversionName "+ this.state.unitConvertion);
    //  var id="";
    console.log(this.state);
    if (
      SelectedChalanType !== 0 &&
      SelectedChalanType !== "" &&
      selectedSupplier !== 0 &&
      selectedSupplier !== "" &&
      goodType !== null
    ) {
      if (goodType === "SRN") {
        this.setState({ costAlert: true });
      } else
        this.setState({
          loading: true,
          alert: true,
        });
    } else {
      this.basicAlert("Please Enter All Mandatory Fields..!!");
    }
  }

  onClickAddItem() {
    var arr = this.state.invoiceList;
    var itemIndex = 0,
      hasItem = false,
      lineId = 0;

    if (this.state.updateItem) {
      itemIndex = this.state.id;
      lineId = this.state.lineId;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    arr.map((q) => {
      if (q.item === this.state.itemName && !this.state.updateItem)
        hasItem = true;
    });
    var addItemObj = {
      id: itemIndex,
      lineId: lineId,
      department: this.state.deptName,
      itemGroup: this.state.itemGrpName,
      item: this.state.itemName,
      quantity: this.state.quantity,
      unit: this.state.uom,
      stockValue: this.state.stock,
      rate: this.state.rate,
      deptId: this.state.selectedDepartment,
      itemGroupId: this.state.selectedItemGroup,
      itemId: this.state.selectedItem,
      itemGrp: this.state.selectedItemGroup + "" + this.state.selectedItem,
      poNo: this.state.poNo,
      poLineItemId: this.state.poLineItemId,
      poQuantity: this.state.poQuantity,
      isActive: 1,
      costDesc: this.state.costDesc,
      costFactor: this.state.costFactor,
      description: this.state.description,
      selectedBudgetHeadItem: this.state.selectedBudgetHeadItem + "",
      budgetHeadName: this.state.selectedbudgetName,
      poDetailsId: this.state.poDetailsId,
      poId: this.state.poId,
    };

    const {
      selectedBudgetHeadItem,
      quantity,
      poQuantity,
      updateView,
      SelectedChalanType,
      poNo,
      goodType,
      rate,
      uom,
    } = this.state;

    if (!hasItem) {
      if (goodType === "SRN") {
        if (quantity !== "" && rate !== "" && uom !== "") {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            loading: true,
            invoiceList: arr,
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      } else {
        if (selectedBudgetHeadItem !== "" && quantity !== "") {
          if (SelectedChalanType.value === 1 && poNo !== "" && poNo !== null) {
            if (parseFloat(quantity) > parseFloat(poQuantity)) {
              this.basicAlert(
                "Quantity Should Not Be Greater Than PO Quantity " +
                  poQuantity +
                  "..!!"
              );
            } else {
              if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
              } else arr.splice(arr.length, 0, addItemObj);

              this.setState({
                loading: true,
                invoiceList: arr,
                updateItem: false,
              });
              this.hideAlert();
            }
          } else {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);

            this.setState({
              loading: true,
              invoiceList: arr,
              updateItem: false,
            });
            this.hideAlert();
          }
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      }
    } else {
      this.basicAlert(
        "Item Already Saved For This item Group. Please Select Another One..!!"
      );
    }
  }

  //invoiceTableSize
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  //addItem
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  //onselect challan
  handleSelectedValues = (selectedValue) => {
    //const {classes}=this.props;

    // const {selectedJuteType}=this.state;
    if (selectedValue.value === 1)
      this.setState({
        loading: true,
        indentAlert: true,
      });

    this.setState({
      SelectedChalanType: selectedValue,
    });

    //console.log(`Option selected:`, defValue);
  };

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectedChalanType: "",
    });
  }

  afterSelectPO = () => {
    // var data = this.state.dataTable;
    var juteId = "";
    var data = {
      poIds: this.state.selectedPO,
    };
    console.log("jute  " + "  " + this.state.selectedPO);
    requestList(serverApi.GET_POS_BY_IDS, data, this.handlePOResp);
    // getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
  };

  handlePOResp = (resp) => {
    console.log("resp", resp);
    // var header = resp.poHeader;
    var list = resp;
    var indentlist = [];
    list.map((prop, key) => {
      if (prop.remainingQuantity > 0)
        indentlist.push({
          id: key,
          department: prop.departmentName,
          itemGroup: prop.itemGroup,
          item: prop.itemName,
          itemGrp: prop.itemGroupId + "" + prop.itemId,
          deptId: prop.departmentId,
          itemGroupId: prop.itemGroupId,
          itemId: prop.itemId,
          budgetHeadName: prop.budgetHeadName,
          budgetHeadId: prop.budgetHeadId + "",
          description: prop.description,
          poQuantity: prop.remainingQuantity,
          quantity: prop.remainingQuantity,
          unit: prop.unitId,
          rate: prop.rate,
          // stockValue: prop.stock,
          // deptId: prop.deptId,
          // itemGroupId: prop.itemGroupId,
          // itemId: prop.itemId,
          poId: prop.poId,
          poNo: prop.id,
          poDetailsId: prop.id,
          isActive: 1,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.basicItemAlert("Do You Like To Update This Item?");
                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      this.setState({
                        // alert:true,
                        id: o.id,
                        updateItemAlert: true,
                        updateItem: true,
                        deptName: o.department,
                        itemGrpName: o.itemGroup,
                        itemName: o.item,
                        selectedDepartment: o.deptId,
                        selectedItemGroup: o.itemGroupId,
                        selectedItem: o.itemId,
                        uom: o.unit,
                        stock: o.stockValue,
                        quantity: o.quantity,
                        poNo: o.poNo,
                        rate: o.rate,
                        poDetailsId: o.poDetailsId,
                        poDetailsIds: o.id,
                        poId: o.poId,
                        poQuantity: o.poQuantity,
                        loadItem: true,
                        loadItemGroup: true,
                        budgetHeadName: o.budgetHeadName,
                        budgetHeadId: o.budgetHeadId + "",
                      });
                      return true;
                    }
                  });
                }}
                color="primaryNoBackground"
                customClass="remove"
              >
                <Edit />
              </IconButton>
              {/* use this button to remove the data row */}
              <IconButton
                onClick={() => {
                  var data = this.state.invoiceList;
                  data.find((o, i) => {
                    if (o.id === key) {
                      this.setState({
                        // alert:true,
                        id: o.id,
                        deleteItem: true,
                      });

                      this.basicItemAlert("Do You Like To Delete This Item?");

                      // here you should add some custom code so you can delete the data
                      // from this component and from your server as well
                      // data.splice(i,1);
                      return true;
                    }
                  });
                  this.setState({ invoiceList: data });
                }}
                color="dangerNoBackground"
                customClass="remove"
              >
                <Close />
              </IconButton>
            </div>
          ),
        });
    });
    //   console.log("indentWeight "+header.supplierId);
    // if(header!== null){

    this.setState({
      loading: false,
      indentAlert: false,
      reloadSelect: true,
      //   selectedSupplier:header.supplierId,
      //  selectedPO:header.id,
      invoiceList: indentlist,
    });

    //  }
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      poStatus,
      inwardStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick(4);
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(inwardStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        18,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    var fileNameArr = fileName.split(".");
    for (var i = 0; i < fileNameArr.length; i++) {
      fileType = fileNameArr[i].toUpperCase();
    }
    fileName = fileName.split(".");

    // fileType = fileName[1].toUpperCase();
    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX" ||
        fileType === "CSV"
      ) {
        console.log("getting the fileType here", fileType);
        this.setState({
          file: file,
          fileName: file.name,
          // fileType: fileName[1],
          fileType: fileType,
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  getDataTable = () => {
    var data = [];
    const options = [
      { value: 3, label: "APPROVE", name: "APPROVE" },
      { value: 4, label: "REJECT", name: "REJECT" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var companyId = this.props.state.companyInfo.id;
    const { classes } = this.props;
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        prop.id = key;
        var status = "";
        if (prop.itemStatus == 3) status = options[0];
        if (prop.itemStatus == 4) status = options[1];
        prop.status = (
          <div className="actions-right">
            <Select
              isDisabled={
                this.state.inwardStatus == "3" || this.state.inwardStatus == "4"
              }
              defaultValue={status}
              name={"itemStatus"}
              // value={this.state.itemStatus}
              onChange={(defValue) => {
                var data = this.state.invoiceList;
                prop.itemStatus = defValue.value;
                data[key].itemStatus = defValue.value;
                if (defValue.value === 4) {
                  prop.rejectedQTY = data[key].quantity;
                  data[key].rejectedQTY = data[key].quantity;
                  data[key].approvedQTY = 0;
                }
                if (defValue.value === 3) {
                  prop.approvedQTY = data[key].quantity;

                  data[key].approvedQTY = data[key].quantity;
                  data[key].rejectedQTY =
                    parseFloat(data[key].quantity) -
                    parseFloat(data[key].approvedQTY);
                }
                this.setState({
                  itemStatus: defValue,
                  invoiceList: data,
                });

                //  console.log(`Option selected:`, defValue);
              }}
              options={options}
              className={classes.selectFormControl}
              theme={(theme) => ({
                ...theme,
                borderRadius: "4px",
                height: "20px",
                colors: {
                  ...theme.colors,
                  //  primary25: '#00ABDC',
                  primary: "#00ABDC",
                  //   primary50:"#00ABDC"
                },
              })}
              styles={{
                control: (base, state) => ({
                  ...base,
                  height: "30px",
                  marginBottom: "30px",
                }),
              }}
            />
          </div>
        );

        prop.approvedReason = (
          <div>
            <Select
              isDisabled={
                this.state.inwardStatus == "3" || this.state.inwardStatus == "4"
              }
              //isDisabled={this.state.updateView}
              defaultValue={{ label: prop.reason, value: prop.reason }}
              name={"reason"}
              // value={this.state.itemStatus}
              onChange={(defValue) => {
                var data = this.state.invoiceList;
                data[key].reason = defValue.value;
                prop.reason = defValue.value;
                this.setState({
                  reason: defValue.value,
                  invoiceList: data,
                });
              }}
              options={ReasonTypes}
              className={classes.selectFormControl}
              theme={(theme) => ({
                ...theme,
                borderRadius: "4px",
                height: "20px",
                colors: {
                  ...theme.colors,
                  //  primary25: '#00ABDC',
                  primary: "#00ABDC",
                  //   primary50:"#00ABDC"
                },
              })}
              styles={{
                control: (base, state) => ({
                  ...base,
                  height: "30px",
                  marginBottom: "30px",
                }),
              }}
            />
          </div>
        );
        if (prop.itemStatus == 4) {
          prop.approvedQuantity = 0;
        }
        if (prop.itemStatus == 3) {
          prop.approvedQuantity = (
            <div>
              <TextField
                defValue={prop.approvedQTY}
                disabled={this.state.inwardStatus == 3}
                id="approvedQTY"
                // className={classes.textField}
                //  value={prop.approvedQTY}
                iType={"number"}
                onChange={(event) => {
                  var data = this.state.invoiceList;
                  if (parseInt(event.target.value) > data[key].quantity) {
                    data[key].approvedQTY = prop.approvedQTY;

                    if (data[key].approvedQTY !== "")
                      data[key].rejectedQTY =
                        parseFloat(data[key].quantity) -
                        parseFloat(prop.approvedQTY);
                    else {
                      data[key].rejectedQTY = 0;
                      data[key].approvedQTY = 0;
                    }
                    prop.approvedQTY = data[key].quantity;
                    prop.rejectedQTY = data[key].rejectedQTY;
                    this.setState({
                      approvedQTY: data[key].quantity,
                      rejectedQTY: data[key].rejectedQTY,
                      invoiceList: data,
                    });

                    this.basicAlert(
                      "Approved Quantity Should Not Exceed Quantity..!!"
                    );
                  } else {
                    data[key].approvedQTY = event.target.value;
                    if (data[key].approvedQTY !== "")
                      data[key].rejectedQTY =
                        parseFloat(data[key].quantity) -
                        parseFloat(data[key].approvedQTY);
                    else {
                      data[key].rejectedQTY = 0;
                      data[key].approvedQTY = 0;
                    }
                    prop.approvedQTY = event.target.value;
                    this.setState({
                      approvedQTY: event.target.value,
                      invoiceList: data,
                    });
                  }

                  //  console.log(`Option selected:`, defValue);
                }}
                decimal={
                  companyId === "1" || companyId === "2" || companyId === "116"
                    ? 3
                    : 2
                }
                fullWidth={true}
                variant="outlined"
                autoFocus={true}
                InputProps={inputFocus}
              />
            </div>
          );
        }
        if (prop.isActive === 1) {
          var obj = {
            ...prop,

            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        this.basicItemAlert("Do You Like To Update This Item?");
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          //  alert:true,
                          id: key,
                          updateItemAlert: true,
                          lineId: o.lineId,
                          updateItem: true,
                          deptName: o.department,
                          itemGrpName: o.itemGroup,
                          itemName: o.item,
                          selectedDepartment: parseInt(o.deptId),
                          selectedItemGroup: o.itemGroupId,
                          selectedItem: o.itemId + "",
                          budgetHeadName: o.budgetHeadName,
                          budgetHeadId: o.budgetHeadId + "",
                          uom: o.unit,
                          quantity: o.quantity,
                          poId: o.poId,
                          poDetailsId: o.poDetailsId,
                          poNo: o.poNo,
                          rate: o.rate,
                          poQuantity: o.poQuantity,
                          poLineItemId: o.poLineItemId,
                          loadItem: true,
                          loadItemGroup: true,
                          description: o.description,
                          inwardId: o.inwardId,
                        });
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="remove"
                >
                  <Edit />
                </IconButton>{" "}
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        //if (this.state.approveButton) {

                        this.setState({
                          deleteItem: true,
                          id: key,
                        });

                        this.basicItemAlert("Do You Like To Delete This Item?");
                        //  data.splice(i,1);
                        // }
                        return true;
                      }
                    });
                    this.setState({ invoiceList: data });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };
          data.push(obj);
        }
      });
    return data;
  };

  handlePrintData = () => {
    console.log(
      "getting the inward id on click print",
      this.state.selectedSupplier
    );
    // fetch(
    //   serverApi.PRINT_INWARD
    // )
    fetch(
      serverApi.PRINT_INWARD +
        this.state.materialInsId +
        "/" +
        getCookie(cons.USERID) +
        "/" +
        4,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.state.inwardSequenceNo}_${this.state.selectedSupplier}.pdf`
        );
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    const { classes } = this.props;
    const { selectedPO } = this.state;
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    const inputFocus = {
      autoComplete: "off",
    };
    var options = [
      { value: 0, label: "Select..." },
      { value: 1, label: "With PO" },
      { value: 2, label: "Without PO" },
    ];
    var isoutButton = getConfigValue(configNames.GATE_ENTRY_OUT);
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    // var withoutpo = getConfigValue(configNames.GATE_ENTRY_WITHOUT_PO);
    // if (withoutpo === "2") {
    //     options = [
    //         { value: 0, label: 'Select...' },
    //         { value: 1, label: 'With PO' }
    //     ]
    // }

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert ? (
                    <Popup
                      onClickSave={() => this.afterSelectPO()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        WO List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <MultiSelect
                                        id={"selectedPO"}
                                        defValue={selectedPO}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          var dates = name;
                                          console.log("dates", dates);
                                          if (dates)
                                            dates = dates.map((prop) => {
                                              var val = prop.split("^");
                                              return val[1];
                                            });
                                          this.setState({ poDates: dates });
                                        }}
                                        stateKey="selectedPO"
                                        postData={{
                                          companyId: this.props.state
                                            .companyInfo.id,
                                          branchId: this.state.branchId,
                                          financialYear: getCookie(
                                            cons.ACADEMIC_YEAR
                                          ),
                                          recordType: "WO",
                                          supplierId: this.state
                                            .selectedSupplier,
                                        }}
                                        url={serverApi.GET_APPROVED_POS}
                                        value={selectedPO}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.costAlert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Cost Factor
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    id={"costFactor"}
                                    onSelect={this.onSelect}
                                    defValue={this.state.costFactor}
                                    getName={(name) => {
                                      this.setState({ costDesc: name });
                                    }}
                                    stateKey="costFactor"
                                    url={
                                      serverApi.SEARCH_COST_FACTOR +
                                      this.props.state.companyInfo.id +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={3}
                                    iType={"number"}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    UOM
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="uom"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.uom}
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Description
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="description"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    height={60}
                                    value={this.state.description}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Budget Head
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedBudgetHeadItem"}
                                        onSelect={this.onSelect}
                                        defValue={
                                          this.state.selectedBudgetHeadItem
                                        }
                                        getName={this.getBudgetName}
                                        stateKey="selectedBudgetHeadItem"
                                        staticData={this.state.budgetHeadList}
                                        isRefresh={this.state.loadDepartment1}
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadDepartment1: false,
                                          });
                                        }}
                                        value={
                                          this.state.selectedBudgetHeadItem
                                        }
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6}>
                                  <br />
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Description
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="description"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        value={this.state.description}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12} md={12}>
                              <br />
                              <GridContainer>
                                {/* <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item<span style={{
                                                                                        color: "red",
                                                                                        fontSize: "12px"
                                                                                    }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    selectDisabled={this.state.SelectedChalanType.value === 1}
                                                                                    defValue={this.state.selectedItem}
                                                                                    id={"selectedItem"}
                                                                                    onSelect={this.onSelect}
                                                                                    staticData={this.state.ItemOptions}
                                                                                    getName={this.getItemDetailsByName}
                                                                                    isRefresh={this.state.loadItem}
                                                                                    stateKey="selectedItem"
                                                                                    setIsRefresh={this.afterRefreshItem}
                                                                                    //   url={serverApi.GET_ITEMS_BY_GRPID+this.state.selectedItemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                                    value={this.state.selectedItem}
                                                                                />


                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid> */}

                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Quantity
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="quantity"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.quantity}
                                        fullWidth={true}
                                        variant="outlined"
                                        decimal={3}
                                        iType={"number"}
                                        InputProps={{
                                          autoComplete: "off",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <span
                                                style={{
                                                  color: "#00ABDC",
                                                  fontSize: "16px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {this.state.uom}
                                              </span>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        UOM
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="uom"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        value={this.state.uom}
                                        fullWidth={true}
                                        variant="outlined"
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Inward No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              //  disabled={true}
                              id="workReportNo"
                              className={classes.textField}
                              value={this.state.workReportNo}
                              onChange={this.onChangeValues}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={inputFocus}
                            />
                          </ItemGrid>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="chalanNo"
                          className={classes.textField}
                          value={this.state.chalanNo}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "chalanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "chalanDate",
                              value: this.state.chalanDate,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          // selectDisabled={true}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          url={
                            serverApi.SELECT_BRANCH +
                            this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {/* {labelType==="2"?"Phase":"Project"}<span style={{color:"red" ,fontSize:"12px"}}>*</span> */}
                          Project
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.projectId}
                          id={"projectId"}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          staticData={this.state.projectNameList}
                          getName={(name) => {
                            this.setState({ projectName: name });
                          }}
                          value={this.state.projectId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedSupplier"}
                            selectDisabled={
                              this.state.updateView &&
                              this.state.SelectedChalanType.value !== 2 &&
                              this.state.outDateTime === ""
                            }
                            defValue={this.state.selectedSupplier}
                            isReload={this.state.reloadSelect}
                            setIsReload={this.afterReloadSelect}
                            onSelect={this.onSelect}
                            getName={this.getSupplierName}
                            stateKey="selectedSupplier"
                            staticData={this.state.supplierList}
                            isRefresh={this.state.loadSupplier}
                            setIsRefresh={(val) => {
                              this.setState({ loadSupplier: false });
                            }}
                            // url={serverApi.SELECT_SUPPLIER_STORE + this.props.state.companyInfo.id}
                            value={this.state.selectedSupplier}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Type no
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          defValue={this.state.SelectedChalanType}
                          name={"SelectedChalanType"}
                          value={this.state.SelectedChalanType}
                          onChange={this.handleSelectedValues}
                          options={options}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                              marginBottom: "10px",
                            }),
                          }}
                        />
                        {/* <span>{this.state.selectedPO}</span> */}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Remarks
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="remarks"
                                                    // label="Multiline"
                                                    fullWidth
                                                    multiline={true}
                                                    rowsMax="3"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeValues}
                                                    className={classes.textField}
                                                    height={60}
                                                    // helperText="hello"
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}

                  {/* <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Goods Type<span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"goodType"}
                                                    selectDisabled={this.state.updateView || this.state.SelectedChalanType.value === 1}
                                                    defValue={this.state.goodType}
                                                    onSelect={this.onSelect}
                                                    stateKey="goodType"
                                                    staticData={GoodTypes}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {/* {console.log(this.state.invoiceList.length+" length")}*/}
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          //disableActions={this.state.inspectionChk === "Y"}
                          columns={[
                            {
                              Header: "Item Code",
                              accessor: "itemGrp",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Department",
                              accessor: "department",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Item Group",
                              accessor: "itemGroup",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Item",
                              accessor: "item",
                              minWidth: 200,
                              style: {
                                color: "#000",
                                textAlign: "left",
                                textOverflow: "unset",
                              },
                            },
                            {
                              Header: "Budget Head",
                              accessor: "budgetHeadName",
                              minWidth: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "PO No",
                              accessor: "poSequence",
                              minWidth: 80,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Description",
                              accessor: "description",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Quantity",
                              accessor: "quantity",
                              minWidth: 70,
                              style: { color: "#000", textAlign: "left" },
                              Cell: (row) => (
                                <div
                                  style={{
                                    border: "1px solid grey",
                                    borderRadius: "3px",
                                  }}
                                >
                                  <span
                                    title={
                                      row.original
                                        ? row.original.item
                                        : row.value
                                    }
                                  >
                                    {row.value}
                                  </span>
                                </div>
                              ),
                            },
                            {
                              Header: "Unit",
                              accessor: "unit",
                              width: 50,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Status",
                              accessor: "status",
                              minWidth: 120,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Approved Quantity",
                              accessor: "approvedQuantity",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Rejected Quantity",
                              accessor: "rejectedQTY",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "left" },
                            },
                            {
                              Header: "Reason ",
                              accessor: "approvedReason",
                              minWidth: 130,
                              style: { color: "#000", textAlign: "left" },
                            },
                          ]}
                        />
                      </ItemGrid>
                      {/* <ItemGrid xs={12}>
                                                {this.state.inspectionChk !== 'Y' && this.state.SelectedChalanType.value === 2 ?
                                                    <Button color={"info"} right={true} onClick={this.handleAddItem}>Add Item</Button> : null}
                                            </ItemGrid> */}
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView && this.state.status === 3 ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={6}>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: 400,
                              color: "#003F66",
                            }}
                          >
                            Approved By:
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#003F66",
                            }}
                          >
                            {" " + this.state.inspectionApprovedBy}
                            <br />
                          </span>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {/* {this.state.updateView &&
                  this.state.creditnoteValue !== null ? (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                          <InputLabel className={classes.label}>
                            Credit Note:
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                          {this.state.creditnoteValue}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null} */}

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} md={6}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={4}>
                            <InputLabel className={classes.label}>
                              Internal Note
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={8}>
                            <TextField
                              id="internalNote"
                              fullWidth
                              multiline
                              rowsMax="3"
                              height={60}
                              value={this.state.internalNote}
                              onChange={this.onChangeValues}
                              className={classes.textField}
                            />
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          <ItemGrid xs={4}>
                            <IconButton
                              right={true}
                              onClick={() => {
                                this.setState({
                                  deleteInvoice: true,
                                  invoiceId: r.fileUploadId,
                                  fileId: i,
                                });
                                this.InvoicebasicAlert(
                                  "Do You Like To Delete This File?"
                                );
                              }}
                              color="dangerNoBackground"
                            >
                              {this.state.status === 3 ||
                              this.state.status === 5 ? null : (
                                <Close />
                              )}
                            </IconButton>{" "}
                            {""}
                          </ItemGrid>
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={4}>
                      {this.state.status === 3 ||
                      this.state.status === 5 ? null : (
                        <Button color={"info"} onClick={this.handleFileClick}>
                          Upload File
                        </Button>
                      )}
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <GridContainer justify="center">
                            {this.state.approveButton === false ? (
                              this.state.status === 1 ? (
                                <ItemGrid>
                                  <Button
                                    color="primary"
                                    style={{ width: 80 }}
                                    onClick={() => {
                                      this.onApproveButtonClick("");
                                    }}
                                  >
                                    Update
                                  </Button>
                                </ItemGrid>
                              ) : null
                            ) : (
                              <>
                                <ItemGrid>
                                  <Button
                                    color="primary"
                                    style={{ width: 80 }}
                                    onClick={() => {
                                      this.onApproveButtonClick("");
                                    }}
                                  >
                                    Update
                                  </Button>
                                </ItemGrid>
                                <ItemGrid>
                                  <Button
                                    style={{ width: 80 }}
                                    color="success"
                                    onClick={() => {
                                      this.setState({ isApprove: true });
                                      this.InvoicebasicAlert(
                                        "Do You Like To Approve..!!"
                                      );
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </ItemGrid>
                              </>
                            )}
                            {/* {this.state.status === 17 ||
                            this.state.status == 1 ? (
                              this.state.approveButton === true ? (
                                <ItemGrid>
                                  <Button
                                    style={{ width: 80 }}
                                    color="success"
                                    onClick={() => {
                                      this.setState({ isApprove: true });
                                      this.InvoicebasicAlert(
                                        "Do You Like To Approve..!!"
                                      );
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </ItemGrid>
                              ) : null
                            ) : null} */}
                            <ItemGrid>
                              <Button
                                color="danger"
                                style={{ width: 80 }}
                                onClick={this.handleCreate}
                              >
                                Cancel
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <LogsComponents
                      id={this.state.entryId}
                      recType={"INWARD"}
                    />
                  ) : null}
                  {this.state.updateView ? (
                    // <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                    //   <SamplePDF
                    //     invoiceType={8}
                    //     printResp={this.state.printResp}
                    //   />
                    // </ItemGrid>
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <div className="exportPdf">
                        <IconButton
                          color="primary"
                          onClick={this.handlePrintData}
                        >
                          {<Print />}
                        </IconButton>
                      </div>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                // removed search field from list table
                <div className="materialInspectionTable">
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Po No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="poNum"
                                value={this.state.poNum}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Inward No.
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="inwardNum"
                                value={this.state.inwardNum}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  staticData={this.state.supplierList}
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Project" : "Branch"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                url={
                                  serverApi.SELECT_BRANCH +
                                  this.props.state.companyInfo.id
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                {labelType === "2" ? "Phase" : "Project"}
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.projectId}
                                id={"projectId"}
                                onSelect={this.onSelect}
                                stateKey="selectedProject"
                                staticData={this.state.projectNameList}
                                getName={(name) => {
                                  this.setState({ projectId: name });
                                }}
                                value={this.state.projectId}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        {/* <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon />
                                                    </IconButton>

                                                </ItemGrid> */}
                        <ItemGrid xs={12}>
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <PaginationTableComponent
                        // url={serverApi.GET_ALL_INWARDS}
                        // searchData={this.getData()}
                        staticData={this.state.dataTable}
                        // dataTableAll={this.state.dataTable}
                        showPagination={true}
                        handlePage={this.handlePage}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_gate_entry_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textField: {},

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(StoreMaterialInsc)
);
