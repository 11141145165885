import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {getDataFromUrl, requestList} from "../../../server/server";
import moment from "moment";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import SamplePDF from "../../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";


class JutePoChallanMr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            selectMukam: "",
            selectUnitConversion: "",
            VehicleType: "",
            mukamId: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "indentNo",
            approveStatus: false,

            indentStatus: "3",
            canChangeUnit: true,
            canChangeMukam: true,
            updateView: false,
            statusDesc: "",
            date1: moment().format('DD-MM-YYYY'),
            indentNo: "",
            year: moment().format('YYYY'),
            unitConvertion: "",
            unitConvertionId: "",
            unitConversionName: "",
            indentQuantity: "",
            indentQuantityTon: "",
            indentBales: "",
            createdBy: "",
            createdOn: "",
            selectedVehicleType: "",
            vehicleQuantiy: "",
            selectedMukam: "",
            alert: false,
            selectedJuteQuality: "",
            selectedQualityName: "",
            selectedTax: "",
            invoiceList: [],
            selectedJuteType: "",
            hideJuteType: false,
            url: "",
            selectedJuteName: "",
            selectedIndentQuantity: "",
            selectedDeptId: "",
            selectedGrpId: "",
            selectedUnit: "",
            stock: "",
            typeId:0

        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
    }

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + id + "/" + getCookie(cons.USERID) + "/getIndentDetails", this.handleViewClickResp)
    };
    handleViewClickResp = (resp) => {
        var header = resp.indentHeader;
        var list = resp.indentItems;
        var indentlist = list.map((prop, key) => {
            return (
                {
                    id: key,
                    juteType: parseInt(prop.itemId),
                    juteName: prop.itemIdDesc,
                    quality: prop.qualityCode,
                    qualityName: prop.qualityCodeDesc,
                    quantity: prop.indentQuantity,
                    unit: prop.unitId,
                    deptId: prop.deptId,
                    grpId: prop.itemGroupId,
                    stockValue: prop.stock,
                    indentstatus: prop.indentStatus,
                    indentItemId: prop.indentLineItemId,
                    quantityType: prop.noOfBales,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    var data = this.state.invoiceList;
                                    if (this.state.approveStatus)
                                        data.find((o, i) => {
                                            if (o.id === key) {
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                // console.log("no of bales",o.quantityType);
                                                this.setState({
                                                    id: o.id,
                                                    updateItemAlert: true,
                                                    updateItem: true,
                                                    indentItemId: o.indentItemId,
                                                    selectedJuteType: o.juteType,
                                                    selectedJuteName: o.juteName,
                                                    selectedJuteQuality: o.quality,
                                                    selectedQualityName: o.qualityName,
                                                    quantity: o.quantityType,
                                                    selectedUnit: o.unit,
                                                    selectedDeptId: o.deptId,
                                                    selectedGrpId: o.grpId,
                                                    stock: o.stockValue,
                                                    // alert:true,

                                                });
                                                return true;
                                            }
                                        });
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit/>
                            </IconButton>

                            {/* use this button to remove the data row */}
                            <IconButton
                                onClick={() => {

                                    var data = this.state.invoiceList;
                                    data.find((o, i) => {

                                        if (o.id === key) {
                                            if (this.state.approveStatus) {

                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                });

                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                //  data.splice(i,1);
                                            }
                                            return true;
                                        }
                                    });

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>{" "}
                        </div>

                    )
                }
            )
        });
        //console.log("indentWeight "+header.indentWeight);
        if (header !== null) {
            this.setState({
                updateView: true,
                isCreated: true,
                canChangeMukam: true,
                canChangeUnit: true,
                indentStatus: header.status,
                approveStatus: header.approveButton,
                indentNo: header.id,
                statusDesc: header.statusDesc,
                unitConvertion: this.getDefaultUnitConvertion(header.unitConversion),
                // unitConvertionId:"0",
                indentQuantity: header.indentWeight,
                //  sumOFQuantities:header.indentWeight,
                year: header.finnacialYear,
                createdBy: header.submitter,
                createdOn: header.createDateDesc,
                date1: header.indentDateDesc,
                selectedMukam: header.mukamNo,
                selectedVehicleType: header.vehicleTypeId,
                vehicleQuantity: header.vehicleQuantity,
                quantity: "",
                stock: "",
                selectedJuteQuality: "",
                selectedQualityName: "",
                invoiceList: indentlist,
            });

        }


    };
    getDefaultUnitConvertion = (name) => {
        var val = "", label = "", data = "";
        if (name !== null) {
            if (name === 'LOOSE') {
                val = 1;
                label = "LOOSE";
            } else {
                val = 2;
                label = 'BALE';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    }
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    getContent = () => {
        return(
            <div className="container1">
            <div>
                <div className="col-xs-12">
                    <div id="content1">
                        <h2 >Export from HTML</h2>
                        <p>This is a demo for exporting PDF from html using jsPDF. Click save <code>button</code> to
                            export this content.
                        </p>
                        <br/>

                        <table >

                            <tr>
                                <td>Serial Number</td>
                                <td>Name</td>
                                <td>Percentile</td>
                            </tr>

                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>James</td>
                                <td>8.9</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Harry</td>
                                <td>7.6</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Emma</td>
                                <td>7.0</td>
                            </tr>
                            </tbody>
                        </table>

                        <br/>


                    </div>


                </div>
            </div>
        </div>)
/*
        return (
            "<!DOCTYPE html>" +
            "<html lang=\"en\">" +
            "<head>" +
            "    <meta charset=\"UTF-8\">" +
            "    <title>ITIS - Offer Letter</title>" +
            "    <style rel=\"stylesheet\">" +
            "        body {" +
            "            font-family: \"Arial Hebrew\";" +
            "            font-size: 11px;" +
            "        }" +
            "        .padding {" +
            "            padding: 5px;" +
            "        }" +

            "        .right {" +
            "            text-align: right;" +
            "        }" +

            "        p {" +
            "            font-size: 11px;" +
            "            font-family: \"Arial Hebrew\";" +
            "            padding: 5px;" +
            "            text-align: justify;" +
            "        }" +
            "        table {" +
            "            font-size: 11px;" +
            "            font-family: \"Arial Hebrew\";" +
            "            padding: 5px;" +
            "            text-align: justify;" +
            "        }" +

            "        .leftpad {" +
            "            padding-left: 5px;" +
            "        }" +

            "        .center {" +
            "            text-align: center;" +
            "        }" +

            "        .btn {" +
            "            font-family: Arial;" +
            "            color: #ffffff;" +
            "            font-size: 16px;" +
            "            padding: 10px;" +
            "            background: #25346b;" +
            "            text-decoration: none;" +
            "        }" +

            "        .btn:hover {" +
            "            cursor: pointer;" +
            "        }" +
            "    </style>" +
            "</head>" +
            "<body>" +
            "<table width=\"600\" cellpadding=\"0\" cellspacing=\"0\" border=\"0\">" +
            "    <tbody>" +
            "    <tr>" +
            "        <td><img src=\"http://139.59.89.15:5000/media/file/1\"/></td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td class=\"padding right\"> Date: " + date + "</td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td class=\"padding\">" +
            "            Ref: " + refNo + "<br/>" +
            "            To: Mr. " + name + "<br/>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <p>" +
            "                On behalf of ITIS, We are pleased to offer you a position of NDT Technician. This offer is based on the" +
            "                following terms & conditions Subjecting to availability of Visa, of which you will be issued a two year" +
            "                contract." +
            "            </p>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td class=\"padding\">" +
            "            <table>" +
            "                <tr>" +
            "                    <td>Job Title</td>" +
            "                    <td>"+title+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Contract Status</td>" +
            "                    <td>"+conStatus+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Work Location</td>" +
            "                    <td>"+workLocation+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Contract Period</td>" +
            "                    <td>"+cPeriod+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Probation Period</td>" +
            "                    <td>"+pPeriod+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Working Hours</td>" +
            "                    <td>"+wHours+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Basic Salary</td>" +
            "                    <td>"+bSalary+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td>Food Allowance</td>" +
            "                    <td>"+fAllowance+"</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td colspan=\"2\"> In cases where food is provided to the candidate, the candidate is not entitled for" +
            "                        food" +
            "                        allowance during that period." +
            "                    </td>" +
            "                </tr>" +
            "            </table>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <p>" +
            "                <b>Note1:</b> During the probation period you will be paid a lump sum amount of SAR 1000/-. You will be" +
            "                paid a" +
            "                basic salary and allowance as mentioned above after 30 days of Aramco RT/UT shear wave practical and" +
            "                theory exam qualification. Further based on your efficiency and skills you will be considered for" +
            "                scheduling other Aramco Examinations. Successfully passing other Aramco examinations will be added to" +
            "                the increment as per Company’s norms." +
            "            </p>" +

            "            <p>" +
            "                <b>Note2:</b> No resignation is accepted or company will not give a final exit up to 3 years period from" +
            "                the" +
            "                date of latest successful completion of any company sponsored Qualification." +
            "            </p>" +
            "            <p>" +
            "                <b>Note3:</b> The candidate is required to drive the vehicle and execute the job during work and" +
            "                properly" +
            "                maintain the vehicle given to him and follow the instruction of the company vehicle coordinator." +
            "            </p>" +
            "            <p>" +
            "                <b>Note4:</b> The candidate is required to ensure proper housekeeping at his work place and living" +
            "                place." +
            "            </p>" +
            "            <p>" +
            "                <b>Note5:</b> Overtime hours are considered only above 48 hours per week. These 48 hours/week are" +
            "                approved by" +
            "                management based on the minimum production requirements as per the overtime policy and/or other company" +
            "                norms." +
            "            </p>" +
            "            <p>" +
            "                <b>Note6:</b> Candidate is required to work anywhere inside the kingdom and any client related/internal" +
            "                qualifications does not give the candidate right to ask about immediate salary revision. Revision of" +
            "                salary is solely at the discretion of the company management." +
            "            </p>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <table>" +
            "                <tr>" +
            "                    <td nowrap=\"nowrap\">Other Benefits</td>" +
            "                    <td class=\"leftpad\">As per the Labor Rules of Saudi Arabia.</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td nowrap=\"nowrap\">Terms & Conditions</td>" +
            "                    <td class=\"leftpad\">As per Company HR Manual and Saudi Labor Law</td>" +
            "                </tr>" +
            "                <tr>" +
            "                    <td valign=\"top\">Increment</td>" +
            "                    <td class=\"leftpad\">" +
            "                        Increment on the basic salary is provided based on the performance and qualifications" +
            "                        achieved during work tenure. Initial increment is provided after the initial contract period" +
            "                        from date of First Qualification and thereafter based on the company norms." +
            "                    </td>" +
            "                </tr>" +
            "            </table>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <p>" +
            "                The candidate is asked to bring all Original Certificates for verification and Original Four Wheel" +
            "                Driving License to apply for Saudi Driving License, and also 12 nos. passport size photograph with white" +
            "                background. In addition the candidate is required to attest his highest qualification at Saudi Embassy." +
            "            </p>" +
            "            <p>" +
            "                During your employment, you will report directly to NDT coordinator/Supervisor. You will be expected to" +
            "                carry out the duties assigned to you in a competent and efficient fashion." +
            "            </p>" +
            "            <p>" +
            "                In any unavoidable case and if the candidate tends to resign from his position with the company, the" +
            "                candidate is required to pay all expenses spent on the candidate including, training (in house &" +
            "                external) & visa related expenses etc. computed by company management. In any case company will not give" +
            "                final exit to the candidate." +
            "            </p>" +
            "            <p>" +
            "                During the initial two years and in case of any emergency, if the candidate tends to go for vacation," +
            "                then the candidate is required to provide two guarantees or security deposit decided by the management." +
            "            </p>" +
            "            <p>" +
            "                The company may terminate your employment if you could not pass Aramco RT/UT Practical or any of the" +
            "                scheduled qualification examinations and Theory examination without any end of service benefits." +
            "            </p>" +
            "            <p>" +
            "                All the above terms and conditions in the offer are valid upon the acceptance of the candidate by duly" +
            "                signing it and will be valid only after the candidate successfully complete the visa processes and reach" +
            "                the Kingdom of Saudi Arabia." +
            "            </p>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            Regards," +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <br/><br/>" +
            "            Jagdheesh<br/>" +
            "            General Manager.<br/>" +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td class=\"center\">" +
            "            <br/><br/>" +
            "            Please signify your acceptance of the terms and conditions of this Job offer by click below ACCEPT NOW" +
            "            button." +
            "        </td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td>" +
            "            <br/><br/>" +
            "            <a href='" + link + "'>" +
            "                <button class=\"btn\">APPROVE NOW</button>" +
            "            </a>" +
            "        </td>" +
            "    </tr>" +
            "    </tbody>" +
            "</table>" +
            "</body>" +
            "</html>"+
            <table id="demo" className="table table-bordered">
            <thead>
            <tr>
            <th>Serial Number</th>
        <th>Name</th>
        <th>Percentile</th>
    </tr>
    </thead>
        <tbody>
        <tr>
            <td>1</td>
            <td>James</td>
            <td>8.9</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Harry</td>
            <td>7.6</td>
        </tr>
        <tr>
            <td>3</td>
            <td>Emma</td>
            <td>7.0</td>
        </tr>
        </tbody>
        </table>

        );
*/

    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "indentType": "J",
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate
            , "mukam": this.state.mukamId,
            "status": this.state.status
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });

        this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date1;
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    }


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return Math.round(parseFloat((this.state.indentQuantity * 100) / 150));
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };

    getStock = () => {
        console.log("sttt", this.state.stock);
        return this.state.stock;
    };

    onChangeValues = (event) => {
        var value = event.target.value;
        if (event.target.id === "indentQuantity") {
            //   console.log("val "+this.state.indentQuantity);
            if (value > 0 && value <= 100) {
                this.setState({
                    selectedVehicleType: 3,
                    vehicleQuantity: 1,
                })

            } else if (value > 100 && value <= 120) {
                this.setState({
                    selectedVehicleType: 2,
                    vehicleQuantity: 1,
                })

            } else if (value > 120 && value <= 160) {
                this.setState({
                    selectedVehicleType: 1,
                    vehicleQuantity: 1,
                })

            } else if (value > 160 && value <= 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: 1,
                })

            } else if (value > 200) {
                this.setState({
                    selectedVehicleType: 4,
                    vehicleQuantity: Math.round(value / 200),
                })
            } else if (value === '') {
                this.setState({
                    selectedVehicleType: "",
                    vehicleQuantity: "",
                })
            }
        }


        if (event.target.id === "vehicleQuantity") {

            if (value !== null && value !== "") {
                if (this.state.selectedVehicleType !== "")
                    this.setState({
                        indentQuantity: parseInt(value) * parseInt(this.state.selectedIndentQuantity)
                    });
                else {
                    value = "";
                    this.basicAlert("Please Select Vehicle Type..!!")
                }
            }
        }
        if (event.target.id === "stock") {
            //   console.log("val "+this.state.indentQuantity);
            this.setState({
                stock: this.state.stock
            })
        }
        if (event.target.id === 'quantity' && this.state.updateItem) {

        }
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/

        if (stateKey === 'selectedJuteType') {
            this.setState({
                loadQuality: true
            });
        }
        if (stateKey === 'selectedMukam' && this.state.invoiceList.length > 0 && !this.state.canChangeMukam) {
            this.unitConvertionAlert("Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!")
        }
        else
            this.setState({
                [stateKey]: selectedValue
            });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            selectedGrpId: values[1],
            selectedUnit: values[2],
            selectedDeptId: values[3],
        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            selectedJuteType: "",
            selectedJuteName: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            quantity: "",
            stock: ""
        });
    }

    hideAlertMessage() {
        this.setState({
            canChangeMukam: true,
            canChangeUnit: true,
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            indentNo: "",
            unitConvertion: "",
            unitConvertionId: 0,
            indentQuantity: "",
            indentQuantityTon: "",
            indentBales: "",
            createdBy: "",
            createdOn: "",
            selectedVehicleType: "",
            vehicleQuantity: "",
            quantity: "",
            stock: "",
            selectedJuteQuality: "",
            selectedQualityName: "",
            selectedMukam: "",
            selectedTax: "",
            invoiceList: [],
            selectedJuteType: "",
            selectedJuteName: "",
            selectedIndentQuantity: "",
            sumOFQuantities: 0,
            selectedDeptId: "",
            selectedGrpId: "",
            selectedUnit: "",
            typeId:0
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }


    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{
                        display: "block",
                        marginTop: "-100px",
                        marginLeft: "-80px",
                        fontSize: "8px",
                        color: "black"
                    }}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onRejectButtonClick = () => {
        var invoiceList = this.state.invoiceList.map((prop, key) => {
            return ({
                id: prop.indentItemId,
                indentHeaderId: this.state.indentNo,
                itemId: prop.juteType,
                itemGroupId: prop.grpId,
                qualityCode: prop.quality,
                deptId: prop.deptId,
                stock: prop.stockValue,
                indentQuantity: prop.quantity,
                unitId: prop.unit,
                noOfBales: prop.quantityType
            })
        });

        var data = {
            "indentHeader": {
                "id": this.state.indentNo,
                "type": "J",
                "mukam": this.state.selectedMukam,
                "vehicleTypeId": this.state.selectedVehicleType,
                "vehicleQuantity": this.state.vehicleQuantiy,
                "submitter": getCookie(cons.USERID),
                "indentDate": this.dateChanged(),
                "finnacialYear": this.state.year,
                "unitConversion": this.state.unitConvertionName,
                "status": "4"

            },
            "indentItems": invoiceList
        };
        this.handleLoading();
        requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent)
    };


    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item
    onClickAddItem() {
        var arr = this.state.invoiceList;
        var sumOfQty = 0;
        var itemIndex = 0, quantity = 0, hasItem = false;
        if (this.state.updateItem) {
            itemIndex = this.state.id;
            arr[itemIndex].quantityType = this.state.quantity
        } else {
            itemIndex = this.state.invoiceList.length
        }

        //  console.log("arr[itemIndex].quantityType ",arr[itemIndex].quantityType);
        arr.map(q => {
            sumOfQty = sumOfQty + parseInt(q.quantityType);
            if (q.quality === this.state.selectedJuteQuality && !this.state.updateItem)
                hasItem = true;
        });
        if (!this.state.updateItem)
            sumOfQty = sumOfQty + parseInt(this.state.quantity);


        if (this.state.unitConvertion.value === 1) {
            quantity = (parseInt(this.state.indentQuantity) * this.state.quantity) / 100
        } else {
            quantity = Math.round(parseFloat((this.state.quantity * 150) / 100).toFixed(2));
        }

        console.log("selectedUnit " + this.state.selectedUnit);
        var addItemObj = {
            id: itemIndex,
            indentItemId: this.state.indentItemId,
            juteType: this.state.selectedJuteType,
            juteName: this.state.selectedJuteName,
            quality: this.state.selectedJuteQuality,
            qualityName: this.state.selectedQualityName,
            quantityType: this.state.quantity,
            quantity: quantity,
            unit: this.state.selectedUnit,
            deptId: this.state.selectedDeptId,
            grpId: this.state.selectedGrpId,
            stockValue: this.state.stock,
            actions: (
                <div className="actions-right">
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    this.basicItemAlert("Do You Like To Update This Item?");

                                    this.setState({
                                        id: o.id,
                                        indentItemId: o.indentItemId,
                                        updateItemAlert: true,
                                        updateItem: true,
                                        selectedJuteType: o.juteType,
                                        selectedJuteName: o.juteName,
                                        selectedJuteQuality: o.quality,
                                        selectedQualityName: o.qualityName,
                                        quantity: o.quantityType,
                                        selectedUnit: o.unit,
                                        selectedDeptId: o.deptId,
                                        selectedGrpId: o.grpId,
                                        stock: o.stockValue,
                                        // alert:true,

                                    });
                                    return true;
                                }
                            });
                        }}
                        color="primaryNoBackground"
                        customClass="remove">
                        <Edit/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            var data = this.state.invoiceList;
                            data.find((o, i) => {
                                if (o.id === addItemObj.id) {
                                    // here you should add some custom code so you can delete the data
                                    // from this component and from your server as well
                                    this.basicItemAlert("Do You Like To Delete This Item?");
                                    this.setState({
                                        deleteItem: true,
                                        id: o.id,
                                    });

                                    //  data.splice(i,1);
                                    return true;
                                }
                            });
                            this.setState({
                                invoiceList: data,
                                selectedJuteType: "",
                                selectedJuteName: "",
                                selectedJuteQuality: "",
                                selectedQualityName: "",
                                quantity: "",
                                selectedUnit: "",
                                selectedDeptId: "",
                                selectedGrpId: "",
                                stock: "",
                            });
                        }}
                        color="dangerNoBackground"
                        customClass="remove">
                        <Close/>
                    </IconButton>{" "}
                </div>

            )
        };

        if (!hasItem) {
            if (this.state.selectedJuteType !== "" && this.state.selectedJuteQuality !== "" && this.state.quantity !== "" && this.state.vehicleQuantity !== "") {

                if (this.getConvertedQty() !== "") {

                    if (sumOfQty <= parseInt(this.getConvertedQty())) {
                        if (this.state.updateItem) {
                            arr[itemIndex] = addItemObj;
                        } else
                            arr.splice(arr.length, 0, addItemObj);
                        console.log(arr.toString());

                        this.setState({
                            hideJuteType: false,
                            loading: true,
                            sumOFQuantities: sumOfQty,
                            canChangeMukam: false,
                            invoiceList: arr,
                        });
                        this.hideAlert();
                    } else {
                        this.basicAlert("Quantity Should Not Exceed Converted Quantity..!!");
                        // alert("Quantity should be less than or equal to Indent quantity")
                    }

                } else {
                    if (sumOfQty <= 100) {
                        if (this.state.updateItem) {
                            arr[itemIndex] = addItemObj;
                        } else
                            arr.splice(arr.length, 0, addItemObj);
                        //console.log(arr.toString());

                        this.setState({
                            loading: true,
                            invoiceList: arr,
                            sumOFQuantities: sumOfQty,
                        });
                        this.hideAlert();
                    } else {
                        this.basicAlert("Quantity % can not exceed 100..!!");
                    }
                }
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        } else {
            this.basicAlert("Item Already Saved For This Quality. Please Select Another One..!!");
        }
    }

    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };
    getQuantityType = () => {
        var val = this.state.unitConvertion.value;
        if (val === 2) {
            return "BALE"
        } else {
            return "%";
        }
    };
    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedQualityName: values[0],
            stock: values[1]
        });
        console.log("stock " + this.state.stock);

    };

    handleAddItem() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
        console.log("unitConversionName " + this.state.unitConvertion);
        //  var id="";
        console.log(this.state);
        if (this.state.selectedMukam !== '0' && this.state.selectedMukam !== "" && this.state.unitConvertion !== "" && this.state.selectedVehicleType !== "")
            this.setState({
                loading: true,
                alert: true
            });
        else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
    };

    getVehicleQlty = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedIndentQuantity: values[1],
            indentQuantity: values[1]
        });
        console.log("indentQuantity " + this.state.indentQuantity);

    };
    handleSelectedValues = (defValue) => {

            this.setState(
                {
                    unitConvertion: defValue,
                    typeId: defValue.value,
                    unitConvertionName: defValue.label,
                    canChangeUnit: false,
                    isRefresh: true,

                });
    }

    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
        const options = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'PO', name: 'PO'},
            {value: 2, label: 'Challan', name: 'Challan'},
            {value: 3, label: 'MR', name: 'MR'}
        ];
        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>

                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <RegularCard
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }

                                                                    inputProps={{
                                                                        id: "fromDate",
                                                                        value: this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height: "10px", inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id: "toDate",
                                                                        value: this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Type<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <Select

                                                                defValue={this.state.type}
                                                                name ={"type"}
                                                                value={this.state.type}
                                                                onChange={this.handleSelectedValues}
                                                                options={options}
                                                                className={classes.selectFormControl}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: '4px',
                                                                    // height:"20px",
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        //  primary25: '#00ABDC',
                                                                        primary: '#00ABDC',
                                                                        //   primary50:"#00ABDC"
                                                                    },
                                                                })}
                                                                styles={{
                                                                    control: (base, state) => ({
                                                                        ...base,
                                                                        height: '30px',
                                                                        'min-height': '30px',
                                                                        marginBottom:"10px"
                                                                    }),

                                                                }}
                                                            />



                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>





                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                {/*<ItemGrid xs={12}>*/}
                                                {/*<IconButton*/}
                                                {/*onClick={this.handleCreate}*/}
                                                {/*color="success" right={true}>*/}
                                                {/*<ExpandMoreIcon/>*/}
                                                {/*</IconButton>*/}

                                                {/*</ItemGrid>*/}
                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>

                                            {this.state.typeId===1?
                                                <ReactTable
                                                    //loading={this.state.isRefresh}
                                                    defaultPageSize={10}
                                                    data={[ {"poNo":"J_398","poDate":"25-01-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"3.0","poValue":"14153.0","status":"OPEN"},
                                                        {"poNo":"J_317","poDate":"25-01-2019","supplierName":"G.G &BROS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"0.0","poValue":"0.0","status":"OPEN"},
                                                        {"poNo":"J_318","poDate":"25-01-2019","supplierName":"G.G &BROS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"0.0","poValue":"0.0","status":"OPEN"},
                                                        {"poNo":"J_319","poDate":"31-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"10","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
                                                        {"poNo":"J_320","poDate":"31-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"10","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
                                                        {"poNo":"J_352","poDate":"04-02-2019","supplierName":"SANT KUMAR MOHATA","mukam":"NAZIRPUR","vehicleType":"MEDIUM","vehicleQty":"1","broker":"ASMAT ALI","juteUnit":"JUTE","weight":"1.0","poValue":"3000.0","status":"OPEN"},
                                                        {"poNo":"J_353","poDate":"04-02-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"ASMAT ALI","juteUnit":"JUTE","weight":"3.0","poValue":"7510.0","status":"OPEN"},
                                                        {"poNo":"J_362","poDate":"04-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"DILIP KUMAR GOLCHA","juteUnit":"JUTE","weight":"4.0","poValue":"12020.0","status":"OPEN"},
                                                        {"poNo":"J_369","poDate":"25-01-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"0.0","poValue":"14153.0","status":"OPEN"},
                                                        {"poNo":"J_498","poDate":"12-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"","juteUnit":"JUTE","weight":"36.0","poValue":"157600.0","status":"OPEN"},
                                                        {"poNo":"J_500","poDate":"12-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"","juteUnit":"JUTE","weight":"36.0","poValue":"157600.0","status":"OPEN"},
                                                        {"poNo":"J_680","poDate":"21-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"SMALL","vehicleQty":"1","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"0.0","poValue":"275000.0","status":"OPEN"},
                                                        {"poNo":"J_681","poDate":"21-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"NAZIRPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"0.0","poValue":"753760.0","status":"OPEN"},
                                                        {"poNo":"J_513","poDate":"13-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"Select....","juteUnit":"JUTE","weight":"36.0","poValue":"0.0","status":"OPEN"},
                                                        {"poNo":"J_532","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
                                                        {"poNo":"J_533","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
                                                        {"poNo":"J_535","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
                                                        {"poNo":"J_652","poDate":"19-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"1.0","poValue":"4750.0","status":"OPEN"},{"poNo":"J_423","poDate":"07-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"9.0","poValue":"22510.0","status":"OPEN"},

                                                    ]}
                                                    filterable={true}
                                                    columns={[{"filterable":"true","Header":"PO No","accessor":"poNo","minWidth":90,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"PO Date","accessor":"poDate","minWidth":90,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Supplier Name","accessor":"supplierName","minWidth":200,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Mukam","accessor":"mukam","minWidth":150,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Vh. Type","accessor":"vehicleType","minWidth":80,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Vh. Qty","accessor":"vehicleQty","minWidth":50,"style":{"textAlign":"center"},"sortable":"true"},{"filterable":"true","Header":"Broker","accessor":"broker","minWidth":150,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Type","accessor":"juteUnit","minWidth":80,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Weight","accessor":"weight","minWidth":80,"style":{"textAlign":"right"},"sortable":"true"},{"filterable":"true","Header":"PO Val","accessor":"poValue","minWidth":100,"style":{"textAlign":"right"},"sortable":"true"},{"filterable":"true","Header":"Status","accessor":"status","minWidth":100,"style":{"textAlign":"left"},"sortable":"true"}


                                                    ]}
                                                    // defaultPageSize={this.state.dataTable.defaultPageSize}
                                                    // showPaginationTop={this.state.dataTable.showPaginationTop}
                                                    //showPaginationBottom={this.state.dataTable.showPaginationBottom}
                                                    className="-striped -highlight"
                                                    style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}

                                                />
                                            :null}


                                            {this.state.typeId===2?
                                                <ReactTable
                                                    //loading={this.state.isRefresh}
                                                    defaultPageSize={10}
                                                    data={[ {"chalanDate":"05-02-2019","vehicleNo":"225","supplierName":"ANDULIA","gateEntryNum":1003,"driver":"","exitTime":""},{"chalanDate":"05-02-2019","vehicleNo":"225","supplierName":"ALMA EXPORTS PVT LTD","gateEntryNum":1004,"driver":"","exitTime":"05-02-2019 12:30:44"},{"chalanDate":"04-02-2019","vehicleNo":"1","supplierName":"ARARIA","gateEntryNum":750,"driver":"","exitTime":""},{"chalanDate":"04-02-2019","vehicleNo":"1022","supplierName":"ANDULIA","gateEntryNum":800,"driver":"nn","exitTime":""},{"chalanDate":"04-02-2019","vehicleNo":"120","supplierName":"ANDULIA","gateEntryNum":802,"driver":"nn","exitTime":""},{"chalanDate":"05-02-2019","vehicleNo":"12235","supplierName":"ANDULIA","gateEntryNum":1050,"driver":"ll","exitTime":""},

                                                    ]}
                                                    filterable={true}
                                                    columns={[{"filterable":"true","Header":"Entry No","accessor":"gateEntryNum","minWidth":100,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Entry Date","accessor":"chalanDate","minWidth":100,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Vehicle No","accessor":"vehicleNo","minWidth":100,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Supplier","accessor":"supplierName","minWidth":200,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Driver","accessor":"driver","minWidth":200,"style":{"textAlign":"left"},"sortable":"true"},{"filterable":"true","Header":"Exit Time","accessor":"exitTime","minWidth":200,"style":{"textAlign":"left"},"sortable":"true"}


                                                    ]}
                                                    // defaultPageSize={this.state.dataTable.defaultPageSize}
                                                    // showPaginationTop={this.state.dataTable.showPaginationTop}
                                                    //showPaginationBottom={this.state.dataTable.showPaginationBottom}
                                                    className="-striped -highlight"
                                                    style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}

                                                />
                                                :null}

                                            {this.state.typeId===3?
                                                <ReactTable
                                                    //loading={this.state.isRefresh}
                                                    defaultPageSize={10}
                                                    data={[ {"mrNo":"271","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"DRAFTED","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"272","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"DRAFTED","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"270","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"DRAFTED","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"276","mrDate":"07-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"DRAFTED","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"275","mrDate":"07-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"OPEN","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"278","mrDate":"07-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"OPEN","weight":"","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"280","mrDate":"07-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"OPEN","weight":" ","mukham":"ANDULIA","poNo":"J_394"},{"mrNo":"274","mrDate":"07-02-2019","supplierName":"ALMA EXPORTS PVT LTD","agentName":" ","vehicleNo":"12235","mrStatus":"OPEN","weight":" ","mukham":"ANDULIA","poNo":"J_394"}

                                                    ]}
                                                    filterable={true}
                                                    columns={[{"filterable":true,"Header":"MR No","accessor":"mrNo","minWidth":100,"style":{"textAlign":"center"},"sortable":true},{"filterable":true,"Header":"PO No.","accessor":"poNo","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"MR Date","accessor":"mrDate","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Supplier","accessor":"supplierName","minWidth":150,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Vh. No","accessor":"vehicleNo","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Mukham","accessor":"mukham","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Weight","accessor":"weight","minWidth":100,"style":{"textAlign":"right"},"sortable":true},{"filterable":true,"Header":"Status","accessor":"mrStatus","minWidth":100,"style":{"textAlign":"left"},"sortable":true}


                                                    ]}
                                                    // defaultPageSize={this.state.dataTable.defaultPageSize}
                                                    // showPaginationTop={this.state.dataTable.showPaginationTop}
                                                    //showPaginationBottom={this.state.dataTable.showPaginationBottom}
                                                    className="-striped -highlight"
                                                    style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}

                                                />
                                                :null}



                                            {/*
                                            <ExportToExcel posts={this.state.dataTable.data}
                                                           dataTable={this.state.dataTable}
                                                           fileName={"Jute indent"}
                                                           sheetName={"Order1"}/>

*/}
                                            {this.state.typeId===1?
                                                <SamplePDF printDocument={this.printDocument}/>:null
                                            }
                                            {this.state.typeId===2?
                                                <SamplePDF printDocument={this.challanprintDocument}/>:null
                                            }
                                            {this.state.typeId===3?
                                                <SamplePDF printDocument={this.mrprintDocument}/>:null
                                            }


                                            {/*<TableComponent*/}
                                                {/*url={serverApi.DATATABLE_SERVICE_URL + "/getAllIndent"}*/}
                                                {/*searchData={this.getData()}*/}
                                                {/*isRefresh={this.state.isRefresh}*/}
                                                {/*handleSearch={this.handleSeacrh.bind(this)}*/}
                                                {/*isLoading={this.state.isRefresh}*/}
                                                {/*onActionClick={this.handleViewClick}*/}
                                                {/*itemViewType={this.state.itemViewType}*/}
                                                {/*printDoc={this.printDocument}*/}
                                                {/*actionType={VIEW}/>*/}


                                        </ItemGrid>
                                        {/*<ItemGrid xs={12} sm={6}></ItemGrid>*/}
                                        {/*<ItemGrid xs={12} sm={4}><SamplePDF printDocument={this.printDocument}/></ItemGrid>*/}
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
     printDocument() {
        var pageNo=1;
         var modules=[
             {"poNo":"J_398","poDate":"25-01-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"3.0","poValue":"14153.0","status":"OPEN"},
             {"poNo":"J_317","poDate":"25-01-2019","supplierName":"G.G &BROS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"0.0","poValue":"0.0","status":"OPEN"},
             {"poNo":"J_318","poDate":"25-01-2019","supplierName":"G.G &BROS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"0.0","poValue":"0.0","status":"OPEN"},
             {"poNo":"J_319","poDate":"31-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"10","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
             {"poNo":"J_320","poDate":"31-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"10","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
             {"poNo":"J_352","poDate":"04-02-2019","supplierName":"SANT KUMAR MOHATA","mukam":"NAZIRPUR","vehicleType":"MEDIUM","vehicleQty":"1","broker":"ASMAT ALI","juteUnit":"JUTE","weight":"1.0","poValue":"3000.0","status":"OPEN"},
             {"poNo":"J_353","poDate":"04-02-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"ASMAT ALI","juteUnit":"JUTE","weight":"3.0","poValue":"7510.0","status":"OPEN"},
             {"poNo":"J_362","poDate":"04-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"DILIP KUMAR GOLCHA","juteUnit":"JUTE","weight":"4.0","poValue":"12020.0","status":"OPEN"},
             {"poNo":"J_369","poDate":"25-01-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"0.0","poValue":"14153.0","status":"OPEN"},
             {"poNo":"J_498","poDate":"12-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"","juteUnit":"JUTE","weight":"36.0","poValue":"157600.0","status":"OPEN"},
             {"poNo":"J_500","poDate":"12-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"","juteUnit":"JUTE","weight":"36.0","poValue":"157600.0","status":"OPEN"},
             {"poNo":"J_680","poDate":"21-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"SMALL","vehicleQty":"1","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"0.0","poValue":"275000.0","status":"OPEN"},
             {"poNo":"J_681","poDate":"21-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"NAZIRPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"0.0","poValue":"753760.0","status":"OPEN"},
             {"poNo":"J_513","poDate":"13-02-2019","supplierName":"BANSILAL NATHMAL","mukam":"DHULIYAN","vehicleType":"SMALL","vehicleQty":"1","broker":"Select....","juteUnit":"JUTE","weight":"36.0","poValue":"0.0","status":"OPEN"},
             {"poNo":"J_532","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
             {"poNo":"J_533","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
             {"poNo":"J_535","poDate":"25-01-2019","supplierName":"HARNATA RAI BANARASILAL & SONS","mukam":"MADHURAPUR","vehicleType":"BIG","vehicleQty":"1","broker":"SHYAM MUNDRA","juteUnit":"JUTE","weight":"100.0","poValue":"1000.0","status":"OPEN"},
             {"poNo":"J_652","poDate":"19-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"SS RUNGTA","juteUnit":"JUTE","weight":"1.0","poValue":"4750.0","status":"OPEN"},{"poNo":"J_423","poDate":"07-02-2019","supplierName":"S.S RUNGATA & CO.","mukam":"ISLAMPUR","vehicleType":"BIG","vehicleQty":"2","broker":"AJAY SHARMA","juteUnit":"JUTE","weight":"9.0","poValue":"22510.0","status":"OPEN"},
         ]


       var config={printHeaders:true,autoSize:true,margins:{left:0,top:0,bottom:0,width:0},fontSize:10};
        const doc = new Jspdf('l', 'pt', 'a4');
         doc.setFontSize(14);
        doc.text(300, 40, 'NELLIMARLA JUTE MILLS CO LTD');
         doc.setFontSize(10);
         var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

         doc.text(730, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
       // doc.setFont("courier");
       // doc.setFontType("normal");
         doc.setFontSize(10);
         // doc.table(50, 100,modules,modulesHeader,config);
        doc.text(350, 60, 'Report No.:[JUTE/03]');
         doc.text(300, 80, 'Jute Purchase Order Report Dated : 26/05/2018  ' );
         doc.text(700, 80, 'Quantity in : Quintal');
         doc.setFontSize(9);
         doc.setFontType("bold");
         doc.cellInitialize();
         doc.cell(30, 100,30,30, 'PO No','','C');
         doc.cell(60, 100,50,30, 'PO Date','','C');
         doc.cell(110, 100,150,30, 'Supplier Name','','center');
         doc.cell(141, 100,100,30, 'Mukam','','center');
         doc.cell(172, 100,50,30, 'Vh. Type','','center');
         doc.cell(203, 100,50,30, 'Vh. Qty','','center');
         doc.cell(203, 100,100,30, 'Broker','','center');
         doc.cell(203, 100,50,30, 'Type','','center');
         doc.cell(203, 100,50,30, 'Weight','','center');
         doc.cell(203, 100,50,30, 'PO Val','','center');
         doc.cell(203, 100,50,30, 'Status','','center');


          var cellWidths=['',30, 50, 150,100 ,50,50, 100,50 ,50,50,50];
          var cellTextaligns=['','left', 'left', 'left', 'left','left' ,'left','left','left','right', 'right','left'];
          doc.setFontType("normal");
          doc.setFontSize(7);
          {
             var k=105;
             var i=0;
               modules.map((prop,key)=>{
                   k=k+25;
                   i=i+1;
                   doc.cellInitialize();
                   if(i===17){
                       k=130;
                       pageNo=pageNo+1;
                      var today =moment().format('DD/MM/YYYY H:mm:ss');
                       var name=getCookie(cons.USERID);
                       var newdat = " Printed on: "+ today;
                       var printBy = " Printed By: "+ name;
                      doc.text(30,570,newdat);
                       doc.text(740,570,printBy);
                       doc.addPage('a4','l');
                       doc.setFontSize(14);
                       doc.text(300, 40, 'NELLIMARLA JUTE MILLS CO LTD');
                       doc.setFontSize(10);
                       var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

                       doc.text(730, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
                       // doc.setFont("courier");
                       // doc.setFontType("normal");
                       doc.setFontSize(10);
                       // doc.table(50, 100,modules,modulesHeader,config);
                       doc.text(350, 60, 'Report No.:[JUTE/03]');
                       doc.text(300, 80, 'Jute Purchase Order Report Dated : 26/05/2018  ' );
                       doc.text(700, 80, 'Quantity in : Quintal');
                       doc.setFontSize(9);
                       doc.setFontType("bold");
                       doc.cellInitialize();
                       doc.cell(30, 100,30,30, 'PO No','','C');
                       doc.cell(60, 100,50,30, 'PO Date','','C');
                       doc.cell(110, 100,150,30, 'Supplier Name','','center');
                       doc.cell(141, 100,100,30, 'Mukam','','center');
                       doc.cell(172, 100,50,30, 'Vh. Type','','center');
                       doc.cell(203, 100,50,30, 'Vh. Qty','','center');
                       doc.cell(203, 100,100,30, 'Broker','','center');
                       doc.cell(203, 100,50,30, 'Type','','center');
                       doc.cell(203, 100,50,30, 'Weight','','center');
                       doc.cell(203, 100,50,30, 'PO Val','','center');
                       doc.cell(203, 100,50,30, 'Status','','center');
                      doc.setFontType("normal");
                      doc.setFontSize(7);
                  }
                  var a=0;
                  return(
                      Object.keys(prop).map((key, index) => {
                          a=a+1;
                          var myItem = prop[key]+' ';
                          return (
                              doc.cell(30,k,cellWidths[a],25,myItem,2,cellTextaligns[a])
                          )
                      })

                  )

              });

          }
         var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"123.76 ","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

         doc.cellInitialize();
         doc.setFontType("bold");

         //Opening




         doc.text(30, k+80, 'Dept. Incharge');
         doc.text(700, k+80, 'Authorised Signature');
         doc.setFontType("normal");
         doc.setFontSize(7);
         var today =moment().format('DD/MM/YYYY H:mm:ss');
         var name=getCookie(cons.USERID);
         var newdat = " Printed on: "+ today;
         var printBy = " Printed By: "+ name;
         doc.text(30,k+105,newdat);
         doc.text(740,k+105,printBy);

         doc.save('JutePO.pdf');


     }


     //challan pdf
    challanprintDocument() {
        var pageNo=1;
        var modules=[
            {"gateEntryNum":"1003","chalanDate":"05-02-2019","vehicleNo":"225","supplierName":"ANDULIA","driver":" ","exitTime":" "},
            {"gateEntryNum":"1004","chalanDate":"05-02-2019","vehicleNo":"225","supplierName":"ALMA EXPORTS PVT LTD","driver":" ","exitTime":"05-02-2019 12:30:44"},
            {"gateEntryNum":"750","chalanDate":"04-02-2019","vehicleNo":"1","supplierName":"ARARIA","driver":" ","exitTime":" "},
            {"gateEntryNum":"800","chalanDate":"04-02-2019","vehicleNo":"1022","supplierName":"ANDULIA","driver":"nn","exitTime":" "},
            {"gateEntryNum":"802","chalanDate":"04-02-2019","vehicleNo":"120","supplierName":"ANDULIA","driver":"nn","exitTime":" "},
            {"gateEntryNum":"1050","chalanDate":"05-02-2019","vehicleNo":"12235","supplierName":"ANDULIA","driver":"ll","exitTime":" "},
        ];


     //   var config={printHeaders:true,autoSize:true,margins:{left:0,top:0,bottom:0,width:0},fontSize:10};
        const doc = new Jspdf('p', 'pt', 'a4');
        doc.setFontSize(14);
        doc.text(170, 40, 'NELLIMARLA JUTE MILLS CO LTD');
        doc.setFontSize(10);
        var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

        doc.text(500, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
        // doc.setFont("courier");
        // doc.setFontType("normal");
        doc.setFontSize(10);
        // doc.table(50, 100,modules,modulesHeader,config);
        doc.text(220, 60, 'Report No.:[JUTE/03]');
        doc.text(170, 80, 'Jute Challan Order Report Dated : 26/05/2018  ' );
        doc.text(450, 80, 'Quantity in : Quintal');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.cellInitialize();
        doc.cell(30, 100,50,30, 'Entry No','','C');
        doc.cell(60, 100,50,30, 'Entry Date','','C');
        doc.cell(110, 100,70,30, 'Vehicle No','','center');
        doc.cell(141, 100,150,30, 'Supplier','','center');
        doc.cell(172, 100,50,30, 'Driver','','center');
        doc.cell(203, 100,100,30, 'Ext Time','','center');



        var cellWidths=['',50, 50, 70,150 ,50,100];
        var cellTextaligns=['','left', 'left', 'left', 'left','left' ,'left'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k=105;
            var i=0;
            modules.map((prop,key)=>{
                k=k+25;
                i=i+1;
                doc.cellInitialize();
                if(i===17){
                    k=130;
                    pageNo=pageNo+1;
                    var today =moment().format('DD/MM/YYYY H:mm:ss');
                    var name=getCookie(cons.USERID);
                    var newdat = " Printed on: "+ today;
                    var printBy = " Printed By: "+ name;
                    doc.text(30,570,newdat);
                    doc.text(500,570,printBy);
                    doc.addPage('a4','l');
                    doc.setFontSize(14);
                    doc.text(300, 40, 'NELLIMARLA JUTE MILLS CO LTD');
                    doc.setFontSize(10);
                    var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

                    doc.text(730, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
                    // doc.setFont("courier");
                    // doc.setFontType("normal");
                    doc.setFontSize(10);
                    // doc.table(50, 100,modules,modulesHeader,config);
                    doc.text(350, 60, 'Report No.:[JUTE/03]');
                    doc.text(300, 80, 'Jute Challan  Report Dated : 26/05/2018  ' );
                    doc.text(700, 80, 'Quantity in : Quintal');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.cellInitialize();
                    doc.cell(30, 100,50,30, 'Entry No','','C');
                    doc.cell(60, 100,50,30, 'Entry Date','','C');
                    doc.cell(110, 100,70,30, 'Vehicle No','','center');
                    doc.cell(141, 100,150,30, 'Supplier','','center');
                    doc.cell(172, 100,50,30, 'Driver','','center');
                    doc.cell(203, 100,100,30, 'Ext Time','','center');
                    doc.setFontType("normal");
                    doc.setFontSize(7);
                }
                var a=0;
                return(
                    Object.keys(prop).map((key, index) => {
                        a=a+1;
                        var myItem = prop[key];
                        return (
                            doc.cell(30,k,cellWidths[a],25,myItem,2,cellTextaligns[a])
                        )
                    })

                )

            });

        }
        var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"123.76 ","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

        doc.cellInitialize();
        doc.setFontType("bold");

        //Opening




        doc.text(30, k+80, 'Dept. Incharge');
        doc.text(400, k+80, 'Authorised Signature');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today =moment().format('DD/MM/YYYY H:mm:ss');
        var name=getCookie(cons.USERID);
        var newdat = " Printed on: "+ today;
        var printBy = " Printed By: "+ name;
        doc.text(30,k+125,newdat);
        doc.text(500,k+125,printBy);

        doc.save('JuteChallan.pdf');


    }
 //mr pdf
    mrprintDocument() {
        var pageNo=1;
        var modules=[{"mrNo":"271","poNo":"J_394","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","vehicleNo":"12235","mukham":"ANDULIA","weight":" ","mrStatus":"DRAFTED"},
            {"mrNo":"271","poNo":"J_394","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","vehicleNo":"12235","mukham":"ANDULIA","weight":" ","mrStatus":"DRAFTED"},
            {"mrNo":"271","poNo":"J_394","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","vehicleNo":"12235","mukham":"ANDULIA","weight":" ","mrStatus":"DRAFTED"},
            {"mrNo":"271","poNo":"J_394","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","vehicleNo":"12235","mukham":"ANDULIA","weight":" ","mrStatus":"DRAFTED"},{"mrNo":"271","poNo":"J_394","mrDate":"06-02-2019","supplierName":"ALMA EXPORTS PVT LTD","vehicleNo":"12235","mukham":"ANDULIA","weight":" ","mrStatus":"DRAFTED"}];


        //   var config={printHeaders:true,autoSize:true,margins:{left:0,top:0,bottom:0,width:0},fontSize:10};
        const doc = new Jspdf('p', 'pt', 'a4');
        doc.setFontSize(14);
        doc.text(170, 40, 'NELLIMARLA JUTE MILLS CO LTD');
        doc.setFontSize(10);
        var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

        doc.text(500, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
        // doc.setFont("courier");
        // doc.setFontType("normal");
        doc.setFontSize(10);
        // doc.table(50, 100,modules,modulesHeader,config);
        doc.text(220, 60, 'Report No.:[JUTE/03]');
        doc.text(170, 80, 'Jute MR  Report Dated : 26/05/2018  ' );
        doc.text(450, 80, 'Quantity in : Quintal');
        doc.setFontSize(9);
        doc.setFontType("bold");
        doc.cellInitialize();
        doc.cell(30, 100,50,30, 'MR No','','C');
        doc.cell(60, 100,50,30, 'PO NO','','C');
        doc.cell(110, 100,50,30, 'MR Date','','center');
        doc.cell(141, 100,130,30, 'Supplier','','center');
        doc.cell(172, 100,50,30, 'Vh. No','','center');
        doc.cell(203, 100,70,30, 'Mukam','','center');
        doc.cell(203, 100,70,30, 'Weight','','center');
        doc.cell(203, 100,70,30, 'Status','','center');



        var cellWidths=['',50, 50, 50,130 ,50,70,70,70];
        var cellTextaligns=['','left', 'left', 'left', 'left','left' ,'left'];
        doc.setFontType("normal");
        doc.setFontSize(7);
        {
            var k=105;
            var i=0;
            modules.map((prop,key)=>{
                k=k+25;
                i=i+1;
                doc.cellInitialize();
                if(i===17){
                    k=130;
                    pageNo=pageNo+1;
                    var today =moment().format('DD/MM/YYYY H:mm:ss');
                    var name=getCookie(cons.USERID);
                    var newdat = " Printed on: "+ today;
                    var printBy = " Printed By: "+ name;
                    doc.text(30,570,newdat);
                    doc.text(500,570,printBy);
                    doc.addPage('a4','l');
                    doc.setFontSize(14);
                    doc.text(300, 40, 'NELLIMARLA JUTE MILLS CO LTD');
                    doc.setFontSize(10);
                    var TotalPgaeNo=Math.ceil(parseFloat(modules.length)/17);

                    doc.text(730, 40, "Page "+pageNo+" of "+TotalPgaeNo+"");
                    // doc.setFont("courier");
                    // doc.setFontType("normal");
                    doc.setFontSize(10);
                    // doc.table(50, 100,modules,modulesHeader,config);
                    doc.text(350, 60, 'Report No.:[JUTE/03]');
                    doc.text(300, 80, 'Jute MR  Report Dated : 26/05/2018  ' );
                    doc.text(700, 80, 'Quantity in : Quintal');
                    doc.setFontSize(9);
                    doc.setFontType("bold");
                    doc.cellInitialize();
                    doc.cell(30, 100,50,30, 'MR No','','C');
                    doc.cell(60, 100,50,30, 'PO NO','','C');
                    doc.cell(110, 100,50,30, 'MR Date','','center');
                    doc.cell(141, 100,130,30, 'Supplier','','center');
                    doc.cell(172, 100,50,30, 'Vh. No','','center');
                    doc.cell(203, 100,70,30, 'Mukam','','center');
                    doc.cell(203, 100,70,30, 'Weight','','center');
                    doc.cell(203, 100,70,30, 'Status','','center');
                    doc.setFontType("normal");
                    doc.setFontSize(7);
                }
                var a=0;
                return(
                    Object.keys(prop).map((key, index) => {
                        a=a+1;
                        var myItem = prop[key];
                        return (
                            doc.cell(30,k,cellWidths[a],25,myItem,2,cellTextaligns[a])
                        )
                    })

                )

            });

        }
        var grandTotals={ "opBs":" ", "opHbs":" ","opDrm":" ","opWght":"123.76 ","recBs":" ","recHbs":" ","recDrm":" ","recWght":" ","issBs":"11 ","issHbs":" ","issDrm":" ","issWght":"14.58","delBs":" ","delHbs":" ","delDrm":" ","delWght":" ","clsoeBs":"1251","clsoeHbs":" ","clsoeDrm":" ","clsoeWght":"1670.86"};

        doc.cellInitialize();
        doc.setFontType("bold");

        //Opening




        doc.text(30, k+80, 'Dept. Incharge');
        doc.text(400, k+80, 'Authorised Signature');
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today =moment().format('DD/MM/YYYY H:mm:ss');
        var name=getCookie(cons.USERID);
        var newdat = " Printed on: "+ today;
        var printBy = " Printed By: "+ name;
        doc.text(30,k+125,newdat);
        doc.text(500,k+125,printBy);
        // var string = doc.output('datauristring');
        // var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
        // var x = window.open();
        // x.document.open();
        // x.document.write(iframe);
        // x.document.close();
       // window.navigator.msSaveOrOpenBlob(doc.output("blob"), "Name.pdf");
        //doc.output('save', 'filename.pdf');
       // doc.output('dataurlnewwindow');
        doc.setProperties({
            title: "JuteMR.pdf"
        });
        window.onload = () => {
            let blob = new Blob(["file"], {
                type: "text/plain"
            });
          //  let url = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = doc.getBlob();
            a.download = "file.txt";
            document.body.appendChild(a);
           // console.log(url);
            a.click();
        };
        console.log(doc.get)
      //  doc.save('JuteMR.pdf', {returnPromise:false});
        window.open(doc.output('bloburl'), '_blank');
        //doc.save('JuteMR.pdf');


    }






}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
export default withStyles(extendedFormsStyle)(JutePoChallanMr);
