import React from "react";
import Datetime from "react-datetime";
import defaultImage from "assets/img/default-avatar.png";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList, uploadFormdataWithImage} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import  {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import { connect } from 'react-redux';


class WorkerMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "ebId",
            deptId:0,
            catId:0,
            occId:0,
            category:"",
            ebNo:"",
            empCode:"",
            dateOfJoin:"",
            nominee:"",
            relation:"",
            workerName:"",
            dapartment:"",
            occupation:"",
            address:"",
            dateOfBirth:"",
            gender:"",
            maritalStatus:"",
            religion:"",
            dateOfPf:"",
            offDay:"",
            contractHead:"",
            workingRelay:"",
            active:"",
            rateTime1:"",
            rateTime2:"",
            rateTime3:"",
            travelAllowance:"",
            c2d:"",
            incTime:"",
            incPiece:"",
            iftu:"",
            hra:"",
            houseNo:"",
            lic:"",
            am1:"",
            am2:"",
            am3:"",
            landRent:"",
            cad:"",
            mastCode:"",
            emastCode:"",
            retiredDate:"",
            prtdNo:"",
            prtdCode:"",
            prtdPf:"",
            lastWorkedMonth:"",
            notWorked:"",
            workStatus:"",
            landNo:"",
            pbr:"",
            notWorkStatus:"",
            ntwDate:"",
            gprtd:"",
            reason:"",
            bankName:"",
            accountNo:"",
            branch:"",
            ifscCode:"",
            branchCode:"",
            unded:"",
            rates:"",
            rate2:"",
            esiMemEnabled:false,
            esiMem:"",
            pfMemEnabled:false,
            pfMem:"",
            imagePreviewUrl:defaultImage,
            imageFile:null,imageType:"",phtoUrl:null



        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }

    handleViewClick = (id) => {

        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {

            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_ID+id+"/userId/"+getCookie(cons.USERID),this.handleViewClickResp)
            // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + id + "/" + getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER), this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            })
        }else {
            this.showMessage(resp.message,resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp.data;

        if(resp.status){
            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    empId:resp.id,
                    category:header.cataId,
                    ebNo:header.ebNo,
                    empCode:header.empCode!==null?header.empCode:"",
                    dateOfJoin:header.dateOfJoining!==null?header.dateOfJoining:"",
                    nominee:header.nomine!==null?header.nomine:"",
                    relation:header.relation!==null?"":"",
                    workerName:header.workerName!==null?header.workerName:"",
                    fatherHusbandName:header.fatherOrHusbandName!==null?header.fatherOrHusbandName:"",
                    dapartment:header.deptId?header.deptId:"",
                    occupation:header.occuId?header.occuId:"",
                    address:header.empAddr!==null?header.empAddr:"", dateOfBirth:header.dateOfBirth!==null?header.dateOfBirth:"", gender:header.sex!==null?header.sex:"", maritalStatus:header.martialStatus!=null?parseInt(header.martialStatus):"",
                    religion:header.religion!==null?header.religion:"", dateOfPf:header.dateOfPfJoining!==null?header.dateOfPfJoining:"", offDay:header.offDay!==null?header.offDay:"", contractHead:header.contractHead!==null?parseInt(header.contractHead):"", active:header.active!==null?header.active:"", rateTime1:header.rateTime1!==null?header.rateTime1:"", rateTime2:header.rateTime2!==null?header.rateTime2:"",
                    rateTime3:header.rateTime3!==null?header.rateTime3:"", travelAllowance:header.travelAllowance!==null?header.travelAllowance:"", c2d:header.c2d!==null?header.c2d:"", incTime:header.incTimeRate!==null?header.incTimeRate:"", incPiece:header.incPieceRate!==null?header.incPieceRate:"", iftu:header.iftu!==null?header.iftu:"", hra:header.hra!==null?header.hra:"",
                    houseNo:header.houseno!==null?header.houseno:"", lic:header.lic!==null?header.lic:"", am1:header.am1!==null?header.am1:"", am2:header.am2!==null?header.am2:"", am3:header.am3!==null?header.am3:"", landRent:header.landrent!==null?header.landrent:"", cad:header.cad!==null?header.cad:"", mastCode:header.mastcode!==null?header.mastcode:"", emastCode:header.emastcode!==null?header.emastcode:"",
                    retiredDate:header.retiredDate!==null?header.retiredDate:"", prtdNo:header.prtdTktno!==null?header.prtdTktno:"", prtdCode:header.prtdEcode!==null?header.prtdEcode:"",
                    prtdPf:header.prtdpfNo!==null?header.prtdpfNo:"", lastWorkedMonth:header.lastWorkedMonth!==null?header.lastWorkedMonth:"", notWorked:header.notWorked!==null?header.notWorked:"", workStatus:header.workStatus!==null?header.workStatus:"", landNo:header.landno!==null?header.landno:"", pbr:header.pbr!==null?header.pbr:"", notWorkStatus:header.notWorkStatus!==null?header.notWorkStatus:"",
                    ntwDate:header.ntwDate!==null?header.ntwDate:"", gprtd:header.gprtd!==null?header.gprtd:"", reason:header.reason!==null?header.reason:"", bankName:header.bankName!==null?header.bankName:"",
                    accountNo:header.bankAcNo!==null?header.bankAcNo:"", branch:header.branch!==null?header.branch:"", ifscCode:header.ifscCode!==null?header.ifscCode:"", branchCode:header.branchCode!==null?header.branchCode:"", unded:header.unded!==null?header.unded:"", rates:header.rates!==null?header.rates:"", rate2:header.rate2!==null?header.rate2:"", esiMemEnabled:header.esiMember==='Y'?true:false,
                    esiMem:header.esiMember, pfMemEnabled:header.pfMember==='Y'?true:false,pfNo:header.pfNo!==null?header.pfNo:"",esiNo:header.esiNo!==null?header.esiNo:"",
                    createdBy: header.createdBy,
                    imageFile:header.photoFile,
                    imageType:header.photo,
                    phtoUrl:header.photoUrl,
                    imagePreviewUrl:serverConfig.SERVER_URL+header.photoUrl,
                    createdOn: header.updatedOnDesc,
                });
            }

        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    componentDidMount(){
        var data =  {
            "companyId": this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER)
        };
        requestList(serverApi.AGENT_COMPANY_LIST,data,this.handleAgentslist)

    }
    handleAgentslist=(resp)=>{
        console.log(resp)
        this.setState({
            AgentList:resp
        })

    }


    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data = {
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER),
            "cataId":this.state.catId,
            "deptId":this.state.deptId,
            "occupationId":this.state.occId
        };
        return data;
    };



    onChangeValues = (event) => {
        var value = event.target.value;

        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {

        this.setState({
            [stateKey]: selectedValue
        });
    };


    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            deptCode:"",
            deptName:"",
            createdBy:"",
            createdOn: ""
        });
    }

    hideAlertMessage() {
        this.setState({
            empId:"",
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            ebNo:"",
            empCode:"",
            dateOfJoin:"",
            nominee:"",
            relation:"",
            workerName:"",
            dapartment:"",
            occupation:"",
            address:"",
            dateOfBirth:"",
            gender:"",
            maritalStatus:"",
            religion:"",
            dateOfPf:"",
            offDay:"",
            contractHead:"",
            workingRelay:"",
            active:"",
            rateTime1:"",
            rateTime2:"",
            rateTime3:"",
            travelAllowance:"",
            c2d:"",
            incTime:"",
            incPiece:"",
            iftu:"",
            hra:"",
            houseNo:"",
            lic:"",
            am1:"",
            am2:"",
            am3:"",
            landRent:"",
            cad:"",
            mastCode:"",
            emastCode:"",
            retiredDate:"",
            prtdNo:"",
            prtdCode:"",
            prtdPf:"",
            lastWorkedMonth:"",
            notWorked:"",
            workStatus:"",
            landNo:"",
            pbr:"",
            notWorkStatus:"",
            ntwDate:"",
            gprtd:"",
            reason:"",
            bankName:"",
            accountNo:"",
            branch:"",
            ifscCode:"",
            branchCode:"",
            unded:"",
            rates:"",
            rate2:"",
            esiMemEnabled:false,
            esiMem:"",
            pfMemEnabled:false,
            pfMem:"",
            imagePreviewUrl:defaultImage,
            imageFile:null,
            imageType:"",
            createdBy:"",
            createdOn: "",
            phtoUrl:null

        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id+"/"+getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {category, ebNo, empCode, dateOfJoin, nominee, relation, workerName,fatherHusbandName, dapartment, occupation, address, dateOfBirth, gender, maritalStatus,
                religion, dateOfPf, offDay, contractHead, workingRelay, active, rateTime1, rateTime2, rateTime3, travelAllowance, c2d, incTime, incPiece, iftu, hra,
                houseNo, lic, am1, am2, am3, landRent, cad, mastCode, emastCode, retiredDate, prtdNo, prtdCode,
                prtdPf, lastWorkedMonth, notWorked, workStatus, landNo, pbr, notWorkStatus, ntwDate, gprtd, reason, bankName,
                accountNo, branch, ifscCode, branchCode, unded, rates, rate2, esiMemEnabled,esiMem, pfMemEnabled,pfMem,pfNo,esiNo,empId,imageFile,imageType,phtoUrl}=this.state;

            const formData = new FormData();
            formData.append( "ebId", empId);
            formData.append("ebNo", ebNo);
            formData.append( "cataId", category);
            formData.append("wrkName",workerName);
            formData.append("userId", getCookie(cons.USERID));
            formData.append("companyId", this.props.state.companyInfo.id);
            formData.append("deptId",dapartment);
            formData.append("occuId",occupation);
            formData.append("fatherHusbandName", fatherHusbandName);

            if(esiMemEnabled)
                formData.append("esiNo", esiNo);
            if(pfMemEnabled)
                formData.append("pfNo", pfNo);
            formData.append("dateOfBirth", dateOfBirth);
            formData.append("dateOfJoining", dateOfJoin);
            formData.append("dateOfPfJoining", dateOfPf);
            formData.append("dateOfInactive", "");
            formData.append("sex", gender);
            formData.append("employeeCode", empCode);
            formData.append("nomine", nominee);
            formData.append("relation", relation);
            formData.append("address", address);
            formData.append("martialStatus", maritalStatus);
            formData.append("religion", religion);
            formData.append("offDay", offDay);
            formData.append("contractHead", contractHead);
            //  formData.append("workingRelay", workingRelay);
            formData.append("rateTime1", rateTime1);
            formData.append("rateTime2", rateTime2);
            formData.append("rateTime3", rateTime3);
           formData.append("travelAllowance", travelAllowance);
            formData.append("c2d", c2d);
            formData.append("incTimeRate", incTime);
            formData.append("incPieceRate", incPiece);
            formData.append("iftu", iftu);
            formData.append("hra", hra);
            formData.append("houseno", houseNo);
            formData.append("lic", lic);
            formData.append("am1", am1);
            formData.append("am2", am2);
            formData.append("am3", am3);
            formData.append("landrent", landRent);
            formData.append("cad", cad);
            formData.append("mastcode", mastCode);
            formData.append("emastcode", emastCode);
            formData.append("retiredDate", retiredDate);
            formData.append("prtdTktno", prtdNo);
            formData.append("prtdpfNo", prtdPf);
            formData.append("prtdEcode", prtdCode);
            formData.append("lastWorkedMonth", lastWorkedMonth);
            formData.append("notWorked", notWorked);
            formData.append("workStatus", workStatus);
            formData.append("startMonth", null);
            formData.append("retiredYear", retiredDate);
            formData.append("landno", landNo);
            formData.append("pbr", pbr);
            formData.append("notWorkStatus", notWorkStatus);
            formData.append("ntwDate", ntwDate);
            formData.append("gprtd", gprtd);
            formData.append("reason", reason);
            formData.append("bankName", bankName);
            formData.append("bankAcNo", accountNo);
            formData.append("branch", branch);
            formData.append("ifscCode", ifscCode);
            formData.append("branchCode", branchCode);
            formData.append("unded", unded);
            formData.append("rates", rates);
            formData.append("rate2", rate2);
            formData.append("active", active);
         /*   formData.append("esiMember", esiMemEnabled?"Y":"N");
            formData.append("pfMember", pfMemEnabled?"Y":"N");*/
            if(imageFile!==null){
                formData.append("photo", "jpg");
                formData.append("photoFile", imageFile);
            }else if(phtoUrl!==null){
                formData.append("phtoUrl",phtoUrl)
                formData.append("photo", "jpg");

            }
            // formData.append("faceImageType", "null");
            formData.append("createdBy", getCookie(cons.USER_NAME));

            console.log("satate",this.state);
          //  console.log("values",ebNo+" 1- "+workerName+" 2- "+gender+" 3- "+fatherHusbandName+" 4- "+religion+" "+" 5");


            // formData.append("faceImageType", "null");
            formData.append("createdBy", getCookie(cons.USER_NAME));
            console.log(this.state);
            console.log("values",ebNo+" 1- "+workerName+" 2- "+gender+" 3- "+fatherHusbandName+" 4- "+religion+" "+" 5"+" "+empId);

            if (empId!==''&&ebNo!== ''&&workerName!==''&&gender!==''&&gender!==0&&fatherHusbandName!==''&&religion!==''&&category!=='') {
                this.handleLoading();
                uploadFormdataWithImage(serverApi.WORKER_MASTER_UPDATE,formData, this.handleAddIndent);
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };

    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {category, ebNo, empCode, dateOfJoin, nominee, relation, workerName,fatherHusbandName, dapartment, occupation, address, dateOfBirth, gender, maritalStatus,
                religion, dateOfPf, offDay, contractHead, workingRelay, active, rateTime1, rateTime2, rateTime3, travelAllowance, c2d, incTime, incPiece, iftu, hra,
                houseNo, lic, am1, am2, am3, landRent, cad, mastCode, emastCode, retiredDate, prtdNo, prtdCode,
                prtdPf, lastWorkedMonth, notWorked, workStatus, landNo, pbr, notWorkStatus, ntwDate, gprtd, reason, bankName,
                accountNo, branch, ifscCode, branchCode, unded, rates, rate2, esiMemEnabled,esiMem, pfMemEnabled,imageType,pfMem,pfNo,esiNo,imageFile}=this.state;
            const formData = new FormData();
            formData.append("ebNo", ebNo);
            formData.append( "cataId", category);
            formData.append("wrkName",workerName);
            formData.append("userId", getCookie(cons.USERID));
            formData.append("companyId", this.props.state.companyInfo.id);

            formData.append("deptId",dapartment);
            formData.append("occuId",occupation);
            formData.append("fatherHusbandName", fatherHusbandName);
            formData.append("photo", imageType);
            if(esiMemEnabled)
            formData.append("esiNo", esiNo);
            if(pfMemEnabled)
            formData.append("pfNo", pfNo);
            formData.append("dateOfBirth", dateOfBirth);
            formData.append("dateOfJoining", dateOfJoin);
            formData.append("dateOfPfJoining", dateOfPf);
            formData.append("dateOfInactive", "");
            formData.append("sex", gender);
            formData.append("employeeCode", empCode);
            formData.append("nomine", nominee);
            formData.append("relation", relation);
            formData.append("address", address);
            formData.append("martialStatus", maritalStatus);
            formData.append("religion", religion);
            formData.append("offDay", offDay);
            formData.append("contractHead", contractHead);
          //  formData.append("workingRelay", workingRelay);
            formData.append("rateTime1", rateTime1);
            formData.append("rateTime2", rateTime2);
            formData.append("rateTime3", rateTime3);
            formData.append("travelAllowance", travelAllowance);
            formData.append("c2d", c2d);
            formData.append("incTimeRate", incTime);
            formData.append("incPieceRate", incPiece);
            formData.append("iftu", iftu);
            formData.append("hra", hra);
            formData.append("houseno", houseNo);
            formData.append("lic", lic);
            formData.append("am1", am1);
            formData.append("am2", am2);
            formData.append("am3", am3);
            formData.append("landrent", landRent);
            formData.append("cad", cad);
            formData.append("mastcode", mastCode);
            formData.append("emastcode", emastCode);
            formData.append("retiredDate", retiredDate);
            formData.append("prtdTktno", prtdNo);
            formData.append("prtdpfNo", prtdPf);
            formData.append("prtdEcode", prtdCode);
            formData.append("lastWorkedMonth", lastWorkedMonth);
            formData.append("notWorked", notWorked);
            formData.append("workStatus", workStatus);
            formData.append("startMonth", "");
            formData.append("retiredYear", retiredDate);
            formData.append("landno", landNo);
            formData.append("pbr", pbr);
            formData.append("notWorkStatus", notWorkStatus);
            formData.append("ntwDate", ntwDate);
            formData.append("gprtd", gprtd);
            formData.append("reason", reason);
            formData.append("bankName", bankName);
            formData.append("bankAcNo", accountNo);
            formData.append("branch", branch);
            formData.append("ifscCode", ifscCode);
            formData.append("branchCode", branchCode);
            formData.append("unded", unded);
            formData.append("rates", rates);
            formData.append("rate2", rate2);
            formData.append("esiMember", esiMemEnabled?"Y":"N");
            formData.append("pfMember", pfMemEnabled?"Y":"N");
            if(imageFile!==null)
            formData.append("photoFile", imageFile);
           // formData.append("faceImageType", "null");
            formData.append("createdBy", getCookie(cons.USER_NAME));

            console.log(this.state);
            console.log("values",ebNo+" 1- "+workerName+" 2- "+gender+" 3- "+fatherHusbandName+" 4- "+religion+" "+" 5");

            if (ebNo!== ''&&workerName!==''&&gender!==''&&gender!==0&&fatherHusbandName!==''&&religion!==''&&category!=='')
            {
                this.handleLoading();
                uploadFormdataWithImage(serverApi.WORKER_MASTER_ADD,formData, this.handleAddIndent);
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id]: ''
            });

    }
    handleChange = name => event => {
        this.setState({[name]: event.target.checked});
    };
    changeProfilePic=(file,image)=>{
        this.setState({
            imageFile:file,
            imagePreviewUrl:image,
            imageType:file.type
        })

    };
    handleSeacrh = () => {
        this.setState({
            isRefresh: !this.state.isRefresh,
        });
    };

    render() {
        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        };
        const genderOptions=[{label:"Male",value:"M"},{label:"Female",value:"F"}];
        const maritalOptions=[{label:"Married",value:1},{label:"UnMarried",value:2}];
        const offDayOptions=[{label:"Sunday",value:"Sunday"},{label:"Monday",value:"Monday"},{label:"Tuesday",value:"Tuesday"},{label:"Wednesday",value:"Wednesday"}
        ,{label:"Tursday",value:"Tursday"},{label:"Friday",value:"Friday"},{label:"Saturday",value:"Saturday"}];
        const ActiveOptions=[{label:"Y",value:"Y"},{label:"N",value:"N"}];

        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {

                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    <ItemGrid xs={9}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Category<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <SelectboxTemp
                                                            // selectDisabled={true}
                                                            id={"category"}
                                                            onSelect={this.onSelect}
                                                            defValue={this.state.category}
                                                            // getName={this.getDeptName}
                                                            stateKey="category"
                                                            url={serverApi.SELECT_WORKER_CATEGORY + this.props.state.companyInfo.id}
                                                            value={this.state.category}
                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            EB No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="ebNo"
                                                            value={this.state.ebNo}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}

                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>

                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Employee Code
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="empCode"
                                                            value={this.state.empCode}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}

                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Employee Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="workerName"
                                                            value={this.state.workerName}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Father/Husband's Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="fatherHusbandName"
                                                            value={this.state.fatherHusbandName}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>


                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Nominee
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="nominee"
                                                            value={this.state.nominee}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}

                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Relation
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <TextField
                                                            id="relation"
                                                            value={this.state.relation}
                                                            className={classes.textField}
                                                            onChange={this.onChangeValues}
                                                            fullWidth={true}
                                                            InputProps={inputFocus}

                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            Date Of Join
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "dateOfJoin")
                                                                }}
                                                                height={"15px"}
                                                                formControlProps={
                                                                    {height: "15px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "dateOfJoin",
                                                                    value: this.state.dateOfJoin,
                                                                    autoComplete: 'off'
                                                                }}

                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={3} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={1}/>
                                            <ItemGrid xs={12} sm={10}>
                                                <ImageUpload btnTitle={"Upload"} imagePreviewUrl={this.state.imagePreviewUrl} changeProfilePic={this.changeProfilePic}/>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}/>





                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Department<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"dapartment"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.dapartment}
                                                    getName={this.getDeptName}
                                                    stateKey="dapartment"
                                                    url={serverApi.SEARCH_DEPATMENTS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                    value={this.state.dapartment}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Occupation<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.occupation}
                                                  //  getName={this.getDeptName}
                                                    stateKey="occupation"
                                                    url={serverApi.SEARCH_OCCUPATION + this.props.state.companyInfo.id}
                                                    value={this.state.occupation}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="address"
                                                    value={this.state.address}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                    height={60}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date Of Birth
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "dateOfBirth")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "dateOfJoin",
                                                            value: this.state.dateOfBirth,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Gender<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"gender"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.gender}
                                                    // getName={this.getDeptName}
                                                    stateKey="gender"
                                                    staticData={genderOptions}
                                                    value={this.state.gender}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Marital Status
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp

                                                    // selectDisabled={true}
                                                    id={"maritalStatus"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.maritalStatus}
                                                    // getName={this.getDeptName}
                                                    stateKey="maritalStatus"
                                                    staticData={maritalOptions}
                                                    value={this.state.maritalStatus}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Religion<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="religion"
                                                    value={this.state.religion}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PF NO
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="pfNo"
                                                    value={this.state.pfNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    ESI NO
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="esiNo"
                                                    value={this.state.esiNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}


                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date Of PF Joining
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "dateOfPf")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "dateOfPf",
                                                            value: this.state.dateOfPf,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Off Day<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"offDay"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.offDay}
                                                    // getName={this.getDeptName}
                                                    stateKey="offDay"
                                                    staticData={offDayOptions}
                                                    value={this.state.offDay}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Contract Head<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"contractHead"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.contractHead}
                                                    // getName={this.getDeptName}
                                                    stateKey="contractHead"
                                                    staticData={this.state.AgentList}
                                                    value={this.state.contractHead}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
{/*
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Working Relay<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id1={"workingRelay"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.workingRelay}
                                                    // getName={this.getDeptName}
                                                    stateKey="workingRelay"
                                                    staticData={this.state.AgentList}
                                                    value={this.state.workingRelay}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Active
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    // selectDisabled={true}
                                                    id={"active"}
                                                    onSelect={this.onSelect}
                                                    defValue={this.state.active}
                                                    // getName={this.getDeptName}
                                                    stateKey="active"
                                                    staticData={ActiveOptions}
                                                    value={this.state.active}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rate Time1
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rateTime1"
                                                    value={this.state.rateTime1}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rate Time2
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rateTime2"
                                                    value={this.state.rateTime2}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rate Time3
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rateTime3"
                                                    value={this.state.rateTime3}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Travel Allowance
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="travelAllowance"
                                                    value={this.state.travelAllowance}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    C2D<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="c2d"
                                                    value={this.state.c2d}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   INC Time
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="incTime"
                                                    value={this.state.incTime}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    INC Piece
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="incPiece"
                                                    value={this.state.incPiece}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    IFTU
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="iftu"
                                                    value={this.state.iftu}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    HRA
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="hra"
                                                    value={this.state.hra}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    House No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="houseNo"
                                                    value={this.state.houseNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    LIC
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="lic"
                                                    value={this.state.lic}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    AM1
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="am1"
                                                    value={this.state.am1}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    AM2
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="am2"
                                                    value={this.state.am2}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    AM3
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="am3"
                                                    value={this.state.am3}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Land Rent
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="landRent"
                                                    value={this.state.landRent}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    CAD
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="cad"
                                                    value={this.state.cad}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    MAST Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="mastCode"
                                                    value={this.state.mastCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    EMAST Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="emastCode"
                                                    value={this.state.emastCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date Of Retirement
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "retiredDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "retiredDate",
                                                            value: this.state.retiredDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PRTD Tkt No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="prtdNo"
                                                    value={this.state.prtdNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PRTD Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="prtdCode"
                                                    value={this.state.prtdCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PRTD PF No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="prtdPf"
                                                    value={this.state.prtdPf}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Last Worked Month
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="lastWorkedMonth"
                                                    value={this.state.lastWorkedMonth}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Not Worked
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="notWorked"
                                                    value={this.state.notWorked}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Work Status
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="workStatus"
                                                    value={this.state.workStatus}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Land No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="landNo"
                                                    value={this.state.landNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    PBR
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="pbr"
                                                    value={this.state.pbr}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Not Work Status
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="notWorkStatus"
                                                    value={this.state.notWorkStatus}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    NTW Date
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "ntwDate")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "ntwDate",
                                                            value: this.state.ntwDate,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    GPRTD
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="gprtd"
                                                    value={this.state.gprtd}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Reason
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="reason"
                                                    value={this.state.reason}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Bank Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="bankName"
                                                    value={this.state.bankName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Account No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="accountNo"
                                                    value={this.state.accountNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Branch
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="branch"
                                                    value={this.state.branch}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    IFSC Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="ifscCode"
                                                    value={this.state.ifscCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Branch Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="branchCode"
                                                    value={this.state.branchCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Unded
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="unded"
                                                    value={this.state.unded}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rates
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rates"
                                                    value={this.state.rates}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Rate2
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="rate2"
                                                    value={this.state.rate2}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={8} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={3}>
                                                <InputLabel className={classes.label}>
                                                    ESI Mem
                                                </InputLabel>

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            value={this.state.esiMemEnabled}
                                                            checked={this.state.esiMemenabled}
                                                            onChange={
                                                                this.handleChange("esiMemEnabled")
                                                            }
                                                            // onClick={() => this.handleToggle(prop)}
                                                            checkedIcon={
                                                                <Check className={classes.checkedIcon} />
                                                            }
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked
                                                            }}
                                                            style={{marginLeft:10}}

                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.menulabel
                                                    }}
                                                />


                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={9} style={{marginTop: "10px"}}>
                                                <TextField
                                                    disabled={!this.state.esiMemEnabled}
                                                    id="esiNo"
                                                    value={this.state.esiNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={8} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={3}>
                                                <InputLabel className={classes.label}>
                                                    PF Mem
                                                </InputLabel>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            value={this.state.pfMemEnabled}
                                                            checked={this.state.pfMemenabled}
                                                            onChange={
                                                                this.handleChange("pfMemEnabled")
                                                            }
                                                            // onClick={() => this.handleToggle(prop)}
                                                            checkedIcon={
                                                                <Check className={classes.checkedIcon} />
                                                            }
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked
                                                            }}
                                                            style={{marginLeft:10}}

                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.menulabel
                                                    }}
                                                />

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={9} style={{marginTop: "10px"}}>
                                                <TextField
                                                    disabled={!this.state.pfMemEnabled}
                                                    id="pfNo"
                                                    value={this.state.pfNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            {!this.state.updateView ?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>:
                                                <Button color="primary"
                                                        onClick={this.onUpdateButtonClick}>Update</Button>

                                            }
                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Department
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                onSelect={this.onSelect}
                                                                defValue={this.state.deptId}
                                                                getName={this.getDeptName}
                                                                stateKey="deptId"
                                                                url={serverApi.SEARCH_DEPATMENTS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                                                value={this.state.deptId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Category
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                onSelect={this.onSelect}
                                                                defValue={this.state.catId}
                                                                getName={this.getDeptName}
                                                                stateKey="catId"
                                                                url={serverApi.SEARCH_CATEGORIES + this.props.state.companyInfo.id}
                                                                value={this.state.catId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Occupation
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                onSelect={this.onSelect}
                                                                defValue={this.state.occId}
                                                                getName={this.getDeptName}
                                                                stateKey="occId"
                                                                url={serverApi.SEARCH_OCCUPATION + this.props.state.companyInfo.id}
                                                                value={this.state.occId}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>



                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
                                                <ItemGrid xs={12}>
                                                    <br/>

                                                    <Button color="primary" right={true}
                                                            onClick={this.handleSeacrh}>Search</Button>
                                                    <br/>
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.WORKER_MASTER_TABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                actionType={VIEW}
                                                // showDelete={true}
                                                //  onDeleteClick={this.handleDeleteClick}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(WorkerMaster)
);
