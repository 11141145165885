import React from "react";
import moment from "moment";
import Datetime from "react-datetime";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import NavPills from "../../components/NavPills/NavPills";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import {VIEW} from "../../ui-actions/UiActions";
import withStyles from "material-ui/styles/withStyles";
import TableComponent from "../CustumComponents/TableComponent";
import cons, {serverApi} from "../../helper/Consts";
import {getCookie} from "../../helper/Cookies";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import TextField from "../../components/CustomInput/CustomTextField";
import Undo from "@material-ui/icons/Undo";
import IconButton from "components/CustomButtons/IconButton.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {getDataFromUrl, requestList} from "../../server/server";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Popup from "../../components/Popup/PopUp";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";
import TallyUndo from "../../assets/img/undo_tally.png";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import { connect } from "react-redux";
class TallySync extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().day(-10).format('DD-MM-YYYY'),
            todate: moment().format('DD-MM-YYYY'),
            fromDate1: moment().day(-10).format('DD-MM-YYYY'),
            todate1: moment().format('DD-MM-YYYY'),
            fromDate2: moment().day(-10).format('DD-MM-YYYY'),
            todate2: moment().format('DD-MM-YYYY'),
            fromDate3: moment().day(-10).format('DD-MM-YYYY'),
            todate3: moment().format('DD-MM-YYYY'),
            branchList:[],
            branchSelectionList:[],

        };
    }
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    componentDidMount() {
        this.getBranches();


    }
    getBranches=()=>{
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };

        requestList(serverApi.BRANCHMASTER_DATATABLE,data,this.handleBranches);
    };
    handleBranches=(resp)=>{
        var list=[],branchSelectionList=[];
        resp.data.map((prop,key)=>{
            var val={
                id:prop.id,
                bName:prop.brname,
                "tallyUrl":prop.tallyUrl,
                "tallyPort":prop.tallyPort,
                "tallyEmails":prop.tallyEmails,
                "autoSync":"N"
            };
            branchSelectionList.push({label:prop.brname,value:prop.id,name:prop.brname})

            // if(key>0)
            list.push(val)
        });
        this.setState({
            branchList:list,
            branchSelectionList:branchSelectionList,
            loadBranch:true,
            branchId:list[0]?list[0].id:"",
            branchId1:list[0]?list[0].id:"",
            branchId2:list[0]?list[0].id:"",
            branchId3:list[0]?list[0].id:"",
        });
        this.getTransTable("P");
        this.getReceiptsTable();
        this.getInvoiceTableList();
        this.getBillsList();
    };

    getBillsList=()=>{
        var data = {
            "fromDate": this.state.fromDate1
            , "toDate": this.state.todate1,
            "status": "3",
            "companyId": this.props.state.companyInfo.id,
            "branchId":this.state.branchId1
        };
        requestList(serverApi.DATATABLE_SERVICE_URL+"getAllTypesBills",data,this.handleBillsList)
    };
    handleBillsList=(resp)=>{
        const {classes} = this.props;
        var list=resp;
        list.column.push({Header:"Tally",accessor:"tally",sortable:false,filterable:false,minWidth:200});
        list.data=resp.data.map((prop,i)=>{
            var obj={...prop,
                tally:(
                    <div className="actions-right">
                        <div className="actions-right">
                            {prop.tallySync==="Y"?<img src={TallyIcon} width={50} height={50}/>:null}
                            {prop.tallySync==="R"?<img src={TallyRedIcon} width={50} height={50} style={{cursor:"pointer"}} onClick={()=>{
                                this.showMessage(prop.tallyMessage,false)
                            }}/>:null}

                            {prop.tallySync==="R"||prop.tallySync==="Y"?<img src={TallyUndo}  width={30} height={30} style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
                               // this.handleLoading();
                                requestList(serverApi.UPDATE_BILLS_TO_TALLY,{"ids":[prop.billPassNo],"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleBillsSyncResp)

                            }}/>:null}
                        </div>
                        {/*
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={prop.tallySync==="N"}
                                    value={2}
                                    name="radio button demo"
                                    aria-label="A"
                                    onChange={(event)=>{
                                        if(event.target.checked){
                                            this.handleLoading();
                                            getDataFromUrl(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID+prop.invoiceId,this.handleInvoiceSyncResp)
                                        }
                                    }}

                                    icon={
                                        <FiberManualRecord
                                            className={classes.radioUnchecked}
                                        />
                                    }
                                    checkedIcon={
                                        <FiberManualRecord
                                            className={classes.radioChecked}
                                        />
                                    }
                                    classes={{
                                        checked: classes.radio
                                    }}
                                />
                            }

                            label={"No"}
                        />
*/}


                    </div>
                )}
            return obj;
        });
        if(resp){
            this.setState({billsList:list,isRefreshBill:true})
        }
    };
    getInvoiceTableList=()=>{
        var data = {
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate,
            "cusId":"",
            "invoiceNo": "",
            "status": "3",
            "type":"",
            "companyId": this.props.state.companyInfo.id,
            branchId:this.state.branchId
           // "acYear": getCookie(cons.ACADEMIC_YEAR),
        };

        requestList(serverApi.INVOICE_LIST,data,this.handleInvoiceList)
    };
    handleInvoiceList=(resp)=>{
        const {classes} = this.props;
        var list=resp;
        list.column.push({Header:"Tally",accessor:"tally",sortable:false,filterable:false,minWidth:150});
        list.data=resp.data.map((prop,i)=>{
            var obj={...prop,tally:(
                    <div className="actions-right">
                        <div className="actions-right">
                            {prop.tallySync==="Y"?<img src={TallyIcon} width={50} height={50}/>:null}
                            {prop.tallySync==="R"?<img src={TallyRedIcon} width={50} height={50} style={{cursor:"pointer"}} onClick={()=>{
                                this.showMessage(prop.tallyMessage,false)
                            }}/>:null}

                            {prop.tallySync==="R"||prop.tallySync==="Y"?<img src={TallyUndo}  width={30} height={30} style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
                               // this.handleLoading();
                                requestList(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID,{"ids":[prop.invoiceId],"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleInvoiceSyncResp)

                            }}/>:null}
                        </div>
  {/*
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={prop.tallySync==="N"}
                                    value={2}
                                    name="radio button demo"
                                    aria-label="A"
                                    onChange={(event)=>{
                                        if(event.target.checked){
                                            this.handleLoading();
                                            getDataFromUrl(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID+prop.invoiceId,this.handleInvoiceSyncResp)
                                        }
                                    }}

                                    icon={
                                        <FiberManualRecord
                                            className={classes.radioUnchecked}
                                        />
                                    }
                                    checkedIcon={
                                        <FiberManualRecord
                                            className={classes.radioChecked}
                                        />
                                    }
                                    classes={{
                                        checked: classes.radio
                                    }}
                                />
                            }

                            label={"No"}
                        />
*/}


                    </div>
                )}
                return obj;
        });
        if(resp){
            this.setState({invoiceList:list,isRefresh:true})
        }
    };

    handleSeacrh = () => {
        if(!this.state.isRefresh){
            this.getInvoiceTableList()
        }

        this.setState({
            isRefresh: !this.state.isRefresh,
        });
    };
    handleSeacrhbill = () => {
        if(!this.state.isRefreshBill)
            this.getBillsList();
        this.setState({
            isRefreshBill: !this.state.isRefreshBill,
        });
    };
    getData = (list,branchId) => {
        var tally=this.getTallyDetailsFromBranch(branchId);
        var data={"ids":list,"autoSync":"N",companyId:this.props.state.companyInfo.id,tallyUrl:tally.tallyUrl,tallyPort:tally.tallyPort,tallyEmails:tally.tallyEmails}
        return data;
    };
    getTallyDetailsFromBranch=(id)=>{
        var list=this.state.branchList;
        var obj="";
        list.forEach(prop=>{
            if(prop.id===id)
                obj= prop;
        })
        return obj;
    }

    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id]: ''
            });
        //  this.dateChanged();
    };
    getTransTable=()=>{
        var data={
            "fromDate":this.state.fromDate2,
            "toDate":this.state.todate2,
            "tranType":"P",
            "tranStatus":"3",
            "companyId":this.props.state.companyInfo.id,
            "ledgerId":"","suppType":"",
            "acYear": getCookie(cons.ACADEMIC_YEAR),
            "branchId":this.state.branchId2
        };
        // this.handleLoading();
        requestList(serverApi.GET_TRANSACTIONS_TABLE_LIST,data,this.handleTransResp)

    };
    getReceiptsTable=()=>{
        var data={
            "fromDate":this.state.fromDate3,
            "toDate":this.state.todate3,
            "tranType":"R",
            "tranStatus":"3",
            "companyId":this.props.state.companyInfo.id,
            "ledgerId":"","suppType":"",
            "acYear": getCookie(cons.ACADEMIC_YEAR),
            "branchId":this.state.branchId3
        };
        // this.handleLoading();
        requestList(serverApi.GET_TRANSACTIONS_TABLE_LIST,data,this.handleReceiptsResp)

    };

    handleTransResp = (resp) =>{
        console.log("transactions",JSON.stringify(resp));
        var data=resp.data.map((prop,i)=>{
            var obj={
                transactionId:prop.transactionId,
                transaUniqNo:prop.transaUniqNo,
                ledgerName:prop.ledgerName,
                accountName:prop.accountName,
                "description": prop.description,
                "billNo": prop.billNo,
                "tranDate": prop.tranDate,
                "fundsOut":prop.fundsOut>0?parseFloat(prop.fundsOut).toFixed(2):0.00,
                "status":prop.status,
                "dueAmount":prop.dueAmount,
                "bankId":prop.bankId,
                "ledgerId":prop.ledgerId,
                "invoiceNo":prop.invoiceNo,
                "tallySync":prop.tallySync,
                tally:(
                    <div className="actions-right">
                        <div className="actions-right">
                            {prop.tallySync==="Y"?<img src={TallyIcon} width={50} height={50}/>:null}
                            {prop.tallySync==="R"?<img src={TallyRedIcon} width={50} height={50} style={{cursor:"pointer"}} onClick={()=>{
                                this.showMessage(prop.tallyMessage,false)
                            }}/>:null}

                            {prop.tallySync==="R"||prop.tallySync==="Y"?<img src={TallyUndo}  width={30} height={30} style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
                                //this.handleLoading();
                                requestList(serverApi.UPDATE_RECEIPTS_TO_TALLY,{"ids":[prop.transactionId],"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handlePaymentsSyncResp)

                            }}/>:null}
                        </div>
                        {/*
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={prop.tallySync==="N"}
                                    value={2}
                                    name="radio button demo"
                                    aria-label="A"
                                    onChange={(event)=>{
                                        if(event.target.checked){
                                            this.handleLoading();
                                            getDataFromUrl(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID+prop.invoiceId,this.handleInvoiceSyncResp)
                                        }
                                    }}

                                    icon={
                                        <FiberManualRecord
                                            className={classes.radioUnchecked}
                                        />
                                    }
                                    checkedIcon={
                                        <FiberManualRecord
                                            className={classes.radioChecked}
                                        />
                                    }
                                    classes={{
                                        checked: classes.radio
                                    }}
                                />
                            }

                            label={"No"}
                        />
*/}


                    </div>
                )
            };

            return obj;
        });
        var tableList= {"data":data,"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":true,"Header":"TRN No","accessor":"transaUniqNo","minWidth":50,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Ledger","accessor":"ledgerName","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Account","accessor":"accountName","minWidth":60,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Description","accessor":"description","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Bill No","accessor":"billNo","minWidth":50,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Date","accessor":"tranDate","minWidth":60,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Amount","accessor":"fundsOut","minWidth":80,"style":{"textAlign":"right"},"sortable":true},{"filterable":true,"Header":"Status","accessor":"status","minWidth":80,"style":{"textAlign":"center"}},   {Header:"Tally",accessor:"tally",sortable:false,filterable:false,minWidth:150}]};

        //  if(resp.status){
        this.setState({
            tableList:tableList,
            isRefreshPayments: true,
        });
        // }
        //  this.handleLoading();

    };
    handleReceiptsResp = (resp) => {
        const {classes} = this.props;
        console.log("transactions", JSON.stringify(resp));
        var data = resp.data.map((prop, i) => {
            var obj = {
                transactionId:prop.transactionId,
                transaUniqNo: prop.transaUniqNo,
                ledgerName: prop.ledgerName,
                accountName: prop.accountName,
                "description": prop.description,
                "invoiceNo": prop.invoiceNo,
                "tranDate": prop.tranDate,
                "fundsIn": prop.fundsIn > 0 ? parseFloat(prop.fundsIn).toFixed(2) : 0.00,
                "status": prop.status,
                "dueAmount": prop.dueAmount > 0 ? parseFloat(prop.dueAmount).toFixed(2) : 0.00,
                "bankId": prop.bankId,
                "ledgerId": prop.ledgerId,
                "billNo": prop.billNo,
                "fundsOut": prop.fundsOut,
                "tallySync":prop.tallySync,
                tally:(
                    <div className="actions-right">
                        <div className="actions-right">
                            {prop.tallySync==="Y"?<img src={TallyIcon} width={50} height={50}/>:null}
                            {prop.tallySync==="R"?<img src={TallyRedIcon} width={50} height={50} style={{cursor:"pointer"}} onClick={()=>{
                                this.showMessage(prop.tallyMessage,false)
                            }}/>:null}

                            {prop.tallySync==="R"||prop.tallySync==="Y"?<img src={TallyUndo}  width={30} height={30} style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
                                //this.handleLoading();
                                requestList(serverApi.UPDATE_RECEIPTS_TO_TALLY,{"ids":[prop.transactionId],"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleReceiptsSyncResp)

                            }}/>:null}
                        </div>
                        {/*
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={prop.tallySync==="N"}
                                    value={2}
                                    name="radio button demo"
                                    aria-label="A"
                                    onChange={(event)=>{
                                        if(event.target.checked){
                                            this.handleLoading();
                                            getDataFromUrl(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID+prop.invoiceId,this.handleInvoiceSyncResp)
                                        }
                                    }}

                                    icon={
                                        <FiberManualRecord
                                            className={classes.radioUnchecked}
                                        />
                                    }
                                    checkedIcon={
                                        <FiberManualRecord
                                            className={classes.radioChecked}
                                        />
                                    }
                                    classes={{
                                        checked: classes.radio
                                    }}
                                />
                            }

                            label={"No"}
                        />
*/}


                    </div>
                )
            };

            return obj;
        });

        var tableList = {
            "data": data,
            "filterTable": true,
            "defaultPageSize": 5,
            "showPaginationTop": false,
            "showPaginationBottom": true,
            "column": [{
                "filterable": true,
                "Header": "TRN No",
                "accessor": "transaUniqNo",
                "minWidth": 50,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Ledger",
                "accessor": "ledgerName",
                "minWidth": 100,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Account",
                "accessor": "accountName",
                "minWidth": 60,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Description",
                "accessor": "description",
                "minWidth": 100,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Invoice No",
                "accessor": "invoiceNo",
                "minWidth": 100,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Date",
                "accessor": "tranDate",
                "minWidth": 60,
                "style": {"textAlign": "left"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Amount",
                "accessor": "fundsIn",
                "minWidth": 80,
                "style": {"textAlign": "right"},
                "sortable": true
            }, {
                "filterable": true,
                "Header": "Status",
                "accessor": "status",
                "minWidth": 80,
                "style": {"textAlign": "center"}
            },
                {Header:"Tally",accessor:"tally",sortable:false,filterable:false,minWidth:150}
                ]
        };

        //  if(resp.status){
        this.setState({
            rectableList: tableList,
            isRefreshReceipts: true,
        });
        // }
        //  this.handleLoading();

    };
    tallySyncButtonClick=()=>{
        var list=[];
        if(this.state.invoiceList){
            this.state.invoiceList.data.forEach(prop=>{
                if(prop.tallySync==="N"){
                    list.push(prop.invoiceId)
                }
            })
        }
        if(list.length>0){
            var data=this.getData(list,this.state.branchId);
           // this.handleLoading();
            requestList(serverApi.ADD_INVOICE_TO_TALLY,data,this.handleInvoiceSyncResp)
        }
    };
    tallySyncBillsButtonClick=()=>{
        var list=[];
        if(this.state.billsList){
            this.state.billsList.data.forEach(prop=>{
                if(prop.tallySync==="N"){
                    list.push(prop.billPassNo)
                }
            })
        }
        if(list.length>0){
            //this.handleLoading();
            var data=this.getData(list,this.state.branchId1);
            requestList(serverApi.ADD_BILLS_TO_TALLY,data,this.handleBillsSyncResp)
        }
    };
    tallySyncPaymentsButtonClick=()=>{
        var list=[];
        if(this.state.tableList){
            this.state.tableList.data.forEach(prop=>{
                if(prop.tallySync==="N"){
                    list.push(prop.transactionId)
                }
            })
        }
        if(list.length>0){

            var data=this.getData(list,this.state.branchId2);           // this.handleLoading();
            requestList(serverApi.ADD_RECEIPTS_TO_TALLY,data,this.handlePaymentsSyncResp)
        }
    };

    tallySyncReceiptsButtonClick=()=>{
        var list=[];
        if(this.state.rectableList){
            this.state.rectableList.data.forEach(prop=>{
                if(prop.tallySync==="N"){
                    list.push(prop.transactionId)
                }
            })
        }
        if(list.length>0){
           // this.handleLoading();
            var data=this.getData(list,this.state.branchId3);
            requestList(serverApi.ADD_RECEIPTS_TO_TALLY,data,this.handleReceiptsSyncResp)
        }
    };

    handleBillsSyncResp=(resp)=>{
        //this.handleLoading();
        this.showMessage(resp.message, resp.status);
        if(resp.status){
            this.getBillsList();
        }
    }
    handlePaymentsSyncResp=(resp)=>{
        //this.handleLoading();
        this.showMessage(resp.message, resp.status);
        if(resp.status){
            this.getTransTable();
        }
    };
    handleReceiptsSyncResp=(resp)=>{
       // this.handleLoading();
        this.showMessage(resp.message, resp.status);
        if(resp.status){
            this.getReceiptsTable();
        }
    };


    handleInvoiceSyncResp=(resp)=>{
       // this.handleLoading();
        this.showMessage(resp.message, resp.status);
        if(resp.status){
            this.getInvoiceTableList();
        }
    };
    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                       this.setState({  showMessagealert: false})
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    onSaveTallyConfig=()=>{
        var list=this.state.branchList;
      requestList(serverApi.UPDATE_TALLY_CONFIG,list,this.handleTallyConfig)
    };
    handleTallyConfig=(resp)=>{
        this.showMessage(resp.message,false);
        this.getBranches();
    };
    onSelect = (selectedValue, stateKey) => {
        this.setState({
            [stateKey]: selectedValue
        });
    };

    render() {
        const {classes} = this.props;
        const inputFocus = {
            autoComplete: 'off'
        };
        var yesterday = Datetime.moment().subtract(1, 'day');
        var Tabs;
            Tabs = [
                {
                    tabButton: "Invoice",
                    tabContent: (
                        <ItemGrid xs={12} style={{overflowX: 'hidden'}}>

                            <GridContainer>
                                <ItemGrid xs={10}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        From date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDate")
                                                            }}
                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "fromDate",
                                                                value: this.state.fromDate,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        To date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "todate")
                                                            }}

                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "toDate",
                                                                value: this.state.todate,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} md={4}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Branch
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.branchId}
                                                        onSelect={this.onSelect}
                                                        stateKey="branchId"
                                                        staticData={this.state.branchSelectionList}
                                                        value={this.state.branchId}
                                                        isRefresh={this.state.loadBranch}
                                                        setIsRefresh={()=>{
                                                            this.setState({loadBranch:false})
                                                        }}
                                                    />

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>



                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={2}>
                                    <GridContainer>
                                        <ItemGrid xs={6}>
                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrh}>Search</Button>
                                        </ItemGrid>
                                        <ItemGrid xs={6}>
                                            <Button color="info" right={true}
                                                    onClick={this.tallySyncButtonClick}>Sync</Button>
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>

                                <ItemGrid xs={12}>


                                    <TableComponent
                                        staticData={this.state.invoiceList}
                                      //  url={serverApi.INVOICE_LIST}
                                     //   searchData={this.getData()}
                                        isRefresh={this.state.isRefresh}
                                        handleSearch={this.handleSeacrh.bind(this)}
                                        isLoading={this.state.isRefresh}
                                        onActionClick={this.handleViewClick}
                                        itemViewType={this.state.itemViewType}
                                        fileName={getCookie(cons.COMPANY_NAME) + '_INVOICES'}
                                        // showDelete={true}
                                        //  onDeleteClick={this.onDeleteBill}
                                       // actionType={VIEW}
                                    />

                                </ItemGrid>
                                {this.state.invoiceList&&this.state.invoiceList.data.length>0?<ItemGrid xs={11} style={{textAlign:'right',fontStyle:'bold',marginTop:"-40px"}} >
                                   <Button color="dangerNoBackground" onClick={()=>{
                                       var list=[];
                                       if(this.state.invoiceList){
                                           this.state.invoiceList.data.forEach(prop=>{
                                               if(prop.tallySync==="Y"||prop.tallySync==="R"){
                                                   list.push(prop.invoiceId)
                                               }
                                           })
                                       }

                                       if(list.length>0)
                                       requestList(serverApi.UPDATE_INVOCE_TO_TALLY_BY_ID,{"ids":list,"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleInvoiceSyncResp)

                                   }}>  Click Here To Rollback All Invoices To Sync Status As "No"</Button>
                                </ItemGrid>:null}

                            </GridContainer>

                        </ItemGrid>
                    )
                },
                {
                    tabButton: "Bills",
                    tabContent: (
                        <ItemGrid xs={12} style={{overflow: 'hidden'}}>
                            <GridContainer>
                                <ItemGrid xs={10}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        From date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDate1")
                                                            }}
                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "fromDate1",
                                                                value: this.state.fromDate1,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        To date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "todate1")
                                                            }}

                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "toDate1",
                                                                value: this.state.todate1,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} md={4}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Branch
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.branchId1}
                                                        onSelect={this.onSelect}
                                                        stateKey="branchId1"
                                                        staticData={this.state.branchSelectionList}
                                                        value={this.state.branchId1}
                                                        isRefresh={this.state.loadBranch}
                                                        setIsRefresh={()=>{
                                                            this.setState({loadBranch:false})
                                                        }}
                                                    />

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>



                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={2}>
                                    <GridContainer>


                                        <ItemGrid xs={6}>
                                            <Button color="primary" right={true}
                                                    onClick={this.handleSeacrhbill}>Search</Button>
                                        </ItemGrid>
                                        <ItemGrid xs={6}>
                                            <Button color="info" right={true}
                                                    onClick={this.tallySyncBillsButtonClick}>Sync</Button>
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>

                                <ItemGrid xs={12}>


                                    <TableComponent
                                        staticData={this.state.billsList}
                                       // url={serverApi.ACCOUNT_BILLS_TABLE}
                                        //searchData={this.getBillsData()}
                                        isRefresh={this.state.isRefreshBill}
                                        handleSearch={this.handleSeacrhbill.bind(this)}
                                        isLoading={this.state.isRefreshBill}
                                       // onActionClick={this.handleViewClick}
                                        itemViewType={this.state.itemViewType}
                                        fileName={getCookie(cons.COMPANY_NAME) + '_BILLS'}
                                       // showDelete={true}
                                      //  onDeleteClick={this.onDeleteBill}
                                     //   actionType={VIEW}
                                    />

                                </ItemGrid>
                                {this.state.billsList&&this.state.billsList.data.length>0?<ItemGrid xs={11} style={{textAlign:'right',fontStyle:'bold',marginTop:"-40px"}} >
                                    <Button color="dangerNoBackground" onClick={()=>{
                                        var list=[];
                                        if(this.state.billsList){
                                            this.state.billsList.data.forEach(prop=>{
                                                if(prop.tallySync==="Y"||prop.tallySync==="R"){
                                                    list.push(prop.billPassNo)
                                                }
                                            })
                                        }
                                        if(list.length>0)
                                        requestList(serverApi.UPDATE_BILLS_TO_TALLY,{"ids":list,"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleBillsSyncResp)

                                    }}>  Click Here To Rollback All Bills To Sync Status As "No"</Button>
                                </ItemGrid>:null}
                            </GridContainer>

                        </ItemGrid>
                    )
                },
                {
                    tabButton: "Payments",
                    tabContent: (
                        <ItemGrid xs={12} style={{overflow: 'hidden'}}>
                            <GridContainer>
                                <ItemGrid xs={10}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        From date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDate2")
                                                            }}
                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "fromDate2",
                                                                value: this.state.fromDate2,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        To date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "todate2")
                                                            }}

                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none2"}
                                                            }
                                                            inputProps={{
                                                                id: "toDate",
                                                                value: this.state.todate2,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} md={4}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Branch
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.branchId2}
                                                        onSelect={this.onSelect}
                                                        stateKey="branchId2"
                                                        staticData={this.state.branchSelectionList}
                                                        value={this.state.branchId2}
                                                        isRefresh={this.state.loadBranch}
                                                        setIsRefresh={()=>{
                                                            this.setState({loadBranch:false})
                                                        }}
                                                    />

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>



                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={2}>
                                    <GridContainer>


                                        <ItemGrid xs={6}>
                                            <Button color="primary" right={true}
                                                    onClick={()=>{
                                                        if(!this.state.isRefreshPayments){
                                                            this.getTransTable("P");
                                                        }
                                                        this.setState({
                                                            isRefreshPayments: !this.state.isRefreshPayments,
                                                        });
                                                    }}>Search</Button>
                                        </ItemGrid>
                                        <ItemGrid xs={6}>
                                            <Button color="info" right={true}
                                                    onClick={this.tallySyncPaymentsButtonClick}>Sync</Button>
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>

                                <ItemGrid xs={12}>


                                    <TableComponent
                                        staticData={this.state.tableList}
                                        isRefresh={this.state.isRefreshPayments}
                                        handleSearch={()=>{
                                            if(!this.state.isRefreshPayments){
                                                this.getTransTable("P");
                                            }
                                            this.setState({
                                                isRefreshPayments: !this.state.isRefreshPayments,
                                            });
                                        }}
                                        isLoading={this.state.isRefresh}
                                       // onActionClick={this.handleViewClick}
                                        itemViewType={this.state.itemViewType}
                                        fileName={getCookie(cons.COMPANY_NAME) + '_BILLS'}
                                       // actionType={VIEW}
                                    />

                                </ItemGrid>
                                {this.state.tableList&&this.state.tableList.data.length>0?<ItemGrid xs={11} style={{textAlign:'right',fontStyle:'bold',marginTop:"-40px"}} >
                                    <Button color="dangerNoBackground" onClick={()=>{
                                        var list=[];
                                        if(this.state.tableList){
                                            this.state.tableList.data.forEach(prop=>{
                                                if(prop.tallySync==="Y"||prop.tallySync==="R"){
                                                    list.push(prop.transactionId)
                                                }
                                            })
                                        }
                                        if(list.length>0)
                                        requestList(serverApi.UPDATE_RECEIPTS_TO_TALLY,{"ids":list,"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handlePaymentsSyncResp)

                                    }}>  Click Here To Rollback All Payments To Sync Status As "No"</Button>
                                </ItemGrid>:null}

                            </GridContainer>

                        </ItemGrid>
                    )
                },
                {
                    tabButton: "Receipts",
                    tabContent: (
                        <ItemGrid xs={12} style={{overflow: 'hidden'}}>
                            <GridContainer>
                                <ItemGrid xs={10}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        From date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "fromDate3")
                                                            }}
                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "fromDate3",
                                                                value: this.state.fromDate3,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        To date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <Datetime
                                                            closeOnSelect={true}
                                                            timeFormat={false}
                                                            onChange={(dateObj) => {
                                                                this.timeChanged(dateObj, "todate3")
                                                            }}

                                                            formControlProps={
                                                                {height: "10px", inlineBlock: "none"}
                                                            }
                                                            inputProps={{
                                                                id: "toDate3",
                                                                value: this.state.todate3,
                                                                autoComplete: 'off'
                                                            }}
                                                        />
                                                    </FormControl>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        <ItemGrid xs={12} md={4}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Branch
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.branchId3}
                                                        onSelect={this.onSelect}
                                                        stateKey="branchId3"
                                                        staticData={this.state.branchSelectionList}
                                                        value={this.state.branchId3}
                                                        isRefresh={this.state.loadBranch}
                                                        setIsRefresh={()=>{
                                                            this.setState({loadBranch:false})
                                                        }}
                                                    />

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>



                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={2}>
                                    <GridContainer>


                                        <ItemGrid xs={6}>
                                            <Button color="primary" right={true}
                                                    onClick={()=>{
                                                        if(!this.state.isRefreshReceipts){
                                                            this.getReceiptsTable();
                                                        }
                                                        this.setState({
                                                            isRefreshReceipts: !this.state.isRefreshReceipts,
                                                        });
                                                    }}>Search</Button>
                                        </ItemGrid>
                                        <ItemGrid xs={6}>
                                            <Button color="info" right={true}
                                                    onClick={this.tallySyncReceiptsButtonClick}>Sync</Button>
                                        </ItemGrid>

                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>

                                <ItemGrid xs={12}>


                                    <TableComponent
                                        staticData={this.state.rectableList}
                                        isRefresh={this.state.isRefreshReceipts}
                                        handleSearch={()=>{
                                            if(!this.state.isRefreshReceipts){
                                                this.getReceiptsTable();
                                            }
                                            this.setState({
                                                isRefreshReceipts: !this.state.isRefreshReceipts,
                                            });
                                        }}
                                        isLoading={this.state.isRefresh}
                                        onActionClick={this.handleViewClick}
                                        itemViewType={this.state.itemViewType}
                                        fileName={getCookie(cons.COMPANY_NAME) + '_BILLS'}
                                       // actionType={VIEW}
                                    />

                                </ItemGrid>
                                {this.state.rectableList&&this.state.rectableList.data.length>0?<ItemGrid xs={11} style={{textAlign:'right',fontStyle:'bold',marginTop:"-40px"}} >
                                    <Button color="dangerNoBackground" onClick={()=>{
                                        var list=[];
                                        if(this.state.rectableList){
                                            this.state.rectableList.data.forEach(prop=>{
                                                if(prop.tallySync==="Y"||prop.tallySync==="R"){
                                                    list.push(prop.transactionId)
                                                }
                                            })
                                        }
                                        if(list.length>0)
                                        requestList(serverApi.UPDATE_RECEIPTS_TO_TALLY,{"ids":list,"autoSync":"N",companyId:this.props.state.companyInfo.id},this.handleReceiptsSyncResp)

                                    }}>  Click Here To Rollback All Receipts To Sync Status As "No"</Button>
                                </ItemGrid>:null}

                            </GridContainer>

                        </ItemGrid>
                    )
                },
                {
                    tabButton: "Tally Config",
                    tabContent: (
                        <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                            <GridContainer>
                                <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                        Branch
                                    </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                        Url
                                    </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                        Port
                                    </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                        Email
                                    </InputLabel>
                                </ItemGrid>

                                <ItemGrid xs={12} md={12} >
                                    <GridContainer>

                                        {this.state.branchList.map((prop,i)=>{
                                            return(
                                                <ItemGrid xs={12} sm={12}>
                                                    <GridContainer>
                                                        <ItemGrid xs={3}>
                                                            {prop.bName}
                                                        </ItemGrid>
                                                        <ItemGrid xs={3}>
                                                            <TextField
                                                                id="url"
                                                                className={classes.textField}
                                                                value={prop.tallyUrl}
                                                                onChange={(event)=>{
                                                                    var list=this.state.branchList;
                                                                    list[i].tallyUrl=event.target.value
                                                                    this.setState({branchList:list})
                                                                }}
                                                                fullWidth={true}
                                                                variant="outlined"

                                                            />
                                                        </ItemGrid>
                                                        <ItemGrid xs={3}>
                                                            <TextField
                                                                id="port"
                                                                className={classes.textField}
                                                                value={prop.tallyPort}
                                                                onChange={(event)=>{
                                                                    var list=this.state.branchList;
                                                                    list[i].tallyPort=event.target.value
                                                                    this.setState({branchList:list})
                                                                }}
                                                                fullWidth={true}
                                                                variant="outlined"

                                                            />
                                                        </ItemGrid>
                                                        <ItemGrid xs={3}>
                                                            <TextField
                                                                id="email"
                                                                className={classes.textField}
                                                                value={prop.tallyEmails}
                                                                onChange={(event)=>{
                                                                    var list=this.state.branchList;
                                                                    list[i].tallyEmails=event.target.value
                                                                    this.setState({branchList:list})
                                                                }}
                                                                fullWidth={true}
                                                                variant="outlined"

                                                            />
                                                        </ItemGrid>

                                                    </GridContainer>

                                                </ItemGrid>

                                            )
                                        })}
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                    <GridContainer justify="center">

                                            <ItemGrid>
                                                <Button color="primary"
                                                        onClick={this.onSaveTallyConfig}>Save</Button>
                                            </ItemGrid>

                                    </GridContainer>

                                </ItemGrid>

                            </GridContainer>
                        </ItemGrid>
                    )
                }


            ];


        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>


                    <ItemGrid xs={12}>
                        {this.state.showMessagealert}
                        <RegularCard
                            isLoading={this.state.isLoading}

                           // cardTitle={}
                            content={
                                <NavPills
                                    color="green"
                                    tabs={Tabs}
                                />
                            }
                        />

                    </ItemGrid>
            </GridContainer>
        );
    }

}
const extendedFormsStyle = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(TallySync)
);