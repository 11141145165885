import React from "react";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {
  configNames,
  getConfigValue,
  serverApi,
} from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList, uploadFile } from "../../server/server";
import moment from "moment";
import { getCookie, removeCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import { PaymentSource } from "../TableData";
import Jspdf from "jspdf";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import InputAdornment from "material-ui/Input/InputAdornment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import LineItemTable from "../CustumComponents/LineItemTable";
import TallyIcon from "../../assets/img/tally_icon.png";
import TallyRedIcon from "../../assets/img/tally_red.png";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      billPassNo: "",
      ledgerOptions: [],
      file: null,
      fileName: "No file Chosen",
      isRefresh: false,
      isLoading: false,
      itemViewType: "transaUniqNo",
      //itemViewType: "mrNo",
      approveStatus: false,
      updateView: false,
      statusDesc: "",
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      invoiceList: [],
      lineItemId: "0",
      billPassStatus: "",
      form_supplierId: "",
      date1: moment().format("DD-MM-YYYY"),
      taxType: null,
      totalAmount: "",
      netTotal: "",
      transaUniqNo: "",
      taxId: "",
      uom: "",
      amount: 0,
      rate: "",
      description: "",
      taxPercentage: 0,
      accountOptions: [],
      igstPercent: 0,
      cgstPercent: 0,
      sgstPercent: 0,
      billNo: 0,
      billAmount: 0,
      tdsReason: "",
      tdsAmount: "",
      advanceId: "",
      billsList: [],
      branchId: "",
      tallySync: "",
      showupdateBillNo: false,
      receiptNo: "",
      trnDetailId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    this.getReportsTable();
    var data1 = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.BANK_ACCOUNTS_MASTER, data1, this.handleBankAccounts);
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.LEDGER_MASTER, data1, this.handleLedgerList);

    if (
      getCookie(cons.ADVANCE_ID) !== undefined &&
      getCookie(cons.ADVANCE_ID) !== null
    ) {
      this.setState({
        advanceId: getCookie(cons.ADVANCE_ID),
        ledger: parseInt(getCookie(cons.LEDGER_ID)),
        isCreated: true,
        reloadLedger: true,
      });
      removeCookie(cons.ADVANCE_ID);
      removeCookie(cons.LEDGER_ID);
    }
    this.getBills();
  }

  handleLedgerList = (resp) => {
    var options = [];
    resp.data.forEach((prop) => {
      var obj = {
        label: prop.ledgerName,
        value: prop.id,
        name: prop.ledgerName,
      };
      options.push(obj);
    });
    this.setState({
      ledgerOptions: options,
      reloadLedger: true,
    });
  };
  getBills = (ledger) => {
    var data = {
      fromDate: "",
      toDate: "",
      tranType: "B",
      tranStatus: 3,
      companyId: this.props.state.companyInfo.id,
      ledgerId: ledger,
      suppType: "",
    };
    // this.handleLoading();
    requestList(
      serverApi.GET_TRANSACTIONS_TABLE_LIST,
      data,
      this.handleBuillsResp
    );
  };

  getReportsTable = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      tranType: "P",
      tranStatus: "",
      companyId: this.props.state.companyInfo.id,
      ledgerId: "",
      suppType: "",
      acYear: getCookie(cons.ACADEMIC_YEAR),
    };
    // this.handleLoading();
    requestList(
      serverApi.GET_TRANSACTIONS_TABLE_LIST,
      data,
      this.handleTransResp
    );
  };
  handleBankAccounts = (resp) => {
    var options = [];
    resp.data.forEach((prop) => {
      var obj = {
        label: prop.accountantName,
        value: prop.id,
        name: prop.accountantName,
      };
      options.push(obj);
    });
    this.setState({
      accountOptions: options,
      reloadAccounts: true,
    });
  };
  handleBuillsResp = (resp) => {
    console.log("transactions bills", JSON.stringify(resp));
    var data = [];
    data.push({
      value: 0,
      name: "Select................",
      label: "Select................",
    });
    resp.data.forEach((prop, i) => {
      var obj = {
        label: prop.invoiceNo + "_" + prop.ledgerName + "_" + prop.fundsIn,
        name:
          prop.billNo +
          "_" +
          prop.ledgerId +
          "_" +
          prop.fundsIn +
          "_" +
          prop.invoiceNo +
          "_" +
          prop.paidAmount +
          "_" +
          prop.rcptNo,
        value: prop.billNo,
        ledgerId: prop.ledgerId,
      };

      data.push(obj);
    });
    this.setState({
      billsList: data,
      loadBills: true,
    });

    //  this.handleLoading();
  };

  handleTransResp = (resp) => {
    console.log("transactions", JSON.stringify(resp));
    var data = resp.data.map((prop, i) => {
      var obj = {
        transaUniqNo: prop.transaUniqNo,
        ledgerName: prop.ledgerName,
        accountName: prop.accountName,
        description: prop.description,
        billNo: prop.billNo,
        tranDate: prop.tranDate,
        fundsOut:
          prop.fundsOut > 0 ? parseFloat(prop.fundsOut).toFixed(2) : 0.0,
        status: prop.status,
        dueAmount: prop.dueAmount,
        bankId: prop.bankId,
        ledgerId: prop.ledgerId,
        invoiceNo: prop.invoiceNo,
        tally: (
          <div className="actions-right">
            {prop.tallySync === "Y" ? (
              <img src={TallyIcon} color={"danger"} width={50} height={50} />
            ) : null}
            {prop.tallySync === "R" ? (
              <img
                src={TallyRedIcon}
                width={50}
                height={50}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.showMessage(prop.tallyMessage, false);
                }}
              />
            ) : null}
          </div>
        ),
      };

      return obj;
    });
    var tableList = {
      data: data,
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      column: [
        {
          filterable: true,
          Header: "TRN No",
          accessor: "transaUniqNo",
          minWidth: 50,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Ledger",
          accessor: "ledgerName",
          minWidth: 100,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Account",
          accessor: "accountName",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Description",
          accessor: "description",
          minWidth: 100,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Bill No",
          accessor: "billNo",
          minWidth: 50,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Date",
          accessor: "tranDate",
          minWidth: 60,
          style: { textAlign: "left" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Amount",
          accessor: "fundsOut",
          minWidth: 80,
          style: { textAlign: "right" },
          sortable: true,
        },
        {
          filterable: true,
          Header: "Status",
          accessor: "status",
          minWidth: 80,
          style: { textAlign: "center" },
        },
        {
          Header: "Tally",
          accessor: "tally",
          sortable: false,
          filterable: false,
          minWidth: 100,
        },
      ],
    };

    //  if(resp.status){
    this.setState({
      tableList: tableList,
      isRefresh: true,
    });
    // }
    //  this.handleLoading();
  };

  handleSeacrh = () => {
    if (!this.state.isRefresh) {
      this.getReportsTable();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  getDefaultSideConvertion = (name) => {
    var val = 0,
      label = "Select..",
      data = "";
    if (name !== null && name !== 0 && name !== "") {
      if (name === 1) {
        val = 1;
        label = "INTRA STATE";
      } else {
        val = 2;
        label = "INTER STATE";
      }
      data = {
        value: val,
        label: label,
      };
    }
    return data;
  };

  handleViewClick = (id) => {
    // var data = this.state.tableList;
    var juteId = "";
    console.log("jute  " + "  " + id);
    // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.ACCOUNT_TRANSACTIONS_BY_ID +
          id +
          "/" +
          this.props.state.companyInfo.id +
          "/" +
          getCookie(cons.USERID),
        this.handleViewClickResp
      );
    }
  };
  handleViewClickResp = (resp) => {
    this.setState({
      printResp: resp,
    });
    var header = resp;
    if (header !== null) {
      var taxType = 0;
      var netTotal = 0;

      this.setState({
        updateView: true,
        isCreated: true,
        approveStatus: header.aprroveStatus,
        transaUniqNo: header.transactionUnqNo,
        //billNo:header.billPassid,
        transactionId: header.transactionId,
        ledger: parseInt(header.ledgerId),
        date1: header.tranDateDes,
        description: header.description,
        statusDesc: header.status,
        //  mrDate: header.goodReceiptDateDesc,
        totalAmount: header.fundsOut,
        //  year: header.acYear,
        createdBy: header.createdBy,
        createdOn: header.createDateDesc,
        taxId: header.taxId + "",
        cgstPercent: header.cgstPercentage,
        //  frieghtCharges:header.frieghtCharges,
        sgstPercent: header.sgstPercentage,
        igstPercent: header.igstPercentage,

        billPassStatus: header.tranStatus,
        paymentSource: header.paymentSource,
        bankRefnumber: header.bankRefnumber,

        account: header.bankId,
        netTotal: parseFloat(header.fundsOut).toFixed(2),
        taxType: this.getDefaultSideConvertion(taxType),
        tdsReason: header.tdsReason,
        tdsAmount: header.tdsAmount,
        branchId: header.branchId,
        tallySync: header.tallySync,
        showTransactionUpdate:
          header.bankRefnumber === null || header.bankRefnumber === ""
            ? true
            : false,
        showupdateBillNo: header.billPassid > 0 ? true : false,
        invoiceList: header.tranDetails,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    } else {
      this.getReportsTable();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  getData = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      type: "O",
      status: this.state.status,
      companyId: this.props.state.companyInfo.id,
    };
    return data;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });

    //  this.dateChanged();
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    // if (event.target.id === "amount") {
      // if (value !== "") {
        //if(parseFloat(value)<=parseFloat(this.state.billAmount)){
        this.setState({
          [event.target.id]: value,
        });
        //}else{
        // this.basicAlert("Amount should not greater than due amount "+this.state.billAmount);
        //}
      // }
    // } else
    //   this.setState({
    //     [event.target.id]: value,
    //   });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "ledger") {
      this.setState({
        ledger: selectedValue,
      });
      this.getBills(selectedValue);
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
      quantity: "",
      costCenterId: "",
      costCenter: "",
      taxId: "",
      uom: "",
      rate: "",
      billNo: "",
      amount: "",
      receiptNo: "",
      billAmount: 0,
      trnDetailId: "",
      invoiceNo: "",
    });
  }
  //log

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.getReportsTable();
      this.getBills();
    }
    this.setState({
      billPassStatus: "",
      transaUniqNo: "",
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      isRefresh: false,
      createdBy: "",
      createdOn: "",
      totalAmount: "",
      billPassDate: moment().format("DD-MM-YYYY"),
      date1: moment().format("DD-MM-YYYY"),
      taxType: null,
      netTotal: "",
      taxId: "",
      uom: "",
      amount: 0,
      rate: "",
      description: "",
      taxPercentage: 0,
      billAmount: 0,
      tdsReason: "",
      tdsAmount: "",
      advanceId: "",
      igstPercent: 0,
      cgstPercent: 0,
      sgstPercent: 0,
      account: "",
      paymentSource: "",
      bankRefnumber: "",
      ledger: "",
      billNo: 0,
      branchId: "",
      showTransactionUpdate: false,
      tallySync: "",
      showupdateBillNo: false,
      invoiceList: [],
    });
  }
  dateChanged = (myDate) => {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  onUpdateButtonClick = (status, isTally) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        advanceId,
        ledger,
        bankRefnumber,
        billAmount,
        tdsAmount,
        tdsReason,
        account,
        description,
        year,
        billNo,
        date1,
        transactionId,
        taxType,
        totalAmount,
        taxId,
        netTotal,
        cgstPercent,
        igstPercent,
        sgstPercent,
        chequeNo,
        paymentSource,
        branchId,
        tallySync,
        invoiceList,
      } = this.state;
      /*  var cgstAmount=parseFloat(amount)*parseFloat(cgstPercent)/100,
                sgstAmount=parseFloat(amount)*parseFloat(sgstPercent)/100,igstAmount=parseFloat(amount)*parseFloat(igstPercent)/100;
         */ var list = [];
      invoiceList.forEach((prop) => {
        list.push({
          billId: prop.billId,
          amount: prop.amount,
          receiptNo: prop.receiptNo,
          modifiedBy: getCookie(cons.USERID),
          isActive: prop.isActive,
          trnDetailId: prop.trnDetailId,
          invoiceNo: prop.invoiceNo,
          transactionId: transactionId,
        });
      });
      var data = {
        transactionId: transactionId,
        ledgerId: ledger,
        // "billPassid":billNo,
        transactionType: "P",
        fundsIn: "",
        fundsOut: totalAmount,
        bankId: account,
        transactionDate: this.dateChanged(date1),
        paidAmount: 0,
        recievedAmount: 0,
        tdsAmount: 0,
        tdsReason: "",
        /* "taxId":taxId,
                "cgstPercentage":cgstPercent,
                "sgstPercentage":sgstPercent,
                "igstPercentage":igstPercent,
                "cgstAmount":parseFloat(cgstAmount.toFixed(2)),
                "sgstAmount":parseFloat(sgstAmount.toFixed(2)),
                "igstAmount":parseFloat(igstAmount.toFixed(2)),*/
        updatedBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        paymentSource: paymentSource,
        bankRefnumber: bankRefnumber,
        description: description,
        acYear: year,
        tranStatus: status,
        userId: getCookie(cons.USERID),
        advanceId: advanceId,
        branchId: branchId,
        tallyButoon: isTally,
        tranDetails: list,
      };

      // new code for validation....
      const validateFields = [
        { field: this.state.branchId, msg: "Branch", type: 2 },
        { field: this.state.date1, msg: "Date", type: 2 },
        { field: this.state.account, msg: "Account", type: 2 },
        { field: this.state.paymentSource, msg: "Payment Source", type: 2 },
        { field: this.state.invoiceList, msg: "One LineItem", type: 2 },
      ];

      const validObj = validate(validateFields);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        if (list.length > 0) {
          this.handleLoading();
          requestList(
            serverApi.ACCOUNT_TRANSACTIONS_UPDATE,
            data,
            this.handleAddIndent
          );
        } else if (ledger > 0) {
          this.handleLoading();
          requestList(
            serverApi.ACCOUNT_TRANSACTIONS_UPDATE,
            data,
            this.handleAddIndent
          );
        } else {
          this.basicAlert("Please Select Bill No or Ledger..!!");
        }
      }

      // old code for validation...

      // if (
      //   date1 !== "" &&
      //   paymentSource !== "" &&
      //   account > 0 &&
      //   branchId !== "" &&
      //   branchId !== 0
      // ) {
      //   if (list.length > 0) {
      //     //  if(amount<=billAmount){
      //     this.handleLoading();
      //     requestList(
      //       serverApi.ACCOUNT_TRANSACTIONS_UPDATE,
      //       data,
      //       this.handleAddIndent
      //     );
      //     /* }else {
      //                    this.basicAlert("Amount should not greater than due amount "+billAmount);
      //                }*/
      //   } else if (ledger > 0) {
      //     this.handleLoading();
      //     requestList(
      //       serverApi.ACCOUNT_TRANSACTIONS_UPDATE,
      //       data,
      //       this.handleAddIndent
      //     );
      //   } else {
      //     this.basicAlert("Please Select Bill No or Ledger..!!");
      //   }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        advanceId,
        ledger,
        billNo,
        date1,
        account,
        billAmount,
        totalAmount,
        tdsAmount,
        tdsReason,
        taxId,
        description,
        cgstPercent,
        igstPercent,
        sgstPercent,
        bankRefnumber,
        paymentSource,
        year,
        branchId,
        invoiceList,
      } = this.state;
      /*    var cgstAmount=parseFloat(amount)*parseFloat(cgstPercent)/100,
                sgstAmount=parseFloat(amount)*parseFloat(igstPercent)/100,igstAmount=parseFloat(amount)*parseFloat(sgstPercent)/100;
       */ var list = [];
      invoiceList.forEach((prop) => {
        if (prop.isActive === 1) {
          list.push({
            billId: prop.billId,
            amount: prop.amount,
            receiptNo: prop.receiptNo,
            invoiceNo: prop.invoiceNo,
            createdBy: getCookie(cons.USERID),
          });
        }
      });
      var data = {
        ledgerId: ledger,
        //   "billPassid":billNo>0?billNo:0,
        transactionType: "P",
        fundsIn: "",
        fundsOut: totalAmount,
        bankId: account,
        transactionDate: this.dateChanged(date1),
        tdsAmount: 0,
        tdsReason: "",
        /*  "taxId":taxId,
                "cgstPercentage":cgstPercent,
                "sgstPercentage":sgstPercent,
                "igstPercentage":igstPercent,
                "cgstAmount":parseFloat(cgstAmount.toFixed(2)),
                "sgstAmount":parseFloat(sgstAmount.toFixed(2)),
                "igstAmount":parseFloat(igstAmount.toFixed(2)),*/
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        paymentSource: paymentSource,
        bankRefnumber: bankRefnumber,
        description: description,
        acYear: year,
        tranStatus: 1,
        advanceId: advanceId,
        branchId: branchId,
        tranDetails: list,
      };

      const validateFields = [
        { field: this.state.branchId, msg: "Branch", type: 2 },
        { field: this.state.date1, msg: "Date", type: 2 },
        { field: this.state.account, msg: "Account", type: 2 },
        { field: this.state.paymentSource, msg: "Payment Source", type: 2 },
        { field: this.state.invoiceList, msg: "One LineItem", type: 2 },
      ];

      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!`);
        }
      } else {
        // this.basicAlert("Created Successfully!!");
        if (list.length > 0) {
          //  if(amount<=billAmount){
          this.handleLoading();
          requestList(
            serverApi.ACCOUNT_TRANSACTIONS_ADD,
            data,
            this.handleAddIndent
          );
          /* }else {
                            this.basicAlert("Amount should not greater than due amount "+billAmount);
                        }*/
        } else if (ledger > 0) {
          this.handleLoading();
          requestList(
            serverApi.ACCOUNT_TRANSACTIONS_ADD,
            data,
            this.handleAddIndent
          );
        } else {
          this.basicAlert("Please Select Bill No or Ledger..!!");
        }
      }

      // if (
      //   date1 !== "" &&
      //   paymentSource !== "" &&
      //   account > 0 &&
      //   branchId !== "" &&
      //   branchId !== 0
      // ) {
      //   if (list.length > 0) {
      //     //  if(amount<=billAmount){
      //     this.handleLoading();
      //     requestList(
      //       serverApi.ACCOUNT_TRANSACTIONS_ADD,
      //       data,
      //       this.handleAddIndent
      //     );
      //     /* }else {
      //                   this.basicAlert("Amount should not greater than due amount "+billAmount);
      //               }*/
      //   } else if (ledger > 0) {
      //     this.handleLoading();
      //     requestList(
      //       serverApi.ACCOUNT_TRANSACTIONS_ADD,
      //       data,
      //       this.handleAddIndent
      //     );
      //   } else {
      //     this.basicAlert("Please Select Bill No or Ledger..!!");
      //   }
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.deleteBill) {
        this.getReportsTable();
      }
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    if (this.state.deleteBill) {
      this.setState({ billId: "", deleteBill: false });
    } else this.handleLoading();
  };
  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteBill) {
      //this.handleLoading();
      getDataFromUrl(
        serverApi.ACCOUNT_TRANSACTION_DELETE +
          this.state.billId +
          "/" +
          this.props.state.companyInfo.id,
        this.handleAddIndent
      );
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  data.splice(this.state.id, 1);
      // data[this.state.id].isActive=0;

      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        } else {
          if (o.isActive === 1) {
            sumOfamt = sumOfamt + parseFloat(o.amount);
          }
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        totalAmount: sumOfamt.toFixed(2),
      });
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  getQualityName = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^^");
    this.setState({
      selectedQualityName: values[0],
      stock: values[1],
    });
    console.log("stock " + this.state.stock);
  };

  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0;
    var data = this.state.invoiceList;
    // console.log("itemTaxAmt",prop.itemTaxAmt);
    if (this.state.amount !== "") {
      if (this.state.sgstPercent !== undefined)
        totSgst =
          totSgst +
          (parseFloat(this.state.amount) * parseFloat(this.state.sgstPercent)) /
            100;
      if (this.state.igstPercent !== undefined)
        totIgst =
          totIgst +
          (parseFloat(this.state.amount) * parseFloat(this.state.igstPercent)) /
            100;
      if (this.state.cgstPercent !== undefined)
        totcgst =
          totcgst +
          (parseFloat(this.state.amount) * parseFloat(this.state.cgstPercent)) /
            100;
    }
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    }

    return parseFloat(totalTax.toFixed(2));
  }
  handleSelectedUnitValue = (defValue) => {
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    // data.map((prop,i)=>{
    var SlectedTaxType = defValue.value;
    if (SlectedTaxType === 1) {
      this.setState({
        igstPercent: this.state.taxPercentage,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    } else if (SlectedTaxType === 2) {
      var taxPercent = this.state.taxPercentage / 2;
      this.setState({
        igstPercent: 0,
        sgstPercent: taxPercent,
        cgstPercent: taxPercent,
      });
    } else {
      this.setState({
        igstPercent: 0,
        sgstPercent: 0,
        cgstPercent: 0,
      });
    }
    // });
    this.setState({
      invoiceList: data,
      taxType: defValue,
    });
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.state.amount;
    if (taxPercent !== "" && itemAmount !== "") {
      var itemtaxAmount =
        (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
      var total = parseFloat(itemAmount) + itemtaxAmount;
      this.setState({
        itemTaxAmt: itemtaxAmount,
        taxPercentage: taxPercent,
        netTotal: parseFloat(total).toFixed(2),
      });
    }
    this.setState({
      taxPercentage: taxPercent,
    });
    if (this.state.taxType !== null && this.state.taxType !== "") {
      var SlectedTaxType = this.state.taxType.value;
      //alert(SlectedTaxType);
      if (SlectedTaxType === 1) {
        this.setState({
          igstPercent: taxPercent,
          sgstPercent: 0,
          cgstPercent: 0,
        });
      } else {
        taxPercent = taxPercent / 2;

        this.setState({
          igstPercent: 0,
          sgstPercent: taxPercent,
          cgstPercent: taxPercent,
        });
      }
    }
  };
  onDeleteBill = (id) => {
    this.setState({ deleteBill: true, billId: id });
    this.basicItemAlert("Do You Like To Delete This Bill?");
  };
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      billPassStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onUpdateButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onUpdateButtonClick(billPassStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        22,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handleAddItem = () => {
    this.setState({
      loading: true,
      alert: true,
      updateItem: false,
    });
  };

  getDataTable = () => {
    console.log("updateClick", this.state.invoiceList);
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.forEach((prop, key) => {
        console.log("prop", prop.isActive);
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to remove the data row */}
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;

                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        this.basicItemAlert("Do You Like To Update This Item?");
                        this.setState({
                          //alert: true,
                          updateItem: true,
                          updateItemAlert: true,
                          id: o.id,
                          trnDetailId: o.trnDetailId,
                          billNo: o.billId,
                          amount: o.amount,
                          receiptNo: o.receiptNo,
                          invoiceNo: o.invoiceNo,
                          isActive: 1,
                        });

                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.invoiceList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };
  onClickAddItem() {
    const {
      billNo,
      amount,
      description,
      receiptNo,
      invoiceList,
      trnDetailId,
      updateView,
      invoiceNo,
    } = this.state;
    var arr = invoiceList;
    var itemIndex = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
    }
    var addItemObj = {
      id: itemIndex,
      trnDetailId: trnDetailId,
      billId: billNo,
      amount: amount,
      receiptNo: receiptNo,
      isActive: 1,
      invoiceNo: invoiceNo,
    };

    // new code for validation...
    const validateFields = [{ field: amount, msg: "Amount", type: 1 }];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message}...!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message}...!!`);
      }
    } else {
      var val;

      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else arr.splice(arr.length, 0, addItemObj);

      var totalAmount = 0;
      var discription = "Against Bill Numbers ";
      arr.forEach((prop, i) => {
        totalAmount = totalAmount + parseFloat(prop.amount);
        if (prop.invoiceNo === "") {
          val = "undefined";
        } else {
          val = prop.invoiceNo;
        }
        if (i === 0) discription = discription + val;
        else discription = discription + "," + val;
      });
      if (!updateView) {
        this.setState({ description: discription });
      }
      this.setState({
        loading: true,
        invoiceList: arr,
        updateItem: false,
        totalAmount: totalAmount.toFixed(2),
      });
      this.hideAlert();
    }

    // old code for validation...

    // if (amount !== "") {
    //   var val;

    //   if (this.state.updateItem) {
    //     arr[itemIndex] = addItemObj;
    //   } else arr.splice(arr.length, 0, addItemObj);

    //   var totalAmount = 0;
    //   var discription = "Against Bill Numbers ";
    //   arr.forEach((prop, i) => {
    //     totalAmount = totalAmount + parseFloat(prop.amount);
    //     if (prop.invoiceNo === "") {
    //       val = "undefined";
    //     } else {
    //       val = prop.invoiceNo;
    //     }
    //     if (i === 0) discription = discription + val;
    //     else discription = discription + "," + val;
    //   });
    //   if (!updateView) {
    //     this.setState({ description: discription });
    //   }
    //   this.setState({
    //     loading: true,
    //     invoiceList: arr,
    //     updateItem: false,
    //     totalAmount: totalAmount.toFixed(2),
    //   });
    //   this.hideAlert();
    // } else {
    //   this.basicAlert("Please Enter All Mandatory Fieldssssss..!!");
    // }
  }

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;
    //console.log("jute type",selectedJuteType);
    //add item button click
    const ucoptions = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 1, label: "INTRA STATE", name: "INTRA STATE" },
      { value: 2, label: "INTER STATE", name: "INTER STATE" },
    ];
    var billList = this.state.billsList;
    if (
      this.state.billPassStatus === 3 &&
      this.state.billNo <= 0 &&
      billList.length > 0
    ) {
      var list = [];
      billList.forEach((prop) => {
        if (prop.ledgerId === this.state.ledger) {
          list.push(prop);
        }
      });
      billList = list;
    }

    const inputFocus = {
      autoComplete: "off",
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var columns = [
      {
        Header: "Bill No",
        accessor: "invoiceNo",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },
      // {
      //     Header: "Receipt No",
      //     accessor: "receiptNo",
      //     minWidth: 200,
      //     style: {color: "#000", textAlign: "left"}

      // },
      {
        Header: "Amount",
        accessor: "amount",
        minWidth: 200,
        style: { color: "#000", textAlign: "left" },
      },

      {
        Header: "Actions",
        minWidth: 100,
        accessor: "actions",
        style: { color: "#000", textAlign: "center" },
        sortable: false,
        filterable: false,
      },
    ];

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {this.state.basicAlert1}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            {this.state.advanceId !== "" &&
                            this.state.advanceId !== null ? (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Advance No
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <TextField
                                      disabled={true}
                                      id="advanceId"
                                      value={this.state.advanceId}
                                      className={classes.textField}
                                      onChange={this.onChangeValues}
                                      fullWidth={true}
                                      variant="outlined"
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : (
                              <ItemGrid
                                xs={12}
                                md={6}
                                style={{ marginTop: "10px" }}
                              >
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Bill No
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={
                                        this.state.showupdateBillNo
                                      }
                                      id={"billNo"}
                                      defValue={this.state.billNo}
                                      onSelect={this.onSelect}
                                      isRefresh={this.state.loadBills}
                                      setIsRefresh={(val) => {
                                        this.setState({ loadBills: false });
                                      }}
                                      getName={(name) => {
                                        var val = name.split("_");
                                        // if(!this.state.updateView&&this.state.billNo+""!==val[0]){
                                        //     if(val[3]!==undefined)
                                        //         this.setState({
                                        //             description:"Against Bill No: "+val[3]
                                        //         });
                                        //     else
                                        //         this.setState({description:""})

                                        // }
                                        if (val[4] !== "null")
                                          var amount =
                                            parseFloat(val[2]) -
                                            parseFloat(val[4]);
                                        else
                                          var amount = parseFloat(
                                            val[2]
                                          ).toFixed(2);

                                        if (!this.state.updateView)
                                          this.setState({
                                            // ledger: parseInt(val[1]),
                                            billAmount:
                                              val[2] !== undefined
                                                ? amount
                                                : "",
                                            amount:
                                              val[2] !== undefined
                                                ? amount
                                                : "",
                                            receiptNo:
                                              val[5] !== undefined
                                                ? val[5]
                                                : "",
                                            invoiceNo:
                                              val[3] !== undefined
                                                ? val[3]
                                                : "",
                                            /// description:"Bill against "+this.state.billNo
                                          });
                                        else {
                                          amount =
                                            amount +
                                            parseFloat(this.state.amount);
                                          this.setState({
                                            billAmount:
                                              val[2] !== undefined
                                                ? amount
                                                : "",
                                          });
                                        }
                                      }}
                                      stateKey="billNo"
                                      staticData={billList}
                                      //  url={serverApi.ACCOUNT_APPROVED_BILLS+this.props.state.companyInfo.id}
                                      value={this.state.billNo}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            )}

                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Amount{" "}
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  sm={8}
                                  style={{ textAlign: "right" }}
                                >
                                  <TextField
                                    disabled={this.state.billPassStatus === 3}
                                    id="amount"
                                    className={classes.textField}
                                    value={this.state.amount}
                                    onChange={this.onChangeValues}
                                    fullWidth={true}
                                    iType="number"
                                    InputProps={inputFocus}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {labelType === "2" ? "Project" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          //  selectDisabled={this.state.updateView}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH +
                            this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                              disabled: this.state.billPassStatus === 3,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ledger
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={
                            (this.state.billNo !== "" &&
                              this.state.billNo !== null &&
                              this.state.billNo !== 0) ||
                            this.state.billPassStatus === 3
                          }
                          id={"ledger"}
                          defValue={this.state.ledger}
                          onSelect={this.onSelect}
                          isRefresh={this.state.reloadLedger}
                          setIsRefresh={(val) => {
                            this.setState({ reloadLedger: val });
                          }}
                          stateKey="ledger"
                          staticData={this.state.ledgerOptions}
                          // url={serverApi.SELECT_PARENT_LEDGER_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                          value={this.state.ledger}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Account
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.billPassStatus === 3}
                          id={"account"}
                          defValue={this.state.account}
                          onSelect={this.onSelect}
                          stateKey="account"
                          staticData={this.state.accountOptions}
                          isRefresh={this.state.reloadAccounts}
                          setIsRefresh={(val) => {
                            this.setState({ reloadAccounts: val });
                          }}
                          // url={serverApi.SELECT_PARENT_LEDGER_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                          value={this.state.account}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Payment Source
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.billPassStatus === 3}
                          id={"paymentSource"}
                          defValue={this.state.paymentSource}
                          onSelect={this.onSelect}
                          stateKey="paymentSource"
                          staticData={PaymentSource}
                          // url={serverApi.SELECT_PARENT_LEDGER_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                          value={this.state.paymentSource}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Cheque/Utr No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8} style={{ textAlign: "right" }}>
                        <TextField
                          // disabled={true}
                          id="bankRefnumber"
                          className={classes.textField}
                          value={this.state.bankRefnumber}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12}>
                    <LineItemTable
                      data={this.getDataTable()}
                      loading={this.state.loading}
                      columns={columns}
                    />
                  </ItemGrid>
                  <ItemGrid xs={12}>
                    <Button
                      color={"info"}
                      right={true}
                      onClick={this.handleAddItem}
                    >
                      Add Item
                    </Button>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Description
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="description"
                          value={this.state.description}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          multiline={true}
                          height={50}
                          rowsMax={2}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Total Amount{" "}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8} style={{ textAlign: "right" }}>
                        <TextField
                          disabled={this.state.billPassStatus === 3}
                          id="totalAmount"
                          className={classes.textField}
                          value={this.state.totalAmount}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          iType="number"
                          InputProps={{
                            autoComplete: "off",
                            textAlign: "right",
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.setState({ isApprove: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Approve..!!"
                              );
                            }}
                          >
                            Approve
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.approveStatus ? (
                        <ItemGrid>
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({ isReject: true });
                              this.InvoicebasicAlert(
                                "Do You Like To Reject..!!"
                              );
                            }}
                          >
                            Reject
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {console.log(
                        "getting the data here to check",
                        this.state.approveStatus,
                        this.state.billPassStatus
                      )}
                      {/* {(this.state.approveStatus &&
                        this.state.billPassStatus !== 3) ||
                      this.state.billPassStatus === "1" ? ( */}
                      {(this.state.updateView &&
                        this.state.billPassStatus !== 3) ||
                      this.state.billPassStatus === "1" ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.showTransactionUpdate &&
                      this.state.billPassStatus === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            Transaction Update
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.tallySync === "N" &&
                      this.state.billPassStatus === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("", true);
                            }}
                          >
                            Post To Tally
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {this.state.tallySync === "Y" &&
                      this.state.billPassStatus === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("", true);
                            }}
                          >
                            Update To Tally
                          </Button>
                        </ItemGrid>
                      ) : null}

                      {!this.state.showupdateBillNo &&
                      this.state.billPassStatus === 3 ? (
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={() => {
                              this.onUpdateButtonClick("");
                            }}
                          >
                            Update Bill No
                          </Button>
                        </ItemGrid>
                      ) : null}
                      {!this.state.updateView ? (
                        <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                          <GridContainer justify="center">
                            <ItemGrid>
                              <Button
                                color="primary"
                                onClick={this.onCreateButtonClick}
                              >
                                Create
                              </Button>
                            </ItemGrid>
                            <ItemGrid>
                              <Button
                                color="danger"
                                onClick={this.handleCreate}
                              >
                                Cancel
                              </Button>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      ) : null}

                      {this.state.updateView &&
                      this.state.billPassStatus === 3 ? (
                        <ItemGrid xs={12} style={{ marginTop: 25 }}>
                          <SamplePDF
                            invoiceType={4}
                            printResp={this.state.printResp}
                          />
                        </ItemGrid>
                      ) : null}
                    </GridContainer>
                  </ItemGrid>
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      {this.state.basicAlert}
                      {this.state.showMessagealert}
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_JUTE_STATUS}
                                value={this.state.status}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            // round color="white"
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>

                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        //  url={serverApi.ACCOUNT_BILLS_TABLE}
                        //searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        staticData={this.state.tableList}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={getCookie(cons.COMPANY_NAME) + "_PAYMENTS"}
                        showDelete={true}
                        onDeleteClick={this.onDeleteBill}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(Payments)
);
