import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";

class WeavingQualityMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "qualityId",
      approveStatus: false,
      qualityId: null,
      qualityCode: null,
      qualityName: null,
      width: null,
      ports: null,
      shots: null,
      /*"lrs":32.5,
            "leadLength":117,
            "finishedLength":113,*/
      ends: null,
      speed: null,
      ozsYds: null,
      /* "stdOzsYds":10.582,
             "actualShots":6.79,*/
      mcTeeth: null,
      finishedLength: 0,
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    if (this.state.isRefresh) {
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";

    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var data = {
        qualityId: id,
        cipher: getCookie(cons.CIPHER),
      };
      requestList(
        serverApi.WEAVING_QUALITY_MASTER_DETAILS_BY_ID,
        data,
        this.handleViewClickResp
      );
    }
  };

  handleViewClickResp = (resp) => {
    var o = resp.data;

    if (o !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        ledgerId: o.id,
        qualityId: o.qualityId,
        qualityCode: o.qualityCode,
        qualityName: o.qualityName,
        width: o.width,
        ports: o.ports,
        shots: o.shots,
        /*"lrs":32.5,
                "leadLength":117,
                "finishedLength":113,*/
        ends: o.ends,
        speed: o.speed,
        ozsYds: o.ozsYds,
        /* "stdOzsYds":10.582,
                 "actualShots":6.79,*/
        mcTeeth: o.mcTeeth,
        createdBy: o.createdBy,
        createdOn: o.createDateTime,
        finishedLength: o.finishedLength,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };
  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      qualityType: 1,
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onChangeNumberValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onChangeValues = (event, type) => {
    var value = event.target.value;
    //console.log("val "+value);
    if (type === "AN") {
      if (/[^0-9a-zA-Z\s]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "N") {
      if (/[^0-9]/.test(value)) {
        value = this.state[event.target.id];
      } else
        this.setState({
          [event.target.id]: value,
        });
    } else if (type === "D") {
      this.setState({
        [event.target.id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
      designationName: "",
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      data.splice(this.state.id, 1);
      //  if(this.state.approveStatus)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      qualityId: null,
      qualityCode: null,
      qualityName: null,
      width: null,
      ports: null,
      shots: null,
      ends: null,
      speed: null,
      ozsYds: null,
      mcTeeth: null,
      finishedLength: null,
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        qualityId,
        qualityCode,
        qualityName,
        width,
        ports,
        shots,
        ends,
        speed,
        ozsYds,
        mcTeeth,
        finishedLength,
      } = this.state;
      var data = {
        qualityId: qualityId,
        qualityCode: qualityCode,
        qualityName: qualityName,
        width: width,
        ports: ports,
        shots: shots,
        /*"lrs":32.5,
                "leadLength":117,
                "finishedLength":113,*/
        ends: ends,
        speed: speed,
        ozsYds: ozsYds,
        /* "stdOzsYds":10.582,
                 "actualShots":6.79,*/
        mcTeeth: mcTeeth,
        finishedLength: finishedLength,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        qualityType: 1,
      };

      // if (
      //   qualityCode !== null &&
      //   //qualityName !== null &&
      //   ports > 0 &&
      //   shots > 0 &&
      //   ends > 0 &&
      //   speed > 0 &&
      //   ozsYds > 0
      // ) {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.WEAVING_QUALITY_MASTER_UPDATE,
      //     data,
      //     this.handleAddTax
      //   );
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
      const validateFields = [
        { field: qualityCode, msg: "Quality Code", type: 1 },
        { field: qualityName, msg: "Quality Name", type: 1 },
        { field: ports, msg: "Ports", type: 1 },
        { field: shots, msg: "Shots", type: 1 },
        { field: ends, msg: "Ends", type: 1 },
        { field: speed, msg: "Speed", type: 1 },
        { field: ozsYds, msg: "Ounce Per Yard", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.WEAVING_QUALITY_MASTER_UPDATE,
          data,
          this.handleAddTax
        );
      }


    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        qualityCode,
        qualityName,
        width,
        ports,
        shots,
        ends,
        speed,
        ozsYds,
        mcTeeth,
        finishedLength,
        //qualityId,
      } = this.state;
      var data = {
        //qualityId: qualityId,
        qualityCode: qualityCode,
        qualityName: qualityName,
        width: width,
        ports: ports,
        shots: shots,
        /*"lrs":32.5,
                "leadLength":117,
                "finishedLength":113,*/
        ends: ends,
        speed: speed,
        ozsYds: ozsYds,
        /* "stdOzsYds":10.582,
                "actualShots":6.79,*/
        mcTeeth: mcTeeth,
        createdBy: getCookie(cons.USERID),
        companyId: this.props.state.companyInfo.id,
        qualityType: 1,
        finishedLength: finishedLength,
      };
      console.log("state", this.state);

      // if (
      //   qualityCode !== null &&
      //   qualityName !== null &&
      //   ports > 0 &&
      //   shots > 0 &&
      //   ends > 0 &&
      //   speed > 0 &&
      //   ozsYds > 0
      // ) {
      //   this.handleLoading();
        // requestList(
        //   serverApi.WEAVING_QUALITY_MASTER_ADD,
        //   data,
        //   this.handleAddTax
        // );
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }

      const validateFields = [
        { field: qualityCode, msg: "Quality Code", type: 1 },
        { field: qualityName, msg: "Quality Name", type: 1 },
        { field: ports, msg: "Ports", type: 1 },
        { field: shots, msg: "Shots", type: 1 },
        { field: ends, msg: "Ends", type: 1 },
        { field: speed, msg: "Speed", type: 1 },
        { field: ozsYds, msg: "Ounce Per Yard", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      } else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.WEAVING_QUALITY_MASTER_ADD,
          data,
          this.handleAddTax
        );
      }
    }
  };
  handleAddTax = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Quality Id
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            diabled={true}
                            id="qualityId"
                            value={this.state.qualityId}
                            className={classes.textField}
                            onChange={(event) => {
                              this.onChangeValues(event, "AN");
                            }}
                            fullWidth={true}
                            variant="outlined"
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Quality Code
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          diabled={true}
                          id="qualityCode"
                          value={this.state.qualityCode}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "AN");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Quality Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="qualityName"
                          value={this.state.qualityName}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Cloth Width (Inch)
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="width"
                          value={this.state.width}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                    onChange={(event)=>{
                                                        if(!Number.isInteger(Number(event.target.value)))
                                                            this.setState({
                                                                width:Number(event.target.value)
                                                            });
                                                       else if(event.target.value==='')
                                                            this.setState({
                                                                width:0.0
                                                            });

                                                    }}
*/
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ports
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="ports"
                          value={this.state.ports}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                                                                        onChange={(event)=>{
                                                                                                            if(!Number.isInteger(Number(event.target.value)))
                                                                                                                this.setState({
                                                                                                                    ports:Number(event.target.value)
                                                                                                                });
                                                                                                            else if(event.target.value==='')
                                                                                                                this.setState({
                                                                                                                    ports:0.0
                                                                                                                });

                                                                                                        }}
                                                    */
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Shots
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="shots"
                          value={this.state.shots}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          /*
                                                                                                        onChange={(event)=>{
                                                                                                            if(!Number.isInteger(Number(event.target.value)))
                                                                                                                this.setState({
                                                                                                                    shots:Number(event.target.value)
                                                                                                                });
                                                                                                            else if(event.target.value==='')
                                                                                                                this.setState({
                                                                                                                    shots:0.0
                                                                                                                });
                                                                                                        }}
                                                    */
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ends
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="ends"
                          value={this.state.ends}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Speed
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="speed"
                          value={this.state.speed}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Ounce per Yard
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="ozsYds"
                          value={this.state.ozsYds}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "D");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          No Of Teeth
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="mcTeeth"
                          value={this.state.mcTeeth}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Finished Length
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="finishedLength"
                          value={this.state.finishedLength}
                          className={classes.textField}
                          onChange={(event) => {
                            this.onChangeValues(event, "N");
                          }}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created By
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdBy"
                            value={this.state.createdBy}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Created On
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            disabled={true}
                            id="createdOn"
                            value={this.state.createdOn}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            InputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center" />
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        url={serverApi.WEAVING_QUALITY_MASTER}
                        searchData={this.getData()}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        actionType={VIEW}
                        showDelete={false}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(WeavingQualityMaster)
);
