import React from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Search from '@material-ui/icons/Search';
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox";
import {Check} from "@material-ui/icons";

import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";


class AppDataBalesOpened extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            itemViewType:"issueObj",
            date1:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),

            selectedJuteType:'',
            form_QualityId:'',
            spell:'',
            empNo:"",
            bayId:'',
            balesOpened:'',
            totalOpened:0,
            reloadSelect:false,
            stockList:[],
            balesList:[]


        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount() {
        this.getStockList();
    }
    getStockList=()=>{
        getDataFromUrl(serverApi.LIST_OF_ONFLOOR_STOCK+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER),this.handelStock)

    };
    handelStock=(resp)=>{
        var qualityList=[],juteTypes=[];
        console.log("stock",resp);
        resp.map(prop=>{
           var jute={label:prop.juteTypeDesc,value:prop.juteTypeId,name:prop.juteTypeDesc};
            var quality={label:prop.qualityDesc,value:prop.qualityId,name:prop.qualityDesc};
            var hasJute=false,hasQuality=false;
            juteTypes.forEach(prop=>{
                if(prop.value+""===jute.value+""){
                   hasJute=true
                }
            });
            if(!hasJute)
                juteTypes.push(jute);

            qualityList.forEach(prop=>{
                if(prop.value+""===quality.value+""){
                    hasQuality=true
                }
            });

            if(!hasQuality){
                qualityList.push(quality)
            }
        });
        console.log("juteTypes",juteTypes);
        this.setState({qualityList:qualityList,juteTypes:juteTypes,stockList:resp,loadQuality:true,loadJute:true})
    };

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    handleSelectedUnitValues = (defValue) => {
        this.setState(
            {
                formunitConvertion: defValue,
                formunitConvertionId: defValue.value,
                unitConvertionName: defValue.label
            });
        console.log(`Option selected:`, this.state.unitConvertionName);
    };

    handleViewClick = (id) => {
        this.handleLoading();
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverApi.GET_OPENED_BALES_BY_ID+ id, this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            });
            this.getStockList();


        }else {
            this.showMessage(resp.message,resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        if(resp.status){
            var header = resp.data;

            if (header !== null) {
                this.setState({
                    updateView: true,
                    isCreated: true,
                    balesId:header.id,
                    date1:header.openDate,
                    time:moment(header.openTime,'HH:mm:ss').format("hh:mm:ss A"),
                    time2:header.openTime,
                    selectedJuteType:header.jutetype,
                    form_QualityId:header.quality,
                    spell:header.spell,
                    empNo:header.empId,
                    balesOpened:header.openBales,
                    totalOpened:header.totalOenedBales,
                    empName: header.empName,
                    dept: header.department,
                    occupation:header.designation,
                    shippingAddress:header.shippingAddress,
                    loadQuality:true,
                    reloadSelect:true

                });

            }
            this.handleLoading();

        }

    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    handleCreate = () => {
        //alert();
       /* if (!this.state.isCreated) {
            this.hideAlertMessage();
        }*/
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
          this.hideAlertMessage();
    };
    getData = () => {
        if(this.state.fromDate!==""||this.state.todate!==""){
            var data = {
                "fromDate": this.state.fromDate
                , "toDate": this.state.todate,
                "companyId":this.props.state.companyInfo.id,
                "cipher":getCookie(cons.CIPHER)
            };
        }else
        var data = {
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate,
            "cipher":getCookie(cons.CIPHER)
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object'){

            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });

            if(id==='date1'){
                const {empNo,spell} = this.state;
                if(empNo!==''&& spell!==''&& spell!=='0'){
                    var data={
                        "empId":this.state.empNo,
                        "date":dateObj.format('DD-MM-YYYY'),
                        "spell":this.state.spell,
                        "companyId":this.props.state.companyInfo.id,
                        "cipher":getCookie(cons.CIPHER)
                    };
                    requestList(serverApi.GET_TOTAL_BALES_OPENED,data,this.handleTotalBalesOPened)
                }
            }

        }
        else if (id !== 'date1')
            this.setState({
                [id]: ''
            });


        //this.dateChanged();
    };
    dateChanged = () => {
        var myDate = this.state.date1;
        if(myDate!=null){
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }

    };

    timeChangedFormat = (myDate,time) => {
        console.log("time",myDate + time);

        myDate=myDate.split("-");
        var timeParts = time.split(":");
        return new Date(myDate[2], myDate[1]-1, myDate[0], timeParts[0], timeParts[1],timeParts[2]).getTime();
    };

    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return parseFloat(parseFloat((this.state.indentQuantity * 100) / 150)).toFixed(2);
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };


    onChangeValues = (event) => {
        var value = event.target.value;
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if(stateKey==="selectedJuteType"&&selectedValue!==this.state.selectedJuteType){
            var qualityList=[];
            this.state.stockList.map(prop=>{
                var quality={label:prop.qualityDesc,value:prop.qualityId,name:prop.qualityDesc};
                var hasQuality=false;
                qualityList.forEach(prop=>{
                    if(prop.value+""===quality.value+""){
                        hasQuality=true
                    }
                });

                if(!hasQuality){
                    if(prop.juteTypeId===selectedValue)
                    qualityList.push(quality)
                }
            });
            this.setState({qualityList:qualityList,loadQuality:true})

        }
        if((stateKey==="selectedJuteType"&&selectedValue!==this.state.selectedJuteType)||
            (stateKey==="form_QualityId"&&selectedValue!==this.state.form_QualityId)){
            this.setState({balesList:[]})
        }

        if(stateKey==='spell'){
            if(this.state.empNo!==''){
                var data={
                    "empId":this.state.empNo,
                    "date":this.state.date1,
                    "spell":selectedValue,
                    "companyId":this.props.state.companyInfo.id,
                    "cipher":getCookie(cons.CIPHER)
                };
                requestList(serverApi.GET_TOTAL_BALES_OPENED,data,this.handleTotalBalesOPened)
            }

        }
            this.setState({
                [stateKey]: selectedValue
            });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            uom: values[1],

        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        if(this.state.showBales){
            var balesOpened=0;
            this.state.balesList.forEach(prop=>{
                if(prop.noOfBalesOpen>0){
                    balesOpened=balesOpened+parseFloat(prop.noOfBalesOpen)
                }
            });
            this.setState({balesOpened:balesOpened.toFixed(2)})
        };
        this.setState({
            alert: false,
            showMessagealert: false,
            loading: false,
            showBales:false
        });
    }

    hideAlertMessage() {

        this.setState({
            isRefresh:true,
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            loading: false,
           // date1:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
           // selectedJuteType:"",
           // form_QualityId:"",
            //spell:"",
            empNo:'',
           // bayId:'',
            balesOpened:'',
            totalOpened:0,
            trollyNo:0,
            empName:'',
            dept: '',
            occupation:'',
            loadJute:true,
            loadQuality:true,
            reloadSelect:true,
            balesList:[]
        });
        this.getStockList();


    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
       var obj=JSON.parse(id);
       console.log("obj",obj);
        var data={"date":obj.openDate,"spell":obj.spell,"juteTypeId":obj.juteTypeId,"qualityId":obj.qualityId,"ebNo":obj.ebNo+"","bayId":obj.bayId,
            "companyId":this.props.state.companyInfo.id};
        requestList(serverApi.DELETE_BALES_OPENED,data, this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }



    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {date1,balesId,time2,selectedJuteType,form_QualityId,spell,empNo,balesOpened,totalOpened,balesList,bayId,trollyNo}=this.state;
            var data= [];
            balesList.forEach(prop=>{
                var obj={
                    "hrdId":balesId,
                    "openedDateTime":this.timeChangedFormat(date1,time2),
                    "juteTypeId":selectedJuteType,
                    "qualityId":form_QualityId,"spell":spell,"empId":empNo,"openedBales":prop.noOfBalesOpen,
                    "totalOpenedBales":totalOpened,"bayId":bayId,
                    "trollyNo":trollyNo,
                    "createdBy":getCookie(cons.USERID),
                    "companyId":this.props.state.companyInfo.id,"jutePrimId":prop.jutePrimId,"cipher":getCookie(cons.CIPHER)};
                if(prop.isSelected)
                    data.push(obj);
            });
            // if (date1 !== ''  && selectedJuteType !== "" && selectedJuteType !== "0"
            //     &&form_QualityId !== "" && form_QualityId !== "0"
            //     && spell !== '' && spell !== '0'&& balesOpened!==''&&bayId!=="") {
            //     this.handleLoading();
            //     requestList(serverApi.UPDATE_OPENED_BALES, data, this.handleAddIndent)
            // }
            // else {
            //     this.basicAlert("Please Enter All Mandatory Fields..!!");
            // }

            const validateFields = [
                { field: date1, msg: "Date", type: 2 },
                { field: selectedJuteType, msg: "Jute Type", type: 2 },
                { field: form_QualityId, msg: "Quality", type: 2 },
                { field: spell, msg: "Spell", type: 2 },
                // { field: bayId, msg: "Bay No", type: 2 },
                { field: trollyNo, msg: "Trolly No", type: 2 },
                { field: balesOpened, msg: "Bales Opened", type: 1 },
              ];
        
              const validObj = validate(validateFields);
              console.log(validObj);
              if (validObj) {
                if (validObj.type === 1) {
                  this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                } else {
                  this.basicAlert(`Please Select ${validObj.message} ..!!`);
                }
              } else {
                this.handleLoading();
                // this.basicAlert("Created Successfully");
                 requestList(serverApi.UPDATE_OPENED_BALES, data, this.handleAddIndent)
              }



        }
    };




    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {date1,time2,selectedJuteType,form_QualityId,spell,empNo,balesOpened,totalOpened,balesList,bayId,empName,trollyNo}=this.state;
         var data= [];
         balesList.forEach(prop=>{
                var obj={"openedDateTime":this.timeChangedFormat(date1,time2),
                    "juteTypeId":selectedJuteType,
                    "qualityId":form_QualityId,"spell":spell,"ebNo":empNo,"openedBales":prop.noOfBalesOpen,
                    "totalOpenedBales":totalOpened,"bayId":bayId,
                    "trollyNo":trollyNo,
                    "createdBy":getCookie(cons.USERID),
                    "companyId":this.props.state.companyInfo.id,"jutePrimId":prop.jutePrimId,"cipher":getCookie(cons.CIPHER)};
                if(prop.isSelected)
                    data.push(obj);
            });

                // if (date1 !== ''  && selectedJuteType !== "" && selectedJuteType !== "0"
                //     &&form_QualityId !== "" && form_QualityId !== "0"
                //     && spell !== '' && spell !== '0'&& balesOpened>0&&bayId!==""&&trollyNo!==""&&trollyNo!==0) {
                //     this.handleLoading();
                //     requestList(serverApi.ADD_BALES_OPENED, data, this.handleAddIndent)
                // }
                // else {
                //     this.basicAlert("Please Enter All Mandatory Fields..!!");
                // }

                const validateFields = [
                    { field: date1, msg: "Date", type: 2 },
                    { field: selectedJuteType, msg: "Jute Type", type: 2 },
                    { field: form_QualityId, msg: "Quality", type: 2 },
                    { field: spell, msg: "Spell", type: 2 },
                    // { field: bayId, msg: "Bay No", type: 2 },
                    { field: trollyNo, msg: "Trolly No", type: 2 },
                    { field: balesOpened, msg: "Bales Opened", type: 1 },
                  ];
            
                  const validObj = validate(validateFields);
                  console.log(validObj);
                  if (validObj) {
                    if (validObj.type === 1) {
                      this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                    } else {
                      this.basicAlert(`Please Select ${validObj.message} ..!!`);
                    }
                  } else {
                    this.handleLoading();
                    // this.basicAlert("Created Successfully");
                    requestList(serverApi.ADD_BALES_OPENED, data, this.handleAddIndent)
                  }

        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            this.hideAlertMessage();

            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status);
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };



    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlert();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    getQualityName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^^");
        this.setState({
            selectedQualityName: values[0],
            stock: values[1]
        });
        console.log("stock " + this.state.stock);

    };

    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('hh:mm:ss A'),
               time2:dateObj.format('HH:mm:ss')
            });
    };

    getEmpDetails=()=>{
        const {empNo,spell} = this.state;
        if(empNo!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+empNo+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if((empNo==='' &&  spell!==''&& spell!=='0')||empNo===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                empName: header.workerName,
                dept: header.deptIdDesc,
                occupation:header.designationIdDesc
            });
            const {empNo,spell} = this.state;
            if(empNo!==''&& spell!==''&& spell!=='0'){
                var data={
                    "empId":this.state.empNo,
                    "date":this.state.date1,
                    "spell":this.state.spell,
                    "companyId":this.props.state.companyInfo.id,
                    "cipher":getCookie(cons.CIPHER)
                };
                requestList(serverApi.GET_TOTAL_BALES_OPENED,data,this.handleTotalBalesOPened)
            }

        }else {
            this.showMessage(resp.message,false)
        }


    };
    handleTotalBalesOPened = (resp) =>{
        if (resp.status === true) {
            this.setState({
                totalOpened:resp.data
            })
        }else {
            this.setState({
                totalOpened:0
            })

        }

    };
    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }


    render() {

/*
        setInterval(function(){
            if(!this.state.updateView)
                this.setState({
                time: moment().format('hh:mm:ss A'),
                time2:moment().format('HH:mm:ss'),
                })
            }.bind(this), 1000);
*/

        const {classes} = this.props;

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    {this.state.showBales ?
                                        <Popup closePopup={()=>this.hideAlert()}
                                               onClickSave={() => this.hideAlert()}
                                               isShowSave={true}
                                               isShowCancel={true}
                                               save_btn_txt={"ADD"}
                                               cancel_button_text={"Cancel"}
                                               content={
                                                   <form>
                                                       <GridContainer>
                                                           <ItemGrid xs={12} sm={3} style={{marginTop:"10px",textAlign:"center"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Jute Type
                                                               </InputLabel>
                                                           </ItemGrid>

                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"center"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Quality
                                                               </InputLabel>
                                                           </ItemGrid>
                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"center"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Net Weight
                                                               </InputLabel>
                                                           </ItemGrid>

                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"center"}}>
                                                               <InputLabel className={classes.label}>
                                                                   No. Bales
                                                               </InputLabel>
                                                           </ItemGrid>
                                                           <ItemGrid xs={12} md={3} style={{marginTop: "10px",textAlign:"center"}}>
                                                               <InputLabel className={classes.label}>
                                                                   Open Bales
                                                               </InputLabel>
                                                           </ItemGrid>

                                                           <ItemGrid xs={12} style={{maxHeight:200,overflow:"auto"}}>
                                                               {this.state.balesList.map((prop,i)=>{
                                                                   return(
                                                                       <GridContainer>
                                                                           <ItemGrid xs={12} sm={3} style={{marginTop:"10px"}}>
                                                                               <FormControlLabel
                                                                                   control={
                                                                                       <Checkbox
                                                                                           tabIndex={-1}
                                                                                           value={prop.isSelected}
                                                                                           checked={prop.isSelected}
                                                                                           onChange={()=>{
                                                                                               var list=this.state.balesList;
                                                                                               list[i].isSelected=!prop.isSelected;
                                                                                               console.log("balesOpened",prop.openedBales);
                                                                                               if(list[i].isSelected)
                                                                                               list[i].noOfBalesOpen=prop.openedBales;
                                                                                               else
                                                                                                   list[i].noOfBalesOpen="";

                                                                                               this.setState({balesList:list})
                                                                                           }
                                                                                           }
                                                                                        //   onClick={() => this.handleToggle(1)}
                                                                                           checkedIcon={
                                                                                               <Check className={classes.checkedIcon} />
                                                                                           }
                                                                                           icon={<Check className={classes.uncheckedIcon} />}
                                                                                           classes={{
                                                                                               checked: classes.checked
                                                                                           }}
                                                                                           style={{marginLeft:10}}

                                                                                       />
                                                                                   }
                                                                                   classes={{
                                                                                       label: classes.menulabel
                                                                                   }}
                                                                                   label={prop.juteTypeDesc}
                                                                               />

                                                                             {/*  {prop.juteTypeDesc}*/}
                                                                           </ItemGrid>

                                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"center"}}>
                                                                              {prop.qualityDesc}
                                                                           </ItemGrid>
                                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"right"}}>
                                                                               {prop.netWeight}
                                                                           </ItemGrid>

                                                                           <ItemGrid xs={12} md={2} style={{marginTop: "10px",textAlign:"right"}}>
                                                                               {prop.openedBales}
                                                                           </ItemGrid>
                                                                           <ItemGrid xs={12} md={3} style={{marginTop: "10px"}}>
                                                                               <TextField
                                                                                 //  id="openedBales"
                                                                                   className={classes.textField}
                                                                                   onChange={(event)=>{
                                                                                       var list=this.state.balesList;
                                                                                       console.log("bales",list[i].openedBales);
                                                                                       console.log("val",event.target.value);
                                                                                       if(event.target.value==="")
                                                                                           list[i].noOfBalesOpen=event.target.value;

                                                                                       if(event.target.value!==""&&parseFloat(event.target.value)<=parseFloat(prop.openedBales))
                                                                                           list[i].noOfBalesOpen=event.target.value;

                                                                                       this.setState({balesList:list})
                                                                                   }}
                                                                                   value={prop.noOfBalesOpen}
                                                                                   fullWidth={true}
                                                                                   iType={"number"}
                                                                                   variant="outlined"
                                                                               />

                                                                           </ItemGrid>
                                                                       </GridContainer>
                                                                   )
                                                               })}
                                                           </ItemGrid>


                                                       </GridContainer>


                                                   </form>

                                               }

                                        />

                                        : null
                                    }

                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>

                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Date<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Datetime
                                                    defaultValue={new Date()}
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    onChange={(dateObj) => {
                                                        this.timeChanged(dateObj, "date1")
                                                    }}
                                                    formControlProps={
                                                        {height: "10px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "date1",
                                                        value: this.state.date1,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8} >
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        defaultValue={new Date()}
                                                        closeOnSelect={true}
                                                        // defaultValue={this.state.time1}
                                                        dateFormat={false}
                                                        onChange={(timeObj) => {
                                                            this.timeChanged1(timeObj, "time")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id:"time",
                                                            value:this.state.time,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Jute Type<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp id={"selectedJuteType"}
                                                               selectDisabled={this.state.updateView}
                                                               defValue={this.state.selectedJuteType}
                                                               onSelect={this.onSelect}
                                                               isRefresh={this.state.loadJute}
                                                               setIsRefresh={(val)=>{this.setState({loadJute:false})}}
                                                               getName={this.getName}
                                                               stateKey="selectedJuteType"
                                                               staticData={this.state.juteTypes}
                                                              // url={serverApi.GET_ITEMS_BY_GRPID+"999/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                               value={this.state.selectedJuteType}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Quality<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.form_QualityId}
                                                    id={"form_QualityId"}
                                                    getName={this.getQualityDetails}
                                                    isRefresh={this.state.loadQuality}
                                                    setIsRefresh={this.afterRefreshQuality}
                                                  /*  isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}*/
                                                    onSelect={this.onSelect}
                                                    stateKey="form_QualityId"
                                                    staticData={this.state.qualityList}
                                                    //url={serverApi.DATATABLE_SERVICE_URL + this.state.selectedJuteType +"/"+this.props.state.companyInfo.id+ "/getQualityByItem"}
                                                    value={this.state.form_QualityId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Spell<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.spell}
                                                    id={"spell"}
                                                    getName={this.getQualityDetails}
                                                    onSelect={this.onSelect}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    stateKey="spell"
                                                    url={serverApi.GET_ALL_SPELL_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.spell}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Emp No
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="empNo"
                                                    // label="Multiline"
                                                    value={this.state.empNo}
                                                    onChange={this.onChangeValues}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Bay No<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    id={"bayId"}
                                                    selectDisabled={this.state.updateView}
                                                    defValue={this.state.bayId}
                                                    onSelect={this.onSelect}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    //  getName={this.getName}
                                                    stateKey="bayId"
                                                    url={serverApi.GET_FRAME_NUMBERS+this.props.state.companyInfo.id+"/BAY"}
                                                    value={this.state.bayId}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Trolly No<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.trollyNo}
                                                    id={"trollyNo"}
                                                    isReload={this.state.reloadSelect}
                                                    setIsReload={this.afterReloadSelect}
                                                    getName={this.getTrollyDetails}
                                                    onSelect={this.onSelect}
                                                    stateKey="trollyNo"
                                                    url={serverApi.GET_TROLLY_LIST+'1/'+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                    value={this.state.trollyNo}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "15px"}}></ItemGrid>
                                    <ItemGrid xs={12} style={{marginTop: "15px"}}>
                                        <p>Name       : {this.state.empName}</p>
                                        <p>Department : {this.state.dept}</p>
                                        <p>Occupation : {this.state.occupation}</p>
                                              </ItemGrid>

                                    <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Bales Opened<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="balesOpened"
                                                    disabled={true}

                                                    // label="Multiline"
                                                    value={this.state.balesOpened}
                                                    onChange={this.onChangeValues}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>
                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={()=>{
                                                                        if(this.state.selectedJuteType!==""&&this.state.form_QualityId!==""){
                                                                            var balesList=this.state.balesList;
                                                                            if(balesList.length===0){
                                                                                this.state.stockList.forEach(prop=>{
                                                                                    if(this.state.selectedJuteType===prop.juteTypeId&&this.state.form_QualityId===prop.qualityId){
                                                                                        var obj={
                                                                                            juteTypeId: prop.juteTypeId,
                                                                                            juteTypeDesc: prop.juteTypeDesc,
                                                                                            qualityId: prop.qualityId,
                                                                                            qualityDesc: prop.qualityDesc,
                                                                                            openedBales: prop.openedBales,
                                                                                            netWeight: prop.netWeight,
                                                                                            jutePrimId: prop.jutePrimId,
                                                                                            isSelected:false,
                                                                                            noOfBalesOpen:"",
                                                                                        };
                                                                                        balesList.push(obj)
                                                                                    };
                                                                                });
                                                                                this.setState({showBales:true,balesList:balesList})

                                                                            }else {
                                                                                this.setState({showBales:true})

                                                                            }

                                                                        }else {
                                                                            this.basicAlert("Please select jute type and quality..!!")
                                                                        }

                                                                    }}/>
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}

                                                    //className={classes.textField}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Total Opened<span style={{color:"red" }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="totalOpened"
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    value={this.state.totalOpened}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>









                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                {!this.state.updateView ?
                                                    <ItemGrid>
                                                    <Button color="primary" onClick={this.onCreateButtonClick}>Create</Button>
                                                    </ItemGrid>: <ItemGrid>
                                                        <Button color="primary" onClick={this.onUpdateButtonClick}>Update</Button>
                                                    </ItemGrid>
                                                }

                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>

                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>


                                    <ItemGrid xs={10} style={{marginTop: "10px"}}>
                                        <GridContainer>

                                            <ItemGrid xs={12} sm={6}>
                                                {this.state.basicAlert}
                                                {this.state.showMessagealert}
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "fromDate",
                                                                    value: this.state.fromDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "todate")
                                                                }}
                                                                inputProps={{
                                                                    id: "toDate",
                                                                    value: this.state.todate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={2}>
                                        <GridContainer>
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>
                                                <br/>
                                                <br/>
                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>
                                                <br/>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>


                                    <ItemGrid xs={12}>
                                        <TableComponent
                                            url={serverApi.BALES_OPENED_TABLE_LIST}
                                            searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                            isLoading={this.state.isRefresh}
                                            onActionClick={this.handleDeleteClick}
                                            itemViewType={this.state.itemViewType}
                                            actionType={DELETE}
                                            fileName={'BalesOpened'}

                                            // showDelete={true}
                                            // onDeleteClick={this.handleDeleteClick}
                                        />
                                    </ItemGrid>
                                </GridContainer>
                            }/>

                    </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    inputAdornmentIcon: {
        color: "#00ABDC",
        cursor: 'pointer'

    },
    ...customCheckboxRadioSwitch,
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AppDataBalesOpened)
);
