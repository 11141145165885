// ##############################
// // // CustomSelects styles
// #############################

import { roseColor, primaryBoxShadow,defaultFont,primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const customSelectStyle = {
    select: {
        //padding: "12px 0 7px",
        //fontSize: "12px",
        "&:focus": {
            backgroundColor: "transparent",

        },
        "&[aria-owns] + input + svg": {
            transform: "rotate(180deg)"
        },
        "& + input + svg": {
            transition: "all 300ms linear"
        },
        padding: "5px 0 0 5px ",
        fontWeight: "400",
        height: "15px",
        fontSize: "14px",
        lineHeight: "1.428571429",
        color: "#555",
        "&[rows]": {
            height: "auto"
        },
    },
    selectFormControl: {
        "& > div": {
            "&:before": {
                backgroundColor: "#D2D2D2 !important",
               // height: "1px !important"
            },
            "&:after": {
                backgroundColor: primaryColor
            }
        },
    },
    selectDateFormControl: {
        ...defaultFont,
       // backgroundImage:"none",

        border : "1px solid #D2D2D2", borderRadius: "4px",
        borderBottom:"none",
    },
    selectLabel: {
        ...defaultFont,
        color: "#AAAAAA",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.0",
        transition: "0.3s ease all",
        // padding:"0px 0 10px"
        //bottom: "8px"
    },
    outLinedInput:{
        ...defaultFont,
        variant:"outlined",
        height:30, fontWeight: "400", fontSize: "14px",
        lineHeight: "1.0",
        transition: "0.3s ease all"
    },
    selectMenu: {
        "& > div": {
            boxSizing: "borderBox",
            borderRadius: "4px",
            padding: "0",
            minWidth: "100%",
            display: "block",
            border: "0",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
            backgroundClip: "padding-box",
            margin: "2px 0 0",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "transparent"
        },
        "& > div > ul": {
            border: "0",
            padding: "2px 0",
            margin: "0",
            boxShadow: "none",
            minWidth: "100%",
            borderRadius: "4px",
            boxSizing: "border-box",
            display: "block",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "#fff",
            backgroundClip: "padding-box"
        }
    },
    selectMenuItem: {
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "2",
        whiteSpace: "nowrap",
        color: "#333",
        "&:hover": {
            backgroundColor: primaryColor,
            color: "#FFFFFF",
            ...primaryBoxShadow
        }
    },
    selectMenuItemSelected: {
        backgroundColor: primaryColor + "!important",
        color: "#FFFFFF"
    },
    rectangleBoarder:{
        border: '2px solid '+primaryColor,
        borderRadius:4,
    }
};

export default customSelectStyle;
