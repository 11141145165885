import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCompony, setComponyAction, fetchCompanies, setCompaniesAction } from '../../../src/redux/actions/company-action';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
import { simpleBarChart, multipleBarsChart } from "../../variables/charts.jsx";
// react plugin for creating vector maps
//import {VectorMap} from "react-jvectormap";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Card from "material-ui/Card";
import CardHeader from "material-ui/Card/CardHeader";
import Typography from "material-ui/Typography";
//import Tooltip from "material-ui/Tooltip";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard.jsx";
import Table from "../../components/Table/Table.jsx";

import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import ReactEcharts from "../Components/ReactECharts";
import CHARTCONFIG from "../Components/ReactECharts/ChartConfig";
import linetheme, {
  bartheme,
  pietheme,
} from "../Components/ReactECharts/ChartThemes";
import ExpandMoreIcon from "@material-ui/icons/Add";
import IconButton from "../../components/CustomButtons/IconButton";
import classnames from "classnames";
import CardContent from "material-ui/Card/CardContent";
import Collapse from "material-ui/transitions/Collapse";
import CustomButton from "components/CustomButtons/Button.jsx";
import TextField from "../../components/CustomInput/CustomTextField";
import SweetAlert from "react-bootstrap-sweetalert";
import fusioncharts from "fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

// Step 4 - Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Step 5 - Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
// Step 6 - Include the ExcelExport
import ExcelExport from "fusioncharts/features/excelexport";
import { configNames, getConfigValue, serverApi } from "../../helper/Consts";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import { getDataFromUrl, requestList, getReduxState } from "../../server/server";
import moment from "moment";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import Button from "../../components/CustomButtons/Button";
import {
  defaultFont,
  liteBlue,
} from "../../assets/jss/material-dashboard-pro-react";
import mySampleTheme from "../CustumComponents/mySampleTheme";
import { IsMenuAvailable } from "../CustumComponents/PermissionAlert";
import GenericWidgetComponent from "../CustumComponents/GenericWidgetComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from "material-ui/Radio/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import StatsCard from "../../components/Cards/StatsCard";
import TextHeadCard from "../../components/Cards/TextHeadCard";
import MultiSelect from "../CustumComponents/SelectboxMultiple";

// Step 7 - Adding the chart, theme and ExcelExport as dependency to the core fusioncharts

// Resolves charts dependancy
charts(fusioncharts);

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, ExcelExport);

const bar4 = {};
const pie2 = {};
const line2 = {};

bar4.options = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  legend: {
    data: ["Instock", "Ordered", "Shortfall"],
    textStyle: {
      color: CHARTCONFIG.color.text,
    },
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: { show: true, title: "save" },
    },
  },
  // calculable: true,
  xAxis: [
    {
      type: "value",
      axisLabel: {
        textStyle: {
          color: CHARTCONFIG.color.text,
        },
      },
      splitLine: {
        lineStyle: {
          color: CHARTCONFIG.color.splitLine,
        },
      },
    },
  ],
  yAxis: [
    {
      type: "category",
      data: ["Matr 1", "Matr 2", "Matr 3", "Matr 4"],
      axisLabel: {
        textStyle: {
          color: CHARTCONFIG.color.text,
        },
      },
      splitLine: {
        lineStyle: {
          color: CHARTCONFIG.color.splitLine,
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: CHARTCONFIG.color.splitArea,
        },
      },
    },
  ],
  series: [
    {
      name: "Instock",
      type: "bar",
      stack: "Sum",
      fillColor: "green",
      itemStyle: { normal: { label: { show: true, position: "insideRight" } } },
      data: [30, 35, 40, 25],
    },
    {
      name: "Ordered",
      type: "bar",
      stack: "Sum",
      itemStyle: { normal: { label: { show: true, position: "insideRight" } } },
      data: [40, 35, 20, 35],
    },
    {
      name: "Shortfall",
      type: "bar",
      stack: "Sum",
      itemStyle: { normal: { label: { show: true, position: "insideRight" } } },
      data: [30, 30, 40, 40],
    },
  ],
};

var labelTop = {
  normal: {
    label: {
      show: true,
      position: "center",
      formatter: "{b}",
      textStyle: {
        baseline: "bottom",
      },
    },
    labelLine: {
      show: false,
    },
  },
};
var labelFromatter = {
  normal: {
    label: {
      formatter: function(params) {
        return 100 - params.value + "%";
      },
      textStyle: {
        baseline: "top",
      },
    },
  },
};
var labelBottom = {
  normal: {
    color: "#ccc",
    label: {
      show: true,
      position: "center",
    },
    labelLine: {
      show: false,
    },
  },
  emphasis: {
    color: "rgba(0,0,0,0)",
  },
};
var radius = [40, 55];

pie2.options = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  // legend: {
  //    // orient: 'vertical',
  //     x: 'left',
  //     data: ['Spining', 'Beaming', 'Weaving'],
  //     textStyle: {
  //         color: CHARTCONFIG.color.text
  //     }
  // },
  /* tooltip: {
        show: false,
    },*/
  toolbox: {
    show: false,
    feature: {
      saveAsImage: { show: true, title: "save" },
    },
  },
  calculable: true,
  series: [
    {
      type: "pie",
      center: ["15%", "50%"],
      radius: radius,
      x: "10%", // for funnel
      itemStyle: labelFromatter,
      data: [
        { name: "Spining", value: 38, itemStyle: labelTop },
        { name: "other", value: 62, itemStyle: labelBottom },
      ],
    },
    {
      type: "pie",
      center: ["50%", "50%"],
      radius: radius,
      x: "30%", // for funnel
      itemStyle: labelFromatter,
      data: [
        { name: "Beaming", value: 19, itemStyle: labelTop },
        { name: "other", value: 81, itemStyle: labelBottom },
      ],
    },
    {
      type: "pie",
      center: ["80%", "50%"],
      radius: radius,
      x: "50%", // for funnel
      itemStyle: labelFromatter,
      data: [
        { name: "Weaving", value: 43, itemStyle: labelTop },
        { name: "other", value: 57, itemStyle: labelBottom },
      ],
    },
    {
      type: "pie",
      center: ["80%", "50%"],
      radius: radius,
      x: "50%", // for funnel
      itemStyle: labelFromatter,
      data: [
        { name: "Weaving", value: 43, itemStyle: labelTop },
        { name: "other", value: 57, itemStyle: labelBottom },
      ],
    },
  ],
};
line2.options = {
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["Average Rate", "Issue weight", "Issue Value"],
    textStyle: {
      color: CHARTCONFIG.color.text,
    },
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: { show: true, title: "save" },
    },
  },
  calculable: true,
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Nov",
        "Dec",
      ],
      axisLabel: {
        textStyle: {
          color: CHARTCONFIG.color.text,
        },
      },
      splitLine: {
        lineStyle: {
          color: CHARTCONFIG.color.splitLine,
        },
      },
    },
  ],
  yAxis: [
    {
      //   name:'Avg Rate',
      //  data: ['1000', '2000', '3000', '4000','5000','6000','7000','8000','9000','10000','11000'],
      //   scale:true,
      /*  min:0,
            max:10000,*/
      type: "value",
      axisLabel: {
        textStyle: {
          color: CHARTCONFIG.color.text,
        },
      },
      splitLine: {
        lineStyle: {
          color: CHARTCONFIG.color.splitLine,
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: CHARTCONFIG.color.splitArea,
        },
      },
    },
  ],
  series: [
    {
      name: "Average Rate",
      type: "line",
      stack: "Sum",
      data: [1001, 1002, 1100, 1500],
    },
    {
      name: "Issue weight",
      type: "line",
      stack: "Sum",
      data: [2000, 1500, 2200, 3000],
    },
    {
      name: "Issue Value",
      type: "line",
      stack: "Sum",
      data: [4000, 5000, 6000, 8000],
    },
  ],
};

class Dashboard extends React.Component {
  constructor(props) {
    console.log('here it is',props.state);
    super(props);
    this.state = {
      alert: null,
      value: 0,
      expanded: false,
      width_l: 6,
      itemWidth: 12,
      taskTitle: "",
      taskDue: "",
      taskDueHrs: "",
      taskReminder: "",
      taskInvite: "",
      poList: [],
      juteStockList: [],
      totals: [],
      juteIssueList: [],
      juteIssueIdList: [],
      totalIssueVal: [],
      totalIssueValue: 0,
      totalIssueWeight: 0,
      weightData: null,
      valueData: [],
      rateData: [],
      jutePieList: [],
      reportsData: [],
      reportDate: moment()
        .subtract(1, "d")
        .format("DD-MM-YYYY"),
      issueDate: moment()
        .subtract(1, "d")
        .format("DD-MM-YYYY"),
      companyList: [],
      companyNamesList: [getCookie(cons.COMPANY_NAME)],
      fromDate: moment()
        .days(-10)
        .format("DD-MM-YYYY"),
      toDate: moment().format("DD-MM-YYYY"),
      companyId: [parseInt(this.props.state.companyInfo.id)],
      branchId: [],
      projectId: [],
      type: [],
      indentValue: "",
      DueXaxis: [],
      indentYAxis: [],
      orderYaxis: [],
      invoiceYaxis: [],
      paymentYaxis: [],
      expiryYaxis: [],
      dueYaxis: [],
      stockYaxis: [],
      consumptioYAxis: [],
      totalindent: 0,
      PendingPO: 0,
      totalCanelled: 0,
      totaPoVal: 0,
      totalCancelPo: 0,
      totalPendingDel: 0,
      totaOpening: 0,
      totalReceived: 0,
      totalClosing: 0,
      totalBill: 0,
      totalPaid: 0,
      totalDue: 0,
      totalConsumed: 0,
      totalTransfered: 0,
      totalExpired: 0,
      requestedXAxis: [],
      requestedQuantityYAxis: [],
      requestedValueYAxis: [],
      requestedTypeList: [
        { label: "Quantity", value: 1 },
        { label: "Value", value: 2 },
      ],
      requestedType: 1,
      indentTypesList: [],
      branchList: [],
      itemGroupList: [],
      itemgroup: [],
      itemsList: [],
      item: [],
    };

    this.onChangeValues = this.onChangeValues.bind(this);
  }
  componentDidMount() {
    var data = {
      purchaseType: "J",
      frmDt: "",
      toDt: "",
      mukam: "",
      statusId: "",
      brokerId: "",
      supplierId: "",
      poNum: "",
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    var url = serverApi.DATATABLE_SERVICE_URL + "getAllPOList";
    this.handleLoading();
    requestList(url, data, this.handlePO,this.props.state.companyInfo.id);
    var juteStockUrl = serverApi.JUTE_STOCK_WIDGET;
    var data1 = {
      taskCode: 1023,
      payloadSize: 0,
      data: {},
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(juteStockUrl, data1, this.handleJutestock);
    var jutedata = {
      fromDate: moment()
        .subtract(10, "d")
        .format("DD-MM-YYYY"),
      toDate: moment().format("DD-MM-YYYY"),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getJuteIssueList",
      jutedata,
      this.handleJuteIssue
    );
    this.onGoButtonClick();
    this.onGoButtonClickGraph();
    this.onGoButtonCickJuteReport();
    if (IsMenuAvailable(459) || IsMenuAvailable(460)) {
      getDataFromUrl(serverApi.GET_STORE_INDENT_TYPES, this.handleTypesResp);
      this.getBranches(this.state.companyId);
      var data = {
        companyId: this.props.state.companyInfo.id,
        cipher: getCookie(cons.CIPHER),
      };
      requestList(
        serverApi.COMPANYMASTER_DATATABLE,
        data,
        this.handleCompaniesList
      );
      /*  if(IsMenuAvailable(459))
          this.getDashboard1();*/
      if (IsMenuAvailable(460)) {
        this.getItemGroups();
        //  this.getDashboard2();
      }
    }
  }
  handleTypesResp = (resp) => {
    var indentTypesList = [],
      type = [];
    if (resp) {
      resp.forEach((prop, i) => {
        if (i > 0) indentTypesList.push(prop);
        if (i === 1) type = [prop.value];
      });
    }
    this.setState({
      indentTypesList: indentTypesList,
      loadTypes: true,
      type: type,
    });
  };
  handleCompaniesList = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          label: prop.companyCode + "-" + prop.companyName,
          value: prop.companyId,
          name: prop.companyName,
        };
        list.push(obj);
      });
    }
    this.setState({ companyList: list, loadCompany: true });
  };

  getDashboard1 = () => {
    const {
      fromDate,
      toDate,
      companyId,
      branchId,
      projectId,
      type,
    } = this.state;
    var data = {
      taskCode: 1029,
      payloadSize: 6,
      data: {
        fromDate: fromDate,
        toDate: toDate,
        companyId: companyId,
        branchId: branchId,
        projectId: projectId,
        type: type,
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
    };
    if (!this.state.isLoading) this.handleLoading();

    requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handleDashboard1);
  };
  getDashboard2 = () => {
    const {
      fromDate,
      toDate,
      companyId,
      branchId,
      projectId,
      type,
    } = this.state;
    var data = {
      taskCode: 1030,
      payloadSize: 9,
      data: {
        fromDate: fromDate,
        toDate: toDate,
        type: [type],
        itemgroup: [],
        item: [],
        companyId: companyId,
        branchId: [],
        projectId: [],
        finYear: getCookie(cons.ACADEMIC_YEAR),
      },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
    };
    if (!this.state.isLoading) this.handleLoading();
    requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handleDashboard2);
  };
  getBranches = (list) => {
    var data = {
      companies: list,
    };
    requestList(
      serverApi.GET_BRANCHES_FOR_MULTIPLE_COMPANIES,
      data,
      this.handleBranches
    );
  };
  getItemGroups = () => {
    getDataFromUrl(
      serverApi.SEARCH_ITEM_GROUPS +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleItemGroups
    );
  };
  handleItemGroups = (resp) => {
    var list = [],
      itemGrp = [];
    if (resp) {
      resp.forEach((prop, i) => {
        if (i > 0) list.push(prop);
        if (i === 1) itemGrp.push(prop.value);
      });
    }
    if (this.state.itemgroup.length === 0) this.getItems(itemGrp);

    this.setState({
      itemGroupList: list,
      itemgroup: itemGrp,
      loadItemGroup: true,
    });
  };
  getProjects = (list) => {
    var data = {
      companies: this.state.companyId,
      branches: list,
    };
    requestList(
      serverApi.GET_PROJECTS_FOR_MULTIPLE_BRANCHES,
      data,
      this.handleProjects
    );
  };

  handleProjects = (resp) => {
    console.log("respproject", resp);

    var list = [],
      project = [];
    if (resp) {
      resp.forEach((prop, i) => {
        if (i > 0) list.push(prop);
        if (i === 1) project.push(prop.value);
      });
    }
    this.setState({ projectList: list, projectId: project, loadProject: true });
  };
  getItems = (list) => {
    var data = {
      companies: this.state.companyId,
      groupCodes: list,
    };
    requestList(
      serverApi.GET_ITEMS_MULTIPLE_ITEM_GROUPS,
      data,
      this.handlItems
    );
  };

  handlItems = (resp) => {
    var list = [],
      project = [];
    if (resp) {
      resp.forEach((prop, i) => {
        if (i > 0) list.push(prop);
        if (i === 1) project.push(prop.value);
      });
    }
    this.setState({ itemsList: list, item: project, loadItem: true });
  };
  handleBranches = (resp) => {
    var list = [],
      branch = [];
    if (resp) {
      resp.forEach((prop, i) => {
        if (i > 0) list.push(prop);
        if (i === 1) branch.push(prop.value);
      });
    }
    if (this.state.branchId.length === 0) this.getProjects(branch);

    this.setState({ branchList: list, branchId: branch, loadBranch: true });
  };
  handleDashboard2 = (resp) => {
    console.log("dashboard2", JSON.stringify(resp));
    var requestedXAxis = [],
      requestedQuantityYAxis = [],
      requestedValueYAxis = [];
    if (resp.data) {
      this.state.companyId.forEach((prop) => {
        var list = [],
          valList = [];
        resp.data.forEach((o) => {
          if (!(requestedXAxis.indexOf(o.date) > -1))
            requestedXAxis.push(o.date);
          if (prop + "" === o.companyId) {
            list.push(o.qty);
            valList.push(o.value);
          }
        });
        requestedQuantityYAxis.push(list);
        requestedValueYAxis.push(valList);
      });
      this.setState({
        requestedXAxis: requestedXAxis,
        requestedQuantityYAxis: requestedQuantityYAxis,
        requestedValueYAxis: requestedValueYAxis,
      });
    }
    if (this.state.isLoading) this.handleLoading();
  };

  handleDashboard1 = (resp) => {
    console.log("dashboard1", JSON.stringify(resp));

    var totalindent = 0,
      PendingPO = 0,
      totalCanelled = 0,
      totaPoVal = 0,
      totalCancelPo = 0,
      totalPendingDel = 0,
      totaOpening = 0,
      totalReceived = 0,
      totalClosing = 0,
      totalBill = 0,
      totalPaid = 0,
      totalDue = 0,
      totalConsumed = 0,
      totalTransfered = 0,
      totalExpired = 0,
      grps = [],
      indents = [],
      orderYaxis = [],
      invoiceYaxis = [],
      dueYaxis = [],
      paymentYaxis = [],
      stockYaxis = [],
      consumptioYAxis = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        if (prop.indentValue > 0) totalindent = totalindent + prop.indentValue;
        if (prop.cancelIndent > 0)
          totalCanelled = totalCanelled + prop.cancelIndent;
        if (prop.pendingPo > 0) PendingPO = PendingPO + prop.pendingPo;
        if (prop.poValue > 0) totaPoVal = totaPoVal + prop.poValue;
        if (prop.cancelPo > 0) totalCancelPo = totalCancelPo + prop.cancelPo;
        if (prop.pendingDelivery > 0)
          totalPendingDel = totalPendingDel + prop.pendingDelivery;
        var open = 0,
          close = 0,
          consumption = 0;
        if (prop.srValue > 0) open = prop.srValue;
        if (prop.consumedValue > 0) open = open - prop.consumedValue;
        if (prop.transferValue > 0) {
          open = open - prop.transferValue;
        }
        close = open + open;
        totaOpening = totaOpening + open;
        totalClosing = totalClosing + close;
        if (prop.billValue > 0) totalBill = totalBill + prop.billValue;

        if (prop.srValue > 0) totalReceived = totalReceived + prop.srValue;
        if (prop.paidValue > 0) totalPaid = totalPaid + prop.paidValue;
        if (prop.dueValue > 0) totalDue = totalDue + prop.dueValue;
        if (prop.consumedValue > 0) {
          totalConsumed = totalConsumed + prop.consumedValue;
          consumption = consumption + prop.consumedValue;
        }
        if (prop.transferValue > 0) {
          consumption = consumption + prop.transferValue;
          totalTransfered = totalTransfered + prop.transferValue;
        }

        grps.push(prop.groupDesc);
        indents.push(prop.indentValue > 0 ? prop.indentValue : 0);
        orderYaxis.push(prop.poValue > 0 ? prop.poValue : 0);
        invoiceYaxis.push(prop.billValue > 0 ? prop.billValue : 0);
        dueYaxis.push(prop.dueValue > 0 ? prop.dueValue : 0);
        paymentYaxis.push(prop.paidValue > 0 ? prop.paidValue : 0);
        stockYaxis.push(close);
        consumptioYAxis.push(consumption);
      });
      this.setState({
        DueXaxis: grps,
        indentYAxis: indents,
        orderYaxis: orderYaxis,
        dueYaxis: dueYaxis,
        paymentYaxis: paymentYaxis,
        invoiceYaxis: invoiceYaxis,
        totalindent: totalindent,
        PendingPO: PendingPO,
        totalCanelled: totalCanelled,
        totaPoVal: totaPoVal,
        totalCancelPo: totalCancelPo,
        totalPendingDel: totalPendingDel,
        totaOpening: totaOpening,
        totalReceived: totalReceived,
        totalClosing: totalClosing,
        totalBill: totalBill,
        totalPaid: totalPaid,
        totalDue: totalDue,
        totalConsumed: totalConsumed,
        totalTransfered: totalTransfered,
        totalExpired: totalExpired,
        stockYaxis: stockYaxis,
        consumptioYAxis: consumptioYAxis,
      });
    }
    if (this.state.isLoading) this.handleLoading();

    // console.log("dashboard1",JSON.stringify(resp));
  };

  onGoButtonCickJuteReport = () => {
    var data = {
      taskCode: 1024,
      payloadSize: 1,
      data: { date: this.state.reportDate },
      cipher: getCookie(cons.CIPHER),
      userId: getCookie(cons.USERID),
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.REPORTS_JUTE_WITH_VALUE, data, this.handleJuteReport);
  };
  //reports response
  handleJuteReport = (resp) => {
    console.log("response", JSON.stringify(resp));
    var data = resp.data,
      yarnList = [],
      reportList = [];
    if (data !== null) {
      data.forEach((prop, key) => {
        if (!(yarnList.indexOf(prop.yarnType) > -1)) {
          yarnList.push(prop.yarnType);
        }
      });
      yarnList.forEach((prop, k) => {
        var weight = 0,
          yarnType,
          planName,
          tableData = [];
        data.find((o) => {
          if (o.yarnType == prop) {
            var quality = "Group-" + o.category;
            yarnType = o.yarnType;
            planName = o.planName;
            o.qualities.forEach((p) => {
              if (quality === "") quality = p;
              else quality = quality + "/" + p;
            });
            var tableList = [
              quality,
              o.percentage,
              o.desiredIssue,
              o.actualIssue,
              o.deviation,
              o.deviationPercentage,
            ];
            weight = weight + parseFloat(o.actualIssue);

            tableData.push(tableList);
          }
        });
        var obj = {
          yarnType: yarnType,
          planName: planName,
          weight: weight.toFixed(2),
          tableData: tableData,
        };
        reportList.push(obj);
      });
      this.setState({
        reportsData: reportList,
      });
    }
  };
  handleJuteIssueByIdGraph = (resp) => {
    console.log("resp", resp);
    if (resp !== null) {
      var poList = [],
        yarnList = [],
        qualityList = [];
      resp.forEach((prop, key) => {
        if (!(qualityList.indexOf(prop.qualityDesc) > -1)) {
          qualityList.push(prop.qualityDesc);
        }
      });
      resp.forEach((prop, key) => {
        if (!(yarnList.indexOf(prop.yarnId) > -1)) {
          yarnList.push(prop.yarnId);
        }
      });

      console.log("yarn types", JSON.stringify(yarnList));
      yarnList.forEach((prop, key) => {
        var yarnName = "";
        var weight = 0,
          arr = [];

        resp.find((o, i) => {
          if (prop === o.yarnId) {
            yarnName = o.yarnType;
            weight = weight + o.totalWeight;
            var obj = {
              quality: o.qualityDesc,
              weight: o.totalWeight,
            };
            arr.push(obj);
          }
        });
        var array = [];
        qualityList.forEach((prop, key) => {
          var arr1 = arr.filter((e) => e.quality === prop);
          if (arr1.length > 0) {
            var quality = prop,
              wt = 0;
            arr1.forEach((o, i) => {
              wt = wt + o.weight;
            });
            var obj = {
              quality: quality,
              weight: wt,
            };
            array.push(obj);
          }
        });

        // var array=this.removeDuplicates(arr,"quality");
        var listObj = {
          yarnId: prop,
          yarnName: yarnName,
          totalWeight: weight,
          qualities: array,
        };
        poList.push(listObj);
      });

      this.setState({
        jutePieList: poList,
      });

      //  this.handleLoading();
    }
  };

  handleJuteIssueById = (resp) => {
    console.log("resp", resp);
    if (resp !== null) {
      var poList = [],
        sumOfPL = 0,
        dueDays = "",
        noOfDays = 0,
        totalWeight = 0,
        totalValue = 0;
      resp.map((prop, key) => {
        var arr = [
          prop.yarnType,
          prop.baleLoose,
          prop.itemDesc,
          prop.qualityDesc,
          prop.quantity,
          prop.totalWeight,
          prop.uomCode,
          prop.issueValue,
        ];
        totalWeight = totalWeight + prop.totalWeight;
        totalValue = totalValue + prop.issueValue;
        poList.push(arr);
      });
      totalWeight = totalWeight.toFixed(2);
      totalValue = totalValue.toFixed(2);
      var totarr = ["Total", "  ", " ", " ", " ", totalWeight, " ", totalValue];
      // poList.push(arr1);

      this.setState({
        juteIssueIdList: poList,
        totalIssueVal: totarr,
        totalIssueWeight: totalWeight,
        totalIssueValue: totalValue,
      });
      //  this.handleLoading();
    }
  };
  getTotalBatchCost = () => {
    var issueVal = this.state.totalIssueValue,
      weight = this.state.totalIssueWeight;
    if (issueVal > 0 && weight > 0) {
      var val = parseFloat(issueVal / weight).toFixed(2);
      console.log("val", val);
      return issueVal + "/" + weight + "=" + val;
    } else {
      return 0;
    }
  };

  handleJuteIssue = (resp) => {
    if (resp.data !== null) {
      var poList = [],
        weightData = {},
        weightDataX = [],
        weightDataY = [],
        valueData = {},
        valueDataY = [],
        rateData = {},
        rateDataY = [],
        sumOfPL = 0,
        dueDays = "",
        noOfDays = 0;
      resp.data.map((prop, key) => {
        var arr = [
          prop.issueDate,
          prop.batchCost,
          prop.issueWeight,
          prop.issueValue,
        ];
        weightDataX.push(prop.issueDate);
        weightDataY.push(prop.issueWeight);
        var val = (prop.issueValue / 1000).toFixed(2);
        valueDataY.push(val);
        rateDataY.push(prop.batchCost);
        /*  var weightObj={
                    label: prop.issueDate,
                    value:prop.issueWeight
                };
                var valuetObj={
                    label: prop.issueDate,
                    value:prop.issueValue
                };
                var ratetObj={
                    label: prop.issueDate,
                    value:prop.batchCost
                };

*/
        poList.push(arr);
        // weightData.push(weightObj);
        // valueData.push(valuetObj);
        //rateData.push(ratetObj);
      });
      weightData = {
        xAxis: weightDataX,
        yAxis: weightDataY,
      };
      valueData = {
        xAxis: weightDataX,
        yAxis: valueDataY,
      };
      rateData = {
        xAxis: weightDataX,
        yAxis: rateDataY,
      };

      // poList.push(arr1);

      this.setState({
        juteIssueList: poList,
        weightData: weightData,
        valueData: valueData,
        rateData: rateData,
      });
      /*   console.log("weightData",weightData);
            console.log("valueData",valueData);
            console.log("rateData",rateData);*/
      //  this.handleLoading();
    }
  };
  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };
  handleJutestock = (resp) => {
    console.log("resp", resp);
    if (resp.data !== null) {
      var poList = [],
        sumOfPL = 0,
        dueDays = "",
        noOfDays = 0;
      var totals = resp.data.totals;
      resp.data.stockwidgets.map((prop, key) => {
        var quality = "Group-" + prop.groupName;
        prop.qualities.forEach((o, i) => {
          quality = quality + "/" + o;
        });
        var arr = [quality, prop.bales, prop.drums, prop.weight];
        poList.push(arr);
      });
      var arr1 = ["Total", totals.bales, totals.drums, totals.weight];

      // poList.push(arr1);

      this.setState({
        juteStockList: poList,
        totals: arr1,
      });
      //  this.handleLoading();
    }
  };

  handlePO = (resp) => {
    if (resp.data !== null) {
      var poList = [],
        sumOfPL = 0,
        dueDays = "",
        noOfDays = 0;
      resp.data.map((prop, key) => {
        var penqty = parseFloat(prop.pendingQty);
        if (penqty > 0 && prop.status === "APPROVED") {
          var podate = prop.poDate;
          var new_date = moment(podate, "DD-MM-YYYY").add(15, "days");
          var current_date = moment(new Date());
          var duration = moment.duration(current_date.diff(new_date));
          var days = parseInt(duration.asDays());
          if (days > 0) {
            dueDays = days + " days";
            noOfDays = days;
          } else {
            dueDays = " ";
            noOfDays = 0;
          }
          console.log(days);
          sumOfPL = sumOfPL + penqty;
          var arr = [
            prop.poNo,
            prop.poDate,
            prop.supplierName,
            prop.pendingQty,
            dueDays,
            noOfDays,
          ];
          poList.push(arr);
        }
      });
      poList.sort(function(a, b) {
        return b[5] > a[5] ? 1 : -1;
      });

      var list = [];
      poList.forEach((o, i) => {
        var ob = o;
        delete ob[5];
        list.push(ob);
      });

      this.setState({
        poList: list,
        sumPendingPO: sumOfPL,
      });
      this.handleLoading();
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));

    if (this.state.expanded) {
      this.handleChangeWidth(6);
      this.handleChangeItemWidth(12);
    } else {
      this.handleChangeWidth(12);
      this.handleChangeItemWidth(6);
    }
  };
  handleChangeWidth = (width) => {
    this.setState({ width_l: width });
  };
  handleChangeItemWidth = (width) => {
    this.setState({ itemWidth: width });
  };
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  onChangeValues = (event) => {
    var value = event.target.value;
    this.setState({
      [event.target.id]: value,
    });
  };
  ShowAlert = (msg) => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-10px", fontSize: "10px" }}
          title={msg}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  };
  clearData() {
    this.setState({
      taskTitle: "",
      taskDue: "",
      taskDueHrs: "",
      taskReminder: "",
      taskInvite: "",
    });
  }

  addTask = (e) => {
    var tTitle = this.state.taskTitle;
    var tDue = this.state.taskDue;
    var tHrs = this.state.taskDueHrs;
    var tRemind = this.state.taskReminder;
    var tInvite = this.state.taskInvite;
    if (
      tTitle.length > 0 &&
      tDue.length > 0 &&
      tHrs.length > 0 &&
      tRemind.length > 0 &&
      tInvite.length > 0
    ) {
      e.preventDefault();
      this.ShowAlert("Task Created Successfully..");
      this.clearData();
      //this.handleExpandClick();
    } else {
      // this.setErrorState(true, "Enter All Fields");
      this.ShowAlert("Enter All Fields!!");
    }
  };
  onGoButtonClickGraph = () => {
    var data = {
      looseOrBale: "",
      date: this.state.issueDate,
      yarnType: "",
      cipher: getCookie(cons.CIPHER),
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
    };
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "getJuteIssueById/",
      data,
      this.handleJuteIssueByIdGraph
    );
  };
  onClickShowMore = (path) => {
    this.props.history.push("/" + path);
  };

  onGoButtonClick = () => {
    var data = {
      looseOrBale: "",
      date: this.state.issueDate,
      yarnType: "",
      cipher: getCookie(cons.CIPHER),
      companyId: this.props.state.companyInfo.id,
      userId: getCookie(cons.USERID),
    };
    requestList(
      serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/",
      data,
      this.handleJuteIssueById
    );
  };

  getPieOptions = (prop) => {
    var arr = prop.qualities,
      arr1 = [],
      remainingPercent = 100;
    arr.forEach((o, i) => {
      console.log("weight", o.weight);
      console.log("weight1", prop.totalWeight);

      var val = (
        (parseFloat(o.weight) / parseFloat(prop.totalWeight)) *
        100
      ).toFixed(2);
      console.log("value %", val);
      remainingPercent = remainingPercent - val;

      var obj = { name: o.quality, value: val, itemStyle: labelTop };
      arr1.push(obj);
    });

    if (remainingPercent > 0) {
      arr1.push({
        name: "other",
        value: remainingPercent,
        itemStyle: labelBottom,
      });
    }
    pie2.options = {
      title: {
        text: prop.yarnName,
        textStyle: {
          //  color:'#00ABDC',
          fontWeight: 400,
        },
        padding: [
          20, // up
          0, // right
          0, // down
          30, // left
        ],
      },
      tooltip: {
        trigger: "item",
        right: true,
        formatter: "{b}</br>({d}%)",
      },
      // legend: {
      //    // orient: 'vertical',
      //     x: 'left',
      //     data: ['Spining', 'Beaming', 'Weaving'],
      //     textStyle: {
      //         color: CHARTCONFIG.color.text
      //     }
      // },
      /* tooltip: {
                 show: false,
             },*/
      toolbox: {
        show: false,
        feature: {
          saveAsImage: { show: true, title: "save" },
        },
      },
      calculable: true,
      series: [
        {
          type: "pie",
          center: ["50%", "50%"],
          radius: radius,
          x: "30%", // for funnel
          itemStyle: labelFromatter,
          data: arr1,
        },
      ],
    };
    return pie2.options;
  };
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
  };

  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "companyId") {
      var list = [];
      this.state.companyList.forEach((prop) => {
        selectedValue.forEach((o) => {
          if (o === prop.value) {
            var name = prop.label.split("-");
            list.push(name[0]);
          }
        });
      });

      this.setState({ companyNamesList: list });
      if (selectedValue.length > 0) this.getBranches(selectedValue);
      else this.setState({ branchList: [], branchId: [], loadBranch: true });
    }
    if (stateKey === "branchId") {
      console.log("vvvvv");
      if (selectedValue.length > 0) this.getProjects(selectedValue);
      else this.setState({ projectList: [], projectId: [], loadProject: true });
    }
    if (stateKey === "itemgroup") {
      if (selectedValue.length > 0) this.getItems(selectedValue);
      else this.setState({ itemsList: [], item: [], loadItem: true });
    }

    /*
        if(stateKey==='companyId'){
            this.setState({loadBranch:true})
        }
*/
    /*
        if(stateKey==='branchId'){
            this.setState({loadProject:true})
        }
*/
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  render() {
    var labelOption = {
      show: true,
      formatter: "{c}  {name|{a}}",
      fontSize: 16,
      rich: {
        name: {
          textBorderColor: "#fff",
        },
      },
    };
    var widgets = JSON.parse(getCookie(cons.WIDGETMENUS));
    console.log("widgets", widgets);
    const { classes } = this.props;
    const dataSource = {
      chart: {
        /* caption: "Countries With Most Oil Reserves [2017-18]",
                 subcaption: "In MMbbl = One Million barrels",*/
        xaxisname: "Issue Date",
        yaxisname: "ISSUE WEIGHT IN QTL",
        numbersuffix: " ",
        theme: "fusion",
        paletteColors: "#94BA33",
      },
      data: this.state.weightData,
    };
    const dataSource1 = {
      chart: {
        /* caption: "Countries With Most Oil Reserves [2017-18]",
                 subcaption: "In MMbbl = One Million barrels",*/
        xaxisname: "Issue Date",
        yaxisname: "ISSUE VALUE",
        numbersuffix: " ",
        theme: "fusion",
        paletteColors: "#003F66",
      },
      data: this.state.valueData,
    };
    const dataSource2 = {
      chart: {
        /* caption: "Countries With Most Oil Reserves [2017-18]",
                 subcaption: "In MMbbl = One Million barrels",*/
        xaxisname: "Issue Date",
        yaxisname: "AVG RATE",
        numbersuffix: " ",
        theme: "fusion",
        paletteColors: "#00abdc",
      },
      data: this.state.rateData,
    };
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var yAxisList = [],
      yAxisList2 = [];
    this.state.requestedQuantityYAxis.forEach((prop, i) => {
      var obj = {
        name: this.state.companyNamesList[i],
        type: "line",
        barGap: 0,
        label: labelOption,
        data: prop,
      };
      yAxisList.push(obj);
    });
    this.state.requestedValueYAxis.forEach((prop, i) => {
      var obj = {
        name: this.state.companyNamesList[i],
        type: "line",
        barGap: 0,
        label: labelOption,
        data: prop,
      };
      yAxisList2.push(obj);
    });

    return (
      <div>
        <GridContainer>
          {IsMenuAvailable(459) || IsMenuAvailable(460) ? (
            <ItemGrid xs={12} sm={12} md={12}>
              <GridContainer>
                <ItemGrid xs={11}>
                  <GridContainer>
                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={3}>
                          <InputLabel className={classes.label}>
                            Start Dateing
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              //   closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "fromDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "fromDate",
                                value: this.state.fromDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}

                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={3}>
                          <InputLabel className={classes.label}>
                            End Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <FormControl variant="outlined" fullWidth>
                            <Datetime
                              //   closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "toDate");
                              }}
                              formControlProps={{
                                height: "10px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "toDate",
                                value: this.state.toDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}

                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={3}>
                          <InputLabel className={classes.label}>
                            {labelType === "2" ? "City" : "Company"}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <MultiSelect
                            id={"companyId"}
                            defValue={this.state.companyId}
                            onSelect={this.onSelect}
                            stateKey="companyId"
                            staticData={this.state.companyList}
                            isRefresh={this.state.loadCompany}
                            setIsRefresh={(val) => {
                              this.setState({ loadCompany: false });
                            }}
                          /> */}

                    {/*
                            //  Dont remove............
                                                <SelectboxTemp
                                                    id={"companyId"}
                                                    defValue={this.state.companyId}
                                                    onSelect={this.onSelect}
                                                    stateKey="companyId"
                                                    isRefresh={this.state.loadCompany}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadCompany:false})
                                                    }}
                                                    staticData={this.state.companyList}
                                                />
*/}
                    {/* </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}
                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={3}>
                          <InputLabel className={classes.label}>
                            {labelType === "2" ? "Center" : "Branch"}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <MultiSelect
                            id={"branchId"}
                            defValue={this.state.branchId}
                            onSelect={this.onSelect}
                            stateKey="branchId"
                            staticData={this.state.branchList}
                            isRefresh={this.state.loadBranch}
                            setIsRefresh={(val) => {
                              this.setState({ loadBranch: false });
                            }}
                          /> */}

                    {/*
                          // dont remove..........
                                                <SelectboxTemp
                                                    id={"branchId"}
                                                    defValue={this.state.branchId}
                                                    onSelect={this.onSelect}
                                                    stateKey="branchId"
                                                    url={serverApi.SELECT_BRANCH+this.state.companyId}
                                                    isRefresh={this.state.loadBranch}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadBranch:false})
                                                    }}
                                                />
*/}
                    {/* </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}

                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={3}>
                          <InputLabel className={classes.label}>
                            {labelType === "2" ? "Client" : "Project"}
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <MultiSelect
                            id={"projectId"}
                            defValue={this.state.projectId}
                            onSelect={this.onSelect}
                            stateKey="projectId"
                            staticData={this.state.projectList}
                            isRefresh={this.state.loadProject}
                            setIsRefresh={(val) => {
                              this.setState({ loadProject: false });
                            }}
                          /> */}

                    {/*
                            // dont remove............

                                                <SelectboxTemp
                                                    defValue={this.state.projectId}
                                                    url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.state.companyId+"/customer/"+0+"/branch/"+this.state.branchId}
                                                    //  staticData={this.state.ProjectOptions}
                                                    onSelect={this.onSelect}
                                                    stateKey="projectId"
                                                    value={this.state.projectId}
                                                    isRefresh={this.state.loadProject}
                                                    setIsRefresh={(val)=>{
                                                        this.setState({loadProject:false})
                                                    }}
                                                />
*/}
                    {/* </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}

                    {/* <ItemGrid xs={12} sm={3}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={3}>
                          <InputLabel className={classes.label}>
                            Type
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={9}>
                          <MultiSelect
                            id={"type"}
                            defValue={this.state.type}
                            onSelect={this.onSelect}
                            stateKey="type"
                            staticData={this.state.indentTypesList}
                            isRefresh={this.state.loadTypes}
                            setIsRefresh={(val) => {
                              this.setState({ loadTypes: false });
                            }}
                          /> */}

                    {/*

                            // Dont remove.......                            

                                                <SelectboxTemp id={"type"}
                                                               defValue={this.state.type}
                                                               onSelect={this.onSelect}
                                                               stateKey="type"
                                                               staticData={this.state.indentTypesList}
                                                               isRefresh={this.state.loadTypes}
                                                               setIsRefresh={(val)=>{
                                                                   this.setState({loadTypes:false})
                                                               }}
                                                />
*/}
                    {/* </ItemGrid>
                      </GridContainer>
                    </ItemGrid> */}

                    {/* {IsMenuAvailable(460) ? (
                      <ItemGrid xs={12} sm={3}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={3}>
                            <InputLabel className={classes.label}>
                              Item Group
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <MultiSelect
                              id={"itemgroup"}
                              defValue={this.state.itemgroup}
                              onSelect={this.onSelect}
                              stateKey="itemgroup"
                              staticData={this.state.itemGroupList}
                              isRefresh={this.state.loadItemGroup}
                              setIsRefresh={(val) => {
                                this.setState({ loadItemGroup: false });
                              }}
                            /> */}

                    {/*
                                                <SelectboxTemp
                                                    defValue={this.state.itemgroup}
                                                    id={"itemgroup"}
                                                    onSelect={this.onSelect}
                                                  //  getName={this.getItemGroupName}
                                                    isRefresh={this.state.loadItemGroup}
                                                    stateKey="itemgroup"
                                                    setIsRefresh={()=>{
                                                        this.setState({loadItemGroup:false})
                                                    }}
                                                    url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                />
*/}
                    {/* </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null} */}
                    {/* {IsMenuAvailable(460) ? (
                      <ItemGrid xs={12} sm={3}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={3}>
                            <InputLabel className={classes.label}>
                              Item
                            </InputLabel>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={9}>
                            <MultiSelect
                              id={"item"}
                              defValue={this.state.item}
                              onSelect={this.onSelect}
                              stateKey="item"
                              staticData={this.state.itemsList}
                              isRefresh={this.state.loadItem}
                              setIsRefresh={(val) => {
                                this.setState({ loadItem: false });
                              }}
                            /> */}

                    {/*
                                                <SelectboxTemp
                                                    defValue={this.state.itemgroup}
                                                    id={"itemgroup"}
                                                    onSelect={this.onSelect}
                                                  //  getName={this.getItemGroupName}
                                                    isRefresh={this.state.loadItemGroup}
                                                    stateKey="itemgroup"
                                                    setIsRefresh={()=>{
                                                        this.setState({loadItemGroup:false})
                                                    }}
                                                    url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                />
*/}
                    {/* </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    ) : null} */}
                  </GridContainer>
                </ItemGrid>
                {/* <ItemGrid xs={1}>
                  <Button
                    color="primary"
                    right={true}
                    onClick={() => {
                      if (IsMenuAvailable(459)) this.getDashboard1();
                      if (IsMenuAvailable(460)) this.getDashboard2();
                    }}
                  >
                    Search
                  </Button>
                </ItemGrid> */}

                {IsMenuAvailable(459) ? (
                  <ItemGrid xs={12}>
                    <GridContainer>
                      {/* <ItemGrid xs={12} sm={2}>
                        <TextHeadCard
                          Heading={"Indent Value"}
                          //  icon={ContentCopy}
                          iconColor="liteBlue"
                          title="Used Space"
                          statText1="Raised"
                          statText2="Cancelled"
                          statText3="PO Val"
                          val1={this.state.totalindent}
                          val2={this.state.totalCanelled}
                          val3={this.state.PendingPO}

                          //  small="GB"
                          // statIcon={Warning}
                          // statIconColor="danger"
                          //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                        />
                      </ItemGrid> */}
                      {/* <ItemGrid xs={12} sm={2}>
                        <TextHeadCard
                          Heading={"Order Value"}
                          //  icon={ContentCopy}
                          iconColor="red"
                          title="Used Space"
                          statText1="PO Val"
                          statText2="Cancelled"
                          statText3="Pending Del"
                          val1={this.state.totaPoVal}
                          val2={this.state.totalCancelPo}
                          val3={this.state.totalPendingDel} //  small="GB"
                          // statIcon={Warning}
                          // statIconColor="danger"
                          //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                        />
                      </ItemGrid> */}
                      {/* <ItemGrid xs={12} sm={2}>
                        <TextHeadCard
                          Heading={"Stock Value"}
                          //  icon={ContentCopy}
                          iconColor="warning"
                          title="Used Space"
                          statText1="Opening"
                          statText2="Received"
                          statText3="Closing"
                          val1={this.state.totaOpening}
                          val2={this.state.totalReceived}
                          val3={this.state.totalClosing}
                        />
                      </ItemGrid> */}

                      {/* <ItemGrid xs={12} sm={3}>
                        <TextHeadCard
                          Heading={"Invoice Value"}
                          //  icon={ContentCopy}
                          iconColor="blue"
                          title="Used Space"
                          statText1="Bill Value"
                          statText2="Paid"
                          statText3="Due"
                          val1={this.state.totalBill}
                          val2={this.state.totalPaid}
                          val3={this.state.totalDue} //  small="GB"
                          // statIcon={Warning}
                          // statIconColor="danger"
                          //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                        />
                      </ItemGrid> */}

                      {/* <ItemGrid xs={12} sm={3}>
                        <TextHeadCard
                          Heading={"Consumption Value"}
                          //  icon={ContentCopy}
                          iconColor="brown"
                          title="Used Space"
                          statText1="Consumed"
                          statText2="Transferred"
                          statText3="Expired"
                          val1={this.state.totalConsumed}
                          val2={this.state.totalTransfered}
                          val3={this.state.totalExpired} //  small="GB"
                        />
                      </ItemGrid> */}
                    </GridContainer>
                  </ItemGrid>
                ) : null}
                {/*
                            <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                    <ItemGrid xs={12} sm={6}>
                                        <TextHeadCard
                                            Heading={"Payment Value"}
                                            //  icon={ContentCopy}
                                            iconColor="blue"

                                            title="Used Space"
                                            statText2="Paid"
                                            val2={this.state.totalPaid}
                                            // statIcon={Warning}
                                            // statIconColor="danger"
                                            //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <TextHeadCard
                                            Heading={"Due Value"}
                                            //  icon={ContentCopy}
                                            iconColor="orange"

                                            title="Used Space"
                                            statText3="Due Value"

                                            val3={this.state.totalDue}                                             //  small="GB"
                                            // statIcon={Warning}
                                            // statIconColor="danger"
                                            //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <TextHeadCard
                                            Heading={"Pending Delivery Value"}
                                            //  icon={ContentCopy}
                                            iconColor="red"

                                            title="Used Space"
                                            statText3="Pending Del"
                                            val3={this.state.totalPendingDel}                                              //  small="GB"
                                            // statIcon={Warning}
                                            // statIconColor="danger"
                                            //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                                        />

                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6}>
                                        <TextHeadCard
                                            Heading={"Consumption Value"}
                                            //  icon={ContentCopy}
                                            iconColor="brown"

                                            title="Used Space"
                                            statText1="Consumed"
                                            statText2="Transferred"
                                            statText3="Expired"
                                            val1={this.state.totalConsumed}
                                            val2={this.state.totalTransfered}
                                            val3={this.state.totalExpired}                                               //  small="GB"
                                            // statIcon={Warning}
                                            // statIconColor="danger"
                                            //  statLink={{ text: "Cancelled Value: 10000\n PO Value:200 "}}
                                        />

                                    </ItemGrid>

                                </GridContainer>
                            </ItemGrid>
*/}

                {IsMenuAvailable(459) ? (
                  <ItemGrid xs={12} sm={6} md={6}>
                    <RegularCard
                      isLoading={this.state.isLoading}
                      content={
                        <div>
                          <legend
                            style={{ color: "#003F66", fontSize: "18px" }}
                          >
                            Due Value
                          </legend>

                          <ReactEcharts
                            option={{
                              color: [
                                "#00ABDC",
                                "#e5323e",
                                "#ff9800",
                                "#003F66",
                                "#94BA33",
                                "#e91e63",
                                "#4caf50",
                                "#f44336",
                              ],
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                },
                              },
                              legend: {
                                data: [
                                  "Indent Value",
                                  "Order Value",
                                  "Invoice Value",
                                  "Payment Value",
                                  "Due Value",
                                ],
                              },
                              toolbox: {
                                show: true,
                                orient: "vertical",
                                left: "right",
                                top: "center",
                                feature: {
                                  mark: { show: true },
                                  dataView: {
                                    show: false,
                                    readOnly: false,
                                    title: "Data View",
                                  },
                                  magicType: {
                                    show: true,
                                    type: ["line", "bar", "stack", "tiled"],
                                    title: {
                                      line: "Line",
                                      bar: "Bar",
                                      stack: "Stack",
                                      tiled: "tiled",
                                    },
                                  },
                                  restore: { show: true, title: "Restore" },
                                  saveAsImage: {
                                    show: true,
                                    title: "Save Image",
                                  },
                                },
                              },
                              xAxis: [
                                {
                                  type: "category",
                                  axisTick: { show: false },
                                  data: this.state.DueXaxis,
                                  nameLocation: "center",
                                  name: "Group Code",
                                  nameTextStyle: {
                                    padding: [5, 0, 0, 0],
                                    fontSize: 14,
                                  },
                                },
                              ],
                              yAxis: [
                                {
                                  type: "value",
                                  name: "Value",
                                  axisLabel: {
                                    textStyle: {
                                      color: CHARTCONFIG.color.text,
                                    },
                                    fontSize: 8,
                                    //formatter: '{value} K'
                                  },
                                },
                              ],
                              series: [
                                {
                                  name: "Indent Value",
                                  type: "line",
                                  barGap: 0,
                                  label: labelOption,
                                  data: this.state.indentYAxis,
                                },
                                {
                                  name: "Order Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.orderYaxis,
                                },
                                {
                                  name: "Invoice Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.invoiceYaxis,
                                },
                                {
                                  name: "Payment Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.paymentYaxis,
                                },
                                {
                                  name: "Due Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.dueYaxis,
                                },
                              ],
                            }}
                            showLoading={false}
                          />
                        </div>
                      }
                    />
                  </ItemGrid>
                ) : null}
                {IsMenuAvailable(459) ? (
                  <ItemGrid xs={12} sm={6} md={6}>
                    <RegularCard
                      isLoading={this.state.isLoading}
                      content={
                        <div>
                          <legend
                            style={{ color: "#003F66", fontSize: "18px" }}
                          >
                            Consumption Value
                          </legend>

                          <ReactEcharts
                            option={{
                              color: [
                                "#00ABDC",
                                "#e5323e",
                                "#ff9800",
                                "#003F66",
                                "#94BA33",
                                "#e91e63",
                                "#4caf50",
                                "#f44336",
                              ],
                              tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow",
                                },
                              },
                              legend: {
                                data: [
                                  "Indent Value",
                                  "Order Value",
                                  "Stock Value",
                                  "Consumption Value",
                                ],
                              },
                              toolbox: {
                                show: true,
                                orient: "vertical",
                                left: "right",
                                top: "center",
                                feature: {
                                  mark: { show: true },
                                  dataView: {
                                    show: false,
                                    readOnly: false,
                                    title: "Data View",
                                  },
                                  magicType: {
                                    show: true,
                                    type: ["line", "bar", "stack", "tiled"],
                                    title: {
                                      line: "Line",
                                      bar: "Bar",
                                      stack: "Stack",
                                      tiled: "tiled",
                                    },
                                  },
                                  restore: { show: true, title: "Restore" },
                                  saveAsImage: {
                                    show: true,
                                    title: "Save Image",
                                  },
                                },
                              },
                              xAxis: [
                                {
                                  type: "category",
                                  axisTick: { show: false },
                                  data: this.state.DueXaxis,
                                  nameLocation: "center",
                                  name: "Group Code",
                                  nameTextStyle: {
                                    padding: [5, 0, 0, 0],
                                    fontSize: 14,
                                  },
                                },
                              ],
                              yAxis: [
                                {
                                  nameLocation: "end",
                                  type: "value",
                                  name: "Value",
                                  axisLabel: {
                                    textStyle: {
                                      color: CHARTCONFIG.color.text,
                                    },
                                    fontSize: 8,
                                    //formatter: '{value} K'
                                  },
                                },
                              ],
                              series: [
                                {
                                  name: "Indent Value",
                                  type: "line",
                                  barGap: 0,
                                  label: labelOption,
                                  data: this.state.indentYAxis,
                                },
                                {
                                  name: "Order Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.orderYaxis,
                                },
                                {
                                  name: "Stock Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.stockYaxis,
                                },
                                {
                                  name: "Consumption Value",
                                  type: "line",
                                  label: labelOption,
                                  data: this.state.consumptioYAxis,
                                },
                              ],
                            }}
                            showLoading={false}
                          />
                        </div>
                      }
                    />
                  </ItemGrid>
                ) : null}
                {IsMenuAvailable(460) ? (
                  <ItemGrid xs={12} sm={6} md={6}>
                    <RegularCard
                      isLoading={this.state.isLoading}
                      content={
                        <GridContainer>
                          <ItemGrid xs={12} sm={12} md={12}>
                            <ItemGrid
                              xs={12}
                              sm={12}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={3}>
                                  <legend
                                    style={{
                                      color: "#003F66",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Requested
                                  </legend>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={7}>
                                  <SelectboxTemp
                                    defValue={this.state.requestedType}
                                    staticData={this.state.requestedTypeList}
                                    onSelect={this.onSelect}
                                    stateKey="requestedType"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </ItemGrid>
                          <ItemGrid xs={12}>
                            <ReactEcharts
                              option={{
                                color: [
                                  "#00ABDC",
                                  "#e5323e",
                                  "#ff9800",
                                  "#003F66",
                                  "#94BA33",
                                  "#e91e63",
                                  "#4caf50",
                                  "#f44336",
                                ],
                                tooltip: {
                                  trigger: "axis",
                                  axisPointer: {
                                    type: "shadow",
                                  },
                                },
                                legend: {
                                  data: this.state.companyNamesList,
                                },
                                toolbox: {
                                  show: true,
                                  orient: "vertical",
                                  left: "right",
                                  top: "center",
                                  feature: {
                                    mark: { show: true },
                                    dataView: {
                                      show: false,
                                      readOnly: false,
                                      title: "Data View",
                                    },
                                    magicType: {
                                      show: true,
                                      type: ["line", "bar", "stack", "tiled"],
                                      title: {
                                        line: "Line",
                                        bar: "Bar",
                                        stack: "Stack",
                                        tiled: "tiled",
                                      },
                                    },
                                    restore: { show: true, title: "Restore" },
                                    saveAsImage: {
                                      show: true,
                                      title: "Save Image",
                                    },
                                  },
                                },
                                xAxis: [
                                  {
                                    type: "category",
                                    axisTick: { show: false },
                                    data: this.state.requestedXAxis,
                                    nameLocation: "center",
                                    name: "Group Code",
                                    nameTextStyle: {
                                      padding: [5, 0, 0, 0],
                                      fontSize: 14,
                                    },
                                  },
                                ],
                                yAxis: [
                                  {
                                    nameLocation: "end",
                                    type: "value",
                                    name:
                                      this.state.requestedType === 1
                                        ? "Quantity"
                                        : "Value",
                                    axisLabel: {
                                      textStyle: {
                                        color: CHARTCONFIG.color.text,
                                      },
                                      fontSize: 8,
                                      //formatter: '{value} K'
                                    },
                                  },
                                ],
                                series:
                                  this.state.requestedType === 1
                                    ? yAxisList
                                    : yAxisList2,
                              }}
                              showLoading={false}
                            />
                          </ItemGrid>
                        </GridContainer>
                      }
                    />
                  </ItemGrid>
                ) : null}
              </GridContainer>
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(202) ? (
            <ItemGrid xs={12} sm={4} md={4}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <div>
                        <legend style={{ color: "#003F66", fontSize: "18px" }}>
                          {this.state.sumPendingPO + " Lorries Yet To Come"}
                        </legend>
                      </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12} style={{ height: 250 }}>
                      {console.log("sssssssssss", this.state.poList)}
                      {this.state.poList.length > 0 ? (
                        <div
                          style={{
                            height: 250,
                            overflowY: "auto",
                            overflowScrolling: "touch",
                          }}
                        >
                          <Table
                            tableHead={[
                              "PO No ",
                              "PO Date",
                              "Supplier Name",
                              "Pending Lorries",
                              "Due Since",
                            ]}
                            tableData={this.state.poList}
                          />
                        </div>
                      ) : (
                        <legend
                          style={{
                            ...defaultFont,
                            color: "#003F66",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          No Data Found
                        </legend>
                      )}
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(203) ? (
            <ItemGrid xs={12} sm={4} md={4}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <div>
                        <legend style={{ color: "#003F66", fontSize: "18px" }}>
                          Jute Stock
                        </legend>
                      </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12} style={{ height: 250 }}>
                      {this.state.juteStockList.length > 0 ? (
                        <div
                          style={{
                            height: 250,
                            overflowY: "auto",
                            overflowScrolling: "touch",
                          }}
                        >
                          <Table
                            tableHead={[
                              "Quality ",
                              "Bales",
                              "Drums",
                              "Weight(QNT)",
                            ]}
                            tableData={this.state.juteStockList}
                            showTooltip={true}
                            totals={this.state.totals}
                          />
                        </div>
                      ) : (
                        <legend
                          style={{
                            ...defaultFont,
                            color: "#003F66",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          No Data Found
                        </legend>
                      )}
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(209) ? (
            <ItemGrid xs={12} sm={4} md={4}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <div>
                        <legend style={{ color: "#003F66", fontSize: "18px" }}>
                          Jute Issue
                        </legend>
                      </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12} style={{ height: 220 }}>
                      {this.state.juteIssueList.length > 0 ? (
                        <div
                          style={{
                            height: 220,
                            overflowY: "auto",
                            overflowScrolling: "touch",
                          }}
                        >
                          <Table
                            tableHead={[
                              "Issue Date ",
                              "Average Rate",
                              "Issue Weight",
                              "Issue Value",
                            ]}
                            tableData={this.state.juteIssueList}
                          />
                        </div>
                      ) : (
                        <legend
                          style={{
                            ...defaultFont,
                            color: "#003F66",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          No Data Found
                        </legend>
                      )}
                    </ItemGrid>
                    <ItemGrid xs={2} sm={12}>
                      <Button
                        color="primary"
                        right={true}
                        onClick={() => {
                          this.props.history.push("/jute_issue");
                        }}
                      >
                        Show More
                      </Button>
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(210) ? (
            <ItemGrid xs={12} sm={6} md={6}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={3}>
                            <legend
                              style={{ color: "#003F66", fontSize: "18px" }}
                            >
                              Issue Date
                            </legend>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  if (typeof dateObj === "object")
                                    this.setState({
                                      issueDate: dateObj.format("DD-MM-YYYY"),
                                    });
                                }}
                                height={"14px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "issueDate",
                                  value: this.state.issueDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                          <ItemGrid xs={2} sm={3}>
                            <Button
                              color="primary"
                              onClick={this.onGoButtonClick}
                            >
                              Go
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12} style={{ height: 230 }}>
                      {this.state.juteIssueIdList.length > 0 ? (
                        <div
                          style={{
                            height: 220,
                            overflow: "auto",
                            overflowScrolling: "touch",
                          }}
                        >
                          <Table
                            tableHead={[
                              "Yarn Type",
                              "Unit Conversion",
                              "Jute Type",
                              "Quality",
                              "Bale/ Drum",
                              "Weight",
                              "Unit",
                              "Issue Value",
                            ]}
                            tableData={this.state.juteIssueIdList}
                            totals={this.state.totalIssueVal}
                          />
                          <legend className={classes.legendStyle}>
                            Average Rate={" "}
                            <span style={{ color: "#000" }}>
                              {this.getTotalBatchCost()}
                            </span>
                          </legend>
                        </div>
                      ) : (
                        <legend
                          style={{
                            ...defaultFont,
                            color: "#003F66",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          No Data Found
                        </legend>
                      )}
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(211) ? (
            <ItemGrid xs={12} sm={6} md={6}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <div>
                    <legend style={{ color: "#003F66", fontSize: "18px" }}>
                      Jute Issue
                    </legend>
                    {/*
                                    <ChartistGraph
                                       // className="ct-chart-white-colors"
                                        data={simpleBarChart.data}
                                        type="Bar"
                                        options={simpleBarChart.options}
                                        responsiveOptions={simpleBarChart.responsiveOptions}
                                        listener={simpleBarChart.animation}
                                    />
*/}
                    {this.state.weightData !== null ? (
                      /*                                        <ReactEcharts
                                        option={{
                                            color:["#94BA33"],
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            xAxis: {
                                                name:'Issue Date',
                                                nameLocation:'center',
                                                type: 'category',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14,
                                                },
                                                nameGap:25,
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    }
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                data:this.state.weightData.xAxis
                                            },
                                            yAxis: {
                                                name:'Issue Weight',
                                               // nameLocation:'center',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                                nameGap:25,
                                                type: 'value',
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    }
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                splitArea: {
                                                    show: true,
                                                    areaStyle: {
                                                        color: CHARTCONFIG.color.splitArea
                                                    }
                                                }
                                            },
                                            series: [{
                                                data:this.state.weightData.yAxis,
                                                type: 'bar',
                                                barMaxWidth:50                                            }]
                                        }}
                                        theme={bartheme}
                                        showLoading={false}/>:null}*/

                      <ReactEcharts
                        option={{
                          color: ["#00ABDC", "#94BA33", "#003F66", "#e5323e"],
                          tooltip: {
                            trigger: "axis",
                            axisPointer: {
                              type: "shadow",
                            },
                          },
                          legend: {
                            data: ["Issue Weight", "Issue Rate", "Issue Value"],
                          },
                          toolbox: {
                            show: true,
                            orient: "vertical",
                            left: "right",
                            top: "center",
                            feature: {
                              mark: { show: true },
                              dataView: {
                                show: false,
                                readOnly: false,
                                title: "Data View",
                              },
                              magicType: {
                                show: true,
                                type: ["line", "bar", "stack", "tiled"],
                                title: {
                                  line: "Line",
                                  bar: "Bar",
                                  stack: "Stack",
                                  tiled: "tiled",
                                },
                              },
                              restore: { show: true, title: "Restore" },
                              saveAsImage: { show: true, title: "Save Image" },
                            },
                          },
                          xAxis: [
                            {
                              type: "category",
                              axisTick: { show: false },
                              data: this.state.weightData.xAxis,
                            },
                          ],
                          yAxis: [
                            {
                              type: "value",
                            },
                          ],
                          series: [
                            {
                              name: "Issue Weight",
                              type: "bar",
                              barGap: 0,
                              label: labelOption,
                              data: this.state.weightData.yAxis,
                            },
                            {
                              name: "Issue Rate",
                              type: "bar",
                              label: labelOption,
                              data: this.state.rateData.yAxis,
                            },
                            {
                              name: "Issue Value",
                              type: "bar",
                              label: labelOption,
                              data: this.state.valueData.yAxis,
                            },
                          ],
                        }}
                        showLoading={false}
                      />
                    ) : null}
                  </div>
                }
              />
            </ItemGrid>
          ) : null}
          {/*
                    {IsMenuAvailable(212)?  <ItemGrid xs={12} sm={6} md={6}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <legend style={{color: "#003F66", fontSize: "18px"}}>Jute Issue Value
                                    </legend>
                                    {this.state.valueData!==null?  <ReactEcharts
                                        option={{
                                            color:["#003F66"],
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                },
                                                formatter:'{b}<br/>{c}K'
                                                },
                                            xAxis: {
                                                name:'Issue Date',
                                                nameLocation:'center',
                                                type: 'category',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                                nameGap:25,
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    },
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                data:this.state.valueData.xAxis
                                            },
                                            yAxis: {
                                              //  max:90000,
                                                boundaryGap: ['30%', '30%'],
                                               // splitNumber:10,

                                                name:'Issue Value',
                                               // nameLocation:'center',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                              //  nameGap:25,
                                                type: 'value',
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    },
                                                    fontSize:10,
                                                    formatter: '{value} K'

                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                splitArea: {
                                                    show: true,
                                                    areaStyle: {
                                                        color: CHARTCONFIG.color.splitArea
                                                    }
                                                }
                                            },
                                            series: [{
                                                data:this.state.valueData.yAxis,
                                                type: 'bar',
                                                barMaxWidth:50                                            }]
                                        }}
                                        theme={bartheme}
                                        showLoading={false}/>:null}


                                    <ReactFusioncharts
                                        type="column2d"
                                        width="400"
                                        height="250"
                                        color="#00ADBC"
                                        dataFormat="JSON"
                                        dataSource={dataSource1}
                                    />


                                </div>
                            }
                        />
                    </ItemGrid>:null}
*/}
          {/*
                    {IsMenuAvailable(213)?<ItemGrid xs={12} sm={6} md={6}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            content={
                                <div>
                                    <legend style={{color: "#003F66", fontSize: "18px"}}>Jute Average Rate
                                    </legend>

                                    {this.state.rateData!==null?
                                        <ReactEcharts
                                        option={{
                                            color:["#00ABDC"],
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            xAxis: {
                                                name:'Issue Date',
                                                nameLocation:'center',
                                                type: 'category',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                                nameGap:25,
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    }
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                data:this.state.rateData.xAxis
                                            },
                                            yAxis: {
                                               // max:90000,
                                                boundaryGap: ['30%', '30%'],
                                                // splitNumber:10,

                                                name:'Average Rage',
                                                // nameLocation:'center',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                                //  nameGap:25,
                                                type: 'value',
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    },
                                                  //  fontSize:'10px'
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                splitArea: {
                                                    show: true,
                                                    areaStyle: {
                                                        color: CHARTCONFIG.color.splitArea
                                                    }
                                                }
                                            },
                                            series: [{
                                                data:this.state.rateData.yAxis,
                                                type: 'bar',
                                                barMaxWidth:50                                            }]
                                        }}
                                        theme={bartheme}
                                        showLoading={false}/>:null}


                                   <ReactEcharts option={pie2.options}
                                    //                                                       theme={pietheme}
                                    //                                                       showLoading={false}/>

                                </div>
                            }
                        />
                    </ItemGrid>:null}
*/}

          {IsMenuAvailable(214) ? (
            <ItemGrid xs={12} sm={6} md={6}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={3}>
                            <legend
                              style={{ color: "#003F66", fontSize: "18px" }}
                            >
                              Yarn Based Issue
                            </legend>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  if (typeof dateObj === "object")
                                    this.setState({
                                      issueDate: dateObj.format("DD-MM-YYYY"),
                                    });
                                }}
                                height={"14px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "issueDate",
                                  value: this.state.issueDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                          <ItemGrid xs={2} sm={3}>
                            <Button
                              color="primary"
                              onClick={this.onGoButtonClickGraph}
                            >
                              Go
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </ItemGrid>
                    <ItemGrid xs={12} style={{ height: 220 }}>
                      <GridContainer>
                        {this.state.jutePieList.map((prop, key) => {
                          return (
                            <ItemGrid xs={4}>
                              <ReactEcharts
                                option={this.getPieOptions(prop)}
                                theme={pietheme}
                                showLoading={false}
                              />
                            </ItemGrid>
                          );
                        })}
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {IsMenuAvailable(215) ? (
            <ItemGrid xs={12} sm={6} md={6}>
              <RegularCard
                isLoading={this.state.isLoading}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <legend style={{ color: "#003F66", fontSize: "18px" }}>
                        Batch Deviation
                      </legend>

                      <ItemGrid xs={12} sm={12}>
                        <GridContainer>
                          <ItemGrid xs={12} sm={3}>
                            <legend
                              style={{ color: "#003F66", fontSize: "18px" }}
                            >
                              Date
                            </legend>
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <FormControl variant="" fullWidth>
                              <Datetime
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(dateObj) => {
                                  if (typeof dateObj === "object")
                                    this.setState({
                                      reportDate: dateObj.format("DD-MM-YYYY"),
                                    });
                                }}
                                height={"14px"}
                                formControlProps={{
                                  height: "15px",
                                  inlineBlock: "none",
                                }}
                                inputProps={{
                                  id: "reportDate",
                                  value: this.state.reportDate,
                                  autoComplete: "off",
                                }}
                              />
                            </FormControl>
                          </ItemGrid>
                          <ItemGrid xs={2} sm={3}>
                            <Button
                              color="primary"
                              onClick={this.onGoButtonCickJuteReport}
                            >
                              Go
                            </Button>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        height: 240,
                        overflow: "auto",
                        overflowScrolling: "touch",
                      }}
                    >
                      {this.state.reportsData.length > 0 ? (
                        this.state.reportsData.map((prop, key) => {
                          // console.log("prop.tableList",prop.tableList);
                          return (
                            <div>
                              <legend className={classes.legendStyle}>
                                Yarn Type :{" "}
                                <span style={{ color: "#000" }}>
                                  {prop.yarnType}{" "}
                                </span>{" "}
                                <span style={{ marginLeft: 30 }}>
                                  {" "}
                                  Plan Name :
                                </span>{" "}
                                <span style={{ color: "#000" }}>
                                  {prop.planName}
                                </span>
                              </legend>

                              <Table
                                tableHead={[
                                  "Qualities",
                                  "Target(%)",
                                  "Exp.Issue",
                                  "Actual Issue",
                                  "Diff",
                                  "Devi(%)",
                                ]}
                                tableData={prop.tableData}
                                totals={[
                                  "Total",
                                  " ",
                                  " ",
                                  prop.weight,
                                  " ",
                                  " ",
                                ]}
                                showTooltip={true}
                              />
                              {/*
                                                        <legend className={classes.legendStyle}>
                                                            Total  Weight<span style={{color:'#000'}}>{this.getTotalBatchCost()}</span>
                                                        </legend>
*/}
                            </div>
                          );
                        })
                      ) : (
                        <legend
                          style={{
                            ...defaultFont,
                            color: "#003F66",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          No Data Found
                        </legend>
                      )}
                    </ItemGrid>
                  </GridContainer>
                }
              />
            </ItemGrid>
          ) : null}
          {widgets.map((prop) => {
            if (prop.wide === "Y")
              return (
                <ItemGrid xs={12} sm={12} md={12}>
                  <GenericWidgetComponent
                    menuId={prop.menuId}
                    menuType={prop.menuType}
                    menuName={prop.name}
                    onClickShowMore={this.onClickShowMore}
                  />
                </ItemGrid>
              );
            else
              return (
                <ItemGrid xs={12} sm={6} md={6}>
                  <GenericWidgetComponent
                    menuId={prop.menuId}
                    menuType={prop.menuType}
                    menuName={prop.name}
                    onClickShowMore={this.onClickShowMore}
                  />
                </ItemGrid>
              );
          })}
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};
//  <GridContainer>
//                     <ItemGrid xs={12} sm={this.state.width_l} md={this.state.width_l}>
//                         <RegularCard
//                             content={
//                                 /*task grid*/
//                                 <GridContainer>
//
//                                     <ItemGrid xs={12} sm={this.state.itemWidth} md={this.state.itemWidth}>
//                                             <legend style={{color: "#003F66",fontSize: "23px"}}>TASKS
//                                                 <span style={{marginLeft:250,alignSelf:"flex-end"}}>
//                                                     <IconButton
//                                                     className={classnames(classes.expand, {
//                                                         [classes.expandOpen]: this.state.expanded,
//                                                     })}
//                                                     // round color="white"
//                                                     color="liteGreenNoBackground"
//                                                     onClick={this.handleExpandClick}
//                                                     aria-expanded={this.state.expanded}
//                                                     aria-label="Show more"
//                                                 >
//                                                 <ExpandMoreIcon style={{width:30}}/>
//                                             </IconButton></span>
//                                             </legend>
//                                             <br/>
//                                             <br/>
//                                             <br/>
//                                             <br/>
//                                             <p style={{color: "#003F66", fontSize: "14px"}}> <br/> EVENTS</p>
//                                             <br/>
//                                             <br/>
//                                             <br/>
//                                             <br/>
//
//                                     </ItemGrid>
//
//
//                                     <ItemGrid xs={12} sm={1} md={1}>
//                                         <div>
//                                             <IconButton
//                                                 className={classnames(classes.expand, {
//                                                     [classes.expandOpen]: this.state.expanded,
//                                                 })}
//                                                 // round color="white"
//                                                 color="liteGreenNoBackground"
//                                                 onClick={this.handleExpandClick}
//                                                 aria-expanded={this.state.expanded}
//                                                 aria-label="Show more"
//                                             >
//                                                 <ExpandMoreIcon style={{width:30,marginLeft:10}}/>
//                                             </IconButton>
//                                         </div>
//                                     </ItemGrid>
//
//
//                                     <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
//
//                                     <ItemGrid xs={12} sm={6} md={6}>
//                                             <CardContent>
//                                                 <GridContainer>
//                                                     <ItemGrid>
//                                                         <div>
//                                                             <legend style={{textAlign:"center",color:"#94BA33",fontSize: "30px"}}> New Task</legend>
//                                                             <br/>
//                                                             <legend style={{color: "#003F66", fontSize: "20px"}}>
//                                                                 {this.state.alert}
//                                                                 Title :
//                                                                 <TextField
//                                                                     id="taskTitle"
//                                                                     className={classes.textField}
//                                                                     value={this.state.taskTitle}
//                                                                     onChange={this.onChangeValues}
//                                                                     variant="outlined"
//                                                                     style={{marginLeft:10,width:200, height:30}}
//                                                                 />
//                                                             </legend>
//                                                             <br/>
//                                                             <legend style={{color: "#003F66", fontSize: "20px"}}>Due :
//                                                                 <TextField
//                                                                     id="taskDue"
//                                                                     className={classes.textField}
//                                                                     value={this.state.taskDue}
//                                                                     onChange={this.onChangeValues}
//                                                                     variant="outlined"
//                                                                     style={{marginLeft:10,width:140, height:30}}
//                                                                 />
//                                                                 <TextField
//                                                                     id="taskDueHrs"
//                                                                     className={classes.textField}
//                                                                     value={this.state.taskDueHrs}
//                                                                     onChange={this.onChangeValues}
//                                                                     variant="outlined"
//                                                                     style={{marginLeft:10,width:60, height:30}}
//                                                                 /> hrs
//
//                                                             </legend>
//                                                             <br/>
//                                                             <legend style={{color: "#003F66", fontSize: "20px"}}>Remainder :
//                                                                 <TextField
//                                                                     id="taskReminder"
//                                                                     className={classes.textField}
//                                                                     value={this.state.taskReminder}
//                                                                     onChange={this.onChangeValues}
//                                                                     variant="outlined"
//                                                                     style={{marginLeft:10,width:100, height:30}}
//                                                                 />
//                                                                 mins before
//
//                                                             </legend>
//                                                             <br/>
//                                                             <legend style={{color: "#003F66", fontSize: "20px"}}>Invite
//                                                                 <TextField
//                                                                     id="taskInvite"
//                                                                     className={classes.textField}
//                                                                     value={this.state.taskInvite}
//                                                                     onChange={this.onChangeValues}
//                                                                     variant="outlined"
//                                                                     style={{marginLeft:10,width:200, height:30}}
//                                                                 />
//                                                             </legend>
//
//                                                             <br/>
//                                                             <CustomButton color="darkBlue" wd size="lg" onClick={this.addTask}>
//                                                                 ADD
//                                                             </CustomButton>
//                                                         </div>
//                                                     </ItemGrid>
//                                                 </GridContainer>
//                                             </CardContent>
//
//                                     </ItemGrid>
//                                     </Collapse>
//
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             content={
// // cumulative grid
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Cumulative Statistics
//                                             </legend>
//                                         </div>
//                                         <br/>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={6} md={6}>
//                                         <legend style={{
//                                             color: "#94BA33",
//                                             textAlign: "left",
//                                             fontSize: "55px",
//                                             fontWeight: ''
//                                         }}>X
//                                             <small style={{fontSize: "20px"}}>%</small>
//                                         </legend>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={6} md={6}>
//                                         <br/>
//                                         <legend style={{color: "grey", fontSize: "16px"}}>Ready stock</legend>
//                                     </ItemGrid>
//                                     <br/>
//                                     <br/>
//                                     <br/>
//                                     <br/>
//                                     <ItemGrid xs={12} sm={6} md={6}>
//                                         <legend style={{
//                                             color: "#00ABDC",
//                                             textAlign: "left",
//                                             fontSize: "60px",
//                                             fontWeight: ''
//                                         }}>25
//                                             <small style={{fontSize: "20px"}}>ton</small>
//                                         </legend>
//                                     </ItemGrid>
//                                     <br/>
//                                     <ItemGrid xs={12} sm={6} md={6}>
//                                         <br/>
//                                         <legend style={{color: "grey", fontSize: "16px"}}>Raw Material
//                                         </legend>
//                                       <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Purchase Orders
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <legend style={{color: "#003F66", fontSize: "16px"}}>Order <span
//                                             style={{marginLeft: "50px"}}>Date </span> <span
//                                             style={{marginLeft: "30px"}}>Status </span></legend>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             isLoading={this.state.isLoading}
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>{this.state.sumPendingPO+' Lorries Yet To Come'}
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         {this.state.poList.length>0?
//                                         <div  style={{height:250, overflowY: 'auto', overflowScrolling: "touch"}}>
//                                             <Table
//                                                 tableHead={["PO No ","PO Date",'Supplier Name','Pending Lorries','Due Since']}
//                                                 tableData={this.state.poList}
//                                             />
//
//                                         </div>:null}
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             isLoading={this.state.isLoading}
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Jute stock
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         {this.state.juteStockList.length>0?
//                                             <div  style={{height:250, overflowY: 'auto', overflowScrolling: "touch"}}>
//                                                 <Table
//                                                     tableHead={["Quality ","Weight(QNT)",'Drums','Bales']}
//                                                     tableData={this.state.juteStockList}
//
//                                                 />
//
//                                             </div>:null}
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//
//                     <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                 <ItemGrid xs={12} sm={12} md={12}>
//                                     <div>
//                                         <legend style={{color: "#003F66", fontSize: "23px"}}>Jute Inventory
//                                         </legend>
//                                     </div>
//                                 </ItemGrid>
//                                 <ItemGrid xs={12} sm={12} md={12}>
//
//                                 <ReactEcharts
//                                     theme={linetheme}
//                                     option={line2.options} showLoading={false}/>
//                                 </ItemGrid>
//                             </GridContainer>
//                 }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={12} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Store Inventory
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//
//                                         <ReactEcharts option={bar4.options}
//                                                       theme={bartheme}
//                                                       notMerge={true}
//                                                       lazyUpdate={true}
//                                             //  className='echarts-for-echarts'
//                                                       showLoading={false}/>
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//
//                 </GridContainer>
//
//                 <GridContainer>
//                     <ItemGrid xs={12} sm={12} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Store Inventory
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//
//                                         <ReactEcharts option={pie2.options}
//                                                        theme={pietheme}
//                                                       showLoading={false}/>
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                     <ItemGrid xs={12} sm={12} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Godown Wise Stock
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//
//                                         <ReactFusioncharts
//                                             type="column2d"
//                                             width="400"
//                                             height="250"
//                                             dataFormat="JSON"
//                                             dataSource={dataSource}
//                                         />
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//                    <ItemGrid xs={12} sm={6} md={6}>
//                         <RegularCard
//                             content={
//                                 <GridContainer>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <div>
//                                             <legend style={{color: "#003F66", fontSize: "23px"}}>Purchase Orders
//                                             </legend>
//                                         </div>
//                                     </ItemGrid>
//                                     <ItemGrid xs={12} sm={12} md={12}>
//                                         <legend style={{color: "#003F66", fontSize: "16px"}}>Order <span
//                                             style={{marginLeft: "50px"}}>Date </span> <span
//                                             style={{marginLeft: "30px"}}>Status </span></legend>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                         <br/>
//                                     </ItemGrid>
//                                 </GridContainer>
//                             }
//                         />
//                     </ItemGrid>
//
//                 </GridContainer>

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompony: () => dispatch(fetchCompony()),
    setCompony: (componyInfo) => dispatch(setComponyAction(componyInfo)),
    getCompanies: () => dispatch(fetchCompanies()),
    setCompanies: (companies) => dispatch(setCompaniesAction(companies)),
  };
};

function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));
