import React from "react";
// @material-ui/icons

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig,serverApi} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../server/server";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import {getMenuPermission} from "../CustumComponents/PermissionAlert";
import { connect } from "react-redux";

class EmailConfiguration extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            basicAlert:null,
            isCreated:false,

            itemViewType:"userId",
            approveStatus:false,

            alert:false,
            hostName:'',emailName:'',emailID:'',password:'',portNo:'',jutePO:'',juteMR:'',storePO:'',storeMR:'',

            companyId:this.props.state.companyInfo.id
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleSimple = this.handleSimple.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }
    handleSeacrh = () => {
        var loading=false;
        if(this.state.isRefresh){
            loading=true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };
    componentDidMount() {
        this.handleLoading();
        getDataFromUrl(serverApi.GET_EMAIL_CONFIGURATION+'/'+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER),this.handleViewClickResp)
    }

    handleViewClickResp = (resp) => {
        var header = resp;
        //  var list = resp.data;
        //console.log("indentWeight "+header.indentWeight);
        if(header !== null){
            this.setState({
                updateView:true,
                isCreated:true,
                hostName:header.alertMailHost,
                emailName:header.alertMailName,
                emailID:header.alertMailId,
                password:header.alertMailPassword,
                portNo:header.portNo
                ,jutePO:header.jutePoBccMail,juteMR:header.juteMrBccMail,storePO:header.storePoBccMail,storeMR:header.storeMrBccMail,
            });
        }
        this.handleLoading();

    };
    handleLoading =()=>{
        this.setState({
            isLoading:!this.state.isLoading,
        });

    };

    handleCreate=()=>{
        if(!this.state.isCreated)
            this.hideAlertMessage();

        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    getData = () => {
        var data={
            "comId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER)

        };
        return data;
    };
    timeChanged = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('DD-MM-YYYY')
            });
        else
        if (id !== 'date1')
            this.setState({
                [id] :''
            });

        this.dateChanged();
    };
    dateChanged = () => {
        var myDate=this.state.date1;
        myDate=myDate.split("-");
        var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2];
        return new Date(newDate).getTime();
    };


    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected "+event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };


    onChangeValues =(event)=> {
        var value= event.target.value;

        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        if(stateKey==='companyId'){
            if(selectedValue!==parseInt(this.props.state.companyInfo.id)){
                this.setState({
                    loadUsers:true,
                    updateView: false,
                    selectedApprole:0,
                    selectedWebrole:0,
                    contactNo:'',
                    activeStatus:'',
                    emailID:'',
                    profileName:"",
                    userName:'',
                    userId:'',
                    createdBy:"",
                    createdOn:"",
                    reloadSelect:true
                });
            }
            else {
                this.basicAlert("Please Select Different Company..!!")
            }
            // this.hideAlertMessage();
        }
        this.setState({
            [stateKey]: selectedValue,
        });


    };
    getName = (selectedValue) => {
        var values=selectedValue;
        values=values.split("^");
        this.setState({
            selectedJuteName:values[0],
            selectedGrpId:values[1],
            selectedUnit:values[2],
            selectedDeptId:values[3],
        });
    };


    hideAlert() {
        console.log("alert");
        this.setState({
            alert: false,
            showMessagealert:false,
            loading:false,
            showUseralert:false
        });
    }
    hideAlertMessage() {
        console.log("alert");
        this.setState({
            updateView: false,
            alert: false,
            showMessagealert:false,
            loading:false,
            isCreated:false,
        });
    }

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    onRejectButtonClick=()=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
                "indentHeader": {
                    "id": this.state.indentNo,
                    "type": "J",
                    "mukam": this.state.selectedMukam,
                    "vehicleTypeId": this.state.selectedVehicleType,
                    "vehicleQuantity": this.state.vehicleQuantiy,
                    "submitter": getCookie(cons.USERID),
                    "indentDate": this.dateChanged(),
                    "finnacialYear": this.state.year,
                    "unitConversion": this.state.unitConvertionName,
                    "status": "4",
                    "companyId": this.props.state.companyInfo.id,
                    "cipher": getCookie(cons.CIPHER)


                },
            };
            this.handleLoading();
            requestList(serverApi.UPDATE_INDENT, data, this.handleAddIndent)
        }
    };

    onUpdateButtonClick=()=>{
            const {hostName,emailName,emailID,password,portNo,jutePO,juteMR,storePO,storeMR}=this.state;
            var data = {
                "alertMailId":emailID,
                "alertMailName":emailName,
                "alertMailPassword":password,
                "alertMailHost":hostName,
                "portNo":portNo,
                "jutePoBccMail":jutePO,
                "juteMrBccMail":juteMR,
                "storePoBccMail":storePO,
                "storeMrBccMail":storeMR,
                "companyId": this.props.state.companyInfo.id,
                "cipher": getCookie(cons.CIPHER)
            };
            if (hostName !== '' && emailName !== "" && emailID !== "" && password!==''&& portNo !== ''&&jutePO!==''&&storePO!==''&&storeMR!=='') {
                this.handleLoading();
                requestList(serverApi.UPDATE_EMAIL_CONFIGURATION, data, this.handleAddIndent)
            } else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }


    };

    handleAddIndent = (resp) => {
        if (resp.status) {
            console.log("resp "+ JSON.stringify(resp));
            this.showMessage(resp.message,resp.status)
        }else {
            this.showMessage(resp.message,resp.status)
        }
        this.handleLoading();
    };

    //add Item
    showMessage = (msg,status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    onClickSave={() => {
                        if (status){
                            this.hideAlertMessage();
                        }else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}

                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign:"center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    showUserSelectionByCompany = (msg,status) => {
        this.setState({
            showUseralert: true
        });

    };

    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    handleSelectedValues = (defValue) => {
        this.setState(
            {
                activeStatus:defValue,
                activeStatusId:defValue.value,
            });
    };
    getActiveStatus = (defValue) => {
        if(defValue ==='0'){
            return { value: 0, label: 'InActive',name: 'InActive' }
        }else  if(defValue==='1'){
            return { value: 1, label: 'Active' ,name:'Active'}

        }else return""
    };
    afterRefreshUsers = (val) => {

        this.setState({
            loadUsers: val
        });
    };

    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };


    render(){
        const { classes } = this.props;
        const options = [
            { value: "", label: 'Select...' ,name:'Select...'},
            { value: 1, label: 'Active' ,name:'Active'},
            { value: 0, label: 'InActive',name: 'InActive' }

        ];
        const inputFocus={
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                    <ItemGrid xs={12}>
                        {this.state.basicAlert}
                        {this.state.showMessagealert}
                        {this.state.showUseralert}
                        <RegularCard
                            isLoading={this.state.isLoading}
/*
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
*/
                            content={
                                <GridContainer>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Host Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="hostName"
                                                    className={classes.textField}
                                                    value={this.state.hostName}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}
                                                />


                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Email Name<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="emailName"
                                                    className={classes.textField}
                                                    value={this.state.emailName}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Email ID<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="emailID"
                                                    className={classes.textField}
                                                    value={this.state.emailID}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Password<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="password"
                                                    className={classes.textField}
                                                    value={this.state.password}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Port No<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="portNo"
                                                className={classes.textField}
                                                value={this.state.portNo}
                                                onChange={this.onChangeValues}
                                                fullWidth={true}
                                                variant="outlined"
                                                InputProps={inputFocus}

                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                    <ItemGrid xs={12}
                                              style={{height: 2, backgroundColor: "#00acc1", marginTop: "15px"}}/>

                                    <ItemGrid xs={12}>
                                        <span style={{color:"#00ADBC",fontSize:'16px',fontWeight:400}}>BCC Emails :</span>

                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Jute PO<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="jutePO"
                                                    className={classes.textField}
                                                    value={this.state.jutePO}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Jute MR<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="juteMR"
                                                    className={classes.textField}
                                                    value={this.state.juteMR}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Store PO<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="storePO"
                                                    className={classes.textField}
                                                    value={this.state.storePO}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} md={6}  style={{marginTop:"10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Store MR<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="storeMR"
                                                    className={classes.textField}
                                                    value={this.state.storeMR}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    InputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>


{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
*/}
{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} sm={6}  style={{marginTop:"10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
*/}

                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary" onClick={this.onUpdateButtonClick}>update</Button>
                                                </ItemGrid>
{/*
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>
*/}

                                            </GridContainer>

                                        </ItemGrid>

                                </GridContainer>
                            }/>

                    </ItemGrid>
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField:{
    },
    input: {
        border : "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit :{
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt:{
        textTransform: "none",
        borderRadius: "4px",
        lineHeight:"1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(EmailConfiguration)
);
