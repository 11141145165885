import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, serverVars} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import TableComponent from "../CustumComponents/TableComponent";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";


class AppDataSelectionEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            itemViewType:"selectionEntryId",
            date:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
            spreaderNo:'',
            trollyNo:'',
            spell:'',
            grossWeightKG:'',
            tareWeightKG:'',
            netWeightKG:'',
            netWeightTotalKG:'',





        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
    }
    componentDidMount() {
        var data={
            "entryDateTime":this.dateChanged(this.state.date),
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER)
        };
        requestList(serverApi.GET_NET_WEIGHT_SELECTION_ENTRY,data,this.handleTotalNetWeight)
    }
    handleTotalNetWeight = (resp) => {
        if(resp.status && resp.data!==null&&resp.data!==undefined){
            this.setState({
                netWeightTotalKG:(resp.data*100).toFixed(2)
            })
        }else {
            this.setState({
                netWeightTotalKG:0
            })

        }
        // var data = this.state.dataTable;

    };

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });

    };

    handleViewClick = (id) => {
        this.handleLoading();
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            getDataFromUrl(serverVars.PO_API + "/getJuteIssueById/" + id, this.handleViewClickResp)
            //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
        }
    };
    handleDeleteClick = (id) => {

        this.basicDeleteAlert("Do you want to delete this record?",id);
        // var data = this.state.dataTable;
        var juteId = "";
        //getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/getJuteIssueById/" + 91, this.handleViewClickResp)
    };
    handleDeleteClickResp= (resp) =>{
        if(resp.status){
            this.showMessage(resp.message,resp.status);
            this.setState({
                isRefresh:true
            });
            var data={
                "entryDateTime":this.dateChanged(this.state.date),
                "companyId":this.props.state.companyInfo.id,
                "cipher":getCookie(cons.CIPHER)
            };

            requestList(serverApi.GET_NET_WEIGHT_SELECTION_ENTRY,data,this.handleTotalNetWeight)

        }else {
            this.showMessage(resp.message,resp.status);
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp;

        if (header !== null) {
            this.setState({
                updateView: true,
                isCreated: true

            });

        }


    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        if(this.state.fromDate!==""||this.state.todate!==""){
            var data = {
                "fromDate": this.state.fromDate
                , "toDate": this.state.todate,
                "companyId":this.props.state.companyInfo.id,
                "cipher":getCookie(cons.CIPHER)
            };
        }else
        var data = {
            "fromDate": this.state.fromDate
            , "toDate": this.state.todate,
            "cipher":getCookie(cons.CIPHER)
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object'){
            if(id==='date'){
                var data={
                    "entryDateTime":this.dateChanged(dateObj.format('DD-MM-YYYY')),
                    "companyId":this.props.state.companyInfo.id,
                    "cipher":getCookie(cons.CIPHER)

                };
                requestList(serverApi.GET_NET_WEIGHT_SELECTION_ENTRY,data,this.handleTotalNetWeight)
            }
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });

        } else if (id !== 'date')
            this.setState({
                [id]: ''
            });
        //this.dateChanged();
    };
    dateChanged = (date1) => {
        var myDate = date1;
        if(myDate!=null){
            myDate = myDate.split("-");
            var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
            return new Date(newDate).getTime();
        }

    }


    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/

        if (stateKey === 'selectedJuteType') {


            this.setState({
                loadQuality: true,

            });
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    afterRefreshQuality = (val) => {

        this.setState({
            loadQuality: val
        });
    };

    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({

            alert: false,
            showMessagealert: false,
            loading: false,
        });
    }

    hideAlertMessage() {
        this.setState({
            isRefresh:true,
            isCreated: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
          //  date:moment().format('DD-MM-YYYY'),
            time:moment().format('hh:mm:ss A'),
            time2:moment().format('HH:mm:ss'),
           // spreaderNo:0,
            trollyNo:0,
           // spell:0,
            grossWeightKG:'',
            tareWeightKG:'',
            netWeightKG:'',
            netWeightTotalKG:'',
            reloadSelect:true

        });
        var data={
            "entryDateTime":this.dateChanged(moment().format('DD-MM-YYYY')),
            "companyId":this.props.state.companyInfo.id,
            "cipher":getCookie(cons.CIPHER)

        };
        requestList(serverApi.GET_NET_WEIGHT_SELECTION_ENTRY,data,this.handleTotalNetWeight)
    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    hideDeleteAlert(id){
        this.setState({basicAlert:null});
        getDataFromUrl(serverApi.DELETE_SELECTION_ENTRY + id, this.handleDeleteClickResp);
    }
    basicDeleteAlert(msg,id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }


    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: [],
            sumOFQuantities: 0

        });

    }



    onUpdateButtonClick = () => {
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            var data = {
                "issueId": this.state.indentNo,
                "juteType": this.state.selectedJuteType,
                "finYear": this.state.year,
                "issueDate": this.dateChanged(),
                "spreadNo": this.state.spreaderNo,
                "trollyNo": this.state.trollyNo,
                "grossWeightKG": this.state.grossWeightKG,
                "tareWeightKG": this.state.tareWeightKG,
                "netWeightKG": this.state.netWeightKG,
                "netWeightTotalKG": this.state.netWeightTotalKG,
                "createdBy": getCookie(cons.USER_NAME),



            };
            /*  if (this.state.date1 !== '' && this.state.unitConvertion !== "" && this.state.selectedJuteType !== "" && this.state.form_QualityId !== "" && this.state.form_godownNo !== '') {
                  this.handleLoading();
                  requestList(serverApi.UPDATE_JUTE_ISSUE, data, this.handleAddIndent)
              }
              else {
                  this.basicAlert("Please Enter All Mandatory Fields..!!");
              }*/
        }
    };

    timeChangedFormat = (myDate,time) => {
        console.log("time",myDate + time);

        myDate=myDate.split("-");
        var timeParts = time.split(":");
        return new Date(myDate[2], myDate[1]-1, myDate[0], timeParts[0], timeParts[1],timeParts[2]).getTime();
    };



    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {spreaderNo,trollyNo,grossWeightKG,tareWeightKG,netWeightKG,netWeightTotalKG,date,time2,spell}=this.state;
            var data = {
                //"spell":"A",
                "spreadNo":spreaderNo,
                "trollyNo":trollyNo,
                "spell":spell,
                "grossWeight":parseFloat(grossWeightKG)/100,
                "totNetWeight":parseFloat(netWeightTotalKG)/100,
                "tareWeight":parseFloat(tareWeightKG)/100,
                "netWeight":parseFloat(this.getNetWeight())/100,
                "createdBy":getCookie(cons.USERID),
                "entryDateTime":this.timeChangedFormat(date,time2),
                "companyId":this.props.state.companyInfo.id
            };
                //  if (spreaderNo !== '' && trollyNo !== "" && grossWeightKG !== "" ) {
                //      if(this.getNetWeight()>0){
                //          this.handleLoading();
                //          requestList(serverApi.ADD_SELECTION_ENTRY, data, this.handleAddIndent)
                //      }else {
                //          this.basicAlert("Gross Weight Should Be Greater Than Tare Weight..!!");
                //      }
                //  }
                //  else {
                //      this.basicAlert("Please Enter All Mandatory Fields..!!");
                //  }

                const validateFields = [
                    { field: spreaderNo, msg: "Spreader No", type: 2 },
                    { field: trollyNo, msg: "Trolly No", type: 2 },
                    { field: spell, msg: "Spell", type: 2 },
                    { field: grossWeightKG, msg: "Gross Weight", type: 2 },
                  ];
            
                  const validObj = validate(validateFields);
                  console.log(validObj);
                  if (validObj) {
                    if (validObj.type === 1) {
                      this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                    } else {
                      this.basicAlert(`Please Select ${validObj.message} ..!!`);
                    }
                  }
                  else if(this.getNetWeight()<0){
                    this.basicAlert("Gross Weight Should Be Greater Than Tare Weight..!!");

                  }
                   else {
                    this.handleLoading();
                    // this.basicAlert("Created Successfully");
                    requestList(serverApi.ADD_SELECTION_ENTRY, data, this.handleAddIndent)
                  }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            this.hideAlertMessage();
            this.showMessage(resp.message, resp.status);
            var data={
                "entryDateTime":this.dateChanged(this.state.date),
                "companyId":this.props.state.companyInfo.id,
                "cipher":getCookie(cons.CIPHER)
            };

            requestList(serverApi.GET_NET_WEIGHT_SELECTION_ENTRY,data,this.handleTotalNetWeight)

        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };


    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlert();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    timeChanged1 = (dateObj,id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id] :dateObj.format('hh:mm:ss A'),
                  time2:dateObj.format('HH:mm:ss')
            });
    };

    getTrollyDetails = (selectedValue) =>{
        var val=selectedValue.split("^");
        this.setState({
            tareWeightKG:val[1]
        });

    };
    getNetWeight(){
        if(this.state.grossWeightKG!==''&& this.state.tareWeightKG!=='')
            return parseFloat(this.state.grossWeightKG)-parseFloat(this.state.tareWeightKG);
        else return 0
    }
    afterReloadSelect=(val)=>{
        this.setState({
            reloadSelect:val
        })
    };


    render() {
        const {classes} = this.props;
        const psoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'WARP', name: 'WARP'},
            {value: 2, label: 'WEFT', name: 'WEFT'},

        ];
        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>

                <ItemGrid xs={12}>
                    <RegularCard
                        isLoading={this.state.isLoading}
                        content={
                            <GridContainer>
                                {this.state.basicAlert}
                                {this.state.showMessagealert}

                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>

                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Date<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Datetime
                                                    defaultValue={new Date()}
                                                    closeOnSelect={true}
                                                    timeFormat={false}
                                                    onChange={(dateObj) => {
                                                        this.timeChanged(dateObj, "date")
                                                    }}
                                                    formControlProps={
                                                        {height: "10px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id: "date",
                                                        value: this.state.date,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Time<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8} >
                                            <FormControl variant="" fullWidth>
                                                <Datetime
                                                    defaultValue={new Date()}
                                                    closeOnSelect={true}
                                                    // defaultValue={this.state.time1}
                                                    dateFormat={false}
                                                    onChange={(timeObj) => {
                                                        this.timeChanged1(timeObj, "time")
                                                    }}
                                                    height={"15px"}
                                                    formControlProps={
                                                        {height: "15px", inlineBlock: "none"}
                                                    }
                                                    inputProps={{
                                                        id:"outTime",
                                                        value:this.state.time,
                                                        autoComplete: 'off'
                                                    }}

                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Spreader No<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                id={"spreaderNo"}
                                                selectDisabled={this.state.updateView}
                                                defValue={this.state.spreaderNo}
                                                onSelect={this.onSelect}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                //  getName={this.getName}
                                                stateKey="spreaderNo"
                                                url={serverApi.GET_FRAME_NUMBERS+this.props.state.companyInfo.id+"/SPREADER"}
                                                value={this.state.spreaderNo}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Trolly No<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.trollyNo}
                                                id={"trollyNo"}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                getName={this.getTrollyDetails}
                                                onSelect={this.onSelect}
                                                stateKey="trollyNo"
                                                url={serverApi.GET_TROLLY_LIST+'1/'+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                value={this.state.trollyNo}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Spell<span
                                                style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.spell}
                                                id={"spell"}
                                                getName={this.getQualityDetails}
                                                onSelect={this.onSelect}
                                                isReload={this.state.reloadSelect}
                                                setIsReload={this.afterReloadSelect}
                                                stateKey="spell"
                                                url={serverApi.GET_ALL_SPELL_LIST+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                value={this.state.spell}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Gross Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="grossWeightKG"
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.grossWeightKG}
                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Tare Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="tareWeightKG"
                                                disabled={true}
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.tareWeightKG}
                                                fullWidth={true}
                                                variant="outlined"
                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Net Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="netWeightKG"
                                                disabled={true}
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.getNetWeight()}
                                                fullWidth={true}
                                                variant="outlined"

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={6} style={{marginTop:"10px"}}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Total Net Weight<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <TextField
                                                id="netWeightTotalKG"
                                                disabled={true}
                                                className={classes.textField}
                                                onChange={this.onChangeValues}
                                                value={this.state.netWeightTotalKG}
                                                fullWidth={true}
                                                variant="outlined"

                                                InputProps={{
                                                    autoComplete: 'off',
                                                    endAdornment: <InputAdornment position="end">
                                                        <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>KG</span>
                                                    </InputAdornment>,
                                                }}

                                            />

                                        </ItemGrid>


                                    </GridContainer>
                                </ItemGrid>

                                {!this.state.updateView ?
                                    <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                            <ItemGrid>
                                                <Button color="primary"
                                                        onClick={this.onCreateButtonClick}>Create</Button>
                                            </ItemGrid>

                                            <ItemGrid>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>

                                    </ItemGrid> : null
                                }

                                <ItemGrid xs={12}
                                          style={{
                                              height: 2,
                                              backgroundColor: "#00acc1",
                                              marginTop: "5px"
                                          }}></ItemGrid>


                                <ItemGrid xs={10} style={{marginTop: "10px"}}>
                                        <GridContainer>

                                            <ItemGrid xs={12} sm={6}>
                                                {this.state.basicAlert}
                                                {this.state.showMessagealert}
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            From date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "fromDate")
                                                                }}
                                                                formControlProps={
                                                                    {height: "10px", inlineBlock: "none"}
                                                                }
                                                                inputProps={{
                                                                    id: "fromDate",
                                                                    value: this.state.fromDate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>

                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={4}>
                                                        <InputLabel className={classes.label}>
                                                            To date
                                                        </InputLabel>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={8}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <Datetime
                                                                closeOnSelect={true}
                                                                timeFormat={false}
                                                                onChange={(dateObj) => {
                                                                    this.timeChanged(dateObj, "todate")
                                                                }}
                                                                inputProps={{
                                                                    id: "toDate",
                                                                    value: this.state.todate,
                                                                    autoComplete: 'off'
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </ItemGrid>
                                                </GridContainer>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={2}>
                                        <GridContainer>
                                            <ItemGrid xs={12} style={{marginTop: "10px"}}>
                                                <br/>
                                                <br/>
                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>
                                                <br/>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>


                                    <ItemGrid xs={12}
                                              style={{
                                                  height: 2,
                                                  backgroundColor: "#00acc1",
                                                  marginTop: "5px"
                                              }}></ItemGrid>


                                <ItemGrid xs={12}>




                                        <TableComponent
                                            url={serverApi.SELECTION_ENTRY_TABLE_LIST}
                                            searchData={this.getData()}
                                            isRefresh={this.state.isRefresh}
                                            handleSearch={this.handleSeacrh.bind(this)}
                                            isLoading={this.state.isRefresh}
                                            onActionClick={this.handleDeleteClick}
                                            itemViewType={this.state.itemViewType}
                                            actionType={DELETE}
                                            fileName={'Selection Entry'}

                                            // showDelete={true}
                                           // onDeleteClick={this.handleDeleteClick}
                                        />

                                </ItemGrid>


                            </GridContainer>
                        }/>

                </ItemGrid>

            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(AppDataSelectionEntry)
);
