import React from "react";
import ReactTable from "react-table";
import Datetime from "react-datetime";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import RegularCard from "../../../components/Cards/RegularCard";
import Add from '@material-ui/icons/Add';
import Button from "../../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import cons, {configNames, serverApi} from '../../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../../components/CustomInput/CustomTextField";
import Popup from "../../../components/Popup/PopUp";
import {getDataFromUrl, requestList} from "../../../server/server";
import {getCookie} from "../../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../../CustumComponents/SelectboxTemp";
import {VIEW} from "../../../ui-actions/UiActions";
import {getMenuPermission} from "../../CustumComponents/PermissionAlert";
import TableComponent from "../../CustumComponents/TableComponent";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Radio from 'material-ui/Radio/Radio';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import {ApprovalTasks, invoiceTypesList, menuTypes, supplierTypesList} from "../../TableData";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {Check} from "@material-ui/icons";
import {columnIndexOf} from "fusioncharts/vendors/fc-datatable/src/utils/datatable-utils";
import InputAdornment from "material-ui/Input/InputAdornment";

class MenusMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            companyList: [], menusList: [], menuToAdd: [], menuTypeList: 0
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
    }

    hideAlertMessage() {
        this.setState({
            showMessagealert: false,
            alert: false,
            basicAlert: false,
        });

    }

    componentDidMount() {
        if (getCookie(cons.COMPANIES_LIST) !== undefined) {
            var companyList = JSON.parse(getCookie(cons.COMPANIES_LIST));
            console.log("companyList", companyList);
            this.setState({
                companyList: companyList,
                reloadList: true
            });
        }
        this.getMenusList(this.state.menuTypeList)
    }

    getMenusList = (selectedValue) => {
        this.handleLoading();
        getDataFromUrl(serverApi.GET_MENUS_BY_ROLE + selectedValue + "/0", this.handleMenus);
    };
    handleMenus = (resp,hasData) => {
        var menus = [];
        if (resp.length > 0) {
            resp.map((prop, key) => {
                var data = {
                    id: 0,
                    menuId: prop.menuId,
                    isEnable: 0,
                };
                menus.push(data);
                if (prop.subMenus.length > 0) {
                    prop.subMenus.map((prop, key) => {
                        var data = {
                            id: 0,
                            menuId: prop.menuId,
                            isEnable: 0,
                        };
                        menus.push(data);
                        if (prop.subSubMenus.length > 0) {
                            prop.subSubMenus.map((prop, key) => {
                                var data = {
                                    id: 0,
                                    menuId: prop.menuId,
                                    isEnable: 0,
                                };
                                menus.push(data);
                            })

                        }
                    })
                }
            })
        }
        console.log("updateView",hasData,this.state.menusToAdd);
        if (hasData) {
            menus.map((prop, key) => {
                this.state.menusToAdd.find((o, i) => {

                    if (prop.menuId === o.menuId && o.isActive) {
                        menus[key].id = o.id;
                        menus[key].isEnable = o.isActive;
                    }
                })
            });
            this.setState({
                menusToAdd: menus,
            });

        } else {
            this.setState({
                menusList: resp,
                menusToAdd: menus,
                isCreated: true,
            });

        }

        this.handleLoading();

    };
    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }

    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });

    };


    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            alert: false,
            showMessagealert: false,
            loading: false,

        });
    }

    hideBasicAlert() {
        this.setState({
            basicAlert: null
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }


    onSelect = (selectedValue, stateKey) => {
        if (stateKey === 'menuTypeList' && this.state.menuTypeList !== selectedValue) {
            this.getMenusList(selectedValue);
        }
        if (stateKey === 'companyId') {
            this.handleLoading();
            getDataFromUrl(serverApi.GET_MENU_COMPANY_MAPPED_LIST + selectedValue+"/"+ this.state.menuTypeList, this.handleConfigData)
        }
        this.setState({
            [stateKey]: selectedValue
        });
    };
    handleConfigData = (resp) => {
        console.log("configggg", resp);
        if (resp.length>0) {
            this.setState({menusToAdd: resp});
            this.handleMenus(this.state.menusList,true);
        }else {
            this.handleMenus(this.state.menusList,false);
        }

    };
    getAutoIdFromList = (configParameter) => {
        var list = this.state.configList;
        var id = undefined;
        if (list.length > 0) {
            list.forEach(prop => {
                if (prop.configParameter === configParameter) {
                    id = prop.autoId;
                    console.log("id", id);

                }
            })
        }
        return id;

    };

    onCreateButtonClick = () => {

        const {
            menusToAdd,companyId,menuTypeList
        } = this.state;

        var data =[]
            menusToAdd.map(prop=>{
            if(prop.isEnable===1){
                var obj={
                    "menuId":prop.menuId,"companyId":companyId,"createdBy":getCookie(cons.USERID),"isActive":1,reqSource:menuTypeList
                }
                data.push(obj)
            };
        });

        if (companyId !== "") {
            this.handleLoading();
            requestList(serverApi.ADD_MENU_COMPANY_MAPPING, data, this.handleAddMenu)
        } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }

    };
    handleAddMenu = (resp) => {
        if (resp.status) {
            this.showMessage(resp.message)
        } else {
            this.showMessage(resp.message)
        }
        this.handleLoading();
    };


    showMessage = (msg) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        this.hideAlertMessage();
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    onCheckBranch = (prop) => event => {

        var list = this.state.supplierTypes;
        if (list.filter(vendor => vendor.value === prop.value).length > 0) {
            const index = list.findIndex(vendor => vendor.value === prop.value);
            list.splice(index, 1)
        } else {
            list.push(prop)
        }
        this.setState({supplierTypes: list});
        //  console.log("supplierrmmm",list);

    };
    onCheckInvoiceModel = (prop) => event => {

        var list = this.state.invoicingModel;
        if (list.filter(vendor => vendor.value === prop.value).length > 0) {
            const index = list.findIndex(vendor => vendor.value === prop.value);
            list.splice(index, 1)
        } else {
            list.push(prop)
        }
        this.setState({invoicingModel: list});
        //  console.log("supplierrmmm",list);

    };

    onCheckTaxType = (prop) => event => {

        var list = this.state.taxTypes;
        if (prop === 'gst') {
            list.gst = list.gst === 0 ? 1 : 0
        } else if (prop === 'vat') {
            list.vat = list.vat === 0 ? 1 : 0
        } else if (prop === 'noTax') {
            list.noTax = list.noTax === 0 ? 1 : 0
        }
        this.setState({taxTypes: list});
        //  console.log("supplierrmmm",list);

    };
    onCheckBrokerType = (prop) => event => {

        var list = this.state.brokerTypes;
        if (prop === 'jute') {
            list.jute = list.jute === 0 ? 1 : 0
        } else if (prop === 'sales') {
            list.sales = list.sales === 0 ? 1 : 0
        }
        this.setState({brokerTypes: list});
        //  console.log("supplierrmmm",list);

    };

    onCheckTasks = (prop) => event => {

        var list = this.state.tasksList;
        if (list.filter(vendor => vendor.taskId === prop.taskId).length > 0) {
            const index = list.findIndex(vendor => vendor.taskId === prop.taskId);
            list.splice(index, 1)
        } else {
            list.push(prop)
        }
        this.setState({tasksList: list});
        //  console.log("supplierrmmm",list);

    };
    onCheckSaleType = (prop) => event => {

        var list = this.state.saleOrderTypes;
        if (prop === 'general') {
            list.general = list.general === 0 ? 1 : 0
        } else if (prop === 'rawMaterial') {
            list.rawMaterial = list.rawMaterial === 0 ? 1 : 0
        } else if (prop === 'juteSale') {
            list.juteSale = list.juteSale === 0 ? 1 : 0
        }
        this.setState({saleOrderTypes: list});
        //  console.log("supplierrmmm",list);

    };
    handleChange = (object, permission) => event => {
        var id = object.menuId;
        var menus = this.state.menusToAdd;
        menus.map((prop, key) => {
            if (id === prop.menuId) {
                var obj = prop;

                if (event.target.checked) {
                    if (object.subMenus.length > 0) {
                        object.subMenus.map((sub, i) => {

                            menus.find(
                                (obj, i) => {
                                    if (obj.menuId === sub.menuId) {
                                        if (permission === 0) {
                                            obj.isEnable = 1;
                                        }
                                        menus[i] = obj;
                                    }
                                }
                            );
                            if (sub.subSubMenus.length > 0) {

                                sub.subSubMenus.map((sub, i) => {
                                    menus.find(
                                        (obj, i) => {
                                            if (obj.menuId === sub.menuId) {
                                                if (permission === 0) {
                                                    obj.isEnable = 1;
                                                }

                                                menus[i] = obj;
                                            }
                                        }
                                    );
                                });

                            }

                        });
                    }


                    if (permission === 0) {
                        obj.isEnable = 1;
                    }

                } else {
                    object.subMenus.map((sub, i) => {
                        var subId = sub.menuId;

                        menus.find((obj, i) => {
                                if (obj.menuId === sub.menuId) {
                                    if (permission === 0) {
                                        obj.isEnable = 0;
                                    }
                                    menus[i] = obj;
                                }
                            }
                        );
                        if (sub.subSubMenus.length > 0) {

                            sub.subSubMenus.map((sub, i) => {

                                menus.find(
                                    (obj, i) => {
                                        if (obj.menuId === sub.menuId) {
                                            if (permission === 0) {
                                                obj.isEnable = 0;
                                            }

                                            menus[i] = obj;
                                        }
                                    }
                                );
                            });

                        }

                        // }
                    });

                    if (permission === 0) {
                        obj.isEnable = 0;
                    }
                }
                menus[key] = obj;
            }
        });
        this.setState({
            // [id]: event.target.checked,
            //menusToSend:menusToSend,
            menusToAdd: menus,
        });
        // console.log("menus to sens",JSON.stringify(menusToSend));
    };
    handleSubMenuChange = (menuId, subObject, permission) => event => {
        var id = subObject.menuId;
        var menus = this.state.menusToAdd;
        menus.map((prop, key) => {
            if (id === prop.menuId) {
                var obj = prop;

                if (event.target.checked) {
                    if (subObject.subSubMenus.length > 0) {
                        subObject.subSubMenus.map((sub, i) => {

                            // if(prop.menuId===sub.menuId){
                            menus.find((obj, i) => {
                                    if (obj.menuId === sub.menuId) {
                                        if (permission === 0) {
                                            obj.isEnable = 1;
                                        }
                                        menus[i] = obj;
                                    }
                                }
                            );
                            // }
                        });

                    }


                    if (permission === 0) {
                        obj.isEnable = 1;
                    }

                } else {
                    subObject.subSubMenus.map((sub, i) => {
                        var subId = sub.menuId;

                        // if(prop.menuId===sub.menuId){
                        menus.find((obj, i) => {
                                if (obj.menuId === sub.menuId) {
                                    if (permission === 0) {
                                        obj.isEnable = 0;
                                    }

                                    menus[i] = obj;
                                }
                            }
                        );
                        // }
                    });

                    if (permission === 0) {
                        obj.isEnable = 0;

                    }
                }
                menus[key] = obj;


            }
        });
        this.setState({
            menusToAdd: menus,
        });

    };
    handleSubSubMenuChange = (menuId, subObject, permission) => event => {
        var id = subObject.menuId;
        var menus = this.state.menusToAdd;
        menus.map((prop, key) => {
            if (id === prop.menuId) {
                var obj = prop;

                if (event.target.checked) {

                    if (permission === 0) {
                        obj.isEnable = 1;
                    }
                } else {

                    if (permission === 0) {
                        obj.isEnable = 0;
                    }
                }
                menus[key] = obj;
            }
        });
        this.setState({
            menusToAdd: menus,
        });
        console.log("menus to add", JSON.stringify(menus));
    };

    render() {
        const {classes} = this.props;
        const {companyList, menusList, companyId} = this.state;
        const options = [
            {value: 0, label: 'Portal', name: 'Portal role'},
            {value: 1, label: 'App', name: 'App Role'}
        ];


        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                <ItemGrid xs={12}>
                    {this.state.showMessagealert}
                    {this.state.basicAlert}
                    <RegularCard
                        cardTitle={""}
                        isLoading={this.state.isLoading}
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Menu Source
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.menuTypeList}
                                                onSelect={this.onSelect}
                                                stateKey="menuTypeList"
                                                staticData={options}
                                            />

                                        </ItemGrid>
                                    </GridContainer>

                                </ItemGrid>

                                <ItemGrid xs={12} md={6}>

                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel className={classes.label}>
                                                Company<span style={{color: "red", fontSize: "12px"}}>*</span>
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={8}>
                                            <SelectboxTemp
                                                defValue={this.state.companyId}
                                                onSelect={this.onSelect}
                                                isRefresh={this.state.reloadList}
                                                setIsRefresh={() => {
                                                    this.setState({reloadList: false})
                                                }}
                                                stateKey="companyId"
                                                staticData={companyList}
                                            />
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                {companyId !== "" ?
                                    <ItemGrid xs={12}>
                                        <GridContainer>

                                            <ItemGrid xs={12} sm={2} style={{marginTop: "10px"}}>
                                                <InputLabel className={classes.label}>
                                                    Menus List
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={10} style={{maxHeight: 400, overflow: "auto"}}>
                                                <GridContainer>
                                                    {menusList.map((prop, key) => {
                                                        var menuID = prop.menuId;
                                                        var menuToAdd = this.state.menusToAdd;
                                                        //  console.log("menusToAdd",key);
                                                        // var checkedIndex = this.state.menusToSend.indexOf(prop);
                                                        var index = menuToAdd.findIndex(obj => obj.menuId === prop.menuId);

                                                        if (menuToAdd[index] !== undefined)
                                                            return (
                                                                <ItemGrid xs={12}>
                                                                    <GridContainer>
                                                                        <ItemGrid xs={12} sm={12}>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        tabIndex={-1}
                                                                                        value={menuToAdd[index].isEnable}
                                                                                        checked={menuToAdd[index].isEnable}
                                                                                        onChange={
                                                                                            this.handleChange(prop, 0)
                                                                                        }
                                                                                        // onClick={() => this.handleToggle(prop)}
                                                                                        checkedIcon={
                                                                                            <Check
                                                                                                className={classes.checkedIcon}/>
                                                                                        }
                                                                                        icon={<Check
                                                                                            className={classes.uncheckedIcon}/>}
                                                                                        classes={{
                                                                                            checked: classes.checked
                                                                                        }}
                                                                                        style={{marginLeft: 10}}

                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    label: classes.menulabel
                                                                                }}
                                                                                label={prop.menuName}
                                                                            />

                                                                        </ItemGrid>
                                                                        <ItemGrid xs={12} sm={12}>
                                                                            {prop.subMenus.map((prop, key) => {
                                                                                var subIndex = menuToAdd.findIndex(obj => obj.menuId === prop.menuId);
                                                                                return (
                                                                                    <GridContainer>
                                                                                        <ItemGrid xs={12} sm={12}>

                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox
                                                                                                        // checked={this.getDefaultChecked(menuID)}
                                                                                                        tabIndex={-1}
                                                                                                        value={menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                        checked={menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                        onChange={
                                                                                                            this.handleSubMenuChange(menuID, prop, 0)
                                                                                                        }
                                                                                                        //  onClick={() => this.handleToggle(prop.menuId)}
                                                                                                        checkedIcon={
                                                                                                            <Check
                                                                                                                className={classes.checkedIcon}/>
                                                                                                        }
                                                                                                        icon={
                                                                                                            <Check
                                                                                                                className={classes.uncheckedIcon}/>}
                                                                                                        classes={{
                                                                                                            checked: classes.checked
                                                                                                        }}
                                                                                                        style={{marginLeft: 30}}
                                                                                                    />
                                                                                                }
                                                                                                classes={{
                                                                                                    label: classes.menulabel
                                                                                                }}
                                                                                                label={prop.menuName}
                                                                                            />


                                                                                        </ItemGrid>
                                                                                        {prop.subSubMenus.map((prop, key) => {
                                                                                            var subSubindex = menuToAdd.findIndex(obj => obj.menuId === prop.menuId);
                                                                                            return (
                                                                                                <ItemGrid xs={12}
                                                                                                          sm={12}>

                                                                                                    <FormControlLabel
                                                                                                        control={
                                                                                                            <Checkbox
                                                                                                                tabIndex={-1}
                                                                                                                value={menuToAdd[subSubindex]&&menuToAdd[subSubindex].isEnable && menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                                checked={menuToAdd[subSubindex]&&menuToAdd[subSubindex].isEnable && menuToAdd[index].isEnable && menuToAdd[subIndex].isEnable}
                                                                                                                onChange={
                                                                                                                    this.handleSubSubMenuChange(menuID, prop, 0)
                                                                                                                }
                                                                                                                //  onClick={() => this.handleToggle(prop.menuId)}
                                                                                                                checkedIcon={
                                                                                                                    <Check
                                                                                                                        className={classes.checkedIcon}/>
                                                                                                                }
                                                                                                                icon={
                                                                                                                    <Check
                                                                                                                        className={classes.uncheckedIcon}/>}
                                                                                                                classes={{
                                                                                                                    checked: classes.checked
                                                                                                                }}
                                                                                                                style={{marginLeft: 60}}
                                                                                                            />
                                                                                                        }
                                                                                                        classes={{
                                                                                                            label: classes.menulabel
                                                                                                        }}
                                                                                                        label={prop.menuName}
                                                                                                    />


                                                                                                </ItemGrid>

                                                                                            )

                                                                                        })}

                                                                                    </GridContainer>

                                                                                )

                                                                            })}

                                                                        </ItemGrid>
                                                                    </GridContainer>
                                                                </ItemGrid>


                                                            )
                                                        else return null
                                                    })
                                                    }
                                                </GridContainer>
                                            </ItemGrid>

                                            <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                                <GridContainer justify="center">
                                                    <ItemGrid>
                                                        <Button color="primary"
                                                                onClick={this.onCreateButtonClick}>save</Button>
                                                    </ItemGrid>
                                                    <ItemGrid>
                                                        <Button color="danger"
                                                                onClick={this.hideAlertMessage}>Cancel</Button>
                                                    </ItemGrid>

                                                </GridContainer>

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    : null}


                            </GridContainer>
                        }
                    />

                </ItemGrid>

            </GridContainer>
        );
    }


}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },
    ...customSelectStyle,
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch
};
export default withStyles(extendedFormsStyle)(MenusMapping);
