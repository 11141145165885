import React from "react";
import { connect } from "react-redux";
import {
  fetchCompony, setComponyAction, fetchCompanies, setCompaniesAction} from "../../../src/redux/actions/company-action";

import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Link, NavLink } from "react-router-dom";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Drawer from "material-ui/Drawer";
import List from "material-ui/List";
import ListItem from "material-ui/List/ListItem";
import ListItemIcon from "material-ui/List/ListItemIcon";
import ListItemText from "material-ui/List/ListItemText";
import Hidden from "material-ui/Hidden";
import Collapse from "material-ui/transitions/Collapse";
import Dashboard from "@material-ui/icons/Dashboard";
import rightArrow from "@material-ui/icons/KeyboardArrowRight";
import Select from "react-select";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import { getCookie, removeCookie, setCookie, getComponyDetail } from "../../helper/Cookies";
import cons, { getTenantId, serverApi } from "../../helper/Consts";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import { Manager, Target, Popper } from "react-popper";
import classNames from "classnames";
import ClickAwayListener from "material-ui/utils/ClickAwayListener";
import Grow from "material-ui/transitions/Grow";
import Paper from "material-ui/Paper/Paper";
import MenuList from "material-ui/Menu/MenuList";
import MenuItem from "material-ui/Menu/MenuItem";

import Button from "material-ui/Button";
import Menu from "@material-ui/core/Menu";
import { KeyboardArrowDown } from "@material-ui/icons";
import CustomIconButton from "../../components/CustomButtons/IconButton.jsx";
import { getDataFromUrl } from "../../server/server";
import VendorLoginBnr from "../../assets/img/flags/VendorLoginBnr.png";

//import avatar from "assets/img/faces/avatar.jpg";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const componyDetail = getComponyDetail();
    props.setCompony(componyDetail);
    this.state = {
      openAvatar: false,
      /*  openStore: this.activeRoute("/Store"),
            openStorePurchaseOrder:this.activeRoute("/Store"),
            openProduction: this.activeRoute("/Production"),
            openPurchase: this.activeRoute("/Purchase"),
            openSecurity: this.activeRoute("/Security"),
            openReports: this.activeRoute("/Reports"),
            openSettings: this.activeRoute("/Settings"),*/
      miniActive: true,
      //   companyName:getCookie(cons.COMPANY_NAME)
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName, menuId, menuName, menuType) {
    if (this.props.location.pathname.indexOf(routeName) > -1) {
      //  console.log("menuId",menuId);
      setCookie(cons.MENU_ID, menuId);

      setCookie(cons.MENU_NAME, menuName);
      setCookie(cons.MENU_TYPE, menuType);
    }
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  render() {
    let supplierurl = getCookie(cons.USER_ROLE_ID);
    // console.log("url----------", supplierurl);
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      companiesList,
    } = this.props;
    /* const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret =
            classes.caret +
            " " +
            cx({
                [classes.caretRTL]: rtlActive
            });
        const collapseItemMini =
            classes.collapseItemMini +
            " " +
            cx({
                [classes.collapseItemMiniRTL]: rtlActive
            });
        const photo =
            classes.photo +
            " " +
            cx({
                [classes.photoRTL]: rtlActive
            });*/

    var links = (
      <List className={classes.list}>
        {getCookie(cons.USERID) !== "1" ? (
          <ListItem className={classes.item}>
            <NavLink
              to="/Home"
              className={
                classes.itemLink +
                " " +
                cx({ [" " + classes[color]]: this.activeRoute("/Home", 0) })
              }
            >
              <ListItemIcon className={classes.itemIcon}>
                <Dashboard />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                disableTypography={true}
                className={classes.itemText}
              />
            </NavLink>
          </ListItem>
        ) : null}
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.isHide) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.collapseItemLink +
              " " +
              cx({
                [" " + classes[color]]: this.activeRoute(
                  prop.path,
                  prop.menuId,
                  prop.menuName,
                  prop.menuType
                ),
              });
            /*
                        const navLinkClasses =
                            classes.itemLink +
                            " " +
                            cx({
                                [" " + classes[color]]: this.activeRoute(prop.path)
                            });
*/
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive,
              });
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive,
              });
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive,
              });
            return (
              <ListItem key={key} className={classes.item}>
                {/* {console.log("getting the menuId", prop)} */}
                {getTenantId(window.location.hostname) === "sls.vowerp.com" ? (
                  prop.menuId === 64 ? (
                    <a
                      href="https://sls.vowerp.com/#/Login"
                      target="_blank"
                      className={navLinkClasses}
                    >
                      <ListItemIcon className={itemIcon}>
                        <img src={prop.icon} alt="icon" />
                      </ListItemIcon>
                      {prop.name}
                    </a>
                  ) : (
                    <>
                      <NavLink
                        to={"#"}
                        className={navLinkClasses}
                        onClick={() => this.openCollapse(prop.state)}
                      >
                        <ListItemIcon className={itemIcon}>
                          {/*<prop.icon/>*/}
                          <img src={prop.icon} alt="icon" />
                        </ListItemIcon>
                        <ListItemText
                          primary={prop.name}
                          secondary={
                            <b
                              className={
                                caret +
                                " " +
                                (this.state[prop.state]
                                  ? classes.caretActive
                                  : "")
                              }
                            />
                          }
                          disableTypography={true}
                          className={itemText}
                        />
                      </NavLink>
                      <Collapse in={this.state[prop.state]} unmountOnExit>
                        <List
                          className={classes.list + " " + classes.collapseList}
                        >
                          {prop.views.map((prop, key) => {
                            if (prop.redirect) {
                              return null;
                            }
                            if (prop.isHide) {
                              return null;
                            }
                            const navLinkClasses =
                              classes.collapseItemLink +
                              " " +
                              cx({
                                [" " + classes[color]]: this.activeRoute(
                                  prop.path,
                                  prop.menuId,
                                  prop.menuName,
                                  prop.menuType
                                ),
                              });
                            const collapseItemMini =
                              classes.collapseItemMini +
                              " " +
                              cx({
                                [classes.collapseItemMiniRTL]: rtlActive,
                              });
                            if (prop.isHasSub) {
                              const navLinkClasses =
                                classes.collapseItemLink +
                                " " +
                                cx({
                                  [" " + classes[color]]: this.activeRoute(
                                    prop.path,
                                    prop.menuId,
                                    prop.menuName,
                                    prop.menuType
                                  ),
                                });
                              /*
                                                                      const navLinkClasses =
                                                                          classes.itemLink +
                                                                          " " +
                                                                          cx({
                                                                              [" " + classes[color]]: this.activeRoute(prop.path)
                                                                          });
                                              */
                              const itemText =
                                classes.itemText +
                                " " +
                                cx({
                                  [classes.itemTextMini]:
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                  [classes.itemTextMiniRTL]:
                                    rtlActive &&
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                  [classes.itemTextRTL]: rtlActive,
                                });
                              const collapseItemText =
                                classes.collapseItemText +
                                " " +
                                cx({
                                  [classes.collapseItemTextMini]:
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                  [classes.collapseItemTextMiniRTL]:
                                    rtlActive &&
                                    this.props.miniActive &&
                                    this.state.miniActive,
                                  [classes.collapseItemTextRTL]: rtlActive,
                                });
                              const itemIcon =
                                classes.itemIcon +
                                " " +
                                cx({
                                  [classes.itemIconRTL]: rtlActive,
                                });
                              const caret =
                                classes.caret +
                                " " +
                                cx({
                                  [classes.caretRTL]: rtlActive,
                                });
                              return (
                                <ListItem key={key} className={classes.item}>
                                  <NavLink
                                    to={"#"}
                                    className={navLinkClasses}
                                    onClick={() =>
                                      this.openCollapse(prop.state)
                                    }
                                  >
                                    <span className={collapseItemMini}>
                                      {prop.mini}
                                    </span>
                                    <ListItemText
                                      primary={prop.name}
                                      secondary={
                                        <b
                                          className={
                                            caret +
                                            " " +
                                            (this.state[prop.state]
                                              ? classes.caretActive
                                              : "")
                                          }
                                        />
                                      }
                                      disableTypography={true}
                                      className={collapseItemText}
                                    />
                                  </NavLink>
                                  <Collapse
                                    in={this.state[prop.state]}
                                    unmountOnExit
                                  >
                                    <List
                                      className={
                                        classes.list +
                                        " " +
                                        classes.collapseList
                                      }
                                    >
                                      {prop.subViews.map((prop, key) => {
                                        if (prop.redirect) {
                                          return null;
                                        }
                                        if (prop.isHide) {
                                          return null;
                                        }
                                        const navLinkClasses =
                                          classes.collapseItemLink +
                                          " " +
                                          cx({
                                            [" " +
                                            classes[color]]: this.activeRoute(
                                              prop.path,
                                              prop.menuId,
                                              prop.menuName,
                                              prop.menuType
                                            ),
                                          });
                                        const collapseItemMini =
                                          classes.collapseItemMini +
                                          " " +
                                          cx({
                                            [classes.collapseItemMiniRTL]: rtlActive,
                                          });
                                        return (
                                          <ListItem
                                            key={key}
                                            className={classes.collapseItem}
                                          >
                                            <NavLink
                                              to={prop.path}
                                              className={navLinkClasses}
                                            >
                                              <span
                                                className={collapseItemMini}
                                              >
                                                {prop.mini}
                                              </span>
                                              <ListItemText
                                                primary={prop.name}
                                                disableTypography={true}
                                                className={collapseItemText}
                                              />
                                            </NavLink>
                                          </ListItem>
                                        );
                                      })}
                                    </List>
                                  </Collapse>
                                </ListItem>
                              );
                            }

                            return (
                              <ListItem
                                key={key}
                                className={classes.collapseItem}
                              >
                                <NavLink
                                  to={prop.path}
                                  className={navLinkClasses}
                                >
                                  <span className={collapseItemMini}>
                                    {prop.mini}
                                  </span>
                                  <ListItemText
                                    primary={prop.name}
                                    disableTypography={true}
                                    className={collapseItemText}
                                  />
                                </NavLink>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  )
                ) : (
                  <>
                    <NavLink
                      to={"#"}
                      className={navLinkClasses}
                      onClick={() => this.openCollapse(prop.state)}
                    >
                      <ListItemIcon className={itemIcon}>
                        {/*<prop.icon/>*/}
                        <img src={prop.icon} alt="icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={prop.name}
                        secondary={
                          <b
                            className={
                              caret +
                              " " +
                              (this.state[prop.state]
                                ? classes.caretActive
                                : "")
                            }
                          />
                        }
                        disableTypography={true}
                        className={itemText}
                      />
                    </NavLink>
                    <Collapse in={this.state[prop.state]} unmountOnExit>
                      <List
                        className={classes.list + " " + classes.collapseList}
                      >
                        {prop.views.map((prop, key) => {
                          if (prop.redirect) {
                            return null;
                          }
                          if (prop.isHide) {
                            return null;
                          }
                          const navLinkClasses =
                            classes.collapseItemLink +
                            " " +
                            cx({
                              [" " + classes[color]]: this.activeRoute(
                                prop.path,
                                prop.menuId,
                                prop.menuName,
                                prop.menuType
                              ),
                            });
                          const collapseItemMini =
                            classes.collapseItemMini +
                            " " +
                            cx({
                              [classes.collapseItemMiniRTL]: rtlActive,
                            });
                          if (prop.isHasSub) {
                            const navLinkClasses =
                              classes.collapseItemLink +
                              " " +
                              cx({
                                [" " + classes[color]]: this.activeRoute(
                                  prop.path,
                                  prop.menuId,
                                  prop.menuName,
                                  prop.menuType
                                ),
                              });
                            /*
                                                                      const navLinkClasses =
                                                                          classes.itemLink +
                                                                          " " +
                                                                          cx({
                                                                              [" " + classes[color]]: this.activeRoute(prop.path)
                                                                          });
                                              */
                            const itemText =
                              classes.itemText +
                              " " +
                              cx({
                                [classes.itemTextMini]:
                                  this.props.miniActive &&
                                  this.state.miniActive,
                                [classes.itemTextMiniRTL]:
                                  rtlActive &&
                                  this.props.miniActive &&
                                  this.state.miniActive,
                                [classes.itemTextRTL]: rtlActive,
                              });
                            const collapseItemText =
                              classes.collapseItemText +
                              " " +
                              cx({
                                [classes.collapseItemTextMini]:
                                  this.props.miniActive &&
                                  this.state.miniActive,
                                [classes.collapseItemTextMiniRTL]:
                                  rtlActive &&
                                  this.props.miniActive &&
                                  this.state.miniActive,
                                [classes.collapseItemTextRTL]: rtlActive,
                              });
                            const itemIcon =
                              classes.itemIcon +
                              " " +
                              cx({
                                [classes.itemIconRTL]: rtlActive,
                              });
                            const caret =
                              classes.caret +
                              " " +
                              cx({
                                [classes.caretRTL]: rtlActive,
                              });
                            return (
                              <ListItem key={key} className={classes.item}>
                                <NavLink
                                  to={"#"}
                                  className={navLinkClasses}
                                  onClick={() => this.openCollapse(prop.state)}
                                >
                                  <span className={collapseItemMini}>
                                    {prop.mini}
                                  </span>
                                  <ListItemText
                                    primary={prop.name}
                                    secondary={
                                      <b
                                        className={
                                          caret +
                                          " " +
                                          (this.state[prop.state]
                                            ? classes.caretActive
                                            : "")
                                        }
                                      />
                                    }
                                    disableTypography={true}
                                    className={collapseItemText}
                                  />
                                </NavLink>
                                <Collapse
                                  in={this.state[prop.state]}
                                  unmountOnExit
                                >
                                  <List
                                    className={
                                      classes.list + " " + classes.collapseList
                                    }
                                  >
                                    {prop.subViews.map((prop, key) => {
                                      if (prop.redirect) {
                                        return null;
                                      }
                                      if (prop.isHide) {
                                        return null;
                                      }
                                      const navLinkClasses =
                                        classes.collapseItemLink +
                                        " " +
                                        cx({
                                          [" " +
                                          classes[color]]: this.activeRoute(
                                            prop.path,
                                            prop.menuId,
                                            prop.menuName,
                                            prop.menuType
                                          ),
                                        });
                                      const collapseItemMini =
                                        classes.collapseItemMini +
                                        " " +
                                        cx({
                                          [classes.collapseItemMiniRTL]: rtlActive,
                                        });
                                      return (
                                        <ListItem
                                          key={key}
                                          className={classes.collapseItem}
                                        >
                                          <NavLink
                                            to={prop.path}
                                            className={navLinkClasses}
                                          >
                                            <span className={collapseItemMini}>
                                              {prop.mini}
                                            </span>
                                            <ListItemText
                                              primary={prop.name}
                                              disableTypography={true}
                                              className={collapseItemText}
                                            />
                                          </NavLink>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Collapse>
                              </ListItem>
                            );
                          }

                          return (
                            <ListItem
                              key={key}
                              className={classes.collapseItem}
                            >
                              <NavLink
                                to={prop.path}
                                className={navLinkClasses}
                              >
                                <span className={collapseItemMini}>
                                  {prop.mini}
                                </span>
                                <ListItemText
                                  primary={prop.name}
                                  disableTypography={true}
                                  className={collapseItemText}
                                />
                              </NavLink>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                )}

                {/* {prop.menuId === 159 ? "" : prop.menuId === 158 ? "" : ""} */}
              </ListItem>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(
                prop.path,
                prop.menuId,
                prop.menuName,
                prop.menuType
              ),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive,
            });
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  <img src={prop.icon} alt="icon" />
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
        {supplierurl === "100" ? (
          <div
            style={{
              textAlign: "center",
              cursor: "pointer",
              padding: "15px",
              textcolor: "primery",
            }}
            onClick={() => {
              window.open("https://vowerp.com/#contact");
            }}
          >
            <img src={VendorLoginBnr} width="100%" />
          </div>
        ) : (
          ""
        )}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const options = [
      {
        value: 1,
        label: "NJM-NELLIMARLA JUTE MILLS PVT.LTD",
        name: "NJM PVT.LTD",
      },
      { value: 2, label: "EMP-EMPIRE JUTE MILLS", name: "EMP" },
      { value: 3, label: "SWARNA-SWARNA TECHNOLOGY PVT.LTD", name: "SWARNA" },
    ];

    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: rtlActive,
      });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    const wrapper =
      classes.wrapper +
      " " +
      cx({
        [classes.wrapperRTL]: rtlActive,
      });

    //   var open=false;
    const handleClick = () => {
      // open=!open;
      this.setState({ open: !this.state.open });
    };
    const handleClose = () => {
      this.setState({ open: false });
      //   open=false;
    };
    var text = logoText;
    var companies = null;
    const handleMenuClose = (company) => {
      if (company.value !== this.props.state.companyInfo.id + "") {
        getDataFromUrl(
          serverApi.GET_ACADEMIC_YEAR + company.value,
          handleYears
        );
        this.props.setCompony(company)
        companies = company;
      }
    };
    const handleYears = (resp) => {
      if (resp.data.length > 0) {
        var yearsData = [];
        resp.data.forEach((prop, i) => {
          var obj = { label: prop.displayLable, value: prop.year };
          yearsData.push(obj);
          if (i === 0) {
            setCookie(cons.ACADEMIC_YEAR, prop.year);
          }
        });
        setCookie(cons.ACADEMIC_YEAR_LIST, JSON.stringify(yearsData));
        var company_name = companies.label.split("-");
        setCookie(cons.COMPANY_TITLE, company_name[1]);
        setCookie(cons.COMPANY_ADDRESS, companies.address1);
        setCookie(cons.COMPANY_ADDRESS2, companies.address2);
        setCookie(cons.COMPANY_MAIL_ID, companies.emailId);
        setCookie(cons.COMPANY_NAME, companies.name);
        setCookie(cons.COMPANY_ID, companies.value);
        setCookie(cons.USER_GRP_ID, companies.userGroupId);
        setCookie(cons.COMPANY_CONTACT_NO, companies.contactNo);
        setCookie(cons.COMPANY_GST, companies.gstNo);
        setCookie(cons.COMPANY_ICON, companies.companyIcon);
        setCookie(cons.COMPANY_LOGO, companies.companyLogo);
        setCookie(cons.COMPANY_CIN, companies.cinNo);
        setCookie(cons.SHIPPING_ADDRESS, companies.companyAddress2);
        setCookie(cons.COMPANY_STATECODE2, companies.stateCode2);
        setCookie(cons.COMPANY_GST2, companies.gstNo2);
        setCookie(cons.COMPANY_STATECODE, companies.stateCode1);
        setCookie(cons.COMPANY_STATE, companies.stateName1);
        setCookie(cons.COMPANY_STATE2, companies.stateName2);
        setCookie(cons.CONTACT_PERSON, companies.contactPerson);
        const componyDetail = getComponyDetail();
        this.props.setCompony(componyDetail);
        if (companies.configList) {
          if (companies.configList.length > 0)
            setCookie(cons.CONFIG_LIST, JSON.stringify(companies.configList));
          else removeCookie(cons.CONFIG_LIST);
        }
        this.props.refreshDashboard();
      } else {
        alert("No Financial Year Available to Display!!");
      }
    };

    /*
             const  handleMenuClose=(company)=>{
                 if(company.value!==this.props.state.companyInfo.id+""){
              /!*       setCookie(cons.COMPANY_NAME, companyName);
                     setCookie(cons.COMPANY_ID, id);
                     setCookie(cons.USER_GRP_ID, grpId);*!/
                     var company_name=(company.label).split('-');
                     setCookie(cons.COMPANY_TITLE, company_name[1]);
                     setCookie(cons.COMPANY_ADDRESS, company.address1);
                     setCookie(cons.COMPANY_ADDRESS2, company.address2);
                     setCookie(cons.COMPANY_MAIL_ID, company.emailId);
                     setCookie(cons.COMPANY_NAME, company.name);
                     setCookie(cons.COMPANY_ID, company.value);
                     setCookie(cons.USER_GRP_ID,company.userGroupId);
                     setCookie(cons.COMPANY_CONTACT_NO,company.contactNo);
                     setCookie(cons.COMPANY_GST,company.gstNo);
                     //console.log("COMPANY_NAME", getCookie(cons.COMPANY_NAME));
                     this.props.refreshDashboard();
                 }
                };
        */

    var companies = [];
    var company = this.props.state.companyInfo.name;
    if (
      getCookie(cons.COMPANIES_LIST) !== null &&
      getCookie(cons.COMPANIES_LIST) !== undefined
    )
    var componyList = JSON.parse(getCookie(cons.COMPANIES_LIST))
      this.props.setCompanies(componyList)
      companies = this.props.state.companies;
    var brand = (
      <div className={logoClasses}>
        <Button round={true} className={classes.button} onClick={handleClick}>
          <a className={logoMini}>
            <img src={logo} alt="logo" className={classes.img} />{" "}
          </a>
          <div className={wrapper}>
            <div>
              <Manager className={managerClasses}>
                <Target>
                  <span className={logoNormal}>{company}</span>
                </Target>
                <Popper
                  placement="bottom-start"
                  eventsEnabled={this.state.open}
                  className={
                    classNames({ [classes.popperClose]: !this.state.open }) +
                    " " +
                    classes.pooperResponsive
                  }
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Grow
                      in={this.state.open}
                      id="menu-list"
                      style={{ transformOrigin: "0 0 0" }}
                    >
                      <Paper className={classes.dropdown} onClick={handleClose}>
                        <MenuList role="menu">
                          {companies.map((option) => (
                            <MenuItem
                              selected={
                                +option.value=== +this.props.state.companyInfo.id
                              }
                              data={option}
                              onClick={() => {
                                handleClose();
                                handleMenuClose(option);
                              }}
                              className={dropdownItem}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                </Popper>
              </Manager>
            </div>
          </div>
          <KeyboardArrowDown color={"primary"} />
        </Button>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={
                <HeaderLinks
                  rtlActive={rtlActive}
                  handleLogout={this.props.handleLogout}
                  handleProfile={this.props.handleProfile}
                  handleSettings={this.props.handleSettings}
                  handleTallySync={this.props.handleTallySync}
                />
              }
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                // style={{backgroundImage: "url(" + image + ")"}}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "litegreen",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  companiesList: PropTypes.arrayOf(PropTypes.object),
};

// export default withStyles(sidebarStyle)(Sidebar);


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompony: () => dispatch(fetchCompony()),
    setCompony: (componyInfo) => dispatch(setComponyAction(componyInfo)),
    getCompanies: () => dispatch(fetchCompanies()),
    setCompanies: (companies) => dispatch(setCompaniesAction(companies)),
  };
};

function mapStateToProps(state) {
  return { state: state.compony };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sidebarStyle)(Sidebar));
