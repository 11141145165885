import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import { getMenuPermission } from "../CustumComponents/PermissionAlert";
import Datetime from "react-datetime";
import FormControl from "material-ui/Form/FormControl";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import { VIEW } from "../../ui-actions/UiActions";
import { RoundOffTypes } from "../TableData";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";

class PaySchemeParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      fromDate: "",
      todate: "",
      status: "",
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      approveStatus: false,
      date1: moment().format("DD-MM-YYYY"),
      year: moment().format("YYYY"),
      issueStatus: "3",
      paramId: "",
      paramCode: "",
      paramName: "",
      dataTable: null,
      tableList: [],
      description: "",
      parameterCategory: "",
      effectiveFrom: moment().format("DD-MM-YYYY"),
      effectiveTill: moment().format("DD-MM-YYYY"),
      isCustom: false,
      isDisplayable: false,
      isOccationally: false,
      parent: "",
      defaultValue: 0,
      roundOfType: "",
      //   roundToDecimals: [
      //     { value: 1, name: "1", label: "1" },
      //     { value: 2, name: "2", label: "2" },
      //     { value: 3, name: "3", label: "3" },
      //     { value: 4, name: "4", label: "4" },
      //     { value: 5, name: "5", label: "5" },
      //     { value: 6, name: "6", label: "6" },
      //   ],
      roundToDecimals: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    //this.mapParamCategory=this.mapParamCategory.bind(this);
  }

  handleSeacrh = () => {
    var loading = false;
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };

  handleViewClick = (id) => {
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.PAY_SCHEME_DATA_GET_BYID + "/" + id,
        this.handleViewClickResp
      );
    }
  };

  format(inputDate) {
    var splitDate = inputDate.split("-");
    if (splitDate.count == 0) {
      return null;
    }
    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];
    return day + "-" + month + "-" + year;
  }

  handleViewClickResp = (resp) => {
    var header = resp;
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        paramId: header.id,
        paramCode: header.code,
        paramName: header.name,
        parameterCategory: header.type,
        loadType: true,
        description: header.description,
        effectiveFrom: this.format(header.effectiveFrom),
        effectiveTill: this.format(header.endsOn),
        isCustom: header.isCustomComponent,
        isDisplayable: header.isDisplayableInPayslip,
        isOccationally: header.isOccasionally,
        parent: header.parentId,
        defaultValue: header.defaultValue,
        status: header.status,
        roundToDecimals: header.roundOf,
        createdBy: header.createdBy,
        createdOn: header.createdDateTime,
        roundOfType: header.roundOfType,
        isExcelDownloadable: header.isExcelDownloadable,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    if (this.state.isCreated) {
      this.setState({
        canChangeMukam: true,
        canChangeUnit: true,
      });
    }
    this.setState({
      isCreated: !this.state.isCreated,
    });
    //  this.hideAlertMessage();
  };

  mainMenu = () => {
    this.hideAlertMessage();
    //this.props.history.push("/payscheme");
  };
  getData = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    return data;
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  hasWhiteSpace(s) {
    let reWhiteSpace = new RegExp(/\s/);
    if (reWhiteSpace.test(s)) {
      this.basicAlert(" Space is not Allowed..!!");
      return false;
    } else {
      this.setState({
        paramCode: s,
        paramName: s,
      });
    }
    return true;
  }
  checkLength = (id, value, length) => {
    var values = value.length;
    if (values >= length) {
      this.basicAlert(
        "You have exceeded your maximum limit of characters allowed..!!"
      );
    }
  };

  onChangeValues = (event, id, length) => {
    var value = event.target.value;
    if (length) {
      this.checkLength(event, id, length);
    }
    if (id === "paramCode") {
      this.hasWhiteSpace(value);
    } else {
      this.setState({
        [id]: value,
      });
    }
  };

  onSelect = (selectedValue, stateKey) => {
    this.setState({
      [stateKey]: selectedValue,
    });
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
    }
    if (this.state.deleteParameter) {
      this.handleLoading();
      getDataFromUrl(
        serverApi.PAY_SCHEME_PARAMETERS_DELETE_BYID +
          this.state.paramId +
          "/" +
          getCookie(cons.USERID),
        this.handleAddParameter
      );
    }
  }

  hideAlert() {
    this.setState({
      updateItem: false,
      isJuteSelected: false,
      hideJuteType: false,
      alert: false,
      showMessagealert: false,
      loading: false,
    });
  }

  hideAlertMessage() {
    // if(this.state.isCreated){
    this.callPaySchemeParameters();
    // }
    this.setState({
      loadType: true,
      canChangeMukam: true,
      canChangeUnit: true,
      isCreated: false,
      isExcelDownloadable: false,
      approveStatus: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      paramCode: "",
      paramName: "",
      parameterCategory: "",
      description: "",
      effectiveFrom: moment().format("DD-MM-YYYY"),
      effectiveTill: moment().format("DD-MM-YYYY"),
      isCustom: false,
      isDisplayable: false,
      isOccationally: false,
      parent: "",
      defaultValue: 0,
      roundToDecimals: "",
      roundOfType: "",
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }
  roundToDecimalsData = [
    { value: 0, name: "0", label: "0" },
    { value: 1, name: "1", label: "1" },
    { value: 2, name: "2", label: "2" },
    { value: 3, name: "3", label: "3" },
    { value: 4, name: "4", label: "4" },
    { value: 5, name: "5", label: "5" },
    { value: 6, name: "6", label: "6" },
  ];

  paramCategory = [
    { value: 1, name: "Earning", label: "Earning" },
    { value: 2, name: "Deduction", label: "Deduction" },
    { value: 0, name: "noCalculation", label: "No Calculation" },
    { value: 3, name: "Summary", label: "Summary" },
  ];

  hideDeleteAlert(id) {
    this.setState({ basicAlert: null });
    getDataFromUrl(
      serverApi.DATATABLE_SERVICE_URL +
        "/deleteJuteIssue/" +
        id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleDeleteClickResp
    );
  }

  basicDeleteAlert(msg, id) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideDeleteAlert(id)}
          onCancel={() => this.hideBasicAlert()}
          showCancel
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onUpdateButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        paramId,
        paramCode,
        paramName,
        parameterCategory,
        description,
        effectiveFrom,
        effectiveTill,
        isExcelDownloadable,
        isCustom,
        isDisplayable,
        isOccationally,
        defaultValue,
        roundToDecimals,
        parent,
        status,
        roundOfType,
      } = this.state;
      var data = {
        code: paramCode,
        name: paramName,
        type: parameterCategory,
        id: paramId,
        defaultValue: defaultValue,
        description: description,
        displayableInPayslip: isDisplayable,
        effectiveFrom: effectiveFrom,
        endsOn: effectiveTill,
        isCustomComponent: isCustom,
        modifiedBy: getCookie(cons.USERID),
        occasionally: isOccationally,
        parentId: parent,
        roundOf: roundToDecimals,
        status: status,
        lupdate: moment().format("YYYY-MM-DD"),
        roundOfType: roundOfType,
        isExcelDownloadable: isExcelDownloadable,
      };

      if (
        paramCode !== "" &&
        paramName !== "" &&
        parameterCategory !== "" &&
        effectiveFrom !== "" &&
        effectiveTill !== "" &&
        roundToDecimals !== ""
      ) {
        this.handleLoading();
        requestList(
          serverApi.PAY_SCHEME_UPDATE_PARAMS,
          data,
          this.handleAddParameter
        );
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        paramCode,
        paramName,
        parameterCategory,
        description,
        effectiveFrom,
        effectiveTill,
        isExcelDownloadable,
        isCustom,
        isDisplayable,
        isOccationally,
        defaultValue,
        roundToDecimals,
        parent,
        roundOfType,
      } = this.state;
      var data = {
        code: paramCode,
        name: paramName,
        type: parameterCategory,
        description: description,
        effectiveFrom: effectiveFrom,
        endsOn: effectiveTill,
        isCustomComponent: isCustom,
        displayableInPayslip: isDisplayable,
        occasionally: isOccationally,
        parentId: parent,
        defaultValue: defaultValue,
        roundOf: roundToDecimals,
        createdBy: getCookie(cons.USERID),
        lupdate: moment().format("YYYY-MM-DD"),
        roundOfType: roundOfType,
        isExcelDownloadable: isExcelDownloadable,
        /* "companyId": this.props.state.companyInfo.id  */
      };

      // new code for validation...

      const validateFields = [
        { field: paramCode, msg: "Code", type: 1 },
        { field: paramName, msg: "Name", type: 2 },
        { field: parameterCategory, msg: "Type", type: 2 },

        // { field: wageType, msg: "Wage Type", type: 2 },
      ];

      const validObj = validate(validateFields);

      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message}...!!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message}...!!!`);
        }
      } else {
        // this.basicAlert("Created Successfully!!!");
        this.handleLoading();
        requestList(
          serverApi.PAY_SCHEME_ADD_PARAMS,
          data,
          this.handleAddParameter
        );
      }

      // old code for validation...

      // if (
      //   paramCode !== "" &&
      //   paramName !== "" &&
      //   parameterCategory !== "" &&
      //   effectiveFrom !== "" &&
      //   effectiveTill !== "" &&
      //   roundToDecimals !== ""
      // ) {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.PAY_SCHEME_ADD_PARAMS,
      //     data,
      //     this.handleAddParameter
      //   );
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fields..!!");
      // }
    }
  };
  handleAddParameter = (resp) => {
    if (resp.status) {
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item

  showDeleteMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  //effective date and end date
  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else if (id !== "date1")
      this.setState({
        [id]: "",
      });

    this.dateChanged();
  };
  dateChanged = () => {
    var myDate = this.state.date1;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };
  onCheckBox = (obj, id) => {
    var values = obj.target.value;
    var value;
    if (values === "1") {
      value = false;
    } else {
      value = true;
    }

    this.setState({
      [id]: value,
    });
  };

  componentDidMount() {
    this.callPaySchemeParameters();
    /*  this.getParamsCategory(); */
    this.setState({
      roundToDecimalsData: this.roundToDecimalsData,
      loadType: true,
    });
    this.setState({ paramCategory: this.paramCategory, loadType: true });
  }
  getParamsCategory() {
    getDataFromUrl(
      serverApi.GET_PAY_SCHEME_PARAMETERS_CATEGORY,
      this.handleparamCategory
    );
  }
  handleparamCategory = (resp) => {
    var ppcategory = [];
    resp.map((p, i) => {
      var obj = {
        value: p.ppcid,
        name: p.categoryName,
        label: p.categoryName,
      };
      ppcategory.push(obj);
    });

    this.setState({ paramCategory: ppcategory });
  };

  mapParamCategory(value) {
    let result = "";
    this.paramCategory.forEach(function(param) {
      if (param.value + "" === value + "") {
        result = param.label;
      }
    });

    return result;
  }
  mapParentId(value) {
    let result = "";
    if (value === 0) {
      result = "";
    } else {
      this.state.parentData.forEach(function(parent) {
        if (parent.value === value) {
          result = parent.name;
        }
      });
    }
    return result;
  }
  callPaySchemeParameters() {
    getDataFromUrl(serverApi.PAY_SCHEME_DATATABLE, this.handleparameters);
  }
  handleparameters = (resp) => {
    var parent = [];
    resp.forEach(function(p, i) {
      if (i === 0) {
        var obj1 = {
          value: null,
          name: "",
          label: "Select...",
        };
        parent.push(obj1);
      }
      var obj = {
        value: p.id,
        name: p.code,
        label: p.code,
      };
      parent.push(obj);
    });
    this.setState({ parentData: parent, loadType: true });
    var that = this;
    resp.forEach(function(item) {
      if (item.isOccasionally !== undefined)
        item.isOccasionally = item.isOccasionally ? "True" : "False";
      if (item.isCustomComponent !== undefined)
        item.isCustomComponent = item.isCustomComponent ? "True" : "False";
      if (item.isDisplayableInPayslip !== undefined)
        item.isDisplayableInPayslip = item.isDisplayableInPayslip
          ? "True"
          : "False";
      if (item.isExcelDownloadable !== undefined)
        item.isExcelDownloadable = item.isExcelDownloadable ? "True" : "False";

      item.type = that.mapParamCategory(item.type);
      item.parentId = that.mapParentId(item.parentId);
    });
    var parameters = [];
    var tableList = {
      data: resp,
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsAccess: false,
      column: [
        {
          filterable: "true",
          Header: "Code",
          accessor: "code",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Name",
          accessor: "name",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Description",
          accessor: "description",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Type",
          accessor: "type",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Effective From",
          accessor: "effectiveFrom",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Effective Till",
          accessor: "endsOn",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Is Custom",
          accessor: "isCustomComponent",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Is Displayable",
          accessor: "isDisplayableInPayslip",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Is Occationally",
          accessor: "isOccasionally",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Is Excel Downloadable",
          accessor: "isExcelDownloadable",
          minWidth: 120,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Parent",
          accessor: "parentId",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Default Value",
          accessor: "defaultValue",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: " Round To Decimals",
          accessor: "roundOf",
          minWidth: 100,
          style: { textAlign: "center" },
          sortable: "true",
        },
      ],
    };

    this.setState({ isRefresh: true, tdata: tableList });
  };

  render() {
    const { classes } = this.props;
    const { selectedJuteType } = this.state;

    const inputFocus = {
      autoComplete: "off",
    };
    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        <ItemGrid xs={12}>
          <RegularCard
            isLoading={this.state.isLoading}
            /*  cardTitle={
                             <Button color={"info"} onClick={this.mainMenu}>
                                 Back To List</Button>
                         } */
            content={
              <GridContainer>
                {this.state.basicAlert}
                {this.state.showMessagealert}
                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Code
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="paramCode"
                        value={this.state.paramCode}
                        className={classes.textField}
                        onChange={(codeObj) => {
                          this.onChangeValues(codeObj, "paramCode");
                        }}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Name
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="paramName"
                        value={this.state.paramName}
                        className={classes.textField}
                        onChange={(codeObj) => {
                          this.onChangeValues(codeObj, "paramName", 20);
                        }}
                        fullWidth={true}
                        InputProps={inputFocus}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Type
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    {this.state.paramCategory !== undefined ? (
                      <ItemGrid xs={12} sm={8}>
                        {console.log(
                          "getting the parameterCategory",
                          this.state.parameterCategory
                        )}
                        <SelectboxTemp
                          defValue={
                            this.state.parameterCategory === ""
                              ? 1
                              : this.state.parameterCategory
                          }
                          id={"parameterCategory"}
                          onSelect={this.onSelect}
                          //getName={this.getQualityDetails}
                          isRefresh={this.state.loadType}
                          setIsRefresh={(val) => {
                            this.setState({ loadType: false });
                          }}
                          stateKey="parameterCategory"
                          staticData={this.state.paramCategory}
                          value={this.state.parameterCategory}
                        />
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>
                {/* date fields are starting from here */}
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Effective From
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "effectiveFrom");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "effectiveFrom",
                            value: this.state.effectiveFrom,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Effective Till
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          onChange={(dateObj) => {
                            this.timeChanged(dateObj, "effectiveTill");
                          }}
                          formControlProps={{
                            height: "10px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "effectiveTill",
                            value: this.state.effectiveTill,
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                {/* date fields end here */}
                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>Parent</InputLabel>
                    </ItemGrid>
                    {this.state.parentData !== undefined ? (
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.parent}
                          id={"parent"}
                          onSelect={this.onSelect}
                          stateKey="parent"
                          isRefresh={this.state.loadType}
                          setIsRefresh={(val) => {
                            this.setState({ loadType: false });
                          }}
                          staticData={this.state.parentData}
                          value={this.state.parent}
                        />
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Is Custom
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              value={this.state.isCustom ? 1 : 0}
                              checked={this.state.isCustom ? 1 : 0}
                              onChange={(obj) => {
                                this.onCheckBox(obj, "isCustom");
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          }
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Is Displayable
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={this.state.isDisplayable ? 1 : 0}
                              checked={this.state.isDisplayable ? 1 : 0}
                              onChange={(obj) => {
                                this.onCheckBox(obj, "isDisplayable");
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          }
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Is Occationally
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={this.state.isOccationally ? 1 : 0}
                              checked={this.state.isOccationally ? 1 : 0}
                              onChange={(obj) => {
                                this.onCheckBox(obj, "isOccationally");
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          }
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} sm={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Is Excel Downloadable
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <FormControl variant="outlined" fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              value={this.state.isExcelDownloadable ? 1 : 0}
                              checked={this.state.isExcelDownloadable ? 1 : 0}
                              onChange={(obj) => {
                                this.onCheckBox(obj, "isExcelDownloadable");
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          }
                        />
                      </FormControl>
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Round To Decimals
                        {console.log(
                          "this.state.roundToDecimals",
                          this.state.roundToDecimals
                        )}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </InputLabel>
                    </ItemGrid>
                    {this.state.roundToDecimals !== undefined ? (
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={
                            this.state.roundToDecimals === ""
                              ? 2
                              : this.state.roundToDecimals
                          }
                          id={"roundToDecimals"}
                          onSelect={this.onSelect}
                          stateKey="roundToDecimals"
                          isRefresh={this.state.loadType}
                          setIsRefresh={(val) => {
                            this.setState({ loadType: false });
                          }}
                          staticData={this.state.roundToDecimalsData}
                          value={this.state.roundToDecimals}
                        />
                      </ItemGrid>
                    ) : null}
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Round of Type
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <SelectboxTemp
                        defValue={
                          this.state.roundOfType === ""
                            ? 1
                            : this.state.roundOfType
                        }
                        onSelect={this.onSelect}
                        stateKey="roundOfType"
                        isRefresh={this.state.loadType}
                        setIsRefresh={(val) => {
                          this.setState({ loadType: false });
                        }}
                        staticData={RoundOffTypes}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Default Value
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="defaultValue"
                        value={this.state.defaultValue}
                        className={classes.defaultValue}
                        onChange={(codeObj) => {
                          this.onChangeValues(codeObj, "defaultValue", 199);
                        }}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>
                <ItemGrid xs={12} md={4} style={{ marginTop: "10px" }}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={4}>
                      <InputLabel className={classes.label}>
                        Description
                      </InputLabel>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={8}>
                      <TextField
                        id="description"
                        value={this.state.description}
                        className={classes.textField}
                        onChange={(codeObj) => {
                          this.onChangeValues(codeObj, "description", 199);
                        }}
                        fullWidth={true}
                        InputProps={{
                          autoComplete: "off",
                        }}
                        height={60}
                        multiline={true}
                        rowsMax={3}
                      />
                    </ItemGrid>
                  </GridContainer>
                </ItemGrid>

                <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                  <GridContainer justify="center" />
                </ItemGrid>

                {!this.state.updateView ? (
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      <ItemGrid>
                        <Button
                          color="primary"
                          onClick={this.onCreateButtonClick}
                        >
                          Create
                        </Button>
                      </ItemGrid>
                      <ItemGrid>
                        <Button color="danger" onClick={this.mainMenu}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                ) : (
                  <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                    <GridContainer justify="center">
                      <ItemGrid>
                        <Button
                          color="primary"
                          onClick={this.onUpdateButtonClick}
                        >
                          Update
                        </Button>
                      </ItemGrid>
                      <ItemGrid>
                        <Button color="danger" onClick={this.mainMenu}>
                          Cancel
                        </Button>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                )}

                {/*<ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>*/}

                {/*<ReactTable*/}
                {/*data={this.state.tableList}*/}
                {/*filterable={false}*/}
                {/*resizable={false}*/}
                {/*loading={this.state.loading}*/}
                {/*getTableProps={() => {*/}
                {/*return {*/}
                {/*style: {*/}
                {/*border: "2px solid #00ABDC",*/}
                {/*borderRadius: "4px"*/}
                {/*}*/}
                {/*}}}*/}

                {/*getTheadProps={() => {*/}
                {/*return {*/}
                {/*style: {*/}
                {/*borderBottom: "2px solid #00ABDC",*/}
                {/*//height:45*/}
                {/*//lineHeight:"1.3"*/}
                {/*}*/}
                {/*}}}*/}
                {/*getTheadThProps={() => {*/}
                {/*return {*/}
                {/*style: {*/}
                {/*borderRight: "1px solid #999999",*/}
                {/*textAlign:"center",*/}
                {/*whiteSpace:"pre-wrap",*/}
                {/*lineHeight:1.3,*/}
                {/*}*/}
                {/*}}}*/}
                {/*style={{color: "#00ABDC", textAlign: "center",overflowY: "auto"}}*/}
                {/*columns={[*/}
                {/*{*/}
                {/*Header: "Parameter Code",*/}
                {/*accessor: "paramCode",*/}
                {/*minWidth:30,*/}
                {/*style: {color: "#000", textAlign: "left"}*/}

                {/*},*/}
                {/*{*/}
                {/*Header: "Parameter Name",*/}
                {/*accessor: "paramName",*/}
                {/*minWidth:50,*/}
                {/*style: {color: "#000", textAlign: "center"}*/}
                {/*},*/}

                {/*{*/}
                {/*Header: "Parameter Category",*/}
                {/*accessor: "pCategory",*/}
                {/*minWidth:30,*/}
                {/*style: {color: "#000", textAlign: "center"},*/}
                {/*sortable: false,*/}
                {/*filterable: false,*/}
                {/*}*/}
                {/*]}*/}
                {/*showPagination={false}*/}
                {/*pageSize={this.getInvoiceTablePageSize()}*/}
                {/*// defaultPageSize={5}*/}
                {/*sortable={false}*/}
                {/*getNoDataProps={()=>{*/}
                {/*return{*/}
                {/*style:{*/}
                {/*display:"none"*/}
                {/*}*/}

                {/*};*/}
                {/*}}*/}
                {/*className="-striped -highlight"*/}
                {/*/>*/}

                {/*</ItemGrid>*/}
                <ItemGrid
                  xs={12}
                  style={{
                    height: 2,
                    backgroundColor: "#00acc1",
                    marginTop: "5px",
                  }}
                />

                <ItemGrid xs={12}>
                  {console.log("tableeeee", this.state.tdata)}
                  {/*{this.state.dataTable!==null?*/}
                  <TableComponent
                    //  url={serverApi.ACCOUNT_BILLS_TABLE}
                    //searchData={this.getData()}
                    isRefresh={this.state.isRefresh}
                    handleSearch={this.handleSeacrh.bind(this)}
                    staticData={this.state.tdata}
                    isLoading={this.state.isRefresh}
                    onActionClick={this.handleViewClick}
                    itemViewType={this.state.itemViewType}
                    // // fileName={getCookie(cons.COMPANY_NAME)+'_PAYMENTS'}
                    // showDelete={true}
                    // onDeleteClick={this.onDeleteParamater}
                    actionType={VIEW}
                  />
                </ItemGrid>
              </GridContainer>
            }
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(PaySchemeParameters)
);
