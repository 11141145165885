import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverConfig, serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import { getDataFromUrl, requestList } from "../../server/server";
import moment from "moment";
import { getCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import Jspdf from "jspdf";
import { NoorYes, PackageTypes, PeriodTypes, Status } from "../TableData";
import FormControl from "material-ui/Form/FormControl";
import Datetime from "react-datetime";
import LineItemTable from "../CustumComponents/LineItemTable";
import SelectboxMultiple from "../CustumComponents/SelectboxMultiple";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import { Check } from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import InputAdornment from "material-ui/Input/InputAdornment";
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";

class PackageMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicAlert: null,
      isCreated: false,
      isRefresh: false,
      isLoading: false,
      itemViewType: "id",
      updateView: false,
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      alert: false,
      PackageList: [],
      packageName: "",
      packagePrice: "",
      periodType: "",
      sureVisits: "",
      maxVisit: "",
      standbyEligible: "",
      remoteSupport: "",
      description: "",
      itemCode: "",
      itemGroupCode: "",
      qty: "",
      withSpare: "",
      status: 1,
      isActive: "",
      isDisabled: false,
      propertyList: [],
      PackageMasterList: [],
      properties: [],
      itemGroupsList: [],
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.hideAlertMessage = this.hideAlertMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  handleSeacrh = () => {
    if (!this.state.isRefresh) {
      this.calLPackageMasterList();
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(serverApi.CM_PACKAGE_BY_ID + id, this.handleViewClickResp);
    }
  };
  handleViewClickResp = (resp) => {
    var header = resp.data.packageHdr;
    var list = resp.data.packageDtl;
    var Packagelist = list.map((prop, key) => {
      var properties = [];
      resp.data.packagePropertiesDtl.forEach((o) => {
        if (prop.variantId === o.variantId && prop.productId === o.productId) {
          var obj = {
            name: o.property,
            id: o.productPropId,
            value: o.value,
            isActive: o.status,
            propId: o.id,
          };
          properties.push(obj);
        }
      });

      return {
        id: key,
        lineItemId: prop.id,
        variantId: prop.variantId,
        productId: prop.productId,
        variantName: prop.variantName,
        productName: prop.productName,
        cnt: prop.cnt,
        propertyList: properties,
        isActive: prop.status,
        actions: (
          <div className="actions-right">
            <IconButton
              onClick={() => {
                var data = this.state.PackageMasterList;
                data.find((o, i) => {
                  if (o.id === key) {
                    this.basicItemAlert("Do You Like To Update This Item?");

                    this.setState({
                      id: o.id,
                      lineItemId: o.lineItemId,
                      updateItemAlert: true,
                      updateItem: true,
                      status: o.status,
                      variantId: o.variantId,
                      productId: o.productId,
                      variantName: o.variantName,
                      productName: o.productName,
                      cnt: o.cnt,
                      propertyList: o.propertyList,
                    });
                    return true;
                  }
                });
              }}
              color="primaryNoBackground"
              customClass="remove"
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                var data = this.state.PackageMasterList;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    this.basicItemAlert("Do You Like To Delete This Item?");
                    this.setState({
                      deleteItem: true,
                      id: o.id,
                    });

                    //  data.splice(i,1);
                    return true;
                  }
                });
              }}
              color="dangerNoBackground"
              customClass="remove"
            >
              <Close />
            </IconButton>{" "}
          </div>
        ),
      };
    });
    //console.log("indentWeight "+header.indentWeight);
    if (header !== null) {
      this.setState({
        updateView: true,
        isCreated: true,
        description: header.description,
        amt: header.amt,
        maxDiscountAllowed: header.maxDiscountAllowed,
        image: header.image,
        name: header.name,
        sla: header.sla,
        type: header.type,
        validFrom: header.validFrom,
        validTo: header.validTo,
        isActive: header.status,
        packageId: header.id,
        PackageMasterList: Packagelist,
      });
    }
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleCreate = () => {
    //alert();
    if (!this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
      // PackageMasterList:""
    });
    //  this.hideAlertMessage();
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("YYYY-MM-DD"),
      });
  };
  dateChanged = (date) => {
    var myDate = date;
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
  };

  handleSimple = (event) => {
    console.log("selected " + event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    //console.log("val "+value);
    this.setState({
      [event.target.id]: value,
    });
  };

  onSelect = (selectedValue, stateKey) => {
    /*
        if (stateKey === 'variantId') {
            var data = {
                "itemGroupId": selectedValue,
                "saleable": "",
                "consumable": "",
                "companyId": this.props.state.companyInfo.id
            };
            requestList(serverApi.GET_ITEMS_BY_GRPID, data, this.handleItem);
        }
*/
    if (stateKey === "productId") {
      /*  if(this.state.productId===selectedValue){
                if(this.state.propertyList.length===0){
                    var data={
                        "productId":selectedValue};
                    requestList(serverApi.PRODUCT_PROPERTY_LIST,data,this.handlePropertyData)
                }
            }else {*/
      var data = {
        productId: selectedValue,
        companyId: this.props.state.companyInfo.id,
      };
      requestList(
        serverApi.PRODUCT_PROPERTY_LIST,
        data,
        this.handlePropertyData
      );
      //  }
    }
    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handlePropertyData = (resp) => {
    var list = [];
    if (resp.data) {
      resp.data.forEach((prop) => {
        var obj = {
          name: prop.property,
          id: prop.id,
          value: prop.defaultValue,
          isActive: 0,
          propId: null,
          dataType: prop.dataType,
          maxLenght: prop.maxLenght,
        };
        list.push(obj);
      });
    }
    // console.log("propertyList",list);
    if (this.state.updateItem) {
      list.forEach((prop, i) => {
        this.state.propertyList.forEach((o) => {
          if (prop.id === o.id) {
            list[i].isActive = o.isActive;
            list[i].propId = o.propId;
            list[i].value = o.value;
          }
        });
      });
    }
    this.setState({ propertyList: list, loadProperty: true });
  };

  handleItem = (resp) => {
    var list = [];
    resp.forEach((prop) => {
      var obj = { label: prop.label, value: prop.itemId, name: prop.name };
      list.push(obj);
    });
    this.setState({ ItemOptions: list, loadItem: true });
  };

  getitemDetails = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    this.setState({
      productName: values[0],
    });
  };

  getInvoiceTablePageSize = () => {
    var length = this.state.PackageMasterList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      this.setState({
        alert: true,
        //  updateItem: false,
        updateItemAlert: false,
      });
    }
    // if(this.state.deleteList)
    if (this.state.deleteList) {
      this.handleLoading();
      getDataFromUrl(
        serverApi.DELETE_PACKAGE_MASTER + "/" + this.state.packageMasterId,
        this.handleAddIndent
      );
    }

    if (this.state.deleteItem) {
      var data = this.state.PackageMasterList,
        sum_of_tax = 0,
        sumOfamt = 0;
      //  data.splice(this.state.id, 1);
      // data[this.state.id].isActive=0;

      data.map((o, key) => {
        if (o.id === this.state.id) {
          if (this.state.updateView) data[key].isActive = 0;
          else data.splice(key, 1);
        }
      });

      //  if(this.state.approveStatus)

      this.setState({
        PackageMasterList: data,
        deleteItem: false,
      });
      this.hideAlert();
    }
    this.setState({
      basicAlert: null,
    });
  }

  hideAlert() {
    console.log("alert");
    this.setState({
      updateItem: false,
      loading: false,
      alert: false,
      showMessagealert: false,
      variantId: "",
      productId: "",
      variantName: "",
      productName: "",
      cnt: "",
      propertyList: [],
      id: null,
      lineItemId: null,
      isActive: 1,
    });
  }

  hideAlertMessage() {
    if (this.state.isCreated) {
      this.calLPackageMasterList();
    }
    this.setState({
      isCreated: false,
      showMessagealert: false,
      updateView: false,
      alert: false,
      basicAlert: false,
      packageId: "",
      description: "",
      amt: "",
      maxDiscountAllowed: "",
      image: "",
      name: "",
      sla: "",
      type: "",
      validFrom: "",
      validTo: "",
      PackageMasterList: [],
      propertyList: [],
    });
  }

  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var packagePropertiesDtl = [];
      var packageDtl = this.state.PackageMasterList.map((o, key) => {
        o.propertyList.forEach((k) => {
          var obj = {
            value: k.value,
            variantId: o.variantId,
            productId: o.productId,
            productPropId: k.id,
          };
          if (k.isActive === 1) packagePropertiesDtl.push(obj);
        });
        return {
          cnt: o.cnt,
          variantId: o.variantId,
          productId: o.productId,
        };
      });

      const {
        description,
        amt,
        maxDiscountAllowed,
        image,
        name,
        sla,
        type,
        validFrom,
        validTo,
        branchId
      } = this.state;

      var data = {
        packageDtl: packageDtl,
        packageHdr: {
          amt: amt,
          companyId: this.props.state.companyInfo.id,
          createdBy: getCookie(cons.USERID),
          description: description,
          image: image,
          maxDiscountAllowed: maxDiscountAllowed,
          name: name,
          sla: sla,
          type: type,

          validFrom: validFrom,
          validTo: validTo,
        },
        packagePropertiesDtl: packagePropertiesDtl,
      };

      // if (this.state.PackageMasterList.length >= 1) {
      //   this.handleLoading();
        // requestList(
        //   serverApi.CM_PACKAGE_MASTER_ADD,
        //   data,
        //   this.handleAddIndent
        // );
      // } else if (this.state.PackageMasterList.length < 1) {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fieldssss..!!");
      // }
      const validateFields = [
        { field: name, msg: "Package Name", type: 1 },
        { field: branchId, msg: "Branch", type: 2 },
        { field: amt, msg: "Package Price", type: 1 },
        { field: validFrom, msg: "Valid From", type: 1 },
        { field: validTo, msg: "Valid Till", type: 1 },
        { field: description, msg: "Description", type: 1 },
        // { field: PackageMasterList, msg: "Line Items", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      }
      else if (this.state.PackageMasterList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      }
      
      else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.ADD_PACKAGE_MASTER,
          data,
          this.handleAddIndent
        );
      }
    }
  };

  onUpdateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      var packagePropertiesDtl = [];
      var packageDtl = this.state.PackageMasterList.map((o, key) => {
        o.propertyList.forEach((k) => {
          var obj = {
            id: k.propId,
            value: k.value,
            variantId: o.variantId,
            productId: o.productId,
            productPropId: k.id,
            status: k.isActive,
          };
          //  if(k.isActive===1)
          packagePropertiesDtl.push(obj);
        });
        return {
          id: o.lineItemId,
          cnt: o.cnt,
          variantId: o.variantId,
          productId: o.productId,
          status: o.isActive,
        };
      });

      const {
        packageId,
        description,
        amt,
        maxDiscountAllowed,
        image,
        name,
        sla,
        type,
        validFrom,
        validTo,
        branchId
      } = this.state;
      var data = {
        packageDtl: packageDtl,
        packageHdr: {
          id: packageId,
          amt: amt,
          companyId: this.props.state.companyInfo.id,
          modBy: getCookie(cons.USERID),
          description: description,
          image: image,
          maxDiscountAllowed: maxDiscountAllowed,
          name: name,
          sla: sla,
          type: type,
          validFrom: validFrom,
          validTo: validTo,
          status: 1,
        },
        packagePropertiesDtl: packagePropertiesDtl,
      };
      // if (this.state.PackageMasterList.length >= 1) {
      //   this.handleLoading();
      //   requestList(
      //     serverApi.CM_PACKAGE_MASTER_UPDATE,
      //     data,
      //     this.handleAddIndent
      //   );
      // } else if (this.state.PackageMasterList.length < 1) {
      //   this.basicAlert("Please Add Atleast One Item..!!");
      // } else {
      //   this.basicAlert("Please Enter All Mandatory Fieldsddddd..!!");
      // }
      const validateFields = [
        { field: name, msg: "Package Name", type: 1 },
        { field: branchId, msg: "Branch", type: 2 },
        { field: amt, msg: "Package Price", type: 1 },
        { field: validFrom, msg: "Valid From", type: 1 },
        { field: validTo, msg: "Valid Till", type: 1 },
        { field: description, msg: "Description", type: 1 },
        // { field: PackageMasterList, msg: "Line Items", type: 1 },
      ];

      const validObj = validate(validateFields);
      console.log(validObj);
      if (validObj) {
        if (validObj.type === 1) {
          this.basicAlert(`Please Enter ${validObj.message} ..!!`);
        } else {
          this.basicAlert(`Please Select ${validObj.message} ..!!`);
        }
      }
      else if (this.state.PackageMasterList.length < 1) {
        this.basicAlert("Please Add Atleast One Item..!!");
      }
      
      else {
        this.handleLoading();
        // this.basicAlert("Created Successfully");
        requestList(
          serverApi.EDIT_PACKAGE_MASTER,
          data,
          this.handleAddIndent
        );
      }
    }
  };

  // onUpdateButtonClick=()=>{
  //     if(!getMenuPermission(2)){
  //         this.basicAlert("Access Denied. Please contact administrator!!!")
  //     }else {
  //
  //         const {description,maxVisit,packageName,packagePrice,periodType,remoteSupport,standbyEligible,sureVisits,hdrId,isActive}=this.state;
  //
  //         var PackageMasterList = this.state.PackageMasterList.map((o, key) => {
  //             return ({
  //                 dtlId: o.dtlId,
  //                 hdrId: o.hdrId,
  //                 withSpare: o.withSpare,
  //                 qty: o.qty,
  //                 itemGroupCode: o.itemGroupCode,
  //                 itemCode: o.itemCode,
  //                 "companyId": this.props.state.companyInfo.id,
  //                 "updatedBy": getCookie(cons.USERID)
  //             })
  //         });
  //
  //         var data = {
  //             "dtlList": PackageMasterList,
  //             "pmdto": {
  //                 "companyId": this.props.state.companyInfo.id,
  //                 "updatedBy": getCookie(cons.USERID),
  //                 "activeStatus": isActive,
  //                 "hdrId": hdrId,
  //                 "description": description,
  //                 "maxVisit": maxVisit,
  //                 "packageName": packageName,
  //                 "packagePrice":packagePrice,
  //                 "periodType": periodType,
  //                 "remoteSupport": remoteSupport,
  //                 "standbyEligible": standbyEligible,
  //                 "sureVisits": sureVisits
  //             }
  //         };
  //         if ( this.state.PackageMasterList.length >= 1) {
  //             this.handleLoading();
  //            requestList(serverApi.LEAVE_POLICIES_UPDATE, data, this.handleAddIndent)
  //         } else if (this.state.PackageMasterList.length < 1) {
  //             this.basicAlert("Please Add Atleast One Item..!!");
  //         }
  //         else {
  //             this.basicAlert("Please Enter All Mandatory Fields..!!");
  //         }
  //     }
  // };
  handleAddIndent = (resp) => {
    if (resp.status) {
      if (this.state.deleteList) this.calLPackageMasterList();
      this.setState({
        deleteList: false,
        basicAlert: null,
      });
      console.log("resp " + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  //add Item
  onClickAddItem() {
    var arr = this.state.PackageMasterList;

    var itemIndex = 0,
      packageMasterItemId = 0;
    if (this.state.updateItem) {
      itemIndex = this.state.id;
      packageMasterItemId = this.state.packageMasterItemId;
      //   arr[itemIndex].quantity = this.state.quantity
    } else {
      itemIndex = this.state.PackageMasterList.length;
    }
    var addItemObj = {
      id: itemIndex,
      lineItemId: this.state.lineItemId,
      variantId: this.state.variantId,
      productId: this.state.productId,
      variantName: this.state.variantName,
      productName: this.state.productName,
      cnt: this.state.cnt,
      propertyList: this.state.propertyList,
      isActive: 1,
      actions: (
        <div className="actions-right">
          <IconButton
            onClick={() => {
              var data = this.state.PackageMasterList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  this.basicItemAlert("Do You Like To Update This Item?");

                  this.setState({
                    id: o.id,
                    updateItemAlert: true,
                    updateItem: true,
                    lineItemId: o.lineItemId,
                    status: o.status,
                    variantId: o.variantId,
                    productId: o.productId,
                    variantName: o.variantName,
                    productName: o.productName,
                    cnt: o.cnt,
                    propertyList: o.propertyList,
                  });
                  return true;
                }
              });
            }}
            color="primaryNoBackground"
            customClass="remove"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              var data = this.state.PackageMasterList;
              data.find((o, i) => {
                if (o.id === addItemObj.id) {
                  // here you should add some custom code so you can delete the data
                  // from this component and from your server as well
                  this.basicItemAlert("Do You Like To Delete This Item?");
                  this.setState({
                    deleteItem: true,
                    id: o.id,
                  });

                  //  data.splice(i,1);
                  return true;
                }
              });
            }}
            color="dangerNoBackground"
            customClass="remove"
          >
            <Close />
          </IconButton>{" "}
        </div>
      ),
    };
    // if (
    //   this.state.variantId !== "" &&
    //   this.state.productId !== "" &&
    //   this.state.cnt !== ""
    //   //   &&
    //   //   this.state.propertyList.length > 0
    // ) {
    //   if (this.state.updateItem) {
    //     arr[itemIndex] = addItemObj;
    //   } else {
    //     arr.splice(arr.length, 0, addItemObj);
    //   }
    //   this.setState({
    //     //loading:,
    //     PackageMasterList: arr,
    //   });
    //   this.hideAlert();
    // } else {
    //   this.basicAlert("Please Enter All Mandatory Fieldsfff..!!");
    // }
    const {variantId,productId,cnt}=this.state;
    const validateFields = [
      { field: variantId, msg: "Variant", type: 2 },
      { field: productId, msg: "Product", type: 2 },
      { field: cnt, msg: "Count", type: 1 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    }
    else  {
      if (this.state.updateItem) {
        arr[itemIndex] = addItemObj;
      } else {
        arr.splice(arr.length, 0, addItemObj);
      }
      this.setState({
        //loading:,
        PackageMasterList: arr,
      });
      this.hideAlert();
    }
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status === true) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };

  handleAddItem() {
    const { classes } = this.props;
    const { name, packagePrice, sureVisits, description,branchId,amt,validFrom,validTo } = this.state;

    const validateFields = [
      { field: name, msg: "Package Name", type: 1 },
      { field: branchId, msg: "Branch", type: 2 },
      { field: amt, msg: "Package Price", type: 1 },
      { field: validFrom, msg: "Valid From", type: 1 },
      { field: validTo, msg: "Valid Till", type: 1 },
      { field: description, msg: "Description", type: 1 },
      // { field: PackageMasterList, msg: "Line Items", type: 1 },
    ];

    const validObj = validate(validateFields);
    console.log(validObj);
    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message} ..!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message} ..!!`);
      }
    }
    else {
      this.setState({
        loading: true,
        alert: true,
      });
    }
    
  }

  // onDeletePackageMaster = (id) => {
  //
  //     this.setState({deleteList: true, packageMasterId: id});
  //
  //     this.basicItemAlert("Do You Like To Delete This Record?");
  // };

  componentDidMount() {
    var table = {
      data: [],
      filterTable: true,
      defaultPageSize: 5,
      showPaginationTop: false,
      showPaginationBottom: true,
      hrmsAccess: false,
      approveButton: false,
      totalRecords: 0,
      column: [
        {
          filterable: "true",
          Header: "Package Name",
          accessor: "name",
          style: { textAlign: "center" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Description",
          accessor: "description",
          style: { textAlign: "left" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "ValidFrom",
          accessor: "validFrom",
          style: { textAlign: "left" },
          sortable: "true",
        },
        {
          filterable: "true",
          Header: "Valid Til",
          accessor: "validTo",
          style: { textAlign: "left" },
          sortable: "true",
        },
      ],
    };
    this.setState({ tableList: table, isRefresh: true });
    this.calLPackageMasterList();
    this.getItemGroups();
  }
  getItemGroups = () => {
    getDataFromUrl(
      serverApi.SEARCH_ITEM_GROUPS +
        this.props.state.companyInfo.id +
        "/" +
        getCookie(cons.CIPHER),
      this.handleItemGroups
    );
  };
  handleItemGroups = (resp) => {
    console.log("itemGroups", resp);
    var list = [];
    resp.forEach((prop) => {
      var obj = {
        label: prop.label,
        value: prop.id,
        name: prop.name + "^" + prop.value,
      };
      list.push(obj);
    });
    this.setState({ itemGroupsList: list, loadItemGroup: true });
  };
  calLPackageMasterList = () => {
    var data = {
      companyId: this.props.state.companyInfo.id,
    };
    requestList(serverApi.CM_PACKAGE_MASTER_LIST, data, this.handleList);
  };

  handleList = (resp) => {
    var list = this.state.tableList;
    list.data = resp.data;
    this.setState({ tableList: list, isRefresh: true });
  };
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //  console.log("file type",file.type);
    var fileName = file.name,
      fileType;
    fileName = fileName.split(".");
    fileType = fileName[1].toUpperCase();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      var result = reader.result.split(",");
      console.log("reader.result", result[1]);

      if (fileType === "JPG" || fileType === "JPEG" || fileType === "PNG") {
        this.setState({
          //  file: file,
          fileName: file.name,
          fileType: fileName[1],
          //filesList: arr,
          image: result[1],
        });
        //  this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
      } else {
        this.basicAlert(
          "File type you have selected can not be upload. Please select another file!!!"
        );
      }
    };
  }
  getDataTable = () => {
    var data = [];
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.PackageMasterList.length > 0)
      this.state.PackageMasterList.forEach((prop, key) => {
        console.log("prop", prop.isActive);
        prop.id = key;
        if (prop.isActive === 1) {
          var obj = {
            ...prop,
            actions: (
              <div className="actions-right">
                <IconButton
                  onClick={() => {
                    var data = this.state.PackageMasterList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        this.basicItemAlert("Do You Like To Update This Item?");

                        this.setState({
                          id: o.id,
                          updateItemAlert: true,
                          updateItem: true,
                          lineItemId: o.lineItemId,
                          status: o.status,
                          variantId: o.variantId,
                          productId: o.productId,
                          variantName: o.variantName,
                          productName: o.productName,
                          cnt: o.cnt,
                          propertyList: o.propertyList,
                        });
                        return true;
                      }
                    });
                  }}
                  color="primaryNoBackground"
                  customClass="remove"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    var data = this.state.PackageMasterList;
                    data.find((o, i) => {
                      if (o.id === obj.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        this.basicItemAlert("Do You Like To Delete This Item?");
                        this.setState({
                          deleteItem: true,
                          id: o.id,
                        });

                        //  data.splice(i,1);
                        return true;
                      }
                    });
                  }}
                  color="dangerNoBackground"
                  customClass="remove"
                >
                  <Close />
                </IconButton>{" "}
              </div>
            ),
          };

          data.push(obj);
        }
      });
    return data;
  };

  render() {
    const { classes } = this.props;
    const options = [{ label: "All", value: 0 }, { label: "Active", value: 1 }];
    const inputFocus = {
      autoComplete: "off",
    };

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              content={
                <GridContainer>
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"Add"}
                      cancel_button_text={"Cancel"}
                      content={
                        <form>
                          <GridContainer xs={12} md={12}>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Variant
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.variantId}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          name = name.split("^");
                                          this.setState({
                                            variantName: name[0],
                                          });
                                          var data = {
                                            itemGroupId: name[1],
                                            saleable: "",
                                            consumable: "",
                                            companyId: getCookie(
                                              cons.COMPANY_ID
                                            ),
                                          };
                                          requestList(
                                            serverApi.GET_ITEMS_BY_GRPID,
                                            data,
                                            this.handleItem
                                          );
                                        }}
                                        isRefresh={this.state.loadItemGroup}
                                        stateKey="variantId"
                                        setIsRefresh={(val) => {
                                          this.setState({
                                            loadItemGroup: false,
                                          });
                                        }}
                                        staticData={this.state.itemGroupsList}
                                        //  url={serverApi.SEARCH_ITEM_GROUPS + this.props.state.companyInfo.id + "/" + getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>

                                <ItemGrid xs={6} sm={6}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Product
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        defValue={this.state.productId}
                                        onSelect={this.onSelect}
                                        getName={this.getitemDetails}
                                        stateKey="productId"
                                        isRefresh={this.state.loadItem}
                                        setIsRefresh={(val) => {
                                          this.setState({ loadItem: false });
                                        }}
                                        staticData={this.state.ItemOptions}
                                        //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                <ItemGrid
                                  xs={12}
                                  md={6}
                                  style={{ marginTop: "10px" }}
                                >
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Count
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        // disabled={true}
                                        id="cnt"
                                        value={this.state.cnt}
                                        //  className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                                {/*
                                                                    <ItemGrid xs={6} sm={6}  style={{marginTop: "10px"}}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Properties<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxMultiple
                                                                                    defValue={this.state.properties}
                                                                                    onSelect={this.onSelect}
                                                                                    isRefresh={this.state.loadProperty}
                                                                                    stateKey="properties"
                                                                                    setIsRefresh={(val) => {
                                                                                        this.setState({loadProperty: val})
                                                                                    }}
                                                                                    staticData={this.state.propertyList}
                                                                                    //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
*/}
                                {this.state.propertyList !== null ? (
                                  <ItemGrid xs={12}>
                                    <GridContainer>
                                      <ItemGrid>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              tabIndex={
                                                -1
                                              } /*
                                                                                            value={this.state.selectAll?1:0}
                                                                                            checked={this.state.selectAll?1:0}*/
                                              onChange={() => {
                                                var list = this.state
                                                  .propertyList;
                                                var data = list;
                                                console.log("indentlist", data);
                                                data.forEach((o, k) => {
                                                  if (o.isActive === 0)
                                                    data[k].isActive = 1;
                                                  else data[k].isActive = 0;
                                                });
                                                list.indentItems = data;

                                                this.setState({
                                                  indentlist: list,
                                                });
                                              }}
                                              // onClick={() => this.handleToggle(prop)}
                                              checkedIcon={
                                                <Check
                                                  className={
                                                    classes.checkedIcon
                                                  }
                                                />
                                              }
                                              icon={
                                                <Check
                                                  className={
                                                    classes.uncheckedIcon
                                                  }
                                                />
                                              }
                                              classes={{
                                                checked: classes.checked,
                                              }}
                                              style={{ marginLeft: 10 }}
                                            />
                                          }
                                          classes={{
                                            label: classes.menulabel,
                                          }}
                                          label={""}
                                        />
                                      </ItemGrid>
                                      <ItemGrid xs={4}>
                                        <InputLabel className={classes.label}>
                                          Property Name
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={4}>
                                        <InputLabel className={classes.label}>
                                          Value
                                        </InputLabel>
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                ) : null}

                                <ItemGrid
                                  xs={12}
                                  style={{
                                    maxHeight: 250,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <GridContainer>
                                    {this.state.propertyList !== null
                                      ? this.state.propertyList.map(
                                          (prop, i) => {
                                            return (
                                              <ItemGrid xs={12}>
                                                <GridContainer>
                                                  <ItemGrid>
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          tabIndex={-1}
                                                          value={prop.isActive}
                                                          checked={
                                                            prop.isActive
                                                          }
                                                          onChange={() => {
                                                            var list = this
                                                              .state
                                                              .propertyList;
                                                            var data = list;
                                                            if (
                                                              data[i]
                                                                .isActive === 0
                                                            )
                                                              data[
                                                                i
                                                              ].isActive = 1;
                                                            else
                                                              data[
                                                                i
                                                              ].isActive = 0;
                                                            list = data;
                                                            this.setState({
                                                              propertyList: list,
                                                            });
                                                          }}
                                                          // onClick={() => this.handleToggle(prop)}
                                                          checkedIcon={
                                                            <Check
                                                              className={
                                                                classes.checkedIcon
                                                              }
                                                            />
                                                          }
                                                          icon={
                                                            <Check
                                                              className={
                                                                classes.uncheckedIcon
                                                              }
                                                            />
                                                          }
                                                          classes={{
                                                            checked:
                                                              classes.checked,
                                                          }}
                                                          style={{
                                                            marginLeft: 10,
                                                          }}
                                                        />
                                                      }
                                                      classes={{
                                                        label:
                                                          classes.menulabel,
                                                      }}
                                                      label={""}
                                                    />
                                                  </ItemGrid>
                                                  <ItemGrid xs={4}>
                                                    {prop.name}
                                                  </ItemGrid>
                                                  <ItemGrid xs={4}>
                                                    <TextField
                                                      id="value"
                                                      className={
                                                        classes.textField
                                                      }
                                                      onChange={(event) => {
                                                        var list = this.state
                                                          .propertyList;
                                                        var data = list;
                                                        if (
                                                          event.target.value
                                                            .length <=
                                                          prop.maxLenght
                                                        ) {
                                                          data[i].value =
                                                            event.target.value;
                                                        }
                                                        list = data;
                                                        this.setState({
                                                          propertyList: list,
                                                        });
                                                      }}
                                                      value={prop.value}
                                                      fullWidth={true}
                                                      iType={prop.dataType}
                                                      decimal={3}
                                                    />
                                                  </ItemGrid>
                                                </GridContainer>
                                              </ItemGrid>
                                            );
                                          }
                                        )
                                      : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Package Name
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="name"
                          value={this.state.name}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Branch
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          //  getName={this.getQualityName}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Package Price
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="amt"
                          value={this.state.amt}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Max Discount Value
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id={"maxDiscountAllowed"}
                          value={this.state.maxDiscountAllowed}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Type</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.type}
                          onSelect={this.onSelect}
                          stateKey="type"
                          staticData={PackageTypes}
                          value={this.state.type}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Elapsed Time
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id={"sla"}
                          value={this.state.sla}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          iType={"number"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#003F66",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                >
                                  Days
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Valid From
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "validFrom");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "validFrom",
                              value: this.state.validFrom,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Valid Til
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "validTo");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "validTo",
                              value: this.state.validTo,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {/*
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Status
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <SelectboxTemp
                                                        defValue={this.state.isActive}
                                                        onSelect={this.onSelect}
                                                        // selectDisabled={this.state.isDisabled}
                                                        stateKey="isActive"
                                                        staticData={Status}
                                                        value={this.state.isActive}
                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null
                                    }
*/}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Description
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          disabled={this.state.isDisabled}
                          id="description"
                          value={this.state.description}
                          //  className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          multiline={true}
                          height={60}
                          rowsMax={2}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>Image</InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="fileName"
                          value={this.state.fileName}
                          className={classes.textField}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <span
                                  style={{
                                    color: "#003F66",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                  onClick={this.handleFileClick}
                                >
                                  BROWSE
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ItemGrid>
                      {/*
                                            <ItemGrid xs={12} sm={1}>
                                                <Button color={"defaultNoBackground"}
                                                        onClick={this.handleFileClick} style={{fontSize:'16px',marginRight:20}}>Browse
                                                </Button>

                                            </ItemGrid>
*/}
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        <LineItemTable
                          loading={this.state.loading}
                          data={this.state.PackageMasterList}
                          columns={[
                            {
                              Header: "Variant",
                              accessor: "variantName",
                              minWidth: 160,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Product",
                              accessor: "productName",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Count",
                              accessor: "cnt",
                              minWidth: 100,
                              style: { color: "#000", textAlign: "center" },
                            },
                            {
                              Header: "Actions",
                              minWidth: 100,
                              accessor: "actions",
                              style: { color: "#000", textAlign: "center" },
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                        />
                      </ItemGrid>

                      {this.state.updateView ? (
                        <ItemGrid xs={12}>
                          <GridContainer>
                            {/* {this.state.approveButton ?*/}
                            <ItemGrid xs={12}>
                              <Button
                                color={"info"}
                                right={true}
                                onClick={this.handleAddItem}
                              >
                                Add Item
                              </Button>
                            </ItemGrid>
                            {/* : null}*/}
                          </GridContainer>
                        </ItemGrid>
                      ) : (
                        <ItemGrid xs={12}>
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        </ItemGrid>
                      )}
                    </GridContainer>
                  </ItemGrid>

                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            color="primary"
                            onClick={this.onUpdateButtonClick}
                          >
                            Update
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button color="danger" onClick={this.handleCreate}>
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              content={
                <div>
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        {/*                                                <ItemGrid xs={12} sm={4}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Status
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <SelectboxTemp
                                                                defValue={this.state.status}
                                                                id={"status"}
                                                                onSelect={this.onSelect}
                                                                stateKey="status"
                                                                staticData={options}
                                                                value={this.state.status}
                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
                                                <Button color="primary" right={true}
                                                        onClick={this.handleSeacrh}>Search</Button>*/}

                        <ItemGrid xs={12} sm={6}>
                          {this.state.basicAlert}
                          {this.state.showMessagealert}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <IconButton
                          onClick={this.handleCreate}
                          color="success"
                          right={true}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "5px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <TableComponent
                        //url={serverApi.DATATABLE_SERVICE_URL+"/getAllIndent"}
                        //   searchData={this.getData()}
                        staticData={this.state.tableList}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isRefresh}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"Package_List"}
                        actionType={VIEW}
                        showDelete={false}
                        // onDeleteClick={this.onDeletePackageMaster}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  textField: {},
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  ...customSelectStyle,
  ...sweetAlertStyle,
  ...customCheckboxRadioSwitch,
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(PackageMaster)
);
