import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory, createHashHistory } from "history";
import { Router, Route, Switch, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store.jsx";
import "./assets/scss/custom.css";
import indexRoutes from "./routes/index.jsx";
//import Test from 'tlvince-reduced-test-case-material-ui-component';

import "./assets/scss/material-dashboard-pro-react/material-dashboard-pro-react1.css?v=1.1.1";

const hist = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
  <Provider store={store} history={hist}>
    <HashRouter>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
