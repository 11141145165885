import React from "react";
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import cons from "../../helper/Consts";
import * as Cookies from "../../helper/Cookies";
import {getCookie} from "../../helper/Cookies";

export function getMenuPermission (permissionType){
   // var permissionType=1;
    var menuId=parseInt(getCookie(cons.MENU_ID));
    var ArrayData=[],val=false;
    var menusData=Cookies.getCookie(cons.MENUS_DATA);
    ArrayData = JSON.parse(menusData);

    if(ArrayData.length>0){
       //console.log("menuId ",menuId);
       // console.log("ArrayData",JSON.stringify(ArrayData));
            ArrayData.map((prop,key)=>{

                if(prop.menuId===menuId) {
                   // console.log("menuId 11",prop.menuId);
                    if(permissionType===0){
                        val= prop.readPermission === 1;
                    }else if(permissionType===1){
                        val= prop.editPermission === 1;
                    }else if(permissionType===2){
                        val= prop.writePermission === 1;
                    }
                }

                 prop.subMenus.forEach((sub,key)=>{
                        if(sub.menuId===menuId) {
                         //   console.log("menuId 22",sub.readPermission+" "+sub.menuId);

                            if(permissionType===0){
                                val= sub.readPermission === 1;
                            }else if(permissionType===1){
                             //   console.log("true wwww",sub.editPermission === 0);
                                val= sub.editPermission === 1;
                            }else if(permissionType===2){
                                val= sub.writePermission === 1;

                            }
                        }
                    sub.subSubMenus.forEach((subSub,key)=>{
                            if(subSub.menuId===menuId) {
                                if(permissionType===0){
                                    val= prop.readPermission === 1;
                                }else if(permissionType===1){
                                    val= prop.editPermission === 1;
                                }else if(permissionType===2){
                                    val= prop.writePermission === 1;

                                }
/*
                                data={
                                    menuId: subSub.menuId,
                                    readPermission: subSub.readPermission,
                                    editPermission: subSub.editPermission,
                                    writePermission: subSub.writePermission
                                };
*/
                            }

                        })
                    });


            });


    }
    return val;
};
   export function IsMenuAvailable (menuId){

    var ArrayData=[],val=false;
    var menusData=Cookies.getCookie(cons.MENUS_DATA);
    ArrayData = menusData&&menusData!=="undefined"?JSON.parse(menusData):[];

    if(ArrayData.length>0){

        ArrayData.map((prop,key)=>{

            if(prop.menuId===menuId&&prop.readPermission===1) {
               val=true
            }

            prop.subMenus.forEach((sub,key)=>{
                if(sub.menuId===menuId&&sub.readPermission===1) {
                    val=true
                }
                sub.subSubMenus.forEach((subSub,key)=>{
                    if(subSub.menuId===menuId&&subSub.readPermission===1) {
                       val=true
                    }

                })
            });

        });

    }
    return val;
};
export function GETSUB_SUB_MENUS (menuId){

    var ArrayData=[],val=false;
    var menusData=Cookies.getCookie(cons.MENUS_DATA);
    ArrayData = menusData&&menusData!=="undefined"?JSON.parse(menusData):[];
    var subList=[];
    if(ArrayData.length>0){

        ArrayData.map((prop,key)=>{

            prop.subMenus.forEach((sub,key)=>{
               // console.log("menuId..",menuId,sub.menuId);
                if(sub.menuId+""==menuId&&sub.readPermission===1) {
                    sub.subSubMenus.forEach((subSub,key)=>{
                        if(subSub.readPermission===1) {
                            subList.push(subSub)
                        }

                    })

                }
            });

        });

    }
    return subList;
}
export function IsTallyAvailable (menuName){

    var ArrayData=[],val=false;
    var menusData=Cookies.getCookie(cons.MENUS_DATA);
//    console.log("IsTallyAvailable",menusData);
    ArrayData = menusData&&menusData!=="undefined"?JSON.parse(menusData):[];

    if(ArrayData.length>0){

        ArrayData.map((prop,key)=>{
            prop.subMenus.forEach((sub,key)=>{
                if(sub.menuName===menuName&&sub.readPermission===1) {
                    val=true
                }
            });

        });

    }
    return val;
}
