import React, { Component } from "react";
// react component for creating dynamic tables
import Datetime from "react-datetime";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import RegularCard from "../../components/Cards/RegularCard";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, { serverApi } from "../../helper/Consts";

import withStyles from "material-ui/styles/withStyles";
import { requestList } from "../../server/server";
import moment from "moment";
import { getCookie, setCookie } from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import ReportsTableComponent from "../CustumComponents/ReportsTableComponent";
import Select from "react-select";
import { GETSUB_SUB_MENUS } from "../CustumComponents/PermissionAlert";
import {
  getSubComponent,
  getSubSubComponent,
  subMenus,
} from "../../routes/routeIcons";
import StockView from "./Store/StockView";
import Jutewithvalue from "./Jute/JuteWithValue";
import JuteQualityWise from "./Jute/JuteQualityWise";
import JuteIssueReceipt from "./Jute/JuteIssueReceipt";
import JuteGodownWise from "./Jute/JuteGodownWise";
import JutePoChallanMr from "./Jute/JutePoChallanMr";
import JuteMrWise from "./Jute/JuteMRWise";
import MrInStock from "./Jute/MrInStock";
import MrWiseSales from "./Jute/MrWiseSales";
import JutePOmarketRate from "./Jute/JutePOMarketRate";
import DaywiseJuteReceipt from "./Jute/DaywiseJuteReceipt";
import MonthwiseJuteReceipt from "./Jute/MonthwiseJuteReceipt";
import JuteMarketRate from "../Jute/JuteMarketRate";
import ReconciliationReport from "./ReconciliationReport";
import IndentReport from "./Store/IndentReport";
import ItemWiseIndentReport from "./Store/ItemWiseIndentReport";
import IndentWaitingForPo from "./Store/IndentWaitingForPo";
import IndentDetailsReport from "./Store/IndentDetailsReport";
import IssueCheckListReport from "./Store/IssueCheckListReport";
import LedgerWiseTrasactions from "../Accounting/LedgerWiseTrasactions";
import StockReport from "./Store/StockReport";
import IndentDetailsReportAllCompany from "./Store/IndentDetailsReportAllCompany";
import IndentDetailsReportWithQtyForAllCompany from "./Store/IndentDetailsReportWithQtyForAllCompany";
import PODetailsReportWithQtyForAllCompany from "./Store/PODetailsReportWithQtyForAllCompany";
import Terms from "../Master/Terms";
import TermPackageMapping from "../Master/TermPackageMapping";
import BatchDeviation from "./Jute/BatchDeviation";
import SpinningDoff from "./PiReports/SpinningDoffSummary";
import SpinningDoffDetails from "./PiReports/SpinningDoffDetails";
import Item_legder_report from "./Store/ItemLedgerReport";
import GenericWidgetComponent from "../CustumComponents/GenericWidgetComponent";
import ItemWiseStockQuantity from "./Store/ItemWiseStockQuantity";
import ItemGroupStockReport from "./Store/ItemGroupStockReport";
import EmpTypeSummary from "./HrReports/EmpTypeSummary";
import EmpDeptSummary from "./HrReports/EmpDeptSummary";
import EmpCatSummary from "./HrReports/EmpCatSummary";
import EmpSubDeptSummary from "./HrReports/EmpSubDeptSummary";
import EmpCatandDeptSummary from "./HrReports/EmpCatandDeptSummary";
import EmpDesignationSummary from "./HrReports/EmpDesignationSummary";
import EmpSubDeptandDesignationSummary from "./HrReports/EmpSubDeptandDesignationSummary";
import PaySchemeEmpSummary from "./HrReports/PaySchemeEmpSummary";
import EmpPaySchemeList from "./HrReports/EmpPaySchemeList";
import EmpLeavesList from "./HrReports/EmpLeavesList";
import LeaveTypeSummary from "./HrReports/LeaveTypeSummary";
import EmpPayStructures from "./HrReports/EmpPayStructures";
import BranchSalSummary from "./HrReports/BranchSalSummary";
import MonthBranchSalSummary from "./HrReports/MonthBranchSalSummary";
import BranchPayschemeSalSummary from "./HrReports/BranchPayschemeSalSummary";
import BranchCatSalSummary from "./HrReports/BranchCatSalSummary";
import EmpBankStatement from "./HrReports/EmpBankStatement";
import PaySummaryReport from "./HrReports/PaySummaryReport";
import PaySchemeWiseEmpSalary from "./HrReports/PaySchemeWiseEmpSalary";
import MonthsWiseSalarySummary from "./HrReports/MonthsWiseSalarySummary";
import MonthlySalaryEstimate from "./HrReports/MonthlySalaryEstimate";
import PanIndiaStockBalance from "./Store/PanIndiaStockBalance";
import StockWithValue from "./Store/StockWithValue";
import EsiChallan from "./HrReports/EsiChallan";
import SundayAttendance from "./HrReports/SundayAttendance";
import PfChallan from "./HrReports/PfChallan";
import BulkPayslip from "./HrReports/BulkPayslip";

class SubSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: false,
      menuPath: "",
      selectedMenu: "",
    };
  }

  componentSubSub = (name) => {
    switch (name) {
      case subMenus.JuteWithValue:
        return <Jutewithvalue />;
      case subMenus.JuteQualityWise:
        return <JuteQualityWise />;
      case subMenus.JuteIssueReceipt:
        return <JuteIssueReceipt />;
      case subMenus.JuteGodownWise:
        return <JuteGodownWise />;
      case subMenus.JutePoChallanMr:
        return <JutePoChallanMr />;
      case subMenus.JuteMrWise:
        return <JuteMrWise />;
      case subMenus.MrInStock:
        return <MrInStock />;
      case subMenus.MrWiseSales:
        return <MrWiseSales />;
      case subMenus.JutePOMarketRate:
        return <JutePOmarketRate />;

      case subMenus.EmpBankStatement:
        return <EmpBankStatement />;

      case subMenus.PaySummaryReport:
        return <PaySummaryReport />;

      case subMenus.PaySchemeWiseEmpSalary:
        return <PaySchemeWiseEmpSalary />;

      case subMenus.MonthsWiseSalarySummary:
        return <MonthsWiseSalarySummary />;

      case subMenus.MonthlySalaryEstimate:
        return <MonthlySalaryEstimate />;

      case subMenus.EsiChallan:
        return <EsiChallan />;

      case subMenus.SundayAttendance:
        return <SundayAttendance/> ;

      case subMenus.PfChallan:
        return <PfChallan />;

      case subMenus.BulkPayslip:
        return <BulkPayslip />;

      case subMenus.DaywiseJuteReceipt:
        return <DaywiseJuteReceipt />;

      case subMenus.MonthwiseJuteReceipt:
        return <MonthwiseJuteReceipt />;

      case subMenus.JUTEMARKETRATE:
        return <JuteMarketRate />;

      case subMenus.RECONCILIATION_REPORT:
        return <ReconciliationReport />;

      case subMenus.REPORTS_STORE_INDENT:
        return <IndentReport />;
      case subMenus.ITEM_WISE_INDENT_REPORT:
        return <ItemWiseIndentReport />;
      case subMenus.INDENT_WAITING_FOR_PO_REPORT:
        return <IndentWaitingForPo />;
      case subMenus.INDENT_DETAIL_REPORT:
        return <IndentDetailsReport />;

      case subMenus.ISSUE_CHECK:
        return <IssueCheckListReport />;

      case subMenus.LEDGER_WISE_TRANSACTIONS:
        return <LedgerWiseTrasactions />;

      case subMenus.STOCK_REPORT:
        return <StockReport />;

      case subMenus.CITY_INDENT_DETAIL_REPORT:
        return <IndentDetailsReportAllCompany />;
      case subMenus.CITY_INDENT_DETAIL_REPORT_WITH_QTY:
        return <IndentDetailsReportWithQtyForAllCompany />;
      case subMenus.CITY_PO_DETAIL_REPORT_WITH_QTY:
        return <PODetailsReportWithQtyForAllCompany />;
      case subMenus.TERMS:
        return <Terms />;
      case subMenus.TERMS_PACKAGE_MAPPING:
        return <TermPackageMapping />;
      case subMenus.BATCH_DEVIATION_REPORT:
        return <BatchDeviation />;
      case subMenus.SPINNING_DOFF_SUMMARY:
        return <SpinningDoff />;
      case subMenus.SPINNING_DOFF_DETAILS:
        return <SpinningDoffDetails />;

      case subMenus.ITEM_LEDGER_REPORT:
        return <Item_legder_report />;
      case subMenus.STOCK_VIEW_REPORT:
        return <StockView />;
      case subMenus.ITEM_WISE_STOCK_QUANTITY_REPORT:
        return <ItemWiseStockQuantity menuPath={this.state.menuPath} />;
      case subMenus.ITEM_WISE_STOCK_VALUE_REPORT:
        return <ItemWiseStockQuantity menuPath={this.state.menuPath} />;
      case subMenus.ITEM_GROUP_STOCK_REPORT:
        return <ItemGroupStockReport />;

      case subMenus.EMP_TYPE_SUMMARY:
        return <EmpTypeSummary />;
      case subMenus.EMP_DEPT_SUMMARY:
        return <EmpDeptSummary />;
      case subMenus.EMP_CAT_SUMMARY:
        return <EmpCatSummary />;
      case subMenus.EMP_SUB_DEPT_SUMMARY:
        return <EmpSubDeptSummary />;
      case subMenus.EMP_CAT_AND_DEPT_SUMMARY:
        return <EmpCatandDeptSummary />;
      case subMenus.EMP_DESIGNATION_SUMMARY:
        return <EmpDesignationSummary />;
      case subMenus.EMP_SUB_DEPT_AND_DESIGN_SUMMARY:
        return <EmpSubDeptandDesignationSummary />;
      case subMenus.PAY_SCHEME_EMP_SUMMARY:
        return <PaySchemeEmpSummary />;
      case subMenus.EMP_PAY_SCHEME_LIST:
        return <EmpPaySchemeList />;
      case subMenus.EMP_LEAVES_LIST:
        return <EmpLeavesList />;
      // case subMenus.LEAVE_TYPE_SUMMARY:
      //     return <LeaveTypeSummary/>
      case subMenus.EMP_PAY_STRUCTURES:
        return <EmpPayStructures />;
      case subMenus.BRANCH_SAL_SUMMARY:
        return <BranchSalSummary />;
      case subMenus.MONTH_BRANCH_SAL_SUMMARY:
        return <MonthBranchSalSummary />;
      case subMenus.BRANCH_PAYSCHEME_SAL_SUMMARY:
        return <BranchPayschemeSalSummary />;
      case subMenus.BRANCH_CAT_SAL_SUMMARY:
        return <BranchCatSalSummary />;
      case subMenus.PAN_INDIA_STOCK_BALANCE:
        return <PanIndiaStockBalance />;
      case subMenus.STORE_STOCK_WITH_VALUE_REPORT:
        return <StockWithValue />;

      default:
        return "";
    }
  };

  render() {
    const { classes } = this.props;

    const { menuPath, menuType, menuId, selectedMenu } = this.state;
    console.log("sss", GETSUB_SUB_MENUS(getCookie(cons.MENU_ID)));
    var list = GETSUB_SUB_MENUS(getCookie(cons.MENU_ID));
    var subsubMenus = [];
    list.forEach((prop, key) => {
      subsubMenus.push({
        label: prop.menuName,
        value: prop.menuId,
        path: prop.menuPath,
        menuType: prop.menuType,
      });
    });
    return (
      <div>
        <GridContainer>
          <ItemGrid xs={4} style={{ marginTop: "-10px", marginLeft: "40px" }}>
            {subsubMenus.length > 0 ? (
              <Select
                name={"defValue"}
                value={selectedMenu}
                onChange={(defValue) => {
                  this.setState({
                    selectedMenu: defValue,
                    menuPath: "/" + defValue.path,
                    menuType: defValue.menuType,
                    menuId: defValue.value,
                  });
                }}
                options={subsubMenus}
                className={classes.selectFormControl}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  height: "20px",
                  colors: {
                    ...theme.colors,
                    //  primary25: '#00ABDC',
                    primary: "#00ABDC",
                    //   primary50:"#00ABDC"
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "30px",
                    "min-height": "30px",
                    marginBottom: "10px",
                  }),
                }}
              />
            ) : null}
          </ItemGrid>
        </GridContainer>
        <div>
          {menuType === 2 ? (
            <GenericWidgetComponent
              menuType={menuType}
              menuId={menuId}
              menuName={selectedMenu ? selectedMenu.label : null}
            />
          ) : (
            this.componentSubSub(menuPath)
          )}
        </div>
      </div>
    );
  }
}

const extendedFormsStyle = {
  ...customSelectStyle,
  ...sweetAlertStyle,
};
export default withStyles(extendedFormsStyle)(SubSubMenu);
