import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Print from "@material-ui/icons/Print";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from "@material-ui/icons/Add";
import PaginationTableComponent from "../CustumComponents/PaginationTableComponent";
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import InputAdornment from "material-ui/Input/InputAdornment";
import Popup from "../../components/Popup/PopUp";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import {
  configNames,
  getConfigValue,
  serverApi,
  serverConfig,
  getTenantId,
} from "../../helper/Consts";
import { DELETE, VIEW } from "../../ui-actions/UiActions";
import moment from "moment";
import {
  getDataFromUrl,
  getFileDownload,
  requestList,
  uploadFile,
} from "../../server/server";
import { getCookie } from "../../helper/Cookies";
import cons from "../../helper/Consts";
import Select from "react-select";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import MultiSelect from "../CustumComponents/SelectboxMultiple";
import PermissionAlert, {
  getMenuPermission,
} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import LineItemTable from "../CustumComponents/LineItemTable";
import { GoodTypes, ReasonTypes } from "../TableData";
import { ThumbsUpDown } from "@material-ui/icons";
import LogsComponents from "../CustumComponents/LogsComponents";
import { connect } from "react-redux";
import { validate } from "../../components/Validation/validate";
class WorkReport extends React.Component {
  redirectToStoreIndent = () => {
    this.props.history.push("/store/StoreIndent");
  };
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowData: null,
      isCreated: false,
      // fromDate:
      //   getCookie(cons.YEAR_FROM_DATE) && getCookie(cons.YEAR_FROM_DATE) != null
      //     ? getCookie(cons.YEAR_FROM_DATE)
      //     : "",
      // todate:
      //   getCookie(cons.YEAR_TO_DATE) && getCookie(cons.YEAR_TO_DATE) != null
      //     ? getCookie(cons.YEAR_TO_DATE)
      //     : "",
      fromDate: moment()
        .day(-7)
        .format("DD-MM-YYYY"),
      todate: moment().format("DD-MM-YYYY"),
      status: "",
      vehicleNo: "",
      gateEntryNO: "",
      selectMukam: "",
      supplierId:
        getCookie(cons.USER_ROLE_ID) === "100"
          ? getCookie(cons.SUPP_CODE)
          : null,
      brokerId: "",
      poNo: "",
      wrNum: "",
      isDisabled: false,
      outDateTime: "",
      itemViewType: "id",
      updateView: false,
      showMessagealert: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      entryId: "",
      selectedPO: "",
      departmentId: "",
      itemGroupId: "",
      itemCode: "",
      quantity: "",
      itemGroup: "",
      itemName: "",
      departmentName: "",
      uomCode: "",
      branchId: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      time2: moment().format("HH:mm:ss"),
      outDate: "",
      outTime: "",
      invoiceList: [],
      projectIds: 0,
      branchIds: "",
      internalNote: "",
      goodType: "SRN",
      costDesc: "",
      costFactor: "",
      description: "",
      supplierList: [],
      filesList: [],
      consignmentNumber: null,
      consignmentDate: null,
      vehicleNumber: null,
      driverContactNumber: null,
      oldQty: 0,
      customerList: [],
      taxType: "",
      approvedQTY: 0,
      rejectedQTY: 0,
      invoiceDate: "",
      invoiceNumber: "",
      ewaybillnoDate: "",
      ewaybillno: "",
      poList: [],
      creditNote: "",
      dataTable: "",
      startIndex: 0,
      pageSize: 10,
      lastIndex: 10,
      status: null,
      workRpId: "",
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSimple = this.handleSimple.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
  }

  componentDidMount() {
    this.getData(this.state.startIndex, this.state.lastIndex);

    if (getCookie(cons.USER_ROLE_ID) !== "100")
      getDataFromUrl(
        serverApi.SELECT_SUPPLIER_STORE + this.props.state.companyInfo.id,
        this.handleSuppliers
      );

    var data = {
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
    };
    requestList(serverApi.PROJECT_LIST_LIST, data, this.handleProjectNameList);
    getDataFromUrl(
      serverApi.GET_BUDGET_HEAD + "company/" + this.props.state.companyInfo.id,
      this.handleDepartments
    );
    getDataFromUrl(
      serverApi.GET_CUSTOMER_BY_COMPANY + this.props.state.companyInfo.id,
      this.handelCustomer
    );
    if (getCookie(cons.USER_ROLE_ID) == "100") {
      this.setState({ isSupplier: true });
    }
    getDataFromUrl(
      serverApi.SELECT_BRANCH + this.props.state.companyInfo.id,
      this.handleBranch
    );
  }

  handleBranch = (resp) => {
    console.log("getting branch list", resp);
    var data = resp;
    var branchList = [];
    if (data.length > 0) {
      data.map((prop, ind) => {
        var name = prop.name;
        var obj = { label: prop.label, value: prop.value, name: name };
        branchList.push(obj);
      });
      this.setState({
        branchList: branchList,
      });
    }
  };
  handelCustomer = (resp) => {
    var data = resp,
      customerList = [];
    if (data.length > 0) {
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.value, name: name };
        customerList.push(obj);
      });

      this.setState({
        customerList: customerList,
      });
    }
  };
  handleDepartments = (resp) => {
    this.setState({ budgetHeadList: resp, loadDepartment: true });
  };
  getBudgetName = (selectedValue) => {
    this.setState({
      selectedbudgetName: selectedValue,
    });
  };
  handleProjectNameList = (resp) => {
    var data = resp.data,
      projectNameList = [];
    if (data.length > 0) {
      var obj = { label: "Select", value: null, name: "null" };
      projectNameList.push(obj);
      data.forEach((prop) => {
        var name = prop.name;
        var obj = { label: prop.name, value: prop.projectId, name: prop.name };
        projectNameList.push(obj);
      });
      this.setState({
        projectNameList: projectNameList,
        loadProject: true,
      });
    }
  };
  handleSuppliers = (resp) => {
    this.setState({ supplierList: resp, loadSupplier: true });
  };
  getDefaultValue = (i) => {
    var arr = this.state.invoiceList;
    return arr[i];
  };

  hideAlert() {
    this.setState({
      id: 0,
      indentAlert: false,
      alert: false,
      loading: false,
      costAlert: false,
      showMessagealert: false,
      departmentName: "",
      itemGroup: "",
      itemName: "",
      uomCode: "",
      stock: "",
      rate: "",
      quantity: "",
      departmentId: "",
      itemGroupId: "",
      itemCode: "",
      poNo: "",
      poLineItemId: "",
      costDesc: "",
      costFactor: "",
      description: "",
      taxId: "",
      taxPercentage: "",
      igstPercentage: "",
      cgstPercentage: "",
      sgstPercentage: "",
      hsnCode: "",
      remarks1: "",
      approvedQTY: 0,
      rejectedQTY: 0,
    });
  }

  hideBasicAlert() {
    if (this.state.updateItemAlert) this.hideAlert();
    this.setState({
      basicAlert: null,
      deleteItem: false,
      updateItemAlert: false,
    });
  }

  hideItemBasicAlert() {
    if (this.state.updateItemAlert) {
      // if (this.state.goodType === "SRN")
      // 	this.setState({
      // 		costAlert: true,
      // 		updateItemAlert: false,
      // 	});
      // else
      this.setState({
        alert: true,
        updateItemAlert: false,
      });
      //  this.hideAlert();
    }
    if (this.state.deleteItem) {
      var data = this.state.invoiceList;
      // data.splice(this.state.id, 1);
      if (this.state.updateView) data[this.state.id].isActive = 0;
      else data.splice(this.state.id, 1);
      //  if(this.state.approveButton)
      this.setState({
        invoiceList: data,
        deleteItem: false,
      });
    }
    this.setState({
      basicAlert: null,
    });
  }

  basicItemAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideItemBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        />
      ),
    });
  }

  hideAlertMessage() {
    this.setState({
      isCreated: false,
      updateView: false,
      showMessagealert: false,
      isDisabled: false,
      alert: false,
      basicAlert: null,
      indentAlert: false,
      selectedPO: "",
      departmentId: "",
      itemGroupId: "",
      itemCode: "",
      quantity: "",
      itemGroup: "",
      itemName: "",
      departmentName: "",
      uomCode: "",
      chalanNo: "",
      chalanDate: "",
      vehicleNum: "",
      driverName: "",
      SelectedChalanType: "",
      selectedSupplier:
        getCookie(cons.USER_ROLE_ID) === "100" ? getCookie(cons.SUPP_CODE) : "",
      remarks: "",
      date1: moment().format("DD-MM-YYYY"),
      year: getCookie(cons.ACADEMIC_YEAR),
      time1: moment().format("hh:mm:ss A"),
      invoiceList: [],
      outDateTime: "",
      id: 0,
      branchId: "",
      poLineItemId: "",
      consignmentNo: "",
      consignmentDate: "",
      inspectionChk: "",
      projectId: "",
      projectIds: "",
      branchIds: "",
      internalNote: "",
      goodType: "SRN",
      filesList: [],
      budgetHeadId: "",
      consignmentNumber: null,
      consignmentDate: null,
      vehicleNumber: null,
      driverContactNumber: null,
      oldQty: 0,
      inwardStatus: "",
      taxType: "",
      taxTypeDesc: "",
      invoiceNumber: "",
      invoiceDate: "",
      ewaybillno: "",
      ewaybillnoDate: "",
      poList: [],
    });
  }
  handleCreate = () => {
    //alert();
    if (this.state.isCreated) {
      this.hideAlertMessage();
    }
    console.log(this.state);
    this.setState({
      isCreated: !this.state.isCreated,
    });
  };
  getData = (startIndex, lastIndex) => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.todate,
      supplierId: this.state.supplierId,
      companyId: this.props.state.companyInfo.id,
      cipher: getCookie(cons.CIPHER),
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      erpType: getConfigValue(configNames.LABELS_TYPE),
      branchId: this.state.branchIds !== 0 ? this.state.branchIds : "",
      projectId: this.state.projectIds !== 0 ? this.state.projectIds : "",
      recordType: "SRN",
      status: this.state.statusList,
      poNum: this.state.poNum,
      lastIndex: this.state.lastIndex ? this.state.lastIndex : lastIndex,
      startIndex: this.state.startIndex ? this.state.startIndex : startIndex,
      inwardNum: this.state.wrNum,
    };

    requestList(serverApi.GET_ALL_INWARDS, data, this.handleReports);
  };

  handleReports = (resp) => {
    this.setState({
      dataTable: resp,
      isRefresh: true,
    });

    // this.handleLoading();
  };

  handlePage = (start, lastIndex) => {
    // console.log("Nagesh handlepage", start, pageSize);
    this.getData(start, lastIndex);
  };

  handleLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  timeChanged = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("DD-MM-YYYY"),
      });
    else
      this.setState({
        [id]: "",
      });
  };
  timeChanged1 = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("hh:mm:ss A"),
        time2: dateObj.format("HH:mm:ss"),
      });
  };
  //dateFormate
  dateChanged = (myDate) => {
    if (myDate !== null && myDate !== undefined && myDate !== "") {
      myDate = myDate.split("-");
      var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
      return new Date(newDate).getTime();
    }
  };
  timeChangedFormat = (myDate, time) => {
    console.log("time", time);
    myDate = myDate.split("-");
    var timeParts = time.split(":");
    // var newDate=myDate[1]+"/"+myDate[0]+"/"+myDate[2] + timeParts[0]+ timeParts[1]+timeParts[2];
    return new Date(
      myDate[2],
      myDate[1] - 1,
      myDate[0],
      timeParts[0],
      timeParts[1],
      timeParts[2]
    ).getTime();
  };

  onChangeValues = (event) => {
    var value = event.target.value;
    this.setState({
      [event.target.id]: value,
    });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: Number(event.target.value) });
  };
  handleSeacrh = () => {
    //   console.log(this.state);
    var loading = false;
    if (!this.state.isRefresh) {
      this.getData(this.state.startIndex, this.state.lastIndex);
      loading = true;
    }
    this.setState({
      isRefresh: !this.state.isRefresh,
    });
  };
  onSelect = (selectedValue, stateKey) => {
    if (stateKey === "projectId") {
      this.setState({
        projectIds: selectedValue,
      });
    }
    if (stateKey === "status") {
      this.setState({
        statusList: selectedValue,
      });
    }
    if (stateKey === "taxType") {
      this.handleSelectedUnitValue(selectedValue);
    }
    if (stateKey === "departmentId") {
      // url={}
      getDataFromUrl(
        serverApi.DATATABLE_SERVICE_URL +
          selectedValue +
          "/" +
          this.props.state.companyInfo.id +
          "/getAllItemGroupsByDepartmentId/" +
          getCookie(cons.CIPHER),
        this.handleItemGroupData
      );
      this.setState({
        loadItemGroup: true,
        loadItem: true,
      });
    }

    if (stateKey === "budgetHeadId") {
      getDataFromUrl(
        serverApi.GET_ITEM_GROUP_BY_BUDGET_HEAD +
          "company/" +
          this.props.state.companyInfo.id +
          "/budgetHead/" +
          selectedValue,
        this.handleItemGroupData
      );
    }
    if (stateKey === "branchId" || stateKey === "branchIds") {
      this.setState({ loadProject: true });
    }
    if (stateKey === "itemGroupId") {
      var data = {
        itemGroupId: selectedValue,
        saleable: "",
        consumable: "",
        companyId: this.props.state.companyInfo.id,
        indentTypeId: "",
        tangible: "N",
      };

      requestList(serverApi.GET_ITEMGROUP, data, this.handleItem);
    }

    if (stateKey === "SelectedChalanType") {
      this.handleSelectedValues(selectedValue);
    }

    if (stateKey === "selectedPO") {
      console.log("Nagesh Selected PO", selectedValue[0]);
      this.state.poList.forEach((prop) => {
        console.log("Nagesh Selected PO---->>", prop.value[0]);
        if (selectedValue[0] == prop.value[0]) {
          console.log("Nagesh Selected PO----------->>>>>", prop);
          this.setState({
            branchId: prop.branchId,
            projectId: prop.projectId,
          });
        }
      });
    }

    this.setState({
      [stateKey]: selectedValue,
    });
  };
  handleItemGroupData = (resp) => {
    this.setState({
      itemGroupList: resp ? resp : [],
      loadItemGroup: true,
    });
  };
  handleItem = (resp) => {
    this.setState({ ItemOptions: resp, loadItem: true });
  };
  afterReloadSelect = (val) => {
    this.setState({
      reloadSelect: val,
    });
  };
  afterRefreshItem = (val) => {
    this.setState({
      loadItem: val,
    });
  };

  afterRefreshItemGrp = (val) => {
    this.setState({
      loadItemGroup: val,
      loadItem: val,
    });
  };
  getSupplierName = (selectedValue) => {
    var name = selectedValue.split("_");
    name = name[0];
    this.setState({
      supplierName: name,
    });
  };
  getDeptName = (selectedValue) => {
    this.setState({
      departmentName: selectedValue,
    });
  };

  getItemGroupName = (selectedValue) => {
    this.setState({
      itemGroup: selectedValue,
    });
  };

  getItemDetailsByName = (selectedValue) => {
    if (selectedValue !== null) {
      var values = selectedValue;
      values = values.split("^");
      this.setState({
        itemName: values[0],
        // itemCodeId:values[1],
        uomCode: values[1],
        stock: values[2],
        rate: this.state.itemName === values[0] ? this.state.rate : values[3],
      });
    }
  };
  handleTableLoading = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  handleViewClick = (id) => {
    // var data = this.state.dataTable;
    var juteId = "";
    console.log("jute  " + "  " + id);
    if (!getMenuPermission(0)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      getDataFromUrl(
        serverApi.INWARD_FETCH_BY_ID + id + "/" + getCookie(cons.USERID),
        this.handleViewClickResp,
        this.handlePrintResp
      );

      this.setState({
        workRpId: id,
      });
    }
  };

  getDefaultChalanType = (name) => {
    var options = [
      { value: 0, label: "Select..." },
      { value: 1, label: "With WO" },
      { value: 2, label: "Without WO" },
    ];
    var data = "";
    if (name == "WITHWO") {
      data = options[1];
    } else {
      data = options[2];
    }

    return data;
  };

  handleViewClickResp = (resp) => {
    this.setState({ printResp: resp });
    console.log("user id", getCookie(cons.USERID));
    var header = resp;
    var list = resp.inwardDetails;
    var indentlist = [],
      poNo = [],
      indentNo = [];
    list.forEach((prop, key) => {
      if (poNo.indexOf(prop.poId) <= -1 && prop.poId) {
        poNo.push(prop.poId);
        getDataFromUrl(
          serverApi.GET_FILE_BY_SOURCE +
            "14/" +
            prop.poId +
            "/" +
            this.props.state.companyInfo.id,
          this.handleInvoiceFiles
        );
      }
      // if (indentNo.indexOf(prop.indentHdrid) <= -1 && prop.indentHdrid) {
      // 	indentNo.push(prop.indentHdrid);
      // 	getDataFromUrl(
      // 		serverApi.GET_FILE_BY_SOURCE +
      // 			"10/" +
      // 			prop.indentHdrid +
      // 			"/" +
      // 			this.props.state.companyInfo.id,
      // 		this.handleInvoiceFiles
      // 	);
      // }
      var actualQty = prop.inwardQty;
      var approvedQty = 0;

      if (prop.poRcdQty && prop.poQty)
        actualQty = prop.poQty - prop.poRcdQty + prop.inwardQty;

      if (prop.approvedQty) {
        approvedQty = prop.approvedQty;
      } else if (prop.approvedQty == 0) {
        approvedQty = prop.approvedQty;
      } else {
        approvedQty = prop.inwardQty;
      }

      var obj = {
        id: key,
        lineId: prop.id,
        department: prop.departmentName,
        itemGroup: prop.itemGroup,
        item: prop.itemName,
        quantity: prop.inwardQty,
        poQuantity: parseFloat(prop.poQuantity) + parseFloat(prop.inwardQty),
        uomCode: prop.uom,
        //stockValue: prop.stock,
        deptId: parseInt(prop.departmentId),
        itemGroupId: prop.itemGroupId,
        itemId: prop.itemId + "",
        itemGrp: prop.itemGroupId + "" + prop.itemCode,
        poNo: prop.poId,
        poId: prop.poId,
        rate: prop.rate === null ? 0.0 : prop.rate,
        inwardId: prop.inwardId,
        poLineItemId: prop.poLineItemId,
        isActive: prop.isActive ? prop.isActive : 1,
        costDesc: prop.costDesc,
        costFactor: prop.costFactor,
        description: prop.description,
        budgetHeadId: prop.budgetHeadId + "",
        budgetHeadName: prop.budgetHeadName,
        oldQty: prop.oldQty,
        isActive: prop.isActive,
        poQty: actualQty,
        igstPercentage: prop.igstPercentage,
        cgstPercentage: prop.cgstPercentage,
        sgstPercentage: prop.sgstPercentage,
        hsnCode: prop.hsnCode,
        taxPercentage: prop.taxPercentage,
        taxId: prop.tax,
        //approvedQTY: prop.approvedQty, // ? prop.approvedQty : prop.inwardQty,
        approvedQTY: approvedQty,
        poDetailsId: prop.poDetailsId,
        rejectedQTY: prop.rejectedQty,
        itemStatus: prop.status > 1 ? prop.status : 3,
        reason: prop.reason,
      };
      //  if(!prop.deleteRec)
      indentlist.push(obj);
    });
    //  console.log("indentWeight "+header.indentWeight);
    if (header.poNo !== "") var poNo = header.poNo;

    if (header !== null) {
      this.setState({
        updateItem: false,
        updateView: true,
        isCreated: true,
        isDisabled: true,
        approveButton: header.approveButton,
        entryId: header.id,
        chalanNo: header.challanno,
        chalanDate: header.challannoDate,
        selectedSupplier: header.suppCode,
        supplierName: header.supplierName,
        selectedSupplier: header.supplierId + "",
        remarks: header.remarks,
        selectedPO: poNo,
        poDates:
          header.podatedescription !== "" ? header.podatedescription : [],
        createdBy: header.createdBy,
        invoiceList: indentlist,
        SelectedChalanType: this.getDefaultChalanType(header.source),
        consignmentNo: header.consignmentNo,
        consignmentDate: header.consignmentDateDesc,
        branchId: header.branchId,
        inspectionChk: header.inspectionChk,
        projectId: header.projectId,
        internalNote: header.internalNote,
        inwardSequenceNo: header.inwardSequenceNo,
        goodType: "SRN",
        lastModifiedBy: header.lastModifiedBy,
        consignmentNumber: header.consignmentNumber,
        consignmentDate: header.consignmentDate,
        vehicleNumber: header.vehicleNumber,
        driverContactNumber: header.driverContactNumber,
        remarks: header.remarks,
        inwardStatus: header.status,
        taxType: header.taxType,
        taxTypeDesc: header.taxTypeDesc,
        invoiceNumber: header.invoiceNumber,
        invoiceDate: header.invoiceDate,
        ewaybillno: header.ewaybillno,
        ewaybillnoDate: header.ewaybillnoDate,
        customerId: header.customerId,
        status: header.status,
        storeApprovedBy: header.storeApprovedBy,
        poValueWithoutTax: header.poValueWithoutTax,
        poValueWithTax: header.poValueWithTax,
        creditNote: header.creditnoteValue,
      });
      getDataFromUrl(
        serverApi.GET_FILE_BY_SOURCE +
          "18/" +
          header.id +
          "/" +
          this.props.state.companyInfo.id,
        this.handleInvoiceFiles
      );
    }
  };
  handleInvoiceFiles = (resp) => {
    var list = [];
    if (resp.data) {
      list = resp.data;
      if (this.state.filesList.length > 0) list.push(...this.state.filesList);
      this.setState({ filesList: list });
    }
  };
  //out
  onOutButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
      } = this.state;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          dtlId: prop.lineId,
          itemCode: prop.itemId,
          itemDesc: prop.item,
          itemGroupId: prop.itemGroupId,
          // qualityCode:prop.quality,
          dept: prop.deptId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          //stock:prop.stockValue,
          quantity: Number(prop.quantity),
          uomCode: prop.uomCode,
          poLineItemId: prop.poLineItemId,
          poNo: prop.poNo,
          deleteRec: prop.isActive === 0,
          costFactor: prop.costFactor,
          description: prop.description,
        };
      });

      var data = {
        entryHeader: {
          hdrId: this.state.entryId,
          challanNo: this.state.chalanNo,
          challanDate: this.dateChanged(this.state.chalanDate),
          vehicleNo: this.state.vehicleNum,
          driverName: this.state.driverName,
          suppCode: this.state.selectedSupplier,
          supplierName: this.state.supplierName,
          remarks: this.state.remarks,
          finYear: this.state.year,
          consignmentNo: this.state.consignmentNo,
          consignmentDate: this.dateChanged(this.state.consignmentDate),
          branchId: this.state.branchId,
          projectId: this.state.projectId,
          /*inDate: this.dateChanged(this.state.date1),
                    inTime: this.timeChangedFormat(this.state.date1, this.state.time2),*/
          outDate: this.dateChanged(this.state.outDate),
          outTime: this.timeChangedFormat(this.state.outDate, this.state.time2),
          //  pos: this.state.selectedPO,
          updateBy: getCookie(cons.USER_NAME),
          internalNote: this.state.internalNote,
          companyId: this.props.state.companyInfo.id,
          consignmentNumber: this.state.consignmentNumber,
          consignmentDate: this.state.consignmentDate,
          vehicleNumber: this.state.vehicleNumber,
          driverContactNumber: this.state.driverContactNumber,
          remarks: this.state.remarks,
        },
        gateStoreLineItems: invoiceList,
      };
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (
          this.state.invoiceList.length >= 1 &&
          this.state.outDate !== "" &&
          this.state.outTime &&
          branchId !== "" &&
          branchId !== 0 &&
          projectId !== "" &&
          projectId !== 0
        ) {
          this.handleLoading();
          requestList(
            serverApi.UPDATE_STORE_GATE_ENTRY,
            data,
            this.handleAddStoreGate
          );
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        } else {
          this.basicAlert("Please Enter Out Date & Time..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  //approve
  onApproveButtonClick = (inwardStatus) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
        taxType,
        taxTypeDesc,
        taxPercentage,
        ewaybillnoDate,
        ewaybillno,
        invoiceNumber,
        invoiceDate,
        customerId,
        creditNote,
      } = this.state;
      var aprroveStatus = false,
        emptyQty = false;
      var invoiceList = this.state.invoiceList
        .map((prop, key) => {
          console.log("getting data from invoiceList", prop);
          if (prop.isActive != 0) {
            return {
              id: prop.lineId,
              departmentId: prop.deptId,
              itemId: prop.itemId,
              itemGroupId: prop.itemGroupId,
              rate: prop.rate !== "null" ? prop.rate : 0,
              budgetHeadId: prop.budgetHeadId,
              inwardQty: Number(prop.quantity),
              uomCode: prop.uom,
              description: prop.item,
              poId: prop.poId,
              poDetailsId: prop.poDetailsId,
              oldQty: prop.oldQty,
              isActive: prop.isActive,
              tax: prop.taxId,
              igstPercentage: prop.igstPercentage,
              cgstPercentage: prop.cgstPercentage,
              sgstPercentage: prop.sgstPercentage,
              hsnCode: prop.hsnCode,
              taxPercentage: prop.taxPercentage,
              approvedQty: prop.approvedQTY,
              rejectedQty: prop.rejectedQTY,
              status: prop.itemStatus,
              reason: prop.reason,
            };
          }
        })
        .filter((prop) => prop !== undefined);

      var data = {
        id: this.state.entryId,
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType.value == 1 ? "WITHWO" : "WITHOUTWO",
        recordType: "SRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        lastModifiedBy: getCookie(cons.USERID),
        inwardSequenceNo: this.state.inwardSequenceNo,
        isCommitted: false,
        inwardDetails: invoiceList,
        consignmentNumber: this.state.consignmentNumber,
        consignmentDate: this.state.consignmentDate,
        vehicleNumber: this.state.vehicleNumber,
        driverContactNumber: this.state.driverContactNumber,
        remarks: this.state.remarks,
        taxType: taxType,
        taxTypeDesc: taxTypeDesc,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        ewaybillno: ewaybillno,
        ewaybillnoDate: ewaybillnoDate,
        customerId: customerId,
        acceptStatus: "N",
        creditnoteValue: parseFloat(creditNote),
        //status: inwardStatus ? inwardStatus : "",
        status: this.state.isApprove
          ? this.state.status
          : this.state.isReject
          ? 4
          : "",
      };
      console.log("data", data);
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (this.state.invoiceList.length >= 1) {
          if (inwardStatus) {
            if (!aprroveStatus) {
              if (!emptyQty) {
                this.handleLoading();
                requestList(
                  serverApi.UPDATE_INWARD,
                  data,
                  this.handleAddStoreGate
                );
                console.log("updated successfully", data);
              } else {
                this.basicAlert(
                  "Please Enter Approve Quantity For Approved Items..!!"
                );
              }
            } else {
              this.basicAlert("Please Add Status To All Line Items..!!");
            }
          } else {
            this.handleLoading();
            requestList(serverApi.UPDATE_INWARD, data, this.handleAddStoreGate);
          }
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onRejectButtonClick = () => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
        taxType,
        taxTypeDesc,
        taxPercentage,
        ewaybillnoDate,
        ewaybillno,
        invoiceNumber,
        invoiceDate,
        customerId,
      } = this.state;
      var aprroveStatus = false,
        emptyQty = false;
      var invoiceList = this.state.invoiceList
        .map((prop, key) => {
          if (prop.isActive != 0) {
            console.log("getting prop value", prop);
            return {
              id: prop.lineId,
              departmentId: prop.deptId,
              itemId: prop.itemId,
              itemGroupId: prop.itemGroupId,
              rate: prop.rate !== "null" ? prop.rate : 0,
              budgetHeadId: prop.budgetHeadId,
              inwardQty: Number(prop.quantity),
              // inwardQty: 0,
              uomCode: prop.uom,
              description: prop.item,
              poId: prop.poId,
              poDetailsId: prop.poDetailsId,
              oldQty: prop.oldQty,
              isActive: prop.isActive,
              tax: prop.taxId,
              igstPercentage: prop.igstPercentage,
              cgstPercentage: prop.cgstPercentage,
              sgstPercentage: prop.sgstPercentage,
              hsnCode: prop.hsnCode,
              taxPercentage: prop.taxPercentage,
              approvedQty: prop.approvedQTY,
              rejectedQty: prop.rejectedQTY,
              status: 4,
              reason: prop.reason,
            };
          }
        })
        .filter((prop) => prop !== undefined);

      var data = {
        id: this.state.entryId,
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType.value == 1 ? "WITHWO" : "WITHOUTWO",
        recordType: "SRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        lastModifiedBy: getCookie(cons.USERID),
        inwardSequenceNo: this.state.inwardSequenceNo,
        isCommitted: false,
        inwardDetails: invoiceList,
        consignmentNumber: this.state.consignmentNumber,
        consignmentDate: this.state.consignmentDate,
        vehicleNumber: this.state.vehicleNumber,
        driverContactNumber: this.state.driverContactNumber,
        remarks: this.state.remarks,
        taxType: taxType,
        taxTypeDesc: taxTypeDesc,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        ewaybillno: ewaybillno,
        ewaybillnoDate: ewaybillnoDate,
        customerId: customerId,
        acceptStatus: "N",
        //status: inwardStatus ? inwardStatus : "",
        status: 4,
      };
      console.log("data", data);
      if (
        SelectedChalanType !== 0 &&
        SelectedChalanType !== "" &&
        selectedSupplier !== 0 &&
        selectedSupplier !== ""
      ) {
        if (this.state.invoiceList.length >= 1) {
          // if (inwardStatus) {
          if (!aprroveStatus) {
            if (!emptyQty) {
              this.handleLoading();
              requestList(
                serverApi.UPDATE_INWARD,
                data,
                this.handleAddStoreGate
              );
              console.log("rejected successfully", data);
            } else {
              this.basicAlert(
                "Please Enter Approve Quantity For Approved Items..!!"
              );
            }
            // } else {
            //   this.basicAlert("Please Add Status To All Line Items..!!");
            // }
          } else {
            this.handleLoading();
            console.log("rejected successfully", data);
            requestList(serverApi.UPDATE_INWARD, data, this.handleAddStoreGate);
          }
        } else if (this.state.invoiceList.length < 1) {
          this.basicAlert("Please Add Atleast One Item..!!");
        }
      } else {
        this.basicAlert("Please Enter All Mandatory Fields..!!");
      }
    }
  };

  onAcceptButtonClick = (inwardStatus) => {
    if (!getMenuPermission(1)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNum,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
        taxType,
        taxTypeDesc,
        taxPercentage,
        ewaybillnoDate,
        ewaybillno,
        invoiceNumber,
        invoiceDate,
        customerId,
      } = this.state;
      var aprroveStatus = false,
        emptyQty = false;
      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          id: prop.lineId,
          departmentId: prop.deptId,
          itemId: prop.itemId,
          itemGroupId: prop.itemGroupId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          budgetHeadId: prop.budgetHeadId,
          inwardQty: Number(prop.quantity),
          uomCode: prop.uom,
          description: prop.item,
          poId: prop.poId,
          poDetailsId: prop.poDetailsId,
          oldQty: prop.oldQty,
          isActive: prop.isActive,
          tax: prop.taxId,
          igstPercentage: prop.igstPercentage,
          cgstPercentage: prop.cgstPercentage,
          sgstPercentage: prop.sgstPercentage,
          hsnCode: prop.hsnCode,
          taxPercentage: prop.taxPercentage,
          approvedQty: prop.approvedQTY,
          rejectedQty: prop.rejectedQTY,
          status: prop.itemStatus,
          reason: prop.reason,
        };
      });

      var data = {
        id: this.state.entryId,
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType.value == 1 ? "WITHWO" : "WITHOUTWO",
        recordType: "SRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        lastModifiedBy: getCookie(cons.USERID),
        inwardSequenceNo: this.state.inwardSequenceNo,
        isCommitted: false,
        inwardDetails: invoiceList,
        consignmentNumber: this.state.consignmentNumber,
        consignmentDate: this.state.consignmentDate,
        vehicleNumber: this.state.vehicleNumber,
        driverContactNumber: this.state.driverContactNumber,
        remarks: this.state.remarks,
        taxType: taxType,
        taxTypeDesc: taxTypeDesc,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        ewaybillno: ewaybillno,
        ewaybillnoDate: ewaybillnoDate,
        customerId: customerId,
        acceptStatus: "Y",
        //status: inwardStatus ? inwardStatus : "",
        // status: this.state.isApprove
        //   ? this.state.status
        //   : this.state.isReject
        //   ? 4
        //   : "",
        status: this.state.status,
      };
      console.log("data", data);
      if (this.state.filesList.length > 0) {
        if (
          SelectedChalanType !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== 0 &&
          selectedSupplier !== ""
        ) {
          if (this.state.invoiceList.length >= 1) {
            if (inwardStatus) {
              if (!aprroveStatus) {
                if (!emptyQty) {
                  this.handleLoading();
                  requestList(
                    serverApi.UPDATE_INWARD,
                    data,
                    this.handleAddStoreGate
                  );
                } else {
                  this.basicAlert(
                    "Please Enter Approve Quantity For Approved Items..!!"
                  );
                }
              } else {
                this.basicAlert("Please Add Status To All Line Items..!!");
              }
            } else {
              this.handleLoading();
              requestList(
                serverApi.UPDATE_INWARD,
                data,
                this.handleAddStoreGate
              );
            }
          } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        this.basicAlert(
          "There should atleast one document to accept the dispatch!!"
        );
      }
    }
  };

  onCreateButtonClick = () => {
    if (!getMenuPermission(2)) {
      this.basicAlert("Access Denied. Please contact administrator!!!");
    } else {
      const {
        vehicleNumber,
        driverName,
        SelectedChalanType,
        selectedSupplier,
        branchId,
        projectId,
        taxType,
        taxTypeDesc,
        taxPercentage,
        ewaybillnoDate,
        ewaybillno,
        invoiceNumber,
        invoiceDate,
        customerId,
        driverContactNumber,
        creditNote,
      } = this.state;

      var invoiceList = this.state.invoiceList.map((prop, key) => {
        return {
          departmentId: prop.deptId,
          itemId: prop.itemId,
          itemGroupId: prop.itemGroupId,
          rate: prop.rate !== "null" ? prop.rate : 0,
          budgetHeadId: prop.budgetHeadId,
          inwardQty: Number(prop.quantity),
          uom: prop.uomCode,
          description: prop.item,
          poId: prop.poId,
          poDetailsId: prop.poDetailsId,
          oldQty: 0,
          hdrStatus: 1,
          isActive: 1,
          tax: prop.taxId,
          igstPercentage: prop.igstPercentage,
          cgstPercentage: prop.cgstPercentage,
          sgstPercentage: prop.sgstPercentage,
          hsnCode: prop.hsnCode,
          taxPercentage: prop.taxPercentage,
        };
      });

      var data = {
        inwardDate: this.state.date1,
        challanno: this.state.chalanNo,
        challannoDate: this.state.chalanDate,
        branchId: branchId,
        projectId: projectId,
        supplierId: this.state.selectedSupplier,
        source: SelectedChalanType.value == 1 ? "WITHWO" : "WITHOUTWO",
        recordType: "SRN",
        companyId: this.props.state.companyInfo.id,
        internalNote: this.state.internalNote,
        createdBy: getCookie(cons.USERID),
        isCommitted: false,
        inwardDetails: invoiceList,
        consignmentNumber: this.state.consignmentNumber,
        consignmentDate: this.state.consignmentDate,
        vehicleNumber: vehicleNumber,
        driverContactNumber: driverContactNumber,
        remarks: this.state.remarks,
        taxType: taxType,
        taxTypeDesc: taxTypeDesc,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        ewaybillno: ewaybillno,
        ewaybillnoDate: ewaybillnoDate,
        customerId: customerId,
        creditnoteValue: parseFloat(creditNote),
        status:
          getCookie(cons.SUPP_CODE) > 0 && getCookie(cons.SUPP_CODE) != "null"
            ? "21"
            : "1",
      };
      if (
        getCookie(cons.USER_ROLE_ID) === "100" ||
        getCookie(cons.USER_ROLE_ID) === "99"
      ) {
        if (
          SelectedChalanType !== 0 &&
          SelectedChalanType !== "" &&
          selectedSupplier !== 0 &&
          selectedSupplier !== "" &&
          branchId !== "" &&
          branchId !== 0 &&
          projectId !== "" &&
          projectId !== 0 &&
          projectId !== undefined &&
          invoiceNumber !== "" &&
          invoiceDate !== "" &&
          ewaybillno !== "" &&
          ewaybillnoDate !== ""
        ) {
          if (this.state.invoiceList.length >= 1) {
            /* if (this.state.selectedPO !== "") {*/

            this.handleLoading();
            // alert(projectId);

            requestList(serverApi.SAVE_INWARD, data, this.handleAddStoreGate);
            /*} else {
                      this.onCreatePO();
                  }*/
          } else if (this.state.invoiceList.length < 1) {
            this.basicAlert("Please Add Atleast One Item..!!");
          }
        } else {
          this.basicAlert("Please Enter All Mandatory Fields..!!");
        }
      } else {
        // new code for validation....

        const validateFields = [
          { field: this.state.selectedSupplier, msg: "Supplier", type: 2 },

          {
            field: this.state.SelectedChalanType,
            msg: "Source",
            type: 2,
          },
          { field: this.state.branchId, msg: "Branch", type: 2 },
          { field: this.state.projectId, msg: "Project", type: 2 },
          { field: this.state.invoiceList, msg: "one lineItem", type: 2 },
        ];
        const validObj = validate(validateFields);

        if (validObj) {
          if (validObj.type === 1) {
            this.basicAlert(`Please Enter ${validObj.message}...!!`);
          } else {
            this.basicAlert(`Please Select ${validObj.message}...!!`);
          }
        } else {
          // this.basicAlert("Created Successfully");
          this.handleLoading();
          requestList(serverApi.SAVE_INWARD, data, this.handleAddStoreGate);
        }

        // old validation code....

        // if (
        //   SelectedChalanType !== 0 &&
        //   SelectedChalanType !== "" &&
        //   selectedSupplier !== 0 &&
        //   selectedSupplier !== "" &&
        //   branchId !== "" &&
        //   branchId !== 0 &&
        //   projectId !== "" &&
        //   projectId !== 0 &&
        //   projectId !== undefined
        // ) {
        //   if (this.state.invoiceList.length >= 1) {
        //     /* if (this.state.selectedPO !== "") {*/

        //     this.handleLoading();
        //     // alert(projectId);

        //     requestList(serverApi.SAVE_INWARD, data, this.handleAddStoreGate);
        //     /*} else {
        //               this.onCreatePO();
        //           }*/
        //   } else if (this.state.invoiceList.length < 1) {
        //     this.basicAlert("Please Add Atleast One Item..!!");
        //   }
        // } else {
        //   this.basicAlert("Please Enter All Mandatory Fields..!!");
        // }
      }
    }
  };

  handleAddStoreGate = (resp) => {
    if (resp.status) {
      console.log("resp datatattatatata" + JSON.stringify(resp));
      this.showMessage(resp.message, resp.status);
      this.getData(this.state.startIndex, this.state.pageSize);
    } else {
      this.showMessage(resp.message, resp.status);
    }
    this.handleLoading();
  };

  handleAddItem() {
    // const {classes}=this.props;
    const {
      vehicleNum,
      driverName,
      SelectedChalanType,
      selectedSupplier,
      goodType,
    } = this.state;
    // console.log("unitConversionName "+ this.state.unitConvertion);
    //  var id="";
    console.log(this.state);

    // new code for addItem Validation....

    const validateFields = [
      { field: this.state.selectedSupplier, msg: "Supplier", type: 2 },

      {
        field: this.state.SelectedChalanType,
        msg: "Source",
        type: 2,
      },
      { field: this.state.branchId, msg: "Branch", type: 2 },
      { field: this.state.projectId, msg: "Project", type: 2 },
      // { field: this.state.invoiceList, msg: "one lineItem", type: 2 },
    ];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        this.basicAlert(`Please Enter ${validObj.message}...!!`);
      } else {
        this.basicAlert(`Please Select ${validObj.message}...!!`);
      }
    } else {
      this.setState({
        loading: true,
        alert: true,
      });
    }

    // old code for addItem validation...

    // if (
    //   SelectedChalanType !== 0 &&
    //   SelectedChalanType !== "" &&
    //   selectedSupplier !== 0 &&
    //   selectedSupplier !== "" &&
    //   goodType !== null
    // ) {
    //   // if (goodType === "SRN") {
    //   // 	this.setState({ costAlert: true });
    //   // } else
    //   this.setState({
    //     loading: true,
    //     alert: true,
    //   });
    // } else {
    //   this.basicAlert("Please Enter All Mandatory Fields..!!");
    // }
  }

  onClickAddItem() {
    var arr = this.state.invoiceList;
    const {
      remarks,
      taxPercentage,
      selectedbudgetName,
      description,
      costFactor,
      costDesc,
      departmentName,
      itemGroup,
      itemGroupId,
      taxId,
      igstPercentage,
      cgstPercentage,
      sgstPercentage,
      departmentId,
      itemCode,
      quantity,
      poQuantity,
      updateView,
      SelectedChalanType,
      poNo,
      goodType,
      rate,
      uomCode,
      budgetHeadId,
      hsnCode,
      remarks1,
      printingPop,
    } = this.state;

    var itemIndex = 0,
      hasItem = false,
      lineId = 0;

    if (this.state.updateItem) {
      itemIndex = this.state.id;
      lineId = this.state.lineId;
    } else {
      itemIndex = this.state.invoiceList.length;
    }
    arr.map((q) => {
      if (q.item === this.state.itemName && !this.state.updateItem)
        hasItem = true;
    });
    var addItemObj = {
      id: itemIndex,
      lineId: lineId,
      departmentName: departmentName,
      itemGroup: itemGroup,
      item: this.state.itemName,
      quantity: quantity,
      uomCode: uomCode,
      stockValue: this.state.stock,
      rate: this.state.rate,
      deptId: this.state.departmentId,
      itemGroupId: itemGroupId,
      itemId: this.state.itemCode,
      itemGrp: this.state.itemGroupId + "" + this.state.itemCode,
      poNo: this.state.poNo,
      poLineItemId: this.state.poLineItemId,
      poQuantity: poQuantity,
      // poQty: poQuantity,
      isActive: 1,
      costDesc: costDesc,
      costFactor: costFactor,
      description: description,
      budgetHeadId: budgetHeadId,
      budgetHeadName: selectedbudgetName,
      taxPercentage: taxPercentage,
      taxId: taxId,
      igstPercentage: igstPercentage,
      cgstPercentage: cgstPercentage,
      sgstPercentage: sgstPercentage,
      hsnCode: hsnCode,
      remarks: remarks1,
    };

    if (!hasItem) {
      // if (goodType === "SRN") {
      // 	if (quantity !== "" && rate !== "" && uomCode !== "") {
      // 		if (this.state.updateItem) {
      // 			arr[itemIndex] = addItemObj;
      // 		} else arr.splice(arr.length, 0, addItemObj);

      // 		this.setState({
      // 			loading: true,
      // 			invoiceList: arr,
      // 			updateItem: false,
      // 		});
      // 		this.hideAlert();
      // 	} else {
      // 		this.basicAlert("Please Select All Mandatory Fields..!!");
      // 	}
      // }
      // else {
      if (printingPop) {
        if (
          itemGroupId !== "" &&
          itemCode !== "" &&
          quantity !== "" &&
          rate != ""
        ) {
          if (this.state.updateItem) {
            arr[itemIndex] = addItemObj;
          } else arr.splice(arr.length, 0, addItemObj);

          this.setState({
            loading: true,
            invoiceList: arr,
            updateItem: false,
          });
          this.hideAlert();
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      } else {
        if (
          (departmentId !== "" || budgetHeadId !== "") &&
          itemGroupId !== "" &&
          itemCode !== "" &&
          quantity !== "" &&
          rate != ""
        ) {
          if (SelectedChalanType.value === 1 && poNo !== "" && poNo !== null) {
            if (parseFloat(quantity) > parseFloat(poQuantity)) {
              this.basicAlert(
                "Quantity Should Not Be Greater Than PO Quantity " +
                  poQuantity +
                  "..!!"
              );
            } else {
              if (this.state.updateItem) {
                arr[itemIndex] = addItemObj;
              } else arr.splice(arr.length, 0, addItemObj);

              this.setState({
                loading: true,
                invoiceList: arr,
                updateItem: false,
              });
              this.hideAlert();
            }
          } else {
            if (this.state.updateItem) {
              arr[itemIndex] = addItemObj;
            } else arr.splice(arr.length, 0, addItemObj);

            this.setState({
              loading: true,
              invoiceList: arr,
              updateItem: false,
            });
            this.hideAlert();
          }
        } else {
          this.basicAlert("Please Select All Mandatory Fields..!!");
        }
      }
      //}
    } else {
      this.basicAlert(
        "Item Already Saved For This item Group. Please Select Another One..!!"
      );
    }
  }

  //invoiceTableSize
  getInvoiceTablePageSize = () => {
    var length = this.state.invoiceList.length;
    if (length > 5) {
      return length;
    } else {
      return 5;
    }
  };

  //addItem
  basicAlert(msg) {
    this.setState({
      basicAlert: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideBasicAlert()}
          onCancel={() => this.hideBasicAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        />
      ),
    });
  }

  showMessage = (msg, status) => {
    this.setState({
      showMessagealert: (
        <Popup
          //  onClickSave={() => this.onClickAddItem()}
          onClickSave={() => {
            if (status) {
              this.hideAlertMessage();
            } else {
              this.hideAlert();
            }
          }}
          isShowSave={true}
          save_btn_txt={"OK"}
          content={
            <form>
              <legend style={{ textAlign: "center" }}>{msg}</legend>
            </form>
          }
        />
      ),
    });
  };
  //onselect challan
  handleSelectedValues = (selectedValue) => {
    //const {classes}=this.props;

    // const {selectedJuteType}=this.state;

    if (selectedValue.value === 1) {
      this.getApprovedPos();
      if (!this.state.updateView) {
        this.setState({
          // loading: true,
          indentAlert: true,
          isDisabled: true,
        });
      }
    } else {
      this.setState({
        isDisabled: false,
        poList: null,
        loadPoList: true,
        selectedPO: null,
        invoiceList: [],
      });
    }

    this.setState({
      SelectedChalanType: selectedValue,
    });

    //console.log(`Option selected:`, defValue);
  };

  indenthideAlert() {
    this.setState({
      indentAlert: false,
      loading: false,
      SelectedChalanType: "",
    });
  }

  afterSelectPO = () => {
    // var data = this.state.dataTable;
    var juteId = "";
    var data = {
      poIds: this.state.selectedPO,
    };
    console.log("jute  " + "  " + this.state.selectedPO);
    requestList(serverApi.GET_POS_BY_IDS, data, this.handlePOResp);
    // getDataFromUrl(serverApi.GET_PO_DETAILS+this.state.selectedPO+"/"+getCookie(cons.USERID)+"/"+getCookie(cons.CIPHER),this.handlePOResp)
  };

  handlePOResp = (resp) => {
    // var header = resp.poHeader;
    var list = resp;
    var indentlist = [];
    var taxType = "";
    list.map((prop, key) => {
      if (key === 0) {
        if (prop.iTaxPercentage > 0) {
          taxType = 1;
        }
        if (prop.ctaxPercentage > 0) {
          taxType = 2;
        }
      }
      var qty = prop.qty;
      var rate = prop.rate !==null ? prop.rate :0;
      var installationRate=prop.installationRate !==null ? prop.installationRate:0
      if (installationRate) rate = rate + installationRate;
      if (prop.qtyRecvd) qty = qty - prop.qtyRecvd;
      // change prop.isActive Response
      if (prop.isActive !== 0) {
        if (prop.remainingQuantity > 0)
          indentlist.push({
            id: key,
            //dataId:prop.itemId,
            departmentName: prop.departmentName,
            itemGroup: prop.itemGroup,
            item: prop.itemName,
            itemGrp: prop.itemGroupId + "" + prop.itemId,
            budgetHeadId: prop.budgetHeadId + "",
            budgetHeadId: prop.budgetHeadId + "",
            budgetHeadName: prop.budgetHeadName,
            departmentId: prop.departmentId,
            poQuantity: qty,
            poQty: qty,
            quantity: qty,
            uomCode: prop.uomCode,
            rate: rate,
            // stockValue: prop.stock,
            deptId: prop.departmentId,
            itemGroupId: prop.itemGroupId,
            itemId: prop.itemId,
            poNo: prop.poId,
            poId: prop.poId,
            poDetailsId: prop.id,
            poLineItemId: prop.id,
            igstPercentage: prop.iTaxPercentage,
            cgstPercentage: prop.ctaxPercentage,
            sgstPercentage: prop.staxPercentage,
            taxId: prop.taxId,
            taxPercentage: prop.taxPercentage,
            hsnCode: prop.hsnCode,
            isActive: prop.isActive,
          });
      }
    });
    //   console.log("indentWeight "+header.supplierId);
    // if(header!== null){
    this.setState({
      loading: false,
      indentAlert: false,
      reloadSelect: true,
      //   selectedSupplier:header.supplierId,
      //  selectedPO:header.id,
      invoiceList: indentlist,
      taxType: taxType,
      loadTaxType: true,
    });
    console.log("resp after selected data", indentlist);

    //  }
  };

  getApprovedPos = (resp) => {
    var data = {
      purchaseType: "store",
      startDate: "",
      endDate: "",
      status: "3",
      financialYear: getCookie(cons.ACADEMIC_YEAR),
      branchId: this.state.branchId !== 0 ? this.state.branchId : "",
      project: this.state.projectId > 0 ? this.state.projectId : "",
      companyId: this.props.state.companyInfo.id,
      supplierId: this.state.selectedSupplier,
      recordType: "WO",
    };
    requestList(serverApi.GET_ALL_PO_OLD, data, this.handlePOList);
  };
  handlePOList = (resp) => {
    var list = [];
    console.log("Nagesh", resp);
    if (resp.data) {
      resp.data.forEach((prop) => {
        list.push({
          value: [prop.id],
          label: prop.poSequenceNo,
          name: prop.poSequenceNo,
          branchId: prop.branchId,
          projectId: prop.projectId,
        });
      });
    }
    this.setState({
      poList: list,
      loadPoList: true,
      branchId: this.state.branchId,
      projectId: this.state.projectId,
    });
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }
  hideInvoiceBasicAlert() {
    const {
      fileName,
      fileType,
      file,
      uploadChallan,
      uploadInvoice,
      entryId,
      isReject,
      isApprove,
      inwardStatus,
    } = this.state;

    if (isReject) {
      this.setState({ isReject: false });
      this.onApproveButtonClick("4");
    }
    if (isApprove) {
      this.setState({ isApprove: false });
      this.onApproveButtonClick(inwardStatus);
    }

    if (uploadInvoice) {
      uploadFile(
        fileName,
        file,
        fileType,
        18,
        fileName,
        entryId,
        this.handleInvoiceResp
      );
      //  uploadInvoice(serverApi.UPLOAD_INVOICE, "I", id, this.state.file, this.handleInvoiceResp,this.state.fileName);
      //  this.uploadInvoiceFile();
    }
    if (this.state.deleteInvoice) {
      getDataFromUrl(
        serverApi.DELETE_FILE_BI_ID + this.state.invoiceId,
        this.handleDeleteInvoiceResp
      );
    }
    this.setState({
      basicAlert1: null,
    });
  }
  handleDeleteInvoiceResp = (resp) => {
    if (resp.status) {
      var arr = this.state.filesList;
      arr.splice(this.state.fileId, 1);
      this.setState({
        filesList: arr,
        deleteInvoice: false,
        fileId: "",
      });
    }
  };

  handleDownloadedFile = (url) => {
    window.open(url);
    //  console.log("url...",url)
  };
  handleInvoiceResp = (resp) => {
    console.log("file resp", resp);
    var data = resp;
    if (resp.status) {
      //            data.docUrl=serverConfig.SERVER_URL+resp.data.docUrl;

      var arr = this.state.filesList;
      data = {
        fileUploadId: data.id,
        fileName: this.state.fileName,
        fileExtension: this.state.fileType,
        retrievalPath: data.fileUrl,
        sourceMenu: 2,
      };
      arr.splice(arr.length, 0, data);
      this.setState({
        uploadInvoice: false,
        filesList: arr,
        basicAlert: null,
      });
    }
    console.log("resp", JSON.stringify(resp));
  };

  hideInvoiceAlert() {
    this.setState({
      basicAlert1: null,
      isReject: false,
      isApprove: false,
    });
  }

  InvoicebasicAlert(msg) {
    this.setState({
      basicAlert1: (
        <SweetAlert
          style={{
            display: "block",
            marginTop: "-100px",
            marginLeft: "-80px",
            fontSize: "8px",
            color: "red",
          }}
          title={msg}
          onConfirm={() => this.hideInvoiceBasicAlert()}
          onCancel={() => this.hideInvoiceAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      ),
    });
  }
  handleFileChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var fileName = file.name,
      fileType;
    var fileNameArr = fileName.split(".");

    for (var i = 0; i < fileNameArr.length; i++) {
      // fileType = fileNameArr[i].toUpperCase();
      console.log(
        "getting the looped record",
        fileNameArr[i],
        fileName,
        fileType
      );
      fileType = fileNameArr[i].toUpperCase();
      console.log("getting the looped record3", fileType);
    }

    fileName = fileName.split(".");
    console.log(
      "getting the looped record2",

      fileName,
      fileType
    );

    reader.onloadend = () => {
      if (
        fileType === "PDF" ||
        fileType === "JPG" ||
        fileType === "JPEG" ||
        fileType === "PNG" ||
        fileType === "XLSX"
      ) {
        this.setState({
          file: file,
          fileName: file.name,
          // fileType: fileName[1],
          fileType: fileType,
          //filesList: arr,
          uploadInvoice: true,
          // imagePreviewUrl: reader.result
        });
        this.InvoicebasicAlert("Do you want to upload file " + file.name + "?");
      }
    };
    reader.readAsDataURL(file);
  }

  getDataTable = () => {
    var data = [];
    const { classes } = this.props;
    const options = [
      { value: 0, label: "Select...", name: "Select..." },
      { value: 3, label: "APPROVE", name: "APPROVE" },
      { value: 4, label: "REJECT", name: "REJECT" },
    ];
    const inputFocus = {
      autoComplete: "off",
    };
    var companyId = this.props.state.companyInfo.id;
    //  console.log("dta",JSON.stringify(this.state.invoiceList))
    if (this.state.invoiceList.length > 0)
      this.state.invoiceList.map((prop, key) => {
        prop.id = key;
        var status = "";
        if (prop.itemStatus == 3) status = options[1];
        if (prop.itemStatus == 4) status = options[2];
        if (
          getCookie(cons.USER_ROLE_ID) === "100" &&
          getCookie(cons.USER_ROLE_ID === "99")
        ) {
          prop.status = (
            <div className="actions-right">
              <Select
                isDisabled={this.state.isDisabled}
                defaultValue={status}
                name={"itemStatus"}
                // value={this.state.itemStatus}
                onChange={(defValue) => {
                  var data = this.state.invoiceList;
                  prop.itemStatus = defValue.value;
                  data[key].itemStatus = defValue.value;
                  if (defValue.value === 4) {
                    prop.rejectedQTY = data[key].quantity;
                    data[key].rejectedQTY = data[key].quantity;
                    data[key].approvedQTY = 0;
                  }
                  if (defValue.value === 3) {
                    prop.approvedQTY = data[key].quantity;

                    data[key].approvedQTY = data[key].quantity;
                    data[key].rejectedQTY =
                      parseFloat(data[key].quantity) -
                      parseFloat(data[key].approvedQTY);
                  }
                  this.setState({
                    itemStatus: defValue,
                    invoiceList: data,
                  });

                  //  console.log(`Option selected:`, defValue);
                }}
                options={options}
                className={classes.selectFormControl}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  height: "20px",
                  colors: {
                    ...theme.colors,
                    //  primary25: '#00ABDC',
                    primary: "#00ABDC",
                    //   primary50:"#00ABDC"
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "30px",
                    marginBottom: "30px",
                  }),
                }}
              />
            </div>
          );
        } else {
          prop.status = (
            <div className="actions-right">
              <Select
                isDisabled={
                  this.state.inwardStatus == "3" ||
                  this.state.inwardStatus == "4"
                }
                defaultValue={status}
                name={"itemStatus"}
                // value={this.state.itemStatus}
                onChange={(defValue) => {
                  var data = this.state.invoiceList;
                  prop.itemStatus = defValue.value;
                  data[key].itemStatus = defValue.value;
                  if (defValue.value === 4) {
                    prop.rejectedQTY = data[key].quantity;
                    data[key].rejectedQTY = data[key].quantity;
                    data[key].approvedQTY = 0;
                  }
                  if (defValue.value === 3) {
                    prop.approvedQTY = data[key].quantity;

                    data[key].approvedQTY = data[key].quantity;
                    data[key].rejectedQTY =
                      parseFloat(data[key].quantity) -
                      parseFloat(data[key].approvedQTY);
                  }
                  this.setState({
                    itemStatus: defValue,
                    invoiceList: data,
                  });

                  //  console.log(`Option selected:`, defValue);
                }}
                options={options}
                className={classes.selectFormControl}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  height: "20px",
                  colors: {
                    ...theme.colors,
                    //  primary25: '#00ABDC',
                    primary: "#00ABDC",
                    //   primary50:"#00ABDC"
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "30px",
                    marginBottom: "30px",
                  }),
                }}
              />
            </div>
          );
        }

        if (
          getCookie(cons.USER_ROLE_ID) === "100" &&
          getCookie(cons.USER_ROLE_ID) === "99"
        ) {
          prop.approvedReason = (
            <div>
              <Select
                isDisabled={this.state.isDisabled}
                //isDisabled={this.state.updateView}
                defaultValue={{ label: prop.reason, value: prop.reason }}
                name={"reason"}
                // value={this.state.itemStatus}
                onChange={(defValue) => {
                  var data = this.state.invoiceList;
                  data[key].reason = defValue.value;
                  prop.reason = defValue.value;
                  this.setState({
                    reason: defValue.value,
                    invoiceList: data,
                  });

                  //  console.log(`Option selected:`, defValue);
                }}
                options={ReasonTypes}
                className={classes.selectFormControl}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  height: "20px",
                  colors: {
                    ...theme.colors,
                    //  primary25: '#00ABDC',
                    primary: "#00ABDC",
                    //   primary50:"#00ABDC"
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "30px",
                    marginBottom: "30px",
                  }),
                }}
              />
            </div>
          );
        } else {
          prop.approvedReason = (
            <div>
              <Select
                isDisabled={
                  this.state.inwardStatus == "3" ||
                  this.state.inwardStatus == "4"
                }
                //isDisabled={this.state.updateView}
                defaultValue={{ label: prop.reason, value: prop.reason }}
                name={"reason"}
                // value={this.state.itemStatus}
                onChange={(defValue) => {
                  var data = this.state.invoiceList;
                  data[key].reason = defValue.value;
                  prop.reason = defValue.value;
                  this.setState({
                    reason: defValue.value,
                    invoiceList: data,
                  });

                  //  console.log(`Option selected:`, defValue);
                }}
                options={ReasonTypes}
                className={classes.selectFormControl}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  height: "20px",
                  colors: {
                    ...theme.colors,
                    //  primary25: '#00ABDC',
                    primary: "#00ABDC",
                    //   primary50:"#00ABDC"
                  },
                })}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    height: "30px",
                    marginBottom: "30px",
                  }),
                }}
              />
            </div>
          );
        }

        if (prop.itemStatus == 4) {
          prop.approvedQuantity = 0;
        }
        if (prop.itemStatus == 3) {
          if (
            getCookie(cons.USER_ROLE_ID) === "100" &&
            getCookie(cons.USER_ROLE_ID) === "99"
          ) {
            prop.approvedQuantity = (
              <div>
                <TextField
                  defValue={prop.approvedQTY}
                  disabled={true}
                  id="approvedQTY"
                  // className={classes.textField}
                  //  value={prop.approvedQTY}
                  iType={"number"}
                  onChange={(event) => {
                    var data = this.state.invoiceList;
                    if (parseInt(event.target.value) > data[key].quantity) {
                      this.basicAlert(
                        "Approved Quantity Should Not Exceed Quantity..!!"
                      );
                    } else {
                      data[key].approvedQTY = event.target.value;
                      if (data[key].approvedQTY !== "")
                        data[key].rejectedQTY =
                          parseFloat(data[key].quantity) -
                          parseFloat(data[key].approvedQTY);
                      else {
                        data[key].rejectedQTY = 0;
                        data[key].approvedQTY = 0;
                      }
                      prop.approvedQTY = event.target.value;
                      this.setState({
                        approvedQTY: event.target.value,
                        invoiceList: data,
                      });
                    }

                    //  console.log(`Option selected:`, defValue);
                  }}
                  decimal={
                    companyId === "1" ||
                    companyId === "2" ||
                    companyId === "116"
                      ? 3
                      : 2
                  }
                  fullWidth={true}
                  variant="outlined"
                  autoFocus={true}
                  InputProps={inputFocus}
                />
              </div>
            );
          } else {
            prop.approvedQuantity = (
              <div>
                <TextField
                  defValue={prop.approvedQTY}
                  disabled={this.state.inwardStatus == 3}
                  id="approvedQTY"
                  // className={classes.textField}
                  //  value={prop.approvedQTY}
                  iType={"number"}
                  onChange={(event) => {
                    var data = this.state.invoiceList;
                    if (parseInt(event.target.value) > data[key].quantity) {
                      this.basicAlert(
                        "Approved Quantity Should Not Exceed Quantity..!!"
                      );
                    } else {
                      data[key].approvedQTY = event.target.value;
                      if (data[key].approvedQTY !== "")
                        data[key].rejectedQTY =
                          parseFloat(data[key].quantity) -
                          parseFloat(data[key].approvedQTY);
                      else {
                        data[key].rejectedQTY = 0;
                        data[key].approvedQTY = 0;
                      }
                      prop.approvedQTY = event.target.value;
                      this.setState({
                        approvedQTY: event.target.value,
                        invoiceList: data,
                      });
                    }

                    //  console.log(`Option selected:`, defValue);
                  }}
                  decimal={
                    companyId === "1" ||
                    companyId === "2" ||
                    companyId === "116"
                      ? 3
                      : 2
                  }
                  fullWidth={true}
                  variant="outlined"
                  autoFocus={true}
                  InputProps={inputFocus}
                />
              </div>
            );
          }
        }
        var amount = this.getAmount(
          prop.quantity,
          prop.rate,
          0,
          0,
          0,
          prop.approvedQTY,
          prop.rejectedQTY
        );
        amount = parseFloat(amount);
        var taxAmount = 0;
        if (prop.taxPercentage != "0" && prop.taxPercentage > 0) {
          taxAmount = (amount * parseFloat(prop.taxPercentage)) / 100;
        }
        var netAmount = amount;
        if (taxAmount) netAmount = amount + taxAmount;
        taxAmount = taxAmount.toFixed(2);
        netAmount = netAmount.toFixed(2);
        amount = amount.toFixed(2);
        if (prop.isActive === 1) {
          var obj = {};
          if (
            this.state.selectedRowData &&
            this.state.selectedRowData.index == key &&
            this.state.inwardStatus != "3" &&
            this.state.inwardStatus != "4"
          ) {
            obj = {
              ...prop,
              taxAmount: taxAmount,
              amount: amount,
              qty: (
                <div>
                  <TextField
                    id="quantity"
                    className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;
                      console.log(
                        "getting the event.target.value2",
                        parseFloat(event.target.value),
                        list[key],
                        list[key].poQty
                        // list[key].poQuantity,
                      );
                      if (event.target.value !== "") {
                        if (list[key].poId && list[key].poQty) {
                          if (
                            parseFloat(event.target.value) > list[key].poQty
                          ) {
                            this.basicAlert(
                              "Quantity Should Not Be Greater Than PO Quantity " +
                                list[key].poQty
                            );
                          } else {
                            list[key].quantity = event.target.value;
                            this.setState({ invoiceList: list });
                          }
                        } else {
                          list[key].quantity = event.target.value;
                          this.setState({ invoiceList: list });
                        }
                      } else {
                        this.basicAlert("Quantity should not be empty..!!");
                      }
                    }}
                    value={prop.quantity}
                    fullWidth={true}
                    iType="number"
                    variant="outlined"
                  />
                </div>
              ),
              hsn: (
                <div>
                  <TextField
                    id="hsnCode"
                    className={classes.textField}
                    onChange={(event) => {
                      var list = this.state.invoiceList;
                      list[key].hsnCode = event.target.value;
                      this.setState({ invoiceList: list });
                    }}
                    value={prop.hsnCode}
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
              ),
              subitem:
                prop.item && prop.item.length > 220
                  ? prop.item.substring(0, 220) + "..."
                  : prop.item,
              rateData: prop.poId ? (
                prop.rate
              ) : (
                <TextField
                  id="rate"
                  className={classes.textField}
                  onChange={(event) => {
                    var list = this.state.invoiceList;
                    if (event.target.value !== "") {
                      list[key].rate = event.target.value;
                      this.setState({ invoiceList: list });
                    }
                  }}
                  value={prop.quantity}
                  fullWidth={true}
                  iType="number"
                  variant="outlined"
                />
              ),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {netAmount}
                  <IconButton
                    onClick={() => {
                      var data = this.state.invoiceList;
                      data.find((o, i) => {
                        if (o.id === key) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //if (this.state.approveButton) {

                          this.setState({
                            deleteItem: true,
                            id: key,
                          });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                          // }
                          return true;
                        }
                      });
                      this.setState({ invoiceList: data });
                    }}
                    color="dangerNoBackground"
                    customClass="remove"
                  >
                    <Close />
                  </IconButton>{" "}
                </div>
              ),
            };
          } else {
            obj = {
              ...prop,
              taxAmount: taxAmount,
              amount: amount,
              qty: prop.quantity,
              subitem:
                prop.item && prop.item.length > 220
                  ? prop.item.substring(0, 220) + "..."
                  : prop.item,
              rateData: prop.rate,
              hsn: prop.hsnCode,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {netAmount}
                  <IconButton
                    onClick={() => {
                      var data = this.state.invoiceList;
                      data.find((o, i) => {
                        if (o.id === key) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          //if (this.state.approveButton) {

                          this.setState({
                            deleteItem: true,
                            id: key,
                          });

                          this.basicItemAlert(
                            "Do You Like To Delete This Item?"
                          );
                          //  data.splice(i,1);
                          // }
                          return true;
                        }
                      });
                      this.setState({ invoiceList: data });
                    }}
                    color="dangerNoBackground"
                    customClass="remove"
                  >
                    <Close />
                  </IconButton>{" "}
                </div>
              ),
            };
          }

          data.push(obj);
        }
      });
    return data;
  };
  setHoveredRow = (rowInfo) => {
    console.log("hovver", rowInfo);
    this.setState({ selectedRowData: rowInfo });
  };
  handleSelectedUnitValue = (defValue) => {
    var data = this.state.invoiceList,
      sum_of_tax = 0,
      sumOfamt = 0;
    data.map((prop, i) => {
      var SlectedTaxType = defValue;
      if (SlectedTaxType === 1 || SlectedTaxType === 3) {
        prop.isgst = prop.taxPercent;
        prop.ssgst = 0;
        prop.csgst = 0;
      } else {
        var taxPercent = prop.taxPercent / 2;
        prop.isgst = 0;
        prop.ssgst = taxPercent;
        prop.csgst = taxPercent;
      }
    });

    this.setState({
      invoiceList: data,
      loadTaxType: true,
    });
  };
  gettaxpercent = (selectedValue) => {
    var values = selectedValue;
    values = values.split("^");
    var taxPercent = values[1];

    var itemAmount = this.getAmount(
      this.state.quantity,
      this.state.rate,
      this.state.InsRate,
      this.state.discountMode,
      this.state.discountPercentage,
      this.state.approvedQTY,
      this.state.rejectedQTY
    );
    if (taxPercent !== "") {
      if (itemAmount !== "") {
        var itemtaxAmount =
          (parseFloat(itemAmount) * parseFloat(taxPercent)) / 100;
        //  itemtaxAmount = Math.round(itemtaxAmount);
        this.setState({
          itemTaxAmt: itemtaxAmount,
          taxPercentage: parseFloat(taxPercent),
        });
      } else {
        this.setState({
          itemTaxAmt: 0,
          taxPercentage: parseFloat(taxPercent),
        });
      }
    }
    var SlectedTaxType = this.state.taxType;
    //alert(SlectedTaxType);
    if (SlectedTaxType === 1 || SlectedTaxType === 3) {
      this.setState({
        igstPercentage: taxPercent,
        sgstPercentage: 0,
        cgstPercentage: 0,
      });
    } else {
      taxPercent = taxPercent / 2;
      this.setState({
        igstPercentage: 0,
        sgstPercentage: taxPercent,
        cgstPercentage: taxPercent,
      });
    }
  };
  getAmount = (
    quantity,
    rate,
    insRate,
    disMode,
    discountPercentage,
    approvedQTY,
    rejectedQTY
  ) => {
    var amt = 0,
      rate1 = 0;

    if (parseFloat(approvedQTY) > 0) {
      quantity = approvedQTY;
    } else if (parseFloat(rejectedQTY) > 0) {
      quantity = parseFloat(quantity) - parseFloat(rejectedQTY);
    }

    if (insRate > 0 && insRate !== "0") {
      rate1 = parseFloat(rate1) + parseFloat(insRate);
    }
    if (rate > 0 && rate !== "0") rate1 = parseFloat(rate1) + parseFloat(rate);
    if (quantity > 0 && quantity !== "0") {
      amt = (parseFloat(rate1) * parseFloat(quantity)).toFixed(2);
    }
    var disAmount = 0;
    if (discountPercentage && discountPercentage !== "0") {
      if (disMode == "2") {
        disAmount = parseFloat(amt) * (parseFloat(discountPercentage) / 100);
      } else {
        disAmount = parseFloat(discountPercentage);
      }
    }
    amt = (parseFloat(amt) - disAmount).toFixed(2);
    console.log("amm", amt);

    return amt;
  };
  getTotalTax(tax) {
    var totSgst = 0,
      totIgst = 0,
      totcgst = 0,
      totalAmount = 0;
    var data = this.state.invoiceList;
    data.forEach((prop, key) => {
      if (prop.isActive === 1) {
        var qty = parseFloat(prop.quantity);
        if (parseFloat(prop.approvedQTY) > 0) {
          qty = prop.approvedQTY;
        } else if (parseFloat(prop.rejectedQTY) > 0) {
          qty = parseFloat(prop.quantity) - parseFloat(prop.rejectedQTY);
        }
        var amount = parseFloat(qty) * parseFloat(prop.rate);
        console.log("getting rate calculation", prop.rate);

        totalAmount = totalAmount + parseFloat(amount);
        if (prop.sgstPercentage !== undefined && prop.sgstPercentage !== null)
          totSgst =
            totSgst +
            (parseFloat(amount) * parseFloat(prop.sgstPercentage)) / 100;
        if (prop.igstPercentage !== undefined && prop.igstPercentage !== null)
          totIgst =
            totIgst +
            (parseFloat(amount) * parseFloat(prop.igstPercentage)) / 100;
        if (prop.cgstPercentage !== undefined && prop.cgstPercentage !== null)
          totcgst =
            totcgst +
            (parseFloat(amount) * parseFloat(prop.cgstPercentage)) / 100;
      }
    });
    var totalTax = 0;
    if (tax === "sgst") {
      totalTax = totSgst;
    } else if (tax === "igst") {
      totalTax = totIgst;
    } else if (tax === "cgst") {
      totalTax = totcgst;
    } else if (tax === "totalTax") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;
    } else if (tax === "totalAmount") {
      totalTax = totalAmount;
    } else if (tax === "netTotal") {
      if (totIgst > 0) totalTax = totIgst;
      if (totSgst > 0 && totcgst > 0) totalTax = totcgst + totSgst;

      totalTax = totalTax + totalAmount;
    }
    return totalTax.toFixed(2);
  }

  handlePrintData = () => {
    console.log(
      "getting the inward id on click print",
      // this.state.workRpId,
      this.state.inwardSequenceNo,
      this.state.selectedSupplier
    );
    // fetch(
    //   serverApi.PRINT_INWARD
    // )
    fetch(
      serverApi.PRINT_INWARD +
        this.state.workRpId +
        "/" +
        getCookie(cons.USERID) +
        "/" +
        38,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.state.inwardSequenceNo}_${this.state.selectedSupplier}.pdf`
        );
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      selectedPO,
      taxType,
      printingPop,
      updateView,
      inwardStatus,
      invoiceDate,
      chalanDate,
    } = this.state;
    var yesterday = Datetime.moment().subtract(1, "day");
    var tomorrow = Datetime.moment().add(0, "day");

    var isBackDate = getConfigValue(configNames.BACK_DATE_ALLOWABLE);
    var isfutureDate = getConfigValue(configNames.FUTURE_DATE_ALLOWABLE);
    const ucoptions = [{ value: 0, label: "Select...", name: "Select..." }];
    var taxTypes = getConfigValue(configNames.TAX_TYPES);
    if (taxTypes !== "1") {
      taxTypes = JSON.parse(taxTypes);
      if (taxTypes.gst === 1) {
        ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
        ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
      }
      if (taxTypes.vat === 1) {
        ucoptions.push({ value: 3, label: "VAT", name: "VAT" });
      }
      if (taxTypes.noTax === 1) {
        ucoptions.push({ value: 4, label: "NO TAX", name: "NO TAX" });
      }
    } else {
      ucoptions.push({ value: 1, label: "IGST", name: "IGST" });
      ucoptions.push({ value: 2, label: "CGST & SGST", name: "CGST & SGST" });
    }

    var valid = function(current) {
      if (isBackDate === "2" && isfutureDate === "2")
        return current.isAfter(yesterday) && current.isBefore(tomorrow);
      else if (isfutureDate === "2" && isBackDate === "1") {
        return current.isBefore(tomorrow);
      } else if (isfutureDate === "1" && isBackDate === "2") {
        return current.isAfter(yesterday);
      } else return true;
    };
    var invoicevalid = function(current) {
      if (chalanDate) {
        var momentA = moment(chalanDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        // console.log("momentB",momentB);
        return momentA <= momentB;
      } else return true;
    };
    var challanvalid = function(current) {
      if (invoiceDate) {
        var momentA = moment(invoiceDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        // console.log("momentB",momentB);
        return momentA <= momentB;
      } else return true;
    };
    var eawayvalid = function(current) {
      if (invoiceDate) {
        var momentA = moment(invoiceDate, "DD/MM/YYYY");
        var momentB = moment(current, "DD/MM/YYYY");
        // console.log("momentB",momentB);
        return momentA <= momentB;
      } else return true;
    };
    const inputFocus = {
      autoComplete: "off",
    };
    var options = [
      { value: 0, label: "Select..." },
      { value: 1, label: "With WO" },
      { value: 2, label: "Without WO" },
    ];
    var options1 = [{ value: 1, label: "With WO" }];
    var isoutButton = getConfigValue(configNames.GATE_ENTRY_OUT);
    var labelType = getConfigValue(configNames.LABELS_TYPE);
    var withoutpo = getConfigValue(configNames.GATE_ENTRY_WITHOUT_PO);
    var columns = [
      {
        Header: "HSN/SAC Code",
        accessor: "hsn",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Item Code",
        accessor: "itemGrp",
        minWidth: 50,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "WO No",
        accessor: "poNo",
        minWidth: 80,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Budget Head",
        accessor: "budgetHeadName",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Department",
        accessor: "departmentName",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Item Group",
        accessor: "itemGroup",
        minWidth: 100,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Item",
        accessor: "subitem",
        minWidth: 200,
        Cell: (row) => (
          <div>
            <span title={row.original ? row.original.item : row.value}>
              {row.value}
            </span>
          </div>
        ),
        style: {
          color: "#000",
          textAlign: "left",
          textOverflow: "unset",
        },
      },
      {
        Header: "Quantity",
        accessor: "qty",
        minWidth: 70,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Unit",
        accessor: "uomCode",
        width: 50,
        style: { color: "#000", textAlign: "left" },
      },

      {
        Header: "Rate",
        accessor: "rateData",
        style: {
          color: "#000",
          textAlign: "center",
        },
        sortable: false,
        filterable: false,
      },
      {
        Header: "Tax",
        accessor: "taxPercentage",
        width: 50,
        style: { color: "#000", textAlign: "left" },
      },
      {
        Header: "Amount",
        accessor: "amount",
        width: 100,
        style: {
          color: "#000",
          textAlign: "right",
        },
      },
      {
        Header: "Tax Amount",
        accessor: "taxAmount",
        width: 70,
        style: {
          color: "#000",
          textAlign: "right",
        },
      },
      {
        Header: "Net Amount",
        accessor: "actions",
        width: 150,
        style: { color: "#000", textAlign: "left" },
      },
    ];
    if (updateView) {
      columns = [
        {
          Header: "HSN/SAC Code",
          accessor: "hsn",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Item Code",
          accessor: "itemGrp",
          minWidth: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "WO No",
          accessor: "poNo",
          minWidth: 80,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Budget Head",
          accessor: "budgetHeadName",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Department",
          accessor: "departmentName",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Item Group",
          accessor: "itemGroup",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Item",
          accessor: "subitem",
          minWidth: 200,
          Cell: (row) => (
            <div>
              <span title={row.original ? row.original.item : row.value}>
                {row.value}
              </span>
            </div>
          ),
          style: {
            color: "#000",
            textAlign: "left",
            textOverflow: "unset",
          },
        },
        {
          Header: "Quantity",
          accessor: "qty",
          minWidth: 70,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Status",
          accessor: "status",
          minWidth: 120,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Approved Quantity",
          accessor: "approvedQuantity",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Rejected Quantity",
          accessor: "rejectedQTY",
          minWidth: 100,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Reason",
          accessor: "approvedReason",
          minWidth: 130,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Unit",
          accessor: "uomCode",
          width: 50,
          style: { color: "#000", textAlign: "left" },
        },

        {
          Header: "Rate",
          accessor: "rateData",
          style: {
            color: "#000",
            textAlign: "center",
          },
          sortable: false,
          filterable: false,
        },
        {
          Header: "Tax",
          accessor: "taxPercentage",
          width: 50,
          style: { color: "#000", textAlign: "left" },
        },
        {
          Header: "Amount",
          accessor: "amount",
          width: 100,
          style: {
            color: "#000",
            textAlign: "right",
          },
        },
        {
          Header: "Tax Amount",
          accessor: "taxAmount",
          width: 70,
          style: {
            color: "#000",
            textAlign: "right",
          },
        },
        {
          Header: "Net Amount",
          accessor: "actions",
          width: 150,
          style: { color: "#000", textAlign: "left" },
        },
      ];
    }

    return (
      <GridContainer justify="center" style={{ backgroundColor: "#00ABDC" }}>
        {this.state.isCreated ? (
          <ItemGrid xs={12}>
            <RegularCard
              isLoading={this.state.isLoading}
              cardTitle={
                <Button color={"info"} onClick={this.handleCreate}>
                  Back To List
                </Button>
              }
              // headerColor={"blue"}
              content={
                <GridContainer>
                  {//get indent alert
                  this.state.indentAlert && !this.state.updateView ? (
                    <Popup
                      onClickSave={() => this.afterSelectPO()}
                      closePopup={() => this.indenthideAlert()}
                      title=""
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={"OK"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid xs={12} md={12}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        PO List
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          *
                                        </span>
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <SelectboxTemp
                                        id={"selectedPO"}
                                        defValue={selectedPO ? selectedPO : []}
                                        onSelect={this.onSelect}
                                        getName={(name) => {
                                          var dates = name;
                                          console.log("dates", dates);
                                          if (dates) {
                                            var val = dates.split("^");
                                            return val[1];
                                          }
                                          // dates = dates.map((prop) => {
                                          //   var val = prop.split("^");
                                          //   return val[1];
                                          // });
                                          this.setState({ poDates: dates });
                                        }}
                                        stateKey="selectedPO"
                                        staticData={this.state.poList}
                                        isRefresh={this.state.loadPoList}
                                        setIsRefresh={() => {
                                          this.setState({ loadPoList: false });
                                        }}
                                        // postData={{
                                        // 	companyId: this.props.state.companyInfo.id,
                                        // 	branchId: this.state.branchId,
                                        // 	financialYear: getCookie(
                                        // 		cons.ACADEMIC_YEAR
                                        // 	),
                                        // 	recordType: "WO",
                                        // 	supplierId: this.state
                                        // 		.selectedSupplier,
                                        // }}
                                        // url={serverApi.GET_APPROVED_POS}
                                        value={selectedPO}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}

                  {this.state.costAlert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Cost Factor
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    id={"costFactor"}
                                    onSelect={this.onSelect}
                                    defValue={this.state.costFactor}
                                    getName={(name) => {
                                      this.setState({ costDesc: name });
                                    }}
                                    stateKey="costFactor"
                                    url={
                                      serverApi.SEARCH_COST_FACTOR +
                                      this.props.state.companyInfo.id +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={3}
                                    iType={"number"}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              sm={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    UOM
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="uomCode"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.uomCode}
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                    {this.state.selectedJuteQuality > 0 ? (
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          marginLeft: "150px",
                                          color: "red",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        {"Last purchase at  " +
                                          this.state.lastPurchase}
                                      </span>
                                    ) : null}
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>

                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Description
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="description"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    height={60}
                                    value={this.state.description}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.alert ? (
                    <Popup
                      onClickSave={() => this.onClickAddItem()}
                      closePopup={() => this.hideAlert()}
                      title="Post Temperature"
                      isShowSave={true}
                      isShowCancel={true}
                      save_btn_txt={this.state.updateItem ? "update" : "Add"}
                      cancel_button_text={"Cancel"}
                      // juteType={selectedJuteType}
                      content={
                        <form>
                          <GridContainer>
                            {!printingPop ? (
                              <ItemGrid xs={12} md={12}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={6}>
                                    <br />
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Department
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          selectDisabled={
                                            this.state.SelectedChalanType
                                              .value === 1
                                          }
                                          id={"departmentId"}
                                          onSelect={this.onSelect}
                                          defValue={this.state.departmentId}
                                          getName={this.getDeptName}
                                          stateKey="departmentId"
                                          url={
                                            serverApi.SEARCH_DEPATMENTS +
                                            this.props.state.companyInfo.id +
                                            "/" +
                                            getCookie(cons.CIPHER)
                                          }
                                          value={this.state.departmentId}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={6}>
                                    <br />
                                    <GridContainer>
                                      <ItemGrid xs={12} sm={4}>
                                        <InputLabel className={classes.label}>
                                          Budget Head
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            *
                                          </span>
                                        </InputLabel>
                                      </ItemGrid>
                                      <ItemGrid xs={12} sm={8}>
                                        <SelectboxTemp
                                          id={"budgetHeadId"}
                                          onSelect={this.onSelect}
                                          defValue={this.state.budgetHeadId}
                                          getName={this.getBudgetName}
                                          stateKey="budgetHeadId"
                                          staticData={this.state.budgetHeadList}
                                          isRefresh={this.state.loadDepartment}
                                          setIsRefresh={(val) => {
                                            this.setState({
                                              loadDepartment: false,
                                            });
                                          }}
                                          value={this.state.budgetHeadId}
                                        />
                                      </ItemGrid>
                                    </GridContainer>
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            {!printingPop ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item Group
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={
                                        this.state.SelectedChalanType.value ===
                                        1
                                      }
                                      defValue={this.state.itemGroupId}
                                      id={"itemGroupId"}
                                      onSelect={this.onSelect}
                                      getName={this.getItemGroupName}
                                      isRefresh={this.state.loadItemGroup}
                                      stateKey="itemGroupId"
                                      setIsRefresh={this.afterRefreshItemGrp}
                                      staticData={this.state.itemGroupList}
                                      value={this.state.itemGroupId}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {this.state.printingPop ? (
                              <ItemGrid xs={6} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      id={"itemCode"}
                                      defValue={this.state.itemCode}
                                      postData={{
                                        itemGroupId: "997",
                                        saleable: "",
                                        consumable: "",
                                        companyId: this.props.state.companyInfo
                                          .id,
                                        indentTypeId: "",
                                      }}
                                      url={serverApi.GET_ITEMGROUP}
                                      onSelect={this.onSelect}
                                      getName={this.getItemDetailsByName}
                                      stateKey="itemCode"
                                      //url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}
                            {!printingPop ? (
                              <ItemGrid xs={12} sm={6}>
                                <GridContainer>
                                  <ItemGrid xs={12} sm={4}>
                                    <InputLabel className={classes.label}>
                                      Item
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </InputLabel>
                                  </ItemGrid>
                                  <ItemGrid xs={12} sm={8}>
                                    <SelectboxTemp
                                      selectDisabled={
                                        this.state.SelectedChalanType.value ==
                                          1 && !printingPop
                                      }
                                      defValue={this.state.itemCode}
                                      id={"itemCode"}
                                      onSelect={this.onSelect}
                                      staticData={this.state.ItemOptions}
                                      getName={this.getItemDetailsByName}
                                      isRefresh={this.state.loadItem}
                                      stateKey="itemCode"
                                      setIsRefresh={this.afterRefreshItem}
                                      //   url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroupId+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                      value={this.state.itemCode}
                                    />
                                  </ItemGrid>
                                </GridContainer>
                              </ItemGrid>
                            ) : null}

                            <ItemGrid xs={12} sm={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Quantity
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      *
                                    </span>
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="quantity"
                                    className={classes.textField}
                                    onChange={this.onChangeValues}
                                    value={this.state.quantity}
                                    fullWidth={true}
                                    variant="outlined"
                                    decimal={3}
                                    iType={"number"}
                                    InputProps={{
                                      autoComplete: "off",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <span
                                            style={{
                                              color: "#00ABDC",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {this.state.uomCode}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Price
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="rate"
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        style={{ height: 30 }}
                                        value={this.state.rate}
                                        iType={"number"}
                                        decimal={3}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={6}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                  <GridContainer>
                                    <ItemGrid xs={12} sm={4}>
                                      <InputLabel className={classes.label}>
                                        Amount
                                      </InputLabel>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={8}>
                                      <TextField
                                        id="amount"
                                        disabled={true}
                                        className={classes.textField}
                                        onChange={this.onChangeValues}
                                        fullWidth={true}
                                        variant="outlined"
                                        InputProps={inputFocus}
                                        value={this.getAmount(
                                          this.state.quantity,
                                          this.state.rate,
                                          0,
                                          0,
                                          0,
                                          this.state.approvedQTY,
                                          this.state.rejectedQTY
                                        )}
                                        iType={"number"}
                                      />
                                    </ItemGrid>
                                  </GridContainer>
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={6} md={6}>
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Tax
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <SelectboxTemp
                                    defValue={this.state.taxId}
                                    id={"taxId"}
                                    onSelect={this.onSelect}
                                    stateKey="taxId"
                                    getName={this.gettaxpercent}
                                    isReload={this.state.loadTax}
                                    setIsReload={this.afterRefreshTax}
                                    url={
                                      serverApi.TAX_MASTER_LIST +
                                      "/" +
                                      this.props.state.companyInfo.id +
                                      "/" +
                                      getCookie(cons.CIPHER)
                                    }
                                    value={this.state.taxId}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                            <ItemGrid
                              xs={12}
                              md={6}
                              style={{ marginTop: "10px" }}
                            >
                              <GridContainer>
                                <ItemGrid xs={12} sm={4}>
                                  <InputLabel className={classes.label}>
                                    Remarks
                                  </InputLabel>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={8}>
                                  <TextField
                                    id="remarks1"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    height={60}
                                    value={this.state.remarks1}
                                    onChange={this.onChangeValues}
                                    className={classes.textField}
                                  />
                                </ItemGrid>
                              </GridContainer>
                            </ItemGrid>
                          </GridContainer>
                        </form>
                      }
                    />
                  ) : null}
                  {this.state.basicAlert1}

                  {this.state.basicAlert}
                  {this.state.showMessagealert}
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Date
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            timeFormat={false}
                            isValidDate={valid}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "date1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "date1",
                              value: this.state.date1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Time
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            defaultValue={new Date()}
                            dateFormat={false}
                            onChange={(timeObj) => {
                              this.timeChanged1(timeObj, "time1");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "time1",
                              value: this.state.time1,
                              autoComplete: "off",
                              disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="chalanNo"
                          className={classes.textField}
                          value={this.state.chalanNo}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Challan Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            isValidDate={valid && challanvalid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "chalanDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "chalanDate",
                              value: this.state.chalanDate,
                              autoComplete: "off",
                              //disabled: this.state.updateView,
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Vehicle Number
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="vehicleNumber"
                          // className={classes.textField}
                          value={this.state.vehicleNumber}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Driver Name
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="driverContactNumber"
                          className={classes.textField}
                          value={this.state.driverContactNumber}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {/* <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {this.state.isSupplier ? "Bill To" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          onSelect={this.onSelect}
                          stateKey="branchId"
                          // isRefresh={this.state.loadQuality}
                          // setIsRefresh={this.afterRefreshQuality}
                          url={
                            serverApi.SELECT_BRANCH + this.props.state.companyInfo.id
                          }
                          value={this.state.branchId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid> */}
                  {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Supplier
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <SelectboxTemp
                            id={"selectedSupplier"}
                            // selectDisabled={
                            //   this.state.updateView &&
                            //   this.state.SelectedChalanType.value !== 2 &&
                            //   this.state.outDateTime === "" &&
                            //   this.state.isDisabled
                            // }
                            //selectDisabled={this.state.isDisabled}
                            defValue={this.state.selectedSupplier}
                            isReload={this.state.reloadSelect}
                            setIsReload={this.afterReloadSelect}
                            onSelect={this.onSelect}
                            getName={this.getSupplierName}
                            stateKey="selectedSupplier"
                            staticData={this.state.supplierList}
                            isRefresh={this.state.loadSupplier}
                            setIsRefresh={(val) => {
                              this.setState({ loadSupplier: false });
                            }}
                            // url={serverApi.SELECT_SUPPLIER_STORE + this.props.state.companyInfo.id}
                            value={this.state.selectedSupplier}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Source
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <Select
                          isDisabled={this.state.updateView}
                          onChange={this.handleSelectedValues}
                          options={options}
                          defaultValue={this.state.SelectedChalanType}
                          name={"SelectedChalanType"}
                          value={this.state.SelectedChalanType}
                          className={classes.selectFormControl}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            // height:"20px",
                            colors: {
                              ...theme.colors,
                              //  primary25: '#00ABDC',
                              primary: "#00ABDC",
                              //   primary50:"#00ABDC"
                            },
                          })}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: "30px",
                              "min-height": "30px",
                              marginBottom: "10px",
                            }),
                          }}
                        />
                        {/* <span>{this.state.selectedPO}</span> */}
                        {/* <SelectboxTemp
                          isDisabled={this.state.updateView}
                          defValue={getCookie(cons.USER_ROLE_ID) == "100" || getCookie(cons.USER_ROLE_ID) == "99" ? 1 :  this.state.SelectedChalanType}
                          id={"SelectedChalanType"}
                          // onSelect={this.onSelect}
                          onSelect={this.handleSelectedValues}
                          stateKey="SelectedChalanType"
                          staticData={getCookie(cons.USER_ROLE_ID) == "100" || getCookie(cons.USER_ROLE_ID) == "99" ? options1 : options}     
                          name={"SelectedChalanType"}                   
                          value={getCookie(cons.USER_ROLE_ID) == "100" || getCookie(cons.USER_ROLE_ID) == "99" ? 1 :  this.state.SelectedChalanType}
                        /> */}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          {this.state.isSupplier ? "Bill To" : "Branch"}
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"branchId"}
                          selectDisabled={this.state.isDisabled}
                          defValue={this.state.branchId}
                          value={this.state.branchId}
                          onSelect={this.onSelect}
                          getName={(name) => {
                            this.setState({ branchName: name });
                          }}
                          isRefresh={this.state.loadTaxType}
                          setIsRefresh={(val) => {
                            this.setState({ loadCustomer: false });
                          }}
                          stateKey="branchId"
                          staticData={this.state.branchList}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Project
                          <span style={{ color: "red", fontSize: "12px" }}>
                            *
                          </span>
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          selectDisabled={this.state.isDisabled}
                          // url={serverApi.PROJECT_MASTER_SELECTION_LIST + this.props.state.companyInfo.id + "/customer/0/branch/" + this.state.branchId}
                          defValue={this.state.projectId}
                          staticData={this.state.projectNameList}
                          onSelect={this.onSelect}
                          stateKey="projectId"
                          value={this.state.projectId}
                          isRefresh={this.state.loadProject}
                          setIsRefresh={(val) => {
                            this.setState({ loadProject: false });
                          }}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Client
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          defValue={this.state.customerId}
                          id={"customerId"}
                          onSelect={this.onSelect}
                          stateKey="customerId"
                          staticData={this.state.customerList}
                          isRefresh={this.state.loadCustomer}
                          setIsRefresh={() => {
                            this.setState({ loadCustomer: false });
                          }}
                          getName={(name) => {
                            this.setState({ customerName: name });
                          }}
                          value={this.state.customerId}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Consignment Date
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <FormControl variant="" fullWidth>
                          <Datetime
                            closeOnSelect={true}
                            //  isValidDate={valid}
                            timeFormat={false}
                            onChange={(dateObj) => {
                              this.timeChanged(dateObj, "consignmentDate");
                            }}
                            height={"15px"}
                            formControlProps={{
                              height: "15px",
                              inlineBlock: "none",
                            }}
                            inputProps={{
                              id: "consignmentDate",
                              value: this.state.consignmentDate,
                              autoComplete: "off",
                            }}
                          />
                        </FormControl>
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Consignment No
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          //  disabled={true}
                          id="consignmentNumber"
                          // className={classes.textField}
                          value={this.state.consignmentNumber}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                          iType={"number"}
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Goods Type
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="goodType"
                          disabled="true"
                          // label="Multiline"
                          fullWidth
                          value={this.state.goodType}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          // helperText="hello"
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_ROLE_ID) === "100" ||
                  getCookie(cons.USER_ROLE_ID) === "99" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Invoice No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="invoiceNumber"
                            value={this.state.invoiceNumber}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            inputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Invoice No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="invoiceNumber"
                            value={this.state.invoiceNumber}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            inputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  )}
                  {getCookie(cons.USER_ROLE_ID) === "100" ||
                  getCookie(cons.USER_ROLE_ID) === "99" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Invoice Date
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              isValidDate={invoicevalid}
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "invoiceDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "invoiceDate",
                                value: this.state.invoiceDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Invoice Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              isValidDate={invoicevalid}
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "invoiceDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "invoiceDate",
                                value: this.state.invoiceDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  )}
                  {getCookie(cons.USER_ROLE_ID) === "100" ||
                  getCookie(cons.USER_ROLE_ID) === "99" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Eway Bill No
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="ewaybillno"
                            value={this.state.ewaybillno}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            inputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Eway Bill No
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <TextField
                            id="ewaybillno"
                            value={this.state.ewaybillno}
                            className={classes.textField}
                            onChange={this.onChangeValues}
                            fullWidth={true}
                            variant="outlined"
                            inputProps={inputFocus}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  )}
                  {getCookie(cons.USER_ROLE_ID) === "100" ||
                  getCookie(cons.USER_ROLE_ID) === "99" ? (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Eway Bill Date
                            <span style={{ color: "red", fontSize: "12px" }}>
                              *
                            </span>
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              isValidDate={eawayvalid}
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "ewaybillnoDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "ewaybillnoDate",
                                value: this.state.ewaybillnoDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : (
                    <ItemGrid xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={4}>
                          <InputLabel className={classes.label}>
                            Eway Bill Date
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={8}>
                          <FormControl variant="" fullWidth>
                            <Datetime
                              isValidDate={eawayvalid}
                              closeOnSelect={true}
                              timeFormat={false}
                              onChange={(dateObj) => {
                                this.timeChanged(dateObj, "ewaybillnoDate");
                              }}
                              height={"15px"}
                              formControlProps={{
                                height: "15px",
                                inlineBlock: "none",
                              }}
                              inputProps={{
                                id: "ewaybillnoDate",
                                value: this.state.ewaybillnoDate,
                                autoComplete: "off",
                              }}
                            />
                          </FormControl>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  )}
                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Tax Type
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <SelectboxTemp
                          id={"taxType"}
                          selectDisabled={this.state.updateView}
                          defValue={this.state.taxType}
                          onSelect={this.onSelect}
                          getName={(name) => {
                            this.setState({ taxTypeDesc: name });
                          }}
                          isRefresh={this.state.loadTaxType}
                          setIsRefresh={(val) => {
                            this.setState({ loadTaxType: false });
                          }}
                          stateKey="taxType"
                          staticData={ucoptions}
                        />
                        {/* <Select
													isDisabled={this.state.updateView}
													defValue={this.state.taxType}
													name={"taxType"}
													value={this.state.taxType}
													onChange={this.handleSelectedUnitValue}
													options={ucoptions}
													className={classes.selectFormControl}
													theme={(theme) => ({
														...theme,
														borderRadius: "4px",
														colors: {
															...theme.colors,
															primary: "#00ABDC",
														},
													})}
													styles={{
														control: (base, state) => ({
															...base,
															height: "30px",
															"min-height": "30px",
														}),
													}}
												/> */}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={4}>
                        <InputLabel className={classes.label}>
                          Remarks
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={8}>
                        <TextField
                          id="remarks"
                          // label="Multiline"
                          fullWidth
                          multiline={true}
                          rowsMax="3"
                          value={this.state.remarks}
                          onChange={this.onChangeValues}
                          className={classes.textField}
                          height={60}
                          // helperText="hello"
                          variant="outlined"
                        />
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} sm={12} style={{ marginTop: "10px" }}>
                    <GridContainer justify="center">
                      <ItemGrid xs={12}>
                        {/* {console.log(this.state.invoiceList.length+" length")}*/}
                        <LineItemTable
                          data={this.getDataTable()}
                          loading={this.state.loading}
                          setHoveredRow={this.setHoveredRow}
                          hideOverFlow={true}
                          disableActions={
                            this.state.inwardStatus == 3 ||
                            this.state.inwardStatus == 4
                          }
                          columns={columns}
                        />
                      </ItemGrid>
                      {this.state.SelectedChalanType.value == 2 &&
                      this.state.inwardStatus != "3" &&
                      this.state.inwardStatus != "4" &&
                      this.state.inwardStatus != "5" &&
                      this.state.inwardStatus != "6" &&
                      !this.state.isSupplier ? (
                        <ItemGrid
                          xs={12}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              itemGroupId: "997",
                              printingPop: true,
                              alert: true,
                            });
                          }}
                        >
                          Click Here To Add Printing Charges/Labour
                          Charges/Transport Charges/Delivery Charges/Packing &
                          Forwarding Charges/Other Charges
                        </ItemGrid>
                      ) : null}
                      <ItemGrid xs={12}>
                        {this.state.inwardStatus != 3 &&
                        this.state.inwardStatus != 4 &&
                        this.state.SelectedChalanType.value === 2 ? (
                          <Button
                            color={"info"}
                            right={true}
                            onClick={this.handleAddItem}
                          >
                            Add Item
                          </Button>
                        ) : null}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  {this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={6}>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: 400,
                              color: "#003F66",
                            }}
                          >
                            PO Net Amount:
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#003F66",
                            }}
                          >
                            {" " + this.state.poValueWithoutTax}
                            <br />
                          </span>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid xs={12}>
                      <GridContainer>
                        <ItemGrid xs={6}>
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: 400,
                              color: "#003F66",
                            }}
                          >
                            PO Total Amount:
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#003F66",
                            }}
                          >
                            {" " + this.state.poValueWithTax}
                            <br />
                          </span>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView && this.state.status === 3 ? (
                    getCookie(cons.USER_ROLE_ID) === "100" ? null : this.state
                        .storeApprovedBy === null ? null : (
                      <ItemGrid xs={12}>
                        <GridContainer>
                          <ItemGrid xs={6}>
                            <span
                              style={{
                                fontStyle: "italic",
                                fontWeight: 400,
                                color: "#003F66",
                              }}
                            >
                              Approved By:
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#003F66",
                              }}
                            >
                              {" " + this.state.storeApprovedBy}
                              <br />
                            </span>
                          </ItemGrid>
                        </GridContainer>
                      </ItemGrid>
                    )
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          {taxType === 3 ? "Total VAT :" : "Total IGST :"}
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.getTotalTax("igst")}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {taxType !== 3 ? (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                          <InputLabel className={classes.label}>
                            Total SGST :
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                          {this.getTotalTax("sgst")}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {taxType !== 3 ? (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                          <InputLabel className={classes.label}>
                            Total CGST :
                          </InputLabel>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                          {this.getTotalTax("cgst")}
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          Total Amount :
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.getTotalTax("totalAmount")}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      {/* <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          Credit Note:
                        </InputLabel>
                      </ItemGrid> */}
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {/* {this.getTotalTax("netTotal")} */}
                        {/* <TextField
                          id="creditNote"
                          className={classes.textField}
                          // defaultValue={this.state.creditnoteValue}
                          onChange={this.onChangeValues}
                          fullWidth={true}
                          variant="outlined"
                          InputProps={inputFocus}
                          style={{ height: 30 }}
                          value={this.state.creditNote}
                          iType={"number"}
                          decimal={3}
                        /> */}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>

                  <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                    <GridContainer>
                      <ItemGrid xs={12} sm={9} style={{ textAlign: "right" }}>
                        <InputLabel className={classes.label}>
                          Net Total:
                        </InputLabel>
                      </ItemGrid>
                      <ItemGrid xs={12} sm={2} style={{ textAlign: "right" }}>
                        {this.getTotalTax("netTotal") - this.state.creditNote}
                      </ItemGrid>
                    </GridContainer>
                  </ItemGrid>
                  {getCookie(cons.USER_ROLE_ID) === "100" ? null : (
                    <ItemGrid xs={12} style={{ marginTop: "10px" }}>
                      <GridContainer>
                        <ItemGrid xs={12} md={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Internal Note
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="internalNote"
                                fullWidth
                                multiline
                                rowsMax="3"
                                height={60}
                                value={this.state.internalNote}
                                onChange={this.onChangeValues}
                                className={classes.textField}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  )}

                  {(this.state.updateView &&
                    getCookie(cons.USER_ROLE_ID) !== "100" &&
                    this.state.status === 1) ||
                  this.state.status === 21 ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : this.state.status === 3 &&
                    this.state.filesList.length > 0 ? (
                    <ItemGrid xs={12} sm={12}>
                      <InputLabel className={classes.label}>
                        Support Documents :
                      </InputLabel>
                    </ItemGrid>
                  ) : null}

                  {this.state.updateView ? (
                    <ItemGrid
                      xs={8}
                      style={{ overflowY: "auto", maxHeight: 250 }}
                    >
                      {this.state.filesList.map((r, i) => (
                        <GridContainer style={{ marginTop: "10px" }}>
                          <ItemGrid xs={12} sm={2}>
                            {i + 1}
                          </ItemGrid>
                          <ItemGrid xs={12} sm={6}>
                            <a
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL + r.retrievalPath,
                                  r.fileName,
                                  r.fileExtension,
                                  this.handleDownloadedFile
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {r.fileName !== null && r.fileName !== ""
                                ? r.fileName
                                : "indent" + (i + 1)}
                            </a>
                          </ItemGrid>
                          {this.state.status === 21 ? (
                            <ItemGrid xs={4}>
                              <IconButton
                                right={true}
                                onClick={() => {
                                  this.setState({
                                    deleteInvoice: true,
                                    invoiceId: r.fileUploadId,
                                    fileId: i,
                                  });
                                  this.InvoicebasicAlert(
                                    "Do You Like To Delete This File?"
                                  );
                                }}
                                color="dangerNoBackground"
                              >
                                <Close />
                              </IconButton>{" "}
                              {""}
                            </ItemGrid>
                          ) : null}
                        </GridContainer>
                      ))}
                    </ItemGrid>
                  ) : null}

                  {(this.state.updateView &&
                    getCookie(cons.USER_ROLE_ID) === "100" &&
                    getCookie(cons.USER_ROLE_ID) === "99" &&
                    this.state.status !== 1) ||
                  this.state.status === 21 ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : (this.state.updateView &&
                      getCookie(cons.USER_ROLE_ID) !== "100" &&
                      getCookie(cons.USER_ROLE_ID) !== "99" &&
                      this.state.status === 1) ||
                    this.state.status === 21 ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null}

                  {/* {this.state.updateView &&
                  this.state.outDateTime === "" &&
                  this.state.status !== 1 &&
                  this.state.status == 21 ? (
                    <ItemGrid xs={4}>
                      <Button color={"info"} onClick={this.handleFileClick}>
                        Upload File
                      </Button>
                    </ItemGrid>
                  ) : null} */}
                  {!this.state.updateView ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        <ItemGrid>
                          <Button
                            style={{ width: 80 }}
                            color="primary"
                            onClick={this.onCreateButtonClick}
                          >
                            Create
                          </Button>
                        </ItemGrid>
                        <ItemGrid>
                          <Button
                            style={{ width: 80 }}
                            color="danger"
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {this.state.updateView &&
                  this.state.outDateTime === "" &&
                  inwardStatus != 3 &&
                  inwardStatus != 4 &&
                  inwardStatus != 5 &&
                  inwardStatus != 6 ? (
                    <ItemGrid xs={12} style={{ marginTop: "5px" }}>
                      <GridContainer justify="center">
                        {/* <ItemGrid>
                          <Button
                            color="primary"
                            style={{ width: 80 }}
                            onClick={() => {
                              this.onApproveButtonClick("");
                            }}
                          >
                            Update
                          </Button>
                        </ItemGrid> */}
                        {getCookie(cons.USER_ROLE_ID) === "100" ? (
                          this.state.status !== 1 ||
                          this.state.status === 21 ? (
                            <ItemGrid>
                              <Button
                                color="primary"
                                style={{ width: 80 }}
                                onClick={() => {
                                  this.onApproveButtonClick("");
                                }}
                              >
                                Update
                              </Button>
                            </ItemGrid>
                          ) : null
                        ) : (
                          <ItemGrid>
                            <Button
                              color="primary"
                              style={{ width: 80 }}
                              onClick={() => {
                                this.onApproveButtonClick("");
                              }}
                            >
                              Update
                            </Button>
                          </ItemGrid>
                        )}
                        {getCookie(cons.USER_ROLE_ID) !== "100" &&
                        getCookie(cons.USER_ROLE_ID) !== "99" ? (
                          this.state.status === 21 ? (
                            <ItemGrid>
                              <Button
                                color="primary"
                                style={{ width: 80 }}
                                onClick={this.onAcceptButtonClick}
                              >
                                Accept
                              </Button>
                            </ItemGrid>
                          ) : null
                        ) : null}
                        {this.state.approveButton &&
                        getCookie(cons.USER_ROLE_ID) !== "100" &&
                        getCookie(cons.USER_ROLE_ID) !== "99" ? (
                          <ItemGrid>
                            <Button
                              style={{ width: 80 }}
                              color="success"
                              onClick={() => {
                                this.setState({ isApprove: true });
                                this.InvoicebasicAlert(
                                  "Do You Like To Approve..!!"
                                );
                              }}
                            >
                              Approve
                            </Button>
                          </ItemGrid>
                        ) : null}
                        {this.state.approveButton &&
                        getCookie(cons.USER_ROLE_ID) !== "100" &&
                        getCookie(cons.USER_ROLE_ID) !== "99" ? (
                          <ItemGrid>
                            <Button
                              style={{ width: 80 }}
                              color="danger"
                              // onClick={() => {
                              //   this.setState({ isReject: true });
                              //   this.InvoicebasicAlert(
                              //     "Do You Like To Reject..!!"
                              //   );
                              // }}
                              onClick={this.onRejectButtonClick}
                            >
                              Reject
                            </Button>
                          </ItemGrid>
                        ) : null}

                        {isoutButton !== "2" ? (
                          <ItemGrid>
                            <Button
                              color="success"
                              style={{ width: 80 }}
                              onClick={this.onOutButtonClick}
                            >
                              Out
                            </Button>
                          </ItemGrid>
                        ) : null}

                        <ItemGrid>
                          <Button
                            color="danger"
                            style={{ width: 80 }}
                            onClick={this.handleCreate}
                          >
                            Cancel
                          </Button>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  ) : null}
                  {/* {getCookie(cons.USER_ROLE_ID) === "100" ? (
                    this.state.updateView &&
                    this.state.status !== 1 &&
                    this.state.status === 21 ? (
                      <LogsComponents
                        id={this.state.entryId}
                        recType={"INWARD"}
                      />
                    ) : null
                  ) : this.state.status === 3 ? null : (
                    <LogsComponents
                      id={this.state.entryId}
                      recType={"INWARD"}
                    />
                  )} */}

                  {getCookie(cons.USER_ROLE_ID) === "100" ? null : (
                    <LogsComponents
                      id={this.state.entryId}
                      recType={"INWARD"}
                    />
                  )}
                  {this.state.updateView ? (
                    // <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                    //   <SamplePDF
                    //     invoiceType={24}
                    //     printResp={this.state.printResp}
                    //   />
                    // </ItemGrid>
                    <ItemGrid xs={12} style={{ marginTop: "15px" }}>
                      <div className="exportPdf">
                        <IconButton
                          color="primary"
                          onClick={this.handlePrintData}
                        >
                          {<Print />}
                        </IconButton>
                      </div>
                    </ItemGrid>
                  ) : null}

                  {/*
                                    {this.state.updateView && this.state.outDateTime !== ""?
                                        <ItemGrid xs={12} style={{marginTop:"5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="primary" onClick={this.onApproveButtonClick}>update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button style={{width:80}} color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>:null
                                    }
*/}
                </GridContainer>
              }
            />
          </ItemGrid>
        ) : (
          <ItemGrid xs={12}>
            <RegularCard
              /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
              content={
                // removed search field from list table
                <div className="workReportTable">
                  <GridContainer>
                    <ItemGrid xs={10}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                From date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "fromDate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "fromDate",
                                    value: this.state.fromDate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                To date
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <FormControl variant="outlined" fullWidth>
                                <Datetime
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(dateObj) => {
                                    this.timeChanged(dateObj, "todate");
                                  }}
                                  formControlProps={{
                                    height: "10px",
                                    inlineBlock: "none",
                                  }}
                                  inputProps={{
                                    id: "toDate",
                                    value: this.state.todate,
                                    autoComplete: "off",
                                  }}
                                />
                              </FormControl>
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Status
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                defValue={this.state.statusList}
                                id={"status"}
                                onSelect={this.onSelect}
                                stateKey="status"
                                url={serverApi.SEARCH_STORE_STATUS}
                                value={this.state.statusList}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                WO No
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="poNum"
                                value={this.state.poNum}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Work Report No.
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <TextField
                                id="wrNum"
                                value={this.state.wrNum}
                                className={classes.textField}
                                onChange={this.onChangeValues}
                                fullWidth={true}
                                variant="outlined"
                                style={{ height: 30 }}
                                InputProps={inputFocus}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                        {getCookie(cons.USER_ROLE_ID) !== "100" ? (
                          <ItemGrid
                            xs={12}
                            md={6}
                            style={{ marginTop: "10px" }}
                          >
                            <GridContainer>
                              <ItemGrid xs={12} sm={4}>
                                <InputLabel className={classes.label}>
                                  Supplier
                                </InputLabel>
                              </ItemGrid>
                              <ItemGrid xs={12} sm={8}>
                                <SelectboxTemp
                                  id={"supplierId"}
                                  onSelect={this.onSelect}
                                  stateKey="supplierId"
                                  staticData={this.state.supplierList}
                                  isRefresh={this.state.loadSupplier}
                                  setIsRefresh={(val) => {
                                    this.setState({ loadSupplier: false });
                                  }}
                                  // url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                  value={this.state.supplierId}
                                />
                              </ItemGrid>
                            </GridContainer>
                          </ItemGrid>
                        ) : null}
                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Branch
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              <SelectboxTemp
                                // selectDisabled={true}
                                defValue={this.state.branchIds}
                                onSelect={this.onSelect}
                                stateKey="branchIds"
                                url={
                                  serverApi.SELECT_BRANCH +
                                  this.props.state.companyInfo.id
                                }
                                value={this.state.branchIds}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>

                        <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                          <GridContainer>
                            <ItemGrid xs={12} sm={4}>
                              <InputLabel className={classes.label}>
                                Project
                              </InputLabel>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={8}>
                              {/* <SelectboxTemp
                                url={
                                  serverApi.PROJECT_MASTER_SELECTION_LIST +
                                  this.props.state.companyInfo.id +
                                  "/customer/0/branch/" +
                                  this.state.branchIds
                                }
                                defValue={this.state.projectIds}
                                // staticData={this.state.ProjectOptions}
                                onSelect={this.onSelect}
                                stateKey="projectIds"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              /> */}
                              <SelectboxTemp
                                //    selectDisabled={this.state.SelectindentType.value===1||this.state.updateView}
                                // url={serverApi.PROJECT_MASTER_SELECTION_LIST+this.props.state.companyInfo.id+"/customer/0/branch/"+this.state.branchId}
                                defValue={this.state.projectIds}
                                staticData={this.state.projectNameList}
                                onSelect={this.onSelect}
                                stateKey="projectId"
                                value={this.state.projectIds}
                                isRefresh={this.state.loadProject}
                                setIsRefresh={(val) => {
                                  this.setState({ loadProject: false });
                                }}
                              />
                            </ItemGrid>
                          </GridContainer>
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={2}>
                      <GridContainer>
                        <ItemGrid xs={12}>
                          <IconButton
                            onClick={this.handleCreate}
                            color="success"
                            right={true}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <br />
                        </ItemGrid>
                        <ItemGrid xs={12}>
                          <Button
                            color="primary"
                            right={true}
                            onClick={this.handleSeacrh}
                          >
                            Search
                          </Button>
                          <br />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>

                    <ItemGrid
                      xs={12}
                      style={{
                        height: 2,
                        backgroundColor: "#00acc1",
                        marginTop: "15px",
                      }}
                    />

                    <ItemGrid xs={12}>
                      <PaginationTableComponent
                        // url={serverApi.GET_ALL_INWARDS}
                        // searchData={this.getData()}
                        // dataTableAll={this.state.dataTable}
                        staticData={this.state.dataTable}
                        showPagination={true}
                        handlePage={this.handlePage}
                        isRefresh={this.state.isRefresh}
                        handleSearch={this.handleSeacrh.bind(this)}
                        isLoading={this.state.isLoading}
                        handleLoading={this.handleTableLoading.bind(this)}
                        onActionClick={this.handleViewClick}
                        itemViewType={this.state.itemViewType}
                        fileName={"store_gate_entry_currentdate"}
                        actionType={VIEW}
                      />
                    </ItemGrid>
                  </GridContainer>
                </div>
              }
            />
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

const extendedFormsStyle = {
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#00ABDC",
    fontSize: "15px",
    //  lineHeight: "1.0",
    fontWeight: "400",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  input: {
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textField: {},

  input_group_addonunit: {
    padding: 0,
    width: "70px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    overflow: "hidden",
  },
  buttontxt: {
    textTransform: "none",
    borderRadius: "4px",
    lineHeight: "1.2",
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#00ADBC",
    },
    "&:after": {
      borderColor: "#D2D2D2",
    },
  },

  notchedOutline: {},
  ...customSelectStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};
function mapStateToProps(state) {
  return { state: state.compony };
}
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(WorkReport)
);
