import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import Close from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from 'react-select';
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi, serverVars} from '../../helper/Consts';

import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import InputAdornment from "material-ui/Input/InputAdornment";
import {getDataFromUrl, requestList, uploadFormdataWithImage, uploadInvoice} from "../../server/server";
import moment from "moment";
import {getCookie, setCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import Edit from "@material-ui/icons/Edit";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Jspdf from "jspdf";
import {subMenus} from "../../routes/routeIcons";
import LineItemTable from "../CustumComponents/LineItemTable";
import SelectboxMultiple from "../CustumComponents/SelectboxMultiple";
import { connect } from 'react-redux';


class PriceRequestResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            billPassNo:"",
            customerList:[],
            file: null,
            fileName: "No file Chosen",
            isRefresh: false,
            isLoading: false,
            itemViewType: "issueObj",
            //itemViewType: "mrNo",
            approveStatus: false,
            updateView: false,
            statusDesc: "",
            year:getCookie(cons.ACADEMIC_YEAR),
            alert: false,
            invoiceList: [],
            lineItemId: '0',
            "hdrId":"",
            "suppResponseDateTime":moment().format("DD-MM-YYYY"),
            "itemRate": "",
            "suppCode": "",
            "suppComments": "",
            "suppQuoteFile": "",
            "createdBy": "",
            "createdOn":"",
            hdrId1:""
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);

    }
    componentDidMount() {
          var dataTable= {"data":[],"filterTable":true,"defaultPageSize":5,"showPaginationTop":false,"showPaginationBottom":true,"column":[{"filterable":true,"Header":"Price Request Id","accessor":"hdrId","minWidth":100,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Item Code","accessor":"item","minWidth":60,"style":{"textAlign":"left"},"sortable":true},
                  {"filterable":true,"Header":"Item Description","accessor":"itemDesc","minWidth":100,"style":{"textAlign":"left"},"sortable":true},
                  {"filterable":true,"Header":"Supp Code","accessor":"suppCode","minWidth":50,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Supp Name","accessor":"suppName","minWidth":100,"style":{"textAlign":"left"},"sortable":true},
                  {"filterable":true,"Header":"Supplier Rate","accessor":"itemRate","minWidth":60,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Is Active","accessor":"isActive","minWidth":50,"style":{"textAlign":"left"},"sortable":true},
                  {"filterable":true,"Header":"Response Time","accessor":"suppResponseDateTimeDesc","minWidth":50,"style":{"textAlign":"left"},"sortable":true},{"filterable":true,"Header":"Status","accessor":"statusDesc","minWidth":50,"style":{"textAlign":"left"},"sortable":true}

                  ]};

       this.setState({
           dataTable:dataTable,
           isRefresh: true,
       })
        this.getPriceList();
     /*   var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.CUSTOMER_MASTER,data,this.handelCustomer)*/
    }
    getPriceList=()=>{
        var data={ "companyId": this.props.state.companyInfo.id,
            "itemCode": this.state.itemCode1,
            "suppCode": this.state.suppCode1,
            "hdrId":this.state.hdrId1
        };
        requestList(serverApi.GET_PRICE_REQUEST_RESPONSE_LIST,data,this.handelPriceResponse)
    }
    handelPriceResponse=(resp)=>{
        var data=resp,customerList=[];
        var table=this.state.dataTable;
        if(data.length>0){
            table.data=resp.map(prop=>{
                return {
                    ...prop,
                    item:prop.itemGroupCode+""+prop.itemCode
                }
            });
          this.setState({
              dataTable:table,
              isRefresh:true
          })
        }else{
            table.data=[];
            this.setState({
                dataTable:table,
                isRefresh:true
            })
        }
    };

    handleSeacrh = () => {
        var loading = false;
        if (!this.state.isRefresh) {
            this.getPriceList();
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });
    };
    getDefaultSideConvertion = (name) => {
        var val = 0, label = "Select..", data = "";
        if (name !== null&&name!==0 && name!=='') {
            if (name === 1) {
                val = 1;
                label = "INTER STATE";
            }
            else {
                val = 2;
                label = 'INTRA STATE';
            }
            data = {
                value: val, label: label
            };
        }
        return data;
    };

    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        // getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "getMaterialGoodReceiveDetailsById/" + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
        if(!getMenuPermission(0)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            this.handleViewClickResp(JSON.parse(id));
          //  getDataFromUrl(serverApi.GET_PRICE_MASTER_BY_ID + id + "/" + getCookie(cons.USERID), this.handleViewClickResp)
        }
    };
     groupBy=(list, keyGetter)=> {
         const map = new Map();
         list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
         });
         return map;
     };
    handleViewClickResp = (resp) => {
     //   this.setState({printResp:resp});
        var header = resp;

        if (header !== null) {
            //  alert(header.agentId);
            this.setState({
                updateView: true,
                isCreated: true,
                approveStatus: header.approveStatus,
                "dtlId": header.dtlId,
                "hdrId": header.hdrId,
                "itemGroupCode": header.itemGroupCode,
                "itemCode": header.itemCode,
                "isActive": header.isActive,
                "itemRate": header.itemRate,
                "suppCode": header.suppCode,
                "isRateActive": header.isRateActive,
                "suppResponseDateTime": header.suppResponseDateTimeDesc!==null?header.suppResponseDateTimeDesc:moment().format("DD-MM-YYYY"),
                "suppComments": header.suppComments,
                "suppQuoteFile": header.suppQuoteFile,
                "hdrPriceRequestStatus": header.hdrPriceRequestStatus,
                "itemDesc": header.itemDesc,
                "itemGrpDesc": header.itemGrpDesc,
                "statusDesc": header.statusDesc,
                "suppName":header.suppName,
                "createdOn":header.createDateTime,
                "deliveryTimeLine":header.deliveryTimeLine,
            });

        }


    };

    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    };
    getAgentName = (name) => {
        this.setState({
            agentName: name,
        });
    };
    getitemDetails = (selectedValue) => {
        var values = selectedValue;
          values = values.split("^");

        this.setState({
            itemName:values[0],
            uom:values[1],
        });
        if(values[0]!==this.state.itemName){
            this.setState({
                rate:parseFloat(values[3]),
                hsn:values[4]!=='null'?values[4]:""
            });

        }
        // console.log("indentQuantity " + this.state.indentQuantity);

    };
    afterReloadSelect = (val) => {

        this.setState({
            reloadSelect: val
        });
    };
    afterRefreshquality = (val) => {

        this.setState({
            isCustomer: val
        });
    };
    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({

            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    handleTableLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }
    getData = () => {
        var data = {
            "companyId":this.props.state.companyInfo.id,
            "acYear": this.state.year,
        };
        return data;
    };
    timeChanged = (dateObj, id) => {
        if (typeof dateObj === 'object')
            this.setState({
                [id]: dateObj.format('DD-MM-YYYY')
            });
        else
            this.setState({
                [id]: ''
            });

        //  this.dateChanged();
    }
    dateChanged = (date) => {
        var myDate = date;
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    };
    onCreateInvoiceButtonClick =() =>{
        console.log("quotation_id",this.state.quotation);
        setCookie(cons.QUOTATION_ID,this.state.quotation);
        this.props.history.push(subMenus.SALES_INVOICE);
    };

    redirectToStoreIndent = () => {
        this.props.history.push('/store/StoreIndent');
    };
    handleSimple = event => {
        console.log("selected " + event.target.value);
        this.setState({[event.target.name]: event.target.value});
    };
    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };
    getConvertedQty = () => {
        if (this.state.unitConvertion.value === 2) {
            console.log("indentQuantity  " + this.state.indentQuantity);
            return Math.round(parseFloat(parseFloat((this.state.indentQuantity * 100) / 150)));
        } else {
            return "";
        }
    };
    getIndentQtyTon = () => {
        var val = this.state.indentQuantity;
        if (val !== "") {
            //  console.log("unitConvertionId"+this.state.indentQuantity);
            return parseFloat(parseFloat(val / 10).toFixed(2));
        } else {
            return "";
        }
    };
    getIndentQty = () => {
        var val = this.state.vehicleQuantity;
        console.log(val + "vehicleQuantity " + this.state.selectedIndentQuantity);
        if (val !== "" && this.state.indentQuantity !== "") {
            return parseInt(val) * parseInt(this.state.selectedIndentQuantity)
        } else {
            return "";
        }
    };


    onChangeValues = (event) => {
        var value = event.target.value;

        if(event.target.id==='freightCharges'){
            var sumOfamt=this.state.sumOfamt;
            if(value!=='') {
                var total = Math.round(parseFloat(this.state.sumOfamt) + parseFloat(value)).toFixed(2);
                this.setState({
                    netTotal: total
                })
            }else {
                this.setState({netTotal:sumOfamt})
            }
        }

        if (event.target.id === "rate"&&this.state.quantity!==""&&this.state.quantity!==null) {

            console.log("val " + value);
            if (value !== null && value !== "") {
                var Calrate = this.state.quantity;
                var amount=parseFloat(Calrate)*parseFloat(value);
                this.setState({
                    amount: amount.toFixed(2)
                });

            } else {
                this.setState({
                    amount: 0
                });
            }
        }
        if (event.target.id === "quantity"&&this.state.rate!==""&&this.state.rate!==null) {

            console.log("val " + value);
            if (value !== null && value !== "") {
                var Calrate = this.state.rate;
                var amount=parseFloat(Calrate)*parseFloat(value);
                this.setState({
                    amount: amount.toFixed(2)
                });

            } else {
                this.setState({
                    amount: 0
                });
            }
        }

        this.setState({
            [event.target.id]: value
        });

    };

    onSelect = (selectedValue, stateKey) => {
        var type = this.state.selectedJuteType;
        /*  this.setState({
              [stateKey]: selectedValue,
          });*/
        if (stateKey === 'suppielr_id') {
            this.setState({
                loadGateEntry: true
            })

        }
        if (stateKey === 'itemGroupCode') {

            var data={
                "itemGroupId":selectedValue,
                "saleable":"",
                "consumable":"",
                "companyId":this.props.state.companyInfo.id
            };

            requestList(serverApi.GET_ITEMS_BY_GRPID,data,this.handleItem);
            // this.setState({
            //     loadItem: true
            // })
        }
        if (stateKey === 'selectedJuteType' && selectedValue > 0 && selectedValue !== type) {
            this.setState({
                hideJuteType: true
            });
        }
        if (stateKey === 'selectedMukam' && this.state.invoiceList.length > 0 && !this.state.canChangeMukam) {
            this.unitConvertionAlert("Changing of Mukam Name will remove all the line items. Click yes if you want to continue..!!")
        } else if (stateKey === 'selectedMukam')
            this.setState({
                [stateKey]: selectedValue,
                canChangeMukam: false
            });
        else
            this.setState({
                [stateKey]: selectedValue
            });
    };

    handleItem = (resp) => {
        this.setState({ItemOptions:resp,loadItem:true})

    }
    afterRefreshGateEntry = (val) => {
        this.setState({
            loadGateEntry: val
        });
    };
    getGateEntryDetails = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            form_poNO: values[0],
            poDate: values[1],
            challanNo: values[2],
            challanDate: values[3],
            form_vehicleNo: values[4],
        });
        // console.log("indentQuantity " + this.state.stock);

    };
    getName = (selectedValue) => {
        var values = selectedValue;
        values = values.split("^");
        this.setState({
            selectedJuteName: values[0],
            selectedGrpId: values[1],
            selectedUnit: values[2],
            selectedDeptId: values[3],
        });
    };

    hideBasicAlert() {
        this.setState({
            basicAlert: null,
        });

    }
    handleDeleteInvoiceResp = (resp)=>{
        if(resp.status){
            if(this.state.deleteChallan){
                var arr = this.state.challanList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    challanList: arr,
                    deleteChallan:false,
                    fileId:""
                });
            }else {
                var arr = this.state.filesList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    filesList: arr,
                    deleteInvoice: false,
                    fileId:""
                });
            }
        }
        console.log("resp",JSON.stringify(resp));

    };

    handleInvoiceResp = (resp)=>{
        if(resp.status){
            var data=resp.data;
            if(this.state.uploadChallan){
                var arr = this.state.challanList;
                arr.splice(arr.length, 0, data);
                this.setState({
                    uploadChallan: false,
                    challanList:arr,
                    basicAlert:null
                });
            }else {
                var arr = this.state.filesList;
                arr.splice(arr.length, 0, resp.data);
                this.setState({
                    uploadInvoice: false,
                    filesList: arr,
                    basicAlert: null
                });
            }
        }
        console.log("resp",JSON.stringify(resp));

    };

    hideInvoiceBasicAlert() {

        if(this.state.uploadInvoice){
            var id = this.state.billPassNo;
            uploadInvoice(serverApi.UPLOAD_INVOICE,"I", id ,this.state.file, this.handleInvoiceResp);
            //  thi
            //  s.uploadInvoiceFile();
        }
        if(this.state.uploadChallan){
            var id = this.state.billPassNo;
            uploadInvoice(serverApi.UPLOAD_CHALLAN,"C", id ,this.state.file, this.handleInvoiceResp);
            //  this.uploadInvoiceFile();
        }

        if(this.state.deleteInvoice){
            var url=serverApi.DELETE_BILL_INVOICE + this.state.invoiceId +"/"+getCookie(cons.USER_NAME)+"/"+getCookie(cons.CIPHER);
            getDataFromUrl( url, this.handleDeleteInvoiceResp);
        }
        if(this.state.deleteChallan){
            var url=serverApi.DELETE_BILL_INVOICE + this.state.challanId +"/"+getCookie(cons.USER_NAME)+"/"+getCookie(cons.CIPHER);
            getDataFromUrl( url, this.handleDeleteInvoiceResp);
        }

        this.setState({
            basicAlert: null,
        });

    }


    hideAlert() {

        console.log("alert");
        this.setState({

            alert: false,
            showMessagealert: false,
            loading: false,
            id: "",
            itemCode:"",
            itemName:"",
            itemGroup:"",
            itemGroupName:"",
            suppCode:null,
            suppName:"",
            lineItemId:"",
            isActive:"",
        });
    }
    //log

    hideAlertMessage() {
        this.setState({
            billPassStatus:"",
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            "dtlId": "",
            "hdrId": "",
            "itemGroupCode":"",
            "itemCode":"",
            "isActive": "",
            "itemRate":"",
            "suppCode": "",
            "isRateActive":"",
            "suppResponseDateTime":"",
            "suppComments":"",
            "suppQuoteFile":"",
            "hdrPriceRequestStatus": "",
            "itemDesc":"",
            "itemGrpDesc":"",
            "statusDesc":"",
            "suppName":"",
            "createdOn":"",
            "deliveryTimeLine":"",
        });
    }
    dateChanged = (myDate) => {
        myDate = myDate.split("-");
        var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
        return new Date(newDate).getTime();
    };
    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}

                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }

    hideUnitConvertionAlert() {
        this.setState({
            basicAlert: null,
            canChangeUnit: true,
            canChangeMukam: true,
            invoiceList: []

        });

    }


    unitConvertionAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    //warning
                    showCancel
                    style={{
                        display: "block",
                        marginTop: "-100px",
                        marginLeft: "-80px",
                        fontSize: "8px",
                        color: "black"
                    }}
                    title={msg}
                    onConfirm={() => this.hideUnitConvertionAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnText={"Yes"}
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }


                />
            )
        });
    }

    onRejectButtonClick = () => {
        /*        var invoiceList = this.state.invoiceList.map((prop, key) => {
                    return ({
                        "id": prop.lineItemId,
                        "materialGrnHdrId": prop.materialHdrId,
                        "quantity": prop.quantity,
                        "marka": '',
                        "advisedQuality": prop.qualityCode,
                        "actualQuality": prop.qualityCode,
                        "advisedWeight": prop.weight,
                        "actualWeight": prop.weight,
                        "deviation": 0,
                        "rate": prop.rate,
                        "claimsQuality": "",
                        "claimsCondition": "16.6",
                        "warehouseNo": prop.warehouseno,
                        "remarks": "",
                        "totalPrice": prop.amount,
                        "itemId": prop.itemId,
                        "debitNotesFlag": "",
                        "quantityUnit": this.state.uom,
                        "issuableWeight": "0",
                        "bale": 0,
                        "loose": 0,
                        "actualBale": 0,
                        "actualLoose": 0,
                        "advisedItemId": this.state.itemId
                    })
                });

                var data = {
                    "materialGoodReceiveHeader": {
                        "id": this.state.mrNo,
                        "goodReceiptDate": this.dateChanged(this.state.mrDate),
                        "supplierId": this.state.form_supplierId,
                        "poId": this.state.form_poNO,
                        "poDate": this.dateChanged(this.state.poDate),
                        "contractNo": this.state.contractNo,
                        "contractDate": this.dateChanged(this.state.contractDate),
                        "chalanNo": this.state.challanNo,
                        "chalanDate": this.dateChanged(this.state.challanDate),
                        "warehouseNo": '',
                        "exSTN": this.state.exstn,
                        "submitter": getCookie(cons.USERID),
                        "agentId": this.state.agentId,
                        "agentName": this.state.agentName,
                        "sourceId": "64",
                        "vehicleNo": this.state.form_vehicleNo,
                        "weight": 0,
                        "mukamId": this.state.mukamId,
                        "modBy": getCookie(cons.USER_NAME),
                        "status": "4"
                    },
                    "materialGRItemList": invoiceList
                };*/
        var data = {
            "id":this.state.billPassNo,
            "status":"4",
            "userName":getCookie(cons.USER_NAME),
            "userId":getCookie(cons.USERID)
        };

        this.handleLoading();
        requestList(serverApi.UPDATE_BILL_PASS, data, this.handleAddIndent)
    };
    onUpdateButtonClick=(status)=>{
        if(!getMenuPermission(1)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {invoiceList,comments,priceRequestDate,priceRequestSubject,dtlId,hdrId,isActive,isRateActive,itemCode,itemGroupCode,
                itemRate,suppCode,suppComments,suppQuoteFile,suppResponseDateTime}=this.state;
            var invoiceList1 = [];

            var data = {
                "companyId": this.props.state.companyInfo.id,
                "dtlId": dtlId,
                "hdrId": hdrId,
                "isActive": isActive,
                "isRateActive": isRateActive,
                "itemCode": itemCode,
                "itemGroupCode": itemGroupCode,
                "itemRate": itemRate,
                "suppCode": suppCode,
                "suppComments": suppComments,
                "suppQuoteFile":suppQuoteFile,
                "suppResponseDateTime":suppResponseDateTime,
                "updatedBy":getCookie(cons.USERID)
            };

            if(suppResponseDateTime!==""&&itemRate!=="") {
                this.handleLoading();
                requestList(serverApi.UPDATE_PRICE_RESPONSE, data, this.handleAddIndent)
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }

    };


    onCreateButtonClick = () => {
        if(!getMenuPermission(2)){
            this.basicAlert("Access Denied. Please contact administrator!!!")
        }else {
            const {invoiceList,comments,priceRequestDate,priceRequestSubject}=this.state;
            var invoiceList1 = []
                invoiceList.forEach((prop, key) => {
                //    prop.suppCode.forEach(supp=>{
                        var obj={
                            "companyId": this.props.state.companyInfo.id,
                            "createdBy":getCookie(cons.USERID),
                            "isRateActive": 1,
                            "isActive":1,
                            "itemCode": prop.itemCode,
                            "itemGroupCode": prop.itemGroup,
                            "suppCode": prop.suppCode,
                             "itemRate": "",
                             "suppComments": "",
                             "suppQuoteFile": "",
                             "suppResponseDateTime": ""
                        };
                        invoiceList1.push(obj)
                    //});
            });

            var data = {
                "hdrdto":{
                    "comments": comments,
                    "priceRequestDate": priceRequestDate,
                    "priceRequestStatus": "1",
                    "priceRequestSubject": priceRequestSubject,
                    "createdBy":getCookie(cons.USERID),
                   // "acYear":getCookie(cons.ACADEMIC_YEAR),
                    "companyId":this.props.state.companyInfo.id,
                },
                "dtlList":invoiceList1
            };

            if(priceRequestDate!==""&& invoiceList.length>0&&priceRequestSubject!=="") {
                this.handleLoading();
                requestList(serverApi.PRICE_REQUEST_ADD, data, this.handleAddIndent)
            } else if (this.state.invoiceList.length < 1) {
                this.basicAlert("Please Add Atleast One Item..!!");
            }
            else {
                this.basicAlert("Please Enter All Mandatory Fields..!!");
            }
        }
    };
    handleAddIndent = (resp) => {
        if (resp.status) {
            if(this.state.deleteBill)
                console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        if(this.state.deleteBill){
            this.setState({billId:"",deleteBill:false,isRefresh:true})
        }else
            this.handleLoading();
    };
    handleDeleteBill=(resp)=>{

    };
    hideItemBasicAlert(){
        if(this.state.updateItemAlert){
            this.setState({
                alert:true,
                updateItemAlert:false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList,sum_of_tax=0,sumOfamt=0;

            data.map((o, key) => {
                if(o.id===this.state.id){
                    if(this.state.updateView)
                        data[key].isActive=0;
                    else
                        data.splice(key, 1);
                }

            });


            this.setState({
                invoiceList: data,
                deleteItem: false
            });
            this.hideAlert();
        }
        this.setState({
            basicAlert: null,
        });

    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px" ,marginLeft:"-80px",fontSize:"8px",color:"red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }

    //add Item
    onClickAddItem() {
        var arr = this.state.invoiceList;
        console.log("quantity " + this.state.quantity);
        var itemIndex = 0, sum_of_tax = 0, sumOfamt = 0, hasItem = false;
        if (this.state.updateItem) {
            itemIndex = this.state.id;

        } else {
            itemIndex = this.state.invoiceList.length;
        }
     /*   var supplier="";
        if(this.state.supplierName!==""&&this.state.supplierName!==undefined) {
            this.state.supplierName.forEach((prop, i) => {
                if (i === 0)
                    supplier = supplier + prop
                else
                    supplier = supplier + ", " + prop

            });
        }*/
        this.state.suppCode.forEach((supp,i)=>{
            var addItemObj = {
                id: itemIndex,
                itemCode:this.state.itemCode,
                itemName:this.state.itemName,
                itemGroup:this.state.itemGroup,
                itemGroupName:this.state.itemGroupName,
                suppCode:supp,
                suppName:this.state.supplierName[i],
                lineItemId: this.state.lineItemId,
                isActive: 1,
            };
            arr.splice(arr.length, 0, addItemObj);

        });
        if (this.state.itemCode !== ""&&this.state.itemCode !== 0&&this.state.itemGroup!==""&& this.state.itemGroup !== 0 && this.state.suppCode!==null) {

            this.setState({
                loading: true,
                invoiceList: arr,

                updateItem: false
            });
            this.hideAlert();

        } else {
            this.basicAlert("Please Enter All Mandatory Fields..!!");
        }





    }


    getInvoiceTablePageSize = () => {
        var length = this.state.invoiceList.length;
        if (length > 5) {
            return length
        } else {
            return 5;
        }
    };


    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };





    handleAddItem() {
        const {classes} = this.props;
     //   const {selectedJuteType} = this.state;

      //  console.log(this.state);

        this.setState({
                loading: true,
                alert: true,
                updateItem: false,
            });

    };



    getDataTable =()=>{
        var indentlist=[],supplierNames="",suppCode=[];
        //  console.log("dta",JSON.stringify(this.state.invoiceList))
        var list=this.state.invoiceList;
        if(list.length>0)

            list.forEach((prop, key) => {
                if(prop.isActive===1) {
                    if (key === 0)
                        supplierNames = prop.suppName;
                    else supplierNames = supplierNames + "," + prop.suppName;
                    suppCode.push(prop.suppCode);
                    var obj = {
                        id: key,
                        itemCode: prop.itemCode,
                        itemName: prop.itemName,
                        itemGroup: prop.itemGroup,
                        itemGroupName: prop.itemGroupName,
                        suppCode: suppCode,
                        suppName: supplierNames,
                        lineItemId: prop.lineItemId,
                        isActive: prop.isActive,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to remove the data row */}
{/*
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                // alert(o.qualityId);
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                //  data.splice(i,1);
                                                this.basicItemAlert("Do You Like To Update This Item?");
                                                this.setState({
                                                    //alert: true,
                                                    updateItem: true,
                                                    updateItemAlert: true,
                                                    id: obj.id,
                                                    itemCode: obj.itemCode,
                                                    itemName: obj.itemName,
                                                    itemGroup: obj.itemGroup,
                                                    itemGroupName: obj.itemGroupName,
                                                    suppCode: obj.suppCode,
                                                    suppName: obj.suppName,
                                                    lineItemId: obj.lineItemId,
                                                    isActive: obj.isActive,
                                                });
                                                console.log("suppCode",o.suppCode);


                                                return true;
                                            }
                                        });

                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
*/}
                                <IconButton
                                    onClick={() => {
                                        var data = this.state.invoiceList;
                                        data.find((o, i) => {
                                            if (o.id === obj.id) {
                                                // here you should add some custom code so you can delete the data
                                                // from this component and from your server as well
                                                this.setState({
                                                    deleteItem: true,
                                                    id: o.id,
                                                });
                                                this.basicItemAlert("Do You Like To Delete This Item?");
                                                return true;
                                            }
                                        });
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>
                            </div>

                        )
                    };
                    //   if(list[key+1]!==undefined){
                    if (indentlist.filter(vendor => vendor.itemCode === prop.itemCode).length > 0) {
                        const index = indentlist.findIndex(vendor => vendor.itemCode === prop.itemCode);
                        console.log("suppName", indentlist[index]);
                        indentlist[index].suppName = indentlist[index].suppName + ", " + prop.suppName;
                        indentlist[index].suppCode.push(prop.suppCode)
                    } else {
                        indentlist.push(obj);
                        supplierNames = "";
                        suppCode = [];
                    }
                }


            });



        return indentlist;
    };

    render() {
        const {classes} = this.props;
        const {selectedJuteType} = this.state;
        //console.log("jute type",selectedJuteType);
//add item button click
        const ucoptions = [
            {value: 0, label: 'Select...', name: 'Select...'},
            {value: 1, label: 'INTER STATE', name: 'INTER STATE'},
            {value: 2, label: 'INTRA STATE', name: 'INTRA STATE'},

        ];

        const inputFocus = {
            autoComplete: 'off'
        };
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            // headerColor={"blue"}
                            content={
                                <GridContainer>
                                    {
                                        this.state.alert ?
                                            <Popup
                                                onClickSave={() => this.onClickAddItem()}
                                                closePopup={() => this.hideAlert()}
                                                title="Post Temperature"
                                                isShowSave={true}
                                                isShowCancel={true}
                                                save_btn_txt={this.state.updateItem ? "Update" : "save"}
                                                cancel_button_text={"Cancel"}
                                                //juteType={itemtype}
                                                content={
                                                    <form>
                                                        <GridContainer>

                                                                    <ItemGrid xs={12} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.itemGroup}
                                                                                    id={"itemGroup"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={(name)=>{
                                                                                        this.setState({itemGroupName:name})
                                                                                    }}
                                                                                    isRefresh={this.state.loadItemGroup}
                                                                                    stateKey="itemGroup"
                                                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                                                    url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                                />
                                                                            </ItemGrid>
                                                                        </GridContainer>

                                                                    </ItemGrid>

                                                                    <ItemGrid xs={6} sm={6}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={12} sm={4}>
                                                                                <InputLabel className={classes.label}>
                                                                                    Item<span style={{
                                                                                    color: "red",
                                                                                    fontSize: "12px"
                                                                                }}>*</span>
                                                                                </InputLabel>
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={12} sm={8}>
                                                                                <SelectboxTemp
                                                                                    defValue={this.state.itemCode}
                                                                                    staticData={this.state.ItemOptions}
                                                                                    id={"itemCode"}
                                                                                    onSelect={this.onSelect}
                                                                                    getName={this.getitemDetails}
                                                                                    isRefresh={this.state.loadItem}
                                                                                    stateKey="itemCode"
                                                                                    setIsRefresh={(val)=>{
                                                                                       this.setState({ loadItem:val})
                                                                                    }}
                                                                                  //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                                                />


                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                            <ItemGrid xs={12} md={12} style={{marginTop: "10px"}}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={3}>
                                                                        <InputLabel className={classes.label}>
                                                                            Supplier<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={9}>
                                                                        <SelectboxMultiple
                                                                            id={"suppCode"}
                                                                            defValue={this.state.suppCode}
                                                                            onSelect={this.onSelect}
                                                                            getName={(val) => {
                                                                                console.log("suppmane",val);
                                                                              //  var name=val.split('_');
                                                                                this.setState({
                                                                                    supplierName: val,
                                                                                })
                                                                            }}
                                                                            isRefresh={this.state.loadsupCode}
                                                                            stateKey="suppCode"
                                                                            url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                                                        />

                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>




                                                        </GridContainer>


                                                    </form>

                                                }
                                            >
                                            </Popup> : null
                                    }
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}

                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier Response Date<span
                                                    style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <FormControl variant="" fullWidth>
                                                    <Datetime
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        onChange={(dateObj) => {
                                                            this.timeChanged(dateObj, "suppResponseDateTime")
                                                        }}
                                                        height={"15px"}
                                                        formControlProps={
                                                            {height: "15px", inlineBlock: "none"}
                                                        }
                                                        inputProps={{
                                                            id: "suppResponseDateTime",
                                                            value: this.state.suppResponseDateTime,
                                                            autoComplete: 'off'
                                                        }}

                                                    />
                                                </FormControl>
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item Group
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="itemGrpDesc"
                                                    value={this.state.itemGrpDesc}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    inputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item Group<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.itemGroupCode}
                                                    onSelect={this.onSelect}
                                                    getName={(name)=>{
                                                        this.setState({itemGroupName:name})
                                                    }}
                                                    isRefresh={this.state.loadItemGroup}
                                                    stateKey="itemGroupCode"
                                                    setIsRefresh={this.afterRefreshItemGrp}
                                                    url={serverApi.SEARCH_ITEM_GROUPS+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                />
                                            </ItemGrid>
                                        </GridContainer>

                                    </ItemGrid>
*/}

{/*
                                    <ItemGrid xs={6} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item<span style={{
                                                    color: "red",
                                                    fontSize: "12px"
                                                }}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <SelectboxTemp
                                                    defValue={this.state.itemCode}
                                                    staticData={this.state.ItemOptions}
                                                    id={"itemCode"}
                                                    onSelect={this.onSelect}
                                                    getName={this.getitemDetails}
                                                    isRefresh={this.state.loadItem}
                                                    stateKey="itemCode"
                                                    setIsRefresh={(val)=>{
                                                        this.setState({ loadItem:val})
                                                    }}
                                                    //  url={serverApi.GET_ITEMS_BY_GRPID+this.state.itemGroup+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.CIPHER)}
                                                />


                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
*/}
                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Item
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="itemDesc"
                                                    value={this.state.itemDesc}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    inputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="suppCode"
                                                    value={this.state.suppCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    inputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="suppName"
                                                    value={this.state.suppName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    inputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6} >
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Supplier Rate<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    id="itemRate"
                                                    disabled={true}
                                                    value={this.state.itemRate}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    inputProps={inputFocus}
                                                    iType={"number"}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Supplier Comments
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={8}>
                                                <TextField
                                                    disabled={true}
                                                    id="suppComments"
                                                    value={this.state.suppComments}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    height={60}
                                                    multiline={true}
                                                    rowsMax={3}
                                                    inputProps={inputFocus}

                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
*/}

{/*
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                      Created Date
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
*/}
                                    {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Status
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="statusDesc"
                                                        value={this.state.statusDesc}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}
                                        {this.state.updateView?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                    Delivery_TimeLine
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={8}>
                                                    <TextField
                                                        disabled={true}
                                                        id="deliveryTimeLine"
                                                        value={this.state.deliveryTimeLine}
                                                        className={classes.textField}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>:null}






                                    {/* <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                        <GridContainer justify="center">
                                           {this.state.approveStatus?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.onUpdateButtonClick(this.state.quoteStatus)
                                                            }}>Approve</Button>

                                                </ItemGrid>
                                                :null}
                                           {this.state.approveStatus?
                                                <ItemGrid>
                                                    <Button color="danger"
                                                            onClick={()=>{
                                                                this.onUpdateButtonClick("4")}}>Reject</Button>
                                                </ItemGrid>  :null}
                                            {this.state.updateView&&this.state.hdrPriceRequestStatus!=="5"?
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={()=>{
                                                                this.onUpdateButtonClick("")}}>save</Button>

                                                </ItemGrid>:null}



                                            {this.state.updateView?
                                                <ItemGrid xs={12}>
                                                    <SamplePDF
                                                    invoiceType={2} printResp={this.state.printResp}/>
                                                </ItemGrid>:null}



                                        </GridContainer>

                                    </ItemGrid> */}

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer >
                                        <ItemGrid xs={10}>
                                            {this.state.basicAlert}
                                            {this.state.showMessagealert}
                                            <GridContainer>
{/*
                                                <ItemGrid xs={12} sm={6}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                From date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "fromDate")
                                                                    }}
                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"fromDate",
                                                                        value:this.state.fromDate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
*/}
{/*
                                                <ItemGrid xs={12} sm={6}>

                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                To date
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <FormControl variant="outlined" fullWidth >
                                                                <Datetime
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    onChange={(dateObj) => {
                                                                        this.timeChanged(dateObj, "todate")
                                                                    }}

                                                                    formControlProps={
                                                                        {height:"10px",inlineBlock: "none"}
                                                                    }
                                                                    inputProps={{
                                                                        id:"toDate",
                                                                        value:this.state.todate,
                                                                        autoComplete: 'off'
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                    </GridContainer>

                                                </ItemGrid>
*/}

                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Item Code
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="itemCode1"
                                                                value={this.state.itemCode1}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                inputProps={inputFocus}

                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>

                                                <ItemGrid xs={12} md={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <InputLabel className={classes.label}>
                                                                Supplier
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={9}>
                                                            <SelectboxTemp
                                                                defValue={this.state.suppCode1}
                                                                onSelect={this.onSelect}
                                                                stateKey="suppCode1"
                                                                url={serverApi.SEARCH_STORE_SUPPLIER + "/" + this.props.state.companyInfo.id}
                                                            />

                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4} style={{marginTop: "10px"}}>
                                                    <GridContainer>
                                                        <ItemGrid xs={12} sm={4}>
                                                            <InputLabel className={classes.label}>
                                                                Price Request Id
                                                            </InputLabel>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={8}>
                                                            <TextField
                                                                id="hdrId1"
                                                                value={this.state.hdrId1}
                                                                className={classes.textField}
                                                                onChange={this.onChangeValues}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                inputProps={inputFocus}

                                                            />
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>



                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>

{/*
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        // round color="white"
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>
*/}


                                                <ItemGrid xs={12}>
                                                    <Button color="primary" right={true} onClick={this.handleSeacrh}>Search</Button>
                                                </ItemGrid>


                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{height: 2, backgroundColor: "#00acc1", marginTop: "5px"}}></ItemGrid>

                                        <ItemGrid xs={12}>
                                            {/*
                                            <ReactTable
                                                data={this.state.data}
                                                filterable
                                                // resizable={false}
                                                style={{color: "#00ABDC", textAlign: "center", overflowX: "auto"}}
                                                columns={[
                                                    {
                                                        Header: "PO Number",
                                                        accessor: "po_num",
                                                        style: {color: "#000", textAlign: "left"}

                                                    },
                                                    {
                                                        Header: "Date",
                                                        accessor: "po_date",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Suplier Name",
                                                        accessor: "suplier_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Broker Name",
                                                        accessor: "broker_name",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Mukam",
                                                        accessor: "mukam",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Type",
                                                        accessor: "vehicle_type",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Vehicle Quantity",
                                                        accessor: "vehicle_quantity",
                                                        minWidth: 150,
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Qualities",
                                                        accessor: "qualities",
                                                        style: {color: "#000", textAlign: "left"}
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        style: {color: "#000", textAlign: "center"},
                                                        sortable: false,
                                                        filterable: false,
                                                    }
                                                ]}
                                                defaultPageSize={5}
                                                //showPaginationTop
                                                showPaginationBottom
                                                className="-striped -highlight"
                                            >

                                            </ReactTable>
*/}


                                           {this.state.dataTable!==null?
                                               <TableComponent
                                               // url={serverApi.PRICE_REQUEST_LIST}
                                                //searchData={this.getData()}
                                               staticData={this.state.dataTable}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                fileName={getCookie(cons.COMPANY_NAME)+'_PRICE_REQUEST'}
                                               // showDelete={true}
                                              //  onDeleteClick={this.onDeleteBill}
                                                actionType={VIEW}/>:null}

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }


    handlePrintResp = (resp)=> {
        var printHeaderValues=resp.quoteHdr;
        var modules=[];
        var lineItems=resp.quoteLineItems;
        var totalIgst=0,totalSgst=0,totalCgst=0;

        if(lineItems.length>0){
            lineItems.map((prop,key)=>{
                var tax=0;
                if(prop.igstAmt>0)
                totalIgst=totalIgst+parseFloat(prop.igstAmt);
                if(prop.sgstAmt>0)
                totalSgst=totalSgst+parseFloat(prop.sgstAmt);
                if(prop.cgstAmt>0)
                totalCgst=totalCgst+parseFloat(prop.cgstAmt);
                if(prop.cgstPer>0)
                 tax=parseFloat(prop.cgstPer)+parseFloat(prop.sgstPer);
                var desc=" ";
                if(prop.itemDescription!==null){
                    desc=prop.itemDescription
                }
                var obj={
                    itemId:prop.itemId,
                    itemName:prop.itemName+'\n'+desc,
                    hsnCode:prop.hsnCode,
                    rate:parseFloat(prop.rate).toFixed(2),
                    quantity:prop.quantity,
                    tax:prop.igstPer>0?prop.igstPer+'%':tax+'%',
                    amount:prop.amountWithoutTax>0?parseFloat(prop.amountWithoutTax).toFixed(2):0.00
                };
                modules.push(obj)
            })
        }
        var poId=printHeaderValues.quoteUniqueNo;
        poId=poId+' ';
        const doc = new Jspdf('p', 'pt', 'a4');

        var pageNo = 1;

        var TotalPgaeNo = doc.internal.getNumberOfPages();
        doc.setFontSize(8);

        doc.text(500, 20, "Page " + pageNo + " of " + TotalPgaeNo + "");
        doc.setFontSize(16);
        doc.setFontType("bold");
        doc.text(450, 40, "QUOTATION");
        doc.setFontSize(8);
        var company = doc.splitTextToSize(" "+getCookie(cons.COMPANY_TITLE), 180);
        doc.text(550,60,company,{align:'right'});
        doc.setFontType("normal");
        doc.setLineHeightFactor(2);
        var add1 = doc.splitTextToSize(" "+getCookie(cons.COMPANY_ADDRESS)+'\n'+getCookie(cons.COMPANY_ADDRESS2), 180);
        doc.text(550,80,add1,{align:'right'});
        doc.setLineHeightFactor(1);

        doc.line(20, 120, 550, 120);
        doc.text(20, 130, "BILL TO : ");
        doc.setFontType("bold");
        var billingAddress = doc.splitTextToSize(printHeaderValues.billingAddress, 100);
        doc.text(21,145,billingAddress);
        doc.line(200, 120, 200, 200);
        doc.setFontType("normal");
        doc.text(200, 130, "SHIP TO : ");
        doc.setFontType("bold");
        var shippingAddress = doc.splitTextToSize(printHeaderValues.shippingAddress, 100);
        doc.text(201,145,shippingAddress);
        doc.line(400, 120, 400, 200);
        doc.setFontSize(8);
        var quote = doc.splitTextToSize("Quote No : "+printHeaderValues.quoteNoString, 150);
        doc.text(550,131,quote,{align:'right'});
        var quoteDate = doc.splitTextToSize("Quote Date : " +printHeaderValues.quoteDateDesc, 100);
        doc.text(550,145,quoteDate,{align:'right'});
        var expiryDate = doc.splitTextToSize("Expiry Date : " +printHeaderValues.expireDateDesc, 100);
        doc.text(550,160,expiryDate,{align:'right'});
        var grandTotal = doc.splitTextToSize("GRAND TOTAL(INR) : " +parseFloat(printHeaderValues.grandTotal).toFixed(2), 200);
        doc.text(550,174,grandTotal,{align:'right'});

        doc.line(20, 200, 550, 200);

        doc.cellInitialize();

        var itemCode = doc.splitTextToSize('ITEM CODE', 100);
        doc.cell(20, 200, 60, 20, itemCode, '', 'center');
        var desc = doc.splitTextToSize('ITEM', 180);
        doc.cell(50, 200, 180, 20, desc, '', 'center');
        var hsnCode = doc.splitTextToSize('HSN CODE', 50);
        doc.cell(100, 200, 60, 20, hsnCode, '', 'center');
        var bales_drums = doc.splitTextToSize('RATE',50);
        doc.cell(150, 200, 60, 20, bales_drums, '', 'center');
        var weight = doc.splitTextToSize('QUANTITY', 50);
        doc.cell(200, 200, 60, 20, weight, '', 'C');
        var unit = doc.splitTextToSize('TAX', 40);
        doc.cell(250, 200, 30, 20, unit, '', 'center');
        var moisture = doc.splitTextToSize('AMOUNT', 80);
        doc.cell(300, 200, 80, 20, moisture, '', 'center');
        doc.cellInitialize();

        var cellWidths = ['', 60, 180, 60, 60, 60, 30,80, 40, 40, 50];
        var cellTextaligns = ['', 'left', 'left', 'left', 'left', 'right', 'left', 'right', 'right', 'left', 'right'];
        doc.setFontType("normal");
        doc.setFontSize(8);


        {
            var k = 195;
            var i = 0;
            modules.map((prop, key) => {
                k = k + 25;
                i = i + 1;

                doc.cellInitialize();
                if (i === 27) {
                    k = 190;
                    i=0;
                    pageNo = pageNo + 1;
                    var today = moment().format('DD/MM/YYYY H:mm:ss');
                    var name = getCookie(cons.USERID);
                    var newdat = " Printed on: " + today;
                    var printBy = " Printed By: " + name;
                    doc.text(30, 800, newdat);
                    doc.text(500, 800, printBy);
                    doc.addPage('a4', 'p');

                    doc.setFontType("bold");
                    doc.cellInitialize();
                    var itemCode = doc.splitTextToSize('ITEM CODE', 50);
                    doc.cell(20, 200, 60, 20, itemCode, '', 'C');
                    var desc = doc.splitTextToSize('ITEM', 50);
                    doc.cell(50, 200, 100, 20, desc, '', 'C');
                    var hsnCode = doc.splitTextToSize('HSN CODE', 50);
                    doc.cell(100, 200, 60, 20, hsnCode, '', 'C');
                    var bales_drums = doc.splitTextToSize('RATE',50);
                    doc.cell(150, 200, 60, 20, bales_drums, '', 'C');
                    var weight = doc.splitTextToSize('QUANTITY', 100);
                    doc.cell(200, 200, 100, 20, weight, '', 'C');
                    var unit = doc.splitTextToSize('TAX', 40);
                    doc.cell(250, 200, 40, 20, unit, '', 'C');
                    var moisture = doc.splitTextToSize('AMOUNT', 100);
                    doc.cell(300, 200, 100, 20, moisture, '', 'C');
                    doc.cellInitialize();
                    doc.setFontType("normal");
                    doc.setFontSize(7);
                }
                var a = 0;
                return (
                    Object.keys(prop).map((key, index) => {
                        a = a + 1;
                        if(prop[key]==0 || prop[key]==0.0){
                            var myItem=" ";
                        }

                        else{
                            if(key==='itemDescription'){
                                doc.setFontStyle("bold")

                            }else{
                                doc.setFontStyle("normal")
                            }
                            var myItem = prop[key]+" ";
                            myItem = doc.splitTextToSize(myItem,cellTextaligns[a]);
                        }


                        return (
                            doc.cell(20, k, cellWidths[a], 25, myItem, 2, cellTextaligns[a])
                        )
                    })

                )

            });

        }
        var totalamount = doc.splitTextToSize(parseFloat(printHeaderValues.quoteAmount).toFixed(2)+' ', 50);
        totalCgst=doc.splitTextToSize(parseFloat(totalCgst).toFixed(2)+' ', 50);
        totalIgst=doc.splitTextToSize(parseFloat(totalIgst).toFixed(2)+' ', 50);
        totalSgst=doc.splitTextToSize(parseFloat(totalSgst).toFixed(2)+' ', 50);

       var totaltax=doc.splitTextToSize(parseFloat(printHeaderValues.taxAmount).toFixed(2)+' ', 50);
        doc.cellInitialize();
        doc.setFontStyle("bolditalic");
        doc.cell(20, k + 25, 450, 20, 'SUB TOTAL ', '', 'right');
        doc.setFontStyle("normal");
        doc.cell(450, k + 25, 80, 20, totalamount, '', 'right');
        doc.cellInitialize();
        doc.setFontStyle("bolditalic");
        doc.cell(20, k + 45, 65, 20, 'IGST', '', 'right');
        doc.setFontStyle("normal");
        doc.cell(60, k + 45, 60, 20,  ' '+totalIgst, '', 'right');
        doc.setFontStyle("bolditalic");

        doc.cell(60, k + 45, 65, 20, 'CGST', '', 'right');
        doc.setFontStyle("normal");

        doc.cell(120, k + 45, 60, 20, ' '+totalCgst, '', 'right');
        doc.setFontStyle("bolditalic");

        doc.cell(180, k + 45, 65, 20, 'SGST', '', 'right');
        doc.setFontStyle("normal");

        doc.cell(250, k + 45, 60, 20, ' '+totalSgst, '', 'right');
        doc.setFontStyle("bolditalic");

        doc.cell(310, k + 45, 75, 20, 'TAX TOTAL', '', 'right');
        doc.setFontStyle("normal");

        doc.cell(370, k + 45, 80, 20, totaltax, '', 'right');
        doc.cellInitialize();
        doc.setFontStyle("bolditalic");
        doc.cell(20, k + 65, 450, 20,'Freight Charges ', '', 'right');
        doc.setFontStyle("normal");
        doc.cell(370, k + 65, 80, 20,+printHeaderValues.freightCharges>0?parseFloat(printHeaderValues.freightCharges).toFixed(2)+' ':0.00+' ', '', 'right');
        doc.cellInitialize();
        doc.setFontStyle("bolditalic");
        doc.cell(20, k + 85, 450, 20,'GRAND TOTAL ', '', 'right');
        doc.setFontStyle("normal");
        doc.cell(370, k + 85, 80, 20,+printHeaderValues.grandTotal>0?parseFloat(printHeaderValues.grandTotal).toFixed(2)+' ':0.00 +' ', '', 'right');
        doc.setFontType("normal");
       // doc.setFontSize(9);

        var a = ['','One ','Two ','Three ','Four ', 'Five ','six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
        var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
        function inWords (num) {
            if ((num = num.toString()).length > 9) return 'overflow';
            var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return; var str = '';
            str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
            str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
            str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
            str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
            return str;
        }
      //  doc.text(30,k + 120,'IN WORDS : '+inWords(printHeaderValues.quoteAmount)+ ' RUPEES ONLY.');
        doc.cellInitialize();

        doc.setFontStyle("bold");
        doc.cell(20, k + 105, 60, 20,'In Words: ', '', 'right');
        doc.cell(100, k + 105, 470, 20, inWords(printHeaderValues.quoteAmount)+'Rupees Only. ', '', 'left');
        doc.setFontSize(10);
        doc.setFontStyle("normal");
        doc.text(30, k + 140, 'Payment Terms : ');
        doc.text(50, k + 160, printHeaderValues.paymentTerms+' ');
        //  doc.text(30, k + 180, '2. This purchase order can be cancelled by us at any time. ');
        doc.text(30, k + 200, 'Other Terms  : ');
        doc.text(50, k + 220, printHeaderValues.termsConditions+' ');
        doc.setFontStyle("bold");
        doc.text(30, k + 280,'Note*: ');
        doc.text(70, k + 280,printHeaderValues.footerNotes!==null?printHeaderValues.footerNotes:" ");


        //  doc.text(30, k + 220, '4. Taxes as applicable and the prices mentioned are excluded taxes');

        doc.text(30, k + 350, 'FOR'+' '+getCookie(cons.COMPANY_TITLE));
        doc.text(30, k + 400, 'Authorised Signature'+' ');


        //doc.text(400, k + 180, 'Authorised Signature');
      /*  doc.setFontSize(8);
        doc.setFontType("bold");
        var note='Note* : This is a computer generated print, Signature is not required.';
        var NotexOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(note) * doc.internal.getFontSize() / 2);
        doc.text(note, NotexOffset, k + 290);

        //doc.text(30, k + 200, 'Note*: This is a computer generated print, Signature is not required.');*/
        doc.setFontType("normal");
        doc.setFontSize(7);
        var today = moment().format('DD/MM/YYYY H:mm:ss');
        var name =getCookie(cons.USERID);
        var newdat = " Printed on: " + today;
        var printBy = " Printed By: " + name;
        doc.text(30, k + 450, newdat);
        doc.text(500, k + 450, printBy);
       // addWaterMark(doc,printcopy);
        if(this.state.sendMail){


            const filename = 'QUOTATION_'+printHeaderValues.quoteNoString+'.pdf';

            var pdf =doc.output('blob');
            // var file = new File(pdf, filename, {type: "application/pdf;charset=utf-8"});



            const formData = new FormData();
            formData.append("file", pdf);
            formData.append("userId", getCookie(cons.USERID));
            formData.append("companyId", this.props.state.companyInfo.id);
            formData.append("cipher", getCookie(cons.CIPHER));
            formData.append("quoteId", this.state.quoteId);
            formData.append("fileName", filename);


            var URL=serverApi.QUOTATIONS_MAIL_SERVICE;
            // requestList(URL,data,this.handleMRMail);

            uploadFormdataWithImage(URL,formData, this.handleAddIndent);

            this.setState({
                sendMail:false
            })

        }else {
            doc.save('QUOTATION_'+printHeaderValues.quoteNoString+'.pdf');
        }


    };

}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    textFieldRight: {
        textAlign:'right',
        paddingRight:"5px",
        paddingBottom:'10px'
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};
function mapStateToProps(state) {
  return { state: state.compony };
};
export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(PriceRequestResponse)
);





