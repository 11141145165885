import React from "react";
import ReactTable from "react-table";
import { getDataFromUrl, requestList } from "../../server/server";
import Actions from "../../ui-actions/UiActions";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import SamplePDF from "../../components/ExportToPdf/SamplePDF";
import Popup from "../../components/Popup/PopUp";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import { getCookie } from "../../helper/Cookies";
import ExpandMoreIcon from "@material-ui/icons/Videocam";
import IconButton from "../../components/CustomButtons/IconButton";
import GridContainer from "../../components/Grid/GridContainer";
import cons, { serverApi } from "../../helper/Consts";

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsTable: null,
      dataTable: null,
      columns: [],
      pageSize: 10,
      pageIndex: 1,
      totalRecords: 0,
      listVideoLink: "",
      listUserManualLink: "",
    };
  }

  getData = () => {
    // var data={
    //     "purchaseType":"J",
    //     "frrmDt":"12-OCT-18",
    //     "toDt":"10-NOV-18",
    //     "supplierId":"J74",
    //     "mukam":"3",
    //     "statusId":"3"
    // }
    if (this.props.staticData !== null && this.props.staticData !== undefined) {
      this.handleDept(this.props.staticData);
    } else {
      var data = this.props.searchData;
      if (data !== undefined)
        requestList(this.props.url, data, this.handleDept);
      else getDataFromUrl(this.props.url, this.handleDept);
    }
  };
  handleDept = (resp) => {
    if (resp) {
      //  if(this.props.isRefresh)
      if (
        this.props.staticData !== null &&
        this.props.staticData !== undefined &&
        this.props.isRefresh
      ) {
        this.setActions(resp);
      } else {
        if (
          this.props.staticData === null ||
          this.props.staticData === undefined
        )
          this.setActions(resp);
      }
      var result = resp;
      var column = [];
      // console.log("isrefreshed",this.props.isRefresh);

      // console.log("columns ",JSON.stringify(resp.column));
      //  result.column=column;

      this.setState({
        dataTable: resp,
        columns: column,
      });
      if (this.props.isRefresh) this.props.handleSearch();
    }
  };

  setActions = (tableData) => {
    const {
      onDownloadClick,
      isDownload,
      actionType,
      staticData,
      onActionClick,
      itemViewType,
      onDeleteClick,
      onClickResetPassword,
      ...rest
    } = this.props;
    if (this.props.staticData !== null && this.props.staticData !== undefined)
      tableData = staticData;
    if (actionType > 0) {
      if (tableData.column[tableData.column.length - 1].Header !== "Actions")
        tableData.column.push({
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          style: { color: "#000", textAlign: "center" },
          //   width:100,
          filterable: false,
        });
      tableData.data.map((prop, key) => {
        //console.log("prop...itemViewType",prop[itemViewType]);
        if (prop.actions === undefined) {
          prop.actions = (
            <div>
              <Actions
                actionType={actionType}
                btnKey={
                  itemViewType === "issueObj"
                    ? JSON.stringify(prop)
                    : prop[itemViewType]
                }
                {...rest}
                actionClick={onActionClick}
                deleteClick={onDeleteClick}
                isDelete={this.props.showDelete}
                isDownload={isDownload}
                onDownloadClick={onDownloadClick}
                isResetPassword={this.props.showPasswordReset}
                resetPasswordClick={onClickResetPassword}
              />
            </div>
          );
        }
        return null;
      });
      this.setState({
        dataTable: tableData,
      });
    } else {
      this.setState({ dataTable: tableData });
      console.log(tableData);
    }
  };
  componentDidMount() {
    this.getData();
    getDataFromUrl(
      serverApi.GET_LIST_VIDEO_BY_MENU + getCookie(cons.MENU_ID),
      this.handleLink
    );
  }
  handleLink = (resp) => {
    if (resp.listVideoLink) {
      this.setState({
        listVideoLink: "https://www.youtube.com/embed/" + resp.listVideoLink,
      });
    }
    if (resp.listUserManualLink)
      this.setState({ listUserManualLink: resp.listUserManualLink });
  };

  render() {
    const {
      isRefresh,
      isLoading,
      showPrint,
      printDoc,
      actionType,
    } = this.props;
    if (isRefresh) {
      this.getData();
    }
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (row[id] !== undefined && typeof row[id] === "string")
        return String(row[id].toLowerCase()).includes(
          filter.value.toLowerCase()
        );
      else if (row[id] !== undefined && typeof row[id] !== "string")
        return String(row[id]).includes(filter.value);
      return true;

      /*
            return (
                row[id] !== undefined && typeof(row[id]) === "string"?
                    String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                    true
            );
*/
    }
    return (
      <div>
        {this.state.dataTable !== null ? (
          <div>
            <ReactTable
              loading={isRefresh}
              minRows={this.state.dataTable.defaultPageSize}
              data={this.state.dataTable.data}
              filterable={this.state.dataTable.filterTable}
              columns={this.state.dataTable.column}
              defaultPageSize={this.state.dataTable.defaultPageSize}
              showPaginationTop={this.state.dataTable.showPaginationTop}
              showPaginationBottom={this.state.dataTable.showPaginationBottom}
              className="-striped -highlight"
              style={{
                color: "#00ABDC",
                textAlign: "center",
                overflowX: "auto",
              }}
              noDataText={
                this.props.noDataText !== undefined
                  ? this.props.noDataText
                  : "No Data Found"
              }
              defaultFilterMethod={(filter, row) =>
                filterCaseInsensitive(filter, row)
              }
              getTdProps={() => {
                return {
                  style: {
                    borderRight: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    lineHeight: 1.5,
                    paddingTop: actionType > 0 ? 0 : 10,
                    paddingBottom: actionType > 0 ? 0 : 10,
                    minHeight: 30,
                    fontSize: "12px",
                    //  backgroundColor:'green'
                  },
                };
              }}
            >
              {/*{(state, filtredData, instance) => {
                        this.reactTable = state.pageRows.map(data => {
                            console.log("filtredData",JSON.stringify(state));
                            return data
                        });
                        return (
                            <div>
                                {filtredData()}
                                <ExportToExcel posts={this.reactTable}
                                               dataTable={this.state.dataTable}
                                               fileName={"Jute indent"}
                                               sheetName={"Order1"}/>
                            </div>
                        )
                    }
                    }*/}
            </ReactTable>
            {this.state.listVideoLink !== "" ? (
              <IconButton
                right={true}
                style={{ marginTop: "-40px" }}
                color="success"
                onClick={() => {
                  this.setState({ playVideo: true });
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : null}
            {this.state.listUserManualLink !== "" ? (
              <IconButton
                right={true}
                style={{ marginTop: "-40px" }}
                color="primary"
                onClick={() => {
                  window.open(this.state.listUserManualLink, "_/");
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : null}

            <ExportToExcel
              posts={this.state.dataTable.data}
              dataTable={this.state.dataTable}
              fileName={this.props.fileName}
              sheetName={"Order1"}
            />
            {printDoc !== undefined ? (
              <SamplePDF printDocument={printDoc} />
            ) : null}
            {this.state.playVideo ? (
              <Popup
                closePopup={() => {
                  this.setState({ playVideo: false });
                }}
                title="Post Temperature"
                isShowSave={false}
                isShowCancel={true}
                cancel_button_text={"Close"}
                //juteType={itemtype}
                content={
                  <GridContainer>
                    <ItemGrid xs={12} style={{ height: 300 }}>
                      <iframe
                        src={this.state.listVideoLink}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 10,
                          height: "97%",
                          width: "100%",
                        }}
                        title="video"
                      />
                    </ItemGrid>
                  </GridContainer>
                }
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default TableComponent;
