import * as types from "./action-types";

export function setComponyAction(componyInfo) {
  return { type: types.SET_COMPANY, componyInfo };
}

export function setCompaniesAction(companies = []) {
  return { type: types.SET_COMPANIES, companies };
}

export function fetchCompony() {
  return { type: types.FETCH_COMPANY };
}

export function fetchCompanies() {
  return { type: types.FETCH_COMPANY };
}

export function getComponyAction() {
  return (dispatch) => {
    dispatch(fetchCompony());
  };
}
