// ##############################
// // // Table styles
// #############################

import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
 // tooltip
} from "../../../../assets/jss/material-dashboard-pro-react.jsx";

const tableStyle = theme => ({
  warning: {
    color: warningColor
  },
  primary: {
    color: primaryColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  right: {
    textAlign: "right"
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    overflow: "auto"
  },
  tableShoppingHead: {
    fontSize: "0.9em !important",
    textTransform: "uppercase !important"
  },
  tableHeadFontSize: {
    fontSize: "18px"
  },
  tableHeadCell: {
    color: "#003F66",
    border: "none !important",
    fontSize: "14px"

  },
  tableHeadCellRight: {
    color: "#003F66",
    border: "none !important",
    fontSize: "14px",
    textAlign:"right",
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "6px 5px!important",
    verticalAlign: "middle",
    // fontSize: "12px",
    borderBottom: "none",
    borderTop: "1px solid #ddd",
    position: "relative",

  },
    tableCellRight:{
      ...defaultFont,
        lineHeight: "1.42857143",
        padding: "6px 5px!important",
        verticalAlign: "middle",
        // fontSize: "12px",
        borderBottom: "none",
        borderTop: "1px solid #ddd",
        position: "relative",
        textAlign:"right"
    },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "6px 5px!important",
    verticalAlign: "middle",
    fontSize: "12px",
    borderBottom: "none",
    borderTop: "1px solid #ddd",
    position: "relative",
    wordBreak:"break-word",
    maxWidth:"60px"
    
  },
  tableCellTotal: {
    fontWeight: "500",
    fontSize: "1.2em",
    paddingTop: "5px",
    textAlign: "right"
  },
  tableCellAmount: {
    fontSize: "26px",
    fontWeight: "300",
    marginTop: "5px",
    textAlign: "right"
  },
  tableResponsive: {
    // width: "100%",
    minHeight: "0.1%",
    overflowX: "auto"
  },
  tableStripedRow: {
    backgroundColor: "#f9f9f9"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  },
  warningRow: {
    backgroundColor: "#fcf8e3",
    "&:hover": {
      backgroundColor: "#faf2cc"
    }
  },
  dangerRow: {
    backgroundColor: "#f2dede",
    "&:hover": {
      backgroundColor: "#ebcccc"
    }
  },
  successRow: {
    backgroundColor: "#dff0d8",
    "&:hover": {
      backgroundColor: "#d0e9c6"
    }
  },
  infoRow: {
    backgroundColor: "#d9edf7",
    "&:hover": {
      backgroundColor: "#c4e3f3"
    }
  },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
  tooltip:{
    color: "#FFFFFF",
    fontSize: "14px",
  }
});

export default tableStyle;
