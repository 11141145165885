import React from "react";
// react component for creating dynamic tables
// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import RegularCard from "../../components/Cards/RegularCard";
import ExpandMoreIcon from '@material-ui/icons/Add';
import TableComponent from "../CustumComponents/TableComponent";
import Button from "../../components/CustomButtons/Button";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import cons, {serverConfig, serverApi} from '../../helper/Consts';
import SelectboxTemp from "../CustumComponents/SelectboxTemp";
import withStyles from "material-ui/styles/withStyles";
import TextField from "../../components/CustomInput/CustomTextField";
import {DELETE, VIEW} from "../../ui-actions/UiActions";
import Popup from "../../components/Popup/PopUp";
import {getDataFromUrl, requestList, getFileDownload, uploadFile} from "../../server/server";
import moment from "moment";
import {getCookie} from "../../helper/Cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import PermissionAlert, {getMenuPermission} from "../CustumComponents/PermissionAlert";
import InputAdornment from "material-ui/Input/InputAdornment";
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { validate } from "../../components/Validation/validate";



class BranchMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basicAlert: null,
            isCreated: false,
            fromDate: "",
            todate: "",
            status: '',
            isRefresh: false,
            isLoading: false,
            itemViewType: "id",
            approveStatus: false,
            date1: moment().format('DD-MM-YYYY'),
            year: moment().format('YYYY'),
            issueStatus: "3",
            taxName: '',
            branchName:'',
            address: '',
            branchId:'',
            gstNo: '',
            contactNo: '',
            personName: '',
            personAddress:'',
            email:'',
            branchHead:'',
            stateName:'',
            stateCode:'',
            statesList:[],
            //itemName:"",
            gstFileName: "",
            otherFileName: "",
            filesList:[],
            filesList1:[]
        }
        this.hideAlert = this.hideAlert.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeValues = this.onChangeValues.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);

    }

    handleSeacrh = () => {
        var loading = false;
        if (this.state.isRefresh) {
            loading = true
        }
        this.setState({
            isRefresh: !this.state.isRefresh,
        });
    };

    handleViewClick = (id) => {
        // var data = this.state.dataTable;
        var juteId = "";
        console.log("jute  " + "  " + id);
        if (!getMenuPermission(0)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            var data={
                "id": id,
                "cipher":getCookie(cons.CIPHER)
            }
            requestList(serverApi.BRANCHMASTER_DETAILS_BYID, data, this.handleViewClickResp);
        }
    };

    handleViewClickResp = (resp) => {
        var header = resp.data;
        if (header !== null) {
            this.setState({
                updateView: true,
                branchId: header.id,
                branchName: header.brname,
                address: header.address,
                gstNo:header.gstNo,
                contactNo:header.contactNo,
                personName:header.personName,
                personAddress:header.personAddress,
                createdBy: header.createdBy,
                createdOn: header.createdOn,
                email:header.email,
                stateName:header.stateName,
                stateCode:header.stateCode,
                branchHead:header.empCode,
                ebId:header.ebId
            });
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"34/"+header.id+"/"+this.props.state.companyInfo.id,this.handleFileView);
            getDataFromUrl(serverApi.GET_FILE_BY_SOURCE+"35/"+header.id+"/"+this.props.state.companyInfo.id,this.handleFileViews);
        }
    };
    handleFileView=(resp)=>{
        console.log("response",resp)
        if(resp.data){
            this.setState({
                filesList:resp.data,
                //isCreated: true
            })
        }
    }
    handleFileViews=(resp)=>{
        console.log("response",resp)
        if(resp.data){
            this.setState({
                filesList1:resp.data,
                isCreated: true
            })
        }
    }


    handleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading,
        });

    }
    componentDidMount() {
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        requestList(serverApi.LEDGER_GROUP_MASTER, data, this.handleLedgerGroup);
        getDataFromUrl(serverApi.GET_ALL_STATES,this.handleStates)
    }
    handleStates=(resp)=>{
        var list=[];
        if(resp.status===undefined){
            resp.forEach(prop=>{
                var obj=prop;
                if(prop.value!==0)
                obj.value=prop.label;
                else
                    obj.value="";
                list.push(obj)
            });
        }
        this.setState({
            statesList:list
        })
    };

    handleCreate = () => {
        //alert();
        if (!this.state.isCreated) {
            this.hideAlertMessage();
        }
        if (this.state.isCreated) {
            this.setState({
                canChangeMukam: true,
                canChangeUnit: true,
            })
        }
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
        //  this.hideAlertMessage();
    };
    getData = () => {
        var data = {
            "companyId": this.props.state.companyInfo.id,
            "cipher": getCookie(cons.CIPHER)
        };
        return data;
    };

    handleChange = name => event => {
        this.setState({[name]: Number(event.target.value)});
    };



    onChangeValues = (event) => {
        var value = event.target.value;
        //console.log("val "+value);
        this.setState({
            [event.target.id]: value
        });
    };

    onSelect = (selectedValue, stateKey) => {
        this.setState({
            [stateKey]: selectedValue
        });
    };

    InvoicebasicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideInvoiceBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}

                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }


                />
            )
        });
    }
    hideInvoiceBasicAlert() {
        const {fileName,fileType,file,uploadChallan,uploadInvoice,billPassNo,uploadSupportFile}=this.state;

        // if (uploadInvoice) {
        //     uploadFile(fileName,file,fileType,2,fileName,billPassNo, this.handleInvoiceResp);
        // }
        // if(uploadSupportFile){
        //     uploadFile(fileName,file,fileType,29,fileName,billPassNo, this.handleInvoiceResp);
        // }
        // if (uploadChallan) {
        //     uploadFile(fileName,file,fileType,3,fileName,billPassNo, this.handleInvoiceResp);
        // }
        if (this.state.deleteGstFile) {
            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.gstFileId, this.handleDeleteInvoiceResp);
        }
        if (this.state.deleteOtherFile) {
            getDataFromUrl(serverApi.DELETE_FILE_BI_ID + this.state.otherDocId, this.handleDeleteInvoiceResp);
        }
        this.setState({
            basicAlert: null,
        });
    }
    handleDeleteInvoiceResp = (resp)=>{
        if(resp.status){
            if(this.state.deleteGstFile){
                var arr = this.state.filesList;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    filesList: arr,
                    deleteGstFile:false,
                    fileId:""
                });
            }else{
                var arr = this.state.filesList1;
                arr.splice(this.state.fileId, 1);
                this.setState({
                    filesList1: arr,
                    deleteOtherFile: false,
                    fileId: ""
                });
            }
        }
        console.log("resp",JSON.stringify(resp));

    };
    hideBasicAlert() {
        if (this.state.updateItemAlert)
            this.hideAlert();
        this.setState({
            basicAlert: null,
            deleteItem: false,
            updateItemAlert: false
        });

    }

    hideItemBasicAlert() {
        if (this.state.updateItemAlert) {
            this.setState({
                alert: true,
                updateItemAlert: false
            });
        }
        if (this.state.deleteItem) {
            var data = this.state.invoiceList;
            data.splice(this.state.id, 1);
            //  if(this.state.approveStatus)
            this.setState({
                invoiceList: data,
                deleteItem: false
            });
        }
        this.setState({
            basicAlert: null,
        });

    }

    hideAlert() {
        console.log("alert");
        this.setState({
            updateItem: false,
            isJuteSelected: false,
            hideJuteType: false,
            alert: false,
            showMessagealert: false,
            loading: false,
            

        });
    }

    hideAlertMessage() {
        this.setState({
            canChangeMukam: true,
            canChangeUnit: true,
            isCreated: false,
            approveStatus: false,
            showMessagealert: false,
            updateView: false,
            alert: false,
            basicAlert: false,
            branchName:'',
            address: '',
            gstNo: '',
            contactNo: '',
            personName: '',
            personAddress:'',
            email:'',
            stateName:'',
            stateCode:'',
            branchHead:"",
            gstFile: "",
            gstFileName: "",
            gstFileType: "",
            otherFile: "",
            otherFileName: "",
            otherFileType: "",
            filesList:[]
        });

    }

    basicAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }

                />
            )
        });
    }
    handleDownloadedFile=(url)=>{
        window.open(url);
    };
    hideDeleteAlert(id) {
        this.setState({basicAlert: null});
        getDataFromUrl(serverApi.DATATABLE_SERVICE_URL + "/deleteJuteIssue/" + id + "/" + getCookie(cons.CIPHER), this.handleDeleteClickResp);
    }

    basicDeleteAlert(msg, id) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideDeleteAlert(id)}
                    onCancel={() => this.hideBasicAlert()}
                    showCancel
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"


                />
            )
        });
    }

    basicItemAlert(msg) {
        this.setState({
            basicAlert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", marginLeft: "-80px", fontSize: "8px", color: "red"}}
                    title={msg}
                    onConfirm={() => this.hideItemBasicAlert()}
                    onCancel={() => this.hideBasicAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    showCancel
                />
            )
        });
    }


    onUpdateButtonClick = () => {
        if (!getMenuPermission(1)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {branchName,branchId,address,gstNo,contactNo,personName,personAddress,email,stateName,stateCode,ebId} = this.state;
            var data = {
                "branch": {
                    "brname": branchName,
                    "address": address,
                    "id": branchId,
                    "gstNo": gstNo,
                    "contactNo": contactNo,
                    "personName": personName,
                    "personAddress": personAddress,
                    "email":email,
                    "stateName": stateName,
                    "stateCode":stateCode,
                    "ebId":ebId,
                    "createdBy": getCookie(cons.USERID),
                    
                },
                "postAuthenticationDto": {
                    "companyId": this.props.state.companyInfo.id,
                    "cipher": getCookie(cons.CIPHER)
                }
            };

            

            // if (branchName !== '' && address !== "") {
            //     this.handleLoading();
            //     requestList(serverApi.BRANCHMASTER_UPDATE, data, this.handleOncreateFileUpload)
            // }
            // else {
            //     this.basicAlert("Please Enter All Mandatory Fields..!!");
            // }

            const validateFields = [
                { field: branchName, msg: "Branch Name", type: 1 },
                { field: address, msg: "Branch Address", type: 1 },
                { field: stateName, msg: "State", type: 2 },
                // { field: branchHead, msg: "Branch Head", type: 1 },
                { field: email, msg: "Email", type: 1 },
              ];
        
              const validObj = validate(validateFields);
              console.log(validObj);
              if (validObj) {
                if (validObj.type === 1) {
                  this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                } else {
                  this.basicAlert(`Please Select ${validObj.message} ..!!`);
                }
              } else {
                this.handleLoading();
                // this.basicAlert("Created Successfully");
                requestList(serverApi.BRANCHMASTER_UPDATE, data, this.handleOncreateFileUpload)
              }
        }
    };

    onCreateButtonClick = () => {
        if (!getMenuPermission(2)) {
            this.basicAlert("Access Denied. Please contact administrator!!!")
        } else {
            const {branchName, address,gstNo,contactNo,personName,personAddress,email,stateCode,stateName,ebId,branchHead} = this.state;
            var data = {
                "branch": {
                    "brname": branchName,
                    "address": address,
                    "gstNo": gstNo,
                    "contactNo": contactNo,
                    "personName": personName,
                    "personAddress": personAddress,
                    "email":email,
                    "stateName": stateName,
                    "stateCode":stateCode,
                    "ebId":ebId,
                    "createdBy": getCookie(cons.USERID)
                },
                "postAuthenticationDto": {
                    "companyId": this.props.state.companyInfo.id,
                    "cipher": getCookie(cons.CIPHER)
                }
            };

            // if (branchName!== ''&& address!== ""&& stateName!==""&& email!=="") {
            //     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            //     if (pattern.test(email)) {
            //     if(gstNo!==""){
            //         var compareStr = this.state.gstNo.slice(0,2);
            //         if(compareStr===stateCode){
            //             this.handleLoading();
            //             requestList(serverApi.BRANCHMASTER_ADD, data, this.handleOncreateFileUpload)
            //         }
            //         else{
            //             this.basicAlert("GST Number is not matching with State Code ..!!");
            //         }
            //     }
            //     else{
            //         this.handleLoading();
            //         requestList(serverApi.BRANCHMASTER_ADD, data, this.handleOncreateFileUpload)
            //     }
            // }
            // else{
            //     this.basicAlert("Please Enter valid Email..!!");
            // }
            // }
            // else {
            //     this.basicAlert("Please Enter All Mandatory Fields..!!");
            // }

            const validateFields = [
                { field: branchName, msg: "Branch Name", type: 1 },
                { field: address, msg: "Branch Address", type: 1 },
                { field: stateName, msg: "State", type: 2 },
                // { field: branchHead, msg: "Branch Head", type: 1 },
                { field: email, msg: "Email", type: 1 },
              ];
        
              const validObj = validate(validateFields);
              console.log(validObj);
              if (validObj) {
                if (validObj.type === 1) {
                  this.basicAlert(`Please Enter ${validObj.message} ..!!`);
                } else {
                  this.basicAlert(`Please Select ${validObj.message} ..!!`);
                }
              } else {
                this.handleLoading();
                // this.basicAlert("Created Successfully");
                requestList(serverApi.BRANCHMASTER_ADD, data, this.handleOncreateFileUpload)
              }

        }
    };
    handleOncreateFileUpload = (resp) => {
        //console.log("%%%%%",resp,this.state.gstFileName,resp.id)
        const{gstFileName,gstFile,gstFileType,otherFileName,otherFile,otherFileType}=this.state

        var response = resp
        if (resp.status) {
            if (this.state.gstFileName !== ""||otherFileName!=="") {
                uploadFile(gstFileName,gstFile,gstFileType,34,gstFileName,resp.id,this.handleAddBranch);
                uploadFile(otherFileName,otherFile,otherFileType,35,otherFileName,resp.id,this.handleAddBranch);

            }
            else {
                this.handleAddBranch(response)
            }
        }
        else{
            this.handleAddBranch(response)
        }
    }
    handleAddBranch = (resp) => {
        if (resp.status) {
            console.log("resp " + JSON.stringify(resp));
            this.showMessage(resp.message, resp.status)
        } else {
            this.showMessage(resp.message, resp.status)
        }
        this.handleLoading();
    };

    //add Item

    showDeleteMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };

    showMessage = (msg, status) => {
        this.setState({
            showMessagealert: (
                <Popup
                    //  onClickSave={() => this.onClickAddItem()}
                    onClickSave={() => {
                        if (status) {
                            this.hideAlertMessage();
                        } else {
                            this.hideAlert();
                        }
                    }}
                    isShowSave={true}
                    save_btn_txt={"OK"}
                    content={
                        <form>
                            <legend style={{textAlign: "center"}}>{msg}</legend>
                        </form>

                    }
                >
                </Popup>
            )
        });

    };
    handleFileClick(e) {
        var fName = e.currentTarget.id 
        var itemName = e.currentTarget.name
        var input = document.createElement("input");
        input.id = fName
        input.name = itemName
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let fName = e.target.id
        var itemName = e.target.name
        var fType = fName + "Type"
        let reader = new FileReader();
        let file = e.target.files[0];
        var fileType = fName + 'Type'
        var fileName = file.name, fileType;
        fileName = fileName.split(".");
        fileType = fileName[1].toUpperCase();
        reader.onloadend = () => {
            if (fileType === 'PDF' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG') {
                this.setState({
                    //file: file,
                    [fName]: file,
                    [itemName]: file.name,
                    [fType]: fileType,
                    //filesList: arr,
                    imagePreviewUrl: reader.result
                });
                console.log("^^^", this.state)
                //this.UploadbasicAlert("Do you want to upload file " + file.name + "?");
            } else {
                this.basicAlert("File type you have selected can not be upload. Please select another file!!!")
            }


        };
        reader.readAsDataURL(file);

    }
    handleFileClose = (e) => {
        //console.log("YYY",e.currentTarget.id)
        if (e.currentTarget.id === "gstFile") {
            this.setState({
                gstFile: "",
                gstFileName: "",
                gstFileType: ""
            })
        }
        else if (e.currentTarget.id === "otherFile") {
            this.setState({
                otherFile: "",
                otherFileName: "",
                otherFileType: ""
            })

        }
        this.setState({
            itemName: "",
            file: "",
            fileType: ""
        });
    }
    getEmpDetails=()=>{
        const {branchHead} = this.state;
        if(branchHead!==''){
            getDataFromUrl(serverApi.WORKER_MASTER_DETAIL_BY_EBNO+branchHead+"/"+this.props.state.companyInfo.id+"/"+getCookie(cons.USERID), this.handleEmpDetails)
        }else if(branchHead===''){
            this.basicAlert("Please Enter Employee No..!!")
        }

    };
    handleEmpDetails = (resp) => {
        if (resp.status) {
            var header = resp.data;
            this.setState({
                ebId:header.ebId,
                personName:header.workerName,
                contactNo:header.mobileNo,
                email:header.officialEmailId,
                //branchHeadId: header.
                //empName: header.workerName,
                //department:header.deptIdDesc,
                //designation:header.designationIdDesc,
                
                loadDept:true
                
            });

        }else {
            this.showMessage(resp.message,false)
        }


    };
    render() {
        console.log("****",this.state.filesList)
        const {classes} = this.props;
        const {selectedJuteType} = this.state;

        const inputFocus = {
            autoComplete: 'off'
        }
        return (
            <GridContainer justify="center" style={{backgroundColor: "#00ABDC"}}>
                {this.state.isCreated ?

                    <ItemGrid xs={12}>
                        <RegularCard
                            //isLoading={this.state.isLoading}
                            cardTitle={
                                <Button color={"info"} onClick={this.handleCreate}>
                                    Back To List</Button>
                            }
                            content={
                                <GridContainer>
                                    {this.state.basicAlert}
                                    {this.state.showMessagealert}
                                    
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Branch Name<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="branchName"
                                                    value={this.state.branchName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                   Branch Address<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="address"
                                                    value={this.state.address}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                    multiline
                                                    rowsMax="3"
                                                    height={60}
                                                />

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}  style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                        State<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={6}>
                                                                    <SelectboxTemp
                                                                        id="stateName"
                                                                        onSelect={this.onSelect}
                                                                        defValue={this.state.stateName}
                                                                        getName={(name)=>{
                                                                            var val=name.split("^");
                                                                            this.setState({stateCode:val[1]!=="null"?val[1]:""})

                                                                        }}
                                                                        stateKey="stateName"
                                                                        staticData={this.state.statesList}
                                                                       // url={serverApi.GET_ALL_STATES}
                                                                    />
                                                                </ItemGrid>
                                                            </GridContainer>

                                                        </ItemGrid>

                                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                                            <GridContainer>
                                                                <ItemGrid xs={12} sm={4}>
                                                                    <InputLabel className={classes.label}>
                                                                       State Code
                                                                    </InputLabel>
                                                                </ItemGrid>
                                                                <ItemGrid xs={12} sm={6}>
                                                                    <TextField
                                                                        disabled={true}
                                                                        id="stateCode"
                                                                        value={this.state.stateCode}
                                                                        className={classes.textField}
                                                                        onChange={this.onChangeValues}
                                                                        fullWidth={true}
                                                                        InputProps={inputFocus}

                                                                    />

                                                                </ItemGrid>
                                                            </GridContainer>
                                                        </ItemGrid>
                                    {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    State Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="stateName"
                                                    value={this.state.stateName}
                                                    className={classes.textField}
                                                    //onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    State Code
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="stateCode"
                                                    value={this.state.stateCode}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
                                    {/* Branch Heads */}
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4} >
                                                <InputLabel className={classes.label}>
                                                    Branch Head<span style={{color:"red" ,fontSize:"12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="branchHead"
                                                    value={this.state.branchHead}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <span style={{color:"#00ABDC",fontSize:"16px",fontWeight:"400"}}>

                                                                <Search
                                                                    className={classes.inputAdornmentIcon} onClick={this.getEmpDetails}
                                                                />
                                                                </span>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Person Name
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="personName"
                                                    value={this.state.personName}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Contact Number
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="contactNo"
                                                    value={this.state.contactNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    iType={"number"}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                  Email<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="email"
                                                    value={this.state.email}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid>

                                    {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Person Address
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="personAddress"
                                                    value={this.state.personAddress}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid> */}
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    GST NO
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="gstNo"
                                                    value={this.state.gstNo}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                />
                                            </ItemGrid>
                                            {this.state.gstNo !== "" && this.state.gstNo !== null && this.state.gstNo.length > 0 ?
                                                <ItemGrid sm={2}>
                                                    <Button color="info" size="sm" id="gstFile" name="gstFileName"
                                                        onClick={this.handleFileClick}>UPLOAD
                                                    </Button>
                                                </ItemGrid> : null}
                                            {this.state.updateView?
                                            this.state.filesList.map((r, i) => (
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid xs={12} sm={1}>
                                                    {i+1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                            getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                        }}>{r.fileName}</a>
                                                </ItemGrid>
                                                <ItemGrid xs={1}>
                                                    <IconButton
                                                        right={true}
                                                        onClick={() => {
                                                            this.setState({
                                                                deleteGstFile: true,
                                                                gstFileId: r.fileUploadId,
                                                                fileId: i
                                                            });
                                                            this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        }}
                                                        color="dangerNoBackground"
                                                    >
                                                        <Close/>
                                                    </IconButton> {""}
                                                </ItemGrid>
                                            </GridContainer>
                                            )):null}
                                            {this.state.gstFileName !== "" ?
                                             <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid xs={12} sm={1}>
                                                    {this.state.filesList.length+1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a>{this.state.gstFileName}</a>
                                                </ItemGrid>
                                                <ItemGrid xs={1}>
                                                        <IconButton id="gstFile"
                                                            right={true}
                                                            onClick={this.handleFileClose}
                                                            //  onClick={() => {
                                                            //      this.setState({
                                                            //          deleteInvoice: true,
                                                            //          invoiceId: r.fileUploadId,
                                                            //          fileId: i
                                                            //      });
                                                            //      this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                            //  }}
    
                                                            color="dangerNoBackground"
                                                        >
                                                            <Close/>
                                                        </IconButton> {""}
                                                    </ItemGrid>
                                            </GridContainer>
                                                // <GridContainer>
                                                //     <ItemGrid xs={12} sm={4}></ItemGrid>
                                                //     <ItemGrid sm={6}>
                                                //         <h6>{this.state.gstFileName}<span id="gstFile" style={{ color: "red", margin: "0 0 0 10px", cursor: "pointer" }} onClick={this.handleFileClose}>X</span></h6>
                                                //     </ItemGrid>
                                                // </GridContainer>
                                                :null
                                                }
                                            
                                        </GridContainer>
                                    </ItemGrid>
                                    {/* GST DOC Upload */}
                                    {/* <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    GST Doc<span style={{color: "red", fontSize: "12px"}}>*</span>
                                                </InputLabel>
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="gstDoc"
                                                    value={this.state.gstDoc}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid>
                                            <ItemGrid sm={2}>
                                            <Button color="info" size="sm"
                                                            onClick={() => {
                                                                

                                                            }}>UPLOAD</Button>
                                                </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid> */}
                                    <ItemGrid xs={12} md={6} style={{ marginTop: "10px" }}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Other Docs
                                                </InputLabel>
                                            </ItemGrid>
                                            {/* <ItemGrid xs={12} sm={6}>
                                                <TextField
                                                    id="gstDoc"
                                                    value={this.state.gstDoc}
                                                    className={classes.textField}
                                                    onChange={this.onChangeValues}
                                                    fullWidth={true}
                                                    InputProps={inputFocus}
                                                />
                                            </ItemGrid> */}
                                            <ItemGrid sm={2}>
                                                <Button color="info" size="sm" id="otherFile" name="otherFileName"
                                                    onClick={this.handleFileClick}>UPLOAD
                                                </Button>
                                            </ItemGrid>
                                            {this.state.updateView?
                                            this.state.filesList1.map((r, i) => (
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid xs={12} sm={1}>
                                                    {i+1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a onClick={()=>{
                                                            getFileDownload(serverConfig.SERVER_URL + r.retrievalPath,r.fileName,r.fileExtension,this.handleDownloadedFile)
                                                        }}>{r.fileName}</a>
                                                </ItemGrid>
                                                <ItemGrid xs={1}>
                                                        <IconButton
                                                            right={true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    deleteOtherFile: true,
                                                                    otherDocId: r.fileUploadId,
                                                                    fileId: i
                                                                });
                                                                this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                            }}

                                                            color="dangerNoBackground"
                                                        >
                                                            <Close/>
                                                        </IconButton> {""}

                                                    </ItemGrid>
                                            </GridContainer>
                                            )):null}
                                            {this.state.otherFileName !== "" ?
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}></ItemGrid>
                                                <ItemGrid xs={12} sm={1}>
                                                    {this.state.filesList1.length+1}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <a>{this.state.otherFileName}</a>
                                                </ItemGrid>
                                                <ItemGrid xs={1}>
                                                        <IconButton id="otherFile"
                                                            right={true}
                                                            onClick={this.handleFileClose}
                                                        //  onClick={() => {
                                                        //      this.setState({
                                                        //          deleteInvoice: true,
                                                        //          invoiceId: r.fileUploadId,
                                                        //          fileId: i
                                                        //      });
                                                        //      this.InvoicebasicAlert("Do You Like To Delete This File?");
                                                        //  }}

                                                            color="dangerNoBackground"
                                                        >
                                                            <Close/>
                                                        </IconButton> {""}

                                                    </ItemGrid>
                                            </GridContainer>
                                                // <ItemGrid sm={6}>
                                                //     <h6>{this.state.otherFileName}<span id="otherFile" style={{ color: "red", margin: "0 0 0 10px", cursor: "pointer" }} onClick={this.handleFileClose}>X</span></h6>
                                                // </ItemGrid> 
                                                : null}
                                        </GridContainer>
                                    </ItemGrid>
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} md={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdBy"
                                                        value={this.state.createdBy}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        InputProps={inputFocus}

                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} sm={6} style={{marginTop: "10px"}}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <InputLabel className={classes.label}>
                                                        Created On
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <TextField
                                                        disabled={true}
                                                        id="createdOn"
                                                        value={this.state.createdOn}
                                                        className={classes.textField}
                                                        onChange={this.onChangeValues}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        InputProps={inputFocus}

                                                    />
                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid> : null}
                                    <ItemGrid xs={12} sm={12} style={{marginTop: "10px"}}>
                                        <GridContainer justify="center">

                                        </GridContainer>
                                    </ItemGrid>

                                    {!this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onCreateButtonClick}>Create</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }
                                    {this.state.updateView ?
                                        <ItemGrid xs={12} style={{marginTop: "5px"}}>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    <Button color="primary"
                                                            onClick={this.onUpdateButtonClick}>Update</Button>
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid> : null
                                    }

                                </GridContainer>
                            }/>

                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <RegularCard
                            /* icon={ExpandMoreIcon}
                             iconColor={"green"}*/
                            content={
                                <div>
                                    <GridContainer>
                                        <ItemGrid xs={10}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    {this.state.basicAlert}
                                                    {this.state.showMessagealert}
                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <GridContainer>
                                                <ItemGrid xs={12}>
                                                    <IconButton
                                                        onClick={this.handleCreate}
                                                        color="success" right={true}>
                                                        <ExpandMoreIcon/>
                                                    </IconButton>

                                                </ItemGrid>

                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12}
                                                  style={{
                                                      height: 2,
                                                      backgroundColor: "#00acc1",
                                                      marginTop: "5px"
                                                  }}></ItemGrid>

                                        <ItemGrid xs={12}>


                                            <TableComponent
                                                url={serverApi.BRANCHMASTER_DATATABLE}
                                                searchData={this.getData()}
                                                isRefresh={this.state.isRefresh}
                                                handleSearch={this.handleSeacrh.bind(this)}
                                                isLoading={this.state.isRefresh}
                                                onActionClick={this.handleViewClick}
                                                itemViewType={this.state.itemViewType}
                                                actionType={VIEW}
                                                showDelete={false}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                </div>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#00ABDC",
        fontSize: "15px",
        //  lineHeight: "1.0",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    textField: {},
    input: {
        border: "1px solid #ccc",
        borderRadius: "4px"
    },

    input_group_addonunit: {
        padding: 0,
        width: "70px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        overflow: "hidden"
    },
    buttontxt: {
        textTransform: "none",
        borderRadius: "4px",
        lineHeight: "1.2"
    },
    ...customSelectStyle,
    ...sweetAlertStyle
};

function mapStateToProps(state) {
  return { state: state.compony };
};

export default connect(mapStateToProps)(
  withStyles(extendedFormsStyle)(BranchMaster)
);
